/**
 * Breaks items into batches of given size, runs each through the provided request,
 * and returns the results in a flat array. Will run consecutive by default, though
 * can be run concurrently if passed the concurrent mode parameter
 */
export async function batchedFetch<T, U>(
  request: (items: T[]) => Promise<U[]>,
  items: T[],
  batchSize: number,
  mode: 'concurrent' | 'consecutive' = 'consecutive'
): Promise<U[]> {
  if (mode == 'concurrent') {
    return batchedFetchConcurrent(request, items, batchSize);
  }

  return batchedFetchConsecutive(request, items, batchSize);
}

async function batchedFetchConsecutive<T, U>(
  request: (items: T[]) => Promise<U[]>,
  items: T[],
  batchSize: number
): Promise<U[]> {
  if (items.length <= batchSize) {
    return request(items);
  }

  const firstSet = items.slice(0, batchSize);
  const secondSet = items.slice(batchSize);

  const firstRequest = await batchedFetchConsecutive(
    request,
    firstSet,
    batchSize
  );
  const secondRequest = await batchedFetchConsecutive(
    request,
    secondSet,
    batchSize
  );

  return [...firstRequest, ...secondRequest];
}

async function batchedFetchConcurrent<T, U>(
  request: (items: T[]) => Promise<U[]>,
  items: T[],
  batchSize: number
): Promise<U[]> {
  if (items.length <= batchSize) {
    return request(items);
  }

  const firstSet = items.slice(0, batchSize);
  const secondSet = items.slice(batchSize);

  const firstRequest = batchedFetchConcurrent(request, firstSet, batchSize);
  const secondRequest = batchedFetchConcurrent(request, secondSet, batchSize);

  const [first, second] = await Promise.all([firstRequest, secondRequest]);
  return [...first, ...second];
}
