import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useHttpService, useTableItemContext } from '../../context';
import { useModalOutput } from '../../hooks/useModalOutput';
import type { ConfirmDeleteModalProps } from './confirmDeleteModal';
import { ConfirmDeleteModal } from './confirmDeleteModal';
import {
  notifyDeleteFailed,
  notifyDeleteNotAllowed,
  notifyItemsDeleted
} from './notifications';
import { permanentlyDeleteItem } from './recycleBinDelete';
import { allowPermanentlyDeleteItem } from './requirements';

type RecycleBinDeleteFunction = (items: PWItem[]) => Promise<void>;

export function useRecycleBinDelete(): RecycleBinDeleteFunction {
  const httpService = useHttpService();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const getModalOutput = useModalOutput<boolean, ConfirmDeleteModalProps>(
    ConfirmDeleteModal
  );

  const recycleBinDeleteWorkflow = useCallback(
    async (items: PWItem[]): Promise<void> => {
      if (!items.every(allowPermanentlyDeleteItem)) {
        notifyDeleteNotAllowed();
        return;
      }

      const confirm = await getModalOutput({ items });
      if (!confirm) {
        return;
      }

      try {
        await Promise.all(
          items.map((item) => permanentlyDeleteItem(item, httpService))
        );
        notifyItemsDeleted(items);
        refreshCurrentFolder();
      } catch {
        notifyDeleteFailed(items);
      }
    },
    [getModalOutput, httpService, refreshCurrentFolder]
  );

  return recycleBinDeleteWorkflow;
}
