import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { Input, Label } from '@itwin/itwinui-react';
import { useTableItemContext } from '../../../context';

type PWInfoRowProps<T extends PWItem> = {
  id?: string;
  label: string;
  property: keyof T | ((item: T) => string);
  filter?: (items: PWItem[]) => boolean;
};

export function PWInfoRow<T extends PWItem>({
  label,
  id = label,
  property,
  filter
}: PWInfoRowProps<T>): JSX.Element | null {
  const { actionableItems } = useTableItemContext();

  if (filter && !filter(actionableItems)) {
    return null;
  }

  function valueFromItem(item: T): string {
    if (typeof property === 'function') {
      return property(item);
    }
    return String(item[property] ?? '');
  }

  const value = valueFromItem(actionableItems[0] as T);

  if (actionableItems.length !== 1) {
    const commonPropertyValue = actionableItems.reduce(
      (commonProperty, item) =>
        commonProperty == valueFromItem(item as T) ? commonProperty : '',
      value
    );

    if (commonPropertyValue != value) {
      return (
        <>
          <Label htmlFor={`${id}-row`}>{label}</Label>
          <Input
            key={actionableItems[0].instanceId}
            size="small"
            id={`${id}-row`}
            value=""
            disabled={true}
          />
        </>
      );
    }
  }

  return (
    <>
      <Label htmlFor={`${id}-row`}>{label}</Label>
      <Input
        key={actionableItems[0].instanceId}
        size="small"
        id={`${id}-row`}
        value={value}
        readOnly={true}
      />
    </>
  );
}
