/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { UserManagerSettings, UserSettings } from 'oidc-client';
import { Log, User, UserManager } from 'oidc-client';

export class UserManagerWithSaml extends UserManager {
  private samlSignInInProgress?: boolean;

  constructor(settings: UserManagerSettings) {
    super(settings);
  }

  get samlStoreKey(): string {
    return `samlUser:${this.settings.authority}:${this.settings.client_id}`;
  }

  async loadSamlUser(): Promise<User | null> {
    const storageString = (await this.settings.userStore?.get(
      this.samlStoreKey
    )) as string | null;

    if (!storageString) {
      Log.debug('UserManagerExtended.loadSamlUser: no user storageString');
      return null;
    }

    Log.debug(
      'UserManagerExtended.loadSamlUser: samlUser storageString loaded'
    );
    const user = User.fromStorageString(storageString);
    return user;
  }

  /**
   * getSamlUser can be called after user successfully signs in.
   * User returned by this function only has one saml scope and is not the same as the one returned by UserManager.getUser()
   * @param {string} samlScope scope for which saml token is needed e.g. https://qa-ims-services.bentley.com
   */
  async getSamlUser(samlScope: string, forceRefresh = false): Promise<User> {
    if (forceRefresh) {
      const user = await this.requestSamlTokenSilent(samlScope);
      return user;
    }

    const user = await this.loadSamlUser();
    if (user && !user.expired) {
      Log.debug('UserManagerExtended.loadSamlUser: samlUser loaded');
      return user;
    }

    await this.storeSamlUser(null);

    const refreshedUser = await this.requestSamlTokenSilent(samlScope);
    return refreshedUser;
  }

  async removeSamlUser(): Promise<void> {
    await this.storeSamlUser(null);
  }

  async storeSamlUser(user: User | null): Promise<void> {
    if (!user || !user.access_token.startsWith('PHN')) {
      Log.debug('UserManagerExtended.storeSamlUser: removing samlUser');
      await this.settings.userStore?.remove(this.samlStoreKey);
      return;
    }

    Log.debug('UserManagerExtended.storeSamlUser: storing user');
    const storageString = user.toStorageString();
    await this.settings.userStore?.set(this.samlStoreKey, storageString);
  }

  async requestSamlTokenSilent(samlScope: string): Promise<User> {
    if (!this.settings.silent_redirect_uri) {
      throw new Error('No silent_redirect_uri configured');
    }

    try {
      if (this.samlSignInInProgress) {
        await waitForAsyncCondition(async () => {
          const user = await this.loadSamlUser();
          return user != null;
        }, 1000);

        const user = await this.getSamlUser(samlScope);
        return user;
      }

      this.samlSignInInProgress = true;

      const args = {
        scope: samlScope,
        redirect_uri: this.settings.silent_redirect_uri as string,
        prompt: 'none'
      };

      const navigatorParams = {
        startUrl: this.settings.silent_redirect_uri as string,
        silentRequestTimeout: this.settings.silentRequestTimeout as number
      };

      const navResponse = await (this as any)._signinStart(
        args,
        (this as any)._iframeNavigator,
        navigatorParams
      );

      const signinResponse = await this.processSigninResponse(
        navResponse.url as string
      );
      const expires_at =
        Date.now() + (signinResponse.expires_in ?? 3600) * 1000;
      const userSettings = { ...signinResponse, expires_at } as UserSettings;

      const user = new User(userSettings);
      await this.storeSamlUser(user);
      return user;
    } finally {
      this.samlSignInInProgress = false;
    }
  }
}

async function waitForAsyncCondition(
  condition: () => Promise<boolean>,
  pollFrequency: number
): Promise<void> {
  return new Promise((resolve) => {
    async function checkCondition() {
      if (await condition()) {
        resolve();
      } else {
        setTimeout(() => void checkCondition(), pollFrequency);
      }
    }

    void checkCondition();
  });
}
