import './spinner.css';

import React from 'react';
import { ProgressRadial } from '@itwin/itwinui-react';

type DCWSpinnerProps = {
  className?: string;
};

export function DCWSpinner({ className }: DCWSpinnerProps): JSX.Element {
  return (
    <div className={`dcw-spinner ${className ?? ''}`} data-testid="dcw-spinner">
      <ProgressRadial size="large" indeterminate />
    </div>
  );
}
