import { useMemo } from 'react';
import { itemIsProject } from '@bentley/pw-api';
import { ActionColumn, tableFilters } from '@itwin/itwinui-react';
import type { CellProps, Column } from '@itwin/itwinui-react/react-table';
import type { AuditTrail } from '../../../actions/exportAuditTrail';
import { useTableItemContext } from '../../../context';
import { formatDateTime } from '../../../services/stringFormatter';
import { t } from '../../../services/translation';
import { ColumnHeader } from '../../pwTable/cell/header/columnHeader';
import { ColumnValuesFilter } from '../../pwTable/filter/columnValuesFilter';

type AuditTrailColumns = {
  columns: Column<AuditTrail>[];
  hiddenColumns?: string[];
};

export function useAuditTrailColumns(): AuditTrailColumns {
  const { actionableItems } = useTableItemContext();

  const translatedLabels = useMemo(
    () => ({
      filter: t('Search.Filter'),
      clear: t('Search.Clear'),
      from: t('Generic.From'),
      to: t('Generic.To')
    }),
    []
  );

  const columns = useMemo(
    (): Column<AuditTrail>[] => [
      {
        id: 'date',
        accessor: (row) => new Date(row.Date),
        Header: () => ColumnHeader({ displayLabel: t('Generic.Date') }),
        Cell: (props: CellProps<AuditTrail>) =>
          formatDateTime(props.value as string),
        Filter: tableFilters.DateRangeFilter({
          translatedLabels,
          showYearSelection: true
        }),
        filter: 'betweenDate',
        width: 165
      },
      {
        id: 'user',
        accessor: 'UserName',
        Header: () => ColumnHeader({ displayLabel: t('Generic.User') }),
        Filter: ColumnValuesFilter,
        width: 120
      },
      {
        id: 'action',
        accessor: 'ActionName',
        Header: () =>
          ColumnHeader({ displayLabel: t('AuditTrail.ActionName') }),
        Filter: ColumnValuesFilter,
        width: 130
      },
      {
        id: 'objectType',
        accessor: 'ObjectType',
        Header: () =>
          ColumnHeader({ displayLabel: t('AuditTrail.ObjectType') }),
        Filter: ColumnValuesFilter,
        width: 120
      },
      {
        id: 'objectName',
        accessor: 'ObjectName',
        Header: () => ColumnHeader({ displayLabel: t('Generic.Name') }),
        Filter: ColumnValuesFilter,
        width: 150
      },
      {
        id: 'data',
        accessor: 'AdditionalData',
        Header: () =>
          ColumnHeader({ displayLabel: t('AuditTrail.AdditionalData') }),
        Filter: tableFilters.TextFilter(translatedLabels),
        width: 190
      },
      {
        id: 'comment',
        accessor: 'Comment',
        Header: () => ColumnHeader({ displayLabel: t('AuditTrail.Comments') }),
        Filter: tableFilters.TextFilter(translatedLabels),
        width: 160
      },
      ActionColumn({ columnManager: true })
    ],
    [translatedLabels]
  );

  const hiddenColumns = useMemo(() => {
    if (!itemIsProject(actionableItems[0])) {
      return ['objectName', 'objectType'];
    }

    return [];
  }, [actionableItems]);

  return { columns, hiddenColumns };
}
