import type {
  HttpService,
  PWDataItem,
  PWFlatSet,
  PWItem
} from '@bentley/pw-api';
import { filterDataItems, parseSetRelationshipInstance } from '@bentley/pw-api';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { t } from '../../../services/translation';
import { getRemoveQueryBody } from '../utils';

export async function removeFlatSetMembers(
  flatSet: PWFlatSet,
  httpService: HttpService,
  items: PWItem[],
  onRemoveSuccess?: (item: PWItem, response: Response) => void,
  onRemoveFailure?: (item: PWItem, response: Response) => void,
  onComplete?: () => void
): Promise<Response[] | undefined> {
  const flatSetMembers = filterDataItems(items).filter((item) =>
    parseSetRelationshipInstance(item, flatSet)
  );

  const responses = await Promise.all(
    flatSetMembers.map((flatSetMember) =>
      usingConcurrencyLimiter(
        async () =>
          removeFlatSetMember(
            flatSet,
            flatSetMember,
            httpService,
            onRemoveSuccess,
            onRemoveFailure
          ),
        'background'
      )
    )
  );

  onComplete?.();
  return responses;
}

async function removeFlatSetMember(
  flatSet: PWFlatSet,
  flatSetMember: PWDataItem,
  httpService: HttpService,
  onRemoveSuccess?: (item: PWItem, response: Response) => void,
  onRemoveFailure?: (item: PWItem, response: Response) => void
): Promise<Response> {
  const relationshipInstance = parseSetRelationshipInstance(
    flatSetMember,
    flatSet
  );

  if (!relationshipInstance?.instanceId) {
    throw new Error(t('FlatSet.Notifications.MissingSetId'));
  }

  const query = `PW_WSG/FlatSet/${flatSet.instanceId}`;
  const body = getRemoveQueryBody(
    flatSet.instanceId,
    relationshipInstance.instanceId,
    flatSetMember.instanceId
  );
  const response = await httpService.post(query, body);
  handleResponse(response, flatSetMember, onRemoveSuccess, onRemoveFailure);
  return response;
}

function handleResponse(
  response: Response,
  flatSetMember: PWDataItem,
  onRemoveSuccess?: (item: PWItem, response: Response) => void,
  onRemoveFailure?: (item: PWItem, response: Response) => void
) {
  if (response.ok) {
    onRemoveSuccess?.(flatSetMember, response);
  } else {
    onRemoveFailure?.(flatSetMember, response);
  }
}
