import { useCallback } from 'react';
import { t } from '../../services/translation';
import { showEnablePopupsModal } from './enablePopupsModal';
import type { CoAuthoringError } from './useCoAuthoringClient';
import type { CoAuthoringErrorId } from './useCoAuthoringClient/coAuthoringTypes';
import type { CoAuthoringModal } from './useCoAuthoringModal';

export const useCommonErrorHandler = (
  modal: CoAuthoringModal,
  refreshSessions: () => Promise<string[] | undefined>
): ((
  { errorId, errorMessage }: CoAuthoringError,
  onComplete: () => void
) => void) => {
  return useCallback(
    ({ errorId, errorMessage }: CoAuthoringError, onComplete: () => void) => {
      if (errorId === 'LoginFailed') {
        showEnablePopupsModal(modal.open);
        return;
      }

      const messages: Partial<Record<CoAuthoringErrorId, string>> = {
        SessionNotFound: t('OfficeIntegration.EditSessionEnded'),
        LockedDocument: t('OfficeIntegration.FileLocked'),
        WsgDocumentNotFound: t('Generic.FileNotFound')
      };

      const defaultMessage = t('Generic.AnErrorOccurred', {
        errorMessage
      });

      modal.showPrimary(
        undefined,
        messages[errorId] ?? defaultMessage,
        t('Generic.Error'),
        undefined,
        t('Generic.Close')
      );
      void refreshSessions();
      onComplete();
    },
    [modal, refreshSessions]
  );
};
