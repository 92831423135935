import type { WsgDataType } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';

export type ElementValidation = {
  MaxLength?: number;
  MaxValue?: number;
  MinValue?: number;
};

export function getFormattedText(
  prevValue: WsgDataType,
  format: string,
  validation: ElementValidation
): WsgDataType {
  if (!prevValue) {
    return prevValue;
  }

  // Recursive function might be passed empty string as format and no validation. Return value
  if (!format && !validation) {
    return prevValue;
  }

  // Serial Numbers have optional formatting following '|'
  if (format.startsWith('SerialNumber')) {
    // If format includes '|' it has a text format. Pass format back to function.
    if (format.includes('|')) {
      return getFormattedText(prevValue, format.split('|')[1], validation);
    } else {
      // If no '|' there is no format, return original value
      return prevValue;
    }
  }

  // Determine which type of format to apply
  if (format == 'UpperCase') {
    return formatUpperCase(prevValue);
  } else if (format == 'LowerCase') {
    return formatLowerCase(prevValue);
  } else if (format == 'date') {
    return formatDate(prevValue);
  } else if (format == 'date,day') {
    return formatDateDay(prevValue);
  } else if (format == 'date,sec') {
    return formatDateSeconds(prevValue);
  } else if (format.startsWith('%')) {
    return formatCString(prevValue, format, validation);
  } else if (
    validation.MaxLength &&
    String(prevValue).length > validation.MaxLength
  ) {
    return formatMaxLength(prevValue, validation.MaxLength);
  } else if (
    validation.MaxValue &&
    parseFloat(String(prevValue)) > validation.MaxValue
  ) {
    return validation.MaxValue;
  } else if (
    validation.MinValue &&
    parseFloat(String(prevValue)) < validation.MinValue
  ) {
    return validation.MinValue;
  } else {
    return prevValue;
  }
}

function formatUpperCase(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): string {
  return String(prevValue).toUpperCase();
}

function formatLowerCase(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): string {
  return String(prevValue).toLowerCase();
}

function formatDate(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): number {
  const date = new Date(String(prevValue));
  return date.getDate();
}

function formatDateDay(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): number {
  const date = new Date(String(prevValue));
  return date.getDay();
}

function formatDateSeconds(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): number {
  const date = new Date(String(prevValue));
  return date.getSeconds();
}

function formatCString(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>,
  format: string,
  validation: ElementValidation
): WsgDataType {
  // For now, we only care about taking care of preceding zeros.
  if (format.startsWith('%0')) {
    return formatPrecedingZeros(format, validation, prevValue);
  }

  // ToDo: Handle other c-strings
  return prevValue as WsgDataType;
}

function formatPrecedingZeros(
  format: string,
  validation: ElementValidation,
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>
): string {
  const formatLength = formattedLength(format);
  const maxLength = formatLength ?? validation.MaxLength;
  if (!maxLength) {
    return String(prevValue);
  }

  const length = String(prevValue).length;
  if (length >= maxLength) {
    return String(prevValue);
  }

  return String(prevValue).padStart(maxLength, '0');
}

function formattedLength(format: string): number | undefined {
  const formatParts = format.split(/^%0|^%-/);
  if (formatParts.length < 2) {
    return;
  }

  const formatLength = parseInt(formatParts[1].charAt(0));
  if (isNaN(formatLength)) {
    return;
  }

  return formatLength;
}

function formatMaxLength(
  prevValue: Omit<WsgDataType, 'null' | 'undefined'>,
  maxLength: number
): WsgDataType {
  if (String(prevValue).length <= maxLength) {
    return prevValue as WsgDataType;
  }

  // Check if value is DateTime and possibly needs to be shortened
  const dateTime = parseDateTime(String(prevValue));
  if (dateTime instanceof Date && !isNaN(dateTime.getTime())) {
    return formatDateTime(dateTime, maxLength);
  }

  return String(prevValue).slice(0, maxLength);
}

function parseDateTime(dateString: string): Date {
  const [datePart, timePart = ''] = dateString.split(' ');

  const [year, month, day] = datePart.split('-').map(Number);
  const [hours = 0, minutes = 0, seconds = 0] = timePart.split(':').map(Number);

  const dateTime = new Date(year, month - 1, day, hours, minutes, seconds);
  return dateTime;
}

function formatDateTime(dateTime: Date, maxLength: number): string {
  if (
    dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).length <= maxLength
  ) {
    return dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  } else if (
    dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).length <= maxLength
  ) {
    return dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  } else if (
    dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }).length <= maxLength
  ) {
    return dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
  } else {
    return dateTime.toLocaleString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  }
}
