import React, { useMemo } from 'react';
import { SvgRefresh } from '@itwin/itwinui-icons-react';
import { useRefreshData } from '../../../../actions/refresh';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useRefreshOption(): MenuItemAction {
  const refreshData = useRefreshData();

  const refreshOption = useMemo(
    (): MenuItemAction => ({
      title: t('Generic.Refresh'),
      icon: <SvgRefresh />,
      onClick: () => void refreshData()
    }),
    [refreshData]
  );

  return refreshOption;
}
