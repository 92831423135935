import {
  AuthorizationService,
  CacheService,
  HttpService
} from '@bentley/pw-api';
import type { GetToken } from '../../services/http';

export async function azureAccessibilityCheck(
  getOidcToken: GetToken,
  connectionServiceUrl: string,
  contextId: string,
  connectionId: string
): Promise<boolean> {
  try {
    const httpService = new HttpService({
      authorization: new AuthorizationService({ getOidcToken }),
      baseUrl: connectionServiceUrl,
      cache: new CacheService({ name: 'ConnectionsApi' })
    });
    const url = buildUrl(contextId, connectionId);
    const response = await httpService.get(url);

    const serverAccessible = await parseResponse(response);
    return serverAccessible;
  } catch {
    return false;
  }
}

function buildUrl(contextId: string, connectionId: string): string {
  return `api/v1/context/${contextId}/connections/${connectionId}/accessibility`;
}

async function parseResponse(response: Response): Promise<boolean> {
  if (!response.ok || response.status != 200) {
    return false;
  }

  const data = await response.text();
  return data.toLowerCase() == 'true';
}
