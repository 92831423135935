import type {
  HttpService,
  PWFileType,
  PWParentType,
  PWProject
} from '@bentley/pw-api';
import {
  downloadFile,
  getProject,
  itemIsFlatSet,
  itemIsProject,
  parseRelatedParent
} from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import { deleteItem } from '../delete';
import { copyFlatSet } from '../flatSetActions/utils';
import { renameItem } from '../rename';
import { replaceFile } from '../replace';
import { newFile, uploadDocumentWorkflow } from '../upload';
import type { CustomFile } from '../upload/tree';
import { getItemFromResponse } from '../upload/utils';
import type { OnComplete } from './move';
import { moveItem } from './move';
import { notifyMoveComplete, notifyMoveInProgress } from './notifications';
import type { Outcome } from './outcomes';
import { handleFailedMoves, handleSuccessfulMoves } from './outcomes';
import { newProgressTracker, updateProgressTracker } from './progressTracker';

export async function resolveConflicts(
  resolutions: ConflictResolution[],
  httpService: HttpService,
  trackFeature: TrackFeature,
  destinationParent: PWProject,
  sourceParent: PWParentType,
  openModal: OpenModal,
  closeModal: CloseModal,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  onComplete?: OnComplete
): Promise<void> {
  if (resolutions.every((resolution) => resolution.action == 'keep')) {
    return;
  }

  const moveItems = resolutions
    .filter((resolution) => resolution.action != 'keep')
    .map((resolution) => resolution.sourceItem);
  const toastHandle = notifyMoveInProgress(moveItems.length);
  const progressTracker = newProgressTracker(moveItems);

  const outcomes = (
    await Promise.all(
      resolutions.map(async (resolution) => {
        const outcome = await handleResolutionWorkflow(
          resolution,
          sourceParent,
          httpService,
          trackFeature,
          parent,
          appView,
          readOnly
        );
        updateProgressTracker(progressTracker, outcome?.response);
        return outcome;
      })
    )
  ).filter((outcome) => outcome != undefined);

  notifyMoveComplete(progressTracker, toastHandle);
  await handleSuccessfulMoves(
    outcomes,
    destinationParent,
    sourceParent,
    onComplete
  );
  handleFailedMoves(
    outcomes,
    openModal,
    closeModal,
    destinationParent,
    sourceParent,
    httpService,
    trackFeature,
    parent,
    appView,
    readOnly,
    onComplete
  );
}

async function handleResolutionWorkflow(
  resolution: ConflictResolution,
  sourceParent: PWParentType,
  httpService: HttpService,
  trackFeature: TrackFeature,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): Promise<Outcome | undefined> {
  if (resolution.action == 'keep') {
    return;
  }
  const sourceItem = resolution.sourceItem;
  const destinationItem =
    resolution.destinationItems[resolution.destinationItems.length - 1];

  if (!destinationItem) {
    throw new Error('Missing destination item');
  }

  let response: Response | undefined;

  if (itemIsProject(sourceItem)) {
    response = await performProjectResolutionAction(
      resolution,
      sourceItem,
      destinationItem as PWProject,
      httpService,
      parent,
      appView,
      readOnly
    );
  } else {
    response = await performFileResolutionAction(
      resolution,
      sourceItem as PWFileType,
      destinationItem as PWFileType,
      httpService,
      trackFeature
    );
    if (response && response.ok) {
      await deleteItem(sourceItem, sourceParent, httpService, trackFeature);
    }
  }

  if (!response) {
    return;
  }

  const item = await getItemFromResponse(response, destinationItem.ParentGuid);
  if (!item) {
    return;
  }

  return { response, item };
}

async function performProjectResolutionAction(
  resolution: ConflictResolution,
  sourceItem: PWProject,
  destinationItem: PWProject,
  httpService: HttpService,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): Promise<Response | undefined> {
  if (resolution.action != 'rename' || !destinationItem.ParentGuid) {
    return;
  }

  await renameItem(sourceItem, httpService, resolution.customName);

  const newTarget = await getProject({
    instanceId: destinationItem.ParentGuid,
    httpService
  });
  return moveItem(
    newTarget,
    sourceItem,
    parent,
    appView,
    readOnly,
    httpService
  );
}

async function performFileResolutionAction(
  resolution: ConflictResolution,
  sourceItem: PWFileType,
  destinationItem: PWFileType,
  httpService: HttpService,
  trackFeature: TrackFeature
): Promise<Response | undefined> {
  if (!destinationItem) {
    return;
  }

  if (resolution.action == 'replace') {
    const file = await downloadFile({
      document: sourceItem,
      httpService,
      requestOptions: { uncached: true }
    });
    return replaceFile(file, destinationItem, httpService, trackFeature, false);
  } else if (resolution.action == 'version') {
    const file = (await downloadFile({
      document: sourceItem,
      httpService,
      requestOptions: { uncached: true }
    })) as CustomFile;

    file.customName = resolution.customName;
    return uploadDocumentWorkflow(
      file,
      parseRelatedParent(destinationItem) as PWProject,
      httpService,
      trackFeature,
      undefined,
      undefined,
      undefined,
      resolution.customVersion ?? ''
    );
  } else if (resolution.action == 'rename') {
    const destinationParent = parseRelatedParent(destinationItem);

    if (itemIsFlatSet(sourceItem)) {
      if (!destinationParent) {
        throw new Error('Missing parent instanceId');
      }
      return await copyFlatSet(
        sourceItem,
        resolution.customName,
        destinationParent.instanceId,
        httpService
      );
    } else {
      const file = await downloadFile({
        document: sourceItem,
        httpService,
        requestOptions: { uncached: true }
      });

      return await uploadDocumentWorkflow(
        newFile(file, resolution.customName),
        destinationParent as PWProject,
        httpService,
        trackFeature,
        undefined,
        undefined,
        undefined
      );
    }
  } else {
    return;
  }
}
