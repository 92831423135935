import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgRedo } from '@itwin/itwinui-icons-react';
import { useRestore } from '../../../../actions/restore';
import { useAppViewContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useRestoreItemAction(items: PWItem[]): MenuItemAction {
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const restoreItemsWorkflow = useRestore();

  const onRestoreClick = useCallback((): void => {
    void restoreItemsWorkflow(items);
  }, [items, restoreItemsWorkflow]);

  const restoreItem = useMemo(
    (): MenuItemAction => ({
      title: t('Restore.Restore'),
      icon: <SvgRedo />,
      onClick: onRestoreClick,
      disabled: !items.length,
      hidden: currentAppView.type != 'RecycleBin'
    }),
    [currentAppView.type, items.length, onRestoreClick]
  );

  return restoreItem;
}
