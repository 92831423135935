import React from 'react';
import { SvgLock, SvgLockUnlocked } from '@itwin/itwinui-icons-react';
import { InputWithDecorations } from '@itwin/itwinui-react';

type LockIconProps = {
  disabled?: boolean;
  locked: boolean;
  onClick: (e?: React.MouseEvent) => void;
  tooltipText: string;
};

export function LockIcon({
  disabled = false,
  locked,
  onClick,
  tooltipText
}: LockIconProps): JSX.Element {
  function handleClick(): void {
    if (disabled) {
      return;
    }

    onClick();
  }

  function icon(): JSX.Element {
    if (disabled) {
      return <SvgLock data-testid="disabledLockedIcon" />;
    }

    if (locked) {
      return <SvgLock data-testid="enabledLockedIcon" />;
    }

    return <SvgLockUnlocked data-testid="unlockedIcon" />;
  }

  return (
    <InputWithDecorations.Button
      label={tooltipText}
      onClick={handleClick}
      disabled={disabled}
    >
      {icon()}
    </InputWithDecorations.Button>
  );
}
