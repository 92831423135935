import './inProgress.css';

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgClock,
  SvgClose,
  SvgCloseCircular
} from '@itwin/itwinui-icons-react';
import {
  IconButton,
  ProgressLinear,
  Surface,
  Tabs,
  Text
} from '@itwin/itwinui-react';
import type {
  InProgressActivity,
  InProgressTracker
} from '../../hooks/useProgressManager';
import { formatFileSize } from '../../services/stringFormatter';
import { t } from '../../services/translation';
import { DisplayInProgressActivity } from './inProgressActivity';

type tab = {
  type: 'uploads' | 'downloads' | 'workflows';
  title: string;
};

type InProgressProps = {
  activities: InProgressActivity[];
  onClearCallback: () => void;
  readOnly: boolean;
  inProgressTracker: InProgressTracker;
  tab?: number;
};

export function InProgress({
  activities,
  onClearCallback,
  readOnly,
  inProgressTracker,
  tab = 0
}: InProgressProps): JSX.Element {
  const tabs: tab[] = [
    {
      type: 'uploads',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      title: t('InProgressTab.Uploads')
    },
    {
      type: 'downloads',
      title: t('InProgressTab.Downloads')
    },
    {
      type: 'workflows',
      title: t('InProgressTab.Workflows')
    }
  ];
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(tab);

  const tabbedActivities = [
    activities.filter((aip) => aip.activityType == 'upload'),
    activities.filter((aip) => aip.activityType == 'download'),
    activities.filter((aip) => aip.activityType == 'workflow')
  ];

  function panelHeight(): string {
    const numberActivities = tabbedActivities[currentTab].length;
    let height = numberActivities > 0 ? numberActivities * 55 : 98;
    if (!readOnly) {
      height += 43;
    }
    return `${height}px`;
  }

  function onTabSelected(e: number): void {
    if (e == currentTab) {
      return;
    }
    setCurrentTab(e);
  }

  function clearTabbedActivity(): void {
    tabbedActivities[currentTab].forEach((activity) => {
      if (
        !activity.abortDisabled &&
        activity.status != 'success' &&
        activity.status != 'error'
      ) {
        activity.abortController.abort();
        activity.status = 'abort';
      }
    });

    if (currentTab == 0) {
      inProgressTracker.cancelAllUploads = true;
    }

    if (currentTab == 1) {
      inProgressTracker.cancelAllDownloads = true;
    }
  }

  useEffect(() => {
    if (tab !== currentTab) {
      setCurrentTab(tab);
    }
  }, [tab]);

  function InProgressSummary({
    activities
  }: {
    activities: InProgressActivity[];
  }): JSX.Element | null {
    const ongoingActivities = activities.filter((a) => a.status != 'error');
    const completedActivities = activities.filter((a) => a.status == 'success');
    const amountTransferred = ongoingActivities.reduce(
      (sum, { amountTransferred }) => sum + amountTransferred,
      0
    );

    const showCancelAll = tabbedActivities[currentTab].some(
      (act) =>
        ['inprogress', 'processing'].includes(act.status) && !act.abortDisabled
    );

    const display = useMemo(() => {
      if (
        tabbedActivities[currentTab].every((activity) =>
          ['error', 'abort'].includes(activity.status)
        )
      ) {
        return { message: undefined, percent: undefined };
      } else if (activities[0].activityType == 'workflow') {
        const message = `${t('InProgress.WorkflowsCompleted', {
          successCount: completedActivities.length,
          totalCount: activities.length
        })}`;
        const percent = (completedActivities.length / activities.length) * 100;

        return { message, percent };
      } else {
        const totalSize = ongoingActivities.reduce(
          (sum, { size }) => sum + size,
          0
        );
        const fileSize = formatFileSize(totalSize);

        const percent =
          totalSize > 0 ? (amountTransferred / totalSize) * 100 : 0;
        const message = fileSize
          ? `${t('InProgress.PercentageComplete', {
              completed: percent.toFixed(0),
              total: fileSize
            })}`
          : '';

        return { message, percent };
      }
    }, [
      activities,
      amountTransferred,
      completedActivities.length,
      ongoingActivities
    ]);

    if (!activities.length) {
      return null;
    }

    return (
      <>
        <div className="in-progress-summary-information">
          <div className="in-progress-summary-information-progress">
            <Text variant="small" className="in-progress-summary-message">
              {display.message}
            </Text>
          </div>
          {display.message && display.percent != 100 && (
            <IconButton
              label={`${t('InProgress.CancelAll')}`}
              styleType="borderless"
              className="in-progress-cancel-button"
              onClick={clearTabbedActivity}
              hidden={!showCancelAll}
            >
              <SvgCloseCircular />
            </IconButton>
          )}
        </div>
        {display.message && (
          <div className="in-progress-summary-progress-bar">
            <ProgressLinear value={display.percent} />
          </div>
        )}
      </>
    );
  }

  return (
    <Surface className="in-progress-container">
      <div className="in-progress-title-container">
        <div title={t('InProgress.InProgress')} data-testid="in-progress-title">
          <Text variant="subheading">{t('InProgress.InProgress')}</Text>
        </div>
        <div className="button-row">
          {activities.length !== 0 && (
            <button
              className="title-button"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              <div
                className="title-button-content"
                title={
                  collapsed
                    ? t('InProgress.ShowProgress')
                    : t('InProgress.HideProgress')
                }
              >
                <div className="title-button-svg">
                  {collapsed ? <SvgChevronUp /> : <SvgChevronDown />}
                </div>
              </div>
            </button>
          )}
          <button className="title-button" onClick={() => onClearCallback()}>
            <div
              className="title-button-content"
              title={t('Generic.Close')}
              data-testid="InProgressClose"
            >
              <div className="title-button-svg">
                <SvgClose />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        className={
          collapsed ? 'in-progress-hidden' : 'in-progress-activities-container'
        }
        style={{
          height: panelHeight()
        }}
      >
        {readOnly ? null : (
          <Tabs
            orientation="horizontal"
            labels={tabs.map((tab) => tab.title)}
            activeIndex={currentTab}
            onTabSelected={onTabSelected}
          />
        )}
        <div className="in-progress-list">
          {tabbedActivities[currentTab].length > 0 ? (
            tabbedActivities[currentTab].map((ipa, index) => {
              return <DisplayInProgressActivity {...ipa} key={index} />;
            })
          ) : (
            <div className="in-progress-empty-list">
              <span className="in-progress-empty-list-items">
                <SvgClock />
                <Text>{t('InProgress.NoActions')}</Text>
              </span>
            </div>
          )}
        </div>
      </div>
      {tabbedActivities[currentTab].length > 0 && (
        <div className="in-progress-summary">
          <InProgressSummary activities={tabbedActivities[currentTab]} />
        </div>
      )}
    </Surface>
  );
}
