import { t } from '../../services/translation';

export type ErrorPageType =
  | '401'
  | '403'
  | '404'
  | '500'
  | '502'
  | '503'
  | 'generic';

export async function errorMessage(
  response: Response | Record<string, string>,
  fallbackMessage: string
): Promise<string> {
  try {
    const responseBody = (await (response as Response).json()) as Record<
      string,
      string
    >;
    if (responseBody?.errorMessage) {
      return responseBody.errorMessage;
    }
  } catch {
    // Continue below if cannot read body
  }

  if ((response as Record<string, string>)?.message == 'Failed to fetch') {
    return t('ErrorPage.UnableToCommunicate');
  }

  return response.statusText || fallbackMessage;
}

export function errorType(response: Response): ErrorPageType {
  return errorTypeWithStatus(response.status);
}

export function errorTypeWithStatus(status: number = 0): ErrorPageType {
  const errorType = [401, 403, 404, 500, 502, 503].includes(status)
    ? status.toString()
    : 'generic';

  return errorType as ErrorPageType;
}
