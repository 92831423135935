import type { DataError } from '../../../actions/formRendering';
import { getErrorMessage } from '../../../actions/formRendering';
import { logError } from '../../../hooks/useErrorLogging';
import type { ToastHandle } from '../../../services/pwToast';
import { replaceToast } from '../../../services/pwToast';

export async function handleDCWError(
  error: DataError | Response | Error,
  toastHandle?: ToastHandle
): Promise<void> {
  const content = await getErrorMessage(error as DataError);
  logError('DCW error', { error });
  replaceToast(toastHandle, { content, category: 'negative' });
}
