import type {
  HttpService,
  PWDataItem,
  PWItem,
  PWParentType,
  RequestOptions
} from '@bentley/pw-api';
import { deleteItem as deleteItemApi, itemIsProject } from '@bentley/pw-api';
import type { FeatureName } from '../../hooks/useTrackFeature';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { itemPartOfSet } from './itemInSet';
import { allowDelete, allowDeleteAll } from './requirements';

/**
 * Deletes items from the PW datasource
 * @param {PWItem[]} items Items to be deleted
 * @param {PWParentType} parent Parent of items to be deleted
 * @param {HttpService} httpService Configured http service
 * @param {(item: PWItem, response: Response) => any} [onDeleteSuccess] Callback when item successfully deleted
 * @param {(item: PWItem, response: Response) => any} [onDeleteFailure] Callback when item unable to be deleted
 * @param {() => void} [onComplete] Callback when delete workflow completed
 * @returns {Promise<Response[]>}
 * @throws {'Delete not allowed'} If items not able to be deleted
 */
export async function deleteItems(
  items: PWItem[],
  parent: PWParentType,
  httpService: HttpService,
  trackFeature: (featureName: FeatureName) => void,
  onDeleteSuccess?: (item: PWItem, response: Response) => void,
  onDeleteFailure?: (item: PWItem, response: Response) => void | Promise<void>,
  onComplete?: () => void
): Promise<Response[]> {
  if (!allowDeleteAll(items, parent)) {
    throw 'Delete not allowed';
  }

  const responses = await Promise.all(
    items.map((item) =>
      deleteItem(
        item,
        parent,
        httpService,
        trackFeature,
        onDeleteSuccess,
        onDeleteFailure
      )
    )
  );

  onComplete?.();
  return responses;
}

/**
 * Deletes item from the PW datasource
 * @param {PWItem} item Item to be deleted
 * @param {PWParentType} parent Parent of item to be deleted
 * @param {HttpService} httpService Configured http service
 * @param {(item: PWItem, response: Response) => any} [onDeleteSuccess] Callback when item successfully deleted
 * @param {(item: PWItem, response: Response) => any} [onDeleteFailure] Callback when item unable to be deleted
 * @returns {Promise<Response>}
 * @throws {'Delete not allowed'} If items not able to be deleted
 */
export async function deleteItem(
  item: PWItem,
  parent: PWParentType,
  httpService: HttpService,
  trackFeature: (featureName: FeatureName) => void,
  onDeleteSuccess?: (item: PWItem, response: Response) => void,
  onDeleteFailure?: (item: PWItem, response: Response) => void | Promise<void>
): Promise<Response> {
  if (!allowDelete(item, parent)) {
    throw 'Delete not allowed';
  }

  trackFeature(itemIsProject(item) ? 'DELETE_FOLDER' : 'DELETE_DOCUMENT');

  const response = await usingConcurrencyLimiter(async () => {
    try {
      const response = await deleteItemApi({ item, httpService });
      return response;
    } catch (e) {
      if (e instanceof Response) {
        await onDeleteFailure?.(item, e);
        return e;
      }

      throw e;
    }
  });

  if (response.ok) {
    onDeleteSuccess?.(item, response);
  } else {
    await onDeleteFailure?.(item, response);
  }
  return response;
}

export async function itemsIncludedInSets(
  items: PWDataItem[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWDataItem[]> {
  const setItems: PWDataItem[] = [];
  const promises = items.map(async (item: PWDataItem) => {
    const isInSet = await itemPartOfSet(item, httpService, httpOptions);
    if (isInSet) {
      setItems.push(item);
    }
  });
  await Promise.all(promises);

  return setItems;
}
