import React from 'react';
import type { PWDataItem, PWFileType, PWItem } from '@bentley/pw-api';
import { itemFileExtension, parseFileExtension } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import { t } from '../../services/translation';
import type { RenameForm } from './renameModal';
import RenameModal from './renameModal';

interface IFileNameExtensionChangeModalProps {
  onYes: () => void;
  onSuccess: (updatedItem: PWItem) => void;
  item: PWDataItem | PWFileType;
  formToSubmit: RenameForm;
}

function FileNameExtensionChangeModal({
  onYes,
  onSuccess,
  item,
  formToSubmit
}: IFileNameExtensionChangeModalProps): JSX.Element | null {
  const { primaryModal } = useNavigationContext();
  if (item == null) {
    return null;
  }
  const initialFileExtension: string = itemFileExtension(item);
  const changedFileExtension: string = parseFileExtension(
    formToSubmit.fileName ?? ''
  );
  const itemForItemSummary: PWDataItem = { ...item };
  itemForItemSummary.FileName = formToSubmit.fileName ?? '';
  let messageToDisplay = '';
  if (changedFileExtension === '' || !formToSubmit.fileName?.includes('.')) {
    messageToDisplay = t('Rename.NoFileExtension');
  } else {
    messageToDisplay = `${t('Rename.ConfirmRenameExtension', {
      original: initialFileExtension,
      changed: changedFileExtension
    })}`;
  }

  return (
    <PWModal
      title={t('Rename.ConfirmExtensionChange')}
      primaryButton={{ title: t('Generic.Yes'), onClick: onYes }}
      secondaryButton={{
        title: t('Generic.No'),
        onClick: () =>
          primaryModal.open(
            <RenameModal
              item={item}
              recoveredInputs={formToSubmit}
              onSuccess={onSuccess}
            />
          )
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'FileExtensionChangeModal' }}
    >
      <ItemSummary item={itemForItemSummary} useFileName={true} />
      <Text>{messageToDisplay}</Text>
    </PWModal>
  );
}

export default FileNameExtensionChangeModal;
