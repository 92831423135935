import './environmentInterfaceSelect.css';

import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { SelectOption } from '@itwin/itwinui-react';
import { Select } from '@itwin/itwinui-react';
import {
  getInterfaces,
  updateDefaultInterface
} from '../../../actions/formRendering';
import { useFeatureTracking } from '../../../context';
import { getAttributesHttpService } from '../data';
import {
  updatingEnvironmentInterfacesState,
  useFormReloadWorkflow
} from '../form';
import { originalEnvironmentInstanceState } from '../state';
import { environmentInterfacesState } from './state';

export function EnvironmentInterfaceSelect(): JSX.Element | null {
  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const [updatingEnvironmentInterfaces, setUpdatingEnvironmentInterfaces] =
    useRecoilState(updatingEnvironmentInterfacesState);

  const [environmentInterfaces, setEnvironmentInterfaces] = useRecoilState(
    environmentInterfacesState
  );

  const { reloadFormWorkflow } = useFormReloadWorkflow();
  const { trackFeature } = useFeatureTracking();

  const defaultInterfaceValue = useMemo(() => {
    const defaultInterface = environmentInterfaces.find(
      (environment) => environment.IsDefault
    );

    const defaultName = defaultInterface?.Name ?? '';
    return defaultName;
  }, [environmentInterfaces]);

  const updateEnvironmentInterfaces = useCallback(async (): Promise<void> => {
    const environmentClass = originalEnvironmentInstance?.className;
    const httpService = getAttributesHttpService();

    if (!environmentClass || !httpService) {
      return;
    }

    const environmentInterfaces = await getInterfaces(
      environmentClass,
      httpService
    );

    setEnvironmentInterfaces(environmentInterfaces);
  }, [originalEnvironmentInstance, setEnvironmentInterfaces]);

  const onInterfaceSelect = useCallback(
    async (selectedName: string): Promise<void> => {
      if (updatingEnvironmentInterfaces) {
        return;
      }

      const selectedInterface = environmentInterfaces.find(
        (i) => i.Name == selectedName
      );

      const oldInterface = environmentInterfaces.find(
        (environment) => environment.IsDefault
      );

      if (!selectedInterface || selectedInterface.IsDefault || !oldInterface) {
        return;
      }

      setUpdatingEnvironmentInterfaces(true);

      const httpService = getAttributesHttpService();

      await updateDefaultInterface(selectedInterface.instanceId, httpService);

      await updateEnvironmentInterfaces();

      await reloadFormWorkflow();
      setUpdatingEnvironmentInterfaces(false);
      trackFeature('CHANGE_ENVIRONMENT_ATTRIBUTE_INTERFACE');
    },
    [
      environmentInterfaces,
      reloadFormWorkflow,
      setUpdatingEnvironmentInterfaces,
      updateEnvironmentInterfaces,
      trackFeature,
      updatingEnvironmentInterfaces
    ]
  );

  function interfaceOptions(): SelectOption<string>[] {
    return environmentInterfaces
      .map((i) => {
        return {
          label: i.Name,
          value: i.Name
        };
      })
      .sort((a, b) =>
        a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase() ? -1 : 1
      );
  }

  if (!environmentInterfaces?.length) {
    return null;
  }

  return (
    <div className={'af-i-s-container'}>
      <Select
        className={`af-interface-select ${
          updatingEnvironmentInterfaces ? 'i-s-disabled' : ''
        }`}
        options={interfaceOptions()}
        onChange={(e: string) => void onInterfaceSelect(e)}
        disabled={updatingEnvironmentInterfaces}
        data-testid="environment-select"
        value={defaultInterfaceValue}
      />
    </div>
  );
}
