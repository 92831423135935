import { atom } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';

export const originalEnvironmentInstanceState = atom<WSGInstance | undefined>({
  key: 'dc_originalEnvironmentInstanceState',
  default: undefined
});

export const currentEnvironmentInstanceState = atom<WSGInstance | undefined>({
  key: 'dc_currentEnvironmentInstanceState',
  default: undefined
});

export const editingDocCodeFormState = atom<boolean>({
  key: 'dc_editingDocCodeFormState',
  default: false
});
