import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsProject, parseRelatedParent } from '@bentley/pw-api';
import type { ProgressManager } from '../../hooks/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { openToast } from '../../services/pwToast';
import type { ConflictResolution } from '../conflictResolution';
import { replaceFileWorkflow } from '../replace';
import { notifyConflictsResolved } from './notifications';
import type { CustomFile, UploadNode } from './tree';
import { uploadDocumentWorkflow } from './upload';
import { getItemFromResponse } from './utils';

export async function resolveDCWConflicts(
  uploadNode: UploadNode,
  resolutions: ConflictResolution[],
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  onComplete: (uploadNode: UploadNode, uploadedItems: PWItem[]) => void
): Promise<void> {
  const resolvedItems = await Promise.all(
    resolutions.map((resolution) =>
      handleResolution(
        uploadNode,
        resolution,
        httpService,
        trackFeature,
        progressManager
      )
    )
  );

  const uploadedItems = resolvedItems.filter((item) => item != undefined);

  if (uploadedItems.length) {
    notifyConflictsResolved(uploadedItems.length);
  }

  onComplete(uploadNode, uploadedItems);
}

async function handleResolution(
  uploadNode: UploadNode,
  resolution: ConflictResolution,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager
): Promise<PWItem | void> {
  if (resolution.action == 'keep') {
    return keepResolution(uploadNode, resolution);
  }

  if (resolution.action == 'replace') {
    return replaceResolution(
      uploadNode,
      resolution,
      httpService,
      trackFeature,
      progressManager
    );
  }

  if (resolution.action == 'version') {
    return versionResolution(
      uploadNode,
      resolution,
      httpService,
      trackFeature,
      progressManager
    );
  }

  if (resolution.action == 'rename') {
    return renameResolution(uploadNode, resolution);
  }
}

function keepResolution(
  uploadNode: UploadNode,
  resolution: ConflictResolution
): void {
  if (itemIsProject(resolution.sourceItem)) {
    removeDirectoryFromUploadNode(uploadNode, resolution.originalName);
  } else {
    removeFileFromUploadNode(uploadNode, [
      resolution.originalName,
      resolution.originalFileName
    ]);
  }
}

async function replaceResolution(
  uploadNode: UploadNode,
  resolution: ConflictResolution,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager
): Promise<PWItem | undefined> {
  const uploadFile = popFileFromUploadNode(uploadNode, [
    resolution.originalName,
    resolution.originalFileName
  ]);

  const destinationItem = getResolutionDestinationItem(
    resolution
  ) as PWDataItem;

  const response = await replaceFileWorkflow(
    uploadFile,
    destinationItem,
    httpService,
    trackFeature,
    progressManager
  );

  if (!response) {
    return;
  }

  return getItemFromResponse(response, destinationItem.ParentGuid);
}

async function versionResolution(
  uploadNode: UploadNode,
  resolution: ConflictResolution,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager
): Promise<PWItem | undefined> {
  const uploadFile = popFileFromUploadNode(uploadNode, [
    resolution.originalName,
    resolution.originalFileName
  ]);
  uploadFile.customName = resolution.originalName;
  const destinationItem = getResolutionDestinationItem(resolution);
  uploadFile.documentDescription = destinationItem.Description;

  const destinationParent = parseRelatedParent(destinationItem);

  if (!destinationParent) {
    return;
  }

  const jobId = progressManager.addNewJobToTracker('upload');

  const response = await uploadDocumentWorkflow(
    uploadFile,
    destinationParent,
    httpService,
    trackFeature,
    jobId,
    progressManager,
    undefined,
    resolution.customVersion ?? ''
  );

  if (!response.ok) {
    const responseBody = (await response.json()) as Record<string, string>;
    const errorText = responseBody?.errorMessage || response.statusText;

    openToast({ content: errorText, category: 'negative' });
    return;
  }

  const item = await getItemFromResponse(response);
  return item;
}

function renameResolution(
  uploadNode: UploadNode,
  resolution: ConflictResolution
) {
  if (!resolution.sourceItem) {
    throw new Error('Missing source item for rename resolution');
  }

  if (itemIsProject(resolution.sourceItem)) {
    renameUploadNodeDirectory(
      uploadNode,
      resolution.sourceItem.Name,
      resolution.customName
    );
  } else {
    renameUploadNodeFile(
      uploadNode,
      resolution.originalName,
      resolution.customName
    );
    renameUploadNodeFile(
      uploadNode,
      resolution.originalFileName,
      resolution.customName
    );
  }
}

function getResolutionDestinationItem(resolution: ConflictResolution): PWItem {
  const destinationItem =
    resolution.destinationItems[resolution.destinationItems.length - 1];

  if (!destinationItem) {
    throw new Error('Missing destination item for conflict resolution');
  }

  return destinationItem;
}

function removeDirectoryFromUploadNode(
  uploadNode: UploadNode,
  folderName: string | undefined
): void {
  uploadNode.directories = uploadNode.directories.filter(
    ({ current }) => current?.name !== folderName
  );
}

function removeFileFromUploadNode(
  uploadNode: UploadNode,
  names: (string | undefined)[]
): void {
  uploadNode.files = uploadNode.files.filter(
    ({ name }) => !names.includes(name)
  );
}

function popFileFromUploadNode(
  uploadNode: UploadNode,
  names: (string | undefined)[]
): CustomFile {
  const uploadFile = uploadNode.files.find(({ name }) => names.includes(name));

  removeFileFromUploadNode(uploadNode, names);

  if (!uploadFile) {
    throw new Error('Unable to resolve file');
  }

  return uploadFile;
}

function renameUploadNodeDirectory(
  uploadNode: UploadNode,
  name: string,
  newName: string
): void {
  const folder = uploadNode.directories.find(
    ({ current }) => current?.name === name
  );

  if (!folder?.current) {
    throw new Error('Unable to resolve folder');
  }

  folder.current = { ...folder.current, name: newName };
}

function renameUploadNodeFile(
  uploadNode: UploadNode,
  name: string | undefined,
  newName: string
): void {
  const fileIndex = uploadNode.files.findIndex((file) => file.name == name);

  const file = uploadNode.files[fileIndex];
  if (!file) {
    return;
  }

  const renamedFile = new File([file], newName);

  uploadNode.files[fileIndex] = renamedFile;
}
