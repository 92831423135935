import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsPlaceholder } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { downloadZippedItems } from '../download';
import type { PWDriveManager } from '../drive';
import { getItemDriveLock } from '../drive';
import type { DriveResponseData, PwDriveData } from '../drive/pwDrive.utils';
import {
  checkOutFileDrive,
  isDriveFeatureAvailable
} from '../drive/pwDrive.utils';
import { checkOutFile } from './data';
import {
  get400ErrorString,
  get403ErrorString,
  notifyAccessIssue,
  notifyCheckOut,
  notifyCheckOutErrorWhenDriveIsOff,
  notifyCheckOutFailure,
  notifyCheckOutSuccess,
  notifyDownloadDrive,
  notifyErrorDuringCheckOut,
  notifyInternalServerErrorDrive,
  notifyStandardCheckoutFailure
} from './notifications';
import { allowCheckForDriveIntegratedMode } from './requirements';

export async function checkOut(
  selectedItems: PWItem[],
  httpService: HttpService,
  refresh: () => void,
  refreshOutToMe: () => void,
  contextId: string,
  driveData: PWDriveManager,
  progressManager?: ProgressManager,
  openModal?: OpenModal,
  closeModal?: CloseModal,
  trackFeature?: TrackFeature,
  userGuid?: string,
  consumerApp?: ConsumerApp,
  useAttributeExchange?: boolean
): Promise<Response[]> {
  const dataItems = selectedItems as PWDataItem[];

  await httpService.cache?.clearEntriesMatching(
    dataItems.map((item) => item.ParentGuid)
  );

  const toastHandle = notifyCheckOut(dataItems.length);
  let toggleLockInfo: Response[] = [];
  const otherNodeCount = 0;

  const driveIntegratedMode = allowCheckForDriveIntegratedMode(
    driveData,
    selectedItems as PWDataItem[],
    true
  );

  if (driveIntegratedMode) {
    // Currently checkout for drive returns empty object {}. Unlike check in and free file .

    toggleLockInfo = await Promise.all(
      dataItems
        ?.filter(
          (item) =>
            item.IsCheckedOut == false ||
            getItemDriveLock(item)?.lockedViaAutoCheckout
        )
        .map((value: PWDataItem) => {
          // Checks if in drive integrated mode we have placeholder file , if yes , executing checkout using non integrated mode
          return !itemIsPlaceholder(value)
            ? checkOutFileDrive(
                {
                  InstanceId: value.instanceId,
                  UserId: userGuid,
                  ProjectId: contextId
                } as PwDriveData,
                driveData.httpDriveService,
                trackFeature
              ).catch((res) => {
                return Promise.reject(
                  notifyCheckOutErrorWhenDriveIsOff(toastHandle)
                );
              })
            : checkOutFile(value, httpService, useAttributeExchange);
        })
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toggleLockInfo = await Promise.all(
      dataItems.map((value: PWDataItem) => {
        trackFeature?.('CHECKOUT_FILE');
        return checkOutFile(value, httpService, useAttributeExchange);
      })
    );
  }

  if (driveIntegratedMode) {
    if (
      toggleLockInfo.some((item) => item.status == 500 || item.status == 401)
    ) {
      return Promise.reject(notifyInternalServerErrorDrive(toastHandle));
    } else if (toggleLockInfo.some((item) => item.status == 503)) {
      return Promise.reject(notifyDownloadDrive(toastHandle));
    }
  } else {
    if (
      toggleLockInfo.some((item) => item.status == 400 || item.status == 500)
    ) {
      refresh();
      return Promise.reject(
        notifyStandardCheckoutFailure(get400ErrorString(), toastHandle)
      );
    } else if (toggleLockInfo.some((item) => item.status == 403)) {
      refresh();
      return Promise.reject(
        notifyStandardCheckoutFailure(get403ErrorString(false), toastHandle)
      );
    }
  }

  if (
    driveIntegratedMode &&
    driveData.driveVersion &&
    isDriveFeatureAvailable(driveData.driveVersion, 'CheckOut')
  ) {
    const response = await Promise.all(
      toggleLockInfo.map(async (item) => {
        return (await item.json()) as DriveResponseData;
      })
    );

    if (
      !toggleLockInfo ||
      response.some((item) => item.data && !item.data.success)
    ) {
      const errorMsg = response.filter(
        (item) => item.data && !item.data.success
      )[0].data.message;
      if (
        errorMsg &&
        errorMsg.indexOf(
          "'Updateable' policy, the requested operation is not allowed due to permissions for the specified 'Document' instance."
        ) != -1
      ) {
        notifyAccessIssue(toastHandle);
      } else {
        notifyErrorDuringCheckOut(
          toastHandle,
          errorMsg ?? t('Sync.ErrorDuringCheckout')
        );
      }
      refresh();
      return Promise.reject();
    }
  }

  if (toggleLockInfo.length > 0) {
    driveIntegratedMode
      ? notifyCheckOutSuccess(dataItems.length - otherNodeCount, toastHandle)
      : notifyCheckOutSuccess(selectedItems.length, toastHandle);
  } else {
    driveIntegratedMode &&
    dataItems?.some((item) => getItemDriveLock(item)?.locked)
      ? notifyCheckOutFailure(dataItems.length - otherNodeCount, toastHandle)
      : notifyCheckOutFailure(selectedItems.length, toastHandle);
  }

  if (ecPluginFeatures.outToMePage()) {
    refreshOutToMe();
  }
  refresh();
  if (
    progressManager &&
    openModal &&
    closeModal &&
    trackFeature &&
    !driveIntegratedMode
  ) {
    void downloadZippedItems(
      selectedItems,
      httpService,
      openModal,
      closeModal,
      trackFeature,
      progressManager,
      false,
      false,
      consumerApp
    );
  }
  return toggleLockInfo;
}
