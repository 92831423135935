import { useCallback } from 'react';
import type { PWItem, PWParentType, PWProject } from '@bentley/pw-api';
import {
  useAppViewContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { moveItemsWorkflow } from './move';
import { useOnMoveComplete } from './useOnMoveComplete';

type MoveAction = (
  items: PWItem[],
  destinationFolder: PWProject,
  sourceFolder: PWParentType
) => Promise<Response[]>;

export function useMoveAction(): MoveAction {
  const { trackFeature } = useFeatureTracking();
  const { readOnly } = usePluginContext();
  const httpService = useHttpService();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const onMoveComplete = useOnMoveComplete();

  const moveItems = useCallback(
    async (
      items: PWItem[],
      destinationFolder: PWProject,
      sourceFolder: PWParentType
    ): Promise<Response[]> => {
      const responses = await moveItemsWorkflow(
        destinationFolder,
        items,
        sourceFolder,
        currentAppView.type,
        readOnly,
        httpService,
        primaryModal.open,
        primaryModal.close,
        trackFeature,
        onMoveComplete
      );

      return responses;
    },
    [
      currentAppView.type,
      httpService,
      onMoveComplete,
      primaryModal.close,
      primaryModal.open,
      readOnly,
      trackFeature
    ]
  );

  return moveItems;
}
