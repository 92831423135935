import { useCallback, useMemo } from 'react';
import { useEnvironmentContext } from '../../../context';
import type { Attribute } from '../../useEnvironment';
import type { ColumnInfo } from '../defaultView';
import type { View } from '../useViews';

type EnvironmentPropertiesFunctions = {
  addEnvironmentPropertiesToView: (view: View) => View;
};

export function useEnvironmentProperties(): EnvironmentPropertiesFunctions {
  const {
    environmentManager: { getEnvironmentProperty }
  } = useEnvironmentContext();

  const columnEnvironmentProperty = useCallback(
    (columnInfo: ColumnInfo): Attribute | undefined => {
      if (!columnInfo) {
        return undefined;
      }

      if (
        columnInfo.propertyType == 'General' ||
        columnInfo.propertyType == 'Folder' ||
        !columnInfo.envLabel
      ) {
        return undefined;
      }

      const { envLabel, name } = columnInfo;

      const environmentProperty = getEnvironmentProperty(envLabel, name);
      return environmentProperty;
    },
    [getEnvironmentProperty]
  );

  const addEnvironmentPropertyToColumn = useCallback(
    (columnInfo: ColumnInfo): void => {
      const attribute = columnEnvironmentProperty(columnInfo);
      // Todo: could search for project column labels in project types

      if (!attribute) {
        return;
      }

      columnInfo.displayLabel = attribute.label;
      columnInfo.dataType = attribute.typeName ?? columnInfo.dataType;
    },
    [columnEnvironmentProperty]
  );

  const addEnvironmentPropertiesToView = useCallback(
    (view: View): View => {
      view.columns.forEach(addEnvironmentPropertyToColumn);

      return view;
    },
    [addEnvironmentPropertyToColumn]
  );

  const environmentPropertiesFunctions = useMemo(
    (): EnvironmentPropertiesFunctions => ({
      addEnvironmentPropertiesToView
    }),
    [addEnvironmentPropertiesToView]
  );

  return environmentPropertiesFunctions;
}
