import './errorFallback.css';

import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { SvgError } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';

export function RouteErrorBoundary(): JSX.Element {
  const error = useRouteError() as Error;

  useEffect(() => {
    function reloadOnce(): void {
      const reloaded = sessionStorage.getItem('error-reload');
      if (reloaded != 'true') {
        sessionStorage.setItem('error-reload', 'true');
        window.location.reload();
      }
    }

    setTimeout(reloadOnce, 5000);
  }, []);

  return (
    <NonIdealState
      className="error-fallback"
      svg={<SvgError />}
      heading={error.name}
      description={<pre>{error.message}</pre>}
    />
  );
}
