import type {
  HttpService,
  PWDataItem,
  PWItem,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  itemIsDataItem,
  itemIsProject,
  parseResponseInstances
} from '@bentley/pw-api';
import type { Repository } from '../../services/support';
import type { IModel } from './iModel';
import type { IModelBridge, IModelMapping } from './iModelBridge';

export async function getSpatialRootDocument(
  pwHttpService: HttpService,
  documentId: string
): Promise<PWDataItem | undefined> {
  const documentQuery = `PW_WSG/Document/${documentId}`;
  try {
    const response = await pwHttpService.get(documentQuery, {
      uncached: true
    });
    if (!response.ok) {
      return undefined;
    }
    const documents = await parseResponseInstances<PWDataItem>(response);
    if (documents.length) {
      return documents[0];
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
}

export async function getDatasourceLocation(
  httpService: HttpService
): Promise<string> {
  const response = await httpService.get('');
  const repos = await parseResponseInstances<Repository>(response);
  if (repos && repos.length) {
    return repos[0].Location;
  }
  return '';
}

export function loadExistingMapData(
  startingMappings: IModelMapping[],
  iModel: IModel,
  iModelBridges: IModelBridge[]
): IModelMapping[] {
  const newMappings = [...startingMappings];
  newMappings.forEach((mapping) => {
    const existingMappings = associatedIModelBridges(
      mapping.document,
      iModelBridges
    );
    if (existingMappings) {
      const mappingForIModel = existingMappings.filter((existingMapping) => {
        return existingMapping.properties?.iModelId == iModel.instanceId;
      });
      if (mappingForIModel.length > 0) {
        const existingMapping = mappingForIModel[0];
        mapping.isMaster = existingMapping.properties?.IsMaster == 'true';
        mapping.isSheet = existingMapping.properties?.IsMaster != 'true';
        mapping.isSpatialRoot =
          existingMapping.properties?.IsSpatialRoot == 'true';
        mapping.isExistingBridge = true;
      }
    }
  });
  return newMappings;
}

export function loadExistingSavedSearchMapData(
  existingBridges: IModelBridge[],
  savedSearchId: string,
  workAreaId: string
): IModelMapping[] {
  const savedSearchBridges = existingBridges.filter((bridge) => {
    return (
      bridge.properties?.DocumentGuid == savedSearchId ||
      bridge.properties?.DocumentGuid == `${savedSearchId};${workAreaId}`
    );
  });
  return savedSearchBridges.map((bridge) => {
    return {
      isSheet: bridge.properties?.IsMaster != 'true',
      isMaster: bridge.properties?.IsMaster == 'true',
      isExistingBridge: true
    } as IModelMapping;
  });
}

export async function getIModelBridges(
  filterString: string,
  httpService?: HttpService,
  httpOptions?: RequestOptions
): Promise<IModelBridge[]> {
  if (!httpService) {
    return [];
  }

  const queryUrl = `IModelConfigSchema/ConfigInfo?$filter=${filterString}`;
  const newHttpOptions = httpOptions ?? { uncached: true };
  newHttpOptions.uncached = true;

  try {
    const response = await httpService.get(queryUrl, newHttpOptions);
    if (!response.ok) {
      return [];
    }

    const jsonData = (await response.json()) as WSGInstancesResponse;
    const data = jsonData.instances as IModelBridge[];
    return data;
  } catch (err) {
    return [];
  }
}

export function bridgeExists(iModelMappings: IModelMapping[]): boolean {
  return iModelMappings.some((mapping) => mapping.isExistingBridge);
}

export function getIModelLabels(
  item: PWItem,
  iModelBrides: IModelBridge[],
  iModels?: IModel[]
): string | undefined {
  if (!itemIsDataItem(item)) {
    return undefined;
  }

  const associatedBridges = associatedIModelBridges(item, iModelBrides);

  if (!iModels?.length || !associatedBridges.length) {
    return undefined;
  }

  return associatedBridges
    .filter((bridge) => bridge.properties?.IsSpatialRoot == 'false')
    .map((bridge) => matchingIModel(iModels, bridge)?.properties?.Name)
    .filter((iModelName) => iModelName != undefined)
    .join(', ');
}

export function getSpatialRootLabels(
  item: PWItem,
  iModelBridges: IModelBridge[],
  iModels?: IModel[]
): string | undefined {
  if (itemIsProject(item)) {
    return undefined;
  }

  const dataItem = item as PWDataItem;
  const associatedBridges = associatedIModelBridges(dataItem, iModelBridges);

  if (!iModels?.length || !associatedBridges.length) {
    return undefined;
  }

  return associatedBridges
    .filter((bridge) => bridge.properties?.IsSpatialRoot == 'true')
    .map((bridge) => matchingIModel(iModels, bridge)?.properties?.Name)
    .filter((iModelName) => iModelName != undefined)
    .join(', ');
}

export function matchingIModel(
  iModels: IModel[],
  iModelBridge: IModelBridge
): IModel | undefined {
  return iModels.find(
    (iModel) => iModel.instanceId == iModelBridge.properties?.iModelId
  );
}

export function associatedIModelBridges(
  item: PWItem,
  iModelBridges: IModelBridge[]
): IModelBridge[] {
  if (!itemIsDataItem(item)) {
    return [];
  }
  const iModelBridgesForItem = iModelBridges.filter((bridge) =>
    String(bridge.properties?.DocumentGuid ?? '').includes(item.instanceId)
  );

  return iModelBridgesForItem;
}

export function updateIModelMappings(
  iModelMappings: IModelMapping[],
  setIModelMappings: (iModelMappings: IModelMapping[]) => void,
  document: PWDataItem,
  isSheet: boolean,
  isMaster: boolean,
  isSpatialRoot = false
): void {
  const updatedMappings = [...iModelMappings];
  const docIndex = iModelMappings
    .map((mapping) => {
      return mapping.document.instanceId;
    })
    .indexOf(document.instanceId);
  updatedMappings[docIndex].isSheet = isSheet;
  updatedMappings[docIndex].isMaster = isMaster;
  updatedMappings[docIndex].isSpatialRoot = isSpatialRoot;
  setIModelMappings(updatedMappings);
}
