import React from 'react';
import { Checkbox } from '@itwin/itwinui-react';
import type { HeaderProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../../services/translation';
import type { MappingDataRecord } from '../iModelMapTable';

type SheetHeaderProps = {
  allSheetChecked?: boolean;
  hasExistingBridges?: boolean;
  notEnoughMappings?: boolean;
  tableDisabled?: boolean;
  onSheetHeaderClick?: React.ChangeEventHandler<HTMLInputElement>;
} & HeaderProps<MappingDataRecord>;

export function SheetHeader({
  allSheetChecked,
  hasExistingBridges,
  notEnoughMappings,
  tableDisabled,
  onSheetHeaderClick
}: SheetHeaderProps): JSX.Element {
  return (
    <Checkbox
      disabled={tableDisabled || hasExistingBridges || notEnoughMappings}
      label={t('IModel.Sheet')}
      name="allSheetCheck"
      checked={allSheetChecked}
      onChange={onSheetHeaderClick}
      data-testid={'table-header-input-sheet'}
    />
  );
}
