export function productionEnvironment():
  | 'localhost'
  | 'dev'
  | 'qa'
  | 'release'
  | 'prod' {
  try {
    if (
      !document ||
      !window ||
      !window?.location ||
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('local-pw365.bentley.com')
    ) {
      return 'localhost';
    }

    if (location.origin.includes('dev-')) {
      return 'dev';
    }

    if (location.origin.includes('release')) {
      return 'release';
    }

    if (location.origin.includes('qa-')) {
      return 'qa';
    }

    return 'prod';
  } catch {
    return 'prod';
  }
}

export function getBrowserDetails(): string {
  const navUserAgent = navigator.userAgent;
  let browserName = navigator.appName;
  let browserVersion = String(parseFloat(navigator.appVersion));
  let majorVersion = parseInt(navigator.appVersion, 10);
  let tempNameOffset, tempVersionOffset, tempVersion;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((tempVersionOffset = navUserAgent.indexOf('Opera')) != -1) {
    browserName = 'Opera';
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf('Version')) != -1) {
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    }
  } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) != -1) {
    // In MSIE, the true version is after "MSIE" in userAgent
    browserName = 'Microsoft Internet Explorer';
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
  } else if ((tempVersionOffset = navUserAgent.indexOf('Edg')) != -1) {
    // In Edge, the true version is after "Edg" in userAgent
    browserName = 'Edge';
    browserVersion = navUserAgent.substring(tempVersionOffset + 4);
  } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) != -1) {
    // In Chrome, the true version is after "Chrome"
    browserName = 'Chrome';
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
  } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) != -1) {
    // In Safari, the true version is after "Safari" or after "Version"
    browserName = 'Safari';
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf('Version')) != -1) {
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    }
  } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) != -1) {
    // In Firefox, the true version is after "Firefox"
    browserName = 'Firefox';
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if (
    // In most other browsers, "name/version" is at the end of userAgent
    (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
    (tempVersionOffset = navUserAgent.lastIndexOf('/'))
  ) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the browserVersion string at semicolon/space if present
  if ((tempVersion = browserVersion.indexOf(';')) != -1) {
    browserVersion = browserVersion.substring(0, tempVersion);
  }
  if ((tempVersion = browserVersion.indexOf(' ')) != -1) {
    browserVersion = browserVersion.substring(0, tempVersion);
  }
  majorVersion = parseInt('' + browserVersion, 10);
  if (isNaN(majorVersion)) {
    browserVersion = String(parseFloat(navigator.appVersion));
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  const browserAndVersion = `${browserName} ${browserVersion}`;
  return browserAndVersion;
}
