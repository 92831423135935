import { useEffect, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { filterProjects } from '@bentley/pw-api';
import { useNavigationContext } from '../../context';

export function useLastFolderWithConnectedProject(): PWProject | undefined {
  const {
    breadcrumbManager: { breadcrumbs }
  } = useNavigationContext();
  const [lastFolderWithConnectedProject, setLastFolderWithConnectedProject] =
    useState<PWProject>();

  useEffect(() => {
    const reversedBreadcrumbs = filterProjects([...breadcrumbs].reverse());

    const lastFolderWithConnectedProject = reversedBreadcrumbs.find(
      (breadcrumb) => breadcrumb.ConnectedProjectGuid
    );

    setLastFolderWithConnectedProject(lastFolderWithConnectedProject);
  }, [breadcrumbs]);

  return lastFolderWithConnectedProject;
}
