import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgArrowRight } from '@itwin/itwinui-icons-react';
import { MoveModal, allowMoveAll } from '../../../../actions/move';
import {
  useAppViewContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { useRootFolderSelected } from '../useRootFolderSelected';

export function useMoveItemAction(items: PWItem[]): MenuItemAction {
  const { readOnly } = usePluginContext();
  const {
    primaryModal,
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const rootFolderSelected = useRootFolderSelected(items);

  const showMove = useMemo(() => {
    if (readOnly) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    return true;
  }, [readOnly, currentAppView.type]);

  const moveItem = useMemo(
    (): MenuItemAction => ({
      title: t('Move.Move'),
      icon: <SvgArrowRight />,
      onClick: () => primaryModal.open(<MoveModal moveItems={items} />),
      disabled:
        !showMove ||
        rootFolderSelected ||
        !allowMoveAll(
          items,
          currentParent,
          currentAppView.type,
          readOnly,
          allItemsExistInConnection
        ),
      hidden: !showMove
    }),
    [
      showMove,
      items,
      currentParent,
      currentAppView.type,
      readOnly,
      allItemsExistInConnection,
      rootFolderSelected,
      primaryModal
    ]
  );

  return moveItem;
}
