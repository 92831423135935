import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PWItem, RequestOptions } from '@bentley/pw-api';
import { sharedProjectParam } from '../../actions/getShareableLink';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useFolderExplorerContext,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { useFolderEnvironment } from '../useEnvironment';
import { useFocusedTab } from '../useFocusedTab';
import { useLatestVersionItems } from '../useLatestVersionItems';
import { useOutToMeSearch } from '../useOutToMeSearch';
import { usePWIntervalData } from './usePWIntervalData';
import { usePWSecondaryData } from './usePWSecondaryData';
import type { DataManager } from '.';
import { useData } from '.';

export type PWDataManager = {
  refreshCurrentFolder: () => void;
  refresh: () => Promise<void>;
} & DataManager<PWItem>;

export function usePWDataManager(): PWDataManager {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { dataAccessLevel, connection } = usePluginContext();
  const { reloadExplorerFolders } = useFolderExplorerContext();
  const httpService = useHttpService();
  const {
    userManager: { currentUserId }
  } = useEnvironmentContext();
  const {
    navigationManager,
    primaryModal,
    searchState: { setOutToMeCount }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    searchBreadcrumbManager: { updateSearchCount }
  } = useAppViewContext();

  const filterLatestVersionItems = useLatestVersionItems();
  const refreshOutToMe = useOutToMeSearch();
  useFolderEnvironment();
  const tabFocused = useFocusedTab();

  const [filteredItems, setFilteredItems] = useState<PWItem[]>([]);

  const dataRequest = useCallback(
    async (requestOptions?: RequestOptions): Promise<PWItem[]> => {
      const items = await currentAppView.fetchItems({ requestOptions });
      return items;
    },
    [currentAppView]
  );

  const secondaryRequests = usePWSecondaryData();
  const { intervalRequests } = usePWIntervalData();
  const suspendIntervalRequest = useRef<boolean>(false);

  const requestInterval =
    (launchDarklyFeatures.pwDriveStatusUpdateFrequency as number) * 1000;

  const {
    items,
    isLoading,
    error,
    refresh: dataManagerRefresh
  } = useData<PWItem>({
    dataRequest,
    secondaryRequests,
    secondaryBatchSize: 20,
    intervalRequests,
    requestInterval: requestInterval ?? 10000,
    suspendIntervalRequest
  });

  const refresh = useCallback(
    async (condition?: () => boolean): Promise<void> => {
      if (currentAppView.type == 'FolderNavigation') {
        await httpService.cache?.clearEntriesMatching(
          navigationManager.currentParent.instanceId
        );
        reloadExplorerFolders([navigationManager.currentParent.instanceId]);
      }
      dataManagerRefresh(condition);
      return Promise.resolve();
    },
    [
      httpService.cache,
      currentAppView.type,
      dataManagerRefresh,
      reloadExplorerFolders,
      navigationManager.currentParent.instanceId
    ]
  );

  const refreshCurrentFolder = useCallback((): void => {
    const refreshCondition = () => {
      const navigatedProject = new URLSearchParams(location.search).get(
        sharedProjectParam
      );

      return (
        navigatedProject == navigationManager.currentParent.instanceId ||
        navigatedProject == null
      );
    };

    void refresh(refreshCondition);
  }, [refresh, navigationManager.currentParent.instanceId]);

  useEffect(() => {
    const filteredItems = filterLatestVersionItems(items);
    setFilteredItems(filteredItems);
  }, [filterLatestVersionItems, items]);

  useEffect(() => {
    if (!isLoading) {
      updateSearchCount(filteredItems);
    }
  }, [isLoading, filteredItems, updateSearchCount]);

  useEffect(() => {
    if (currentUserId && dataAccessLevel == 'WorkArea') {
      setOutToMeCount(undefined);
      void refreshOutToMe();
    }
  }, [
    currentUserId,
    dataAccessLevel,
    refreshOutToMe,
    connection.Id,
    setOutToMeCount
  ]);

  useEffect(() => {
    // No need to run interval requests in background while user has a dialog open
    // or tab is not foreground in browser
    suspendIntervalRequest.current = primaryModal.modal != null || !tabFocused;
  }, [primaryModal.modal, tabFocused]);

  const pwDataManager = useMemo(
    (): PWDataManager => ({
      items: filteredItems,
      error,
      isLoading,
      refresh,
      refreshCurrentFolder
    }),
    [error, isLoading, filteredItems, refresh, refreshCurrentFolder]
  );

  return pwDataManager;
}
