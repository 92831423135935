import './pdfLink.css';

import React, { useCallback, useEffect, useRef } from 'react';
import type { PWDocument, PWParentType } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { SvgChevronLeft } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import type { DataAccessLevel } from '../../context';
import { usePluginContext } from '../../context';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import { buildPDFMarkupLink } from './pdfLinkSrv';
import { fileIsPdf } from './utils';

export type OpenPdfProps = {
  connectionId: string;
  datasourceId: string;
  item: PWDocument;
  readOnly: boolean;
  dataAccessLevel?: DataAccessLevel;
  itemParent?: PWParentType;
  isOpen: boolean;
  closeHandle: () => void;
};

type OpenPdfMarkUpProps = {
  PDFMarkupUrl: string;
  projectId?: string;
  trackFeature: TrackFeature;
} & OpenPdfProps;

export function OpenPdfMarkUp({
  PDFMarkupUrl,
  projectId,
  trackFeature,
  connectionId,
  datasourceId,
  readOnly,
  dataAccessLevel,
  item,
  itemParent,
  isOpen,
  closeHandle
}: OpenPdfMarkUpProps): JSX.Element {
  const isPdf = fileIsPdf(item);
  const url: string = buildPDFMarkupLink(
    PDFMarkupUrl,
    projectId,
    connectionId,
    datasourceId,
    item,
    readOnly,
    itemParent,
    dataAccessLevel
  );

  if (isPdf) {
    if (!readOnly) {
      trackFeature('OPEN_PDFMARKUP_READONLY');
    } else {
      trackFeature('OPEN_PDFMARKUP_RW');
    }
  } else {
    trackFeature('VIEW_DOCUMENT');
  }

  trackFeature('OPEN_VIEWER_IN_CURRENT_TAB');

  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { consumerApp, pdfMarkupForms } = usePluginContext();

  function handleMouseDown(event: React.MouseEvent) {
    if (
      overlayRef &&
      overlayRef.current &&
      overlayRef.current.contains(event.target as Node) &&
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      closeHandle();
    }
  }
  const getPostMessage = useCallback(() => {
    return consumerApp == 'ProjectWise 365' ||
      consumerApp == 'ProjectWise 365 in Teams'
      ? JSON.stringify({
          type: 'setInitOptions',
          options: {
            forms: {
              pdfMarkupForm: pdfMarkupForms
            },
            tools: {
              hiddenHeaderItems: ['userProfileMenu']
            }
          }
        })
      : JSON.stringify({
          type: 'setInitOptions',
          options: {
            tools: {
              hiddenHeaderItems: ['userProfileMenu']
            }
          }
        });
  }, [consumerApp, pdfMarkupForms]);

  const handlePdfMarkupToolInit = useCallback(
    (event: MessageEvent) => {
      if (event.data === 'pdfMarkupLoaded') {
        const message = getPostMessage();

        if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
          iframeRef.current.contentWindow.postMessage(message, '*');
        }
      }
    },
    [getPostMessage]
  );

  useEffect(() => {
    window.addEventListener('message', handlePdfMarkupToolInit, false);

    return () => window.removeEventListener('message', handlePdfMarkupToolInit);
  }, [handlePdfMarkupToolInit, isOpen]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeHandle();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [closeHandle]);

  return (
    <>
      {isOpen && (
        <div
          className="pdf-markup-overlay"
          tabIndex={0}
          ref={overlayRef}
          onMouseDown={handleMouseDown}
          role="button"
        >
          <div className="pdf-markup-modal" ref={modalRef}>
            <div className="pdf-markup-modal-header">
              <button className={'modal-back'} onClick={closeHandle}>
                <SvgChevronLeft />
                <Text>{t('Generic.Back')}</Text>
              </button>
              <div className={'modal-title'}>
                <ItemIcon item={item} size="s" />
                {item.Name}
              </div>
            </div>
            <iframe
              src={url}
              title={t('MenuItem.PDFReview')}
              ref={iframeRef}
              className={'modal-content'}
              allow="fullscreen"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
}
