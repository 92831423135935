import type { HttpService, PWDataItem } from '@bentley/pw-api';
import { modifyItem } from '@bentley/pw-api';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';

/**
 * Helper for checkOut function
 * @param {PWDataItem} dataItem Item to lock
 * @param {HttpService} httpService Configured http service
 * @returns {Response}
 */
export async function checkOutFile(
  dataItem: PWDataItem,
  httpService: HttpService,
  useAttributeExchange?: boolean
): Promise<Response> {
  const response = await usingConcurrencyLimiter(async () => {
    return await modifyItem({
      item: dataItem,
      httpService,
      modifyOptions: { attributeExchange: useAttributeExchange },
      properties: { IsCheckedOut: true }
    });
  });
  return response;
}

/**
 * Helper for freeDocument function
 * @param {PWDataItem} dataItem Item to lock
 * @param {HttpService} httpService Configured http service
 * @returns {Response}
 */
export async function free(
  dataItem: PWDataItem,
  httpService: HttpService
): Promise<Response> {
  const response = await usingConcurrencyLimiter(async () => {
    return await modifyItem({
      item: dataItem,
      httpService,
      properties: { IsLocked: false }
    });
  });

  return response;
}
