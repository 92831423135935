import { useCallback, useMemo } from 'react';
import type {
  PWItem,
  PWProject,
  RequestOptions,
  Select
} from '@bentley/pw-api';
import { parseResponseInstances, query } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';

type SearchWithoutAncestorFunctions = {
  hasAncestorBug: boolean;
  searchWithoutAncestor: (requestOptions?: RequestOptions) => Promise<PWItem[]>;
};

export function useSearchWithoutAncestor(): SearchWithoutAncestorFunctions {
  const httpService = useHttpService();

  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { advancedSearch }
  } = useNavigationContext();

  const hasAncestorBug = useMemo((): boolean => {
    if (
      ecPluginFeatures.apiAncestorBug() &&
      advancedSearch?.ancestor?.instanceId &&
      advancedSearch?.itemClass == 'Document' &&
      advancedSearch?.environment != 'General'
    ) {
      return true;
    }

    return false;
  }, [advancedSearch, ecPluginFeatures]);

  const getSubfolders = useCallback(
    async (requestOptions?: RequestOptions): Promise<PWProject[]> => {
      const ancestor = advancedSearch?.ancestor as PWProject;

      const url = query({
        schemaName: 'PW_WSG',
        className: 'Project',
        apiAncestor: { className: 'Project', instanceId: ancestor.instanceId },
        select: { propertyName: 'instanceId' }
      });

      const response = await httpService.get(url, requestOptions);
      const subfolders = await parseResponseInstances<PWProject>(response);
      return subfolders;
    },
    [advancedSearch?.ancestor, httpService]
  );

  const buildSearch = useCallback((): string => {
    if (!advancedSearch) {
      throw new Error('Advanced search not set');
    }

    const { query: searchQuery, environment } = advancedSearch;

    const select: Select[] = [
      { properties: 'all' },
      {
        relationshipClass: {
          relationshipClassName: 'DocumentParent',
          direction: 'forward',
          relatedClassName: 'Project'
        },
        properties: 'all'
      },
      {
        relationshipClass: {
          relationshipSchema: 'PW_WSG',
          relationshipClassName: 'DocumentEnvironment',
          direction: 'forward',
          relatedSchema: 'PW_WSG_Dynamic',
          relatedClassName: environment
        },
        properties: 'all'
      }
    ];

    const url =
      query({
        schemaName: 'PW_WSG',
        className: 'Document',
        polymorphic: true,
        filter: [],
        select: select,
        filterSettings: 'CaseInsensitive'
      }) + `&$filter=(${searchQuery})`;

    return url;
  }, [advancedSearch]);

  const searchWithoutAncestor = useCallback(
    async (requestOptions?: RequestOptions): Promise<PWItem[]> => {
      const subfolders = await getSubfolders(requestOptions);

      const url = buildSearch();

      const response = await httpService.get(url, requestOptions);
      if (!response.ok) {
        throw response;
      }

      const datasourceItems = await parseResponseInstances<PWItem>(response);

      const itemsUnderAncestor = datasourceItems.filter((item) =>
        subfolders.find((folder) => folder.instanceId == item.ParentGuid)
      );
      return itemsUnderAncestor;
    },
    [buildSearch, getSubfolders, httpService]
  );

  const searchWithoutAncestorFunctions = useMemo(
    () => ({
      searchWithoutAncestor,
      hasAncestorBug
    }),
    [searchWithoutAncestor, hasAncestorBug]
  );

  return searchWithoutAncestorFunctions;
}
