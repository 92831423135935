import type { Attribute } from '../../../hooks/useEnvironment';
import type { EnvironmentProperty } from '../../../hooks/useProperties';
import type { ColumnInfo } from '../../../hooks/useViews';
import { t } from '../../../services/translation';

export type TransferItem = {
  label: string;
  description?: string;
  value: ColumnInfo;
  environment: string;
};

export function propertyToTransferItem(
  property: EnvironmentProperty | ColumnInfo
): TransferItem {
  let environment = property.envLabel ?? 'General';
  if (environment == t('CustomColumns.GeneralProperties')) {
    environment = 'General';
  }

  if (property.name == 'FileSize') {
    property.dataType = 'fileSize';
  }

  return {
    label: property.displayLabel,
    description: environment == 'General' ? undefined : property.envLabel,
    value: property,
    environment: environment
  };
}

export function environmentAttributeToTransferItem(
  attribute: Attribute
): TransferItem {
  return {
    label: attribute.label,
    description: attribute.environmentLabel,
    value: attributeToColumnInfo(attribute),
    environment: attribute.environmentLabel
  };
}

function attributeToColumnInfo(attribute: Attribute): ColumnInfo {
  return {
    displayLabel: attribute.label,
    propertyType: attribute.environmentLabel,
    dataType: attribute.typeName ?? 'string',
    name: attribute.name,
    envLabel: attribute.environmentLabel
  };
}
