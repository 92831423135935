import { CacheService, HttpService } from '@bentley/pw-api';
import packageInfo from '../../../package.json';
import type { Config } from './configContext';

export async function loadConfig(): Promise<Config> {
  try {
    const httpService = new HttpService({
      baseUrl: '/api',
      cache: new CacheService({ name: 'AppSettings' })
    });
    const response = await httpService.get('appsettings', {
      cacheSuffix: 'persistent',
      uncached: location.origin.includes('localhost') ? true : undefined
    });
    const config = (await response.json()) as Config;
    config.branchName = packageInfo.branchName;
    return config;
  } catch (e) {
    console.error('Error loading config', e);
    throw e;
  }
}
