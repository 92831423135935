import type { PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { RBACPermission } from '../../hooks/useRbac/rbacPermission';

export function disableFolderAccessMenuItem(items: PWItem[]): boolean {
  if (!items || items.length == 0 || items.length > 1) {
    return true;
  } else {
    return false;
  }
}

export function showFolderAccessMenuItem(
  item: PWItem,
  rbacPermissions: RBACPermission[]
): boolean {
  if (!item) {
    return false;
  }

  if (!rbacPermissions.length) {
    return false;
  }
  const folderAccessPermission = rbacPermissions.find(
    (permission) =>
      permission.Name.toLowerCase() == 'access team member and role management'
  );
  if (!folderAccessPermission) {
    return false;
  }

  return itemIsProject(item);
}
