import React from 'react';
import { parseFileExtension } from '@bentley/pw-api';
import { SvgFiletypeAmim, SvgFiletypeAudio, SvgFiletypeCompressed, SvgFiletypeDgndb, SvgFiletypeDocument, SvgFiletypeDwg, SvgFiletypeImage, SvgFiletypeImodel, SvgFiletypeMicrostation, SvgFiletypeMism, SvgFiletypeMsg, SvgFiletypePdf, SvgFiletypePdfMarkup, SvgFiletypePpt, SvgFiletypeRevit, SvgFiletypeSketchup, SvgFiletypeSystem, SvgFiletypeTxt2, SvgFiletypeVideo, SvgFiletypeVisio, SvgFiletypeWord, SvgFiletypeXls } from '@itwin/itwinui-icons-color-react';
import { pwIconProps } from '../../sizes/iconClassName';
import { SvgFiletypeAutopipe } from '../fileTypes/SvgFiletypeAutopipe';
import { SvgFiletypeAutopipeNozzle } from '../fileTypes/SvgFiletypeAutopipeNozzle';
import { SvgFiletypeAutopipeVessel } from '../fileTypes/SvgFiletypeAutopipeVessel';
import { SvgFiletypeIfc } from '../fileTypes/SvgFiletypeIfc';
import { SvgFiletypeRamConcepts } from '../fileTypes/SvgFiletypeRamConcepts';
import { SvgFiletypeRamConnection } from '../fileTypes/SvgFiletypeRamConnection';
import { SvgFiletypeRamElements } from '../fileTypes/SvgFiletypeRamElements';
import { SvgFiletypeRamStructural } from '../fileTypes/SvgFiletypeRamStructural';
import { SvgFiletypeStaad } from '../fileTypes/SvgFiletypeStaad';
import { SvgFiletypeVue } from '../fileTypes/SvgFiletypeVue';
export function DocumentIcon({ item, size, ...rest }) {
    const fileExtension = parseFileExtension(item.FileName);
    const iconProps = pwIconProps(size, rest);
    switch (fileExtension) {
        case 'amim': {
            return React.createElement(SvgFiletypeAmim, { "data-testid": "amim-filetype", ...iconProps });
        }
        case 'aac':
        case 'aif':
        case 'm4a':
        case 'mid':
        case 'mp3':
        case 'ogg':
        case 'wav':
        case 'wma': {
            return React.createElement(SvgFiletypeAudio, { "data-testid": "audio-filetype", ...iconProps });
        }
        case 'dat': {
            return (React.createElement(SvgFiletypeAutopipe, { "data-testid": "autopipe-filetype", ...iconProps }));
        }
        case 'noz': {
            return (React.createElement(SvgFiletypeAutopipeNozzle, { "data-testid": "autopipe-nozzle-filetype", ...iconProps }));
        }
        case 'emvd': {
            return (React.createElement(SvgFiletypeAutopipeVessel, { "data-testid": "autopipe-vessel-filetype", ...iconProps }));
        }
        case '7z':
        case 'cab':
        case 'gz':
        case 'iso':
        case 'rar':
        case 'tar':
        case 'zip':
        case 'zipx': {
            return (React.createElement(SvgFiletypeCompressed, { "data-testid": "compressed-filetype", ...iconProps }));
        }
        case 'dgndb':
        case 'dgndbz':
        case 'idgndb':
        case 'imodel':
        case 'omim':
        case 'pmim': {
            return React.createElement(SvgFiletypeDgndb, { "data-testid": "dgndb-filetype", ...iconProps });
        }
        case 'dwf':
        case 'dwg':
        case 'dws':
        case 'dwt':
        case 'dxf': {
            return React.createElement(SvgFiletypeDwg, { "data-testid": "dwg-filetype", ...iconProps });
        }
        case 'ifc': {
            return React.createElement(SvgFiletypeIfc, { "data-testid": "ifc-filetype", ...iconProps });
        }
        case 'bmp':
        case 'dib':
        case 'gif':
        case 'ico':
        case 'jfif':
        case 'jpe':
        case 'jpeg':
        case 'jpg':
        case 'pcx':
        case 'png':
        case 'psd':
        case 'raw':
        case 'svg':
        case 'tga':
        case 'tif':
        case 'tiff':
        case 'webp': {
            return React.createElement(SvgFiletypeImage, { "data-testid": "image-filetype", ...iconProps });
        }
        case 'i-model': {
            return React.createElement(SvgFiletypeImodel, { "data-testid": "imodel-filetype", ...iconProps });
        }
        case 'dgn': {
            return (React.createElement(SvgFiletypeMicrostation, { "data-testid": "microstation-filetype", ...iconProps }));
        }
        case 'mism': {
            return React.createElement(SvgFiletypeMism, { "data-testid": "mism-filetype", ...iconProps });
        }
        case 'eml':
        case 'msg': {
            return React.createElement(SvgFiletypeMsg, { "data-testid": "msg-filetype", ...iconProps });
        }
        case 'pdf': {
            return React.createElement(SvgFiletypePdf, { "data-testid": "pdf-filetype", ...iconProps });
        }
        case 'xfdf': {
            return (React.createElement(SvgFiletypePdfMarkup, { "data-testid": "pdf-markup-filetype", ...iconProps }));
        }
        case 'pot':
        case 'potm':
        case 'potx':
        case 'ppam':
        case 'pps':
        case 'ppsm':
        case 'ppsx':
        case 'ppt':
        case 'pptm':
        case 'pptx': {
            return React.createElement(SvgFiletypePpt, { "data-testid": "ppt-filetype", ...iconProps });
        }
        case 'cpt': {
            return (React.createElement(SvgFiletypeRamConcepts, { "data-testid": "ram-concepts-filetype", ...iconProps }));
        }
        case 'rcnx': {
            return (React.createElement(SvgFiletypeRamConnection, { "data-testid": "ram-connection-filetype", ...iconProps }));
        }
        case 'retx': {
            return (React.createElement(SvgFiletypeRamElements, { "data-testid": "ram-elements-filetype", ...iconProps }));
        }
        case 'rss': {
            return (React.createElement(SvgFiletypeRamStructural, { "data-testid": "ram-structural-filetype", ...iconProps }));
        }
        case 'rfa':
        case 'rft':
        case 'rte':
        case 'rvt': {
            return React.createElement(SvgFiletypeRevit, { "data-testid": "revit-filetype", ...iconProps });
        }
        case 'skb':
        case 'skp': {
            return (React.createElement(SvgFiletypeSketchup, { "data-testid": "sketchup-filetype", ...iconProps }));
        }
        case 'anl':
        case 'sfa':
        case 'std': {
            return React.createElement(SvgFiletypeStaad, { "data-testid": "staad-filetype", ...iconProps });
        }
        case 'apk':
        case 'app':
        case 'bat':
        case 'com':
        case 'dll':
        case 'exe':
        case 'ini':
        case 'jar':
        case 'msi':
        case 'sh':
        case 'sys': {
            return React.createElement(SvgFiletypeSystem, { "data-testid": "system-filetype", ...iconProps });
        }
        case 'har':
        case 'json':
        case 'log':
        case 'rtf':
        case 'text':
        case 'txt':
        case 'xml': {
            return React.createElement(SvgFiletypeTxt2, { "data-testid": "txt2-filetype", ...iconProps });
        }
        case 'avi':
        case 'm4v':
        case 'mov':
        case 'mp4':
        case 'mpeg':
        case 'mpg':
        case 'ogv':
        case 'qt':
        case 'webm':
        case 'wmv': {
            return React.createElement(SvgFiletypeVideo, { "data-testid": "video-filetype", ...iconProps });
        }
        case 'vsd':
        case 'vsdm':
        case 'vsdx':
        case 'vss':
        case 'vssm':
        case 'vssx':
        case 'vst':
        case 'vstm':
        case 'vstx': {
            return React.createElement(SvgFiletypeVisio, { "data-testid": "visio-filetype", ...iconProps });
        }
        case 'vue': {
            return React.createElement(SvgFiletypeVue, { "data-testid": "vue-filetype", ...iconProps });
        }
        case 'doc':
        case 'docm':
        case 'docx':
        case 'dot':
        case 'dotm':
        case 'dotx':
        case 'odt': {
            return React.createElement(SvgFiletypeWord, { "data-testid": "word-filetype", ...iconProps });
        }
        case 'csv':
        case 'xla':
        case 'xlam':
        case 'xlb':
        case 'xlc':
        case 'xls':
        case 'xlsb':
        case 'xlsm':
        case 'xlsx':
        case 'xlt':
        case 'xltm':
        case 'xltx': {
            return React.createElement(SvgFiletypeXls, { "data-testid": "xls-filetype", ...iconProps });
        }
        default: {
            return (React.createElement(SvgFiletypeDocument, { "data-testid": "document-filetype", ...iconProps }));
        }
    }
}
