import { useCallback } from 'react';
import type {
  PWItem,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { byDefaultOrder, parseResponseInstances } from '@bentley/pw-api';
import { useHttpService, useNavigationContext } from '../../../context';
import { logError } from '../../../hooks/useErrorLogging';
import { configureHttpOptionsWithAbortController } from '../../../services/http';
import { t } from '../../../services/translation';
import { useSearchTimeout } from '../../searchTimeoutModal';
import { useBuildAdvancedSearch } from './useBuildAdvancedSearch';
import { useSearchWithoutAncestor } from './useSearchWithoutAncestor';

type SearchFunction = (requestOptions?: RequestOptions) => Promise<PWItem[]>;

export function useAdvancedSearch(): SearchFunction {
  const httpService = useHttpService();

  const {
    primaryModal: { close: closePrimaryModal },
    searchState: { advancedSearch }
  } = useNavigationContext();

  const { hasAncestorBug, searchWithoutAncestor } = useSearchWithoutAncestor();
  const buildAdvancedSearch = useBuildAdvancedSearch();
  const scheduleSearchTimeout = useSearchTimeout();

  const handleError = useCallback(
    async (error: unknown): Promise<void> => {
      if (error instanceof Response) {
        const responseMessage = (await error
          .clone()
          .json()) as WSGInstancesResponse;
        if (
          responseMessage.errorId?.includes('NotFound') ||
          responseMessage.errorMessage?.includes('not found')
        ) {
          logError('Advanced search failed', {
            error: { responseMessage },
            advancedSearch
          });
          throw new Error(t('AdvancedSearch.PropertiesError'));
        }
      }
      logError('Advanced search failed', { error, advancedSearch });
    },
    [advancedSearch]
  );

  const search = useCallback(
    async (requestOptions?: RequestOptions) => {
      if (!advancedSearch) {
        throw new Error('Advanced search not set');
      }

      requestOptions = configureHttpOptionsWithAbortController(requestOptions);
      const cancelSearchTimeout = scheduleSearchTimeout(
        requestOptions.abortController
      );

      try {
        if (hasAncestorBug) {
          // Work around for plugin 3.1 defect with api.ancestor and environment select
          const items = await searchWithoutAncestor(requestOptions);
          return items
            .filter(
              ({ instanceId }) =>
                instanceId != advancedSearch.ancestor?.instanceId
            )
            .sort(byDefaultOrder);
        }

        const url = buildAdvancedSearch();
        const response = await httpService.get(url, requestOptions);
        if (!response.ok) {
          throw response;
        }

        const items = await parseResponseInstances<PWItem>(response);

        return items
          .filter(
            ({ instanceId }) =>
              instanceId != advancedSearch.ancestor?.instanceId
          )
          .sort(byDefaultOrder);
      } catch (error) {
        await handleError(error);
        throw error;
      } finally {
        cancelSearchTimeout();
        closePrimaryModal();
      }
    },
    [
      advancedSearch,
      buildAdvancedSearch,
      closePrimaryModal,
      httpService,
      hasAncestorBug,
      handleError,
      scheduleSearchTimeout,
      searchWithoutAncestor
    ]
  );

  return search;
}
