import React from 'react';
import { Checkbox } from '@itwin/itwinui-react';
import type { HeaderProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../../services/translation';
import type { MappingDataRecord } from '../iModelMapTable';

type MasterModelHeaderProps = {
  allMasterChecked?: boolean;
  hasExistingBridges?: boolean;
  notEnoughMappings?: boolean;
  tableDisabled?: boolean;
  onMasterHeaderClick?: React.ChangeEventHandler<HTMLInputElement>;
} & HeaderProps<MappingDataRecord>;

export function MasterModelHeader({
  allMasterChecked,
  hasExistingBridges,
  notEnoughMappings,
  tableDisabled,
  onMasterHeaderClick
}: MasterModelHeaderProps): JSX.Element {
  return (
    <Checkbox
      disabled={tableDisabled || hasExistingBridges || notEnoughMappings}
      label={t('IModel.MasterModel')}
      name="allMasterCheck"
      checked={allMasterChecked}
      onChange={onMasterHeaderClick}
      data-testid={'table-header-input-master-model'}
    />
  );
}
