import { useCallback, useEffect, useMemo, useState } from 'react';
import { itemIsParentType } from '@bentley/pw-api';
import type { DynamicFolderSettings } from '../../actions/dynamicFolderSettings';
import { getFolderSettings } from '../../actions/dynamicFolderSettings';
import {
  useNavigationContext,
  usePluginContext,
  useProjectSettingsContext
} from '../../context';
import type { AppView } from '../useAppView';

export type FolderSettingsManager = {
  folderSettings?: DynamicFolderSettings;
  reloadFolderSettings: () => void;
  errorRetrievingSettings: boolean;
};

/**
 * Retrieves and saves the current folder settings for the current parent
 * When the current parent updates, re-queries the folder settings
 */
export function useFolderSettings(appView: AppView): FolderSettingsManager {
  const { organizationId, httpService, projectSettings } =
    useProjectSettingsContext();
  const { connection, contextId, readOnly } = usePluginContext();
  const {
    navigationManager: { currentParent },
    breadcrumbManager: { breadcrumbs }
  } = useNavigationContext();

  const [folderSettings, setFolderSettings] = useState<DynamicFolderSettings>();
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const [errorRetrievingSettings, setErrorRetrievingSettings] =
    useState<boolean>(false);

  const dynamicSettingsEnabled = !readOnly && itemIsParentType(currentParent);

  const ancestorIds = useMemo(
    () => breadcrumbs.map((breadcrumb) => breadcrumb.instanceId),
    [breadcrumbs]
  );

  const updateFolderSettings = useCallback(
    async (abortController?: AbortController): Promise<void> => {
      if (!httpService) {
        throw new Error('Http service not initialized');
      }

      const parentId = ancestorIds[ancestorIds.length - 1];
      try {
        const folderSettings = await getFolderSettings(
          connection.DatasourceInstanceId,
          connection.ProjectId,
          contextId ?? '',
          organizationId,
          parentId,
          projectSettings,
          ancestorIds,
          httpService,
          { abortController }
        );

        if (!abortController?.signal.aborted) {
          setErrorRetrievingSettings(false);
          setFolderSettings(folderSettings);
        }
      } catch (error) {
        setErrorRetrievingSettings(true);
      }
    },
    [
      ancestorIds,
      connection.DatasourceInstanceId,
      connection.ProjectId,
      contextId,
      httpService,
      organizationId,
      projectSettings
    ]
  );

  const reloadFolderSettings = useCallback((): void => {
    setForceRefresh((cur) => !cur);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (
      dynamicSettingsEnabled &&
      appView == 'FolderNavigation' &&
      httpService?.baseUrl
    ) {
      void updateFolderSettings(abortController);
    }

    return () => {
      abortController.abort();
      setFolderSettings(undefined);
    };
  }, [
    appView,
    dynamicSettingsEnabled,
    forceRefresh,
    updateFolderSettings,
    httpService
  ]);

  return {
    folderSettings,
    errorRetrievingSettings,
    reloadFolderSettings
  };
}
