import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ToastHandle } from '../../services/pwToast';
import { confirmLargeDownloadModal } from './modals';
import { notifyDownloadCancelled, notifyProcessing } from './notifications';
import { maxDownloadSize } from './utils';

export function confirmLargeDownload(
  openModal: OpenModal,
  closeModal: CloseModal,
  toastHandle: ToastHandle
): (downloadSize: number) => Promise<boolean> {
  async function confirmSize(downloadSize: number): Promise<boolean> {
    if (downloadSize < maxDownloadSize) {
      return true;
    }

    const proceed = new Promise<boolean>((resolve, reject) => {
      confirmLargeDownloadModal(
        downloadSize,
        openModal,
        closeModal,
        (proceed) => {
          if (!proceed) {
            notifyDownloadCancelled(toastHandle);
          }
          resolve(proceed);
        }
      );
    });

    return proceed;
  }

  return confirmSize;
}

export function onZipProgress(
  toastHandle: ToastHandle
): (percent: number, error?: boolean) => void {
  const processingHandle = notifyProcessing(toastHandle);
  return (percent: number, error?: boolean) => {
    if (percent >= 100 || error) {
      processingHandle?.close();
    }
  };
}
