import type { EnvironmentProperty } from '../../../hooks/useProperties';
import { escapeSearchString } from '../../../services/search';
import { t } from '../../../services/translation';
import type { AdvancedSearchRowData } from '../advancedSearchModal';
import type { AdvancedSearchGroup } from '../advancedSearchRowProvider';
import { booleanValueDropdownOptions } from '../row/dropdownOptions';

export const dataTypes = {
  dateTime: 'dateTime',
  boolean: 'boolean',
  string: 'string',
  int: 'int',
  double: 'double',
  long: 'long',
  id: 'id'
} as const;

export function buildQuery(
  rows: AdvancedSearchRowData[],
  groupData: AdvancedSearchGroup[]
): string {
  let queryString = '';

  rows.forEach((row, index) => {
    const isFirstRow = index == 0;
    const isGroupStart = groupData.some((element) => element.startRow == index);
    const isGroupEnd = groupData.some((element) => element.endRow == index);

    const rowQuery = getQueryFilter(
      row,
      queryString,
      isFirstRow,
      isGroupStart,
      isGroupEnd
    );

    queryString += rowQuery;
  });

  return queryString;
}

function getQueryFilter(
  {
    selectedProperty,
    selectedOperator,
    value,
    selectedLogicalOperator
  }: AdvancedSearchRowData,
  existingQueryString: string,
  isFirstRow: boolean,
  isGroupStart: boolean,
  isGroupEnd: boolean
): string {
  if (value === '' || !selectedProperty.name || !selectedOperator) {
    return '';
  }

  let query = '(';

  if (isGroupStart) {
    query += '(';
  }

  query += queryStringByDataType(selectedOperator, selectedProperty, value);

  if (isGroupEnd) {
    query += ')';
  }

  if (!isFirstRow && existingQueryString) {
    query = ` ${selectedLogicalOperator.value} ${query})`;
  } else {
    query += `)`;
  }

  return query;
}

function queryStringByDataType(
  selectedOperator: string,
  selectedProperty: EnvironmentProperty,
  value: string | number
): string {
  const propertyName = propertyNameWithEnvironment(selectedProperty);

  switch (selectedProperty.dataType) {
    case dataTypes.string:
    case 'id': {
      return getStringQuery(
        selectedOperator,
        propertyName,
        escapeSearchString(value as string)
      );
    }

    case dataTypes.dateTime: {
      return getDateQueryString(
        selectedOperator,
        propertyName,
        value as string
      );
    }

    case dataTypes.int:
    case dataTypes.double:
    case dataTypes.long: {
      return getNumberQuery(selectedOperator, propertyName, value as number);
    }

    case dataTypes.boolean: {
      return getBooleanQuery(selectedOperator, propertyName, value as string);
    }
  }

  return '';
}

function propertyNameWithEnvironment(
  selectedProperty: EnvironmentProperty
): string {
  if (
    selectedProperty.propertyType != undefined &&
    selectedProperty.propertyType != 'General' &&
    selectedProperty.propertyType != 'Folder'
  ) {
    const relationshipClass = selectedProperty.isProjectEnv
      ? 'ProjectProjectType'
      : 'DocumentEnvironment';
    return `${relationshipClass}-forward-PW_WSG_Dynamic.${selectedProperty.propertyType}!poly.${selectedProperty.name}`;
  }

  return selectedProperty.name;
}

function getStringQuery(
  selectedOperator: string,
  property: string,
  value: string
): string {
  switch (selectedOperator) {
    case t('AdvancedSearch.Equals'): {
      return `${property} eq '${value}'`;
    }

    case t('AdvancedSearch.DoesNotEqual'): {
      return `${property} ne '${value}'`;
    }

    case t('AdvancedSearch.Contains'): {
      return `${property} like '${value}'`;
    }

    case t('AdvancedSearch.StartsWith'): {
      // Using like % is faster than startswith() in the WSG implementation
      return `${property} like '${value}%'`;
    }

    case t('AdvancedSearch.DoesNotContain'): {
      return `${property} notlike '${value}'`;
    }
  }

  return '';
}

function getDateQueryString(
  selectedOperator: string,
  property: string,
  value: string
): string {
  switch (selectedOperator) {
    case t('AdvancedSearch.Before'): {
      return `${property} lt datetime'${new Date(value).toJSON()}'`;
    }

    case t('AdvancedSearch.OnOrBefore'): {
      const date: Date = new Date(value);
      date.setDate(date.getDate() + 1);
      return `${property} lt datetime'${date.toJSON()}'`;
    }

    case t('AdvancedSearch.On'): {
      const date: Date = new Date(value);
      const greaterThanQuery = `${property} ge datetime'${date.toJSON()}' and `;

      date.setDate(date.getDate() + 1);
      const lessThanQuery = `${property} lt datetime'${date.toJSON()}'`;

      return `${greaterThanQuery}${lessThanQuery}`;
    }

    case t('AdvancedSearch.OnOrAfter'): {
      return `${property} ge datetime'${new Date(value).toJSON()}'`;
    }

    case t('AdvancedSearch.After'): {
      const date: Date = new Date(value);
      date.setDate(date.getDate() + 1);
      return `${property} ge datetime'${date.toJSON()}'`;
    }

    case t('AdvancedSearch.NotOn'): {
      const date: Date = new Date(value);
      const lessThanQuery = `${property} lt datetime'${date.toJSON()}' or `;

      date.setDate(date.getDate() + 1);
      const greaterThanQuery = `${property} ge datetime'${date.toJSON()}'`;

      return `${lessThanQuery}${greaterThanQuery}`;
    }
  }

  return '';
}

function getNumberQuery(
  selectedOperator: string,
  property: string,
  value: number
): string {
  switch (selectedOperator) {
    case t('AdvancedSearch.IsLessThan'): {
      return `${property} lt ${value}`;
    }

    case t('AdvancedSearch.IsLessThanOrEqualTo'): {
      return `${property} le ${value}`;
    }

    case t('AdvancedSearch.Equals'): {
      return `${property} eq ${value}`;
    }

    case t('AdvancedSearch.IsGreaterThanOrEqualTo'): {
      return `${property} ge ${value}`;
    }

    case t('AdvancedSearch.IsGreaterThan'): {
      return `${property} gt ${value}`;
    }

    case t('AdvancedSearch.DoesNotEqual'): {
      return `${property} ne ${value}`;
    }
  }

  return '';
}

function getBooleanQuery(
  selectedOperator: string,
  property: string,
  value: string
): string {
  const booleanOperators = booleanValueDropdownOptions();
  const booleanOperator = booleanOperators.find(
    (op) => op.value.toLowerCase() == value.toLowerCase()
  );

  if (!booleanOperator) {
    throw new Error('Invalid boolean value');
  }

  switch (selectedOperator) {
    case t('AdvancedSearch.Equals'): {
      return `${property} eq ${booleanOperator.value}`;
    }

    case t('AdvancedSearch.DoesNotEqual'): {
      return `${property} ne ${booleanOperator.value}`;
    }
  }
  return '';
}

export function addLatestVersionSearchToQuery(
  query: string,
  filterLatestVersion: boolean
): string {
  if (!filterLatestVersion) {
    return query;
  }

  return `(${query} and IsLatest eq true)`;
}

export function updateRowToSearchOnId(row: AdvancedSearchRowData): void {
  if (
    !row.selectedProperty.picklistOptions?.length ||
    row.selectedProperty.propertyType != 'General'
  ) {
    return;
  }

  const propertyContainsMappedValues =
    !row.selectedProperty.picklistOptions.every(
      (option) => option.Value == option.DisplayLabel
    );

  if (!propertyContainsMappedValues) {
    return;
  }

  // Prevent updating row repeatedly
  if (row.selectedProperty.name.endsWith('Id')) {
    return;
  }

  // State, Workflow, User
  row.selectedProperty.name += 'Id';
  row.value = parseInt(String(row.value));
  row.selectedProperty.dataType = 'int';
}
