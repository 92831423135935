import { useCallback } from 'react';
import { useLocalStorage, useSessionStorage } from '../useStorage';
import type { ECPluginVersion } from './ecPluginVersion';
import { pluginVersionStorageKey } from './sessionStorage';

type ECPluginCache = [ECPluginVersion, (version: ECPluginVersion) => void];

export function useECPluginCache(connectionId?: string): ECPluginCache {
  const cacheKey = `${pluginVersionStorageKey}:${connectionId ?? ''}`;

  const [ecPluginCache, setECPluginCache] =
    useLocalStorage<ECPluginVersion | null>(cacheKey, null);

  const [ecPluginVersion, setECPluginVersion] =
    useSessionStorage<ECPluginVersion>(pluginVersionStorageKey, ecPluginCache);

  const setCachedVersion = useCallback(
    (version: ECPluginVersion) => {
      setECPluginVersion(version);

      if (connectionId) {
        setECPluginCache(version);
      }
    },
    [connectionId, setECPluginCache, setECPluginVersion]
  );

  return [ecPluginVersion, setCachedVersion];
}
