import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgScript } from '@itwin/itwinui-icons-react';
import { CreateRenditionModal } from '../../../../actions/createRendition';
import { allowCreateRenditionsAll } from '../../../../actions/createRendition/requirements';
import {
  useAppViewContext,
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCreateRenditionAction(items: PWItem[]): MenuItemAction {
  const { readOnly, connection } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    primaryModal,
    navigationManager: { currentParent },
    breadcrumbManager: { breadcrumbs }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const showCreateRendition = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }
    if (connection.Canned) {
      return false;
    }
    if (currentAppView.type == 'RecycleBin') {
      return false;
    }
    return true;
  }, [readOnly, currentAppView.type, connection.Canned]);

  const createRenditionAction = useMemo(
    (): MenuItemAction => ({
      title: t('CreateRendition.CreateRendition'),
      icon: <SvgScript />,
      onClick: () =>
        primaryModal.open(
          <CreateRenditionModal
            items={items}
            currentParent={currentParent}
            breadcrumbs={breadcrumbs}
          />
        ),
      disabled:
        !showCreateRendition ||
        !allowCreateRenditionsAll(items, allItemsExistInConnection),
      hidden: !showCreateRendition || !ecPluginFeatures.createRenditions()
    }),
    [
      showCreateRendition,
      items,
      allItemsExistInConnection,
      ecPluginFeatures,
      primaryModal,
      currentParent,
      breadcrumbs
    ]
  );

  return createRenditionAction;
}
