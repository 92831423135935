import React, { useCallback, useMemo } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsLatestVersion, itemIsPlaceholder } from '@bentley/pw-api';
import { SvgComputer } from '@itwin/itwinui-icons-react';
import { openInDesktopApp, showDesktopApp } from '../../../../actions/drive';
import { allowItemOpenInWindowsExplorerOrApp } from '../../../../actions/sync';
import {
  useAppViewContext,
  useConnectionAuth,
  useConnectionsContext,
  useFeatureTracking,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { useOutToMeSearch } from '../../../../hooks/useOutToMeSearch';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useOpenItemInDesktopAppAction(items: PWItem[]): MenuItemAction {
  const {
    repositoryManager: { driveSyncedConnections }
  } = useConnectionsContext();

  const { trackFeature } = useFeatureTracking();
  const { userId } = useConnectionAuth();
  const { pwDriveData, contextId, progressManager } = usePluginContext();
  const httpService = useHttpService();
  const graphApiManager = useGraphApiContext();

  const { primaryModal } = useNavigationContext();
  const {
    folderSettingsManager: { folderSettings },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const outToMe = useOutToMeSearch();

  const refreshOutToMe = useCallback((): void => {
    void outToMe();
  }, [outToMe]);

  const item = items[0] as PWDataItem;

  const itemTooltip = useMemo(() => {
    if (
      pwDriveData.isDriveEnabledForConnection &&
      !pwDriveData.pwDriveMetadataEnabled
    ) {
      return t('PWDrive.DriveRequiredForAction');
    }
    if (item && !itemIsLatestVersion(item)) {
      return t('PWDrive.LatestDocVersionRequired');
    }
    if (item && itemIsPlaceholder(item)) {
      return t('PWDrive.DocumentHasNoFile');
    }
    return undefined;
  }, [pwDriveData, item]);

  const showOpenInDesktop = useMemo((): boolean => {
    if (!showDesktopApp(items, pwDriveData)) {
      return false;
    }

    return true;
  }, [items, pwDriveData]);

  const isDriveEnabled = useMemo((): boolean => {
    if (pwDriveData.pwDriveSyncDisabled) {
      return false;
    }

    if (pwDriveData.driveVersionType == 'Obsolete') {
      return false;
    }

    if (!pwDriveData.isDriveEnabledForConnection) {
      return false;
    }

    if (pwDriveData.validDriveUser == 'LogicalUser') {
      return false;
    }

    return true;
  }, [pwDriveData]);

  const openInDesktopEnabled = useMemo((): boolean => {
    return allowItemOpenInWindowsExplorerOrApp(
      items,
      !isDriveEnabled,
      allItemsExistInConnection
    );
  }, [allItemsExistInConnection, isDriveEnabled, items]);

  const openItemInDesktopAppItem = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.OpenInDesktop'),
      icon: <SvgComputer />,
      itemTooltip,
      onClick: () =>
        void openInDesktopApp(
          item,
          httpService,
          refreshCurrentFolder,
          refreshOutToMe,
          contextId ?? '',
          pwDriveData,
          driveSyncedConnections,
          userId,
          !folderSettings?.AutoCheckoutDocsInDrive,
          graphApiManager,
          primaryModal.open,
          primaryModal.close,
          trackFeature,
          progressManager
        ),
      disabled: !showOpenInDesktop || !openInDesktopEnabled,
      hidden: !showOpenInDesktop
    }),
    [
      itemTooltip,
      pwDriveData,
      showOpenInDesktop,
      item,
      httpService,
      refreshCurrentFolder,
      refreshOutToMe,
      contextId,
      openInDesktopEnabled,
      driveSyncedConnections,
      userId,
      folderSettings?.AutoCheckoutDocsInDrive,
      graphApiManager,
      primaryModal.open,
      primaryModal.close,
      trackFeature,
      progressManager
    ]
  );

  return openItemInDesktopAppItem;
}
