import React, { useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { t } from '../../../../services/translation';
import { useCancelWizard } from '../../cancel';
import {
  uploadInProgressState,
  useDocumentCreation,
  useFlatSetCreation
} from '../../create';
import { useCopy } from '../../create/useCopy';
import { generatingDocCodeState, usingDocCodeState } from '../../docCode';
import {
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState,
  useTriggeredPropertyWorkflow
} from '../../form';
import { currentEnvironmentInstanceState } from '../../state';
import { applyToAllState } from '../applyToRemaining';
import { CreatingDocument } from '../creatingDocument';
import { NavigationButtons } from '../navigationButtons';
import {
  currentStepIndexState,
  isCopyModeState,
  isFlatSetCreationModeState
} from '../state';
import { DocCodeSection } from './docCodeSection';
import { docCodeFormValidState } from './state';

export function DocCodeStep(): JSX.Element {
  const { create } = useDocumentCreation();
  const { create: createFlatSet } = useFlatSetCreation();
  const { copyDocuments } = useCopy();
  const { cancelWizard } = useCancelWizard();

  const setCurrentStepIndex = useSetRecoilState(currentStepIndexState);
  const docCodeFormValid = useRecoilValue(docCodeFormValidState);
  const [uploadInProgress, setUploadInProgress] = useRecoilState(
    uploadInProgressState
  );
  const [usingDocCode, setUsingDocCode] = useRecoilState(usingDocCodeState);
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );
  const generatingDocCode = useRecoilValue(generatingDocCodeState);
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const instanceWithoutDocCodeChanges = useRef(currentEnvironmentInstance);

  const isFlatSetCreationMode = useRecoilValue(isFlatSetCreationModeState);
  const isCopyMode = useRecoilValue(isCopyModeState);
  const applyToRemaining = useRecoilValue(applyToAllState);

  const { updateInstanceWithTriggeredDocCodeProperties } =
    useTriggeredPropertyWorkflow();

  const actionButtonTitle = isCopyMode
    ? t('Generic.Copy')
    : t('Generic.Create');

  function allowSkipCode(): boolean {
    if (
      updatingTriggeredProperties ||
      updatingReloadProperties ||
      generatingDocCode
    ) {
      return false;
    }

    if (usingDocCode && docCodeFormValid) {
      return false;
    }

    return true;
  }

  function onSkipCode(): void {
    proceedWithoutDocCode();
    setCurrentStepIndex((cur) => cur + 1);
  }

  function allowNext(): boolean {
    if (isCopyMode && applyToRemaining) {
      return false;
    }

    if (!docCodeFormValid) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (generatingDocCode) {
      return false;
    }

    return true;
  }

  async function onNext(): Promise<void> {
    await proceedWithDocCode();
    setCurrentStepIndex((cur) => cur + 1);
  }

  function allowCreate(): boolean {
    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (generatingDocCode) {
      return false;
    }

    if (!usingDocCode || !docCodeFormValid) {
      return false;
    }

    if (uploadInProgress) {
      return false;
    }
    return true;
  }

  async function onCreate(): Promise<void> {
    if (!usingDocCode) {
      return;
    }

    setUploadInProgress(true);
    const updatedInstance = await proceedWithDocCode();

    if (isFlatSetCreationMode) {
      void createFlatSet(updatedInstance);
    } else if (isCopyMode) {
      void copyDocuments(updatedInstance);
    } else {
      void create(updatedInstance);
    }
  }

  function proceedWithoutDocCode(): void {
    setUsingDocCode(false);
    setCurrentEnvironmentInstance(instanceWithoutDocCodeChanges.current);
  }

  async function proceedWithDocCode(): Promise<WSGInstance | null> {
    setUsingDocCode(true);
    const updatedInstance =
      await updateInstanceWithTriggeredDocCodeProperties();
    return updatedInstance;
  }

  if (uploadInProgress) {
    return <CreatingDocument />;
  }

  return (
    <>
      <DocCodeSection />
      <NavigationButtons
        backButton={{
          children: t('DCW.SkipCode'),
          onClick: onSkipCode,
          disabled: !allowSkipCode()
        }}
        nextButton={{
          children: t('Generic.Next'),
          onClick: () => void onNext(),
          disabled: !allowNext()
        }}
        createButton={{
          children: actionButtonTitle,
          onClick: () => void onCreate(),
          disabled: !allowCreate(),
          styleType: 'cta'
        }}
        cancelButton={{ children: t('Generic.Cancel'), onClick: cancelWizard }}
      />
    </>
  );
}
