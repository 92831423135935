import type {
  PWItem,
  PWProject,
  WSGProperties,
  WSGRelationshipInstance
} from '@bentley/pw-api';

export function setItemWorkArea(
  item: PWItem,
  workArea: PWProject | undefined
): void {
  removeItemWorkArea(item);

  if (!item.relationshipInstances?.length) {
    item.relationshipInstances = [];
  }

  const relatedInstance = {
    schemaName: 'PW_WSG',
    className: 'WorkArea',
    direction: 'forward',
    relatedInstance: workArea
  } as WSGRelationshipInstance;

  item.relationshipInstances.push(relatedInstance);
}

function removeItemWorkArea(item: PWItem): void {
  const relatedInstanceIndex = item.relationshipInstances?.findIndex(
    (instance) => instance.className === 'WorkArea'
  );

  if (relatedInstanceIndex !== undefined && relatedInstanceIndex >= 0) {
    item.relationshipInstances?.splice(relatedInstanceIndex, 1);
  }
}

export function getItemWorkArea(item: PWItem): PWProject | undefined {
  const relatedInstance = item.relationshipInstances?.find(
    (instance) => instance.className === 'WorkArea'
  );

  return relatedInstance?.relatedInstance as PWProject;
}

export function getItemProjectAttributes(
  item: PWItem
): WSGProperties | undefined {
  const workArea = getItemWorkArea(item);

  if (!workArea?.properties?.projectAttributes) {
    return undefined;
  }

  return workArea.properties.projectAttributes as WSGProperties;
}
