import React from 'react';
import ReactDOM from 'react-dom';
import { generateUUID } from '../../services/data';

function mountFileInput(
  handleInput: (file: File) => void
): HTMLInputElement | null {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const container = document.getElementById('file-input-div');

  if (container) {
    ReactDOM.render(
      React.createElement('input', {
        type: 'file',
        ref: fileInputRef,
        id: 'file-input',
        style: { display: 'hidden' },
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event, handleInput);
        },
        key: generateUUID()
      }),
      container
    );
  }

  return fileInputRef.current;
}

function unmountFileInput(): void {
  const container = document.getElementById('file-input-div');
  if (container) {
    ReactDOM.unmountComponentAtNode(container);
  }
}

function onChange(
  event: React.ChangeEvent<HTMLInputElement>,
  handleInput: (file: File) => void
) {
  if (event.target.files && event.target.files.length) {
    const newFile = event.target.files[0];
    handleInput(newFile);
    unmountFileInput();
  }
}

/**
 * Opens browser file input dialog with given callback
 * ***Important!*** Do not run any async code between a user click and this function or else some browsers (MS Teams, cough cough) will not open the dialog programmatically
 * @param {(file: File) => void} handleInput Callback to use after file selected
 * @returns {void} void
 */
export function openFileInput(handleInput: (file: File) => void): void {
  unmountFileInput(); // Old file input may be left over if user cancelled
  mountFileInput(handleInput)?.click();
}
