import * as React from 'react';
export const SvgProjectWiseWebView = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15">
    <polygon points="10.204 1.998 10.327 1.85 8.87 1.414 13.171 0 14 2.879 12.512 2.461 12.471 2.507 9.294 6.593 8.612 6.384 7.175 5.942 7.176 5.94 8.723 3.953 8.707 3.948 10.204 1.998" />
    <polygon points="1.116 5.634 0.283 2.756 4.583 1.342 3.609 2.577 3.764 2.619 8.36 4.062 7.007 5.823 6.965 5.877 6.885 5.852 2.196 4.411 2.125 4.396 1.116 5.634" />
    <polygon points="3.796 10.002 3.671 10.15 5.129 10.586 0.829 12 0 9.121 1.431 9.523 1.497 9.535 4.706 5.407 6.826 6.057 6.808 6.082 5.278 8.047 5.294 8.052 3.796 10.002" />
    <path d="M12,9a4.42471,4.42471,0,0,0-4,2.99432c1.1,2.635,3.75,3.77283,5.95,2.45533A5.062,5.062,0,0,0,16,11.99432,4.42471,4.42471,0,0,0,12,9Zm-.75,1.13782.021.00189a.817.817,0,0,1,.729.89642.8557.8557,0,0,1-.75.89832.913.913,0,0,1,0-1.79663ZM14,13.3717a3.00494,3.00494,0,0,1-3.9,0,3.788,3.788,0,0,1-1.25-1.37738,3.86193,3.86193,0,0,1,1.2-1.43731c.05,0,.05-.05988.1-.05988a2.23648,2.23648,0,0,0-.15.83844A2.2313,2.2313,0,0,0,12,13.731a2.23136,2.23136,0,0,0,2-2.39551c0-.29944-.05-.53888-.05-.77844a3.86127,3.86127,0,0,1,1.2,1.43725A4.70216,4.70216,0,0,1,14,13.3717Z" />
    <path d="M11.9574,8.00092,12,7.99908l.0426.00184c.026.00109.05048.00756.07642.009a4.19926,4.19926,0,0,1,1.28668.15332l-.52063-1.79816-1.01129,1.241-.06915-.01782-3.249-.999L7.03558,6.12311l-.0589.07574L5.64087,7.93768,9.03949,9.00439A5.40986,5.40986,0,0,1,11.9574,8.00092Z" />
  </svg>
);
