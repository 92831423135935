export type BlackListFilter = {
  propertyName: string;
  environmentName?: string;
  filterOutCondition: (className: string) => boolean;
};

export const blacklistedProperties: BlackListFilter[] = [
  { propertyName: 'CreatedById', filterOutCondition: documentFilter },
  { propertyName: 'EnvironmentId', filterOutCondition: documentFilter },
  { propertyName: 'FileUpdatedById', filterOutCondition: documentFilter },
  { propertyName: 'FinalById', filterOutCondition: documentFilter },
  { propertyName: 'FinalBy', filterOutCondition: documentFilter },
  { propertyName: 'RelativeDirectory', filterOutCondition: documentFilter },
  {
    propertyName: 'IsWorkflowStateChangeable',
    filterOutCondition: documentFilter
  },
  {
    propertyName: 'IsStateChangeControlledByWre',
    filterOutCondition: documentFilter
  },
  {
    propertyName: 'IsVersioningControlledByWre',
    filterOutCondition: documentFilter
  },
  { propertyName: 'OutToId', filterOutCondition: documentFilter },
  { propertyName: 'StateId', filterOutCondition: documentFilter },
  { propertyName: 'UpdatedById', filterOutCondition: documentFilter },
  { propertyName: 'WorkflowId', filterOutCondition: documentFilter },
  { propertyName: 'IsWriteable', filterOutCondition: documentFilter },
  { propertyName: 'ParentGuid', filterOutCondition: documentFilter },

  { propertyName: 'Storage', filterOutCondition: documentFilter },
  { propertyName: 'Workflow', filterOutCondition: documentFilter },
  { propertyName: 'State', filterOutCondition: documentFilter },
  { propertyName: 'OwnerId', filterOutCondition: documentFilter },
  { propertyName: 'HasConflicts', filterOutCondition: documentFilter },
  {
    propertyName: 'FileOperationFailedById',
    filterOutCondition: documentFilter
  },
  { propertyName: 'FileOperationFailedBy', filterOutCondition: documentFilter },
  { propertyName: 'FileOperationFailTime', filterOutCondition: documentFilter }
];

function documentFilter(className: string): boolean {
  return className == 'Document';
}
