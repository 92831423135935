import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function signInFailureToast(
  usingIms: boolean,
  tooManyAttempts?: boolean
): void {
  const message = signInErrorText(usingIms, tooManyAttempts);
  openToast({ category: 'negative', content: message });
}

function signInErrorText(usingIms: boolean, tooManyAttempts?: boolean): string {
  if (tooManyAttempts) {
    return t('SignInFailure.TooManyAttempts');
  }

  if (usingIms) {
    return t('SignInFailure.IMSFailed');
  }

  return t('SignInFailure.CheckNameAndPassword');
}
