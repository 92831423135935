import type { FederatedRepository } from '../../services/support';

export function byPrimaryAndName(
  primaryConnectionId: string
): (a: FederatedRepository, b: FederatedRepository) => number {
  function sortFunction(
    a: FederatedRepository,
    b: FederatedRepository
  ): number {
    if (a.Id == primaryConnectionId) {
      return -1;
    }
    if (b.Id == primaryConnectionId) {
      return 1;
    }
    return byName(a, b);
  }

  return sortFunction;
}

export function byName(a: FederatedRepository, b: FederatedRepository): number {
  if (a.Canned && b.Canned) {
    return customStringCompare(
      a.Name.toLocaleLowerCase(),
      b.Name.toLocaleLowerCase()
    );
  }
  if (a.Canned) {
    return -1;
  }
  if (b.Canned) {
    return 1;
  }
  return customStringCompare(
    a.Name.toLocaleLowerCase(),
    b.Name.toLocaleLowerCase()
  );
}

/**
 * Replicating normal string comparison, but with hyphens ordered before spaces
 */
function customStringCompare(a: string, b: string): number {
  let aIndex = 0;
  let bIndex = 0;

  while (aIndex < a.length && bIndex < b.length) {
    const charA = a[aIndex];
    const charB = b[bIndex];

    // Hyphens should go before spaces (opposite of default lex order)
    if (charA === '-' && charB === ' ') {
      return -1;
    } else if (charA === ' ' && charB === '-') {
      return 1;
    }

    // Comparing characters just like normal string comparison
    if (charA < charB) {
      return -1;
    } else if (charA > charB) {
      return 1;
    } else {
      aIndex++;
      bIndex++;
    }
  }

  // Order shorter word first just like normal string comparison
  if (aIndex < a.length) {
    return 1;
  } else if (bIndex < b.length) {
    return -1;
  } else {
    return 0;
  }
}
