import { useRecoilValue } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { usingDocCodeState } from '../docCode';
import { validateEnvironmentProperties } from '../properties/compare';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync
} from '../state';
import {
  applyToAllState,
  useInitializeProperties,
  useWizardFunctionsContext
} from '../wizard';
import { isCopyModeState, itemsToCopyState } from '../wizard/state';
import { getUniqueName } from './naming';

type flatSetCreationFunctions = {
  create: (updatedInstance?: WSGInstance | null) => Promise<void>;
};

export function useFlatSetCreation(): flatSetCreationFunctions {
  const { initializeProperties } = useInitializeProperties();
  const { onSuccess, copy, closeModal } = useWizardFunctionsContext();

  const applyToAll = useRecoilValue(applyToAllState);
  const currentEnvironmentInstance = useRecoilValue(
    currentEnvironmentInstanceState
  );
  const originalEnvironmentInstance = useRecoilValue(
    defaultEnvironmentInstanceStateAsync
  );
  const isCopyMode = useRecoilValue(isCopyModeState);
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const flatSetsToCopy = useRecoilValue(itemsToCopyState);

  const create = async (
    updatedInstance?: WSGInstance | null
  ): Promise<void> => {
    const docProperties = initializeProperties();
    const { name, description } = docProperties;
    let environmentInstance = updatedInstance ?? currentEnvironmentInstance;
    if (
      environmentInstance?.properties &&
      originalEnvironmentInstance?.properties
    ) {
      environmentInstance = {
        ...environmentInstance,
        properties: validateEnvironmentProperties(
          environmentInstance.properties,
          originalEnvironmentInstance.properties,
          usingDocCode
        )
      };
    }

    if (isCopyMode) {
      if (copy && flatSetsToCopy) {
        if (applyToAll) {
          const siblingNames: string[] = [];
          let currentSetName = name;
          for (const flatSet of flatSetsToCopy) {
            currentSetName = getUniqueName(name, siblingNames, 'parentheses');
            const newFlatSet = [
              {
                ...flatSet,
                Name: currentSetName,
                Description: description
              }
            ];
            await copy(newFlatSet);
            void onSuccess('', '', name, description, environmentInstance);
            siblingNames.push(currentSetName);
            closeModal?.();
          }
          return Promise.resolve();
        } else {
          const newFlatSet = [
            {
              ...flatSetsToCopy[0],
              Name: name,
              Description: description
            }
          ];
          await copy(newFlatSet);
        }
      }
    }

    void onSuccess('', '', name, description, environmentInstance);
    return Promise.resolve();
  };

  return { create };
}
