import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { LabeledSelect } from '@itwin/itwinui-react';
import type { FormInterface } from '../../../../actions/formRendering';
import {
  getInterfaces,
  updateDefaultInterface
} from '../../../../actions/formRendering';
import { t } from '../../../../services/translation';
import {
  environmentInterfacesState,
  updatingEnvironmentInterfacesState,
  useFormReloadWorkflow
} from '../../form';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync,
  getDCWHttpService,
  useRecoilValueAsync
} from '../../state';

export function EnvironmentInterfaceSelect(): JSX.Element | null {
  const defaultEnvironmentInstance = useRecoilValueAsync(
    defaultEnvironmentInstanceStateAsync
  );

  const [updatingEnvironmentInterfaces, setUpdatingEnvironmentInterfaces] =
    useRecoilState(updatingEnvironmentInterfacesState);

  const [environmentInterfaces, setEnvironmentInterfaces] = useRecoilState(
    environmentInterfacesState
  );

  const currentEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    currentEnvironmentInstanceState
  );

  const { reloadFormWorkflow } = useFormReloadWorkflow('attributespage');

  const updateEnvironmentInterfaces = useCallback(async (): Promise<void> => {
    const environmentClass = defaultEnvironmentInstance?.className;
    const httpService = getDCWHttpService();

    if (!environmentClass || !httpService) {
      return;
    }

    const environmentInterfaces = await getInterfaces(
      environmentClass,
      httpService
    );

    setEnvironmentInterfaces(environmentInterfaces);
  }, [defaultEnvironmentInstance, setEnvironmentInterfaces]);

  const updateTabUI = useCallback(
    (oldInterface: FormInterface, selectedInterface: FormInterface) => {
      const interfaces = _.cloneDeep(environmentInterfaces);

      const clonedOld = interfaces.find(
        (i) => i.instanceId == oldInterface.instanceId
      );

      const clonedNew = interfaces.find(
        (i) => i.instanceId == selectedInterface.instanceId
      );

      if (clonedOld) {
        clonedOld.IsDefault = false;
      }

      if (clonedNew) {
        clonedNew.IsDefault = true;
      }

      setEnvironmentInterfaces(interfaces);
    },
    [environmentInterfaces, setEnvironmentInterfaces]
  );

  const onInterfaceSelect = useCallback(
    async (selectedName: string): Promise<void> => {
      if (updatingEnvironmentInterfaces) {
        return;
      }

      const selectedInterface = environmentInterfaces.find(
        (i) => i.Name == selectedName
      );

      const oldInterface = environmentInterfaces.find(
        (environment) => environment.IsDefault
      );

      if (!selectedInterface || selectedInterface.IsDefault || !oldInterface) {
        return;
      }

      setUpdatingEnvironmentInterfaces(true);
      updateTabUI(oldInterface, selectedInterface);

      const httpService = getDCWHttpService();

      await updateDefaultInterface(selectedInterface.instanceId, httpService);

      await updateEnvironmentInterfaces();

      if (currentEnvironmentInstance) {
        await reloadFormWorkflow(currentEnvironmentInstance);
      }

      setUpdatingEnvironmentInterfaces(false);
    },
    [
      currentEnvironmentInstance,
      environmentInterfaces,
      reloadFormWorkflow,
      setUpdatingEnvironmentInterfaces,
      updateEnvironmentInterfaces,
      updateTabUI,
      updatingEnvironmentInterfaces
    ]
  );

  function interfaceOptions() {
    return environmentInterfaces
      .map(({ Name }) => ({ value: Name, label: Name }))
      .sort((a, b) =>
        a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase() ? -1 : 1
      );
  }

  function defaultInterfaceValue(): string {
    const defaultInterface = environmentInterfaces.find(
      (environment) => environment.IsDefault
    );

    const defaultName = defaultInterface?.Name ?? '';
    return defaultName;
  }

  useEffect(() => {
    async function initializeInterfaces(): Promise<void> {
      setUpdatingEnvironmentInterfaces(true);
      await updateEnvironmentInterfaces();
      setUpdatingEnvironmentInterfaces(false);
    }

    if (!environmentInterfaces.length) {
      void initializeInterfaces();
    }
  }, [
    environmentInterfaces,
    setUpdatingEnvironmentInterfaces,
    updateEnvironmentInterfaces
  ]);

  if (!environmentInterfaces?.length || environmentInterfaces.length < 2) {
    return null;
  }

  return (
    <LabeledSelect
      label={t('DocumentCreation.Interface')}
      displayStyle="inline"
      options={interfaceOptions()}
      value={defaultInterfaceValue()}
      onChange={(option: string) => void onInterfaceSelect(option)}
      disabled={updatingEnvironmentInterfaces}
      data-testid={'environment-select'}
      wrapperProps={{ style: { marginBottom: '8px', maxWidth: '300px' } }}
    />
  );
}
