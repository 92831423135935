import React from 'react';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';

type DriveDecisionModalProps = {
  modalText: string;
  modalTitle: string;
  optionOneTitle: string;
  optionTwoTitle: string;
  optionOneFunction: () => Promise<void> | void;
  optionTwoFunction: () => Promise<void> | void;
};

export function DriveDecisionModal({
  modalTitle,
  optionOneTitle,
  optionTwoTitle,
  modalText,
  optionOneFunction,
  optionTwoFunction
}: DriveDecisionModalProps): JSX.Element {
  const {
    primaryModal: { close }
  } = useNavigationContext();

  return (
    <PWModal
      title={modalTitle}
      primaryButton={{
        title: optionOneTitle,
        onClick: () => {
          void optionOneFunction();
          close();
        }
      }}
      secondaryButton={{
        title: optionTwoTitle,
        onClick: () => {
          void optionTwoFunction();
          close();
        }
      }}
      onClose={close}
    >
      <Text>{modalText}</Text>
    </PWModal>
  );
}
