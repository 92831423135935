import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgDownload } from '@itwin/itwinui-icons-react';
import { allowDownloadSome, download } from '../../../../actions/download';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { useLatestVersionItems } from '../../../../hooks/useLatestVersionItems';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { useRootFolderSelected } from '../useRootFolderSelected';

export function useDownloadItemAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures, trackFeature } = useFeatureTracking();
  const { progressManager, consumerApp, readOnly } = usePluginContext();
  const httpService = useHttpService();
  const { primaryModal, searchState } = useNavigationContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const rootFolderSelected = useRootFolderSelected(items);
  const filterLatestVersionItems = useLatestVersionItems();

  const useAttributeExchange =
    ecPluginFeatures.attributeExchange() &&
    !readOnly &&
    (launchDarklyFeatures.useAttributeExchange as boolean);

  let searchName = '';
  if (searchState.basicQuery) {
    searchName = searchState.basicQuery;
  } else if (searchState.advancedSearch && searchState.advancedSearch.name) {
    const advancedSearchName = searchState.advancedSearch.name;
    if (advancedSearchName) {
      searchName = advancedSearchName;
    }
  } else if (searchState.savedSearch) {
    searchName = searchState.savedSearch.Name;
  } else if (currentAppView.type === 'OutToMe') {
    searchName = t('Generic.OutToMe');
  }

  const downloadItem = useMemo(
    (): MenuItemAction => ({
      title: t('Download.Download'),
      icon: <SvgDownload />,
      disabled: !allowDownloadSome(items) || (readOnly && rootFolderSelected),
      hidden: currentAppView.type == 'RecycleBin',
      onClick: () =>
        void download(
          items,
          httpService,
          primaryModal.open,
          primaryModal.close,
          progressManager,
          trackFeature,
          consumerApp,
          filterLatestVersionItems,
          searchName,
          useAttributeExchange
        )
    }),
    [
      consumerApp,
      currentAppView.type,
      filterLatestVersionItems,
      httpService,
      items,
      primaryModal.close,
      primaryModal.open,
      progressManager,
      readOnly,
      rootFolderSelected,
      searchName,
      trackFeature,
      useAttributeExchange
    ]
  );

  return downloadItem;
}
