import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { ecPluginFeatures } from '../../useECPluginVersion';
import { getSavedSearchView } from '../data/savedSearchViews';
import { defaultView } from '../defaultView';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function useSavedSearchView(
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const httpService = useHttpService();
  const { connection } = usePluginContext();
  const {
    navigationManager: { currentParent },
    searchState: { savedSearch }
  } = useNavigationContext();

  const [view, setView] = useState<View>(defaultView);
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeView(): Promise<void> {
      setInitialized(false);

      if (!ecPluginFeatures.savedSearchDefaultView()) {
        setInitialized(true);
        return;
      }

      if (currentParent.instanceId == null) {
        setInitialized(true);
        return;
      }

      if (connection.Canned) {
        setInitialized(true);
        return;
      }

      if (!savedSearch) {
        setInitialized(true);
        return;
      }

      if (!currentParent?.instanceId) {
        return;
      }

      try {
        const view = await getSavedSearchView(
          savedSearch.instanceId,
          httpService,
          { abortController }
        );
        if (view && !abortController.signal.aborted) {
          setView(view);
        }
      } catch {
        setView(defaultView);
      } finally {
        setInitialized(true);
      }
    }

    void initializeView();

    return () => {
      abortController.abort();
    };
  }, [connection.Canned, currentParent.instanceId, httpService, savedSearch]);

  const saveView = useCallback((view: View): void => {
    throw new Error('Save saved search view not supported');
  }, []);

  const setViewType = useCallback((): void => {
    setCurrentViewType('SavedSearchView');
  }, [setCurrentViewType]);

  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit saved search view not supported');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete saved search view not supported');
  }, []);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized,
      view: view ?? ({} as View),
      views: [view ?? ({} as View)],
      saveView,
      setView: setViewType,
      editView,
      deleteView
    }),
    [initialized, view, saveView, setViewType, editView, deleteView]
  );

  return viewSetting;
}
