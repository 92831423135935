import type {
  HttpService,
  RequestOptions,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  parseResponseInstances,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import { ecPluginFeatures } from '../../useECPluginVersion';
import { logError } from '../../useErrorLogging';
import type { EnvironmentDefinition } from './environmentDefinition';

export async function getEnvironmentDefinitions(
  connectionProjectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<EnvironmentDefinition[]> {
  if (!ecPluginFeatures.projectResourceMetadata()) {
    return getEnvironmentsFromMetaschema(httpService, httpOptions);
  }

  if (!connectionProjectId) {
    return getEnvironmentsInDatasource(httpService, httpOptions);
  }

  return getEnvironmentsInProject(
    connectionProjectId,
    httpService,
    httpOptions
  );
}

async function getEnvironmentsInProject(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<EnvironmentDefinition[]> {
  const select = 'ProjectResource-forward-EnvironmentDefinition.*';
  const query = `PW_WSG/Project/${projectId}?$select=${select}`;
  const response = await httpService.get(query, httpOptions);

  const environmentDefinitions = await parseProjectEnvironmentDefinitions(
    response
  );
  return environmentDefinitions;
}

async function parseProjectEnvironmentDefinitions(
  response: Response
): Promise<EnvironmentDefinition[]> {
  if (!response.ok) {
    logError('Unable to retrieve environment definitions', { response });
    return [];
  }

  const environmentDefinitions =
    await parseResponseRelationshipInstances<EnvironmentDefinition>(response);

  return environmentDefinitions;
}

async function getEnvironmentsInDatasource(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<EnvironmentDefinition[]> {
  const query = 'PW_WSG/EnvironmentDefinition';
  const response = await httpService.get(query, httpOptions);

  const environmentDefinitions = await parseDatasourceEnvironmentDefinitions(
    response
  );
  return environmentDefinitions;
}

async function parseDatasourceEnvironmentDefinitions(
  response: Response
): Promise<EnvironmentDefinition[]> {
  if (!response.ok) {
    console.error('Unable to retrieve environment definitions');
    return [];
  }

  const environmentDefinitions =
    await parseResponseInstances<EnvironmentDefinition>(response);
  return environmentDefinitions;
}

async function getEnvironmentsFromMetaschema(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<EnvironmentDefinition[]> {
  const query =
    "metaschema/ecclassdef?$filter=SchemaHasClass-backward-ECSchemaDef.$id eq 'N~3APW_WSG_Dynamic.01.02'";
  const response = await httpService.get(query, httpOptions);

  const environmentDefinitions = await parseMetaschemaEnvironmentDefinitions(
    response
  );
  return environmentDefinitions;
}

async function parseMetaschemaEnvironmentDefinitions(
  response: Response
): Promise<EnvironmentDefinition[]> {
  if (!response.ok) {
    logError('Unable to retrieve environment definitions', { response });
    return [];
  }

  const data = (await response.json()) as WSGInstancesResponse;
  const environmentDefinitions = data.instances
    .filter(isEnvironmentClass)
    .map(ecClassDefToEnvironmentDefinition);

  return environmentDefinitions;
}

function isEnvironmentClass(instance: WSGInstance): boolean {
  const baseClasses = (instance.properties?.BaseClasses ?? []) as string[];
  return baseClasses.includes('PW_WSG:Environment');
}

function ecClassDefToEnvironmentDefinition(
  classDef: WSGInstance
): EnvironmentDefinition {
  const instanceId = classDef.instanceId.split('~3AEnv_')[1].split('_')[0];
  const name = String(classDef.properties?.Name)?.split(/Env_\d+_/)[1];
  return {
    className: 'EnvironmentDefinition',
    instanceId: instanceId,
    Name: classDef.properties?.DisplayLabel || name,
    Description: classDef.properties?.Description
  } as EnvironmentDefinition;
}
