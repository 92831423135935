import type { HttpService, RequestOptions } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import { useHttpService } from '../../../context';
import type { WebConnection } from '../../../services/webConnections/webConnections';

type ContextInfo = {
  buddiRegionCode: string;
  contextId: string;
};

export function useConnections({
  buddiRegionCode,
  contextId
}: ContextInfo): (
  httpOptions?: RequestOptions
) => Promise<WebConnection[] | undefined> {
  const httpService = useHttpService();
  const connectionsUrl = useBuddi(
    'ProjectWiseWebConnections.URL',
    buddiRegionCode
  );

  async function initializeConnections(
    httpOptions?: RequestOptions
  ): Promise<WebConnection[] | undefined> {
    if (!httpService || !connectionsUrl) {
      return undefined;
    }

    const connections = await fetchConnections(
      contextId,
      connectionsUrl,
      httpService,
      httpOptions
    );
    return connections;
  }

  return initializeConnections;
}

async function fetchConnections(
  contextId: string,
  connectionsUrl: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<WebConnection[]> {
  const connections = [] as WebConnection[];
  let continuationToken = null as string | null;

  do {
    httpOptions = addContinuationTokenToHeaders(httpOptions, continuationToken);
    const response = await httpService.get(
      `${connectionsUrl}/api/v1/context/${contextId}/connections`,
      { ...httpOptions, uncached: true }
    );
    const data = await parseConnectionsResponse(response);
    connections.push(...data);

    continuationToken = response.headers.get('ContinuationToken');
  } while (continuationToken);

  return connections;
}

function addContinuationTokenToHeaders(
  httpOptions: RequestOptions | undefined,
  continuationToken: string | null
): RequestOptions | undefined {
  if (!continuationToken) {
    return httpOptions;
  }

  if (!httpOptions) {
    return { headers: { ContinuationToken: continuationToken } };
  }

  return {
    headers: {
      ContinuationToken: continuationToken,
      ...httpOptions?.headers
    } as HeadersInit,
    ...httpOptions
  };
}

async function parseConnectionsResponse(
  response: Response
): Promise<WebConnection[]> {
  if (!response.ok || response.status == 404) {
    return [];
  }

  const connections = (await response.json()) as WebConnection[];
  return connections;
}
