/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import type { HttpService } from '@bentley/pw-api';

export function getDCWHttpService(): HttpService {
  const dcwHttpService = tryGetDCWHttpService();
  if (!dcwHttpService) {
    throw new Error(
      'Http service has not been configured. Use setDCWHttpService'
    );
  }

  return dcwHttpService;
}

export function tryGetDCWHttpService(): HttpService | null {
  const dcwHttpService = (window as any).dcwHttpService;
  if (!dcwHttpService) {
    return null;
  }

  return dcwHttpService as HttpService;
}

export function setDCWHttpService(httpService: HttpService): void {
  (window as any).dcwHttpService = httpService;
}
