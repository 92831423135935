import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  itemFileExtension,
  itemIsParentType,
  itemIsPlaceholder,
  parseFileExtension
} from '@bentley/pw-api';
import { t } from '../../services/translation';
import type { RenameForm } from './renameModal';

const ERROR_ACCESS_FORBIDDEN = t('Rename.UnauthorizedAccess');
const ERROR_NETWORKISSUE = t('Rename.NetworkError');
const ERROR_UNKNOWN = t('Generic.UnknownError');
const ERROR_UNAUTHORIZED_ACCESS = t('Rename.UnauthorizedAccess');

/**
 * Returns boolean to indicate whether File Name input should be displayed in the rename modal
 * @param {PWItem} item Item to be renamed
 */
export function displayFileNameEdit(item: PWItem): boolean {
  if (itemIsParentType(item)) {
    return false;
  }

  if (itemIsPlaceholder(item)) {
    return false;
  }

  return true;
}

/**
 * Returns boolean for whether extension for a file has been changed or not
 * @param {RenameForm} dataToSubmit Input form to submit
 * @param {PWDataItem | null} item Original item
 */
export function checkFileExtensionChange(
  dataToSubmit: RenameForm,
  item: PWDataItem | PWFileType
): boolean {
  const initialFileExtension: string = itemFileExtension(item);
  const changedFileExtension: string = parseFileExtension(
    dataToSubmit.fileName || ''
  );
  if (initialFileExtension.trim() !== changedFileExtension.trim()) {
    return true;
  }
  return false;
}

/**
 * Makes a network request to check if a user is allowed to change the file name and returns the consequent boolean
 * @param {HttpService} httpService Configured http service
 */
export async function fileNameChangeAllowed(
  httpService: HttpService
): Promise<boolean> {
  const query = `MetaSchema/ECPropertyDef?$filter=Name+eq+'FileName'+and+ClassHasProperty-backward-ECClassDef.Name+eq+'Document'&$select=CustomAttributeContainerHasCustomAttribute-forward-DataSourceSpecification.LockedProperty.*`;

  try {
    const response = await httpService.get(query);

    if (response.status === 401) {
      throw new Error(ERROR_UNAUTHORIZED_ACCESS);
    }
    if (response.status === 403) {
      throw new Error(ERROR_ACCESS_FORBIDDEN);
    }
    if (!response.ok) {
      throw new Error(ERROR_UNKNOWN);
    }

    const data = (await response.json()) as WSGInstancesResponse;

    if (data.instances[0].relationshipInstances?.length) {
      return false;
    }

    return true;
  } catch (error) {
    throw new Error(ERROR_NETWORKISSUE);
  }
}
