export type CachedTokenFunctions = {
  get: () => Promise<string>;
};

export function cachedToken(
  getToken: () => Promise<string>,
  expireMinutes: number
): CachedTokenFunctions {
  function getTokenRefreshTime(expireMinutes: number): Date {
    const time = new Date();
    time.setMinutes(time.getMinutes() + expireMinutes);
    return time;
  }

  let token = '';
  let tokenRefreshTime: Date = new Date();

  async function get(): Promise<string> {
    if (tokenRefreshTime > new Date() && token) {
      return token;
    }

    token = await getToken();
    tokenRefreshTime = getTokenRefreshTime(expireMinutes);
    return token;
  }

  return { get };
}
