export function byStringOrNumber(
  a: string | number,
  b: string | number
): number {
  const numA = parseFloat(String(a));
  const numB = parseFloat(String(b));

  if (isNaN(numA) && !isNaN(numB)) {
    return 1;
  }

  if (!isNaN(numA) && isNaN(numB)) {
    return -1;
  }

  if (isNaN(numA) && isNaN(numB)) {
    return stringSort(String(a), String(b));
  }

  return numA - numB;
}

function stringSort(a: string, b: string): number {
  const valA = String(a).toLocaleLowerCase();
  const valB = String(b).toLocaleLowerCase();

  return valA > valB ? 1 : -1;
}

type CompareFunction<T> = (a: T, b: T) => number;

/**
 * Sorts by first sort provided; if same, proceeds to next sort until all sorts are exhausted
 * @param a Item to compare
 * @param b Item to compare
 * @param sortFunction Required sort function
 * @param rest Optional additional sort functions to apply in order
 * @returns Sort function to sort items by cascading sort functions
 */
export function byCascadingSort<T>(
  a: T,
  b: T,
  sortFunction: CompareFunction<T>,
  ...rest: CompareFunction<T>[]
): number {
  const primarySortValue = sortFunction(a, b);

  if (primarySortValue != 0 || !rest.length) {
    return primarySortValue;
  }

  return byCascadingSort(a, b, rest[0], ...rest.slice(1));
}
