import { useCallback } from 'react';
import { useEnvironmentContext } from '../../../context';

type LatestVersionFilterStringFunction = () => string;

export function useLatestVersionFilterString(): LatestVersionFilterStringFunction {
  const {
    versions: { displayLatestVersion }
  } = useEnvironmentContext();

  const latestVersionFilterString = useCallback((): string => {
    if (!displayLatestVersion) {
      return '';
    }

    return ' and IsLatest eq true';
  }, [displayLatestVersion]);

  return latestVersionFilterString;
}
