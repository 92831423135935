import type { MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RequestOptions } from '@bentley/pw-api';
import { useHttpService, usePluginContext } from '../../context';
import type { ECPluginVersion } from '../useECPluginVersion';
import type { Attribute } from './environmentAttributes/attributeDefinitions';
import { useEnvironmentAttributeDefinitions } from './environmentAttributes/useEnvironmentAttributeDefinitions';
import type { EnvironmentDefinition } from './environmentDefinitions/environmentDefinition';
import { getEnvironmentDefinitions } from './environmentDefinitions/environmentDefinitions';
import { byAllowedAppEnvironment } from './environmentDefinitions/environmentFilter';
import { useProjectPropertyDefinitions } from './projectProperties/useProjectPropertyDefinitions';

export type EnvironmentManager = {
  attributeDefinitions: Record<string, Attribute[]>;
  environmentDefinitions?: EnvironmentDefinition[];
  invalidDocCodeEnvironments: MutableRefObject<Set<string>>;
  projectPropertyDefinitions: Record<string, Attribute[]>;
  getEnvironmentDefinitionByName: (
    name?: string
  ) => EnvironmentDefinition | undefined;
  getEnvironmentProperty: (
    environment: string,
    property: string
  ) => Attribute | undefined;
  loadAllEnvironmentProperties: () => Promise<void>;
};

export function useEnvironments(
  ecPluginVersion: ECPluginVersion
): EnvironmentManager {
  const { connection, consumerApp, logError } = usePluginContext();
  const httpService = useHttpService();

  const {
    attributeDefinitions,
    getAttributeDefinition,
    loadAttributeDefinitions
  } = useEnvironmentAttributeDefinitions();
  const { projectPropertyDefinitions, loadProjectPropertyDefinitions } =
    useProjectPropertyDefinitions();

  const [environmentDefinitions, setEnvironmentDefinitions] =
    useState<EnvironmentDefinition[]>();

  const invalidDocCodeEnvironments = useRef(new Set<string>());

  const getEnvironmentDefinitionByName = useCallback(
    (environmentName?: string): EnvironmentDefinition | undefined => {
      if (!environmentName) {
        return undefined;
      }
      return environmentDefinitions?.find(
        (environment) => environment.Name == environmentName
      );
    },
    [environmentDefinitions]
  );

  const getEnvironmentProperty = useCallback(
    (environment: string, property: string): Attribute | undefined => {
      if (!environmentDefinitions) {
        return undefined;
      }

      const environmentDefinition = getEnvironmentDefinitionByName(environment);
      if (!environmentDefinition) {
        return undefined;
      }

      const environmentProperty = getAttributeDefinition(
        environmentDefinition,
        property
      );
      return environmentProperty;
    },
    [
      environmentDefinitions,
      getAttributeDefinition,
      getEnvironmentDefinitionByName
    ]
  );

  const loadEnvironmentDefinitions = useCallback(
    async (httpOptions?: RequestOptions): Promise<EnvironmentDefinition[]> => {
      const environmentDefinitions = await getEnvironmentDefinitions(
        connection.ProjectId,
        httpService,
        { ...httpOptions, cacheSuffix: 'persistent' }
      );

      const validEnvironmentDefinitions = environmentDefinitions.filter(
        byAllowedAppEnvironment(consumerApp)
      );

      return validEnvironmentDefinitions;
    },
    [connection.ProjectId, consumerApp, httpService]
  );

  /**
   * Loads all attribute definitions for all environments available in work area
   * Loads all project type definitions
   * Function returns once all loaded; if already loaded will return immediately
   * Only useful for places needing full property info,
   * e.g., adv search and customize columns, though these should ideally be
   * re-written to only get attribute definitions for an env when needed
   */
  const loadAllEnvironmentProperties = useCallback(async (): Promise<void> => {
    let environments = environmentDefinitions;
    if (!environments) {
      environments = await loadEnvironmentDefinitions();
    }

    await Promise.all([
      ...environments.map(async (environmentDefinition) => {
        await loadAttributeDefinitions(environmentDefinition);
      }),
      loadProjectPropertyDefinitions()
    ]);
  }, [
    environmentDefinitions,
    loadAttributeDefinitions,
    loadEnvironmentDefinitions,
    loadProjectPropertyDefinitions
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    async function initEnvironmentDefinitions(): Promise<void> {
      try {
        const environmentDefinitions = await loadEnvironmentDefinitions({
          abortController
        });

        if (!abortController.signal.aborted) {
          setEnvironmentDefinitions(environmentDefinitions);
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          logError('Error retrieving environment definitions', { error: e });
          setEnvironmentDefinitions([]);
        }
      }
    }

    if (ecPluginVersion) {
      void initEnvironmentDefinitions();
    }

    return () => {
      abortController.abort();
    };
  }, [ecPluginVersion, loadEnvironmentDefinitions, logError]);

  const environmentManager = useMemo(
    (): EnvironmentManager => ({
      attributeDefinitions,
      environmentDefinitions,
      invalidDocCodeEnvironments,
      projectPropertyDefinitions:
        projectPropertyDefinitions ?? ({} as Record<string, Attribute[]>),
      getEnvironmentDefinitionByName,
      getEnvironmentProperty,
      loadAllEnvironmentProperties
    }),
    [
      attributeDefinitions,
      environmentDefinitions,
      projectPropertyDefinitions,
      getEnvironmentDefinitionByName,
      getEnvironmentProperty,
      loadAllEnvironmentProperties
    ]
  );

  return environmentManager;
}
