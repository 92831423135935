import { useCallback, useMemo, useRef, useState } from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { useHttpService, usePluginContext } from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { getItemAncestors } from '../../services/data';

type KnownParents = {
  knownWorkAreaParents: PWProject[];
  addKnownWorkAreaParents: (newParents: PWProject[]) => void;
  queriedParentIds: string[];
  queryAndCacheAncestors: (item: PWItem) => Promise<void>;
};

export function useQueriedParents(): KnownParents {
  const httpService = useHttpService();
  const { connection } = usePluginContext();

  const [knownWorkAreaParents, setKnownWorkAreaParents] = useState<PWProject[]>(
    []
  );
  const queriedParentIds = useRef<string[]>([]);
  const queryingParentIds = useRef<string[]>([]);

  const addKnownWorkAreaParents = useCallback(
    (newParents: PWProject[]): void => {
      setKnownWorkAreaParents((knownParents) => {
        const uniqueNewParents = newParents.filter(
          (newParent) =>
            !knownParents.some(
              (knownParent) => knownParent.instanceId == newParent.instanceId
            )
        );
        return [...knownParents, ...uniqueNewParents];
      });
    },
    []
  );

  const saveUniqueQueriedParents = useCallback((parents: PWProject[]): void => {
    const parentIds = parents.map((parent) => parent.instanceId);
    const uniqueParents = parentIds.filter(
      (parentId) => !queriedParentIds.current.includes(parentId)
    );
    queriedParentIds.current.push(...uniqueParents);
  }, []);

  const queryAndCacheAncestors = useCallback(
    async (item: PWItem): Promise<void> => {
      const parentId = item.ParentGuid;

      if (
        !parentId ||
        queriedParentIds.current.includes(parentId) ||
        queryingParentIds.current.includes(parentId)
      ) {
        return;
      }

      queryingParentIds.current.push(parentId);

      const itemAncestors = await usingConcurrencyLimiter(
        () => getItemAncestors(item, connection.ProjectId, httpService),
        'background'
      );

      saveUniqueQueriedParents(itemAncestors);
      if (itemAncestors[0].instanceId == connection.ProjectId) {
        addKnownWorkAreaParents(itemAncestors);
      }
    },
    [
      addKnownWorkAreaParents,
      connection.ProjectId,
      httpService,
      saveUniqueQueriedParents
    ]
  );

  const knownParents = useMemo(
    (): KnownParents => ({
      knownWorkAreaParents,
      addKnownWorkAreaParents,
      queriedParentIds: queriedParentIds.current,
      queryAndCacheAncestors
    }),
    [addKnownWorkAreaParents, knownWorkAreaParents, queryAndCacheAncestors]
  );

  return knownParents;
}
