import type { User } from 'oidc-client';

export function isValidSamlUser(samlUser: User | null): samlUser is User {
  if (!samlUser?.access_token) {
    return false;
  }

  if (samlUser.expired) {
    return false;
  }

  if (!samlUser.access_token.startsWith('PHN')) {
    return false;
  }

  return true;
}
