import { useEffect, useMemo, useState } from 'react';
import { featureMapValues } from './featureMapValues';
import { buildFeatureToggleClient } from './featureToggleClient';
/**
 * Initializes Bentley feature toggle client and returns feature flags.
 * @param featureSettings Feature settings
 * @param featureSettings.getSamlToken Async function to get SAML token
 * @param featureSettings.buddiRegionCode Region code, if not using feature toggle key
 * @param featureSettings.dataAccessLevel Optional data access level, if not using feature toggle key
 * @param featureSettings.featureToggleKey Feature toggle client key, if not using region code
 * @returns Object containing feature flags, loading state, and error
 */
export function useFeatures({ getSamlToken, buddiRegionCode, dataAccessLevel, featureToggleKey }) {
    const [features, setFeatures] = useState();
    const [error, setError] = useState();
    useEffect(() => {
        if (!buddiRegionCode && !featureToggleKey) {
            setError(new Error('Missing feature toggle key.'));
            return;
        }
        const abortController = new AbortController();
        async function initializeClient() {
            try {
                const clientSideId = featureToggleKey !== null && featureToggleKey !== void 0 ? featureToggleKey : lookupLDKey(buddiRegionCode, dataAccessLevel);
                const client = await buildFeatureToggleClient(getSamlToken, clientSideId);
                const features = featureMapValues(client);
                if (!abortController.signal.aborted) {
                    setFeatures(features);
                }
            }
            catch (error) {
                setError(error);
            }
        }
        if (!features) {
            void initializeClient();
        }
        return () => {
            abortController.abort();
        };
    }, [
        buddiRegionCode,
        dataAccessLevel,
        featureToggleKey,
        getSamlToken,
        setFeatures,
        features
    ]);
    const featureFlagManager = useMemo(() => ({
        features: features,
        isLoading: !features,
        error: error
    }), [error, features]);
    return featureFlagManager;
}
// Hardcoded PW Web LD keys so npm package can find the right flags without config
function lookupLDKey(regionCode, dataAccessLevel) {
    if (dataAccessLevel == 'Datasource') {
        return getWebViewLDKey(regionCode);
    }
    return getPWWebLDKey(regionCode);
}
function getPWWebLDKey(regionCode) {
    switch (regionCode) {
        case '103':
            return '5e45a4f0b5d1b007df8f4829';
        case '102':
            return '5e45a4f19ca94d07b8d5c9b7';
        default:
            return '5e45a4f0c38c4907f342b868';
    }
}
function getWebViewLDKey(regionCode) {
    switch (regionCode) {
        case '103':
            return '5d5ecc80978a2f085f0ac80e';
        case '102':
            return '5d5ecc812916f808bb92cbac';
        default:
            return '5d5ecc80458032086328373c';
    }
}
