import './toolbarButton.css';

import React from 'react';
import { IconButton } from '@itwin/itwinui-react';
import type { MenuItemAction } from '../MenuItemAction';
import { ToolbarButtonWithChildren } from './toolbarButtonWithChildren';

type ToolbarButtonProps = {
  action: MenuItemAction;
};

export function ToolbarButton({
  action: {
    groupLabel,
    icon,
    itemTooltip,
    startGroup,
    submenuItems,
    ...iconButtonProps
  }
}: ToolbarButtonProps): JSX.Element | null {
  if (submenuItems?.length) {
    return (
      <ToolbarButtonWithChildren
        childItems={submenuItems}
        icon={icon}
        iconButtonProps={iconButtonProps}
      />
    );
  }

  const { title, ...rest } = iconButtonProps;

  return (
    <IconButton {...rest} label={itemTooltip || title}>
      {icon}
    </IconButton>
  );
}
