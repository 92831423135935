import type { ECPluginVersion } from './ecPluginVersion';

export const pluginVersionStorageKey = 'ECPluginVersion';

export function storedECPluginVersion(): ECPluginVersion | null {
  const ecPluginVersion = stripQuotes(
    sessionStorage.getItem(pluginVersionStorageKey)
  );

  if (ecPluginVersion == 'null' || ecPluginVersion == null) {
    return null;
  }
  return Number(ecPluginVersion) as ECPluginVersion;
}

function stripQuotes(value: string | null): string | null {
  if (!value) {
    return value;
  }

  return value.replace(/"/g, '');
}
