import { useCallback, useMemo } from 'react';
import type { PWItem, RequestOptions } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import { useEnvironmentContext, usePropertyContext } from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { useEnvironmentAttributes } from '../../services/itemProperties';
import type { SecondaryRequest } from '.';

export function usePWSecondaryData(): SecondaryRequest<PWItem>[] {
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    viewManager: {
      currentView: { columns }
    }
  } = usePropertyContext();

  const {
    attachEnvironmentAttributesToItemsBatched,
    attachWorkAreaAttributesToItems
  } = useEnvironmentAttributes();

  const addWorkAreaAttributesToItems = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      if (!columns?.some((col) => col.isProjectEnv)) {
        return;
      }

      await usingConcurrencyLimiter(async () => {
        await attachWorkAreaAttributesToItems(items, httpOptions);
      }, 'background');
    },
    [columns, attachWorkAreaAttributesToItems]
  );

  const addEnvironmentDataToItems = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      if (ecPluginFeatures.environmentQueryPerformance()) {
        return;
      }

      const hasDocumentEnvironmentColumn = columns.some(
        (column) =>
          !column.isProjectEnv &&
          column.propertyType != 'General' &&
          column.propertyType != 'Folder'
      );

      if (!hasDocumentEnvironmentColumn) {
        return;
      }

      await usingConcurrencyLimiter(async () => {
        await attachEnvironmentAttributesToItemsBatched(
          filterDataItems(items),
          httpOptions
        );
      }, 'background');
    },
    [attachEnvironmentAttributesToItemsBatched, columns, ecPluginFeatures]
  );

  const secondaryRequests = useMemo(() => {
    const requests = [];
    if (columns?.some((col) => col.isProjectEnv)) {
      requests.push(addWorkAreaAttributesToItems);
    }

    if (
      !ecPluginFeatures.environmentQueryPerformance() &&
      columns.some(
        (column) =>
          !column.isProjectEnv &&
          column.propertyType != 'General' &&
          column.propertyType != 'Folder'
      )
    ) {
      requests.push(addEnvironmentDataToItems);
    }

    return requests;
  }, [
    columns,
    ecPluginFeatures,
    addWorkAreaAttributesToItems,
    addEnvironmentDataToItems
  ]);

  return secondaryRequests;
}
