import type { ToastHandle } from '../../services/pwToast';
import { replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function uploadStartedToast(toastHandle: ToastHandle): ToastHandle {
  const content = t('Upload.Notification.UploadInProgress');

  return replaceToast(toastHandle, {
    content,
    type: 'persisting',
    spinner: true
  });
}

export function uploadRestartedToast(
  toastHandle: ToastHandle,
  uploadedCount: number,
  totalCount: number
): ToastHandle {
  const content = t('Upload.Notification.UploadProgress', {
    uploadedCount,
    totalCount
  });

  return replaceToast(toastHandle, {
    content,
    type: 'persisting',
    spinner: true
  });
}

export function uploadFinishedToast(
  toastHandle: ToastHandle,
  count: number
): ToastHandle {
  const content = t('Upload.Notification.UploadProgress', {
    uploadedCount: count,
    totalCount: count
  });

  return replaceToast(toastHandle, { content, category: 'positive' });
}
