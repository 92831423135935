import { useEffect, useState } from 'react';
import type { PWParentType, PWProject } from '@bentley/pw-api';
import { getProject } from '@bentley/pw-api';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { getItemAncestors } from '../../../services/data';
import { addConnectionBreadcrumb } from '../retrieveBreadcrumbs';

export function useAdvancedSearchBreadcrumbs(): PWParentType[] {
  const { connection } = usePluginContext();
  const httpService = useHttpService();
  const {
    breadcrumbManager: { connectionBreadcrumb },
    searchState: { advancedSearch }
  } = useNavigationContext();

  const [breadcrumbs, setBreadcrumbs] = useState<PWParentType[]>([]);

  useEffect(() => {
    function parseSearchBreadcrumbs(ancestors: PWProject[]): PWParentType[] {
      if (!advancedSearch?.ancestor) {
        return [connectionBreadcrumb];
      }

      const breadcrumbs = ancestors;

      addConnectionBreadcrumb(breadcrumbs, connection, connectionBreadcrumb);
      return breadcrumbs;
    }

    async function retrieveSearchParents(): Promise<void> {
      if (!advancedSearch?.ancestor) {
        return;
      }

      if (advancedSearch.ancestor.instanceId == null) {
        setBreadcrumbs([connectionBreadcrumb]);
        return;
      }

      const ancestor = await getProject({
        instanceId: advancedSearch.ancestor.instanceId,
        httpService
      });
      advancedSearch.ancestor = { ...ancestor, ...advancedSearch.ancestor };

      const ancestors = await getItemAncestors(
        { ParentGuid: advancedSearch.ancestor.instanceId } as PWProject,
        connectionBreadcrumb.instanceId,
        httpService
      );

      const searchBreadcrumbs = parseSearchBreadcrumbs(ancestors);

      setBreadcrumbs(searchBreadcrumbs);
    }

    void retrieveSearchParents();
  }, [connectionBreadcrumb, httpService, connection, advancedSearch]);

  return breadcrumbs;
}
