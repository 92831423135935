import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import {
  SvgChevronLeft,
  SvgChevronLeftDouble,
  SvgChevronRight,
  SvgChevronRightDouble
} from '@itwin/itwinui-icons-react';
import { IconButton, TransferList } from '@itwin/itwinui-react';
import { t } from '../../../services/translation';
import type { TransferData } from './columnSelection';

type TransferToolbarProps<T> = {
  leftColumn: T[];
  rightColumn: T[];
  leftFilter?: (item: T) => boolean;
  rightFilter?: (item: T) => boolean;
  setLeftColumn: Dispatch<SetStateAction<T[]>>;
  setRightColumn: Dispatch<SetStateAction<T[]>>;
};

export function TransferToolbar<T extends TransferData>({
  leftColumn,
  rightColumn,
  leftFilter,
  rightFilter,
  setLeftColumn,
  setRightColumn
}: TransferToolbarProps<T>): JSX.Element {
  function transferAllRightEnabled(): boolean {
    const validItems = leftFilter ? leftColumn.filter(leftFilter) : leftColumn;

    return validItems.length > 0;
  }

  function transferAllRight(): void {
    const newItems = leftFilter ? leftColumn.filter(leftFilter) : leftColumn;

    setRightColumn((oldRightColumn) => {
      const newRightColumn = [...oldRightColumn, ...newItems];
      return newRightColumn.map(clearActiveStatus);
    });

    setLeftColumn(leftColumn.filter((item) => !newItems.includes(item)));
  }

  function transferActiveRight(): void {
    setRightColumn((oldRightColumn) => {
      const newRightColumn = [
        ...oldRightColumn,
        ...leftColumn.filter((item) => item.active)
      ];

      return newRightColumn.map(clearActiveStatus);
    });

    setLeftColumn([...leftColumn.filter((item) => !item.active)]);
  }

  function transferActiveLeft(): void {
    setLeftColumn((oldLeftColumn) => {
      const newLeftColumn = [
        ...oldLeftColumn,
        ...rightColumn.filter((item) => item.active)
      ];

      return newLeftColumn.map(clearActiveStatus);
    });

    setRightColumn([...rightColumn.filter((item) => !item.active)]);
  }

  function transferAllLeftEnabled(): boolean {
    const validItems = rightFilter
      ? rightColumn.filter(rightFilter)
      : rightColumn;

    return validItems.length > 0;
  }

  function transferAllLeft(): void {
    const newItems = rightFilter
      ? rightColumn.filter(rightFilter)
      : rightColumn;

    setLeftColumn((oldLeftColumn) => {
      const newLeftColumn = [...oldLeftColumn, ...newItems];
      return newLeftColumn.map(clearActiveStatus);
    });

    setRightColumn(rightColumn.filter((item) => !newItems.includes(item)));
  }

  function clearActiveStatus(item: T): T {
    return { ...item, active: false };
  }

  return (
    <TransferList.Toolbar className="column-toolbar">
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveAllRight')}
        onClick={transferAllRight}
        disabled={!transferAllRightEnabled()}
        labelProps={{ placement: 'right' }}
        data-testid="moveAllRight"
      >
        <SvgChevronRightDouble />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveRight')}
        onClick={transferActiveRight}
        disabled={!leftColumn.filter((item) => item.active).length}
        labelProps={{ placement: 'right' }}
        data-testid="moveRight"
      >
        <SvgChevronRight />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveLeft')}
        onClick={transferActiveLeft}
        disabled={!rightColumn.filter((item) => item.active).length}
        labelProps={{ placement: 'left' }}
        data-testid="moveLeft"
      >
        <SvgChevronLeft />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveAllLeft')}
        onClick={transferAllLeft}
        disabled={!transferAllLeftEnabled()}
        labelProps={{ placement: 'left' }}
        data-testid="moveAllLeft"
      >
        <SvgChevronLeftDouble />
      </IconButton>
    </TransferList.Toolbar>
  );
}
