import React, { useMemo } from 'react';
import type { PWDataItem, PWFileType } from '@bentley/pw-api';
import { itemIsDataItem, itemIsProject } from '@bentley/pw-api';
import { ExpandableBlock, InformationPanelContent } from '@itwin/itwinui-react';
import { useEnvironmentContext, useTableItemContext } from '../../../context';
import { useSessionStorage } from '../../../hooks/useStorage';
import {
  getItemLockedTo,
  getItemStatus
} from '../../../services/itemProperties';
import {
  formatDateTime,
  formatFileSize
} from '../../../services/stringFormatter';
import { t } from '../../../services/translation';
import { PWInfoRow } from './pwInfoRow';
import { useFetchInfoPanelProperties } from './useFetchInfoPanelProperties';

type ExpandedBlocks = {
  general?: boolean;
  history?: boolean;
  environment?: boolean;
  file?: boolean;
  parent?: boolean;
  children?: boolean;
};

export function PWInfoPanelDetails(): JSX.Element {
  const {
    environmentManager: { getEnvironmentDefinitionByName },
    getWorkflowString
  } = useEnvironmentContext();
  const { actionableItems } = useTableItemContext();

  const { descendantProperties, parent } = useFetchInfoPanelProperties();
  const [expandedBlocks, setExpandedBlocks] = useSessionStorage<ExpandedBlocks>(
    'infoPanelBlocks',
    { general: true }
  );

  const generalProperties = useMemo((): JSX.Element => {
    return (
      <ExpandableBlock
        title={t('CustomColumns.GeneralProperties')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.general}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, general: isExpanding })
        }
        data-testid="general-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow label={t('Generic.Name')} property="Name" />
          <PWInfoRow label={t('Generic.Description')} property="Description" />
          <PWInfoRow<PWDataItem>
            label={t('Generic.Version')}
            property="Version"
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.Status')}
            property={getItemStatus}
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem> label={t('Generic.Owner')} property="Owner" />
          <PWInfoRow<PWDataItem>
            label={t('Generic.CheckedOutTo')}
            property={getItemLockedTo}
            filter={(items) =>
              items.every(itemIsDataItem) && items.every((item) => item.OutTo)
            }
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [expandedBlocks, setExpandedBlocks]);

  const historyProperties = useMemo((): JSX.Element => {
    return (
      <ExpandableBlock
        title={t('InfoPanel.History')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.history}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, history: isExpanding })
        }
        data-testid="history-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow label={t('Generic.CreatedBy')} property="CreatedBy" />
          <PWInfoRow
            label={t('Generic.CreatedOn')}
            property={({ CreateTime }) => formatDateTime(CreateTime)}
          />
          <PWInfoRow label={t('Generic.UpdatedBy')} property="UpdatedBy" />
          <PWInfoRow
            label={t('Generic.UpdatedOn')}
            property={({ UpdateTime }) => formatDateTime(UpdateTime)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.FinalBy')}
            property="FinalBy"
            filter={(items) =>
              items.every(itemIsDataItem) && items.every((item) => item.FinalBy)
            }
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [expandedBlocks, setExpandedBlocks]);

  const environmentProperties = useMemo((): JSX.Element => {
    return (
      <ExpandableBlock
        title={t('Generic.Environment')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.environment}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, environment: isExpanding })
        }
        data-testid="environment-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow
            label={t('Generic.Environment')}
            property={(item) =>
              itemIsProject(item) ? item.Environment : parent?.Environment ?? ''
            }
          />
          <PWInfoRow
            id="Environment description"
            label={t('Generic.Description')}
            property={(item) =>
              getEnvironmentDefinitionByName(
                itemIsProject(item) ? item.Environment : parent?.Environment
              )?.Description ?? ''
            }
          />
          <PWInfoRow
            label={t('Generic.Workflow')}
            property={getWorkflowString}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.State')}
            property={(item) => item.State ?? ''}
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.Sequence')}
            property="Sequence"
            filter={(items) => items.every(itemIsDataItem)}
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [
    expandedBlocks,
    getEnvironmentDefinitionByName,
    getWorkflowString,
    parent?.Environment,
    setExpandedBlocks,
    t
  ]);

  const fileProperties = useMemo((): JSX.Element | null => {
    if (!actionableItems.every(itemIsDataItem)) {
      return null;
    }

    return (
      <ExpandableBlock
        title={t('InfoPanel.FileProperties')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.file}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, file: isExpanding })
        }
        data-testid="file-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow<PWDataItem>
            label={t('Generic.FileName')}
            property="FileName"
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.FileSize')}
            property={({ FileSize }) => formatFileSize(FileSize) ?? '0.00 KB'}
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.FileUpdatedBy')}
            property="FileUpdatedBy"
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.FileUpdatedOn')}
            property={({ FileUpdateTime }) => formatDateTime(FileUpdateTime)}
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWDataItem>
            label={t('Generic.MimeType')}
            property="MimeType"
            filter={(items) => items.every(itemIsDataItem)}
          />
          <PWInfoRow<PWFileType>
            label={t('Generic.Application')}
            property="Application"
            filter={(items) => items.every(itemIsDataItem)}
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [actionableItems, expandedBlocks, setExpandedBlocks]);

  const parentProperties = useMemo((): JSX.Element | null => {
    if (!parent) {
      return null;
    }

    return (
      <ExpandableBlock
        title={t('Generic.ParentFolder')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.parent}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, parent: isExpanding })
        }
        data-testid="parent-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow
            label={t('Generic.ParentFolder')}
            property={(item) => parent?.Name ?? ''}
          />
          <PWInfoRow
            id="Parent folder description"
            label={t('Generic.Description')}
            property={(item) => parent?.Description ?? ''}
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [expandedBlocks, parent, setExpandedBlocks]);

  const childrenProperties = useMemo((): JSX.Element | null => {
    if (!actionableItems.every(itemIsProject) || actionableItems.length != 1) {
      return null;
    }

    return (
      <ExpandableBlock
        title={t('InfoPanel.Contents')}
        size="small"
        styleType="borderless"
        isExpanded={expandedBlocks.children}
        onToggle={(isExpanding) =>
          setExpandedBlocks({ ...expandedBlocks, children: isExpanding })
        }
        data-testid="children-properties"
      >
        <InformationPanelContent displayStyle="inline">
          <PWInfoRow
            label={t('Generic.Folders')}
            property={(item) => descendantProperties?.FolderCount ?? ''}
          />
          <PWInfoRow
            label={t('Generic.Documents')}
            property={(item) => descendantProperties?.DocumentCount ?? ''}
          />
          <PWInfoRow
            label={t('Generic.DiskUsage')}
            property={(item) => descendantProperties?.DiskUsage ?? ''}
          />
        </InformationPanelContent>
      </ExpandableBlock>
    );
  }, [
    actionableItems,
    descendantProperties?.DiskUsage,
    descendantProperties?.DocumentCount,
    descendantProperties?.FolderCount,
    expandedBlocks,
    setExpandedBlocks,
    t
  ]);

  return (
    <>
      {generalProperties}
      {historyProperties}
      {environmentProperties}
      {fileProperties}
      {parentProperties}
      {childrenProperties}
    </>
  );
}
