import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { PWItem, WSGInstance } from '@bentley/pw-api';
import { itemIsDataItem } from '@bentley/pw-api';
import { useEnvironmentContext, useHttpService } from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import type { CurrentUserDocumentAccessRights } from '../../services/permissions';
import { getUserDocumentsPermissions } from '../../services/permissions';

export type DocumentsPermissionsSummary = Pick<
  CurrentUserDocumentAccessRights,
  Exclude<keyof CurrentUserDocumentAccessRights, keyof WSGInstance>
>;

export type SelectedDocumentsPermissions = {
  getMappedDocumentPermissionsById: (
    id: string
  ) => Promise<CurrentUserDocumentAccessRights | undefined>;
  permissionsSummary: DocumentsPermissionsSummary | undefined;
};

export function useSelectedDocumentsPermissions(
  actionableItems: PWItem[]
): SelectedDocumentsPermissions {
  const httpService = useHttpService();

  const { ecPluginFeatures } = useEnvironmentContext();

  const instanceIdPermissionsMap = useRef(
    new Map<string, CurrentUserDocumentAccessRights>()
  );
  const [permissionsSummary, setPermissionsSummary] = useState<
    DocumentsPermissionsSummary | undefined
  >();

  const allSelectedItemsAreDocuments = useCallback(() => {
    return actionableItems.every((item) => itemIsDataItem(item));
  }, [actionableItems]);

  const fetchNewlySelectedItemsPermissions = useCallback(
    async (
      newlySelectedIds: string[]
    ): Promise<CurrentUserDocumentAccessRights[]> => {
      return usingConcurrencyLimiter(
        () => getUserDocumentsPermissions(newlySelectedIds, httpService),
        'background'
      );
    },
    [httpService]
  );

  const summarizePermissions = useCallback(
    (selectedIds: string[]): DocumentsPermissionsSummary | undefined => {
      if (!selectedIds.length) {
        return undefined;
      }

      const summary: DocumentsPermissionsSummary = {
        ChangePermissions: false,
        ChangeWorkflowState: false,
        Delete: false,
        FullControl: false,
        NoAccess: false,
        Read: false,
        Write: false,
        FileRead: false,
        FileWrite: false,
        Free: false
      };

      const selectedPermissions = selectedIds.map(
        (selectedId) =>
          instanceIdPermissionsMap.current.get(
            selectedId
          ) as CurrentUserDocumentAccessRights
      );
      const permissionKeys = Object.keys(summary) as Array<
        keyof typeof summary
      >;

      for (const permissionKey of permissionKeys) {
        const joinedPermissions = selectedPermissions.every(
          (permission) => permission[permissionKey]
        );
        summary[permissionKey] = joinedPermissions;
      }

      return summary;
    },
    []
  );

  const manageSelectedItemsPermissionsSummary = useCallback(async () => {
    if (!allSelectedItemsAreDocuments()) {
      return undefined;
    }
    if (!ecPluginFeatures.userDocumentPermissions()) {
      return undefined;
    }

    const selectedIds = actionableItems.map((item) => item.instanceId);
    const unmappedIds = selectedIds.filter(
      (id) => !instanceIdPermissionsMap.current.has(id)
    );

    if (unmappedIds.length) {
      const unmappedPermissions = await fetchNewlySelectedItemsPermissions(
        unmappedIds
      );

      if (!unmappedPermissions.length) {
        return undefined;
      }

      unmappedPermissions.forEach((unmappedPermission) =>
        instanceIdPermissionsMap.current.set(
          unmappedPermission.instanceId,
          unmappedPermission
        )
      );
    }

    return summarizePermissions(selectedIds);
  }, [
    actionableItems,
    allSelectedItemsAreDocuments,
    ecPluginFeatures,
    fetchNewlySelectedItemsPermissions,
    summarizePermissions
  ]);

  useEffect(() => {
    async function getSelectedItemsPermissionsSummary(): Promise<void> {
      const summary = await manageSelectedItemsPermissionsSummary();
      setPermissionsSummary(summary);
    }

    void getSelectedItemsPermissionsSummary();
  }, [manageSelectedItemsPermissionsSummary]);

  const getMappedDocumentPermissionsById = useCallback(
    async (id: string) => {
      if (!ecPluginFeatures.userDocumentPermissions()) {
        return undefined;
      }

      const mappedPermissions = instanceIdPermissionsMap.current.get(id);
      if (mappedPermissions) {
        return mappedPermissions;
      }

      const newlyFetchedPermissions = await getUserDocumentsPermissions(
        [id],
        httpService
      );
      if (!newlyFetchedPermissions.length) {
        return undefined;
      }

      instanceIdPermissionsMap.current.set(id, newlyFetchedPermissions[0]);
      return newlyFetchedPermissions[0];
    },
    [ecPluginFeatures, httpService]
  );

  const selectedDocumentsPermissions = useMemo(
    () => ({
      getMappedDocumentPermissionsById,
      permissionsSummary
    }),
    [getMappedDocumentPermissionsById, permissionsSummary]
  );

  return selectedDocumentsPermissions;
}
