import { useEffect, useMemo, useState } from 'react';
import { useAppViewContext, useTableItemContext } from '../../context';
import type { MenuItemAction } from '../toolbar';

type SearchInputState = {
  dropdownEnabled: boolean;
  inputEnabled: boolean;
  inputValue: string;
  inSearchState: boolean;
  showDropdown: boolean;
  setInputValue: (value: string) => void;
};

export function useSearchInputState(
  searchActions: MenuItemAction[]
): SearchInputState {
  const {
    appViewManager: { currentAppView },
    workAreaManager
  } = useAppViewContext();
  const { dataManager } = useTableItemContext();

  const [inputValue, setInputValue] = useState<string>('');

  const inSearchState = useMemo((): boolean => {
    if (
      currentAppView.type == 'FolderNavigation' ||
      currentAppView.type == 'OutToMe'
    ) {
      return false;
    }

    return true;
  }, [currentAppView.type]);

  const inputEnabled = useMemo((): boolean => {
    if (!workAreaManager.workAreaAvailable) {
      return false;
    }

    if (dataManager.error) {
      return false;
    }

    if (dataManager.isLoading) {
      return false;
    }

    if (currentAppView.type == 'OutToMe') {
      return false;
    }

    return true;
  }, [
    currentAppView.type,
    dataManager.error,
    dataManager.isLoading,
    workAreaManager.workAreaAvailable
  ]);

  const dropdownEnabled = useMemo((): boolean => {
    if (!workAreaManager.workAreaAvailable) {
      return false;
    }

    if (dataManager.isLoading) {
      return false;
    }

    if (searchActions.every((action) => action.disabled || action.hidden)) {
      return false;
    }
    return true;
  }, [dataManager.isLoading, searchActions, workAreaManager.workAreaAvailable]);

  const showDropdown = useMemo((): boolean => {
    if (!searchActions.length) {
      return false;
    }

    if (searchActions.every((action) => action.hidden)) {
      return false;
    }

    return true;
  }, [searchActions]);

  useEffect(() => {
    if (currentAppView.type != 'BasicSearch') {
      setInputValue('');
    }
  }, [currentAppView.type, setInputValue]);

  const searchInputState = useMemo(
    (): SearchInputState => ({
      dropdownEnabled,
      inputEnabled,
      inputValue,
      inSearchState,
      showDropdown,
      setInputValue
    }),
    [dropdownEnabled, inSearchState, inputEnabled, inputValue, showDropdown]
  );

  return searchInputState;
}
