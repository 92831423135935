import React from 'react';
import { SvgDocument } from '@itwin/itwinui-icons-react';
import { InformationPanelContent, Text } from '@itwin/itwinui-react';
import { t } from '../../../services/translation';

export function PWInfoPanelEmptyState(): JSX.Element {
  return (
    <InformationPanelContent className="pw-info-empty">
      <SvgDocument className="pw-info-empty-icon" />
      <Text isMuted={true}>{t('InfoPanel.SelectFile')}</Text>
    </InformationPanelContent>
  );
}
