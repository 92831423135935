import { useMemo } from 'react';
import type { MenuItemAction } from '../MenuItemAction';
import { formatMenuItems } from '../formatMenuItems';
import { useToolbarsDisabled } from '../useToolbarsDisabled';
import {
  useAddToSetAction,
  useCreateFlatSetAction,
  useNewFolderAction,
  useUploadFilesAction
} from './buttons';

export function useNewActions(): MenuItemAction[] {
  const newFolder = useNewFolderAction();
  const uploadItems = useUploadFilesAction();
  const createFlatSet = useCreateFlatSetAction();
  const addToSet = useAddToSetAction();

  const toolbarDisabled = useToolbarsDisabled();

  const newActions = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems(
        [newFolder, uploadItems, createFlatSet, addToSet],
        toolbarDisabled
      ),
    [addToSet, createFlatSet, newFolder, toolbarDisabled, uploadItems]
  );

  return newActions;
}
