import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import {
  useAppViewContext,
  useNavigationContext,
  usePropertyContext
} from '../../../context';
import type { ColumnDisplayOptions, ColumnInfo } from '../../../hooks/useViews';
import { useFolderPropertyColumn } from './byAccessorType/useFolderPropertyColumn';
import { useGeneralEnvironmentColumn } from './byAccessorType/useGeneralEnvironmentColumn';
import { useGeneralPropertyColumn } from './byAccessorType/useGeneralPropertyColumn';
import { useParentColumn } from './byAccessorType/useParentColumn';
import { useProjectEnvironmentColumn } from './byAccessorType/useProjectEnvironmentColumn';

export function useTableColumns(): Column<PWItem>[] {
  const { viewManager } = usePropertyContext();

  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const generalPropertyColumn = useGeneralPropertyColumn();
  const folderPropertyColumn = useFolderPropertyColumn();
  const projectEnvironmentColumn = useProjectEnvironmentColumn();
  const generalEnvironmentColumn = useGeneralEnvironmentColumn();

  const parentColumn = useParentColumn();

  const getColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions: ColumnDisplayOptions = {}
    ): Column<PWItem> => {
      if (columnInfo.propertyType == 'General') {
        return generalPropertyColumn(columnInfo, displayOptions);
      }

      if (columnInfo.propertyType == 'Folder') {
        return folderPropertyColumn(columnInfo, displayOptions);
      }

      if (columnInfo.isProjectEnv) {
        return projectEnvironmentColumn(columnInfo, displayOptions);
      }

      return generalEnvironmentColumn(columnInfo, displayOptions);
    },
    [
      folderPropertyColumn,
      generalEnvironmentColumn,
      generalPropertyColumn,
      projectEnvironmentColumn
    ]
  );

  const showParentColumn = useCallback((): boolean => {
    if (itemIsFlatSet(currentParent)) {
      return true;
    }

    if (
      ['BasicSearch', 'AdvancedSearch', 'SavedSearch', 'OutToMe'].includes(
        currentAppView.type
      )
    ) {
      return true;
    }

    return false;
  }, [currentAppView.type, currentParent]);

  const insertParentColumn = useCallback(
    (columns: Column<PWItem>[]): void => {
      if (!showParentColumn()) {
        return;
      }

      columns.splice(1, 0, parentColumn);
    },
    [parentColumn, showParentColumn]
  );

  const columns = useMemo((): Column<PWItem>[] => {
    if (!viewManager.initialized) {
      return [];
    }

    const viewColumns = viewManager.currentView.columns.map((column, index) =>
      getColumn(column, {
        isPrimary: index == 0,
        displayIcon: index == 0,
        isLast: index == viewManager.currentView.columns.length - 1
      })
    );

    insertParentColumn(viewColumns);

    return viewColumns;
  }, [
    getColumn,
    insertParentColumn,
    viewManager.initialized,
    viewManager.currentView.columns
  ]);

  return columns;
}
