import { useCallback, useMemo } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import { productionEnvironment } from '../../services/environment';
import type { GetToken } from '../../services/http';
import type { Feature, FeatureName } from './features';
import { features } from './features';

type UserData = { key: string; value: string }[];
export type TrackFeature = (feature: FeatureName, userData?: UserData) => void;

type FeaturePost = {
  UserData?: UserData;
  ProjectId?: string;
} & Feature;

export function useTrackFeature(
  buddiRegion: string,
  getOidcToken: GetToken,
  contextId?: string
): TrackFeature {
  const apiUrl = contextId ? 'ProjectWiseWAC.URL' : 'ProjectWiseWebView.URL';
  const pwHost = useBuddi(apiUrl, buddiRegion);

  const httpService = useMemo((): HttpService | undefined => {
    if (!pwHost) {
      return undefined;
    }

    const httpService = new HttpService({
      authorization: new AuthorizationService({ getOidcToken }),
      baseUrl: pwHost
    });
    return httpService;
  }, [getOidcToken, pwHost]);

  const trackFeature = useCallback(
    (featureName: FeatureName, userData: UserData = []) => {
      if (!httpService || productionEnvironment() == 'localhost') {
        return;
      }

      const feature = features[featureName];
      userData.push({ key: 'Location', value: location.hostname });

      const featurePost = {
        ...feature,
        UserData: userData,
        ProjectId: contextId
      } as FeaturePost;

      void httpService.post('api/trackfeature', JSON.stringify(featurePost));
    },
    [httpService, contextId]
  );

  return trackFeature;
}
