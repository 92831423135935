import React, { useEffect, useMemo, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { pw as DocumentPicker } from '@bentley/pwnxt-document-api';
import { DocumentApi } from '@bentley/pwnxt-document-api';
import type {
  DocumentLink,
  DocumentPickerModalProps,
  DocumentPickerMode,
  SavedSearchLink
} from '@bentley/pwnxt-document-picker';
import { DocumentPickerModal } from '@bentley/pwnxt-document-picker';
import { WebConnectionApi } from '@bentley/pwnxt-web-connections';
import { LoadingPage } from '../../../components/loadingPage';
import {
  useConnectionAuth,
  usePluginContext,
  useToken
} from '../../../context';
import { t } from '../../../services/translation';

export type DocumentPickerParentDataType = {
  instanceId: string;
  ParentGuid: string;
  className: string;
  Name: string;
};

type DocumentPickerModalWrapperProps = {
  onClose: () => void;
  onItemsSelected: (selectedItems: DocumentLink[]) => void;
  maximumSelectedItems?: number;
  customFilter?: (item: PWItem & DocumentPicker.Item) => boolean;
  isNavigationAllowed?: (item: PWItem & DocumentPicker.Item) => boolean;
  currentFolder?: DocumentPickerParentDataType;
  hideConnectionListView?: boolean;
  title?: string;
  isSelectable: (item: PWItem & DocumentPicker.Item) => boolean;
  selectionMode?: DocumentPickerMode;
  children?: React.ReactNode;
  onNavigate?: (item: PWItem & DocumentPicker.Item) => void;
};

export function DocumentPickerModalWrapper({
  onClose,
  onItemsSelected,
  maximumSelectedItems,
  customFilter,
  isNavigationAllowed,
  currentFolder,
  hideConnectionListView,
  title,
  isSelectable,
  selectionMode,
  children,
  onNavigate
}: DocumentPickerModalWrapperProps): JSX.Element | null {
  const [oidcAuthToken, setOidcAuthToken] = useState<string | undefined>();
  const [samlAuthToken, setSamlAuthToken] = useState<string | undefined>();

  const { contextId, pwWebConnectionUrl, connection } = usePluginContext();
  const { basicCredentials, authorizationService } = useConnectionAuth();
  const { getOidcToken, getSamlToken } = useToken();

  useEffect(() => {
    async function getAuthTokens() {
      if (oidcAuthToken && samlAuthToken) {
        return;
      }

      const oidcToken = await getOidcToken();
      const samlToken = await getSamlToken();
      setOidcAuthToken(oidcToken);
      setSamlAuthToken(samlToken);
    }

    void getAuthTokens();
  }, [samlAuthToken, oidcAuthToken, getOidcToken, getSamlToken]);

  const connectionApi = useMemo((): WebConnectionApi | undefined => {
    if (!pwWebConnectionUrl || !oidcAuthToken) {
      return undefined;
    }

    const connectionApi = new WebConnectionApi({
      baseUrl: pwWebConnectionUrl,
      authToken: oidcAuthToken
    });

    const { getAllConnections } = connectionApi;

    connectionApi.getAllConnections = async (contextId: string) => {
      const connections = await getAllConnections(contextId);

      return connections.map((connection) => {
        if (connection.canned) {
          return { ...connection, name: t('Generic.Documents') };
        }
        return connection;
      });
    };

    return connectionApi;
  }, [oidcAuthToken, pwWebConnectionUrl]);

  const documentApi = useMemo((): DocumentApi | undefined => {
    const loginType = authorizationService?.authorizationType;

    if (loginType == 'SAML token') {
      return new DocumentApi({ samlToken: samlAuthToken });
    }

    if (loginType == 'user credentials' && basicCredentials) {
      return new DocumentApi({ credential: basicCredentials });
    }

    if (loginType == 'OIDC token' && oidcAuthToken) {
      return new DocumentApi({ oidcToken: oidcAuthToken });
    }

    return undefined;
  }, [
    authorizationService?.authorizationType,
    basicCredentials,
    oidcAuthToken,
    samlAuthToken
  ]);

  const location = {
    connectionId: connection.Id,
    currentFolder,
    hideConnectionListView
  };

  if (!connectionApi || !documentApi) {
    return null;
  }

  const loadingIcon = <LoadingPage loadingSubText={t('Generic.Loading')} />;
  return (
    <DocumentPickerModal
      title={title}
      isOpen
      onClose={onClose}
      onItemsSelected={(
        selectedItems: (
          | DocumentLink
          | SavedSearchLink
          | DocumentPicker.Project
        )[]
      ) => onItemsSelected(selectedItems as DocumentLink[])}
      onNavigate={onNavigate as DocumentPickerModalProps['onNavigate']}
      checkPublicFacing={() => Promise.resolve(true)}
      queryConnections={connectionApi.getAllConnections}
      queryWorkAreaItems={documentApi.getNavigationItems}
      queryItemDetails={documentApi.getItemDetails}
      getCurrentParents={documentApi.getParentsCompletely}
      location={location}
      contextId={contextId ?? ''}
      showAllVersions={true}
      maximumSelectedItems={maximumSelectedItems}
      customFilter={customFilter as DocumentPickerModalProps['customFilter']}
      isNavigationAllowed={
        isNavigationAllowed as DocumentPickerModalProps['isNavigationAllowed']
      }
      getStatusIcon={() => <></>}
      isSelectable={isSelectable as DocumentPickerModalProps['isSelectable']}
      selectionMode={selectionMode}
      loadingIcon={loadingIcon}
      queryOptions={{ refetchOnWindowFocus: false }}
    >
      {children}
    </DocumentPickerModal>
  );
}
