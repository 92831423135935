import React, { useCallback, useEffect, useMemo } from 'react';
import {
  SvgBlank,
  SvgCheckmark,
  SvgImageFrame,
  SvgTable
} from '@itwin/itwinui-icons-react';
import { CustomColumnDialog } from '../../../../../actions/customColumn';
import { MyViewsModal } from '../../../../../actions/myViews';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext,
  usePropertyContext
} from '../../../../../context';
import type { View } from '../../../../../hooks/useViews';
import { defaultView } from '../../../../../hooks/useViews/defaultView';
import { t } from '../../../../../services/translation';
import type { MenuItemAction } from '../../../MenuItemAction';

type ViewsMenuItems = {
  configureColumns: MenuItemAction;
  manageMyViews: MenuItemAction;
  folderViews: MenuItemAction[];
  savedViews: MenuItemAction[];
  myViews: MenuItemAction[];
};

export function useViewsMenuItems(): ViewsMenuItems {
  const { trackFeature } = useFeatureTracking();
  const { connection } = usePluginContext();

  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    navigationManager: { currentParent },
    primaryModal
  } = useNavigationContext();
  const { viewManager } = usePropertyContext();

  const showViews = useCallback((): boolean => {
    if (connection.Canned) {
      return false;
    }
    if (!ecPluginFeatures?.views()) {
      return false;
    }
    return true;
  }, [connection.Canned, ecPluginFeatures]);

  const globalViewChecked = useMemo(
    (): boolean =>
      viewManager.currentView == viewManager.pweFolderViewSetting.view,
    [viewManager.currentView, viewManager.pweFolderViewSetting.view]
  );

  const defaultViewChecked = useMemo(
    (): boolean =>
      viewManager.currentView == defaultView ||
      viewManager.currentView == viewManager.adminViewSetting.view,
    [viewManager.currentView, viewManager.adminViewSetting.view]
  );

  const disableGlobalView = useMemo(
    (): boolean =>
      currentParent?.instanceId == null ||
      viewManager.adminViewSetting.view ==
        viewManager.pweFolderViewSetting.view,
    [
      currentParent?.instanceId,
      viewManager.adminViewSetting.view,
      viewManager.pweFolderViewSetting.view
    ]
  );

  const viewChecked = useCallback(
    (view: View): boolean => viewManager.currentView == view,
    [viewManager.currentView]
  );

  const onConfigureColumnsClick = useCallback((): void => {
    trackFeature('GRID_OPEN_CUSTOMIZE_COLUMN');
    primaryModal.open(
      <CustomColumnDialog
        initialView={viewManager.currentView}
        onClose={primaryModal.close}
      />
    );
  }, [primaryModal, trackFeature, viewManager.currentView]);

  const onManageMyViewsClick = useCallback((): void => {
    trackFeature('GRID_OPEN_MANAGE_MY_VIEWS');
    primaryModal.open(<MyViewsModal />);
  }, [primaryModal, trackFeature]);

  const onGlobalViewClick = useCallback((): void => {
    trackFeature('GRID_SET_VIEW');
    if (globalViewChecked) {
      viewManager.customViewSetting.setView();
    } else {
      viewManager.pweFolderViewSetting.setView();
    }
  }, [globalViewChecked, trackFeature, viewManager]);

  const onDefaultViewClick = useCallback((): void => {
    trackFeature('GRID_SET_VIEW');
    if (defaultViewChecked) {
      viewManager.customViewSetting.setView();
    } else {
      viewManager.adminViewSetting.setView();
    }
  }, [defaultViewChecked, trackFeature, viewManager]);

  const onSavedViewClick = useCallback(
    (view: View): void => {
      trackFeature('GRID_SET_VIEW');
      if (viewChecked(view)) {
        viewManager.customViewSetting.setView();
      } else {
        viewManager.savedViewSetting.setView(view);
      }
    },
    [trackFeature, viewChecked, viewManager]
  );

  const onMyViewClick = useCallback(
    (view: View): void => {
      trackFeature('GRID_SET_VIEW');
      if (viewChecked(view)) {
        viewManager.customViewSetting.setView();
      } else {
        viewManager.userViewSetting.setView(view);
      }
    },
    [trackFeature, viewChecked, viewManager]
  );

  const configureColumns = useMemo(
    (): MenuItemAction => ({
      title: t('ViewManager.ConfigureColumns'),
      icon: <SvgTable />,
      'data-testid': 'ConfigureColumns',
      onClick: onConfigureColumnsClick
    }),
    [onConfigureColumnsClick]
  );

  const manageMyViews = useMemo(
    (): MenuItemAction => ({
      title: t('MyViews.ManageMyViews'),
      icon: <SvgImageFrame />,
      'data-testid': 'ManageMyViews',
      onClick: onManageMyViewsClick
    }),
    [onManageMyViewsClick]
  );

  const globalView = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.GlobalView'),
      icon:
        disableGlobalView || !globalViewChecked ? (
          <SvgBlank />
        ) : (
          <SvgCheckmark />
        ),
      startGroup: true,
      groupLabel: t('MenuItem.FolderViews'),
      'data-testid': 'GlobalView',
      disabled: disableGlobalView,
      onClick: onGlobalViewClick
    }),
    [disableGlobalView, globalViewChecked, onGlobalViewClick]
  );

  const defaultViewRow = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.DefaultView'),
      icon: defaultViewChecked ? <SvgCheckmark /> : <SvgBlank />,
      groupLabel: t('MenuItem.FolderViews'),
      'data-testid': 'DefaultView',
      onClick: onDefaultViewClick
    }),
    [defaultViewChecked, onDefaultViewClick]
  );

  const folderViews = useMemo((): MenuItemAction[] => {
    if (!showViews()) {
      return [];
    }

    return [globalView, defaultViewRow];
  }, [defaultViewRow, globalView, showViews]);

  const savedView = useCallback(
    (view: View, index: number): MenuItemAction => ({
      title: view.name,
      icon: viewChecked(view) ? <SvgCheckmark /> : <SvgBlank />,
      startGroup: index == 0,
      groupLabel: t('DisplayOptions.SavedViews'),
      'data-testid': view.name,
      onClick: () => onSavedViewClick(view)
    }),
    [onSavedViewClick, viewChecked]
  );

  const savedViews = useMemo((): MenuItemAction[] => {
    if (!showViews()) {
      return [];
    }

    return viewManager.savedViewSetting.views?.map(savedView) ?? [];
  }, [savedView, showViews, viewManager.savedViewSetting.views]);

  const myView = useCallback(
    (view: View, index: number): MenuItemAction => ({
      title: view.name,
      icon: viewChecked(view) ? <SvgCheckmark /> : <SvgBlank />,
      startGroup: index == 0,
      groupLabel: t('MenuItem.MyViews'),
      'data-testid': view.name,
      onClick: () => onMyViewClick(view)
    }),
    [onMyViewClick, viewChecked]
  );

  const myViews = useMemo((): MenuItemAction[] => {
    return viewManager.userViewSetting.views?.map(myView) ?? [];
  }, [myView, viewManager.userViewSetting.views]);

  useEffect(() => {
    viewManager.loadViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { configureColumns, manageMyViews, folderViews, savedViews, myViews };
}
