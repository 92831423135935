import { useMemo, useRef } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { usePluginContext } from '../../../context';
import type { MenuItemAction } from '../../toolbar';
import { useItemActions } from '../../toolbar';
import {
  useDownloadItemAction,
  useGoToDropDownAction,
  useLinkItemActions
} from '../../toolbar/itemActionsToolbar/buttons';

export function useFolderNodeContextMenuItems(
  folder: PWProject
): MenuItemAction[] {
  const { connection } = usePluginContext();

  const isConnectionRoot = connection.ProjectId == (folder.instanceId ?? '');

  const stableParent = useRef<[PWProject]>([folder]);
  const buildItemActions = useItemActions(stableParent.current);

  const downloadItemAction = useDownloadItemAction(stableParent.current);
  const getLinkItemAction = useLinkItemActions(stableParent.current);
  const goToDropdownAction = useGoToDropDownAction(stableParent.current);
  // const refreshOption = useRefreshOption();
  // const pwDriveOption = useDriveOption();
  // const displayOption = useDisplayOption();
  // const aboutOption = useAboutConnectionOption();
  // const signOutOption = useSignOutOption();

  const menuOptions = useMemo(() => {
    const itemActions = buildItemActions('contextMenu');

    if (isConnectionRoot) {
      return [
        downloadItemAction,
        getLinkItemAction('contextMenu'),
        goToDropdownAction
        // refreshOption,
        // pwDriveOption,
        // displayOption,
        // aboutOption,
        // signOutOption,
      ];
    }
    return itemActions;
  }, [
    // aboutOption,
    buildItemActions,
    // displayOption,
    downloadItemAction,
    goToDropdownAction,
    isConnectionRoot,
    getLinkItemAction
    // pwDriveOption,
    // refreshOption,
    // signOutOption,
  ]);

  return menuOptions;
}
