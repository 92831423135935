import React, { useMemo } from 'react';
import { SvgClock } from '@itwin/itwinui-icons-react';
import { usePluginContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useInProgressOption(): MenuItemAction {
  const { progressManager } = usePluginContext();

  const inProgressOption = useMemo(
    (): MenuItemAction => ({
      title: t('InProgress.InProgress'),
      icon: <SvgClock />,
      onClick: progressManager.openIPModal,
      isActive: progressManager.IPModal != null
    }),
    [progressManager.IPModal, progressManager.openIPModal]
  );

  return inProgressOption;
}
