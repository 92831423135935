import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyCheckOut(numberOfItems: number): ToastHandle {
  const content =
    numberOfItems == 1
      ? t('Sync.Notification.CheckingOut')
      : t('Sync.Notification.CheckingOutMulti', {
          count: numberOfItems
        });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyCheckIn(length: number): ToastHandle {
  const content =
    length == 1
      ? t('Sync.Notification.CheckingInFile')
      : t('Sync.Notification.CheckingInFiles', { count: length });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyDocumentUpdate(): ToastHandle {
  const content = t('Sync.Notification.UpdatingFile');

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyErrorDuringCheckOut(
  toastHandle: ToastHandle,
  errorMsg: string
): void {
  const content = t('Sync.Notification.ErrorDuringCheckOut', {
    errorMsg
  });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyErrorDuringCheckIn(
  toastHandle: ToastHandle,
  errorMsg: string
): void {
  const content = t('Sync.Notification.ErrorDuringCheckIn', {
    errorMsg
  });
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyErrorDuringUpdate(
  toastHandle: ToastHandle,
  errorMsg: string
): void {
  const content = t('Sync.Notification.ErrorDuringUpdate', {
    errorMsg
  });
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyCheckOutSuccess(
  numberOfItems: number,
  toastHandle: ToastHandle
): void {
  const content =
    numberOfItems == 1
      ? t('Sync.Notification.FileSuccessfullyCheckedOut')
      : t('Sync.Notification.FilesSuccessfullyCheckedOut', {
          count: numberOfItems
        });

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function notifyDocumentCheckInSuccess(toastHandle: ToastHandle): void {
  const content = t('Sync.Notification.FileSuccessfullyCheckedIn');
  replaceToast(toastHandle, { content, category: 'positive' });
}

export function notifyDocumentUpdateSuccess(toastHandle: ToastHandle): void {
  const content = t('Sync.Notification.FileSuccessfullyUpdated');
  replaceToast(toastHandle, { content, category: 'positive' });
}

export function get403ErrorString(tryingToUnlock: boolean): string {
  return tryingToUnlock
    ? t('Sync.Notification.InsufficientUnlockPermissions')
    : t('Sync.Notification.InsufficientLockPermissions');
}

export function get400ErrorString(): string {
  return t('Sync.Notification.TroubleLocking');
}

export function notifyCheckedInDocumentsWithPartialSuccess(
  count: number
): ToastHandle {
  const content =
    count == 1
      ? t('Sync.Notification.FileSuccessfullyCheckedIn')
      : t('Sync.Notification.SuccessfullyCheckedInFiles', {
          count
        });

  return openToast({ content, category: 'positive' });
}

export function notifyCheckInDocumentSuccess(
  numberOfItems: number,
  toastHandle: ToastHandle
): void {
  const content =
    numberOfItems == 1
      ? t('Sync.Notification.FileSuccessfullyCheckedIn')
      : t('Sync.Notification.SuccessfullyCheckedInFiles', {
          count: numberOfItems
        });

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function notifyFileLockedError(
  fileLockedCount: number,
  itemsCount: number,
  toastHandle: ToastHandle,
  successCount: number
): void {
  const count = itemsCount - fileLockedCount;

  const errorMsg =
    itemsCount == fileLockedCount + successCount
      ? ''
      : count == 1
      ? t('Sync.FailedToCheckInFile')
      : t('Sync.FailedToCheckInFiles', { count });

  const content =
    fileLockedCount == 1
      ? t('Sync.Notification.FileLocked', { errorMsg })
      : t('Sync.Notification.FilesLocked', {
          fileLockedCount,
          errorMsg
        });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyCheckedOutDocumentsWithPartialSuccess(
  count: number
): ToastHandle {
  const content =
    count == 1
      ? t('Sync.Notification.FileSuccessfullyCheckedOut')
      : t('Sync.Notification.FilesSuccessfullyCheckedOut', {
          count
        });

  return openToast({ content, category: 'positive' });
}

export function notifyCheckOutFailure(
  numberOfItems: number,
  toastHandle: ToastHandle
): void {
  const content =
    numberOfItems == 1
      ? t('Sync.Notification.FileCheckedOutOnAnotherNode')
      : t('Sync.Notification.FilesCheckedOutOnAnotherNode', {
          count: numberOfItems
        });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyCheckInErrorWhenDriveIsOff(
  toastHandle: ToastHandle
): void {
  const content = t('Sync.Notification.CheckDrive');
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyCheckOutErrorWhenDriveIsOff(
  toastHandle: ToastHandle
): void {
  const content = t('Sync.Notification.CheckDrive');
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyOpenFileErrorWhenDriveIsOff(): ToastHandle {
  const content = t('Sync.Notification.CheckDrive');
  return openToast({ content, category: 'negative' });
}

export function notifySyncProjectErrorWhenDriveIsOff(): ToastHandle {
  const content = t('Sync.Notification.CheckDrive');
  return openToast({ content, category: 'negative' });
}

export function notifyNavigateToDriveErrorWhenDriveIsOff(): ToastHandle {
  const content = t('Sync.Notification.CheckDrive');
  return openToast({ content, category: 'negative' });
}

export function notifyInternalServerErrorDrive(
  toastHandle?: ToastHandle
): void {
  const content = t('Sync.Notification.CheckDrive');
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyDownloadDrive(toastHandle?: ToastHandle): void {
  const content = t('PWDrive.Notifications.DriveObsolete');
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyProjectSyncFailure(responseMessage: string): ToastHandle {
  const content = t('Sync.DriveSyncError', {
    message: responseMessage
  });
  return openToast({ content, category: 'negative' });
}

export function notifyAccessIssue(toastHandle?: ToastHandle): void {
  const content = t('Sync.Notification.InsufficientUnlockPermissions');
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyStandardCheckoutFailure(
  reason: string,
  toastHandle?: ToastHandle
): void {
  replaceToast(toastHandle, { content: reason, category: 'negative' });
}
