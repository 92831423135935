import type {
  PWDataItem,
  PWItem,
  PWProject,
  WSGChangedInstanceResponse,
  WSGInstance,
  WSGRelationshipInstance
} from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { CreateCopyProperties } from './createCopy';

export function getCopyDocumentQueryBody(
  instanceId: string,
  properties: CreateCopyProperties,
  destinationFolder: PWProject,
  usingDCW: boolean,
  environmentInstance?: WSGInstance
): string {
  const documentEnvironmentInstance = {
    className: 'DocumentEnvironment',
    direction: 'forward',
    relatedInstance: environmentInstance,
    schemaName: 'PW_WSG'
  } as WSGRelationshipInstance;

  const relationshipInstances = [
    {
      schemaName: 'PW_WSG',
      className: 'DocumentCopySource',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: instanceId
      },
      properties: {
        CopyAttributes: !usingDCW,
        CopyVersions: false,
        CopyFile: true,
        CopyAccessRights: false
      }
    },
    {
      schemaName: 'PW_WSG',
      className: 'DocumentParent',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'Project',
        instanceId: destinationFolder.instanceId
      }
    },
    environmentInstance ? documentEnvironmentInstance : undefined
  ];

  const instance = {
    schemaName: 'PW_WSG',
    className: 'Document',
    instanceId: '',
    changeState: 'new',
    properties: {
      ...properties
    },
    relationshipInstances: relationshipInstances.filter(
      (relInst) => relInst !== undefined
    )
  } as WSGInstance;

  if (usingDCW) {
    return JSON.stringify({
      instance,
      requestOptions: {
        CustomOptions: {
          ignoreAttributeRecordCreation: true
        }
      }
    });
  }

  return JSON.stringify({ instance: instance });
}

export function getCopyProjectQueryBody(
  instanceId: string,
  properties: CreateCopyProperties,
  destinationFolder: PWProject
): string {
  const relationshipInstances = [
    {
      schemaName: 'PW_WSG',
      className: 'ProjectCopySource',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'Project',
        instanceId: instanceId
      }
    },
    {
      schemaName: 'PW_WSG',
      className: 'ProjectParent',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'Project',
        instanceId: destinationFolder.instanceId
      }
    }
  ];

  const instance = {
    schemaName: 'PW_WSG',
    className: 'Project',
    instanceId: '',
    changeState: 'new',
    properties: {
      ...properties
    },
    relationshipInstances
  } as WSGInstance;

  return JSON.stringify({ instance: instance });
}

export function getCopySetQueryBody(
  instanceId: string,
  properties: CreateCopyProperties,
  destinationFolder: PWProject
): string {
  // Check if these properties are correct for copying set
  const relationshipInstances = [
    {
      schemaName: 'PW_WSG',
      className: 'DocumentCopySource',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'FlatSet',
        instanceId
      }
    },
    {
      schemaName: 'PW_WSG',
      className: 'DocumentParent',
      direction: 'forward',
      relatedInstance: {
        schemaName: 'PW_WSG',
        className: 'Project',
        instanceId: destinationFolder.instanceId
      }
    }
  ];

  const instance = {
    schemaName: 'PW_WSG',
    className: 'FlatSet',
    changeState: 'new',
    properties: {
      ...properties
    },
    relationshipInstances
  };

  return JSON.stringify({ instance });
}

export async function parseCopyInstance(
  response: Response
): Promise<WSGInstance> {
  const data = (await response.clone().json()) as WSGChangedInstanceResponse;
  if (!response.ok || response.status != 201 || data.errorMessage) {
    throw data;
  }

  return data.changedInstance.instanceAfterChange;
}

export function getConflictingItems(
  items: PWItem[],
  responses: Response[],
  properties: CreateCopyProperties[]
): PWItem[] {
  const conflictingIndexes = responses
    .map((response, index) => {
      if (response.status == 409) {
        return index;
      }
      return -1;
    })
    .filter((index) => index != -1);

  const conflictingItems: (PWDataItem | PWProject)[] = [];
  properties.forEach((property, index) => {
    if (conflictingIndexes.includes(index)) {
      if (itemIsProject(items[index])) {
        conflictingItems.push({
          ...items[index],
          Name: property.Name,
          Description: property.Description
        } as PWProject);
      } else {
        conflictingItems.push({
          ...items[index],
          Name: property.Name,
          FileName: property.FileName ?? '',
          Description: property.Description
        } as PWDataItem);
      }
    }
  });

  return conflictingItems;
}
