import './connectionsList.css';

import React from 'react';
import { SvgChevronRight } from '@itwin/itwinui-icons-react';
import { IconButton, SidenavSubmenu } from '@itwin/itwinui-react';
import { useConnectionsContext } from '../../context';
import { ConnectionLink } from './connectionLink';
import { useConnectionsListResizer } from './useConnectionsListResizer';

export function ConnectionsList(): JSX.Element {
  const { connections } = useConnectionsContext();

  useConnectionsListResizer();

  return (
    <>
      <SidenavSubmenu
        id="connections-list-nav-panel"
        className="connections-list-nav-panel"
      >
        <div className="connections-list" data-testid="connections-list">
          {connections.map((connection) => (
            <ConnectionLink repository={connection} key={connection.Id} />
          ))}
        </div>
      </SidenavSubmenu>
      <IconButton
        styleType="borderless"
        size="small"
        id="connections-list-placeholder"
        className="connections-list-placeholder-hidden"
      >
        <SvgChevronRight />
      </IconButton>
    </>
  );
}
