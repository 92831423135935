import type { WSGInstancesResponse } from '@bentley/pw-api';
import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import type { ParsableResponse } from '../replace/notifications';
import { notifyReplaceMultipleInProgress } from '../replace/notifications';
import { getUploadErrorText } from './errors';

export function notifyUploadInProgress(toastHandle?: ToastHandle): ToastHandle {
  const content = t('Upload.Notification.UploadInProgress');
  return replaceToast(toastHandle, {
    content,
    type: 'persisting',
    spinner: true
  });
}

function notifyMultipleRenamesInProgress(number: number): ToastHandle {
  const content =
    number == 1
      ? t('Upload.Notification.UploadingWithNewNamesInProgress')
      : t('Upload.Notification.UploadingWithNewNamesInProgressMulti', {
          count: number
        });

  return openToast({ content, type: 'persisting', spinner: true });
}

function notifyMultipleNewVersionsInProgress(number: number): ToastHandle {
  const content =
    number == 1
      ? t('Upload.Notification.UploadingNewVersionsInProgress')
      : t('Upload.Notification.UploadingNewVersionsInProgressMulti', {
          count: number
        });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyNoFilesFound(toastHandle?: ToastHandle): ToastHandle {
  return replaceToast(toastHandle, {
    content: t('Upload.Notification.NoFiles')
  });
}

export function notifyFileSystemError(): ToastHandle {
  return openToast({
    content: t('Upload.Notification.ErrorDuringUpload'),
    category: 'negative'
  });
}

export function notifyDriveServiceError(): ToastHandle {
  return openToast({
    content: t('Upload.Notification.DriveServiceError'),
    category: 'negative'
  });
}

export function notifyGenericUploadError(): ToastHandle {
  return openToast({
    content: t('Upload.Notification.GenericUploadError'),
    category: 'negative'
  });
}

/**
 * Sends a toast to let user know of either successful upload of various errors
 * @param {any} response http response with status and possible error message
 * @param {string} name name of the file/folder attempting to be uploaded
 * @param {'File' | 'Folder'} itemType determines the format of the message
 */
export function sendUploadNotification(
  response: Response,
  name: string,
  itemType: 'File' | 'Folder',
  toastHandle?: ToastHandle
): void {
  if (response.status === 201) {
    const messageTemplate =
      itemType == 'File'
        ? 'Upload.Notification.FileSuccess'
        : 'Upload.Notification.FolderSuccess';
    const content = t(messageTemplate, { name });
    replaceToast(toastHandle, { content, category: 'positive' });
  } else {
    if (itemType == 'File') {
      const content = t('Upload.Notification.ErrorUploading', {
        name: name,
        errorMsg: getUploadErrorText(response as ParsableResponse)
      });
      replaceToast(toastHandle, { content, category: 'negative' });
    } else {
      const content = t('Upload.Notification.ErrorCreating', {
        name: name,
        errorMsg: getUploadErrorText(response as ParsableResponse)
      });
      replaceToast(toastHandle, { content, category: 'negative' });
    }
  }
}

export function sendAccessDeniedNotification(response: Response): void {
  const content = t('Upload.Notification.ErrorUploadingAccessDenied', {
    errorMsg: getUploadErrorText(response as ParsableResponse)
  });
  openToast({ content, category: 'negative' });
}

type ConflictResolutionToasts = {
  replace?: ToastHandle;
  rename?: ToastHandle;
  version?: ToastHandle;
  keep: undefined;
};

/**
 * Builds an object containing Toast Ids to be used for various conflict resolutions
 * @param {ConflictResolution[]} resolutions The resolution used to determine what kind of toasts to use
 * @returns {ConflictResolutionToasts}
 */
export function buildConflictToasts(
  resolutions: ConflictResolution[]
): ConflictResolutionToasts {
  const replaceToast = resolutions.filter((res) => res.action == 'replace')
    .length
    ? notifyReplaceMultipleInProgress(
        resolutions.filter((res) => res.action == 'replace').length
      )
    : undefined;

  const renameToast = resolutions.filter((res) => res.action == 'rename').length
    ? notifyMultipleRenamesInProgress(
        resolutions.filter((res) => res.action == 'rename').length
      )
    : undefined;

  const versionToast = resolutions.filter((res) => res.action == 'version')
    .length
    ? notifyMultipleNewVersionsInProgress(
        resolutions.filter((res) => res.action == 'version').length
      )
    : undefined;

  const toasts = {
    replace: replaceToast,
    rename: renameToast,
    version: versionToast,
    keep: undefined
  } as ConflictResolutionToasts;
  return toasts;
}

export function notifyConflictsResolved(count: number): void {
  const content =
    count == 1
      ? t('Upload.Notification.Conflict.Resolved')
      : t('Upload.Notification.Conflicts.ResolvedWithCount', {
          count
        });
  openToast({ content, category: 'positive' });
}

export async function handleVersionError(response: Response): Promise<void> {
  const responseJson = (await response.json()) as WSGInstancesResponse;
  const errorMessage = responseJson.errorMessage;

  const content = errorMessage
    ? t('Generic.AnErrorOccurred', { errorMessage })
    : t('Generic.UnknownErrorOccurred');

  openToast({ content, category: 'negative' });
}

export function notifyServerDisconnectedError(): ToastHandle {
  const content = t('Upload.Notification.LostInternet');
  return openToast({ content, category: 'negative' });
}
