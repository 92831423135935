import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { MenuItemAction } from '../../../components/toolbar';
import { useItemActions } from '../../../components/toolbar';
import type { ItemMenuType } from '../../../components/toolbar/itemActionsToolbar/useItemActions';
import { useTableItemContext } from '../tableItem';

type ItemActionContext = {
  itemActions: (menuType: ItemMenuType) => MenuItemAction[];
};

const Context = createContext<ItemActionContext | undefined>(undefined);

export function ItemActionProvider({
  children
}: ProviderProps<ItemActionContext | undefined>): JSX.Element {
  const { actionableItems } = useTableItemContext();
  const itemActions = useItemActions(actionableItems);

  const itemActionContext = useMemo(
    (): ItemActionContext => ({ itemActions }),
    [itemActions]
  );

  return (
    <Context.Provider value={itemActionContext}>{children}</Context.Provider>
  );
}

export function useItemActionContext(): ItemActionContext {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useItemActionContext must be used within an ItemActionProvider'
    );
  }

  return context;
}
