import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import type { PWItem, PWParentType, PWProject } from '@bentley/pw-api';
import { filterProjects, itemIsParentType } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { getCreatedProjectInstance } from '../../actions/move/utils';
import { NewFolderDialog } from '../../actions/upload';
import {
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';
import type { BreadcrumbManager } from '../useBreadcrumb';
import { useBreadcrumbs } from '../useBreadcrumb';
import type { ModalElement } from '../useModal';
import { useModal } from '../useModal';

export type DocPickerFunctionManager = {
  documentLinkToProject: (item: DocumentLink) => PWProject;
  newFolderModal: ModalElement;
  onNavigate: (item: PWItem) => void;
  onNewFolderClick: () => void;
  validMoveDestination: (item: PWItem) => boolean;
  validNavigation: (item: PWItem) => boolean;
  docPickerBreadcrumbManager: BreadcrumbManager;
};

export function useDocPickerFunctions(
  actionableItems: PWItem[]
): DocPickerFunctionManager {
  const {
    breadcrumbManager: { breadcrumbs },
    navigationManager: { currentParent }
  } = useNavigationContext();
  const httpService = useHttpService();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const [newFolderModal, openNewFolderModal, closeNewFolderModal] = useModal();
  const [subFolders] = useState<PWProject[]>([]);

  const initialBreadcrumbs = useMemo((): PWParentType[] => {
    const moveBreadcrumbs = _.cloneDeep(breadcrumbs);
    if (actionableItems[0] == currentParent) {
      moveBreadcrumbs.pop();
    }
    return moveBreadcrumbs;
  }, [breadcrumbs, currentParent, actionableItems]);

  const docPickerBreadcrumbManager = useBreadcrumbs(initialBreadcrumbs);

  const onNewFolderComplete = useCallback(
    async (response: Response): Promise<void> => {
      if (response.status == 201) {
        const newFolder = await getCreatedProjectInstance(response);
        docPickerBreadcrumbManager.addBreadcrumb(newFolder);
        await httpService.cache?.clearEntriesMatching(newFolder.ParentGuid);

        refreshCurrentFolder();
      }
    },
    [httpService.cache, docPickerBreadcrumbManager, refreshCurrentFolder]
  );

  const newFolderDialog = useMemo((): JSX.Element => {
    return (
      <NewFolderDialog
        onClose={closeNewFolderModal}
        siblingFolders={[...subFolders, ...filterProjects(actionableItems)]}
        parentId={docPickerBreadcrumbManager.currentBreadcrumb.instanceId ?? ''}
        onFolderComplete={async (response) => {
          await onNewFolderComplete(response);
        }}
      />
    );
  }, [
    actionableItems,
    closeNewFolderModal,
    docPickerBreadcrumbManager.currentBreadcrumb.instanceId,
    onNewFolderComplete,
    subFolders
  ]);

  const onNewFolderClick = useCallback((): void => {
    openNewFolderModal(newFolderDialog);
  }, [newFolderDialog, openNewFolderModal]);

  const validNavigation = useCallback(
    (item: PWItem): boolean => {
      if (
        actionableItems.map((ai) => ai.instanceId).includes(item.instanceId)
      ) {
        return false;
      }
      return true;
    },
    [actionableItems]
  );

  const validMoveDestination = useCallback(
    (item: PWItem): boolean => {
      if (
        actionableItems.map((ai) => ai.instanceId).includes(item.instanceId)
      ) {
        return false;
      }
      if (
        actionableItems.map((ai) => ai.ParentGuid).includes(item.instanceId)
      ) {
        return false;
      }

      return true;
    },
    [actionableItems]
  );

  const onNavigate = useCallback(
    (item: PWItem): void => {
      if (itemIsParentType(item)) {
        docPickerBreadcrumbManager.addBreadcrumb(item);
      }
    },
    [docPickerBreadcrumbManager]
  );

  const documentLinkToProject = useCallback((item: DocumentLink): PWProject => {
    return {
      Name: item.name,
      instanceId: item.id,
      className: item.itemType,
      UpdatedBy: item.lastModifiedByName
    } as PWProject;
  }, []);

  const docPickerFunctionManager = useMemo(
    (): DocPickerFunctionManager => ({
      documentLinkToProject,
      newFolderModal,
      onNavigate,
      onNewFolderClick,
      validMoveDestination,
      validNavigation,
      docPickerBreadcrumbManager
    }),
    [
      documentLinkToProject,
      newFolderModal,
      onNavigate,
      onNewFolderClick,
      validMoveDestination,
      validNavigation,
      docPickerBreadcrumbManager
    ]
  );

  return docPickerFunctionManager;
}
