import { useCallback, useEffect, useMemo, useState } from 'react';
import { itemIsFlatSet } from '@bentley/pw-api';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { getPWEFolderView } from '../data/pweFolderView';
import { defaultView } from '../defaultView';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function usePWEFolderView(
  startLoad: boolean,
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const httpService = useHttpService();
  const { connection } = usePluginContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const [views, setView] = useState<View>(defaultView);
  const [initialized, setInitialized] = useState<boolean>(false);

  const folderId = useMemo(() => {
    const folderId = itemIsFlatSet(currentParent)
      ? currentParent.ParentGuid
      : currentParent.instanceId;
    return folderId;
  }, [currentParent]);

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeView(): Promise<void> {
      setInitialized(false);

      if (folderId === null) {
        setView(defaultView);
        setInitialized(true);
        return;
      }

      if (connection.Canned) {
        setInitialized(true);
        return;
      }

      if (!folderId) {
        return;
      }

      try {
        const view = await getPWEFolderView(folderId, httpService, {
          abortController
        });

        if (!abortController.signal.aborted) {
          setView(view);
        }
      } catch {
        setView(defaultView);
      } finally {
        setInitialized(true);
      }
    }

    if (startLoad) {
      void initializeView();
    }

    return () => {
      abortController.abort();
    };
  }, [connection.Canned, folderId, httpService, startLoad]);

  const saveView = useCallback((view: View): void => {
    throw new Error('Save PWE folder view not supported');
  }, []);

  const setViewType = useCallback((): void => {
    setCurrentViewType('PWEFolderView');
  }, [setCurrentViewType]);

  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit PWE folder view not supported');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete PWE folder view not supported');
  }, []);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized,
      view: views,
      views: [views],
      saveView,
      setView: setViewType,
      editView,
      deleteView
    }),
    [initialized, views, saveView, setViewType, editView, deleteView]
  );

  return viewSetting;
}
