import type { HttpService, RequestOptions } from '@bentley/pw-api';
import { parseResponseRelationshipInstances } from '@bentley/pw-api';
import { ecPluginFeatures } from '../../../hooks/useECPluginVersion';
import type { FormDefinition } from '../formDefinition';

export async function getProjectDefaultFormDefinition(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<FormDefinition | undefined> {
  if (!projectId) {
    return undefined;
  }

  if (ecPluginFeatures.projectDefaultFormDef()) {
    return getProjectEnvironmentFormDefinition(
      projectId,
      httpService,
      httpOptions
    );
  }

  return getEnvironmentFormDefinition(projectId, httpService, httpOptions);
}

async function getProjectEnvironmentFormDefinition(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<FormDefinition | undefined> {
  const query = projectEnvironmentFormDefinitionQuery(projectId);

  const response = await httpService.get(query, {
    ...httpOptions,
    uncached: true
  });

  if (!response.ok) {
    // Fallback to older query for some beta versions of ec plugin 3.4
    return getEnvironmentFormDefinition(projectId, httpService, httpOptions);
  }

  const formDefinition = await parseFormDefinition(response);
  return formDefinition;
}

async function getEnvironmentFormDefinition(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<FormDefinition | undefined> {
  const query = environmentFormDefinitionQuery(projectId);

  const response = await httpService.get(query, {
    ...httpOptions,
    uncached: true
  });

  const formDefinition = await parseFormDefinition(response);
  return formDefinition;
}

function projectEnvironmentFormDefinitionQuery(projectId: string) {
  const baseQuery = `PW_WSG/Project/${projectId}`;

  const relationshipClass =
    'ProjectEnvironmentFormDefinition-forward-Forms_EC_Mapping.FormDefinition!poly.*';

  return `${baseQuery}?$select=${relationshipClass}`;
}

function environmentFormDefinitionQuery(projectId: string) {
  const baseQuery = `PW_WSG/Project/${projectId}`;

  const relationshipClass =
    'EnvironmentFormDefinition-forward-Forms_EC_Mapping.FormDefinition!poly.*';

  return `${baseQuery}?$select=${relationshipClass}`;
}

export async function getDocumentCodeFormDefinition(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<FormDefinition | undefined> {
  const query = documentCodeFormDefinitionQuery(projectId);

  const response = await httpService.get(query, {
    ...httpOptions,
    uncached: true
  });

  const formDefinition = await parseFormDefinition(response);
  return formDefinition;
}

function documentCodeFormDefinitionQuery(projectId: string) {
  const baseQuery = `PW_WSG/Project/${projectId}`;

  const relationshipClass =
    'DocumentCodeFormDefinition-forward-Forms_EC_Mapping.FormDefinition.*';

  return `${baseQuery}?$select=${relationshipClass}`;
}

async function parseFormDefinition(
  response: Response
): Promise<FormDefinition | undefined> {
  const formDefinitions =
    await parseResponseRelationshipInstances<FormDefinition>(response);

  if (!formDefinitions?.length) {
    return undefined;
  }

  return formDefinitions[0];
}
