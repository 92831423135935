import React from 'react';
import { ItemIcon } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { useTableItemContext } from '../../../context';
import { t } from '../../../services/translation';

export function PWInfoPanelHeader(): JSX.Element {
  const { actionableItems } = useTableItemContext();

  function panelText(): string {
    if (actionableItems.length === 0) {
      return t('InfoPanel.NothingSelected');
    }

    if (actionableItems.length === 1) {
      return actionableItems[0].Name;
    }

    return t('InfoPanel.ItemsSelected', { count: actionableItems.length });
  }

  return (
    <>
      <ItemIcon item={actionableItems} size="m" />
      <Text
        variant="subheading"
        className="pw-info-header"
        data-testid="info-panel-title"
      >
        {panelText()}
      </Text>
    </>
  );
}
