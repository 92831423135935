import './contextMenu.css';

import _ from 'lodash';
import type { ComponentPropsWithoutRef } from 'react';
import * as React from 'react';
import { useCallback } from 'react';
import { SvgMore } from '@itwin/itwinui-icons-react';
import type { Button } from '@itwin/itwinui-react';
import { DropdownMenu, IconButton } from '@itwin/itwinui-react';
import type { MenuItemAction } from '../toolbar/MenuItemAction';
import { formatMenuItems } from '../toolbar/formatMenuItems';
import { menuItemActionToMenuItem } from '../toolbar/pwToolbar/menuItemActionToMenuItem';

type DataProps = { [K in `data-${string}`]?: string };
type ButtonProps = ComponentPropsWithoutRef<typeof Button> & DataProps;
type DropdownMenuProps = Omit<
  ComponentPropsWithoutRef<typeof DropdownMenu>,
  'menuItems'
>;

type ContextMenuProps = {
  allowCtrlClick?: boolean;
  buttonProps?: ButtonProps;
  dropdownMenuProps?: Partial<DropdownMenuProps>;
  icon?: JSX.Element;
  itemId: string;
  menuOptions: MenuItemAction[];
  selectedIds?: string[];
  onClose?: () => void;
  setSelectedIds?: (ids: string[]) => void;
};

export const ContextMenu = ({
  allowCtrlClick = false,
  buttonProps,
  dropdownMenuProps,
  itemId,
  icon = <SvgMore data-testid={`${itemId}-contextMenu`} />,
  menuOptions,
  selectedIds,
  setSelectedIds,
  onClose
}: ContextMenuProps): JSX.Element => {
  const menuItems = useCallback(
    (close: () => void) => {
      const menuItems = formatMenuItems(menuOptions);

      const dropdownMenuItems = menuItems.map((action) =>
        menuItemActionToMenuItem(action, () => {
          close();
          onClose?.();
        })
      );

      return dropdownMenuItems;
    },
    [menuOptions, onClose]
  );

  function idsToSelectOnClick(ctrlPressed: boolean): string[] {
    if (selectedIds?.includes(itemId)) {
      return selectedIds;
    }

    if (ctrlPressed && allowCtrlClick) {
      return [...(selectedIds ?? []), itemId];
    }

    return [itemId];
  }

  function onContextMenuClick(e: React.MouseEvent): void {
    const newIds = idsToSelectOnClick(e.ctrlKey);
    if (!_.isEqual(newIds, selectedIds)) {
      setSelectedIds?.(newIds);
    }

    e.stopPropagation();
  }

  return (
    <DropdownMenu
      menuItems={menuItems}
      className="context-menu-list"
      placement="right-start"
      {...dropdownMenuProps}
    >
      <IconButton
        {...buttonProps}
        styleType="borderless"
        onClick={onContextMenuClick}
        onDoubleClick={(e) => e.stopPropagation()}
        className="context-menu-button"
      >
        {icon}
      </IconButton>
    </DropdownMenu>
  );
};
