import { useReducer } from 'react';
import { createFormReducer } from './formReducer';
import type {
  FormChangeEvent,
  FormState,
  InputChange,
  InputError
} from './interface';

export type FormManager<K> = {
  form: FormState<K>;
  onChange: (event: FormChangeEvent<K>) => void;
  setError: (error: InputError<K>) => void;
  setWarning: (error: InputError<K>) => void;
  setData: (data: InputChange<K>) => void;
};

export function useForm<K>(
  initialProps: FormState<K>,
  onChangeHook?: (data: InputChange<K>) => void
): FormManager<K> {
  const formReducer = createFormReducer<K>();
  const [form, dispatch] = useReducer(formReducer, {
    ...initialProps,
    pristine: true
  });

  const onChange = (event: FormChangeEvent<K>) => {
    event.persist?.();
    const {
      target: { name, value, checked, type }
    } = event;
    const changedValue = type == 'checkbox' ? checked : value;
    onChangeHook?.({ name, value: changedValue });
    dispatch({
      type: 'ONCHANGE',
      payload: { name, value: changedValue }
    });
  };

  const setError = (error: InputError<K>) => {
    dispatch({ type: 'ONERROR', payload: error });
  };

  const setWarning = (error: InputError<K>) => {
    dispatch({ type: 'ONWARNING', payload: error });
  };

  const setData = (data: InputChange<K>) => {
    dispatch({ type: 'ONCHANGE', payload: data });
  };

  return {
    form,
    onChange,
    setError,
    setWarning,
    setData
  };
}
