import type { PWDataItem, PWItem } from '@bentley/pw-api';
import {
  itemFileExtension,
  itemIsDataItem,
  itemIsParentType
} from '@bentley/pw-api';
import type { RBACPermission } from '../../hooks/useRbac';
import { productionEnvironment } from '../../services/environment';
import type { IModel } from './iModel';
import type { IModelBridge } from './iModelBridge';
import { associatedIModelBridges } from './utils';

export function allowIModelMapping(
  items: PWItem[],
  rbacPermissions: RBACPermission[],
  allItemsExistInConnection?: (items: PWItem[]) => boolean | undefined,
  iModels?: IModel[]
): boolean {
  if (!iModelMappingRbacPermitted(rbacPermissions)) {
    return false;
  }

  if (!items.every(itemIsDataItem)) {
    return false;
  }

  if (iModels == undefined) {
    return false;
  }

  if (!items.every(isMappableFile)) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

export function allowIModelUnmapping(
  items: PWItem[],
  rbacPermissions: RBACPermission[],
  iModelBridges: IModelBridge[]
): boolean {
  if (!iModelMappingRbacPermitted(rbacPermissions)) {
    return false;
  }

  if (items.some(itemIsParentType)) {
    return false;
  }
  const dataItem = items as PWDataItem[];
  if (!dataItem.every(isMappableFile)) {
    return false;
  }

  return dataItem.every(
    (item) => associatedIModelBridges(item, iModelBridges).length > 0
  );
}

export function iModelMappingRbacPermitted(
  rbacPermissions: RBACPermission[]
): boolean {
  if (productionEnvironment() == 'localhost') {
    return true;
  }

  return rbacPermissions.some(
    (permission) => permission.instanceId?.toLowerCase() == 'imhs_modify_imodel'
  );
}

export function isMappableFile(item: PWItem): boolean {
  if (!itemIsDataItem(item)) {
    return false;
  }

  const iModelExtensions = [
    'dgn',
    'rvt',
    'dwg',
    'rte',
    'rfa',
    'rft',
    'vue',
    'ifc'
  ];

  const extension = itemFileExtension(item);
  return iModelExtensions.includes(extension);
}
