import type { ReactNode } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  useEnvironmentContext,
  useHttpService,
  usePluginContext,
  useTableItemContext
} from '../../../context';
import { t } from '../../../services/translation';
import { setAttributesHttpService } from '../../attributesForm/data';
import { generateReloadFormAction } from '../../documentCreation/form';
import { setDCWHttpService } from '../../documentCreation/state';
import { LoadingPage } from '../../loadingPage';
import { getDocumentEnvironmentInstanceWithDefault } from '../data';
import { DocCodeError } from '../error/docCodeError';
import { handleDocCodeFormError } from '../error/handleError';
import { formDefinitionState, projectIdState } from '../form';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState,
  originalEnvironmentInstanceState
} from './state';

type DocCodeStateProvider = {
  children?: ReactNode;
};

export function DocCodeStateProvider({
  children
}: DocCodeStateProvider): JSX.Element {
  const { contextId } = usePluginContext();
  const httpService = useHttpService();

  const {
    environmentManager: { invalidDocCodeEnvironments }
  } = useEnvironmentContext();
  const {
    dataManager: { isLoading: dataManagerLoading },
    actionableItems
  } = useTableItemContext();

  const [formDefinition, setFormDefinition] =
    useRecoilState(formDefinitionState);
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);

  const setProjectId = useSetRecoilState(projectIdState);
  const setOriginalEnvironmentInstance = useSetRecoilState(
    originalEnvironmentInstanceState
  );
  const setEditingDocCodeForm = useSetRecoilState(editingDocCodeFormState);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialized = useMemo((): boolean => {
    if (currentEnvironmentInstance == undefined) {
      return false;
    }

    if (formDefinition === undefined) {
      return false;
    }

    if (dataManagerLoading) {
      return false;
    }

    return true;
  }, [currentEnvironmentInstance, dataManagerLoading, formDefinition]);

  // Used for saving document environment instance
  useEffect(() => {
    setAttributesHttpService(httpService);
  }, [httpService]);

  // Used for reloading form and doc code preview
  useEffect(() => {
    setDCWHttpService(httpService);
  }, [httpService]);

  useEffect(() => {
    setProjectId(contextId);
  }, [contextId, setProjectId]);

  useEffect(() => {
    async function tryInitializeEnvironmentInstance(): Promise<void> {
      if (dataManagerLoading) {
        return;
      }

      try {
        const environmentInstance =
          await getDocumentEnvironmentInstanceWithDefault(
            actionableItems[0],
            httpService
          );

        setOriginalEnvironmentInstance(environmentInstance);
        setCurrentEnvironmentInstance(environmentInstance);
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          const errorMessage = await handleDocCodeFormError(error);
          setErrorMessage(errorMessage);
        }
      }
    }

    setOriginalEnvironmentInstance(undefined);
    setCurrentEnvironmentInstance(undefined);
    setErrorMessage('');
    void tryInitializeEnvironmentInstance();
  }, [
    actionableItems,
    dataManagerLoading,
    httpService,
    setCurrentEnvironmentInstance,
    setErrorMessage,
    setOriginalEnvironmentInstance
  ]);

  useEffect(() => {
    async function reloadFormDef(): Promise<void> {
      if (dataManagerLoading || !currentEnvironmentInstance) {
        return;
      }

      if (
        invalidDocCodeEnvironments.current.has(
          currentEnvironmentInstance.className
        )
      ) {
        setErrorMessage(t('DocCode.DocCodeUnsupported'));
        return;
      }

      try {
        const formDefinition = await generateReloadFormAction(
          'documentcode',
          currentEnvironmentInstance,
          httpService
        );

        setFormDefinition(formDefinition);
      } catch (error) {
        invalidDocCodeEnvironments.current.add(
          currentEnvironmentInstance.className
        );
        setErrorMessage(t('DocCode.DocCodeUnsupported'));
      }
    }

    setErrorMessage('');
    void reloadFormDef();
  }, [
    actionableItems,
    currentEnvironmentInstance,
    dataManagerLoading,
    invalidDocCodeEnvironments,
    httpService,
    setErrorMessage,
    setFormDefinition
  ]);

  useEffect(() => {
    setEditingDocCodeForm(false);
  }, [actionableItems, dataManagerLoading, setEditingDocCodeForm]);

  if (errorMessage) {
    return <DocCodeError errorMessage={errorMessage} />;
  }

  if (!initialized) {
    return <LoadingPage />;
  }

  return <>{children}</>;
}
