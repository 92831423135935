import { useCallback } from 'react';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { useGraphApiContext } from '../../context';
import { useOutToMeSearch } from '../useOutToMeSearch';

type OpenCoAuthoring = (item: PWItem, refreshCurrentFolder: () => void) => void;

export function useOpenCoAuthoring(): OpenCoAuthoring {
  const {
    checkCoAuthoringSessionExists,
    startCoAuthoringSession,
    joinCoAuthoringSession
  } = useGraphApiContext();
  const outToMe = useOutToMeSearch();
  const refreshOutToMe = useCallback(() => {
    void outToMe();
  }, [outToMe]);

  const openCoAuthoring = useCallback(
    (item: PWItem, refreshCurrentFolder: () => void) => {
      const onComplete = () => {
        refreshCurrentFolder();
        refreshOutToMe();
      };

      if (checkCoAuthoringSessionExists(item)) {
        void joinCoAuthoringSession(item as PWFileType, onComplete);
      } else {
        void startCoAuthoringSession(item as PWFileType, onComplete);
      }
    },
    [
      checkCoAuthoringSessionExists,
      joinCoAuthoringSession,
      refreshOutToMe,
      startCoAuthoringSession
    ]
  );

  return openCoAuthoring;
}
