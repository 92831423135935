import React, { useCallback, useMemo } from 'react';
import { SvgInfoCircular } from '@itwin/itwinui-icons-react';
import { CurrentConnectionDialog } from '../../../../actions/currentConnectionModal';
import { useFeatureTracking, useNavigationContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useAboutConnectionOption(): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const { primaryModal } = useNavigationContext();

  const openAboutConnectionDialog = useCallback(() => {
    primaryModal.open(<CurrentConnectionDialog />);
    trackFeature('ABOUT_CURRENT_CONNECTION_MODAL');
  }, [primaryModal, trackFeature]);

  const aboutConnectionOption = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.AboutConnection'),
      icon: <SvgInfoCircular />,
      onClick: openAboutConnectionDialog
    }),
    [openAboutConnectionDialog]
  );

  return aboutConnectionOption;
}
