import React, { useCallback, useMemo } from 'react';
import { SvgSearch } from '@itwin/itwinui-icons-react';
import {
  useAppViewContext,
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import type { AdvancedSearch } from '../../../../hooks/useSearchState';
import { t } from '../../../../services/translation';
import AdvancedSearchModal from '../../../advancedSearch/advancedSearchModal';
import type { MenuItemAction } from '../../MenuItemAction';

export function useAdvancedSearchAction(): MenuItemAction {
  const { ecPluginFeatures } = useEnvironmentContext();
  const { connection } = usePluginContext();
  const {
    primaryModal,
    searchState: { setAdvancedSearch }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  const showFTRSearch = useMemo((): boolean => {
    if (ecPluginFeatures.allContentSearch()) {
      return false;
    }

    if (!ecPluginFeatures.fullTextSearch()) {
      return false;
    }

    return true;
  }, [ecPluginFeatures]);

  const onSearch = useCallback(
    (search: AdvancedSearch): void => {
      setAdvancedSearch(search);
      setAppView('AdvancedSearch');
    },
    [setAdvancedSearch, setAppView]
  );

  const onAdvancedSearchClick = useCallback((): void => {
    primaryModal.open(
      <AdvancedSearchModal onClose={primaryModal.close} onSearch={onSearch} />
    );
  }, [primaryModal, onSearch]);

  const advancedSearch = useMemo(
    (): MenuItemAction => ({
      title: t('Search.AdvancedSearch'),
      icon: <SvgSearch />,
      startGroup: showFTRSearch,
      hidden: connection.Canned || currentAppView.type == 'OutToMe',
      onClick: onAdvancedSearchClick
    }),
    [
      showFTRSearch,
      connection.Canned,
      currentAppView.type,
      onAdvancedSearchClick
    ]
  );

  return advancedSearch;
}
