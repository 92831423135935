import type {
  ErrorType,
  FormState,
  InputChange,
  InputError
} from './interface';

const ONCHANGE = 'ONCHANGE';
const ONERROR = 'ONERROR';
const ONWARNING = 'ONWARNING';

export type FormAction<K> =
  | { type: typeof ONCHANGE; payload: InputChange<K> }
  | { type: typeof ONERROR; payload: InputError<K> }
  | { type: typeof ONWARNING; payload: InputError<K> };

export const createFormReducer =
  <K>() =>
  (formState: FormState<K>, action: FormAction<K>): FormState<K> => {
    switch (action.type) {
      case 'ONCHANGE':
        return updateInput(formState, action.payload);
      case 'ONERROR':
        return updateError(formState, action.payload);
      case 'ONWARNING':
        return updateWarning(formState, action.payload);
    }
  };

function validateInput<K>(formState: FormState<K>): FormState<K> {
  const errors = Object.values(formState.errors).filter(
    (error) => (error as ErrorType).errorType == 'error'
  );
  const anyErrors = errors.length > 0;
  return {
    ...formState,
    valid: anyErrors ? false : formState.isFormValid(formState.inputs)
  };
}

function updateInput<K>(
  formState: FormState<K>,
  { name, value, type }: InputChange<K>
): FormState<K> {
  if (formState.inputs[name] == value) {
    // Nothing changed, skip validation to prevent occasional infinite loops
    return formState;
  }
  const {
    name: errorKey,
    value: errorMessage,
    type: errorType
  } = formState.validateInput({ name, value, type }, formState.inputs);
  return validateInput({
    ...formState,
    inputs: {
      ...formState.inputs,
      [name]: value
    },
    errors: {
      ...formState.errors,
      [errorKey]: { errorMessage, errorType }
    },
    pristine: false
  });
}

function updateError<K>(
  formState: FormState<K>,
  { name, value }: InputError<K>
): FormState<K> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const errorType = value && (value as any).length > 0 ? 'error' : '';

  return validateInput({
    ...formState,
    errors: {
      ...formState.errors,
      [name]: { errorMessage: value, errorType }
    }
  });
}

function updateWarning<K>(
  formState: FormState<K>,
  { name, value }: InputError<K>
): FormState<K> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const errorType = value && (value as any).length > 0 ? 'warning' : '';

  return validateInput({
    ...formState,
    errors: {
      ...formState.errors,
      [name]: { errorMessage: value, errorType }
    }
  });
}
