// Descriptions should be unique
// FeatureIds should be unique (can create guid in visual studio > tools > create guid)
// Descriptions and FeatureIds should not change once registered

export type Feature = {
  Description: string;
  FeatureId: string;
  IsChargeable: boolean;
};

export type FeatureName = keyof typeof features;

export const features = {
  ABOUT_CURRENT_CONNECTION_MODAL: {
    Description: 'Right Toolbar  > About Current Connection',
    FeatureId: '9C0F0A3B-F49C-4004-A629-07BAD3B59E3E',
    IsChargeable: true
  },
  ADVANCED_SEARCH: {
    Description: 'Advance Search > Advance Search Run',
    FeatureId: '337286D8-E8D7-4667-ACB2-22E187322CB2',
    IsChargeable: true
  },
  ALL_CONTENT_SEARCH: {
    Description: 'Basic Search > All Content Search',
    FeatureId: '7787FC4F-9B80-4C34-A223-96F971F9C701',
    IsChargeable: true
  },
  ADD_SET_ITEM_ACTION_MENU: {
    Description: 'Flat Set Action > Add Set Item From Action Menu',
    FeatureId: '7B5A24A6-639A-43CF-8866-D6850E699CED',
    IsChargeable: true
  },
  ADD_SET_ITEM_INSIDE_SET: {
    Description: 'Flat Set Action > Add Set Item From Inside Set',
    FeatureId: '3E0C36B7-0474-4551-814D-C6CFFF6DE718',
    IsChargeable: true
  },
  BREADCRUMB_NAVIGATE_ANCESTOR: {
    Description: 'Breadcrumb > Navigate Ancestor',
    FeatureId: 'F9312FF3-E1FC-4EFD-AE6E-51169269BEA3',
    IsChargeable: true
  },
  BREADCRUMB_NAVIGATE_PARENT: {
    Description: 'Breadcrumb > Navigate Parent',
    FeatureId: '49694B46-67B3-4876-82E9-374A7265EBCC',
    IsChargeable: true
  },
  CANNED_CONNECTION_DISABLED: {
    Description: 'Canned Connection > Disable Canned Connection',
    FeatureId: '29F66894-3C4C-43DA-A28D-331827E4AD58',
    IsChargeable: true
  },
  CANNED_CONNECTION_ENABLED: {
    Description: 'Canned Connection > Enable Canned Connection',
    FeatureId: '5CA8A1A7-E7E6-412A-8A09-1DFE196DA9BB',
    IsChargeable: true
  },
  CHANGE_ENVIRONMENT_ATTRIBUTE_INTERFACE: {
    Description:
      'Environment Attributes > Change Environment Attribute Interface',
    FeatureId: 'D3194DD3-83E4-467C-B50A-452462B36A72',
    IsChargeable: true
  },
  CHECKIN_FILE: {
    Description: 'File Action > Checkin',
    FeatureId: '6DA7D145-1549-457A-81B9-57691F1EA6A9',
    IsChargeable: true
  },
  CHECKIN_FILE_DRIVE: {
    Description: 'File Action > Checkin through pw drive',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A25',
    IsChargeable: true
  },
  CLOSE_O365_SESSION_WHILE_FILE_STILL_BEING_USED: {
    Description:
      'O365 Integration > Free or Check in O365 Session while file is still being used by others',
    FeatureId: '280AE178-70A0-4A50-A7BF-66D432270C3E',
    IsChargeable: true
  },
  CHECKOUT_FILE: {
    Description: 'File Action > Checkout',
    FeatureId: '7995022F-A0FF-406E-9A84-5B138C759E93',
    IsChargeable: true
  },
  CHECKOUT_FILE_DRIVE: {
    Description: 'File Action > Checkout when pw drive sync is turned on',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A24',
    IsChargeable: true
  },
  CREATE_RENDITION: {
    Description: 'File Action > Create Rendition',
    FeatureId: '16DE7FA3-D395-4C8E-8F27-D67813630E10',
    IsChargeable: true
  },
  CREATE_SET_FROM_SELECTED_ITEMS: {
    Description: 'Flat Set Action> Create Flat Set From Selected Items',
    FeatureId: 'A9217E74-5EA9-4E6F-95C7-D9B35F921D04',
    IsChargeable: true
  },
  CREATE_SET_WITH_ITEMS_PICKER: {
    Description: 'Flat Set Action> Create Flat Set By Selecting Items',
    FeatureId: '8E71CA18-34EC-4D60-8616-ABCBE8AC9659',
    IsChargeable: true
  },
  DCW_OPEN: {
    Description: 'DCW > Open',
    FeatureId: 'CFCCEA3B-F22A-453E-95C1-B553CCE1D9D0',
    IsChargeable: true
  },
  DELETE_DOCUMENT: {
    Description: 'Delete > Delete Document',
    FeatureId: '25F4F05F-7CEC-4B0D-9C7C-FA984BE016AB',
    IsChargeable: true
  },
  DELETE_FOLDER: {
    Description: 'Delete > Delete Folder',
    FeatureId: '864AB4FB-F953-4A80-A2C6-54955486776A',
    IsChargeable: true
  },
  DOWNLOAD_FLAT_SET: {
    Description: 'Download > Flat Set',
    FeatureId: '4BE8FB59-D49C-4951-BA11-B3C485CC8F89',
    IsChargeable: true
  },
  DOWNLOAD_LOGICAL_SET: {
    Description: 'Download > Logical Set',
    FeatureId: '88DE27E6-1245-4AE3-9137-6FDBE268EABB',
    IsChargeable: true
  },
  DOWNLOAD_MULTIPLE: {
    Description: 'Download > Multiple Items',
    FeatureId: 'A61306FC-9736-4CE9-94C7-D6BDC634FB73',
    IsChargeable: true
  },
  DOWNLOAD_SINGLE_FILE: {
    Description: 'Download > File',
    FeatureId: 'F8C2E37A-9B40-470F-AC57-DCA89E7B374A',
    IsChargeable: true
  },
  DOWNLOAD_SINGLE_FOLDER: {
    Description: 'Download > Folder',
    FeatureId: '75FFECA9-8734-4CF0-827B-DE1823488F52',
    IsChargeable: true
  },
  DOWNLOAD_LOGS: {
    Description: 'Manage Connections > Download Logs',
    FeatureId: 'D0396272-BD38-4417-A101-B0EBFFB92C07',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_SET_PROJECT_SETTINGS: {
    Description: 'Dynamic Settings > Set new project settings',
    FeatureId: '6327DB21-96CF-4533-9786-4A7D525BD3A3',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_SET_FOLDER_SETTINGS: {
    Description: 'Dynamic Settings > Set new folder settings',
    FeatureId: '51D1B9F5-CD4B-40D1-B74B-8A3DF2A54CFB',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_DELETE_FOLDER_SETTINGS: {
    Description: 'Dynamic Settings > Delete folder settings',
    FeatureId: '80BC1C44-59D0-4DB8-BFED-EBBE00512683',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_FORCE_DESCENDANT_INHERITANCE: {
    Description: 'Dynamic Settings > Delete all descendant folder settings',
    FeatureId: '8FA88840-E943-469D-A3B7-8DF66683F7F0',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_AUTOMATIC_CHECKOUT_ON: {
    Description: 'Dynamic Settings > Turn on automatic checkout through drive',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A27',
    IsChargeable: true
  },
  DYNAMIC_SETTINGS_AUTOMATIC_CHECKOUT_OFF: {
    Description: 'Dynamic Settings > Turn off automatic checkout through drive',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A28',
    IsChargeable: true
  },
  EDIT_DOC_CODE_MODAL: {
    Description: 'Doc code > Editing with doc code modal',
    FeatureId: '6d3eb338-f23e-4236-9c99-04972c123a3f',
    IsChargeable: true
  },
  EDIT_DOC_CODE_TAB: {
    Description: 'Doc code > Editing with info panel tab',
    FeatureId: '9c8551ad-d3d9-499d-9b6e-341c4e2f532f',
    IsChargeable: true
  },
  END_0365_SESSION: {
    Description: 'O365 Integration > End O365 Session',
    FeatureId: '355E352D-47C3-491C-A2A3-214A13846453',
    IsChargeable: true
  },
  EXPORT_AUDIT_TRAIL: {
    Description: 'Export Audit Trail',
    FeatureId: '5DD8B649-6CB9-4E9D-829B-816EDC52C28A',
    IsChargeable: true
  },
  FILE_SET_FINAL: {
    Description: 'File Action > Set Final',
    FeatureId: '27CD6A07-BC7E-4500-A7D0-1C875BCBB314',
    IsChargeable: true
  },
  FILE_UNSET_FINAL: {
    Description: 'File Action > Unset Final',
    FeatureId: '9CAFDFB7-5083-4056-9F96-919DA9458E74',
    IsChargeable: true
  },
  FORM_SAVE_EDIT_ATTRIBUTES: {
    Description: 'Info Panel > Attributes',
    FeatureId: '9B6D9EFA-54E9-466C-88C2-3476BEE50ED7',
    IsChargeable: true
  },
  FREE_FILE: {
    Description: 'File Action > Free',
    FeatureId: '079DA479-C7D8-4C9E-A6AD-E60D490303A9',
    IsChargeable: true
  },
  FREE_FILE_DRIVE: {
    Description: 'File Action > Free through pw drive',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A26',
    IsChargeable: true
  },
  FREE_O365_SESSION: {
    Description: 'O365 Integration > Freeing O365 Session',
    FeatureId: '9DD67FAC-4CF9-4B2C-B616-AAD8823A1D74',
    IsChargeable: true
  },
  GRID_CUSTOMIZE_COLUMNS: {
    Description: 'Grid > Customize Columns',
    FeatureId: '4C8073CF-AA84-41F8-AB4F-417E7C9F4D9D',
    IsChargeable: true
  },
  GRID_DISPLAY_ALL_VERSIONS: {
    Description:
      'Grid Toolbar > Version Display Options > Display All Versions',
    FeatureId: 'D2F8F623-F11F-46E2-BF66-EFABC7E00C5E',
    IsChargeable: true
  },
  GRID_DISPLAY_LATEST_VERSION: {
    Description:
      'Grid Toolbar > Version Display Options > Display Latest Version',
    FeatureId: 'FB0253EA-4C49-439B-A9EE-0D436B60A69A',
    IsChargeable: true
  },
  GRID_DISPLAY_PW_DRIVE_SYNC_PROJECT_OFF: {
    Description: 'Grid Toolbar > Sync Project Options > Project Sync OFF',
    FeatureId: 'AB0253EA-4C49-439B-A9EE-0D436B60A69A',
    IsChargeable: true
  },
  GRID_DISPLAY_PW_DRIVE_SYNC_PROJECT_ON: {
    Description: 'Grid Toolbar > Sync Project Options > Project Sync ON',
    FeatureId: 'A2F8F623-F11F-46E2-BF66-EFABC7E00C5E',
    IsChargeable: true
  },
  GRID_FILTER_COLUMNS: {
    Description: 'Grid > Filter Column',
    FeatureId: '1C374BCE-5E09-4CD2-91D3-BE9EBE086634',
    IsChargeable: true
  },
  GRID_INFO_PANEL: {
    Description: 'Grid Toolbar > Open Info Panel',
    FeatureId: '09FE9BF4-721A-4E70-AA56-48B55233BF03',
    IsChargeable: true
  },
  GRID_OPEN_COLUMN_MANAGER: {
    Description: 'Grid Toolbar > Open Column Manager',
    FeatureId: '123d5d13-fe06-465d-9cd3-ef6c78028e0f',
    IsChargeable: true
  },
  GRID_OPEN_MANAGE_MY_VIEWS: {
    Description: 'Grid Toolbar > Open Manage My Views',
    FeatureId: 'cc0ece21-5a5f-456f-adef-856d688b9fd6',
    IsChargeable: true
  },
  GRID_OPEN_CUSTOMIZE_COLUMN: {
    Description: 'Grid Toolbar > Open Customize Column',
    FeatureId: '93189914-0246-419C-8ECF-DF7092273738',
    IsChargeable: true
  },
  GRID_OPEN_SET_VIEW: {
    Description: 'Grid Toolbar > Open Set View',
    FeatureId: '09716457-853C-4B8D-8876-1010781110F7',
    IsChargeable: true
  },
  GRID_REFRESH: {
    Description: 'Grid Toolbar > Refresh Button',
    FeatureId: '7E4A957D-DB3E-4AF0-B199-0238EAF27932',
    IsChargeable: true
  },
  GRID_SET_VIEW: {
    Description: 'Grid > Set View',
    FeatureId: '656AA922-7FB2-4ADF-8C39-9A7689622DE9',
    IsChargeable: true
  },
  GRID_SORT_COLUMNS: {
    Description: 'Grid > Sort Column',
    FeatureId: 'C78EE39A-34EF-4DE7-93F0-33E09CA3A152',
    IsChargeable: true
  },
  INFO_PANEL_AUDIT_TRAIL: {
    Description: 'Info Panel > Audit Trail',
    FeatureId: '74BF6722-7495-4A71-BEB2-E32F61CB48AE',
    IsChargeable: true
  },
  INFO_PANEL_AUDIT_TRAIL_DETAILS: {
    Description: 'Info Panel > Audit Trail > Details',
    FeatureId: 'ABB2DE62-4D6A-4CAF-A6D9-DE39030BEB67',
    IsChargeable: true
  },
  INFO_PANEL_DOC_CODE: {
    Description: 'Info Panel > Doc Code',
    FeatureId: '3F2504E0-4F89-11D3-9A0C-0305E82C3301',
    IsChargeable: true
  },
  INFO_PANEL_EMPTY_VIEW: {
    Description: 'Info Panel > Empty View',
    FeatureId: '0B6AD9AB-8A0E-41B4-AF27-7C328CC15CF0',
    IsChargeable: true
  },
  INFO_PANEL_ENV_ATTR: {
    Description: 'Info Panel > Environment Attributes',
    FeatureId: '59FE61C6-7D57-4ACB-A805-38F073E8AE5E',
    IsChargeable: true
  },
  INFO_PANEL_MULTI_VIEW: {
    Description: 'Info Panel > Multi View',
    FeatureId: '1B60B470-9D78-4648-8FC4-0F57A506CB03',
    IsChargeable: true
  },
  INFO_PANEL_SINGLE_VIEW: {
    Description: 'Info Panel > Single View',
    FeatureId: 'BCB6242A-D209-4D34-8F67-C27248AB12CD',
    IsChargeable: true
  },
  JOIN_O365_SESSION: {
    Description: 'O365 Integration > Join O365 Session',
    FeatureId: '8D6BAEA9-14FA-4238-A0D4-14C4BD36FBCE',
    IsChargeable: true
  },
  LINK_ARRIVE_V2: {
    Description: 'Shareable Link > Arrive Via Universal Link',
    FeatureId: 'A2F8A54A-4BE2-4C36-99BD-94530312153E',
    IsChargeable: true
  },
  LINK_GENERATE: {
    Description: 'Shareable Link > Generate Link',
    FeatureId: '7E1BF46C-39B2-482A-A5CF-9F079BB8901A',
    IsChargeable: true
  },
  LOGICAL_SET_REFERENCE_NO: {
    Description: 'Logical Set > Without Referenced Files',
    FeatureId: 'C4DFD807-5786-45AC-B140-51363DD99DEB',
    IsChargeable: true
  },
  LOGICAL_SET_REFERENCE_YES: {
    Description: 'Logical Set > With Referenced Files',
    FeatureId: 'A1DD20C9-4E82-4FDA-B9A4-C5E5A97E8221',
    IsChargeable: true
  },
  LOCK_TO_VERSION_SET: {
    Description: 'Flat Set Action > Lock To Version',
    FeatureId: '17B30A91-4B7B-45B5-984F-58758EB9443B',
    IsChargeable: true
  },
  MAP_TO_IMODEL: {
    Description: 'Map file to iModel',
    FeatureId: '067a7a04-4abb-4cb9-891d-d1043b549f99',
    IsChargeable: true
  },
  MAP_IMODEL_TO_SAVED_SEARCH: {
    Description: 'iModel > Map iModel to Saved Search',
    FeatureId: '3939AC17-1872-4C8D-A95A-A6B2E0A04954',
    IsChargeable: true
  },
  MC_DELETE: {
    Description: 'Manage Connections > Delete Connection',
    FeatureId: 'B14D7ED6-4C82-44DB-8097-690373943C64',
    IsChargeable: true
  },
  MC_EDIT: {
    Description: 'Manage Connections > Edit Connection',
    FeatureId: '7D26AA71-2A31-483F-862F-B2079E5D778A',
    IsChargeable: true
  },
  MC_ENTER_DS: {
    Description: 'Manage Connections > Enter Datasource',
    FeatureId: '28702E4A-2F93-4D58-A346-9F93DD08B917',
    IsChargeable: false
  },
  MC_DRIVE_NO_PRIMARY: {
    Description: 'Manage Connections > Drive Connection without Primary',
    FeatureId: '712D4FF6-A905-45EB-8440-9F72A061D4A7',
    IsChargeable: false
  },
  MC_NEW: {
    Description: 'Manage Connections > New Connection',
    FeatureId: 'FB21085B-15EF-4BE5-B57C-87CEDAD6B86B',
    IsChargeable: true
  },
  MC_PRIMARY: {
    Description: 'Manage Connections > Primary Connetion',
    FeatureId: '60B79E05-B62B-4883-9518-9E1499E20356',
    IsChargeable: true
  },
  MC_AUTO_USER_SYNC: {
    Description: 'Manage Connections > Auto User Sync',
    FeatureId: 'FC5405BA-A038-4F67-ADAB-1121A95A270D',
    IsChargeable: true
  },
  MC_MANUAL_USER_SYNC: {
    Description: 'Manage Connections > Manual User Sync',
    FeatureId: 'A777C5F8-0C3C-4A80-B305-67C5DDBAAE6B',
    IsChargeable: true
  },
  MOVE_ITEMS: {
    Description: 'Move Items',
    FeatureId: '0C397931-7514-4ECE-859F-83244925C56A',
    IsChargeable: true
  },
  NAVIGATE_FLATSET: {
    Description: 'Grid Navigation > FlatSet',
    FeatureId: 'a8f60cdf-671a-4370-b9ae-08a8b2c2043d',
    IsChargeable: true
  },
  NAVIGATE_FOLDER: {
    Description: 'Grid Navigation > Folder',
    FeatureId: '0ea199e1-52d6-4a7a-b0b5-51c4e9f7bcc8',
    IsChargeable: true
  },
  NEW_VERSION: {
    Description: 'Create New Version',
    FeatureId: 'A07259D3-1ED2-4713-AD69-127E8F252443',
    IsChargeable: true
  },
  O365_ENABLED: {
    Description: 'Document Settings > O365 Settings is turned on',
    FeatureId: '38B350F4-6EDA-466A-9F19-CF79CB63E2FD',
    IsChargeable: true
  },
  O365_DISABLED: {
    Description: 'Document Settings > O365 Settings is turned off',
    FeatureId: '0FCB54BE-A430-43E5-8C56-8C7626F8F948',
    IsChargeable: true
  },
  OUT_TO_ME_ACCESS: {
    Description: 'Access Out To Me View',
    FeatureId: '6F06C43F-5D49-4042-A931-CBDB528380C6',
    IsChargeable: true
  },
  OUT_TO_ME_CHECKIN_FILE: {
    Description: 'Out To Me View > Check in File',
    FeatureId: '3A215740-5974-40A7-85D3-5033415D6EA0',
    IsChargeable: true
  },
  OPEN_IN_DESKTOP_APP_OFFICE_FILE: {
    Description: 'Open in desktop app > open office file',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A22',
    IsChargeable: true
  },
  OPEN_IN_DESKTOP_APP_OTHER: {
    Description: 'Open in desktop app > open other file type',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A23',
    isChargeable: true
  },
  OPEN_O365_SESSION_DRIVE: {
    Description: 'O365 Integration > Open O365 Session with drive',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A29',
    IsChargeable: true
  },
  OPEN_OLDER_CONNECTIONS: {
    Description: 'Open Older Connections Link Clicked',
    FeatureId: '43EB0774-89B1-47E3-BCD0-E8D39691FEC2',
    IsChargeable: true
  },
  OPEN_IN_PROJECTWISE: {
    Description: 'Open in ProjectWise',
    FeatureId: '06D69904-5929-4CA9-BC95-EE16FA85D71B',
    IsChargeable: true
  },
  OPEN_PDFMARKUP_READONLY: {
    Description: 'PDF Markup > Open read only',
    FeatureId: 'C29DDA8B-CDBC-447E-BCD5-D1F913562EB1',
    IsChargeable: true
  },
  OPEN_PDFMARKUP_RW: {
    Description: 'PDF Markup > Open editable file',
    FeatureId: 'B5858BDB-A23E-4057-9279-FE619DE94A21',
    IsChargeable: true
  },
  OPEN_PWDM: {
    Description: 'PWDM > Start PWDM processing',
    FeatureId: '92635D44-F548-46C3-9E08-517C8658E0E2',
    IsChargeable: true
  },
  OPEN_VIEWER_IN_CURRENT_TAB: {
    Description: 'Document viewer > Open in current tab',
    FeatureId: 'C3C36993-F637-44CF-9AD8-8B1C320AB1B4',
    IsChargeable: true
  },
  OPEN_VIEWER_IN_NEW_TAB: {
    Description: 'Document viewer > Open in new tab',
    FeatureId: '900B1714-63EC-4782-9111-9FD00CFD7BDD',
    IsChargeable: true
  },
  PROJECTWISE_LINKS: {
    Description: 'PROJECTWISE LINKS',
    FeatureId: '4904B0A9-4A73-498B-B859-25303B95BAA2',
    IsChargeable: true
  },
  REMOVE_SET_ITEM: {
    Description: 'Flat Set Action > Remove Set Item',
    FeatureId: '7A2553B3-7474-4FC6-AE89-B28BA4726F41',
    IsChargeable: true
  },
  RENAME_ITEM: {
    Description: 'Rename > Item Name',
    FeatureId: 'ECDCA36F-E0C2-4BAC-BD77-D9069E78A14F',
    IsChargeable: true
  },
  RENAME_ITEM_FILENAME: {
    Description: 'Rename > Item > File Name',
    FeatureId: '12C12D90-70B4-4F17-B607-6CD5C7AE157D',
    IsChargeable: true
  },
  REPLACE_FILE: {
    Description: 'Replace File',
    FeatureId: '4FCE6335-BE9A-4CB9-9113-0CD07C538281',
    IsChargeable: true
  },
  REPLACE_FILE_IN_FLATSET: {
    Description: 'Replace File In Flatset',
    FeatureId: 'C0B2F86E-CFEE-4746-A350-D596144A1EB7',
    IsChargeable: true
  },
  SAVE_MODIFIED_ATTRIBUTE: {
    Description: 'Environment Attributes > Save Modified Attributes',
    FeatureId: '5E66D0D8-64C1-4413-A158-9D5CE9579BF3',
    IsChargeable: true
  },
  SAVE_MY_VIEW: {
    Description: 'My Views > Save My View',
    FeatureId: '0C8823D2-C6B2-4EC9-869B-36C3817E2F43',
    IsChargeable: true
  },
  SELECT_MY_VIEW: {
    Description: 'My Views > Select My View',
    FeatureId: 'FADEA988-50BE-40CC-94CB-3D0DAF84CF4D',
    IsChargeable: true
  },
  SAVED_ADVANCED_SEARCH_CREATED: {
    Description: 'Saved Search > Create Advanced Search',
    FeatureId: 'B0A8AC33-B657-4BDB-9571-1F350757B159',
    IsChargeable: true
  },
  SAVED_ADVANCED_SEARCH_RUN: {
    Description: 'Saved Search > Run Advanced Search',
    FeatureId: '2E3B2EA8-89C6-4779-AF3C-B5739976BF7D',
    IsChargeable: true
  },
  START_O365_SESSION: {
    Description: 'O365 Integration > Start O365 Session',
    FeatureId: 'D8D14EFF-76EB-4794-9DD6-F7748354451C',
    IsChargeable: true
  },
  SAVED_SEARCH: {
    Description: 'Saved Search > Saved Search Run',
    FeatureId: '957183ba-8d8a-4a11-9baa-41f2d817f7f5',
    IsChargeable: true
  },
  SEARCH: {
    Description: 'Search',
    FeatureId: '68593B54-A7F7-43CD-B58A-98D0E449DE1C',
    IsChargeable: true
  },
  SET_FOLDER_ACCESS_DIALOGUE: {
    Description: 'Canned Connection > Set Folder Access Dialogue Opened',
    FeatureId: '7ACBB1FB-63CD-4ABD-A25B-AB05696DEEB3',
    IsChargeable: true
  },
  SHARE_CONNECTIONS_CLICKED: {
    Description: 'Document Settings > Share Connections Button Clicked',
    FeatureId: 'E2724C92-B01F-4BAB-BDB9-0E20E7FA2410',
    IsChargeable: true
  },
  SIDE_NAV_COLLAPSE: {
    Description: 'Side Navigation > Collapse',
    FeatureId: 'B839072C-B235-4CE2-81C1-2E80E623ABB8',
    IsChargeable: false
  },
  SIDE_NAV_ENTER_DS: {
    Description: 'Side Navigation > Enter Datasource',
    FeatureId: '622B75A9-1AE3-4F7E-9CDE-2C18CF816487',
    IsChargeable: false
  },
  SIDE_NAV_EXPAND: {
    Description: 'Side Navigation > Expand',
    FeatureId: '801FE57B-53F6-411E-8C14-68E946782F3A',
    IsChargeable: false
  },
  SIDE_NAV_GOTO_MC: {
    Description: 'Side Navigation > Enter ManagedConnections',
    FeatureId: '507A6070-FD7F-4420-ADBA-CF8A98A6AB95',
    IsChargeable: false
  },
  SIDE_NAV_NO_DATASOURCES_NO_ADMIN: {
    Description: 'Side Navigation > No Datasources loaded for Non-Admin user',
    FeatureId: '9643A432-171D-4955-BE37-432B108703EF',
    IsChargeable: false
  },
  SIGN_IN_FEDERATED_FAILURE: {
    Description: 'Connection > Federated Sign In Failure',
    FeatureId: 'F7E8C907-22E6-4f2A-8fE6-5C51B51A4fA1',
    IsChargeable: false
  },
  SIGN_IN_FEDERATED_SUCCESS: {
    Description: 'Connection > Federated Sign In Success',
    FeatureId: '71B50094-582E-419F-A477-0A5E42221B17',
    IsChargeable: true
  },
  SIGN_IN_LOGICAL_FAILURE: {
    Description: 'Connection > Logical Sign In Failure',
    FeatureId: '4BC4D68E-E5BF-4D97-8352-6CC72A3BCF1D',
    IsChargeable: false
  },
  SIGN_IN_LOGICAL_SUCCESS: {
    Description: 'Connection > Logical Sign In Success',
    FeatureId: '5B94D27F-5477-414C-8C38-13F572E9A347',
    IsChargeable: true
  },
  SIGN_OUT: {
    Description: 'Connection > Signed Out',
    FeatureId: '235E8EF7-18B2-4863-A7BD-53562DF56913',
    IsChargeable: false
  },
  SYNCHRONIZE_NOW_CLICK: {
    Description: 'Manage Connections > Click Synchronize Now Button',
    FeatureId: 'CA0DEAA3-ABDA-44AE-8E81-4EF5EF6D40B9',
    IsChargeable: true
  },
  UNDO_ATTRIBUTE_EDIT: {
    Description: 'Environment Attributes > Undo Attribute Edit',
    FeatureId: '804E7ED3-C46D-4308-A84E-403252CA8DC3',
    IsChargeable: true
  },
  UPDATE_PROJECT_ASSOCIATION: {
    Description: 'Update Project Association',
    FeatureId: 'B7A9D05F-E315-4D3B-81DA-785D2ACFE262',
    IsChargeable: true
  },
  UPDATE_PROJECT_ASSOCIATION_FAIL: {
    Description: 'Update Project Association Fails',
    FeatureId: 'FA99886D-8A53-42DF-AD6D-12E62A8D8DA5',
    IsChargeable: true
  },
  UPDATE_PROJECT_ASSOCIATION_SUCCESSFUL: {
    Description: 'Update Project Association Succeeds',
    FeatureId: '95C3390C-5F43-4D8E-86B2-9F52C2A51396',
    IsChargeable: true
  },
  UPLOAD_CREATE_FOLDER_START: {
    Description: 'Upload > User creating new folder',
    FeatureId: '1A15C545-A0DB-4A9E-A34B-A842870BD30F',
    IsChargeable: true
  },
  UPLOAD_CREATE_FOLDER_SUCCESSFUL: {
    Description: 'Upload > Create new folder was successful',
    FeatureId: '63F99BA9-6690-4F58-9033-E1D7627F0E43',
    IsChargeable: true
  },
  UPLOAD_FAILURE: {
    Description: 'Upload > Upload Failed',
    FeatureId: '0F969F6E-4DB2-468D-8471-49FF20C5DA51',
    IsChargeable: true
  },
  UPLOAD_START: {
    Description: 'Upload > User began upload of file(s)',
    FeatureId: '1F151688-45A0-4123-9FE7-4DDE1C397CFF',
    IsChargeable: true
  },
  UPLOAD_SUCCESSFUL: {
    Description: 'Upload > Uploading file was successful',
    FeatureId: 'BB01EE67-7B94-4C7E-BE86-D1C2AB9B5AA2',
    IsChargeable: true
  },
  UPLOAD_TRUNCATED_FILENAME: {
    Description: 'Upload > Truncated File Name for Upload',
    FeatureId: '26192DB4-F22D-4848-9A4D-9B46285EF710',
    IsChargeable: true
  },
  UNMAP_IMODEL: {
    Description: 'iModel > Unmap iModel',
    FeatureId: '210B5A23-82A3-4DBB-B50F-0755081A2907',
    IsChargeable: true
  },
  VIEW_DOCUMENT: {
    Description: 'View non PDF Item in PDF Markup',
    FeatureId: '755c9fa0-3f70-4868-b55d-ba0999cce7b9',
    IsChargeable: true
  },
  VIEW_ITEM_IMAGE: {
    Description: 'View Item In Browser > image',
    FeatureId: 'e84beb4f-9516-4a51-a4e1-50d307e7e6e6',
    IsChargeable: true
  },
  VIEW_ITEM_OFFICE_DOC: {
    Description: 'View Item In Browser > Office Document',
    FeatureId: 'c6607352-f7f3-4e96-8e36-dd85c3db6a0b',
    IsChargeable: true
  },
  VIEW_ITEM_PDF: {
    Description: 'View Item In Browser > PDF',
    FeatureId: '7d1abad5-8041-412d-a6e2-57b12be3c1c7',
    IsChargeable: true
  },
  VIEW_ITEM_TXT: {
    Description: 'View Item In Browser > text file',
    FeatureId: '755c9fa9-3f70-4867-b55c-ba0999cce7b8',
    IsChargeable: true
  },
  WARNING_TOOLTIP_CLICK: {
    Description: 'Tooltip button > Click More Information',
    FeatureId: '103857E4-9BF5-4DB1-8062-34147F8ADE3C',
    IsChargeable: true
  }
};
