import React from 'react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import type {
  SavedAdvancedQuery,
  SerializedAdvancedSearch
} from '../../../components/advancedSearch';
import { storeSearchSessionStorage } from '../../../components/advancedSearch';
import { PWTooltip } from '../../../components/pwTooltip';
import {
  useAppViewContext,
  useFeatureTracking,
  useNavigationContext
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';

export function NameCell({ row }: CellProps<SavedAdvancedQuery>): JSX.Element {
  const { trackFeature } = useFeatureTracking();
  const { primaryModal, searchState } = useNavigationContext();
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();

  const search = row.original;

  function onRowClick(): void {
    searchState.setAdvancedSearch({ ...search.query, name: search.name });
    setAppView('AdvancedSearch');
    storeSearchSessionStorage(
      JSON.parse(search.jsonSession) as SerializedAdvancedSearch
    );
    trackFeature('SAVED_ADVANCED_SEARCH_RUN');
    primaryModal.close();
  }

  return (
    <PWTooltip content={search.name}>
      <div
        className={'underline-name-div'}
        onClick={onRowClick}
        onKeyPress={keyPressAsClick(onRowClick)}
        role="button"
        tabIndex={0}
        data-testid={`s-a-search-${search.name}`}
        data-col="s-a-search-name"
        data-val={search.name}
      >
        {search.name}
      </div>
    </PWTooltip>
  );
}
