import React, { useCallback, useEffect, useState } from 'react';
import type { PWProject, RequestOptions } from '@bentley/pw-api';
import { getProjects } from '@bentley/pw-api';
import { useFolderExplorerContext, useHttpService } from '../../../context';
import { byCascadingSort, byProjectLabel, byRichProject } from '../../pwTable';
import { LoadingNode } from '../loadingNode/loadingNode';
import { addParentToNode } from './addParentToNode';
import { FolderNode } from './folderNode';
import { TruncatedFoldersNode, pageSize } from './truncatedFoldersNode';

type SubFoldersNodeProps = {
  parent: PWProject;
  notifyHasChildren?: (value: boolean) => void;
};

export function SubFoldersNode({
  parent,
  notifyHasChildren
}: SubFoldersNodeProps): JSX.Element {
  const httpService = useHttpService();
  const { reloadFolderIds } = useFolderExplorerContext();

  const [children, setChildren] = useState<PWProject[]>();
  const [numChildrenDisplayed, setNumChildrenDisplayed] =
    useState<number>(pageSize);

  const loadChildren = useCallback(
    async (requestOptions?: RequestOptions): Promise<void> => {
      try {
        const children = await getProjects({
          parentId: parent.instanceId,
          httpService,
          requestOptions
        });
        children.forEach((child) => addParentToNode(child, parent));
        children.sort((a, b) =>
          byCascadingSort(a, b, byRichProject, byProjectLabel)
        );

        if (!requestOptions?.abortController?.signal.aborted) {
          setChildren(children);
          notifyHasChildren?.(children.length > 0);
        }
      } catch {
        if (!requestOptions?.abortController?.signal.aborted) {
          setChildren([]);
          notifyHasChildren?.(false);
        }
      }
    },
    [httpService, notifyHasChildren, parent]
  );

  useEffect(() => {
    const abortController = new AbortController();
    void loadChildren({ abortController });

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!reloadFolderIds.includes(parent.instanceId)) {
      return;
    }

    setChildren(undefined);
    void loadChildren({ uncached: true });
  }, [parent.instanceId, loadChildren, reloadFolderIds]);

  if (!children) {
    return <LoadingNode nodeType="folder" />;
  }

  return (
    <>
      {children.slice(0, numChildrenDisplayed).map((folder) => (
        <FolderNode key={folder.instanceId} folder={folder} />
      ))}
      <TruncatedFoldersNode
        folders={children}
        numChildrenDisplayed={numChildrenDisplayed}
        setNumChildrenDisplayed={setNumChildrenDisplayed}
      />
    </>
  );
}
