import { useCallback, useMemo } from 'react';
import { PopupClient } from '@bentley/office365-collaboration-login-modal';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../../context';
import type {
  CoAuthoringError,
  CoAuthoringServiceFileStatus,
  CoAuthoringSession,
  CoAuthoringSessionContext
} from './coAuthoringTypes';

type CoAuthoringClient = {
  startSession: (
    documentId: string
  ) => Promise<CoAuthoringSession | CoAuthoringError>;
  endSession: (
    sessionId: string
  ) => Promise<CoAuthoringServiceFileStatus | CoAuthoringError>;
  freeSession: (
    sessionId: string
  ) => Promise<CoAuthoringServiceFileStatus | CoAuthoringError>;
  joinSession: (
    sessionId: string,
    openInDesktop?: boolean
  ) => Promise<CoAuthoringSessionContext | CoAuthoringError>;
  takeOverSession: (sessionId: string) => Promise<null | CoAuthoringError>;
  getCoAuthoringSessions: () => Promise<
    CoAuthoringSessionContext[] | CoAuthoringError
  >;
  getSessionFileStatus: (
    sessionId: string
  ) => Promise<CoAuthoringServiceFileStatus | CoAuthoringError>;
  updateServerCopy: (sessionId: string) => Promise<null | CoAuthoringError>;
};

export const useCoAuthoringClient = (): CoAuthoringClient => {
  const { getOidcToken } = useToken();
  const { contextId, buddiRegionCode, openAuthenticationPopup, connection } =
    usePluginContext();

  const serviceBaseUrl = useBuddi(
    'Office365CollaborationService.Url',
    buddiRegionCode
  );

  const httpService = useMemo(() => {
    const authorization = new AuthorizationService({ getOidcToken });
    const httpService = new HttpService({
      authorization,
      baseUrl: serviceBaseUrl ?? ''
    });
    return httpService;
  }, [serviceBaseUrl, getOidcToken]);

  const executeWithOfficeLogin = useCallback(
    async <T>(
      fetchFunc: () => Promise<Response>
    ): Promise<T | CoAuthoringError> => {
      if (!serviceBaseUrl) {
        return {
          errorMessage: 'Failed to get service url',
          errorId: 'NoServiceUrl'
        };
      }

      const content = await parseResponse<T>(await fetchFunc());
      if (typeof content !== 'string') {
        return content;
      }

      if (!(await tryLoginToOffice(content, openAuthenticationPopup))) {
        return {
          errorMessage: 'Unauthorized',
          errorId: 'LoginFailed'
        };
      }
      return (await parseResponse(await fetchFunc())) as T | CoAuthoringError;
    },
    [openAuthenticationPopup, serviceBaseUrl]
  );

  const startSession = useCallback(
    async (
      documentId: string
    ): Promise<CoAuthoringSession | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession?withCheckOut=true`;
      const body = JSON.stringify({ documentId, connectionId: connection.Id });
      return await executeWithOfficeLogin(() => httpService.post(path, body));
    },
    [connection.Id, contextId, executeWithOfficeLogin, httpService]
  );

  const endSession = useCallback(
    async (
      sessionId: string
    ): Promise<CoAuthoringServiceFileStatus | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession/${sessionId}?withCheckIn=true`;
      const body = JSON.stringify({ connectionId: connection.Id });
      return await executeWithOfficeLogin(() => httpService.delete(path, body));
    },
    [connection.Id, contextId, executeWithOfficeLogin, httpService]
  );

  const freeSession = useCallback(
    async (
      sessionId: string
    ): Promise<CoAuthoringServiceFileStatus | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession/${sessionId}?withCheckIn=false&freePwdiFile=true`;
      const body = JSON.stringify({ connectionId: connection.Id });
      return await executeWithOfficeLogin(() => httpService.delete(path, body));
    },
    [connection.Id, contextId, executeWithOfficeLogin, httpService]
  );

  const joinSession = useCallback(
    async (
      sessionId: string,
      openInDesktop = false
    ): Promise<CoAuthoringSessionContext | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession/${sessionId}/$joinSession?openInDesktop=${(
        openInDesktop as boolean
      ).toString()}`;
      return await executeWithOfficeLogin(() => httpService.post(path, null));
    },
    [contextId, executeWithOfficeLogin, httpService]
  );

  const takeOverSession = useCallback(
    async (sessionId: string): Promise<null | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession/${sessionId}/$sessionTakeover`;

      return await executeWithOfficeLogin(() => httpService.post(path, null));
    },
    [contextId, executeWithOfficeLogin, httpService]
  );

  const getCoAuthoringSessions = useCallback(async (): Promise<
    CoAuthoringSessionContext[] | CoAuthoringError
  > => {
    const path = `/api/v1/context/${contextId}/collaborationSessions`;
    return await executeWithOfficeLogin(() => httpService.get(path));
  }, [contextId, executeWithOfficeLogin, httpService]);

  const getSessionFileStatus = useCallback(
    async (
      sessionId: string
    ): Promise<CoAuthoringServiceFileStatus | CoAuthoringError> => {
      const path = `/api/v1/collaborationSession/${sessionId}/$status`;
      return await executeWithOfficeLogin(() => httpService.get(path));
    },
    [executeWithOfficeLogin, httpService]
  );

  const updateServerCopy = useCallback(
    async (sessionId: string): Promise<null | CoAuthoringError> => {
      const path = `/api/v1/context/${contextId}/collaborationSession/${sessionId}`;
      const body = JSON.stringify({ connectionId: connection.Id });
      return await executeWithOfficeLogin(() => httpService.put(path, body));
    },
    [connection.Id, contextId, executeWithOfficeLogin, httpService]
  );

  return useMemo(
    () => ({
      startSession,
      endSession,
      freeSession,
      joinSession,
      takeOverSession,
      getCoAuthoringSessions,
      getSessionFileStatus,
      updateServerCopy
    }),
    [
      startSession,
      endSession,
      freeSession,
      joinSession,
      takeOverSession,
      getCoAuthoringSessions,
      getSessionFileStatus,
      updateServerCopy
    ]
  );
};

async function tryLoginToOffice(
  redirectUri: string,
  openAuthenticationPopup?: (url: string) => Promise<boolean>
) {
  try {
    if (openAuthenticationPopup) {
      return await openAuthenticationPopup(redirectUri);
    }

    const client = new PopupClient();
    return await client.acquireTokenPopup(redirectUri);
  } catch (error) {
    return false;
  }
}

async function parseResponse<T>(
  response: Response
): Promise<T | CoAuthoringError | string> {
  const contentType = response.headers.get('content-type');
  if (!contentType) {
    return null as T;
  }

  if (contentType.includes('text/plain')) {
    return response.text();
  }

  return (await response.json()) as T | CoAuthoringError;
}
