import type { ProviderProps } from 'react';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { IModel } from '../../../actions/imodelMapping';
import { useIModels } from '../../../actions/imodelMapping';
import type { PWDMAPIFunction } from '../../../actions/sendToPWDM';
import { usePWDMAPI } from '../../../actions/sendToPWDM';
import { useAzureServerAccessible } from '../../../hooks/useAzureServerAccessible';
import type { CoAuthoringSettings } from '../../../hooks/useCoAuthoringSettings';
import { useCoAuthoringSettings } from '../../../hooks/useCoAuthoringSettings';
import type { State, Workflow } from '../../../hooks/useDocumentProperties';
import { useDocumentProperties } from '../../../hooks/useDocumentProperties';
import type {
  ECPluginFeatures,
  ECPluginVersion
} from '../../../hooks/useECPluginVersion';
import { useECPluginVersion } from '../../../hooks/useECPluginVersion';
import type {
  AttributePicklistValue,
  EnvironmentManager
} from '../../../hooks/useEnvironment';
import { useEnvironments } from '../../../hooks/useEnvironment';
import { useProductSetting } from '../../../hooks/useProductSetting';
import type { UserManager } from '../../../hooks/useUsers';
import { useUsers } from '../../../hooks/useUsers';
import { useTelemetry } from '../../appProviders/telemetry';
import { useConnectionAuth, useFeatureTracking, usePluginContext } from '../..';

type EnvironmentContext = {
  availableStates: State[];
  availableStatuses: string[];
  availableWorkflows: Workflow[];
  azureServerAccessible: boolean;
  documentPropertiesInitialized: boolean;
  ecPluginFeatures: ECPluginFeatures;
  ecPluginVersion: ECPluginVersion;
  environmentContextInitialized: boolean;
  environmentManager: EnvironmentManager;
  goToPWE: { allowed: boolean; setAllowed: (value: boolean) => void };
  openPDFInNewTab: { allowed: boolean; setAllowed: (value: boolean) => void };
  iModels: IModel[];
  office365CoAuthoringSettings: CoAuthoringSettings;
  versions: {
    displayLatestVersion: boolean;
    setDisplayLatestVersion: (value: boolean) => void;
  };
  userManager: UserManager;
  pwdmAPI: PWDMAPIFunction;
  getDocumentPropertyPicklist: (
    columnId: string
  ) => AttributePicklistValue[] | undefined;
  getStateString: (item: PWItem) => string;
  getWorkflowString: (item: PWItem) => string;
};

const Context = createContext<EnvironmentContext | undefined>(undefined);

export function EnvironmentProvider({
  children
}: ProviderProps<EnvironmentContext | undefined>): JSX.Element {
  const { startTelemetry, endTelemetry } = useTelemetry();
  const { connection } = usePluginContext();

  const { ecPluginVersion, ecPluginFeatures, ecPluginInitialized } =
    useECPluginVersion(connection.Id);

  const environmentManager = useEnvironments(ecPluginVersion);

  const { isLogicalUser } = useConnectionAuth();

  const userManager = useUsers();

  const {
    availableStates,
    availableWorkflows,
    availableStatuses,
    initialized: documentPropertiesInitialized,
    getDocumentPropertyPicklist,
    getStateString,
    getWorkflowString
  } = useDocumentProperties();

  const { contextId } = usePluginContext();
  const { launchDarklyFeatures } = useFeatureTracking();

  const [displayLatestVersion, setDisplayLatestVersion] = useProductSetting(
    'displayLatestVersion',
    {
      defaultValue: true,
      settingType: 'User'
    }
  );

  const [allowGoToPWE, setAllowGoToPWE] = useProductSetting(
    'isDisplayGoToProjectWiseExplorer',
    { defaultValue: true, settingType: 'Org' }
  );

  const [allowInNewTab, setAllowInNewTab] = useProductSetting(
    'openPDFDocViewerInNewTab',
    { defaultValue: false, settingType: 'User' }
  );

  const coAuthoringSettings = useCoAuthoringSettings();
  const [coAuthoringEnabledPrdss] = useProductSetting(
    'enableOffice365CoAuthoring',
    {
      defaultValue: true,
      contextId: contextId,
      settingType: 'Context'
    }
  );

  const azureServerAccessible = useAzureServerAccessible();
  const iModels = useIModels();
  const pwdmAPI = usePWDMAPI();

  const environmentContextInitialized = useMemo((): boolean => {
    if (!ecPluginInitialized) {
      return false;
    }

    return true;
  }, [ecPluginInitialized]);

  useEffect(() => {
    startTelemetry('EnvironmentDataLoad');
  }, [startTelemetry]);

  useEffect(() => {
    if (environmentContextInitialized) {
      endTelemetry('EnvironmentDataLoad');
    }
  }, [environmentContextInitialized, endTelemetry]);

  const environmentContext = useMemo(
    (): EnvironmentContext => ({
      availableStates,
      availableStatuses,
      availableWorkflows,
      azureServerAccessible,
      documentPropertiesInitialized,
      ecPluginFeatures,
      ecPluginVersion,
      environmentContextInitialized,
      environmentManager,
      goToPWE: { allowed: allowGoToPWE ?? false, setAllowed: setAllowGoToPWE },
      openPDFInNewTab: {
        allowed: allowInNewTab ?? false,
        setAllowed: setAllowInNewTab
      },
      office365CoAuthoringSettings: {
        ...coAuthoringSettings,
        isCoAuthoringEnabled:
          (launchDarklyFeatures.office365ProjectSettings
            ? coAuthoringSettings.isCoAuthoringEnabled
            : coAuthoringEnabledPrdss ?? false) &&
          azureServerAccessible &&
          !isLogicalUser
      },
      iModels: iModels ?? [],
      versions: {
        displayLatestVersion: displayLatestVersion ?? true,
        setDisplayLatestVersion
      },
      userManager,
      pwdmAPI,
      getDocumentPropertyPicklist,
      getStateString,
      getWorkflowString
    }),
    [
      availableStates,
      availableStatuses,
      availableWorkflows,
      azureServerAccessible,
      documentPropertiesInitialized,
      environmentContextInitialized,
      environmentManager,
      ecPluginFeatures,
      ecPluginVersion,
      getDocumentPropertyPicklist,
      getStateString,
      getWorkflowString,
      allowGoToPWE,
      setAllowGoToPWE,
      allowInNewTab,
      setAllowInNewTab,
      launchDarklyFeatures,
      coAuthoringSettings,
      coAuthoringEnabledPrdss,
      isLogicalUser,
      iModels,
      displayLatestVersion,
      setDisplayLatestVersion,
      userManager,
      pwdmAPI
    ]
  );

  return (
    <Context.Provider value={environmentContext}>{children}</Context.Provider>
  );
}

export function useEnvironmentContext(): EnvironmentContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useEnvironmentContext must be used within an EnvironmentContext'
    );
  }
  return context;
}
