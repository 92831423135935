import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Checkbox, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import type { ResolveOutputFunction } from '../../hooks/useModalOutput';
import { t } from '../../services/translation';

export type ConfirmRestoreModalOutput = {
  confirm: boolean;
  navigate?: boolean;
};

export type ConfirmRestoreModalProps = {
  items: PWItem[];
} & ResolveOutputFunction<ConfirmRestoreModalOutput>;

export function ConfirmRestoreModal({
  items,
  resolveModalOutput
}: ConfirmRestoreModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();

  const [navigate, setNavigate] = useState<boolean>(false);

  const title =
    items.length == 1 ? t('Restore.RestoreItem') : t('Restore.RestoreItems');

  function confirmationMessage(): string {
    if (items.length == 1) {
      return t('Restore.RestoreThisItem', { name: items[0].Name });
    }
    return t('Restore.RestoreTheseItems', { itemCount: items.length });
  }

  function onNavigateToggle(e: ChangeEvent<HTMLInputElement>): void {
    setNavigate(e.target.checked);
  }

  function onYes(): void {
    primaryModal.close();
    resolveModalOutput({ confirm: true, navigate });
  }

  function onNo(): void {
    primaryModal.close();
    resolveModalOutput({ confirm: false });
  }

  return (
    <PWModal
      title={title}
      primaryButton={{ title: t('Restore.Restore'), onClick: onYes }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: onNo }}
      onClose={onNo}
    >
      <ItemSummary item={items} />
      <Text>{confirmationMessage()}</Text>
      {items.length == 1 && (
        <Checkbox
          label={t('Restore.NavigateToLocation')}
          onChange={onNavigateToggle}
        />
      )}
    </PWModal>
  );
}
