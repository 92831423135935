import type { PWItem } from '@bentley/pw-api';
import { itemFileExtension, itemIsFileType } from '@bentley/pw-api';

const supportedFileExtensions = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'jfif'
];

/**
 * Returns boolean whether a document is a PDF (or other supported type of file) or not
 * @param {PWItem} item Clicked item to check
 */
export function allowPDFMarkup(
  items: PWItem[],
  readOnly: boolean,
  excludedExtensions: string[],
  usingLogicalLogin: boolean,
  documentViewEnabled?: boolean,
  showWebViewDocumentViewer?: boolean
): boolean {
  if (readOnly && !showWebViewDocumentViewer) {
    return false;
  }

  if (items.length != 1) {
    return false;
  }

  const item = items[0];

  if (!itemIsFileType(item)) {
    return false;
  }

  const fileExtension = itemFileExtension(item);
  const extensionSupported =
    supportedFileExtensions.includes(fileExtension) &&
    !excludedExtensions.includes(fileExtension);

  if (readOnly) {
    if (!usingLogicalLogin && documentViewEnabled && extensionSupported) {
      return true;
    }
    return fileExtension === 'pdf';
  }

  if (documentViewEnabled) {
    return extensionSupported;
  }

  return fileExtension === 'pdf';
}
