import type { PWProject } from '@bentley/pw-api';

export function byAlphaNumeric(str1: string, str2: string): number {
  return (
    str1?.localeCompare(str2, undefined, {
      numeric: true,
      sensitivity: 'case'
    }) ?? 1
  );
}

export function byProjectLabel(a: PWProject, b: PWProject): number {
  return byAlphaNumeric(
    (a.Label ?? a.Name).toLocaleLowerCase(),
    (b.Label ?? b.Name).toLocaleLowerCase()
  );
}
