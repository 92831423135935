import React, { ReactNode } from 'react';
import { ConnectionProvider, ConnectionsProvider } from '../../context';
import { PluginWrapper } from '../pluginWrapper';
import type { ProjectWiseConnectionProps } from './projectWiseConnection';
import { ProjectWiseConnectionSelector } from './projectWiseConnectionSelector';

export type ProjectWiseConnectionsProps = Omit<
  ProjectWiseConnectionProps,
  'connectionId'
> & {
  emptyConnectionsContent?: ReactNode;
};

export function ProjectWiseConnections(
  props: ProjectWiseConnectionsProps
): JSX.Element {
  return (
    <PluginWrapper>
      <ConnectionsProvider value={{ ...props, connectionList: 'multi' }}>
        <ConnectionProvider value={props}>
          <ProjectWiseConnectionSelector {...props} />
        </ConnectionProvider>
      </ConnectionsProvider>
    </PluginWrapper>
  );
}
