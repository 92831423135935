import './pwDriveSyncProjectDialog.css';

import React, { useEffect, useState } from 'react';
import { Anchor, ToggleSwitch } from '@itwin/itwinui-react';
import { PWModal } from '../../../components/pwModal';
import { href } from '../../../constants';
import {
  useConnectionAuth,
  useConnectionsContext,
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { t } from '../../../services/translation';
import { notifySyncProjectErrorWhenDriveIsOff } from '../../sync/notifications';
import type { PwDriveProject } from '../pwDrive.utils';
import { syncProjectDrive } from '../pwDrive.utils';
import type { PWDriveManager } from '../usePwDrive';
import { handleSyncResponse } from './response';
import { useDriveSyncAlert } from './useDriveSyncAlert';

export function PwDriveProjectSyncDialog(): JSX.Element {
  const { repositoryManager } = useConnectionsContext();
  const { trackFeature } = useFeatureTracking();
  const { userId } = useConnectionAuth();
  const { contextId, pwDriveData } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const { primaryModal } = useNavigationContext();

  const [currentProjectSynced, setCurrentProjectSynced] = useState<boolean>(
    pwDriveData.isCurrentProjectSynced
  );

  const [pristine, setPristine] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const driveSyncAlert = useDriveSyncAlert();

  useEffect(() => {
    if (pwDriveData.pwDriveSyncDisabled) {
      setCurrentProjectSynced(false);
    }
  }, [pwDriveData.pwDriveSyncDisabled]);

  async function onSaveButton() {
    if (pwDriveData.isCurrentProjectSynced == currentProjectSynced) {
      primaryModal.close();
      return;
    }

    try {
      setIsLoading(true);
      const response = await syncProjectDrive(
        {
          ProjectId: contextId,
          UserId: userId,
          ConnectionId: repositoryManager.driveSyncedConnections[0]?.Id
        } as PwDriveProject,
        pwDriveData.httpDriveService
      );
      void handleSyncResponse(response);

      pwDriveData.setPwDriveSyncDisabled(!currentProjectSynced);
      pwDriveData.syncDrive();

      trackFeature(
        currentProjectSynced
          ? 'GRID_DISPLAY_PW_DRIVE_SYNC_PROJECT_ON'
          : 'GRID_DISPLAY_PW_DRIVE_SYNC_PROJECT_OFF'
      );
    } catch {
      notifySyncProjectErrorWhenDriveIsOff();
    } finally {
      setIsLoading(false);
      primaryModal.close();
    }
  }

  function onProjectSyncChange(newValue: boolean): void {
    if (driveOperationEnabled()) {
      setCurrentProjectSynced(newValue);
      setPristine(false);
    }
  }

  function driveOperationEnabled(): boolean {
    if (!pwDriveData.pwDriveMetadataEnabled) {
      return false;
    }
    if (!ecPluginFeatures.pwDrive()) {
      return false;
    }
    if (pwDriveData.validDriveUser != 'SameUser') {
      return false;
    }
    if (pwDriveData.driveVersionType == 'Obsolete') {
      return false;
    }
    if (!pwDriveData.driveSynced.length) {
      return false;
    }
    return true;
  }

  function saveButtonEnabled(): boolean {
    if (pristine) {
      return false;
    }

    if (pwDriveData.isCurrentProjectSynced == currentProjectSynced) {
      return false;
    }

    if (!driveOperationEnabled()) {
      return false;
    }
    if (isLoading) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    pwDriveData.syncDrive();
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * Sync Drive when dialog first opened, but do not re-sync should the drive data change
     */
  }, []);

  return (
    <PWModal
      title={`${t('PWDrive.DriveOptions')}`}
      isLoading={isLoading}
      loadingText={
        currentProjectSynced
          ? t('PWDrive.SyncingConnection')
          : t('PWDrive.UnsyncingConnection')
      }
      primaryButton={{
        title: t('Generic.Save'),
        onClick: () => {
          void onSaveButton();
        },
        disabled: !saveButtonEnabled(),
        'data-testid': 'save-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
    >
      {driveSyncAlert}
      {driveOperationEnabled() && (
        <ToggleSwitch
          className="pwdrive-project-sync-dialog-toggle-switch"
          defaultChecked={currentProjectSynced}
          onChange={(e) => onProjectSyncChange(e.target.checked)}
          data-testid="PWDriveProjectSyncToggle"
          label={t('PWDrive.Notifications.SyncToggleMessage', {
            name: repositoryManager.driveSyncedConnections[0]?.Name
          })}
        />
      )}
      {isDownloadUrlVisible(pwDriveData) && (
        <Anchor
          href={pwDriveData.driveDownloadUrl}
          target="_blank"
          isExternal={true}
          data-testid="download-link"
        >
          {t('PWDrive.DownloadDrive', {
            version: pwDriveData.driveLatestVersion
          })}
        </Anchor>
      )}
      <Anchor
        href={href.pwDrive}
        target="_blank"
        isExternal={true}
        rel="noopener noreferrer"
      >
        {t('PWDrive.LearnMore')}
      </Anchor>
      <Anchor
        href={href.aha}
        target="_blank"
        isExternal={true}
        rel="noopener noreferrer"
      >
        {t('PWDrive.TellUsWhatYouThink')}
      </Anchor>
    </PWModal>
  );
}

function isDownloadUrlVisible(pwDriveData: PWDriveManager) {
  return (
    pwDriveData.driveDownloadUrl?.includes('bentley.com') &&
    pwDriveData.driveVersionType !== 'InvalidVersion'
  );
}
