import type { HttpService, WSGInstance } from '@bentley/pw-api';
import { parseResponseChangedInstance } from '@bentley/pw-api';
import type { FormDefinition } from '../../../../actions/formRendering';
import { t } from '../../../../services/translation';
import type { FormType } from '../useDCWForm';

export async function generateReloadFormAction(
  formType: FormType,
  environmentInstance: WSGInstance,
  httpService: HttpService
): Promise<FormDefinition> {
  const instance = getFormGenerateActionInstance(formType, environmentInstance);
  const body = JSON.stringify({ instance });
  const response = await httpService.post('PW_WSG/FormGenerateAction', body, {
    uncached: true
  });
  throwIfBadResponse(response);
  const updatedFormDefinition = await parseFormDefinitionFromResponse(response);
  throwIfNoFormDefinition(updatedFormDefinition);
  return updatedFormDefinition as FormDefinition;
}

function getFormGenerateActionInstance(
  formtype: FormType,
  environmentInstance: WSGInstance
): WSGInstance {
  return {
    schemaName: 'PW_WSG',
    className: 'FormGenerateAction',
    instanceId: '',
    properties: { formtype },
    relationshipInstances: [
      {
        schemaName: 'PW_WSG',
        className: 'FormGenerateActionEnvironment',
        direction: 'backward',
        relatedInstance: {
          schemaName: 'PW_WSG_Dynamic',
          className: environmentInstance.className,
          instanceId: environmentInstance.instanceId,
          properties: environmentInstance.properties
        }
      }
    ]
  };
}

async function parseFormDefinitionFromResponse(
  response: Response
): Promise<FormDefinition | undefined> {
  const changedInstance = await parseResponseChangedInstance(response);

  const environmentInstances = changedInstance.relationshipInstances?.map(
    (relationshipInstance) => relationshipInstance.relatedInstance
  );

  const updatedInstance = environmentInstances?.[1];

  const updatedFormDefinition = updatedInstance?.properties;
  return updatedFormDefinition as FormDefinition | undefined;
}

function throwIfBadResponse(response: Response): void {
  if (!response.ok) {
    throw new Error(
      `${t('DocumentCreation.FormActionError', {
        status: response.status
      })}`
    );
  }
}

function throwIfNoFormDefinition(
  formDefinition: FormDefinition | undefined
): void {
  if (!formDefinition?.Definition) {
    throw new Error(t('DocumentCreation.UnableToRefreshForm'));
  }
}
