import type { PWItemClass } from '@bentley/pw-api';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';
import { getEnvironmentSelectString } from '../../hooks/useEnvironment';

export function savedSearchSelect(className: PWItemClass): string {
  const environmentSelect = buildEnvironmentSelect(className);
  const parentSelect = buildParentSelect(className);

  return `${environmentSelect},${parentSelect}`;
}

function buildEnvironmentSelect(className: PWItemClass): string {
  // Before saved search views, users may have the incorrect environment columns applied for the saved search environment
  // Get all the environments regardless of columns to increase the changes of users seeing something useful
  if (!ecPluginFeatures.savedSearchDefaultView()) {
    return allEnvironmentsSelect(className);
  }

  const environmentSelectString = environmentSelect(className);
  if (!environmentSelectString) {
    return documentsSelect(className);
  }

  return environmentSelectString;
}

function savedSearchForwardSelector(className: PWItemClass): string {
  const forwardSelector = `SavedSearchResult-forward-${className}!poly`;
  return forwardSelector;
}

function documentsSelect(className: PWItemClass): string {
  const forwardSelector = savedSearchForwardSelector(className);
  return `${forwardSelector}.*`;
}

function allEnvironmentsSelect(className: PWItemClass): string {
  const forwardSelector = savedSearchForwardSelector(className);
  if (className == 'Project') {
    return `${forwardSelector}.*`;
  }

  return `${forwardSelector}.*,${forwardSelector}/PW_WSG.DocumentEnvironment-forward-Environment!poly.*`;
}

function environmentSelect(className: PWItemClass): string {
  const environmentSelectString = getEnvironmentSelectString(className);

  if (!environmentSelectString) {
    return '';
  }

  const forwardSelector = savedSearchForwardSelector(className);
  const envNames = environmentSelectString.split(',');
  const joinedEnvNameQuery = envNames
    .map((name) => `${forwardSelector}/${name}`)
    .join(',');
  return `${forwardSelector}.*,${joinedEnvNameQuery}`;
}

function buildParentSelect(className: PWItemClass): string {
  const parentSelect = `SavedSearchResult-forward-${className}!poly/PW_WSG.${className}Parent-forward-Project.*`;
  return parentSelect;
}
