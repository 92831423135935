import type {
  HttpService,
  PWDocument,
  WSGChangedInstanceResponse,
  WSGInstance
} from '@bentley/pw-api';
import { getDocument } from '@bentley/pw-api';

export async function getChangedInstanceFromResponse(
  response: Response
): Promise<WSGInstance> {
  if (!response.ok || response.status != 201) {
    throw response;
  }
  const data = (await response.json()) as WSGChangedInstanceResponse;
  return data.changedInstance.instanceAfterChange;
}

export async function getCreatedDocument(
  instanceId: string,
  httpService: HttpService
): Promise<PWDocument> {
  const createdDocument = await getDocument({ instanceId, httpService });
  return createdDocument;
}
