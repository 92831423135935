import React, { useMemo } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { LabeledInput, StatusMessage } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useHttpService, useNavigationContext } from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { allowNewVersion } from './requirements';
import { compressWhitespace, useNewVersionForm } from './useNewVersionForm';
import { useNewVersionWorkflow } from './useNewVersionWorkflow';

type NewVersionModalProps = {
  items: PWDataItem[];
};

export function NewVersionModal({ items }: NewVersionModalProps): ModalElement {
  const httpService = useHttpService();
  const {
    primaryModal: { close: closeModal }
  } = useNavigationContext();

  const { form, onChange } = useNewVersionForm(items);

  const newVersionWorkflow = useNewVersionWorkflow();

  const isSaveDisabled = useMemo(() => {
    return !form.pristine && !form.valid;
  }, [form.pristine, form.valid]);

  function onSave(): void {
    closeModal();
    void newVersionWorkflow(
      items,
      form.inputs.versionName
        ? compressWhitespace(form.inputs.versionName)
        : form.inputs.versionName
    );
    void httpService.cache?.clearEntriesMatching(
      items.map((item) => item.ParentGuid)
    );
  }

  if (!allowNewVersion(items)) {
    return null;
  }

  return (
    <PWModal
      title={t('Version.NewVersion')}
      primaryButton={{
        title: t('Generic.Create'),
        onClick: onSave,
        disabled: isSaveDisabled,
        'data-testid': 'create-button'
      }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: closeModal }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'NewVersionModal' }}
    >
      <ItemSummary item={items} />
      <LabeledInput
        label={t('Version.VersionName')}
        id="VersionName"
        name="versionName"
        data-testid="VersionName"
        autoFocus={true}
        type="text"
        onChange={onChange}
        placeholder={t('Generic.Optional')}
        status={
          form.errors.versionName?.errorType == 'error' ? 'negative' : undefined
        }
        message={
          <StatusMessage
            status={
              form.errors.versionName?.errorType == 'error'
                ? 'negative'
                : undefined
            }
          >
            {!form.inputs.versionName
              ? t('Version.VersionAssignedAutomatically')
              : form.errors.versionName?.errorMessage}
          </StatusMessage>
        }
      />
    </PWModal>
  );
}
