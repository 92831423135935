import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';

type FreeDocumentModalProps = {
  items: PWItem[];
  onYes: () => void;
};

export function FreeDocumentModal({
  items,
  onYes
}: FreeDocumentModalProps): ModalElement {
  const { primaryModal } = useNavigationContext();

  function confirmationMessage(): string {
    return items.length > 1
      ? t('Sync.Free.MultipleItems', { count: items.length })
      : t('Sync.Free.SingleItem', { itemName: items[0].Name });
  }

  function onConfirm(): void {
    primaryModal.close();
    onYes();
  }

  return (
    <PWModal
      title={t('MenuItem.Free')}
      primaryButton={{ title: t('MenuItem.Free'), onClick: onConfirm }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'FreeDocumentModal' }}
    >
      <ItemSummary item={items} />
      <Text>{confirmationMessage()}</Text>
    </PWModal>
  );
}
