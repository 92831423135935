import './inProgressActivity.css';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { ItemIcon } from '@bentley/pw-file-icons';
import {
  SvgStatusError,
  SvgStatusSuccess,
  SvgStatusWarning
} from '@itwin/itwinui-icons-color-react';
import { SvgCloseCircular } from '@itwin/itwinui-icons-react';
import {
  IconButton,
  ProgressLinear,
  ProgressRadial,
  Text
} from '@itwin/itwinui-react';
import { MAX_UPLOAD_CHUNK_SIZE } from '../../constants';
import type {
  InProgressActivity,
  InProgressActivityStatus
} from '../../hooks/useProgressManager';
import { t } from '../../services/translation';

const statusTitle = {
  inprogress: t('InProgress.InProgress'),
  success: t('ActivityStatus.Success'),
  error: t('Generic.Error'),
  processing: t('ActivityStatus.Processing'),
  abort: t('ActivityStatus.Abort')
};

export function DisplayInProgressActivity({
  status,
  size,
  amountTransferred,
  item,
  label,
  activityType,
  error,
  warning,
  abortController,
  abortDisabled
}: InProgressActivity): JSX.Element {
  const [iconTitle, setIconTitle] = useState<string>(statusTitle[status]);
  const [percentage, setPercentage] = useState<number>(0);
  const [progressBarValue, setProgressBarValue] = useState<number>(0);
  const [isAborting, setIsAborting] = useState(false);

  useEffect(() => {
    async function getTitle() {
      if (warning) {
        setIconTitle(warning);
      } else if (error) {
        try {
          const text = await error.clone().text();
          setIconTitle(
            (JSON.parse(text) as Record<string, string | undefined>)
              .errorMessage ?? ''
          );
        } catch (error) {
          setIconTitle(statusTitle[status]);
        }
      } else {
        setIconTitle(statusTitle[status]);
      }
    }
    void getTitle();
  }, [error, status, warning]);

  useEffect(() => {
    setPercentage((amountTransferred / size) * 100);
    setProgressBarValue(status == 'success' ? 100 : percentage);
  }, [amountTransferred, percentage, size, status]);

  function renderStatusIcon(status: InProgressActivityStatus) {
    let iconSvg;

    if (isAborting && status != 'abort') {
      iconSvg = <SvgStatusError />;
    }

    if (['inprogress', 'processing'].includes(status)) {
      return <ProgressRadial indeterminate size="x-small" />;
    } else if (status === 'success' && warning == undefined) {
      iconSvg = <SvgStatusSuccess />;
    } else if (status === 'success' && warning) {
      iconSvg = <SvgStatusWarning />;
    } else {
      iconSvg = <SvgStatusError />;
    }
    return (
      <IconButton
        styleType="borderless"
        className="in-progress-status-icon-button"
      >
        {iconSvg}
      </IconButton>
    );
  }

  const ongoingLabelClass = `in-progress-activity-label ${
    status == 'inprogress' ? 'inprogress' : ''
  } ${status == 'success' || status == 'error' ? 'completed' : ''}`;

  const progressBarClassName =
    status == 'error'
      ? 'in-progress-activity-progress-bar error'
      : warning
      ? 'in-progress-activity-progress-bar warning'
      : 'in-progress-activity-progress-bar';

  function showActivityProgress(): boolean {
    switch (activityType) {
      case 'workflow':
        return false;
      case 'upload': {
        if (
          !abortDisabled &&
          percentage < 100 &&
          size - amountTransferred > MAX_UPLOAD_CHUNK_SIZE &&
          ['inprogress', 'processing'].includes(status)
        ) {
          return true;
        }
        return false;
      }
      case 'download':
        return ['inprogress', 'processing'].includes(status) ? true : false;
      default:
        return false;
    }
  }

  return (
    <div className="in-progress-activity-container">
      <div className="in-progress-activity-information">
        <ItemIcon item={item} size="xs" />
        <div className={ongoingLabelClass} title={label}>
          <Text variant="small" className="in-progress-label">
            {label}
          </Text>
        </div>
        <div className="in-progress-activity-percentage">
          {showActivityProgress() && !isAborting ? (
            <>
              <span className="in-progress-percentage-span">
                {size !== 0 && percentage.toFixed(0) + '%'}
              </span>
              <IconButton
                data-testid={`cancel-${item.FileName}`}
                label={t('Generic.Cancel')}
                styleType="borderless"
                onClick={() => {
                  setIsAborting(true);
                  abortController.abort();
                  status = 'error';
                }}
              >
                <SvgCloseCircular />
              </IconButton>
            </>
          ) : (
            <div
              className="in-progress-activity-status"
              title={
                isAborting || status == 'abort'
                  ? t('Downloads.Notifications.Cancelled')
                  : iconTitle
              }
              data-testid={`${status}-${item.FileName}`}
            >
              {renderStatusIcon(status)}
            </div>
          )}
        </div>
      </div>
      <div className={progressBarClassName}>
        {activityType == 'workflow' ? (
          status == 'inprogress' ? (
            <ProgressLinear indeterminate />
          ) : (
            <ProgressLinear value={100} />
          )
        ) : (
          <ProgressLinear value={progressBarValue} isAnimated={true} />
        )}
      </div>
    </div>
  );
}
