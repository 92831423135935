import type { ProviderProps } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

type FolderExplorerContext = {
  reloadFolderIds: string[];
  selectedIds: string[];
  reloadExplorerFolders: (instanceIds: string[]) => void;
  setSelectedIds: (ids: string[]) => void;
};

const Context = createContext<FolderExplorerContext | undefined>(undefined);

export function FolderExplorerProvider({
  children
}: ProviderProps<undefined>): JSX.Element {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [reloadFolderIds, setReloadFolderIds] = useState<string[]>([]);

  function reloadExplorerFolders(instanceIds: string[]): void {
    setReloadFolderIds(instanceIds);
  }

  useEffect(() => {
    if (reloadFolderIds.length) {
      setReloadFolderIds([]);
    }
  }, [reloadFolderIds]);

  const folderExplorerContext = useMemo(
    (): FolderExplorerContext => ({
      reloadFolderIds,
      selectedIds,
      reloadExplorerFolders,
      setSelectedIds
    }),
    [reloadFolderIds, selectedIds]
  );

  return (
    <Context.Provider value={folderExplorerContext}>
      {children}
    </Context.Provider>
  );
}

export function useFolderExplorerContext(): FolderExplorerContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useFolderExplorerContext must be used within a FolderExplorerProvider'
    );
  }

  return context;
}
