import type { ECPluginVersion } from './ecPluginVersion';
import { storedECPluginVersion } from './sessionStorage';

export type ECPluginFeatures = typeof ecPluginFeatures;

export const ecPluginFeatures = {
  apiAncestor: minimumVersion(3.2),
  apiAncestorBug: exactVersion(3.2),
  environmentAttributeLabels: minimumVersion(3.2),
  folderSearch: minimumVersion(3.2),
  projectSearch: minimumVersion(3.2),
  pwDrive: minimumVersion(3.2),

  allPropertySearch: minimumVersion(3.3),
  attributeInterfaces: minimumVersion(3.3),
  outToProperty: minimumVersion(3.3),
  retiredProperties: minimumVersion(3.3),
  stateWorkflowProperties: minimumVersion(3.3),
  views: minimumVersion(3.3),

  docCodeWizard: minimumVersion(3.4),
  environmentSelect: minimumVersion(3.4),
  fullTextSearch: minimumVersion(3.4),
  moveVersions: minimumVersion(3.4),
  projectDefaultFormDef: minimumVersion(3.4),
  allContentSearch: minimumVersion(3.436),
  driveSyncSupport: minimumVersion(3.436),
  projectResourceMetadata: minimumVersion(3.439),
  flatSetActions: minimumVersion(3.449),
  userDocumentPermissions: minimumVersion(3.433),
  userFolderPermissions: (): boolean =>
    minimumVersion(3.449)() && !exactVersion(3.631)(),
  environmentQueryPerformance: minimumVersion(3.456),
  savedSearchDefaultView: minimumVersion(3.456),
  createRenditions: minimumVersion(3.457),
  masterReferenceFileOperations: minimumVersion(3.457),
  outToMePage: minimumVersion(3.459),

  logicalSetVersion: minimumVersion(5.17),

  copyItems: minimumVersion(6.26),

  attributeExchange: minimumVersion(7.13),

  // Todo: define accurate recycle bin API version
  recycleBin: minimumVersion(3.5)
};

function minimumVersion(version: number): () => boolean {
  return () => pluginVersion() >= version;
}

function exactVersion(version: number): () => boolean {
  return () => pluginVersion() == version;
}

function pluginVersion(): number {
  const plugin = storedECPluginVersion();
  const numericPlugin = pluginAsNumber(plugin);
  return numericPlugin;
}

function pluginAsNumber(pluginVersion: ECPluginVersion): number {
  const numericPlugin = Number(pluginVersion ?? 0);
  return numericPlugin;
}

export function isLegacyPlugin(pluginVersion: ECPluginVersion): boolean {
  const plugin = pluginAsNumber(pluginVersion);
  return plugin < 3.4;
}
