import React, { useEffect } from 'react';
import { Flex } from '@itwin/itwinui-react';
import { useFeatureTracking, useNavigationContext } from '../../../context';
import { DocCodeControl } from '../../docCodeForm';

export function PWInfoPanelDocCode(): JSX.Element {
  const { infoPanelDisplayed } = useNavigationContext();
  const { trackFeature } = useFeatureTracking();

  useEffect(() => {
    if (infoPanelDisplayed) {
      trackFeature('EDIT_DOC_CODE_TAB');
    }
  }, [infoPanelDisplayed, trackFeature]);

  return (
    <Flex flexDirection="column" className="pw-info-doc-code" gap="0">
      {infoPanelDisplayed && (
        <Flex.Item alignSelf="flex-start" className="pw-info-form">
          <DocCodeControl />
        </Flex.Item>
      )}
    </Flex>
  );
}
