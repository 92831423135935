import { useMemo, useState } from 'react';
import type { SavedAdvancedQuery } from '../../components/advancedSearch';
import { usePluginContext } from '../../context';
import type { SavedSearchDefinition } from '../../services/search';
import { useProductSetting } from '../useProductSetting';
import type { AdvancedSearch } from './advancedSearch';

export type SearchState = {
  advancedSearch?: AdvancedSearch;
  basicQuery: string;
  ftr: boolean;
  myConnectionSearches?: SavedAdvancedQuery[];
  myGlobalSearches?: SavedAdvancedQuery[];
  outToMeCount: number | undefined;
  savedSearch?: SavedSearchDefinition;
  setAdvancedSearch: React.Dispatch<
    React.SetStateAction<AdvancedSearch | undefined>
  >;
  setBasicQuery: React.Dispatch<React.SetStateAction<string>>;
  setFtr: React.Dispatch<React.SetStateAction<boolean>>;
  setMyConnectionSearches: (newValue: SavedAdvancedQuery[]) => void;
  setMyGlobalSearches: (newValue: SavedAdvancedQuery[]) => void;
  setOutToMeCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSavedSearch: React.Dispatch<
    React.SetStateAction<SavedSearchDefinition | undefined>
  >;
};

// Todo: outToMeCount state should probably go somewhere else; doesn't need to be in search state

export function useSearchState(): SearchState {
  const { connection } = usePluginContext();

  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearch>();
  const [basicQuery, setBasicQuery] = useState<string>('');
  const [ftr, setFtr] = useState<boolean>(false);
  const [myConnectionSearches, setMyConnectionSearches] = useProductSetting<
    SavedAdvancedQuery[]
  >(`savedAdvancedSearches-${connection.Id}`, { settingType: 'User' });
  const [myGlobalSearches, setMyGlobalSearches] = useProductSetting<
    SavedAdvancedQuery[]
  >('savedAdvancedSearches', { settingType: 'User' });
  const [outToMeCount, setOutToMeCount] = useState<number | undefined>();
  const [savedSearch, setSavedSearch] = useState<SavedSearchDefinition>();

  const searchState = useMemo(
    (): SearchState => ({
      advancedSearch,
      basicQuery,
      ftr,
      myConnectionSearches,
      myGlobalSearches,
      outToMeCount,
      savedSearch,
      setAdvancedSearch,
      setBasicQuery,
      setFtr,
      setMyConnectionSearches,
      setMyGlobalSearches,
      setOutToMeCount,
      setSavedSearch
    }),
    [
      advancedSearch,
      basicQuery,
      ftr,
      myConnectionSearches,
      myGlobalSearches,
      outToMeCount,
      savedSearch,
      setMyConnectionSearches,
      setMyGlobalSearches
    ]
  );

  return searchState;
}
