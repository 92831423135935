import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { pwConstants } from '@bentley/pw-api';
import { LabeledInput, StatusMessage } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { SaveSelectTypeahead } from '../../components/saveSelectTypeahead';
import {
  useFeatureTracking,
  useNavigationContext,
  usePropertyContext
} from '../../context';
import type { ColumnInfo, View } from '../../hooks/useViews';
import { t } from '../../services/translation';
import { CustomColumnDialog } from './customizeGridColumnModal';

type SaveMyViewModalProps = {
  columns: ColumnInfo[];
};

export function SaveMyViewModal({
  columns
}: SaveMyViewModalProps): JSX.Element {
  const { viewManager } = usePropertyContext();
  const { primaryModal } = useNavigationContext();

  const [textValue, setTextValue] = useState<string>('');
  const [multiConnection, setMultiConnection] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { trackFeature } = useFeatureTracking();

  function handleSaveClick(): void {
    trackFeature('SAVE_MY_VIEW');
    if (
      viewManager.userViewSetting.views?.some(({ name }) => name == textValue)
    ) {
      setErrorMessage(t('CustomColumns.ViewAlreadyExists'));
      return;
    } else if (errorMessage) {
      return;
    }

    const view = { columns, name: textValue, multiConnection } as View;
    viewManager.userViewSetting.saveView(view);
    viewManager.userViewSetting.setView(view);
    primaryModal.close();
  }

  function onViewNameChange(e: ChangeEvent<HTMLInputElement>): void {
    setTextValue(e.target.value);
    if (!e.target.value.trim()) {
      setErrorMessage(t('Generic.NameCannotBeEmpty'));
    } else {
      setErrorMessage('');
    }
  }

  function onClose(): void {
    primaryModal.open(
      <CustomColumnDialog
        initialView={viewManager.currentView}
        onClose={primaryModal.close}
      />
    );
  }

  return (
    <PWModal
      title={t('CustomColumns.SaveMyViewName')}
      className="pw-modal-md"
      primaryButton={{
        title: t('Generic.Save'),
        onClick: handleSaveClick,
        disabled: !textValue || !!errorMessage
      }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: onClose }}
      onClose={onClose}
      dialogProps={{ 'data-testid': 'CustomizeColumnModal' }}
    >
      <LabeledInput
        label={t('CustomColumns.MyViewName')}
        name="name"
        data-testid="myViewText"
        autoFocus={true}
        value={textValue}
        onChange={onViewNameChange}
        status={errorMessage ? 'negative' : undefined}
        maxLength={pwConstants.maxFileNameLength}
        message={
          <StatusMessage status={errorMessage ? 'negative' : undefined}>
            {errorMessage}
          </StatusMessage>
        }
      />
      <SaveSelectTypeahead onChange={setMultiConnection} />
    </PWModal>
  );
}
