import React from 'react';
import { usePluginContext } from '../../../context';
import { PWToolbar } from '../pwToolbar/pwToolbar';
import { useOptionActions } from './useOptionActions';

export function OptionsToolbar(): JSX.Element | null {
  const { readOnly } = usePluginContext();

  const toolbarActions = useOptionActions();

  return (
    <PWToolbar
      buttons={toolbarActions}
      numVisibleButtons={readOnly ? 4 : 5}
      data-testid="RightToolbar"
    />
  );
}
