import type { ProviderProps } from 'react';
import React, { createContext, useContext } from 'react';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { useGraphApi } from '../../../actions/useGraphApi';
import { CoAuthoringSessionContext } from '../../../actions/useGraphApi/useCoAuthoringClient';
import type { CoAuthoringFeatureFlags } from '../../../components/coAuthoring';

export type GraphApiContext = {
  endCoAuthoringSessions: (
    item: PWFileType[],
    onComplete: () => void | Promise<void>
  ) => Promise<void>;
  freeCoAuthoringSessions: (
    item: PWFileType[],
    onComplete: () => void | Promise<void>
  ) => Promise<void>;
  refreshCoAuthoringSessions: () => Promise<string[] | undefined>;
  getCoAuthoringSession: (
    documentId: string
  ) => CoAuthoringSessionContext | undefined;
  getCoAuthoringSessionsIds: () => string[];
  updateServerCopy: (items: PWItem[]) => Promise<void>;
  coAuthoringFeatures: CoAuthoringFeatureFlags;
  startCoAuthoringSession: (
    item: PWFileType,
    onComplete: () => void
  ) => Promise<void>;
  joinCoAuthoringSession: (
    item: PWFileType,
    onComplete: () => void
  ) => Promise<void>;
  takeOverCoAuthoringSession: (
    item: PWFileType,
    onComplete: () => void
  ) => Promise<void>;
} & OneDriveFileStatusManager;

export type OneDriveFileStatusManager = {
  checkCoAuthoringSessionExists: (document: PWItem) => boolean;
  getSessionFileStatus: (itemId: string) => Promise<OneDriveFileStatus>;
  getCoAuthoringSessionParticipants: (documentId: string) => OneDriveUserData[];
};

export type OneDriveFileStatus =
  | 'Available'
  | 'Editing'
  | 'NotFound'
  | 'Error'
  | 'OldService';

export type OneDriveUserData = {
  userName: string;
  userId: string;
};

const Context = createContext<GraphApiContext | undefined>(undefined);

export function GraphApiProvider({
  children
}: ProviderProps<undefined>): JSX.Element {
  const graphApi = useGraphApi();

  return <Context.Provider value={graphApi}>{children}</Context.Provider>;
}

export function useGraphApiContext(): GraphApiContext {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      'useGraphApiContext must be used within a graphApiProvider'
    );
  }
  return context;
}
