import { useCallback } from 'react';
import type { PWProject } from '@bentley/pw-api';
import type { DynamicFolderSettings } from '../../../actions/dynamicFolderSettings';
import { getFolderSettings } from '../../../actions/dynamicFolderSettings';
import { getDocumentCodeFormDefinition } from '../../../actions/formRendering';
import {
  useEnvironmentContext,
  useHttpService,
  usePluginContext,
  useProjectSettingsContext
} from '../../../context';
import { getItemAncestors } from '../../../services/data';

type AllowDCWManager = {
  dcwAllowedByFeatures: boolean;
  getDCWAllowed: (folder: PWProject) => Promise<boolean>;
};

export function useAllowDCW(): AllowDCWManager {
  const {
    organizationId,
    httpService: projectSettingsHttpService,
    projectSettings
  } = useProjectSettingsContext();
  const { connection, contextId } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const httpService = useHttpService();

  const ecPluginEnabled = ecPluginFeatures.docCodeWizard();

  const allowDCW = useCallback(
    (
      folderSettings: DynamicFolderSettings | undefined,
      docCodeDefined: boolean
    ) => {
      if (!folderSettings && connection.Canned) {
        return false;
      }

      if (!folderSettings) {
        return ecPluginEnabled;
      }

      if (connection.Canned && folderSettings.UseDCWInPWIC !== undefined) {
        return folderSettings.UseDCWInPWIC;
      }

      if (docCodeDefined) {
        return folderSettings.UseDCWInFoldersWithCode;
      }

      return folderSettings.UseDCWInFoldersWithoutCode;
    },
    [connection.Canned, ecPluginEnabled]
  );

  const getDCWAllowed = useCallback(
    async (folder: PWProject): Promise<boolean> => {
      if (!ecPluginEnabled) {
        return false;
      }

      if (!projectSettingsHttpService) {
        throw new Error('Http service not initialized');
      }

      const docCodeFormDef = await getDocumentCodeFormDefinition(
        folder.instanceId,
        httpService
      );

      const ancestorIds = (
        await getItemAncestors(folder, connection.ProjectId, httpService)
      ).map((ancestor) => ancestor.instanceId);

      const fetchedFolderSettings = await getFolderSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        contextId ?? '',
        organizationId,
        folder.instanceId,
        projectSettings,
        ancestorIds,
        projectSettingsHttpService
      );

      return allowDCW(fetchedFolderSettings, docCodeFormDef !== undefined);
    },
    [
      allowDCW,
      ecPluginEnabled,
      connection.DatasourceInstanceId,
      connection.ProjectId,
      contextId,
      httpService,
      organizationId,
      projectSettings,
      projectSettingsHttpService
    ]
  );

  return {
    dcwAllowedByFeatures: ecPluginEnabled,
    getDCWAllowed
  };
}
