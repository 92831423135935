import { useCallback } from 'react';
/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { PWDataItem } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTelemetry
} from '../../context';
import { outToMeSearch } from '../../services/search';

export function useOutToMeSearch(): () => Promise<PWDataItem[]> {
  const { startTelemetry, endTelemetry } = useTelemetry();
  const { userManager } = useEnvironmentContext();
  const { getCoAuthoringSessionsIds } = useGraphApiContext();
  const { connection, logError } = usePluginContext();
  const {
    searchState: { setOutToMeCount }
  } = useNavigationContext();
  const httpService = useHttpService();

  const outToMe = useCallback(async (): Promise<PWDataItem[]> => {
    if (!userManager.currentUserId || !connection.ProjectId) {
      return [];
    }

    try {
      startTelemetry('Search');
      const items = await outToMeSearch(
        userManager.currentUserId,
        getCoAuthoringSessionsIds(),
        connection.ProjectId,
        setOutToMeCount,
        httpService
      );
      endTelemetry('Search', {
        searchType: 'Out to me',
        numItems: items.length
      });

      return items;
    } catch (error) {
      logError('Out to me search failed', { error });
      throw error;
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * getCoAuthoringSessionsIds should not re-trigger effect
     */
  }, [
    connection.ProjectId,
    httpService,
    setOutToMeCount,
    startTelemetry,
    endTelemetry,
    userManager.currentUserId
  ]);

  return outToMe;
}
