import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { getProject, itemIsProject, parseRelatedParent } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../context';
import type { DescendantProperties } from './folderDescendants';
import { getFolderDescendantProperties } from './folderDescendants';

type InfoPanelProperties = {
  descendantProperties?: DescendantProperties;
  parent?: PWProject;
};

export function useFetchInfoPanelProperties(): InfoPanelProperties {
  const httpService = useHttpService();
  const { connection } = usePluginContext();

  const { ecPluginFeatures } = useEnvironmentContext();
  const { infoPanelDisplayed } = useNavigationContext();
  const { actionableItems } = useTableItemContext();

  const [descendantProperties, setDescendantProperties] =
    useState<DescendantProperties>();
  const [parent, setParent] = useState<PWProject>();

  const fetchDescendantProperties = useCallback(
    async (abortController: AbortController): Promise<void> => {
      setDescendantProperties(undefined);

      if (!ecPluginFeatures.apiAncestor()) {
        return;
      }

      if (actionableItems.length != 1) {
        return;
      }

      const item = actionableItems[0];
      if (!itemIsProject(item)) {
        return;
      }

      const descendantProperties = await getFolderDescendantProperties(
        item,
        httpService,
        { abortController }
      );

      if (!abortController.signal.aborted) {
        setDescendantProperties(descendantProperties);
      }
    },
    [actionableItems, ecPluginFeatures, httpService]
  );

  const fetchParent = useCallback(
    async (abortController: AbortController): Promise<void> => {
      setParent(undefined);

      if (actionableItems[0].instanceId == connection.ProjectId) {
        // Do not fetch parent for the root project
        return;
      }

      const parentGuid = actionableItems[0].ParentGuid;

      const allItemsShareParent = actionableItems.every(
        (item) => item.ParentGuid === parentGuid
      );

      if (!allItemsShareParent || !parentGuid) {
        return;
      }

      if (actionableItems.every(parseRelatedParent)) {
        setParent(parseRelatedParent(actionableItems[0]));
        return;
      }

      const parent = await getProject({
        instanceId: parentGuid,
        httpService: httpService,
        requestOptions: { abortController }
      });

      if (!abortController.signal.aborted) {
        setParent(parent);
      }
    },
    [actionableItems, httpService]
  );

  useEffect(() => {
    const abortController = new AbortController();

    if (infoPanelDisplayed && actionableItems.length > 0) {
      void fetchDescendantProperties(abortController);
      void fetchParent(abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [
    infoPanelDisplayed,
    actionableItems,
    fetchDescendantProperties,
    fetchParent
  ]);

  const infoPanelProperties = useMemo((): InfoPanelProperties => {
    return {
      descendantProperties,
      parent
    };
  }, [descendantProperties, parent]);

  return infoPanelProperties;
}
