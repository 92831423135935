import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject,
  WSGChangedInstanceResponse,
  WSGInstance
} from '@bentley/pw-api';
import {
  byDefaultOrder,
  flattenProperties,
  getProjects
} from '@bentley/pw-api';

/**
 * Returns subfolders of the passed in folder, with any checked rows removed
 * @param {PWProject} currentFolder The parent folder to retrieve children from
 * @param {PWItem[]} itemsMoving Items to exclude from the results, typically the checked rows being moved
 * @param {HttpService} httpService Http service
 */
export async function getMoveSubfolders(
  currentFolder: PWProject,
  itemsMoving: PWItem[],
  httpService: HttpService
): Promise<PWProject[]> {
  const subfolders = await getProjects({
    parentId: currentFolder.instanceId,
    httpService,
    requestOptions: { uncached: true }
  });
  const availableFolders = subfolders
    .filter(
      (folder) =>
        !itemsMoving.map((item) => item.instanceId).includes(folder.instanceId)
    )
    .sort(byDefaultOrder);
  return availableFolders;
}

export function getItemInstance(
  item: PWItem,
  target: PWParentType
): WSGInstance {
  return {
    className: item.className,
    instanceId: item.instanceId,
    changeState: 'modified',
    relationshipInstances: [
      {
        className: `${
          item.className == 'Project' ? 'Project' : 'Document'
        }Parent`,
        schemaName: 'PW_WSG',
        direction: 'forward',
        relatedInstance: {
          className: 'Project',
          instanceId: target.instanceId,
          schemaName: 'PW_WSG'
        }
      }
    ],
    schemaName: 'PW_WSG'
  } as WSGInstance;
}

export async function getCreatedProjectInstance(
  response: Response
): Promise<PWProject> {
  const changedInstance = (await response.json()) as WSGChangedInstanceResponse;
  const instance = flattenProperties(
    changedInstance.changedInstance.instanceAfterChange
  );

  const parentGuid =
    instance.relationshipInstances?.[0].relatedInstance.instanceId;
  return { ...instance, ParentGuid: parentGuid } as PWProject;
}
