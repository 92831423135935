import React from 'react';
import { Alert } from '@itwin/itwinui-react';
import type { ConsumerApp } from '../../context';
import { useLocalStorage } from '../../hooks/useStorage';
import { t } from '../../services/translation';

type FlagBannerProps = {
  contextId?: string;
  bannerInfo: BannerInformation;
  consumerApp?: ConsumerApp;
};
export type BannerInformation = {
  bannerText: string;
  consumerApp: string;
  moreInfoButton: string;
  moreInfoUrl: string;
};

export function FlagBanner({
  contextId,
  bannerInfo,
  consumerApp
}: FlagBannerProps): JSX.Element | null {
  const [bannerDismissed, setBannerDismissed] = useLocalStorage(
    `flagBannerDismissed:${contextId ?? ''}:${
      bannerInfo.bannerText ?? 'generic'
    }`,
    false
  );

  if (!bannerInfo.bannerText) {
    return null;
  }

  if (bannerInfo.consumerApp && bannerInfo.consumerApp != '') {
    const apps = bannerInfo.consumerApp.split(',');
    const consumer = consumerApp ?? 'Generic';
    if (!apps.includes(consumer)) {
      return null;
    }
  }

  const infoMessage = t(bannerInfo.bannerText as Parameters<typeof t>[0]);

  function closeBanner(): void {
    setBannerDismissed(true);
  }

  function onMoreInfoClick(): void {
    window.open(bannerInfo.moreInfoUrl, '_blank', 'noopener noreferrer');
  }

  const onClick =
    bannerInfo.moreInfoUrl && bannerInfo.moreInfoUrl != ''
      ? onMoreInfoClick
      : undefined;

  if (bannerDismissed) {
    return null;
  }

  return (
    <Alert.Wrapper type="informational" className="main-page-alert">
      <Alert.Icon />
      <Alert.Message>
        {infoMessage}
        {onClick && (
          <Alert.Action onClick={onClick}>
            {t(bannerInfo.moreInfoButton as Parameters<typeof t>[0])}
          </Alert.Action>
        )}
      </Alert.Message>
      <Alert.CloseButton onClick={closeBanner} />
    </Alert.Wrapper>
  );
}
