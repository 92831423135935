import { useEffect, useState } from 'react';
import type { PWDataItem, PWParentType } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import { getAssociatedVersions } from './associatedVersions';

export function useVersionNames(items: PWDataItem[]): Set<string> {
  const httpService = useHttpService();
  const [versionNames, setVersionNames] = useState<Set<string>>(new Set());

  useEffect(() => {
    const abortController = new AbortController();
    async function updateVersionNames(): Promise<void> {
      for (const item of items) {
        const parent = { instanceId: item.ParentGuid } as PWParentType;

        const itemVersions = await getAssociatedVersions(
          item,
          parent,
          httpService,
          abortController
        );

        const itemVersionNames = itemVersions
          .map((item) => item.Version)
          .filter((version) => version);

        if (!abortController.signal.aborted) {
          setVersionNames(
            (currentNames) => new Set([...currentNames, ...itemVersionNames])
          );
        }
      }
    }

    void updateVersionNames();

    return () => {
      abortController.abort();
    };
  }, [items, httpService]);

  return versionNames;
}
