import type { HttpService, PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary/progressSummaryModal';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { getClearErrorText } from '../../services/errorMappings';
import type { Connection } from '../../services/support/connection';
import {
  deleteDescendantSettings,
  deleteFolderSettings
} from '../dynamicFolderSettings/dynamicFolderSettingsData';
import {
  notifyDeleteCompleted,
  notifyDeleteErrors,
  notifyDeleteInProgress
} from './notifications';

export type DeleteProgressTracker = {
  deletedItems: number;
  failedItems: number;
  totalItems: number;
  failures: ProgressSummaryItem[];
};

export function buildDeleteEvents(
  items: PWItem[],
  openModal: OpenModal,
  closeModal: CloseModal,
  connection: Connection,
  wsgHttpService: HttpService,
  driveHttpService: HttpService,
  trackFeature: TrackFeature,
  userFolderPermissionsIsAvailable: boolean,
  isAdmin: boolean,
  onComplete?: () => void
): {
  onCompleteCallback: () => void;
  onDeleteSuccessCallback: (item: PWItem, response: Response) => void;
  onDeleteFailureCallback: (item: PWItem, response: Response) => Promise<void>;
} {
  const progressTracker = newDeleteProgressTracker(items);
  let toastHandle = notifyDeleteInProgress(items);

  function onCompleteCallback(): void {
    toastHandle = notifyDeleteCompleted(
      progressTracker.deletedItems,
      toastHandle
    );
    if (progressTracker.failures.length) {
      notifyDeleteErrors(progressTracker.failures, openModal, closeModal);
    }
    onComplete?.();
  }

  function onDeleteSuccessCallback(item: PWItem, response: Response): void {
    onDeleteSuccess(progressTracker);
    if (itemIsProject(item)) {
      void deleteFolderSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        item.instanceId,
        trackFeature,
        driveHttpService,
        false
      );
      void deleteDescendantSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        item.instanceId,
        trackFeature,
        wsgHttpService,
        driveHttpService,
        userFolderPermissionsIsAvailable,
        isAdmin,
        false
      );
    }
  }

  async function onDeleteFailureCallback(
    item: PWItem,
    response: Response
  ): Promise<void> {
    await onDeleteFailure(item, response, progressTracker);
  }

  return {
    onCompleteCallback,
    onDeleteSuccessCallback,
    onDeleteFailureCallback
  };
}

function newDeleteProgressTracker(items: PWItem[]): DeleteProgressTracker {
  return {
    deletedItems: 0,
    failedItems: 0,
    totalItems: items.length,
    failures: []
  } as DeleteProgressTracker;
}

function onDeleteSuccess(progressTracker: DeleteProgressTracker): void {
  progressTracker.deletedItems++;
}

async function onDeleteFailure(
  item: PWItem,
  response: Response,
  progressTracker: DeleteProgressTracker
): Promise<void> {
  progressTracker.failedItems++;

  const responseBody = (await response.json()) as Record<string, string> | null;
  const errorText = getClearErrorText(
    (responseBody && responseBody.errorMessage) || response.statusText,
    item
  );

  progressTracker.failures.push({ item, errorText });
}
