import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  filterDataItems,
  itemIsDataItem,
  itemIsFlatSet,
  itemIsImmutable,
  itemIsLogicalSet,
  itemIsProject
} from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Flex, Text } from '@itwin/itwinui-react';
import { usePluginContext } from '../../context';
import { t } from '../../services/translation';
import { allowReplace } from '../replace';
import { allowNewVersion } from '../version';
import type { ConflictResolution } from './conflictResolution';
import { ConflictResolutionOption } from './conflictResolutionOption';

type ConflictResolutionSummaryProps = {
  containsImmutableFile: boolean;
  doNotShowReplaceOption?: boolean;
  doNotShowVersionOption?: boolean;
  resolution: ConflictResolution;
  siblings: PWItem[];
  setDoForNextAvailable: (available: boolean) => void;
  setInputError: (value: boolean) => void;
  updateResolution: (resolution: ConflictResolution) => void;
};

export function ConflictResolutionSummary({
  containsImmutableFile,
  doNotShowReplaceOption,
  doNotShowVersionOption,
  resolution,
  siblings,
  setDoForNextAvailable,
  setInputError,
  updateResolution
}: ConflictResolutionSummaryProps): JSX.Element {
  const {
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();

  const existingItem = primaryConflict(resolution);

  function primaryConflict(resolution: ConflictResolution): PWItem {
    if (!resolution.destinationItems.length) {
      return resolution.sourceItem;
    }

    const latestDataItem = filterDataItems(resolution.destinationItems).find(
      (item) => item.IsLatest
    );

    if (itemIsDataItem(resolution.sourceItem) && latestDataItem) {
      return latestDataItem;
    }

    return resolution.destinationItems[0];
  }

  function summaryText(): string {
    if (itemIsProject(resolution.sourceItem)) {
      return t('ConflictResolution.FolderExists');
    }

    if (resolution.conflictType == 'filename') {
      return t('ConflictResolution.ItemWithFileNameExists');
    }

    return t('ConflictResolution.ItemWithNameExists');
  }

  function keepDescription(): string {
    if (itemIsProject(resolution.sourceItem)) {
      return t('ConflictResolution.SkipFolder');
    }

    if (itemIsFlatSet(resolution.sourceItem)) {
      return t('ConflictResolution.SkipFlatSet');
    }

    return t('ConflictResolution.SkipFile');
  }

  function renameDescription(): string {
    if (itemIsProject(resolution.sourceItem)) {
      return t('ConflictResolution.FolderWillBeNamed');
    }

    return t('ConflictResolution.FileWillBeNamed');
  }

  function showReplaceOption(): boolean {
    if (itemIsImmutable(existingItem) || itemIsLogicalSet(existingItem)) {
      return false;
    }

    if (
      !allowReplace([existingItem], iModelBridges) ||
      doNotShowReplaceOption
    ) {
      return false;
    }

    return true;
  }

  function showVersionOption(): boolean {
    if (!allowNewVersion([existingItem]) || doNotShowVersionOption) {
      return false;
    }

    // Cannot create new version of logical set during conflict resolution as the references will be lost
    // Need to be resolved by WSG Plugin: https://dev.azure.com/bentleycs/ProjectWise/_workitems/edit/1347760
    if (itemIsLogicalSet(existingItem)) {
      return false;
    }

    return true;
  }

  return (
    <Flex flexDirection="column" alignItems="stretch">
      <Text>{summaryText()}</Text>
      <ItemSummary
        item={existingItem}
        className="crm-item-summary"
        useFileName={resolution.conflictType == 'filename'}
      />
      <ConflictResolutionOption
        action="keep"
        label={t('ConflictResolution.KeepOriginal')}
        description={keepDescription()}
        resolution={resolution}
        updateResolution={updateResolution}
        siblings={siblings}
        setDoForNextAvailable={setDoForNextAvailable}
        containsImmutableFile={containsImmutableFile}
        setInputError={setInputError}
      />
      {showReplaceOption() && (
        <ConflictResolutionOption
          action="replace"
          label={t('ConflictResolution.ReplaceOriginal')}
          description={t('ConflictResolution.ReplaceOriginalDescription')}
          resolution={resolution}
          updateResolution={updateResolution}
          siblings={siblings}
          setDoForNextAvailable={setDoForNextAvailable}
          containsImmutableFile={containsImmutableFile}
          setInputError={setInputError}
        />
      )}
      {showVersionOption() && (
        <ConflictResolutionOption
          action="version"
          label={t('ConflictResolution.AddAsNewVersion')}
          description={t('ConflictResolution.AddAsNewVersionDescription')}
          inputBoxText={t('ConflictResolution.NewVersionWillBeNamed')}
          resolution={resolution}
          updateResolution={updateResolution}
          siblings={siblings}
          setDoForNextAvailable={setDoForNextAvailable}
          containsImmutableFile={containsImmutableFile}
          setInputError={setInputError}
        />
      )}

      <ConflictResolutionOption
        action="rename"
        label={t('ConflictResolution.AddAndRename')}
        description={renameDescription()}
        inputBoxText={renameDescription()}
        resolution={resolution}
        updateResolution={updateResolution}
        siblings={siblings}
        setDoForNextAvailable={setDoForNextAvailable}
        containsImmutableFile={containsImmutableFile}
        setInputError={setInputError}
      />
    </Flex>
  );
}
