import React from 'react';
import { Button } from '@itwin/itwinui-react';
import { WithItemResolver } from '../../../components/itemResolver';
import { PluginWrapper } from '../../../components/pluginWrapper';
import type { GetToken } from '../../../services/http';
import { t } from '../../../services/translation';
import { useGetLinkSelector } from '../getLinkSelector';
import { GetShareableLinkUI } from '../getShareableLinkUI';
import { useLinkConnection } from './connectionResolver';
import { GetLinkPluginsProvider } from './getLinkPluginsProvider';

export type GetLinkPluginProps = {
  buddiRegionCode: string;
  connectionId: string;
  contextId: string;
  itemInstanceIds: [string, ...string[]];
  getOidcToken: GetToken;
  getSamlToken: GetToken;
  onClose: () => void;
};

export function GetLinkPlugin(props: GetLinkPluginProps): JSX.Element {
  return (
    <PluginWrapper>
      <GetLinkPluginsProvider {...props}>
        <GetShareableLinkResolver
          buddiRegionCode={props.buddiRegionCode}
          contextId={props.contextId}
          itemInstanceIds={props.itemInstanceIds}
          onClose={props.onClose}
        />
      </GetLinkPluginsProvider>
    </PluginWrapper>
  );
}

type GetShareableLinkResolverProps = {
  buddiRegionCode: string;
  contextId: string;
  itemInstanceIds: [string, ...string[]];
  onClose: () => void;
};

function GetShareableLinkResolver({
  buddiRegionCode,
  contextId,
  itemInstanceIds,
  onClose
}: GetShareableLinkResolverProps) {
  const connection = useLinkConnection();

  const { getLink } = useGetLinkSelector({
    buddiRegionCode,
    contextId,
    connection
  });

  const GetShareableLinkWrapper = WithItemResolver(
    GetShareableLinkUI,
    itemInstanceIds
  );

  return (
    <GetShareableLinkWrapper
      dataAccessLevel="WorkArea"
      getLink={getLink}
      CloseButton={<Button onClick={onClose}>{t('Generic.Close')}</Button>}
    />
  );
}
