import type {
  HttpService,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import type { View } from '../useViews';
import { folderViewToView, instanceToFolderView } from './instanceToFolderView';
import type { Column, View as WSGView } from './view';

export type FolderView = {
  view: WSGView;
  columns: Column[];
};

export async function getPWEFolderView(
  projectId: string,
  httpService: HttpService,
  options?: RequestOptions
): Promise<View> {
  const query = buildQuery(projectId);
  const response = await httpService.get(query, options);
  const view = await parseView(response);
  return view;
}

function buildQuery(projectId: string): string {
  const baseQuery = 'PW_WSG/View';
  const select = '?$select=*,Column.*';
  const filter = `&$filter=ProjectDefaultDocumentListView-backward-Project.$id eq '${projectId}'`;
  return `${baseQuery}${select}${filter}`;
}

async function parseView(response: Response): Promise<View> {
  if (!response.ok) {
    throw new Error('Error fetching view');
  }

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
  const data = (await response.json()) as WSGInstancesResponse;

  if (!data.instances.length) {
    throw new Error('No columns were retrieved from selected view');
  }

  const folderView = instanceToFolderView(data.instances[0]);
  const view = folderViewToView(folderView);
  return view;
}
