import './folderSelectDropdown.css';

import React, { useEffect, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { SvgCaretDownSmall } from '@itwin/itwinui-icons-react';
import { DropdownMenu, LabeledInput } from '@itwin/itwinui-react';
import { FolderSelectNode } from './folderSelectNode';

type FolderSelectDropdownProps = {
  breadcrumbsToInitialSelectedFolder: string[];
  label?: string;
  rootFolder: PWProject;
  selectedFolder: PWProject;
  onFolderSelect: (folder: PWProject) => void;
};

export function FolderSelectDropdown({
  breadcrumbsToInitialSelectedFolder,
  label,
  rootFolder,
  selectedFolder,
  onFolderSelect
}: FolderSelectDropdownProps): JSX.Element {
  const [breadcrumbPath, setBreadcrumbPath] = useState<string[]>(
    breadcrumbsToInitialSelectedFolder
  );

  function appendBreadcrumb(path: string[]): void {
    setBreadcrumbPath([rootFolder.instanceId, ...path]);
  }

  function updateSelectedFolder(folder: PWProject): void {
    onFolderSelect(folder);
  }

  function menuItems(closeDropdown: () => void): JSX.Element[] {
    return [
      <FolderSelectNode
        breadcrumbPath={breadcrumbPath}
        folder={rootFolder}
        selectedFolder={selectedFolder}
        onFolderSelect={updateSelectedFolder}
        appendBreadcrumb={appendBreadcrumb}
        closeDropdown={closeDropdown}
        key={0}
      />
    ];
  }

  // retrieve width size from menu box to set for dropdown
  const [dropDownWidth, setDropDownWidth] = useState<number>(400);

  useEffect(() => {
    resizeDropdownMenu();
    window.addEventListener('resize', resizeDropdownMenu);
    return () => {
      window.removeEventListener('resize', resizeDropdownMenu);
    };
  }, []);

  function resizeDropdownMenu() {
    const folderBox = document.querySelector('#folder-select-dropdown');
    const width = folderBox?.clientWidth;
    if (width) {
      setDropDownWidth(width);
    }
  }

  function onCaretClick() {
    const folderBox = document.querySelector(
      '#folder-select-dropdown'
    ) as HTMLElement;
    folderBox?.click();
  }

  return (
    <DropdownMenu
      menuItems={menuItems}
      className="fsd-dropdown"
      style={{ width: dropDownWidth }}
      matchWidth={true}
    >
      <LabeledInput
        data-testid="folder-select-dropdown"
        id="folder-select-dropdown"
        readOnly={true}
        value={selectedFolder.Name}
        label={label}
        svgIcon={
          <SvgCaretDownSmall
            onClick={onCaretClick}
            className="fsd-input-icon"
          />
        }
        role="combobox"
        wrapperProps={{ className: 'fsd-input' }}
      />
    </DropdownMenu>
  );
}
