import type { PWItem } from '@bentley/pw-api';
import {
  itemIsLockedOrCheckedOut,
  itemIsProject,
  itemIsReadOnly
} from '@bentley/pw-api';

export function allowRename(
  items: PWItem[],
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || items.length != 1) {
    return false;
  }

  const item = items[0];

  if (itemIsProject(item)) {
    return true;
  }

  if (itemIsLockedOrCheckedOut(item) || itemIsReadOnly(item)) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}
