import React from 'react';
import { itemIsProject } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { useNavigationContext } from '../../context';
import type { ResolveOutputFunction } from '../../hooks/useModalOutput';
import { t } from '../../services/translation';
import { DocumentPickerModalWrapper } from '../flatSetActions/useFlatSetWizard/documentPickerModalWrapper';

export type ConfirmRestoreToFolderModalOutput = {
  folderId?: string;
};

export type ConfirmRestoreToFolderModalProps =
  ResolveOutputFunction<ConfirmRestoreToFolderModalOutput>;

export function ConfirmRestoreToFolderModal({
  resolveModalOutput
}: ConfirmRestoreToFolderModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();

  function onItemsSelected(selectedItems: DocumentLink[]): void {
    primaryModal.close();
    resolveModalOutput({ folderId: selectedItems[0].id });
  }

  function onClose(): void {
    primaryModal.close();
    resolveModalOutput({ folderId: undefined });
  }

  return (
    <DocumentPickerModalWrapper
      title={t('Restore.RestoreToFolder')}
      isSelectable={itemIsProject}
      maximumSelectedItems={1}
      customFilter={itemIsProject}
      onItemsSelected={onItemsSelected}
      onClose={onClose}
      hideConnectionListView={true}
    />
  );
}
