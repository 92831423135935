import './pwInfoPanel.css';

import React from 'react';
import {
  InformationPanel,
  InformationPanelBody,
  InformationPanelHeader
} from '@itwin/itwinui-react';
import { PWInfoPanelTabs } from './body/pwInfoPanelTabs';
import { PWInfoPanelHeader } from './header/pwInfoPanelHeader';
import { useInfoPanelActions } from './useInfoPanelActions';

export function PWInfoPanel(): JSX.Element {
  const { actions, isOpen, orientation, onClose } = useInfoPanelActions();

  return (
    <InformationPanel
      isOpen={isOpen}
      orientation={orientation}
      className="pw-info-panel"
      data-testid="info-panel"
    >
      <InformationPanelHeader onClose={onClose} actions={actions}>
        <PWInfoPanelHeader />
      </InformationPanelHeader>
      <InformationPanelBody className="pw-info-body">
        <PWInfoPanelTabs orientation={orientation} />
      </InformationPanelBody>
    </InformationPanel>
  );
}
