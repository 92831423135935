import React from 'react';
import { Table } from '@itwin/itwinui-react';
import type { AuditTrail } from '../../../actions/exportAuditTrail';
import { t } from '../../../services/translation';
import { useAuditTrailColumns } from './useAuditTrailColumns';

type AuditTrailTableProps = {
  auditTrail: AuditTrail[];
};

export function AuditTrailTable({
  auditTrail
}: AuditTrailTableProps): JSX.Element {
  const { columns, hiddenColumns } = useAuditTrailColumns();

  return (
    <Table<AuditTrail>
      columns={columns}
      style={{ height: '100%' }}
      data={auditTrail}
      initialState={{ hiddenColumns }}
      enableVirtualization={true}
      isResizable={true}
      isSortable={true}
      className="audit-trail-table"
      columnResizeMode="expand"
      density="extra-condensed"
      emptyTableContent={t('AuditTrail.NoRecords')}
      data-testid="audit-trail-table"
    />
  );
}
