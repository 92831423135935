import { useRef } from 'react';
import { usePluginContext } from '../../context';
import type {
  InProgressActivity,
  InProgressJob
} from '../../hooks/useProgressManager';
import {
  uploadFinishedToast,
  uploadRestartedToast,
  uploadStartedToast
} from './uploadProgressToasts';

type UploadProgressToastFunctions = {
  trackJob: (jobId: string) => void;
};

export function useUploadProgressToast(): UploadProgressToastFunctions {
  const { progressManager } = usePluginContext();

  const forceToast = useRef<boolean>(false);

  function toastIntervalLoop(job: InProgressJob): void {
    const intervalId = setInterval(() => {
      if (!job.toastHandle) {
        clearInterval(intervalId);
        return;
      }

      if (!job.activitiesInProgress.length) {
        return;
      }

      const { successCount, failureCount, totalCount } =
        uploadActivityCounts(job);

      if (!successCount && !failureCount) {
        job.toastHandle = uploadStartedToast(job.toastHandle);
        return;
      }

      if (successCount == totalCount) {
        job.toastHandle = uploadFinishedToast(job.toastHandle, successCount);
        clearInterval(intervalId);
        return;
      }

      if (successCount + failureCount == totalCount) {
        clearInterval(intervalId);
        job.toastHandle.close();
        return;
      }

      // More items added to job
      if (forceToast.current) {
        forceToast.current = false;

        job.toastHandle = uploadRestartedToast(
          job.toastHandle,
          successCount,
          totalCount
        );
      }
    }, 500);
  }

  function trackJob(jobId: string) {
    const job = progressManager.progressTracker.jobsInProgress.find(
      (job) => job.jobId == jobId
    );

    if (!job?.toastHandle) {
      return;
    }

    // Don't show toast if all jobs have filename conflict (409 status)
    const allConflicts = job.activitiesInProgress.every(
      (aip) => aip.error?.status === 409
    );
    if (job.activitiesInProgress.length && allConflicts) {
      return;
    }

    forceToast.current = true;

    toastIntervalLoop(job);
  }

  return { trackJob };
}

function uploadActivityCounts(job: InProgressJob): {
  successCount: number;
  failureCount: number;
  totalCount: number;
} {
  const uploadActivities = filterUploadActivities(job);

  const successCount = uploadActivities.filter(
    (a) => a.status == 'success'
  ).length;

  const failureCount = uploadActivities.filter(
    (a) => a.status == 'error' || a.status == 'abort'
  ).length;

  const totalCount = uploadActivities.length;

  return { successCount, failureCount, totalCount };
}

function filterUploadActivities(job: InProgressJob): InProgressActivity[] {
  return job.activitiesInProgress.filter(
    ({ activityType }) =>
      activityType == 'upload' || activityType == 'uploadFolder'
  );
}
