import { useCallback, useMemo } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import { itemIsParentType, itemIsProject } from '@bentley/pw-api';
import { allowPDFMarkup, useOpenPDF } from '../../actions/pdf';
import { showOpenIn365 } from '../../actions/useGraphApi';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useGraphApiContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { CurrentUserDocumentAccessRights } from '../../services/permissions';
import type { PWDataManager } from '../useData';
import { useOpenCoAuthoring } from '../useOpenCoAuthoring';

export type TableItemAction = {
  itemHasClickAction: (item: PWItem) => boolean;
  onItemClick: (item: PWItem, autoSelectedItem?: PWItem) => void;
};

export function useTableItemAction(
  forceSelectedIds: (ids: string[]) => void,
  dataManager: PWDataManager,
  getMappedDocumentPermissionsById: (
    id: string
  ) => Promise<CurrentUserDocumentAccessRights | undefined>
): TableItemAction {
  const {
    launchDarklyFeatures: {
      showWebViewDocumentViewer,
      showPdfMarkupDocumentPreview,
      documentViewExcludedExtensions,
      showCoauthoringUpdates
    }
  } = useFeatureTracking();
  const { readOnly } = usePluginContext();
  const { isLogicalUser } = useConnectionAuth();
  const {
    navigationManager: { navigateTo }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView },
    workAreaManager: { addCachedParent, itemExistsInConnection }
  } = useAppViewContext();
  const { office365CoAuthoringSettings } = useEnvironmentContext();
  const { checkCoAuthoringSessionExists } = useGraphApiContext();
  const openCoAuthoring = useOpenCoAuthoring();
  const { refreshCurrentFolder } = dataManager;

  const openPDF = useOpenPDF();

  const allowParentClick = useCallback(
    (item: PWItem): boolean => {
      if (!itemIsParentType(item)) {
        return false;
      }

      if (!itemExistsInConnection(item)) {
        return false;
      }

      return true;
    },
    [itemExistsInConnection]
  );

  const onParentClick = useCallback(
    (item: PWItem, autoSelectedItem?: PWItem): void => {
      if (itemIsProject(item)) {
        addCachedParent(item);
      }

      setAppView('FolderNavigation');
      navigateTo(item as PWParentType, true);
      if (autoSelectedItem) {
        setTimeout(() => forceSelectedIds([autoSelectedItem.instanceId]));
      }
    },
    [setAppView, navigateTo, addCachedParent, forceSelectedIds]
  );

  const allowPDFClick = useCallback(
    (item: PWItem): boolean => {
      const excludedExtensions = String(
        documentViewExcludedExtensions || ''
      ).split(',');

      return allowPDFMarkup(
        [item],
        readOnly,
        excludedExtensions,
        isLogicalUser,
        Boolean(showPdfMarkupDocumentPreview),
        Boolean(showWebViewDocumentViewer)
      );
    },
    [
      documentViewExcludedExtensions,
      readOnly,
      isLogicalUser,
      showPdfMarkupDocumentPreview,
      showWebViewDocumentViewer
    ]
  );

  const onPDFClick = useCallback(
    (item: PWItem): void => {
      openPDF(item);
    },
    [openPDF]
  );

  const allowCoAuthoringClick = useCallback(
    async (item: PWItem) => {
      if (
        !showOpenIn365(
          [item],
          readOnly,
          checkCoAuthoringSessionExists(item),
          itemExistsInConnection,
          office365CoAuthoringSettings
        )
      ) {
        return false;
      }
      if (!showCoauthoringUpdates) {
        return false;
      }

      const permissions = await getMappedDocumentPermissionsById(
        item.instanceId
      );
      if (!permissions?.Write || !permissions?.FileWrite) {
        return false;
      }
      return true;
    },
    [
      checkCoAuthoringSessionExists,
      getMappedDocumentPermissionsById,
      itemExistsInConnection,
      office365CoAuthoringSettings,
      readOnly,
      showCoauthoringUpdates
    ]
  );

  const itemHasClickAction = useCallback(
    (item: PWItem): boolean => {
      if (currentAppView.type == 'RecycleBin') {
        return false;
      }

      if (allowParentClick(item)) {
        return true;
      }

      if (allowPDFClick(item)) {
        return true;
      }

      return false;
    },
    [allowPDFClick, allowParentClick, currentAppView.type]
  );

  const onItemClickAsync = useCallback(
    async (item: PWItem, autoSelectedItem?: PWItem): Promise<void> => {
      if (allowParentClick(item)) {
        return onParentClick(item, autoSelectedItem);
      }

      if (await allowCoAuthoringClick(item)) {
        return openCoAuthoring(item, refreshCurrentFolder);
      }

      if (allowPDFClick(item)) {
        return onPDFClick(item);
      }
    },
    [
      allowParentClick,
      allowCoAuthoringClick,
      allowPDFClick,
      onParentClick,
      openCoAuthoring,
      refreshCurrentFolder,
      onPDFClick
    ]
  );

  const onItemClick = useCallback(
    (item: PWItem, autoSelectedItem?: PWItem): void => {
      void onItemClickAsync(item, autoSelectedItem);
    },
    [onItemClickAsync]
  );

  const tableItemAction = useMemo(
    () => ({
      itemHasClickAction,
      onItemClick
    }),
    [itemHasClickAction, onItemClick]
  );

  return tableItemAction;
}
