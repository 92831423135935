import type { WSGInstancesResponse } from '@bentley/pw-api';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import type { UserManagerWithSaml } from '../../hooks/useOidc';
import type { CachedTokenFunctions } from './cachedToken';
import { cachedToken } from './cachedToken';

type TokenExchangeServiceResponse = {
  RequestedSecurityToken: string;
} & WSGInstancesResponse;

// Todo: Can this be a hook instead of a static class?
export class PublicServiceToken {
  private static _enableOidcForWsg: boolean;
  private static _tokenExchangeUrl: string;
  private static oidcToken: CachedTokenFunctions;
  private static samlToken: CachedTokenFunctions;
  private static userManager: UserManagerWithSaml;

  public static get enabled(): boolean {
    return this._enableOidcForWsg;
  }

  public static async Initialize(
    tokenExchangeUrl: string,
    enableOidcForWsg: boolean,
    userManager: UserManagerWithSaml
  ): Promise<void> {
    if (
      this._tokenExchangeUrl === tokenExchangeUrl &&
      this._enableOidcForWsg === enableOidcForWsg
    ) {
      return;
    }

    this._tokenExchangeUrl = tokenExchangeUrl;
    this._enableOidcForWsg = enableOidcForWsg;
    this.userManager = userManager;

    if (enableOidcForWsg) {
      this.oidcToken = cachedToken(this.getOidcToken, 55);
      this.samlToken = cachedToken(this.getSamlToken, 55);

      await this.oidcToken.get();
      await this.samlToken.get();
    }
  }

  private static get getOidcToken(): () => Promise<string> {
    return async () => {
      const user = await this.userManager.signinSilent();
      return user?.access_token ?? '';
    };
  }

  private static get getSamlToken(): () => Promise<string> {
    return async () => {
      const tokenExchangeHttpService = new HttpService({
        authorization: new AuthorizationService({
          getOidcToken: this.oidcToken.get
        }),
        baseUrl: this._tokenExchangeUrl
      });

      const response = await tokenExchangeHttpService.get('');

      if (!response.ok || response.status != 200) {
        throw new Error(
          `Failed to get SAML token: ${response.status} response`
        );
      }

      const data = (await response.json()) as TokenExchangeServiceResponse;
      if (data.errorMessage) {
        throw new Error(data.errorMessage);
      }

      return data.RequestedSecurityToken ?? '';
    };
  }

  public static get getToken(): (
    tokenType: 'OIDC' | 'SAML'
  ) => Promise<string> {
    return (tokenType: 'OIDC' | 'SAML'): Promise<string> => {
      if (!this._enableOidcForWsg) {
        throw new Error('OidcForWsg is not enabled');
      }

      if (!this.oidcToken || !this.samlToken) {
        throw new Error('Public service token not initialized');
      }

      if (tokenType == 'OIDC') {
        return this.oidcToken.get();
      }

      return this.samlToken.get();
    };
  }
}
