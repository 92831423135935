import type { User } from 'oidc-client';
import { useCallback, useEffect, useState } from 'react';
import { useConfigContext, useOidcContext } from '../../context';
import { refreshInterval } from './refreshInterval';

export function useSamlAuthentication(
  user: User | null | undefined,
  forceRefresh: boolean
): User | null | undefined {
  const { config } = useConfigContext();
  const { userManager } = useOidcContext();

  const [samlUser, setSamlUser] = useState<User | null>();

  const getSamlUser = useCallback(
    async (forceRefresh = true) => {
      if (!user) {
        return null;
      }

      try {
        const samlUser = await userManager.getSamlUser(
          config.OIDC_IMSScope,
          forceRefresh as boolean
        );

        if (!samlUser) {
          return null;
        }

        samlUser.token_type = 'Token';
        return samlUser;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [userManager, config, user]
  );

  useEffect(() => {
    async function fetchUser(): Promise<void> {
      const samlUser = await getSamlUser(forceRefresh);
      setSamlUser(samlUser);
    }

    void fetchUser();
  }, [getSamlUser, forceRefresh]);

  useEffect(() => {
    function tokenRefresh(samlUser: User) {
      const refreshTime = refreshInterval(samlUser);

      const timeoutId = setTimeout(() => {
        void refreshUser();
      }, refreshTime);

      return timeoutId;
    }

    async function refreshUser() {
      const samlUser = await getSamlUser();
      setSamlUser(samlUser);
    }

    if (!samlUser) {
      return;
    }

    const refreshTimeoutId = tokenRefresh(samlUser);

    return () => {
      clearTimeout(refreshTimeoutId);
    };
  }, [samlUser, getSamlUser]);

  return samlUser;
}
