import { useCallback } from 'react';
import { useNavigationContext } from '../../context';

type OpenFileInputFunction = () => void;

export function useOpenFileInput(): OpenFileInputFunction {
  const { dropzoneRef, setAllowDragAndDrop } = useNavigationContext();

  const onDocumentBodyFocus = useCallback((): void => {
    setAllowDragAndDrop(true);
    document.body.onfocus = null;
  }, [setAllowDragAndDrop]);

  const openFileInput = useCallback((): void => {
    dropzoneRef.current?.open();
    setAllowDragAndDrop(false);
    document.body.onfocus = () => onDocumentBodyFocus();
  }, [dropzoneRef, onDocumentBodyFocus, setAllowDragAndDrop]);

  return openFileInput;
}
