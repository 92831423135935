import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { useConnectionAuth, useTelemetry } from '../../../context';
import { t } from '../../../services/translation';
import { LoadingPage } from '../../loadingPage';

type WithAuthenticationProps = {
  children: ReactNode;
};

export function WithAuthentication({
  children
}: WithAuthenticationProps): JSX.Element {
  const {
    connectionAuthenticated,
    isLoading,
    error,
    errorComponent,
    signInComponent
  } = useConnectionAuth();
  const { startTelemetry, endTelemetry } = useTelemetry();

  useEffect(() => {
    if (isLoading) {
      startTelemetry('SigningIn');
    } else {
      endTelemetry('SigningIn');
    }
  }, [isLoading]);

  function signingIn(): boolean {
    return isLoading || connectionAuthenticated == 'checking';
  }

  function signedIn(): boolean {
    return !isLoading && connectionAuthenticated == 'authenticated';
  }

  if (signingIn()) {
    return (
      <LoadingPage
        loadingSubText={t('SignIn.SigningIn')}
        fromValue={50}
        toValue={75}
      />
    );
  }

  if (signedIn()) {
    return <>{children}</>;
  }

  if (error) {
    return errorComponent;
  }

  return signInComponent;
}
