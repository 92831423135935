import type { ComponentPropsWithoutRef } from 'react';
import React, { useEffect, useState } from 'react';
import { SvgCalendar } from '@itwin/itwinui-icons-react';
import type { Input } from '@itwin/itwinui-react';
import {
  DatePicker,
  InputGrid,
  InputWithDecorations,
  Label,
  Popover
} from '@itwin/itwinui-react';

type InputProps = ComponentPropsWithoutRef<typeof Input> & {
  'data-testid'?: string;
};
type WrapperProps = ComponentPropsWithoutRef<typeof InputWithDecorations>;

type DatePickerInputProps = {
  date?: Date;
  disabled?: boolean;
  inputProps?: Omit<InputProps, 'disabled'>;
  label?: string;
  wrapperProps?: WrapperProps;
  isDateDisabled?: (date: Date) => boolean;
  onChange: (date?: Date) => void;
};

export function DatePickerInput({
  date,
  disabled,
  inputProps,
  label,
  wrapperProps,
  isDateDisabled,
  onChange
}: DatePickerInputProps): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null
  );

  function onDateSelected(date?: Date): void {
    onChange(date);
    setIsVisible(false);
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value;
    setInputValue(value);
    if (!value) {
      onChange(undefined);
    }

    const parsedDate = parseInput(value);
    if (parsedDate && !isNaN(parsedDate.valueOf())) {
      onChange(parsedDate);
    }
  }

  useEffect(() => {
    if (date && !isNaN(date.valueOf())) {
      setInputValue(formatDate(date));
    }
  }, [date]);

  return (
    <InputGrid
      labelPlacement="inline"
      {...wrapperProps}
      ref={setWrapperElement}
    >
      {label && <Label disabled={disabled}>{label}</Label>}
      <InputWithDecorations isDisabled={disabled}>
        <InputWithDecorations.Input
          value={inputValue}
          onChange={onInputChange}
          placeholder="MMM dd, yyyy"
          disabled={disabled}
          {...inputProps}
        />
        <Popover
          content={
            <DatePicker
              date={date}
              onChange={onDateSelected}
              setFocus
              showYearSelection={true}
              isDateDisabled={isDateDisabled}
              showDatesOutsideMonth={false}
              applyBackground={false}
            />
          }
          visible={isVisible}
          onVisibleChange={setIsVisible}
          applyBackground={true}
          positionReference={wrapperElement as HTMLElement}
        >
          <InputWithDecorations.Button disabled={disabled}>
            <SvgCalendar />
          </InputWithDecorations.Button>
        </Popover>
      </InputWithDecorations>
    </InputGrid>
  );
}

function parseInput(text: string): Date {
  // Checks whether date is in correct format e.g. Feb 11, 2021
  if (/^[a-z]{3}\s\d{1,2},\s\d{4}$/gi.test(text)) {
    return new Date(text);
  }
  return new Date('');
}

function formatDate(date: Date): string {
  const formatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
  return formatter.format(date);
}
