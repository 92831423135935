import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { PWDataItem, WSGInstance, WSGProperties } from '@bentley/pw-api';
import { useTableItemContext } from '../../../context';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { changedProperties } from '../../documentCreation/properties';
import { validateEnvironmentProperties } from '../../documentCreation/properties/compare';
import {
  createDocumentEnvironmentInstance,
  getDocumentEnvironmentInstances,
  saveDocumentEnvironmentInstance
} from '../data';
import {
  handleAttributesFormError,
  handleAttributesFormSuccess
} from '../error';
import {
  currentEnvironmentInstanceState,
  currentSheetState,
  editingAttributesFormState,
  originalEnvironmentInstanceState,
  selectedItemsState
} from '../state';
import { saveInProgressState } from './state';

type SaveFunctions = {
  saveInstance: () => Promise<void>;
};

export function useSave(): SaveFunctions {
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const [originalEnvironmentInstance, setOriginalEnvironmentInstance] =
    useRecoilState(originalEnvironmentInstanceState);

  const { dataManager } = useTableItemContext();

  const selectedItems = useRecoilValue(selectedItemsState);
  const currentSheet = useRecoilValue(currentSheetState);

  const setEditingAttributesForm = useSetRecoilState(
    editingAttributesFormState
  );
  const [saveInProgress, setSaveInProgress] =
    useRecoilState(saveInProgressState);

  async function saveInstance(): Promise<void> {
    if (saveInProgress) {
      return;
    }
    setSaveInProgress(true);
    try {
      if (selectedItems.length > 1) {
        await saveMultipleInstances();
      } else {
        await saveSingleInstance();
      }
      setEditingAttributesForm(false);
      void dataManager.refresh();
      handleAttributesFormSuccess();
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        void handleAttributesFormError(error);
      }
    } finally {
      setSaveInProgress(false);
    }
  }

  async function saveSingleInstance(): Promise<void> {
    if (!currentEnvironmentInstance) {
      return;
    }

    const updatedProperties = changedProperties(
      currentEnvironmentInstance.properties,
      originalEnvironmentInstance?.properties
    );
    const validatedProperties = validateEnvironmentProperties(
      updatedProperties,
      originalEnvironmentInstance?.properties ?? {}
    );

    const updatedInstance = await createOrUpdateInstance(
      currentEnvironmentInstance,
      validatedProperties
    );

    setOriginalEnvironmentInstance(updatedInstance);
    setCurrentEnvironmentInstance(updatedInstance);
  }

  async function createOrUpdateInstance(
    environmentInstance: WSGInstance,
    properties: WSGProperties
  ): Promise<WSGInstance> {
    if (!originalEnvironmentInstance) {
      return createDocumentEnvironmentInstance(
        selectedItems[0],
        environmentInstance,
        properties
      );
    }

    return saveDocumentEnvironmentInstance(
      selectedItems[0],
      environmentInstance,
      properties
    );
  }

  async function saveMultipleInstances(): Promise<void> {
    await Promise.all(selectedItems.map(fetchInstanceAndSave));

    setOriginalEnvironmentInstance(currentEnvironmentInstance);
  }

  async function fetchInstanceAndSave(item: PWDataItem): Promise<void> {
    return usingConcurrencyLimiter(async () => {
      const environmentInstances = await getDocumentEnvironmentInstances(item);
      const environmentInstance = environmentInstances[currentSheet];
      const updatedProperties = changedProperties(
        currentEnvironmentInstance?.properties,
        originalEnvironmentInstance?.properties
      );

      if (!environmentInstance) {
        if (!currentEnvironmentInstance) {
          return;
        }
        await createDocumentEnvironmentInstance(
          item,
          currentEnvironmentInstance,
          updatedProperties
        );
      } else {
        await saveDocumentEnvironmentInstance(
          item,
          environmentInstance,
          updatedProperties
        );
      }
    });
  }

  return { saveInstance };
}
