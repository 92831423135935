import React from 'react';
import { Tooltip } from '@itwin/itwinui-react';

type TooltipProps = Omit<React.ComponentPropsWithoutRef<typeof Tooltip>, 'as'>;

export function PWTooltip(
  props: TooltipProps & { 'aria-hidden'?: string }
): JSX.Element {
  const { content, children, 'aria-hidden': ariaHidden, ...rest } = props;
  if (!content) {
    return <>{children}</>;
  }
  return (
    <Tooltip content={content} {...rest}>
      {children}
    </Tooltip>
  );
}
