import { useMemo } from 'react';
import { useNavigationContext, usePropertyContext } from '../../context';
import type { PWDataManager } from '../useData';

export function useTableLoading(dataManager: PWDataManager): boolean {
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const {
    viewManager: { initialized: viewsInitialized }
  } = usePropertyContext();

  const tableLoading = useMemo((): boolean => {
    if (dataManager.isLoading) {
      return true;
    }

    if (currentParent.instanceId === undefined) {
      return true;
    }

    if (!viewsInitialized) {
      return true;
    }
    return false;
  }, [dataManager.isLoading, currentParent, viewsInitialized]);

  return tableLoading;
}
