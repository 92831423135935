import './uploadDropzone.css';

import type { ReactNode } from 'react';
import React from 'react';
import type { DropEvent } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { parseFileNameWithoutExtension } from '@bentley/pw-api';
import { SvgUploadToCloud } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import type { ConflictResolution } from '../../actions/conflictResolution/conflictResolution';
import { gatherConflictsFromUploadNode } from '../../actions/conflictResolution/conflictResolution';
import {
  allowUploadFiles,
  anyFileNamesTooLong,
  anyFolderNamesTooLong,
  conflictResolutionModal,
  getTotalFileCount,
  getTotalFiles,
  newFileWithProperties,
  notifyDriveServiceError,
  notifyFileSystemError,
  notifyGenericUploadError,
  notifyNoFilesFound,
  onDragAndDrop,
  onFilesSelected,
  truncateFileNamesModal,
  uploadMultipleWorkflow
} from '../../actions/upload';
import { resolveDCWConflicts } from '../../actions/upload/dcwConflictResolution';
import type { UploadNode } from '../../actions/upload/tree';
import { getItemFromResponse } from '../../actions/upload/utils';
import {
  useAppViewContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import type { ToastHandle } from '../../services/pwToast';
import { t } from '../../services/translation';
import {
  ConfirmCancelModal,
  DocumentWizardModal,
  useAllowDCW
} from '../documentCreation';
import { notifyInitializingUpload } from './notifications';
import { useRefreshAndSelect } from './useRefreshAndSelect';
import { useUploadProgressToast } from './useUploadProgressToast';

type UploadDropzoneProps = {
  children: ReactNode;
};

export function UploadDropzone({ children }: UploadDropzoneProps): JSX.Element {
  const { logError, readOnly, progressManager } = usePluginContext();
  const httpService = useHttpService();
  const { trackFeature } = useFeatureTracking();

  const {
    navigationManager: { currentParent },
    primaryModal,
    setShowDropzone,
    showDropzone,
    allowDragAndDrop,
    setInfoPanelDisplayed,
    dropzoneRef
  } = useNavigationContext();

  const {
    appViewManager: { currentAppView },
    folderSettingsManager: { errorRetrievingSettings },
    workAreaManager: { workAreaAvailable }
  } = useAppViewContext();

  const {
    dataManager,
    selectedState: { setSelectedItems }
  } = useTableItemContext();

  const { trackJob } = useUploadProgressToast();

  const { getDCWAllowed } = useAllowDCW();
  const refreshAndSelect = useRefreshAndSelect();

  function hideDropzone(): void {
    setShowDropzone(false);
  }

  function className(): string {
    if (
      showDropzone &&
      !dataManager.isLoading &&
      allowUploadFiles(currentParent, currentAppView.type, readOnly)
    ) {
      return 'dz-container';
    }

    return 'dz-container dz-hidden';
  }

  function resolveConflictsWithWizard(
    uploadNode: UploadNode
  ): (resolutions: ConflictResolution[]) => void {
    function onConflictsComplete(
      uploadNode: UploadNode,
      uploadedItems: PWItem[]
    ): void {
      if (uploadNode.files.length || uploadNode.directories.length) {
        openDocumentWizard(uploadNode);
      } else {
        primaryModal.close();
      }

      refreshAndSelect(uploadedItems);
    }

    function resolveWorkflow(resolutions: ConflictResolution[]): void {
      void resolveDCWConflicts(
        uploadNode,
        resolutions,
        httpService,
        trackFeature,
        progressManager,
        onConflictsComplete
      );
    }

    return resolveWorkflow;
  }

  function prepareDCWUploadWorkflow(
    uploadJobId: string,
    toastHandle?: ToastHandle
  ): (uploadNode: UploadNode) => Promise<Response[]> {
    progressManager.progressTracker.cancelAllUploads = false;

    async function uploadWorkFlowAndRefresh(
      uploadNode: UploadNode
    ): Promise<Response[]> {
      const responses = await uploadMultipleWorkflow(
        uploadNode,
        uploadNode.parent ?? (currentParent as PWProject),
        httpService,
        primaryModal.open,
        primaryModal.close,
        trackFeature,
        progressManager,
        uploadJobId,
        undefined,
        true,
        trackJob,
        toastHandle
      );

      const createdItems = (
        await Promise.all(
          responses.map((response) => getItemFromResponse(response))
        )
      ).filter((item) => item) as PWItem[];

      refreshAndSelect(createdItems);

      return responses;
    }

    return uploadWorkFlowAndRefresh;
  }

  function kickOffNonDCWUploadWorkflow(
    uploadNode: UploadNode,
    jobId?: string,
    toastHandle?: ToastHandle
  ): Promise<Response[]> {
    progressManager.progressTracker.cancelAllUploads = false;
    return uploadMultipleWorkflow(
      uploadNode,
      uploadNode.parent ?? (currentParent as PWProject),
      httpService,
      primaryModal.open,
      primaryModal.close,
      trackFeature,
      progressManager,
      jobId,
      refreshAndSelect,
      true,
      trackJob,
      toastHandle
    );
  }

  const getFileName = (
    uploadNode: UploadNode,
    fileNumber: number,
    removeExtension?: boolean
  ) => {
    let name;

    if (uploadNode.files.length === 0) {
      const allFiles = getTotalFiles(uploadNode);
      name = allFiles[0].name;
    } else {
      name = uploadNode.files?.[fileNumber].name;
    }

    return removeExtension ? parseFileNameWithoutExtension(name) : name;
  };

  const openDocumentWizard = (
    uploadNode: UploadNode,
    toastHandle?: ToastHandle
  ) => {
    const uploadJobId = progressManager.addNewJobToTracker('upload');
    const dcwUploadNode = prepareDCWUploadWorkflow(uploadJobId, toastHandle);
    const totalFileCount = getTotalFileCount(uploadNode);

    // Create helper methods used by the Wizard
    const wizardOnClose = (
      uploadNode: UploadNode,
      fileNumber: number,
      id?: string,
      customName?: string,
      documentName?: string,
      documentDescription?: string
    ) => {
      // Assign custom file properties
      const file = newFileWithProperties(uploadNode.files[fileNumber], {
        id,
        customName,
        documentName,
        documentDescription
      });

      const newNode = {
        ...uploadNode,
        files: [file],
        directories: []
      } as UploadNode;

      void dcwUploadNode(newNode);

      ++fileNumber;
      primaryModal.close();

      if (fileNumber >= uploadNode.files.length) {
        return;
      } else {
        runWizard(fileNumber, dcwUploadNode, toastHandle);
      }
    };

    const runWizard = (
      fileNumber: number,
      dcwUploadNode: (uploadNode: UploadNode) => Promise<Response[]>,
      toastHandle?: ToastHandle
    ) => {
      function confirmCancel() {
        function removeFileAndRunWizard() {
          if (uploadNode.directories.length) {
            return;
          }

          const newNode = {
            ...uploadNode,
            files: uploadNode.files.slice(fileNumber + 1)
          };

          openDocumentWizard(newNode);
        }

        primaryModal.open(
          <ConfirmCancelModal
            closeModal={primaryModal.close}
            closeConfirmModal={primaryModal.close}
            onConfirm={removeFileAndRunWizard}
          />
        );
      }

      primaryModal.open(
        <DocumentWizardModal
          onSuccess={(
            id?: string,
            customName?: string,
            documentName?: string,
            documentDescription?: string
          ) => {
            wizardOnClose(
              uploadNode,
              fileNumber,
              id,
              customName,
              documentName,
              documentDescription
            );
          }}
          fileNumber={fileNumber}
          numberOfFiles={uploadNode.files.length}
          totalFileCount={totalFileCount}
          projectId={currentParent.instanceId}
          defaultDocumentName={getFileName(uploadNode, fileNumber)}
          defaultDocumentDescription={getFileName(uploadNode, fileNumber, true)}
          fileName={getFileName(uploadNode, fileNumber)}
          version=""
          onCancel={totalFileCount > 1 ? confirmCancel : primaryModal.close}
          upload={dcwUploadNode}
          uploadNode={uploadNode}
          closeModal={primaryModal.close}
          refreshAndSelect={refreshAndSelect}
          toastHandle={toastHandle}
        />
      );
    };

    // Open the Wizard initially
    runWizard(0, dcwUploadNode, toastHandle);
  };

  async function getFilesAndInitializeUpload(event: DropEvent) {
    // Handle drag
    if (event.type != 'drop' && event.type != 'change') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
      return (event as any).dataTransfer.files;
    }

    // Get uploadNode
    let uploadNode: UploadNode | undefined = undefined;
    try {
      uploadNode =
        event.type == 'drop'
          ? await onDragAndDrop(event as DragEvent)
          : onFilesSelected(event as Event);
    } catch (e) {
      logError('File system error', { error: e });
      notifyFileSystemError();
    }

    let toastHandle = undefined;
    try {
      // Hide dropzone for 'drop'
      if (event.type == 'drop') {
        hideDropzone();
      }

      // Do not use undefined uploadNode
      if (!uploadNode) {
        return [];
      }

      if (errorRetrievingSettings) {
        logError('Error retrieving folder settings');
        notifyDriveServiceError();
        return;
      }

      toastHandle = notifyInitializingUpload();

      const dcwAllowed = await getDCWAllowed(currentParent as PWProject);

      dropzoneWorkflow(uploadNode, dcwAllowed, toastHandle);
    } catch (e) {
      toastHandle?.close();
      logError('Error initializing upload', { error: e });
      notifyGenericUploadError();
    }
  }

  function dropzoneWorkflow(
    uploadNode: UploadNode,
    allowDCW: boolean,
    toastHandle: ToastHandle
  ): void {
    if (!uploadNode.files.length && !uploadNode.directories.length) {
      logError('No files found');
      notifyNoFilesFound(toastHandle);
      return;
    }

    const followUpActionsOnCancel = (): void => {
      toastHandle.close();
    };

    if (anyFileNamesTooLong(uploadNode) || anyFolderNamesTooLong(uploadNode)) {
      truncateFileNamesModal(
        uploadNode,
        primaryModal.open,
        primaryModal.close,
        trackFeature,
        (uploadNode: UploadNode) =>
          dropzoneWorkflow(uploadNode, allowDCW, toastHandle),
        followUpActionsOnCancel
      );

      return;
    }

    try {
      setInfoPanelDisplayed(false);
      setSelectedItems([]);

      const fileCount = getTotalFileCount(uploadNode);

      // Document Wizard
      if (allowDCW && fileCount) {
        const conflicts = gatherConflictsFromUploadNode(
          uploadNode,
          dataManager.items
        );

        if (conflicts.length > 0) {
          toastHandle.close();
          return conflictResolutionModal(
            conflicts.map((conflict) => ({ item: conflict })),
            currentParent,
            primaryModal.open,
            primaryModal.close,
            resolveConflictsWithWizard(uploadNode),
            primaryModal.close
          );
        }

        // Open Wizard
        return openDocumentWizard(uploadNode, toastHandle);
      }

      // Normal upload
      void kickOffNonDCWUploadWorkflow(uploadNode, undefined, toastHandle);
    } catch (e) {
      logError('Error initializing upload', { error: e });
      notifyGenericUploadError();
    }
  }

  return (
    <div
      className="table-container"
      onDragOver={() => setShowDropzone(allowDragAndDrop)}
      onDrop={() => setShowDropzone(false)}
      data-testid="table-container"
    >
      {children}
      <Dropzone
        getFilesFromEvent={getFilesAndInitializeUpload}
        onDragLeave={hideDropzone}
        ref={dropzoneRef}
        disabled={!workAreaAvailable}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...(getRootProps() as React.HTMLAttributes<HTMLElement>)}
            className={className()}
            data-testid="dropzone-container"
          >
            <input
              {...(getInputProps() as React.InputHTMLAttributes<HTMLInputElement>)}
              id={'dz-input'}
              data-testid="dropzone"
            />
            <span className="dz-msg">
              <SvgUploadToCloud />
              {workAreaAvailable ? (
                <Text as="p">{t('Upload.DropFoldersAndFiles')}</Text>
              ) : (
                <Text as="p">{t('Upload.Disabled')}</Text>
              )}
            </span>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
