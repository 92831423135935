import React from 'react';
import { Trans } from 'react-i18next';
import { Anchor } from '@itwin/itwinui-react';
import { href } from '../../constants';
import type { ConsumerApp } from '../../context';
import { t } from '../../services/translation';
import { ErrorPage } from '../errorPage';

type ServerErrorComponentProps = {
  onRetry: () => void;
  consumerApp?: ConsumerApp;
};
export function ServerErrorComponent({
  onRetry,
  consumerApp
}: ServerErrorComponentProps): JSX.Element {
  function buildErrorMessage(): string | JSX.Element {
    if (consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams') {
      const translatedError = (
        <Trans i18nKey="ServerError.CorsTeamsError">
          Unable to communicate with server. The issue may be caused by missing{' '}
          <Anchor
            href={href.teamsCors}
            target="_blank"
            isExternal={true}
            rel="noopener noreferrer"
          >
            WSG server CORS configuration
          </Anchor>{' '}
          for Microsoft Teams. Contact your ProjectWise administrator to ensure
          it is updated.
        </Trans>
      );
      return translatedError;
    }

    const errorMsg = t('ErrorPage.UnableToCommunicate');
    return errorMsg;
  }

  const errorMsg = buildErrorMessage();

  return (
    <ErrorPage
      errorType="500"
      errorMsg={errorMsg}
      primaryButtonLabel={t('ErrorPage.Retry')}
      primaryButtonHandle={onRetry}
    />
  );
}
