import type { PWProject } from '@bentley/pw-api';

export function savedSearchesSupported(parent: PWProject): boolean {
  if (!parent.IsRichProject) {
    return false;
  }

  if (
    parent.TypeString != 'Project' &&
    parent.TypeString != 'ConnectedProject'
  ) {
    return false;
  }

  return true;
}
