import type { ComponentPropsWithoutRef } from 'react';
import React, { useMemo } from 'react';
import { SvgMore } from '@itwin/itwinui-icons-react';
import { ButtonGroup, DropdownMenu, IconButton } from '@itwin/itwinui-react';
import type { MenuItemAction } from '../MenuItemAction';
import { formatMenuItems } from '../formatMenuItems';
import { menuItemActionToMenuItem } from './menuItemActionToMenuItem';
import { ToolbarButton } from './toolbarButton';

type ButtonGroupProps = ComponentPropsWithoutRef<typeof ButtonGroup>;

type PWToolbarProps = {
  buttons: MenuItemAction[];
  numVisibleButtons?: number;
} & Omit<ButtonGroupProps, 'children' | 'as'>;

export function PWToolbar({
  buttons,
  numVisibleButtons = buttons.length,
  ...buttonGroupProps
}: PWToolbarProps): JSX.Element | null {
  const buttonWidth = 50;
  const maxWidth = numVisibleButtons
    ? `${numVisibleButtons * buttonWidth + 5}px`
    : 'unset';

  buttons = formatMenuItems(buttons);

  const toolbarButtonCount = useMemo((): number => {
    if (numVisibleButtons >= buttons.length) {
      return buttons.length + 1;
    }

    return numVisibleButtons - 1;
  }, [buttons.length, numVisibleButtons]);

  if (!buttons.length) {
    return null;
  }

  if (buttons.length <= toolbarButtonCount) {
    return (
      <div style={{ maxWidth }}>
        <ButtonGroup {...buttonGroupProps}>
          {buttons.map((button) => (
            <ToolbarButton action={button} key={button.title} />
          ))}
        </ButtonGroup>
      </div>
    );
  }

  // Todo: overflowButton is the native iTwin implementation to add a 'more' button based on space
  // We're having trouble with it closing when updating the buttons https://github.com/iTwin/iTwinUI/issues/1118
  // Once the bug is fixed, we can go back to the native implementation
  return (
    <div style={{ maxWidth }}>
      <ButtonGroup
        {...buttonGroupProps}
        // overflowButton={(overflowStart) => (
        //   <ToolbarOverflow buttons={buttons} overflowStart={overflowStart} />
        // )}
      >
        {buttons.slice(0, toolbarButtonCount).map((button) => (
          <ToolbarButton action={button} key={button.title} />
        ))}

        <DropdownMenu
          className="context-menu-list"
          menuItems={(close) =>
            buttons
              .slice(toolbarButtonCount)
              .map((button) => menuItemActionToMenuItem(button, close))
          }
        >
          <IconButton data-testid="MoreButton">
            <SvgMore />
          </IconButton>
        </DropdownMenu>
      </ButtonGroup>
    </div>
  );
}
