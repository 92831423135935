import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  useAppViewContext,
  useFolderExplorerContext,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';

type OnDeleteComplete = () => void;

export function useOnDeleteComplete(items: PWItem[]): OnDeleteComplete {
  const httpService = useHttpService();
  const { refreshCoAuthoringSessions } = useGraphApiContext();
  const { reloadExplorerFolders } = useFolderExplorerContext();
  const {
    breadcrumbManager: { breadcrumbs },
    navigationManager: { navigateTo }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder },
    selectedState: { setSelectedIds }
  } = useTableItemContext();

  const onDeleteComplete = useCallback(async (): Promise<void> => {
    await httpService.cache?.clearEntriesMatching(
      items.map((item) => item.ParentGuid ?? '')
    );

    reloadExplorerFolders(items.map((item) => item.ParentGuid ?? ''));
    refreshCurrentFolder();

    setSelectedIds([]);
    void refreshCoAuthoringSessions();

    const closestParent = breadcrumbs.find(
      (breadcrumb) => breadcrumb.instanceId == items[0].ParentGuid
    );

    if (closestParent && currentAppView.type == 'FolderNavigation') {
      navigateTo(closestParent, true);
    }
  }, [
    breadcrumbs,
    currentAppView.type,
    httpService,
    items,
    navigateTo,
    refreshCurrentFolder,
    reloadExplorerFolders,
    setSelectedIds,
    refreshCoAuthoringSessions
  ]);

  return () => void onDeleteComplete();
}
