import React from 'react';
import { Trans } from 'react-i18next';
import { SvgAdd, SvgFolderOpened } from '@itwin/itwinui-icons-react';
import { Anchor, Flex, Text } from '@itwin/itwinui-react';
import { useNavigationContext, usePluginContext } from '../../context';
import { t } from '../../services/translation';
import { useUploadFilesAction } from '../toolbar/newActionsToolbar';

export function EmptyFolderState(): JSX.Element {
  const { readOnly, connection } = usePluginContext();
  const { navigationManager } = useNavigationContext();

  const isConnectionRoot =
    connection.ProjectId == (navigationManager.currentParent.instanceId ?? '');

  const { disabled: uploadFilesDisabled, onClick: uploadFiles } =
    useUploadFilesAction();

  function onUploadFilesClick(e: React.MouseEvent): void {
    if (uploadFilesDisabled) {
      return;
    }

    uploadFiles?.(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
  }

  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgFolderOpened className="ets-icon" />
      <Text>
        {t('Grid.NoItems')}
        {!isConnectionRoot && <GoToParentLink />}
      </Text>

      {!readOnly && !uploadFilesDisabled && (
        <Text className="ets-w-icon">
          <Trans i18nKey="Grid.UseTheNewButtonToUpload">
            Use the <SvgAdd /> New button to{' '}
            <Anchor onClick={onUploadFilesClick}>
              upload folders and files
            </Anchor>
            .
          </Trans>
        </Text>
      )}
    </Flex>
  );
}

function GoToParentLink(): JSX.Element | null {
  const {
    navigationManager: { navigateToParent }
  } = useNavigationContext();

  function onGoToParentClick(): void {
    navigateToParent?.();
  }

  if (!navigateToParent) {
    return null;
  }

  return (
    <>
      {' '}
      <Anchor onClick={onGoToParentClick}>{t('Grid.GoToParent')}</Anchor>
    </>
  );
}
