import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { handleAutoUpdateAssociation } from '../../components/manageConnection/connectionStatus/updateAssociation';
import type { GetToken } from '../../services/http';
import type { FederatedRepository } from '../../services/support';
import type { Connection } from '../../services/support/connection';
import type {
  DriveSync,
  IFederatedRepositoryApi
} from '../../services/support/federatedRepositoryApi';
import {
  DeleteError,
  ERROR_NOTFOUND,
  ERROR_UNKNOWN,
  fetchServerVersion,
  getDisplayLabel,
  getFederatedApi
} from '../../services/support/federatedRepositoryApi';
import type { ILegacyRepositoryApi } from '../../services/support/legacyRepository';
import {
  getLegacyConnectionFromUrl,
  getLegacyRepositoryApi
} from '../../services/support/legacyRepository';
import {
  federatedRepoToConnection,
  federatedRepoToLegacyRepo,
  parseConnectionUrl,
  parseServerVersion
} from '../../services/support/repositoryMapper';
import { t } from '../../services/translation';
import { getECPluginVersion, isLegacyPlugin } from '../useECPluginVersion';
import type { CloseModal, OpenModal } from '../useModal';
import { useEquivalentState } from '../useNonRenderingState';
import { byPrimaryAndName } from './sort';
import { useLegacyRepoConversion } from './useLegacyRepoConversion';

type IAddRepository = (
  federatedRepository: FederatedRepository,
  syncUsers: boolean,
  enableDriveSync: boolean,
  primaryConnection: boolean,
  openModal: OpenModal,
  closeModal: CloseModal
) => Promise<FederatedRepository>;

export type RepositoryApi = {
  addRepository: IAddRepository;
  deleteRepositories: (ids: string[]) => Promise<void>;
  updateRepository: (
    federatedRepository: FederatedRepository,
    syncUsers: boolean,
    enableDriveSync: boolean,
    primaryConnection: boolean,
    openModal: OpenModal,
    closeModal: CloseModal
  ) => Promise<void>;
  reload: () => void;
  fetchCannedConnection: () => Promise<FederatedRepository | undefined>;
  deleteLegacyRepository: (connection: FederatedRepository) => Promise<void>;
};

export type DriveApi = {
  updateDriveSync: (repositoryId: string, driveSync: boolean) => Promise<void>;
  getDriveSynced: () => Promise<DriveSync | null>;
  setMultiMode: (multiMode: boolean) => Promise<void>;
};

export interface IFederatedRepositoryData extends RepositoryApi, DriveApi {
  federatedRepos: FederatedRepository[];
  userSyncedRepos: string[];
  driveSyncedConnections: Connection[];
  primaryConnectionId: string;
  driveSyncMultiMode: boolean;
  activeRepo: FederatedRepository | undefined;
  selectFederatedRepository: (repoId?: string) => void;
  unsetActiveRepo: () => void;
  initialized: boolean;
  error?: Error;
}

type FederatedRepositoryProps = {
  buddiRegionCode: string;
  contextId?: string;
  driveLegacySupport?: boolean;
  gprId: number;
  userOrg?: string;
  getOidcToken: GetToken;
  getSamlToken: GetToken;
};

export function useFederatedRepositories({
  buddiRegionCode,
  contextId,
  driveLegacySupport,
  gprId,
  userOrg,
  getOidcToken,
  getSamlToken
}: FederatedRepositoryProps): IFederatedRepositoryData {
  const serviceUrl = useBuddi('NewProductSettingsService.Url', buddiRegionCode);
  const legacyServiceUrl = useBuddi('ProjectGateway.URL', buddiRegionCode);
  const connectionServiceUrl = useBuddi(
    'ProjectWiseWebConnections.URL',
    buddiRegionCode
  );

  const { synchronizeFederatedAndLegacyRepos } = useLegacyRepoConversion({
    buddiRegionCode,
    contextId,
    getSamlToken
  });

  const [federatedRepos, setFederatedRepos] = useState<FederatedRepository[]>(
    []
  );
  const [userSyncedRepos, setUserSyncedRepos] = useEquivalentState<string[]>(
    []
  );
  const [driveSyncedRepos, setDriveSyncedRepos] = useState<DriveSync>({
    multiMode: false,
    connections: []
  });

  const [activeRepo, setActiveRepo] = useState<FederatedRepository>();
  const [primaryConnectionId, setPrimaryConnectionId] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const federatedApi = useMemo((): IFederatedRepositoryApi | undefined => {
    if (!serviceUrl || !connectionServiceUrl) {
      return;
    }

    const federatedApi = getFederatedApi(
      gprId,
      userOrg,
      serviceUrl,
      connectionServiceUrl,
      getOidcToken,
      contextId
    );
    return federatedApi;
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * getToken should not re-trigger
     */
  }, [connectionServiceUrl, contextId, gprId, serviceUrl, userOrg]);

  const [legacyApi, setLegacyApi] = useState<ILegacyRepositoryApi>();

  const updateFederatedRepos = useCallback(
    (
      federatedRepos: FederatedRepository[],
      primaryConnectionId: string
    ): void => {
      const cannedRepo = federatedRepos.find((repo) => repo.Canned == true);
      if (cannedRepo) {
        cannedRepo.Name = t('Generic.Documents');
      }

      federatedRepos.sort(byPrimaryAndName(primaryConnectionId));
      setFederatedRepos(federatedRepos);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * setFederatedRepos should not re-trigger
     */
    []
  );

  const selectFederatedRepository = useCallback(
    (repoId?: string): void => {
      setActiveRepo(federatedRepos.find((repo) => repo.Id == repoId));
    },
    [federatedRepos]
  );

  function unsetActiveRepo(): void {
    setActiveRepo(undefined);
  }

  const backfillDriveSync = useCallback(
    async (
      repos: FederatedRepository[],
      updateDriveSyncRepos: React.Dispatch<React.SetStateAction<DriveSync>>,
      api: IFederatedRepositoryApi
    ) => {
      if (!contextId) {
        return;
      }

      // 365 use case with canned connection
      const cannedRepo = repos.find((repo) => repo.Canned == true);

      if (cannedRepo) {
        await api.addDriveSynced(cannedRepo.Id, true);

        updateDriveSyncRepos((driveSync) => ({
          ...driveSync,
          connections: [...driveSync.connections, cannedRepo.Id]
        }));

        return;
      }

      const samlToken = await getSamlToken();

      for (const repo of repos) {
        let connectionUrl = parseConnectionUrl(repo);
        const currentVersion = parseServerVersion(repo);

        if (currentVersion < 2.8) {
          const serverVersion = await fetchServerVersion(repo);
          connectionUrl = parseConnectionUrl(repo, `v${serverVersion}`);
        }

        const version = await getECPluginVersion({ connectionUrl, samlToken });

        if (!version) {
          continue;
        }

        const isLegacy = isLegacyPlugin(version);

        if (!isLegacy || driveLegacySupport) {
          await api.addDriveSynced(repo.Id, true);

          updateDriveSyncRepos((driveSync) => ({
            ...driveSync,
            connections: [...driveSync.connections, repo.Id]
          }));
        }
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * getToken should not re-trigger
     */
    [contextId, driveLegacySupport]
  );

  const upgradeWsApi = useCallback(
    async (
      repos: FederatedRepository[],
      api: IFederatedRepositoryApi,
      primaryConnectionId: string
    ): Promise<void> => {
      const upgradedRepos: FederatedRepository[] = [];

      for (const repo of repos) {
        const currentVersion = parseServerVersion(repo);

        if (currentVersion >= 2.8) {
          continue;
        }

        const serverVersion = await fetchServerVersion(repo);

        if (serverVersion > currentVersion) {
          try {
            const upgrade = await api.upgradeOne(repo.Id, serverVersion);
            if (upgrade) {
              upgradedRepos.push(upgrade);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }

      if (upgradedRepos.length) {
        updateFederatedRepos(
          [
            ...repos.filter(
              (repo) => !upgradedRepos.find((up) => up.Id == repo.Id)
            ),
            ...upgradedRepos
          ],
          primaryConnectionId
        );
      }
    },
    [updateFederatedRepos]
  );

  const initializeRepos = useCallback(
    async (abortController?: AbortController) => {
      if (!federatedApi) {
        return;
      }

      try {
        const apiData = await Promise.all([
          federatedApi.getAll(),
          federatedApi.getSynced(),
          federatedApi.getDriveSynced(),
          federatedApi.getPrimaryConnection()
        ]);

        if (abortController?.signal.aborted) {
          return;
        }

        const [repos, userSyncedRepos, driveSyncedRepos, primaryConnectionId] =
          apiData;

        updateFederatedRepos(repos, primaryConnectionId);
        setUserSyncedRepos(userSyncedRepos);
        if (driveSyncedRepos) {
          setDriveSyncedRepos(driveSyncedRepos);
        }
        setPrimaryConnectionId(primaryConnectionId);

        void upgradeWsApi(repos, federatedApi, primaryConnectionId ?? '');
        if (!driveSyncedRepos) {
          void backfillDriveSync(repos, setDriveSyncedRepos, federatedApi);
        }
        repos.forEach((repo) => void addMissingDisplayLabel(repo));
      } catch (err) {
        setError(err as Error);
      } finally {
        setInitialized(true);
      }
    },
    [federatedApi, backfillDriveSync, updateFederatedRepos, upgradeWsApi]
  );

  const fetchCannedConnection = useCallback(async () => {
    if (!federatedApi) {
      throw new Error('Federated api not initialized');
    }

    const repos = await federatedApi.getAll();
    const cannedConnection = repos.find((repo) => repo.Canned);
    return cannedConnection;
  }, [federatedApi]);

  useEffect(() => {
    const abortController = new AbortController();

    void initializeRepos(abortController);

    return () => {
      abortController.abort();
    };
  }, [initializeRepos, refresh]);

  useEffect(() => {
    if (contextId && legacyServiceUrl) {
      const legacyApi = getLegacyRepositoryApi(
        legacyServiceUrl,
        getSamlToken,
        contextId
      );
      setLegacyApi(legacyApi);
    }
  }, [contextId, legacyServiceUrl]);

  useEffect(() => {
    async function synchronizeRepos(): Promise<void> {
      if (
        !initialized ||
        !federatedApi ||
        !synchronizeFederatedAndLegacyRepos ||
        error
      ) {
        return;
      }

      const syncedRepos = await synchronizeFederatedAndLegacyRepos(
        federatedRepos,
        federatedApi
      );
      if (_.isEqual(syncedRepos, federatedRepos)) {
        return;
      }

      const driveSyncedRepos = await federatedApi.getDriveSynced();
      if (!driveSyncedRepos) {
        await backfillDriveSync(syncedRepos, setDriveSyncedRepos, federatedApi);
      }
      setFederatedRepos(syncedRepos);
      // todo: should also re-get primary connection, upgrade ws api, update display label...
      // maybe should just re-initialize if we have synchronized repos from legacy...
    }

    // Adding the new repos to PRDSS can happen in the background
    void synchronizeRepos();

    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * setFederatedRepos should not re-trigger
     */
  }, [
    federatedApi,
    federatedRepos,
    initialized,
    synchronizeFederatedAndLegacyRepos
  ]);

  const apiGuard = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends (...args: any) => Promise<U>, U>(
      api: (...params: Parameters<T>) => Promise<U>
    ): ((...params: Parameters<T>) => Promise<U>) => {
      return (...params: Parameters<T>): Promise<U> => {
        if (!initialized) {
          console.error('Federated Api not initialized');
          throw new Error(ERROR_UNKNOWN);
        }

        return api(...params);
      };
    },
    [initialized]
  );

  async function addMissingDisplayLabel(repo: FederatedRepository) {
    if (typeof repo.DisplayLabel !== 'undefined') {
      return;
    }

    const displayLabel = await getDisplayLabel(repo);
    repo.DisplayLabel = displayLabel;
  }

  const updateSyncedRepository = useCallback(
    async (repositoryId: string, syncUsers: boolean): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      const isSynced = userSyncedRepos.includes(repositoryId);

      if (isSynced == syncUsers) {
        return Promise.resolve();
      }

      let newSynced = [];

      if (syncUsers) {
        newSynced = [...userSyncedRepos, repositoryId];
        await federatedApi.addSynced(repositoryId);
      } else {
        newSynced = userSyncedRepos.filter((repo) => repo != repositoryId);
        await federatedApi.deleteSynced([repositoryId]);
      }

      setUserSyncedRepos(newSynced);
    },
    [federatedApi, userSyncedRepos]
  );

  const updateDriveSyncInternal = useCallback(
    async (
      repositoryId: string,
      enabled: boolean,
      driveSync: DriveSync
    ): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      const driveSynced = driveSync.connections;

      if (enabled && driveSynced.includes(repositoryId)) {
        return Promise.resolve();
      }

      if (!enabled && !driveSynced.includes(repositoryId)) {
        return Promise.resolve();
      }

      // drive can be synced to only one repo if multiMode is off
      // step 1 delete current sync
      if (enabled) {
        let newSyncedConnections = driveSynced;

        if (driveSynced.length == 1 && !driveSync.multiMode) {
          await federatedApi.deleteDriveSynced(driveSynced[0]);
          newSyncedConnections = [repositoryId];
        } else {
          newSyncedConnections.push(repositoryId);
        }

        await federatedApi.addDriveSynced(repositoryId);

        setDriveSyncedRepos((previousSync) => ({
          ...previousSync,
          connections: newSyncedConnections
        }));
      }

      if (!enabled) {
        await federatedApi.deleteDriveSynced(repositoryId);

        setDriveSyncedRepos((prev) => ({
          ...prev,
          connections: prev.connections.filter(
            (syncedId) => syncedId !== repositoryId
          )
        }));
      }
    },
    [federatedApi]
  );

  const updateDriveSync = useCallback(
    async (repositoryId: string, enabled: boolean): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      try {
        await updateDriveSyncInternal(repositoryId, enabled, driveSyncedRepos);
      } catch (error) {
        if ((error as Error)?.message == ERROR_NOTFOUND) {
          const newDriveSynced = await federatedApi.getDriveSynced();

          await updateDriveSyncInternal(
            repositoryId,
            enabled,
            newDriveSynced || { multiMode: false, connections: [] }
          );
        }
      }
    },
    [driveSyncedRepos, federatedApi, updateDriveSyncInternal]
  );

  const updatePrimaryConnection = useCallback(
    async (
      repositoryId: string,
      primaryConnection: boolean,
      openModal: OpenModal,
      closeModal: CloseModal,
      newRepository?: FederatedRepository
    ): Promise<string> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      if (repositoryId == primaryConnectionId && primaryConnection) {
        return Promise.resolve(repositoryId);
      }

      if (repositoryId != primaryConnectionId && !primaryConnection) {
        return Promise.resolve(primaryConnectionId);
      }

      function updateAssociation(
        repositoryId: string,
        openModal: OpenModal,
        closeModal: CloseModal
      ) {
        if (contextId) {
          let repo = newRepository ? newRepository : undefined;
          if (!repo) {
            repo = federatedRepos.find((repo) => {
              return repo.Id == repositoryId;
            });
          }
          const updatedConnection = federatedRepoToConnection(repo);
          if (updatedConnection) {
            void handleAutoUpdateAssociation(
              updatedConnection,
              contextId,
              getSamlToken,
              openModal,
              closeModal
            );
          }
        }
      }

      if (
        primaryConnectionId &&
        repositoryId != primaryConnectionId &&
        primaryConnection
      ) {
        try {
          await federatedApi.unsetPrimaryConnection();
          await federatedApi.setPrimaryConnection(
            repositoryId,
            primaryConnection
          );
          updateAssociation(repositoryId, openModal, closeModal);
          return repositoryId;
        } catch (error) {
          // on error, previous primary will be restored
          await federatedApi.setPrimaryConnection(primaryConnectionId, true);
          throw error;
        }
      }

      await federatedApi.setPrimaryConnection(repositoryId, primaryConnection);
      if (primaryConnection) {
        updateAssociation(repositoryId, openModal, closeModal);
      }
      return primaryConnection ? repositoryId : '';
    },
    [federatedApi, primaryConnectionId, federatedRepos, contextId]
  );

  const addRepository = useCallback(
    async (
      federatedRepository: FederatedRepository,
      syncUsers: boolean,
      enableDriveSync: boolean,
      primaryConnection: boolean,
      openModal: OpenModal,
      closeModal: CloseModal
    ): Promise<FederatedRepository> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      if (legacyApi && !federatedRepository.Canned) {
        try {
          const legacyConnection =
            federatedRepoToLegacyRepo(federatedRepository);
          const legacyRepo = await legacyApi.addOne(legacyConnection);
          // store the legacy id in the prdss connection so the delete function works later on
          federatedRepository.LegacyId = legacyRepo.instanceId;
        } catch (err) {
          console.error('Error creating legacy repository: ', err);
        }
      }

      let addedRepo: FederatedRepository;

      try {
        addedRepo = await federatedApi.addOne(federatedRepository);
      } catch (error) {
        // if something went wrong creating the new repo then remove the legacy version
        if (legacyApi && federatedRepository.LegacyId) {
          legacyApi.deleteMany([federatedRepository.LegacyId]);
        }

        throw error;
      }

      if (!addedRepo) {
        throw new Error('Unknown Error');
      }

      await updateSyncedRepository(federatedRepository.Id, syncUsers);
      await updateDriveSync(federatedRepository.Id, enableDriveSync);

      await updatePrimaryConnection(
        federatedRepository.Id,
        primaryConnection,
        openModal,
        closeModal,
        addedRepo
      );

      await initializeRepos();

      return addedRepo;
    },
    [
      federatedApi,
      legacyApi,
      initializeRepos,
      updateDriveSync,
      updateSyncedRepository,
      updatePrimaryConnection
    ]
  );

  const deleteLegacyRepository = useCallback(
    async (repository: FederatedRepository): Promise<void> => {
      if (!legacyApi || !repository.ConnectionUrl || !repository.ProjectId) {
        return;
      }

      const legacyRepos = await legacyApi.getAll();

      const connectionUrl = `${repository.ConnectionUrl}/pw_wsg/project/${repository.ProjectId}`;
      const legacyId = getLegacyConnectionFromUrl(connectionUrl, legacyRepos);
      if (legacyId && legacyApi) {
        legacyApi.deleteMany([legacyId]);
      }
    },
    [legacyApi]
  );

  const deleteRepositories = useCallback(
    async (ids: string[]): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      let deleted = ids;
      let error: Error | undefined = undefined;

      try {
        await federatedApi.deleteMany(ids);
      } catch (err) {
        console.error(err);
        if (!(err instanceof DeleteError)) {
          throw err;
        }
        deleted = err.deletedIds;
        error = err;
      }

      if (legacyApi) {
        const legacyIds = deleted
          .map((id) => federatedRepos.find((repo) => repo.Id == id))
          .filter((repo) => repo?.LegacyId)
          .map((repo) => repo?.LegacyId ?? '');

        if (legacyIds.length > 0) {
          legacyApi.deleteMany(legacyIds);
        }
      }

      const updatedRepos = federatedRepos.filter(
        (repo) => !deleted.some((id) => repo.Id === id)
      );

      await federatedApi.deleteSynced(
        deleted.filter((id) =>
          userSyncedRepos.some((syncedId) => syncedId == id)
        )
      );
      setUserSyncedRepos(userSyncedRepos.filter((id) => !ids.includes(id)));

      for (const id of deleted) {
        await updateDriveSync(id, false);
      }

      updateFederatedRepos(updatedRepos, primaryConnectionId);

      if (
        updatedRepos.length === 0 ||
        (activeRepo && ids.find((id) => id == activeRepo.Id))
      ) {
        setActiveRepo(undefined);
      }

      if (error) {
        throw error;
      }
    },
    [
      activeRepo,
      federatedApi,
      federatedRepos,
      primaryConnectionId,
      legacyApi,
      updateDriveSync,
      userSyncedRepos,
      updateFederatedRepos
    ]
  );

  const updateRepository = useCallback(
    async (
      repository: FederatedRepository,
      syncUsers: boolean,
      enableDriveSync: boolean,
      primaryConnection: boolean,
      openModal: OpenModal,
      closeModal: CloseModal
    ): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      await federatedApi.updateOne(repository);
      await updateSyncedRepository(repository.Id, syncUsers);

      const newPrimaryConnectionId = await updatePrimaryConnection(
        repository.Id,
        primaryConnection,
        openModal,
        closeModal
      );

      if (
        (primaryConnection && primaryConnectionId != newPrimaryConnectionId) ||
        !primaryConnection
      ) {
        await updateDriveSync(repository.Id, enableDriveSync);
      }

      await initializeRepos();
    },
    [
      federatedApi,
      primaryConnectionId,
      initializeRepos,
      updateDriveSync,
      updateSyncedRepository,
      updatePrimaryConnection
    ]
  );

  const setMultiMode = useCallback(
    async (multiMode: boolean): Promise<void> => {
      if (!federatedApi) {
        throw new Error('Federated api not initialized');
      }

      setDriveSyncedRepos({
        ...driveSyncedRepos,
        multiMode: multiMode
      });

      try {
        await federatedApi.setMultiMode(multiMode);
      } catch (err) {
        // revert if error
        setDriveSyncedRepos(driveSyncedRepos);
      }
    },
    [driveSyncedRepos, federatedApi]
  );

  const driveSyncedConnections = useMemo((): Connection[] => {
    return driveSyncedRepos.connections.map((connectionId) => {
      const repo = federatedRepos.find((repo) => repo.Id == connectionId);
      const connection = federatedRepoToConnection(repo);
      return connection;
    });
  }, [driveSyncedRepos.connections, federatedRepos]);

  const repositoryManager = useMemo(
    (): IFederatedRepositoryData => ({
      federatedRepos,
      userSyncedRepos,
      driveSyncedConnections,
      driveSyncMultiMode: driveSyncedRepos.multiMode,
      activeRepo,
      primaryConnectionId,
      unsetActiveRepo,
      selectFederatedRepository,
      addRepository: apiGuard(addRepository),
      deleteRepositories: apiGuard(deleteRepositories),
      deleteLegacyRepository,
      updateRepository: apiGuard(updateRepository),
      updateDriveSync: apiGuard(updateDriveSync),
      getDriveSynced: federatedApi
        ? apiGuard(federatedApi.getDriveSynced)
        : () => Promise.resolve(null),
      setMultiMode: apiGuard(setMultiMode),
      initialized,
      error,
      reload: () => setRefresh((cur) => !cur),
      fetchCannedConnection
    }),
    [
      activeRepo,
      addRepository,
      apiGuard,
      deleteLegacyRepository,
      deleteRepositories,
      driveSyncedConnections,
      driveSyncedRepos.connections,
      driveSyncedRepos.multiMode,
      error,
      federatedApi?.getDriveSynced,
      federatedRepos,
      fetchCannedConnection,
      initialized,
      primaryConnectionId,
      selectFederatedRepository,
      setMultiMode,
      updateDriveSync,
      updateRepository,
      userSyncedRepos
    ]
  );

  return repositoryManager;
}
