import type { PWItemClass } from '@bentley/pw-api';
import { ecPluginFeatures } from '../useECPluginVersion';

export function getEnvironmentSelectString(className: PWItemClass): string {
  if (className == 'Project') {
    return '';
  }

  if (!ecPluginFeatures.environmentQueryPerformance()) {
    return '';
  }

  return 'PW_WSG.DocumentEnvironment!poly-forward-PW_WSG.Environment!poly.*';
}
