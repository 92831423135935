import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { Alert, LabeledTextarea, Radio, Text } from '@itwin/itwinui-react';
import type { DriveSyncFeature } from '../../components/manageConnection';
import { confirmDriveModeChange } from '../../components/manageConnection/driveSyncDialog';
import { PWModal } from '../../components/pwModal';
import { useProjectSettingsContext } from '../../context';
import type { DriveApi } from '../../hooks/useFederatedRepository';
import { useModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import { getProjectSettings } from './dynamicProjectSettingsData';

export type DynamicProjectSettings = {
  UseDCWInFoldersWithCode: boolean;
  UseDCWInFoldersWithoutCode: boolean;
  UseDCWInPWIC: boolean;
  AutoCheckoutDocsInDrive: boolean;
  AutoCreationBlacklist: string[];
};

type DriveSettingsModalProps = {
  projectId: string;
  onClose: () => void;
  driveApi: DriveApi;
  driveSyncFeature: DriveSyncFeature;
  driveSyncMultiMode: boolean;
  driveSyncedRepos: string[];
  trackFeature: TrackFeature;
};

export function DriveSettingsModal({
  projectId,
  onClose,
  driveApi,
  driveSyncFeature,
  driveSyncMultiMode,
  driveSyncedRepos,
  trackFeature
}: DriveSettingsModalProps): JSX.Element {
  const AUTO_CREATE_BLACKLIST_MSG = t(
    'ProjectSettings.AutoCreateBlacklistMessage'
  );
  const BLACKLIST_INVALID = t('ProjectSettings.BlacklistInvalid');
  const MULTIMODE_HEADER = t('ProjectSettings.MultiModeHeader');
  const MULTIMODE_MESSAGE_1 = t('ProjectSettings.MultiModeMessage1');
  const MULTIMODE_MESSAGE_2 = t('ProjectSettings.MultiModeMessage2');
  const USE_SINGLE_MODE = t('ProjectSettings.UseSingleMode');
  const USE_MULTI_MODE = t('ProjectSettings.UseMultiMode');
  const DRIVE_SETTINGS_ERROR = t('ProjectSettings.DriveServiceError');

  const projectSettingsManager = useProjectSettingsContext();
  const [unformattedBlacklist, setUnformattedBlacklist] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);
  const [multiMode, setMultiMode] = useState<boolean>(driveSyncMultiMode);
  const [driveSettingsError, setDriveSettingsError] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [currentModal, openModal, closeModal] = useModal();

  useEffect(() => {
    if (!projectSettingsManager.initialized || initialized) {
      return;
    }

    async function initializeProjectSettings() {
      if (!projectSettingsManager.httpService) {
        return;
      }

      try {
        const projectSettings = await getProjectSettings(
          projectId,
          projectSettingsManager.organizationId,
          projectSettingsManager.httpService
        );

        const { AutoCreationBlacklist } = projectSettings;
        setTextFromBlacklist(AutoCreationBlacklist);
      } catch (error) {
        console.error(error);
        setDriveSettingsError(true);
      } finally {
        setInitialized(true);
      }
    }

    setInitialized(false);
    void initializeProjectSettings();
  }, [
    projectId,
    projectSettingsManager,
    initialized,
    projectSettingsManager.httpService
  ]);

  function getBlacklistFromText(text: string | null): string[] {
    if (!text) {
      return [];
    }
    const patterns = convertTextToPattern(text);
    return patterns ?? [];
  }

  function convertTextToPattern(text: string): string[] | undefined {
    return text
      ?.split(',')
      .map((v) => v.trim())
      .filter((v) => !!v);
  }

  function setTextFromBlacklist(blacklist: string[]): void {
    setUnformattedBlacklist(blacklist.join(', '));
  }

  async function submitNewProjectSettings(): Promise<void> {
    if (multiMode !== driveSyncMultiMode) {
      const response = await confirmDriveModeChange(openModal);
      if (response === 'CANCEL') {
        closeModal();
        setMultiMode(!multiMode);
        return;
      }
    }
    onClose();
    const blacklist = getBlacklistFromText(unformattedBlacklist);
    trackFeature('DYNAMIC_SETTINGS_SET_PROJECT_SETTINGS');
    projectSettingsManager.postNewProjectSettings(projectId, {
      AutoCreationBlacklist: blacklist
    } as DynamicProjectSettings);
    await driveApi.setMultiMode(multiMode);
  }

  function blacklistIsValid(): boolean {
    const patterns = convertTextToPattern(unformattedBlacklist);
    if (!patterns) {
      return true;
    }
    return patterns.every((pattern) => pattern.indexOf(' ') == -1);
  }

  return (
    <PWModal
      title={t('ProjectSettings.DriveSettingsTitle')}
      className="d-p-s-m-content"
      isLoading={!initialized}
      primaryButton={{
        title: t('Generic.Save'),
        onClick: () => void submitNewProjectSettings(),
        disabled: !blacklistIsValid() || driveSettingsError || !formChanged,
        'data-testid': 'saveButton'
      }}
      secondaryButton={{
        title: driveSettingsError ? t('Generic.Close') : t('Generic.Cancel'),
        onClick: onClose
      }}
      onClose={onClose}
    >
      {driveSettingsError ? (
        <Alert type="negative" data-testid="driveSettingsErrorMessage">
          {DRIVE_SETTINGS_ERROR}
        </Alert>
      ) : (
        <>
          <LabeledTextarea
            label={AUTO_CREATE_BLACKLIST_MSG}
            data-testid="driveAutoCreateBlacklist"
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              setUnformattedBlacklist(event.target.value ?? '');
              setFormChanged(true);
            }}
            value={unformattedBlacklist}
            status={blacklistIsValid() ? undefined : 'negative'}
            message={blacklistIsValid() ? undefined : BLACKLIST_INVALID}
          />
          {driveSyncFeature == 'Multi' && (
            <>
              <Text variant="subheading">{MULTIMODE_HEADER}</Text>
              <Text>{MULTIMODE_MESSAGE_1}</Text>
              <Text>{MULTIMODE_MESSAGE_2}</Text>
              <Radio
                label={USE_SINGLE_MODE}
                name="single_mode"
                checked={!multiMode}
                disabled={driveSyncedRepos.length > 1}
                onClick={() => {
                  setMultiMode(false);
                  setFormChanged(true);
                }}
              />
              <Radio
                label={USE_MULTI_MODE}
                name="multi_mode"
                checked={multiMode}
                onClick={() => {
                  setMultiMode(true);
                  setFormChanged(true);
                }}
              />
            </>
          )}
          {currentModal}
        </>
      )}
    </PWModal>
  );
}
