import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import type { ResolveOutputFunction } from '../../hooks/useModalOutput';
import { t } from '../../services/translation';

export type ConfirmDeleteModalProps = {
  items: PWItem[];
} & ResolveOutputFunction<boolean>;

export function ConfirmDeleteModal({
  items,
  resolveModalOutput
}: ConfirmDeleteModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();

  const title =
    items.length == 1
      ? t('RecycleBin.DeleteItem')
      : t('RecycleBin.DeleteItems');

  function confirmationMessage(): string {
    if (items.length == 1) {
      return t('RecycleBin.DeleteItemConfirmation');
    }
    return t('RecycleBin.DeleteItemsConfirmation', { count: items.length });
  }

  function onYes(): void {
    primaryModal.close();
    resolveModalOutput(true);
  }

  function onNo(): void {
    primaryModal.close();
    resolveModalOutput(false);
  }

  return (
    <PWModal
      title={title}
      primaryButton={{ title: t('Generic.Delete'), onClick: onYes }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: onNo }}
      onClose={onNo}
    >
      <ItemSummary item={items} />
      <Text>{confirmationMessage()}</Text>
    </PWModal>
  );
}
