import type { User } from 'oidc-client';
import { useEffect, useState } from 'react';
import {
  AuthorizationService,
  CacheService,
  HttpService
} from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { GetToken } from '../../services/http';
import type { ITwinResponse } from './iTwin';

type UseOrganizationIdProps = {
  buddiRegionCode: string;
  contextId?: string;
  user: User;
  getOidcToken: GetToken;
};

export function useOrganizationId({
  buddiRegionCode,
  contextId,
  user,
  getOidcToken: getOidcToken
}: UseOrganizationIdProps): string | undefined {
  const [organizationId, setOrganizationId] = useState<string>();

  const buddiUrl = useBuddi('ConnectedContextService.URL', buddiRegionCode);

  useEffect(() => {
    if (!buddiUrl || !contextId) {
      return;
    }

    const httpService = new HttpService({
      authorization: new AuthorizationService({
        getOidcToken: () => getOidcToken()
      }),
      baseUrl: buddiUrl,
      cache: new CacheService({ name: 'iTwinProjects' })
    });
    const url = `/v1/iTwins/${contextId}`;
    async function fetchContext() {
      try {
        const response = await httpService.get(url);
        if (!response.ok) {
          throw response;
        }

        const data = (await response.json()) as ITwinResponse;
        const organizationId = data.iTwin.accountOwnerId;
        setOrganizationId(organizationId ?? (user.profile.org as string));
      } catch (e) {
        console.error('Unable to fetch ultimate ID', e);
        setOrganizationId(user.profile.org as string);
      }
    }

    void fetchContext();
  }, [buddiUrl, contextId, getOidcToken, user.profile.org]);

  return organizationId;
}
