import React from 'react';
import { Alert, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useFeatureTracking } from '../../context';
import { t } from '../../services/translation';

interface ILogicalSetConfirmationModalProps {
  closeModal: () => void;
  onNo: () => void;
  onYes: () => void;
}

function LogicalSetConfirmationModal({
  closeModal,
  onNo,
  onYes
}: ILogicalSetConfirmationModalProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();

  function onPrimaryButton(): void {
    closeModal();
    onYes();
    trackFeature('LOGICAL_SET_REFERENCE_YES');
  }

  function onSecondaryButton(): void {
    closeModal();
    onNo();
    trackFeature('LOGICAL_SET_REFERENCE_NO');
  }

  return (
    <PWModal
      title={t('Downloads.DownloadReferences')}
      primaryButton={{ title: t('Generic.Yes'), onClick: onPrimaryButton }}
      secondaryButton={{ title: t('Generic.No'), onClick: onSecondaryButton }}
      dialogProps={{ 'data-testid': 'LogicalSetConfirmationModal' }}
      onClose={closeModal}
    >
      <Alert type="informational">{t('Downloads.FileIsMasterReference')}</Alert>
      <Text>{t('Downloads.DownloadReferencesQuestion')}</Text>
      <Text>
        <strong>{t('Generic.Yes')}:</strong>{' '}
        {t('Downloads.DownloadFilesAndReferences')}
      </Text>
      <Text>
        <strong>{t('Generic.No')}:</strong> {t('Downloads.DownloadFilesOnly')}
      </Text>
    </PWModal>
  );
}

export default LogicalSetConfirmationModal;
