import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormContainer } from '../../../../actions/formRendering';
import { updatingTriggeredPropertiesState, useDCWForm } from '../../form';
import { DCWSpinner } from '../../loading';
import { useRecoilValueAsync } from '../../state';
import { docCodeFormDefinitionState, docCodeFormValidState } from './state';

export function DocCodeFormContainer(): JSX.Element {
  const docCodeFormDefinition = useRecoilValueAsync(docCodeFormDefinitionState);

  const setDocCodeFormIsValid = useSetRecoilState(docCodeFormValidState);
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const formJQuery = useDCWForm(
    'documentcode',
    docCodeFormDefinition,
    setDocCodeFormIsValid
  );

  if (!formJQuery || updatingTriggeredProperties) {
    return <DCWSpinner className="dcw-form-placeholder" />;
  }

  return <FormContainer formJQuery={formJQuery} formType="documentcode" />;
}
