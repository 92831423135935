import React from 'react';
import { SvgDocument } from '@itwin/itwinui-icons-react';
import { Flex, Text } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

export function EmptyOutToMeState(): JSX.Element {
  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgDocument className="ets-icon" />
      <Text>{t('OutToMe.NoItemsCheckedOut')}</Text>
    </Flex>
  );
}
