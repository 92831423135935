import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import type { WSGInstance, WSGProperties } from '@bentley/pw-api';
import { useHttpService } from '../../../context';
import { generateTriggeredProperties } from '../../documentCreation/form/triggeredProperties/updateTriggeredPropertiesAction';
import { mergePropertiesIntoInstance } from '../../documentCreation/properties';
import { handleDocCodeFormError } from '../error';
import { updatingTriggeredPropertiesState } from '../form';
import { currentEnvironmentInstanceState } from '../state';

type TriggeredPropertyWorkflowFunctions = {
  updateInstanceWithTriggeredProperties: (
    propertyName: string,
    changedInstance: WSGInstance
  ) => Promise<WSGInstance | null>;
};

export function useTriggeredPropertyWorkflow(): TriggeredPropertyWorkflowFunctions {
  const setUpdatingTriggeredProperties = useSetRecoilState(
    updatingTriggeredPropertiesState
  );
  const setCurrentEnvironmentInstance = useSetRecoilState(
    currentEnvironmentInstanceState
  );

  const httpService = useHttpService();

  const updateTriggeredProperties = useCallback(
    async (
      environmentInstance: WSGInstance,
      updatePropertiesFunction: () => Promise<WSGProperties>
    ): Promise<WSGInstance | null> => {
      try {
        setUpdatingTriggeredProperties(true);
        const updatedProps = await updatePropertiesFunction();
        const updatedInstance = mergePropertiesIntoInstance(
          environmentInstance,
          updatedProps
        );
        setCurrentEnvironmentInstance(updatedInstance);
        return updatedInstance;
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          void handleDocCodeFormError(error);
        }
        return null;
      } finally {
        setTimeout(() => setUpdatingTriggeredProperties(false), 500);
      }
    },
    [setCurrentEnvironmentInstance, setUpdatingTriggeredProperties]
  );

  const updateInstanceWithTriggeredProperties = useCallback(
    async (
      propertyName: string,
      changedInstance: WSGInstance
    ): Promise<WSGInstance | null> => {
      if (!changedInstance.properties || !propertyName) {
        return null;
      }

      const updatePropertiesFunction = () =>
        generateTriggeredProperties(changedInstance, propertyName, httpService);

      const updatedInstance = await updateTriggeredProperties(
        changedInstance,
        updatePropertiesFunction
      );
      return updatedInstance;
    },
    [updateTriggeredProperties]
  );

  return { updateInstanceWithTriggeredProperties };
}
