import type { ReactNode } from 'react';
import React from 'react';
import { useConnectionsContext, usePluginContext } from '../../../context';
import { t } from '../../../services/translation';
import { ErrorPage } from '../../errorPage';

type WithErrorProps = {
  children: ReactNode;
};

export function WithConnectionError({
  children
}: WithErrorProps): JSX.Element | null {
  const { connection, connectionError } = useConnectionsContext();

  const { readOnly } = usePluginContext();

  function datasourceNotFound(): boolean {
    if (!readOnly) {
      return false;
    }

    if (!connection) {
      return false;
    }

    return !connection.DatasourceInstanceId;
  }

  if (connectionError) {
    return <ErrorPage errorType="404" errorMsg={connectionError} />;
  }

  if (datasourceNotFound()) {
    return (
      <ErrorPage
        errorType="404"
        errorMsg={t('ServerError.DatasourceConnectionNotFound')}
      />
    );
  }

  return <>{children}</>;
}
