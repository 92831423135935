import type { HttpService, PWItem, PWParentType } from '@bentley/pw-api';
import { logError } from '../../hooks/useErrorLogging';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import { parseResponseError, responseAborted } from '../../services/http';
import { t } from '../../services/translation';
import { notifyServerDisconnectedError } from './notifications';
import { checkServerConnectivity } from './utils';

/**
 * Handles notifications and in progress modal based on response after document uploaded
 */
export function afterUploadDocumentSuccess(
  uniqueId: string,
  jobId?: string,
  progressManager?: ProgressManager
): void {
  if (!progressManager || !jobId) {
    return;
  }
  progressManager.updateProgressStatus(jobId, uniqueId, 'success');
}

/**
 * Handles notifications and in progress modal based on response after document upload fails
 */
export async function afterUploadDocumentFailure(
  response: Response,
  file: File,
  parent: PWParentType | undefined,
  uniqueId: string,
  jobId?: string,
  progressManager?: ProgressManager,
  onComplete?: (uploadedItems: PWItem[]) => void
): Promise<void> {
  onComplete?.([]);
  if (!progressManager || !jobId) {
    return;
  }
  if (response.status == 413) {
    const body = await response.text();
    const res = { status: 413, ...JSON.parse(body) } as Response;
    progressManager.addWarningMessageToActivity(
      jobId,
      uniqueId,
      t('Upload.FileTooLarge')
    );
    progressManager.updateProgressStatus(jobId, uniqueId, 'error', res);
  } else if (response.status == 403) {
    const body = await response.text();
    const res = { status: 403, ...JSON.parse(body) } as Response;
    progressManager.addWarningMessageToActivity(
      jobId,
      uniqueId,
      t('Upload.AccessDenied')
    );
    progressManager.updateProgressStatus(jobId, uniqueId, 'error', res);
  } else if (response.status == 409) {
    progressManager.updateProgressStatus(
      jobId,
      uniqueId,
      'error',
      response,
      file,
      parent
    );
  } else if (responseAborted(response)) {
    progressManager.updateProgressStatus(jobId, uniqueId, 'abort', {
      status: 410
    } as Response);
  } else {
    const warning = await parseResponseError(response);
    if (warning) {
      progressManager.addWarningMessageToActivity(jobId, uniqueId, warning);
    }
    progressManager.updateProgressStatus(jobId, uniqueId, 'error', response);
  }
}

export function uploadResponseCallback(
  uniqueId: string,
  jobId?: string,
  progressManager?: ProgressManager
): (bytesUploaded: number, totalBytes: number) => void {
  return (bytesUploaded: number, totalBytes: number): void => {
    if (!progressManager || !jobId) {
      return;
    }

    progressManager.updateAmountUploaded(
      jobId,
      uniqueId,
      bytesUploaded,
      bytesUploaded >= totalBytes
    );
  };
}

/**
 * Handles notifications and in progress modal based on error on document and file error
 */
export async function onUploadError(
  error: Error,
  uniqueId: string,
  httpService?: HttpService,
  jobId?: string,
  progressManager?: ProgressManager
): Promise<void> {
  logError('Upload error', { error });

  let isInternetConnected = true;
  if (httpService) {
    isInternetConnected = await checkServerConnectivity(httpService);
  }

  if (!isInternetConnected) {
    notifyServerDisconnectedError();
  }
  if (!progressManager || !jobId) {
    return;
  }

  if (error.name == 'AbortError') {
    progressManager.clearAbortedActivityData(jobId, uniqueId);
  } else if (!isInternetConnected) {
    progressManager.updateProgressStatus(jobId, uniqueId, 'error', {
      status: 502
    } as Response);
  } else if (error instanceof Response || error instanceof Error) {
    progressManager.updateProgressStatus(jobId, uniqueId, 'error');
  } else {
    progressManager.updateProgressStatus(jobId, uniqueId, 'abort');
  }
}
