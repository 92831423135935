import { useCallback } from 'react';
import {
  PWProject,
  RequestOptions,
  parseResponseInstances,
  query
} from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';

type ProjectFilterStringFunction = (
  httpOptions?: RequestOptions
) => Promise<string>;

export function useProjectFilterString(): ProjectFilterStringFunction {
  const httpService = useHttpService();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const projectFilterString = useCallback(
    async (httpOptions?: RequestOptions): Promise<string> => {
      if (!ecPluginFeatures.apiAncestorBug() || !currentParent.instanceId) {
        return '';
      }

      const url = query({
        schemaName: 'PW_WSG',
        className: 'Project',
        apiAncestor: {
          className: 'Project',
          instanceId: currentParent.instanceId
        },
        select: { propertyName: 'instanceId' }
      });

      const response = await httpService.get(url, httpOptions);
      const subfolders = await parseResponseInstances<PWProject>(response);
      const folderIds = subfolders
        .map((folder) => `'${folder.instanceId}'`)
        .join(',');

      return ` and parentGuid in [${folderIds}]`;
    },
    [currentParent.instanceId, ecPluginFeatures, httpService]
  );

  return projectFilterString;
}
