import _ from 'lodash';
/* eslint-disable import/no-unused-modules */
import type { HttpService, PWItem, RequestOptions } from '@bentley/pw-api';
import { getProjects } from '@bentley/pw-api';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { responseAborted } from '../../services/http';
import { getUserFolderPermissions } from '../../services/permissions';
import type { DynamicProjectSettings } from '../dynamicProjectSettings';
import {
  defaultProjectSettings,
  getProjectSettings
} from '../dynamicProjectSettings/dynamicProjectSettingsData';
import {
  breakUpArray,
  buildMultiFolderRequestUrl,
  generatePostFolderSettingsBody,
  projectSettingsToFolderSettings
} from './dynamicFolderSettingsData.utils';
import type { DynamicFolderSettings } from './dynamicFolderSettingsModal';
import {
  handleDeleteChildSettingsResponses,
  handleDeleteSettingsResponse,
  handleGetSettingsResponse,
  handlePostSettingsResponse
} from './responses';

type MultiFolderRequestResponse = {
  datasource: string;
  folderId: string;
  projectId: string;
  settings: string;
  timestamp: number;
  workAreaId: string;
};

export async function getFolderSettings(
  datasource: string,
  workAreaId: string,
  projectId: string,
  organizationId: string,
  folderId: string,
  projectSettings: DynamicProjectSettings,
  ancestorIds: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<DynamicFolderSettings> {
  try {
    const url = `FolderSettings?datasource=${datasource}&workAreaId=${workAreaId}&projectId=${projectId}&folderId=${folderId}`;

    if (httpOptions?.abortController?.signal.aborted) {
      return projectSettingsToFolderSettings(projectSettings);
    }

    const response = await httpService.get(url, httpOptions);
    if (response.status == 404) {
      const folderSettings = await getInheritedFolderSettings(
        datasource,
        workAreaId,
        projectId,
        organizationId,
        ancestorIds,
        httpService,
        projectSettings,
        httpOptions
      );
      if (folderSettings.InheritProjectSettings) {
        return projectSettingsToFolderSettings(projectSettings, folderSettings);
      }
      return folderSettings;
    }

    const folderSettings = await handleGetSettingsResponse(response);
    if (folderSettings.InheritProjectSettings) {
      return projectSettingsToFolderSettings(projectSettings, folderSettings);
    }
    return folderSettings;
  } catch (e) {
    if (String(e).includes('AbortError')) {
      return projectSettingsToFolderSettings(defaultProjectSettings);
    }
    throw e;
  }
}

/**
 * Posts dynamic settings for specified folder
 * @param {string} datasource Name of datasource
 * @param {string} workAreaId Unique Id of Work Area
 * @param {string} projectId Unique Id of connection
 * @param {string} folderId Unique Id of folder
 * @param {DynamicFolderSettings} folderSettings folder level settings for settings default folder settings
 * @param {TrackFeature} trackFeature function for tracking feature usage
 * @param {HttpService} httpService Http service
 * @param {RequestOptions} [httpOptions] Configured http options
 * @returns {Promise<void>}
 */
export async function postFolderSettings(
  datasource: string,
  workAreaId: string,
  projectId: string,
  folderId: string,
  folderSettings: DynamicFolderSettings,
  projectSettings: DynamicProjectSettings,
  trackFeature: TrackFeature,
  httpService: HttpService,
  autoCheckoutUpdated: boolean,
  httpOptions?: RequestOptions
): Promise<Response> {
  const url = `FolderSettings?datasource=${datasource}&workAreaId=${workAreaId}&projectId=${projectId}&folderId=${folderId}`;
  const body = generatePostFolderSettingsBody(folderSettings, projectSettings);
  const response = await httpService.post(url, body, httpOptions);
  if (autoCheckoutUpdated) {
    folderSettings.AutoCheckoutDocsInDrive
      ? trackFeature('DYNAMIC_SETTINGS_AUTOMATIC_CHECKOUT_ON')
      : trackFeature('DYNAMIC_SETTINGS_AUTOMATIC_CHECKOUT_OFF');
  }
  trackFeature('DYNAMIC_SETTINGS_SET_FOLDER_SETTINGS');
  handlePostSettingsResponse(response);
  return response;
}
/**
 * Deletes recorded settings for specified folder
 * @param {string} datasource Name of datasource
 * @param {string} workAreaId Unique Id of Work Area
 * @param {string} folderId Unique Id of folder
 * @param {TrackFeature} trackFeature function for tracking feature usage
 * @param {string} httpService Http service
 * @param {string} httpOptions Configured http options
 * @returns {Promise<void>}
 */
export async function deleteFolderSettings(
  datasource: string,
  workAreaId: string,
  folderId: string,
  trackFeature: TrackFeature,
  httpService: HttpService,
  shouldToast = true,
  httpOptions?: RequestOptions
): Promise<Response> {
  const url = `FolderSettings?datasource=${datasource}&workAreaId=${workAreaId}&folderId=${folderId}`;
  const response = await httpService.delete(url, null, httpOptions);
  trackFeature('DYNAMIC_SETTINGS_DELETE_FOLDER_SETTINGS');
  handleDeleteSettingsResponse(response, shouldToast);
  return response;
}

export async function getInheritedFolderSettings(
  datasource: string,
  workAreaId: string,
  projectId: string,
  organizationId: string,
  ancestorIds: string[],
  httpService: HttpService,
  projectSettings?: DynamicProjectSettings,
  httpOptions?: RequestOptions
): Promise<DynamicFolderSettings> {
  let index = ancestorIds.length - 1;
  do {
    const url = `FolderSettings?datasource=${datasource}&workAreaId=${workAreaId}&projectId=${projectId}&folderId=${ancestorIds[index]}`;
    const response = await httpService.get(url, httpOptions);
    if (responseAborted(response)) {
      return projectSettingsToFolderSettings(defaultProjectSettings);
    }
    if (response.status == 404) {
      index -= 1;
      continue;
    }
    return handleGetSettingsResponse(response, true);
  } while (index >= 0);

  // If folder settings don't exist, return project settings but don't save them
  if (!projectSettings) {
    projectSettings = await getProjectSettings(
      projectId,
      organizationId,
      httpService,
      httpOptions
    );
  }
  return projectSettingsToFolderSettings(projectSettings, {
    SettingsInherited: true
  } as DynamicFolderSettings);
}

/**
 * Deletes all folder settings for any folder descended from the specified parent
 * @param {string} datasource Name of datasource
 * @param {string} workAreaId Unique Id of work area
 * @param {string} parentFolderId Unique Id of parent folder
 * @param {TrackFeature} trackFeature function for tracking feature usage
 * @param {HttpService} wsgHttpService http service pointing to WSG
 * @param {HttpService} driveHttpService http service pointing to PW Drive
 * @param {userFolderPermissionsIsAvailable} ecPlugin feature userFolderPermissions is available
 * @param {isAdmin} user is admin
 * @param {RequestOptions} [httpOptions] Configured http options
 * @returns
 */
export async function deleteDescendantSettings(
  datasource: string,
  workAreaId: string,
  parentFolderId: string,
  trackFeature: TrackFeature,
  wsgHttpService: HttpService,
  driveHttpService: HttpService,
  userFolderPermissionsIsAvailable: boolean,
  isAdmin: boolean,
  shouldToast = true,
  httpOptions?: RequestOptions
): Promise<void> {
  const descendantIds = await gatherDescendantFolderIds(
    { instanceId: parentFolderId } as PWItem,
    wsgHttpService
  );
  const driveFolderSettingIds = await gatherDriveRecordIds(
    datasource,
    workAreaId,
    descendantIds,
    driveHttpService,
    httpOptions
  );
  const responses = await Promise.all(
    driveFolderSettingIds.map(async (id) => {
      if (!userFolderPermissionsIsAvailable) {
        if (!isAdmin) {
          return {
            ok: false
          } as Response;
        }
      } else {
        const permissions = await getUserFolderPermissions(id, wsgHttpService);
        if (!permissions.Write) {
          return {
            ok: false
          } as Response;
        }
      }

      return deleteFolderSettings(
        datasource,
        workAreaId,
        id,
        trackFeature,
        driveHttpService,
        false,
        httpOptions
      );
    })
  );
  if (shouldToast) {
    handleDeleteChildSettingsResponses(responses);
  }
  trackFeature('DYNAMIC_SETTINGS_FORCE_DESCENDANT_INHERITANCE');
  return;
}

export async function gatherDescendantFolderIds(
  parent: PWItem,
  httpService: HttpService
): Promise<string[]> {
  const children = await getProjects({
    parentId: parent.instanceId,
    httpService
  });
  const idArray = children.map((child) => child.instanceId);
  await Promise.all(
    children.map(async (child) => {
      const childIdArray = await gatherDescendantFolderIds(child, httpService);
      idArray.push(...childIdArray);
    })
  );
  return idArray;
}

export async function gatherDriveRecordIds(
  datasource: string,
  workAreaId: string,
  descendantIds: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<string[]> {
  const chunkedIdArray = breakUpArray(descendantIds, 100);
  const driveRecordIds: string[] = [];
  await Promise.all(
    chunkedIdArray.map(async (idArray) => {
      const url = buildMultiFolderRequestUrl(datasource, workAreaId, idArray);
      const response = await httpService.get(url, httpOptions);
      if (response.ok) {
        const records = (await response.json()) as MultiFolderRequestResponse[];
        const chunkIds = _.castArray(records).map((record) => record.folderId);
        driveRecordIds.push(...chunkIds);
      }
    })
  );
  return driveRecordIds;
}
