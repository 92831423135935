import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgDeveloper } from '@itwin/itwinui-icons-react';
import { EditDocCodeModal } from '../../../../actions/editDocCode';
import { useNavigationContext, usePluginContext } from '../../../../context/';
import { t } from '../../../../services/translation';
import { useAllowEditDocCode } from '../../../docCodeForm/hooks/';
import type { MenuItemAction } from '../../MenuItemAction';

export function useEditDocCodeItemAction(items: PWItem[]): MenuItemAction {
  const { primaryModal } = useNavigationContext();
  const { readOnly } = usePluginContext();

  const allowEditDocCode = useAllowEditDocCode();

  const showEditDocCode = useMemo(() => {
    if (readOnly) {
      return false;
    }

    return allowEditDocCode;
  }, [allowEditDocCode, readOnly]);

  const editDocCodeItem = useMemo(
    (): MenuItemAction => ({
      title: t('DocCode.EditDocCode'),
      icon: <SvgDeveloper />,
      onClick: () => primaryModal.open(<EditDocCodeModal />),
      hidden: !showEditDocCode
    }),
    [primaryModal, showEditDocCode]
  );

  return editDocCodeItem;
}
