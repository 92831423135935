import type {
  HttpService,
  PWParentType,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { getItem } from '@bentley/pw-api';
import { getItemAncestors } from '../../services/data';
import type { Connection } from '../../services/support';

export async function retrieveBreadcrumbs(
  parent: PWParentType,
  connection: Connection,
  connectionBreadcrumb: PWProject,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWParentType[]> {
  if (!parent.Name || !parent.ParentGuid) {
    parent = await addMissingParentInfo(
      parent,
      connection,
      httpService,
      httpOptions
    );
  }

  const ancestors = await getItemAncestors(
    parent,
    connection.ProjectId,
    httpService,
    httpOptions
  );
  const breadcrumbs = [...ancestors, parent];

  addConnectionBreadcrumb(breadcrumbs, connection, connectionBreadcrumb);

  return breadcrumbs;
}

async function addMissingParentInfo(
  parent: PWParentType,
  connection: Connection,
  httpService: HttpService,
  requestOptions?: RequestOptions
): Promise<PWParentType> {
  if (parent.instanceId == null) {
    return { ...parent, Name: connection.Name };
  }

  const fetchedItem = (await getItem({
    instanceId: parent.instanceId,
    httpService,
    requestOptions
  })) as PWParentType;

  return { ...parent, ...fetchedItem };
}

export function addConnectionBreadcrumb(
  breadcrumbs: PWParentType[],
  connection: Connection,
  connectionBreadcrumb: PWProject
): void {
  if (
    breadcrumbs[0].instanceId == connection.ProjectId ||
    !breadcrumbs[0].instanceId
  ) {
    breadcrumbs[0] = connectionBreadcrumb;
    return;
  }

  if (!connection.ProjectId) {
    breadcrumbs.unshift(connectionBreadcrumb);
  }
}
