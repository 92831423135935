import { useCallback, useEffect, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { itemIsProject, parseResponseInstances } from '@bentley/pw-api';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';

/**
 * Ensures current parent always has an environment associated with it.
 * When the current parent updates, if it does not have any environment associated
 * with it, it queries the connection environment and assigns it to the parent
 */
export function useFolderEnvironment(): void {
  const { connection } = usePluginContext();
  const httpService = useHttpService();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const [connectionList, setConnectionList] = useState<PWProject[]>([]);

  const getConnectionInfo = useCallback(
    async (abortController?: AbortController): Promise<PWProject> => {
      const cachedConnection = connectionList?.find(
        (conn) => conn.instanceId == connection.ProjectId
      );
      if (cachedConnection) {
        return cachedConnection;
      }

      const response = await httpService.get(
        `PW_WSG/Project/${connection.ProjectId}?$select=Environment,EnvironmentId`,
        { abortController, cacheSuffix: 'persistent' }
      );
      if (!response.ok) {
        throw response;
      }
      const flattenedResponse = await parseResponseInstances<PWProject>(
        response
      );
      const connectionInfo = flattenedResponse[0];
      setConnectionList((connectionList) => [
        ...connectionList,
        connectionInfo
      ]);
      return connectionInfo;
    },
    [connection.ProjectId, connectionList, httpService]
  );

  const addEnvironmentDataToProject = useCallback(
    async (abortController?: AbortController): Promise<void> => {
      await usingConcurrencyLimiter(async () => {
        if (currentParent.EnvironmentId) {
          return;
        }

        if (!connection.ProjectId) {
          return;
        }

        try {
          const connectionInfo = await getConnectionInfo(abortController);
          if (abortController?.signal.aborted) {
            return;
          }

          currentParent.EnvironmentId = connectionInfo.EnvironmentId;
          if (itemIsProject(currentParent)) {
            currentParent.Environment = connectionInfo.Environment;
          }
        } catch {
          // If error, don't attach any environment class
        }
      }, 'background');
    },
    [connection.ProjectId, currentParent, getConnectionInfo]
  );

  useEffect(() => {
    const abortController = new AbortController();

    void addEnvironmentDataToProject(abortController);

    return () => {
      abortController.abort();
    };
  }, [addEnvironmentDataToProject]);
}
