import type { PWItem, PWItemClass } from '@bentley/pw-api';
import type { SavedSearchDefinition } from '../../services/search';

export function isSavedSearch(
  item: PWItem | SavedSearchDefinition
): item is SavedSearchDefinition {
  return item.className == 'SavedSearchDefinition';
}

export function asItem(item: PWItem | SavedSearchDefinition): PWItem {
  if (!isSavedSearch(item)) {
    return item;
  }

  return {
    Name: item.Name,
    className: 'SavedSearch' as string as PWItemClass
  } as PWItem;
}
