import { FormRenderer } from '@bentley/formsrenderer';
import { DateTimeConversionDirection } from '@bentley/formsrenderer/lib/form-renderer/components/DatePicker/DatePicker';
import type { IFormContainer } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormContainer';
import type { IFormWrapper } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormsSchema';
import type { IRenderOptions } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IRendererConfigurationOptions';
import type {
  IWsgInstance,
  WsgClassType
} from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';
import type { WSGInstance } from '@bentley/pw-api';

export function formDefinitionAsJQuery(
  formDefString: string,
  environmentInstance: IWsgInstance<WsgClassType> | WSGInstance,
  renderOptions?: Partial<IRenderOptions>,
  disableDateTimeFunction?: boolean
): IFormContainer {
  const formJson = JSON.parse(formDefString) as IFormWrapper;
  return FormRenderer.render(
    formJson,
    {
      showValidationErrorsOnHover: true,
      localizationDictionary: { CANCEL_BUTTON_TEXT: 'Refresh' },
      hideEmptySelectOption: true,
      dateTimeLocalizationFunction: disableDateTimeFunction
        ? undefined
        : dateTimeLocalizationFunction,
      ...renderOptions
    },
    [environmentInstance as IWsgInstance<WsgClassType>]
  );
}
function dateTimeLocalizationFunction(
  dateTime: string,
  direction: DateTimeConversionDirection,
  includeTime?: boolean
): string {
  if (!dateTime) {
    return '';
  }

  // Strip Z from date when displaying to prevent localization, add it back in when saving date
  if (direction == DateTimeConversionDirection.To) {
    if (includeTime) {
      return dateTime.split('Z')[0];
    } else {
      // Remove time section from dateTime
      return dateTime.split('T')[0];
    }
  } else {
    if (
      dateTime.includes('T') &&
      !dateTime.includes('+') &&
      !dateTime.includes('Z')
    ) {
      dateTime = dateTime + 'Z';
    }
    return new Date(dateTime).toISOString();
  }
}
