import './currentConnectionDialog.css';

import React, { useEffect, useState } from 'react';
import {
  ExpandableBlock,
  InformationPanelContent,
  Table
} from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useConnectionAuth,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import {
  getECPluginBuild,
  getWsgVersion
} from '../../hooks/useECPluginVersion';
import type { ModalElement } from '../../hooks/useModal';
import packageInfo from '../../packageInfo.json';
import {
  getBrowserDetails,
  productionEnvironment
} from '../../services/environment';
import { t } from '../../services/translation';
import { InfoRow } from './infoRow';

export function CurrentConnectionDialog(): ModalElement {
  const { connection, readOnly } = usePluginContext();
  const httpService = useHttpService();
  const { isAdmin, user } = useConnectionAuth();
  const { primaryModal } = useNavigationContext();

  // State
  const [wsgVersion, setWsgVersion] = useState<string>();
  const [ecPluginVersion, setECPluginVersion] = useState<string>();
  const [browserDetails, setBrowserDetails] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function showSensitiveInfo(): boolean {
    if (connection.Canned) {
      return false;
    }

    if (!user.profile.org) {
      return false;
    }

    return true;
  }

  function showDescription(): boolean {
    if (!showSensitiveInfo()) {
      return false;
    }
    return true;
  }

  function showWorkAreaName(): boolean {
    if (!showSensitiveInfo() || !isAdmin || readOnly) {
      return false;
    }
    return true;
  }

  function showServerUrl(): boolean {
    if (!showSensitiveInfo() || !isAdmin) {
      return false;
    }
    return true;
  }

  function showDatasource(): boolean {
    if (!showSensitiveInfo() || !isAdmin) {
      return false;
    }
    return true;
  }

  function showPluginVersion(): boolean {
    if (productionEnvironment() == 'prod') {
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function fetchConnectionDetails() {
      setIsLoading(true);
      try {
        const [ecPluginVersion, wsgVersion] = await Promise.all([
          getECPluginBuild({ httpService }),
          getWsgVersion({ httpService })
        ]);

        setECPluginVersion(ecPluginVersion);
        setWsgVersion(wsgVersion);
        setBrowserDetails(getBrowserDetails());
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(true);
    void fetchConnectionDetails();
  }, [httpService]);

  return (
    <PWModal
      title={connection.Name}
      className={'current-connection-dialog-modal'}
      isLoading={isLoading}
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'currentConnectionDialogModal' }}
    >
      <InformationPanelContent>
        <InfoRow
          hidden={!showDescription()}
          label={t('Generic.Description')}
          value={connection.Description}
        />
        <InfoRow
          hidden={!showWorkAreaName()}
          label={t('ConnectionInfo.WorkAreaName')}
          value={connection.WorkAreaName ?? ''}
        />
        <InfoRow
          hidden={!showServerUrl()}
          label={t('ConnectionInfo.ServerUrl')}
          value={connection.ServerUrl.substring(
            0,
            connection.ServerUrl.search('/v')
          )}
        />
        <InfoRow
          hidden={!showDatasource()}
          label={t('Generic.Datasource')}
          value={connection.DatasourceInstanceId.replace('~3A', ':')}
        />
        <InfoRow
          label={t('ConnectionInfo.ECPluginVersion')}
          value={ecPluginVersion ?? ''}
        />
        <InfoRow
          label={t('ConnectionInfo.WSGVersion')}
          value={wsgVersion ?? ''}
        />
        <InfoRow
          label={t('ConnectionInfo.BrowserVersion')}
          value={browserDetails ?? ''}
        />
      </InformationPanelContent>
      {showPluginVersion() && (
        <ExpandableBlock title="Package versions" size="small">
          <Table
            columns={[
              { Header: 'Package', accessor: 'name' },
              { Header: 'Version', accessor: 'version' }
            ]}
            emptyTableContent="No data"
            data={Object.entries(packageInfo).map(([name, version]) => ({
              name,
              version
            }))}
            density="extra-condensed"
            style={{ maxHeight: '200px' }}
          />
        </ExpandableBlock>
      )}
    </PWModal>
  );
}
