export type { DCWDocProperties } from './propertiesSection';
export { PropertiesStep } from './propertiesStep';
export {
  allowFileRenameState,
  defaultDocumentDescriptionState,
  defaultDocumentNameState,
  defaultVersionState,
  descriptionChangedState,
  docPropertiesState,
  fileNameChangedState,
  nameChangedState,
  originalFileNameState
} from './state';
export { useInitializeProperties } from './useInitializeProperties';
