import React, { useEffect, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { getItem } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import { ErrorPage } from '../errorPage';
import { LoadingPage } from '../loadingPage';

type ResolvedItems = {
  items: (PWItem | SavedSearchDefinition)[];
};

export function WithItemResolver<
  P extends { items: (PWItem | SavedSearchDefinition)[] }
>(
  Component: React.ComponentType<P>,
  itemInstanceIds: [string, ...string[]]
): React.ComponentType<Omit<P, keyof ResolvedItems>> {
  const httpService = useHttpService();

  const [dataResolved, setDataResolved] = useState<
    'loading' | 'error' | 'resolved'
  >('loading');
  const [items, setItems] = useState<PWItem[]>([]);

  useEffect(() => {
    async function resolveItem(): Promise<void> {
      try {
        const items = await Promise.all(
          itemInstanceIds.map((instanceId) =>
            getItem({ instanceId, httpService })
          )
        );

        if (items.some((item) => !item)) {
          setDataResolved('error');
        } else {
          setItems(items);
          setDataResolved('resolved');
        }
      } catch (error) {
        console.error(error);
        setDataResolved('error');
      }
    }

    if (items.length) {
      return;
    }

    void resolveItem();
  }, [httpService, items, itemInstanceIds]);

  if (dataResolved == 'loading') {
    return (_: Omit<P, keyof ResolvedItems>) => (
      <LoadingPage
        loadingText={t('Generic.Loading')}
        loadingSubText={t('ItemResolver.GettingItemLink')}
      />
    );
  }

  if (dataResolved == 'error' || !items?.length) {
    return (_: Omit<P, keyof ResolvedItems>) => (
      <ErrorPage
        errorType="404"
        errorMsg={t('ItemResolver.UnableToFindItem')}
      />
    );
  }

  return (props: Omit<P, keyof ResolvedItems>) => {
    const newProps = { ...props, items } as P;
    return <Component {...newProps} />;
  };
}
