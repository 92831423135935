import React, { useEffect, useState } from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { getProject } from '@bentley/pw-api';
import { Alert, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useHttpService, useNavigationContext } from '../../context';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import { MoveModal } from './moveModal';
import { useMoveAction } from './useMoveAction';

type DifferentEnvironmentWarningModalProps = {
  destinationFolder: PWProject;
  moveItems: PWItem[];
};

export function DifferentEnvironmentWarningModal({
  destinationFolder,
  moveItems
}: DifferentEnvironmentWarningModalProps): JSX.Element | null {
  const httpService = useHttpService();
  const { primaryModal, navigationManager } = useNavigationContext();
  const [destination, setDestination] = useState<PWProject | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [differentEnvironment, setDifferentEnvironment] =
    useState<boolean>(false);

  const move = useMoveAction();

  useEffect(() => {
    async function getMoveDestination() {
      const moveDestination = await getProject({
        instanceId: destinationFolder.instanceId,
        httpService
      });

      if (moveDestination) {
        setDestination(moveDestination);

        const isDifferentEnvironment = moveItems.some(
          (item) => item.EnvironmentId != moveDestination?.EnvironmentId
        );

        if (isDifferentEnvironment) {
          setDifferentEnvironment(true);
        }
      } else {
        openToast({
          content: t('CreateCopy.Notifications.DestinationFolderError'),
          category: 'negative'
        });
      }

      setLoading(false);
    }

    void getMoveDestination();
  }, [moveItems, destinationFolder.instanceId, httpService, primaryModal]);

  const performMove = async (destination: PWProject) => {
    primaryModal.close();

    await move(moveItems, destination, navigationManager.currentParent);
  };

  function onConfirm() {
    if (destination) {
      void performMove(destination);
    }
  }

  function onCancel() {
    primaryModal.open(<MoveModal moveItems={moveItems} />);
  }

  if (!loading && !differentEnvironment) {
    if (destination) {
      void performMove(destination);
      return null;
    }
  }

  return (
    <PWModal
      title={loading ? '' : t('CreateCopy.DifferentEnvironmentTitle')}
      isLoading={loading}
      primaryButton={{
        title: t('Generic.Yes'),
        onClick: onConfirm,
        disabled: loading
      }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: onCancel }}
      onClose={primaryModal.close}
    >
      <Alert
        type="warning"
        data-testid="differentEnvironmentWarningModalMessage"
      >
        {t('CreateCopy.DifferentEnvironmentMessage')}
      </Alert>
      <Text>{t('ManageConnections.Confirmation')}</Text>
    </PWModal>
  );
}
