import React from 'react';
import { PluginWrapper } from '../../pluginWrapper';
import { DocumentCodeWizard } from '../root';
import type { DocumentWizardProps } from './context';
import DocumentWizardProviders from './context';

export function DocumentWizardPlugin(props: DocumentWizardProps): JSX.Element {
  return (
    <PluginWrapper>
      <DocumentWizardProviders value={props}>
        <DocumentCodeWizard {...props} />
      </DocumentWizardProviders>
    </PluginWrapper>
  );
}
