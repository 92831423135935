import React, { useMemo } from 'react';
import { CoAuthoringUserSettings } from '@bentley/office365-collaboration-settings';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { useCoAuthoringCommonProps } from '../../../../hooks/useCoAuthoringCommonProps';
import { t } from '../../../../services/translation';
import { SvgUserSettings } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCoAuthoringOptionsOption(): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const {
    office365CoAuthoringSettings: { isCoAuthoringEnabled }
  } = useEnvironmentContext();

  const { primaryModal } = useNavigationContext();

  const { buddiRegionCode } = usePluginContext();
  const coAuthoringCommonProps = useCoAuthoringCommonProps(buddiRegionCode);

  const coAuthoringOptionsOptions = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.CoAuthoringOptions'),
      icon: <SvgUserSettings />,
      onClick: () => {
        primaryModal.open(
          <CoAuthoringUserSettings
            {...coAuthoringCommonProps}
            isOpen
            onClose={primaryModal.close}
            loginType="OneDrive"
          />
        );
      },
      hidden: !launchDarklyFeatures.showO365SignOut || !isCoAuthoringEnabled
    }),
    [
      launchDarklyFeatures.showO365SignOut,
      isCoAuthoringEnabled,
      primaryModal,
      coAuthoringCommonProps
    ]
  );

  return coAuthoringOptionsOptions;
}
