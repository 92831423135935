import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { DocumentPickerMode } from '@bentley/pwnxt-document-picker';
import { SvgAdd } from '@itwin/itwinui-icons-react';
import { Button, Tooltip } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../context';
import { useDocPickerFunctions } from '../../hooks/useDocPickerFunctions';
import { t } from '../../services/translation';
import { DocumentPickerModalWrapper } from '../flatSetActions/useFlatSetWizard/documentPickerModalWrapper';
import { pwParentTypeToDocumentPickerParentDataType } from '../flatSetActions/utils';
import { DifferentEnvironmentWarningModal } from './differentEnvironmentWarningModal';

type CopyModalProps = {
  copyItems: PWItem[];
};

export function CreateCopyDestinationComponent({
  copyItems
}: CopyModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();

  const {
    documentLinkToProject,
    newFolderModal,
    onNavigate,
    onNewFolderClick,
    validNavigation,
    docPickerBreadcrumbManager
  } = useDocPickerFunctions(copyItems);

  const onItemsSelected = (items: DocumentLink[]) => {
    primaryModal.open(
      <DifferentEnvironmentWarningModal
        destinationFolder={documentLinkToProject(items[0])}
        copyItems={copyItems}
      />
    );
  };

  if (newFolderModal) {
    return newFolderModal;
  }

  return (
    <DocumentPickerModalWrapper
      onClose={primaryModal.close}
      onItemsSelected={onItemsSelected}
      title={t('CreateCopy.SelectDestinationFolder')}
      hideConnectionListView={true}
      customFilter={validNavigation}
      currentFolder={pwParentTypeToDocumentPickerParentDataType(
        docPickerBreadcrumbManager.currentBreadcrumb
      )}
      isSelectable={itemIsProject}
      isNavigationAllowed={validNavigation}
      maximumSelectedItems={1}
      selectionMode={DocumentPickerMode.FolderSelection}
      onNavigate={onNavigate}
    >
      {
        <div
          style={{
            position: 'absolute',
            left: '15px'
          }}
        >
          <Tooltip
            content={t('Move.AddNewFolder')}
            data-testid="tooltip"
            placement={'top'}
          >
            <Button
              title={t('Move.AddNewFolder')}
              onClick={onNewFolderClick}
              startIcon={<SvgAdd />}
            >
              {t('Move.AddNewFolder')}
            </Button>
          </Tooltip>
        </div>
      }
    </DocumentPickerModalWrapper>
  );
}
