import type { PWItem } from '@bentley/pw-api';
import { itemIsDataItem } from '@bentley/pw-api';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import { isSavedSearch } from './savedSearchConverter';

export function plainTextLinks(
  itemLinks: { item: PWItem | SavedSearchDefinition; link: string }[]
): string {
  const plainTextLinks = itemLinks
    .map((itemLink) => itemLink.link)
    .join('\r\n');
  return plainTextLinks;
}

export function htmlTextLinks(
  itemLinks: { item: PWItem | SavedSearchDefinition; link: string }[]
): string {
  const linkWrapper = document.createElement('div');

  for (const { item, link } of itemLinks) {
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.text = itemName(item);
    linkWrapper.append(anchor);

    const br = document.createElement('br');
    linkWrapper.append(br);
  }

  return linkWrapper.innerHTML;
}

function itemName(item: PWItem | SavedSearchDefinition): string {
  if (isSavedSearch(item)) {
    return item.Name;
  }

  if (itemIsDataItem(item) && item.Version) {
    return `${item.Name} (${t('Generic.Version')}: ${item.Version})`;
  }

  return item.Name;
}
