import { useCallback } from 'react';
import type { PWItem, RequestOptions } from '@bentley/pw-api';
import { getItems } from '@bentley/pw-api';
import { useHttpService } from '../../context';

type UpdateItemsFunction = {
  updateItemsFromServer: (
    instanceIds: string[],
    originalItems: PWItem[],
    httpOptions?: RequestOptions
  ) => Promise<void>;
};

export function useUpdateItems(): UpdateItemsFunction {
  const httpService = useHttpService();

  /**
   * Performs an in-place mutation of the items in the data manager
   * by retrieving updated info from the PW server
   */
  const updateItemsFromServer = useCallback(
    async (
      instanceIds: string[],
      originalItems: PWItem[],
      requestOptions?: RequestOptions
    ): Promise<void> => {
      const updatedItems = await getItems({
        instanceIds,
        httpService,
        requestOptions
      });

      for (const updatedItem of updatedItems) {
        const index = originalItems.findIndex(
          ({ instanceId }) => instanceId == updatedItem.instanceId
        );
        if (index > -1) {
          originalItems[index] = { ...originalItems[index], ...updatedItem };
        }
      }
    },
    [httpService]
  );

  return { updateItemsFromServer };
}
