import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ErrorPage } from '../../../components/errorPage';
import { LoadingPage } from '../../../components/loadingPage';
import { useCurrentConnection } from '../../../hooks/useCurrentConnection';
import type { IFederatedRepositoryData } from '../../../hooks/useFederatedRepository';
import { useFederatedRepositories } from '../../../hooks/useFederatedRepository';
import type { GetToken } from '../../../services/http';
import type {
  Connection,
  FederatedRepository
} from '../../../services/support';
import { federatedRepoToConnection } from '../../../services/support';
import { t } from '../../../services/translation';
import type { SelectionState } from '../plugin';
import { featureMap, useFeatures } from '@bentley/pw-config';

type ConnectionsProps = {
  buddiRegionCode: string;
  connectionId?: string;
  connectionList?: 'single' | 'multi';
  contextId?: string;
  gprId: number;
  selectionState?: SelectionState | string;
  user: User;
  getOidcToken: GetToken;
  getSamlToken: GetToken;
  navigate?: (to: string) => void | Promise<void>;
};

type ConnectionsContext = {
  connection?: Connection;
  connectionError?: string;
  connections: FederatedRepository[];
  repositoryManager: IFederatedRepositoryData;
  navigate?: (to: string) => void | Promise<void>;
  setActiveConnection: (connectionId: string) => void;
  setConnectionError: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const Context = createContext<ConnectionsContext | undefined>(undefined);

export function ConnectionsProvider({
  value: {
    buddiRegionCode,
    connectionId,
    connectionList = 'multi',
    contextId,
    gprId,
    selectionState,
    user,
    getOidcToken,
    getSamlToken,
    navigate
  },
  children
}: ProviderProps<ConnectionsProps>): JSX.Element {
  const {
    cachedConnectionId,
    connectionIdFromLocation,
    setCachedConnectionId
  } = useCurrentConnection(contextId);

  const repositoryManager = useFederatedRepositories({
    buddiRegionCode,
    contextId,
    gprId,
    userOrg: user.profile.org as string,
    getOidcToken,
    getSamlToken
  });

  const { features } = useFeatures({
    getSamlToken,
    buddiRegionCode,
    dataAccessLevel: 'WorkArea'
  });

  const updateWsgVersion = useMemo(()=>{
    const ldFlag = features?.find((flag) => {
      return flag.featureFlag == "updateWsgVersionString"
    })?.value;
    return ldFlag ? true : false;
  },[features, featureMap, getSamlToken, buddiRegionCode])

  const [connectionError, setConnectionError] = useState<string>();

  const connection = useMemo((): Connection | undefined => {
    if (!repositoryManager.initialized) {
      return undefined;
    }
    const repo =
      repositoryManager.activeRepo ?? repositoryManager.federatedRepos[0];
    const connection = federatedRepoToConnection(repo, updateWsgVersion);
    return connection;
  }, [
    repositoryManager.initialized,
    repositoryManager.activeRepo,
    repositoryManager.federatedRepos,
    updateWsgVersion
  ]);

  const connections = useMemo((): FederatedRepository[] => {
    if (connectionList == 'single' && repositoryManager.activeRepo) {
      return [repositoryManager.activeRepo];
    }
    return repositoryManager.federatedRepos;
  }, [
    connectionList,
    repositoryManager.activeRepo,
    repositoryManager.federatedRepos
  ]);

  const setActiveConnection = useCallback(
    (connectionId: string): void => {
      const contextPath = contextId ? `context/${contextId}/` : '';
      const connectionPath = `connection/${connectionId ?? ''}`;

      const search = repositoryManager.activeRepo ? '' : window.location.search;
      void navigate?.(`/${contextPath}${connectionPath}${search}`);

      setCachedConnectionId(connectionId);
      repositoryManager.selectFederatedRepository(connectionId);
    },
    [contextId, navigate, repositoryManager, setCachedConnectionId]
  );

  useEffect(() => {
    if (
      !repositoryManager.initialized ||
      !repositoryManager.federatedRepos.length ||
      (selectionState && connectionList != 'single')
    ) {
      return;
    }

    function initialConnection() {
      if (connectionId) {
        return connectionId;
      }

      if (navigate && connectionIdFromLocation) {
        return connectionIdFromLocation;
      }

      if (
        cachedConnectionId &&
        repositoryManager.federatedRepos.some(
          ({ Id }) => Id == cachedConnectionId
        )
      ) {
        return cachedConnectionId;
      }

      return repositoryManager.federatedRepos[0]?.Id;
    }

    const initialConnectionId = initialConnection();

    if (
      repositoryManager.federatedRepos.some(
        ({ Id }) => Id == initialConnectionId
      )
    ) {
      setActiveConnection(initialConnectionId);
    } else {
      setConnectionError(t('ServerError.DatasourceNotFound'));
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * Do not re-fire this when repos are updated
     */
  }, [repositoryManager.initialized]);

  useEffect(() => {
    setConnectionError(undefined);
  }, [repositoryManager.activeRepo]);

  const connectionsContext = useMemo(
    (): ConnectionsContext => ({
      connection,
      connectionError,
      connections,
      repositoryManager,
      navigate,
      setActiveConnection,
      setConnectionError
    }),
    [
      connection,
      connectionError,
      connections,
      navigate,
      repositoryManager,
      setActiveConnection
    ]
  );

  if (!repositoryManager.initialized) {
    return (
      <LoadingPage
        loadingSubText={t('Connections.FindingConnections')}
        fromValue={67}
        toValue={100}
      />
    );
  }

  if (repositoryManager.error) {
    return (
      <ErrorPage
        errorType="generic"
        errorMsg={t('Connections.CannotRetrieveConnections')}
      />
    );
  }

  return (
    <Context.Provider value={connectionsContext}>{children}</Context.Provider>
  );
}

export function useConnectionsContext(): ConnectionsContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useConnectionsContext must be used within a ConnectionsProvider'
    );
  }

  return context;
}
