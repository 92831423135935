import React, { useEffect, useState } from 'react';
import { usePluginContext } from '../../context';
import type {
  InProgressActivity,
  InProgressTracker
} from '../../hooks/useProgressManager/types';
import { useTimedEffect } from '../../hooks/useTimedEffect';
import { InProgress } from '.';

interface IProgressModalProps {
  closeModal: () => void;
  inProgressTracker: InProgressTracker;
}

export function InProgressModal({
  closeModal,
  inProgressTracker
}: IProgressModalProps): JSX.Element | null {
  const { readOnly } = usePluginContext();
  const [activities, setActivities] = useState<InProgressActivity[]>([]);

  function flattenActivities(tracker: InProgressTracker): InProgressActivity[] {
    return tracker.jobsInProgress.reduce(
      (acc, cur) => [
        ...acc,
        ...cur.activitiesInProgress.filter(
          (aip) => aip.activityType != 'uploadFolder'
        )
      ],
      [] as InProgressActivity[]
    );
  }

  useEffect(() => {
    let timeout = -1;

    if (
      activities.length &&
      activities.every((aip) => aip.status == 'success' && !aip.warning)
    ) {
      timeout = window.setTimeout(() => {
        closeModal();
      }, 10000);
    }

    return () => clearTimeout(timeout);
  }, [activities, closeModal]);

  // Todo: this is the last usage of useTimedEffect. It would be nice to get rid of it, if we can
  // find a better way to update the progress tracker
  useTimedEffect(
    () => {
      const updatedActivities = flattenActivities(inProgressTracker);
      setActivities(updatedActivities);
    },
    [inProgressTracker],
    500
  );

  const tab =
    inProgressTracker.mostRecentJobType == 'download'
      ? 1
      : inProgressTracker.mostRecentJobType == 'workflow'
      ? 2
      : 0;

  return (
    <InProgress
      activities={activities}
      onClearCallback={closeModal}
      readOnly={readOnly}
      tab={tab}
      inProgressTracker={inProgressTracker}
    />
  );
}
