import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import { useEnvironmentContext } from '../../../../context';
import { applications } from '../../../../hooks/useDocumentProperties';
import type {
  ColumnDisplayOptions,
  ColumnInfo
} from '../../../../hooks/useViews';
import {
  getItemLockedTo,
  getItemStatus
} from '../../../../services/itemProperties';
import { IModelBadge, LockedVersionBadge, PDFBadge } from '../../cell/badges';
import { ColumnValuesFilter } from '../../filter/columnValuesFilter';
import { buildDropdownFilter } from '../../filter/dropdownFilter';
import {
  customPropertySortType,
  fileNameColumnSortType,
  nameColumnSortType,
  versionColumnSortType
} from '../../sort/columnSort';
import { lookupAccessor } from '../accessor';
import type { PropertyColumn } from '../typedColumn';
import { useBaseColumn } from '../useBaseColumn';

export function useGeneralPropertyColumn(): PropertyColumn {
  const {
    availableStates,
    availableStatuses,
    availableWorkflows,
    ecPluginFeatures,
    getStateString,
    getWorkflowString
  } = useEnvironmentContext();

  const baseColumn = useBaseColumn();

  const nameColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, {
        ...displayOptions,
        isSecondary: true,
        badges: [PDFBadge, IModelBadge, LockedVersionBadge]
      });

      return {
        ...column,
        sortType: nameColumnSortType
      };
    },
    [baseColumn]
  );

  const versionColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        sortType: versionColumnSortType,
        Filter: ColumnValuesFilter
      };
    },
    [baseColumn]
  );

  const fileNameColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        sortType: fileNameColumnSortType
      };
    },
    [baseColumn]
  );

  const outToColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        accessor: lookupAccessor(getItemLockedTo, 'string'),
        sortType: customPropertySortType(getItemLockedTo, 'string'),
        Filter: ColumnValuesFilter
      } as Column<PWItem>;
    },
    [baseColumn]
  );

  const applicationColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        Filter: buildDropdownFilter(applications)
      };
    },
    [baseColumn]
  );

  const workflowColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);

      const workflowColumn = {
        ...column,
        Filter: buildDropdownFilter(
          availableWorkflows.map((workflow) => workflow.Name)
        )
      };

      if (!ecPluginFeatures.stateWorkflowProperties()) {
        return {
          ...workflowColumn,
          accessor: lookupAccessor(getWorkflowString, 'string'),
          sortType: customPropertySortType(getWorkflowString, 'string')
        } as Column<PWItem>;
      }

      return workflowColumn;
    },
    [availableWorkflows, baseColumn, ecPluginFeatures, getWorkflowString]
  );

  const stateColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);

      const stateColumn = {
        ...column,
        Filter: buildDropdownFilter(availableStates.map((state) => state.Name))
      };

      if (!ecPluginFeatures.stateWorkflowProperties()) {
        return {
          ...stateColumn,
          accessor: lookupAccessor(getStateString, 'string'),
          sortType: customPropertySortType(getStateString, 'string')
        } as Column<PWItem>;
      }

      return stateColumn;
    },
    [availableStates, baseColumn, ecPluginFeatures, getStateString]
  );

  const statusColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        accessor: lookupAccessor(getItemStatus, 'string'),
        sortType: customPropertySortType(getItemStatus, 'string'),
        Filter: buildDropdownFilter(availableStatuses)
      } as Column<PWItem>;
    },
    [availableStatuses, baseColumn]
  );

  const picklistColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(columnInfo, displayOptions);
      return {
        ...column,
        Filter: ColumnValuesFilter
      };
    },
    [baseColumn]
  );

  const objectGuidColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseColumn(
        { ...columnInfo, name: 'instanceId' },
        displayOptions
      );

      return column;
    },
    [baseColumn]
  );

  const generalPropertyColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions: ColumnDisplayOptions = {}
    ): Column<PWItem> => {
      if (columnInfo.name == 'Name') {
        return nameColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'Version') {
        return versionColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'FileName') {
        return fileNameColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'OutTo' && !ecPluginFeatures.outToProperty()) {
        return outToColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'Application') {
        return applicationColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'Workflow') {
        return workflowColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'State') {
        return stateColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'Status') {
        return statusColumn(columnInfo, displayOptions);
      }

      if (
        [
          'Owner',
          'OutTo',
          'CreatedBy',
          'UpdatedBy',
          'FileUpdatedBy',
          'FinalBy'
        ].includes(columnInfo.name)
      ) {
        return picklistColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == '$id') {
        return objectGuidColumn(columnInfo, displayOptions);
      }

      return baseColumn(columnInfo, displayOptions);
    },
    [
      ecPluginFeatures,
      baseColumn,
      nameColumn,
      versionColumn,
      fileNameColumn,
      outToColumn,
      applicationColumn,
      workflowColumn,
      stateColumn,
      statusColumn,
      picklistColumn,
      objectGuidColumn
    ]
  );

  return generalPropertyColumn;
}
