import { responseAborted } from '../../services/http';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import { defaultProjectSettings } from '../dynamicProjectSettings';
import { projectSettingsToFolderSettings } from './dynamicFolderSettingsData.utils';
import type { DynamicFolderSettings } from './dynamicFolderSettingsModal';
import { notifyMultiSaveFailure } from './notifications';

type DynamicFolderSettingsResponse = {
  projectId: string;
  timestamp: number;
  settings: string;
};

type DynamicSettingsJson = {
  autoCheckout?: {
    enabled: boolean;
  };
  documentWizard: {
    showIfDocumentCode: boolean;
    showIfNoDocumentCode: boolean;
    showIfPWIC: boolean;
    inheritProjectSettings: boolean;
  };
  forceChildInherit: boolean;
};

export async function handleGetSettingsResponse(
  response: Response,
  settingsInherited?: boolean
): Promise<DynamicFolderSettings> {
  if (responseAborted(response)) {
    return projectSettingsToFolderSettings(defaultProjectSettings);
  }

  if (!response.ok) {
    throw t('FolderSettings.ErrorRetrievingSettings', {
      status: response.statusText
    });
  }
  const data = (await response.json()) as DynamicFolderSettingsResponse;
  return parseDynamicSettingsResponse(data.settings, settingsInherited);
}

function parseDynamicSettingsResponse(
  rawSettings: string,
  settingsInherited?: boolean
): DynamicFolderSettings {
  const settings = JSON.parse(rawSettings) as DynamicSettingsJson;
  return {
    UseDCWInFoldersWithCode: settings?.documentWizard.showIfDocumentCode,
    UseDCWInFoldersWithoutCode: settings?.documentWizard.showIfNoDocumentCode,
    UseDCWInPWIC: settings?.documentWizard.showIfPWIC,
    AutoCheckoutDocsInDrive: settings?.autoCheckout?.enabled,
    InheritProjectSettings: settings?.documentWizard.inheritProjectSettings,
    ForcedInheritance: settings?.forceChildInherit,
    SettingsInherited: settingsInherited ?? false
  } as DynamicFolderSettings;
}

export function handlePostSettingsResponse(
  response: Response,
  shouldToast = true
): void {
  if (!response.ok) {
    const content = t('FolderSettings.ErrorSettingSettings', {
      status: response.statusText
    });
    openToast({ content, category: 'negative' });
    return;
  }
  if (shouldToast) {
    const content = t('FolderSettings.Saved');
    openToast({ content, category: 'positive' });
  }
}

export function handleDeleteSettingsResponse(
  response: Response,
  shouldToast = true
): void {
  if (response.status == 404) {
    return;
  }
  handlePostSettingsResponse(response, shouldToast);
}

export function handleDeleteChildSettingsResponses(
  responses: Response[]
): void {
  const successes = responses.filter(isSuccessfulResponse);
  const failures = responses.filter(isFailureResponse);

  if (successes.length) {
    toastMultiSaveSuccess(successes);
  }

  if (failures.length) {
    notifyMultiSaveFailure(failures);
  }
}

function isSuccessfulResponse(response: Response): boolean {
  return response.ok || response.status == 404;
}

function isFailureResponse(response: Response): boolean {
  return !isSuccessfulResponse(response);
}

function toastMultiSaveSuccess(responses: Response[]): void {
  let content = t('FolderSettings.Saved');
  if (responses.length > 1) {
    content = t('FolderSettings.SavedMulti', {
      count: responses.length
    });
  }

  openToast({ content, category: 'positive' });
}
