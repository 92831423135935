import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { SvgChevronDown, SvgChevronUp } from '@itwin/itwinui-icons-react';
import { IconButton, TransferList } from '@itwin/itwinui-react';
import {
  SvgChevronDownBottom,
  SvgChevronUpTop
} from '../../../components/icons';
import { t } from '../../../services/translation';
import type { TransferData } from './columnSelection';

type ReorderToolbarProps<T> = {
  data: T[];
  setData: Dispatch<SetStateAction<T[]>>;
};

export function ReorderToolbar<T extends TransferData>({
  data,
  setData
}: ReorderToolbarProps<T>): JSX.Element {
  const activeItems = data.filter((item) => item.active);

  function moveTopEnabled(): boolean {
    if (!activeItems.length) {
      return false;
    }

    if (activeItems.every((item) => data.indexOf(item) < activeItems.length)) {
      return false;
    }

    return true;
  }

  function moveActiveTop(): void {
    const newData = [...data];

    const activeIndices = activeItems.map((item) =>
      newData.findIndex((dataItem) => dataItem == item)
    );

    activeIndices.forEach((activeIndex, insertionIndex) => {
      const activeItem = newData.splice(activeIndex, 1)[0];
      newData.splice(insertionIndex, 0, activeItem);
    });

    setData(newData);
  }

  function moveUpEnabled(): boolean {
    if (!activeItems.length || data[0].active) {
      return false;
    }

    return true;
  }

  function moveActiveUp(): void {
    const newData = [...data];

    const activeIndices = activeItems.map((item) =>
      newData.findIndex((dataItem) => dataItem == item)
    );

    activeIndices.forEach((activeIndex) => {
      const activeItem = newData.splice(activeIndex, 1)[0];
      newData.splice(activeIndex - 1, 0, activeItem);
    });

    setData(newData);
  }

  function moveDownEnabled(): boolean {
    if (!activeItems.length || data[data.length - 1].active) {
      return false;
    }

    return true;
  }

  function moveActiveDown(): void {
    const newData = [...data];

    const activeIndices = activeItems.map((item) =>
      newData.findIndex((dataItem) => dataItem == item)
    );

    activeIndices.reverse().forEach((activeIndex) => {
      const activeItem = newData.splice(activeIndex, 1)[0];
      newData.splice(activeIndex + 1, 0, activeItem);
    });

    setData(newData);
  }

  function moveBottomEnabled(): boolean {
    if (!activeItems.length) {
      return false;
    }

    if (
      activeItems.every(
        (item) => data.indexOf(item) >= data.length - activeItems.length
      )
    ) {
      return false;
    }

    return true;
  }

  function moveActiveBottom(): void {
    const newData = [...data];

    const activeIndices = activeItems.map((item) =>
      newData.findIndex((dataItem) => dataItem == item)
    );

    activeIndices.reverse().forEach((activeIndex, insertionIndex) => {
      const activeItem = newData.splice(activeIndex, 1)[0];
      newData.splice(newData.length - insertionIndex, 0, activeItem);
    });

    setData(newData);
  }

  return (
    <TransferList.Toolbar className="column-toolbar">
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveToTop')}
        disabled={!moveTopEnabled()}
        onClick={moveActiveTop}
        labelProps={{ placement: 'right' }}
        data-testid="moveTop"
      >
        <SvgChevronUpTop />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveUp')}
        disabled={!moveUpEnabled()}
        onClick={moveActiveUp}
        labelProps={{ placement: 'right' }}
        data-testid="moveUp"
      >
        <SvgChevronUp />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveDown')}
        disabled={!moveDownEnabled()}
        onClick={moveActiveDown}
        labelProps={{ placement: 'right' }}
        data-testid="moveDown"
      >
        <SvgChevronDown />
      </IconButton>
      <IconButton
        styleType="borderless"
        label={t('ColumnConfiguration.MoveToBottom')}
        disabled={!moveBottomEnabled()}
        onClick={moveActiveBottom}
        labelProps={{ placement: 'right' }}
        data-testid="moveBottom"
      >
        <SvgChevronDownBottom />
      </IconButton>
    </TransferList.Toolbar>
  );
}
