import React from 'react';
import { SvgDelete, SvgDuplicate, SvgEdit } from '@itwin/itwinui-icons-react';
import { IconButton } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import type { SavedAdvancedQuery } from '../../../components/advancedSearch';
import AdvancedSearchModal from '../../../components/advancedSearch/advancedSearchModal';
import { useNavigationContext } from '../../../context';
import { t } from '../../../services/translation';
import { DeleteConfirmationModal } from '../../myViews/deleteConfirmationModal';
import { AdvancedSavedSearchesModal } from '../AdvancedSavedSearchesModal';
import { useSearchActions } from './useSearchActions';

export function ActionsCell({
  row
}: CellProps<SavedAdvancedQuery>): JSX.Element | null {
  const { primaryModal, searchState } = useNavigationContext();

  const { onEdit, onCopy, onDelete } = useSearchActions();

  const search = row.original;

  const globalSearch = searchState.myGlobalSearches?.some(
    (globalSearch) => globalSearch.name == search.name
  );
  const searchNames = [
    ...(searchState.myConnectionSearches ?? []),
    ...(searchState.myGlobalSearches ?? [])
  ].map((search) => search.name);

  function onEditClick(): void {
    primaryModal.open(
      <AdvancedSearchModal
        onClose={() => primaryModal.open(<AdvancedSavedSearchesModal />)}
        onSearch={(newSearch, multiConnectionSearch, name, json) =>
          onEdit(newSearch, search, multiConnectionSearch, name, json)
        }
        mode="Edit"
        selectedSearch={search}
        multiConnectionSearch={globalSearch}
        takenNames={searchNames}
      />
    );
  }

  function onCopyClick(): void {
    primaryModal.open(
      <AdvancedSearchModal
        onClose={() => primaryModal.open(<AdvancedSavedSearchesModal />)}
        onSearch={onCopy}
        mode="Copy"
        selectedSearch={search}
        multiConnectionSearch={globalSearch}
        takenNames={searchNames}
      />
    );
  }

  function onDeleteClick(): void {
    primaryModal.open(
      <DeleteConfirmationModal
        onYes={() => {
          onDelete(search.name);
          primaryModal.open(<AdvancedSavedSearchesModal />);
        }}
        onNo={() => primaryModal.open(<AdvancedSavedSearchesModal />)}
        customTitle={t('SavedAdvSearch.DeleteAdvSearch')}
        customMessage={t('SavedAdvSearch.DeleteSelectedSearch')}
      />
    );
  }

  return (
    <>
      <IconButton
        label={t('Generic.Edit')}
        styleType="borderless"
        onClick={onEditClick}
        data-testid={`edit-search-btn-${search.name}`}
      >
        <SvgEdit />
      </IconButton>
      <IconButton
        label={t('Generic.Copy')}
        styleType="borderless"
        onClick={onCopyClick}
        data-testid={`copy-search-btn-${search.name}`}
      >
        <SvgDuplicate />
      </IconButton>
      <IconButton
        label={t('Generic.Delete')}
        styleType="borderless"
        onClick={onDeleteClick}
        data-testid={`delete-search-btn-${search.name}`}
      >
        <SvgDelete />
      </IconButton>
    </>
  );
}
