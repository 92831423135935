import type { PWDataItem, PWItem, PWParentType } from '@bentley/pw-api';
import {
  itemIsFlatSet,
  itemIsLockedOrCheckedOut,
  itemIsProject
} from '@bentley/pw-api';

/**
 * Returns whether items are able to be deleted
 * @param {PWItem[]} items Items to be deleted
 * @param {PWParentType} parent Parent of items to be deleted
 * @returns {boolean} True if all items may be deleted
 */
export function allowDeleteAll(
  items: PWItem[],
  parent: PWParentType,
  isMultipleItemExistsInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || !items.length) {
    return false;
  }

  if (!items.every((item) => allowDelete(item, parent))) {
    return false;
  }

  if (
    isMultipleItemExistsInConnection &&
    !isMultipleItemExistsInConnection(items)
  ) {
    return false;
  }

  return true;
}

/**
 * Returns whether item is able to be deleted
 * @param {PWItem} item Item to be deleted
 * @param {PWParentType} parent Parent of item to be deleted
 * @returns {boolean} True if item may be deleted
 */
export function allowDelete(item: PWItem, parent: PWParentType): boolean {
  if (!item || !item.className || !item.instanceId) {
    return false;
  }

  if (itemIsLockedOrCheckedOut(item) || itemIsReadOnly(item)) {
    return false;
  }

  if (itemIsFlatSet(parent) && !itemIsFlatSet(item)) {
    return false;
  }

  return true;
}

// Old versions are marked readonly, but are still deletable, so check IsLatest as well
function itemIsReadOnly(item: PWItem): boolean {
  if (itemIsProject(item)) {
    return false;
  }

  const dataItem = item as PWDataItem;
  return dataItem.IsReadOnly && dataItem.IsLatest;
}
