import type {
  HttpService,
  PWItem,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import {
  flattenProperties,
  getProject,
  parseResponseInstances
} from '@bentley/pw-api';

/**
 * Returns ancestors of {@link item} up to the {@link connectionRootId}.
 * The returned array is ordered from highest to lowest
 */
export async function getItemAncestors(
  item: PWItem,
  connectionRootId: string,
  httpService: HttpService,
  requestOptions?: RequestOptions
): Promise<PWProject[]> {
  if (!item.ParentGuid) {
    return [];
  }

  if (item.instanceId === connectionRootId) {
    return [];
  }

  try {
    const parent = await getProject({
      instanceId: item.ParentGuid,
      httpService,
      requestOptions
    });
    if (!parent) {
      return [];
    }

    if (!parent.instanceId || parent.instanceId == connectionRootId) {
      return [parent];
    }

    const ancestors = await getFullAncestorStack(
      parent,
      httpService,
      connectionRootId,
      requestOptions
    );

    return [...ancestors, parent];
  } catch (e) {
    const response = e as Response;
    if (response.status == 404) {
      return [
        { instanceId: connectionRootId } as PWProject,
        { instanceId: item.ParentGuid } as PWProject
      ];
    } else {
      return [];
    }
  }
}

/**
 * Returns ancestors of passed project up to root of connection, not including passed project
 */
async function getFullAncestorStack(
  project: PWProject,
  httpService: HttpService,
  connectionRootId: string,
  httpOptions?: RequestOptions
): Promise<PWProject[]> {
  function topAncestor({ instanceId, ParentGuid }: PWProject): boolean {
    return !instanceId || !ParentGuid || instanceId == connectionRootId;
  }

  const ancestors = [] as PWProject[];

  do {
    const batch = await getAncestors(project, 6, httpService, httpOptions);
    ancestors.unshift(...batch);
    project = ancestors[0];
  } while (ancestors.length && !ancestors.find(topAncestor));

  return removeAncestorsAboveConnection(ancestors, connectionRootId);
}

/**
 * Returns ancestors of passed project, up to specified depth, not including passed project
 */
async function getAncestors(
  project: PWProject,
  depth: number,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWProject[]> {
  const url = `PW_WSG/Project/$query`;
  const select = getParentsSelectQuery(depth);
  const body = `$filter=$id+eq+'${project.instanceId}'&$select=${select}`;

  const response = await httpService.post(url, body, httpOptions);

  const instances = await parseResponseInstances<PWProject>(response);
  const ancestorInstances = parseAncestorInstances(instances);

  return ancestorInstances;
}

function getParentsSelectQuery(depth: number): string {
  const ancestorSelect = [...new Array<string>(depth)]
    .map((_val, i) => selectAncestor(i + 1))
    .join(',');

  return `*,${ancestorSelect}`;
}

function selectAncestor(depth: number): string {
  const projectParentRelationshipClass = 'ProjectParent-forward-Project';

  const ancestorRelationship = new Array(depth)
    .fill(projectParentRelationshipClass)
    .join('/');

  return `${ancestorRelationship}.*`;
}

function parseAncestorInstances(instances: PWProject[]) {
  const parentList = [] as PWProject[];
  let parent = instances[0];

  while (parent.relationshipInstances?.length) {
    const relatedInstance = parent.relationshipInstances[0].relatedInstance;
    parent = flattenProperties(relatedInstance) as PWProject;
    parentList.unshift(parent);
  }

  return parentList;
}

function removeAncestorsAboveConnection(
  ancestors: PWProject[],
  connectionRootId: string
): PWProject[] {
  const connectionIndex = ancestors.findIndex(
    ({ instanceId }) => instanceId == connectionRootId
  );

  if (connectionIndex >= 0) {
    return ancestors.slice(connectionIndex);
  }

  return ancestors;
}
