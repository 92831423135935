import { useCallback, useMemo } from 'react';
import type {
  PWDataItem,
  PWItem,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { byDefaultOrder, parseResponseInstances } from '@bentley/pw-api';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { getEnvironmentSelectString } from '../../useEnvironment';
import { formatAllContentQuery } from './formatAllContentQuery';

type AllContentSearchFunction = (
  httpOptions?: RequestOptions | undefined
) => Promise<PWItem[]>;

export function useAllContentSearch(): AllContentSearchFunction {
  const httpService = useHttpService();
  const { logError } = usePluginContext();
  const {
    navigationManager: { currentParent },
    searchState: { basicQuery }
  } = useNavigationContext();

  const invalidQuery = useMemo((): boolean => {
    if (
      basicQuery == '.' ||
      basicQuery == '*' ||
      basicQuery == '*.' ||
      basicQuery == '*%' ||
      basicQuery == '**' ||
      basicQuery == `*'` ||
      basicQuery == '*_' ||
      basicQuery == '*?' ||
      basicQuery == '?' ||
      basicQuery == "'" ||
      basicQuery == '_'
    ) {
      return true;
    }

    return false;
  }, [basicQuery]);

  const apiAncestorQuery = useMemo((): string => {
    if (!currentParent.instanceId) {
      return '';
    }

    return `api.ancestor=PW_WSG.Project-${currentParent.instanceId}&`;
  }, [currentParent.instanceId]);

  const projectSearchUrl = useCallback((): string => {
    const searchString = formatAllContentQuery(basicQuery);
    const filter = `SimpleSearchResult-backward-AllContentSearch.Query eq '${searchString}'`;
    const environmentSelect = getEnvironmentSelectString('Project');
    const select = `*,${environmentSelect},ProjectParent-forward-Project.*`;
    return `PW_WSG/Project!poly?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
  }, [apiAncestorQuery, basicQuery]);

  const projectSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWProject[]> => {
      const url = projectSearchUrl();
      const response = await httpService.get(url, httpOptions);
      if (response.ok) {
        const projects = await parseResponseInstances<PWProject>(response);
        const nonRootProjects = projects.filter(
          (project) => project.instanceId != currentParent.instanceId
        );
        return nonRootProjects;
      }
      // If error occurs, return empty array and document it in the console
      logError(`All content search failed ${response.statusText}`, {
        response,
        url
      });
      return [];
    },
    [currentParent.instanceId, httpService, projectSearchUrl, logError]
  );

  const documentSearchUrl = useCallback((): string => {
    const searchString = formatAllContentQuery(basicQuery);
    const filter = `SimpleSearchResult-backward-AllContentSearch.Query eq '${searchString}'`;
    const environmentSelect = getEnvironmentSelectString('Document');
    const select = `*,${environmentSelect},DocumentParent-forward-Project.*`;
    return `PW_WSG/Document!poly?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
  }, [apiAncestorQuery, basicQuery]);

  const documentSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWDataItem[]> => {
      const url = documentSearchUrl();
      const response = await httpService.get(url, httpOptions);
      if (response.ok) {
        const documents = await parseResponseInstances<PWDataItem>(response);
        return documents;
      }
      // If error occurs, return empty array and document it in the console
      console.error(
        `An error occurred with the document query during AllContentSearch: ${response.statusText}`
      );
      return [];
    },
    [documentSearchUrl, httpService]
  );

  const allContentSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWItem[]> => {
      if (invalidQuery) {
        return [];
      }

      const [projects, documents] = await Promise.all([
        projectSearch(httpOptions),
        documentSearch(httpOptions)
      ]);
      const items = [...projects, ...documents];
      items.sort(byDefaultOrder);
      return items;
    },
    [documentSearch, invalidQuery, projectSearch]
  );

  return allContentSearch;
}
