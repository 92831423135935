import { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import type { PWDataItem, RequestOptions, WSGInstance } from '@bentley/pw-api';
import { parseRelatedEnvironmentSheets } from '@bentley/pw-api';
import { getProjectDefaultEnvironmentInstance } from '../../../actions/formRendering';
import { useHttpService } from '../../../context';
import { currentSheetState } from '../state';
import { getDocumentEnvironmentInstances } from './data';

type GetFormInstanceFunction = (
  item: PWDataItem,
  requestOptions?: RequestOptions
) => Promise<WSGInstance>;

export function useFormInstance(): GetFormInstanceFunction {
  const defaultFormInstances = useRef<Record<string, WSGInstance>>({});

  const httpService = useHttpService();

  const currentSheet = useRecoilValue(currentSheetState);

  const getFormInstanceOrDefault = useCallback(
    async (
      item: PWDataItem,
      requestOptions?: RequestOptions
    ): Promise<WSGInstance> => {
      if (requestOptions?.abortController?.signal.aborted) {
        return Promise.reject();
      }

      const existingSheets = parseRelatedEnvironmentSheets(item);
      if (existingSheets.length) {
        return existingSheets[currentSheet];
      }

      const formInstances = await getDocumentEnvironmentInstances(
        item,
        requestOptions
      );
      if (formInstances.length && formInstances[currentSheet]) {
        return formInstances[currentSheet];
      }

      if (defaultFormInstances.current[item.ParentGuid]) {
        return defaultFormInstances.current[item.ParentGuid];
      }

      const defaultFormInstance = await getProjectDefaultEnvironmentInstance(
        item.ParentGuid,
        false,
        httpService,
        requestOptions
      );

      defaultFormInstances.current[item.ParentGuid] = defaultFormInstance[0];
      return defaultFormInstance[0];
    },
    [currentSheet, httpService]
  );

  return getFormInstanceOrDefault;
}
