import './graphErrorModal.css';

import type { User } from 'oidc-client';
import React, { useEffect, useRef, useState } from 'react';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Text
} from '@itwin/itwinui-react';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';
import type { GraphErrorSummaryItem } from './graphErrorSummary';

export function showGraphFreeErrorModal(
  rows: PWItem[],
  summaries: GraphErrorSummaryItem[],
  openModal: OpenModal,
  closeModal: CloseModal,
  operation: (files: PWFileType[]) => Promise<void>,
  user: User,
  showCustomModal?: (title?: string, content?: JSX.Element) => void
): void {
  return showGraphErrorModal(
    rows,
    summaries,
    openModal,
    closeModal,
    () => ({
      error: t('Sync.FailedToFree'),
      warning: t('Sync.Coauthoring.FreeWarning')
    }),
    (count) => ({
      warning: t('Sync.Coauthoring.FreeWarningDescription', {
        count
      })
    }),
    operation,
    user,
    undefined,
    showCustomModal
  );
}

export function showGraphCheckInErrorModal(
  rows: PWItem[],
  summaries: GraphErrorSummaryItem[],
  openModal: OpenModal,
  closeModal: CloseModal,
  operation: (files: PWFileType[]) => Promise<void>,
  user: User,
  showCustomModal?: (title?: string, content?: JSX.Element) => void
): void {
  return showGraphErrorModal(
    rows,
    summaries,
    openModal,
    closeModal,
    () => ({
      error: t('Sync.Coauthoring.FailedToCheckIn'),
      warning: t('Sync.Coauthoring.CheckInWarning')
    }),
    (count) => ({
      warning: t('Sync.Coauthoring.CheckInWarningDescription', {
        count
      })
    }),
    operation,
    user,
    t('Sync.Coauthoring.CheckInConfirmation'),
    showCustomModal
  );
}

function showGraphErrorModal(
  rows: PWItem[],
  summaries: GraphErrorSummaryItem[],
  openModal: OpenModal,
  closeModal: CloseModal,
  getModalTitle: () => { error: string; warning: string },
  getMessage: (count: number) => { error?: string; warning?: string },
  operation: (files: PWFileType[]) => Promise<void>,
  user: User,
  checkBoxLabel?: string,
  showCustomModal?: (title?: string, content?: JSX.Element) => void
) {
  const modal = showCustomModal
    ? (
        _: OpenModal,
        closeModal: CloseModal,
        failures: GraphErrorSummaryItem[],
        type: 'negative' | 'warning',
        title: string,
        user: User,
        errorMessage?: string,
        continueFunction?: () => Promise<void>,
        checkBoxLabel?: string
      ) =>
        showCustomModal(
          title,
          <GraphErrorModalContent
            closeModal={closeModal}
            summaries={failures}
            type={type}
            errorMessage={errorMessage}
            continueFunction={continueFunction}
            checkBoxLabel={checkBoxLabel}
            user={user}
          />
        )
    : graphErrorSummaryModal;

  const errors = summaries.filter((summary) => summary.type === 'negative');
  const warnings = summaries.filter((summary) => summary.type === 'warning');

  if (warnings.length === 0) {
    modal(
      openModal,
      closeModal,
      errors,
      'negative',
      getModalTitle().error,
      user,
      undefined
    );
  } else {
    const files = rows.map((row) => row as PWFileType);

    // If there are both errors and warnings at the same time related to co-authoring,
    // we want to show a modal with warnings first, execute the operation on files
    // without any errors and then open a modal showing those errors
    const continueFunction =
      errors.length > 0
        ? async () => {
            await operation(
              files.filter((file) =>
                errors.some(
                  (error) => error.item.instanceId === file.instanceId
                )
              )
            );
            modal(
              openModal,
              closeModal,
              errors,
              'negative',
              getModalTitle().error,
              user,
              undefined
            );
          }
        : async () => {
            showCustomModal?.();
            await operation(files);
          };

    modal(
      openModal,
      closeModal,
      warnings,
      'warning',
      getModalTitle().warning,
      user,
      getMessage(warnings.length).warning,
      continueFunction,
      checkBoxLabel
    );
  }
}

type GraphErrorModalProps = {
  checkBoxLabel?: string;
  errorMessage?: string;
  summaries: GraphErrorSummaryItem[];
  type: 'warning' | 'negative';
  user: User;
  closeModal: () => void;
  continueFunction?: () => Promise<void>;
};

export function graphErrorSummaryModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  failures: GraphErrorSummaryItem[],
  type: 'negative' | 'warning',
  title: string,
  user: User,
  errorMessage?: string,
  continueFunction?: () => Promise<void>,
  checkBoxLabel?: string
): void {
  openModal(
    <PWModal
      title={title}
      className="ge-modal"
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'GraphErrorModal' }}
    >
      <GraphErrorModalContent
        closeModal={closeModal}
        summaries={failures}
        type={type}
        errorMessage={errorMessage}
        continueFunction={continueFunction}
        checkBoxLabel={checkBoxLabel}
        user={user}
      />
    </PWModal>
  );
}

function GraphErrorModalContent({
  checkBoxLabel,
  errorMessage,
  summaries,
  type,
  user,
  closeModal,
  continueFunction
}: GraphErrorModalProps): JSX.Element {
  const listInnerRef = useRef<HTMLDivElement>(null);

  const [confirmed, setConfirmed] = useState(
    !(checkBoxLabel && continueFunction)
  );
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => onScroll(), [listInnerRef]);

  function onScroll() {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      // .ge-error-div has a bottom margin of 20px. Don't need to scroll all the way down
      // Also, on systems using display scaling, scrollTop may have a decimal value
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setScrolledToBottom(true);
      }
    }
  }

  function continueButtonFunction(): void {
    void continueFunction?.();
    closeModal();
  }

  const primaryButtonLabel = continueFunction
    ? t('Generic.Continue')
    : undefined;

  return (
    <>
      <Flex flexDirection="column" alignItems="stretch">
        {errorMessage && <Alert type={type}>{errorMessage}</Alert>}
        <Flex
          flexDirection="column"
          alignItems="stretch"
          className={'ge-list'}
          onScroll={() => onScroll()}
          ref={listInnerRef}
        >
          {summaries
            .sort((a, b) => (a.item.Name < b.item.Name ? -1 : 1))
            .map((file) => (
              <FailureItem
                graphErrorSummary={file}
                key={file.item.Name}
                user={user}
              />
            ))}
        </Flex>
        {checkBoxLabel && continueFunction && (
          <Checkbox
            disabled={!scrolledToBottom}
            label={checkBoxLabel}
            onChange={(e) => setConfirmed(e.target.checked)}
            checked={confirmed}
          />
        )}
      </Flex>
      <Dialog.ButtonBar>
        {primaryButtonLabel && (
          <Button
            styleType="high-visibility"
            autoFocus={true}
            onClick={continueButtonFunction}
            disabled={!confirmed}
          >
            {primaryButtonLabel}
          </Button>
        )}
        <Button onClick={closeModal}>{t('Generic.Cancel')}</Button>
      </Dialog.ButtonBar>
    </>
  );
}

type GraphErrorItemProps = {
  graphErrorSummary: GraphErrorSummaryItem;
  user: User;
};

function FailureItem({
  graphErrorSummary,
  user
}: GraphErrorItemProps): JSX.Element {
  const participants =
    !graphErrorSummary.participants ||
    graphErrorSummary.participants.some(
      (p) => p.userName == user?.profile.email
    )
      ? graphErrorSummary.participants
      : [
          ...graphErrorSummary.participants,
          { userId: user?.profile.sub, userName: user?.profile.email }
        ];

  return (
    <Flex flexDirection="column" alignItems="stretch">
      <ItemSummary item={graphErrorSummary.item} />
      {graphErrorSummary.errorTextKey && (
        <Text>
          {t(
            graphErrorSummary.errorTextKey,
            graphErrorSummary.displayOutTo
              ? { user: graphErrorSummary.item.OutTo }
              : undefined
          )}
        </Text>
      )}
      {participants && (
        <>
          <Text>{t('Sync.Coauthoring.Participants')}</Text>
          <ul style={{ margin: 0 }}>
            {participants.map((participant, index) => (
              <li key={index}>{participant.userName}</li>
            ))}
          </ul>
        </>
      )}
    </Flex>
  );
}
