import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PWParentType } from '@bentley/pw-api';
import {
  getFlatSet,
  getProject,
  itemIsFlatSet,
  itemIsParentType
} from '@bentley/pw-api';
import { useToaster } from '@itwin/itwinui-react';
import { getLocation } from '../../actions/getShareableLink';
import {
  useConnectionContext,
  useFeatureTracking,
  useHttpService,
  usePluginContext
} from '../../context';
import type { BreadcrumbManager } from '../useBreadcrumb';
import { useCachedParent } from './useCachedParent';

export type NavigationManager = {
  currentParent: PWParentType;
  linkError: Error | undefined;
  navigateTo: (item: PWParentType, rebuildBreadcrumbs?: boolean) => void;
  navigateToParent?: () => void;
  navigateToRoot: () => void;
  refreshNavigation: () => Promise<void>;
  setLinkError: Dispatch<SetStateAction<Error | undefined>>;
};

export function usePWNavigation(
  {
    addBreadcrumb,
    breadcrumbs,
    connectionBreadcrumb,
    currentBreadcrumb,
    jumpTo,
    setBreadcrumbsToItem
  }: BreadcrumbManager,
  setInfoPanelDisplayed: Dispatch<SetStateAction<boolean>>,
  setShowDropzone: Dispatch<SetStateAction<boolean>>
): NavigationManager {
  const toaster = useToaster();
  const { trackFeature } = useFeatureTracking();
  const { selectionState } = useConnectionContext();
  const { connection, navigate, progressManager } = usePluginContext();
  const httpService = useHttpService();

  const [cachedParent, setCachedParent] = useCachedParent();

  const [currentParent, setCurrentParent] = useState<PWParentType>(
    selectionState.selectedItemId ? ({} as PWParentType) : connectionBreadcrumb
  );

  const [linkError, setLinkError] = useState<Error>();

  const trackNavigationFeature = useCallback(
    (parent: PWParentType): void => {
      if (itemIsFlatSet(parent)) {
        trackFeature('NAVIGATE_FLATSET');
      } else {
        trackFeature('NAVIGATE_FOLDER');
      }
    },
    [trackFeature]
  );

  const afterNavigated = useCallback(
    (parent: PWParentType): void => {
      trackNavigationFeature(parent);
      setCachedParent(parent);
      setInfoPanelDisplayed(false);
      setShowDropzone(false);
    },
    [
      setCachedParent,
      setInfoPanelDisplayed,
      setShowDropzone,
      trackNavigationFeature
    ]
  );

  const folderIsConnectionRoot = useCallback(
    (parent: PWParentType): boolean => {
      return connection.ProjectId === parent.instanceId;
    },
    [connection.ProjectId]
  );

  const navigateTo = useCallback(
    (parent: PWParentType, rebuildBreadcrumbs = false): void => {
      if (!itemIsParentType(parent)) {
        return;
      }

      if (rebuildBreadcrumbs) {
        void setBreadcrumbsToItem(parent);
      } else if (
        !breadcrumbs.find((bc) => bc.instanceId == parent.instanceId)
      ) {
        addBreadcrumb(parent);
      }

      if (parent.instanceId) {
        navigate?.(getLocation(connection, parent));
      }

      setCurrentParent(
        folderIsConnectionRoot(parent) ? connectionBreadcrumb : parent
      );
      afterNavigated(parent);
    },
    [
      addBreadcrumb,
      afterNavigated,
      breadcrumbs,
      connection,
      connectionBreadcrumb,
      folderIsConnectionRoot,
      navigate,
      setBreadcrumbsToItem
    ]
  );

  const refreshNavigation = useCallback(async (): Promise<void> => {
    const refreshedParent = itemIsFlatSet(currentParent)
      ? await getFlatSet({
          instanceId: currentParent.instanceId,
          httpService,
          selectRelationships: { parent: true },
          requestOptions: { uncached: true }
        })
      : await getProject({
          instanceId: currentParent.instanceId,
          httpService,
          selectRelationships: { parent: true },
          requestOptions: { uncached: true }
        });

    navigateTo(refreshedParent, true);
  }, [currentParent, httpService, navigateTo]);

  const allowNavigateToParent = useCallback((): boolean => {
    if (breadcrumbs.length <= 1) {
      return false;
    }

    if (currentBreadcrumb.instanceId == breadcrumbs[0].instanceId) {
      return false;
    }

    if (currentBreadcrumb.instanceId == connection.ProjectId) {
      return false;
    }

    return true;
  }, [breadcrumbs, currentBreadcrumb.instanceId, connection.ProjectId]);

  const navigateToParent = useCallback((): void => {
    if (!allowNavigateToParent()) {
      return;
    }

    const parentId = currentBreadcrumb.ParentGuid;
    const parentBreadCrumb = breadcrumbs.find(
      (breadcrumb) => breadcrumb.instanceId == parentId
    );

    if (parentBreadCrumb) {
      jumpTo(parentId)();
      navigateTo(parentBreadCrumb);
    }
  }, [
    allowNavigateToParent,
    breadcrumbs,
    currentBreadcrumb.ParentGuid,
    jumpTo,
    navigateTo
  ]);

  const navigateToRoot = useCallback((): void => {
    navigateTo(connectionBreadcrumb, true);
  }, [connectionBreadcrumb, navigateTo]);

  useEffect(() => {
    void httpService.cache?.clear();
    toaster.closeAll();
    progressManager.closeIPModal();

    if (selectionState.selectedItemId || selectionState.currentProjectId) {
      return;
    }

    const parentFromLink = new URLSearchParams(location.search).get('project');
    if (!parentFromLink) {
      navigateTo(cachedParent, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.Id]);

  useEffect(() => {
    if (
      currentParent.instanceId &&
      currentParent.instanceId == connectionBreadcrumb.instanceId &&
      currentParent != connectionBreadcrumb
    ) {
      // Update root project info with async details
      setCurrentParent(connectionBreadcrumb);
      return;
    }
  }, [connectionBreadcrumb, currentParent, selectionState]);

  const navigationManager = useMemo(
    (): NavigationManager => ({
      currentParent,
      linkError,
      navigateTo,
      navigateToParent,
      navigateToRoot,
      refreshNavigation,
      setLinkError
    }),
    [
      currentParent,
      linkError,
      navigateTo,
      navigateToParent,
      navigateToRoot,
      refreshNavigation
    ]
  );

  return navigationManager;
}
