import React, { useCallback, useEffect, useState } from 'react';
import {
  useAppViewContext,
  useConnectionAuth,
  useConnectionContext,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import { ProjectWiseErrorPage } from './projectWiseErrorPage';

export function usePWErrorPage(): JSX.Element | null {
  const { signOut } = useConnectionAuth();
  const {
    selectionState: { savedSearchId }
  } = useConnectionContext();
  const { connection } = usePluginContext();
  const {
    navigationManager: {
      linkError,
      navigateToRoot,
      setLinkError,
      currentParent
    }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();
  const {
    dataManager: { error: dataError, refresh: refreshData }
  } = useTableItemContext();

  const [errorPage, setErrorPage] = useState<JSX.Element | null>(null);

  const clearErrorPage = useCallback((): void => {
    setErrorPage(null);
    setLinkError(undefined);
  }, [setLinkError]);

  const clearLinkError = useCallback((): void => {
    navigateToRoot();
    clearErrorPage();
  }, [clearErrorPage, navigateToRoot]);

  const clearDataError = useCallback((): void => {
    setAppView('FolderNavigation');
    void refreshData();
  }, [refreshData, setAppView]);

  useEffect(() => {
    clearErrorPage();
  }, [
    connection.Id,
    savedSearchId,
    currentParent.instanceId,
    currentAppView,
    clearErrorPage
  ]);

  useEffect(() => {
    if (!linkError) {
      return;
    }

    const errorPage = (
      <ProjectWiseErrorPage
        clearErrorPage={clearErrorPage}
        error={linkError}
        buttonLabel={'Generic.ViewConnection'}
        buttonHandle={clearLinkError}
        signOut={signOut}
      />
    );
    setErrorPage(errorPage);
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * clearLinkError causes too many re-renders and will not clear error page
     */
  }, [linkError, signOut, clearErrorPage]);

  useEffect(() => {
    if (!dataError) {
      return;
    }

    const errorPage = (
      <ProjectWiseErrorPage
        clearErrorPage={clearErrorPage}
        error={dataError}
        buttonLabel={'Generic.Refresh'}
        buttonHandle={clearDataError}
        signOut={signOut}
      />
    );
    setErrorPage(errorPage);
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * clearDataError causes too many re-renders and will not clear error page
     */
  }, [dataError, signOut, clearErrorPage]);

  return errorPage;
}
