import React from 'react';
import { DCWSpinner } from '.';

export function DCWLoadingSpinner(): JSX.Element {
  return (
    <div className="dcw-wizard" data-testid="dcw-loading">
      <DCWSpinner />
    </div>
  );
}
