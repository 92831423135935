import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import { useEnvironmentContext } from '../../../../context';
import type {
  ColumnDisplayOptions,
  ColumnInfo
} from '../../../../hooks/useViews';
import { getItemProjectAttributes } from '../../../../services/itemProperties';
import { buildDropdownFilter } from '../../filter/dropdownFilter';
import { customPropertySortType } from '../../sort/columnSort';
import { lookupAccessor } from '../accessor';
import type { PropertyColumn } from '../typedColumn';
import { getColumnDataType } from '../typedColumn';
import { useBaseColumn } from '../useBaseColumn';

export function useProjectEnvironmentColumn(): PropertyColumn {
  const {
    environmentManager: { getEnvironmentProperty }
  } = useEnvironmentContext();

  const baseColumn = useBaseColumn();

  const baseProjectEnvironmentColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const { name, dataType, envLabel } = columnInfo;
      const columnDataType = getColumnDataType(dataType);

      const column = baseColumn(columnInfo, displayOptions);

      if (envLabel) {
        const property = getEnvironmentProperty(envLabel, name);
        if (property?.picklist) {
          column.Filter = buildDropdownFilter(
            property.picklist.map((p) => p.Value)
          );
        }
      }

      const lookupFunction = (row: PWItem) =>
        (getItemProjectAttributes(row)?.[name] as string) ?? '';

      return {
        ...column,
        accessor: lookupAccessor(lookupFunction, columnDataType),
        sortType: customPropertySortType(lookupFunction, columnDataType)
      } as Column<PWItem>;
    },
    [baseColumn, getEnvironmentProperty]
  );

  const projectEnvironmentColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions: ColumnDisplayOptions = {}
    ): Column<PWItem> => {
      return baseProjectEnvironmentColumn(columnInfo, displayOptions);
    },
    [baseProjectEnvironmentColumn]
  );

  return projectEnvironmentColumn;
}
