import { useCallback, useEffect } from 'react';
import { useEquivalentState } from '../../../hooks/useNonRenderingState';
import { t } from '../../../services/translation';
import { useConnectionsContext } from '../connections';
import type { SelectionState } from './types';

export function useSelectionState(
  pluginSelectionState?: SelectionState | string
): SelectionState {
  const {
    connection,
    connections,
    repositoryManager: { selectFederatedRepository },
    navigate,
    setConnectionError
  } = useConnectionsContext();

  const [sessionSelectionState, setSessionSelectionState] =
    useEquivalentState<SelectionState>({});

  const setActiveConnection = useCallback(
    (encodedSelectionState: string): void => {
      const { datasourceInstanceId, connectionProjectId } =
        decodeSelectionStateParameter(encodedSelectionState);
      if (
        connection?.DatasourceInstanceId == datasourceInstanceId &&
        connection?.ProjectId == connectionProjectId
      ) {
        return;
      }

      const repo = connections?.find((repo) => {
        const urlParts = repo.Url.split('/');
        let datasourceLocation = urlParts.indexOf('repositories');
        if (datasourceLocation == -1) {
          datasourceLocation = urlParts.indexOf('Repositories');
        }
        return (
          urlParts[datasourceLocation + 1].toLowerCase() ==
            datasourceInstanceId.toLowerCase() &&
          repo.Url.includes(connectionProjectId ?? '')
        );
      });

      if (repo) {
        selectFederatedRepository(repo.Id);
        void navigate?.(`connection/${repo.Id}${location.search}`);
      } else {
        setConnectionError(t('ServerError.DatasourceNotFound'));
      }
    },
    [
      connection?.DatasourceInstanceId,
      connection?.ProjectId,
      connections,
      selectFederatedRepository,
      setConnectionError,
      navigate
    ]
  );

  useEffect(() => {
    function initializeSelectionState(): void {
      let builtState: SelectionState | null = null;

      if (typeof pluginSelectionState == 'string') {
        builtState = readSelectionStateFromParameter(pluginSelectionState);
        setActiveConnection(pluginSelectionState);
      } else if (pluginSelectionState) {
        builtState = pluginSelectionState;
      }

      if (connection?.ProjectId == builtState?.selectedItemId) {
        builtState = {};
      }

      if (builtState) {
        setSessionSelectionState(builtState);
      }
    }

    if (!sessionSelectionState.currentProjectId) {
      void initializeSelectionState();
    }
  }, [
    connection?.ProjectId,
    pluginSelectionState,
    sessionSelectionState,
    setActiveConnection,
    setSessionSelectionState
  ]);

  return sessionSelectionState;
}

function readSelectionStateFromParameter(
  encodedSelectionState: string
): SelectionState {
  const { selectedItemId, itemType } = decodeSelectionStateParameter(
    encodedSelectionState
  );

  const selectionState = {
    itemType,
    selectedItemId: itemType == 'saved-search' ? undefined : selectedItemId,
    savedSearchId: itemType == 'saved-search' ? selectedItemId : undefined
  } as SelectionState;

  return selectionState;
}

function decodeSelectionStateParameter(encodedSelectionState: string): {
  datasourceInstanceId: string;
  selectedItemId: string;
  itemType: string;
  connectionProjectId?: string;
} {
  const decodedBase64String = base64DecodeUrl(encodedSelectionState);
  const plainTextUrl = window.atob(decodedBase64String);
  const [datasourceInstanceId, selectedItemId, itemType, connectionProjectId] =
    plainTextUrl.split('/');
  return {
    datasourceInstanceId,
    selectedItemId,
    itemType,
    connectionProjectId
  };
}

function base64DecodeUrl(str: string): string {
  return str.replace(/-/g, '+').replace(/_/g, '/');
}
