import { useCallback } from 'react';
import { useEnvironmentContext } from '../../../context';
import { getSearchColumnNames } from './searchColumns';

type ColumnFilterStringFunction = (searchString: string) => string;

export function useColumnFilterString(): ColumnFilterStringFunction {
  const { ecPluginFeatures, getDocumentPropertyPicklist } =
    useEnvironmentContext();

  const picklistMatches = useCallback(
    (property: string, searchString: string): string[] => {
      const picklist = getDocumentPropertyPicklist?.(property);
      if (!picklist) {
        return [];
      }

      const matches = picklist
        .filter(({ Value }) =>
          Value.toLowerCase().includes(searchString.toLowerCase())
        )
        .map((match) => match.Value);
      return matches;
    },
    [getDocumentPropertyPicklist]
  );

  const picklistPropertyQuery = useCallback(
    (property: string, searchString: string): string | undefined => {
      if (basicSearchPicklistBlacklistProperties.includes(property)) {
        return;
      }

      const matches = picklistMatches(property, searchString);
      if (!matches.length) {
        return;
      }

      const query = matches
        .map((option) => `${property} eq '${option}'`)
        .join(' or ');

      if (query.length > 50) {
        return;
      }

      return query;
    },
    [picklistMatches]
  );

  const propertyQuery = useCallback(
    (property: string, searchString: string): string => {
      const picklistQuery = picklistPropertyQuery(property, searchString);
      if (picklistQuery) {
        return picklistQuery;
      }

      return `${property} like '${searchString}'`;
    },
    [picklistPropertyQuery]
  );

  const columnFilterString = useCallback(
    (searchString: string): string => {
      const searchColumns = ecPluginFeatures.apiAncestorBug() ? [] : [];
      const columnNames = getSearchColumnNames(searchColumns);

      const propertyQueries = columnNames
        .map((column) => propertyQuery(column, searchString))
        .join(' or ');

      return `(${propertyQueries})`;
    },
    [ecPluginFeatures, propertyQuery]
  );

  return columnFilterString;
}

const basicSearchPicklistBlacklistProperties: string[] = [
  'CreatedBy',
  'FileUpdatedBy',
  'FinalBy',
  'OutTo',
  'Owner',
  'UpdatedBy'
] as const;
