import { useCallback, useMemo } from 'react';
import { useConnectionAuth, usePluginContext } from '../../../context';
import { formattedDisplayLabel } from '../../useProperties/formatDisplayLabel';
import { defaultView } from '../defaultView';
import { usePRDSSViews } from '../prdssViews/usePRDSSViews';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function useCustomView(
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const { connection } = usePluginContext();
  const { userName } = useConnectionAuth();

  const userCustomViewsSetting = usePRDSSViews(
    `customView-${connection.Id}-${userName}`,
    'User'
  );

  if (userCustomViewsSetting.views?.[0]?.columns) {
    userCustomViewsSetting.views[0].columns
      .filter((c) => c)
      .forEach(
        (c) => (c.displayLabel = formattedDisplayLabel(c.displayLabel ?? ''))
      );
  }

  const saveView = useCallback(
    (view: View): void => {
      const newViews = [view];
      userCustomViewsSetting.setViews(newViews);
      setCurrentViewType('Custom');
    },
    [setCurrentViewType, userCustomViewsSetting]
  );

  const setView = useCallback(
    (view?: View): void => {
      setCurrentViewType('Custom');
    },
    [setCurrentViewType]
  );

  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit custom view not supported. Use save view');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete custom view not supported. Use save view');
  }, []);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized: userCustomViewsSetting.views != undefined,
      view: userCustomViewsSetting.views?.[0] ?? defaultView,
      views: userCustomViewsSetting.views ?? [defaultView],
      saveView,
      setView,
      editView,
      deleteView
    }),
    [deleteView, editView, saveView, setView, userCustomViewsSetting]
  );

  return viewSetting;
}
