import { useEffect, useMemo, useState } from 'react';
import {
  useEnvironmentContext,
  usePluginContext,
  useTelemetry
} from '../../context';
import { useLocalStorage } from '../useStorage';
import type { EnvironmentProperty } from './environmentProperty';
import { usePropertyRequests } from './usePropertyRequests';

export type PropertyManager = {
  initialized: boolean;
  documentProperties: EnvironmentProperty[];
  folderProperties: EnvironmentProperty[];
};

export function useProperties(): PropertyManager {
  const { connection } = usePluginContext();
  const { getDocumentPropertyPicklist, ecPluginVersion } =
    useEnvironmentContext();
  const { startTelemetry, endTelemetry } = useTelemetry();

  const [propertyDefinitions, setPropertyDefinitions] = useLocalStorage<{
    folderProperties: EnvironmentProperty[];
    documentProperties: EnvironmentProperty[];
  }>(`propertyDefinitions:${connection.Id}:${String(ecPluginVersion)}`, {
    folderProperties: [],
    documentProperties: []
  });

  const [initialized, setInitialized] = useState<boolean>(
    propertyDefinitions.folderProperties.length > 0 &&
      propertyDefinitions.folderProperties.length > 0
  );

  const { getDocumentProperties, getFolderProperties } = usePropertyRequests();

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeProperties(): Promise<void> {
      startTelemetry('PropertyDataLoad');

      const [documentProperties, folderProperties] = await Promise.all([
        getDocumentProperties({ abortController, cacheSuffix: 'persistent' }),
        getFolderProperties({ abortController, cacheSuffix: 'persistent' })
      ]);
      if (!abortController.signal.aborted) {
        setPropertyDefinitions({ folderProperties, documentProperties });
        endTelemetry('PropertyDataLoad');
        setInitialized(true);
      }
    }

    if (!initialized) {
      void initializeProperties();
    }

    return () => {
      abortController.abort();
    };
  }, [
    initialized,
    setPropertyDefinitions,
    getDocumentProperties,
    getFolderProperties,
    startTelemetry,
    endTelemetry
  ]);

  useEffect(() => {
    propertyDefinitions.documentProperties.forEach(
      (property) =>
        (property.picklistOptions = getDocumentPropertyPicklist(property.name))
    );
  }, [propertyDefinitions.documentProperties, getDocumentPropertyPicklist]);

  useEffect(() => {
    propertyDefinitions.folderProperties.forEach(
      (property) =>
        (property.picklistOptions = getDocumentPropertyPicklist(property.name))
    );
  }, [propertyDefinitions.folderProperties, getDocumentPropertyPicklist]);

  const propertyManager = useMemo(
    (): PropertyManager => ({
      initialized,
      documentProperties: propertyDefinitions.documentProperties,
      folderProperties: propertyDefinitions.folderProperties
    }),
    [propertyDefinitions, initialized]
  );

  return propertyManager;
}
