import type { MutableRefObject } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigationContext } from '../../context';
import type { CloseModal, ModalElement, OpenModal } from '../../hooks/useModal';
import {
  showCoAuthoringModal,
  showEndingCoAuthoringSessionModal,
  showPreparingForCoAuthoringModal,
  showUpdatingServerCopyModal
} from './O365CoauthoringModal';

export type CoAuthoringModal = {
  open: OpenModal;
  close: CloseModal;
  modalIsOpen: MutableRefObject<boolean>;
  showPreparingSession: ModalFunc<typeof showPreparingForCoAuthoringModal>;
  showEndingSession: ModalFunc<typeof showEndingCoAuthoringSessionModal>;
  showUpdatingSession: ModalFunc<typeof showUpdatingServerCopyModal>;
  showPrimary: ModalFunc<typeof showCoAuthoringModal>;
  showSecondary: (
    message: JSX.Element,
    title: string,
    openOption?: 'desktop' | 'web'
  ) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func = (...args: any[]) => unknown;
type ModalFuncArgs<T extends Func> = DropFirstTwo<Parameters<T>>;
type ModalFunc<T extends Func> = (...args: ModalFuncArgs<T>) => void;

type DropFirstTwo<T extends unknown[]> = T extends [
  unknown,
  unknown,
  ...infer U
]
  ? U
  : never;

export const useCoAuthoringModal = (): CoAuthoringModal => {
  const { primaryModal } = useNavigationContext();
  const modalIsOpen = useRef(false);

  const open = useCallback(
    (modal: ModalElement) => {
      if (modal) {
        primaryModal.open(modal);
        modalIsOpen.current = true;
      }
    },
    [primaryModal]
  );

  const close = useCallback(() => {
    primaryModal.close();
    modalIsOpen.current = false;
  }, [primaryModal]);

  const showPreparingSession = useCallback(
    (...args: ModalFuncArgs<typeof showPreparingForCoAuthoringModal>) =>
      showPreparingForCoAuthoringModal(open, close, ...args),
    [close, open]
  );

  const showEndingSession = useCallback(
    (...args: ModalFuncArgs<typeof showEndingCoAuthoringSessionModal>) =>
      showEndingCoAuthoringSessionModal(open, close, ...args),
    [close, open]
  );

  const showUpdatingSession = useCallback(
    (...args: ModalFuncArgs<typeof showUpdatingServerCopyModal>) =>
      showUpdatingServerCopyModal(open, close, ...args),
    [close, open]
  );

  const showPrimary = useCallback(
    (...args: ModalFuncArgs<typeof showCoAuthoringModal>) =>
      showCoAuthoringModal(open, close, ...args),
    [close, open]
  );

  const showSecondary = useCallback(
    (message: JSX.Element, title: string, openOption?: 'desktop' | 'web') => {
      if (modalIsOpen.current) {
        showCoAuthoringModal(
          open,
          close,
          undefined,
          message,
          title,
          undefined,
          undefined,
          false,
          true,
          openOption
        );
      }
    },
    [close, open]
  );

  return useMemo(
    () => ({
      open,
      close,
      modalIsOpen,
      showPreparingSession,
      showEndingSession,
      showUpdatingSession,
      showPrimary,
      showSecondary
    }),
    [
      open,
      close,
      showPreparingSession,
      showEndingSession,
      showUpdatingSession,
      showPrimary,
      showSecondary
    ]
  );
};
