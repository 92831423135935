import React, { useCallback, useEffect, useState } from 'react';
import type { PWProject, RequestOptions } from '@bentley/pw-api';
import { byDefaultOrder, getProjects } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import { LoadingNode } from '../folderExplorer/loadingNode/loadingNode';
import { FolderSelectNode } from './folderSelectNode';

type FolderSelectChildrenNodeProps = {
  breadcrumbPath: string[];
  parent: PWProject;
  selectedFolder: PWProject;
  appendBreadcrumb: (path: string[]) => void;
  closeDropdown: () => void;
  onFolderSelect: (folder: PWProject) => void;
  setHasChildren: (hasChildren: boolean) => void;
};

export function FolderSelectChildrenNode({
  breadcrumbPath,
  parent,
  selectedFolder,
  appendBreadcrumb,
  closeDropdown,
  onFolderSelect,
  setHasChildren
}: FolderSelectChildrenNodeProps): JSX.Element {
  const httpService = useHttpService();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [children, setChildren] = useState<PWProject[]>([]);

  const loadChildren = useCallback(
    async (requestOptions?: RequestOptions): Promise<void> => {
      try {
        const children = await getProjects({
          parentId: parent.instanceId,
          httpService,
          requestOptions
        });
        children.sort(byDefaultOrder);

        if (!requestOptions?.abortController?.signal.aborted) {
          setChildren(children);
          setHasChildren(children.length > 0);
        }
      } catch {
        if (!requestOptions?.abortController?.signal.aborted) {
          setChildren([]);
          setHasChildren(false);
        }
      } finally {
        setLoaded(true);
      }
    },
    [httpService, setHasChildren, parent]
  );

  useEffect(() => {
    const abortController = new AbortController();
    void loadChildren({ abortController });

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="fsd-children"
      data-testid={`folder-select-children-${parent.Name}`}
    >
      {loaded ? (
        children.map((folder) => (
          <FolderSelectNode
            breadcrumbPath={breadcrumbPath}
            appendBreadcrumb={appendBreadcrumb}
            folder={folder}
            selectedFolder={selectedFolder}
            closeDropdown={closeDropdown}
            onFolderSelect={onFolderSelect}
            key={folder.instanceId}
          />
        ))
      ) : (
        <span className="fsd-loading-node">
          <LoadingNode nodeType="folder" />
        </span>
      )}
    </div>
  );
}
