import { useCallback, useMemo } from 'react';
import type { SavedAdvancedQuery } from '../../../components/advancedSearch';
import { useNavigationContext } from '../../../context';
import type { AdvancedSearch } from '../../../hooks/useSearchState';

type SearchActions = {
  onEdit: (
    newSearch: AdvancedSearch,
    search: SavedAdvancedQuery,
    multiConnectionSearch?: boolean | undefined,
    name?: string | undefined,
    json?: string | undefined
  ) => void;
  onCopy: (
    newSearch: AdvancedSearch,
    multiConnectionSearch?: boolean | undefined,
    name?: string | undefined,
    json?: string | undefined
  ) => void;
  onDelete: (searchName: string) => void;
};

export function useSearchActions(): SearchActions {
  const {
    searchState: {
      myConnectionSearches = [],
      setMyConnectionSearches,
      myGlobalSearches = [],
      setMyGlobalSearches
    }
  } = useNavigationContext();

  const onEdit = useCallback(
    (
      newSearch: AdvancedSearch,
      search: SavedAdvancedQuery,
      multiConnectionSearch?: boolean,
      name?: string,
      json?: string
    ): void => {
      if (!name || !json) {
        return;
      }

      const editedSearch = {
        query: newSearch,
        name: name,
        jsonSession: json
      } as SavedAdvancedQuery;

      const globalSearches = myGlobalSearches.filter(
        ({ name }) => name != search.name
      );

      const connectionSearches = myConnectionSearches.filter(
        ({ name }) => name != search.name
      );

      if (multiConnectionSearch) {
        setMyGlobalSearches([...globalSearches, editedSearch]);
        setMyConnectionSearches([...connectionSearches]);
      } else {
        setMyGlobalSearches([...globalSearches]);
        setMyConnectionSearches([...connectionSearches, editedSearch]);
      }
    },
    [
      myConnectionSearches,
      myGlobalSearches,
      setMyConnectionSearches,
      setMyGlobalSearches
    ]
  );

  const onCopy = useCallback(
    (
      newSearch: AdvancedSearch,
      multiConnectionSearch?: boolean,
      name?: string,
      json?: string
    ): void => {
      if (!name || !json) {
        return;
      }

      const copiedSearch = {
        name: name,
        query: newSearch,
        jsonSession: json
      } as SavedAdvancedQuery;

      if (multiConnectionSearch) {
        setMyGlobalSearches([...myGlobalSearches, copiedSearch]);
        return;
      }

      setMyConnectionSearches([...myConnectionSearches, copiedSearch]);
    },
    [
      myConnectionSearches,
      myGlobalSearches,
      setMyConnectionSearches,
      setMyGlobalSearches
    ]
  );

  const onDelete = useCallback(
    (searchName: string): void => {
      setMyGlobalSearches([
        ...myGlobalSearches.filter(({ name }) => name != searchName)
      ]);
      setMyConnectionSearches([
        ...myConnectionSearches.filter(({ name }) => name != searchName)
      ]);
    },
    [
      myConnectionSearches,
      myGlobalSearches,
      setMyConnectionSearches,
      setMyGlobalSearches
    ]
  );

  const searchActions = useMemo(
    () => ({
      onEdit,
      onCopy,
      onDelete
    }),
    [onCopy, onDelete, onEdit]
  );

  return searchActions;
}
