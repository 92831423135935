import { parseFileExtension } from '@bentley/pw-api';

/*
 * Gets the updated file or document name
 * If useOldName and file extensions are different, then append newFileExtension onto oldName
 */
export function getUpdatedName(
  useOldName: boolean,
  oldName: string,
  newName: string,
  isDocumentName?: boolean
): string {
  if (!useOldName) {
    return newName;
  }

  const oldExtension = parseFileExtension(oldName);
  const newExtension = parseFileExtension(newName);

  const oldFileExtension = oldExtension ? `.${oldExtension}` : '';
  const newFileExtension = newExtension ? `.${newExtension}` : '';

  // Don't append newFileExtension onto oldName if both file extension are the same
  if (
    (!oldFileExtension && isDocumentName) ||
    oldFileExtension === newFileExtension
  ) {
    return oldName;
  }

  return oldName + newFileExtension;
}
