import type { IForm } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormsSchema';

export function parseBindingsFromFormDefinition(
  formDefinition: string
): { environment: string; attribute: string }[] {
  const formDefinitionJson = JSON.parse(formDefinition) as { Form: IForm };

  const dataBindings = formDefinitionJson.Form.DataBindings.map(
    (dataBinding) => {
      const binding = dataBinding.DataBinding.Binding;
      const [environment, attribute] = binding.split('.');
      return { environment, attribute };
    }
  );

  return dataBindings;
}
