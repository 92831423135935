import React, { useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { pwConstants } from '@bentley/pw-api';
import { LabeledInput, StatusMessage } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext
} from '../../context';
import { t } from '../../services/translation';
import { sendUploadNotification, uploadNewFolderWorkflow } from '.';

type NewFolderDialogProps = {
  siblingFolders: PWProject[];
  parentId: string;
  onFolderComplete?: (response: Response) => void | Promise<void>;
  onClose?: () => void;
};

export function NewFolderDialog({
  siblingFolders,
  parentId,
  onFolderComplete,
  onClose
}: NewFolderDialogProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();
  const httpService = useHttpService();
  const { primaryModal } = useNavigationContext();

  const [projectName, setProjectName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  function isSaveDisabled(): boolean {
    return (
      !projectName ||
      projectName.trim().length < 1 ||
      namingConflict() ||
      nameTooLongConflict() ||
      descriptionTooLongConflict()
    );
  }

  function namingConflict(): boolean {
    return siblingFolders
      .map((folder) => folder.Name.toLowerCase().trim())
      .includes(projectName.toLowerCase().trim());
  }

  function nameTooLongConflict(): boolean {
    return projectName.length > pwConstants.maxFolderNameLength;
  }

  function descriptionTooLongConflict(): boolean {
    return description.length > pwConstants.maxDescriptionLength;
  }

  function nameIllegalSymbolConflict(): boolean {
    const regExp = new RegExp(/[<>|:"?/\\*]+|\.$/);
    return regExp.test(projectName);
  }

  function closeModal(): void {
    if (onClose) {
      onClose();
    } else {
      primaryModal.close();
    }
  }

  async function onSaveButton(): Promise<void> {
    closeModal();
    trackFeature('UPLOAD_CREATE_FOLDER_START');

    const response = await uploadNewFolderWorkflow(
      projectName,
      description,
      parentId,
      httpService,
      trackFeature
    );

    sendUploadNotification(response, projectName, 'Folder');
    await onFolderComplete?.(response);
  }

  return (
    <PWModal
      title={t('Upload.CreateFolder')}
      primaryButton={{
        title: t('Generic.Create'),
        onClick: () => void onSaveButton(),
        disabled: isSaveDisabled(),
        'data-testid': 'create-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: closeModal
      }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'NewFolderDialog' }}
    >
      <LabeledInput
        label={t('Generic.Name')}
        autoFocus={true}
        value={projectName}
        placeholder={t('Generic.Required')}
        status={
          namingConflict() || nameTooLongConflict()
            ? 'negative'
            : nameIllegalSymbolConflict()
            ? 'warning'
            : undefined
        }
        onChange={(event) => setProjectName(event.target.value)}
        data-testid="FolderName"
        message={
          <StatusMessage
            status={
              namingConflict() || nameTooLongConflict()
                ? 'negative'
                : nameIllegalSymbolConflict()
                ? 'warning'
                : undefined
            }
          >
            {namingConflict()
              ? t('Upload.FolderNameAlreadyExists')
              : nameTooLongConflict()
              ? `${t('Generic.ContentLimitedTo', {
                  count: pwConstants.maxFolderNameLength,
                  excess: projectName.length - pwConstants.maxFolderNameLength
                })}`
              : nameIllegalSymbolConflict()
              ? t('Upload.FolderNameContainsSpecialCharacters')
              : undefined}
          </StatusMessage>
        }
      />
      <LabeledInput
        label={t('Generic.Description')}
        value={description}
        placeholder={t('Generic.Optional')}
        status={descriptionTooLongConflict() ? 'negative' : undefined}
        onChange={(event) => setDescription(event.target.value)}
        data-testid="FolderDescription"
        message={
          <StatusMessage
            status={descriptionTooLongConflict() ? 'negative' : undefined}
          >
            {descriptionTooLongConflict()
              ? `${t('Generic.ContentLimitedTo', {
                  count: pwConstants.maxDescriptionLength,
                  excess: description.length - pwConstants.maxDescriptionLength
                })}`
              : undefined}
          </StatusMessage>
        }
      />
    </PWModal>
  );
}
