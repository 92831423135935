import type { HttpService, RequestOptions } from '@bentley/pw-api';
import { parseResponseRelationshipInstances } from '@bentley/pw-api';
import type { ColumnInfo } from '../defaultView';
import { parseColumns } from './instanceToFolderView';
import type { Column } from './view';

export async function getPWEViewColumns(
  instanceId: string,
  httpService: HttpService,
  options?: RequestOptions
): Promise<ColumnInfo[]> {
  const query = buildQuery(instanceId);
  const response = await httpService.get(query, options);
  const columns = await parseCurrentColumns(response);

  return columns;
}

function buildQuery(instanceId: string): string {
  const baseQuery = 'PW_WSG/View';
  const filter = `$filter=$id+eq+'${instanceId}'`;
  const select = '$select=ViewColumn-forward-column.*';

  return `${baseQuery}/?${filter}&${select}`;
}

async function parseCurrentColumns(response: Response): Promise<ColumnInfo[]> {
  if (!response.ok) {
    throw new Error('Error fetching column data');
  }

  const columns = await parseResponseRelationshipInstances<Column>(response);
  const validColumns = parseColumns(columns);
  return validColumns;
}
