import type { ChangeEvent } from 'react';
import React, { useState } from 'react';
import { pwConstants } from '@bentley/pw-api';
import { LabeledInput, StatusMessage } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../context';
import type { AdvancedSearch } from '../../hooks/useSearchState';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';
import { SaveSelectTypeahead } from '../saveSelectTypeahead/saveSelectTypeahead';

interface IAdvancedSearchSaveModalProps {
  onClose: (
    updatedSearchesConn: SavedAdvancedQuery[] | undefined,
    updatedSearchesGlobal: SavedAdvancedQuery[] | undefined
  ) => void;
  queryToSave: AdvancedSearch;
  jsonSession: string;
}

export type SavedAdvancedQuery = {
  name: string;
  query: AdvancedSearch;
  jsonSession: string;
};

export default function AdvancedSearchSaveModal({
  onClose,
  queryToSave,
  jsonSession
}: IAdvancedSearchSaveModalProps): JSX.Element {
  const { searchState } = useNavigationContext();

  const [textValue, setTextValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [multiConnectionSearch, setMultiConnectionSearch] =
    useState<boolean>(false);

  function submitHandler(): void {
    let savedAdvancedConn = searchState.myConnectionSearches ?? [];
    let savedAdvancedGlobal = searchState.myGlobalSearches ?? [];
    if (errorMessage) {
      return;
    }
    if (
      savedAdvancedConn.some((item) => item.name == textValue) ||
      savedAdvancedGlobal.some((item) => item.name == textValue)
    ) {
      setErrorMessage(t('AdvSearch.NameInUse'));
      return;
    }

    const query = { name: textValue, query: queryToSave, jsonSession };
    if (multiConnectionSearch) {
      savedAdvancedGlobal = [...savedAdvancedGlobal, query];
    } else {
      savedAdvancedConn = [...savedAdvancedConn, query];
    }

    searchState.setMyConnectionSearches(savedAdvancedConn);
    searchState.setMyGlobalSearches(savedAdvancedGlobal);

    onClose(savedAdvancedConn, savedAdvancedGlobal);
  }

  function submitDisabled(): boolean {
    return (
      queryToSave.query.length <= 0 || textValue.length <= 0 || !!errorMessage
    );
  }

  function onAdvancedSearchNameChange(e: ChangeEvent<HTMLInputElement>): void {
    setTextValue(e.target.value);
    if (!e.target.value.trim()) {
      setErrorMessage(t('Generic.NameCannotBeEmpty'));
    } else {
      setErrorMessage('');
    }
  }

  return (
    <PWModal
      title={t('AdvSearch.SaveMyAdvSearch')}
      onClose={() =>
        onClose(searchState.myConnectionSearches, searchState.myGlobalSearches)
      }
      primaryButton={{
        title: t('Generic.Save'),
        onClick: submitHandler,
        disabled: submitDisabled()
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: () =>
          onClose(
            searchState.myConnectionSearches,
            searchState.myGlobalSearches
          )
      }}
    >
      <LabeledInput
        label={t('AdvSearch.MyAdvSearchName')}
        name="name"
        data-testid="advSearchText"
        autoFocus={true}
        value={textValue}
        onChange={onAdvancedSearchNameChange}
        status={errorMessage ? 'negative' : undefined}
        maxLength={pwConstants.maxFileNameLength}
        message={
          <StatusMessage status={errorMessage ? 'negative' : undefined}>
            {errorMessage}
          </StatusMessage>
        }
      />
      <SaveSelectTypeahead onChange={setMultiConnectionSearch} />
    </PWModal>
  );
}
