import './advancedSearchModal.css';

import _ from 'lodash';
import type { ChangeEvent } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { itemIsProject, pwConstants } from '@bentley/pw-api';
import { SvgCheckboxSelect } from '@itwin/itwinui-icons-react';
import type { SelectOption } from '@itwin/itwinui-react';
import {
  ComboBox,
  Dialog,
  Flex,
  IconButton,
  InputGrid,
  Label,
  LabeledInput,
  StatusMessage
} from '@itwin/itwinui-react';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePropertyContext
} from '../../context';
import { valueMustBeLimitedToList } from '../../hooks/useDocumentProperties';
import type { AttributePicklistValue } from '../../hooks/useEnvironment/environmentAttributes/attributeDefinitions';
import {
  getEnvironmentDescription,
  getEnvironmentLabel
} from '../../hooks/useEnvironment/environmentLabels/environmentLabels';
import type { CloseModal } from '../../hooks/useModal';
import type { EnvironmentProperty } from '../../hooks/useProperties';
import { byEnvironmentAndDisplayLabel } from '../../hooks/useProperties/sort';
import type { AdvancedSearch } from '../../hooks/useSearchState';
import { generateUUID } from '../../services/data';
import { t } from '../../services/translation';
import { FolderSelectDropdown } from '../folderSelectDropdown';
import { PWModal } from '../pwModal';
import { SaveSelectTypeahead } from '../saveSelectTypeahead/saveSelectTypeahead';
import type { AdvancedSearchGroup } from './advancedSearchRowProvider';
import { RowProvider } from './advancedSearchRowProvider';
import type { SavedAdvancedQuery } from './advancedSearchSaveModal';
import AdvancedSearchSaveModal from './advancedSearchSaveModal';
import { advancedSearchBlacklist } from './properties/blacklistProperties';
import { defaultValue } from './row/defaultValues';
import {
  defaultOperator,
  logicalDropdownOptions,
  operatorDropdownOptions,
  searchClassDropdownOptions
} from './row/dropdownOptions';
import {
  addLatestVersionSearchToQuery,
  buildQuery,
  updateRowToSearchOnId
} from './search/query';
import { useSessionSearch } from './useSessionSearch';

type SearchMode = 'Edit' | 'Copy' | undefined;

export type AdvancedSearchRowData = {
  id: string;
  value: string | number;
  properties: EnvironmentProperty[];
  operators: string[];
  selectedProperty: EnvironmentProperty;
  selectedOperator: string;
  selectedLogicalOperator: SelectOption<string>;
  logicalOperators: string[];
  isLogicalOperatorVisible: boolean;
  isCheckBoxChecked: boolean;
};

export type SerializedAdvancedSearch = {
  selectedClass: string;
  selectedEnvironment: SelectOption<string>;
  isAdvanceSearch: boolean;
  rowsData: AdvancedSearchRowData[];
  searchFolder: PWProject;
  groups: AdvancedSearchGroup[];
};

type CheckedRows = {
  id: string;
  index: number;
  isChecked: boolean;
};

type AdvancedSearchModalProps = {
  onClose: CloseModal;
  onSearch: (
    newSearch: AdvancedSearch,
    multiConnectionSearch?: boolean,
    name?: string,
    json?: string
  ) => void;
  mode?: SearchMode;
  selectedSearch?: SavedAdvancedQuery;
  multiConnectionSearch?: boolean;
  takenNames?: string[];
};

const AdvancedSearchModal = ({
  onClose,
  onSearch,
  mode,
  selectedSearch,
  multiConnectionSearch = false,
  takenNames
}: AdvancedSearchModalProps): JSX.Element => {
  const {
    documentPropertiesInitialized,
    environmentManager,
    versions: { displayLatestVersion },
    ecPluginFeatures
  } = useEnvironmentContext();
  const { propertyManager } = usePropertyContext();

  const { loadAllEnvironmentProperties } = environmentManager;
  const {
    primaryModal,
    searchState,
    breadcrumbManager: { connectionBreadcrumb, breadcrumbs },
    navigationManager: { currentParent }
  } = useNavigationContext();

  // Constants
  const isEditing = mode != undefined;
  const guid = generateUUID();
  const documentClass = 'Document';
  const folderClass = 'Project';

  // State
  const defaultProperties = getPropertiesForClass('Documents');

  const [isIconVisible, setIconVisible] = useState<boolean>(true);

  const [environments, setEnvironments] = useState<EnvironmentProperty[]>([]);
  const [filteredEnvironments, setFilteredEnvironments] = useState<
    EnvironmentProperty[]
  >([]);
  const [initialEnvironments, setInitialEnvironments] = useState<
    SelectOption<string>[]
  >([]);
  const [environmentPropertiesLoaded, setEnvironmentPropertiesLoaded] =
    useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<CheckedRows[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [environmentLabel, setEnvironmentLabel] = useState<string>(
    t('AdvSearch.SearchEnvironment')
  );
  const [rowsData, setRowsData] = useState<AdvancedSearchRowData[]>([
    {
      id: guid,
      value: '',
      properties: defaultProperties,
      selectedProperty: defaultProperties[0],
      selectedOperator: '',
      operators: [],
      logicalOperators: logicalDropdownOptions().map((op) => op.label),
      selectedLogicalOperator: { value: '', label: '' },
      isLogicalOperatorVisible: false,
      isCheckBoxChecked: false
    }
  ]);

  const {
    searchCriteria: sessionRowsData,
    setSearchCriteria: setSessionRowsData,
    clearSearchCriteria: clearSessionRowsData,
    editingSearchCriteria,
    clearEditingSearchData
  } = useSessionSearch(
    defaultProperties,
    itemIsProject(currentParent) ? currentParent : connectionBreadcrumb,
    selectedSearch?.jsonSession
  );

  const [selectedEnvironment, setSelectedEnvironment] = useState<
    SelectOption<string>
  >({
    label: sessionRowsData.selectedEnvironment.label,
    value: sessionRowsData.selectedEnvironment.value
  });
  const [rowCount, setRowCount] = useState<number>(
    sessionRowsData.rowsData.length > 1 ? sessionRowsData.rowsData.length : 1
  );
  const [selectedClass, setSelectedClass] = useState<string>(
    sessionRowsData.selectedClass
  );
  const [searchFolder, setSearchFolder] = useState<PWProject>(
    sessionRowsData.searchFolder
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [multiConnection, setMultiConnection] = useState<boolean>(
    multiConnectionSearch
  );
  const [searchName, setSearchName] = useState<string>(
    selectedSearch?.name.substring(0, pwConstants.maxFileNameLength) ?? ''
  );
  const [nameError, setNameError] = useState<string>('');

  const ADVANCED_SEARCH_TITLE = t('Search.AdvancedSearch');
  const { trackFeature } = useFeatureTracking();

  function getGeneralPropertiesForSelectedClass(): EnvironmentProperty[] {
    return getPropertiesForClass(selectedClass);
  }

  function getPropertiesForClass(propertyClass: string) {
    const properties =
      propertyClass == 'Folders'
        ? propertyManager.folderProperties
        : propertyManager.documentProperties;

    const approvedProperties = properties.filter(
      (property) =>
        !advancedSearchBlacklist.some(
          (blacklistProperty) =>
            blacklistProperty.propertyName == property.name &&
            blacklistProperty.filterOutCondition(
              propertyClass == 'Folders' ? 'Project' : 'Document'
            )
        )
    );

    approvedProperties.sort((a, b) =>
      a.displayLabel < b.displayLabel ? -1 : 1
    );

    return approvedProperties;
  }

  function getEnvironmentPropertiesForSelectedClass(): EnvironmentProperty[] {
    const environmentProperties =
      selectedClass == 'Documents'
        ? environmentManager.attributeDefinitions
        : environmentManager.projectPropertyDefinitions;

    const approvedProperties = Object.values(environmentProperties)
      .flatMap((properties) => properties)
      .filter((property) => property.typeName != 'ECArray')
      .filter(
        (property) =>
          !advancedSearchBlacklist.some(
            (blacklistedProperty) =>
              blacklistedProperty.propertyName == property.name &&
              blacklistedProperty.filterOutCondition('Environment')
          )
      )
      .map(
        (property) =>
          ({
            name: property.name,
            displayLabel: property.label,
            dataType: property.typeName,
            propertyType: property.environmentClass,
            envLabel: getEnvironmentLabel(
              environmentManager.environmentDefinitions ?? [],
              property
            ),
            envDescription: getEnvironmentDescription(
              environmentManager.environmentDefinitions ?? [],
              property
            ),
            isProjectEnv: property.binding == '',
            picklistOptions: property.picklist
          } as EnvironmentProperty)
      )
      .sort(byEnvironmentAndDisplayLabel);

    return approvedProperties;
  }

  // Effects
  useEffect(() => {
    setIconVisible(selectedRows.length > 1 && consecutiveCheck(selectedRows));
  }, [selectedRows, selectedRows.length, sessionRowsData.groups]);

  useEffect(() => {
    async function initEnvironmentProperties(): Promise<void> {
      await loadAllEnvironmentProperties();
      setEnvironmentPropertiesLoaded(true);
    }

    void initEnvironmentProperties();
  }, [loadAllEnvironmentProperties]);

  useEffect(() => {
    if (
      !propertyManager.initialized ||
      !documentPropertiesInitialized ||
      !environmentPropertiesLoaded
    ) {
      return;
    }

    const generalProperties = getGeneralPropertiesForSelectedClass();
    const environmentProperties = getEnvironmentPropertiesForSelectedClass();

    setEnvironments(environmentProperties);

    if (sessionRowsData.isAdvanceSearch) {
      let filteredProps: EnvironmentProperty[] = [];
      filteredProps = environmentProperties.filter(
        (a) => a.envLabel == sessionRowsData.selectedEnvironment.label
      );
      setFilteredEnvironments([...filteredProps]);
      setRowsData([...sessionRowsData.rowsData]);
    } else {
      setRowsData([
        ...rowsData.map((a) => {
          return {
            ...a,
            properties: generalProperties.concat(environmentProperties),
            selectedProperty: {
              name: '',
              dataType: '',
              displayLabel: '',
              propertyType: '',
              envLabel: '',
              isProjectEnv: selectedClass == 'Folders'
            },
            selectedOperator: defaultOperator(generalProperties[0]?.dataType),
            operators: operatorDropdownOptions({
              dataType: generalProperties[0]?.dataType
            })
          };
        })
      ]);
    }

    let initialEnv: SelectOption<string>[] = environmentProperties.map(
      (property) => {
        return {
          label: property.envLabel,
          sublabel: property.envDescription,
          value: property.propertyType
        };
      }
    );
    initialEnv = _.uniqBy(initialEnv, 'value').sort((a, b) =>
      a.label < b.label ? -1 : 1
    );
    initialEnv = [
      { label: t('CustomColumns.GeneralProperties'), value: 'General' }
    ].concat(initialEnv);

    setInitialEnvironments(initialEnv);

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    propertyManager.initialized,
    selectedClass,
    documentPropertiesInitialized,
    environmentPropertiesLoaded
  ]);

  // Functions
  function editNameDupe(): boolean {
    if (isEditing && takenNames) {
      let namesToCheck = takenNames;
      if (mode == 'Edit') {
        namesToCheck = namesToCheck.filter(
          (item) => item != selectedSearch?.name
        );
      }
      return namesToCheck.includes(searchName);
    } else {
      return false;
    }
  }

  function onSave(): void {
    if (editNameDupe()) {
      setNameError(t('AdvSearch.SearchWithSameNameExists'));
      return;
    } else if (nameError) {
      return;
    }

    trackFeature('ADVANCED_SEARCH');

    if (isEditing) {
      setSessionRowsData(editingSearchCriteria);
    } else {
      clearEditingSearchData();
      setSessionRowsData({
        selectedClass,
        selectedEnvironment,
        isAdvanceSearch: true,
        rowsData,
        searchFolder,
        groups: sessionRowsData.groups
      } as SerializedAdvancedSearch);
    }

    rowsData.map(updateRowToSearchOnId);
    let query = buildQuery(rowsData, sessionRowsData.groups);

    query = addLatestVersionSearchToQuery(
      query,
      displayLatestVersion && selectedClass.toLowerCase() == 'documents'
    );

    onClose();
    const newSearch = {
      itemClass:
        selectedClass.toLowerCase() == 'documents'
          ? documentClass
          : folderClass,
      environment: calculateEnvironment(),
      query: query,
      ancestor: searchFolder
    } as AdvancedSearch;

    if (isEditing) {
      onSearch(
        newSearch,
        multiConnection,
        searchName,
        prepDataForSessionStorage()
      );
      clearSessionRowsData();
    } else {
      onSearch(newSearch);
    }
  }

  function onSearchNameChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchName(e.target.value);
    if (isEditing && !e.target.value.trim()) {
      setNameError(t('Generic.NameCannotBeEmpty'));
    } else {
      setNameError('');
    }
  }

  function prepDataForSessionStorage(): string {
    const filteredData = rowsData.map((row) => ({
      ...row,
      properties: []
    }));
    return JSON.stringify({
      selectedClass: selectedClass,
      selectedEnvironment: selectedEnvironment,
      isAdvanceSearch: true,
      searchFolder: searchFolder,
      rowsData: filteredData,
      groups: sessionRowsData.groups
    } as SerializedAdvancedSearch);
  }

  function closeHandler() {
    // Replacing the old search if one is present; otherwise unsaved changes remain
    if (!searchState.advancedSearch) {
      clearSessionRowsData();
    } else {
      setSessionRowsData(editingSearchCriteria);
    }
    clearEditingSearchData();
    onClose();
  }

  function calculateEnvironment(): string {
    const containsEnvironment = rowsData.find(
      ({ selectedProperty }) =>
        selectedProperty.propertyType != 'General' &&
        selectedProperty.propertyType != ''
    );

    if (containsEnvironment) {
      return selectedEnvironment.value;
    }

    return '';
  }

  function onAdd(id: string): void {
    const {
      dummyRow,
      guid
    }: { dummyRow: AdvancedSearchRowData; guid: string } = dummyRowProvider(
      defaultProperties,
      selectedClass
    );
    const index = rowsData.findIndex((a) => a.id == id);
    rowsData.splice(index + 1, 0, dummyRow);

    const checkedData: CheckedRows[] = rowsData
      .map((a, index: number) => {
        return { isChecked: a.isCheckBoxChecked, index: index, id: a.id };
      })
      .filter((b) => b.isChecked == true);

    setSessionRowsData({
      ...sessionRowsData,
      groups: adjustGroupsAdd(index, guid)
    });
    setErrorMessage('');
    setIconVisible(selectedRows.length > 1 && consecutiveCheck(checkedData));
    setRowsData([...rowsData]);
    setRowCount(rowsData.length);
  }

  function adjustGroupsAdd(index: number, id: string): AdvancedSearchGroup[] {
    return sessionRowsData.groups.map((a) => {
      if (index == a.startRow || (index > a.startRow && index < a.endRow)) {
        return {
          ...a,
          endRow: a.endRow + 1,
          selectedRowIDs: a.selectedRowIDs.concat(id)
        };
      } else if (
        (index > a.startRow && index > a.endRow) ||
        a.endRow == index
      ) {
        return {
          ...a,
          endRow: a.endRow
        };
      } else {
        return {
          ...a,
          endRow: a.endRow + 1,
          startRow: a.startRow + 1,
          selectedRowIDs: a.selectedRowIDs.concat(id)
        };
      }
    });
  }

  function onRemove(id: string, index: number): void {
    setErrorMessage('');
    setRowCount((rowCount) => rowCount - 1);
    if (rowCount > 1) {
      const rowIndex = rowsData.findIndex((a) => a.id == id);
      if (rowIndex !== -1) {
        rowsData.splice(rowIndex, 1);
        rowsData[0].selectedLogicalOperator = { value: '', label: '' };
        setRowsData([...rowsData]);
      }
      setRowCount(rowsData.length);
    } else {
      rowsInfo(rowsData);
      setRowsData([...rowsData]);
      setRowCount(rowsData.length);
    }
    const groupOnRemove: AdvancedSearchGroup[] = adjustGroupsRemove(
      index,
      id
    ).filter((a) => a.startRow != a.endRow);
    setSessionRowsData({ ...sessionRowsData, groups: groupOnRemove });
    setRowCount(rowsData.length);

    if (rowsData[index].isCheckBoxChecked) {
      onCheckBoxChange(id, index);
    }
  }

  function adjustGroupsRemove(
    index: number,
    id: string
  ): AdvancedSearchGroup[] {
    return sessionRowsData.groups.map((a) => {
      if (index == a.endRow || index == a.startRow) {
        return {
          ...a,
          endRow: a.endRow - 1,
          startRow: a.startRow,
          selectedRowIDs: a.selectedRowIDs.filter((e) => e != id)
        };
      } else if (index > a.startRow && index < a.endRow) {
        return {
          ...a,
          endRow: a.endRow - 1,
          startRow: a.startRow,
          selectedRowIDs: a.selectedRowIDs.filter((e) => e != id)
        };
      } else if (index < a.startRow) {
        return {
          ...a,
          endRow: a.endRow - 1,
          startRow: a.startRow - 1,
          selectedRowIDs: a.selectedRowIDs.filter((e) => e != id)
        };
      } else {
        return {
          ...a,
          endRow: a.endRow,
          startRow: a.startRow,
          selectedRowIDs: a.selectedRowIDs
        };
      }
    });
  }

  function availableGroups(index: number): AdvancedSearchGroup[] {
    const validGroups = sessionRowsData.groups.filter(
      (grouping) => grouping.startRow > index || grouping.endRow < index
    );
    return validGroups;
  }

  function onPropertiesChange(
    id: string,
    name: string,
    propertyType: string,
    envLabel: string,
    label: string,
    dataType: string,
    picklistOptions?: AttributePicklistValue[]
  ) {
    const data = rowsData.findIndex((obj) => obj.id == id);
    let filteredProps: EnvironmentProperty[] = [];
    if (propertyType != 'General' && propertyType != 'Folder') {
      filteredProps = environments.filter((a) => a.envLabel == envLabel);
      setFilteredEnvironments([...filteredProps]);
      setSelectedEnvironment({ label: envLabel, value: propertyType });
    } else {
      setFilteredEnvironments(
        environments.filter((s) => s.envLabel == selectedEnvironment.label)
      );
    }
    rowsData[data].operators = operatorDropdownOptions({
      dataType,
      picklistOptions
    });
    rowsData[data].selectedOperator = defaultOperator(dataType);
    rowsData[data].value = defaultValue(dataType);
    rowsData[data].selectedProperty = {
      name: name,
      dataType: dataType,
      displayLabel: label,
      propertyType: propertyType,
      envLabel: envLabel,
      isProjectEnv: selectedClass == 'Folders',
      picklistOptions: picklistOptions,
      isLimitedToList: valueMustBeLimitedToList(name)
    };

    setRowsData([...rowsData]);
  }

  function onOperatorsChange(id: string, text: string) {
    const data = rowsData.findIndex((obj) => obj.id == id);
    rowsData[data].selectedOperator = text;
    setRowsData([...rowsData]);
  }

  function onTextChange(id: string, text: string) {
    const data = rowsData.findIndex((obj) => obj.id == id);
    rowsData[data].value = text;
    setRowsData([...rowsData]);
  }
  function isNumeric(n: string) {
    return (
      !isNaN(parseInt(n)) && n.indexOf('.') != 1 && /^[+-]?\d+(\.\d+)?$/.test(n)
    );
  }

  function onNumericTextChange(id: string, text: number) {
    const data = rowsData.findIndex((obj) => obj.id == id);
    rowsData[data].value = isNumeric(text.toString()) ? text : '';
    setRowsData([...rowsData]);
  }

  function onLogicalOperatorChange(id: string, text: string) {
    const selectedLogicalOperator = logicalDropdownOptions().find(
      ({ label }) => label == text
    );
    if (!selectedLogicalOperator) {
      throw new Error('Invalid logical operator');
    }
    const data = rowsData.findIndex((obj) => obj.id == id);
    rowsData[data].selectedLogicalOperator = selectedLogicalOperator;
    setRowsData([...rowsData]);
  }

  function consecutiveCheck(rows: CheckedRows[]) {
    const actualSum = rows.reduce((a, b) => a + b.index, 0);
    const expectedSum =
      (rows.length / 2) *
      (2 * Math.min(...rows.map((a) => a.index)) + (rows.length - 1));
    return actualSum == expectedSum;
  }

  function onCheckBoxChange(id: string, index: number) {
    if (rowsData[index].isCheckBoxChecked) {
      const validGroups = availableGroups(index);
      if (validGroups.length) {
        setSessionRowsData({ ...sessionRowsData, groups: validGroups });
      }
      setSelectedRows((prevState) => prevState.filter((a) => a.id != id));
    } else {
      setSelectedRows((prevState) =>
        prevState.concat({ isChecked: true, index: index, id: id })
      );
    }

    rowsData[index].isCheckBoxChecked = !rowsData[index].isCheckBoxChecked;
    setRowsData([...rowsData]);
    setErrorMessage('');
  }

  function onGroupByClick() {
    const maxGroupId = Math.max(...selectedRows.map((a) => a.index));
    const minGroupId = Math.min(...selectedRows.map((a) => a.index));

    let flattened: string[] = [];
    if (sessionRowsData.groups.length) {
      flattened = sessionRowsData.groups
        .map((obj) => obj.selectedRowIDs)
        .reduce(function (accumulator, item) {
          return accumulator.concat(item);
        });
    }

    let hasError = false;

    // Check if any of the checked value exists in flattened array
    if (
      flattened.some(
        (val) => selectedRows.map((obj) => obj.id).indexOf(val) != -1
      )
    ) {
      setErrorMessage(t('AdvancedSearch.GroupAlreadyExists'));
      hasError = true;
    }

    setRowsData(rowsData.map((a) => ({ ...a, isCheckBoxChecked: false })));
    if (!hasError) {
      const numGroups = sessionRowsData.groups.length;
      const nextId = numGroups
        ? sessionRowsData.groups[numGroups - 1].id + 1
        : 1;

      const newGroup = {
        depthLevel: 1,
        id: nextId,
        endRow: maxGroupId,
        startRow: minGroupId,
        selectedRowIDs: selectedRows.map((row) => row.id)
      } as AdvancedSearchGroup;

      setSessionRowsData({
        ...sessionRowsData,
        groups: [...sessionRowsData.groups, newGroup]
      });
    }
    //  setSearchVisible(false);
    setSelectedRows([]);
  }

  function removeGroup(groupId: number) {
    const newGroups = sessionRowsData.groups.filter(
      (group) => group.id != groupId
    );
    setSessionRowsData({ ...sessionRowsData, groups: newGroups });
    setErrorMessage('');
  }

  function onSelectOptionChange(optionValue: string) {
    const data = rowsData.slice(0, 1);
    rowsInfo(data);
    setRowsData([...data]);
    setSessionRowsData({ ...sessionRowsData, rowsData: data, groups: [] });
    setSelectedClass(optionValue);
    setRowCount(1);
    setSelectedEnvironment({
      label: t('CustomColumns.GeneralProperties'),
      value: 'General'
    });
    if (optionValue === 'Folders') {
      setEnvironmentLabel(t('AdvSearch.WorkAreaType'));
    } else {
      setEnvironmentLabel(t('AdvSearch.SearchEnvironment'));
    }
  }

  function onEnvironmentChange(value: string) {
    const generalPropsLabel = t('CustomColumns.GeneralProperties');
    const label =
      environments.find(({ propertyType }) => propertyType == value)
        ?.envLabel ?? generalPropsLabel;

    setSelectedEnvironment({ label, value });
    const filteredEnv =
      label == 'Any'
        ? environments
        : environments.filter((a) => a.envLabel == label);

    const filteredData = rowsData.filter(
      (a) =>
        a.selectedProperty.envLabel == generalPropsLabel ||
        a.selectedProperty.envLabel == '' ||
        a.selectedProperty.envLabel == label
    );

    if (filteredData.length == 0) {
      filteredData.push(
        dummyRowProvider(defaultProperties, selectedClass).dummyRow
      );
    }

    setRowsData([...filteredData]);
    setRowCount(filteredData.length);

    setSessionRowsData({ ...sessionRowsData, groups: [] });
    setFilteredEnvironments(filteredEnv);
  }

  const isInputValid = useMemo(() => {
    if (nameError) {
      return false;
    }

    return (
      rowsData.filter(
        (a) =>
          a.selectedOperator == '' &&
          a.value === '' &&
          a.selectedProperty?.displayLabel == ''
      ).length == rowCount ||
      rowsData.filter(
        (a) =>
          a.selectedOperator != '' &&
          a.value !== '' &&
          a.selectedProperty?.displayLabel != ''
      ).length == rowCount
    );
  }, [nameError, rowCount, rowsData]);

  const getEnvironments = useMemo((): SelectOption<string>[] => {
    return initialEnvironments.map((env) => {
      if (
        env.value == t('CustomColumns.GeneralProperties') &&
        env.label != 'General'
      ) {
        return {
          value: env.value,
          label: env.label,
          sublabel: t('Generic.Environment')
        };
      }
      return env;
    });
  }, [initialEnvironments]);

  function getProperties(): EnvironmentProperty[] {
    const generalProperties = getGeneralPropertiesForSelectedClass();

    if (selectedEnvironment.value == 'General') {
      return generalProperties;
    } else {
      return filteredEnvironments.concat(generalProperties);
    }
  }

  function hideEnvironment(): boolean {
    return !environments.length || !initialEnvironments.length;
  }

  function getRow(row: AdvancedSearchRowData, index: number) {
    return (
      <RowProvider
        onAdd={onAdd}
        onRemove={onRemove}
        isCheckBoxVisible={true}
        operators={row.operators}
        properties={getProperties()}
        id={row.id}
        onPropertiesChange={onPropertiesChange}
        onOperatorsChange={onOperatorsChange}
        selectedProperty={row.selectedProperty}
        onTextChange={onTextChange}
        key={row.id}
        textValue={row.value}
        logicalOperator={logicalDropdownOptions()}
        onLogicalOperatorChange={onLogicalOperatorChange}
        isLogicalOperatorVisible={rowCount > 1}
        isChecked={row.isCheckBoxChecked}
        onCheckBoxChange={onCheckBoxChange}
        index={index}
        groups={sessionRowsData.groups}
        onGroupRemove={removeGroup}
        selectedOperator={row.selectedOperator}
        onNumericTextChange={onNumericTextChange}
        selectedLogicalOperator={row.selectedLogicalOperator}
      />
    );
  }

  return (
    <PWModal
      title={ADVANCED_SEARCH_TITLE}
      size="l"
      isLoading={isLoading}
      loadingText={t('AdvSearch.LoadingEnvironments')}
      primaryButton={{
        title: isEditing ? t('Generic.Apply') : t('AdvSearch.Search'),
        disabled: !isInputValid || isLoading,
        onClick: onSave
      }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: closeHandler }}
      tertiaryButton={
        isEditing
          ? undefined
          : {
              title: t('AdvSearch.SaveSearch'),
              disabled: !isInputValid,
              onClick: () => {
                setSessionRowsData({
                  selectedClass,
                  selectedEnvironment,
                  isAdvanceSearch: true,
                  rowsData,
                  searchFolder,
                  groups: sessionRowsData.groups
                } as SerializedAdvancedSearch);
                primaryModal.open(
                  <AdvancedSearchSaveModal
                    onClose={(
                      newSavedSearchesConn: SavedAdvancedQuery[] | undefined,
                      newSavedSearchesGlobal: SavedAdvancedQuery[] | undefined
                    ) => {
                      trackFeature('SAVED_ADVANCED_SEARCH_CREATED');
                      onClose();
                      if (newSavedSearchesConn) {
                        searchState.setMyConnectionSearches(
                          newSavedSearchesConn
                        );
                      }
                      if (newSavedSearchesGlobal) {
                        searchState.setMyGlobalSearches(newSavedSearchesGlobal);
                      }
                      primaryModal.open(
                        <AdvancedSearchModal
                          onClose={onClose}
                          onSearch={onSearch}
                        />
                      );
                    }}
                    queryToSave={{
                      query: buildQuery(rowsData, sessionRowsData.groups),
                      itemClass:
                        selectedClass.toLowerCase() == 'documents'
                          ? documentClass
                          : folderClass,
                      environment: calculateEnvironment(),
                      ancestor: {
                        instanceId: searchFolder.instanceId
                      } as PWProject
                    }}
                    jsonSession={prepDataForSessionStorage()}
                  />
                );
              }
            }
      }
      onClose={closeHandler}
      dialogProps={{ 'data-testid': 'AdvancedSearchModal' }}
    >
      <Dialog.Content style={{ flexGrow: 0, flexShrink: 0 }}>
        <div className="advanced-search-header">
          <InputGrid className="search-for-column" data-testid="classType">
            <Label htmlFor="search-for-input">{t('AdvSearch.SearchFor')}</Label>
            <ComboBox<string>
              options={searchClassDropdownOptions()}
              value={selectedClass == '' ? undefined : selectedClass}
              inputProps={{ id: 'search-for-input' }}
              onChange={(option: string) => {
                onSelectOptionChange(option);
              }}
            />
          </InputGrid>
          {ecPluginFeatures.apiAncestor() && (
            <div className="search-folder-column" data-testid="parentFolder">
              <FolderSelectDropdown
                breadcrumbsToInitialSelectedFolder={breadcrumbs.map(
                  (breadcrumb) => breadcrumb.instanceId
                )}
                label={t('AdvSearch.SearchFolder')}
                rootFolder={connectionBreadcrumb}
                selectedFolder={searchFolder}
                onFolderSelect={setSearchFolder}
              />
            </div>
          )}
          {!hideEnvironment() && (
            <InputGrid
              className="environment-column"
              data-testid="environment-select"
            >
              <Label htmlFor="with-environment-input">{environmentLabel}</Label>
              <ComboBox<string>
                options={getEnvironments}
                value={selectedEnvironment.value}
                inputProps={{ id: 'with-environment-input' }}
                onChange={(value: string) => {
                  onEnvironmentChange(value);
                }}
              />
            </InputGrid>
          )}
        </div>
      </Dialog.Content>
      <Dialog.Content className="a-s-m-content">
        <div className="cls-table">
          <table
            cellPadding="0"
            cellSpacing="0"
            id="advTable"
            className="divTable"
          >
            <thead className="scrollTable">
              <tr hidden={rowsData.length == 0} className="tableHeader">
                <th className="row-op-btn"></th>
                <th className="row-op-btn"></th>
                <th className="row-op-btn">
                  <IconButton
                    onClick={onGroupByClick}
                    disabled={!isIconVisible}
                    styleType="borderless"
                    data-testid="group-button"
                  >
                    <SvgCheckboxSelect />
                  </IconButton>
                </th>
                <th className="cls-group-by"></th>
                <th className="logical-width"></th>
                <th>{t('AdvSearch.Field')}</th>
                <th>{t('AdvSearch.Operator')}</th>
                <th>{t('Generic.Value')}</th>
              </tr>
            </thead>
            <tbody>{rowsData.map((obj, index) => getRow(obj, index))}</tbody>
          </table>
        </div>
        {errorMessage && (
          <StatusMessage status="negative">{errorMessage}</StatusMessage>
        )}
      </Dialog.Content>
      <Dialog.Content style={{ flexGrow: 0, flexShrink: 0 }}>
        {isEditing && (
          <Flex flexDirection="column" alignItems="stretch">
            <LabeledInput
              value={searchName}
              label={t('AdvSearch.SearchName')}
              onChange={onSearchNameChange}
              status={nameError ? 'negative' : undefined}
              maxLength={pwConstants.maxFileNameLength}
              data-testid="s-a-search-name"
              message={
                <StatusMessage status={nameError ? 'negative' : undefined}>
                  {nameError}
                </StatusMessage>
              }
            />
            <SaveSelectTypeahead
              onChange={setMultiConnection}
              multiConnection={multiConnectionSearch}
            />
          </Flex>
        )}
      </Dialog.Content>
    </PWModal>
  );
};

export default AdvancedSearchModal;

function dummyRowProvider(
  properties: EnvironmentProperty[],
  selectedClass: string
) {
  const guid = generateUUID();
  // TODO: may need to update this to check what is in modal to determine the isProjectEnv value
  const dummyRow: AdvancedSearchRowData = {
    id: guid,
    value: '',
    properties: properties,
    operators: operatorDropdownOptions({ dataType: properties[0].dataType }),
    logicalOperators: logicalDropdownOptions().map((op) => op.label),
    selectedLogicalOperator: logicalDropdownOptions()[0],
    isLogicalOperatorVisible: true,
    selectedOperator: defaultOperator(properties[0].dataType),
    selectedProperty: {
      dataType: '',
      displayLabel: '',
      name: '',
      propertyType: '',
      envLabel: '',
      isProjectEnv: selectedClass == 'Folders'
    },
    isCheckBoxChecked: false
  };
  return { dummyRow, guid };
}

function rowsInfo(rowsData: AdvancedSearchRowData[]) {
  rowsData[0].selectedOperator = '';
  rowsData[0].selectedProperty.displayLabel = '';
  rowsData[0].selectedProperty.name = '';
  rowsData[0].selectedProperty.dataType = '';
  rowsData[0].value = '';
}
