import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useConnectionAuth } from '../../context';
import type { GetToken } from '../../services/http';
import type { Connection } from '../../services/support';
import { useDriveFeatureClient } from './featureClient/useDriveFeatureClient';
import type { DriveData, DriveUpdate, VersionInfo } from './pwDrive.utils';
import {
  findPWDriveInfo,
  getDriveMetaData,
  getDriveVersionInfo,
  getVersionTypeDriveApi
} from './pwDrive.utils';
import { useDriveSyncedIds } from './useDriveSyncedIds';

export type PWDriveManager = {
  driveDownloadUrl?: string;
  driveLatestVersion?: string;
  driveMessageText: string;
  driveSynced: string[];
  driveVersion: string;
  driveVersionType: DriveVersionType;
  httpDriveService: HttpService;
  isCurrentConnectionSynced: boolean;
  isCurrentProjectSynced: boolean;
  isDriveConnectionSynced: boolean;
  isDriveEnabledForConnection: boolean;
  pwDriveMetadataEnabled: boolean;
  pwDriveSyncDisabled: boolean;
  validDriveUser: ValidDriveUser;
  setPwDriveSyncDisabled: (status: boolean) => void;
  syncDrive: () => void;
};

export type ValidDriveUser =
  | 'UserMismatch'
  | 'SameUser'
  | 'UserNotLoggedInDrive'
  | 'LogicalUser';

export type DriveVersionType =
  | 'Obsolete'
  | 'Latest'
  | 'OldValidVersion'
  | 'InvalidVersion'
  | '';

export function usePWDrive(
  getOidcToken: GetToken,
  contextId: string | undefined,
  readOnly: boolean,
  connectionId: string,
  driveSyncedConnections: Connection[] | undefined
): PWDriveManager {
  const { userId, isLogicalUser } = useConnectionAuth();

  const driveFeatureClient = useDriveFeatureClient();
  const driveSynced = useDriveSyncedIds(driveSyncedConnections);

  const [isCurrentProjectSynced, setIsCurrentProjectSynced] =
    useState<boolean>(false);
  const [pwDriveSyncDisabled, setPwDriveSyncDisabled] = useState<boolean>(true);
  const [pwDriveMetadataEnabled, setPwDriveMetadataEnabled] =
    useState<boolean>(false);
  const [isCurrentConnectionSynced, setIsCurrentConnectionSynced] =
    useState<boolean>(false);
  const [driveVersion, setDriveVersion] = useState<string>('');
  const [driveMessageText, setDriveMessageText] = useState<string>('');
  const [validDriveUser, setValidDriveUser] =
    useState<ValidDriveUser>('SameUser');
  const [driveVersionType, setDriveVersionType] =
    useState<DriveVersionType>('');
  const [httpDriveService, setHttpDriveService] = useState<HttpService>(
    {} as HttpService
  );
  const [isDriveEnabledForConnection, setIsDriveEnabledForConnection] =
    useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const syncDrive = useCallback((): void => {
    setRefresh((cur) => !cur);
  }, []);

  const initDriveHttpService = useCallback(
    (port: number): HttpService => {
      const baseUrl = `http://localhost:${port}`;

      const httpService = new HttpService({
        authorization: new AuthorizationService({ getOidcToken }),
        baseUrl,
        defaultHeaders: { 'User-Ultimate-Id': userId }
      });

      return httpService;
    },
    [getOidcToken, userId]
  );

  const initDriveUser = useCallback(
    (driveData: DriveData): void => {
      if (isLogicalUser) {
        return setValidDriveUser('LogicalUser');
      }
      if (!driveData.driveInfo.user.id) {
        return setValidDriveUser('UserNotLoggedInDrive');
      }
      if (driveData.driveInfo.user.id != userId) {
        return setValidDriveUser('UserMismatch');
      }
      setValidDriveUser('SameUser');
    },
    [isLogicalUser, userId]
  );

  const initDriveVersionTypeFromDataCall = useCallback(
    (driveUpdate: DriveUpdate): void => {
      if (!driveUpdate) {
        return;
      }

      const versionType = getVersionTypeDriveApi(driveUpdate.updateState);
      setDriveVersionType(versionType);
      if (driveUpdate.displayText) {
        setDriveMessageText(driveUpdate.displayText);
      }
    },
    []
  );

  const initDriveVersionType = useCallback(
    (driveData: DriveData, versionData?: VersionInfo): void => {
      if (versionData?.driveUpdate) {
        initDriveVersionTypeFromDataCall(versionData.driveUpdate);
      } else {
        // for only the newer versions of drive supporting download link will have this 'driveUpdate' property
        initDriveVersionTypeFromDataCall(driveData.driveInfo.driveUpdate);
      }
    },
    [initDriveVersionTypeFromDataCall]
  );

  const metadataEnabled = useCallback(
    ({ isDriveActive, driveInfo }: DriveData): boolean => {
      if (!isDriveActive) {
        return false;
      }
      if (!driveInfo.enabledFeatures.includes('syncProject')) {
        return false;
      }
      return true;
    },
    []
  );

  const currentProjectSynced = useCallback(
    ({ driveInfo }: DriveData): boolean => {
      if (!contextId) {
        return false;
      }
      if (!driveSynced.length) {
        return false;
      }
      if (!driveInfo.syncedProjects.includes(contextId)) {
        return false;
      }
      return true;
    },
    [contextId, driveSynced.length]
  );

  const currentConnectionSynced = useCallback(
    ({ driveInfo }: DriveData): boolean => {
      if (!driveInfo.activeConnectionIds) {
        return false;
      }
      if (!driveSynced.length) {
        return false;
      }
      return driveInfo.activeConnectionIds.includes(connectionId);
    },
    [connectionId, driveSynced.length]
  );

  const initDriveManager = useCallback(
    async (abortController: AbortController): Promise<void> => {
      const connectionDriveEnabled = driveSynced.some(
        (driveSyncedId) => driveSyncedId == connectionId
      );
      setIsDriveEnabledForConnection(connectionDriveEnabled);
      const initialInfo = await findPWDriveInfo(abortController);

      if (abortController.signal.aborted) {
        return;
      }

      if (!initialInfo) {
        setPwDriveMetadataEnabled(false);
        return;
      }

      const httpDriveService = initDriveHttpService(initialInfo.port);
      setHttpDriveService(httpDriveService);

      // Todo: driveData should be the same as initialInfo, and we can skip this c
      const driveData = await getDriveMetaData(initialInfo.port);
      const versionData = await getDriveVersionInfo(initialInfo.port);

      if (!driveData || abortController.signal.aborted) {
        return;
      }

      initDriveVersionType(driveData, versionData);
      initDriveUser(driveData);

      setPwDriveMetadataEnabled(metadataEnabled(driveData));
      setIsCurrentProjectSynced(currentProjectSynced(driveData));
      setPwDriveSyncDisabled(!currentProjectSynced(driveData));
      setIsCurrentConnectionSynced(currentConnectionSynced(driveData));
      setDriveVersion(driveData.driveInfo.version);
    },
    [
      currentConnectionSynced,
      currentProjectSynced,
      initDriveHttpService,
      initDriveUser,
      initDriveVersionType,
      metadataEnabled,
      driveSynced,
      connectionId
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();

    if (!connectionId || !userId || readOnly) {
      return;
    }

    void initDriveManager(abortController);

    return () => {
      abortController.abort();
    };
  }, [connectionId, initDriveManager, readOnly, refresh, userId]);

  const pwDriveManager = useMemo(
    (): PWDriveManager => ({
      driveDownloadUrl: driveFeatureClient?.driveFeatures.downloadURL,
      driveLatestVersion: driveFeatureClient?.driveFeatures.downloadVersion,
      driveMessageText,
      driveSynced,
      driveVersion,
      driveVersionType,
      httpDriveService,
      isCurrentConnectionSynced,
      isCurrentProjectSynced,
      isDriveConnectionSynced: driveSynced.includes(connectionId),
      isDriveEnabledForConnection,
      pwDriveMetadataEnabled,
      pwDriveSyncDisabled,
      validDriveUser,
      setPwDriveSyncDisabled,
      syncDrive
    }),
    [
      driveFeatureClient,
      driveMessageText,
      driveSynced,
      driveVersion,
      driveVersionType,
      httpDriveService,
      isCurrentConnectionSynced,
      isCurrentProjectSynced,
      isDriveEnabledForConnection,
      connectionId,
      pwDriveMetadataEnabled,
      pwDriveSyncDisabled,
      syncDrive,
      validDriveUser
    ]
  );

  return pwDriveManager;
}
