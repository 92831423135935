import { atom } from 'recoil';
import type { FormDefinition } from '../../../../actions/formRendering';

export const docCodeFormValidState = atom<boolean>({
  key: 'docCodeFormValidState',
  default: false
});

export const docCodeFormDefinitionState = atom<
  FormDefinition | null | undefined
>({
  key: 'docCodeFormDefinitionState',
  default: undefined
});
