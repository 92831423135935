import './loadingPage.css';

import React, { useEffect, useState } from 'react';
import { ProgressLinear } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

type LoadingPageProps = {
  fullscreen?: boolean;
  loadingText?: string;
  loadingSubText?: string;
  value?: number;
  fromValue?: number;
  toValue?: number;
  className?: string;
};

export function LoadingPage({
  fullscreen = false,
  loadingText = t('Generic.Loading'),
  loadingSubText = '',
  value,
  fromValue,
  toValue,
  className
}: LoadingPageProps): JSX.Element {
  const classes = `loading-page ${
    fullscreen ? 'loading-page-fullscreen' : ''
  } ${className ?? ''}`;
  const indeterminate =
    value === undefined && fromValue === undefined && toValue === undefined;
  const [progressValue, setProgressValue] = useState<number | undefined>(
    fromValue ?? value
  );

  useEffect(() => {
    if (fromValue === undefined || toValue === undefined) {
      return;
    }

    const timeoutId = setInterval(() => {
      setProgressValue((current) => {
        if (current !== undefined && current < toValue) {
          return current + 1;
        }
        return current;
      });
    }, 200);

    return () => {
      clearInterval(timeoutId);
    };
  }, [fromValue, toValue]);

  return (
    <div className={classes}>
      <div className="loading-container">
        <div className="loading-text">{loadingText}</div>
        <ProgressLinear
          className="progress-linear"
          labels={[loadingSubText, undefined]}
          labelGroupProps={{ className: 'loading-subtext' }}
          indeterminate={indeterminate}
          value={value ?? progressValue}
          isAnimated={true}
        />
      </div>
    </div>
  );
}
