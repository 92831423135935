import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { itemIsFlatSet } from '@bentley/pw-api';
import { replaceInvalidFileNameCharacters } from '../../../../actions/rename';
import { useLocalStorage } from '../../../../hooks/useStorage';
import { docCodeStateAsync, usingDocCodeState } from '../../docCode';
import { buildFileName } from '../../properties';
import { useRecoilValueAsync } from '../../state';
import { itemsToCopyState } from '../state';
import type { DCWDocProperties } from './propertiesSection';
import {
  allowFileRenameState,
  defaultDocumentDescriptionState,
  defaultDocumentNameState,
  defaultVersionState,
  docPropertiesState,
  fileNameChangedState,
  nameChangedState,
  originalFileNameState
} from '.';

type InitializeProperties = {
  initializeProperties: () => DCWDocProperties;
  propertiesInitialized: boolean;
};

export function useInitializeProperties(): InitializeProperties {
  const [docProperties, setDocProperties] = useRecoilState(docPropertiesState);
  const defaultDocumentName = useRecoilValue(defaultDocumentNameState);
  const docCode = useRecoilValueAsync(docCodeStateAsync);
  const nameChanged = useRecoilValue(nameChangedState);
  const usingDocCode = useRecoilValue(usingDocCodeState);

  const defaultDocumentDescription = useRecoilValue(
    defaultDocumentDescriptionState
  );

  const fileNameChanged = useRecoilValue(fileNameChangedState);
  const originalFileName = useRecoilValue(originalFileNameState);
  const defaultVersion = useRecoilValue(defaultVersionState);

  const itemsToCopy = useRecoilValue(itemsToCopyState);

  const [nameIconLocked] = useLocalStorage<boolean>(
    'fileNameLockedSelection',
    false
  );

  const allowFileRename = useRecoilValue(allowFileRenameState);

  const [propertiesInitialized, setPropertiesInitialized] =
    useState<boolean>(false);

  const initialName = useCallback((): string | undefined => {
    if (usingDocCode && !nameChanged) {
      return docCode;
    }

    if (docProperties.name) {
      return docProperties.name;
    }

    return defaultDocumentName;
  }, [
    defaultDocumentName,
    docCode,
    docProperties.name,
    nameChanged,
    usingDocCode
  ]);

  const initialDescription = useCallback((): string => {
    if (docProperties.description) {
      return docProperties.description;
    }

    return defaultDocumentDescription;
  }, [defaultDocumentDescription, docProperties.description]);

  const initialFileName = useCallback((): string | undefined => {
    if (usingDocCode && docCode && !fileNameChanged) {
      return buildFileName(docCode, originalFileName);
    }

    if (docProperties.fileName) {
      return replaceInvalidFileNameCharacters(docProperties.fileName);
    }

    if (usingDocCode && !docCode) {
      return undefined;
    }

    return originalFileName;
  }, [
    docCode,
    docProperties.fileName,
    fileNameChanged,
    originalFileName,
    usingDocCode
  ]);

  const initialVersion = useCallback((): string => {
    if (docProperties.version) {
      return docProperties.version;
    }

    return defaultVersion;
  }, [defaultVersion, docProperties.version]);

  const updateDocProperties = useCallback(
    (properties: DCWDocProperties): void => {
      if (
        properties.name == docProperties.name &&
        properties.fileName == docProperties.fileName &&
        properties.description == docProperties.description &&
        properties.version == docProperties.version &&
        properties.isLocked == docProperties.isLocked
      ) {
        // Prevent getting in an infinite re-render when initialize is called from outside this hook
        return;
      }

      setDocProperties(properties);
    },
    [docProperties, setDocProperties]
  );

  const initializeProperties = useCallback((): DCWDocProperties => {
    const name = initialName();
    const description = initialDescription();
    const fileName = initialFileName();
    const version = initialVersion();
    const isLocked = nameIconLocked || !allowFileRename;

    if (name && fileName) {
      const properties = { name, description, fileName, version, isLocked };

      updateDocProperties(properties);
      setPropertiesInitialized(true);
      return properties;
    }

    if (
      name &&
      itemsToCopy &&
      itemsToCopy.length > 0 &&
      itemIsFlatSet(itemsToCopy[0])
    ) {
      const properties = { name, description, fileName: '', version, isLocked };

      updateDocProperties(properties);
      setPropertiesInitialized(true);
      return properties;
    }

    return docProperties;
  }, [
    allowFileRename,
    docProperties,
    initialDescription,
    initialFileName,
    initialName,
    initialVersion,
    nameIconLocked,
    updateDocProperties
  ]);

  useEffect(() => {
    if (!propertiesInitialized) {
      initializeProperties();
    }
  }, [initializeProperties, propertiesInitialized]);

  return { initializeProperties, propertiesInitialized };
}
