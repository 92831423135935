import type { PWFileType, WSGInstance } from '@bentley/pw-api';
import {
  AuthorizationService,
  HttpService,
  parseInstanceRelationshipInstances,
  parseResponseChangedInstance
} from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import type { GetToken } from '../../services/http';
import type { Connection } from '../../services/support';

type PWDMProjectInfo = {
  HaveAccess: boolean;
  IsEnabled: boolean;
} & WSGInstance;

export function buildPWDMMarkupLink(
  PWDMMarkupUrl: string | null | undefined,
  projectId: string
): string {
  if (!PWDMMarkupUrl) {
    return '';
  }
  if (!PWDMMarkupUrl.endsWith('/')) {
    PWDMMarkupUrl += '/';
  }
  const link = `${PWDMMarkupUrl}${projectId}/Projects/${projectId}/Drafts/Transmittals/00000000-0000-0000-0000-000000000000?embeddedin=pwweb#/documents`;
  return link;
}

export function convertPWDocumentToDocumentLink(
  PWDocuments: PWFileType[],
  connection: Connection
): DocumentLink[] {
  return PWDocuments.map(
    (PWDocument) =>
      ({
        id: PWDocument.instanceId,
        name: PWDocument.FileName,
        version: PWDocument.Version,
        itemType: PWDocument.className,
        isFolder: false,
        repository: {
          location:
            connection.ConnectionUrl + `/PW_WSG/Project/${connection.ProjectId}`
        },
        pwConnectionId: connection.Id
      } as DocumentLink)
  );
}

export async function checkProject(
  url: string,
  projectId: string | undefined,
  getOidcToken: GetToken
): Promise<boolean> {
  if (!projectId) {
    return false;
  }

  const pwdmUrl = url + '/v2.4/Repositories/BTS/BtsSchema';
  const body = JSON.stringify({
    instance: {
      className: 'CheckProject',
      schemaName: 'BtsSchema',
      changeState: 'new',
      properties: {
        Key: projectId
      }
    }
  });

  const authorizationService = new AuthorizationService({ getOidcToken });
  const httpService = new HttpService({
    baseUrl: pwdmUrl,
    authorization: authorizationService
  });

  const response = await httpService.post('CheckProject', body);

  const instance = await parseResponseChangedInstance(response);
  const projectInfo =
    parseInstanceRelationshipInstances<PWDMProjectInfo>(instance)[0];

  return projectInfo.HaveAccess && projectInfo.IsEnabled;
}
