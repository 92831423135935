import React from 'react';
import { Alert } from '@itwin/itwinui-react';
import { useEnvironmentContext, usePluginContext } from '../../../context';
import { t } from '../../../services/translation';

export function useDriveSyncAlert(): JSX.Element | null {
  const { pwDriveData } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();

  if (!pwDriveData.driveSynced.length && pwDriveData.pwDriveMetadataEnabled) {
    return (
      <Alert type="warning">
        {t('PWDrive.Notifications.NoConnectionSynced')}
      </Alert>
    );
  }

  if (
    !pwDriveData?.pwDriveMetadataEnabled ||
    pwDriveData?.validDriveUser == 'UserNotLoggedInDrive'
  ) {
    return (
      <Alert type="warning">
        {t('PWDrive.Notifications.CouldNotCommunicate')}
      </Alert>
    );
  }

  if (!ecPluginFeatures.pwDrive()) {
    return (
      <Alert type="warning">
        {t('PWDrive.Notifications.SyncFailedServerOld')}
      </Alert>
    );
  }

  if (
    pwDriveData?.validDriveUser == 'UserMismatch' ||
    pwDriveData?.validDriveUser == 'LogicalUser'
  ) {
    return (
      <Alert type="warning">{t('PWDrive.Notifications.UserMisMatch')}</Alert>
    );
  }

  if (!pwDriveData.isDriveConnectionSynced) {
    return (
      <Alert type="warning">
        {t('PWDrive.Notifications.DriveNotConfigured')}
      </Alert>
    );
  }

  if (pwDriveData?.driveVersionType == 'Obsolete') {
    return (
      <Alert type="warning">
        {pwDriveData.driveMessageText ||
          t('PWDrive.Notifications.DriveObsolete')}
      </Alert>
    );
  }

  if (pwDriveData?.driveVersionType == 'OldValidVersion') {
    return (
      <Alert type="informational">
        {pwDriveData.driveMessageText ||
          t('PWDrive.Notifications.NewerVersionAvailable')}
      </Alert>
    );
  }

  if (pwDriveData?.driveVersionType == 'Latest') {
    return (
      <Alert type="informational">
        {pwDriveData.driveMessageText ||
          t('PWDrive.Notifications.DriveUpToDate')}
      </Alert>
    );
  }

  return null;
}
