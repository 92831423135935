import type { HttpService, PWProject, RequestOptions } from '@bentley/pw-api';
import { parseResponseInstances } from '@bentley/pw-api';
import { logError } from '../../hooks/useErrorLogging';
import type { SavedSearchDefinition } from './savedSearchDefinition';

export async function getSavedSearches(
  parent: PWProject,
  httpService: HttpService,
  customOptions?: RequestOptions
): Promise<SavedSearchDefinition[]> {
  try {
    const url = buildSavedSearchQuery(parent);
    const response = await httpService.get(url, customOptions);
    const searches = await parseSavedSearchesResponse(response);
    return searches;
  } catch (e) {
    logError('Error loading saved searches', { error: e });
    return [];
  }
}

function buildSavedSearchQuery(parent: PWProject): string {
  const query = 'PW_WSG/SavedSearchDefinition';
  const parentIdString =
    parent.instanceId == null ? 'null' : `'${parent.instanceId}'`;
  const filter = `$filter=ParentProjectGuid+eq+${parentIdString}`;
  return `${query}?${filter}`;
}

async function parseSavedSearchesResponse(
  response: Response
): Promise<SavedSearchDefinition[]> {
  if (!response.ok) {
    throw response;
  }

  const searches = await parseResponseInstances<SavedSearchDefinition>(
    response
  );
  return searches;
}
