import React, { useState } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { LabeledInput, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { beginUpdateWFWorkflow } from './workflow';
import type { Command } from './wreCommands';

type WorkflowModalProps = {
  checkedRows: PWItem[];
  command: Command;
};

export const WorkFlowModal = ({
  checkedRows,
  command
}: WorkflowModalProps): ModalElement => {
  const { progressManager } = usePluginContext();
  const httpService = useHttpService();
  const { primaryModal } = useNavigationContext();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const [comment, setComment] = useState(command.CommentDefault);

  function onSuccess(): void {
    void httpService.cache?.clearEntriesMatching(
      checkedRows.map((item) => item.instanceId)
    );
    refreshCurrentFolder();
  }

  function onSubmit(): void {
    primaryModal.close();
    void beginUpdateWFWorkflow(
      checkedRows as PWDataItem[],
      command,
      comment,
      httpService,
      progressManager,
      primaryModal.open,
      primaryModal.close,
      onSuccess
    );
  }

  return (
    <PWModal
      title={t('Workflows.ChangeDocumentState')}
      primaryButton={{
        title: t('Generic.Confirm'),
        onClick: onSubmit,
        disabled: false
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'WorkFlowModal' }}
    >
      <ItemSummary item={checkedRows} />
      {command.Prompt ? (
        <Text style={{ marginBottom: '5px' }}>{command.Prompt}</Text>
      ) : (
        <Text style={{ marginBottom: '5px' }}>
          {getWorkflowMessage(command.Label, checkedRows.length)}
        </Text>
      )}
      {command.CommentRequested && (
        <LabeledInput
          label={command.CommentLabel}
          name="textbox"
          autoFocus={true}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          data-testid="WorkFlowComment"
        />
      )}
    </PWModal>
  );
};

function getWorkflowMessage(
  commandLabel: string,
  checkedRowsLength: number
): string {
  if (
    commandLabel.toLocaleLowerCase() == 'next' ||
    commandLabel.toLocaleLowerCase() == 'previous'
  ) {
    const message =
      checkedRowsLength == 1
        ? t('Workflows.WouldYouLikeTo', { commandLabel })
        : t('Workflows.WouldYouLikeToMulti', { commandLabel });
    return message;
  }
  return `${t('Workflows.Execute', { commandLabel })}`;
}
