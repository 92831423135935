import { useCallback, useMemo } from 'react';
import { usePluginContext } from '../../../context';
import { defaultView } from '../defaultView';
import { usePRDSSViews } from '../prdssViews/usePRDSSViews';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function useAdminView(
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const { connection } = usePluginContext();

  const adminConnectionViewSetting = usePRDSSViews(
    `adminConnectionViews-${connection.Id}`,
    'Org'
  );

  const saveView = useCallback(
    (view: View): void => {
      adminConnectionViewSetting.setViews([view]);
    },
    [adminConnectionViewSetting]
  );

  const setView = useCallback((): void => {
    setCurrentViewType('AdminView');
  }, [setCurrentViewType]);

  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit admin folder view not supported');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete admin folder view not supported');
  }, []);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized: adminConnectionViewSetting.views != undefined,
      view: adminConnectionViewSetting.views?.[0] ?? defaultView,
      views: adminConnectionViewSetting.views ?? [defaultView],
      saveView,
      setView,
      editView,
      deleteView
    }),
    [adminConnectionViewSetting, deleteView, editView, saveView, setView]
  );

  return viewSetting;
}
