import type { MenuItemAction } from './MenuItemAction';

export function formatMenuItems(
  menuItems: MenuItemAction[],
  toolbarDisabled = false
): MenuItemAction[] {
  const formattedMenuItems = menuItems
    .map((menuItem) => ({
      ...menuItem,
      disabled:
        menuItem.disabled ||
        allChildrenHiddenOrDisabled(menuItem) ||
        toolbarDisabled,
      hidden: menuItem.hidden || allChildrenHidden(menuItem),
      'data-testid':
        menuItem['data-testid'] ??
        `${menuItem.title?.replace(/ /g, '') ?? ''}Button`
    }))
    .filter((menuItem) => !menuItem.hidden);

  formattedMenuItems.forEach((menuItem) => {
    if (menuItem.submenuItems) {
      menuItem.submenuItems = formatMenuItems(
        menuItem.submenuItems,
        toolbarDisabled
      );
    }
  });

  return formattedMenuItems;
}

function allChildrenHidden(menuItem: MenuItemAction): boolean {
  if (!menuItem.submenuItems) {
    return false;
  }

  return menuItem.submenuItems.every((child) => child.hidden);
}

function allChildrenHiddenOrDisabled(menuItem: MenuItemAction): boolean {
  if (!menuItem.submenuItems) {
    return false;
  }

  return menuItem.submenuItems.every((child) => child.disabled || child.hidden);
}
