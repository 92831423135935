import React from 'react';
import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject
} from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary/progressSummaryModal';
import { ProgressSummaryModal } from '../../components/ProgressSummary/progressSummaryModal';
import type { AppView } from '../../hooks/useAppView';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { ConflictResolutionModal } from '../conflictResolution';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import { resolveConflicts } from './conflictResolution';
import type { OnComplete } from './move';

export async function openErrorModal(
  outcomes: { response: Response; item: PWItem }[],
  openModal: OpenModal,
  closeModal: CloseModal
): Promise<void> {
  const failures = await Promise.all(
    outcomes.map((outcome) =>
      getFailureSummaryItem(outcome.response, outcome.item)
    )
  );

  if (failures.length) {
    openModal(
      <ProgressSummaryModal
        closeModal={closeModal}
        summaries={failures}
        title={t('Move.UnableToMove')}
      />
    );
  }
}

export function openConflictResolutionModal(
  sourceItems: PWItem[],
  destinationParent: PWProject,
  sourceParent: PWParentType,
  openModal: OpenModal,
  closeModal: CloseModal,
  httpService: HttpService,
  trackFeature: TrackFeature,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  onComplete?: OnComplete,
  onClose?: () => void | Promise<void>
): void {
  openModal(
    <ConflictResolutionModal
      sourceItems={sourceItems.map((item) => ({ item }))} // These may be FileType OR Project...
      destinationParent={destinationParent}
      closeModal={closeModal}
      onClose={onClose}
      resolveConflicts={(resolutions: ConflictResolution[]) => {
        void resolveConflicts(
          resolutions,
          httpService,
          trackFeature,
          destinationParent,
          sourceParent,
          openModal,
          closeModal,
          parent,
          appView,
          readOnly,
          onComplete
        );
      }}
    />
  );
}

async function getFailureSummaryItem(
  response: Response,
  item: PWItem
): Promise<ProgressSummaryItem> {
  return {
    item: item,
    errorText: await getErrorText(response)
  } as ProgressSummaryItem;
}

async function getErrorText(response: Response): Promise<string> {
  const body = await response.text();
  const error = JSON.parse(body) as Record<string, string>;
  return (
    error.errorMessage ??
    error.errorDescription ??
    response.statusText ??
    t('Generic.UnknownErrorOccurred')
  );
}
