import { useCallback, useEffect } from 'react';
import type { WSGInstancesResponse } from '@bentley/pw-api';
import {
  useConnectionAuth,
  useHttpService,
  usePluginContext
} from '../../context';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import { useLocalStorage } from '../useStorage';

export function useWorkAreaAvailable(): boolean {
  const { userName } = useConnectionAuth();
  const { connection, logError } = usePluginContext();
  const httpService = useHttpService();

  const [workAreaAvailable, setWorkAreaAvailable] = useLocalStorage<boolean>(
    `workAreaAvailable:${userName}:${connection.Id}`,
    true
  );

  const workAreaExists = useCallback(
    async (abortController?: AbortController): Promise<boolean> => {
      try {
        const url = `PW_WSG/Project/${connection.ProjectId}?$select=$id`;
        const response = await httpService.get(url, {
          uncached: true,
          abortController
        });
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        const data = (await response.json()) as WSGInstancesResponse;
        return data.instances?.length > 0;
      } catch (e) {
        logError('Cannot access work area', { error: e });
        return false;
      }
    },
    [connection.ProjectId, httpService, logError]
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeWorkAreaAvailable(): Promise<void> {
      if (!connection.ProjectId || !httpService) {
        return;
      }

      try {
        const workAreaAvailable = await workAreaExists(abortController);

        if (abortController.signal.aborted) {
          return;
        }

        if (!abortController.signal.aborted) {
          setWorkAreaAvailable(workAreaAvailable);
        }

        if (!workAreaAvailable) {
          notifyWorkAreaUnavailable();
        }
      } catch {
        // Possibly aborted
      }
    }

    // delay to allow higher-priority requests through
    const timeoutId = setTimeout(() => {
      if (!abortController.signal.aborted) {
        void usingConcurrencyLimiter(async () => {
          await initializeWorkAreaAvailable();
        }, 'background');
      }
    }, 5000);

    return () => {
      setWorkAreaAvailable(true);
      clearTimeout(timeoutId);
      abortController.abort();
    };
  }, [connection.ProjectId, httpService, setWorkAreaAvailable, workAreaExists]);

  return workAreaAvailable;
}

function notifyWorkAreaUnavailable() {
  const content = t('WorkArea.DeletedWarning');
  openToast({ content, category: 'negative', type: 'persisting' });
}
