import './AdvancedSavedSearchesModal.css';

import React from 'react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import { t } from '../../services/translation';
import { SearchesTable } from './table/searchesTable';

export function AdvancedSavedSearchesModal(): JSX.Element {
  const { primaryModal } = useNavigationContext();

  return (
    <PWModal
      title={t('SavedAdvSearch.MyAdvSearches')}
      className="s-a-s-modal"
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
    >
      <SearchesTable />
    </PWModal>
  );
}
