import React from 'react';
import type { PWFlatSet, PWItem } from '@bentley/pw-api';
import { itemIsFileType } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import { t } from '../../../services/translation';
import { DocumentPickerModalWrapper } from '../useFlatSetWizard/documentPickerModalWrapper';
import { pwParentTypeToDocumentPickerParentDataType } from '../utils';
import { addFlatSetMembers } from './addFlatSetMember';

interface IAddFlatSetMemberProps {
  flatSetParent?: PWItem;
}

export function AddFlatSetMemberModal({
  flatSetParent
}: IAddFlatSetMemberProps): JSX.Element {
  const { primaryModal, navigationManager } = useNavigationContext();
  const httpService = useHttpService();
  const { dataManager, selectedState } = useTableItemContext();
  const { trackFeature } = useFeatureTracking();
  const onComplete = (uploadedItems: PWItem[]): void => {
    const itemsInProject = uploadedItems.filter(
      (item) => item.ParentGuid == navigationManager.currentParent.instanceId
    );
    selectedState.setSelectedItems(itemsInProject);
    dataManager.refreshCurrentFolder();
  };

  const onClose = () => {
    primaryModal.close();
  };

  const onItemsSelected = (items: DocumentLink[]) => {
    primaryModal.close();
    trackFeature('ADD_SET_ITEM_INSIDE_SET');
    void addFlatSetMembers(
      navigationManager.currentParent as PWFlatSet,
      items,
      httpService,
      onComplete,
      primaryModal.open,
      primaryModal.close
    );
  };

  return (
    <DocumentPickerModalWrapper
      onClose={onClose}
      onItemsSelected={onItemsSelected}
      title={t('DocumentPicker.SelectDocuments')}
      hideConnectionListView={true}
      currentFolder={pwParentTypeToDocumentPickerParentDataType(flatSetParent)}
      isSelectable={itemIsFileType}
    />
  );
}
