import { parseQueryString } from '../../actions/getShareableLink/getShareableLink';

type PWLink = {
  datasource: string;
  workArea: string;
  item: string;
  itemType: string;
  savedSearchId: string;
};

export function getLinkPartsFromUrl(): Partial<PWLink> {
  const datasource = parseQueryString('datasource');
  const item = parseQueryString('item');
  const workArea = parseQueryString('workArea');
  const itemType = parseQueryString('type');
  const savedSearchId = parseQueryString('savedSearchId');

  return {
    datasource,
    item,
    workArea,
    itemType,
    savedSearchId
  };
}
