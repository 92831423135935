import type {
  ECPropertyDef,
  HttpService,
  PWItemClass,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { flattenProperties } from '@bentley/pw-api';
import { t } from '../../services/translation';
import { blacklistedProperties } from './blacklistedProperties';
import type { EnvironmentProperty } from './environmentProperty';
import { formattedDisplayLabel } from './formatDisplayLabel';

export async function getPropertyDefs(
  className: PWItemClass,
  httpService: HttpService,
  options?: RequestOptions
): Promise<EnvironmentProperty[]> {
  const query = buildPropertyDefQuery(className);
  const response = await httpService.get(query, options);
  const propertyDefinitions = await parsePropertyDefs(response, options);
  const properties = parseProperties(propertyDefinitions, className);
  return properties;
}

function buildPropertyDefQuery(className: PWItemClass): string {
  const blackListFilter = blacklistedPropertyFilter(className);
  const filter = `OriginClassName eq 'PW_WSG:${className}' and Name notin [${blackListFilter}]`;
  const select = 'Name,TypeName,DisplayLabel';
  return `MetaSchema/ECPropertyDef?$filter=${filter}&$select=${select}`;
}

function blacklistedPropertyFilter(className: PWItemClass): string {
  const properties = blacklistedProperties.filter((property) =>
    property.filterOutCondition(className)
  );

  const propertyList = properties
    .map(({ propertyName }) => `'${propertyName}'`)
    .join(',');

  return propertyList;
}

async function parsePropertyDefs(
  response: Response,
  options?: RequestOptions
): Promise<ECPropertyDef[]> {
  if (options?.abortController?.signal.aborted) {
    return [];
  }
  if (!response.ok) {
    console.error('Error fetching properties');
    throw new Error('Error fetching properties');
  }

  const data = (await response.json()) as WSGInstancesResponse;
  const instances = data.instances;
  const propertyDefs = instances.map(flattenProperties);
  return propertyDefs as ECPropertyDef[];
}

function parseProperties(
  propertyDefinitions: ECPropertyDef[],
  className: PWItemClass
): EnvironmentProperty[] {
  return propertyDefinitions.map(
    (propertyDefinition) =>
      ({
        name: propertyDefinition.Name,
        dataType: propertyDefinition.TypeName,
        displayLabel: formattedDisplayLabel(propertyDefinition.DisplayLabel),
        propertyType: className == 'Project' ? 'Folder' : 'General',
        envLabel:
          className == 'Project'
            ? t('CustomColumns.FolderProperties')
            : t('CustomColumns.GeneralProperties'),
        isProjectEnv: false
      } as EnvironmentProperty)
  );
}
