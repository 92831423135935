import React, { useCallback } from 'react';
import type { PWDocument, PWParentType } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { DataAccessLevel } from '../../context';
import { useFeatureTracking } from '../../context';
import type { OpenPdfProps } from './pdfLink';
import { OpenPdfMarkUp } from './pdfLink';
import type { OpenPdfLink } from './pdfLinkSrv';
import { openPDFMarkupUrl } from './pdfLinkSrv';
const PDF_MARKUP = 'ConnectPDFMarkup';

type PDFMarkupManager = {
  PDFMarkupUrl: string | null | undefined;
  OpenPdfMarkUp: (props: OpenPdfProps) => JSX.Element | null;
  openPDFMarkupUrl: OpenPdfLink;
};

export function usePDFMarkup(
  regionCode: string,
  projectId?: string
): PDFMarkupManager {
  const { trackFeature } = useFeatureTracking();

  const PDFMarkupUrl = useBuddi(PDF_MARKUP, regionCode);

  const OpenPdfMarkUpIniFrame = useCallback(
    (props: OpenPdfProps) => {
      if (!PDFMarkupUrl) {
        return null;
      }

      return (
        <OpenPdfMarkUp
          {...props}
          PDFMarkupUrl={PDFMarkupUrl}
          projectId={projectId}
          trackFeature={trackFeature}
        />
      );
    },
    [PDFMarkupUrl, projectId, trackFeature]
  );

  const openPDFMarkupViaUrl = useCallback(
    (
      connectionId: string,
      datasourceId: string,
      item: PWDocument,
      serverIsAccessible: boolean,
      dataAccessLevel?: DataAccessLevel,
      itemParent?: PWParentType
    ) => {
      if (!PDFMarkupUrl) {
        throw new Error('PDF markup url missing');
      }

      return openPDFMarkupUrl(
        PDFMarkupUrl,
        projectId,
        trackFeature,
        connectionId,
        datasourceId,
        item,
        serverIsAccessible,
        dataAccessLevel,
        itemParent
      );
    },
    [PDFMarkupUrl, projectId, trackFeature]
  );

  return {
    PDFMarkupUrl,
    OpenPdfMarkUp: OpenPdfMarkUpIniFrame,
    openPDFMarkupUrl: openPDFMarkupViaUrl
  };
}
