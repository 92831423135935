import { Sema } from 'async-sema';

const connectionPoolSize = 15;
export const activeThread = new Sema(connectionPoolSize);
export const backgroundThread = new Sema(connectionPoolSize);

/**
 * Runs a function inside an app-universal concurrency limiter which will limit requests to http or other resources for high-request workflows
 * This can prevent a large backlog from building up in the browser's pending requests, freeing browser memory,
 * and keeping a connection free for other user requests, such as navigating or opening the info panel
 * Can specify if the function should be given 'active' or 'background' priority
 * @template T
 * @param {() => Promise<T>} request An invocable function to call when a semaphore can be acquired
 * @param {'active' | 'background'} [priority] The priority of the request; background requests will wait until all active requests are complete
 * @returns {Promise<T>} Request is awaited, and its response is returned
 * @throws Any error produced during the request will be caught and thrown
 */
export async function usingConcurrencyLimiter<T>(
  request: () => Promise<T>,
  priority: 'active' | 'background' = 'active'
): Promise<T> {
  const token = await acquireThread(priority);

  try {
    const response = await request();
    // Return response in timeout to put at end of UI rendering queue
    return new Promise((resolve) => setTimeout(() => resolve(response)));
  } finally {
    releaseThread(priority, token);
  }
}

async function acquireThread(
  priority: 'active' | 'background'
): Promise<unknown> {
  if (priority == 'active') {
    return (await activeThread.acquire()) as unknown;
  }
  await waitForCondition(backgroundThreadAvailable, 100);
  return (await backgroundThread.acquire()) as unknown;
}

function backgroundThreadAvailable(): boolean {
  return (
    activeThread.nrWaiting() + backgroundThread.nrWaiting() < connectionPoolSize
  );
}

export function waitForCondition(
  condition: () => boolean,
  pollFrequency: number
): Promise<void> {
  return new Promise<void>((resolve) => {
    function checkCondition() {
      if (condition()) {
        resolve();
      } else {
        setTimeout(checkCondition, pollFrequency);
      }
    }
    checkCondition();
  });
}

function releaseThread(
  priority: 'active' | 'background',
  token: unknown
): void {
  if (priority == 'active') {
    activeThread.release(token);
  }
  backgroundThread.release(token);
}
