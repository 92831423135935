import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { parseRelatedEnvironmentSheets } from '@bentley/pw-api';
import { InformationPanelContent } from '@itwin/itwinui-react';
import { useTableItemContext } from '../../../context';
import { PWInfoRow } from '../details/pwInfoRow';

type AttributesListProps = {
  currentAttributeSheet: number;
};

export function AttributesList({
  currentAttributeSheet
}: AttributesListProps): JSX.Element | null {
  const { actionableItems } = useTableItemContext();

  if (!actionableItems.length) {
    return null;
  }

  const attributeSheets = parseRelatedEnvironmentSheets(actionableItems[0]);
  const attributeSheet = attributeSheets[currentAttributeSheet] ?? {};
  const attributeKeys = Object.keys(attributeSheet.properties ?? {});

  function itemProperty(item: PWItem, key: string): string {
    const attributeSheets = parseRelatedEnvironmentSheets(item);
    const attributeSheet = attributeSheets[currentAttributeSheet] ?? {};
    const propertyValue = attributeSheet.properties?.[key];

    return String(propertyValue ?? '');
  }

  return (
    <InformationPanelContent displayStyle="inline">
      {attributeKeys.map((key) => (
        <PWInfoRow
          label={key}
          property={(item) => itemProperty(item, key)}
          key={key}
        />
      ))}
    </InformationPanelContent>
  );
}
