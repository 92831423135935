import type {
  HttpService,
  PWFlatSet,
  PWItem,
  WSGChangedInstanceResponse,
  WSGInstance,
  WSGInstancesResponse,
  WSGRelationshipInstance
} from '@bentley/pw-api';
import { getFlatSetChildren } from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { DocumentPickerParentDataType } from './useFlatSetWizard/documentPickerModalWrapper';

export type AddFlatSetMemberProgressTracker = {
  failedItems: number;
  totalItems: number;
  failures: ProgressSummaryItem[];
};

export function getAddItemQueryBody(
  flatSetId: string,
  documentId: string
): string {
  const body = {
    instance: {
      instanceId: flatSetId,
      schemaName: 'PW_WSG',
      className: 'FlatSet',
      properties: {},
      relationshipInstances: [
        {
          instanceId: '',
          schemaName: 'PW_WSG',
          className: 'SetDocument',
          direction: 'forward',
          properties: {},
          relatedInstance: {
            instanceId: documentId,
            schemaName: 'PW_WSG',
            className: 'Document',
            properties: {}
          }
        }
      ]
    }
  };

  return JSON.stringify(body);
}

function getCreateFlatSetQueryBody(
  parentProjectId: string,
  flatSetName: string,
  flatSetDescription: string,
  documentIds: string[],
  currentEnvironmentInstance?: WSGInstance | null
): string {
  const relationshipInstances = documentIds.map(
    (id) =>
      ({
        schemaName: 'PW_WSG',
        className: 'SetDocument',
        direction: 'forward',
        properties: {},
        relatedInstance: {
          instanceId: id,
          schemaName: 'PW_WSG',
          className: 'Document',
          properties: {}
        }
      } as WSGRelationshipInstance)
  );

  if (currentEnvironmentInstance) {
    const relationshipInstance = {
      schemaName: 'PW_WSG',
      className: 'DocumentEnvironment',
      direction: 'forward',
      relatedInstance: currentEnvironmentInstance
    } as WSGRelationshipInstance;
    relationshipInstances.push(relationshipInstance);
  }

  const body = {
    instance: {
      schemaName: 'PW_WSG',
      className: 'FlatSet',
      changeState: 'new',
      properties: {
        name: flatSetName,
        description: flatSetDescription,
        ParentGuid: parentProjectId
      },
      relationshipInstances
    }
  };
  return JSON.stringify(body);
}

export function getRemoveQueryBody(
  flatSetInstanceId: string,
  setMemberId?: string,
  itemInstanceId?: string
): string {
  const body = {
    instance: {
      instanceId: flatSetInstanceId,
      schemaName: 'PW_WSG',
      className: 'FlatSet',
      properties: {},
      relationshipInstances: [
        {
          instanceId: setMemberId,
          schemaName: 'PW_WSG',
          className: 'SetDocument',
          changeState: 'Deleted',
          direction: 'forward',
          properties: {},
          relatedInstance: {
            instanceId: itemInstanceId,
            schemaName: 'PW_WSG',
            className: 'Document',
            properties: {}
          }
        }
      ]
    }
  };

  return JSON.stringify(body);
}

export function getToggleVersionLockQueryBody(
  flatSetInstanceId: string,
  setMemberId: string,
  itemInstanceId: string,
  lockToVersion: boolean
): string {
  const body = {
    instance: {
      instanceId: flatSetInstanceId,
      schemaName: 'PW_WSG',
      className: 'FlatSet',
      properties: {},
      relationshipInstances: [
        {
          instanceId: setMemberId,
          schemaName: 'PW_WSG',
          className: 'SetDocument',
          changeState: 'modified',
          direction: 'forward',
          properties: {
            LockToVersion: lockToVersion
          },
          relatedInstance: {
            instanceId: itemInstanceId,
            schemaName: 'PW_WSG',
            className: 'Document',
            properties: {}
          }
        }
      ]
    }
  };

  return JSON.stringify(body);
}

async function createFlatSet(
  httpService: HttpService,
  flatSetName: string,
  flatSetDescription: string,
  parentProjectId: string,
  documentIds: string[],
  environmentInstance?: WSGInstance | null
): Promise<Response> {
  const url = `PW_WSG/FlatSet`;
  const body = getCreateFlatSetQueryBody(
    parentProjectId,
    flatSetName,
    flatSetDescription,
    documentIds,
    environmentInstance
  );

  return await httpService.post(url, body);
}

export async function createFlatSetAndGetParsedResult(
  httpService: HttpService,
  flatSetName: string,
  flatSetDescription: string,
  parentProjectId: string,
  documentIds: string[],
  environmentInstance?: WSGInstance | null
): Promise<WSGInstance | undefined> {
  try {
    const response = await createFlatSet(
      httpService,
      flatSetName,
      flatSetDescription,
      parentProjectId,
      documentIds,
      environmentInstance
    );
    const flatSetInstance = await parseFlatSetInstance(response);
    openToast({
      content: t('FlatSet.CreatedNotification'),
      category: 'positive'
    });

    return flatSetInstance;
  } catch (e) {
    handleBadResponse(e as WSGInstancesResponse);
    return undefined;
  }
}

async function parseFlatSetInstance(response: Response): Promise<WSGInstance> {
  const data = (await response.clone().json()) as WSGChangedInstanceResponse & {
    errorMessage?: string;
  };
  if (!response.ok || response.status != 201 || data.errorMessage) {
    throw data;
  }

  return data.changedInstance.instanceAfterChange;
}

function handleBadResponse(responseBody: WSGInstancesResponse) {
  if (
    responseBody.errorMessage?.includes(
      'Failed to get object identifier by its GUID. Not found.'
    )
  ) {
    const message = t('FlatSet.Create.Notification.ItemsDeletedMessage');
    const toastContent = t(
      'FlatSet.Create.Notification.UnableToCreateSetWithMessage',
      { message }
    );

    return openToast({ content: toastContent, category: 'negative' });
  }

  if (responseBody.errorMessage) {
    const message = responseBody.errorMessage;
    const toastContent = t(
      'FlatSet.Create.Notification.UnableToCreateSetWithMessage',
      { message }
    );
    return openToast({ content: toastContent, category: 'negative' });
  }

  const toastContent = t('FlatSet.Create.Notification.UnableToCreateSet');
  return openToast({ content: toastContent, category: 'negative' });
}

export function pwParentTypeToDocumentPickerParentDataType(
  parent?: PWItem
): DocumentPickerParentDataType | undefined {
  if (!parent) {
    return;
  }

  return {
    ParentGuid: parent.ParentGuid ?? '',
    className: 'Project',
    instanceId: parent.instanceId,
    Name: parent.Name
  };
}

export async function copyFlatSet(
  flatSet: PWFlatSet,
  flatSetNewName: string,
  newParentId: string,
  httpService: HttpService
): Promise<Response> {
  const flatSetChildren = await getFlatSetChildren({
    flatSetId: flatSet.instanceId,
    httpService
  });
  return await createFlatSet(
    httpService,
    flatSetNewName,
    flatSet.Description,
    newParentId,
    flatSetChildren.map((i) => i.instanceId)
  );
}

export function newAddFlatSetMemberProgressTracker(
  items: PWItem[]
): AddFlatSetMemberProgressTracker {
  return {
    failedItems: 0,
    totalItems: items.length,
    failures: []
  } as AddFlatSetMemberProgressTracker;
}
