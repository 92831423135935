import React, { useCallback, useMemo } from 'react';
import { itemIsFlatSet } from '@bentley/pw-api';
import { SvgProject } from '@itwin/itwinui-icons-react';
import {
  allowFlatSetCreation,
  openFlatSetCreationConfirmationModal,
  useFlatSetWizard
} from '../../../../actions/flatSetActions';
import {
  useAppViewContext,
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCreateFlatSetAction(): MenuItemAction {
  const { readOnly } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();

  const {
    navigationManager: { currentParent },
    primaryModal
  } = useNavigationContext();

  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const {
    selectedState: { selectedItems }
  } = useTableItemContext();

  const { show: showFlatSetWizard } = useFlatSetWizard();

  const showCreateFlatSet = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }

    if (itemIsFlatSet(currentParent)) {
      return false;
    }

    if (!ecPluginFeatures.flatSetActions()) {
      return false;
    }

    if (currentAppView.type != 'FolderNavigation') {
      return false;
    }

    return true;
  }, [currentAppView.type, currentParent, ecPluginFeatures, readOnly]);

  const onCreateFlatSetClick = useCallback((): void => {
    openFlatSetCreationConfirmationModal({
      showFlatSetWizard,
      items: selectedItems,
      primaryModal
    });
  }, [primaryModal, selectedItems, showFlatSetWizard]);

  const createFlatSetAction = useMemo(
    (): MenuItemAction => ({
      title: t('FlatSet.Create'),
      icon: <SvgProject />,
      disabled: !allowFlatSetCreation(
        currentParent,
        currentAppView.type,
        readOnly
      ),
      hidden: !showCreateFlatSet,
      onClick: onCreateFlatSetClick
    }),
    [
      currentAppView.type,
      currentParent,
      onCreateFlatSetClick,
      readOnly,
      showCreateFlatSet
    ]
  );

  return createFlatSetAction;
}
