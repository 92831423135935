import type { WSGInstance } from '@bentley/pw-api';
import {
  flattenProperties,
  parseInstanceRelationshipInstances
} from '@bentley/pw-api';
import type { ColumnInfo } from '../defaultView';
import type { View } from '../useViews';
import type { FolderView } from './pweFolderView';
import type { Column, View as WSGView } from './view';
import { viewColumnToColumnInfo } from './viewColumnToColumnInfo';

export function instanceToFolderView(instance: WSGInstance): FolderView {
  const view = flattenProperties(instance) as WSGView;
  const columns = parseInstanceRelationshipInstances<Column>(view);

  return { view, columns };
}

export function folderViewToView(folderView: FolderView): View {
  const name = folderView.view.Name ?? '';

  const columns = parseColumns(folderView.columns);
  const isGlobalPWE = folderView.view.IsGlobal;
  const instanceId = folderView.view.instanceId;
  return { name, columns, isGlobalPWE, instanceId };
}

export function parseColumns(viewColumns: Column[]): ColumnInfo[] {
  const columns = viewColumns.map((viewColumn) =>
    viewColumnToColumnInfo(viewColumn)
  );

  const validColumns = columns.filter((column) => column) as ColumnInfo[];
  return validColumns;
}
