import { atom } from 'recoil';
import type { FormDefinition } from '../../../actions/formRendering';

export const formDefinitionState = atom<FormDefinition | undefined>({
  key: 'dc_formDefinitionState',
  default: undefined
});

export const formValidState = atom<boolean>({
  key: 'dc_formValidState',
  default: false
});

export const updatingTriggeredPropertiesState = atom<boolean>({
  key: 'dc_updatingTriggeredPropertiesState',
  default: false
});

export const updatingReloadPropertiesState = atom<boolean>({
  key: 'dc_updatingReloadPropertiesState',
  default: false
});

export const projectIdState = atom<string | undefined>({
  key: 'dc_projectIdState',
  default: undefined
});

export const generatingDocCodeState = atom<boolean>({
  key: 'dc_generatingDocCodeState',
  default: false
});
