import React from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsFlatSet, parseSetRelationshipInstance } from '@bentley/pw-api';
import { SvgDocumentLock } from '@itwin/itwinui-icons-react';
import { useNavigationContext } from '../../../../context';
import { t } from '../../../../services/translation';
import { PWTooltip } from '../../../pwTooltip';

type LockedVersionBadgeProps = {
  item: PWItem;
};

export function LockedVersionBadge({
  item
}: LockedVersionBadgeProps): JSX.Element | null {
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  if (!itemIsFlatSet(currentParent)) {
    return null;
  }

  const lockedToVersion = parseSetRelationshipInstance(item, currentParent)
    ?.properties?.LockToVersion;

  if (lockedToVersion || (!lockedToVersion && !(item as PWDataItem).IsLatest)) {
    return (
      <PWTooltip content={t('Grid.LockToVersion')}>
        <span className="pw-cell-badge">
          <SvgDocumentLock data-testid="lock-to-version-icon" />
        </span>
      </PWTooltip>
    );
  }

  return null;
}
