import { atom } from 'recoil';
import type { FormDefinition } from '../../../actions/formRendering';

export const formDefinitionState = atom<FormDefinition | null | undefined>({
  key: 'formDefinitionState',
  default: undefined
});

export const formValidState = atom<boolean>({
  key: 'formValidState',
  default: false
});

export const updatingEnvironmentInterfacesState = atom<boolean>({
  key: 'attr_updatingEnvironmentInterfacesState',
  default: false
});

export const updatingTriggeredPropertiesState = atom<boolean>({
  key: 'attr_updatingTriggeredPropertiesState',
  default: false
});

export const updatingReloadPropertiesState = atom<boolean>({
  key: 'attr_updatingReloadPropertiesState',
  default: false
});

export const projectIdState = atom<string | undefined>({
  key: 'attr_projectIdState',
  default: undefined
});
