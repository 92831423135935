import './columnHeader.css';

import React from 'react';
import { PWTooltip } from '../../../pwTooltip';

type ColumnHeaderProps = {
  displayLabel: string;
};

export function ColumnHeader({ displayLabel }: ColumnHeaderProps): JSX.Element {
  return (
    <PWTooltip content={displayLabel}>
      <div className="pw-col-header" data-testid={`${displayLabel}-header`}>
        {displayLabel}
      </div>
    </PWTooltip>
  );
}
