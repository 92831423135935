import { useCallback, useMemo, useRef, useState } from 'react';
import { useFeatureTracking, useHttpService } from '../../../context';
import type { EnvironmentDefinition } from '../environmentDefinitions/environmentDefinition';
import type { Attribute } from './attributeDefinitions';
import { buildAttributeDefinitions } from './attributeDefinitions';

type EnvironmentAttributeDefinitions = {
  attributeDefinitions: Record<string, Attribute[]>;
  getAttributeDefinition: (
    environmentDefinition: EnvironmentDefinition,
    property: string
  ) => Attribute | undefined;
  loadAttributeDefinitions: (
    environmentDefinition: EnvironmentDefinition
  ) => Promise<void>;
};

export function useEnvironmentAttributeDefinitions(): EnvironmentAttributeDefinitions {
  const { launchDarklyFeatures } = useFeatureTracking();
  const httpService = useHttpService();

  const [attributeDefinitions, setAttributeDefinitions] = useState<
    Record<string, Attribute[]>
  >({});

  const environmentsFetched = useRef<string[]>([]);

  const includePicklistAttributes = launchDarklyFeatures.showPicklistDropdowns
    ? 'includePicklist'
    : 'excludePicklist';

  const loadAttributeDefinitions = useCallback(
    async (environmentDefinition: EnvironmentDefinition): Promise<void> => {
      if (
        environmentsFetched.current.includes(environmentDefinition.instanceId)
      ) {
        // Prevent sending multiple requests concurrently
        return;
      }

      environmentsFetched.current.push(environmentDefinition.instanceId);
      const attributeDefinitions = await buildAttributeDefinitions(
        environmentDefinition,
        includePicklistAttributes,
        httpService
      );
      setAttributeDefinitions((current) => {
        current[environmentDefinition.Name] = attributeDefinitions;
        return current;
      });
    },
    [httpService, includePicklistAttributes]
  );

  const getAttributeDefinition = useCallback(
    (
      environmentDefinition: EnvironmentDefinition,
      property: string
    ): Attribute | undefined => {
      const environmentAttributes =
        attributeDefinitions[environmentDefinition.Name];

      if (!environmentAttributes) {
        void loadAttributeDefinitions(environmentDefinition);
        return undefined;
      }

      const attributeDefinition = environmentAttributes.find(
        ({ name }) => name == property
      );
      return attributeDefinition;
    },
    [attributeDefinitions, loadAttributeDefinitions]
  );

  const environmentAttributeDefinitions = useMemo(
    (): EnvironmentAttributeDefinitions => ({
      attributeDefinitions,
      getAttributeDefinition,
      loadAttributeDefinitions
    }),
    [attributeDefinitions, getAttributeDefinition, loadAttributeDefinitions]
  );

  return environmentAttributeDefinitions;
}
