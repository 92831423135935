import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  getFlatSetChildren,
  getProjectChildren,
  itemIsFlatSet
} from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';
import type { AppViewSetting, FetchItemsArgs } from './appViewSetting';

export function useFolderNavigationView(): AppViewSetting {
  const httpService = useHttpService();
  const {
    ecPluginFeatures: { environmentQueryPerformance }
  } = useEnvironmentContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const fetchItems = useCallback(
    async ({ requestOptions }: FetchItemsArgs): Promise<PWItem[]> => {
      // Workaround for when current parent is set to {} in navigation manager
      if (currentParent.instanceId === undefined) {
        return [];
      }

      if (itemIsFlatSet(currentParent)) {
        const items = await getFlatSetChildren({
          flatSetId: currentParent.instanceId,
          selectRelationships: {
            parent: true,
            environment: environmentQueryPerformance(),
            setRelationshipProperties: true
          },
          httpService,
          requestOptions: { ...requestOptions, uncached: true }
        });

        return items;
      }

      const items = await getProjectChildren({
        parentId: currentParent.instanceId,
        selectRelationships: {
          parent: true,
          environment: environmentQueryPerformance()
        },
        httpService,
        requestOptions
      });
      return items;
    },
    [currentParent, environmentQueryPerformance, httpService]
  );

  function clearView(): void {
    // No-op
  }

  const folderNavigationViewSetting = useMemo(
    (): AppViewSetting => ({
      type: 'FolderNavigation',
      fetchItems,
      clearView
    }),
    [fetchItems]
  );

  return folderNavigationViewSetting;
}
