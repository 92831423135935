import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import { filterDataItems, itemIsFinal, modifyItem } from '@bentley/pw-api';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { t } from '../../services/translation';
import {
  notifyErrorDuringFinalStatus,
  notifyErrorDuringUnsetFinalStatus,
  notifyFinalStatusSuccess,
  notifyOnChangeFinalStatus,
  notifyOnSetAsFinal,
  notifyOnUnsetFinalStatus
} from './notifications';

export async function setFinal(
  selectedItems: PWItem[],
  httpService: HttpService,
  trackFeature?: TrackFeature,
  onSuccess?: () => void
): Promise<Response[]> {
  const dataItems = filterDataItems(selectedItems);
  const toastHandle = notifyOnSetAsFinal(dataItems.length);
  trackFeature?.('FILE_SET_FINAL');

  try {
    const responses = await Promise.all(
      dataItems.map((item: PWDataItem) =>
        toggleStatusForItem(item, httpService)
      )
    );

    notifyFinalStatusSuccess(toastHandle);
    onSuccess?.();
    await clearRelevantCacheEntries(dataItems, httpService);

    return responses;
  } catch (error) {
    const errorMessage = (error as Error).message;
    notifyErrorDuringFinalStatus(toastHandle, errorMessage);
    throw new Error(errorMessage);
  }
}

export async function unsetFinal(
  selectedItems: PWItem[],
  httpService: HttpService,
  trackFeature?: TrackFeature,
  onSuccess?: () => void
): Promise<Response[]> {
  const dataItems = filterDataItems(selectedItems);
  const toastHandle = notifyOnChangeFinalStatus(dataItems.length);
  trackFeature?.('FILE_UNSET_FINAL');

  try {
    const responses = await Promise.all(
      dataItems.map((value: PWDataItem) =>
        toggleStatusForItem(value, httpService)
      )
    );

    notifyOnUnsetFinalStatus(toastHandle);
    onSuccess?.();
    await clearRelevantCacheEntries(dataItems, httpService);

    return responses;
  } catch (error) {
    const errorMessage = (error as Error).message;
    notifyErrorDuringUnsetFinalStatus(toastHandle, errorMessage);
    throw new Error(errorMessage);
  }
}

async function toggleStatusForItem(item: PWDataItem, httpService: HttpService) {
  const response = await usingConcurrencyLimiter(
    async () =>
      await modifyItem({
        item,
        properties: { IsFinal: !itemIsFinal(item) },
        httpService
      })
  );

  handleBadResponse(response, item);
  return response;
}

function handleBadResponse(response: Response, dataItem: PWDataItem) {
  if (response.status === 403) {
    const message = `${t('Workflows.403Error', {
      name: dataItem.Name
    })}`;
    throw new Error(message);
  }

  if (response.status === 400) {
    const message = `${t('Workflows.TroubleChangingStatus', {
      name: dataItem.Name
    })}`;
    throw new Error(message);
  }

  if (!response.ok) {
    const message = response.statusText || t('Generic.FileNotFound');
    throw new Error(message);
  }
}

async function clearRelevantCacheEntries(
  items: PWDataItem[],
  httpService: HttpService
): Promise<void> {
  const instanceIds = items.map((item) => item.instanceId);
  const parentIds = items.map((item) => item.ParentGuid);

  await httpService.cache?.clearEntriesMatching([...instanceIds, ...parentIds]);
}
