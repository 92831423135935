import type { ToastHandle } from '../../services/pwToast';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyInitializingUpload(): ToastHandle {
  return openToast({
    content: t('Upload.Notification.UploadInitializing'),
    type: 'persisting',
    spinner: true
  });
}
