import * as React from 'react';
export const SvgDeliverableManagement = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 16 16">
        <polygon id="XMLID_5_" points="0.4,8.4 9.4,17.2 25,4.3 "/>
        <polygon id="XMLID_4_" points="0,8.9 6.2,15.2 0,22.9 "/>
        <polygon id="XMLID_3_" points="0.5,23.4 6.8,15.7 9.4,18.4 14.1,14.5 25,27.6 "/>
        <polygon id="XMLID_2_" points="14.7,13.9 25.8,27.2 25.8,4.6 "/>
        <polygon id="XMLID_1_" points="26.6,4.7 32,8.2 32,24.5 26.7,27.7 "/>
    </svg>
);