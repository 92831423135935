import './iModelMappingModal.css';

import React, { useEffect, useMemo, useState } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { SvgInfoHollow } from '@itwin/itwinui-icons-color-react';
import type { SelectOption } from '@itwin/itwinui-react';
import { LabeledSelect } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { PWTooltip } from '../../components/pwTooltip';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { sanitizeDataSourceId } from '../../services/stringFormatter';
import { t } from '../../services/translation';
import type { IModel } from './iModel';
import type { IModelBridge, IModelMapping } from './iModelBridge';
import { notifyMappingCompleted } from './notifications';
import { IModelMapTable } from './table/iModelMapTable';
import {
  getDatasourceLocation,
  getSpatialRootDocument,
  loadExistingMapData,
  updateIModelMappings
} from './utils';

type MapIModelModalProps = {
  items: PWDataItem[];
  onComplete?: () => void;
};

export function MapIModelModal({
  items,
  onComplete
}: MapIModelModalProps): ModalElement {
  const { trackFeature } = useFeatureTracking();
  const { connection, iModelMappingManager, connectionIModelBridges } =
    usePluginContext();
  const pwHttpService = useHttpService();
  const { iModels } = useEnvironmentContext();
  const { primaryModal } = useNavigationContext();

  const [selectedIModel, setSelectedIModel] = useState<IModel>();
  const [selectedSpatialRoot, setSelectedSpatialRoot] = useState<PWDataItem>(
    items[0]
  );
  const [spatialRootName, setSpatialRootName] = useState<string>(
    t('IModel.NotDefined')
  );
  const [tableDisabled, setTableDisabled] = useState<boolean>(true);
  const [showSpatialDropDown, setShowSpatialDropDown] =
    useState<boolean>(false);
  const [spatialRootChosen, setSpatialRootChosen] = useState<boolean>(false);

  const availableIModels = useMemo(
    () =>
      iModels.sort((a, b) =>
        (a.properties?.Name ?? '') > (b.properties?.Name ?? '') ? 1 : -1
      ),
    [iModels]
  );

  const initialMappings: IModelMapping[] = useMemo(
    () =>
      items.map(
        (item) =>
          ({
            document: item,
            isSheet: false,
            isMaster: false,
            isSpatialRoot: false
          } as IModelMapping)
      ),
    [items]
  );

  const [iModelMappings, setIModelMappings] =
    useState<IModelMapping[]>(initialMappings);

  const iModelDropDownData = useMemo(
    (): SelectOption<string>[] => [
      ...availableIModels.map((i) => {
        return {
          label: String(i.properties?.Name ?? ''),
          value: String(i.properties?.Name ?? '')
        };
      })
    ],
    [availableIModels]
  );

  const spatialRootDropDownData: SelectOption<string>[] = [
    ...items.map((i) => {
      return { label: i.Name, value: i.Name };
    })
  ];

  function mapButtonDisabled(): boolean | undefined {
    const mappingsNotDone = iModelMappings.some((mapping) => {
      return !mapping.isMaster && !mapping.isSpatialRoot && !mapping.isSheet;
    });
    // if all documents are already mapped to the imodel then no mapping can be done
    const noMappingsNeeded =
      iModelMappings.filter((mapping) => {
        return !mapping.isExistingBridge;
      }).length == 0;
    return mappingsNotDone || noMappingsNeeded;
  }

  async function performMapping() {
    primaryModal.close();

    if (selectedIModel) {
      iModelMappings.forEach((mapping) => {
        mapping.iModel = selectedIModel;
      });
    }

    const datasourceLocation = await getDatasourceLocation(pwHttpService);

    const responses = await Promise.all(
      iModelMappings.map(async (mapping) => {
        if (mapping.isExistingBridge) {
          return false;
        }

        try {
          const result = await iModelMappingManager.createIModelBridge(
            mapping,
            datasourceLocation,
            trackFeature,
            connection.ProjectId
          );
          return result;
        } catch (e) {
          console.error(e);
          return false;
        }
      })
    );

    connectionIModelBridges.refreshBridges();
    notifyMappingCompleted(
      responses.filter((response) => response).length,
      String(selectedIModel?.properties?.Name)
    );

    onComplete?.();
  }

  async function iModelSelected(iModelName: string) {
    const iModels = availableIModels.filter((item) => {
      return item.properties?.Name == iModelName;
    });
    if (iModels.length > 0) {
      const iModel = iModels[0];
      const existingBridges: IModelBridge[] =
        await iModelMappingManager.getIModelBridgesForIModel(iModel.instanceId);
      setSelectedIModel(iModel);
      const spatialRootExists = existingBridges.length > 0;
      if (spatialRootExists) {
        const spatialRootForIModel = existingBridges.find((bridge) => {
          return bridge.properties?.IsSpatialRoot == 'true';
        });
        if (spatialRootForIModel) {
          const documentGuid = spatialRootForIModel.properties
            ?.DocumentGuid as string;
          if (!documentGuid) {
            throw new Error('documentId not found');
          }
          const documentId = documentGuid.split(';')[0];
          const spatialRootDocument = await getSpatialRootDocument(
            pwHttpService,
            documentId
          );
          if (spatialRootDocument) {
            setSpatialRootName(spatialRootDocument.Name);
          } else {
            const spatialRootInCurrentConnection =
              sanitizeDataSourceId(connection.DatasourceInstanceId) ==
              spatialRootForIModel.properties?.DataSourceIdentifier;

            if (spatialRootInCurrentConnection) {
              setSpatialRootName(
                `${t('IModel.LocatedIn', {
                  connectionName: connection.Name
                })}`
              );
            } else {
              setSpatialRootName(t('IModel.LocatedInDifferentConnection'));
            }
          }
        }
      } else {
        setSpatialRootName(t('IModel.NotDefined'));
      }
      setSpatialRootChosen(spatialRootExists);
      setTableDisabled(!spatialRootExists);
      setShowSpatialDropDown(!spatialRootExists);
      // reset chosen mappings
      const newMappings = loadExistingMapData(
        initialMappings,
        iModel,
        connectionIModelBridges.iModelBridges
      );
      setIModelMappings(newMappings);
    } else {
      setShowSpatialDropDown(false);
      setTableDisabled(true);
      setSpatialRootChosen(false);
      setIModelMappings(initialMappings);
      setSelectedIModel(undefined);
      setSpatialRootName(t('IModel.NotDefined'));
    }
  }

  function spatialRootSelected(selectedSpatialRootName: string) {
    const spatialRootItems = items.filter((item) => {
      return item.Name == selectedSpatialRootName;
    });
    updateIModelMappings(
      iModelMappings,
      setIModelMappings,
      selectedSpatialRoot,
      false,
      false,
      false
    );
    if (spatialRootItems.length > 0) {
      setTableDisabled(false);
      setSelectedSpatialRoot(spatialRootItems[0]);
      updateIModelMappings(
        iModelMappings,
        setIModelMappings,
        spatialRootItems[0],
        false,
        false,
        true
      );
      setSpatialRootChosen(true);
    } else {
      setTableDisabled(true);
    }
  }

  useEffect(() => {
    if (iModelDropDownData?.length) {
      void iModelSelected(iModelDropDownData[0].value);
    }
  }, [iModelDropDownData]);

  return (
    <PWModal
      title={t('IModel.MapToIModel')}
      className="iModelMapping"
      primaryButton={{
        title: t('IModel.Map'),
        onClick: () => void performMapping(),
        disabled: mapButtonDisabled(),
        'data-testid': 'map-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'MapIModelModal' }}
    >
      <div className="iModelDropdowns">
        <LabeledSelect
          id="iModelSelect"
          label={t('IModel.MapTo')}
          options={iModelDropDownData}
          onChange={(e) => void iModelSelected(e)}
          data-testid="map-to-dropdown"
          value={String(selectedIModel?.properties?.Name ?? '')}
          wrapperProps={{ className: 'iModelMapDropDown' }}
        />
        {showSpatialDropDown ? (
          <LabeledSelect
            id="spatialRootSelect"
            label={t('IModel.SpatialRoot')}
            options={spatialRootDropDownData}
            onChange={spatialRootSelected}
            data-testid="spatial-root-dropdown"
            value={spatialRootChosen ? selectedSpatialRoot?.Name : ''}
            wrapperProps={{ className: 'iModelMapDropDown' }}
          />
        ) : null}
      </div>
      <div className="spatialRootRow">
        <div className="spatialRootLabel">{t('IModel.SpatialRoot')}:</div>
        <div className="spatialRootName">{spatialRootName}</div>
        {!spatialRootChosen ? (
          <PWTooltip
            placement={'top'}
            content={t('IModel.MappingCannotBeCreated')}
          >
            <span>
              <SvgInfoHollow
                data-testid="spatial-root-warning"
                style={{
                  height: 'var(--iui-size-m)',
                  width: 'var(--iui-size-m)'
                }}
              />
            </span>
          </PWTooltip>
        ) : null}
      </div>
      <IModelMapTable
        tableDisabled={tableDisabled}
        iModelMappings={iModelMappings}
        setIModelMappings={setIModelMappings}
        updateIModelMapping={(document, isSheet, isMaster, isSpatialRoot) =>
          updateIModelMappings(
            iModelMappings,
            setIModelMappings,
            document,
            isSheet,
            isMaster,
            isSpatialRoot
          )
        }
      />
    </PWModal>
  );
}
