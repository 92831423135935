import type {
  HttpService,
  RequestOptions,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';

export async function getProjectDefaultEnvironmentInstance(
  projectId: string,
  includeDocCodeDefaults: boolean,
  httpService: HttpService,
  requestOptions?: RequestOptions
): Promise<WSGInstance[]> {
  const query = projectDefaultEnvironmentQuery(
    projectId,
    includeDocCodeDefaults
  );

  const response = await httpService.get(query, requestOptions);

  if (!response.ok && includeDocCodeDefaults) {
    return getProjectDefaultEnvironmentInstance(
      projectId,
      false,
      httpService,
      requestOptions
    );
  }

  const environmentInstances = await parseEnvironmentInstances(response);
  return environmentInstances;
}

function projectDefaultEnvironmentQuery(
  projectId: string,
  docCode: boolean
): string {
  const baseQuery = `PW_WSG/Project/${projectId}`;

  const relationshipClass =
    'ProjectDefaultEnvironment-forward-Environment!poly.*';

  const docCodeSelect = `includeDefaultDocCodeProperties=${String(docCode)}`;

  return `${baseQuery}?$select=${relationshipClass}&${docCodeSelect}`;
}

export async function parseEnvironmentInstances(
  response: Response
): Promise<WSGInstance[]> {
  const data = (await response.json()) as WSGInstancesResponse;

  const environmentInstances = data.instances?.[0]?.relationshipInstances?.map(
    (ri) => ri.relatedInstance
  );

  if (!environmentInstances?.length) {
    return [];
  }

  return environmentInstances;
}
