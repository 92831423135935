import type {
  HttpService,
  PWItem,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { parseRelatedEnvironmentSheets } from '@bentley/pw-api';
import {
  parseBindingsFromFormDefinition,
  parseEnvironmentInstances
} from '../../../actions/formRendering';
import { t } from '../../../services/translation';
import { generateReloadFormAction } from '../../documentCreation/form';

export async function getDocumentEnvironmentInstanceWithDefault(
  item: PWItem,
  httpService: HttpService
): Promise<WSGInstance> {
  try {
    const defaultDocumentEnvironmentInstance =
      await getDefaultDocumentEnvironmentInstance(item, true, httpService);
    const documentEnvironmentInstance = await getDocumentEnvironmentInstance(
      item,
      httpService
    );

    const mergedInstance = await mergeDefaultDocCodeAttributesIntoInstance(
      documentEnvironmentInstance,
      defaultDocumentEnvironmentInstance,
      httpService
    );

    return mergedInstance;
  } catch {
    return getDefaultDocumentEnvironmentInstance(item, true, httpService);
  }
}

async function getDocumentEnvironmentInstance(
  item: PWItem,
  httpService: HttpService
): Promise<WSGInstance> {
  const existingSheets = parseRelatedEnvironmentSheets(item);
  if (existingSheets.length) {
    return existingSheets[0];
  }

  const query = `PW_WSG/Environment!poly?$filter=DocumentEnvironment-backward-Document!poly.$id eq '${item.instanceId}'`;
  const response = await httpService.get(query, { uncached: true });
  if (!response?.ok) {
    throw response;
  }

  const wsgResponse = (await response.json()) as WSGInstancesResponse;
  handleWSGErrorResponse(wsgResponse);
  if (wsgResponse.instances && wsgResponse.instances.length > 0) {
    return wsgResponse.instances[0];
  }

  throw new Error(t('DocCode.EnvironmentNotFound'));
}

async function getDefaultDocumentEnvironmentInstance(
  item: PWItem,
  includeDefaultDocCodeProperties: boolean,
  httpService: HttpService
): Promise<WSGInstance> {
  const query = `PW_WSG/Project/${item.ParentGuid}?$select=ProjectDefaultEnvironment-forward-Environment!poly.*&includeDefaultDocCodeProperties=${includeDefaultDocCodeProperties}`;
  const response = await httpService.get(query);
  if (!response?.ok) {
    throw response;
  }

  const instances = await parseEnvironmentInstances(response);
  if (!instances.length) {
    return { properties: {} } as WSGInstance;
  }
  return instances[0];
}

async function mergeDefaultDocCodeAttributesIntoInstance(
  documentEnvironmentInstance: WSGInstance,
  defaultDocumentEnvironmentInstance: WSGInstance,
  httpService: HttpService
): Promise<WSGInstance> {
  if (
    !documentEnvironmentInstance.properties ||
    !defaultDocumentEnvironmentInstance.properties
  ) {
    return documentEnvironmentInstance;
  }

  const docCodeAttributes = await getDocCodeAttributes(
    documentEnvironmentInstance,
    httpService
  );

  for (const { attribute } of docCodeAttributes) {
    const existingValue = documentEnvironmentInstance.properties[attribute];
    if (existingValue) {
      continue;
    }

    const defaultValue =
      defaultDocumentEnvironmentInstance.properties[attribute];
    documentEnvironmentInstance.properties[attribute] = defaultValue;
  }

  return documentEnvironmentInstance;
}

async function getDocCodeAttributes(
  documentEnvironmentInstance: WSGInstance,
  httpService: HttpService
) {
  const form = await generateReloadFormAction(
    'documentcode',
    documentEnvironmentInstance,
    httpService
  );

  const dataBindings = parseBindingsFromFormDefinition(form.Definition);
  return dataBindings;
}

function handleWSGErrorResponse(response: WSGInstancesResponse): void {
  if (response.errorDescription || response.errorId || response.errorMessage) {
    const errorData = JSON.stringify({
      errorDescription: response.errorDescription,
      errorId: response.errorId,
      errorMessage: response.errorMessage
    });

    throw new Error(errorData);
  }
}
