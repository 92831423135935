import { useEffect, useState } from 'react';
import type { PWParentType, PWProject } from '@bentley/pw-api';
import {
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { getItemAncestors } from '../../../services/data';
import { addConnectionBreadcrumb } from '../retrieveBreadcrumbs';

export function useSavedSearchBreadcrumbs(): PWParentType[] {
  const { connection } = usePluginContext();
  const httpService = useHttpService();
  const {
    breadcrumbManager: { connectionBreadcrumb },
    searchState: { savedSearch }
  } = useNavigationContext();

  const [breadcrumbs, setBreadcrumbs] = useState<PWParentType[]>([]);

  useEffect(() => {
    function getSavedSearchBreadcrumbs(ancestors: PWProject[]): PWParentType[] {
      if (!savedSearch?.ParentProjectGuid) {
        return [connectionBreadcrumb];
      }

      const breadcrumbs = ancestors;

      addConnectionBreadcrumb(breadcrumbs, connection, connectionBreadcrumb);
      return breadcrumbs;
    }

    async function getSavedSearchParents(): Promise<void> {
      if (!savedSearch) {
        return;
      }
      const ancestors = await getItemAncestors(
        { ParentGuid: savedSearch.ParentProjectGuid } as PWProject,
        connectionBreadcrumb.instanceId,
        httpService
      );

      const savedSearchBreadcrumbs = getSavedSearchBreadcrumbs(ancestors);

      setBreadcrumbs(savedSearchBreadcrumbs);
    }

    void getSavedSearchParents();
  }, [savedSearch, connectionBreadcrumb, httpService, connection]);

  return breadcrumbs;
}
