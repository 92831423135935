import React from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { itemIsDocument } from '@bentley/pw-api';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import { ConflictResolutionModal } from '../conflictResolution';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import type { CreateCopyProperties } from './createCopy';

export function openConflictResolutionModal(
  sourceItems: PWItem[],
  destinationParent: PWProject,
  openModal: OpenModal,
  closeModal: CloseModal,
  onResolutionsComplete: (resolutions: ConflictResolution[]) => void
): void {
  openModal(
    <ConflictResolutionModal
      sourceItems={sourceItems.map((item) => ({ item }))}
      destinationParent={destinationParent}
      closeModal={closeModal}
      onClose={closeModal}
      doNotShowReplaceOption={true}
      doNotShowVersionOption={true}
      resolveConflicts={(resolutions: ConflictResolution[]) => {
        void handleResolutions(resolutions, onResolutionsComplete);
      }}
    />
  );
}

function handleResolutions(
  resolutions: ConflictResolution[],
  onResolutionsComplete: (resolutions: ConflictResolution[]) => void
) {
  resolutions = resolutions.filter((resolution) => resolution.action != 'keep');

  // Resolutions must be add and rename at this point since it is the only other option
  if (resolutions.length) {
    for (const resolution of resolutions) {
      if (itemIsDocument(resolution.sourceItem)) {
        resolution.sourceItem.Name = resolution.customName;
        resolution.sourceItem.FileName = resolution.customName;
        if (resolution.sourceItem.properties) {
          const { Name, Description, FileName, Version } =
            resolution.sourceItem;
          resolution.sourceItem.properties = {
            Name,
            Description,
            FileName,
            Version
          } as CreateCopyProperties;
        }
      } else {
        resolution.sourceItem.Name = resolution.customName;
      }
    }

    onResolutionsComplete(resolutions);
  }
}
