import './progressSummaryModal.css';

import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { Flex } from '@itwin/itwinui-react';
import { t } from '../../services/translation';
import { FailureItem } from '../failureItem';
import { PWModal } from '../pwModal';

export type ProgressSummaryItem = {
  item: PWItem;
  errorText?: string;
  uniqueId?: string;
  successText?: string;
};

type ProgressSummaryModalProps = {
  closeModal: () => void;
  summaries: ProgressSummaryItem[];
  onClose?: () => void;
  title: string;
};

export function ProgressSummaryModal({
  closeModal,
  summaries,
  onClose,
  title
}: ProgressSummaryModalProps): JSX.Element {
  function secondaryButtonHandle(): void {
    closeModal();
    onClose?.();
  }

  return (
    <PWModal
      title={title}
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: secondaryButtonHandle
      }}
      onClose={secondaryButtonHandle}
      className="fsm-modal"
      dialogProps={{ 'data-testid': 'ProgressSummaryModal' }}
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        className="fsm-list fsm-show-scroll"
      >
        {summaries
          .sort((a, b) => (a.item.Name < b.item.Name ? -1 : 1))
          .map((file) => (
            <FailureItem itemProgressSummary={file} key={file.item.Name} />
          ))}
      </Flex>
    </PWModal>
  );
}
