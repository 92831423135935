import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import { useAppViewContext } from '../../context';

type AllowProjectWise = (items: PWItem[]) => boolean;

export function useProjectWiseRequirements(): AllowProjectWise {
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const allowOpenInProjectWise = useCallback(
    (items: PWItem[]): boolean => {
      if (!items) {
        return false;
      }

      if (items.length != 1) {
        return false;
      }

      // Missing a parent in a search means user does not have access to the parent
      if (
        currentAppView.type != 'FolderNavigation' &&
        items.some((item) => !parseRelatedParent(item))
      ) {
        return false;
      }

      if (!allItemsExistInConnection(items)) {
        return false;
      }

      return true;
    },
    [allItemsExistInConnection, currentAppView.type]
  );

  return allowOpenInProjectWise;
}
