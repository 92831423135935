import type { PWItem } from '@bentley/pw-api';
import type { Accessor, CellValue } from '@itwin/itwinui-react/react-table';
import type { ColumnDataType } from './typedColumn';

export function propertyAccessor(
  property: keyof PWItem,
  columnDataType: ColumnDataType
): Accessor<PWItem> {
  function accessor(item: PWItem): CellValue {
    const value = item[property];
    return castValueToType<PWItem>(value, columnDataType);
  }

  return accessor;
}

export function lookupAccessor<T extends Record<string, unknown>>(
  lookupFunction: (item: T) => CellValue<T[keyof T]>,
  columnDataType: ColumnDataType
): Accessor<T> {
  function accessor(item: T): CellValue {
    const value = lookupFunction(item);
    return castValueToType(value, columnDataType);
  }

  return accessor;
}

function castValueToType<T>(
  value: T[keyof T],
  columnDataType: ColumnDataType
): number | boolean | Date | string | undefined {
  if (value === undefined) {
    return undefined;
  }

  if (columnDataType == 'number' || columnDataType == 'fileSize') {
    if (typeof value == 'string' && value == '') {
      return '';
    }

    return Number(value);
  }

  if (columnDataType == 'boolean') {
    if (typeof value == 'string' && value == '') {
      return undefined;
    }

    if (typeof value == 'string' && value.toLowerCase() == 'false') {
      return false;
    }

    return Boolean(value);
  }

  if (columnDataType == 'dateTime') {
    const date = new Date(String(value));

    if (date.toString() == 'Invalid Date') {
      return String(value);
    }

    return date;
  }

  return String(value);
}
