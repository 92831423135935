import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHttpService, usePluginContext } from '../../../context';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { useLocalStorage } from '../../useStorage';
import { getPWEViewColumns } from '../data/getPWEViewColumns';
import { getPWEViewsWithoutColumns } from '../data/pweViews';
import { defaultView } from '../defaultView';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function usePWEView(
  startLoad: boolean,
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const httpService = useHttpService();
  const { connection } = usePluginContext();

  const [views, setViews] = useState<View[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [currentViewName, setCurrentViewName] = useLocalStorage<string>(
    `pweView-${connection.Id}`,
    ''
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeViews(): Promise<void> {
      setInitialized(false);

      try {
        const views = await getPWEViewsWithoutColumns(
          connection.ProjectId,
          httpService,
          {
            abortController
          }
        );
        const validViews: View[] = [];
        for (const view of views) {
          try {
            await usingConcurrencyLimiter(async () => {
              const columns = await getPWEViewColumns(
                view.instanceId ?? '',
                httpService,
                { abortController }
              );
              validViews.push({ ...view, columns });
            }, 'background');
          } catch {
            continue;
          }
        }

        if (!abortController.signal.aborted) {
          setViews(validViews);
        }
      } catch {
        setViews([]);
      } finally {
        setInitialized(true);
      }
    }

    if (startLoad) {
      void initializeViews();
    }
    return () => {
      abortController.abort();
    };
  }, [connection.ProjectId, httpService, startLoad]);

  const saveView = useCallback(() => {
    throw new Error('Save PWE view not supported');
  }, []);

  const setView = useCallback(
    (view?: View) => {
      if (view) {
        setCurrentViewName(view.name);
        setCurrentViewType('PWESaved');
      }
    },
    [setCurrentViewName, setCurrentViewType]
  );

  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit PWE view not supported');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete PWE view not supported');
  }, []);

  const view = useMemo(
    (): View =>
      views.find((view) => view.name == currentViewName) ?? defaultView,
    [currentViewName, views]
  );

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized,
      view,
      views,
      saveView,
      setView,
      editView,
      deleteView
    }),
    [deleteView, editView, initialized, saveView, setView, view, views]
  );

  return viewSetting;
}
