import { useCallback, useMemo } from 'react';
import type { ColumnDataType } from '../../components/pwTable';
import { usePluginContext } from '../../context';
import { useLocalStorage } from '../useStorage';
import type { ColumnDisplayOptions, View } from '../useViews';
import { defaultColumnWidth, minColumnWidth } from './defaultWidths';

export type ColumnWidthManager = {
  getColumnWidth: (columnId: string, options?: ColumnWidthOptions) => number;
  updateColumnWidths: (
    newResizeInfo: Record<string, number | undefined>
  ) => void;
};

export type ColumnWidthOptions = {
  columnDataType?: ColumnDataType;
} & ColumnDisplayOptions;

export function useColumnWidths(currentView: View): ColumnWidthManager {
  const { connection } = usePluginContext();

  const [columnWidths, setColumnWidths] = useLocalStorage<
    Record<string, number | undefined>
  >(`columnWidths:${connection.Id}`, {});

  const viewColumnName = useCallback(
    (columnId: string): string => {
      return `${currentView.name}:${columnId}`;
    },
    [currentView.name]
  );

  const getMostSpecificColumnWidth = useCallback(
    (columnId: string, options?: ColumnWidthOptions): number => {
      const viewColumnWidth = columnWidths[viewColumnName(columnId)];
      if (viewColumnWidth) {
        return viewColumnWidth;
      }

      const viewAgnosticColumnWidth = columnWidths[columnId];
      if (viewAgnosticColumnWidth) {
        return viewAgnosticColumnWidth;
      }

      const viewDefinitionWidth = currentView.columns.find(
        ({ propertyType, name }) => `${propertyType}.${name}` == columnId
      )?.width;
      if (viewDefinitionWidth) {
        return viewDefinitionWidth * 2;
      }

      return defaultColumnWidth(columnId, options);
    },
    [columnWidths, currentView.columns, viewColumnName]
  );

  const getColumnWidth = useCallback(
    (columnId: string, options?: ColumnWidthOptions): number => {
      const columnWidth = getMostSpecificColumnWidth(columnId, options);

      if (columnWidth < minColumnWidth) {
        return minColumnWidth;
      }

      return columnWidth;
    },
    [getMostSpecificColumnWidth]
  );

  const updateColumnWidths = useCallback(
    (updatedWidths: Record<string, number | undefined>): void => {
      const newWidths = Object.entries(updatedWidths).reduce(
        (widths, [id, width]) => {
          widths[viewColumnName(id)] = width;
          widths[id] = width;
          return widths;
        },
        {} as Record<string, number | undefined>
      );

      setColumnWidths({ ...columnWidths, ...newWidths });
    },
    [columnWidths, setColumnWidths, viewColumnName]
  );

  const columnWidthFunctions = useMemo(
    (): ColumnWidthManager => ({ getColumnWidth, updateColumnWidths }),
    [getColumnWidth, updateColumnWidths]
  );

  return columnWidthFunctions;
}
