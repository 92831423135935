import { useCallback, useState } from 'react';
import type { CloseModal, ModalElement, OpenModal } from './modalElement';

/**
 * Hook to control current modal open
 * @returns currently set modal as JSX.Element, function to open a given modal, function to close the current modal
 */
export const useModal = (): [ModalElement, OpenModal, CloseModal] => {
  const [currentModal, setCurrentModal] = useState<ModalElement>(null);

  const openModal = useCallback((modal: ModalElement): void => {
    if (modal) {
      setCurrentModal(modal);
    }
  }, []);

  const closeModal = useCallback((): void => {
    setCurrentModal(null);
  }, []);

  return [currentModal, openModal, closeModal];
};
