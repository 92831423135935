import './emptyConnectionsState.css';

import type { ReactNode } from 'react';
import React from 'react';
import { Trans } from 'react-i18next';
import { useBuddi } from '@bentley/pw-config';
import { Anchor, Text } from '@itwin/itwinui-react';
import type { DataAccessLevel } from '../../context';
import { useConnectionsContext } from '../../context';
import { useLinkStatus } from '../../hooks/useLinkStatus';
import { t } from '../../services/translation';
import { ErrorPage } from '../errorPage';
import { SvgProjectWiseWeb, SvgProjectWiseWebView } from '../icons';

type EmptyConnectionsStateProps = {
  buddiRegionCode: string;
  dataAccessLevel: DataAccessLevel;
  emptyConnectionsContent?: ReactNode;
};

export function EmptyConnectionsState({
  buddiRegionCode,
  dataAccessLevel,
  emptyConnectionsContent
}: EmptyConnectionsStateProps): JSX.Element | null {
  const { repositoryManager } = useConnectionsContext();

  const connectPortalUrl = useBuddi('ConnectPortalUrl', buddiRegionCode);
  const linkStatus = useLinkStatus({ dataAccessLevel });

  function emptyStateMessage(): string {
    if (!repositoryManager.federatedRepos?.length) {
      return dataAccessLevel == 'Datasource'
        ? t('NoConnectionSelected.NoDatasourceAdded')
        : t('NoConnectionSelected.NoWorkAreaAdded');
    }

    return dataAccessLevel == 'Datasource'
      ? t('NoConnectionSelected.SelectDatasource')
      : t('NoConnectionSelected.SelectWorkArea');
  }

  if (
    !repositoryManager.initialized ||
    linkStatus == 'loading' ||
    linkStatus == 'validLink' ||
    !connectPortalUrl
  ) {
    return null;
  }

  if (linkStatus == 'invalidLink') {
    return (
      <ErrorPage errorType="404" errorMsg={t('SharedLink.PWLinkNotFound')} />
    );
  }

  const productIcon =
    dataAccessLevel == 'Datasource' ? (
      <SvgProjectWiseWebView className="p-w-c-empty-state-icon" />
    ) : (
      <SvgProjectWiseWeb className="p-w-c-empty-state-icon" />
    );

  const defaultEmptyConnectionsContent = (
    <Text>
      <Trans i18nKey="NoConnectionSelected.ContactYourAdmin">
        Contact your{' '}
        <Anchor
          href={connectPortalUrl + '/?showAdmin=true'}
          target="_blank"
          isExternal={true}
        >
          ProjectWise administrator
        </Anchor>
      </Trans>
    </Text>
  );

  const emptyConnectionsComponent =
    emptyConnectionsContent ?? defaultEmptyConnectionsContent;

  return (
    <div className="p-w-c-no-connections pw-empty-state">
      {productIcon}
      <Text>{emptyStateMessage()}</Text>
      {!repositoryManager.federatedRepos?.length && emptyConnectionsComponent}
    </div>
  );
}
