import type { User } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { PluginWrapper } from '../../../components/pluginWrapper';
import type { GetToken } from '../../../services/http';
import { WithItemResolver } from '../../itemResolver';
import {
  showGraphCheckInErrorModal,
  showGraphFreeErrorModal
} from '../graphErrorModal';
import type { GraphErrorSummaryItem } from '../graphErrorSummary';
import { GraphErrorModalPluginsProvider } from './graphErrorModalPluginsProvider';
import type { GraphErrorSummaryPluginItem } from './graphErrorSummaryPlugin';

export type GraphErrorModalPluginProps = {
  baseWsgUrl: string;
  user: User;
  getOidcToken: GetToken;
  getSamlToken: GetToken;
} & GraphErrorModalResolverProps;

export function GraphErrorModalPlugin(
  props: GraphErrorModalPluginProps
): JSX.Element {
  return (
    <PluginWrapper>
      <GraphErrorModalPluginsProvider {...props}>
        <GraphErrorModalResolver {...props} />
      </GraphErrorModalPluginsProvider>
    </PluginWrapper>
  );
}

type GraphErrorModalResolverProps = {
  freeOperation: boolean;
  instanceIds: string[];
  summaries: GraphErrorSummaryPluginItem[];
  user: User;
  onShow: (title?: string) => void;
  onHide: () => void;
  onClose: () => void;
  onContinue: (instanceIds: string[]) => void;
};

function GraphErrorModalResolver({
  freeOperation,
  instanceIds,
  summaries,
  user,
  onShow,
  onHide,
  onClose,
  onContinue
}: GraphErrorModalResolverProps): JSX.Element {
  const [items, setItems] = useState<PWItem[]>();
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();

  useEffect(() => {
    if (!items) {
      return;
    }

    const showModal = freeOperation
      ? showGraphFreeErrorModal
      : showGraphCheckInErrorModal;

    const errors = summaries.map(
      (summary, idx) =>
        ({
          item: items[idx],
          type: summary.type,
          errorTextKey: summary.errorTextKey,
          participants: summary.participants
        } as GraphErrorSummaryItem)
    );

    showModal(
      instanceIds.map((id) => ({ instanceId: id } as PWItem)),
      errors,
      () => null,
      onClose,
      (files) =>
        Promise.resolve(onContinue(files.map((file) => file.instanceId))),
      user,
      (title, content) => {
        setTitle(title);
        setContent(content);
      }
    );
  }, [freeOperation, instanceIds, items, onClose, onContinue, summaries, user]);

  useEffect(() => {
    content && title ? onShow(title) : onHide();
  }, [content, onHide, onShow, title]);

  const ItemLoaderWrapper = WithItemResolver(
    ItemLoader,
    summaries.map((summary) => summary.instanceId) as [string, ...string[]]
  );

  if (!content) {
    return <ItemLoaderWrapper onItemsLoaded={setItems} />;
  }

  return content;
}

function ItemLoader({
  items,
  onItemsLoaded
}: {
  items: PWItem[];
  onItemsLoaded: (items: PWItem[]) => void;
}): JSX.Element {
  useEffect(() => onItemsLoaded(items), [items, onItemsLoaded]);
  return <></>;
}
