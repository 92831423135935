import type { RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RequestOptions } from '@bentley/pw-api';
import { useTelemetry } from '../../context';

export type DataManager<T> = {
  error?: Error | Response;
  isLoading: boolean;
  items: T[];
  refresh: (condition?: () => boolean) => void;
};

type DataRequest<T> = (httpOptions?: RequestOptions) => Promise<T[]>;
export type SecondaryRequest<T> = (
  items: T[],
  httpOptions?: RequestOptions
) => Promise<void> | void;

type UseDataParams<T> = {
  dataRequest: DataRequest<T>;
  secondaryRequests?: SecondaryRequest<T>[];
  secondaryBatchSize?: number;
  intervalRequests?: SecondaryRequest<T>[];
  requestInterval?: number;
  suspendIntervalRequest?: RefObject<boolean>;
};

export function useData<T>({
  dataRequest,
  secondaryRequests,
  secondaryBatchSize = 1,
  intervalRequests,
  requestInterval,
  suspendIntervalRequest
}: UseDataParams<T>): DataManager<T> {
  const { startTelemetry, endTelemetry } = useTelemetry();

  const [initialItems, setInitialItems] = useState<T[]>([] as T[]);
  const [items, setItems] = useState<T[]>([] as T[]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [forceRefresh, setForceRefresh] = useState<number>(0);
  const prevRefreshState = useRef<number>(forceRefresh);

  const refresh = useCallback((condition?: () => boolean): void => {
    if (condition == undefined || condition()) {
      setForceRefresh((cur) => ++cur);
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const doNotReturnCached = forceRefresh != prevRefreshState.current;

    async function fetchPrimaryData() {
      setError(undefined);
      setIsLoading(true);
      setItems([]);

      try {
        const data = await dataRequest({
          abortController,
          uncached: doNotReturnCached || undefined
        });
        if (!abortController.signal.aborted) {
          setInitialItems(data);
          setItems(data);
        }
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err as Error);
        }
      }

      if (!abortController.signal.aborted) {
        prevRefreshState.current = forceRefresh;
        setIsLoading(false);
      }
    }

    void fetchPrimaryData();

    return () => {
      abortController.abort();
    };
  }, [dataRequest, forceRefresh]);

  useEffect(() => {
    const abortController = new AbortController();
    const doNotReturnCached = forceRefresh != prevRefreshState.current;

    async function fetchSecondaryData(data: T[]): Promise<void> {
      if (secondaryRequests?.length && !abortController.signal.aborted) {
        startTelemetry('SecondaryDataLoad');

        const partitionedData = partitionArray(data, secondaryBatchSize);
        for (const partition of partitionedData) {
          for (const secondaryRequest of secondaryRequests ?? []) {
            if (abortController.signal.aborted) {
              return;
            }

            try {
              await secondaryRequest(partition, {
                abortController,
                uncached: doNotReturnCached || undefined
              });
            } catch (e) {
              // Move on to next request
            }
          }

          if (abortController.signal.aborted) {
            return;
          }

          setImmediate(() => setItems(data.map((item) => ({ ...item }))));

          // Wait for the next event loop before continuing; this allows the UI to update
          await new Promise((resolve) => setTimeout(resolve));
        }

        endTelemetry('SecondaryDataLoad', {
          numItems: items.length,
          numRequests: secondaryRequests.length
        });
      }

      void fetchIntervalData(data);
    }

    async function fetchIntervalData(data: T[]): Promise<void> {
      if (!intervalRequests?.length || abortController.signal.aborted) {
        return;
      }

      for (const intervalRequest of intervalRequests) {
        if (abortController.signal.aborted) {
          return;
        }

        if (!suspendIntervalRequest?.current) {
          try {
            await intervalRequest(data, {
              abortController,
              uncached: doNotReturnCached || undefined
            });
            if (abortController.signal.aborted) {
              return;
            }
            setItems(data.map((item) => ({ ...item })));
          } catch (e) {
            // Move on to next request
          }
        }
      }

      if (requestInterval && requestInterval > 0) {
        setTimeout(() => {
          void fetchIntervalData(data);
        }, requestInterval);
      }
    }

    if (initialItems.length && !abortController.signal.aborted) {
      setImmediate(() => void fetchSecondaryData(initialItems));
    }

    return () => {
      abortController.abort();
    };
  }, [
    initialItems,
    forceRefresh,
    intervalRequests,
    requestInterval,
    secondaryBatchSize,
    secondaryRequests,
    suspendIntervalRequest
  ]);

  const dataManager = useMemo(
    (): DataManager<T> => ({ isLoading, error, items, refresh }),
    [error, isLoading, items, refresh]
  );

  return dataManager;
}

function partitionArray<T>(arr: T[], partitionSize: number): T[][] {
  return arr.reduce((acc, cur, idx) => {
    if (idx % partitionSize == 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, [] as T[][]);
}
