// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><path fill=\"%238f8f8f\" d=\"M7 10l5 5 5-5z\"/></svg>", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><path fill=\"%23707070\" d=\"M7 10l5 5 5-5z\"/></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-with-children {\r\n  &:after {\r\n    content: '';\r\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    width: 24px;\r\n    height: 24px;\r\n    position: absolute;\r\n    right: -10px;\r\n    top: 20px;\r\n    transform: rotate(315deg);\r\n  }\r\n\r\n  &:disabled {\r\n    &::after {\r\n      opacity: 40%;\r\n    }\r\n  }\r\n}\r\n\r\n[data-iui-theme='dark'] {\r\n  .button-with-children {\r\n    &:after {\r\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n    }\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://./../../packages/projectwise/src/components/toolbar/pwToolbar/toolbarButton.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;IACX,mDAA+K;IAC/K,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,yBAAyB;EAC3B;;EAEA;IACE;MACE,YAAY;IACd;EACF;AACF;;AAEA;EACE;IACE;MACE,mDAA+K;IACjL;EACF;AACF","sourcesContent":[".button-with-children {\r\n  &:after {\r\n    content: '';\r\n    background: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><path fill=\"%238f8f8f\" d=\"M7 10l5 5 5-5z\"/></svg>');\r\n    width: 24px;\r\n    height: 24px;\r\n    position: absolute;\r\n    right: -10px;\r\n    top: 20px;\r\n    transform: rotate(315deg);\r\n  }\r\n\r\n  &:disabled {\r\n    &::after {\r\n      opacity: 40%;\r\n    }\r\n  }\r\n}\r\n\r\n[data-iui-theme='dark'] {\r\n  .button-with-children {\r\n    &:after {\r\n      background: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><path fill=\"%23707070\" d=\"M7 10l5 5 5-5z\"/></svg>');\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
