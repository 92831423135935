import type {
  HttpService,
  PWDataItem,
  PWItem,
  WSGRelationshipInstance
} from '@bentley/pw-api';
import {
  getDataItems,
  getProjects,
  itemIsDataItem,
  itemIsProject,
  parseResponseInstances
} from '@bentley/pw-api';
import {
  notifyRenditionInProgress,
  notifyRenditionSubmitted
} from './notifications';
import type { RenditionProfile } from './renditionProfile';
import type { RenditionSelection, RenditionSubmitAction } from './utils';
import { flattenRendtionData, getLargestRenditionData } from './utils';

export async function getRenditionProfiles(
  workAreaIdString: string,
  httpService: HttpService
): Promise<RenditionProfile[]> {
  const response = await httpService.get(
    `PW_WSG/Project?$filter=$id+in+[${workAreaIdString}]&$select=ProjectResource-forward-RendSvcProfile!poly.*,ProjectResource-forward-RendSvcProfile!poly/RenditionProfileComponent-forward-RendSvcComponent!poly.*,ProjectResource-forward-RendSvcProfile!poly/RenditionProfileComponent.*,ProjectDefaultRendSvcProfile-forward-RendSvcProfile!poly.Name`
  );
  const responseData = await parseResponseInstances<RenditionProfile>(response);
  const renditionsResources = flattenRendtionData(responseData);
  // It is possible for multiple rendition data to be queried if user is in a flatset. In this case we use the rendition with the largest data
  return getLargestRenditionData(renditionsResources);
}

async function getRenditionItems(
  items: PWItem[],
  httpService: HttpService
): Promise<PWItem[]> {
  let renditionItems: PWItem[] = items.filter((item) => itemIsDataItem(item));
  const renditionFolders = items.filter((item) => itemIsProject(item));
  const folderItems = await Promise.all(
    renditionFolders.map(async (folder) => {
      const subFolders = await getProjects({
        parentId: folder.instanceId,
        httpService
      });
      const subFolderItems = await getRenditionItems(subFolders, httpService);
      const folderItems = await getDataItems({
        parentId: folder.instanceId,
        httpService
      });
      return [...subFolderItems, ...folderItems];
    })
  );
  folderItems.forEach((folderItemList) => {
    renditionItems = [...renditionItems, ...folderItemList];
  });
  return renditionItems;
}

export async function createRendition(
  httpService: HttpService,
  renditionSelection: RenditionSelection,
  items: PWItem[]
): Promise<Response> {
  const postQuery = 'PW_WSG/RenditionSubmitAction';
  const renditionItems = await getRenditionItems(items, httpService);
  const postBody = getCreateRenditionBody(
    renditionSelection,
    renditionItems as PWDataItem[]
  );
  const toastHandle = notifyRenditionInProgress();
  const response = await httpService.post(postQuery, postBody);
  let responseMessage = '';
  if (!response.ok) {
    const responseJson = (await response.json()) as Record<string, string>;
    if (responseJson && responseJson.errorMessage) {
      responseMessage = responseJson.errorMessage;
    }
  }
  notifyRenditionSubmitted(toastHandle, responseMessage);
  return response;
}

function getCreateRenditionBody(
  renditionSelection: RenditionSelection,
  documents: PWDataItem[]
): string {
  const body = {
    instance: {
      schemaName: 'PW_WSG',
      className: 'RenditionSubmitAction',
      properties: {
        NotifyUserOnCompletion: renditionSelection.sendEmail as boolean
      },
      relationshipInstances: getRenditionRelationships(
        renditionSelection,
        documents
      )
    }
  };
  return JSON.stringify(body);
}

function getRenditionRelationships(
  renditionSelection: RenditionSelection,
  documents: PWDataItem[]
): (WSGRelationshipInstance | undefined)[] {
  // required choices
  const documentActions = documents.map((document) => {
    return getRenditionRelationship(
      'RenditionSubmitActionDocument',
      document.className,
      document.instanceId
    );
  });
  const renditionAction = getRenditionRelationship(
    'RenditionSubmitActionRenditionOption',
    'RendSvcRenditionOption',
    renditionSelection.formatId ?? ''
  );
  const fileNameAction = getRenditionRelationship(
    'RenditionSubmitActionFilenameOption',
    'RendSvcFilenameOption',
    renditionSelection.fileNameId ?? ''
  );
  const destinationAction = getRenditionRelationship(
    'RenditionSubmitActionDestinationOption',
    'RendSvcDestinationOption',
    renditionSelection.destinationId ?? ''
  );
  // optional choices
  const presentationAction = renditionSelection.presentationId
    ? getRenditionRelationship(
        'RenditionSubmitActionPresentationOption',
        'RendSvcPresentationOption',
        renditionSelection.presentationId
      )
    : undefined;
  const outputProjectAction = renditionSelection.folder
    ? getRenditionRelationship(
        'RenditionSubmitActionOutputProject',
        'Project',
        renditionSelection.folder
      )
    : undefined;

  const actions = [
    ...documentActions,
    renditionAction,
    fileNameAction,
    destinationAction,
    presentationAction,
    outputProjectAction
  ];
  // remove any possible undefined action choices
  const filteredActions = actions.filter((action) => {
    return action !== undefined;
  });
  return filteredActions;
}

function getRenditionRelationship(
  renditionActionType: RenditionSubmitAction,
  className: string,
  instanceId: string
): WSGRelationshipInstance {
  return {
    className: renditionActionType,
    schemaName: 'PW_WSG',
    direction: 'forward',
    relatedInstance: {
      instanceId: instanceId,
      schemaName: 'PW_WSG',
      className: className
    }
  };
}
