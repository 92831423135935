import type { DataError } from '../../../actions/formRendering';
import { getErrorMessage } from '../../../actions/formRendering';
import { openToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';

export async function handleAttributesFormError(
  error: Response | Error
): Promise<void> {
  const content = await getErrorMessage(error as DataError);
  openToast({ content, category: 'negative' });
}

export function handleAttributesFormSuccess(): void {
  const content = t('AttributesForm.AttributesSaved');
  openToast({ content, category: 'positive' });
}
