import type { Dispatch, ProviderProps, RefObject, SetStateAction } from 'react';
import React, {
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import type { DropzoneRef } from 'react-dropzone';
import type { BreadcrumbManager } from '../../../hooks/useBreadcrumb';
import { useBreadcrumbs } from '../../../hooks/useBreadcrumb';
import type {
  CloseModal,
  ModalElement,
  OpenModal
} from '../../../hooks/useModal';
import { useModal } from '../../../hooks/useModal';
import type { NavigationManager } from '../../../hooks/useNavigation';
import { usePWNavigation } from '../../../hooks/useNavigation';
import { useProductSetting } from '../../../hooks/useProductSetting';
import type { SearchState } from '../../../hooks/useSearchState';
import { useSearchState } from '../../../hooks/useSearchState';
import { usePluginContext } from '../..';

type NavigationContext = {
  allowDragAndDrop: boolean;
  autoDisplayProgressModal?: boolean;
  breadcrumbManager: BreadcrumbManager;
  dropzoneRef: RefObject<DropzoneRef>;
  infoPanelDisplayed: boolean;
  navigationManager: NavigationManager;
  primaryModal: { modal: ModalElement; open: OpenModal; close: CloseModal };
  searchState: SearchState;
  showDropzone: boolean;
  setAllowDragAndDrop: Dispatch<SetStateAction<boolean>>;
  setAutoDisplayProgressModal: (display: boolean) => void;
  setInfoPanelDisplayed: Dispatch<SetStateAction<boolean>>;
  setShowDropzone: Dispatch<SetStateAction<boolean>>;
};

const Context = createContext<NavigationContext | undefined>(undefined);

export function NavigationProvider({
  children
}: ProviderProps<NavigationContext | undefined>): JSX.Element {
  const {
    progressManager: { setAutoDisplayModal }
  } = usePluginContext();

  const [infoPanelDisplayed, setInfoPanelDisplayed] = useState<boolean>(false);
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [allowDragAndDrop, setAllowDragAndDrop] = useState<boolean>(true);
  const [autoDisplayProgressModal, setAutoDisplayProgressModal] =
    useProductSetting('autoDisplayProgressModal', {
      defaultValue: true,
      settingType: 'User'
    });

  const searchState = useSearchState();
  const breadcrumbManager = useBreadcrumbs();
  const navigationManager = usePWNavigation(
    breadcrumbManager,
    setInfoPanelDisplayed,
    setShowDropzone
  );

  const [modal, open, close] = useModal();
  const primaryModal = useMemo(
    () => ({ modal, open, close }),
    [close, modal, open]
  );

  const dropzoneRef = createRef<DropzoneRef>();

  useEffect(() => {
    setAutoDisplayModal(autoDisplayProgressModal ?? true);
  }, [autoDisplayProgressModal, setAutoDisplayModal]);

  const navigationContext = useMemo(
    (): NavigationContext => ({
      allowDragAndDrop,
      autoDisplayProgressModal,
      breadcrumbManager,
      dropzoneRef,
      infoPanelDisplayed,
      navigationManager,
      primaryModal,
      searchState,
      showDropzone,
      setAllowDragAndDrop,
      setAutoDisplayProgressModal,
      setInfoPanelDisplayed,
      setShowDropzone
    }),
    [
      autoDisplayProgressModal,
      breadcrumbManager,
      dropzoneRef,
      allowDragAndDrop,
      infoPanelDisplayed,
      navigationManager,
      primaryModal,
      searchState,
      setAutoDisplayProgressModal,
      showDropzone
    ]
  );

  return (
    <Context.Provider value={navigationContext}>{children}</Context.Provider>
  );
}

export function useNavigationContext(): NavigationContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useNavigationContext must be used within a NavigationContext'
    );
  }
  return context;
}
