import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import { SvgFolderOpened } from '@itwin/itwinui-icons-react';
import { useLinkBaseUrl } from '../../../../actions/getShareableLink';
import {
  allowGoToConnectedProject,
  goToConnectedProject
} from '../../../../actions/goToConnectedProject';
import { useAppViewContext, usePluginContext } from '../../../../context';
import { useLastFolderWithConnectedProject } from '../../../../hooks/useLastFolderWithConnectedProject';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useGoToProjectItemAction(items: PWItem[]): MenuItemAction {
  const { buddiRegionCode, connection, readOnly } = usePluginContext();

  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const lastFolderWithConnectedProject = useLastFolderWithConnectedProject();

  const linkBaseUrl = useLinkBaseUrl(buddiRegionCode);

  const item = items[0];

  const showGoToProject = useMemo((): boolean => {
    if (!readOnly) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    if (!lastFolderWithConnectedProject && !allowGoToConnectedProject(items)) {
      return false;
    }

    return true;
  }, [currentAppView.type, items, lastFolderWithConnectedProject, readOnly]);

  const enableGoToProject = useMemo((): boolean => {
    return items.length == 1;
  }, [items]);

  const goToProject = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.GoToProject'),
      icon: <SvgFolderOpened />,
      onClick: () => {
        const itemWithConnectedProject =
          itemIsProject(item) && item.ConnectedProjectGuid
            ? item
            : lastFolderWithConnectedProject;
        if (!itemWithConnectedProject) {
          return;
        }
        goToConnectedProject(itemWithConnectedProject, connection, linkBaseUrl);
      },
      hidden: !showGoToProject,
      disabled: !enableGoToProject
    }),
    [
      showGoToProject,
      enableGoToProject,
      item,
      lastFolderWithConnectedProject,
      connection,
      linkBaseUrl
    ]
  );

  return goToProject;
}
