import type {
  HttpService,
  PWItem,
  PWProject,
  WSGChangedInstanceResponse
} from '@bentley/pw-api';
import {
  filterProjects,
  itemIsDocument,
  itemIsFlatSet,
  itemIsProject
} from '@bentley/pw-api';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ToastHandle } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { ConflictResolution } from '../conflictResolution';
import { openConflictResolutionModal } from './conflictResolution';
import {
  notifyCopySuccess,
  notifyCreateCopyInProgress,
  notifyErrorDuringCopy,
  on400Error,
  on403Error,
  on409Error
} from './notifications';
import {
  getConflictingItems,
  getCopyDocumentQueryBody,
  getCopyProjectQueryBody,
  getCopySetQueryBody,
  parseCopyInstance
} from './utils';

export type CreateCopyProperties = {
  Name: string;
  Description: string;
  FileName?: string;
  Version?: string;
};

export async function createCopyWorkflow(
  httpService: HttpService,
  items: PWItem[],
  destinationFolder: PWProject,
  onComplete: (instanceId?: string[]) => void,
  openModal: OpenModal,
  closeModal: CloseModal,
  usingDCW: boolean,
  includeEnvironmentInstance?: boolean
): Promise<Response[]> {
  const toastHandle = notifyCreateCopyInProgress(items.length);
  const responses = await createCopyOfItems(
    httpService,
    items,
    destinationFolder,
    toastHandle,
    usingDCW,
    includeEnvironmentInstance
  );
  await handleSuccessfulResponse(responses, onComplete, toastHandle);
  handleConflicts(
    items,
    responses,
    destinationFolder,
    openModal,
    closeModal,
    httpService,
    onComplete,
    toastHandle,
    usingDCW
  );

  return responses;
}

export async function createCopyOfItems(
  httpService: HttpService,
  items: PWItem[],
  destinationFolder: PWProject,
  toastHandle: ToastHandle,
  usingDCW: boolean,
  includeEnvironmentInstance?: boolean
): Promise<Response[]> {
  const properties = getItemProperties(items);

  const responses = await Promise.all(
    items.map((item, index) => {
      if (itemIsDocument(item)) {
        return createCopyOfDocument(
          httpService,
          item,
          destinationFolder,
          properties[index],
          toastHandle,
          usingDCW,
          includeEnvironmentInstance
        );
      } else if (itemIsFlatSet(item)) {
        return createCopyOfSet(
          httpService,
          item,
          destinationFolder,
          properties[index],
          toastHandle
        );
      } else if (itemIsProject(item)) {
        return createCopyOfProject(
          httpService,
          item,
          destinationFolder,
          properties[index],
          toastHandle
        );
      } else {
        throw new Error('Item type cannot be copied');
      }
    })
  );
  return responses;
}

export async function createCopyOfDocument(
  httpService: HttpService,
  item: PWItem,
  destinationFolder: PWProject,
  property: CreateCopyProperties,
  toastHandle: ToastHandle,
  usingDCW: boolean,
  includeEnvironmentInstance?: boolean
): Promise<Response> {
  const environmentInstance = item.relationshipInstances?.find(
    (relIns) => relIns.className == 'DocumentEnvironment'
  )?.relatedInstance;

  const body = getCopyDocumentQueryBody(
    item.instanceId,
    property,
    destinationFolder,
    usingDCW,
    includeEnvironmentInstance ? environmentInstance : undefined
  );
  const query = 'PW_WSG/Document';
  const response = await httpService.post(query, body);
  await handleBadResponse(response, item, toastHandle);
  return response;
}

export async function createCopyOfProject(
  httpService: HttpService,
  item: PWItem,
  destinationFolder: PWProject,
  property: CreateCopyProperties,
  toastHandle: ToastHandle
): Promise<Response> {
  const body = getCopyProjectQueryBody(
    item.instanceId,
    property,
    destinationFolder
  );

  const query = 'PW_WSG/Project';
  const response = await httpService.post(query, body);
  await handleBadResponse(response, item, toastHandle);
  return response;
}

export async function createCopyOfSet(
  httpService: HttpService,
  item: PWItem,
  destinationFolder: PWProject,
  property: CreateCopyProperties,
  toastHandle: ToastHandle
): Promise<Response> {
  const body = getCopySetQueryBody(
    item.instanceId,
    property,
    destinationFolder
  );

  const query = 'PW_WSG/FlatSet';
  const response = await httpService.post(query, body);
  await handleBadResponse(response, item, toastHandle);
  return response;
}

function getItemProperties(items: PWItem[]): CreateCopyProperties[] {
  const itemsProperties = items.map((item) => {
    if (item.properties) {
      return item.properties as CreateCopyProperties;
    }

    if (itemIsDocument(item)) {
      const { Name, Description, FileName, Version } = item;
      return { Name, Description, FileName, Version } as CreateCopyProperties;
    } else {
      const { Name, Description } = item;
      return { Name, Description } as CreateCopyProperties;
    }
  });
  return itemsProperties;
}

async function handleBadResponse(
  response: Response,
  item: PWItem,
  toastHandle: ToastHandle
): Promise<void> {
  if (response.ok || response.status == 409) {
    return;
  }

  if (response.status == 403) {
    notifyErrorDuringCopy(toastHandle, on403Error());
    return;
  }
  if (response.status == 400) {
    const data = (await response.clone().json()) as WSGChangedInstanceResponse;
    notifyErrorDuringCopy(toastHandle, data.errorMessage ?? on400Error(item));
    return;
  }
  notifyErrorDuringCopy(
    toastHandle,
    response.statusText || t('Generic.FileNotFound')
  );
}

export async function handleSuccessfulResponse(
  responses: Response[],
  onComplete: (instanceId?: string[]) => void,
  toastHandle: ToastHandle
): Promise<void> {
  const parsedInstances = await Promise.all(
    responses
      .filter((resp) => resp.ok)
      .map((response) => parseCopyInstance(response))
  );
  const copiedItemsInstanceIds = parsedInstances.map(
    (parsedInstance) => parsedInstance.instanceId
  );

  if (copiedItemsInstanceIds.length) {
    notifyCopySuccess(copiedItemsInstanceIds.length, toastHandle);
    onComplete(copiedItemsInstanceIds);
  } else {
    onComplete();
  }
}

function handleConflicts(
  items: PWItem[],
  responses: Response[],
  destinationFolder: PWProject,
  openModal: OpenModal,
  closeModal: CloseModal,
  httpService: HttpService,
  onComplete: (instanceId?: string[]) => void,
  toastHandle: ToastHandle,
  usingDCW: boolean
): void {
  toastHandle.close();

  const properties = getItemProperties(items);

  const conflictingItems = getConflictingItems(items, responses, properties);

  if (handleFolderConflicts(filterProjects(conflictingItems), toastHandle)) {
    // PW API project copying does not support rename, so not using conflict res modal
    return;
  }

  if (conflictingItems.length) {
    const onResolutionsComplete = (resolutions: ConflictResolution[]) => {
      void createCopyWorkflow(
        httpService,
        resolutions.map((resolution) => resolution.sourceItem),
        destinationFolder,
        onComplete,
        openModal,
        closeModal,
        usingDCW
      );
    };

    openConflictResolutionModal(
      conflictingItems,
      destinationFolder,
      openModal,
      closeModal,
      onResolutionsComplete
    );
  }
}

function handleFolderConflicts(
  folders: PWProject[],
  toastHandle: ToastHandle
): boolean {
  if (folders.length) {
    for (const folder of folders) {
      notifyErrorDuringCopy(toastHandle, on409Error(folder));
    }
    return true;
  }

  return false;
}
