import { t } from '../translation';

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

/**
 * Returns formatted file size based on bytes
 *
 * File size is returned with minimum unit of KB and maximum of PB, with 3 significant digits.
 * E.g., a FileSize of 20971520 will return '20.0 MB'
 * @param {string | number} fileSize FileSize in bytes
 * @returns {string} Formatted file size.
 * If item does not have FileSize or FileSize cannot be determined, an empty string is returned.
 */
export function formatFileSize(fileSize: string | number): string {
  if (!fileSize) {
    return '';
  }

  const bytes = +fileSize || 0;

  if (bytes == 0) {
    return '';
  }

  let unitIdx = Math.floor(Math.log(bytes) / Math.log(1000));
  if (units.length <= unitIdx) {
    // use PB as maximum unit
    unitIdx = units.length - 1;
  }
  if (unitIdx == 0) {
    // use KB as minimum unit
    unitIdx = 1;
  }

  const size = bytes / Math.pow(1024, unitIdx);
  let precision = 2 - Math.floor(Math.log(size < 1 ? 1 : size) / Math.LN10); // Keep 3 significant digits
  precision = precision >= 0 && isFinite(precision) ? precision : 0;

  const magnitude = size < 0.05 && unitIdx <= 1 ? '0' : size.toFixed(precision);

  const unit = units[unitIdx];
  return `${magnitude} ${unit}`;
}

export function formatNumber(numberValue: string | number): string {
  if (numberValue === undefined || numberValue === null) {
    return '';
  }

  return String(+numberValue);
}

export function formatString(stringValue: string): string {
  if (stringValue === undefined || stringValue === null) {
    return '';
  }

  const valueAsString = String(stringValue);

  // remove line break characters
  return valueAsString.replace(/\r?\n|\r/g, ' ');
}

export function formatBoolean(booleanValue: boolean): string {
  if (booleanValue === undefined || booleanValue === null) {
    return '';
  }

  if (booleanValue) {
    return t('Generic.Yes');
  }

  return t('Generic.No');
}

/**
 * Returns formatted date and time string
 * @param {string} dateTimeString Date-parsable string to format
 * @returns {string} Date and time string in {MMM d, yyyy, h:mm a} format.
 * If string cannot be parsed, the original string is returned
 */
export function formatDateTime(dateTimeString: string): string {
  if (!dateTimeString) {
    return '';
  }

  const date = new Date(dateTimeString).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });

  if (date == 'Invalid Date') {
    return dateTimeString;
  }

  return date;
}

export function formatDate(dateTimeString: string): string {
  if (!dateTimeString) {
    return '';
  }

  const date = new Date(dateTimeString).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  if (date == 'Invalid Date') {
    return dateTimeString;
  }

  return date;
}

export function sanitizeDataSourceId(datasourceId: string): string {
  if (!datasourceId) {
    return '';
  }
  return datasourceId.replace('Bentley.PW--', '').replace(/~3A/g, ':');
}
