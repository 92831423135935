import { useCallback } from 'react';
import type {
  PWDataItem,
  WSGChangedInstanceResponse,
  WSGProperties
} from '@bentley/pw-api';
import {
  useFeatureTracking,
  useGraphApiContext,
  useHttpService,
  useTableItemContext
} from '../../context';
import type { ToastHandle } from '../../services/pwToast';
import {
  newDocumentVersion,
  newDocumentVersions,
  newLogicalSetVersion
} from './newDocumentVersion';
import {
  notifyLogicalSetVersionError,
  notifyVersionCreateStart,
  notifyVersionCreated,
  notifyVersionError,
  notifyVersionsCreated,
  notifyVersionsError
} from './notifications';
import { allowNewVersion } from '.';

type NewVersionFunction = (
  items: PWDataItem[],
  version: string | undefined
) => Promise<void>;

export function useNewVersionWorkflow(): NewVersionFunction {
  const { trackFeature } = useFeatureTracking();
  const httpService = useHttpService();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();
  const { refreshCoAuthoringSessions } = useGraphApiContext();

  function allItemsAreDocuments(items: PWDataItem[]): boolean {
    return items.every((item) => item.className === 'Document');
  }

  async function logicalSetResponseMessage(
    response: Response
  ): Promise<WSGProperties[]> {
    const changedInstance =
      (await response.json()) as WSGChangedInstanceResponse;
    const messages = changedInstance.changedInstance.instanceAfterChange
      .properties?.Messages as unknown as WSGProperties[];
    return messages;
  }

  async function updateSingleItemVersion(
    item: PWDataItem,
    version: string | undefined,
    toastHandle: ToastHandle
  ) {
    try {
      const response =
        item.className != 'LogicalSet'
          ? await newDocumentVersion(item, version, httpService)
          : await newLogicalSetVersion(item, version, httpService);

      if (!response.ok) {
        await notifyVersionError(toastHandle, response);
        return;
      }

      if (item.className == 'LogicalSet') {
        const messages = await logicalSetResponseMessage(response);
        if (messages?.length) {
          notifyLogicalSetVersionError(toastHandle, messages);
        } else {
          notifyVersionCreated(toastHandle, item.Name, version);
        }
      } else {
        notifyVersionCreated(toastHandle, item.Name, version);
      }
    } catch (e) {
      console.error(e);
      toastHandle.close();
    } finally {
      refreshCurrentFolder();
      void refreshCoAuthoringSessions();
    }
  }

  async function updateMultiItemsVersion(
    items: PWDataItem[],
    version: string | undefined,
    toastHandle: ToastHandle
  ) {
    try {
      let responses;
      if (allItemsAreDocuments(items)) {
        responses = await newDocumentVersions(items, version, httpService);
        if (!responses.ok) {
          await notifyVersionError(toastHandle, responses);
        } else {
          notifyVersionsCreated(toastHandle, items.length, version);
        }
      } else {
        let success = 0;
        let failure = 0;
        await Promise.all(
          items.map(async (item) => {
            if (item.className === 'LogicalSet') {
              const response = await newLogicalSetVersion(
                item,
                version,
                httpService
              );
              const messages = await logicalSetResponseMessage(response);
              messages ? failure++ : success++;
            } else {
              const response = await newDocumentVersion(
                item,
                version,
                httpService
              );
              response.ok ? success++ : failure++;
            }
          })
        );

        if (items.length == success) {
          notifyVersionsCreated(toastHandle, items.length, version);
        } else {
          notifyVersionsCreated(toastHandle, success, version);
          notifyVersionsError(toastHandle, failure);
        }
      }
    } catch (e) {
      console.error(e);
      toastHandle.close();
    } finally {
      refreshCurrentFolder();
      void refreshCoAuthoringSessions();
    }
  }

  const newVersionWorkflow = useCallback(
    async (items: PWDataItem[], version: string | undefined): Promise<void> => {
      if (!allowNewVersion(items)) {
        throw new Error('New version not allowed');
      }

      const toastHandle = notifyVersionCreateStart();
      trackFeature('NEW_VERSION');

      if (items.length == 1) {
        await updateSingleItemVersion(items[0], version, toastHandle);
      } else {
        await updateMultiItemsVersion(items, version, toastHandle);
      }
    },
    [
      httpService,
      refreshCurrentFolder,
      refreshCoAuthoringSessions,
      trackFeature
    ]
  );

  return newVersionWorkflow;
}
