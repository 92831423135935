import React, { useEffect, useState } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import { itemIsFlatSet, itemIsProject } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { Button, LabeledInput } from '@itwin/itwinui-react';
import type { OpenModal } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { DocumentPickerModalWrapper } from '../flatSetActions/useFlatSetWizard/documentPickerModalWrapper';
import { pwParentTypeToDocumentPickerParentDataType } from '../flatSetActions/utils';
import type { ComponentClassName } from './utils';

export function SelectFolderComponent(
  componentEnabled: boolean,
  renditionChosen: boolean,
  updateChoice: (
    componentType: ComponentClassName,
    value: string,
    valueName: string
  ) => void,
  openModal: OpenModal,
  currentParent: PWParentType,
  closePickerFunction?: () => void,
  currentFolderName?: string
): JSX.Element {
  const [folderName, setFolderName] = useState<string>('');

  useEffect(() => {
    let initialName = currentFolderName ?? '';
    if (!renditionChosen) {
      initialName = '';
    } else if (!componentEnabled) {
      initialName = t('CreateRendition.RenditionsGoInSameFolder');
    } else if (!currentFolderName) {
      initialName = t('CreateRendition.OutputDefinedInDestination');
    } else {
      initialName = currentFolderName;
    }
    setFolderName(initialName);
  }, [componentEnabled, currentFolderName, renditionChosen]);

  const customFilter = (item: PWItem) => {
    return itemIsProject(item) && !itemIsFlatSet(item);
  };

  const isNavigationAllowed = (item: PWItem) => {
    return itemIsProject(item);
  };

  function onCloseFunction() {
    if (closePickerFunction) {
      closePickerFunction();
    }
  }

  function onItemSelectedFunction(folders: DocumentLink[]) {
    if (folders.length) {
      updateChoice('RendSvcDestinationFolder', folders[0].id, folders[0].name);
      setFolderName(folders[0].name);
    }
    if (closePickerFunction) {
      closePickerFunction();
    }
  }

  function openFolderSelection() {
    openModal(
      <DocumentPickerModalWrapper
        onClose={onCloseFunction}
        onItemsSelected={onItemSelectedFunction}
        maximumSelectedItems={1}
        customFilter={(item: PWItem) => customFilter(item)}
        title={t('CreateRendition.SelectFolder')}
        hideConnectionListView={true}
        currentFolder={pwParentTypeToDocumentPickerParentDataType(
          currentParent
        )}
        isSelectable={(item: PWItem) => customFilter(item)}
        isNavigationAllowed={(item: PWItem) => isNavigationAllowed(item)}
      />
    );
  }
  return (
    <div className="destination-component">
      <div className="destination-folder-value">
        <LabeledInput
          label={t('CreateRendition.Folder')}
          value={folderName}
          data-testid="destination-folder"
          disabled={true}
          wrapperProps={{ className: 'd-f-v-name' }}
        />
        <Button
          className="d-f-v-button"
          styleType="high-visibility"
          disabled={!componentEnabled}
          onClick={openFolderSelection}
        >
          {t('CreateRendition.ChangeFolder')}
        </Button>
      </div>
    </div>
  );
}
