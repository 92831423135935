import type { PWItem, PWParentType } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { SvgFind, SvgSearch } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import type { ReactNode } from 'react';
import React from 'react';

export function BreadcrumbLabel(breadcrumb: PWParentType): ReactNode {
  if (itemIsProject(breadcrumb) && !breadcrumb.TypeString) {
    breadcrumb.TypeString = 'Folder';
  }

  return (
    <span className="bc-w-icon">
      <BreadcrumbIcon breadcrumb={breadcrumb} />
      <Text as="span" className="breadcrumb-name">{breadcrumb.Name}</Text>
    </span>
  );
}

function BreadcrumbIcon({
  breadcrumb
}: {
  breadcrumb: PWParentType;
}): JSX.Element | null {
  if (!breadcrumb.className) {
    return null;
  }

  if (
    breadcrumb.className == ('SavedSearch' as string) ||
    breadcrumb.className == ('SavedSearchDefinition' as string)
  ) {
    return (
      <SvgFind className="pw-icon-xs" data-testid="saved-search-filetype" />
    );
  }

  if (breadcrumb.className == ('Search' as string)) {
    return <SvgSearch className="pw-icon-xs" data-testid="search-filetype" />;
  }

  if (breadcrumb.className == ('OutToMe' as string)) {
    return (
      <ItemIcon
        item={{ className: 'Document' } as PWItem}
        badge="checkedOut"
        size="xs"
      />
    );
  }

  if (itemIsProject(breadcrumb) && !breadcrumb.TypeString) {
    breadcrumb.TypeString = 'Folder';
  }

  return <ItemIcon item={breadcrumb} size="xs" />;
}
