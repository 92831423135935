import React, { useState } from 'react';
import { Alert, Checkbox } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import type { ModalElement, OpenModal } from '../../hooks/useModal';
import { useLocalStorage } from '../../hooks/useStorage';
import { t } from '../../services/translation';

function EnablePopupsModal(): ModalElement {
  const { primaryModal } = useNavigationContext();

  const [checked, setChecked] = useState(false);
  const [showEnablePopupsModal, setShowEnablePopupsModal] =
    useLocalStorage<boolean>('showEnablePopupsModal', true);

  function saveAndCloseModal(): void {
    setShowEnablePopupsModal(!checked);
    if (!checked) {
      primaryModal.close();
    }
  }

  if (!showEnablePopupsModal) {
    return null;
  }

  return (
    <PWModal
      title={t('OfficeIntegration.EnablePopupsTitle')}
      primaryButton={{ title: t('Generic.Close'), onClick: saveAndCloseModal }}
      onClose={saveAndCloseModal}
      dialogProps={{ 'data-testid': 'EnablePopupsModal' }}
    >
      <Alert>{t('OfficeIntegration.EnablePopupsMessage')}</Alert>
      <Checkbox
        label={t('OfficeIntegration.EnablePopupsCheckbox')}
        onChange={(e) => setChecked(e.target.checked)}
        checked={checked}
      />
    </PWModal>
  );
}

export function showEnablePopupsModal(openModal: OpenModal): void {
  openModal(<EnablePopupsModal />);
}
