import './attributesFormLayout.css';

import React from 'react';
import { AttributesToolbarContainer } from '../toolbar';
import { AttributesFormContainer } from './attributesFormContainer';

export function AttributesFormLayout(): JSX.Element {
  return (
    <div className="attributesFormPanel">
      <AttributesToolbarContainer />
      <AttributesFormContainer />
    </div>
  );
}
