import type { PWProject, WSGInstancesResponse } from '@bentley/pw-api';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import type { ECPluginVersion } from '../../hooks/useECPluginVersion';
import { getECPluginVersion } from '../../hooks/useECPluginVersion';
import { getWebApiVersion } from '../../hooks/useECPluginVersion/ecPluginVersion';
import type { PRDSSSetting } from '../../hooks/useProductSetting';
import type { GetToken } from '../http';
import { openToast } from '../pwToast';
import { t } from '../translation';
import { getWebConnectionApi } from '../webConnections/webConnections';
import type { FederatedRepository, Repository } from './federatedRepository';
import {
  parseConnectionUrl,
  parseServerVersion,
  prdssSettingToFederatedRepo
} from './repositoryMapper';

export type DriveSync = {
  connections: string[];
  multiMode: boolean;
};

export const ERROR_DUPLICATE_REPO = t(
  'FederatedRepository.ConnectionAlreadyExists'
);
export const ERROR_DUPLICATE_REPO_NAME = t('FederatedRepository.DuplicateName');
export const ERROR_ACCESS_FORBIDDEN = t('FederatedRepository.Forbidden');
export const ERROR_CONNECTION_ALREADY_DELETED = t(
  'FederatedRepository.ConnectionDeleted'
);
export const ERROR_NETWORKISSUE = t('FederatedRepository.NetworkError');
export const ERROR_UNKNOWN = t('Generic.UnknownErrorOccurred');
export const ERROR_NOTFOUND = t('FederatedRepository.NotFound');
export const ERROR_PRIMARY_EXISTS = t(
  'FederatedRepository.ConnectionAlreadyPrimary'
);
export const ERROR_PRIMARY_UNDELETABLE = t(
  'FederatedRepository.PrimaryConnectionUndeletable'
);
const PRDSS_DATASOURCE_NAMESPACE = 'datasources';

export class DeleteError extends Error {
  deletedIds: string[];
  constructor(message: string, deletedIds: string[]) {
    super(message);
    this.deletedIds = deletedIds;
  }
}

export class PrimaryExistsError extends Error {
  constructor(message: string, public contextId: string | undefined) {
    super(message);
  }
}

export interface IFederatedRepositoryApi {
  getAll: () => Promise<FederatedRepository[]>;
  addOne: (
    federatedRepository: FederatedRepository,
    isImport?: boolean
  ) => Promise<FederatedRepository>;
  deleteMany: (repositoryIds: string[]) => Promise<void>;
  updateOne: (
    federatedRepository: FederatedRepository
  ) => Promise<FederatedRepository>;
  addSynced: (repositoryId: string) => Promise<void>;
  deleteSynced: (repositoryId: string[]) => Promise<void>;
  getSynced: () => Promise<string[]>;
  addDriveSynced: (repositoryId: string, backfill?: boolean) => Promise<void>;
  deleteDriveSynced: (repositoryId: string) => Promise<void>;
  getDriveSynced: () => Promise<DriveSync | null>;
  upgradeOne: (
    repositoryId: string,
    upgradeVersion: number
  ) => Promise<FederatedRepository | null>;
  setMultiMode: (multiMode: boolean) => Promise<void>;
  getPrimaryConnection: () => Promise<string>;
  setPrimaryConnection: (
    repositoryId: string,
    primary: boolean
  ) => Promise<void>;
  unsetPrimaryConnection: () => Promise<void>;
}

export function getFederatedApi(
  gprId: number,
  userOrg: string | undefined,
  serviceUrl: string,
  connectionServiceUrl: string,
  getOidcToken: GetToken,
  contextId?: string
): IFederatedRepositoryApi {
  if (contextId) {
    return getWebConnectionApi(connectionServiceUrl, getOidcToken, contextId);
  }

  const baseUrl = userOrg
    ? `${serviceUrl}Application/${gprId}/Org/Setting`
    : `${serviceUrl}Application/${gprId}/User/Setting`;

  const httpService = new HttpService({
    authorization: new AuthorizationService({ getOidcToken }),
    baseUrl
  });

  return {
    getAll: getFederatedRepositories.bind(null, httpService),
    addOne: addFederatedRepository.bind(null, httpService),
    deleteMany: deleteFederatedRepositories.bind(null, httpService),
    updateOne: updateFederatedRepository.bind(null, httpService),
    deleteSynced: (id: string[]) => Promise.resolve(),
    addSynced: (id: string) => Promise.resolve(),
    getSynced: () => Promise.resolve([]),
    deleteDriveSynced: (id: string) => Promise.resolve(),
    addDriveSynced: (id: string) => Promise.resolve(),
    getDriveSynced: () => Promise.resolve(null),
    upgradeOne: (repositoryId: string, upgradeVersion: number) =>
      Promise.resolve(null),
    setMultiMode: (multiMode: boolean) => Promise.resolve(),
    getPrimaryConnection: () => Promise.resolve(''),
    setPrimaryConnection: (repositoryId: string, primary: boolean) =>
      Promise.resolve(),
    unsetPrimaryConnection: () => Promise.resolve()
  };
}

async function getFederatedRepositories(
  httpService: HttpService
): Promise<FederatedRepository[]> {
  const url = `?$filter=namespace+eq+'${PRDSS_DATASOURCE_NAMESPACE}'`;
  let totalData: PRDSSSetting[] = [];
  let continuationToken: string | null = '';
  do {
    let requestHeaders = {} as HeadersInit;
    if (continuationToken) {
      requestHeaders = { ContinuationToken: continuationToken } as HeadersInit;
    }

    try {
      const response = await httpService.get(url, { headers: requestHeaders });

      if (!response?.ok) {
        throw response;
      }

      const data = (await response.json()) as PRDSSSetting[];
      totalData = totalData.concat(data);
      continuationToken = response.headers.get('ContinuationToken');
    } catch (err) {
      throw new Error('Failed to load federated repositories');
    }
  } while (continuationToken);

  return totalData
    .map(prdssSettingToFederatedRepo)
    .filter((repo) => (repo.Id && repo.Name && repo.Url ? true : false));
}

async function addFederatedRepository(
  httpService: HttpService,
  federatedRepository: FederatedRepository
): Promise<FederatedRepository> {
  const totalData = await getFederatedRepositories(httpService);
  if (
    totalData.filter(
      (a) => a.Name.toLowerCase() == federatedRepository.Name.toLowerCase()
    ).length > 0
  ) {
    throw new Error(ERROR_DUPLICATE_REPO_NAME);
  }

  const response = await httpService.post(
    '',
    JSON.stringify({
      name: federatedRepository.Id,
      namespace: PRDSS_DATASOURCE_NAMESPACE,
      properties: federatedRepository
    })
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }
  if (response.status === 409) {
    throw new Error(ERROR_DUPLICATE_REPO);
  }
  if (response.status === 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }
  if (!response.ok) {
    console.error('addFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }
  const data = (await response.json()) as PRDSSSetting;
  return prdssSettingToFederatedRepo(data);
}

async function deleteFederatedRepositories(
  httpService: HttpService,
  repositoryIds: string[]
): Promise<void> {
  const deleted = new Array<string>();
  for (const repoId of repositoryIds) {
    try {
      await deleteFederatedRepository(httpService, repoId);
    } catch (err) {
      throw new DeleteError((err as Error).message, deleted);
    }
    deleted.push(repoId);
  }
}

async function deleteFederatedRepository(
  httpService: HttpService,
  repositoryId: string
): Promise<void> {
  const url = `${PRDSS_DATASOURCE_NAMESPACE}/${repositoryId}`;

  const response = await httpService.delete(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }
  if (response.status == 404) {
    throw new Error(ERROR_CONNECTION_ALREADY_DELETED);
  }
  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }
  if (!response.ok) {
    console.error('deleteFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }
}

async function updateFederatedRepository(
  httpService: HttpService,
  federatedRepository: FederatedRepository
): Promise<FederatedRepository> {
  const url = `${PRDSS_DATASOURCE_NAMESPACE}/${federatedRepository.Id}`;
  const response = await httpService.put(
    url,
    JSON.stringify({
      properties: federatedRepository
    })
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }
  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }
  if (!response.ok) {
    console.error('updateFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as PRDSSSetting;
  return prdssSettingToFederatedRepo(data);
}

export async function getDataSources(serverUrl: string): Promise<Repository[]> {
  try {
    const serverVersion = await getServerVersion(serverUrl);
    if (!serverVersion) {
      throw new Error('Failed to get datasources');
    }

    return getRepositories(serverUrl, serverVersion);
  } catch (err) {
    throw new Error('Failed to get datasources');
  }
}

async function getServerVersion(
  serverUrl: string,
  defaultVersion = 2.5
): Promise<number> {
  const version = await getWebApiVersion(serverUrl);
  return Number(version) || defaultVersion;
}

export async function fetchServerVersion(
  repository: FederatedRepository
): Promise<number> {
  const serverUrl = parseConnectionUrl(repository);
  const currentVersion = await getServerVersion(serverUrl);
  const setVersion = parseServerVersion(repository);
  return currentVersion || setVersion;
}

async function getRepositories(
  serverUrl: string,
  serverVersion: number
): Promise<Repository[]> {
  const response = await fetch(`${serverUrl}/v${serverVersion}/repositories/`);
  const data = (await response.json()) as WSGInstancesResponse;

  return data.instances.map((instance) => {
    const { properties, ...rest } = instance;
    return {
      ...rest,
      ...properties,
      ServerUrl: serverUrl,
      ServerVersion: serverVersion
    } as Repository;
  });
}

export async function getDisplayLabel(
  repo: FederatedRepository
): Promise<string> {
  if (!repo.DataSource || !repo.ServerUrl) {
    return '';
  }

  try {
    const response = await fetch(`${repo.ServerUrl}${repo.DataSource}`);
    const data = (await response.json()) as WSGInstancesResponse;
    const displayLabel = data.instances[0].properties?.DisplayLabel;
    return <string>displayLabel ?? '';
  } catch {
    return '';
  }
}

export async function getWorkAreas(
  repository: Repository,
  getSamlToken: GetToken,
  userCredentials?: { userName: string; password: string }
): Promise<[PWProject[], ECPluginVersion]> {
  let url = `PW_WSG/Project?`;
  url += '$filter=IsRichProject+eq+true';
  url += '&$orderby=Name';
  url += '&api.filtersettings=CaseInsensitive';

  const authorization = userCredentials
    ? new AuthorizationService({ userCredentials })
    : new AuthorizationService({ getSamlToken });
  const baseUrl = `${repository.ServerUrl}/v${repository.ServerVersion}/repositories/${repository.instanceId}`;

  const httpService = new HttpService({
    authorization,
    baseUrl
  });
  const pluginVersion = await getECPluginVersion({ httpService });
  const response = await httpService.get(url, { uncached: true });
  validateResponse(response, userCredentials == undefined);
  const data = (await response.json()) as WSGInstancesResponse;

  return [
    data.instances.map((instance) => {
      const { properties, ...rest } = instance;
      return { ...rest, ...properties } as PWProject;
    }),
    pluginVersion
  ];
}

function validateResponse(response: Response, usingIMS: boolean): void {
  if (response.status == 401 || response.status == 403) {
    const content = usingIMS
      ? t('SignInFailure.IMSFailed')
      : t('SignInFailure.CheckNameAndPassword');
    openToast({ content, category: 'negative' });
    throw new Error('Sign in failed');
  }

  if (response.status == 429) {
    const content = t('SignInFailure.TooManyAttempts');
    openToast({ content, category: 'negative' });
    throw new Error('Sign in failed');
  }

  if (!response.ok) {
    const content = t('SignIn.ErrorOnSignIn');
    openToast({ content, category: 'negative' });
    throw new Error('Sign in failed');
  }
}
