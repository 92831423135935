export function pwIconProps(size = 'm', { className, ...props } = {}) {
    return {
        className: joinClassNames(`pw-icon-${size}`, className),
        ...props
    };
}
export function pwIconBadgeProps({ className, ...props } = {}, additionalClassNames = []) {
    return {
        className: joinClassNames(`pw-icon-badge`, className, ...additionalClassNames),
        ...props
    };
}
function joinClassNames(...classNames) {
    return classNames.filter(Boolean).join(' ');
}
