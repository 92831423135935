import React from 'react';
import { useConnectionContext } from '../../context';
import { ProjectWiseConfiguredConnection } from './projectWiseConfiguredConnection';
import type { ProjectWiseConnectionsProps } from './projectWiseConnections';

export function ProjectWiseConnectionSelector(
  props: ProjectWiseConnectionsProps
): JSX.Element {
  const { connection } = useConnectionContext();

  return (
    <ProjectWiseConfiguredConnection connectionId={connection.Id} {...props} />
  );
}
