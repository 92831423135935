import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import type { WSGInstance, WSGRelationshipInstance } from '@bentley/pw-api';
import {
  docCodePropertiesAsync,
  filterNonDocCodeProperties,
  usingDocCodeState
} from '../docCode';
import type { DCWDocProperties } from '../wizard';
import { isCopyModeState } from '../wizard/state';

type DocumentInstanceFunction = (
  dcwDocProperties: DCWDocProperties,
  environmentInstance: WSGInstance | null,
  projectId: string,
  documentInstanceId?: string
) => WSGInstance;

export function useDocumentInstance(): DocumentInstanceFunction {
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const docCodeProperties = useRecoilValue(docCodePropertiesAsync);
  const isCopyMode = useRecoilValue(isCopyModeState);

  const documentParent = useCallback(
    (projectId: string): WSGRelationshipInstance | undefined => {
      return {
        schemaName: 'PW_WSG',
        className: 'DocumentParent',
        direction: 'forward',
        relatedInstance: {
          schemaName: 'PW_WSG',
          className: 'Project',
          instanceId: projectId
        }
      };
    },
    []
  );

  const documentEnvironmentWithDocCodeProperties = useCallback(
    (
      environmentInstance: WSGInstance,
      documentInstanceId?: string
    ): WSGRelationshipInstance | undefined => {
      if (!environmentInstance.properties) {
        return undefined;
      }

      return {
        schemaName: 'PW_WSG',
        className: 'DocumentEnvironment',
        direction: 'forward',
        relatedInstance: {
          schemaName: 'PW_WSG_Dynamic',
          className: environmentInstance.className,
          instanceId: documentInstanceId ?? '',
          properties: environmentInstance.properties,
          changeState: 'new'
        }
      };
    },
    []
  );

  const documentEnvironmentWithoutDocCodeProperties = useCallback(
    (
      environmentInstance: WSGInstance | null,
      documentInstanceId?: string
    ): WSGRelationshipInstance | undefined => {
      if (!environmentInstance?.properties) {
        return undefined;
      }

      const nonDocCodeProperties = filterNonDocCodeProperties(
        environmentInstance.properties,
        docCodeProperties
      );
      return {
        schemaName: 'PW_WSG',
        className: 'DocumentEnvironment',
        direction: 'forward',
        relatedInstance: {
          schemaName: 'PW_WSG_Dynamic',
          className: environmentInstance.className,
          instanceId: documentInstanceId ?? '',
          properties: nonDocCodeProperties,
          changeState: 'new'
        }
      };
    },
    [docCodeProperties]
  );

  const documentEnvironment = useCallback(
    (
      environmentInstance: WSGInstance | null,
      documentInstanceId?: string
    ): WSGRelationshipInstance | undefined => {
      if (!environmentInstance?.className) {
        return undefined;
      }

      if (usingDocCode) {
        return documentEnvironmentWithDocCodeProperties(
          environmentInstance,
          documentInstanceId
        );
      }
      return documentEnvironmentWithoutDocCodeProperties(
        environmentInstance,
        documentInstanceId
      );
    },
    [
      documentEnvironmentWithDocCodeProperties,
      documentEnvironmentWithoutDocCodeProperties,
      usingDocCode
    ]
  );

  const relationshipInstances = useCallback(
    (
      environmentInstance: WSGInstance | null,
      projectId: string,
      documentInstanceId?: string
    ): WSGRelationshipInstance[] => {
      const relationshipInstances = [
        documentParent(projectId),
        environmentInstance !== null
          ? documentEnvironment(environmentInstance, documentInstanceId)
          : undefined
      ];
      return relationshipInstances.filter((instance) => instance != undefined);
    },
    [documentEnvironment, documentParent]
  );

  const copyDocumentInstance = useCallback(
    (
      { name, description, version, fileName }: DCWDocProperties,
      environmentInstance: WSGInstance | null,
      projectId: string,
      documentInstanceId?: string
    ): WSGInstance => {
      return {
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: documentInstanceId ?? '',
        properties: {
          Name: name,
          Description: description,
          Version: version,
          FileName: fileName
        },
        changeState: 'new',
        relationshipInstances: relationshipInstances(
          environmentInstance,
          projectId,
          documentInstanceId
        )
      };
    },
    [relationshipInstances]
  );

  const uploadDocumentInstance = useCallback(
    (
      { name, description, version }: DCWDocProperties,
      environmentInstance: WSGInstance | null,
      projectId: string
    ): WSGInstance => {
      return {
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: '',
        properties: {
          Name: name,
          Description: description,
          Version: version
        },
        changeState: 'new',
        relationshipInstances: relationshipInstances(
          environmentInstance,
          projectId
        )
      };
    },
    [relationshipInstances]
  );

  return isCopyMode ? copyDocumentInstance : uploadDocumentInstance;
}
