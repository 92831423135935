import type {
  HttpService,
  PWDataItem,
  WSGChangedInstanceResponse,
  WSGInstancesResponse,
  WSGProperties
} from '@bentley/pw-api';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type {
  InProgressActivityStatus,
  ProgressManager
} from '../../hooks/useProgressManager';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { notifyStateChangeInProgress } from './notifications';
import { handleWRSResponse } from './responses';
import { handleWorkflowsSummaryModal } from './workflowSummary';
import type { Command } from './wreCommands';

export async function beginUpdateWFWorkflow(
  items: PWDataItem[],
  command: Command,
  comment: string,
  httpService: HttpService,
  progressManager: ProgressManager,
  openModal: OpenModal,
  closeModal: CloseModal,
  onSuccess: () => void
): Promise<void> {
  const toastHandle = notifyStateChangeInProgress(command.Label, items.length);
  const jobId = progressManager.addNewJobToTracker('workflow', toastHandle);

  const responses = await Promise.all(
    items.map((row) =>
      postWorkflowCommand(
        row,
        httpService,
        jobId,
        progressManager,
        command,
        comment
      )
    )
  );

  if (
    responses.some(
      (response) => response.status == 201 || response.status == 200
    )
  ) {
    onSuccess();
  }

  await handleWorkflowsSummaryModal(
    progressManager,
    jobId,
    openModal,
    closeModal
  );
  toastHandle.close();
}

export async function postWorkflowCommand(
  item: PWDataItem,
  httpService: HttpService,
  jobId: string,
  progressManager: ProgressManager,
  command: Command,
  comment?: string
): Promise<Response> {
  const abortController = new AbortController();
  const uniqueId = progressManager.addWorkflowActivityToJob(
    jobId,
    item,
    abortController
  );

  const url = `PW_WSG/Document/${item.instanceId}/CommandRun`;
  return await usingConcurrencyLimiter(async () => {
    let response = await httpService.post(
      url,
      JSON.stringify({
        instance: {
          schemaName: 'PW_WSG',
          className: 'CommandRun',
          properties: {
            CommandId: command.instanceId
              .replace(/~3A/g, ':')
              .replace(/~20/g, ' '),
            Comment: comment ?? command.CommentDefault
          }
        }
      })
    );

    const changedInstance = (await response
      .clone()
      .json()) as WSGChangedInstanceResponse & WSGInstancesResponse;
    const regEx = /3Awrs/;
    if (changedInstance?.changedInstance?.instanceAfterChange) {
      if (
        regEx.exec(
          changedInstance.changedInstance.instanceAfterChange.instanceId
        )
      ) {
        const instanceId =
          changedInstance.changedInstance.instanceAfterChange.instanceId;
        response = await handleWRSResponse(
          progressManager,
          httpService,
          jobId,
          uniqueId,
          instanceId
        );
      } else {
        let status: InProgressActivityStatus;
        if (
          changedInstance.changedInstance.instanceAfterChange
            .relationshipInstances?.[0].relatedInstance.instanceId ==
            item.instanceId &&
          (
            changedInstance.changedInstance.instanceAfterChange.properties
              ?.Messages as string[]
          )?.length > 0
        ) {
          const messages = changedInstance.changedInstance.instanceAfterChange
            .properties?.Messages as Record<string, string>[];

          const severities = Math.max(
            ...messages.map((message) => {
              const statusMessage = message;
              if ((statusMessage as WSGProperties).Severity == '0') {
                progressManager.addSuccessMessageToActivity(
                  jobId,
                  uniqueId,
                  statusMessage.Message
                );
              } else {
                progressManager.addWarningMessageToActivity(
                  jobId,
                  uniqueId,
                  statusMessage.Message
                );
              }
              return parseInt(statusMessage.Severity);
            })
          );
          status = severities == 0 ? 'success' : 'error';
          progressManager.updateProgressStatus(jobId, uniqueId, status);
        } else {
          status =
            response.status == 200 || response.status == 201
              ? 'success'
              : 'error';
          progressManager.updateProgressStatus(jobId, uniqueId, status);
        }
      }
    }
    if ((changedInstance as WSGInstancesResponse).errorMessage) {
      progressManager.addWarningMessageToActivity(
        jobId,
        uniqueId,
        (changedInstance as WSGInstancesResponse).errorMessage ?? ''
      );
      progressManager.updateProgressStatus(jobId, uniqueId, 'error', response);
    }
    return response;
  });
}
