import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { pwConstants } from '@bentley/pw-api';
import { Flex, LabeledInput, StatusMessage } from '@itwin/itwinui-react';
import { PWModal } from '../../../components/pwModal';
import { t } from '../../../services/translation';

type NameWithDescriptionModalProps = {
  onSubmit: (name: string, description: string) => void;
  onClose: () => void;
};

export function NameWithDescriptionModal({
  onSubmit,
  onClose
}: NameWithDescriptionModalProps): JSX.Element {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | undefined>();
  const [nameStatus, setNameStatus] = useState<'negative' | undefined>();

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState<
    string | undefined
  >();
  const [descriptionStatus, setDescriptionStatus] = useState<
    'negative' | undefined
  >();

  function onNameChange(e: ChangeEvent<HTMLInputElement>): void {
    const newName = e.target.value;
    setName(newName);

    if (!newName.trim()) {
      setNameError(t('Generic.NameCannotBeEmpty'));
      return;
    }

    if (newName.length > pwConstants.maxFileNameLength) {
      setNameError(
        `${t('Generic.ContentLimitedTo', {
          count: pwConstants.maxFileNameLength,
          excess: newName.length - pwConstants.maxFileNameLength
        })}`
      );

      return;
    }

    setNameError(undefined);
  }

  useEffect(() => {
    if (nameError) {
      setNameStatus('negative');
    } else {
      setNameStatus(undefined);
    }
  }, [nameError]);

  function onDescriptionChange(e: ChangeEvent<HTMLInputElement>): void {
    const newDescription = e.target.value;
    setDescription(newDescription);

    if (newDescription.length > pwConstants.maxDescriptionLength) {
      setDescriptionError(
        `${t('Generic.ContentLimitedTo', {
          count: pwConstants.maxDescriptionLength,
          excess: newDescription.length - pwConstants.maxDescriptionLength
        })}`
      );

      return;
    }

    setDescriptionError(undefined);
  }

  useEffect(() => {
    if (descriptionError) {
      setDescriptionStatus('negative');
    } else {
      setDescriptionStatus(undefined);
    }
  }, [descriptionError]);

  const modalContent = (
    <Flex flexDirection="column" alignItems="stretch">
      <LabeledInput
        label={t('Generic.Name')}
        name="name"
        autoFocus={true}
        value={name}
        onChange={onNameChange}
        status={nameStatus}
        data-testid="name-input"
        message={<StatusMessage status={nameStatus}>{nameError}</StatusMessage>}
      />
      <LabeledInput
        label={t('Generic.Description')}
        name="description"
        value={description}
        onChange={onDescriptionChange}
        status={descriptionStatus}
        data-testid="description-input"
        message={
          <StatusMessage status={descriptionStatus}>
            {descriptionError}
          </StatusMessage>
        }
      />
    </Flex>
  );

  function primaryButtonHandle() {
    onSubmit(name, description);
    onClose();
  }

  return (
    <PWModal
      title={t('FlatSet.Create')}
      primaryButton={{
        title: t('Generic.Create'),
        onClick: primaryButtonHandle,
        disabled:
          nameError !== undefined ||
          name == '' ||
          descriptionError !== undefined
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: onClose
      }}
      onClose={onClose}
    >
      {modalContent}
    </PWModal>
  );
}
