import React from 'react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import {
  useFeatureTracking,
  useNavigationContext,
  usePropertyContext
} from '../../../context';
import type { View } from '../../../hooks/useViews';
import { keyPressAsClick } from '../../../services/accessibility';

export function NameCell({ row }: CellProps<View>): JSX.Element {
  const { trackFeature } = useFeatureTracking();
  const { viewManager } = usePropertyContext();
  const { primaryModal } = useNavigationContext();

  const view = row.original;

  function onRowClick(): void {
    viewManager.userViewSetting.setView(view);
    trackFeature('SELECT_MY_VIEW');
    primaryModal.close();
  }

  return (
    <div
      className={'underline-name-div'}
      onClick={onRowClick}
      onKeyPress={keyPressAsClick(onRowClick)}
      role="button"
      tabIndex={0}
      data-testid={`my-view-${view.name}`}
      data-col="my-view-name"
      data-val={view.name}
    >
      {view.name}
    </div>
  );
}
