import JSZip from 'jszip';
import type { HttpService, PWFileType, PWItem } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import type { ToastHandle } from '../../services/pwToast';
import { onZipProgress } from './eventHandlers';
import {
  generateVersionFileName,
  getZipFileName,
  subfolderName
} from './naming';
import { notifyDownloadAborted } from './notifications';
import type { DownloadNode } from './tree';
import { buildDownloadTree, calculateDownloadSize } from './tree';
import type { DownloadResult } from './utils';
import { downloadBlobWorkflow, performDownloadInBrowser } from './utils';

export async function zipAndDownloadItems(
  items: PWItem[],
  httpService: HttpService,
  jobId: string,
  progressManager: ProgressManager,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean,
  confirmLargeDownload: (downloadSize: number) => Promise<boolean>,
  toastHandle: ToastHandle,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  searchName?: string,
  useAttributeExchange?: boolean
): Promise<DownloadResult | undefined> {
  const downloadTree = await buildDownloadTree(
    items,
    httpService,
    downloadReferencedFiles,
    downloadRecursiveReferences
  );

  const downloadSize = calculateDownloadSize(downloadTree);

  const proceedWithDownload = await confirmLargeDownload(downloadSize);
  if (!proceedWithDownload) {
    return;
  }

  const zip = await downloadAndZipTree(
    downloadTree,
    httpService,
    jobId,
    progressManager,
    toastHandle,
    filterLatestVersionItems,
    useAttributeExchange
  );

  const zipProgress = onZipProgress(toastHandle);

  const [blob, name] = await Promise.all([
    prepareZipForDownload(zip, zipProgress),
    getZipFileName(items, httpService, searchName)
  ]);
  performDownloadInBrowser(blob, name);

  return { blob, zip };
}

async function downloadAndZipTree(
  downloadTree: DownloadNode,
  httpService: HttpService,
  jobId: string,
  progressManager: ProgressManager,
  toastHandle: ToastHandle,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  useAttributeExchange?: boolean
): Promise<JSZip> {
  const zip = new JSZip();

  await downloadAndZipTreeRecursive(
    downloadTree,
    zip,
    httpService,
    jobId,
    progressManager,
    toastHandle,
    filterLatestVersionItems,
    useAttributeExchange
  );
  return zip;
}

async function downloadAndZipTreeRecursive(
  node: DownloadNode,
  zip: JSZip,
  httpService: HttpService,
  jobId: string,
  progressManager: ProgressManager,
  toastHandle: ToastHandle,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  useAttributeExchange?: boolean
): Promise<void[]> {
  const processFiles = (node.files || []).map(async (file) => {
    await downloadAndZipFile(
      file,
      node.files,
      zip,
      jobId,
      progressManager,
      httpService,
      toastHandle,
      filterLatestVersionItems,
      useAttributeExchange
    );
  });

  const processFolders = (node.folders || []).map(async (folder) => {
    if (folder.current) {
      const sanitizedName = subfolderName(folder.current, node.folders);
      const zipFolder = zip.folder(sanitizedName);
      if (zipFolder) {
        if (itemIsFlatSet(folder.current)) {
          // Should download everything in flat set regardless of display option
          await downloadAndZipTreeRecursive(
            folder,
            zipFolder,
            httpService,
            jobId,
            progressManager,
            toastHandle,
            undefined,
            useAttributeExchange
          );
        } else {
          await downloadAndZipTreeRecursive(
            folder,
            zipFolder,
            httpService,
            jobId,
            progressManager,
            toastHandle,
            filterLatestVersionItems,
            useAttributeExchange
          );
        }
      }
    }
  });

  return Promise.all([...processFiles, ...processFolders]);
}

async function downloadAndZipFile(
  item: PWFileType,
  siblings: PWFileType[],
  zip: JSZip,
  jobId: string,
  progressManager: ProgressManager,
  httpService: HttpService,
  toastHandle: ToastHandle,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  useAttributeExchange?: boolean
): Promise<void> {
  // Skip downloading file if it is not latest version and downloadLatestVersionOnly is true
  // This will match downloading folder to items that are displayed
  if (filterLatestVersionItems && !filterLatestVersionItems([item]).length) {
    return;
  }

  const itemPath = (zip as JSZip & { root: string }).root.slice(0, -1);
  const fileName = generateVersionFileName(item, siblings, itemPath);
  const abortController = new AbortController();
  const uniqueId = progressManager.addDownloadToJob(
    jobId,
    item,
    abortController
  );

  try {
    const blob = await usingConcurrencyLimiter(async () =>
      downloadBlobWorkflow(
        item,
        httpService,
        jobId,
        uniqueId,
        abortController,
        progressManager,
        useAttributeExchange
      )
    );
    zip.file(fileName, blob, { date: new Date(item.FileUpdateTime) });
  } catch (error) {
    // whenever users aborts file download ,which is in-progress , aborted is true
    if (!abortController.signal.aborted) {
      progressManager.updateProgressStatus(
        jobId,
        uniqueId,
        'error',
        error as Response & Record<string, string>
      );
    } else {
      progressManager.updateProgressStatus(
        jobId,
        uniqueId,
        'abort',
        error as Response & Record<string, string>
      );
    }
    if (!progressManager.progressTracker.cancelAllDownloads) {
      if (abortController.signal.aborted) {
        notifyDownloadAborted(toastHandle);
      } else {
        toastHandle.close();
      }
    }
  }
}

function prepareZipForDownload(
  zip: JSZip,
  onZipProgress: (percent: number, error?: boolean) => void
): Promise<Blob> {
  const downloadedFiles = zip.files;
  const contentsZippedNumber: number = Object.keys(downloadedFiles).length;
  if (contentsZippedNumber <= 0) {
    onZipProgress(100, true);
    throw new Error('Empty Zip');
  } else if (contentsZippedNumber === 1) {
    const firstFileName: string = Object.keys(downloadedFiles)[0];
    if (firstFileName.includes('/')) {
      onZipProgress(100, true);
      throw new Error('Empty Folder');
    }
  }
  return zip.generateAsync({ type: 'blob' }, (metadata) =>
    onZipProgress(metadata.percent)
  );
}
