import './createRenditionModal.css';

import React, { useCallback, useEffect, useState } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import {
  LabeledInput,
  LabeledSelect,
  ToggleSwitch
} from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { createRendition, getRenditionProfiles } from './createRendition';
import type { RenditionProfile } from './renditionProfile';
import { ProfileComponent } from './renditionProfileComponent';
import type {
  ComponentClassName,
  RenditionDropdownOption,
  RenditionSelection
} from './utils';
import { getRenditionProjectId } from './utils';

type CreateRenditionsModalProps = {
  items: PWItem[];
  initialChoices?: RenditionSelection;
  currentParent: PWParentType;
  breadcrumbs: PWParentType[];
};

export function CreateRenditionModal({
  items,
  initialChoices,
  currentParent,
  breadcrumbs
}: CreateRenditionsModalProps): ModalElement {
  const { primaryModal } = useNavigationContext();
  const httpService = useHttpService();
  const createRenditionFunction = () => {
    trackFeature('CREATE_RENDITION');
    void createRendition(httpService, renditionChoice, items);
    primaryModal.close();
  };
  const [renditionProfiles, setRenditionProfiles] = useState<
    RenditionProfile[]
  >([]);
  const [selectedRendition, setSelectedRendition] =
    useState<RenditionProfile>();
  const [profileOptions, setProfileOptions] = useState<
    RenditionDropdownOption[]
  >([]);
  const [selectedProfile, setSelectedProfile] =
    useState<RenditionDropdownOption>();
  const [renditionChoice, setRenditionChoice] = useState<RenditionSelection>(
    initialChoices ?? ({} as RenditionSelection)
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailToggle, setEmailToggle] = useState<boolean>();

  const { trackFeature } = useFeatureTracking();

  const updateProfile = useCallback(
    (label: string, value: string) => {
      if (selectedProfile?.value != value) {
        setSelectedProfile({ label, value });
        const rendition = renditionProfiles.find(
          (profile) => profile.instanceId == value
        );
        setRenditionChoice({
          profileId: value,
          sendEmail: rendition?.NotifyUserOnCompletion
        } as RenditionSelection);
        setEmailToggle(rendition?.NotifyUserOnCompletion);
        setSelectedRendition(rendition);
      }
    },
    [renditionProfiles, selectedProfile?.value]
  );

  useEffect(() => {
    // if there is no initial data set check if there is a default rendition for WA
    if (!initialChoices) {
      const defaultProfile = renditionProfiles.find(
        (profile) => profile.IsDefaultForWA
      );
      if (defaultProfile) {
        updateProfile(defaultProfile.Name, defaultProfile.instanceId);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * updateProfile causes will cause this to reset the default profile
     */
  }, [initialChoices, renditionProfiles]);

  useEffect(() => {
    async function getProfiles() {
      const renditionProjectId = getRenditionProjectId(
        currentParent,
        items,
        breadcrumbs
      );
      const profiles = await getRenditionProfiles(
        renditionProjectId,
        httpService
      );
      setRenditionProfiles(profiles);
      if (initialChoices) {
        const chosenProfile = profiles.find((profile) => {
          return profile.instanceId == initialChoices.profileId;
        });
        if (chosenProfile) {
          setSelectedProfile({
            label: chosenProfile.Name,
            value: chosenProfile.instanceId
          });
        }
        setEmailToggle(initialChoices.sendEmail);
        setSelectedRendition(chosenProfile);
      }
      setProfileOptions(
        profiles.map((profile) => {
          return {
            label: profile.Name,
            value: profile.instanceId
          };
        })
      );

      setIsLoading(false);
    }

    void getProfiles();
  }, [breadcrumbs, currentParent, httpService, initialChoices, items]);

  const closeFolderPicker = useCallback(() => {
    primaryModal.open(
      <CreateRenditionModal
        items={items}
        initialChoices={renditionChoice}
        currentParent={currentParent}
        breadcrumbs={breadcrumbs}
      />
    );
  }, [breadcrumbs, currentParent, items, renditionChoice, primaryModal]);

  const updateRenditionChoices = useCallback(
    (
      choiceType: ComponentClassName,
      choiceValue: string,
      choiceValueName?: string
    ) => {
      const updatedChoice = renditionChoice;
      switch (choiceType) {
        case 'RendSvcDestinationOption':
          updatedChoice.destinationId = choiceValue;
          break;
        case 'RendSvcFilenameOption':
          updatedChoice.fileNameId = choiceValue;
          break;
        case 'RendSvcPresentationOption':
          updatedChoice.presentationId = choiceValue;
          break;
        case 'RendSvcRenditionOption':
          updatedChoice.formatId = choiceValue;
          break;
        case 'RendSvcDestinationFolder':
          updatedChoice.folder = choiceValue;
          if (choiceValueName != null) {
            updatedChoice.folderName = choiceValueName;
          }
          break;
      }
      setRenditionChoice(updatedChoice);
    },
    [renditionChoice]
  );

  function onProfileChange(value: string) {
    const profile = profileOptions.find((prof) => prof.value == value);
    if (!profile) {
      return;
    }
    updateProfile(profile.label, value);
  }

  function onSendNotificationChange(newValue: boolean) {
    const updatedChoice = renditionChoice;
    updatedChoice.sendEmail = newValue;
    setEmailToggle(newValue);
    setRenditionChoice(updatedChoice);
  }

  function createRenditionDisabled(): boolean {
    if (
      !renditionChoice.profileId ||
      !renditionChoice.formatId ||
      !renditionChoice.fileNameId ||
      !renditionChoice.destinationId
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <PWModal
      title={t('CreateRendition.CreateRendition')}
      className="c-r-m-content"
      isLoading={isLoading}
      primaryButton={{
        title: t('Generic.Create'),
        onClick: createRenditionFunction,
        disabled: createRenditionDisabled()
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      dialogProps={{ 'data-testid': 'CreateRenditionModal' }}
      onClose={primaryModal.close}
    >
      {profileOptions.length == 0 ? (
        <LabeledInput
          label={t('CreateRendition.Profile')}
          value={''}
          disabled={true}
          data-testid="profile-combo-box"
        />
      ) : (
        <>
          <LabeledSelect
            label={t('CreateRendition.Profile')}
            options={profileOptions}
            value={selectedProfile?.value}
            onChange={(e: string) => {
              onProfileChange(e);
            }}
            data-testid="profile-combo-box"
          />
        </>
      )}
      {ProfileComponent(
        selectedRendition,
        updateRenditionChoices,
        t('CreateRendition.Presentation'),
        'RendSvcPresentationOption',
        primaryModal.open,
        currentParent,
        initialChoices
      )}
      {ProfileComponent(
        selectedRendition,
        updateRenditionChoices,
        t('CreateRendition.Format'),
        'RendSvcRenditionOption',
        primaryModal.open,
        currentParent,
        initialChoices
      )}
      {ProfileComponent(
        selectedRendition,
        updateRenditionChoices,
        t('Generic.FileName'),
        'RendSvcFilenameOption',
        primaryModal.open,
        currentParent,
        initialChoices
      )}
      {ProfileComponent(
        selectedRendition,
        updateRenditionChoices,
        t('CreateRendition.Destination'),
        'RendSvcDestinationOption',
        primaryModal.open,
        currentParent,
        renditionChoice,
        closeFolderPicker
      )}
      <ToggleSwitch
        className="renditions-toggle"
        defaultChecked={emailToggle}
        onChange={(e) => onSendNotificationChange(e.currentTarget.checked)}
        label={t('CreateRendition.SendEmail')}
        data-testid="renditionToggle"
      />
    </PWModal>
  );
}
