import type { ReactNode } from 'react';
import React from 'react';
import type { useToaster } from '@itwin/itwinui-react';
import { ProgressRadial } from '@itwin/itwinui-react';
import { getGlobalToaster } from './pwToastProvider';

type ToastCategory = 'informational' | 'positive' | 'negative' | 'warning';

type ToastOptions = Parameters<ReturnType<typeof useToaster>[ToastCategory]>[1];

type ToastParams = {
  category?: ToastCategory;
  content: ReactNode;
  spinner?: boolean;
} & Partial<ToastOptions>;

export type ToastHandle = { close: () => void };

export function openToast({
  category = 'informational',
  content,
  spinner,
  ...options
}: ToastParams): ToastHandle {
  const toastFunction = getToastFunction(category);
  const toastContent = getToastContent(content, spinner);
  const toastHandle = toastFunction(toastContent, options);
  return toastHandle;
}

export function replaceToast(
  originalToastHandle: ToastHandle | undefined,
  newToastParams: ToastParams
): ToastHandle {
  setTimeout(() => originalToastHandle?.close());
  return openToast(newToastParams);
}

function getToastFunction(
  category: ToastCategory
): (content: ReactNode, options?: ToastOptions) => ToastHandle {
  if (category == 'informational') {
    return (content: ReactNode, options?: ToastOptions) =>
      getGlobalToaster().informational(content, options);
  }

  if (category == 'positive') {
    return (content: ReactNode, options?: ToastOptions) =>
      getGlobalToaster().positive(content, options);
  }

  if (category == 'negative') {
    return (content: ReactNode, options?: ToastOptions) =>
      getGlobalToaster().negative(content, options);
  }

  if (category == 'warning') {
    return (content: ReactNode, options?: ToastOptions) =>
      getGlobalToaster().warning(content, options);
  }

  return (content: ReactNode, options?: ToastOptions) =>
    getGlobalToaster().informational(content, options);
}

function getToastContent(content: ReactNode, spinner?: boolean): ReactNode {
  if (!spinner) {
    return content;
  }

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
    >
      <ProgressRadial
        size={'small'}
        indeterminate
        style={{ marginRight: '8px' }}
      />
      {content}
    </div>
  );
}
