import React, { useEffect, useMemo, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { Button } from '@itwin/itwinui-react';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import { htmlTextLinks, plainTextLinks } from './clipboardLinks';
import type { Application } from './getShareableLink';

type CopyButtonProps = {
  items: (PWItem | SavedSearchDefinition)[];
  selectedApp: Application;
  getLink: (
    item: PWItem | SavedSearchDefinition,
    application: Application
  ) => string;
};

export function CopyButton({
  items,
  selectedApp,
  getLink
}: CopyButtonProps): JSX.Element {
  const [copyCoolingOff, setCopyCoolingOff] = useState<boolean>(false);

  function copyLinkText(): string {
    if (copyCoolingOff) {
      return t('SharedLink.Copied');
    }

    return t('Generic.Copy');
  }

  function onCopyClick(): void {
    function onCopy(event: ClipboardEvent): void {
      const plainLinks = plainTextLinks(itemLinks);
      event.clipboardData?.setData('text/plain', plainLinks);

      const htmlLinks = htmlTextLinks(itemLinks);
      event.clipboardData?.setData('text/html', htmlLinks);

      event.preventDefault();
    }

    document.addEventListener('copy', onCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', onCopy);

    onLinkCopied();
  }

  function onLinkCopied(): void {
    setCopyCoolingOff(true);
    setTimeout(() => setCopyCoolingOff(false), 5000);
  }

  const itemLinks = useMemo(() => {
    const itemLinks = items.map((item) => ({
      item: item,
      link: getLink(item, selectedApp)
    }));

    return itemLinks;
  }, [getLink, items, selectedApp]);

  useEffect(() => {
    setCopyCoolingOff(false);
  }, [selectedApp]);

  return (
    <Button
      styleType="high-visibility"
      onClick={onCopyClick}
      data-testid="copy-link-button"
      className="copy-link-button"
    >
      {copyLinkText()}
    </Button>
  );
}
