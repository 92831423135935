import type { HttpService, PWItem, WSGInstance } from '@bentley/pw-api';

export async function restoreItem(
  item: PWItem,
  httpService: HttpService
): Promise<void> {
  const instance = buildRestoreInstance(item);
  const body = JSON.stringify({ instance });
  const response = await httpService.post('MAGIC URL TBD', body);
  throwIfBadResponse(response);
}

export async function restoreItemToFolder(
  item: PWItem,
  parentId: string,
  httpService: HttpService
): Promise<void> {
  const instance = buildRestoreToFolderInstance(item, parentId);
  const body = JSON.stringify({ instance });
  const response = await httpService.post('MAGIC URL TBD', body);
  throwIfBadResponse(response);
}

function buildRestoreInstance(item: PWItem): WSGInstance {
  // Todo: build out instance
  return {
    schemaName: 'PW_WSG',
    className: item.className,
    instanceId: item.instanceId
  };
}

function buildRestoreToFolderInstance(
  item: PWItem,
  parentId: string
): WSGInstance {
  const itemClass = item.className == 'Project' ? 'Project' : 'Document';

  // Todo: build out instance
  return {
    schemaName: 'PW_WSG',
    className: item.className,
    instanceId: item.instanceId,
    relationshipInstances: [
      {
        schemaName: 'PW_WSG',
        className: `${itemClass}Parent`,
        direction: 'forward',
        relatedInstance: {
          schemaName: 'PW_WSG',
          className: 'Project',
          instanceId: parentId
        }
      }
    ]
  };
}

function throwIfBadResponse(response: Response): void {
  if (!response.ok || response.status != 200) {
    throw new Error('Unable to restore item');
  }
}
