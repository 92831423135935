import React, { useMemo } from 'react';
import type { RouteObject } from 'react-router-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ConfigProvider, OidcProvider } from '../../context';
import { RouteErrorBoundary } from '../errorFallback/routeErrorBoundary';
import { PWThemeProvider } from '../pwThemeProvider';

export function AppRoutes(): JSX.Element {
  const routes = useMemo(
    (): RouteObject[] => [
      // Authentication
      {
        path: 'logged-out',
        lazy: async () => {
          const { LoggedOutOIDC } = await import('../oidc');
          return { Component: LoggedOutOIDC };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: 'logout',
        lazy: async () => {
          const { LogoutOIDC } = await import('../oidc');
          return { Component: LogoutOIDC };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: 'signin-oidc',
        lazy: async () => {
          const { SignInOIDC } = await import('../oidc');
          return { Component: SignInOIDC };
        },
        ErrorBoundary: RouteErrorBoundary
      },

      // Share link component for ProjectWise Explorer
      {
        path: 'share-link/*',
        lazy: async () => {
          const { ShareLink } = await import('../shareLink');
          return { Component: ShareLink };
        },
        ErrorBoundary: RouteErrorBoundary
      },

      // Universal link resolution page
      {
        path: 'link/*',
        lazy: async () => {
          const { UniversalLink } = await import('../universalLink');
          return { Component: UniversalLink };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: 'pwlink/*',
        lazy: async () => {
          const { UniversalLink } = await import('../universalLink');
          return { Component: UniversalLink };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: 'saved-search/*',
        lazy: async () => {
          const { UniversalLink } = await import('../universalLink');
          return { Component: UniversalLink };
        },
        ErrorBoundary: RouteErrorBoundary
      },

      // Connection settings
      {
        path: 'context/:contextId/connections',
        lazy: async () => {
          const { SettingsPage } = await import('../projectWise');
          return { Component: SettingsPage };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: 'connections',
        lazy: async () => {
          const { SettingsPage } = await import('../projectWise');
          return { Component: SettingsPage };
        },
        ErrorBoundary: RouteErrorBoundary
      },

      // Main app
      {
        path: 'context/:contextId/*',
        lazy: async () => {
          const { ProjectWisePage } = await import('../projectWise');
          return { Component: ProjectWisePage };
        },
        ErrorBoundary: RouteErrorBoundary
      },
      {
        path: '*',
        lazy: async () => {
          const { ProjectWisePage } = await import('../projectWise');
          return { Component: ProjectWisePage };
        },
        ErrorBoundary: RouteErrorBoundary
      }
    ],
    []
  );

  const router = createBrowserRouter(routes);

  return (
    <PWThemeProvider>
      <ConfigProvider value={undefined}>
        <OidcProvider value={undefined}>
          <RouterProvider router={router} />
        </OidcProvider>
      </ConfigProvider>
    </PWThemeProvider>
  );
}

// user context needs around everything except pwe link and universal link, and maybe signin oidc?
