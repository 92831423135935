import React, { useCallback, useRef } from 'react';
import type {
  PWDataItem,
  PWItem,
  PWProject,
  WSGInstance
} from '@bentley/pw-api';
import { getProjectChildren, itemIsFileType } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { DocumentWizardModal } from '../../../components/documentCreation';
import { PWModal } from '../../../components/pwModal';
import {
  useAppViewContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import { openToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';
import { ConflictResolutionModal } from '../../conflictResolution';
import type { ConflictResolution } from '../../conflictResolution/conflictResolution';
import {
  createFlatSetAndGetParsedResult,
  pwParentTypeToDocumentPickerParentDataType
} from '../utils';
import { DocumentPickerModalWrapper } from './documentPickerModalWrapper';
import { NameWithDescriptionModal } from './nameWithDescriptionModal';

type FlatSetWizardFunctions = {
  show: (preSelectedItemIds?: string[]) => void;
};

type FlatSetWizardResetInfo = {
  name: string;
  description: string;
  environmentInstance: WSGInstance;
};

export function useFlatSetWizard(): FlatSetWizardFunctions {
  const httpService = useHttpService();
  const {
    primaryModal,
    navigationManager: { currentParent }
  } = useNavigationContext();

  const {
    folderSettingsManager: { folderSettings }
  } = useAppViewContext();

  const {
    selectedState: { setSelectedItems },
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const flatSetName = useRef('');
  const flatSetDescription = useRef('');
  const flatSetEnvironmentInstance = useRef<WSGInstance | null | undefined>();
  const { trackFeature } = useFeatureTracking();

  const show = useCallback(
    (
      preSelectedItemIds?: string[],
      flatSetResetInfo?: FlatSetWizardResetInfo
    ): void => {
      function onSubmit(
        name: string,
        description: string,
        currentEnvironmentInstance?: WSGInstance | null
      ) {
        flatSetName.current = name;
        flatSetDescription.current = description;

        const validInstance =
          currentEnvironmentInstance &&
          !currentEnvironmentInstance.instanceId.includes('_');

        if (validInstance) {
          flatSetEnvironmentInstance.current = currentEnvironmentInstance;
        } else if (currentEnvironmentInstance) {
          flatSetEnvironmentInstance.current = {
            ...currentEnvironmentInstance,
            instanceId: ''
          };
        }

        void checkConflicts();
      }

      let isAborted = false;

      primaryModal.open(
        <PWModal
          title={t('FlatSetWizard.LaunchingFlatSetWizard')}
          isLoading={true}
          secondaryButton={{
            title: t('Generic.Cancel'),
            onClick: () => {
              primaryModal.close();
              isAborted = true;
            }
          }}
          onClose={() => {
            primaryModal.close();
            isAborted = true;
          }}
        >
          {null}
        </PWModal>
      );

      if (isAborted) {
        return;
      }

      const isUseDCW =
        folderSettings?.UseDCWInFoldersWithCode ||
        folderSettings?.UseDCWInFoldersWithoutCode;

      const wizardModal = (
        <DocumentWizardModal
          onSuccess={(
            instanceId: string,
            fileName: string,
            name: string,
            description: string,
            currentEnvironmentInstance?: WSGInstance | null
          ) => {
            onSubmit(name, description, currentEnvironmentInstance);
          }}
          fileNumber={0}
          numberOfFiles={0}
          totalFileCount={0}
          projectId={currentParent.instanceId}
          defaultDocumentName={flatSetResetInfo?.name ?? ''}
          defaultDocumentDescription={flatSetResetInfo?.description ?? ''}
          presetEnvironmentInstance={
            flatSetResetInfo?.environmentInstance ?? undefined
          }
          fileName={''}
          version=""
          onCancel={primaryModal.close}
          upload={() => Promise.resolve([])}
          uploadNode={{ files: [], directories: [] }}
          closeModal={primaryModal.close}
          isFlatSetCreationMode={true}
        />
      );

      const nameAndDescriptionModal = (
        <NameWithDescriptionModal
          onSubmit={onSubmit}
          onClose={primaryModal.close}
        />
      );

      async function checkConflicts() {
        if (await conflictExists()) {
          showConflictResolutionModal();
        } else {
          showDocumentPicker();
        }
      }

      function checkSelectedItems(selectedItems: DocumentLink[]) {
        if (selectedItems.filter((i) => !i.isFolder).length == 0) {
          openToast({
            content: t('FlatSet.Create.Notification.MustContainAtLeastOneFile'),
            category: 'negative'
          });

          return;
        }

        onItemsSelected(selectedItems.map((i) => i.id));
      }

      function showDocumentPicker() {
        if (preSelectedItemIds) {
          onItemsSelected(preSelectedItemIds);
          return;
        }
        primaryModal.open(
          <DocumentPickerModalWrapper
            onItemsSelected={checkSelectedItems}
            onClose={primaryModal.close}
            title={t('DocumentPicker.SelectDocuments')}
            hideConnectionListView={true}
            currentFolder={pwParentTypeToDocumentPickerParentDataType(
              currentParent
            )}
            isSelectable={itemIsFileType}
          />
        );
      }

      function onFlatSetWizardError(
        name: string,
        description: string,
        environmentInstance: WSGInstance | null | undefined,
        selectedItemIds: string[]
      ): void {
        const resetInfo = {
          name,
          description,
          environmentInstance
        } as FlatSetWizardResetInfo;
        show(selectedItemIds, resetInfo);
      }

      function onItemsSelected(selectedItemIds: string[]) {
        async function createAndRefresh() {
          const flatSet = await createFlatSetAndGetParsedResult(
            httpService,
            flatSetName.current,
            flatSetDescription.current,
            currentParent.instanceId,
            selectedItemIds,
            flatSetEnvironmentInstance.current
          );
          if (flatSet) {
            refresh(flatSet);
          } else {
            onFlatSetWizardError(
              flatSetName.current,
              flatSetDescription.current,
              flatSetEnvironmentInstance.current,
              selectedItemIds
            );
          }
        }

        void createAndRefresh();
        trackFeature('CREATE_SET_WITH_ITEMS_PICKER');
        primaryModal.close();
      }

      async function conflictExists(): Promise<boolean> {
        const items = await getProjectChildren({
          parentId: currentParent.instanceId,
          httpService,
          requestOptions: { uncached: true }
        });

        return items.some(
          (i) => i.Name.toLowerCase() == flatSetName.current.toLowerCase()
        );
      }

      function showConflictResolutionModal() {
        const conflictResolutionModal = (
          <ConflictResolutionModal
            sourceItems={[
              {
                item: {
                  Name: flatSetName.current,
                  FileName: flatSetName.current,
                  className: 'FlatSet'
                } as PWDataItem
              }
            ]}
            destinationParent={currentParent as PWProject}
            resolveConflicts={resolveConflicts}
            onClose={primaryModal.close}
            closeModal={primaryModal.close}
            doNotShowReplaceOption={true}
            doNotShowVersionOption={true}
          />
        );

        function resolveConflicts(resolutions: ConflictResolution[]) {
          const resolution = resolutions[0];

          if (resolution.action == 'rename') {
            flatSetName.current = resolution.customName;
            showDocumentPicker();
          }
        }

        primaryModal.open(conflictResolutionModal);
      }

      function refresh(flatSet?: WSGInstance) {
        if (flatSet) {
          setSelectedItems([{ instanceId: flatSet.instanceId } as PWItem]);
        }

        refreshCurrentFolder();
      }

      primaryModal.open(isUseDCW ? wizardModal : nameAndDescriptionModal);
    },
    [
      currentParent,
      folderSettings,
      refreshCurrentFolder,
      httpService,
      primaryModal,
      setSelectedItems,
      trackFeature
    ]
  );

  return { show };
}
