import type { ProviderProps } from 'react';
import React, { createContext, useContext } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import type { UploadNode } from '../../../../actions/upload';
import type { CloseModal } from '../../../../hooks/useModal';
import type { OnCancelWizardFunction } from '../../cancel';
import type { OnDocumentCreatedFunction } from '../../create/useDocumentCreation';
import type { RefreshAndSelect } from '../../create/useUpload';

export type CopyFunction = (items: PWDataItem[]) => Promise<Response[]>;
export type UploadFunction = (
  uploadNode: UploadNode
) => Promise<Response | Response[]>;

type WizardFunctions = {
  closeModal?: CloseModal;
  onCancel: OnCancelWizardFunction;
  onSuccess: OnDocumentCreatedFunction;
  refreshAndSelect?: RefreshAndSelect;
  upload?: UploadFunction;
  copy?: CopyFunction;
};

const Context = createContext<WizardFunctions | undefined>(undefined);

export function WizardFunctionsProvider({
  value,
  ...props
}: ProviderProps<WizardFunctions>): JSX.Element {
  return <Context.Provider value={value} {...props} />;
}

export function useWizardFunctionsContext(): WizardFunctions {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useWizardFunctionsContext must be used within a WizardFunctionsProvider'
    );
  }
  return context;
}
