import { useCallback, useEffect, useState } from 'react';
import type { PWDataItem, PWItem, RequestOptions } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import { getItemCommands, setItemCommands } from './itemCommands';
import type { Command } from './wreCommands';
import { getCommonCommands, getItemCommandsBatched } from './wreCommands';

export function useCommonWRECommands(
  selectedItems: PWItem[]
): Command[] | undefined {
  const httpService = useHttpService();

  const [commonCommands, setCommonCommands] = useState<Command[] | undefined>();

  const attachCommandsToItems = useCallback(
    async (
      items: PWDataItem[],
      abortController: AbortController
    ): Promise<void> => {
      const httpOptions = { abortController } as RequestOptions;
      const commands = await getItemCommandsBatched(
        items,
        httpService,
        httpOptions
      );
      if (abortController.signal.aborted) {
        return;
      }

      items.forEach((item) => {
        const itemCommands = commands.find(
          (command) => command.itemId == item.instanceId
        )?.commands;

        setItemCommands(item, itemCommands);
      });
    },
    [httpService]
  );

  const attachMissingCommandsToSelectedItems = useCallback(
    async (abortController: AbortController): Promise<void> => {
      const dataItems = filterDataItems(selectedItems);
      const itemsWithoutCommands = dataItems.filter(
        (item) => !getItemCommands(item)?.length
      );

      if (itemsWithoutCommands.length) {
        await attachCommandsToItems(itemsWithoutCommands, abortController);
      }

      if (abortController.signal.aborted) {
        return;
      }
      const commonCommands = getCommonCommands(dataItems);
      setCommonCommands(commonCommands);
    },
    [attachCommandsToItems, selectedItems]
  );

  useEffect(() => {
    const abortController = new AbortController();

    setCommonCommands(undefined);
    void attachMissingCommandsToSelectedItems(abortController);

    return () => {
      abortController.abort();
    };
  }, [attachMissingCommandsToSelectedItems]);

  return commonCommands;
}
