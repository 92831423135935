import './customizeGridColumnModal.css';

import type { ChangeEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { pwConstants } from '@bentley/pw-api';
import { SvgAdd } from '@itwin/itwinui-icons-react';
import type { SelectOption } from '@itwin/itwinui-react';
import {
  Button,
  ComboBox,
  Flex,
  InputGrid,
  Label,
  LabeledInput,
  StatusMessage
} from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { SaveSelectTypeahead } from '../../components/saveSelectTypeahead';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePropertyContext
} from '../../context';
import { environmentDescription } from '../../hooks/useEnvironment';
import type { View } from '../../hooks/useViews';
import { t } from '../../services/translation';
import { useColumnOptions } from './columnOptions/useColumnOptions';
import { ColumnSelection } from './columnSelection/columnSelection';
import { SaveMyViewModal } from './saveMyViewModal';

type ColumnModalEditMode = 'EditMode' | 'NewMode' | 'CopyMode';

type CustomColumnDialogProps = {
  initialView: View;
  mode?: ColumnModalEditMode;
  onClose: () => void;
  onSave?: (view: View) => void;
};

export function CustomColumnDialog({
  initialView,
  mode = 'NewMode',
  onClose,
  onSave
}: CustomColumnDialogProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();

  const {
    environmentManager: { environmentDefinitions, loadAllEnvironmentProperties }
  } = useEnvironmentContext();
  const {
    navigationManager: { currentParent },
    primaryModal
  } = useNavigationContext();
  const { propertyManager, viewManager } = usePropertyContext();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [environments, setEnvironments] = useState<SelectOption<string>[]>([]);

  const [environmentPropertiesLoaded, setEnvironmentPropertiesLoaded] =
    useState<boolean>(false);

  const [multiConnectionView, setMultiConnectionView] = useState<boolean>(
    initialView?.multiConnection ?? false
  );
  const initialMultiConnection = useRef<boolean>(multiConnectionView);

  const [myViewTextValue, setMyViewTextValue] = useState<string>(
    initialView?.name.substring(0, pwConstants.maxFileNameLength) ?? ''
  );
  const initialViewName = useRef<string>(myViewTextValue);

  const {
    environment,
    availableColumns,
    selectedColumns,
    resetColumns,
    setEnvironment,
    setAvailableColumns,
    setSelectedColumns
  } = useColumnOptions({
    propertiesLoaded: propertyManager.initialized,
    environmentsLoaded: environmentPropertiesLoaded,
    existingView: initialView
  });

  function submitEnabled(): boolean {
    if (isLoading) {
      return false;
    }

    if (errorMessage) {
      return false;
    }

    if (!selectedColumns.length) {
      return false;
    }

    const columnsMatchStartingConfiguration =
      selectedColumns.length == initialView.columns.length &&
      selectedColumns.every(
        (column, index) =>
          column.value.name == initialView.columns[index].name &&
          column.value.envLabel == initialView.columns[index].envLabel
      );

    switch (mode) {
      case 'NewMode': {
        return !columnsMatchStartingConfiguration;
      }

      case 'EditMode': {
        return (
          !columnsMatchStartingConfiguration ||
          myViewTextValue != initialViewName.current ||
          multiConnectionView != initialMultiConnection.current
        );
      }

      case 'CopyMode': {
        return myViewTextValue != initialViewName.current;
      }
    }
  }

  function onSaveButton(): void {
    trackFeature('GRID_CUSTOMIZE_COLUMNS');
    const columns = selectedColumns.map((column) => column.value);

    switch (mode) {
      case 'NewMode': {
        viewManager.customViewSetting.saveView({
          name: t('CustomColumns.CustomView'),
          columns
        });
        onClose();
        break;
      }
      case 'EditMode': {
        if (
          viewManager.userViewSetting.views?.some(
            (view) =>
              view.name != initialView?.name && view.name == myViewTextValue
          )
        ) {
          setErrorMessage(t('CustomColumns.ViewAlreadyExists'));
        } else if (!errorMessage) {
          onClose();
          const newView = {
            ...initialView,
            name: myViewTextValue,
            columns,
            multiConnection: multiConnectionView
          } as View;
          onSave?.(newView);
        }
        break;
      }
      case 'CopyMode': {
        if (
          viewManager.userViewSetting.views?.some(
            (view) => view.name == myViewTextValue
          )
        ) {
          setErrorMessage(t('CustomColumns.ViewAlreadyExists'));
        } else if (!errorMessage) {
          onClose();
          const view = {
            columns,
            name: myViewTextValue,
            multiConnection: multiConnectionView
          } as View;
          onSave?.(view);
        }
        break;
      }
    }
  }

  function onViewNameChange(e: ChangeEvent<HTMLInputElement>): void {
    setMyViewTextValue(e.target.value);
    if (!e.target.value.trim()) {
      setErrorMessage(t('Generic.NameCannotBeEmpty'));
    } else {
      setErrorMessage('');
    }
  }

  useEffect(() => {
    async function initEnvironmentProperties(): Promise<void> {
      await loadAllEnvironmentProperties();
      setEnvironmentPropertiesLoaded(true);
    }

    void initEnvironmentProperties();
  }, [loadAllEnvironmentProperties]);

  useEffect(() => {
    if (!propertyManager.initialized || !environmentPropertiesLoaded) {
      return;
    }

    setEnvironments([
      { value: 'General', label: t('CustomColumns.GeneralProperties') },
      ...(environmentDefinitions ?? [])
        .map((environment) => ({
          label: environment.Name,
          sublabel: environmentDescription(environment),
          value: environment.Name
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    ]);

    const currentProjectEnvironment = environmentDefinitions?.find(
      (env) => String(env.instanceId) == String(currentParent.EnvironmentId)
    );

    setEnvironment(currentProjectEnvironment?.Name ?? 'General');
  }, [
    environmentDefinitions,
    environmentPropertiesLoaded,
    currentParent,
    propertyManager,
    setEnvironment
  ]);

  useEffect(() => {
    if (
      propertyManager.initialized &&
      environmentPropertiesLoaded &&
      viewManager.initialized
    ) {
      setIsLoading(false);
    }
  }, [
    propertyManager.initialized,
    environmentPropertiesLoaded,
    viewManager.initialized
  ]);

  return (
    <PWModal
      title={t('ViewManager.ConfigureColumns')}
      className="c-g-modal"
      isLoading={isLoading}
      loadingText={t('AdvSearch.LoadingEnvironments')}
      primaryButton={{
        title: t('Generic.Apply'),
        onClick: onSaveButton,
        disabled: !submitEnabled()
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: onClose
      }}
      tertiaryButton={
        mode == 'NewMode'
          ? {
              title: t('CustomColumns.SaveAsMyView'),
              startIcon: <SvgAdd />,
              onClick: () =>
                primaryModal.open(
                  <SaveMyViewModal
                    columns={selectedColumns.map((column) => column.value)}
                  />
                ),
              disabled: !selectedColumns.length
            }
          : undefined
      }
      onClose={onClose}
      dialogProps={{ 'data-testid': 'CustomizeColumnModal' }}
    >
      <Flex alignItems="baseline">
        <InputGrid labelPlacement="inline" style={{ flexGrow: '1' }}>
          <Label htmlFor="environmentSelectDropdown">
            {t('Generic.Environment')}
          </Label>
          <ComboBox
            inputProps={
              {
                id: 'environmentSelectDropdown',
                disabled: !environments.length || !environment,
                'data-testid': 'environmentSelectDropdown'
              } as never
            }
            options={environments}
            value={environment}
            onChange={setEnvironment}
          />
        </InputGrid>
        <Button onClick={resetColumns} data-testid="resetLink">
          {t('ColumnConfiguration.ResetColumns')}
        </Button>
      </Flex>
      <ColumnSelection
        leftLabel={t('ColumnConfiguration.AvailableColumns')}
        leftData={availableColumns}
        leftFilter={(item) => item.environment == environment}
        leftSort={(a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
            caseFirst: 'false'
          })
        }
        rightLabel={t('ColumnConfiguration.SelectedColumns')}
        rightData={selectedColumns}
        showDescription="right"
        setLeftData={setAvailableColumns}
        setRightData={setSelectedColumns}
      />
      {mode != 'NewMode' && (
        <>
          <LabeledInput
            value={myViewTextValue}
            label={t('CustomColumns.MyViewName')}
            data-testid="my-view-name"
            onChange={onViewNameChange}
            status={errorMessage ? 'negative' : undefined}
            maxLength={pwConstants.maxFileNameLength}
            message={
              <StatusMessage status={errorMessage ? 'negative' : undefined}>
                {errorMessage}
              </StatusMessage>
            }
          />
          <SaveSelectTypeahead
            onChange={setMultiConnectionView}
            multiConnection={multiConnectionView}
          />
        </>
      )}
    </PWModal>
  );
}
