import React from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { Radio } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../../services/translation';
import type { MappingDataRecord } from '../iModelMapTable';

type MasterModelCellProps = {
  tableDisabled?: boolean;
  onMasterCellClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) => void;
} & CellProps<MappingDataRecord>;

export function MasterModelCell({
  row,
  tableDisabled,
  onMasterCellClick
}: MasterModelCellProps): JSX.Element {
  const { isMaster, isExistingBridge, DataItem } = row.original;

  return (
    <Radio
      disabled={tableDisabled || isExistingBridge}
      label={t('IModel.MasterModel')}
      name={`mappingType--${DataItem.instanceId}`}
      checked={isMaster}
      onChange={(event) => onMasterCellClick?.(event, DataItem)}
      data-testid={'input-row-master-model'}
    />
  );
}
