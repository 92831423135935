import '../treeNode.css';

import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import { t } from '../../../services/translation';
import { Chevron } from '../chevron/chevron';

export function OutToMeNode(): JSX.Element | null {
  const { dataAccessLevel } = usePluginContext();
  const { trackFeature } = useFeatureTracking();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { outToMeCount }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  function className(): string {
    if (currentAppView.type == 'OutToMe') {
      return 'tree-node active-node';
    } else {
      return 'tree-node';
    }
  }

  function outToMeLabel(): string {
    const outToMeCountText =
      outToMeCount !== undefined ? `[${outToMeCount}]` : '';
    return `${t('Generic.OutToMe')} ${outToMeCountText}`;
  }

  function onOutToMeClick(): void {
    setAppView('OutToMe');
    trackFeature('OUT_TO_ME_ACCESS');
  }

  if (!ecPluginFeatures.outToMePage() || dataAccessLevel == 'Datasource') {
    return null;
  }

  return (
    <div
      className={`${className()} out-to-me-tree-node`}
      role="treeitem"
      data-testid="out-to-me-tree-node"
    >
      <Chevron hasChildren={false} isExpanded={false} />
      <ItemIcon
        item={{ className: 'Document' } as PWItem}
        badge="checkedOut"
        size="xs"
      />
      <Text
        as="span"
        className="node-label"
        onClick={onOutToMeClick}
        onKeyPress={keyPressAsClick(onOutToMeClick)}
        role="link"
        tabIndex={0}
        data-testid="out-to-me-node-label"
      >
        {outToMeLabel()}
      </Text>
    </div>
  );
}
