import './cellLabel.css';

import React from 'react';
import { Text } from '@itwin/itwinui-react';
import type { ColumnDisplayOptions } from '../../../../hooks/useViews';
import { keyPressAsClick } from '../../../../services/accessibility';
import { PWTooltip } from '../../../pwTooltip';

type CellLabelProps = {
  displayContent: string;
  displayOptions?: ColumnDisplayOptions;
  itemHasClickAction: boolean;
  propertyName: string;
  onItemClick: () => void;
};

export function CellLabel({
  displayContent,
  displayOptions,
  itemHasClickAction,
  propertyName,
  onItemClick
}: CellLabelProps): JSX.Element {
  const hasLink =
    itemHasClickAction &&
    (displayOptions?.isPrimary || displayOptions?.isSecondary);

  function onClick(e: React.MouseEvent): void {
    if (!hasLink) {
      return;
    }

    e.stopPropagation();
    onItemClick();
  }

  return (
    <PWTooltip
      content={displayContent}
      autoUpdateOptions={{ ancestorScroll: false, layoutShift: false }}
    >
      <Text
        as="span"
        className={`pw-cell-text ${hasLink ? 'row-link' : ''}`}
        onClick={onClick}
        onKeyPress={keyPressAsClick(onClick)}
        role={hasLink ? 'link' : undefined}
        tabIndex={hasLink ? 0 : -1}
        data-col={propertyName}
        data-val={displayContent}
      >
        {displayContent}
      </Text>
    </PWTooltip>
  );
}
