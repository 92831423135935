import { useMemo } from 'react';
import type { CoAuthoringContextProps } from '@bentley/office365-collaboration-settings';
import { useBuddi } from '@bentley/pw-config';
import { useToken } from '../../context';
import { openToast } from '../../services/pwToast';

export const useCoAuthoringCommonProps = (
  buddiRegionCode: string
): CoAuthoringContextProps => {
  const { getOidcToken } = useToken();

  const coAuthoringServiceUrl = useBuddi(
    'Office365CollaborationService.Url',
    buddiRegionCode
  );

  return useMemo(
    () => ({
      getToken: getOidcToken,
      showToast: (content, type) => {
        function category() {
          switch (type) {
            case 'error':
              return 'negative';
            case 'success':
              return 'positive';
            case 'info':
              return 'informational';
            case 'warning':
              return 'warning';
          }
        }

        openToast({
          content,
          category: category()
        });
      },
      urlsProvider: () =>
        new Promise((resolve, reject) => {
          if (coAuthoringServiceUrl === null) {
            reject();
          } else if (coAuthoringServiceUrl !== undefined) {
            resolve({ coAuthoringServiceUrl });
          }
        })
    }),
    [coAuthoringServiceUrl, getOidcToken]
  );
};
