import './breadcrumbs.css';

import React, { useMemo } from 'react';
import type { PWParentType } from '@bentley/pw-api';
import { SvgFolder } from '@itwin/itwinui-icons-color-react';
import {
  DropdownButton,
  MenuItem,
  Breadcrumbs as TableBreadcrumbs
} from '@itwin/itwinui-react';
import type { BreadcrumbManager } from '../../hooks/useBreadcrumb';
import { BreadcrumbLabel } from './breadcrumbLabel';

type BreadcrumbsProps = {
  breadcrumbManager: BreadcrumbManager;
  navigateTo?: (item: PWParentType) => void;
};

export function Breadcrumbs({
  breadcrumbManager,
  navigateTo
}: BreadcrumbsProps): JSX.Element {
  const disabledClass = breadcrumbManager.loading ? 'breadcrumb-disabled' : '';
  const singleItemClass =
    breadcrumbManager.breadcrumbs.length == 1 ? 'bc-single-item' : '';

  const jumpTo = useMemo(() => {
    return (instanceId: string) => {
      breadcrumbManager.jumpTo(instanceId)();
      const navigatedBreadCrumb = breadcrumbManager.breadcrumbs.find(
        (bc) => bc.instanceId == instanceId
      );
      if (navigatedBreadCrumb) {
        navigateTo?.(navigatedBreadCrumb);
      }
    };
  }, [breadcrumbManager, navigateTo]);

  const breadcrumbItems = useMemo(() => {
    return breadcrumbManager.breadcrumbs.map((item, index) => (
      <TableBreadcrumbs.Item
        className={`${
          breadcrumbManager.breadcrumbs.length - 1 == index
            ? 'bc-current-item'
            : ''
        } ${singleItemClass}`}
        key={`Breadcrumb${index}`}
        onClick={() => {
          if (breadcrumbManager.breadcrumbs.length - 1 !== index) {
            jumpTo(item.instanceId);
          }
        }}
      >
        {BreadcrumbLabel(item)}
      </TableBreadcrumbs.Item>
    ));
  }, [breadcrumbManager.breadcrumbs, jumpTo, singleItemClass]);

  return (
    <>
      {breadcrumbManager.breadcrumbs.length > 1 && (
        <DropdownButton
          disabled={breadcrumbManager.loading}
          className="bc-folder-dropdown"
          size="small"
          startIcon={<SvgFolder aria-hidden />}
          styleType="borderless"
          dropdownMenuProps={{ matchWidth: false }}
          menuItems={(close) =>
            breadcrumbManager.breadcrumbs
              .slice(0, breadcrumbManager.breadcrumbs.length - 1)
              .map((item, index) => (
                <MenuItem
                  key={`Item${index}`}
                  onClick={() => {
                    jumpTo(item.instanceId);
                    close();
                  }}
                >
                  {BreadcrumbLabel(item)}
                </MenuItem>
              ))
          }
          data-testid="breadcrumb-dropdown"
        />
      )}
      <div className="bc-container">
        <TableBreadcrumbs className={disabledClass}>
          {breadcrumbItems}
        </TableBreadcrumbs>
      </div>
    </>
  );
}
