import type { ProviderProps } from 'react';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import type { WSGProperties } from '@bentley/pw-api';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { ProductSettingType } from '../../../hooks/useProductSetting';
import type { GetToken } from '../../../services/http';
import { getSetting, putSetting } from './productSettingApi';

type ProductSettingContext = {
  initialized: boolean;
  getSetting: (
    key: string,
    settingType: ProductSettingType,
    isSharedSetting: boolean,
    contextId?: string
  ) => Promise<WSGProperties | undefined>;
  putSetting: (
    key: string,
    valueJson: Record<string, unknown>,
    settingType: ProductSettingType,
    isSharedSetting: boolean,
    contextId?: string
  ) => Promise<Response>;
};

const Context = createContext<ProductSettingContext | undefined>(undefined);

type ProductSettingProps = {
  buddiRegionCode: string;
  contextId?: string;
  gprId: number;
  getOidcToken: GetToken;
};

export function ProductSettingProvider({
  value: { contextId, getOidcToken, buddiRegionCode, gprId },
  children
}: ProviderProps<ProductSettingProps>): JSX.Element {
  const productSettingsUrl = useBuddi(
    'NewProductSettingsService.Url',
    buddiRegionCode
  );

  const httpService = useMemo((): HttpService | undefined => {
    if (!productSettingsUrl) {
      return undefined;
    }

    const httpService = new HttpService({
      authorization: new AuthorizationService({
        getOidcToken: () => getOidcToken()
      }),
      baseUrl: productSettingsUrl
    });
    return httpService;
  }, [getOidcToken, productSettingsUrl]);

  const get = useCallback(
    (
      key: string,
      settingType: ProductSettingType,
      isSharedSetting: boolean
    ) => {
      if (!httpService) {
        throw new Error('Http service not defined');
      }

      return getSetting(
        httpService,
        contextId,
        gprId,
        key,
        settingType,
        isSharedSetting
      );
    },
    [httpService, contextId, gprId]
  );
  const put = useCallback(
    (
      key: string,
      valueJson: Record<string, unknown>,
      settingType: ProductSettingType,
      isSharedSetting: boolean
    ) => {
      if (!httpService) {
        throw new Error('Http service not defined');
      }

      return putSetting(
        httpService,
        contextId,
        gprId,
        key,
        valueJson,
        settingType,
        isSharedSetting
      );
    },
    [httpService, contextId, gprId]
  );

  const productSettingsContext = useMemo(
    (): ProductSettingContext => ({
      initialized: productSettingsUrl != '' && productSettingsUrl != undefined,
      getSetting: get,
      putSetting: put
    }),
    [productSettingsUrl, get, put]
  );

  return (
    <Context.Provider value={productSettingsContext}>
      {children}
    </Context.Provider>
  );
}

export function useProductSettingService(): ProductSettingContext {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      `useProductSettingService must be used within a productSettingProvider`
    );
  }

  return context;
}
