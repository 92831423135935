import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import type { PWDataItem } from '@bentley/pw-api';
import { LoadingPage } from '../loadingPage';
import { AttributesFormLayout } from './form';
import { AttributesStateProvider } from './state';

type AttributesControlProps = {
  items: PWDataItem[];
  currentSheet: number;
};

export function AttributesControl({
  items,
  currentSheet
}: AttributesControlProps): JSX.Element {
  return (
    <RecoilRoot>
      <Suspense fallback={<LoadingPage />}>
        <AttributesStateProvider items={items} currentSheet={currentSheet}>
          <AttributesFormLayout />
        </AttributesStateProvider>
      </Suspense>
    </RecoilRoot>
  );
}
