import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { sharedProjectParam } from '../../actions/getShareableLink';
import {
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';

type OnUploadedCallback = (uploadedItems: PWItem[]) => void;

export function useRefreshAndSelect(): OnUploadedCallback {
  const httpService = useHttpService();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    selectedState: { setSelectedItems },
    dataManager: { refresh: refreshDataManager }
  } = useTableItemContext();

  const refreshAndSelect = useCallback(
    (uploadedItems: PWItem[]): void => {
      const itemsInCurrentFolder = uploadedItems.filter(
        (item) => item.ParentGuid == currentParent.instanceId
      );

      setSelectedItems(itemsInCurrentFolder);
      const parentGuids = uploadedItems.map((item) => item.ParentGuid ?? '');
      void httpService.cache?.clearEntriesMatching(parentGuids);

      const navigatedProject = new URLSearchParams(location.search).get(
        sharedProjectParam
      );

      if (
        !navigatedProject ||
        !uploadedItems.length ||
        parentGuids.includes(navigatedProject)
      ) {
        void refreshDataManager();
      }
    },
    [
      currentParent.instanceId,
      refreshDataManager,
      setSelectedItems,
      httpService.cache
    ]
  );

  return refreshAndSelect;
}
