import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  useAppViewContext,
  useConnectionContext,
  useNavigationContext
} from '../../context';
import type { PWDataManager } from '../useData/usePWDataManager';
import { useEquivalentState } from '../useNonRenderingState';

export type SelectedState = {
  selectedIds: string[];
  selectedItems: PWItem[];
  selectedRowIds: React.MutableRefObject<Record<string, boolean>>;
  forceSelectedIds: (ids: string[]) => void;
  setSelectedIds: (ids: string[]) => void;
  setSelectedItems: (items: PWItem[]) => void;
};

export function useSelectedItems(dataManager: PWDataManager): SelectedState {
  const {
    selectionState: { selectedItemId }
  } = useConnectionContext();
  const {
    breadcrumbManager: { descendant },
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const [selectedIds, setSelectedIds, _setSelectedIds] = useEquivalentState<
    string[]
  >([]);

  // Used for table
  const selectedRowIds = useRef<Record<string, boolean>>({});

  const selectedItems = useMemo((): PWItem[] => {
    const currentItemsMatchingSelectedIds = dataManager.items.filter((item) =>
      selectedIds.includes(item.instanceId)
    );

    return currentItemsMatchingSelectedIds;
  }, [selectedIds, dataManager.items]);

  function updateRowIds(ids: string[]): void {
    const rowIds = ids.reduce((selectedRows, selectedId) => {
      selectedRows[selectedId] = true;
      return selectedRows;
    }, {} as Record<string, boolean>);

    selectedRowIds.current = rowIds;
  }

  const updateSelectedIds = useCallback(
    (ids: string[]): void => {
      setSelectedIds(ids);
      updateRowIds(ids);
    },
    [setSelectedIds]
  );

  const forceSelectedIds = useCallback(
    (ids: string[]): void => {
      _setSelectedIds(ids);
      updateRowIds(ids);
    },
    [_setSelectedIds]
  );

  const setSelectedItems = useCallback(
    (items: PWItem[]): void => {
      const itemIds = items.map((item) => item.instanceId);
      updateSelectedIds(itemIds);
    },
    [updateSelectedIds]
  );

  useEffect(() => {
    return () => {
      if (!selectedItemId) {
        updateSelectedIds([]);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParent.instanceId, currentAppView.type]);

  useEffect(() => {
    // Check breadcrumb descendant for selecting folder during breadcrumb navigation
    if (descendant) {
      setSelectedItems([descendant]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descendant]);

  const selectedState = useMemo(
    (): SelectedState => ({
      selectedIds,
      selectedItems,
      selectedRowIds,
      forceSelectedIds,
      setSelectedIds: updateSelectedIds,
      setSelectedItems
    }),
    [
      forceSelectedIds,
      selectedIds,
      selectedItems,
      updateSelectedIds,
      setSelectedItems
    ]
  );

  return selectedState;
}
