import type { HttpService, RequestOptions } from '@bentley/pw-api';
import { t } from '../translation';

export function unauthorizedHttpService(baseUrl: string): HttpService {
  return {
    get: (url, options) => unauthorizedImsResponse(),
    put: (url, body, options) => unauthorizedImsResponse(),
    post: (url, body, options) => unauthorizedImsResponse(),
    delete: (url, options) => unauthorizedImsResponse(),
    baseUrl: baseUrl
  } as HttpService;
}

function unauthorizedImsResponse(): Promise<Response> {
  const unauthorizedResponse = new Response(
    JSON.stringify({
      errorMessage: t('Http.IMSUserNotAuthorized')
    }),
    { status: 403 }
  );
  return Promise.resolve(unauthorizedResponse);
}

export function configureHttpOptionsWithAbortController(
  httpOptions?: RequestOptions
): RequestOptions {
  const abortController = httpOptions?.abortController ?? new AbortController();
  httpOptions = { ...httpOptions, abortController };
  return httpOptions;
}
