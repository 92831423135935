import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import { byAlphaNumeric } from './alphaNumSort';
import { byCascadingSort } from './sort';

export function byRichProject(a: PWItem, b: PWItem): number {
  if (!itemIsProject(a) || !itemIsProject(b)) {
    return 0;
  }

  return Number(b.IsRichProject ?? false) - Number(a.IsRichProject ?? false);
}

export function byFolder(a: PWItem, b: PWItem): number {
  return Number(itemIsProject(b)) - Number(itemIsProject(a));
}

export function byFileName(a: PWItem, b: PWItem): number {
  return byCascadingSort(a, b, byRichProject, byFolder, (a, b) =>
    byAlphaNumeric((a as PWDataItem).FileName, (b as PWDataItem).FileName)
  );
}

export function byVersion(a: PWItem, b: PWItem): number {
  return byCascadingSort(a, b, byRichProject, byFolder, (a, b) =>
    byAlphaNumeric((a as PWDataItem).Version, (b as PWDataItem).Version)
  );
}

export function byDate<T>(a: T[keyof T], b: T[keyof T]): number {
  const dateA = new Date((a ?? '0') as string).getTime();
  const dateB = new Date((b ?? '0') as string).getTime();
  return dateA - dateB;
}

export function byNumber<T>(a: T[keyof T], b: T[keyof T]): number {
  const numA = Number(a ?? '');
  const numB = Number(b ?? '');
  return numA - numB;
}

export function byBoolean<T>(a: T[keyof T], b: T[keyof T]): number {
  const boolA = Boolean(a);
  const boolB = Boolean(b);
  return Number(boolB) - Number(boolA);
}

export function byString<T>(a: T[keyof T], b: T[keyof T]): number {
  const stringA = String(a ?? '').toLowerCase();
  const stringB = String(b ?? '').toLowerCase();
  return stringA > stringB ? 1 : -1;
}
