import type { WSGInstancesResponse } from '@bentley/pw-api';
import type {
  InProgressActivityStatus,
  ProgressManager
} from '../../hooks/useProgressManager';
import { t } from '../../services/translation';

export async function getStatusFromResponse(
  response: Response,
  progressManager: ProgressManager,
  jobId: string,
  uniqueId: string
): Promise<InProgressActivityStatus> {
  const json = (await response.clone().json()) as WSGInstancesResponse;
  const instance = json.instances[0];
  const newStatus = instance.properties?.Status;
  const messages = instance.properties?.Messages as unknown[];
  const message = messages?.[0] as Record<string, number | string>;

  switch (newStatus) {
    case 0: {
      if (message && message.Severity !== undefined) {
        if (message.Severity == 0) {
          progressManager.addSuccessMessageToActivity(
            jobId,
            uniqueId,
            message.Message as string
          );
        }
      }
      return 'success';
    }
    case 1: {
      if (message && message.Severity !== undefined) {
        if (message.Severity == 0) {
          progressManager.addSuccessMessageToActivity(
            jobId,
            uniqueId,
            message.Message as string
          );
          return 'success';
        }

        if (message.Severity == 1) {
          progressManager.addWarningMessageToActivity(
            jobId,
            uniqueId,
            message.Message as string
          );
          return 'error';
        }

        if (message.Severity == 2) {
          progressManager.addWarningMessageToActivity(
            jobId,
            uniqueId,
            message.Message as string
          );
          progressManager.updateProgressStatus(
            jobId,
            uniqueId,
            'error',
            response
          );
          return 'error';
        }
      }

      return 'inprogress';
    }
    case 2: {
      if (message && message.Message && message.Severity == 2) {
        progressManager.addWarningMessageToActivity(
          jobId,
          uniqueId,
          message.Message as string
        );
      }
      progressManager.updateProgressStatus(jobId, uniqueId, 'error', response);
      return 'error';
    }
    case 3: {
      if (message && message.Message) {
        progressManager.addWarningMessageToActivity(
          jobId,
          uniqueId,
          message.Message as string
        );
      }
      return 'success';
    }
    default: {
      progressManager.addWarningMessageToActivity(
        jobId,
        uniqueId,
        t('Generic.UnknownError')
      );
      progressManager.updateProgressStatus(jobId, uniqueId, 'error', response);
      return 'error';
    }
  }
}
