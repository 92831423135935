import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgReplace } from '@itwin/itwinui-icons-react';
import { showUpdateServerCopy } from '../../../../actions/useGraphApi';
import {
  useConnectionAuth,
  useFeatureTracking,
  useGraphApiContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useUpdateServerCopyAction(items: PWItem[]): MenuItemAction {
  const graphApiManager = useGraphApiContext();
  const { userName } = useConnectionAuth();
  const { launchDarklyFeatures } = useFeatureTracking();

  const updateServerCopyAction = useMemo((): MenuItemAction => {
    return {
      title: launchDarklyFeatures.showCoauthoringUpdates
        ? t('MenuItem.UpdateOnProjectWise')
        : t('MenuItem.UpdateServerCopy'),
      icon: <SvgReplace />,
      onClick: () => {
        void graphApiManager.updateServerCopy(items);
      },
      hidden: !showUpdateServerCopy(
        items,
        graphApiManager.checkCoAuthoringSessionExists,
        graphApiManager.coAuthoringFeatures,
        userName
      ),
      startGroup: true
    };
  }, [
    graphApiManager,
    items,
    userName,
    launchDarklyFeatures.showCoauthoringUpdates
  ]);

  return updateServerCopyAction;
}
