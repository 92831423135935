import React, { useCallback, useMemo } from 'react';
import { SvgExit } from '@itwin/itwinui-icons-react';
import { useConnectionAuth, usePluginContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useSignOutOption(): MenuItemAction {
  const { signOut, userName } = useConnectionAuth();
  const { connection, progressManager } = usePluginContext();

  const onSignOutClick = useCallback(() => {
    progressManager.stopAllInProgress();
    signOut();
  }, [progressManager, signOut]);

  const signOutOption = useMemo(
    (): MenuItemAction => ({
      'data-testid': 'SignoutButton',
      title: `${t('MenuItem.SignOut', { name: userName })}`,
      icon: <SvgExit />,
      onClick: onSignOutClick,
      hidden: connection.Canned
    }),
    [connection.Canned, onSignOutClick, userName]
  );

  return signOutOption;
}
