import { useCallback, useEffect, useMemo } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { ConsumerApp, DataAccessLevel } from '../../context';
import { useToken } from '../../context';
import { productionEnvironment } from '../../services/environment';
import type { Connection } from '../../services/support';
import { setGlobalLogError } from './globalErrorLogging';

type ErrorInfo = {
  CannedConnection?: boolean;
  ConnectionId?: string;
  ConnectionName?: string;
  Data?: Record<string, unknown>;
  DatasourceId?: string;
  Description?: string;
  Product?: string;
  ProjectId?: string;
  ServerUrl?: string;
};

type UseErrorLoggingConfig = {
  buddiRegionCode: string;
  connection?: Connection;
  consumerApp?: ConsumerApp;
  contextId?: string;
  dataAccessLevel?: DataAccessLevel;
};

export type LogError = (
  description: ErrorInfo['Description'],
  data?: ErrorInfo['Data']
) => void;

export function useErrorLogging({
  buddiRegionCode,
  connection,
  consumerApp,
  contextId,
  dataAccessLevel
}: UseErrorLoggingConfig): LogError {
  const { getOidcToken } = useToken();

  const apiUrl = contextId ? 'ProjectWiseWAC.URL' : 'ProjectWiseWebView.URL';
  const pwBackEndUrl = useBuddi(apiUrl, buddiRegionCode);

  const httpService = useMemo((): HttpService | undefined => {
    if (!pwBackEndUrl) {
      return undefined;
    }

    const httpService = new HttpService({
      authorization: new AuthorizationService({ getOidcToken }),
      baseUrl: pwBackEndUrl
    });

    return httpService;
  }, [getOidcToken, pwBackEndUrl]);

  const product = useMemo((): string => {
    if (dataAccessLevel == 'Datasource') {
      return 'PW WebView';
    }

    if (!consumerApp || consumerApp == 'Generic') {
      return 'PW Web';
    }

    return consumerApp;
  }, [consumerApp, dataAccessLevel]);

  /**
   * Sends error logging to SEQ via PW Web backend
   */
  const logError = useCallback<LogError>(
    (description: ErrorInfo['Description'], data: ErrorInfo['Data']): void => {
      const errorInfo: ErrorInfo = {
        CannedConnection: connection?.Canned,
        ConnectionId: connection?.Id,
        ConnectionName: connection?.Name,
        Data: data,
        DatasourceId: connection?.DatasourceInstanceId,
        Description: description,
        Product: product,
        ProjectId: contextId,
        ServerUrl: connection?.ServerUrl
      };

      console.error(errorInfo);

      if (!httpService || productionEnvironment() == 'localhost') {
        return;
      }

      const body = JSON.stringify(errorInfo);

      void httpService.post('api/logerror', body);
    },
    [connection, contextId, httpService, product]
  );

  useEffect(() => {
    setGlobalLogError(logError);
  }, [logError]);

  return logError;
}
