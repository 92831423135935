import type { Attribute } from '../environmentAttributes/attributeDefinitions';
import type { EnvironmentDefinition } from '../environmentDefinitions/environmentDefinition';

export function getEnvironmentLabel(
  environments: EnvironmentDefinition[],
  attribute: Attribute
): string {
  const environment = environments.find(
    (env) => env.Name == attribute.environmentLabel
  );

  if (!environment) {
    return attribute.environmentLabel;
  }

  return environment.Name;
}

export function getEnvironmentDescription(
  environments: EnvironmentDefinition[],
  attribute: Attribute
): string {
  const environment = environments.find(
    (env) => env.Name == attribute.environmentLabel
  );

  if (!environment) {
    return '';
  }

  return environmentDescription(environment);
}

export function environmentDescription(
  environment: EnvironmentDefinition
): string {
  if (!environment.Description || environment.Description == environment.Name) {
    return '';
  }
  return environment.Description;
}
