import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { t } from '../../../../services/translation';
import { usingDocCodeState } from '../../docCode';
import { DCWLoadingPage } from '../../loading';
import { applyToAllState } from '../applyToRemaining';
import { docPropertiesState, useInitializeProperties } from '..';

export function CreatingDocument(): JSX.Element {
  const docProperties = useRecoilValue(docPropertiesState);

  const { initializeProperties } = useInitializeProperties();
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const applyToAll = useRecoilValue(applyToAllState);

  useEffect(() => {
    initializeProperties();
  }, [initializeProperties, usingDocCode]);

  function loadingText(): string {
    if (applyToAll) {
      return `${t('DocumentCreation.CreatingDocuments')}`;
    }

    if (docProperties.name) {
      return `${t('DocumentCreation.CreatingDocumentWithName', {
        name: docProperties.name
      })}`;
    }

    return `${t('DocumentCreation.CreatingDocument')}`;
  }

  return (
    <DCWLoadingPage
      loadingText={t('Generic.Loading')}
      loadingSubText={loadingText()}
    />
  );
}
