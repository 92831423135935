import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { AuthorizationService, HttpService } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { DynamicProjectSettings } from '../../../actions/dynamicProjectSettings';
import {
  getProjectSettings,
  postProjectSettings
} from '../../../actions/dynamicProjectSettings';
import { useOrganizationId } from '../../../hooks/useOrganizationId';
import type { GetToken } from '../../../services/http';

const Context = React.createContext<ProjectSettingsContext | undefined>(
  undefined
);

export type ProjectSettingsContext = {
  httpService: HttpService | undefined;
  initialized: boolean;
  organizationId: string;
  projectSettings: DynamicProjectSettings;
  postNewProjectSettings: (
    contextId: string,
    settings: DynamicProjectSettings
  ) => void;
};

type ProjectSettingsProps = {
  buddiRegionCode: string;
  contextId?: string;
  user: User;
  getOidcToken: GetToken;
};

export function ProjectSettingsProvider({
  value: { buddiRegionCode, contextId, user, getOidcToken },
  children
}: ProviderProps<ProjectSettingsProps>): JSX.Element {
  const organizationId = useOrganizationId({
    buddiRegionCode,
    contextId,
    user,
    getOidcToken
  });

  const pwDriveSettingsUrl = useBuddi(
    'ProjectWiseDriveSettingsAPI',
    buddiRegionCode
  );
  const httpService = useMemo((): HttpService | undefined => {
    if (!pwDriveSettingsUrl) {
      return undefined;
    }

    return new HttpService({
      authorization: new AuthorizationService({ getOidcToken }),
      baseUrl: pwDriveSettingsUrl
    });
  }, [getOidcToken, pwDriveSettingsUrl]);

  const defaultSettings: DynamicProjectSettings = useMemo(
    () => ({
      AutoCheckoutDocsInDrive: true,
      AutoCreationBlacklist: [],
      UseDCWInFoldersWithCode: true,
      UseDCWInFoldersWithoutCode: true,
      UseDCWInPWIC: true
    }),
    []
  );

  const [initialized, setInitialized] = useState<boolean>(false);
  const [projectSettings, setProjectSettings] =
    useState<DynamicProjectSettings>(defaultSettings);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchProjectSettings() {
      if (!contextId || !httpService || !organizationId) {
        return;
      }

      try {
        setInitialized(false);
        const projSettings = await getProjectSettings(
          contextId,
          organizationId,
          httpService
        );
        if (!abortController.signal.aborted) {
          setProjectSettings(projSettings);
        }
      } catch {
        setProjectSettings(defaultSettings);
      } finally {
        if (!abortController.signal.aborted) {
          setInitialized(true);
        }
      }
    }

    if (!initialized && pwDriveSettingsUrl) {
      void fetchProjectSettings();
    }

    return () => {
      abortController.abort();
    };
  }, [
    contextId,
    defaultSettings,
    httpService,
    initialized,
    organizationId,
    pwDriveSettingsUrl
  ]);

  function postNewProjectSettings(
    contextId: string,
    settings: DynamicProjectSettings
  ) {
    setProjectSettings(settings);

    if (!httpService) {
      throw new Error('Http service not defined');
    }
    void postProjectSettings(contextId, settings, httpService);
  }

  const projectSettingsContext: ProjectSettingsContext = {
    projectSettings,
    postNewProjectSettings,
    organizationId: organizationId ?? (user.profile.org as string),
    initialized,
    httpService
  };

  return (
    <Context.Provider value={projectSettingsContext}>
      {children}
    </Context.Provider>
  );
}

export function useProjectSettingsContext(): ProjectSettingsContext {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(
      'useProjectSettingsContext must be used within a ProjectSettingsContext'
    );
  }
  return context;
}
