import React from 'react';
import type { HttpService, PWDataItem } from '@bentley/pw-api';
import type { OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import CheckInFileModal from './checkInFileModal';
import UpdateFileModal from './updateFileModal';

export function updateFileModal(
  openModal: OpenModal,
  oldItem: PWDataItem,
  newFile: File,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onSuccess: () => void
): void {
  const modal = (
    <UpdateFileModal
      oldItem={oldItem}
      newFile={newFile}
      httpService={httpService}
      trackFeature={trackFeature}
      onSuccess={onSuccess}
    />
  );
  openModal(modal);
}

export function checkInFileModal(
  openModal: OpenModal,
  oldItem: PWDataItem,
  newFile: File,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onSuccess: () => void
): void {
  const modal = (
    <CheckInFileModal
      oldItem={oldItem}
      newFile={newFile}
      httpService={httpService}
      trackFeature={trackFeature}
      onSuccess={onSuccess}
    />
  );
  openModal(modal);
}
