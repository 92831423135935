import type { PWDataItem, PWItem, PWProject } from '@bentley/pw-api';
import {
  filterDataItems,
  filterFileTypes,
  filterProjects,
  itemFileExtension,
  itemFileNameWithoutExtension,
  itemIsDataItem,
  itemIsProject,
  pwConstants
} from '@bentley/pw-api';
import { castFileToDataItem } from '../../hooks/useProgressManager/utils';
import type { UploadNode } from '../upload/tree';
import { castProjectInfoToProject } from '../upload/utils';

export type Action = 'keep' | 'replace' | 'version' | 'rename';
type ConflictType = 'name' | 'filename';

export type ConflictResolution = {
  action: Action;
  conflictType: ConflictType;
  sourceItem: PWItem;
  file?: File;
  destinationItems: PWItem[];
  customName: string;
  customVersion?: string;
  originalName?: string;
  originalFileName?: string;
};

export function siblingConflicts(item: PWItem, siblings: PWItem[]): PWItem[] {
  if (itemIsProject(item)) {
    const projectSiblings = filterProjects(siblings);
    return projectConflicts(item, projectSiblings);
  } else {
    const dataItem = item as PWDataItem;
    const dataItemSiblings = filterDataItems(siblings);

    return fileConflicts(dataItem, dataItemSiblings);
  }
}

export function gatherConflictsFromUploadNode(
  uploadNode: UploadNode,
  siblings: PWItem[]
): PWItem[] {
  const siblingProjects = filterProjects(siblings);
  const siblingItems = filterDataItems(siblings);

  const folderConflicts = getProjectConflictsFromUploadNode(
    uploadNode,
    siblingProjects
  );

  const docConflicts = getFileConflictsFromUploadNode(uploadNode, siblingItems);

  return [...folderConflicts, ...docConflicts];
}

function getProjectConflictsFromUploadNode(
  uploadNode: UploadNode,
  siblings: PWProject[]
): PWItem[] {
  const projects = uploadNode.directories.map((dir) => {
    if (dir.current) {
      return castProjectInfoToProject(dir.current?.name, dir.current?.name);
    }

    return;
  });

  const folderConflicts = projects.filter((proj) => {
    if (!proj) {
      return undefined;
    }

    if (projectConflicts(proj, siblings).length) {
      return proj as PWItem;
    }

    return undefined;
  });

  return [...folderConflicts] as PWItem[];
}

function getFileConflictsFromUploadNode(
  uploadNode: UploadNode,
  siblings: PWDataItem[]
): PWItem[] {
  if (!siblings.length) {
    return [];
  }

  const files = uploadNode.files.map((file) =>
    castFileToDataItem(file, siblings[0].ParentGuid)
  );

  const docConflicts = files.filter((file) => {
    if (!file) {
      return undefined;
    }

    if (fileConflicts(file, siblings).length) {
      return file as PWItem;
    }

    return undefined;
  });

  return [...docConflicts] as PWItem[];
}

export function getConflictType(
  item: PWItem,
  siblings: PWItem[]
): ConflictType {
  if (itemIsProject(item)) {
    return 'name';
  }

  return containDocumentNameConflict(item.Name, siblings as PWDataItem[])
    ? 'name'
    : 'filename';
}

function projectConflicts(item: PWProject, siblings: PWProject[]): PWItem[] {
  return siblings.filter((sibling) => projectNameConflict(item.Name, sibling));
}

function fileConflicts(item: PWDataItem, siblings: PWDataItem[]): PWItem[] {
  return siblings.filter(
    (sibling) =>
      fileNameConflict(item.Name, sibling) ||
      fileNameConflict(item.FileName, sibling)
  );
}

export function getNextName(item: PWItem, siblings: PWItem[]): string {
  if (itemIsProject(item)) {
    const projectSiblings = filterProjects(siblings);
    return getNextProjectName(item, projectSiblings);
  } else {
    if (!itemIsDataItem(item)) {
      return '';
    }

    const fileTypeSiblings = filterDataItems(siblings);
    return containDocumentNameConflict(item.Name, fileTypeSiblings)
      ? getNextDocumentName(item, fileTypeSiblings)
      : getNextFileName(item, fileTypeSiblings);
  }
}

function getNextProjectName(item: PWProject, siblings: PWProject[]): string {
  let number = 1;

  while (
    containProjectNameConflict(buildNumberedProjectName(item, number), siblings)
  ) {
    number++;
  }

  return buildNumberedProjectName(item, number);
}

function getNextFileName(item: PWDataItem, siblings: PWDataItem[]): string {
  let number = 1;

  while (
    containFileNameConflict(buildUnderscoredFileName(item, number), siblings) ||
    containDocumentNameConflict(
      buildUnderscoredFileName(item, number),
      siblings
    )
  ) {
    number++;
  }

  return buildUnderscoredFileName(item, number);
}

function getNextDocumentName(item: PWDataItem, siblings: PWDataItem[]): string {
  let number = 1;

  while (
    containDocumentNameConflict(
      buildNumberedDocumentName(item, number),
      siblings
    ) ||
    containFileNameConflict(buildNumberedDocumentName(item, number), siblings)
  ) {
    number++;
  }

  return buildNumberedDocumentName(item, number);
}

export function getOriginalName(
  item: PWItem,
  siblings: PWItem[]
): string | undefined {
  const name = siblings.find(
    (sibling) => sibling.Name == item.Name || sibling.Name == item.Name
  )?.Name;

  if (name) {
    return name;
  }

  return filterFileTypes(siblings).find(
    (sibling) =>
      sibling.FileName == (item as PWDataItem).FileName ||
      sibling.FileName == item.Name
  )?.Name;
}

export function getOriginalFileName(
  item: PWItem,
  siblings: PWItem[]
): string | undefined {
  if (!itemIsDataItem(item)) {
    return undefined;
  }

  const fileName = filterFileTypes(siblings).find(
    (sibling) =>
      sibling.FileName == item.FileName || sibling.FileName == item.Name
  )?.FileName;

  if (fileName) {
    return fileName;
  }

  return filterFileTypes(siblings).find(
    (sibling) => sibling.Name == item.FileName || sibling.Name == item.Name
  )?.FileName;
}

function containProjectNameConflict(name: string, items: PWProject[]): boolean {
  return items.some((item) => projectNameConflict(name, item));
}

export function containFileNameConflict(
  name: string,
  items: PWDataItem[]
): boolean {
  return items.some((item) => fileNameConflict(name, item));
}

export function containDocumentNameConflict(
  name: string,
  items: PWDataItem[]
): boolean {
  return items.some((item) => documentNameConflict(name, item));
}

function projectNameConflict(name: string, item: PWProject): boolean {
  return item.Name.toLowerCase() == name.toLowerCase();
}

function documentNameConflict(name: string, item: PWDataItem): boolean {
  return item.Name.toLowerCase() == name.toLowerCase();
}

function fileNameConflict(name: string, item: PWDataItem): boolean {
  if (item.Name && item.Name.toLowerCase() == name.toLowerCase()) {
    return true;
  }

  if (item.FileName && item.FileName.toLowerCase() == name.toLowerCase()) {
    return true;
  }

  return false;
}

function buildNumberedProjectName(item: PWProject, number: number): string {
  const projectNumber = ` (${number})`;

  const shortProjectName = item.Name.substring(
    0,
    pwConstants.maxFolderNameLength - projectNumber.length
  );
  return shortProjectName + projectNumber;
}

function buildNumberedDocumentName(item: PWDataItem, number: number): string {
  const ext = itemFileExtension(item);
  let name = item.Name;

  // an item could not have an extension so this checks if it exists before shortening the new file name
  if (ext) {
    name = itemFileNameWithoutExtension(item);
  }

  const documentNumber = ` (${number})`;
  const fileExtension = `${ext ? '.' : ''}${ext}`;
  const numberWithExtension = documentNumber + fileExtension;

  const shortFileName = name.substring(
    0,
    pwConstants.maxFileNameLength - numberWithExtension.length
  );
  return shortFileName + numberWithExtension;
}

function buildUnderscoredFileName(item: PWDataItem, number: number): string {
  const ext = itemFileExtension(item);
  let name = item.FileName;

  // an item could not have an extension so this checks if it exists before shortening the new file name
  if (ext) {
    name = itemFileNameWithoutExtension(item);
  }

  return `${name}_${number}${ext ? '.' : ''}${ext}`;
}

export function byName(
  conflictResolution: ConflictResolution,
  compareTo: ConflictResolution
): number {
  const a = conflictResolution.sourceItem;
  const b = compareTo.sourceItem;

  if (itemIsProject(a) && !itemIsProject(b)) {
    return -1;
  }

  if (!itemIsProject(a) && itemIsProject(b)) {
    return 1;
  }

  if ([a, b].every(itemIsProject)) {
    return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1;
  }

  return (a as PWDataItem).Name.toLowerCase() >
    (b as PWDataItem).Name.toLowerCase()
    ? 1
    : -1;
}

export function bySequence(a: PWItem, b: PWItem): number {
  if (!itemIsDataItem(a)) {
    return -1;
  }

  if (!itemIsDataItem(b)) {
    return 1;
  }

  return a.Sequence - b.Sequence;
}
