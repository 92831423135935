import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem,
  WSGChangedInstanceResponse
} from '@bentley/pw-api';
import {
  flattenProperties,
  itemFileExtension,
  itemIsDataItem,
  itemIsFileType,
  itemIsPlaceholder,
  itemIsProject,
  parseFileExtension
} from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import {
  InputGrid,
  InputWithDecorations,
  Label,
  LabeledInput,
  StatusMessage
} from '@itwin/itwinui-react';
import { LockIcon } from '../../components/lockIcon';
import { PWModal } from '../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext
} from '../../context';
import type { InputChange, InputError } from '../../hooks/useForm';
import { useForm } from '../../hooks/useForm';
import type { FormChangeEvent } from '../../hooks/useForm/interface';
import { useLocalStorage } from '../../hooks/useStorage';
import {
  getNormalizedFileName,
  getNormalizedItemName
} from '../../services/itemProperties';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import FileNameExtensionChangeModal from './fileExtensionChangeModal';
import {
  isFormValid,
  validInput,
  validateDescription,
  validateName,
  validateVersion
} from './inputValidation';
import { renameItem } from './rename';
import {
  checkFileExtensionChange,
  displayFileNameEdit,
  fileNameChangeAllowed
} from './renameFileRequirements';

export interface RenameForm {
  name: string;
  fileName?: string;
  version?: string;
  description: string;
  renameFileEnabled?: boolean;
  fileIsPlaceholder: boolean;
  isFolder?: boolean;
}

interface IRenameModalProps {
  item: PWItem;
  recoveredInputs?: RenameForm;
  onSuccess: (updatedItem: PWItem) => void;
  itemsInFolder?: PWItem[];
}

const RenameModal = ({
  item,
  recoveredInputs,
  onSuccess,
  itemsInFolder
}: IRenameModalProps): JSX.Element => {
  const { trackFeature } = useFeatureTracking();
  const httpService: HttpService = useHttpService();
  const { primaryModal } = useNavigationContext();

  const [nameIconLocked, storeNameIconLocked] = useLocalStorage<boolean>(
    'fileNameLockedSelection',
    false
  );
  const [renameFileIsEnabled, setRenameFileIsEnabled] = useState<boolean>(
    !nameIconLocked
  );
  const [hasFileRenamePermission, setHasFileRenamePermission] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lockHasChanged, setLockHasChanged] = useState<boolean>(false);
  const initialInputs =
    recoveredInputs ??
    ({
      name: getNormalizedItemName(item),
      description: item.Description,
      fileName: getFileName(item) ?? getNormalizedItemName(item),
      version: itemIsDataItem(item) ? item.Version : null,
      renameFileEnabled: renameFileIsEnabled,
      fileIsPlaceholder: itemIsPlaceholder(item),
      isFolder: itemIsProject(item)
    } as RenameForm);
  const {
    form: { inputs, pristine, valid, errors },
    onChange,
    setData
  } = useForm<RenameForm>({
    inputs: initialInputs,
    errors: {},
    validateInput,
    isFormValid
  });
  const LABEL_NEW_NAME = t('Generic.Name');
  const LABEL_NEW_DESCRIPTION = t('Generic.Description');
  const LABEL_NEW_FILE_NAME = t('Generic.FileName');
  const LABEL_VERSION = t('Generic.Version');

  const setFileName = useCallback((): void => {
    if (!lockHasChanged && renameFileIsEnabled) {
      setData({
        name: 'fileName',
        value: getNormalizedFileName((item as PWDataItem).FileName)
      });
    }
  }, [item, lockHasChanged, renameFileIsEnabled, setData]);

  useEffect(() => {
    async function checkFileNameChangePermission(): Promise<void> {
      try {
        const response = await fileNameChangeAllowed(httpService);
        setHasFileRenamePermission(response);
      } catch (error) {
        const errorMsg: string = (error as Error)?.message ?? 'Unknown Error';
        const content = t('Rename.ErrorWithEditPermission', { errorMsg });
        openToast({ content, category: 'negative' });
      }

      setIsLoading(false);
    }
    if (itemIsFileType(item)) {
      void checkFileNameChangePermission();
    } else {
      setIsLoading(false);
    }
  }, [item, httpService]);

  useEffect(() => {
    if (!itemIsFileType(item) || isLoading) {
      return;
    }

    if (hasFileRenamePermission) {
      setLockHasChanged(true);
    }
    setRenameFileIsEnabled(hasFileRenamePermission && !nameIconLocked);
    setFileName();
  }, [hasFileRenamePermission, isLoading, item, nameIconLocked, setFileName]);

  useEffect(() => {
    if (itemIsFileType(item)) {
      if (!renameFileIsEnabled) {
        const fileNameExtension = parseFileExtension(inputs.fileName ?? '');
        const nameExtension = parseFileExtension(inputs.name ?? '');
        nameExtension == fileNameExtension
          ? setData({ name: 'fileName', value: inputs.name })
          : setData({
              name: 'fileName',
              value: getNormalizedFileName(
                inputs.name + '.' + fileNameExtension
              )
            });
      } else {
        setData({
          name: 'fileName',
          value: getNormalizedFileName(inputs.fileName ?? inputs.name)
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameFileIsEnabled, inputs.name, inputs.fileName]);

  useEffect(() => {
    setData({ name: 'fileName', value: inputs.fileName ?? inputs.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.fileName]);

  function validateInput(
    { name, value, type }: InputChange<RenameForm>,
    inputs: RenameForm
  ): InputError<RenameForm> {
    if ((name == 'name' || name == 'fileName') && typeof value == 'string') {
      return validateName(inputs, value, name, item, itemsInFolder ?? []);
    }
    if (name == 'renameFileEnabled' && typeof value == 'boolean') {
      return validateName(
        inputs,
        inputs.name,
        'name',
        item,
        itemsInFolder ?? []
      );
    }
    if (name == 'description' && typeof value == 'string') {
      return validateDescription(value);
    }
    if (name == 'version' && typeof value == 'string') {
      return validateVersion(
        value,
        item,
        itemsInFolder ?? [],
        initialInputs.version ?? ''
      );
    }
    return validInput(name);
  }

  async function onSave(dataToSubmit: RenameForm): Promise<void> {
    primaryModal.close();
    if (!displayFileNameEdit(item)) {
      dataToSubmit.fileName = undefined;
      return rename(dataToSubmit);
    }
    const itemAsFileType: PWFileType = item as PWFileType;
    executeNextStep(dataToSubmit, itemAsFileType);
  }

  function getFileName(item: PWItem): string | undefined {
    if (itemIsFileType(item)) {
      return getNormalizedFileName(item.FileName);
    }
    return undefined;
  }

  function onFileNameIconClick(e?: React.MouseEvent): void {
    if (hasFileRenamePermission) {
      setRenameFileIsEnabled(!renameFileIsEnabled);
      storeNameIconLocked(!nameIconLocked);
      setLockHasChanged(true);
      const changeEvent = {
        ...e,
        target: {
          name: 'renameFileEnabled',
          value: !renameFileIsEnabled,
          checked: !renameFileIsEnabled,
          type: 'checkbox'
        }
      } as unknown as FormChangeEvent<RenameForm>;
      onChange(changeEvent);
    }
  }

  async function rename({
    name,
    fileName,
    description,
    version
  }: RenameForm): Promise<void> {
    trackFeature('RENAME_ITEM');
    const itemFileName: string | undefined = getFileName(item);
    if (itemFileName && renameFileIsEnabled && fileName !== itemFileName) {
      trackFeature('RENAME_ITEM_FILENAME');
    }

    try {
      const response = await renameItem(
        item,
        httpService,
        name.trim(),
        description,
        fileName?.trim(),
        version?.trim()
      );
      const data = (await response.json()) as WSGChangedInstanceResponse;
      const changedInstance = data.changedInstance.instanceAfterChange;
      const updatedItem = flattenProperties(changedInstance) as PWItem;
      onSuccess({ ...item, ...updatedItem });
      const content = t('Rename.RenamedItemSuccessfully', {
        name: inputs.name
      });
      openToast({ content, category: 'positive' });
    } catch (error) {
      const errorMsg: string = (error as Error)?.message ?? 'Unknown Error';
      const content = t('Rename.ErrorRenaming', { name: item.Name, errorMsg });
      openToast({ content, category: 'negative' });
    }
  }

  function renderNameInput(): JSX.Element {
    return (
      <InputGrid>
        <Label htmlFor="name-input">{LABEL_NEW_NAME}</Label>
        <InputWithDecorations>
          <InputWithDecorations.Input
            id="name-input"
            name="name"
            data-testid="nameInput"
            autoFocus={true}
            value={inputs.name}
            onChange={onChange}
            status={
              errors.name?.errorType == 'error'
                ? 'negative'
                : errors.name?.errorType == 'warning'
                ? 'warning'
                : undefined
            }
            placeholder={t('Rename.EnterNewName')}
          />
          {displayFileNameEdit(item) && (
            <LockIcon
              disabled={!hasFileRenamePermission}
              locked={!renameFileIsEnabled}
              tooltipText={t('Rename.ForceFileNameToBeSame')}
              onClick={onFileNameIconClick}
            />
          )}
        </InputWithDecorations>
        <StatusMessage
          status={
            errors.name?.errorType == 'error'
              ? 'negative'
              : errors.name?.errorType == 'warning'
              ? 'warning'
              : undefined
          }
        >
          {errors.name?.errorMessage ?? ''}
        </StatusMessage>
      </InputGrid>
    );
  }

  function renderFileNameInput(item: PWItem): JSX.Element | null {
    if (!displayFileNameEdit(item)) {
      return null;
    }
    return (
      <LabeledInput
        label={LABEL_NEW_FILE_NAME}
        name="fileName"
        data-testid="fileNameInput"
        value={inputs.fileName}
        onChange={onChange}
        status={
          errors.fileName?.errorType == 'error'
            ? 'negative'
            : errors.fileName?.errorType == 'warning'
            ? 'warning'
            : undefined
        }
        placeholder={t('Rename.EnterNewName')}
        disabled={hasFileRenamePermission ? !renameFileIsEnabled : true}
        message={
          <StatusMessage
            status={
              errors.fileName?.errorType == 'error'
                ? 'negative'
                : errors.fileName?.errorType == 'warning'
                ? 'warning'
                : undefined
            }
          >
            {errors.fileName?.errorMessage ?? ''}
          </StatusMessage>
        }
      />
    );
  }

  function renderVersionInput(item: PWItem): JSX.Element | null {
    if (itemIsProject(item)) {
      return null;
    } else {
      return (
        <LabeledInput
          label={LABEL_VERSION}
          name="version"
          data-testid="versionInput"
          value={inputs.version}
          onChange={onChange}
          status={
            errors.version?.errorType == 'error'
              ? 'negative'
              : errors.version?.errorType == 'warning'
              ? 'warning'
              : undefined
          }
          placeholder={t('Rename.EnterNewVersion')}
          message={
            <StatusMessage
              status={
                errors.version?.errorType == 'error'
                  ? 'negative'
                  : errors.version?.errorType == 'warning'
                  ? 'warning'
                  : undefined
              }
            >
              {errors.version?.errorMessage ?? ''}
            </StatusMessage>
          }
        />
      );
    }
  }

  function renderFileExtensionChangeConfirmation(
    formToSubmit: RenameForm,
    itemAsDataItem: PWDataItem | PWFileType
  ): void {
    primaryModal.open(
      <FileNameExtensionChangeModal
        onYes={() => {
          void rename(formToSubmit), primaryModal.close();
        }}
        onSuccess={onSuccess}
        item={itemAsDataItem}
        formToSubmit={formToSubmit}
      />
    );
  }

  function executeNextStep(
    dataToSubmit: RenameForm,
    itemAsFileType: PWFileType
  ): void {
    const checkNextStep: boolean = decideNextStep(dataToSubmit, itemAsFileType);
    if (checkNextStep) {
      return renderFileExtensionChangeConfirmation(
        dataToSubmit,
        itemAsFileType
      );
    } else {
      void rename(dataToSubmit);
    }
  }

  function decideNextStep(
    dataToSubmit: RenameForm,
    itemAsFileType: PWFileType
  ): boolean {
    const isFileExtensionChanging: boolean = checkFileExtensionChange(
      dataToSubmit,
      itemAsFileType
    );
    if (isFileExtensionChanging && itemFileExtension(itemAsFileType)) {
      return true;
    } else {
      if (!dataToSubmit.fileName?.includes('.')) {
        return true;
      } else {
        if (!parseFileExtension(dataToSubmit.fileName ?? '')) {
          renderFileExtensionChangeConfirmation(dataToSubmit, itemAsFileType);
        } else {
          return false;
        }
      }
    }
    return false;
  }

  const inputsEqualInitial = useMemo(() => {
    return (
      initialInputs.name == inputs.name &&
      initialInputs.fileName == inputs.fileName &&
      initialInputs.description == inputs.description &&
      initialInputs.version == inputs.version
    );
  }, [
    initialInputs.description,
    initialInputs.fileName,
    initialInputs.name,
    initialInputs.version,
    inputs.description,
    inputs.fileName,
    inputs.name,
    inputs.version
  ]);

  const renameDisabled = useMemo(() => {
    return pristine || !valid || inputsEqualInitial;
  }, [pristine, valid, inputsEqualInitial]);

  return (
    <PWModal
      title={t('Generic.Rename')}
      isLoading={isLoading}
      primaryButton={{
        title: t('Generic.Rename'),
        onClick: () => {
          void onSave(inputs);
        },
        disabled: renameDisabled,
        'data-testid': 'rename-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'RenameModal' }}
    >
      <ItemSummary item={[item]} />
      {renderNameInput()}
      {renderFileNameInput(item)}
      {renderVersionInput(item)}
      <LabeledInput
        label={LABEL_NEW_DESCRIPTION}
        name="description"
        data-testid="descriptionInput"
        value={inputs.description}
        onChange={onChange}
        status={
          errors.description?.errorType == 'error'
            ? 'negative'
            : errors.description?.errorType == 'warning'
            ? 'warning'
            : undefined
        }
        placeholder={t('Rename.EnterNewDescription')}
        message={
          <StatusMessage
            status={
              errors.description?.errorType == 'error'
                ? 'negative'
                : errors.description?.errorType == 'warning'
                ? 'warning'
                : undefined
            }
          >
            {errors.description?.errorMessage ?? ''}
          </StatusMessage>
        }
      />
    </PWModal>
  );
};

export default RenameModal;
