import React from 'react';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Flex, StatusMessage } from '@itwin/itwinui-react';
import type { ProgressSummaryItem } from '../ProgressSummary';

type FailureItemProps = {
  itemProgressSummary: ProgressSummaryItem;
};

export function FailureItem({
  itemProgressSummary
}: FailureItemProps): JSX.Element {
  return (
    <Flex flexDirection="column" alignItems="stretch">
      <ItemSummary item={itemProgressSummary.item} />
      {itemProgressSummary.errorText && (
        <StatusMessage status="negative">
          {itemProgressSummary.errorText}
        </StatusMessage>
      )}
      {itemProgressSummary.successText && (
        <StatusMessage status="positive">
          {itemProgressSummary.successText}
        </StatusMessage>
      )}
    </Flex>
  );
}
