import type { CSSProperties } from 'react';
import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Checkbox } from '@itwin/itwinui-react';
import { t } from '../../../../services/translation';
import { InfoTooltip } from '../../../infoTooltip';
import { usingDocCodeState } from '../../docCode';
import { docCodeFormValidState } from '../docCodeStep/state';
import { isCopyModeState } from '../state';
import { applyToAllState, uploadingDirectoriesState } from './state';

type ApplyAllCheckProps = {
  style?: CSSProperties;
  'data-testid'?: string;
};

export function ApplyAllCheck(props: ApplyAllCheckProps): JSX.Element {
  const [applyToAll, setApplyToAll] = useRecoilState(applyToAllState);
  const uploadingDirectories = useRecoilValue(uploadingDirectoriesState);
  const isCopyMode = useRecoilValue(isCopyModeState);
  const docCodeApplied = useRecoilValue(usingDocCodeState);
  const docCodeFormValid = useRecoilValue(docCodeFormValidState);

  function applyAllText(): string {
    if (uploadingDirectories) {
      return t('DocumentCreation.MustApplyToRemaining');
    }
    if (isCopyMode && (docCodeApplied || docCodeFormValid)) {
      return t('DocumentCreation.ApplyToRemainingDisabledWithCopyAndDocCode');
    }
    return t('DocumentCreation.ApplyToRemaining');
  }

  function onCheckApplyAll(): void {
    setApplyToAll((current) => !current);
  }

  const disabled = useMemo(() => {
    return (
      uploadingDirectories ||
      (isCopyMode && (docCodeApplied || docCodeFormValid))
    );
  }, [docCodeApplied, docCodeFormValid, isCopyMode, uploadingDirectories]);

  return (
    <InfoTooltip tooltipText={applyAllText()}>
      <Checkbox
        label={t('DocumentCreation.ApplyToRemainingDocuments')}
        onClick={onCheckApplyAll}
        onChange={() => null}
        checked={applyToAll && !disabled}
        disabled={disabled}
        data-testid={props['data-testid']}
      />
    </InfoTooltip>
  );
}
