import type {
  HttpService,
  PWDataItem,
  PWFlatSet,
  PWItem
} from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import type { CloseModal, OpenModal } from '../../../hooks/useModal';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { t } from '../../../services/translation';
import type { AddFlatSetMemberProgressTracker } from '../utils';
import {
  getAddItemQueryBody,
  newAddFlatSetMemberProgressTracker
} from '../utils';
import {
  handleFailedResponses,
  notifyAddSuccess,
  notifyOnAddItems,
  on400Error,
  on403Error
} from './notifications';

export async function addFlatSetMembers(
  flatSet: PWFlatSet,
  items: DocumentLink[],
  httpService: HttpService,
  onComplete: (addedItems: PWItem[]) => void,
  openModal: OpenModal,
  closeModal: CloseModal
): Promise<Response[] | undefined> {
  const toastHandle = notifyOnAddItems(items.length, flatSet);
  const progressTracker = newAddFlatSetMemberProgressTracker(
    items.map((item) => documentLinkToPWItems(item, flatSet))
  );

  try {
    const addItemsInfo = await Promise.all(
      items.map((item) =>
        usingConcurrencyLimiter(
          async () =>
            addFlatSetMember(flatSet, httpService, item, progressTracker),
          'background'
        )
      )
    );

    const successfullyAddedItemsResponse = addItemsInfo.filter(
      (info) => info.ok
    );

    if (!successfullyAddedItemsResponse?.length) {
      toastHandle.close();
    } else {
      notifyAddSuccess(
        successfullyAddedItemsResponse.length,
        toastHandle,
        flatSet
      );
    }

    const successfullyAddedItems = getSuccessfullyAddedItems(
      addItemsInfo,
      items,
      flatSet
    );

    if (progressTracker.failedItems > 0) {
      handleFailedResponses(progressTracker, openModal, closeModal);
    }
    onComplete(successfullyAddedItems ?? []);

    return addItemsInfo;
  } catch (error) {
    toastHandle.close();
    return undefined;
  }
}

export async function addFlatSetMember(
  flatSet: PWFlatSet,
  httpService: HttpService,
  item: DocumentLink,
  progressTracker: AddFlatSetMemberProgressTracker
): Promise<Response> {
  const query = `PW_WSG/FlatSet/${flatSet.instanceId}`;
  const body = getAddItemQueryBody(flatSet.instanceId, item.id);
  const response = await httpService.post(query, body);

  const documentLinkToPWItem = documentLinkToPWItems(item, flatSet);

  if (!response.ok) {
    progressTracker.failedItems++;
    if (response.status === 403) {
      const errorText = t('FlatSet.AddItem.Notifications.Error', {
        errorMessage: on403Error()
      });
      progressTracker.failures.push({
        item: documentLinkToPWItem,
        errorText
      });
    } else if (response.status === 400) {
      const responseBody = (await response.json()) as Record<string, string>;
      const errorText = t('FlatSet.AddItem.Notifications.Error', {
        errorMessage: on400Error(item, responseBody.errorMessage)
      });
      progressTracker.failures.push({
        item: documentLinkToPWItem,
        errorText
      });
    } else {
      const errorMessage = response.statusText
        ? response.statusText
        : t('Generic.FileNotFound');
      const errorText = t('FlatSet.AddItem.Notifications.Error', {
        errorMessage: errorMessage
      });
      progressTracker.failures.push({
        item: documentLinkToPWItem,
        errorText
      });
    }
  }

  return response;
}

function documentLinkToPWItems(
  item: DocumentLink,
  flatSet: PWFlatSet
): PWDataItem {
  return {
    instanceId: item.id,
    ParentGuid: flatSet.instanceId,
    FileName: item.name,
    Name: item.name
  } as PWDataItem;
}

function getSuccessfullyAddedItems(
  responses: Response[] | undefined,
  items: DocumentLink[],
  flatSet: PWFlatSet
): PWItem[] | undefined {
  const failedResponsesIndex = responses
    ?.map((info, index) => (!info.ok ? index : -1))
    .filter((index) => index != -1);

  const successfullyAddedItems = items.filter(
    (item, index) => !failedResponsesIndex?.includes(index)
  );

  return successfullyAddedItems.map((item) =>
    documentLinkToPWItems(item, flatSet)
  );
}

export function pwItemsToDocumentLink(item: PWDataItem): DocumentLink {
  return {
    id: item.instanceId,
    name: item.Name,
    version: item.Version,
    itemType: item.className
  } as DocumentLink;
}
