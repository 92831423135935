import type { HttpService, PWDataItem } from '@bentley/pw-api';
import { openFileInput } from '../../components/fileInput';
import type { OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { replaceFile } from '../replace';
import { checkIn } from './checkIn';
import { updateFileModal } from './modals';
import {
  notifyDocumentUpdate,
  notifyDocumentUpdateSuccess,
  notifyErrorDuringUpdate
} from './notifications';

/**
 * Initiates the update workflow, either updating server copy of file, or checking in
 * @param {PWDataItem} oldDocument document that is being updated
 * @param {HttpService} httpService HttpService for put and post requests
 * @param {OpenModal} openModal function to open main UI modal
 * @param {TrackFeature} trackFeature function for tracking feature usage
 * @param {() => void} onSuccess callback for successful update operation
 */
export function startUpdateFile(
  oldDocument: PWDataItem,
  httpService: HttpService,
  openModal: OpenModal,
  trackFeature: TrackFeature,
  onSuccess: () => void
): void {
  const handleInput = (newFile: File) => {
    updateFileModal(
      openModal,
      oldDocument,
      newFile,
      httpService,
      trackFeature,
      onSuccess
    );
  };

  openFileInput(handleInput);
}

/**
 * Funciton for splitting update workflow into update server copy or check in operations
 * @param newFile new file updating old item
 * @param oldItem Document being updated
 * @param unlockNewFile Whether or not the document will be checked out after update. True => Check in. False => Stays checked out
 * @param httpService HttpService for put and post requests
 * @param trackFeature function for tracking feature usage
 * @param onSuccess callback for successful operation
 */
export async function updateFile(
  newFile: File,
  oldItem: PWDataItem,
  unlockNewFile: boolean,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onSuccess: () => void
): Promise<void> {
  const toastHandle = notifyDocumentUpdate();
  let response: Response | undefined;
  if (!unlockNewFile) {
    response = await replaceFile(
      newFile,
      oldItem,
      httpService,
      trackFeature,
      false,
      undefined,
      undefined,
      onSuccess
    );
  } else {
    response = await checkIn(newFile, oldItem, httpService);
  }

  if (response?.status == 200) {
    notifyDocumentUpdateSuccess(toastHandle);
    onSuccess();
  } else {
    const errorMsg = (await response?.json()) as Record<string, string>;
    notifyErrorDuringUpdate(
      toastHandle,
      errorMsg.errorMessage ?? t('Sync.FailedToUpdateFile')
    );
  }
}
