import { useCallback } from 'react';
import type {
  PWItem,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import { useSearchTimeout } from '../../components/searchTimeoutModal';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext,
  useTelemetry
} from '../../context';
import { configureHttpOptionsWithAbortController } from '../../services/http';
import { t } from '../../services/translation';
import { useAllContentSearch } from './allContentSearch/useAllContentSearch';
import { useColumnSearch } from './columnSearch/useColumnSearch';
import { useFlatSetSearch } from './flatSetSearch/useFlatSetSearch';
import { useFTRSearch } from './ftrSearch/useFTRSearch';

type SearchFunction = (httpOptions?: RequestOptions) => Promise<PWItem[]>;

export function useBasicSearch(): SearchFunction {
  const { trackFeature } = useFeatureTracking();
  const { logError } = usePluginContext();
  const {
    ecPluginFeatures: { allContentSearch: allContentSearchSupported }
  } = useEnvironmentContext();
  const {
    navigationManager: { currentParent },
    searchState
  } = useNavigationContext();

  const scheduleSearchTimeout = useSearchTimeout();
  const flatSetSearch = useFlatSetSearch();
  const allContentSearch = useAllContentSearch();
  const ftrSearch = useFTRSearch();
  const columnSearch = useColumnSearch();

  const { startTelemetry, endTelemetry } = useTelemetry();

  const basicSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWItem[]> => {
      if (!searchState.basicQuery) {
        throw new Error('Missing query');
      }

      trackFeature('SEARCH');
      startTelemetry('Search');

      httpOptions = configureHttpOptionsWithAbortController(httpOptions);
      const cancelSearchTimeout = scheduleSearchTimeout(
        httpOptions.abortController
      );

      try {
        if (itemIsFlatSet(currentParent)) {
          const items = await flatSetSearch(httpOptions);
          endTelemetry('Search', {
            searchType: 'Flat set',
            query: searchState.basicQuery,
            numItems: items.length
          });
          return items;
        }

        if (allContentSearchSupported()) {
          const items = await allContentSearch(httpOptions);
          trackFeature('ALL_CONTENT_SEARCH');
          endTelemetry('Search', {
            searchType: 'All content',
            query: searchState.basicQuery,
            numItems: items.length
          });
          return items;
        }

        if (searchState.ftr) {
          const items = await ftrSearch(httpOptions);
          endTelemetry('Search', {
            searchType: 'FTR',
            query: searchState.basicQuery,
            numItems: items.length
          });
          return items;
        }
        const items = await columnSearch(httpOptions);
        endTelemetry('Search', {
          searchType: 'Column',
          query: searchState.basicQuery,
          numItems: items.length
        });
        return items;
      } catch (error) {
        endTelemetry('Search', {
          searchType: 'Error',
          query: searchState.basicQuery
        });

        if ((error as Error).name == 'AbortError') {
          throw undefined;
        }

        logError('Search failed', {
          error,
          ftr: searchState.ftr,
          query: searchState.basicQuery
        });

        if (error instanceof Response) {
          const response = (await error.clone().json()) as WSGInstancesResponse;
          if (response?.errorMessage?.includes('Search execution has failed')) {
            throw new Error(t('Search.ServerError'));
          }
        }
        throw error;
      } finally {
        cancelSearchTimeout();
      }
    },
    [
      allContentSearch,
      allContentSearchSupported,
      columnSearch,
      currentParent,
      endTelemetry,
      flatSetSearch,
      ftrSearch,
      scheduleSearchTimeout,
      searchState.basicQuery,
      searchState.ftr,
      startTelemetry,
      trackFeature,
      logError
    ]
  );

  return basicSearch;
}
