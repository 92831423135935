import React, { useEffect, useMemo, useState } from 'react';
import type {
  PWParentType,
  WSGInstance,
  WSGRelationshipInstance
} from '@bentley/pw-api';
import { ComboBox, Label, LabeledInput } from '@itwin/itwinui-react';
import type { OpenModal } from '../../hooks/useModal';
import { SelectFolderComponent } from './renditionDestinationComponent';
import type { RenditionProfile } from './renditionProfile';
import type {
  ComponentClassName,
  RenditionDropdownOption,
  RenditionSelection
} from './utils';

function parseComponentValues(
  renditionRelationships: WSGRelationshipInstance[],
  componentClassName: ComponentClassName
): WSGInstance[] {
  return renditionRelationships
    .filter((component) => {
      return component.relatedInstance.className == componentClassName;
    })
    .map((value) => {
      const relatedInstance = value.relatedInstance;
      if (relatedInstance.properties) {
        relatedInstance.properties.IsDefault =
          value?.properties?.IsDefault ?? false;
      }
      return relatedInstance;
    });
}

function buildDropdownOptions(
  componentValues: WSGInstance[],
  componentClassName: ComponentClassName
): RenditionDropdownOption[] {
  return componentValues.map((item) => {
    let displayLabel = '';
    if (item.properties && item.properties.Name) {
      displayLabel = item.properties.Name as string;
    }
    let isDefault = false;
    if (item.properties && item.properties.IsDefault) {
      isDefault = item.properties.IsDefault as boolean;
    }
    if (componentClassName == 'RendSvcDestinationOption') {
      let allowBrowse = false;
      if (item.properties && item.properties.AllowBrowse) {
        allowBrowse = item.properties.AllowBrowse as boolean;
      }
      return {
        label: displayLabel,
        value: item.instanceId,
        isDefault: isDefault,
        allowBrowse: allowBrowse
      };
    } else {
      return {
        label: displayLabel,
        value: item.instanceId,
        isDefault: isDefault
      };
    }
  });
}

function buildComponentOptions(
  selectedRendition: RenditionProfile | undefined,
  componentClassName: ComponentClassName
): RenditionDropdownOption[] {
  if (selectedRendition && selectedRendition.relationshipInstances) {
    const componentValues = parseComponentValues(
      selectedRendition.relationshipInstances,
      componentClassName
    );
    return buildDropdownOptions(componentValues, componentClassName);
  } else {
    return [];
  }
}

export function ProfileComponent(
  selectedRendition: RenditionProfile | undefined,
  updateChoice: (
    componentType: ComponentClassName,
    value: string,
    valueName?: string
  ) => void,
  componentName: string,
  componentClassName: ComponentClassName,
  openModal: OpenModal,
  currentParent: PWParentType,
  currentChoices?: RenditionSelection,
  closePickerFunction?: () => void
): JSX.Element {
  const componentOptions = useMemo(
    () => buildComponentOptions(selectedRendition, componentClassName),
    [componentClassName, selectedRendition]
  );

  useEffect(() => {
    let currentChoice: RenditionDropdownOption | undefined = undefined;

    function getDefaultChoice(
      choiceId?: string
    ): RenditionDropdownOption | undefined {
      if (choiceId) {
        return componentOptions.find((option) => {
          return option.value == choiceId;
        });
      } else {
        if (componentOptions.length == 1) {
          return componentOptions[0];
        } else {
          return componentOptions.find((option) => {
            return option.isDefault;
          });
        }
      }
    }

    switch (componentClassName) {
      case 'RendSvcDestinationOption':
        currentChoice = getDefaultChoice(currentChoices?.destinationId);
        setBrowseComponentEnabled(currentChoice?.allowBrowse as boolean);
        if (currentChoices?.folderName && currentChoices?.folder) {
          updateChoice(
            'RendSvcDestinationFolder',
            currentChoices.folder,
            currentChoices.folderName
          );
        } else {
          updateChoice('RendSvcDestinationFolder', '', '');
        }
        break;
      case 'RendSvcFilenameOption':
        currentChoice = getDefaultChoice(currentChoices?.fileNameId);
        break;
      case 'RendSvcPresentationOption':
        currentChoice = getDefaultChoice(currentChoices?.presentationId);
        break;
      case 'RendSvcRenditionOption':
        currentChoice = getDefaultChoice(currentChoices?.formatId);
        break;
    }
    setSelectedValue(currentChoice);
    if (currentChoice) {
      updateChoice(componentClassName, currentChoice?.value);
    }
  }, [
    componentClassName,
    componentOptions,
    currentChoices,
    selectedRendition,
    updateChoice
  ]);

  const [selectedValue, setSelectedValue] = useState<RenditionDropdownOption>();
  const [browseComponentEnabled, setBrowseComponentEnabled] =
    useState<boolean>(false);

  function onValueChange(value: string) {
    const option = componentOptions.find((option) => option.value == value);
    if (!option) {
      return;
    }
    updateValues(option.label, value, option.allowBrowse);
  }

  function updateValues(label: string, value: string, allowBrowse?: boolean) {
    setSelectedValue({ label, value });
    updateChoice(componentClassName, value);
    setBrowseComponentEnabled(allowBrowse as boolean);
  }

  return (
    <div>
      {componentOptions.length == 0 ? (
        <LabeledInput label={componentName} value={''} disabled={true} />
      ) : (
        <>
          <Label htmlFor="component-dropdown-combo">{componentName}</Label>
          <ComboBox<string>
            data-testid={`${componentName}SelectDropdown`}
            inputProps={{ id: 'component-dropdown-combo' }}
            options={componentOptions}
            value={selectedValue?.value}
            onChange={(e: string) => {
              onValueChange(e);
            }}
          />
        </>
      )}
      {componentClassName == 'RendSvcDestinationOption' &&
        SelectFolderComponent(
          browseComponentEnabled,
          selectedRendition != undefined,
          updateChoice,
          openModal,
          currentParent,
          closePickerFunction,
          currentChoices?.folderName
        )}
    </div>
  );
}
