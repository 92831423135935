import { useCallback } from 'react';
import type { RequestOptions } from '@bentley/pw-api';
import { useEnvironmentContext, useHttpService } from '../../context';
import { t } from '../../services/translation';
import type { EnvironmentProperty } from './environmentProperty';
import { renameFolderPropertyDisplayLabels } from './formatDisplayLabel';
import { getPropertyDefs } from './getPropertyDefs';
import { idColumn, stateColumn, workflowColumn } from './propertyColumns';
import { byDisplayLabel } from './sort';

type PropertyRequests = {
  getDocumentProperties: (
    options?: RequestOptions
  ) => Promise<EnvironmentProperty[]>;
  getFolderProperties: (
    options?: RequestOptions
  ) => Promise<EnvironmentProperty[]>;
};

export function usePropertyRequests(): PropertyRequests {
  const httpService = useHttpService();
  const { ecPluginFeatures, ecPluginVersion } = useEnvironmentContext();

  const getDocumentProperties = useCallback(
    async (options?: RequestOptions): Promise<EnvironmentProperty[]> => {
      const properties = await getPropertyDefs(
        'Document',
        httpService,
        options
      );

      if (
        ecPluginVersion == null ||
        ecPluginFeatures.stateWorkflowProperties()
      ) {
        properties.push(workflowColumn());
        properties.push(stateColumn());
      }
      properties.push(idColumn(t('Columns.ObjectGuid'), 'General'));

      properties.sort(byDisplayLabel);

      return properties;
    },
    [ecPluginVersion, ecPluginFeatures, httpService]
  );

  const getFolderProperties = useCallback(
    async (options?: RequestOptions): Promise<EnvironmentProperty[]> => {
      const properties = await getPropertyDefs('Project', httpService, options);

      renameFolderPropertyDisplayLabels(properties);
      properties.push(idColumn(t('Columns.FolderGuid'), 'Folder'));

      return properties.sort(byDisplayLabel);
    },
    [httpService]
  );

  return {
    getDocumentProperties,
    getFolderProperties
  };
}
