import { useCallback, useMemo } from 'react';
import { useBuddi } from '@bentley/pw-config';
import type { GetToken } from '../../services/http';
import type {
  FederatedRepository,
  IFederatedRepositoryApi,
  LegacyRepository
} from '../../services/support';
import type { ILegacyRepositoryApi } from '../../services/support/legacyRepository';
import {
  connectionsDeletedInFedRepoService,
  getConnectionsNotInFedRepoService,
  getConnectionsNotInPRDSS,
  getLegacyRepositoryApi
} from '../../services/support/legacyRepository';

type LegacyRepoConversionProps = {
  buddiRegionCode: string;
  contextId?: string;
  getSamlToken: GetToken;
};

type LegacyRepoConversion = {
  synchronizeFederatedAndLegacyRepos?: (
    federatedRepos: FederatedRepository[],
    federatedApi: IFederatedRepositoryApi
  ) => Promise<FederatedRepository[]>;
};

export function useLegacyRepoConversion({
  buddiRegionCode,
  contextId,
  getSamlToken
}: LegacyRepoConversionProps): LegacyRepoConversion {
  const legacyServiceUrl = useBuddi('ProjectGateway.URL', buddiRegionCode);

  const legacyApi = useMemo((): ILegacyRepositoryApi | undefined => {
    if (!contextId || !legacyServiceUrl) {
      return undefined;
    }

    const legacyApi = getLegacyRepositoryApi(
      legacyServiceUrl,
      getSamlToken,
      contextId
    );

    return legacyApi;
  }, [contextId, getSamlToken, legacyServiceUrl]);

  const fetchLegacyRepos = useCallback(async (): Promise<
    LegacyRepository[]
  > => {
    if (!legacyApi) {
      throw new Error('Legacy api not available');
    }

    try {
      const legacyRepos = await legacyApi.getAll();
      return legacyRepos;
    } catch (e) {
      console.error('Error fetching legacy repositories', e);
      throw e;
    }
  }, [legacyApi]);

  const syncLegacyReposToFederatedApi = useCallback(
    async (
      legacyRepos: LegacyRepository[] | undefined,
      federatedRepos: FederatedRepository[],
      federatedApi: IFederatedRepositoryApi
    ): Promise<FederatedRepository[]> => {
      if (!legacyRepos?.length) {
        return federatedRepos;
      }

      const missingRepos = getConnectionsNotInPRDSS(
        federatedRepos,
        legacyRepos
      );

      const addedRepos = [] as FederatedRepository[];
      for (const legacyRepo of missingRepos) {
        try {
          const addedRepo = await federatedApi.addOne(legacyRepo, true);
          addedRepos.push(addedRepo);
        } catch (e) {
          console.error(`Error importing legacy repo ${legacyRepo.Name}`, e);
        }
      }

      return [...federatedRepos, ...addedRepos];
    },
    []
  );

  const syncFederatedReposToLegacyApi = useCallback(
    async (
      legacyRepos: LegacyRepository[] | undefined,
      federatedRepos: FederatedRepository[],
      federatedApi: IFederatedRepositoryApi
    ): Promise<void> => {
      if (!federatedRepos.length || !legacyApi || !legacyRepos) {
        return;
      }

      const missingRepos = getConnectionsNotInFedRepoService(
        federatedRepos,
        legacyRepos
      );

      for (const federatedRepo of missingRepos) {
        try {
          const addedRepo = await legacyApi.addOne(federatedRepo);

          const originalFederatedRepo = federatedRepos.find(
            ({ Url }) => addedRepo.Url.toLowerCase() == Url.toLowerCase()
          );

          if (originalFederatedRepo) {
            originalFederatedRepo.LegacyId = addedRepo.instanceId;
            void federatedApi.updateOne(originalFederatedRepo);
          }
        } catch (e) {
          console.error(
            `Error exporting repo ${federatedRepo.Name} to legacy service`,
            e
          );
        }
      }
    },
    [legacyApi]
  );

  const removeDeletedLegacyRepos = useCallback(
    async (
      legacyRepos: LegacyRepository[] | undefined,
      federatedRepos: FederatedRepository[],
      federatedApi: IFederatedRepositoryApi
    ): Promise<FederatedRepository[]> => {
      if (!federatedRepos.length) {
        return [];
      }

      if (!legacyRepos) {
        return federatedRepos;
      }

      const primaryConnection = await federatedApi.getPrimaryConnection();

      const deletedLegacyRepos = connectionsDeletedInFedRepoService(
        federatedRepos,
        legacyRepos
      ).filter(({ Id }) => Id != primaryConnection);

      for (const federatedRepo of deletedLegacyRepos) {
        try {
          await federatedApi.deleteMany([federatedRepo.Id]);
        } catch (e) {
          console.error(
            `Error deleting repo ${federatedRepo.Name} (deleted in legacy service)`,
            e
          );
        }
      }

      return federatedRepos.filter(
        (repo) => !deletedLegacyRepos.includes(repo)
      );
    },
    []
  );

  const synchronizeFederatedAndLegacyRepos = useCallback(
    async (
      federatedRepos: FederatedRepository[],
      federatedApi: IFederatedRepositoryApi
    ): Promise<FederatedRepository[]> => {
      if (!legacyApi) {
        return federatedRepos;
      }

      let legacyRepos: LegacyRepository[] | undefined = undefined;
      try {
        legacyRepos = await fetchLegacyRepos();
      } catch (e) {
        console.error('There was an error syncing the legacy repositories', e);
      }

      let repos = await syncLegacyReposToFederatedApi(
        legacyRepos,
        federatedRepos,
        federatedApi
      );
      await syncFederatedReposToLegacyApi(legacyRepos, repos, federatedApi);
      repos = await removeDeletedLegacyRepos(legacyRepos, repos, federatedApi);

      return repos;
    },
    [
      legacyApi,
      fetchLegacyRepos,
      removeDeletedLegacyRepos,
      syncFederatedReposToLegacyApi,
      syncLegacyReposToFederatedApi
    ]
  );

  const legacyRepoConversionManager = useMemo(
    (): LegacyRepoConversion => ({
      synchronizeFederatedAndLegacyRepos: legacyApi
        ? synchronizeFederatedAndLegacyRepos
        : undefined
    }),
    [legacyApi, synchronizeFederatedAndLegacyRepos]
  );

  return legacyRepoConversionManager;
}
