import React from 'react';
import { SvgCheckmark } from '@itwin/itwinui-icons-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import type { SavedAdvancedQuery } from '../../../components/advancedSearch';
import { useNavigationContext } from '../../../context';

export function AllConnectionsCell({
  row
}: CellProps<SavedAdvancedQuery>): JSX.Element | null {
  const { searchState } = useNavigationContext();

  const search = row.original;

  if (
    searchState.myConnectionSearches?.find(
      (connectionSearch) => connectionSearch.name == search.name
    )
  ) {
    return null;
  }

  return <SvgCheckmark className="all-connections-check" />;
}
