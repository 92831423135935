import type { FilterValue } from '@itwin/itwinui-react/react-table';
import {
  formatBoolean,
  formatDate,
  formatFileSize,
  formatNumber
} from '../../services/stringFormatter';
import { t } from '../../services/translation';
import type { ColumnDataType } from '../pwTable/column/typedColumn';

export function formattedFilterToken(
  columnDataType: ColumnDataType,
  filterValue: FilterValue
): string {
  if (columnDataType == 'boolean') {
    return formatBoolean(filterValue as boolean);
  }

  if (columnDataType == 'number') {
    return numberToken(filterValue);
  }

  if (columnDataType == 'dateTime') {
    return dateToken(filterValue);
  }

  if (columnDataType == 'fileSize') {
    return fileSizeToken(filterValue);
  }

  return stringToken(filterValue);
}

function numberToken(filterValue: FilterValue): string {
  const [firstValue, secondValue] = filterValue as [string, string];

  const first = formatNumber(firstValue);
  const second = formatNumber(secondValue);

  if (notSet(secondValue)) {
    return t('GridFilters.GreaterThan', { value: first });
  }

  if (notSet(firstValue)) {
    return t('GridFilters.LessThan', { value: second });
  }

  if (first == second) {
    return first;
  }

  return `${first}–${second}`;
}

function dateToken(filterValue: FilterValue): string {
  const [firstValue, secondValue] = filterValue as [string, string];

  const first = formatDate(firstValue);
  const second = formatDate(secondValue);

  if (notSet(secondValue)) {
    return t('GridFilters.After', { date: first });
  }

  if (notSet(firstValue)) {
    return t('GridFilters.Before', { date: second });
  }

  if (first == second) {
    return first;
  }

  return `${first}–${second}`;
}

function fileSizeToken(filterValue: FilterValue): string {
  let [firstValue, secondValue] = filterValue as [number?, number?];

  const first = formatFileSize(firstValue as number) || '0 KB';
  const second = formatFileSize(secondValue as number) || '0 KB';

  if (first == second) {
    return first;
  }

  if (firstValue == 0) {
    firstValue = undefined;
  }
  if (secondValue == Number.MAX_SAFE_INTEGER) {
    secondValue = undefined;
  }

  if (notSet(secondValue)) {
    return t('GridFilters.GreaterThan', { value: first });
  }

  if (notSet(firstValue)) {
    return t('GridFilters.LessThan', { value: second });
  }

  return `${first}–${second}`;
}

function stringToken(filterValue: FilterValue): string {
  const stringValue = String(filterValue);

  if (!stringValue.trim().length) {
    return `"${stringValue}"`;
  }

  return stringValue;
}

function notSet(value?: string | number): boolean {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  return false;
}
