import React from 'react';

export function SvgChevronDownBottom(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path d="M16 4.7l-1.4-1.4L8 9.9 1.4 3.3 0 4.7l8 8z" />
      <rect x="0" y="14" width="16" height="2" />
    </svg>
  );
}
