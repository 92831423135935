import type { PWDataItem, PWItem } from '@bentley/pw-api';
import {
  filterFileTypes,
  itemIsDataItem,
  itemIsDocument,
  itemIsFileType,
  itemIsFlatSet,
  itemIsLatestVersion,
  itemIsLockedOrCheckedOut,
  itemIsLogicalSet,
  itemIsParentType,
  itemIsPlaceholder,
  itemIsProject,
  itemIsReadOnly
} from '@bentley/pw-api';
import type { MenuItemAction } from '../../components/toolbar/MenuItemAction';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';
import type { PWDriveManager } from '../drive';
import { getItemDriveLock } from '../drive';
import { isValidDriveData } from '../drive/pwDrive.utils';
import type { IModelBridge } from '../imodelMapping';
import { allowReplace } from '../replace';

export function canCheckInLockedFile(items: PWItem[]): boolean {
  if (!items || items.length != 1) {
    return false;
  }

  const item = items[0] as PWDataItem;
  return item.IsCheckedOut;
}

/**
 * Returns whether Check Out should be hidden
 * @param {PWItem[]} items Items to be checked out
 * @param {pwDriveSyncEnabled} checks if project sync is available or not
 * @returns {boolean} True if Check Out should be hidden, false if shown
 */
export function hideCheckOutButton(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean
): boolean {
  return (
    items.some(itemIsProject) ||
    !allowCheckOutAll(
      items,
      pwDriveSyncEnabled,
      masterReferenceFileOperationsEnabled
    )
  );
}

/**
 * Returns whether items are able to be checked out
 * @param {PWItem[]} items Items to be checked out
 * @param {pwDriveSyncEnabled} checks if project sync is available or not
 * @param {boolean} masterReferenceFileOperationsEnabled ecPlugin version check to allow checkout of master reference files
 * @param {isMulitipleItemExistsInConnection} checks if item exists in currect connection
 * @returns {boolean} True if all items may be checked out
 */
export function allowCheckOutAll(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  if (
    !items.every((item) =>
      allowCheckOut(
        item,
        pwDriveSyncEnabled,
        masterReferenceFileOperationsEnabled
      )
    )
  ) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

function itemIsLockedViaAutoCheckout(item: PWItem): boolean {
  if (!itemIsDataItem(item)) {
    return false;
  }

  if (itemIsPlaceholder(item)) {
    return false;
  }

  return getItemDriveLock(item)?.lockedViaAutoCheckout ?? false;
}

/**
 * Returns whether item is able to be checked out
 * @param {PWItem} item The item to be checked out
 * @param {pwDriveSyncEnabled} checks if project sync is available or not
 * @param {boolean} masterReferenceFileOperationsEnabled ecPlugin version check to allow checkout of master reference files
 * @returns {boolean} True if item may be checked out
 */
export function allowCheckOut(
  item: PWItem,
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean
): boolean {
  if (itemIsParentType(item)) {
    return false;
  }

  if (!itemIsLockedViaAutoCheckout(item)) {
    if (itemIsLockedOrCheckedOut(item)) {
      return false;
    }

    if (itemIsReadOnly(item)) {
      return false;
    }
  }

  if (
    !masterReferenceFileOperationsEnabled &&
    itemIsLogicalSet(item) &&
    !pwDriveSyncEnabled
  ) {
    return false;
  }

  return true;
}

/**
 * Returns whether Check In should be shown
 * @param {PWItem[]} items Items to be checked in
 * @param {pwDriveSyncEnabled} checks if project sync is available or not
 * @returns {boolean} True if Check In should be shown, false if hidden
 */
export function showCheckInButton(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  readOnly: boolean,
  showCoauthoringUpdates: boolean,
  coAuthoredItemsExist: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean | undefined
): boolean {
  if (!items.length) {
    return false;
  }

  if (readOnly) {
    return false;
  }

  if (items.some(itemIsProject)) {
    return false;
  }

  if (
    !allowCheckAction(
      items,
      pwDriveSyncEnabled,
      masterReferenceFileOperationsEnabled
    )
  ) {
    return false;
  }

  if (showCoauthoringUpdates) {
    if (
      !allowCheckInAll(
        items,
        pwDriveSyncEnabled,
        masterReferenceFileOperationsEnabled,
        coAuthoredItemsExist,
        allItemsExistInConnection
      )
    ) {
      return false;
    }
  }

  return true;
}

export function showEndCoAuthoringButton(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  readOnly: boolean
): boolean {
  if (!items.length) {
    return false;
  }

  if (readOnly) {
    return false;
  }

  if (items.some(itemIsProject)) {
    return false;
  }

  if (
    !allowCheckAction(
      items,
      pwDriveSyncEnabled,
      masterReferenceFileOperationsEnabled
    )
  ) {
    return false;
  }

  return true;
}

export function allowCheckInAll(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  coAuthoredItemsExist: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean | undefined
): boolean {
  if (coAuthoredItemsExist) {
    return false;
  }

  if (items.length !== 1 && !pwDriveSyncEnabled) {
    return false;
  }

  if (
    items.length !== 1 &&
    !items.every((item) => getItemDriveLock(item)?.locked === false)
  ) {
    return false;
  }

  if (
    !items.every((item) =>
      allowCheckIn(
        item,
        pwDriveSyncEnabled,
        masterReferenceFileOperationsEnabled
      )
    )
  ) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

function allowCheckAction(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean | undefined
): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  if (
    !items.every((item) =>
      allowCheckIn(
        item,
        pwDriveSyncEnabled,
        masterReferenceFileOperationsEnabled
      )
    )
  ) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

/**
 * Returns whether an item is able to be checked in
 * @param {PWItem} item The item to be checked in
 * @param {pwDriveSyncEnabled} checks if project sync is available or not
 * @returns {boolean} True if item may be checked in
 */
export function allowCheckIn(
  item: PWItem,
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean
): boolean {
  if (itemIsProject(item) || itemIsFlatSet(item)) {
    return false;
  }

  if (
    !masterReferenceFileOperationsEnabled &&
    itemIsLogicalSet(item) &&
    !pwDriveSyncEnabled
  ) {
    return false;
  }

  return (item as PWDataItem).IsCheckedOut;
}

export function hideUpdateButton(
  items: PWItem[],
  readOnly: boolean,
  iModelBridges: IModelBridge[]
): boolean {
  return (
    readOnly ||
    allowReplace(items, iModelBridges) ||
    !canCheckInLockedFile(items) ||
    items.some(itemIsParentType)
  );
}

export function allowUpdateAll(
  items: PWItem[],
  driveSyncEnabled: boolean,
  isCurrentConnectionSynched: boolean,
  iModelBridges: IModelBridge[],
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || items.length !== 1) {
    return false;
  }

  if (driveSyncEnabled && isCurrentConnectionSynched) {
    return false;
  }
  const item = items[0];

  if (!allowUpdate(item, iModelBridges)) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

function allowUpdate(item: PWItem, iModelBridges: IModelBridge[]): boolean {
  return (
    itemIsPlaceholder(item) ||
    (!allowReplace([item], iModelBridges) && canCheckInLockedFile([item]))
  );
}

/**
 * Returns whether Free File should be hidden
 * @param {PWItem[]} items Items to be freed
 * @returns {boolean} True if Free File should be hidden, false if shown
 */
export function hideFreeFileButton(
  items: PWItem[],
  readOnly: boolean
): boolean {
  return (
    readOnly ||
    items.length == 0 ||
    !items.every(
      (i) => itemIsLockedOrCheckedOut(i) || itemIsPlaceholderAndIsCheckedOut(i)
    )
  );
}

function itemIsPlaceholderAndIsCheckedOut(item: PWItem): boolean {
  return itemIsPlaceholder(item) && (item as PWDataItem).IsCheckedOut;
}

function allowFreeFile(
  item: PWItem,
  syncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  pwUserId: string,
  isAdmin: boolean
): boolean {
  if (!itemIsFileType(item)) {
    return false;
  }

  if (
    !masterReferenceFileOperationsEnabled &&
    itemIsLogicalSet(item) &&
    !syncEnabled
  ) {
    return false;
  }

  if (itemIsPlaceholderAndIsCheckedOut(item)) {
    return true;
  }

  if (!itemIsLockedOrCheckedOut(item)) {
    return false;
  }

  if (isAdmin) {
    return true;
  }

  if (!isCheckedOutToSameUser(item, pwUserId)) {
    return false;
  }

  return true;
}

export function isCheckedOutToSameUser(
  item: PWDataItem,
  pwUserId: string
): boolean {
  if (!ecPluginFeatures.outToProperty()) {
    return item.IsLocked && item.IsCheckedOut;
  }

  if (!item.OutToId) {
    return false;
  }

  return String(item.OutToId) == String(pwUserId);
}

/**
 * Returns whether an item can be oprn in windows Explorer/ Windows App or not
 * @param {PWItem} item The item to be opend in Windows explorer
 * @returns {boolean} True if item may be opend in Windows explorer
 */
export function allowItemOpenInWindowsExplorerOrApp(
  items: PWItem[],
  driveDisabled: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (driveDisabled) {
    return false;
  }

  if (items.length != 1) {
    return false;
  }

  const item = items[0];

  if (!itemIsDataItem(item)) {
    return false;
  }

  if (!isItemOpenInWindowsExplorer(item)) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

export function isItemOpenInWindowsExplorer(item: PWItem): boolean {
  if (!itemIsProject(item)) {
    if (itemIsPlaceholder(item) || !itemIsLatestVersion(item)) {
      return false;
    }
  }

  return true;
}

export function allowCheckForDriveIntegratedMode(
  driveData: PWDriveManager,
  items: PWDataItem[],
  isCheckOut = false
): boolean {
  return (
    isValidDriveData(driveData) &&
    !items.every((item) => itemIsPlaceholder(item)) &&
    (items.every((item) => getItemDriveLock(item)?.locked === false) ||
      isCheckOut)
  );
}

export function allowFreeAll(
  items: PWItem[],
  pwDriveSyncEnabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  pwUserId: string,
  isAdmin: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  if (
    !items.every((item) =>
      allowFreeFile(
        item,
        pwDriveSyncEnabled,
        masterReferenceFileOperationsEnabled,
        pwUserId,
        isAdmin
      )
    )
  ) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

export function isCheckActionsDisabled(
  checkInOperationItems: MenuItemAction[]
): boolean | undefined {
  return checkInOperationItems.every((item) => item.disabled);
}

export function isCheckOutHidden(
  readOnly: boolean,
  checkedRows: PWItem[],
  pwDriveSyncDisabled: boolean,
  masterReferenceFileOperationsEnabled: boolean
): boolean {
  if (readOnly || !checkedRows.length) {
    return true;
  }

  if (checkedRows.some((item) => itemIsParentType(item))) {
    return true;
  }

  if (
    filterFileTypes(checkedRows).some(
      (item) => item.IsLocked && !getItemDriveLock(item)?.lockedViaAutoCheckout
    )
  ) {
    return true;
  }

  if (
    hideCheckOutButton(
      checkedRows,
      !pwDriveSyncDisabled,
      masterReferenceFileOperationsEnabled
    )
  ) {
    return true;
  }

  return false;
}

export function isCheckOutDisabled(
  checkedRows: PWItem[],
  pwDriveSyncDisabled: boolean,
  masterReferenceFileOperationsEnabled: boolean,
  allItemsExistInConnection: (item: PWItem[]) => boolean
): boolean {
  return !allowCheckOutAll(
    checkedRows,
    !pwDriveSyncDisabled,
    masterReferenceFileOperationsEnabled,
    allItemsExistInConnection
  );
}

export function showFileOpenMenu(checkedRows: PWItem[]): boolean {
  if (checkedRows.length < 1) {
    return false;
  }

  if (
    checkedRows.some((item) => {
      return !itemIsDocument(item);
    })
  ) {
    return false;
  }

  return true;
}
