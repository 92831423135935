import { dataTypes } from '../search/query';

export function defaultValue(propertyType: string): string {
  if (propertyType == dataTypes.dateTime) {
    const date = new Date().setHours(0, 0, 0, 0);
    return new Date(date).toISOString();
  }

  if (propertyType == dataTypes.boolean) {
    return 'True';
  }

  return '';
}
