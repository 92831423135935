import type { HttpService, PWDataItem, RequestOptions } from '@bentley/pw-api';
import { parseResponseRelationshipInstances } from '@bentley/pw-api';

export async function itemPartOfSet(
  item: PWDataItem,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<boolean> {
  const url = `PW_WSG/Document/${item.instanceId}?$select=*,SetDocument-backward-Set!poly.$id`;
  const response = await httpService.get(url, httpOptions);

  const relatedSets = await parseResponseRelationshipInstances(response);

  if (relatedSets.some(({ className }) => className == 'FlatSet')) {
    return true;
  }

  return false;
}
