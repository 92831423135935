import React, { useMemo } from 'react';
import { itemIsFlatSet } from '@bentley/pw-api';
import { SvgAdd } from '@itwin/itwinui-icons-react';
import { DropdownButton } from '@itwin/itwinui-react';
import { useAppViewContext, useNavigationContext } from '../../../context';
import { t } from '../../../services/translation';
import { menuItemActionToMenuItem } from '../pwToolbar/menuItemActionToMenuItem';
import { useNewActions } from './useNewActions';

export function NewDropdownButton(): JSX.Element | null {
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();
  const { workAreaManager } = useAppViewContext();

  const dropdownActions = useNewActions();

  const buttonTitle = useMemo((): string => {
    if (itemIsFlatSet(currentParent)) {
      return t('FlatSet.Add');
    }
    return t('NewButton.New');
  }, [currentParent]);

  const newDropdownButton = useMemo(
    (): JSX.Element => (
      <DropdownButton
        className="new-button"
        disabled={!workAreaManager.workAreaAvailable}
        styleType={'high-visibility'}
        menuItems={(close: () => void) =>
          dropdownActions.map((action) =>
            menuItemActionToMenuItem(action, close)
          )
        }
        startIcon={<SvgAdd />}
        data-testid="NewButton"
      >
        {buttonTitle}
      </DropdownButton>
    ),
    [buttonTitle, dropdownActions, workAreaManager.workAreaAvailable]
  );

  if (!dropdownActions.length) {
    return null;
  }

  return newDropdownButton;
}
