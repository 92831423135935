import type { PWItem } from '@bentley/pw-api';
import type { Table } from "@itwin/itwinui-react";
import type { Row } from '@itwin/itwinui-react/react-table';
import { useCallback } from 'react';
import { useTableItemContext } from '../../../context';

type TableProps<T extends Record<string, unknown>> = Omit<React.ComponentPropsWithoutRef<typeof Table<T>>, "as">;
type TableRowProps<T extends Record<string, unknown>> = TableProps<T>['rowProps'];

export function useRowActions(): TableRowProps<PWItem> {
  const {
    tableItemAction: { itemHasClickAction, onItemClick }
  } = useTableItemContext();

  const onContextMenu = useCallback(
    (e: React.MouseEvent, row: Row<PWItem>): void => {
      e.preventDefault();

      const contextMenu = document.querySelector(`*[data-btn-id='${row.id}']`);

      contextMenu?.scrollIntoView({block: 'nearest'});
      contextMenu?.dispatchEvent(
        new MouseEvent('click', { bubbles: true, ctrlKey: e.ctrlKey })
      );
    },
    []
  );

  const onDoubleClick = useCallback(
    (e: React.MouseEvent, row: Row<PWItem>): void => {
      const item = row.original;

      if (!itemHasClickAction(item)) {
        return;
      }

      onItemClick(item);
    },
    [itemHasClickAction, onItemClick]
  );

  const rowProps = useCallback(
    (row: Row<PWItem>) => ({
      onContextMenu: (e: React.MouseEvent) => onContextMenu(e, row),
      onDoubleClick: (e: React.MouseEvent) => onDoubleClick(e, row),
      ['data-rowid']: row.original.instanceId
    }),
    [onContextMenu, onDoubleClick]
  );

  return rowProps;
}
