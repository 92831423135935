import { useCallback, useMemo, useState } from 'react';
import type { PWParentType, PWProject, RequestOptions } from '@bentley/pw-api';
import { useHttpService, usePluginContext } from '../../context';
import { useEquivalentState } from '../useNonRenderingState/useEquivalentState';
import { retrieveBreadcrumbs } from './retrieveBreadcrumbs';
import { useConnectionBreadcrumb } from './useConnectionBreadcrumb';

export type BreadcrumbManager = {
  addBreadcrumb: (item: PWParentType) => void;
  breadcrumbs: PWParentType[];
  connectionBreadcrumb: PWProject;
  currentBreadcrumb: PWParentType;
  descendant: PWParentType | undefined;
  jumpTo: (instanceId: string) => () => void;
  loading: boolean;
  refreshBreadcrumbs: () => void;
  replaceBreadcrumbs: (breadcrumbs: PWParentType[]) => void;
  setBreadcrumbsToItem: (
    item: PWParentType,
    httpOptions?: RequestOptions
  ) => Promise<void>;
  updateBreadcrumb: (updatedItem: PWParentType) => void;
};

export function useBreadcrumbs(
  initialBreadcrumbs?: PWParentType[]
): BreadcrumbManager {
  const { connection } = usePluginContext();
  const httpService = useHttpService();

  const connectionBreadcrumb = useConnectionBreadcrumb();

  const [breadcrumbs, setBreadcrumbs] = useEquivalentState<PWParentType[]>(
    initialBreadcrumbs ?? [connectionBreadcrumb]
  );
  const [descendant, setDescendant] = useState<PWParentType>();
  const [loading, setLoading] = useState<boolean>(false);

  function jumpTo(instanceId: string): () => void {
    return () => {
      let index = breadcrumbs.findIndex((bc) => bc.instanceId == instanceId);
      if (index == -1) {
        index = 0;
      }

      setDescendant(breadcrumbs[index + 1]);

      const updatedBreadcrumbs = [...breadcrumbs.slice(0, index + 1)];
      setBreadcrumbs(updatedBreadcrumbs);
    };
  }

  function addBreadcrumb(breadcrumb: PWParentType): void {
    setBreadcrumbs([...breadcrumbs, breadcrumb]);
  }

  function updateBreadcrumb(updatedItem: PWParentType): void {
    const breadcrumb = breadcrumbs.findIndex(
      (breadcrumb) => breadcrumb.instanceId == updatedItem.instanceId
    );
    const updatedBreadcrumbs = [...breadcrumbs];
    if (breadcrumb > -1) {
      updatedBreadcrumbs.splice(breadcrumb, 1, updatedItem);
    }

    setBreadcrumbs(updatedBreadcrumbs);
  }

  const replaceBreadcrumbs = useCallback(
    (breadcrumbs: PWParentType[]): void => {
      setBreadcrumbs(breadcrumbs);
    },
    [setBreadcrumbs]
  );

  const currentBreadcrumb = useMemo(
    (): PWParentType => breadcrumbs[breadcrumbs.length - 1],
    [breadcrumbs]
  );

  const setBreadcrumbsToItem = useCallback(
    async (item: PWParentType, httpOptions?: RequestOptions): Promise<void> => {
      try {
        setLoading(true);

        const newBreadcrumbs = await retrieveBreadcrumbs(
          item,
          connection,
          connectionBreadcrumb,
          httpService,
          httpOptions
        );

        if (httpOptions?.abortController?.signal.aborted) {
          return;
        }

        replaceBreadcrumbs(newBreadcrumbs);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [connection, connectionBreadcrumb, httpService, replaceBreadcrumbs]
  );

  const refreshBreadcrumbs = useCallback((): void => {
    const parent = { ...currentBreadcrumb, Name: '' };
    void setBreadcrumbsToItem(parent, { uncached: true });
  }, [currentBreadcrumb, setBreadcrumbsToItem]);

  const breadcrumbManager = useMemo(
    (): BreadcrumbManager => ({
      addBreadcrumb,
      breadcrumbs,
      connectionBreadcrumb,
      currentBreadcrumb,
      descendant,
      jumpTo,
      loading,
      updateBreadcrumb,
      refreshBreadcrumbs,
      replaceBreadcrumbs,
      setBreadcrumbsToItem
    }),
    [
      breadcrumbs,
      connectionBreadcrumb,
      currentBreadcrumb,
      descendant,
      loading,
      refreshBreadcrumbs,
      replaceBreadcrumbs,
      setBreadcrumbsToItem
    ]
  );

  return breadcrumbManager;
}
