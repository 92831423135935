import React, { useEffect, useState } from 'react';
import { Button, Dialog, ProgressLinear, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import type { CloseModal, ModalElement, OpenModal } from '../../hooks/useModal';
import { t } from '../../services/translation';

type O365CoAuthoringModalContentProps = {
  closeModal: CloseModal;
  onPrimaryClick?: () => void;
  message?: JSX.Element | string;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  showProgressBar?: boolean;
  autoClose?: boolean;
  openOption?: 'desktop' | 'web';
};

function O365CoAuthoringModalContent({
  closeModal,
  onPrimaryClick,
  message,
  primaryButtonTitle,
  secondaryButtonTitle,
  showProgressBar,
  autoClose,
  openOption
}: O365CoAuthoringModalContentProps): ModalElement {
  const initTimer = openOption == 'desktop' ? 25 : 15;
  const [timer, setTimer] = useState<number>(initTimer);
  const closeDefaultTitle = autoClose
    ? t('OfficeIntegration.AutoCloseIn', { time: timer })
    : t('Generic.Close');

  useEffect(() => {
    if (autoClose) {
      const decrementTimer = setInterval(() => {
        setTimer((timer) => {
          if (timer <= 0) {
            closeModal();
            return 0;
          }

          return timer - 1;
        });
      }, 1000);
      return () => {
        clearInterval(decrementTimer);
      };
    }
    return;
  }, [autoClose, closeModal, timer]);

  return (
    <Dialog.Content>
      {typeof message == 'string' ? <Text>{message}</Text> : message }
      {showProgressBar && <ProgressLinear indeterminate={true} style={{ paddingTop: '5px' }}/>}
      <Dialog.ButtonBar>
        {primaryButtonTitle && (
          <Button
            styleType="high-visibility"
            autoFocus={true}
            onClick={() => {
              onPrimaryClick?.();
              closeModal();
            }}
          >
            {primaryButtonTitle}
          </Button>
        )}
        <Button onClick={closeModal}>
          {secondaryButtonTitle ?? closeDefaultTitle}
        </Button>
      </Dialog.ButtonBar>
    </Dialog.Content>
  );
}

type O365CoauthoringModalProps = {
  closeModal: CloseModal;
  onPrimaryClick?: () => void;
  message?: JSX.Element | string;
  title?: string;
  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
  showProgressBar?: boolean;
  autoClose?: boolean;
  openOption?: 'desktop' | 'web';
};

function O365CoauthoringModal({
  closeModal,
  onPrimaryClick,
  message,
  title,
  primaryButtonTitle,
  secondaryButtonTitle,
  showProgressBar,
  autoClose,
  openOption
}: O365CoauthoringModalProps): ModalElement {
  return (
    <PWModal title={title ?? ''} onClose={closeModal}>
      <O365CoAuthoringModalContent
        closeModal={closeModal}
        onPrimaryClick={onPrimaryClick}
        message={message}
        primaryButtonTitle={primaryButtonTitle}
        secondaryButtonTitle={secondaryButtonTitle}
        showProgressBar={showProgressBar}
        autoClose={autoClose}
        openOption={openOption}
      />
    </PWModal>
  );
}

export function showCoAuthoringModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  onPrimaryClick?: () => void,
  message?: JSX.Element | string,
  title?: string,
  primaryButtonTitle?: string,
  secondaryButtonTitle?: string,
  showProgressBar?: boolean,
  autoClose?: boolean,
  openOption?: 'desktop' | 'web',
  showCustomModal?: (content: JSX.Element) => void
): void {
  showCustomModal
    ? showCustomModal(
        <O365CoAuthoringModalContent
          closeModal={closeModal}
          onPrimaryClick={onPrimaryClick}
          message={message}
          primaryButtonTitle={primaryButtonTitle}
          secondaryButtonTitle={secondaryButtonTitle}
          showProgressBar={showProgressBar}
          autoClose={autoClose}
          openOption={openOption}
        />
      )
    : openModal(
        <O365CoauthoringModal
          closeModal={closeModal}
          onPrimaryClick={onPrimaryClick}
          message={message}
          title={title}
          primaryButtonTitle={primaryButtonTitle}
          secondaryButtonTitle={secondaryButtonTitle}
          showProgressBar={showProgressBar}
          autoClose={autoClose}
          openOption={openOption}
        />
      );
}

export function showPreparingForCoAuthoringModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  fileName?: string,
  showCustomModal?: (content: JSX.Element) => void
): void {
  showCoAuthoringModal(
    openModal,
    closeModal,
    undefined,
    t('OfficeIntegration.PreparingForCo-authoring', {
      name: fileName
    }),
    t('OfficeIntegration.Co-authoringTitle'),
    undefined,
    t('Generic.Hide'),
    true,
    false,
    undefined,
    showCustomModal
  );
}

export function showEndingCoAuthoringSessionModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  fileName?: string,
  showCustomModal?: (content: JSX.Element) => void
): void {
  const modalText = fileName
    ? t('OfficeIntegration.EndCoAuthoringSession', {
        name: fileName
      })
    : t('OfficeIntegration.EndingMultipleCoAuthoringSessions');
  showCoAuthoringModal(
    openModal,
    closeModal,
    undefined,
    modalText,
    t('OfficeIntegration.EndingCoAuthoringSession'),
    undefined,
    t('Generic.Hide'),
    true,
    false,
    undefined,
    showCustomModal
  );
}

export function showUpdatingServerCopyModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  fileName?: string,
  numberOfFiles?: number,
  showCustomModal?: (content: JSX.Element) => void
): void {
  const modalText =
    numberOfFiles === 1
      ? t('OfficeIntegration.UpdateServerCopy', {
          name: fileName
        })
      : t('OfficeIntegration.UpdatingMultipleServerCopies', {
          name: fileName,
          numberOfItems: numberOfFiles ? numberOfFiles - 1 : undefined
        });
  showCoAuthoringModal(
    openModal,
    closeModal,
    undefined,
    modalText,
    t('OfficeIntegration.UpdatingServerCopy'),
    undefined,
    t('Generic.Hide'),
    true,
    false,
    undefined,
    showCustomModal
  );
}
