import type { User } from 'oidc-client';
import type {
  HttpService,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { parseMultiResponseRelationshipInstance } from '@bentley/pw-api';
import type {
  CurrentUserDocumentAccessRights,
  CurrentUserFolderAccessRights
} from '.';

// admin access ims roles
const ROLE_ACCOUNT_ADMIN = 'Account Admin';
const ROLE_PROJECT_MANAGER = 'Project Manager';
const ROLE_CO_ADMIN = 'SITE_ADMINISTRATOR';

/**
 * User in org level needs admin level access to do any Create/Update/Delete on prod settings service
 * @param user Oidc user
 */
export function isAdmin(user: User): boolean {
  if (!user) {
    return false;
  }

  // Allow if user is not associated to any org
  if (!user.profile.org) {
    return true;
  }

  if (!user?.profile?.role) {
    return false;
  }

  const roles: Array<string> = ([] as string[]).concat(
    user.profile.role as string
  );
  return roles.some(
    (role) =>
      role === ROLE_ACCOUNT_ADMIN ||
      role === ROLE_CO_ADMIN ||
      role === ROLE_PROJECT_MANAGER
  );
}

/**
 * Checks if user has permissions to Write to a folder
 * @param {string} folderId Unique id of folder to check
 * @param {HttpService} httpService Configured http service
 * @returns {boolean} Whether or not a user has Write permissions
 */
export async function getUserFolderPermissions(
  folderId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<CurrentUserFolderAccessRights> {
  const url = `PW_WSG/Project/${folderId}?$select=CurrentUserAccessRights-forward-ProjectAccessRights!poly.*`;
  const response = await httpService.get(url, httpOptions);
  const emptyUserFolderAccessRights = {} as CurrentUserFolderAccessRights;

  if (!response.ok) {
    return emptyUserFolderAccessRights;
  }

  const data = (await response.json()) as WSGInstancesResponse;
  const relatedInstances = data.instances[0].relationshipInstances?.map(
    (ri) => ri.relatedInstance
  );

  if (!relatedInstances?.length) {
    return emptyUserFolderAccessRights;
  }
  const properties = relatedInstances[0].properties;
  if (!properties) {
    return emptyUserFolderAccessRights;
  }

  return properties as CurrentUserFolderAccessRights;
}

export async function getUserDocumentsPermissions(
  documentIds: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<CurrentUserDocumentAccessRights[]> {
  const url = `PW_WSG/Document!poly/$query`;
  const joinedDocumentIds = documentIds.join("','");
  const body = `$filter=$id+in+['${joinedDocumentIds}']&$select=CurrentUserAccessRights-forward-DocumentAccessRights!poly.*`;

  try {
    const response = await httpService.post(url, body, httpOptions);
    const accessRights = await parseMultiResponseRelationshipInstance(response);
    return accessRights as CurrentUserDocumentAccessRights[];
  } catch {
    return [];
  }
}
