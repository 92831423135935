import React from 'react';
import { SvgMoreVerticalSmall } from '@itwin/itwinui-icons-react';
import {
  useEnvironmentContext,
  useFolderExplorerContext
} from '../../../context';
import type { SavedSearchDefinition } from '../../../services/search';
import { ContextMenu } from '../../contextMenu';
import { useLinkItemActions } from '../../toolbar/itemActionsToolbar/buttons';

type SavedSearchNodeContextMenuProps = {
  search: SavedSearchDefinition;
};

export function SavedSearchNodeContextMenu({
  search
}: SavedSearchNodeContextMenuProps): JSX.Element | null {
  const { selectedIds, setSelectedIds } = useFolderExplorerContext();
  const { environmentContextInitialized } = useEnvironmentContext();

  const getSearchOption = useLinkItemActions([], search);
  const menuOptions = [getSearchOption('contextMenu')];

  const activeMenu = selectedIds.includes(search.instanceId);

  function onContextMenuClose(): void {
    setSelectedIds([]);
  }

  if (!environmentContextInitialized) {
    return null;
  }

  return (
    <div
      className={
        activeMenu
          ? 'tree-context-menu-active tree-context-menu'
          : 'tree-context-menu'
      }
      data-testid={`tree-context-menu-${search.instanceId}`}
    >
      <ContextMenu
        icon={<SvgMoreVerticalSmall />}
        itemId={search.instanceId}
        menuOptions={menuOptions}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onClose={onContextMenuClose}
        buttonProps={{ size: 'small' }}
      />
    </div>
  );
}
