import React from 'react';
import {
  AuthorizationService,
  HttpService,
  getProject,
  modifyItem
} from '@bentley/pw-api';
import { Text } from '@itwin/itwinui-react';
import type {
  CloseModal,
  ModalElement,
  OpenModal
} from '../../../hooks/useModal';
import type { TrackFeature } from '../../../hooks/useTrackFeature';
import type { GetToken } from '../../../services/http';
import { openToast } from '../../../services/pwToast';
import type { Connection } from '../../../services/support';
import { t } from '../../../services/translation';
import { PWModal } from '../../pwModal';
import { ConnectionAssociationConfirmationModal } from './connectionAssociationConfirmationModal';
import { getConnectionStatus } from './connectionStatus';

export function handleManualAssociation(
  connection: Connection,
  contextId: string,
  getSamlToken: GetToken,
  openModal: OpenModal,
  closeModal: CloseModal,
  onComplete: () => void,
  trackFeature: TrackFeature
): void {
  async function onYes(): Promise<void> {
    closeModal();
    const httpService = new HttpService({
      authorization: new AuthorizationService({ getSamlToken }),
      baseUrl: connection.ConnectionUrl
    });
    const response = await updateAssociation(
      connection,
      contextId,
      httpService
    );
    if (response.ok) {
      const content = t('ProjectAssociation.Succeeded');
      openToast({ content, category: 'positive' });
      trackFeature('UPDATE_PROJECT_ASSOCIATION_SUCCESSFUL');
    } else {
      const content = t('ProjectAssociation.Failed');
      openToast({ content, category: 'negative' });
      trackFeature('UPDATE_PROJECT_ASSOCIATION_FAIL');
    }
    onComplete();
  }

  openModal(
    <ConnectionAssociationConfirmationModal
      onYes={() => void onYes()}
      closeModal={closeModal}
    />
  );
}

export async function handleAutoUpdateAssociation(
  connection: Connection,
  contextId: string,
  getSamlToken: GetToken,
  openModal: OpenModal,
  closeModal: CloseModal
): Promise<void> {
  if (connection.Canned) {
    return;
  }

  const httpService = new HttpService({
    authorization: new AuthorizationService({ getSamlToken }),
    baseUrl: connection.ConnectionUrl
  });
  const connectionStatus = await getConnectionStatus(
    'WorkArea',
    connection,
    httpService,
    true,
    contextId
  );
  if (
    connectionStatus.associationStatus == 'IncorrectAssociation' ||
    connectionStatus.associationStatus == 'NoAssociation'
  ) {
    const response = await updateAssociation(
      connection,
      contextId,
      httpService
    );
    if (!response.ok) {
      let errorMessage = t('PrimaryConnections.CouldNotUpdate');
      if (response.status == 403) {
        errorMessage = t('PrimaryConnections.InsufficientRights');
      }
      openModal(
        <AssociationErrorModal
          closeModal={closeModal}
          errorMessage={errorMessage}
        />
      );
    }
  } else if (connectionStatus.associationStatus == 'InvalidECPlugin') {
    openModal(
      <AssociationErrorModal
        closeModal={closeModal}
        errorMessage={t('PrimaryConnections.ServerOutdated')}
      />
    );
  }
}

type AssociationErrorModalProps = {
  closeModal: CloseModal;
  errorMessage: string;
};
function AssociationErrorModal({
  closeModal,
  errorMessage
}: AssociationErrorModalProps): ModalElement {
  return (
    <PWModal
      title={t('PrimaryConnections.ProjectAssociationFailed')}
      secondaryButton={{ title: t('Generic.Ok'), onClick: closeModal }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'ConnectionAssociationConfirmationModal' }}
    >
      <Text>{errorMessage}</Text>
    </PWModal>
  );
}

async function updateAssociation(
  connection: Connection,
  contextId: string,
  httpService: HttpService
): Promise<Response> {
  if (!connection.ConnectionUrl) {
    return Promise.reject();
  }

  const project = await getProject({
    instanceId: connection.ProjectId,
    selectProperties: ['instanceId'],
    httpService
  });

  const response = await modifyItem({
    item: project,
    httpService,
    properties: { ConnectedProjectGuid: contextId, IsRichProject: true }
  });

  return response;
}
