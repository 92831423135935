import './signIn.css';

import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  LabeledInput,
  Text
} from '@itwin/itwinui-react';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';

interface ISignInProps {
  connectionName: string;
  initWIms?: boolean;
  onSubmit: (useIms: boolean, userName: string, password: string) => void;
}

export function SignIn({
  connectionName,
  initWIms = true,
  onSubmit
}: ISignInProps): JSX.Element {
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [imsChecked, setImsChecked] = useState<boolean>(initWIms);
  function handleIMSChecked() {
    setImsChecked((cur) => !cur);
  }

  function handleSubmit(): void {
    if (formDisabled()) {
      return;
    }

    onSubmit(imsChecked, userName, password);
  }

  function formDisabled(): boolean {
    if (imsChecked) {
      return false;
    }

    if (userName.length && password.length) {
      return false;
    }

    return true;
  }

  function signInButtonDisabled() {
    return !(imsChecked || (password && userName));
  }

  function logicalAccountPrompt(): JSX.Element {
    return (
      <div className="sign-in-dialog-container">
        <Dialog isOpen={true} isDismissible={false} relativeTo="container">
          <Dialog.Main className="sign-in-dialog">
            <Dialog.TitleBar titleText={t('SignIn.SignIn')} />
            <Dialog.Content>
              <Flex flexDirection="column" alignItems="stretch">
                <Text variant="leading">
                  <Trans i18nKey="SignIn.EnterPWCredentials">
                    Enter your ProjectWise credentials for{' '}
                    <span className="sign-in-conn-name">{connectionName}</span>:
                  </Trans>
                </Text>
                <LabeledInput
                  label={t('Generic.UserName')}
                  type={'text'}
                  data-testid="signInUserName"
                  onKeyDown={keyPressAsClick(handleSubmit)}
                  onChange={(e) => setUserName(e.target.value)}
                  onBlur={(e) => setUserName(e.target.value)}
                  disabled={imsChecked}
                />
                <LabeledInput
                  label={t('SignIn.Password')}
                  type={'password'}
                  data-testid="signInPassword"
                  onKeyDown={keyPressAsClick(handleSubmit)}
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={(e) => setPassword(e.target.value)}
                  disabled={imsChecked}
                />
                <Checkbox
                  key={'useIMScheckbox'}
                  label={t('SignIn.UseIMS')}
                  data-testid="useImsCheckbox"
                  checked={imsChecked}
                  onChange={handleIMSChecked}
                />
              </Flex>
            </Dialog.Content>
            <Dialog.ButtonBar>
              <Button
                data-testid="signInSubmitButton"
                title={t('SignIn.SignIn')}
                styleType="high-visibility"
                onClick={handleSubmit}
                disabled={signInButtonDisabled()}
              >
                {t('SignIn.SignIn')}
              </Button>
            </Dialog.ButtonBar>
          </Dialog.Main>
        </Dialog>
      </div>
    );
  }
  return logicalAccountPrompt();
}
