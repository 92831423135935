import { useMemo } from 'react';
import { useLocalStorage } from '../useStorage';

type CurrentConnectionManager = {
  cachedConnectionId: string | undefined;
  connectionIdFromLocation: string | undefined;
  setCachedConnectionId: (connectionId: string | undefined) => void;
};

export function useCurrentConnection(
  contextId?: string
): CurrentConnectionManager {
  const storageKey = contextId
    ? `currentConnection:${contextId}`
    : 'currentConnection';

  const [cachedConnectionId, setCachedConnectionId] = useLocalStorage<
    string | undefined
  >(storageKey, undefined);

  const connectionIdFromLocation = useMemo((): string | undefined => {
    const segments = window.location.pathname.split('/');
    if (!segments.includes('connection')) {
      return undefined;
    }
    return segments.pop();
  }, []);

  return {
    cachedConnectionId,
    connectionIdFromLocation,
    setCachedConnectionId
  };
}
