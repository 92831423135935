import _ from 'lodash';
import type {
  HttpService,
  PWDataItem,
  RequestOptions,
  WSGInstance
} from '@bentley/pw-api';
import { byDefaultOrder, parseResponseInstances } from '@bentley/pw-api';

type CheckOutLocation = {
  DocumentGuid: string;
  FileName: string;
  ProjectGuid: string;
} & WSGInstance;

export async function outToMeSearch(
  userId: string,
  coAuthoringItemIds: string[],
  connectionId: string,
  setOutToMeCount: (count: number) => void,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWDataItem[]> {
  const abortController = httpOptions?.abortController ?? new AbortController();
  httpOptions = { ...httpOptions, uncached: true, abortController };

  const checkedOutDocGuids = await getCheckOutLocationDocGuids(
    userId,
    httpService,
    httpOptions
  );

  const documentGuids = _.uniq([...checkedOutDocGuids, ...coAuthoringItemIds]);

  if (!documentGuids.length) {
    setOutToMeCount(0);
    return [];
  }

  const documents = await getCheckedOutDocs(
    documentGuids,
    connectionId,
    httpService,
    httpOptions
  );

  setOutToMeCount(documents.length);
  return documents.sort(byDefaultOrder);
}

async function getCheckOutLocationDocGuids(
  userId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<string[]> {
  const checkOutQuery = `PW_WSG/CheckOutLocation!poly?$filter=UserId+eq+${userId}+and+Type+eq+'CheckedOut'&$select=DocumentGuid`;
  const response = await httpService.get(checkOutQuery, httpOptions);
  const checkedOutDocs = await parseResponseInstances<CheckOutLocation>(
    response
  );
  return checkedOutDocs.map((doc) => doc.DocumentGuid);
}

async function getCheckedOutDocs(
  documentGuids: string[],
  connectionId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWDataItem[]> {
  const docGuidsString = documentGuids.join("','");
  const classQuery = 'PW_WSG/Document!poly';
  const docFilter = `$filter=$id+in+['${docGuidsString}']&$select=*,DocumentParent-forward-Project.*,PW_WSG.DocumentEnvironment!poly-forward-PW_WSG.Environment!poly.*&api.ancestor=Project-${connectionId}`;
  const response = await httpService.post(
    `${classQuery}/$query`,
    docFilter,
    httpOptions
  );

  const documents = await parseResponseInstances<PWDataItem>(response);
  return documents.filter((document) => document.IsCheckedOut);
}
