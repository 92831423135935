export const applications: string[] = [
  'Acrobat PDF',
  'AECOsim Building Designer',
  'AECOsim Building Designer - Energy Simulator',
  'AECOsim GenerativeComponents',
  'AECOsim MicroStation',
  'AutoCAD',
  'AutoCAD Civil3D Data Shortcut Project File',
  'Autodesk Design Package',
  'Autodesk ReCap',
  'Autodesk Revit',
  'AutoPIPE',
  'AutoPLANT Equipment',
  'AutoPLANT Isometrics',
  'AutoPLANT P&ID',
  'AutoPLANT Piping',
  'AutoPLANT Raceways',
  'AutoPLANT Structural',
  'Batchplot',
  'Bentley CivilStorm',
  'Bentley CulvertMaster',
  'Bentley Digital Print Format(DPR)',
  'Bentley Electrical',
  'Bentley Facilities Planner',
  'Bentley FlowMaster',
  'Bentley Gas Analysis',
  'Bentley HAMMER',
  'Bentley i-model Composer',
  'Bentley Map',
  'Bentley Navigator',
  'Bentley PondPack',
  'Bentley PondPack V8i',
  'Bentley PowerCivil',
  'Bentley PowerMap',
  'Bentley PowerMap Field',
  'Bentley PowerSurvey',
  'Bentley Raceway and Cable Management',
  'Bentley Rail Track',
  'Bentley Rail Track Suite',
  'Bentley SewerCAD',
  'Bentley SewerGEMS',
  'Bentley speedikon A',
  'Bentley speedikon M',
  'Bentley speedikon P',
  'Bentley StormCAD',
  'Bentley Substation',
  'Bentley WaterCAD',
  'Bentley WaterGEMS',
  'Comma Delimited File',
  'Composite Section',
  'Descartes',
  'Design Web Format',
  'Drainage Library',
  'Drainage Preferences',
  'Drainage Profile Preferences',
  'Drainage Project',
  'Drainage Report Template',
  'Draw Cross-section Preferences',
  'Draw Profile Preferences',
  'ESRI ArcMap',
  'ESRI Layer',
  'ESRI Personal Geodatabase',
  'ESRI Raster',
  'ESRI Shapefile',
  'GEOPAK',
  'GEOPAK Ancillary Features Preferences',
  'GEOPAK Comp Book',
  'GEOPAK Coordinate Geometry database',
  'GEOPAK Criteria',
  'GEOPAK Cross Section Sheet Library',
  'GEOPAK D&C Database',
  'GEOPAK Design Tables',
  'GEOPAK Geotech Preferences',
  'GEOPAK Geotech Project',
  'GEOPAK K Values',
  'GEOPAK Legal Description Library',
  'GEOPAK Plan/Profile Sheet Library',
  'GEOPAK Road Project',
  'GEOPAK Site Section Manager',
  'GEOPAK Superelevation Preference',
  'GEOPAK Superelevation Table',
  'GEOPAK Survey Adjusted Coordinates',
  'GEOPAK Survey Control',
  'GEOPAK Survey Feature Database',
  'GEOPAK Survey file',
  'GEOPAK Survey Observations',
  'GEOPAK Survey Preferences',
  'GEOPAK Survey Project Preferences',
  'GEOPAK Survey RAW Data',
  'GEOPAK Survey Raw Data Backup',
  'GEOPAK Survey TDS Raw Data',
  'GEOPAK Trench Volumes',
  'GEOPAK XS Blue Top Report',
  'GEOPAK XS Clearing Report',
  'GEOPAK XS DTM Input 3D Report',
  'GEOPAK XS List Report',
  'GEOPAK XS Multi-Line/Staking Report',
  'GEOPAK XS Profile Grade Report',
  'GEOPAK XS Red Top Report',
  'GEOPAK XS Reports Header',
  'GEOPAK XS Table Report',
  'GEOPAK XS XYZ Report',
  'GEOPAK XS Yellow Top Report',
  'gINT Civil Tools Professional',
  'Google Earth',
  'Grid Surface Companion File',
  'Grid Surface File',
  'Images',
  'Input file',
  'InRoads',
  'InRoads Drafting Notes',
  'InRoads Drainage',
  'InRoads Geometry Projects',
  'InRoads Preferences',
  'InRoads Project',
  'InRoads Roadway Design',
  'InRoads Roadway Libraries',
  'InRoads Site',
  'InRoads Site Suite',
  'InRoads Suite',
  'InRoads Surfaces',
  'InRoads Survey',
  'InRoads Survey Data',
  'InRoads Survey Feature Table',
  'InRoads Survey Preference',
  'InRoads Template Libraries',
  'InRoads Typical Sections',
  'IPLOT IPARM',
  'IPLOT Settings',
  'IRASB',
  'Labeling Styles',
  'LEAP Bridge Concrete',
  'LEAP Bridge Enterprise',
  'LEAP Bridge Steel',
  'LEAP CONBOX',
  'LEAP CONSPAN',
  'LEAP CONSPLICE',
  'LEAP CONSYS',
  'LEAP GEOMATH',
  'LEAP PRESTO',
  'LEAP RC-PIER',
  'LEAP VERTEX',
  'Load DTM Features Preference File',
  'Microsoft Access',
  'Microsoft Excel',
  'Microsoft PowerPoint',
  'Microsoft Project',
  'Microsoft Visio',
  'Microsoft Word',
  'MicroStation',
  'MicroStation Civil Extension - InRoads',
  'MicroStation GeoGraphics',
  'MicroStation Schematics',
  'MineCycle Designer',
  'MineCycle Designer for Microstation',
  'MineCycle Survey',
  'MineCycle Survey for Microstation',
  'Modeler Roadway Network Settings',
  'Modeler Roadway Preferences',
  'Modeler Roadway Tabular Data Settings',
  'Modeler Themes - Range Settings',
  'OpenBridge Designer',
  'OpenBridge Modeler',
  'OpenBuildings Designer',
  'OpenBuildings Designer - Energy Simulator',
  'OpenBuildings Designer - GenerativeComponents',
  'OpenBuildings Designer - MicroStation',
  'OpenBuildings Station Designer',
  'OpenBuildings Station Designer - Energy Simulator',
  'OpenBuildings Station Designer - GenerativeComponents',
  'OpenBuildings Station Designer - MicroStation',
  'OpenPlant Modeler',
  'OpenPlant PID',
  'OpenPlant Support Engineering',
  'OpenRail Designer',
  'OpenRoads Designer',
  'Organizer Plot Set',
  'Outlook Message',
  'Point Cloud',
  'Power InRoads',
  'Power Rail Track',
  'PowerCivilGsf',
  'Print Organizer',
  'Profile Analysis Settings',
  'ProjectWise Advanced Integration for Revit 2016',
  'ProjectWise Advanced Integration for Revit 2017',
  'ProjectWise Advanced Integration for Revit 2018',
  'ProjectWise Advanced Integration for Revit 2019',
  'ProjectWise Advanced Integration for Revit 2020',
  'ProjectWise Advanced Integration for Revit Architecture 2016',
  'ProjectWise Advanced Integration for Revit MEP 2016',
  'ProjectWise Advanced Integration for Revit Structure 2016',
  'ProjectWise Revit Advanced Integration',
  'ProjectWise-integrated AutoCAD 2016',
  'ProjectWise-integrated AutoCAD 2017',
  'ProjectWise-integrated AutoCAD 2018',
  'ProjectWise-integrated AutoCAD 2019',
  'ProjectWise-integrated AutoCAD 2020',
  'ProjectWise-integrated AutoCAD Architecture 2016',
  'ProjectWise-integrated AutoCAD Architecture 2017',
  'ProjectWise-integrated AutoCAD Architecture 2018',
  'ProjectWise-integrated AutoCAD Architecture 2019',
  'ProjectWise-integrated AutoCAD Architecture 2020',
  'ProjectWise-integrated AutoCAD Civil 3D 2016',
  'ProjectWise-integrated AutoCAD Civil 3D 2017',
  'ProjectWise-integrated AutoCAD Civil 3D 2018',
  'ProjectWise-integrated AutoCAD Civil 3D 2019',
  'ProjectWise-integrated AutoCAD Civil 3D 2020',
  'ProjectWise-integrated AutoCAD Electrical 2017',
  'ProjectWise-integrated AutoCAD Electrical 2018',
  'ProjectWise-integrated AutoCAD Electrical 2019',
  'ProjectWise-integrated AutoCAD Electrical 2020',
  'ProjectWise-integrated AutoCAD Map 3D 2016',
  'ProjectWise-integrated AutoCAD Map 3D 2017',
  'ProjectWise-integrated AutoCAD Map 3D 2018',
  'ProjectWise-integrated AutoCAD Map 3D 2019',
  'ProjectWise-integrated AutoCAD Map 3D 2020',
  'ProjectWise-integrated AutoCAD Mechanical 2016',
  'ProjectWise-integrated AutoCAD Mechanical 2017',
  'ProjectWise-integrated AutoCAD Mechanical 2018',
  'ProjectWise-integrated AutoCAD Mechanical 2019',
  'ProjectWise-integrated AutoCAD Mechanical 2020',
  'ProjectWise-integrated AutoCAD MEP 2016',
  'ProjectWise-integrated AutoCAD MEP 2017',
  'ProjectWise-integrated AutoCAD MEP 2018',
  'ProjectWise-integrated AutoCAD MEP 2019',
  'ProjectWise-integrated AutoCAD MEP 2020',
  'ProjectWise-integrated AutoCAD P&ID 2016',
  'ProjectWise-integrated AutoCAD P&ID 2017',
  'ProjectWise-integrated AutoCAD Plant 3D 2017',
  'ProjectWise-integrated AutoCAD Plant 3D 2018',
  'ProjectWise-integrated AutoCAD Plant 3D 2019',
  'ProjectWise-integrated AutoCAD Plant 3D 2020',
  'ProjectWise-integrated Revit 2016',
  'ProjectWise-integrated Revit 2017',
  'ProjectWise-integrated Revit 2018',
  'ProjectWise-integrated Revit 2019',
  'ProjectWise-integrated Revit 2020',
  'ProjectWise-integrated Revit Architecture 2016',
  'ProjectWise-integrated Revit MEP 2016',
  'ProjectWise-integrated Revit Structure 2016',
  'Promis.e',
  'ProStructures',
  'RAM Structural System',
  'Site Modeler Preference File',
  'Site Modeler Project',
  'SpecWave',
  'STAAD(X)',
  'STAAD.Pro',
  'Tabular Data Preferences',
  'Task Linking Rules',
  'Terrain Model',
  'Terrain Model Data',
  'Text',
  'TIN Surface Companion File',
  'TIN Surface File',
  'vCalendar File',
  'vCard File',
  'Water Sewer Library',
  'Water Sewer Preferences',
  'Water Sewer Project',
  'Web Page',
  'XML Data',
  'XPSWMM Project',
  'ZIP'
];
