import React, { useMemo, useState } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import { Flex } from '@itwin/itwinui-react';
import { useEnvironmentContext, useTableItemContext } from '../../../context';
import { AttributesControl } from '../../attributesForm/attributesControl';
import { AttributeSheetSelector } from './attributeSheetSelector';
import { AttributesList } from './attributesList';

export function PWInfoPanelAttributes(): JSX.Element {
  const { ecPluginFeatures } = useEnvironmentContext();
  const { actionableItems } = useTableItemContext();

  const [currentAttributeSheet, setCurrentAttributeSheet] = useState<number>(0);

  const showList = useMemo(() => {
    if (!ecPluginFeatures.attributeInterfaces()) {
      return true;
    }

    const allSameEnvironment = actionableItems.every(
      ({ EnvironmentId }) => EnvironmentId == actionableItems[0]?.EnvironmentId
    );

    if (!allSameEnvironment) {
      return true;
    }

    return false;
  }, [actionableItems, ecPluginFeatures]);

  const items = useMemo((): PWDataItem[] => {
    return filterDataItems(actionableItems);
  }, [actionableItems]);

  return (
    <Flex flexDirection="column" className="pw-info-attributes" gap="0">
      {showList ? (
        <Flex.Item alignSelf="flex-start" className="pw-info-list">
          <AttributesList currentAttributeSheet={currentAttributeSheet} />
        </Flex.Item>
      ) : (
        <Flex.Item alignSelf="flex-start" className="pw-info-form">
          <AttributesControl
            items={items}
            currentSheet={currentAttributeSheet}
          />
        </Flex.Item>
      )}
      <Flex.Spacer />
      <Flex.Item className="pw-sheet-selector">
        <AttributeSheetSelector
          currentAttributeSheet={currentAttributeSheet}
          setCurrentAttributeSheet={setCurrentAttributeSheet}
        />
      </Flex.Item>
    </Flex>
  );
}
