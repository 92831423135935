import type { PWItem } from '@bentley/pw-api';
import { byDefaultOrder } from '@bentley/pw-api';
import type { Row, SortByFn } from '@itwin/itwinui-react/react-table';
import type { ColumnDataType } from '../column/typedColumn';
import {
  byBoolean,
  byDate,
  byFileName,
  byFolder,
  byNumber,
  byString,
  byVersion
} from './typeSorts';

type CompareFunction = <T>(a: T[keyof T], b: T[keyof T]) => number;

export function columnSortType(
  columnDataType: ColumnDataType
): SortByFn<PWItem> {
  const byType = sortType(columnDataType);

  return byFoldersThenProperty(byType);
}

export function nameColumnSortType(
  rowA: Row<PWItem>,
  rowB: Row<PWItem>
): number {
  return byDefaultOrder(rowA.original, rowB.original);
}

export function versionColumnSortType(
  rowA: Row<PWItem>,
  rowB: Row<PWItem>
): number {
  const aItem = rowA.original;
  const bItem = rowB.original;

  const value = byVersion(aItem, bItem);

  return value;
}

export function fileNameColumnSortType(
  rowA: Row<PWItem>,
  rowB: Row<PWItem>
): number {
  const aItem = rowA.original;
  const bItem = rowB.original;

  const value = byFileName(aItem, bItem);

  return value;
}

export function customPropertySortType(
  propertyLookup: (item: PWItem) => PWItem[keyof PWItem],
  columnDataType: ColumnDataType
): SortByFn<PWItem> {
  const byType = sortType(columnDataType);

  function sortByFunction(rowA: Row<PWItem>, rowB: Row<PWItem>): number {
    const folderValue = byFolder(rowA.original, rowB.original);

    if (folderValue != 0) {
      return folderValue;
    }

    const aVal = propertyLookup(rowA.original);
    const bVal = propertyLookup(rowB.original);

    return byType<PWItem>(aVal, bVal);
  }

  return sortByFunction;
}

function byFoldersThenProperty(
  compareFunction: CompareFunction
): SortByFn<PWItem> {
  function sortByFunction(
    rowA: Row<PWItem>,
    rowB: Row<PWItem>,
    columnId: string
  ): number {
    const folderValue = byFolder(rowA.original, rowB.original);

    if (folderValue != 0) {
      return folderValue;
    }

    const column = columnId as keyof PWItem;

    const aVal = rowA.original[column];
    const bVal = rowB.original[column];

    const value = compareFunction<PWItem>(aVal, bVal);

    return value;
  }

  return sortByFunction;
}

function sortType(columnDataType: ColumnDataType): CompareFunction {
  if (columnDataType == 'dateTime') {
    return byDate;
  }

  if (columnDataType == 'boolean') {
    return byBoolean;
  }

  if (
    ['int', 'double', 'float', 'long', 'number', 'fileSize'].includes(
      columnDataType
    )
  ) {
    return byNumber;
  }

  return byString;
}
