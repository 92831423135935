import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SvgEdit, SvgSave, SvgUndo } from '@itwin/itwinui-icons-react';
import { useFeatureTracking } from '../../../context';
import { t } from '../../../services/translation';
import { PWToolbar } from '../../toolbar';
import {
  formValidState,
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from '../form';
import { saveInProgressState, useSave } from '../save';
import {
  currentEnvironmentInstanceState,
  editingAttributesFormState,
  forceFormRefreshState,
  originalEnvironmentInstanceState
} from '../state';

export function AttributesFormToolbar(): JSX.Element {
  const [editingAttributesForm, setEditingAttributesForm] = useRecoilState(
    editingAttributesFormState
  );
  const saveInProgress = useRecoilValue(saveInProgressState);

  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const setForceFormRefresh = useSetRecoilState(forceFormRefreshState);
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const formValid = useRecoilValue(formValidState);

  const { saveInstance } = useSave();

  const { trackFeature } = useFeatureTracking();

  function allowEditToggle(): boolean {
    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (saveInProgress) {
      return false;
    }

    return true;
  }

  function onEditToggleClick(): void {
    if (editingAttributesForm) {
      return onCancelEditClick();
    } else {
      return onEditClick();
    }
  }

  function onEditClick(): void {
    setEditingAttributesForm(true);
  }

  function onCancelEditClick(): void {
    setCurrentEnvironmentInstance(originalEnvironmentInstance);
    setEditingAttributesForm(false);
  }

  function allowUndo(): boolean {
    if (!editingAttributesForm || saveInProgress) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (originalEnvironmentInstance == currentEnvironmentInstance) {
      return false;
    }

    return true;
  }

  function onUndoClick(): void {
    setCurrentEnvironmentInstance(originalEnvironmentInstance);
    setForceFormRefresh((cur) => !cur);
    trackFeature('UNDO_ATTRIBUTE_EDIT');
  }

  function allowSave(): boolean {
    if (!editingAttributesForm || saveInProgress) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (originalEnvironmentInstance == currentEnvironmentInstance) {
      return false;
    }

    if (!formValid) {
      return false;
    }

    return true;
  }

  function onSaveClick(): void {
    void saveInstance();
    trackFeature('SAVE_MODIFIED_ATTRIBUTE');
  }

  return (
    <PWToolbar
      buttons={[
        {
          title: editingAttributesForm
            ? t('Generic.Cancel')
            : t('Generic.Edit'),
          icon: <SvgEdit />,
          onClick: onEditToggleClick,
          disabled: !allowEditToggle(),
          isActive: editingAttributesForm
        },
        {
          title: t('AttributeForm.Undo'),
          icon: <SvgUndo />,
          onClick: onUndoClick,
          disabled: !allowUndo()
        },
        {
          title: t('Generic.Save'),
          icon: <SvgSave />,
          onClick: onSaveClick,
          disabled: !allowSave()
        }
      ]}
    />
  );
}
