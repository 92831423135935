import { useCallback, useEffect, useMemo } from 'react';
import type { DataAccessLevel } from '../../context';
import { useConnectionsContext } from '../../context';
import type { FederatedRepository } from '../../services/support';
import { federatedRepoToConnection } from '../../services/support/repositoryMapper';
import { getLinkPartsFromUrl } from './linkParser';

type LinkStatus = 'loading' | 'noLink' | 'invalidLink' | 'validLink';

type LinkStatusProps = {
  dataAccessLevel: DataAccessLevel;
};

export function useLinkStatus({
  dataAccessLevel
}: LinkStatusProps): LinkStatus {
  const { repositoryManager, navigate } = useConnectionsContext();

  const pwLink = getLinkPartsFromUrl();

  const isLink = useMemo(() => {
    if (pwLink.savedSearchId) {
      if (!pwLink.datasource && !pwLink.workArea) {
        return false;
      }
      return true;
    }

    if (
      !pwLink.datasource &&
      !pwLink.item &&
      !pwLink.itemType &&
      !pwLink.workArea
    ) {
      return false;
    }

    return true;
  }, [pwLink]);

  const repositoryMatchesLink = useCallback(
    (repo: FederatedRepository): boolean => {
      const connection = federatedRepoToConnection(repo);

      if (
        connection.DatasourceInstanceId.toLowerCase() !=
        pwLink.datasource?.toLowerCase()
      ) {
        return false;
      }

      if (pwLink.workArea && pwLink.workArea != connection.ProjectId) {
        return false;
      }

      return true;
    },
    [pwLink]
  );

  const linkedConnection = useMemo((): FederatedRepository | undefined => {
    if (!pwLink.datasource || (!pwLink.savedSearchId && !pwLink.item)) {
      return undefined;
    }

    if (dataAccessLevel == 'WorkArea' && !pwLink.workArea) {
      return undefined;
    }

    if (
      !repositoryManager.federatedRepos?.length ||
      !repositoryManager.initialized
    ) {
      return undefined;
    }

    return repositoryManager.federatedRepos.find(repositoryMatchesLink);
  }, [pwLink, repositoryManager, repositoryMatchesLink]);

  const linkStatus = useMemo(() => {
    if (!repositoryManager.initialized) {
      return 'loading';
    }

    if (!isLink) {
      return 'noLink';
    }

    if (linkedConnection) {
      return 'validLink';
    }

    return 'invalidLink';
  }, [isLink, linkedConnection, repositoryManager.initialized]);

  const visitConnection = useCallback(
    (repository: FederatedRepository) => {
      const query = pwLink.savedSearchId
        ? `?savedSearchId=${pwLink.savedSearchId}`
        : `?item=${pwLink.item ?? ''}&type=${pwLink.itemType ?? ''}`;

      void navigate?.(`connection/${repository.Id}${query}`);
      repositoryManager.selectFederatedRepository(repository.Id);
    },
    [pwLink.savedSearchId, pwLink.item, pwLink.itemType, navigate]
  );

  useEffect(() => {
    if (!repositoryManager.initialized) {
      return;
    }

    if (linkedConnection) {
      visitConnection(linkedConnection);
    }
  }, [repositoryManager.initialized, linkedConnection, visitConnection]);

  return linkStatus;
}
