import type { HttpService, RequestOptions } from '@bentley/pw-api';
import type { DynamicProjectSettings } from './driveSettingsModal';
import type { IgnoredFilePattern } from './responses';
import {
  handleGetSettingsResponse,
  handlePostSettingsResponse
} from './responses';

export const defaultProjectSettings = {
  UseDCWInFoldersWithCode: true,
  UseDCWInFoldersWithoutCode: false,
  UseDCWInPWIC: true,
  AutoCheckoutDocsInDrive: true,
  AutoCreationBlacklist: []
} as DynamicProjectSettings;

/**
 * Retrieves dynamic settings for specified project
 * @param {string} projectId Unique Id of project
 * @param {string} organizationId Unique Id of organization
 * @param {HttpService} httpService Http service
 * @param {RequestOptions} [httpOptions] Configured http options
 * @returns {Promise<DynamicProjectSettings} Stored settings for project
 */
export async function getProjectSettings(
  projectId: string,
  organizationId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<DynamicProjectSettings> {
  const url = `ProjectSettings/${projectId}/${organizationId}`;

  const response = await httpService.get(url, httpOptions);
  if (response.status == 404) {
    void postProjectSettings(projectId, defaultProjectSettings, httpService);
    return defaultProjectSettings;
  }
  return handleGetSettingsResponse(response);
}

/**
 * Post new dynamic project settings
 * @param {string} projectId Id of project to post settings for
 * @param {DynamicProjectSettings} settings New settings to post
 * @param {HttpService} httpService http service
 * @param {RequestOptions} [httpOptions]
 */
export async function postProjectSettings(
  projectId: string,
  settings: DynamicProjectSettings,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<void> {
  const url = `ProjectSettings/${projectId}`;
  const body = generatePostProjectSettingsBody(settings);
  const response = await httpService.post(url, body, httpOptions);
  handlePostSettingsResponse(response);
  return;
}

function generatePostProjectSettingsBody(
  settings: DynamicProjectSettings
): string {
  return JSON.stringify({
    autoCreate: {
      ignoredFilePatterns: generateIgnoredFilePatterns(
        settings.AutoCreationBlacklist
      )
    }
  });
}

function generateIgnoredFilePatterns(
  blacklist: string[]
): IgnoredFilePattern[] {
  return blacklist.map((pattern) => ({
    name: pattern,
    value: pattern
  }));
}
