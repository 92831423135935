import type { ProviderProps, ReactNode } from 'react';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { EmptyConnectionsState } from '../../../components/emptyState';
import type { Connection } from '../../../services/support';
import { useConnectionsContext } from '../connections';
import type { ConsumerApp, DataAccessLevel, SelectionState } from '../plugin';
import { useSelectionState } from '../plugin/useSelectionState';

const Context = createContext<ConnectionContext | undefined>(undefined);

type ConnectionProps = {
  buddiRegionCode: string;
  consumerApp?: ConsumerApp;
  contextId?: string;
  dataAccessLevel?: DataAccessLevel;
  emptyConnectionsContent?: ReactNode;
  selectionState?: SelectionState | string;
};

type ConnectionContext = {
  buddiRegionCode: string;
  connection: Connection;
  connectionAuthenticated: boolean;
  consumerApp?: ConsumerApp;
  contextId?: string;
  dataAccessLevel: DataAccessLevel;
  selectionState: SelectionState;
  setConnectionExplorerAuthenticated: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

export function ConnectionProvider({
  value: {
    buddiRegionCode,
    consumerApp,
    contextId,
    dataAccessLevel = 'WorkArea',
    emptyConnectionsContent,
    selectionState
  },
  children
}: ProviderProps<ConnectionProps>): JSX.Element {
  const { connection } = useConnectionsContext();

  const sessionSelectionState = useSelectionState(selectionState);

  const [connectionExplorerAuthenticated, setConnectionExplorerAuthenticated] =
    useState<boolean>(false);

  const connectionContext: ConnectionContext = useMemo(
    (): ConnectionContext => ({
      buddiRegionCode,
      connection: connection ?? ({} as Connection),
      selectionState: sessionSelectionState,
      connectionAuthenticated: connectionExplorerAuthenticated,
      consumerApp,
      contextId,
      dataAccessLevel,
      setConnectionExplorerAuthenticated
    }),
    [
      buddiRegionCode,
      connection,
      connectionExplorerAuthenticated,
      consumerApp,
      contextId,
      dataAccessLevel,
      sessionSelectionState
    ]
  );

  if (!connection?.Id) {
    return (
      <EmptyConnectionsState
        buddiRegionCode={buddiRegionCode}
        dataAccessLevel={dataAccessLevel}
        emptyConnectionsContent={emptyConnectionsContent}
      />
    );
  }

  return (
    <Context.Provider value={connectionContext}>{children}</Context.Provider>
  );
}

export function useConnectionContext(): ConnectionContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useConnectionContext must be used within a ConnectionProvider'
    );
  }

  return context;
}
