import type { ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import {
  getDocumentCodeFormDefinition,
  getInterfaces,
  getProjectDefaultFormDefinition
} from '../../../actions/formRendering';
import { fileNameChangeAllowed } from '../../../actions/rename';
import type { UploadNode } from '../../../actions/upload';
import { useHttpService } from '../../../context';
import { useECPluginVersion } from '../../../hooks/useECPluginVersion';
import type { ToastHandle } from '../../../services/pwToast';
import { t } from '../../../services/translation';
import { uploadNodeState } from '../create';
import { environmentInterfacesState } from '../form';
import { DCWLoadingPage } from '../loading';
import {
  allowFileRenameState,
  applyToAllState,
  attributesFormDefinitionState,
  defaultDocumentDescriptionState,
  defaultDocumentNameState,
  defaultVersionState,
  docCodeFormDefinitionState,
  docPropertiesState,
  multiUploadState,
  originalFileNameState,
  uploadingDirectoriesState
} from '../wizard';
import {
  isCopyModeState,
  isFlatSetCreationModeState,
  itemsToCopyState,
  uploadToastHandleState
} from '../wizard/state';
import { getDCWHttpService, setDCWHttpService } from './httpService';
import {
  defaultEnvironmentInstanceStateAsync,
  ecPluginVersionState,
  presetEnvironmentInstanceState,
  projectIdState,
  synchronousModeState
} from './state';
import { useRecoilValueAsync } from './useRecoilValueAsync';

type DCWStateProviderProps = {
  children?: ReactNode;
  defaultDocumentDescription: string;
  defaultDocumentName: string;
  fileName: string;
  fileNumber: number;
  isCopyMode?: boolean;
  isFlatSetCreationMode?: boolean;
  numberOfFiles: number;
  presetEnvironmentInstance?: WSGInstance;
  projectId: string;
  synchronous?: boolean;
  toastHandle?: ToastHandle;
  uploadNode: UploadNode;
  version: string;
};

export function DCWStateProvider({
  children,
  defaultDocumentDescription,
  defaultDocumentName,
  fileName,
  fileNumber,
  isCopyMode,
  isFlatSetCreationMode,
  numberOfFiles,
  presetEnvironmentInstance,
  projectId,
  synchronous,
  toastHandle,
  uploadNode,
  version
}: DCWStateProviderProps): JSX.Element {
  const httpService = useHttpService();

  const { ecPluginVersion, ecPluginInitialized } = useECPluginVersion();

  const setEcPluginVersion = useSetRecoilState(ecPluginVersionState);
  const setSynchronousMode = useSetRecoilState(synchronousModeState);
  const setProjectId = useSetRecoilState(projectIdState);
  const setMultiUpload = useSetRecoilState(multiUploadState);
  const setPresetInstance = useSetRecoilState(presetEnvironmentInstanceState);

  const [uploadingDirectories, setUploadingDirectories] = useRecoilState(
    uploadingDirectoriesState
  );

  const [docProperties, setDocProperties] = useRecoilState(docPropertiesState);
  const setDefaultDocumentName = useSetRecoilState(defaultDocumentNameState);

  const setDefaultDocumentDescription = useSetRecoilState(
    defaultDocumentDescriptionState
  );

  const setOriginalFileName = useSetRecoilState(originalFileNameState);
  const setDefaultVersion = useSetRecoilState(defaultVersionState);

  const setEnvironmentInterfaces = useSetRecoilState(
    environmentInterfacesState
  );

  const setAllowFileRename = useSetRecoilState(allowFileRenameState);
  const setApplyToAll = useSetRecoilState(applyToAllState);

  const setIsFlatSetCreationMode = useSetRecoilState(
    isFlatSetCreationModeState
  );

  const setIsCopyMode = useSetRecoilState(isCopyModeState);

  const setItemsToCopy = useSetRecoilState(itemsToCopyState);

  const setUploadToastHandle = useSetRecoilState(uploadToastHandleState);

  const [docCodeFormDefinition, setDocCodeFormDefinition] = useRecoilState(
    docCodeFormDefinitionState
  );

  const [attributesFormDefinition, setAttributesFormDefinition] =
    useRecoilState(attributesFormDefinitionState);

  const defaultEnvironmentInstance = useRecoilValueAsync(
    defaultEnvironmentInstanceStateAsync
  );

  const [serializedUploadNode, setSerializedUploadNode] =
    useRecoilState<UploadNode>(uploadNodeState);

  const initialized = useMemo((): boolean => {
    if (!ecPluginInitialized || !ecPluginVersion) {
      return false;
    }

    if (docCodeFormDefinition === undefined) {
      return false;
    }

    if (docCodeFormDefinition && defaultEnvironmentInstance === undefined) {
      return false;
    }

    if (attributesFormDefinition === undefined) {
      return false;
    }

    if (attributesFormDefinition && defaultEnvironmentInstance === undefined) {
      return false;
    }

    return true;
  }, [
    attributesFormDefinition,
    defaultEnvironmentInstance,
    docCodeFormDefinition,
    ecPluginInitialized,
    ecPluginVersion
  ]);

  useEffect(() => {
    setPresetInstance(presetEnvironmentInstance ?? null);
  }, [presetEnvironmentInstance, setPresetInstance]);

  useEffect(() => {
    setItemsToCopy([...(uploadNode.itemsToCopy?.slice(fileNumber) ?? [])]);
  }, [fileNumber, uploadNode.itemsToCopy, setItemsToCopy]);

  useEffect(() => {
    setEcPluginVersion(ecPluginVersion);
  }, [ecPluginVersion, setEcPluginVersion]);

  useEffect(() => {
    setSynchronousMode(synchronous ?? false);
  }, [setSynchronousMode, synchronous]);

  useEffect(() => {
    setDCWHttpService(httpService);
  }, [httpService]);

  useEffect(() => {
    setProjectId(projectId);
  }, [projectId, setProjectId]);

  useEffect(() => {
    setDefaultDocumentName(defaultDocumentName);
  }, [defaultDocumentName, setDefaultDocumentName]);

  useEffect(() => {
    setDefaultDocumentDescription(defaultDocumentDescription);
  }, [defaultDocumentDescription, setDefaultDocumentDescription]);

  useEffect(() => {
    setOriginalFileName(fileName);
  }, [fileName, setOriginalFileName]);

  useEffect(() => {
    setDefaultVersion(version);
  }, [setDefaultVersion, version]);

  useEffect(() => {
    const defaultProps = presetEnvironmentInstance
      ? {
          ...docProperties,
          name: defaultDocumentName,
          description: defaultDocumentDescription
        }
      : docProperties;
    setDocProperties(defaultProps);
  }, [
    defaultDocumentDescription,
    defaultDocumentName,
    docProperties,
    presetEnvironmentInstance,
    setDocProperties
  ]);

  useEffect(() => {
    const multiUpload = fileNumber < numberOfFiles - 1 || uploadingDirectories;
    setMultiUpload(multiUpload);
  }, [fileNumber, numberOfFiles, setMultiUpload, uploadingDirectories]);

  useEffect(() => {
    async function initializeFileRenamePermission(): Promise<void> {
      try {
        const allowFileRename = await fileNameChangeAllowed(httpService);
        setAllowFileRename(allowFileRename);
      } catch {
        setAllowFileRename(true);
      }
    }

    void initializeFileRenamePermission();
  }, [httpService, setAllowFileRename]);

  useEffect(() => {
    const uploadingDirectories = serializedUploadNode.directories.length > 0;
    setUploadingDirectories(uploadingDirectories);

    if (uploadingDirectories) {
      setApplyToAll(true);
    }
  }, [serializedUploadNode, setApplyToAll, setUploadingDirectories]);

  useEffect(() => {
    const rootFilesToUpload = [...uploadNode.files.slice(fileNumber)];
    setSerializedUploadNode({ ...uploadNode, files: rootFilesToUpload });
  }, [fileNumber, setSerializedUploadNode, uploadNode]);

  useEffect(() => {
    async function tryInitializeEnvironmentInterfaces(): Promise<void> {
      const environmentClass = defaultEnvironmentInstance?.className;
      const httpService = getDCWHttpService();

      if (!environmentClass || !httpService) {
        return;
      }

      const environmentInterfaces = await getInterfaces(
        environmentClass,
        httpService
      );

      setEnvironmentInterfaces(environmentInterfaces);
    }

    void tryInitializeEnvironmentInterfaces();
  }, [defaultEnvironmentInstance, setEnvironmentInterfaces]);

  useEffect(() => {
    async function tryInitializeDocCodeFormDef(): Promise<void> {
      if (!projectId || !httpService) {
        return;
      }

      const formDefinition = await getDocumentCodeFormDefinition(
        projectId,
        httpService
      );

      setDocCodeFormDefinition(formDefinition ?? null);
    }

    void tryInitializeDocCodeFormDef();
  }, [httpService, projectId, setDocCodeFormDefinition]);

  useEffect(() => {
    const abortController = new AbortController();

    async function tryInitializeAttributesFormDef(): Promise<void> {
      if (
        !projectId ||
        !httpService ||
        !ecPluginInitialized ||
        !ecPluginVersion
      ) {
        return;
      }

      const formDefinition = await getProjectDefaultFormDefinition(
        projectId,
        httpService,
        { abortController }
      );

      setAttributesFormDefinition(formDefinition ?? null);
    }

    if (!attributesFormDefinition) {
      void tryInitializeAttributesFormDef();
    }

    return () => {
      abortController.abort();
    };
  }, [
    httpService,
    projectId,
    setAttributesFormDefinition,
    ecPluginInitialized,
    ecPluginVersion,
    attributesFormDefinition
  ]);

  useEffect(() => {
    setIsFlatSetCreationMode(isFlatSetCreationMode ?? false);
  }, [isFlatSetCreationMode, setIsFlatSetCreationMode]);

  useEffect(() => {
    setIsCopyMode(isCopyMode ?? false);
  }, [isCopyMode, setIsCopyMode]);

  useEffect(() => {
    setUploadToastHandle(toastHandle);
  }, [toastHandle, setUploadToastHandle]);

  useEffect(() => {
    if (initialized) {
      toastHandle?.close();
    }
  }, [initialized, toastHandle]);

  if (!initialized) {
    return <DCWLoadingPage loadingText={t('Generic.Loading')} />;
  }

  return <>{children}</>;
}
