import type { PWItem } from '@bentley/pw-api';
import type { ToastHandle } from '../../../services/pwToast';
import { openToast, replaceToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';

export function lockToVersionInProgressToast(): ToastHandle {
  const content = t('LockToVersion.InProgress');

  return openToast({ content, type: 'persisting', spinner: true });
}

export function lockToVersionSuccessToast(toastHandle: ToastHandle): void {
  const content = t('LockToVersion.Success');

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function lockToVersionFailureToast(
  toastHandle: ToastHandle,
  error?: Error
): void {
  const errorMessage = error?.message ?? t('Generic.UnknownError');
  const content = t('LockToVersion.Error', { errorMessage });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function unlockFromVersionInProgressToast(): ToastHandle {
  const content = t('UnlockFromVersion.InProgress');

  return openToast({ content, type: 'persisting', spinner: true });
}

export function unlockFromVersionSuccessToast(toastHandle: ToastHandle): void {
  const content = t('UnlockFromVersion.Success');

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function unlockFromVersionFailureToast(
  toastHandle: ToastHandle,
  error?: Error
): void {
  const errorMessage = error?.message ?? t('Generic.UnknownError');
  const content = t('UnlockFromVersion.Error', { errorMessage });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function on403Error(): string {
  return t('FlatSet.ToggleVersionLock.Notifications.On403Error');
}

export function on400Error(item: PWItem): string {
  return t('FlatSet.ToggleVersionLock.Notifications.On400Error', {
    name: item.Name
  });
}
