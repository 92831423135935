import './docCodeFormLayout.css';

import React from 'react';
import { DocCodePreviewContainer } from '../common/docCodePreviewContainer';
import { DocCodeFormToolbar } from '../toolbar';
import { DocCodeFormContainer } from './docCodeFormContainer';

export function DocCodeFormLayout(): JSX.Element {
  return (
    <div className="docCodeFormPanel">
      <DocCodeFormToolbar />
      <DocCodeFormContainer />
      <DocCodePreviewContainer />
    </div>
  );
}
