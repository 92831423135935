import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ErrorPage } from '../../../components/errorPage';
import { LoadingPage } from '../../../components/loadingPage';
import { PlainHttpServiceProvider } from '../../../context';
import type { Connection } from '../../../services/support/connection';
import { webConnectionToConnection } from '../../../services/support/repositoryMapper';
import { t } from '../../../services/translation';
import { useConnections } from './useConnections';

type ConnectionResolverProps = {
  buddiRegionCode: string;
  connectionId: string;
  contextId: string;
  children: ReactNode;
};

const Context = createContext<Connection>({} as Connection);

export function ConnectionResolver({
  buddiRegionCode,
  connectionId,
  contextId,
  children
}: ConnectionResolverProps): JSX.Element {
  const getConnections = useConnections({ buddiRegionCode, contextId });

  const [dataResolved, setDataResolved] = useState<
    'loading' | 'error' | 'resolved'
  >('loading');
  const [connection, setConnection] = useState<Connection>();

  useEffect(() => {
    const abortController = new AbortController();

    async function resolveConnection(): Promise<void> {
      try {
        const connections = await getConnections({ abortController });
        if (!connections) {
          return;
        }

        const connection = connections.find(
          (connection) => connection.id == connectionId
        );
        if (connection) {
          setConnection(webConnectionToConnection(connection));
          setDataResolved('resolved');
        } else {
          setDataResolved('error');
        }
      } catch (error) {
        console.error(error);
        setDataResolved('error');
      }
    }

    if (connection || !connectionId) {
      return;
    }

    void resolveConnection();

    return () => {
      abortController.abort();
    };
  }, [connection, connectionId, getConnections]);

  function isError(): boolean {
    if (dataResolved == 'loading') {
      return false;
    }

    if (dataResolved == 'error') {
      return true;
    }

    if (!connection?.DatasourceInstanceId || !connection?.ProjectId) {
      return true;
    }

    return false;
  }

  if (dataResolved == 'loading') {
    return (
      <LoadingPage
        loadingText={t('Generic.Loading')}
        loadingSubText={t('ConnectionResolver.ContactingDatasource')}
      />
    );
  }

  if (isError() || !connection) {
    return (
      <ErrorPage errorType="generic" errorMsg={t('ConnectionResolver.Error')} />
    );
  }

  return (
    <PlainHttpServiceProvider value={{ baseWsgUrl: connection.ConnectionUrl }}>
      <Context.Provider value={connection}>{children}</Context.Provider>
    </PlainHttpServiceProvider>
  );
}

export function useLinkConnection(): Connection {
  const connection = useContext(Context);

  if (!connection) {
    throw new Error(
      'useLinkConnection must be used within a ConnectionResolver'
    );
  }

  return connection;
}
