import { useEffect, useMemo, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { getProject } from '@bentley/pw-api';
import { useHttpService, usePluginContext } from '../../context';
import type { Connection } from '../../services/support';

export function useConnectionBreadcrumb(): PWProject {
  const { connection } = usePluginContext();
  const httpService = useHttpService();

  const defaultConnectionBreadcrumb = useMemo(
    () => connectionToBreadcrumb(connection),
    [connection]
  );

  const [connectionBreadcrumb, setConnectionBreadcrumb] = useState<PWProject>(
    defaultConnectionBreadcrumb
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeConnectionBreadcrumb(): Promise<void> {
      if (!connection.ProjectId) {
        return;
      }

      const connectionBreadcrumb = await getProject({
        instanceId: connection.ProjectId,
        httpService,
        requestOptions: { abortController }
      });

      setConnectionBreadcrumb({
        ...connectionBreadcrumb,
        ...defaultConnectionBreadcrumb
      });
    }

    void initializeConnectionBreadcrumb();

    return () => {
      abortController.abort();
    };
  }, [connection, defaultConnectionBreadcrumb, httpService]);

  return connectionBreadcrumb;
}

export function connectionToBreadcrumb(connection: Connection): PWProject {
  return {
    Name: connection.Name,
    Label: connection.Name,
    instanceId: connection.ProjectId || null,
    className: 'Project',
    TypeString: 'Project',
    IsRichProject: true
  } as PWProject;
}
