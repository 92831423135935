import { basicSearchBlacklist } from '../../../services/search';
import type { ColumnInfo } from '../../useViews';

export function getSearchColumnNames(columns: ColumnInfo[]): string[] {
  const validColumns = columns
    .filter(isStringDataType)
    .filter(isGeneralPropertyType)
    .filter(isNotBlacklisted);
  const columnNames = validColumns.map((column) => column.name);

  addColumnNameIfMissing('Version', columnNames);
  addColumnNameIfMissing('Description', columnNames);
  addColumnNameIfMissing('FileName', columnNames);
  addColumnNameIfMissing('Name', columnNames);

  return columnNames;
}

function isStringDataType(column: ColumnInfo): boolean {
  return column.dataType == 'string';
}

function isGeneralPropertyType(column: ColumnInfo): boolean {
  return column.propertyType == 'General';
}

function isNotBlacklisted(column: ColumnInfo): boolean {
  const blacklistedProperties = basicSearchBlacklist.filter(
    ({ filterOutCondition }) => filterOutCondition('Document')
  );

  const blacklistedPropertyNames = blacklistedProperties.map(
    (filter) => filter.propertyName
  );

  return !blacklistedPropertyNames.includes(column.name);
}

function addColumnNameIfMissing(
  columnName: string,
  columnNames: string[]
): void {
  if (!columnNames.includes(columnName)) {
    columnNames.unshift(columnName);
  }
}
