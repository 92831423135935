import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgDuplicate } from '@itwin/itwinui-icons-react';
import { CreateCopyDestinationComponent } from '../../../../actions/copy/createCopyDestinationComponent';
import { allowCreateCopyAll } from '../../../../actions/copy/requirements';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCreateCopyAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { readOnly, connection } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const { ecPluginFeatures } = useEnvironmentContext();

  const isRoot = useMemo((): boolean => {
    return (
      !readOnly &&
      items.length > 0 &&
      items[0].instanceId == connection.ProjectId
    );
  }, [connection, items, readOnly]);

  const showCreateCopy = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }
    if (currentAppView.type == 'RecycleBin') {
      return false;
    }
    if (!launchDarklyFeatures.showCopyAction) {
      return false;
    }

    return true;
  }, [readOnly, currentAppView.type, launchDarklyFeatures.showCopyAction]);

  const createCopyAction = useMemo(
    (): MenuItemAction => ({
      title: t('DocumentCreation.CreateCopy'),
      icon: <SvgDuplicate />,
      onClick: () => {
        primaryModal.open(<CreateCopyDestinationComponent copyItems={items} />);
      },
      disabled:
        isRoot ||
        !showCreateCopy ||
        !allowCreateCopyAll(items, allItemsExistInConnection),
      hidden: !showCreateCopy || !ecPluginFeatures.copyItems()
    }),
    [
      isRoot,
      showCreateCopy,
      items,
      allItemsExistInConnection,
      ecPluginFeatures,
      primaryModal
    ]
  );

  return createCopyAction;
}
