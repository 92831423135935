import type { ReactNode } from 'react';
import React from 'react';
import {
  PlainHttpServiceProvider,
  ProductSettingProvider,
  TokenProvider
} from '../../../context';
import type { GraphErrorModalPluginProps } from './graphErrorModalPlugin';

type GraphErrorModalPluginsProviderProps = GraphErrorModalPluginProps & {
  children: ReactNode;
};

export function GraphErrorModalPluginsProvider({
  baseWsgUrl,
  getOidcToken,
  getSamlToken,
  children
}: GraphErrorModalPluginsProviderProps): JSX.Element {
  return (
    <TokenProvider value={{ getOidcToken, getSamlToken }}>
      <PlainHttpServiceProvider value={{ tokenType: 'SAML', baseWsgUrl }}>
        <ProductSettingProvider value={{} as never}>
          {children}
        </ProductSettingProvider>
      </PlainHttpServiceProvider>
    </TokenProvider>
  );
}
