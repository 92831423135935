import React from 'react';
import { Tabs } from '@itwin/itwinui-react';

type TabProperties = {
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
  enabled: boolean;
  visible: boolean;
};

export type PWInfoPanelTab = {
  label: string;
  tab: JSX.Element | null;
  panel: JSX.Element | null;
  enabled: boolean;
  visible: boolean;
};

export function buildPWInfoPanelTab({
  label,
  icon,
  content,
  enabled,
  visible
}: TabProperties): PWInfoPanelTab {
  if (!visible) {
    return {
      label: label,
      tab: null,
      panel: null,
      enabled: false,
      visible: false
    };
  }

  const tab = (
    <Tabs.Tab
      value={label}
      data-testid={`${label}-tab`}
      key={label}
      disabled={!enabled}
    >
      <Tabs.TabIcon>{icon}</Tabs.TabIcon>
      <Tabs.TabLabel>{label}</Tabs.TabLabel>
    </Tabs.Tab>
  );

  const panel = (
    <Tabs.Panel value={label} key={label} className="tabs-content">
      {content}
    </Tabs.Panel>
  );

  return { label, tab, panel, enabled: enabled && visible, visible };
}
