import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RequestOptions } from '@bentley/pw-api';
import {
  AuthorizationService,
  CacheService,
  HttpService
} from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { FeatureName } from '../../hooks/useTrackFeature';
import { waitForCondition } from '../../services/concurrencyLimiter';
import type { GetToken } from '../../services/http';
import type { IModelBridge, IModelMapping } from './iModelBridge';
import { getIModelBridges } from './utils';

export type IModelMappingFunctions = {
  getIModelBridgesForDocument: (
    documentId: string,
    httpOptions?: RequestOptions,
    workAreaId?: string
  ) => Promise<IModelBridge[]>;
  getIModelBridgesForConnectProject: (
    httpOptions?: RequestOptions
  ) => Promise<IModelBridge[]>;
  getIModelBridgesForIModel: (
    iModelId: string,
    httpOptions?: RequestOptions
  ) => Promise<IModelBridge[]>;
  createIModelBridge: (
    iModelBridge: IModelMapping,
    datasourceIdentifier: string,
    trackFeature: (featureName: FeatureName) => void,
    workAreaId?: string,
    httpOptions?: RequestOptions
  ) => Promise<boolean>;
  deleteIModelBridge: (iModelBridgeId: string) => Promise<boolean>;
};

export function useIModelBridges(
  getSamlToken: GetToken,
  buddiRegionCode: string,
  contextId?: string
): IModelMappingFunctions {
  const initialized = useRef<boolean>(false);
  const iModelBridgeUrl = useBuddi(
    'iModelBridgeConfiguration',
    buddiRegionCode
  );

  const [httpService, setHttpService] = useState<HttpService>();
  useEffect(() => {
    if (!iModelBridgeUrl || initialized.current) {
      return;
    }

    const queryUrl = `${iModelBridgeUrl}/v2.4/repositories/BentleyCONNECT--Main`;
    setHttpService(
      new HttpService({
        authorization: new AuthorizationService({ getSamlToken }),
        baseUrl: queryUrl,
        cache: new CacheService({ name: 'IModelBridge' })
      })
    );
    initialized.current = true;
  }, [getSamlToken, iModelBridgeUrl]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function apiGuard<T extends (...args: any) => Promise<U>, U>(
    api: (...params: Parameters<T>) => Promise<U>
  ): (...params: Parameters<T>) => Promise<U> {
    return async (...params: Parameters<T>): Promise<U> => {
      await waitForCondition(() => initialized.current, 100);
      return api(...params);
    };
  }

  function getDocumentIdWithWorkAreaId(
    documentId: string,
    workAreaId?: string
  ): string {
    const updatedDocumentId = workAreaId
      ? `${documentId};${workAreaId}`
      : documentId;

    return updatedDocumentId;
  }

  const getIModelBridgesForDocument = useCallback(
    async (
      documentId: string,
      httpOptions?: RequestOptions,
      workAreaId?: string
    ): Promise<IModelBridge[]> => {
      const updatedDocumentId = getDocumentIdWithWorkAreaId(
        documentId,
        workAreaId
      );
      const filter = `DocumentGuid+eq+'${updatedDocumentId}'+or+DocumentGuid+eq+'${documentId}'`;
      return getIModelBridges(filter, httpService, httpOptions);
    },
    [httpService]
  );

  const getIModelBridgesForConnectProject = useCallback(
    (httpOptions?: RequestOptions): Promise<IModelBridge[]> => {
      const filter = `ProjectGuid+eq+'${contextId ?? ''}'`;
      return getIModelBridges(filter, httpService, httpOptions);
    },
    [contextId, httpService]
  );

  const getIModelBridgesForIModel = useCallback(
    async (
      iModelId: string,
      httpOptions?: RequestOptions
    ): Promise<IModelBridge[]> => {
      const filter = `iModelId+eq+'${iModelId}'`;
      return getIModelBridges(filter, httpService, httpOptions);
    },
    [httpService]
  );

  const iModelMapToBridge = useCallback(
    (
      mapping: IModelMapping,
      datasourceIdentifier: string,
      workAreaId?: string
    ): IModelBridge => {
      const documentGuid = getDocumentIdWithWorkAreaId(
        mapping.document.instanceId,
        workAreaId
      );
      return {
        ProjectGuid: contextId ?? '',
        DataSourceGuid: '',
        DataSourceIdentifier: datasourceIdentifier,
        DocumentGuid: documentGuid,
        IsMaster: mapping.isMaster ? 'true' : 'false',
        iModelId: mapping.iModel?.instanceId ?? '',
        IsSpatialRoot: mapping.isSpatialRoot ? 'true' : 'false'
      } as IModelBridge;
    },
    [contextId]
  );

  const createIModelBridge = useCallback(
    async (
      iModelMapping: IModelMapping,
      datasourceIdentifier: string,
      trackFeature: (featureName: FeatureName) => void,
      workAreaId?: string
    ): Promise<boolean> => {
      if (!httpService || !contextId || !iModelMapping.iModel) {
        return false;
      }
      try {
        trackFeature('MAP_TO_IMODEL');
        const iModelBridge = iModelMapToBridge(
          iModelMapping,
          datasourceIdentifier,
          workAreaId
        );
        const response = await httpService.post(
          'IModelConfigSchema/ConfigInfo',
          JSON.stringify({
            instance: {
              schemaName: 'IModelConfigSchema',
              className: 'ConfigInfo',
              properties: iModelBridge
            }
          })
        );
        return Promise.resolve(response.ok && response.status == 201);
      } catch {
        return false;
      }
    },
    [contextId, httpService, iModelMapToBridge]
  );

  const deleteIModelBridge = useCallback(
    async (iModelBridgeId: string): Promise<boolean> => {
      if (!httpService) {
        return false;
      }
      const response = await httpService.delete(
        'IModelConfigSchema/ConfigInfo/' + iModelBridgeId,
        null
      );
      return Promise.resolve(response.ok);
    },
    [httpService]
  );

  const iModelMappingFunctions = useMemo(
    (): IModelMappingFunctions => ({
      getIModelBridgesForDocument: apiGuard(getIModelBridgesForDocument),
      createIModelBridge: apiGuard(createIModelBridge),
      deleteIModelBridge: apiGuard(deleteIModelBridge),
      getIModelBridgesForIModel: apiGuard(getIModelBridgesForIModel),
      getIModelBridgesForConnectProject: apiGuard(
        getIModelBridgesForConnectProject
      )
    }),
    [
      createIModelBridge,
      deleteIModelBridge,
      getIModelBridgesForConnectProject,
      getIModelBridgesForDocument,
      getIModelBridgesForIModel
    ]
  );

  return iModelMappingFunctions;
}
