import type { PWItem } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import type { ColumnDisplayOptions, ColumnInfo } from '../../../hooks/useViews';

export type ColumnDataType =
  | 'string'
  | 'dateTime'
  | 'boolean'
  | 'number'
  | 'fileSize';

export function getColumnDataType(dataType: string): ColumnDataType {
  if (['boolean', 'dateTime', 'fileSize'].includes(dataType)) {
    return dataType as ColumnDataType;
  }

  if (['int', 'double', 'float', 'long', 'number'].includes(dataType)) {
    return 'number';
  }

  return 'string';
}

export type PropertyColumn = (
  columnInfo: ColumnInfo,
  displayOptions?: ColumnDisplayOptions
) => Column<PWItem>;
