import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { executeSavedSearch } from '../../../actions/savedSearch';
import {
  useHttpService,
  useNavigationContext,
  useTelemetry
} from '../../../context';
import type { AppViewSetting, FetchItemsArgs } from './appViewSetting';

export function useSavedSearchView(): AppViewSetting {
  const { startTelemetry, endTelemetry } = useTelemetry();
  const httpService = useHttpService();
  const {
    searchState: { savedSearch, setSavedSearch }
  } = useNavigationContext();

  const fetchItems = useCallback(
    async ({
      requestOptions: httpOptions
    }: FetchItemsArgs): Promise<PWItem[]> => {
      if (!savedSearch) {
        throw new Error('Saved search not set');
      }

      startTelemetry('Search');
      const items = await executeSavedSearch(
        savedSearch.instanceId,
        savedSearch.ResultClassName,
        httpService,
        httpOptions
      );
      endTelemetry('Search', {
        searchType: 'Saved',
        query: JSON.stringify(savedSearch),
        numItems: items.length
      });

      return items;
    },

    [endTelemetry, httpService, savedSearch, startTelemetry]
  );

  const clearView = useCallback((): void => {
    setSavedSearch(undefined);
  }, [setSavedSearch]);

  const savedSearchViewSetting = useMemo(
    (): AppViewSetting => ({
      type: 'SavedSearch',
      fetchItems,
      clearView
    }),
    [clearView, fetchItems]
  );

  return savedSearchViewSetting;
}
