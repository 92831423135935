import React from 'react';
import type { PWFlatSet } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import { ProgressSummaryModal } from '../../../components/ProgressSummary';
import type { CloseModal, OpenModal } from '../../../hooks/useModal';
import type { ToastHandle } from '../../../services/pwToast';
import { openToast, replaceToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';
import type { AddFlatSetMemberProgressTracker } from '../utils';

export function notifyOnAddItems(
  numberOfItems: number,
  flatSet: PWFlatSet
): ToastHandle {
  const message =
    numberOfItems == 1
      ? t('FlatSet.AddItem.Notifications.InProgress', {
          name: flatSet.Name
        })
      : t('FlatSet.AddItem.Notifications.InProgressMulti', {
          name: flatSet.Name,
          count: numberOfItems
        });

  return openToast({ content: message, type: 'persisting', spinner: true });
}

export function on403Error(): string {
  return t('FlatSet.AddItem.Notifications.On403Error');
}

export function on400Error(item: DocumentLink, errorMessage?: string): string {
  const documentWithVersionName = item.version
    ? `${item.name};Version: ${item.version}`
    : item.name;
  if (errorMessage?.includes('Unique constraint violated')) {
    return t('FlatSet.AddItem.Notifications.AlreadyExists', {
      documentWithVersionName: documentWithVersionName
    });
  }

  if (
    errorMessage?.includes(
      'Failed to get object identifier by its GUID. Not found.'
    )
  ) {
    return t('FlatSet.AddItem.Notifications.ItemDeleted', {
      documentWithVersionName: documentWithVersionName
    });
  }
  return t('FlatSet.AddItem.Notifications.On400Error', {
    documentWithVersionName: documentWithVersionName
  });
}

export function notifyAddSuccess(
  numberOfItems: number,
  toastHandle: ToastHandle,
  flatSet: PWFlatSet
): void {
  const message =
    numberOfItems == 1
      ? t('FlatSet.AddItem.Notifications.Success', {
          name: flatSet.Name
        })
      : t('FlatSet.AddItem.Notifications.SuccessMulti', {
          name: flatSet.Name,
          count: numberOfItems
        });

  replaceToast(toastHandle, { content: message, category: 'positive' });
}

export function handleFailedResponses(
  progressTracker: AddFlatSetMemberProgressTracker,
  openModal: OpenModal,
  closeModal: CloseModal
): void {
  if (!progressTracker.failures.length) {
    return;
  }
  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={progressTracker.failures}
      title={t('FlatSet.AddItem.Notifications.ErrorMulti')}
    />
  );
}
