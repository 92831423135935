import { atom, selector } from 'recoil';
import type { PWDataItem } from '@bentley/pw-api';
import type { ToastHandle } from '../../../services/pwToast';
import { attributesFormDefinitionState } from './attributesStep';
import { docCodeFormDefinitionState } from './docCodeStep';

export const currentStepIndexState = atom<number>({
  key: 'currentStepIndexState',
  default: 0
});

export const showDocCodeStepState = selector<boolean>({
  key: 'showDocCodeStepState',
  get: ({ get }) => {
    const docCodeFormDefinition = get(docCodeFormDefinitionState);
    return docCodeFormDefinition != null;
  }
});

export const showAttributeStepState = selector<boolean>({
  key: 'showAttributeStepState',
  get: ({ get }) => {
    const attributeFormDefinition = get(attributesFormDefinitionState);
    return attributeFormDefinition != null;
  }
});

export const itemsToCopyState = atom<PWDataItem[] | undefined>({
  key: 'itemsToCopyState',
  default: undefined
});

export const isFlatSetCreationModeState = atom<boolean>({
  key: 'isFlatSetCreationModeState',
  default: false
});

export const isCopyModeState = atom<boolean>({
  key: 'isCopyModeState',
  default: false
});

export const uploadToastHandleState = atom<ToastHandle | undefined>({
  key: 'uploadToastHandle',
  default: undefined
});
