import { atom } from 'recoil';
import type { FormDefinition } from '../../../../actions/formRendering';

export const attributesFormValidState = atom<boolean>({
  key: 'attributesFormValidState',
  default: false
});

export const attributesFormDefinitionState = atom<
  FormDefinition | null | undefined
>({
  key: 'attributesFormDefinitionState',
  default: undefined
});
