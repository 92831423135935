import './errorPage.css';

import type { ReactNode } from 'react';
import React from 'react';
import {
  Svg401,
  Svg403,
  Svg404,
  Svg500,
  Svg502,
  Svg503,
  SvgError
} from '@itwin/itwinui-illustrations-react';
import { Button, NonIdealState } from '@itwin/itwinui-react';
import { t } from '../../services/translation';
import type { ErrorPageType } from './utils';

interface IErrorPageProps {
  errorType: ErrorPageType;
  errorName?: string;
  errorMsg?: ReactNode;
  fullscreen?: boolean;
  primaryButtonHandle?: () => void;
  primaryButtonLabel?: string;
  secondaryButtonHandle?: () => void;
  secondaryButtonLabel?: string;
}
export function ErrorPage({
  errorType,
  errorName,
  errorMsg,
  fullscreen = false,
  primaryButtonHandle,
  primaryButtonLabel,
  secondaryButtonHandle,
  secondaryButtonLabel
}: IErrorPageProps): JSX.Element {
  let errorIcon = <SvgError />;
  let headingMsg = errorName || t('Generic.Error');
  let infoMsg = errorMsg || t('ErrorPage.ErrorOccurred');
  let details;

  if (errorType == '401') {
    errorIcon = <Svg401 />;
    headingMsg = errorName || t('ErrorPage.Unauthorized');
    infoMsg = errorMsg || t('ErrorPage.NotAuthorized');
  } else if (errorType == '403') {
    errorIcon = <Svg403 />;
    headingMsg = errorName || t('ErrorPage.Unauthorized');
    infoMsg = errorMsg || t('ErrorPage.NotAuthorized');
  } else if (errorType == '404') {
    errorIcon = <Svg404 />;
    headingMsg = errorName || t('ErrorPage.PageNotFound');
    infoMsg = errorMsg || t('ErrorPage.CannotFindPWProject');
  } else if (errorType == '500') {
    errorIcon = <Svg500 />;
    headingMsg = errorName || t('ErrorPage.InternalError');
    infoMsg = t('ErrorPage.PleaseRefresh');
    details = errorMsg;
  } else if (errorType == '502') {
    errorIcon = <Svg502 />;
    headingMsg = errorName || t('ErrorPage.BadGateway');
    infoMsg = errorMsg || t('ErrorPage.PleaseTryAgain');
  } else if (errorType == '503') {
    errorIcon = <Svg503 />;
    headingMsg = errorName || t('ErrorPage.ServiceUnavailable');
    infoMsg = errorMsg || t('ErrorPage.ServiceUnavailableTryAgain');
  }

  const classes = fullscreen
    ? 'error-page error-page-fullscreen'
    : 'error-page';

  const primaryButton =
    primaryButtonHandle && primaryButtonLabel ? (
      <Button styleType="high-visibility" onClick={primaryButtonHandle}>
        {primaryButtonLabel}
      </Button>
    ) : null;
  const secondaryButton =
    secondaryButtonHandle && secondaryButtonLabel ? (
      <Button styleType="default" onClick={secondaryButtonHandle}>
        {secondaryButtonLabel}
      </Button>
    ) : null;

  return (
    <div className={classes}>
      <NonIdealState
        className="non-ideal-state"
        svg={errorIcon}
        heading={headingMsg}
        description={infoMsg}
        actions={
          <>
            {details && <pre>{details}</pre>}
            <div className="e-p-action-buttons">
              {primaryButton}
              {secondaryButton}
            </div>
          </>
        }
      />
    </div>
  );
}
