import React from 'react';
import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import { FileNameTruncationModal, newFile, truncateFileName } from '../upload';
import { replaceFileWorkflow } from './replace';
import ReplaceFileModal from './replaceFileModal';

export function replaceFileModal(
  isCannedConnection: boolean,
  openModal: OpenModal,
  closeModal: CloseModal,
  oldItem: PWDataItem,
  newFile: File,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onSuccess: () => void,
  progressManager: ProgressManager,
  differentFileNameAllowed?: boolean
): void {
  const modal = (
    <ReplaceFileModal
      isCannedConnection={isCannedConnection}
      closeModal={closeModal}
      openModal={openModal}
      oldItem={oldItem}
      newFile={newFile}
      httpService={httpService}
      trackFeature={trackFeature}
      onSuccess={onSuccess}
      progressManager={progressManager}
      differentFileNamesAllowed={differentFileNameAllowed}
    />
  );
  openModal(modal);
}

export function fileNameTruncationModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  file: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager?: ProgressManager,
  onSuccess?: (uploadedItems: PWItem[]) => void,
  nameIconLocked?: boolean
): void {
  const onYes = () => {
    const renamedFile = newFile(file, truncateFileName(file.name));

    void replaceFileWorkflow(
      renamedFile,
      oldItem,
      httpService,
      trackFeature,
      progressManager,
      undefined,
      onSuccess,
      openModal,
      closeModal,
      undefined,
      nameIconLocked
    );
  };

  const onNo = () => {
    const content = t('Replace.ReplaceCancelled');
    openToast({ content, category: 'warning' });
  };

  const modal = (
    <FileNameTruncationModal
      closeModal={closeModal}
      onNo={onNo}
      onYes={onYes}
      files={[file]}
      folders={[]}
      trackFeature={trackFeature}
    />
  );
  openModal(modal);
}
