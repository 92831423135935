import type { BlackListFilter } from '../../../hooks/useProperties';
import { blackListedSearchProperties } from '../../../services/search';

const advancedSearchBlacklistProperties = [
  'CreatedById',
  'Department',
  'StateString',
  'EnvironmentId',
  'FileOperationFailTime',
  'FileOperationFailedBy',
  'FileOperationFailedById',
  'FileRevision',
  'FileUpdatedById',
  'FinalById',
  'FinalBy',
  'HasConflicts',
  'IsCheckedOut',
  'IsLocked',
  'IsReadOnly',
  'IsWorkflowStateChangeable',
  'IsWriteable',
  'MimeType',
  'Node',
  'OutToId',
  'OwnerId',
  'Owner',
  'OwnerType',
  'ParentGuid',
  'RelativeDirectory',
  'StateId',
  'Storage',
  'UpdatedById',
  'WorkflowId'
] as const;

export const advancedSearchBlacklist: BlackListFilter[] = [
  ...Object.values(blackListedSearchProperties ?? []),
  ...advancedSearchBlacklistProperties.map(alwaysBlacklist)
] as const;

function alwaysBlacklist(propertyName: string): BlackListFilter {
  return { propertyName, filterOutCondition: () => true };
}
