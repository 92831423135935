import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import { Button } from '@itwin/itwinui-react';

export type PWModalButtonProps = Pick<
  ComponentPropsWithoutRef<typeof Button>,
  | 'className'
  | 'disabled'
  | 'endIcon'
  | 'endIconProps'
  | 'startIcon'
  | 'startIconProps'
  | 'style'
  | 'styleType'
> &
  Required<
    Pick<ComponentPropsWithoutRef<typeof Button>, 'onClick' | 'title'>
  > & { 'data-testid'?: string };

export function PWModalPrimaryButton(
  props: PWModalButtonProps
): JSX.Element | null {
  if (!Object.keys(props).length) {
    return null;
  }

  const { title, ...rest } = props;

  return (
    <Button styleType="high-visibility" autoFocus={true} {...rest}>
      {title}
    </Button>
  );
}

export function PWModalSecondaryButton(
  props: PWModalButtonProps
): JSX.Element | null {
  if (!Object.keys(props).length) {
    return null;
  }

  const { title, ...rest } = props;

  return <Button {...rest}>{title}</Button>;
}

export function PWModalTertiaryButton(
  props: PWModalButtonProps
): JSX.Element | null {
  if (!Object.keys(props).length) {
    return null;
  }

  const { title, ...rest } = props;

  return (
    <Button style={{ marginRight: 'auto', ...rest.style }} {...rest}>
      {title}
    </Button>
  );
}
