import { useEffect, useState } from 'react';
import type { RecoilState, RecoilValue, SetterOrUpdater } from 'recoil';
import { useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil';

export function useRecoilValueAsync<T>(
  recoilValue: RecoilValue<T>,
  initialState?: T
): T | undefined {
  const [currentState, setCurrentState] = useState<T | undefined>(initialState);
  const loadableState = useRecoilValueLoadable(recoilValue);
  useEffect(() => {
    if (loadableState.state == 'hasValue') {
      setCurrentState(loadableState.contents);
    }
    if (loadableState.state == 'hasError') {
      throw loadableState.errorOrThrow();
    }
  }, [loadableState]);

  return currentState;
}

export function useRecoilStateAsync<T>(
  recoilState: RecoilState<T>,
  initialState?: T
): [T | undefined, SetterOrUpdater<T>] {
  const [currentState, setCurrentState] = useState<T | undefined>(initialState);
  const [loadableState, setLoadableState] = useRecoilStateLoadable(recoilState);
  useEffect(() => {
    if (loadableState.state == 'hasValue') {
      setCurrentState(loadableState.contents);
    }
    if (loadableState.state == 'hasError') {
      throw loadableState.errorOrThrow();
    }
  }, [loadableState]);

  return [currentState, setLoadableState];
}
