import jQuery from 'jquery';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import type { IRendererElement } from '@bentley/formsrenderer/lib/form-renderer/components/RendererElement/RendererElement';
import type { IFormContainer } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormContainer';
import type { INameValuePair } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IUtilities';
import type {
  IWsgInstance,
  WsgClassType,
  WsgDataType
} from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';
import type { WSGInstance } from '@bentley/pw-api';
import type { FormDefinition } from '../../../actions/formRendering';
import { formDefinitionAsJQuery } from '../../../actions/formRendering';
import { useFeatureTracking } from '../../../context';
import { generatingDocCodeState } from '../docCode';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync,
  projectIdState,
  useRecoilStateAsync,
  useRecoilValueAsync
} from '../state';
import {
  checkFormValidity,
  formatChangedElement,
  formatMultiSelectOption,
  formatValue,
  highlightElement,
  turnOffAutoCompleteOnSelects
} from './format';
import { useFocusedElement } from './useFocusedElement';
import { useScriptedProperties } from './useScriptedProperties';
import { updatingTriggeredPropertiesState } from '.';

export type FormType = 'documentcode' | 'attributespage';

export function useDCWForm(
  formType: FormType,
  formDefinition: FormDefinition | null | undefined,
  setFormIsValid: (value: boolean) => void
): IFormContainer | undefined {
  const { launchDarklyFeatures } = useFeatureTracking();

  const defaultEnvironmentInstance = useRecoilValueAsync(
    defaultEnvironmentInstanceStateAsync
  );

  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilStateAsync(currentEnvironmentInstanceState);

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const generatingDocCode = useRecoilValue(generatingDocCodeState);

  const { addFocusListener } = useFocusedElement();

  const formInstance = useRef<WSGInstance | null>(
    currentEnvironmentInstance ?? null
  );

  const [formJQuery, setFormJQuery] = useState<IFormContainer>();

  const projectId = useRecoilValue(projectIdState);

  const { saveScriptedProperties, runScriptedProperties } =
    useScriptedProperties(formType, formJQuery);

  const onInstanceDataChange = useCallback(
    (
      changedData: Array<INameValuePair<WsgDataType>>,
      instance: IWsgInstance<WsgClassType>,
      formIsValid: boolean
    ): void => {
      setFormIsValid(formIsValid);
      setCurrentEnvironmentInstance(_.cloneDeep(instance) as WSGInstance);
      void runScriptedProperties(changedData, instance);
    },
    [runScriptedProperties, setCurrentEnvironmentInstance, setFormIsValid]
  );

  const onElementDataChange = useCallback(
    (
      changedData: Array<INameValuePair<WsgDataType>>,
      dataClassInstanceId: string,
      element: IRendererElement,
      event: JQueryEventObject,
      formIsValid: boolean
    ): void => {
      formatChangedElement(
        changedData,
        element,
        defaultEnvironmentInstance,
        formType,
        setFormIsValid
      );
    },
    [formType, defaultEnvironmentInstance, setFormIsValid]
  );

  const onElementRenderComplete = useCallback(
    (element: IRendererElement, $element: JQuery, binding: string): void => {
      if (!binding) {
        return;
      }

      turnOffAutoCompleteOnSelects(element, $element);
      formatMultiSelectOption(element);
      formatValue(element, element.getValueFromControl());

      highlightElement(
        element,
        binding,
        formInstance.current,
        defaultEnvironmentInstance
      );

      saveScriptedProperties(element);
      addFocusListener(element, $element);
    },
    [addFocusListener, defaultEnvironmentInstance, saveScriptedProperties]
  );

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access --
     * Sets jQuery to be available globally, as the forms renderer is not handling it nicely
     * Maybe their 2.0 version will fix this
     * We can also use webpack.ProvidePlugin to do this, but this removes the responsibility from the consumers
     */
    (window as any).$ = jQuery;
  }, []);

  useEffect(() => {
    if (currentEnvironmentInstance) {
      formInstance.current = currentEnvironmentInstance;
    }
  }, [currentEnvironmentInstance]);

  useEffect(() => {
    function getFormJQuery(): IFormContainer | undefined {
      const isReadOnly = false;
      const formDefString = formDefinition?.Definition;

      if (!formDefString || !formInstance.current) {
        return undefined;
      }

      const formJQuery = formDefinitionAsJQuery(
        formDefString,
        _.cloneDeep(formInstance.current),
        {
          projectId,
          readonly: isReadOnly,
          onInstanceDataChange,
          onElementDataChange,
          onElementRenderComplete
        },
        launchDarklyFeatures.disableFormDateTimeLocalization as boolean
      );

      return formJQuery;
    }

    if (!updatingTriggeredProperties && !generatingDocCode) {
      const formJQuery = getFormJQuery();
      setFormJQuery(formJQuery);
    }
  }, [
    projectId,
    formDefinition,
    onElementDataChange,
    onElementRenderComplete,
    onInstanceDataChange,
    updatingTriggeredProperties,
    generatingDocCode,
    launchDarklyFeatures.disableFormDateTimeLocalization
  ]);

  useEffect(() => {
    void checkFormValidity(formType, setFormIsValid);
  }, [formType, generatingDocCode, setFormIsValid, formDefinition]);

  return formJQuery;
}
