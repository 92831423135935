import './iModelUnmappingModal.css';

import React, { useEffect, useState } from 'react';
import { Checkbox, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { PWTooltip } from '../../components/pwTooltip';
import {
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';
import type { IModel } from './iModel';
import type { IModelBridge } from './iModelBridge';
import { notifyUnmappingCompleted } from './notifications';

type UnmapSavedSearchIModelModalProps = {
  onComplete?: () => void;
};

export function UnmapSavedSearchModal({
  onComplete
}: UnmapSavedSearchIModelModalProps): ModalElement {
  const { iModelMappingManager, connection } = usePluginContext();
  const { iModels } = useEnvironmentContext();
  const { primaryModal, searchState } = useNavigationContext();

  const [savedSearchIModelBridges, setSavedSearchIModelBridges] = useState<
    IModelBridge[]
  >([]);
  const [checkedMappings, setCheckedMappings] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );
  const [allMappingsChecked, setAllMappingsChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSpatialRootExists, setIsSpatialRootExists] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    async function getIModelBridgesForSavedSearch() {
      const iModelBridges =
        await iModelMappingManager.getIModelBridgesForDocument(
          searchState.savedSearch?.instanceId ?? '',
          undefined,
          connection.ProjectId
        );
      setSavedSearchIModelBridges(iModelBridges);

      iModelBridges.map((bridge) => {
        setCheckedMappings(checkedMappings.set(bridge.instanceId, false));
      });

      setIsSpatialRootExists(
        iModelBridges.some(
          (bridge) =>
            bridge.properties && bridge.properties.IsSpatialRoot == 'true'
        )
      );
      setIsLoading(false);
    }

    void getIModelBridgesForSavedSearch();
  }, [
    checkedMappings,
    connection.ProjectId,
    iModelMappingManager,
    searchState.savedSearch?.instanceId
  ]);

  function matchingIModel(
    iModels: IModel[],
    iModelBridge: IModelBridge
  ): IModel | undefined {
    return iModels.find(
      (iModel) => iModel.instanceId == iModelBridge.properties?.iModelId
    );
  }

  function disableSelectAll() {
    return !savedSearchIModelBridges.some(
      (mapping) => mapping.properties?.IsSpatialRoot == 'false'
    );
  }

  function isSelectAllMappingChecked() {
    const nonDisabledMappings = savedSearchIModelBridges.filter(
      (bridge) => bridge.properties?.IsSpatialRoot == 'false'
    );
    nonDisabledMappings.some(
      (mapping) =>
        checkedMappings.get(mapping.instanceId) == false ||
        checkedMappings.get(mapping.instanceId) == undefined
    )
      ? setAllMappingsChecked(false)
      : setAllMappingsChecked(true);
  }

  function onIModelMappingChecked(bridge: IModelBridge) {
    if (bridge.properties?.IsSpatialRoot == 'false') {
      setCheckedMappings(
        checkedMappings.set(
          bridge.instanceId,
          !checkedMappings.get(bridge.instanceId)
        )
      );
      isSelectAllMappingChecked();
      setSavedSearchIModelBridges([...savedSearchIModelBridges]);
    }
  }

  function unmapButtonDisabled(): boolean | undefined {
    return !Array.from(checkedMappings.values()).some((item) => item == true);
  }

  function onSelectAllMappingsChecked() {
    if (!disableSelectAll()) {
      const checked = !allMappingsChecked;
      setAllMappingsChecked(checked);
      const nonDisabledMappings = savedSearchIModelBridges.filter(
        (bridge) => bridge.properties?.IsSpatialRoot == 'false'
      );
      nonDisabledMappings.map((mapping) =>
        setCheckedMappings(checkedMappings.set(mapping.instanceId, checked))
      );
    }
  }

  async function performUnMapping() {
    const responses = await Promise.all(
      Array.from(checkedMappings.keys())
        .filter((key) => checkedMappings.get(key) == true)
        .map(async (mapping) => {
          return await iModelMappingManager.deleteIModelBridge(mapping);
        })
    );
    primaryModal.close();
    notifyUnmappingCompleted(
      responses.filter((response) => response).length,
      true
    );
    if (onComplete) {
      onComplete();
    }
  }

  return (
    <PWModal
      title={t('MenuItem.UnmapSearchIModel')}
      className="u-i-modal-content"
      isLoading={isLoading}
      primaryButton={{
        title: t('IModel.Unmap'),
        onClick: () => void performUnMapping(),
        disabled: unmapButtonDisabled(),
        'data-testid': 'unmap-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'UnmapIModelModal' }}
    >
      {savedSearchIModelBridges.length != 0 ? (
        <Text>{t('IModel.SelectSearchToUnmap')}</Text>
      ) : (
        <Text>{t('IModel.NoSavedSearchMappingsAvailable')}</Text>
      )}
      {isSpatialRootExists && (
        <Text isMuted={true}>{t('IModel.SpatialRootRequired')}</Text>
      )}
      <div className={'tree-container-unmap'} data-testid={'unmap-tree'}>
        <div className={'tree-header-unmap'}>
          {savedSearchIModelBridges.length != 0 && (
            <div
              className="selectall-hover"
              onClick={onSelectAllMappingsChecked}
              role="button"
              tabIndex={0}
              onKeyDown={keyPressAsClick(onSelectAllMappingsChecked)}
              data-testid={'select-all-click'}
            >
              <Checkbox
                label={t('IModel.SelectAllMappings')}
                checked={allMappingsChecked}
                onChange={() => null}
                disabled={disableSelectAll()}
                data-testid={'isSelectAllChecked'}
              />
            </div>
          )}
          <div>
            <ul>
              {savedSearchIModelBridges.map(
                (bridge) =>
                  matchingIModel(iModels, bridge)?.properties?.Name && (
                    <li className={'li-margin'} key={`ul-${bridge.instanceId}`}>
                      <PWTooltip
                        content={
                          bridge.properties?.IsSpatialRoot == 'true'
                            ? t('IModel.SpatialRootCannotUnmap')
                            : bridge.properties?.IsMaster == 'true'
                            ? t('IModel.MasterRoot')
                            : t('IModel.SheetRoot')
                        }
                      >
                        <div
                          className="select-imodelmapping"
                          onClick={onIModelMappingChecked.bind({}, bridge)}
                          onKeyDown={keyPressAsClick(
                            onIModelMappingChecked.bind({}, bridge)
                          )}
                          role={'button'}
                          tabIndex={0}
                        >
                          <Checkbox
                            label={String(
                              matchingIModel(iModels, bridge)?.properties?.Name
                            )}
                            name={bridge.instanceId}
                            checked={checkedMappings.get(bridge.instanceId)}
                            onChange={() =>
                              !checkedMappings.get(bridge.instanceId)
                            }
                            disabled={
                              bridge.properties?.IsSpatialRoot == 'true'
                            }
                            data-testid={`isImodelChecked${String(
                              matchingIModel(iModels, bridge)?.properties?.Name
                            )}`}
                          />
                        </div>
                      </PWTooltip>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </PWModal>
  );
}
