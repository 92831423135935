import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHttpService, usePluginContext } from '../../context';
import type { User } from './data';
import { getCurrentUserId, getUsers } from './data';

export type UserManager = {
  currentUserId?: string;
  users: MutableRefObject<User[] | undefined>;
};

export function useUsers(): UserManager {
  const { connection, dataAccessLevel } = usePluginContext();
  const httpService = useHttpService();

  const users = useRef<User[]>();
  const [currentUserId, setCurrentUserId] = useState<string>();

  useEffect(() => {
    const abortController = new AbortController();

    async function updateUsers(): Promise<void> {
      if (dataAccessLevel == 'WorkArea' && !connection.ProjectId) {
        return;
      }

      try {
        const fetchedUsers = await getUsers(connection.ProjectId, httpService, {
          abortController
        });
        fetchedUsers.sort((a, b) =>
          a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase() ? 1 : -1
        );

        if (!abortController.signal.aborted) {
          users.current = fetchedUsers;
        }
      } catch {
        users.current = [];
      }
    }

    setTimeout(() => {
      if (!abortController.signal.aborted) {
        void updateUsers();
      }
    }, 5000);

    return () => {
      abortController.abort();
    };
  }, [connection.ProjectId, dataAccessLevel, httpService]);

  useEffect(() => {
    const abortController = new AbortController();

    async function updateCurrentUserId(): Promise<void> {
      try {
        const userId = await getCurrentUserId(httpService, { abortController });
        if (!abortController.signal.aborted) {
          setCurrentUserId(userId);
        }
      } catch {
        setCurrentUserId(undefined);
      }
    }

    void updateCurrentUserId();

    return () => {
      abortController.abort();
    };
  }, [httpService]);

  const userManager = useMemo(
    (): UserManager => ({
      currentUserId,
      users
    }),
    [currentUserId, users]
  );

  return userManager;
}
