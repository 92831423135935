import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import { useModalOutput } from '../../hooks/useModalOutput';
import type {
  ConfirmRestoreToFolderModalOutput,
  ConfirmRestoreToFolderModalProps
} from './confirmRestoreToFolderModal';
import { ConfirmRestoreToFolderModal } from './confirmRestoreToFolderModal';
import { notifyRestoreFailed, notifyRestoreNotAllowed } from './notifications';
import { allowRestoreItem } from './requirements';
import { restoreItemToFolder } from './restore';
import { useOnRestoreComplete } from './useOnRestoreComplete';

type RestoreToFolderWorkflow = (items: PWItem[]) => Promise<void>;

export function useRestoreToFolder(): RestoreToFolderWorkflow {
  const httpService = useHttpService();

  const confirmRestoreToFolder = useModalOutput<
    ConfirmRestoreToFolderModalOutput,
    ConfirmRestoreToFolderModalProps
  >(ConfirmRestoreToFolderModal);

  const { onRestoreToFolderComplete } = useOnRestoreComplete();

  const restoreItemsToFolderWorkflow = useCallback(
    async (items: PWItem[]): Promise<void> => {
      if (!items.every(allowRestoreItem)) {
        notifyRestoreNotAllowed();
        return;
      }

      const { folderId } = await confirmRestoreToFolder({});
      if (!folderId) {
        return;
      }

      try {
        await Promise.all(
          items.map((item) => restoreItemToFolder(item, folderId, httpService))
        );
        onRestoreToFolderComplete(items, folderId);
      } catch {
        notifyRestoreFailed(items);
      }
    },
    [confirmRestoreToFolder, httpService, onRestoreToFolderComplete]
  );

  return restoreItemsToFolderWorkflow;
}
