import React, { useRef, useState } from 'react';
import { SvgFind } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import {
  useAppViewContext,
  useFolderExplorerContext,
  useNavigationContext
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import type { SavedSearchDefinition } from '../../../services/search';
import { Chevron } from '../chevron/chevron';
import { SavedSearchNodeContextMenu } from './savedSearchNodeContextMenu';

type SavedSearchNodeProps = {
  savedSearch: SavedSearchDefinition;
  savedSearches: SavedSearchDefinition[];
};

export function SavedSearchNode({
  savedSearch,
  savedSearches
}: SavedSearchNodeProps): JSX.Element {
  const { selectedIds } = useFolderExplorerContext();
  const { searchState } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  const [isExpanded, setIsExpanded] = useState<boolean>(containsActiveSearch);

  const nodeRef = useRef<HTMLDivElement>(null);

  const subSearches = savedSearches
    .filter(
      (search) => search.ParentSavedSearchId == Number(savedSearch.instanceId)
    )
    .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1));

  function containsActiveSearch(): boolean {
    const activeSearch = savedSearches.find(
      (search) => search.instanceId == searchState.savedSearch?.instanceId
    );
    if (!activeSearch) {
      return false;
    }

    return parentContainsSearch(activeSearch);
  }

  function parentContainsSearch(search: SavedSearchDefinition): boolean {
    const parentSearch = savedSearches.find(
      (savedSearch) =>
        Number(savedSearch.instanceId) == search.ParentSavedSearchId
    );
    if (!parentSearch) {
      return false;
    }

    if (parentSearch.instanceId == savedSearch.instanceId) {
      return true;
    }

    if (Number(parentSearch.instanceId) == 0) {
      return false;
    }

    return parentContainsSearch(parentSearch);
  }

  function className(): string {
    let className = 'tree-node';

    if (
      currentAppView.type == 'SavedSearch' &&
      searchState.savedSearch?.instanceId == savedSearch.instanceId
    ) {
      className += ' active-node';
    }

    if (selectedIds.includes(savedSearch.instanceId)) {
      className += ' focused-node';
    }

    return className;
  }

  function onSearchClick(): void {
    setIsExpanded((cur) => !cur);
  }

  function onContextMenu(e: React.MouseEvent): void {
    e.preventDefault();
    if (!nodeRef.current) {
      return;
    }
    const contextMenu = nodeRef.current.querySelector(
      'button.context-menu-button'
    ) as HTMLElement;
    contextMenu.click();
  }

  function onSearchLinkClick(e: React.MouseEvent): void {
    e.stopPropagation();
    searchState.setSavedSearch(savedSearch);
    setAppView('SavedSearch');
  }

  return (
    <>
      <div
        className={className()}
        onClick={onSearchClick}
        onKeyPress={keyPressAsClick(onSearchClick)}
        onContextMenu={onContextMenu}
        role="treeitem"
        tabIndex={0}
        data-testid={`${savedSearch.Name}-tree-node`}
        ref={nodeRef}
      >
        <Chevron hasChildren={subSearches.length > 0} isExpanded={isExpanded} />
        <SvgFind
          className="pw-icon-xs explorer-icon"
          data-testid="saved-search-filetype"
        />
        <Text
          as="span"
          className="node-label"
          onClick={onSearchLinkClick}
          onKeyPress={keyPressAsClick(onSearchLinkClick)}
          role="link"
          tabIndex={0}
          data-testid={`${savedSearch.Name}-node-label`}
        >
          {savedSearch.Name || '                                            '}
        </Text>
        <SavedSearchNodeContextMenu search={savedSearch} />
      </div>
      {isExpanded && subSearches.length > 0 && (
        <div
          className="tree-children"
          data-testid={`${savedSearch.Name}-tree-children`}
        >
          {subSearches.map((search) => (
            <SavedSearchNode
              key={search.instanceId}
              savedSearch={search}
              savedSearches={savedSearches}
            />
          ))}
        </div>
      )}
    </>
  );
}
