import type { PWItem } from '@bentley/pw-api';
import {
  itemIsFileType,
  itemIsLockedOrCheckedOut,
  itemIsParentType,
  itemIsReadOnly
} from '@bentley/pw-api';
import type { IModelBridge } from '../imodelMapping';
import { associatedIModelBridges, isMappableFile } from '../imodelMapping';
import { canCheckInLockedFile } from '../sync';

/**
 * Returns whether Replace should be hidden
 * @param {PWItem[]} items Items to be replace
 * @returns {boolean} True if Replace should be hidden, false if shown
 */
export function hideReplaceButton(items: PWItem[]): boolean {
  return (
    !items.length || canCheckInLockedFile(items) || items.some(itemIsParentType)
  );
}

export function allowReplace(
  items: PWItem[],
  iModelBridges: IModelBridge[],
  allItemsExistInConnection?: (items: PWItem[]) => boolean,
  isCannedConnection?: boolean
): boolean {
  if (!items || items.length != 1) {
    return false;
  }

  const item = items[0];
  if (!itemIsFileType(item)) {
    return false;
  }

  if (associatedIModelBridges(item, iModelBridges).length) {
    return false;
  }

  if (itemIsReadOnly(item)) {
    return false;
  }

  if (itemIsLockedOrCheckedOut(item)) {
    return false;
  }

  if (
    isCannedConnection &&
    isMappableFile(item) &&
    !associatedIModelBridges(item, iModelBridges).length
  ) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }
  return true;
}
