import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import type { AuthorizationService } from '@bentley/pw-api';
import { signInFailureToast } from '../../../actions/authorization';
import { ServerErrorComponent } from '../../../components/serverErrorComponent';
import { SignIn } from '../../../components/signIn';
import type { UserCredentials } from '../../../hooks/useAuthorizationService';
import {
  clearCachedSessionCreds,
  useAuthorizationService
} from '../../../hooks/useAuthorizationService';
import { useErrorLogging } from '../../../hooks/useErrorLogging';
import type { RBACPermission } from '../../../hooks/useRbac';
import { useRbac } from '../../../hooks/useRbac';
import { isAdmin as userIsAdmin } from '../../../services/permissions';
import { useConnectionContext } from '../connection/connectionContext';
import { useConnectionsContext } from '../connections';
import { useFeatureTracking } from '../featureTracking/featureTrackingContext';
import type { ConsumerApp, DataAccessLevel } from '../plugin';
import { useToken } from '../token/tokenContext';

export type AuthenticationStatus =
  | 'authenticated'
  | 'notAuthenticated'
  | 'checking'
  | 'unset';

type ConnectionAuthContext = {
  authorizationService?: AuthorizationService;
  basicCredentials?: UserCredentials;
  connectionAuthenticated: AuthenticationStatus;
  error: boolean;
  errorComponent: JSX.Element;
  isAdmin: boolean;
  isLoading: boolean;
  isLogicalUser: boolean;
  rbacPermissions: RBACPermission[];
  signInComponent: JSX.Element;
  user: User;
  userId: string;
  userName: string;
  signOut: () => void;
};

type ConnectionAuthProps = {
  buddiRegionCode: string;
  contextId?: string;
  consumerApp?: ConsumerApp;
  dataAccessLevel?: DataAccessLevel;
  user: User;
};

const Context = createContext<ConnectionAuthContext | undefined>(undefined);

function ConnectionAuthProvider({
  value: { buddiRegionCode, contextId, consumerApp, dataAccessLevel, user },
  ...props
}: ProviderProps<ConnectionAuthProps>): JSX.Element {
  const { connectionError } = useConnectionsContext();
  const { connection, setConnectionExplorerAuthenticated } =
    useConnectionContext();

  const { getOidcToken } = useToken();
  const { trackFeature } = useFeatureTracking();

  const rbacPermissions = useRbac(contextId, buddiRegionCode, getOidcToken);
  const { getAuthorizationService, getSessionCreds } =
    useAuthorizationService(consumerApp);
  const logError = useErrorLogging({
    buddiRegionCode,
    connection,
    consumerApp,
    contextId,
    dataAccessLevel
  });

  const [connectionAuthenticated, setConnectionAuthenticated] =
    useState<AuthenticationStatus>('checking');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [attemptIms, setAttemptIms] = useState<boolean>(true);

  const [logicalUserName, setLogicalUserName] = useState<string>();
  const [basicCredentials, setBasicCredentials] = useState<UserCredentials>();
  const [authorizationService, setAuthorizationService] =
    useState<AuthorizationService>();

  const onSignInSuccess = useCallback(
    (
      authorizationService: AuthorizationService,
      abortController?: AbortController
    ) => {
      if (abortController?.signal.aborted) {
        return;
      }

      const userCredentials = getSessionCreds();

      setAuthorizationService(authorizationService);
      setLogicalUserName(userCredentials?.userName);
      setBasicCredentials(userCredentials ?? undefined);
      setConnectionExplorerAuthenticated?.(true);
      setConnectionAuthenticated('authenticated');

      trackFeature(
        authorizationService.authorizationType == 'user credentials'
          ? 'SIGN_IN_LOGICAL_SUCCESS'
          : 'SIGN_IN_FEDERATED_SUCCESS'
      );
    },

    [getSessionCreds, setConnectionExplorerAuthenticated, trackFeature]
  );

  const onSignInFailure = useCallback(
    (
      userCredentials?: UserCredentials,
      abortController?: AbortController,
      error?: Error
    ): void => {
      if (abortController?.signal.aborted) {
        return;
      }

      void logError('Connection sign in error', {
        error,
        userName: userCredentials?.userName
      });
      setAuthorizationService(undefined);
      setLogicalUserName(undefined);
      setConnectionExplorerAuthenticated?.(false);
      setConnectionAuthenticated('notAuthenticated');

      const usingIms = !userCredentials;
      const tooManyAttempts = error?.message?.includes(
        'Too many sign in attempts'
      );
      signInFailureToast(usingIms, tooManyAttempts);

      if (error && !tooManyAttempts) {
        setServerError(true);
      }

      setAttemptIms(false);

      trackFeature(
        userCredentials
          ? 'SIGN_IN_LOGICAL_FAILURE'
          : 'SIGN_IN_FEDERATED_FAILURE'
      );
    },
    [setConnectionExplorerAuthenticated, trackFeature, logError]
  );

  const signInWorkflow = useCallback(
    async (
      userCredentials?: UserCredentials,
      abortController?: AbortController
    ): Promise<void> => {
      setConnectionAuthenticated('checking');
      setConnectionExplorerAuthenticated?.(false);
      setAuthorizationService(undefined);
      setBasicCredentials(undefined);
      setServerError(false);
      setIsLoading(true);

      try {
        const authorizationService = await getAuthorizationService(
          userCredentials,
          abortController
        );

        if (!authorizationService) {
          void onSignInFailure(userCredentials, abortController);
          return;
        }

        onSignInSuccess(authorizationService, abortController);
      } catch (error) {
        void onSignInFailure(userCredentials, abortController, error as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      setConnectionExplorerAuthenticated,
      getAuthorizationService,
      onSignInFailure,
      onSignInSuccess
    ]
  );

  const signOutWorkflow = useCallback(() => {
    clearCachedSessionCreds(connection.Id, consumerApp);

    setAttemptIms(!logicalUserName);
    setConnectionAuthenticated('unset');
    setConnectionExplorerAuthenticated?.(false);
    setAuthorizationService(undefined);
    setServerError(false);

    trackFeature('SIGN_OUT');
  }, [
    logicalUserName,
    connection.Id,
    consumerApp,
    setConnectionExplorerAuthenticated,
    trackFeature
  ]);

  const errorComponent = useMemo(
    (): JSX.Element => (
      <ServerErrorComponent
        onRetry={() => setServerError(false)}
        consumerApp={consumerApp}
      />
    ),
    [consumerApp]
  );
  const signInComponent = useMemo(
    (): JSX.Element => (
      <SignIn
        connectionName={connection.Name}
        initWIms={attemptIms}
        onSubmit={(useIms, userName, password) => {
          void signInWorkflow(useIms ? undefined : { userName, password });
        }}
      />
    ),
    [connection.Name, attemptIms, signInWorkflow]
  );

  const userName = useMemo(() => {
    return logicalUserName ?? user?.profile.email ?? '';
  }, [logicalUserName, user?.profile.email]);

  const isAdmin = useMemo(() => {
    return userIsAdmin(user);
  }, [user]);

  // Switch connection
  useEffect(() => {
    const abortController = new AbortController();

    if (connection.Id && connection.ConnectionUrl && !connectionError) {
      setAttemptIms(true);
      void signInWorkflow(undefined, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [
    signInWorkflow,
    connection.Id,
    connection.ConnectionUrl,
    connectionError
  ]);

  const connectionAuthContext: ConnectionAuthContext = useMemo(
    () => ({
      authorizationService,
      basicCredentials,
      connectionAuthenticated,
      error: serverError,
      errorComponent,
      isAdmin,
      isLogicalUser:
        authorizationService?.authorizationType == 'user credentials',
      isLoading,
      rbacPermissions,
      signInComponent,
      user,
      userId: user?.profile.sub,
      userName,
      signOut: signOutWorkflow
    }),
    [
      authorizationService,
      basicCredentials,
      connectionAuthenticated,
      errorComponent,
      isAdmin,
      isLoading,
      rbacPermissions,
      serverError,
      signInComponent,
      signOutWorkflow,
      user,
      userName
    ]
  );

  return <Context.Provider value={connectionAuthContext} {...props} />;
}

function useConnectionAuth(): ConnectionAuthContext {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(
      `useConnectionAuth must be used within a ConnectionAuthProvider`
    );
  }
  return context;
}

export { ConnectionAuthProvider, useConnectionAuth };
