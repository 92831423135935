import type {
  HttpService,
  RequestOptions,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  flattenProperties,
  parseResponseInstances,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';

export type User = {
  Name: string;
} & WSGInstance;

export async function getCurrentUserId(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<string | undefined> {
  try {
    const url = 'PW_WSG/User?$filter=IsCurrent eq true&$select=$id';
    const response = await httpService.get(url, httpOptions);

    if (!response.ok) {
      return undefined;
    }

    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
    const data = (await response.json()) as WSGInstancesResponse;
    return data.instances[0].instanceId;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export async function getUsers(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<User[]> {
  if (!projectId) {
    return getDatasourceUsers(httpService, httpOptions);
  }

  const userCollections = await getWorkAreaUserCollectionIds(
    projectId,
    httpService
  );
  if (userCollections.includes('Everyone')) {
    return getDatasourceUsers(httpService, httpOptions);
  }

  let usersFromCollections = [] as User[];
  if (userCollections.length) {
    usersFromCollections = await getUserCollectionUsers(
      userCollections,
      httpService,
      httpOptions
    );
  }

  const workAreaUsers = await getWorkAreaUsers(
    projectId,
    httpService,
    httpOptions
  );

  const uniqueWorkAreaUsers = workAreaUsers.filter(
    (user) =>
      !usersFromCollections.find(
        (collectionUser) => collectionUser.Name === user.Name
      )
  );

  return [...usersFromCollections, ...uniqueWorkAreaUsers];
}

async function getDatasourceUsers(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<User[]> {
  try {
    const url = 'PW_WSG/User?$select=Name';
    const response = await httpService.get(url, {
      cacheSuffix: 'persistent',
      ...httpOptions
    });

    if (!response.ok) {
      return [];
    }

    const users = await parseResponseInstances<User>(response);
    return users;
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function getWorkAreaUsers(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<User[]> {
  try {
    const url = `PW_WSG/Project/${projectId}?$select=ProjectParticipant.Role,ProjectParticipant-forward-User.Name`;
    const response = await httpService.get(url, {
      cacheSuffix: 'persistent',
      ...httpOptions
    });

    if (!response.ok) {
      return [];
    }

    const data = (await response.json()) as WSGInstancesResponse;
    const participants =
      data.instances[0].relationshipInstances?.filter(
        (instance) => instance.properties?.Role != 'NoAccess'
      ) ?? [];
    const users = participants
      .map((instance) => instance?.relatedInstance)
      .filter((instance) => instance)
      .map(flattenProperties) as User[];

    return users;
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function getWorkAreaUserCollectionIds(
  projectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<string[]> {
  try {
    const url = `PW_WSG/Project/${projectId}?$select=ProjectParticipant-forward-UserCollection.$id`;
    const response = await httpService.get(url, {
      cacheSuffix: 'persistent',
      ...httpOptions
    });

    if (!response.ok) {
      return [];
    }

    const userCollections = await parseResponseRelationshipInstances(response);
    return userCollections.map((collection) => collection.instanceId);
  } catch (e) {
    console.error(e);
    return [];
  }
}

async function getUserCollectionUsers(
  userCollectionIds: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<User[]> {
  try {
    const ids = userCollectionIds.filter((id) => id != 'Everyone');
    const filter = `$id in ['${ids.join("','")}']`;
    const url = `PW_WSG/UserCollection?$filter=${filter}&$select=Member-forward-User.Name`;

    const response = await httpService.get(url, {
      cacheSuffix: 'persistent',
      ...httpOptions
    });

    if (!response.ok) {
      return [];
    }

    const users = await parseResponseRelationshipInstances<User>(response);
    return users;
  } catch (e) {
    console.error(e);
    return [];
  }
}
