import React, { useMemo } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import {
  openFolderSelectFileDrive,
  showOpenWindowsExplorer
} from '../../../../actions/drive';
import { useDriveRequirements } from '../../../../actions/sync';
import {
  useAppViewContext,
  useConnectionAuth,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgWindowsExplorer } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useGoToProjectWiseDriveItemAction(
  items: PWItem[]
): MenuItemAction {
  const { userId } = useConnectionAuth();
  const {
    pwDriveData: {
      httpDriveService,
      pwDriveSyncDisabled,
      driveVersionType,
      isCurrentConnectionSynced,
      validDriveUser
    },
    contextId,
    readOnly
  } = usePluginContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const allowGoToDrive = useDriveRequirements();

  const item = items[0];

  const goToProjectWiseDrive = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.GoToDrive'),
      icon: <SvgWindowsExplorer />,
      onClick: () =>
        void openFolderSelectFileDrive(
          item as PWDataItem,
          userId,
          httpDriveService,
          contextId ?? ''
        ),
      disabled: !allowGoToDrive(items),
      hidden:
        !showOpenWindowsExplorer(
          readOnly,
          items,
          !pwDriveSyncDisabled,
          driveVersionType,
          isCurrentConnectionSynced,
          validDriveUser
        ) || currentAppView.type == 'RecycleBin'
    }),
    [
      items,
      allowGoToDrive,
      pwDriveSyncDisabled,
      readOnly,
      driveVersionType,
      isCurrentConnectionSynced,
      validDriveUser,
      currentAppView.type,
      item,
      userId,
      httpDriveService,
      contextId
    ]
  );

  return goToProjectWiseDrive;
}
