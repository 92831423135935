import { useMemo } from 'react';
import type { ProductSettingType } from '../../useProductSetting';
import { useProductSetting } from '../../useProductSetting';
import type { View } from '../useViews';

export type PRDSSViewManager = {
  views: View[] | undefined;
  setViews: (views: View[]) => void;
};

export function usePRDSSViews(
  key: string,
  settingType: ProductSettingType
): PRDSSViewManager {
  const [views, setViews] = useProductSetting<View[]>(key, {
    defaultValue: [],
    settingType
  });

  const prdssViewManager = useMemo(
    (): PRDSSViewManager => ({ views, setViews }),
    [setViews, views]
  );

  return prdssViewManager;
}
