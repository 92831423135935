import '../savedAdvancedSearches/AdvancedSavedSearchesModal.css';
import './myViews.css';

import React from 'react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext, usePropertyContext } from '../../context';
import { useModal } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { CustomColumnDialog } from '../customColumn';
import { ViewsTable } from './table/viewsTable';

export function MyViewsModal(): JSX.Element {
  const { viewManager } = usePropertyContext();
  const { primaryModal } = useNavigationContext();

  const [viewModal, openViewModal] = useModal();

  if (viewModal) {
    return viewModal;
  }

  function configureColumnsClick(): void {
    openViewModal(
      <CustomColumnDialog
        initialView={viewManager.currentView}
        onClose={primaryModal.close}
      />
    );
  }

  return (
    <PWModal
      title={t('MyViews.ManageMyViews')}
      className="my-views-modal"
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: primaryModal.close
      }}
      tertiaryButton={{
        title: t('ViewManager.NewView'),
        onClick: configureColumnsClick
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'MyViews' }}
    >
      <ViewsTable />
    </PWModal>
  );
}
