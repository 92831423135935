import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject
} from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { notifyMoveComplete, notifyMoveInProgress } from './notifications';
import type { Outcome } from './outcomes';
import { handleFailedMoves, handleSuccessfulMoves } from './outcomes';
import { newProgressTracker, updateProgressTracker } from './progressTracker';
import { allowMove } from './requirements';
import { getItemInstance } from './utils';

export type OnComplete = (
  movedItems: PWItem[],
  destinationParent: PWParentType,
  sourceParent: PWParentType
) => Promise<void>;

/**
 * Moves items into the selected target project
 * @param {PWProject} destinationParent The project into which items will be moved
 * @param {PWItem[]} items The items to be moved
 * @param {PWParentType} sourceParent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @param {HttpService} httpService Configured http service
 * @param {OpenModal} openModal Handles error modal
 * @param {CloseModal} closeModal Handles error modal
 * @param {TrackFeature} trackFeature Feature tracking callback
 * @param {(movedItems: PWItem[], destinationParent: PWParentType) => any} [onComplete] Workflow complete callback
 * @returns {Promise<Response[]>} Http response of move request
 */
export async function moveItemsWorkflow(
  destinationParent: PWProject,
  items: PWItem[],
  sourceParent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  onComplete?: OnComplete
): Promise<Response[]> {
  trackFeature('MOVE_ITEMS');

  const toastHandle = notifyMoveInProgress(items.length);
  const progressTracker = newProgressTracker(items);

  const outcomes = await Promise.all(
    items.map(async (item) => {
      const response = await moveItem(
        destinationParent,
        item,
        sourceParent,
        appView,
        readOnly,
        httpService
      );
      updateProgressTracker(progressTracker, response);
      return { response, item } as Outcome;
    })
  );

  const conflicts = outcomes.filter(
    (outcome) => outcome.response.status == 409
  );
  const errors = outcomes.filter(
    (outcome) =>
      !outcome.response.ok && ![200, 201, 409].includes(outcome.response.status)
  );

  if (!conflicts.length || errors.length) {
    notifyMoveComplete(progressTracker, toastHandle);
  } else {
    toastHandle.close();
  }
  handleFailedMoves(
    outcomes,
    openModal,
    closeModal,
    destinationParent,
    sourceParent,
    httpService,
    trackFeature,
    sourceParent,
    appView,
    readOnly,
    onComplete
  );
  await handleSuccessfulMoves(
    outcomes,
    destinationParent,
    sourceParent,
    onComplete
  );

  return outcomes.map((outcome) => outcome.response);
}

/**
 * Moves item into the selected target project
 * @param {PWProject} destinationParent The project into which item will be moved
 * @param {PWItem} item The item to be moved
 * @param {PWParentType} sourceParent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @param {HttpService} httpService Configured http service
 * @param {OpenModal} openModal Handles error modal
 * @param {CloseModal} closeModal Handles error modal
 * @param {TrackFeature} trackFeature Feature tracking callback
 * @param {OnComplete} [onComplete] Workflow complete callback
 * @returns {Promise<Response>} Http response of move request
 */
export async function moveItemWorkflow(
  destinationParent: PWProject,
  item: PWItem,
  sourceParent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  onComplete?: OnComplete
): Promise<Response> {
  trackFeature('MOVE_ITEMS');

  const toastHandle = notifyMoveInProgress(1);
  const progressTracker = newProgressTracker([item]);

  const response = await moveItem(
    destinationParent,
    item,
    sourceParent,
    appView,
    readOnly,
    httpService
  );
  updateProgressTracker(progressTracker, response);

  notifyMoveComplete(progressTracker, toastHandle);
  handleFailedMoves(
    [{ response, item }],
    openModal,
    closeModal,
    destinationParent,
    sourceParent,
    httpService,
    trackFeature,
    sourceParent,
    appView,
    readOnly,
    onComplete
  );
  await handleSuccessfulMoves(
    [{ response, item }],
    destinationParent,
    sourceParent,
    onComplete
  );

  return response;
}

/**
 * Moves item into the selected target project
 * @param {PWProject} target The project into which item will be moved
 * @param {PWItem} item The item to be moved
 * @param {PWParentType} parent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @param {HttpService} httpService Configured http service
 * @returns {Promise<Response>} Http response of move request
 */
export async function moveItem(
  target: PWProject,
  item: PWItem,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  httpService: HttpService
): Promise<Response> {
  if (!allowMove(item, parent, appView, readOnly)) {
    throw new Error('Move not allowed');
  }
  const url = `PW_WSG/${item.className}/${item.instanceId}`;
  const body = JSON.stringify({
    instance: getItemInstance(item, target)
  });

  return usingConcurrencyLimiter(async () => await httpService.post(url, body));
}
