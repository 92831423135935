import React from 'react';
import type { ProgressSummaryItem } from '../../components/ProgressSummary';
import { ProgressSummaryModal } from '../../components/ProgressSummary';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager';
import { t } from '../../services/translation';

export async function handleWorkflowsSummaryModal(
  progressManager: ProgressManager,
  jobId: string,
  openModal: OpenModal,
  closeModal: CloseModal
): Promise<void> {
  const job = progressManager.progressTracker.jobsInProgress.find(
    (jip) => jip.jobId == jobId
  );
  if (!job) {
    return;
  }
  const failures = job.activitiesInProgress.filter(
    (aip) => aip.status == 'error'
  );

  const workflowsSummaryPromises = job.activitiesInProgress.map(
    async (workflow) => {
      let errorText, successText;
      if (workflow.status == 'error') {
        if (workflow.warning) {
          errorText = workflow.warning;
        } else if (workflow.error) {
          const text = await workflow.error.text();
          errorText = (JSON.parse(text) as { errorText?: string }).errorText;
        } else {
          errorText = t('Workflows.ErrorEncountered');
        }
      }
      if (workflow.status == 'success') {
        if (workflow.success) {
          successText = workflow.success;
        } else {
          successText = t('Workflows.CommandCompleted');
        }
        return {
          item: workflow.item,
          successText: successText,
          uniqueId: workflow.uniqueId
        } as ProgressSummaryItem;
      }
      return {
        item: workflow.item,
        errorText: errorText,
        uniqueId: workflow.uniqueId
      } as ProgressSummaryItem;
    }
  );
  const workflowSummaries = await Promise.all(workflowsSummaryPromises);
  const title =
    failures.length == 0
      ? t('Workflows.WorkflowSuccessful')
      : failures.length == 1
      ? t('Workflows.WorkflowFailed')
      : t('Workflows.WorkflowsFailed');

  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={workflowSummaries}
      onClose={() => progressManager.clearFailuresFromJob(jobId)}
      title={title}
    />
  );
}
