import type {
  PWDataItem,
  PWFileType,
  PWItem,
  PWParentType
} from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import { t } from '../../services/translation';

/**
 * Takes file and casts it to a DataItem
 * @param {File} file File to be converted
 * @returns {PWDataItem} Resulting DataItem
 */
export function castFileToDataItem(
  file: File,
  parentGuid?: string
): PWDataItem {
  return {
    className: 'Document',
    Name: file.name,
    FileName: file.name,
    Description: file.name,
    FileSize: String(file.size),
    CreateTime: new Date(file.lastModified).toDateString(),
    UpdateTime: new Date(file.lastModified).toDateString(),
    MimeType: file.type,
    ParentGuid: parentGuid
  } as PWDataItem;
}

/**
 * Takes file and casts it to a FileType
 * @param {File} file File to be converted
 * @returns {PWFileType} Resulting FileType
 */
export function castFileToFileType(
  file: File & { customName?: string },
  parent?: PWParentType
): PWFileType {
  return {
    className: 'Document',
    Name: file.name,
    FileName: file.customName ?? file.name,
    Description: file.name,
    FileSize: String(file.size),
    CreateTime: new Date(file.lastModified).toDateString(),
    UpdateTime: new Date(file.lastModified).toDateString(),
    MimeType: file.type,
    ParentGuid: parent?.instanceId
  } as PWFileType;
}

export function downloadTitle(item: PWItem): string {
  if (!parseRelatedParent(item)?.Name) {
    return item.Name;
  }

  const parentName = parseRelatedParent(item)?.Name ?? '';

  return `${item.Name} ${t('InProgress.FromFolder', {
    parentName
  })}`;
}
