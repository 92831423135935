import _ from 'lodash';
import type { ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import type { PWDataItem } from '@bentley/pw-api';
import {
  getInterfaces,
  getProjectDefaultFormDefinition
} from '../../../actions/formRendering';
import {
  useHttpService,
  usePluginContext,
  useTableItemContext
} from '../../../context';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { mergePropertiesIntoInstance } from '../../documentCreation/properties';
import { LoadingPage } from '../../loadingPage';
import { getDocumentFormDefinition, setAttributesHttpService } from '../data';
import { useFormInstance } from '../data/useFormInstance';
import { formDefinitionState, projectIdState } from '../form';
import { commonProperties } from '../properties';
import { environmentInterfacesState } from '../toolbar';
import {
  currentEnvironmentInstanceState,
  currentSheetState,
  editingAttributesFormState,
  originalEnvironmentInstanceState,
  selectedItemsState
} from './state';

type AttributesStateProviderProps = {
  items: PWDataItem[];
  currentSheet: number;
  children?: ReactNode;
};

export function AttributesStateProvider({
  items,
  currentSheet,
  children
}: AttributesStateProviderProps): JSX.Element {
  const httpService = useHttpService();
  const { contextId } = usePluginContext();
  const {
    dataManager: { isLoading: dataManagerLoading }
  } = useTableItemContext();

  const getFormInstanceOrDefault = useFormInstance();

  const [formDefinition, setFormDefinition] =
    useRecoilState(formDefinitionState);
  const setProjectId = useSetRecoilState(projectIdState);
  const setOriginalEnvironmentInstance = useSetRecoilState(
    originalEnvironmentInstanceState
  );
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);

  const setSelectedItems = useSetRecoilState(selectedItemsState);
  const setCurrentSheet = useSetRecoilState(currentSheetState);
  const setEditingAttributesForm = useSetRecoilState(
    editingAttributesFormState
  );
  const setEnvironmentInterfaces = useSetRecoilState(
    environmentInterfacesState
  );

  const initialized = useMemo((): boolean => {
    if (currentEnvironmentInstance == null) {
      return false;
    }

    if (formDefinition === undefined) {
      return false;
    }

    if (!items?.length || dataManagerLoading) {
      return false;
    }

    return true;
  }, [
    currentEnvironmentInstance,
    dataManagerLoading,
    formDefinition,
    items?.length
  ]);

  useEffect(() => {
    setAttributesHttpService(httpService);
  }, [httpService]);

  useEffect(() => {
    const deepCopiedItems = _.cloneDeep(items);
    setSelectedItems(deepCopiedItems);
  }, [items, setSelectedItems]);

  useEffect(() => {
    setCurrentSheet(currentSheet);
  }, [currentSheet, setCurrentSheet]);

  useEffect(() => {
    setProjectId(contextId);
  }, [contextId, setProjectId]);

  useEffect(() => {
    const abortController = new AbortController();

    async function tryInitializeEnvironmentInstances(): Promise<void> {
      if (!items?.length || dataManagerLoading) {
        return;
      }

      if (items.length > 1) {
        const environmentInstances = await Promise.all(
          items.map((item) =>
            usingConcurrencyLimiter(
              () => getFormInstanceOrDefault(item, { abortController }),
              'background'
            )
          )
        );

        const propertiesInCommon = commonProperties(environmentInstances);
        const commonInstance = mergePropertiesIntoInstance(
          environmentInstances[0],
          propertiesInCommon
        );
        setOriginalEnvironmentInstance(commonInstance);
        setCurrentEnvironmentInstance(commonInstance);
      } else {
        const environmentInstance = await getFormInstanceOrDefault(items[0]);
        setOriginalEnvironmentInstance(environmentInstance);
        setCurrentEnvironmentInstance(environmentInstance);
      }
    }

    setOriginalEnvironmentInstance(null);
    setCurrentEnvironmentInstance(null);

    void tryInitializeEnvironmentInstances();

    return () => {
      abortController.abort();
    };
  }, [
    getFormInstanceOrDefault,
    items,
    setCurrentEnvironmentInstance,
    setOriginalEnvironmentInstance,
    dataManagerLoading
  ]);

  useEffect(() => {
    async function tryInitializeFormDef(): Promise<void> {
      if (!items?.length || dataManagerLoading) {
        return;
      }

      let formDefinition = await getDocumentFormDefinition(items[0]);
      if (!formDefinition) {
        formDefinition = await getProjectDefaultFormDefinition(
          items[0].ParentGuid ?? '',
          httpService
        );
      }
      setFormDefinition(formDefinition ?? null);
    }

    setFormDefinition(undefined);
    void tryInitializeFormDef();
  }, [httpService, items, setFormDefinition, dataManagerLoading]);

  useEffect(() => {
    async function initializeEnvironmentInterfaces(): Promise<void> {
      const environmentClass = currentEnvironmentInstance?.className;
      if (!environmentClass || !httpService) {
        return;
      }
      const environmentInterfaces = await getInterfaces(
        environmentClass,
        httpService
      );
      setEnvironmentInterfaces(environmentInterfaces);
    }

    void initializeEnvironmentInterfaces();
  }, [
    httpService,
    currentEnvironmentInstance?.className,
    setEnvironmentInterfaces
  ]);

  useEffect(() => {
    setEditingAttributesForm(false);
  }, [items, setEditingAttributesForm, dataManagerLoading]);

  if (!initialized) {
    return <LoadingPage />;
  }

  return <>{children}</>;
}
