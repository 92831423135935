import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { PWDocument, PWItem, WSGInstance } from '@bentley/pw-api';
import { filterDocuments } from '@bentley/pw-api';
import { usingDocCodeState } from '../docCode';
import { validateEnvironmentProperties } from '../properties/compare';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync,
  getDCWHttpService,
  projectIdState
} from '../state';
import {
  applyToAllState,
  multiUploadState,
  useWizardFunctionsContext
} from '../wizard';
import { itemsToCopyState } from '../wizard/state';
import {
  getChangedInstanceFromResponse,
  getCreatedDocument
} from './parseResponse';
import { uploadInProgressState } from './state';
import { useDocumentInstance } from './useDocumentInstance';
import { useMultiUploadProperties } from './useMultiUploadProperties';

type CopyFunctions = {
  copyDocuments: (updatedInstance?: WSGInstance | null) => Promise<Response[]>;
};

export function useCopy(): CopyFunctions {
  const originalEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    defaultEnvironmentInstanceStateAsync
  );

  const currentEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    currentEnvironmentInstanceState
  );

  const applyToAll = useRecoilValue(applyToAllState);
  const multiUpload = useRecoilValue(multiUploadState);
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const projectId = useRecoilValue<string>(projectIdState);
  const itemsToCopy = useRecoilValue(itemsToCopyState);
  const setUploadInProgress = useSetRecoilState(uploadInProgressState);
  const httpService = getDCWHttpService();

  const documentInstance = useDocumentInstance();
  const {
    getUploadEnvironmentInstance,
    getUploadDocCode,
    getUploadDocProperties
  } = useMultiUploadProperties();

  const { onSuccess, refreshAndSelect, copy, closeModal } =
    useWizardFunctionsContext();

  const getDocumentInstance = useCallback(
    async (
      copyInstance: WSGInstance | null,
      documentToCopy: PWDocument,
      siblingNames: string[]
    ): Promise<PWDocument> => {
      let environmentInstance = null as WSGInstance | null;

      const copyDocCode = await getUploadDocCode(copyInstance);
      const copyProperties = getUploadDocProperties(
        documentToCopy.FileName,
        copyDocCode,
        siblingNames
      );
      siblingNames.push(copyProperties.name, copyProperties.fileName);

      if (copyInstance?.properties) {
        const validatedProperties =
          copyInstance?.properties && originalEnvironmentInstance?.properties
            ? validateEnvironmentProperties(
                copyInstance?.properties,
                originalEnvironmentInstance?.properties,
                usingDocCode
              )
            : copyInstance?.properties;

        environmentInstance = {
          ...copyInstance,
          properties: validatedProperties
        };
      }

      const document = documentInstance(
        copyProperties,
        environmentInstance,
        projectId,
        documentToCopy.instanceId
      ) as PWDocument;
      return document;
    },
    [
      documentInstance,
      getUploadDocCode,
      getUploadDocProperties,
      originalEnvironmentInstance?.properties,
      projectId,
      usingDocCode
    ]
  );

  const copyDocument = useCallback(
    async (
      documentToCopy: PWDocument,
      siblingNames: string[],
      updatedInstance?: WSGInstance | null
    ): Promise<Response> => {
      if (!copy) {
        throw new Error('Copy function is undefined');
      }

      const currentInstance = updatedInstance ?? currentEnvironmentInstance;
      const copyInstance = await getUploadEnvironmentInstance(currentInstance);
      const document = await getDocumentInstance(
        copyInstance,
        documentToCopy,
        siblingNames
      );

      const responses = await copy([document]);
      if (!responses) {
        throw new Error('No responses returned from document copy');
      }
      const response = responses?.[0];
      if (response.ok) {
        const docInstance = await getChangedInstanceFromResponse(response);
        const createdDocument = await getCreatedDocument(
          docInstance.instanceId,
          httpService
        );
        const { Name, FileName, instanceId, Description } = createdDocument;

        void onSuccess?.(instanceId, FileName, Name, Description);
        refreshAndSelect?.([createdDocument as PWItem]);
      }
      return response;
    },
    [
      copy,
      currentEnvironmentInstance,
      getUploadEnvironmentInstance,
      getDocumentInstance,
      httpService,
      onSuccess,
      refreshAndSelect
    ]
  );

  const copyDocuments = useCallback(
    async (updatedInstance?: WSGInstance | null): Promise<Response[]> => {
      if (!itemsToCopy?.length) {
        throw new Error('No documents to copy found');
      }
      if (!copy) {
        throw new Error('Copy function is undefined');
      }

      setUploadInProgress(true);

      const documentsToCopy = filterDocuments(itemsToCopy);
      const siblingNames = [] as string[];
      if (multiUpload && applyToAll) {
        const documents = [] as PWDocument[];
        let copyInstance = updatedInstance ?? currentEnvironmentInstance;
        for (const document of documentsToCopy) {
          const firstUpload = document == documentsToCopy[0];
          if (!firstUpload) {
            copyInstance = await getUploadEnvironmentInstance(copyInstance);
          }

          const documentInstance = await getDocumentInstance(
            copyInstance,
            document,
            siblingNames
          );
          documents.push(documentInstance);
        }
        closeModal?.();
        const responses = await copy(documents);
        if (!responses) {
          throw new Error('No responses returned from document copy');
        }
        setUploadInProgress(false);
        return responses;
      }

      const response = await copyDocument(
        documentsToCopy[0],
        siblingNames,
        updatedInstance
      );

      setUploadInProgress(false);
      return [response];
    },
    [
      itemsToCopy,
      copy,
      setUploadInProgress,
      multiUpload,
      applyToAll,
      copyDocument,
      closeModal,
      currentEnvironmentInstance,
      getDocumentInstance,
      getUploadEnvironmentInstance
    ]
  );

  return { copyDocuments };
}
