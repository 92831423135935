import './saveSelectTypeahead.css';

import React, { useEffect, useState } from 'react';
import { LabeledSelect } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

type SaveSelectTypeaheadProps = {
  onChange?: (multiConnection: boolean) => void;
  multiConnection?: boolean;
};

export function SaveSelectTypeahead({
  onChange,
  multiConnection: initialValue
}: SaveSelectTypeaheadProps): JSX.Element {
  const [multiConnection, setMultiConnection] = useState<boolean>(
    initialValue ?? false
  );

  useEffect(() => {
    onChange?.(multiConnection);
  }, [onChange, multiConnection]);

  return (
    <LabeledSelect
      label={t('SavedSearch.UsableIn')}
      options={[
        { value: 'This connection', label: t('SavedAdvSearch.ThisConnection') },
        { value: 'All connections', label: t('SavedAdvSearch.AllConnections') }
      ]}
      value={multiConnection ? 'All connections' : 'This connection'}
      onChange={() => setMultiConnection((cur) => !cur)}
      data-testid="usable-in"
      wrapperProps={{ className: 's-s-typeahead' }}
    />
  );
}
