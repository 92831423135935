import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { productionEnvironment } from '../environment';

let appInsights: ApplicationInsights;

export function loadAppInsights(instrumentationKey: string): void {
  if (appInsights) {
    return;
  }

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      disableFetchTracking: false,
      disableTelemetry: productionEnvironment() == 'localhost'
    }
  });

  appInsights.loadAppInsights();
}

export function getAppInsights(): ApplicationInsights {
  return appInsights;
}
