import './pwModal.css';

import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import React from 'react';
import { Dialog, Flex } from '@itwin/itwinui-react';
import { keyPressAsClick } from '../../services/accessibility';
import type { PWModalButtonProps } from './pwModalButton';
import {
  PWModalPrimaryButton,
  PWModalSecondaryButton,
  PWModalTertiaryButton
} from './pwModalButton';
import { PWModalLoadingWrapper } from './pwModalLoadingWrapper';

type DialogProps = ComponentPropsWithoutRef<typeof Dialog> & {
  'data-testid'?: string;
};

type PWModalProps = {
  /**
   * Displays in modal header
   */
  title: string;
  /**
   * Added to the main modal container
   */
  className?: string;
  /**
   * Size of modal; default is 'm'
   */
  size?: 'm' | 'l';
  /**
   * Displays loading page if modal content is loading
   */
  isLoading?: boolean;
  /**
   * Type of loading page to display;
   * default is `'loadingPage'`
   */
  loadingStyle?: 'loadingPage' | 'spinnerOverlay';
  /**
   * Custom loading text to add to loading page
   */
  loadingText?: string;
  /**
   * Modal content
   */
  children: ReactNode;
  /**
   * Primary action button -
   * must include `title` and `onClick`.
   * Some other button props are available
   */
  primaryButton?: PWModalButtonProps;
  /**
   * Secondary action button (usually cancel) -
   * must include `title` and `onClick`.
   * Some other button props are available
   */
  secondaryButton?: PWModalButtonProps;
  /**
   * Tertiary action button (displays on left side) -
   * must include `title` and `onClick`.
   * Some other button props are available
   */
  tertiaryButton?: PWModalButtonProps;
  /**
   * Called when closing modal via close button or escape key
   */
  onClose: () => void;
  /**
   * Additional props to pass to the Dialog component
   */
  dialogProps?: Partial<DialogProps>;
};

export function PWModal({
  title,
  className,
  size = 'm',
  isLoading,
  loadingStyle,
  loadingText,
  children,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  onClose,
  dialogProps
}: PWModalProps): JSX.Element {
  const sizeClass = size == 'l' ? 'pw-modal-lg' : 'pw-modal-md';
  const joinedClassName = [sizeClass, className].filter(Boolean).join(' ');

  function onKeyPress(event: React.KeyboardEvent) {
    if (primaryButton?.onClick && !primaryButton?.disabled) {
      keyPressAsClick(primaryButton.onClick)(event);
    }
  }

  return (
    <Dialog
      isOpen={true}
      onClose={onClose}
      isDraggable={true}
      isResizable={size == 'l'}
      portal={true}
      preventDocumentScroll={true}
      setFocus={true}
      trapFocus={true}
      onKeyPress={onKeyPress}
    >
      <Dialog.Backdrop />
      <Dialog.Main className={joinedClassName} {...dialogProps}>
        <Dialog.TitleBar titleText={title} className="pw-modal-title" />
        <Dialog.Content>
          <PWModalLoadingWrapper
            isLoading={isLoading}
            loadingStyle={loadingStyle}
            loadingText={loadingText}
          >
            <Flex
              flexDirection="column"
              alignItems="stretch"
              className="pw-modal-flex"
            >
              {children}
            </Flex>
          </PWModalLoadingWrapper>
        </Dialog.Content>
        <Dialog.ButtonBar>
          {tertiaryButton && <PWModalTertiaryButton {...tertiaryButton} />}
          {primaryButton && <PWModalPrimaryButton {...primaryButton} />}
          {secondaryButton && <PWModalSecondaryButton {...secondaryButton} />}
        </Dialog.ButtonBar>
      </Dialog.Main>
    </Dialog>
  );
}
