import type { ChangeEvent } from 'react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import type { PWDataItem, PWItem, PWParentType } from '@bentley/pw-api';
import {
  filterDataItems,
  filterParentTypes,
  itemIsDataItem,
  itemIsDocument,
  itemIsParentType,
  itemIsProject,
  parseFileExtension,
  pwConstants
} from '@bentley/pw-api';
import { LabeledInput, Radio, StatusMessage } from '@itwin/itwinui-react';
import { PWTooltip } from '../../components/pwTooltip';
import { t } from '../../services/translation';
import { containsInvalidCharacters } from '../rename/inputValidation';
import type { Action, ConflictResolution } from './conflictResolution';
import { getNextName } from './conflictResolution';

type ConflictResolutionOptionProps = {
  action: Action;
  containsImmutableFile: boolean;
  description: string;
  inputBoxText?: string;
  label: string;
  resolution: ConflictResolution;
  siblings: PWItem[];
  setDoForNextAvailable: (value: boolean) => void;
  setInputError: (value: boolean) => void;
  updateResolution: (resolution: ConflictResolution) => void;
};

export function ConflictResolutionOption({
  action,
  containsImmutableFile,
  description,
  inputBoxText,
  label,
  resolution,
  siblings,
  setDoForNextAvailable,
  setInputError,
  updateResolution
}: ConflictResolutionOptionProps): JSX.Element {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [inputExistsError, setInputExistsError] = useState<boolean>(false);
  const [extensionWarning, setExtensionWarning] = useState<boolean>(false);
  const [extensionOnlyError, setExtensionOnlyError] = useState<boolean>(false);
  const [isFolderNameValid, setIsFolderNameValid] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');

  const changeButtonClicked = useRef<boolean>(false);
  const initialName = useRef(resolution.customName);

  const checked = action == resolution.action;

  const maxLength = useMemo(() => {
    return action == 'rename'
      ? getNameMaxLength(resolution.sourceItem)
      : undefined;
  }, [action, resolution.sourceItem]);

  const resolutionHasCustomName = useCallback(
    (resolution: ConflictResolution): boolean =>
      resolution.customName != getNextName(resolution.sourceItem, siblings),
    [siblings]
  );

  function changeConflictSelection(
    customName?: string,
    customVersion?: string
  ): void {
    let newName = customName ?? getNextName(resolution.sourceItem, siblings);
    if (resolution.action != action) {
      setInputError(false);
    }
    if (maxLength && newName.length > maxLength) {
      setInputError(true);
      setInputExistsError(true);
    }
    if (action == 'version') {
      const destinationItemName =
        resolution.destinationItems.length > 0
          ? resolution.destinationItems[0].Name
          : '';
      newName = destinationItemName;
      if (!customVersion) {
        setInputError(false);
      }
    }
    updateResolution({
      ...resolution,
      action: action,
      customName: newName,
      customVersion: customVersion
    });
  }

  useEffect(() => {
    function openInput(): boolean {
      if (!checked) {
        return false;
      }

      if (changeButtonClicked.current) {
        changeButtonClicked.current = false;
        return true;
      }

      if (resolution.action == 'rename' && action == 'rename') {
        if (!resolutionHasCustomName(resolution)) {
          return showInput;
        } else {
          return true;
        }
      }
      if (resolution.action == 'version' && action == 'version') {
        return resolution.customVersion != undefined;
      }
      return false;
    }

    setShowInput(openInput());
  }, [checked, action, resolution, resolutionHasCustomName, showInput]);

  function getDescription(): JSX.Element | null {
    return (
      <div>
        <div className="optionDetail">
          <PWTooltip
            placement={'bottom'}
            content={getDescriptionText(description, inputBoxText)}
            className="fileName-tooltip"
          >
            <div className="fileName">
              {getDescriptionText(description, inputBoxText)}
            </div>
          </PWTooltip>
          {action == 'version' || action == 'rename' ? (
            <button
              className="changeButton"
              data-testid={`change-${action}`}
              onClick={() => {
                setShowInput((cur) => !cur);
                if (containsImmutableFile && action == 'version' && showInput) {
                  setDoForNextAvailable(false);
                } else {
                  setDoForNextAvailable(showInput);
                }
                if (
                  !checked ||
                  resolutionHasCustomName(resolution) ||
                  resolution.customVersion
                ) {
                  if (!showInput) {
                    changeButtonClicked.current = true;
                  }
                  changeConflictSelection();
                } else {
                  changeButtonClicked.current = false;
                }
                if (action == 'version') {
                  setInputValue('');
                  setInputExistsError(false);
                } else if (action == 'rename') {
                  setInputValue(initialName.current);
                  setExtensionWarning(false);
                  setIsFolderNameValid(true);

                  setInputExistsError(false);
                  setInputError(false);
                }
              }}
            >
              {showInput ? t('Generic.Cancel') : t('Generic.Change')}
            </button>
          ) : null}
        </div>
      </div>
    );
  }

  function getDescriptionText(
    description: string,
    inputBoxText?: string
  ): string {
    if (!inputBoxText) {
      return description;
    }
    if (action == 'rename') {
      return `${inputBoxText}: ${resolution.customName}`;
    }
    if (action == 'version' && showInput && resolution.customVersion) {
      return `${inputBoxText}: ${resolution.customVersion}`;
    }
    return description;
  }

  function verifyInputChoice(
    action: Action,
    value: string,
    sourceItem: PWItem
  ): boolean {
    const dataItems = filterDataItems(siblings);
    setInputValue(value);
    if (action == 'rename') {
      if (!value.trim()) {
        return false;
      }
      if (itemIsParentType(sourceItem)) {
        const parentItems = filterParentTypes(siblings);
        return !parentItems.some((item) =>
          parentNameConflict(item, value.trim())
        );
      }

      return !dataItems.some((item) => nameConflict(item, value.trim()));
    } else if (action == 'version') {
      if (value.length == 0) {
        return true;
      }
      return (
        value.trim().length <= pwConstants.maxVersionLength &&
        !dataItems.some((item) => nameAndVersionConflict(item, value.trim()))
      );
    }
    return true;
  }

  function nameConflict(item: PWDataItem, name: string): boolean {
    return (
      (item.Name == name || item.FileName == name) &&
      item.className == resolution.sourceItem.className
    );
  }

  function parentNameConflict(item: PWParentType, name: string): boolean {
    return (
      item.Name == name && item.className == resolution.sourceItem.className
    );
  }

  function nameAndVersionConflict(item: PWDataItem, version: string): boolean {
    return (
      nameConflict(item, resolution.sourceItem.Name) && item.Version == version
    );
  }

  function getNameMaxLength(item: PWItem): number {
    if (itemIsDataItem(item)) {
      return pwConstants.maxFileNameLength;
    }

    return pwConstants.maxFolderNameLength;
  }

  function checkFileExtensionChange(
    customName: string,
    originalFileName: string
  ): boolean {
    if (
      parseFileExtension(customName) !== parseFileExtension(originalFileName)
    ) {
      return true;
    }

    return false;
  }

  function checkFileExtensionOnly(customName: string): boolean {
    if ('.' + parseFileExtension(customName) === customName) {
      return true;
    } else {
      return false;
    }
  }

  function checkFolderNameForSpecialCharacters(
    customName: string,
    item: PWItem
  ): boolean {
    if (!itemIsProject(item)) {
      return true;
    }

    if (action != 'rename') {
      return true;
    }

    if (!containsInvalidCharacters(customName)) {
      return true;
    }
    return false;
  }

  function getInputBox(): JSX.Element | null {
    if (!showInput) {
      return null;
    }

    function getInputExistsErrorMessage(): string {
      if (action == 'version') {
        if (inputValue.trim().length > pwConstants.maxVersionLength) {
          const excess =
            inputValue.trim().length - pwConstants.maxVersionLength;
          return t('Generic.ContentLimitedTo', {
            count: pwConstants.maxVersionLength,
            excess
          });
        } else {
          return t('ConflictResolution.VersionExists');
        }
      } else if (maxLength && resolution.customName.length > maxLength) {
        const excess = resolution.customName.length - maxLength;
        return t('Generic.ContentLimitedTo', { count: maxLength, excess });
      } else if (!inputValue.trim()) {
        return t('DocumentCreation.FileNameCannotBeEmpty');
      } else if (itemIsDataItem(resolution.sourceItem)) {
        return t('ConflictResolution.FileNameExists');
      } else {
        return t('ConflictResolution.FolderExists');
      }
    }

    function getStatus(): 'negative' | 'warning' | 'positive' | undefined {
      if (inputExistsError) {
        return 'negative';
      } else if (extensionOnlyError && itemIsDocument(resolution.sourceItem)) {
        return 'negative';
      } else if (extensionWarning && itemIsDocument(resolution.sourceItem)) {
        return 'warning';
      } else if (!isFolderNameValid) {
        return 'negative';
      } else {
        return undefined;
      }
    }

    function getErrorMessage(): string | undefined {
      if (inputExistsError) {
        return getInputExistsErrorMessage();
      } else if (extensionOnlyError && itemIsDocument(resolution.sourceItem)) {
        return t('ConflictResolution.ExtensionOnly');
      } else if (extensionWarning && itemIsDocument(resolution.sourceItem)) {
        return action == 'version'
          ? ''
          : t('ConflictResolution.ExtensionChanged');
      } else if (!isFolderNameValid) {
        return t('Rename.FolderNameHasSpecialCharacters');
      } else {
        return undefined;
      }
    }

    return (
      <LabeledInput
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          let inputErrorExists = false;
          if (
            verifyInputChoice(action, e.target.value, resolution.sourceItem)
          ) {
            if (inputExistsError) {
              setInputError(false);
              setInputExistsError(false);
            }
          } else {
            setInputError(true);
            inputErrorExists = true;
            setInputExistsError(true);
          }
          const customName = action == 'rename' ? e.target.value : undefined;
          const customVersion =
            action == 'version' ? e.target.value : undefined;
          changeConflictSelection(customName, customVersion);
          if (action == 'rename') {
            setExtensionWarning(
              checkFileExtensionChange(
                customName ?? '',
                resolution.originalFileName ?? ''
              )
            );

            setIsFolderNameValid(
              checkFolderNameForSpecialCharacters(
                customName ?? '',
                resolution.sourceItem
              )
            );
            setInputError(
              !checkFolderNameForSpecialCharacters(
                customName ?? '',
                resolution.sourceItem
              ) || inputErrorExists
            );

            const extError = checkFileExtensionOnly(customName ?? '');
            setExtensionOnlyError(extError);
            setInputError(extError || inputErrorExists);
          }
        }}
        value={
          action == 'rename'
            ? resolution.customName
            : resolution.customVersion ?? ''
        }
        status={getStatus()}
        data-testid={`${action}InputBox`}
        message={
          <StatusMessage status={getStatus()}>
            {getErrorMessage()}
          </StatusMessage>
        }
      />
    );
  }

  return (
    <div className="conflictOptionGroup">
      <Radio
        className="conflictRadioButton"
        name="Options"
        label={label}
        data-testid={`option-${action}`}
        onClick={() => {
          if (
            containsImmutableFile &&
            (action == 'replace' || action == 'version')
          ) {
            setDoForNextAvailable(false);
          } else {
            setDoForNextAvailable(true);
          }
          changeConflictSelection();
        }}
        onChange={() => null}
        checked={checked}
      />
      <div className="conflictOptionDescription">
        {getDescription()}
        {getInputBox()}
      </div>
    </div>
  );
}
