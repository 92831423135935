import './infoTooltip.css';

import type { ReactNode } from 'react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import type { ModalElement } from '../../hooks/useModal';
import { useModal } from '../../hooks/useModal';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';
import type { PWModalButtonProps } from '../pwModal/pwModalButton';

/**
 * Prop Description
 */
export type InfoTooltipProps = {
  /**
   * Text displayed on tooltip
   */
  tooltipText: string;
  /**
   * Text displayed on info modal
   */
  modalText?: ReactNode;
  /**
   * Optional custom modal title
   */
  modalTitle?: string;
  /**
   * extra function that will be run when the info modal closes
   */
  onModalClose?: () => void;

  'data-testid'?: string;

  tooltipDisabled?: boolean;
  primaryButton?: PWModalButtonProps;
} & React.HTMLAttributes<HTMLElement>;

type InfoTooltipHoverProps = {
  /**
   * controls if the tooltip should be displayed for the item being hovered on
   */
  tooltipVisible: boolean;
  /**
   * ref to the tooltip the hover indicator belongs to
   */
  tooltipRef: React.MutableRefObject<HTMLDivElement | null>;
  /**
   * extra function that will be run when the info modal closes
   */
  onModalClose?: () => void;
} & InfoTooltipProps;

type ItemInfoTooltipModalProps = {
  /**
   * Modal information text
   */
  modalText: ReactNode;
  /**
   * Modal close function
   */
  closeModalFunction: () => void;
  /**
   * Modal title
   */
  modalTitle: string;
  primaryButton?: PWModalButtonProps;
} & React.HTMLAttributes<HTMLElement>;

const ItemInfoTooltipModal = (
  props: ItemInfoTooltipModalProps
): ModalElement => {
  return (
    <PWModal
      title={props.modalTitle}
      primaryButton={props.primaryButton}
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: props.closeModalFunction
      }}
      onClose={props.closeModalFunction}
      dialogProps={{ 'data-testid': 'tooltipInfoModal' }}
    >
      {props.modalText}
    </PWModal>
  );
};

const cleanupTimer = (timerParam: number | null) => {
  if (timerParam) {
    clearTimeout(timerParam);
    timerParam = null;
  }
};

/**
 * tooltip that includes a more info button that opens a modal with more information
 */
export function InfoTooltip({
  onModalClose,
  children,
  className,
  ...rest
}: InfoTooltipProps): JSX.Element {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipIcon = useRef<HTMLDivElement | null>(null);
  const latestTimer = useRef<number | null>(null);

  const toggle = (showTooltip: boolean) => {
    cleanupTimer(latestTimer.current);

    latestTimer.current = window.setTimeout(
      () => {
        setTooltipVisible(showTooltip);
        latestTimer.current = null;
      },
      showTooltip ? 0 : 200
    );
  };

  const hideTooltip = () => {
    toggle(false);
  };

  const showTooltip = () => {
    toggle(true);
  };

  useEffect(() => {
    return () => {
      cleanupTimer(latestTimer.current);
    };
  }, []);

  return (
    <div
      ref={tooltipIcon}
      data-testid={rest['data-testid']}
      className={className}
    >
      <span onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </span>
      <InfoTooltipHover
        {...rest}
        tooltipVisible={tooltipVisible}
        tooltipRef={tooltipIcon}
        onModalClose={onModalClose}
      />
    </div>
  );
}

const InfoTooltipHover = (props: InfoTooltipHoverProps) => {
  const [currentModal, openModal, closeModal] = useModal();

  const closeModalFunction = () => {
    props.onModalClose?.();
    closeModal();
  };

  let modalTitle = t('InfoTooltip.MoreInformation');

  if (props.modalTitle) {
    modalTitle = props.modalTitle;
  }

  const [userInTooltip, setUserInTooltip] = useState(false);
  const tooltipHover = useRef<HTMLDivElement | null>(null);
  const tooltipRoot = document.getElementById('tooltip-root');
  const tooltipVisible = props.tooltipVisible;

  const mainStyle: string =
    tooltipVisible || userInTooltip
      ? 'infotooltiptext'
      : 'infotooltiptext infotooltiptext-hidden';

  const mainArrowStyle: string =
    tooltipVisible || userInTooltip
      ? 'infotooltipArrow'
      : 'infotooltipArrow infotooltiptext-hidden';
  const maxCharactersAllowed = 256;
  const tooltipRef = props.tooltipRef;
  const latestTimer = useRef<number | null>(null);

  const toggleUserInTooltip = () => {
    cleanupTimer(latestTimer.current);

    latestTimer.current = window.setTimeout(
      () => {
        if (tooltipVisible) {
          setUserInTooltip(!userInTooltip);
        } else {
          setUserInTooltip(false);
        }

        latestTimer.current = null;
      },
      userInTooltip ? 200 : 0
    );
  };

  function tooltipPositioning() {
    if (!tooltipRef.current || !tooltipHover.current) {
      return;
    }

    const hoverElement = tooltipHover.current;
    const hoverRect = hoverElement.getBoundingClientRect();

    if (hoverRect.width <= 0 || hoverRect.height <= 0) {
      return;
    }

    const tooltip = tooltipRef.current;
    const tooltipRect = tooltip.getBoundingClientRect();

    const tooltipX = Math.round(
      tooltipRect.left - hoverRect.width / 2 + tooltipRect.width / 2 - 1
    );
    const tooltipY = Math.round(tooltipRect.top - hoverRect.height - 6);
    const currentArrowTop = hoverRect.height;
    const currentArrowLeft = Math.round(hoverRect.width / 2);

    const tooltipPositioning = {
      tooltipX: `${tooltipX}px`,
      tooltipY: `${tooltipY}px`,
      arrowLeft: `${currentArrowLeft}px`,
      arrowTop: `${currentArrowTop}px`
    };
    return tooltipPositioning;
  }

  const tooltipPosition = tooltipPositioning();

  const locationStyle = {
    top: tooltipPosition?.tooltipY,
    left: tooltipPosition?.tooltipX
  };

  const arrowLocationStyle = {
    top: tooltipPosition?.arrowTop,
    left: tooltipPosition?.arrowLeft
  };

  useEffect(() => {
    return () => {
      cleanupTimer(latestTimer.current);
    };
  }, []);

  function openTooltipModal(event: React.MouseEvent): void {
    event.stopPropagation();

    openModal(
      <ItemInfoTooltipModal
        modalTitle={modalTitle}
        modalText={props.modalText ?? ''}
        primaryButton={props.primaryButton}
        closeModalFunction={closeModalFunction}
      />
    );
    setUserInTooltip(false);
  }

  if (!tooltipRoot) {
    return null;
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      {props.tooltipDisabled ? null : (
        <span className="infotooltipContainer" style={locationStyle}>
          <span
            ref={tooltipHover}
            onMouseEnter={toggleUserInTooltip}
            onMouseLeave={toggleUserInTooltip}
            className={mainStyle}
          >
            {props.tooltipText.substr(0, maxCharactersAllowed)}
            {props.modalText && (
              <div
                role="button"
                tabIndex={-1}
                className="infotooltipButton"
                onClick={openTooltipModal}
                onKeyDown={keyPressAsClick(openTooltipModal)}
              >
                {t('InfoTooltip.MoreInfo')}
              </div>
            )}
            {currentModal}
          </span>
          <div style={arrowLocationStyle} className={mainArrowStyle}></div>
        </span>
      )}
    </React.Fragment>,
    tooltipRoot
  );
};
