import './fileNameTruncationModal.css';

import React from 'react';
import type { PWDataItem, PWProject } from '@bentley/pw-api';
import { pwConstants } from '@bentley/pw-api';
import { Flex, Text } from '@itwin/itwinui-react';
import type { ProgressSummaryItem } from '../../components/ProgressSummary';
import { FailureItem } from '../../components/failureItem';
import { PWModal } from '../../components/pwModal';
import { useConnectionAuth } from '../../context';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';

interface IFileNameTruncationModalProps {
  closeModal: () => void;
  onYes: () => void;
  onNo: () => void;
  files: File[];
  folders: FileSystemDirectoryEntry[];
  trackFeature: TrackFeature;
}

export function FileNameTruncationModal({
  closeModal,
  onYes,
  onNo,
  files,
  folders,
  trackFeature
}: IFileNameTruncationModalProps): JSX.Element {
  const { userName } = useConnectionAuth();

  function fileAsDataItem(file: File): PWDataItem {
    return {
      Name: `${file.name}`,
      FileName: file.name,
      className: 'Document',
      UpdateTime: new Date().toISOString(),
      UpdatedBy: userName,
      FileSize: file.size.toString()
    } as PWDataItem;
  }

  function folderAsProject(folder: FileSystemDirectoryEntry): PWProject {
    return {
      Name: `${folder.name}`,
      className: 'Project',
      TypeString: 'Folder'
    } as PWProject;
  }

  const topFileMessage =
    files.length > 1
      ? `${t('Upload.MultipleFileNameExceeds', {
          count: pwConstants.maxFileNameLength
        })}`
      : `${t('Upload.FileNameExceeds', {
          count: pwConstants.maxFileNameLength
        })}`;

  const topFolderMessage =
    folders.length > 1
      ? `${t('Upload.MultipleFolderNamesExceed', {
          count: pwConstants.maxFolderNameLength
        })}`
      : `${t('Upload.FolderNameExceeds', {
          count: pwConstants.maxFolderNameLength
        })}`;

  const bottomMessage =
    files.length + folders.length > 1
      ? t('Upload.ProceedWithUploadingMultiple')
      : t('Upload.ProceedWithUploading');

  const fileItems = files.map((file) => {
    const dataItem = fileAsDataItem(file);
    return { item: dataItem } as ProgressSummaryItem;
  });

  const folderItems = folders.map((folder) => {
    const project = folderAsProject(folder);
    return { item: project } as ProgressSummaryItem;
  });

  return (
    <PWModal
      title={t('Upload.NameTooLong')}
      className="ftm-modal-content"
      primaryButton={{
        title: t('Upload.Proceed'),
        onClick: () => {
          closeModal();
          onYes();
          trackFeature('UPLOAD_TRUNCATED_FILENAME');
        }
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: () => {
          closeModal();
          onNo();
        }
      }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'FileNameTruncationModal' }}
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        className={'ftm-list ftm-show-scroll'}
      >
        {folders.length > 0 && (
          <>
            <Text>{topFolderMessage}</Text>
            {folderItems.map((folder) => (
              <FailureItem
                itemProgressSummary={folder}
                key={folder.item.Name}
              />
            ))}
          </>
        )}
        {files.length > 0 && (
          <>
            <Text>{topFileMessage}</Text>
            {fileItems.map((file) => (
              <FailureItem itemProgressSummary={file} key={file.item.Name} />
            ))}
          </>
        )}
      </Flex>
      <Text>{bottomMessage}</Text>
    </PWModal>
  );
}
