import type { ToastHandle } from '../../services/pwToast';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyMappingCompleted(
  numCompleted: number,
  iModelName?: string,
  inSavedSearch?: boolean,
  savedSearchName?: string
): void {
  if (numCompleted < 1) {
    notifyFailure();
  } else if (inSavedSearch) {
    notifyMapSavedSearchSuccess(savedSearchName, iModelName);
  } else {
    notifySuccess(numCompleted, iModelName);
  }
}

function notifyFailure(): ToastHandle {
  const content = t('IModel.Notifications.ErrorMapping');

  return openToast({ content, category: 'negative' });
}

function notifySuccess(numCompleted: number, iModelName?: string): ToastHandle {
  const iModel = iModelName || t('MenuItem.IModel');
  const message =
    numCompleted == 1
      ? t('IModel.Notifications.Success', { iModelName: iModel })
      : t('IModel.Notifications.SuccessMulti', {
          iModelName: iModel,
          count: numCompleted
        });

  return openToast({ content: message, category: 'positive' });
}

function notifyUnmapSuccess(num: number): ToastHandle {
  const message =
    num == 1
      ? t('IModel.Notifications.Unmapped.Success')
      : t('IModel.Notifications.Unmapped.SuccessMulti', {
          count: num
        });
  return openToast({ content: message, category: 'positive' });
}

function notifyUnmapSavedSearchSuccess(num: number): ToastHandle {
  const message =
    num == 1
      ? t('IModel.Notifications.UnmapSavedSearchSuccess')
      : t('IModel.Notifications.UnmapSavedSearchSuccessMulti', {
          count: num
        });

  return openToast({ content: message, category: 'positive' });
}

function notifyMapSavedSearchSuccess(
  savedSearchName?: string,
  iModelName?: string
): ToastHandle {
  const toasterMsg = `${t('IModel.Notifications.MapSavedSearchSuccess', {
    savedSearchName: savedSearchName,
    iModelName: iModelName
  })}`;
  return openToast({ content: toasterMsg, category: 'positive' });
}

export function notifyUnmappingCompleted(
  numCompleted: number,
  inSavedSearch?: boolean
): void {
  if (numCompleted < 1) {
    notifyFailure();
  } else if (inSavedSearch) {
    notifyUnmapSavedSearchSuccess(numCompleted);
  } else {
    notifyUnmapSuccess(numCompleted);
  }
}
