import './viewStatus.css';

import React from 'react';
import { Text } from '@itwin/itwinui-react';
import { usePropertyContext } from '../../context';
import { t } from '../../services/translation';

export function ViewStatus(): JSX.Element {
  const {
    viewManager: { currentViewDisplayLabel }
  } = usePropertyContext();

  const view = t('Generic.View');

  return (
    <Text variant="small" isMuted={true} className="current-view-container">
      <span className="view-label">{view}:</span>
      <span className="view-name" data-testid="view-name">
        {currentViewDisplayLabel}
      </span>
    </Text>
  );
}
