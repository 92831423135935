import './docCodeError.css';

import React from 'react';
import { StatusMessage } from '@itwin/itwinui-react';
import { t } from '../../../services/translation';

type DocCodeErrorProps = {
  errorMessage?: string;
};

export function DocCodeError({ errorMessage }: DocCodeErrorProps): JSX.Element {
  return (
    <StatusMessage status="negative" className="doc-code-error">
      {errorMessage ?? t('Generic.UnknownErrorOccurred')}
    </StatusMessage>
  );
}
