import './docCodeFormToolbar.css';

import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SvgEdit, SvgSave, SvgUndo } from '@itwin/itwinui-icons-react';
import { usePluginContext } from '../../../context';
import { t } from '../../../services/translation';
import { PWToolbar } from '../../toolbar';
import {
  formValidState,
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from '../form';
import { useSave } from '../save';
import { saveInProgressState } from '../save/state';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState,
  originalEnvironmentInstanceState
} from '../state';

export function DocCodeFormToolbar(): JSX.Element | null {
  const { readOnly } = usePluginContext();
  const [editingDocCodeForm, setEditingDocCodeForm] = useRecoilState(
    editingDocCodeFormState
  );
  const saveInProgress = useRecoilValue(saveInProgressState);

  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const formValid = useRecoilValue(formValidState);

  const { saveInstance } = useSave();

  function onEditClick(): void {
    setEditingDocCodeForm(true);
  }

  function onCancelEditClick(): void {
    setCurrentEnvironmentInstance(originalEnvironmentInstance);
    setEditingDocCodeForm(false);
  }

  const allowEditToggle = useMemo((): boolean => {
    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (saveInProgress) {
      return false;
    }

    return true;
  }, [saveInProgress, updatingReloadProperties, updatingTriggeredProperties]);

  function onEditToggleClick(): void {
    if (editingDocCodeForm) {
      onCancelEditClick();
    } else {
      onEditClick();
    }
  }

  const allowUndo = useMemo((): boolean => {
    if (!editingDocCodeForm || saveInProgress) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (originalEnvironmentInstance == currentEnvironmentInstance) {
      return false;
    }

    return true;
  }, [
    currentEnvironmentInstance,
    editingDocCodeForm,
    originalEnvironmentInstance,
    saveInProgress,
    updatingReloadProperties,
    updatingTriggeredProperties
  ]);

  function onUndoClick(): void {
    setCurrentEnvironmentInstance(originalEnvironmentInstance);
  }

  const allowSave = useMemo((): boolean => {
    if (!editingDocCodeForm || saveInProgress) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (originalEnvironmentInstance == currentEnvironmentInstance) {
      return false;
    }

    if (!formValid) {
      return false;
    }

    return true;
  }, [
    currentEnvironmentInstance,
    editingDocCodeForm,
    formValid,
    originalEnvironmentInstance,
    saveInProgress,
    updatingReloadProperties,
    updatingTriggeredProperties
  ]);

  function onSaveClick(): void {
    void saveInstance();
  }

  if (readOnly) {
    return null;
  }

  return (
    <PWToolbar
      className="docCodeFormToolbar"
      data-testid="docCode-toolbar-container"
      buttons={[
        {
          title: editingDocCodeForm ? t('Generic.Cancel') : t('Generic.Edit'),
          icon: <SvgEdit />,
          onClick: onEditToggleClick,
          disabled: !allowEditToggle,
          isActive: editingDocCodeForm
        },
        {
          title: t('AttributeForm.Undo'),
          icon: <SvgUndo />,
          onClick: onUndoClick,
          disabled: !allowUndo
        },
        {
          title: t('Generic.Save'),
          icon: <SvgSave />,
          onClick: onSaveClick,
          disabled: !allowSave
        }
      ]}
    />
  );
}
