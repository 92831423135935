import type { PWItem } from '@bentley/pw-api';
import type { ToastHandle } from '../../services/pwToast';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyItemsRestored(items: PWItem[]): ToastHandle {
  const message =
    items.length == 1 ? t('Restore.ItemRestored') : t('Restore.ItemsRestored');
  return openToast({ content: message, category: 'positive' });
}

export function notifyRestoreNotAllowed(): ToastHandle {
  const message = t('Restore.RestoreNotAllowed');
  return openToast({ content: message, category: 'warning' });
}

export function notifyRestoreFailed(items: PWItem[]): ToastHandle {
  const message =
    items.length == 1
      ? t('Restore.ErrorRestoringItem')
      : t('Restore.ErrorRestoringItems');
  return openToast({ content: message, category: 'negative' });
}
