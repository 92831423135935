import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  PDFMarkupTooltip,
  PdfMarkupMode,
  getPdfMarkupMode
} from '../../../../actions/pdf';
import { useEnvironmentContext } from '../../../../context';

type PDFBadgeProps = {
  item: PWItem;
  key: string | number;
};

export function PDFBadge({ item, key }: PDFBadgeProps): JSX.Element | null {
  const { azureServerAccessible } = useEnvironmentContext();

  return getPdfMarkupMode(item, azureServerAccessible) ===
    PdfMarkupMode.Readonly ? (
    <PDFMarkupTooltip key={key} serverIsAccessible={azureServerAccessible} />
  ) : null;
}
