import React, { useMemo } from 'react';
import { itemIsFlatSet } from '@bentley/pw-api';
import { SvgUploadToCloud } from '@itwin/itwinui-icons-react';
import { allowUploadFiles, useOpenFileInput } from '../../../../actions/upload';
import {
  useAppViewContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useUploadFilesAction(): MenuItemAction {
  const { readOnly } = usePluginContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const openFileInput = useOpenFileInput();

  const uploadItemsAction = useMemo(
    (): MenuItemAction => ({
      title: t('NewButton.UploadFiles'),
      icon: <SvgUploadToCloud />,
      disabled: !allowUploadFiles(currentParent, currentAppView.type, readOnly),
      hidden:
        readOnly ||
        itemIsFlatSet(currentParent) ||
        currentAppView.type != 'FolderNavigation',
      onClick: openFileInput
    }),
    [currentAppView.type, currentParent, openFileInput, readOnly]
  );

  return uploadItemsAction;
}
