import type { PWItem } from '@bentley/pw-api';
import {
  itemIsProject,
  parseFileExtension,
  parseFileNameWithoutExtension,
  pwConstants
} from '@bentley/pw-api';

export function getNormalizedItemName(item: PWItem): string {
  if (itemIsProject(item)) {
    return item.Name.substring(0, pwConstants.maxFolderNameLength);
  }

  return item.Name.substring(0, pwConstants.maxFileNameLength);
}

export function getNormalizedFileName(fileName: string): string {
  const fileExtension = parseFileExtension(fileName);
  const suffix = fileExtension ? `.${fileExtension}` : '';
  const name = parseFileNameWithoutExtension(fileName);
  return (
    name.substring(0, pwConstants.maxFileNameLength - suffix.length) + suffix
  );
}
