import type { PWDataItem } from '@bentley/pw-api';
import type { AuditTrail } from '../exportAuditTrail';

export function isOlderVersion(
  testItem: PWDataItem,
  currentItem: PWDataItem,
  testItemAuditTrail: AuditTrail[] | undefined,
  currentItemAuditTrail: AuditTrail[] | undefined
): boolean {
  // If audit trail present, use to compare if older version
  // Otherwise, look at name to compare if older version
  // Todo: situation when audit trails turned off and Name has been changed

  const useAuditTrailCheck =
    (testItemAuditTrail?.length ?? 0) > 0 &&
    (currentItemAuditTrail?.length ?? 0) > 0 &&
    currentItem.className != 'LogicalSet';

  return (
    testItem.instanceId != currentItem.instanceId &&
    testItem.Version != currentItem.Version &&
    testItem.Sequence < currentItem.Sequence &&
    !testItem.IsLatest &&
    (useAuditTrailCheck
      ? hasSameAuditTrail(testItemAuditTrail, currentItemAuditTrail)
      : hasSameName(testItem, currentItem))
  );
}

function hasSameAuditTrail(
  testItemAuditTrail: AuditTrail[] | undefined,
  currentItemAuditTrail: AuditTrail[] | undefined
): boolean {
  return (
    JSON.stringify(testItemAuditTrail) == JSON.stringify(currentItemAuditTrail)
  );
}

function hasSameName(item: PWDataItem, current: PWDataItem): boolean {
  return item.Name == current.Name || item.FileName == current.FileName;
}
