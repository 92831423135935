import type { PWItem } from '@bentley/pw-api';
import { itemIsLockedOrCheckedOut } from '@bentley/pw-api';

export function allowCreateRenditionsAll(
  items: PWItem[],
  isMultipleItemExistsInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || !items.length) {
    return false;
  }

  if (!items.every((item) => allowCreateRenditions(item))) {
    return false;
  }

  if (
    isMultipleItemExistsInConnection &&
    !isMultipleItemExistsInConnection(items)
  ) {
    return false;
  }

  return true;
}

function allowCreateRenditions(item: PWItem): boolean {
  if (!item || !item.className || !item.instanceId) {
    return false;
  }

  if (itemIsLockedOrCheckedOut(item)) {
    return false;
  }

  return true;
}
