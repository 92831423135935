import React, { useCallback, useMemo, useState } from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import { Text } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import {
  useAppViewContext,
  usePluginContext,
  useTableItemContext
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import { t } from '../../../services/translation';
import { PWTooltip } from '../../pwTooltip';

export function ParentCell({ row }: CellProps<PWItem>): JSX.Element {
  const { connection, contextId } = usePluginContext();

  const {
    workAreaManager: { itemExistsInConnection }
  } = useAppViewContext();

  const item = row.original;

  const [refreshConnectionCheck, setRefreshConnectionCheck] =
    useState<boolean>(false);

  const inConnection = useMemo(
    () => itemExistsInConnection(item),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item, itemExistsInConnection, refreshConnectionCheck]
  );

  const parentPlaceholder = {
    Name: '',
    className: 'Project',
    instanceId: item.ParentGuid,
    TypeString: 'Folder'
  } as PWProject;

  const parent = parseRelatedParent(item) ?? parentPlaceholder;

  const {
    tableItemAction: { onItemClick }
  } = useTableItemContext();

  const onActionClick = useCallback((): void => {
    const parent = parseRelatedParent(item);

    if (!inConnection || !parent) {
      return;
    }

    onItemClick(parent, item);
  }, [inConnection, item, onItemClick]);

  const parentName = useMemo((): string => {
    if (
      connection.Canned &&
      contextId &&
      parent.Name.includes(contextId) &&
      parent.Name.includes('|')
    ) {
      return t('Generic.Documents');
    }

    if (parent.instanceId == connection.ProjectId) {
      return connection.Name;
    }

    return parent.Name;
  }, [connection, contextId, parent.Name, parent.instanceId]);

  const tooltipText = useMemo((): string => {
    if (inConnection === false) {
      return `${t('Columns.InExternalWA', {
        name: parentName
      })}`;
    }

    return parentName;
  }, [inConnection, parentName]);

  const linkClass = useMemo((): string => {
    if (inConnection === true) {
      return 'row-link';
    }

    if (inConnection === false) {
      return 'external-link';
    }

    return '';
  }, [inConnection]);

  return (
    <PWTooltip content={tooltipText}>
      <Text
        as="span"
        className={`pw-cell-text ${linkClass}`}
        onClick={onActionClick}
        onKeyPress={keyPressAsClick(onActionClick)}
        onMouseEnter={() => setRefreshConnectionCheck((cur) => !cur)}
        role="link"
        tabIndex={0}
        data-col="ParentName"
        data-val={parentName}
      >
        {parentName}
      </Text>
    </PWTooltip>
  );
}
