import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Checkbox,
  Fieldset,
  InputGrid,
  Radio,
  StatusMessage
} from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useProjectSettingsContext,
  useTableItemContext
} from '../../context';
import { t } from '../../services/translation';
import type { DynamicProjectSettings } from '../dynamicProjectSettings';
import {
  defaultProjectSettings,
  getProjectSettings
} from '../dynamicProjectSettings';
import {
  deleteDescendantSettings,
  deleteFolderSettings,
  getFolderSettings,
  postFolderSettings
} from './dynamicFolderSettingsData';
import { useFolderPermissions } from './useFolderPermissions';

export type DynamicFolderSettings = {
  UseDCWInFoldersWithCode: boolean;
  UseDCWInFoldersWithoutCode: boolean;
  UseDCWInPWIC: boolean;
  AutoCheckoutDocsInDrive: boolean;
  InheritProjectSettings: boolean;
  SettingsInherited: boolean;
};

type WizardSetting = 'useWizard' | 'doNotUseWizard' | 'useProjectSettings';

export function FolderSettingsModal(): JSX.Element {
  const INHERIT_SETTINGS_MSG = t('FolderSettings.InheritSettingsMessage');
  const WIZARD_SETTINGS_MSG = t('FolderSettings.WizardSettingsMessage');
  const DONT_USE_WIZARD = t('FolderSettings.DoNotUseWizard');
  const USE_WIZARD = t('FolderSettings.UseWizard');
  const USE_DEFAULT_SETTINGS = t('FolderSettings.UseDefaultSettings');
  const AUTO_CHECKOUT_MSG = t('ProjectSettings.AutoCheckoutMessage');
  const NOTE = t('Generic.Note');
  const AUTO_CREATION_NOTE = t('ProjectSettings.AutoCreationNote');
  const FORCE_CHILD_INHERIT_MSG = t('FolderSettings.ForceChildInheritMessage');
  const DISABLED_MESSAGE = t('FolderSettings.DisabledMessage');
  const DRIVE_SETTINGS_ERROR = t('FolderSettings.DriveServiceError');

  const { organizationId, httpService: driveHttpService } =
    useProjectSettingsContext();
  const wsgHttpService = useHttpService();
  const { connection, contextId: projectId } = usePluginContext();
  const {
    folderSettingsManager: { reloadFolderSettings }
  } = useAppViewContext();
  const { trackFeature } = useFeatureTracking();
  const { isAdmin } = useConnectionAuth();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    breadcrumbManager: { breadcrumbs },
    primaryModal
  } = useNavigationContext();
  const { actionableItems, rootFolderSelected } = useTableItemContext();
  const folderId = actionableItems[0].instanceId;

  const folderSettingsEnabled = useFolderPermissions();

  const [projectSettings, setProjectSettings] =
    useState<DynamicProjectSettings>(defaultProjectSettings);

  const PROJECT_SETTINGS_MSG = useMemo(() => {
    if (connection.Canned) {
      return projectSettings?.UseDCWInPWIC
        ? t('FolderSettings.ProjectSettingsDcwAlways')
        : t('FolderSettings.ProjectSettingsNoDcw');
    }

    if (
      projectSettings?.UseDCWInFoldersWithCode &&
      projectSettings.UseDCWInFoldersWithoutCode
    ) {
      return t('FolderSettings.ProjectSettingsDcwAlways');
    }

    if (projectSettings?.UseDCWInFoldersWithCode) {
      return t('FolderSettings.ProjectSettingsDcwWithCode');
    }

    if (projectSettings?.UseDCWInFoldersWithoutCode) {
      return t('FolderSettings.ProjectSettingsDcwNoCode');
    }

    return t('FolderSettings.ProjectSettingsNoDcw');
  }, [connection.Canned, projectSettings]);

  const [inheritSettings, setInheritSettings] = useState<boolean>(false);
  const [inheritedSettings, setInheritedSettings] =
    useState<DynamicFolderSettings>();
  const [initialSettings, setInitialSettings] =
    useState<DynamicFolderSettings>();

  const [selectedWizardSetting, setSelectedWizardSetting] =
    useState<WizardSetting>('useProjectSettings');
  const [autoCheckoutDocsInDrive, setAutoCheckoutDocsInDrive] =
    useState<boolean>(projectSettings?.AutoCheckoutDocsInDrive ?? false);
  const [forceDescendantInheritance, setForceDescendantInheritance] =
    useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [controlsDisabled, setControlsDisabled] = useState<boolean>(false);
  const [driveSettingsError, setDriveSettingsError] = useState<boolean>(false);

  const initializeUI = useCallback(
    (settings?: DynamicFolderSettings): void => {
      const wizardSetting: WizardSetting = settings?.InheritProjectSettings
        ? 'useProjectSettings'
        : settings?.UseDCWInFoldersWithCode ||
          settings?.UseDCWInFoldersWithoutCode
        ? 'useWizard'
        : 'doNotUseWizard';
      setSelectedWizardSetting(wizardSetting);
      setAutoCheckoutDocsInDrive(settings?.AutoCheckoutDocsInDrive ?? false);
      setForceDescendantInheritance(false);
      setInheritSettings(
        (settings?.SettingsInherited ?? false) && !rootFolderSelected
      );
    },
    [rootFolderSelected]
  );

  const gatherSettings = useCallback((): DynamicFolderSettings => {
    return selectedWizardSetting == 'useProjectSettings'
      ? ({
          AutoCheckoutDocsInDrive: autoCheckoutDocsInDrive,
          UseDCWInFoldersWithCode: projectSettings.UseDCWInFoldersWithCode,
          UseDCWInFoldersWithoutCode:
            projectSettings.UseDCWInFoldersWithoutCode,
          UseDCWInPWIC: projectSettings.UseDCWInPWIC,
          InheritProjectSettings: true,
          SettingsInherited: inheritSettings
        } as DynamicFolderSettings)
      : ({
          AutoCheckoutDocsInDrive: autoCheckoutDocsInDrive,
          UseDCWInFoldersWithCode: selectedWizardSetting == 'useWizard',
          UseDCWInFoldersWithoutCode: selectedWizardSetting == 'useWizard',
          UseDCWInPWIC: selectedWizardSetting == 'useWizard',
          InheritProjectSettings: false,
          SettingsInherited: inheritSettings
        } as DynamicFolderSettings);
  }, [
    autoCheckoutDocsInDrive,
    inheritSettings,
    projectSettings,
    selectedWizardSetting
  ]);

  const settingsAreEqual = useCallback((): boolean => {
    if (!initialSettings?.SettingsInherited && inheritSettings) {
      return false;
    }
    if (initialSettings?.SettingsInherited && !inheritSettings) {
      return false;
    }
    const currentSettings = gatherSettings();
    return (
      currentSettings.InheritProjectSettings ==
        initialSettings?.InheritProjectSettings &&
      currentSettings.AutoCheckoutDocsInDrive ==
        initialSettings.AutoCheckoutDocsInDrive &&
      currentSettings.SettingsInherited == initialSettings.SettingsInherited &&
      currentSettings.UseDCWInFoldersWithCode ==
        initialSettings.UseDCWInFoldersWithCode &&
      currentSettings.UseDCWInFoldersWithoutCode ==
        initialSettings.UseDCWInFoldersWithoutCode
    );
  }, [gatherSettings, inheritSettings, initialSettings]);

  useEffect(() => {
    if (folderSettingsEnabled === undefined) {
      return;
    }

    setInitialized(false);

    const abortController = new AbortController();

    async function initializeFolderSettings(): Promise<void> {
      if (!driveHttpService) {
        throw new Error('Drive http service not initialized');
      }

      try {
        const projectSettings = await getProjectSettings(
          projectId ?? '',
          organizationId,
          driveHttpService,
          { abortController, uncached: true }
        );
        setProjectSettings(projectSettings);
        setAutoCheckoutDocsInDrive(projectSettings.AutoCheckoutDocsInDrive);

        const folderSettings = await getFolderSettings(
          connection.DatasourceInstanceId,
          connection.ProjectId,
          projectId ?? '',
          organizationId,
          folderId,
          projectSettings,
          breadcrumbs.map((bc) => bc.instanceId),
          driveHttpService,
          { abortController }
        );

        if (folderSettings?.SettingsInherited) {
          setInheritedSettings(folderSettings);
        }

        setInitialSettings(folderSettings);
        initializeUI(folderSettings);
      } catch (e) {
        console.error(e);
        setDriveSettingsError(true);
      } finally {
        setInitialized(true);
      }
    }

    void initializeFolderSettings();

    return () => {
      abortController.abort();
    };
  }, [
    breadcrumbs,
    connection,
    driveHttpService,
    folderId,
    initializeUI,
    organizationId,
    projectId,
    folderSettingsEnabled
  ]);

  useEffect(() => {
    if (!initialized || !initialSettings) {
      return;
    }
    if (forceDescendantInheritance) {
      setSaveDisabled(false);
      return;
    }
    if (settingsAreEqual()) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [
    initialSettings,
    initialized,
    inheritSettings,
    selectedWizardSetting,
    forceDescendantInheritance,
    autoCheckoutDocsInDrive,
    settingsAreEqual
  ]);

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (!folderSettingsEnabled) {
      setControlsDisabled(true);
    } else {
      setControlsDisabled(inheritSettings && !rootFolderSelected);
    }
  }, [folderSettingsEnabled, rootFolderSelected, inheritSettings, initialized]);

  async function submitNewProjectSettings(): Promise<void> {
    if (!driveHttpService) {
      throw new Error('Drive http service not initialized');
    }

    if (forceDescendantInheritance) {
      setInitialized(false);
      await deleteDescendantSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        folderId,
        trackFeature,
        wsgHttpService,
        driveHttpService,
        ecPluginFeatures.userFolderPermissions(),
        isAdmin
      );
      setInitialized(true);
    }
    primaryModal.close();
    const folderSettings = gatherSettings();
    if (_.isEqual(folderSettings, initialSettings)) {
      return;
    }
    if (inheritSettings) {
      await deleteFolderSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        folderId,
        trackFeature,
        driveHttpService
      );
    } else {
      await postFolderSettings(
        connection.DatasourceInstanceId,
        connection.ProjectId,
        projectId ?? '',
        folderId,
        folderSettings,
        projectSettings,
        trackFeature,
        driveHttpService,
        folderSettings.AutoCheckoutDocsInDrive !=
          initialSettings?.AutoCheckoutDocsInDrive
      );
    }
    reloadFolderSettings();
  }

  function onInheritSettingsClicked(inheritChecked: boolean): void {
    setInheritSettings(inheritChecked);
    if (inheritChecked && inheritedSettings) {
      initializeUI(inheritedSettings);
    }
  }

  return (
    <PWModal
      title={t('ProjectSettings.ProjectSettingsTitle')}
      isLoading={!initialized}
      primaryButton={{
        title: t('Generic.Save'),
        onClick: () => void submitNewProjectSettings(),
        disabled: saveDisabled || !folderSettingsEnabled,
        'data-testid': 'saveButton'
      }}
      secondaryButton={{
        title: driveSettingsError ? t('Generic.Close') : t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
    >
      {driveSettingsError ? (
        <Alert type="negative" data-testid="driveSettingsErrorMessage">
          {DRIVE_SETTINGS_ERROR}
        </Alert>
      ) : (
        <>
          {folderSettingsEnabled === false && (
            <Alert type="warning">{DISABLED_MESSAGE}</Alert>
          )}
          {!rootFolderSelected && (
            <div>
              <Checkbox
                label={INHERIT_SETTINGS_MSG}
                checked={inheritSettings}
                onChange={(value) =>
                  onInheritSettingsClicked(value.target.checked)
                }
                disabled={!folderSettingsEnabled}
                data-testid="inheritSettingsCheckbox"
              />
            </div>
          )}
          <Fieldset legend={WIZARD_SETTINGS_MSG} disabled={controlsDisabled}>
            <Radio
              label={USE_WIZARD}
              name="wizard_settings"
              value="useWizard"
              checked={selectedWizardSetting == 'useWizard'}
              disabled={controlsDisabled}
              onClick={() => setSelectedWizardSetting('useWizard')}
              data-testid="useWizardRadio"
              onChange={() => {
                /* noop */
              }}
            />
            <Radio
              label={DONT_USE_WIZARD}
              name="wizard_settings"
              value="doNotUseWizard"
              checked={selectedWizardSetting == 'doNotUseWizard'}
              disabled={controlsDisabled}
              onClick={() => setSelectedWizardSetting('doNotUseWizard')}
              data-testid="doNotUseWizardRadio"
              onChange={() => {
                /* noop */
              }}
            />
            <Radio
              label={`${USE_DEFAULT_SETTINGS} (${PROJECT_SETTINGS_MSG})`}
              name="wizard_settings"
              value="useProjectSettings"
              checked={selectedWizardSetting == 'useProjectSettings'}
              disabled={controlsDisabled}
              onClick={() => setSelectedWizardSetting('useProjectSettings')}
              data-testid="defaultSettingsRadio"
              onChange={() => {
                /* noop */
              }}
            />
          </Fieldset>
          <InputGrid>
            <Checkbox
              label={AUTO_CHECKOUT_MSG}
              checked={autoCheckoutDocsInDrive}
              disabled={controlsDisabled}
              onChange={() => {
                setAutoCheckoutDocsInDrive(!autoCheckoutDocsInDrive);
              }}
              data-testid="autoCheckoutCheckbox"
            />
            <StatusMessage>
              {NOTE}
              {AUTO_CREATION_NOTE}
            </StatusMessage>
          </InputGrid>
          <Checkbox
            label={FORCE_CHILD_INHERIT_MSG}
            checked={forceDescendantInheritance}
            onChange={() =>
              setForceDescendantInheritance(!forceDescendantInheritance)
            }
            disabled={!folderSettingsEnabled}
            data-testid="forcedInheritanceCheckbox"
          />
        </>
      )}
    </PWModal>
  );
}
