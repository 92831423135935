import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import type { UploadNode } from '../../../actions/upload';
import type { CloseModal } from '../../../hooks/useModal';
import type { ToastHandle } from '../../../services/pwToast';
import type { OnCancelWizardFunction } from '../cancel';
import type { OnDocumentCreatedFunction, RefreshAndSelect } from '../create';
import { DCWLoadingSpinner } from '../loading';
import { DCWStateProvider } from '../state';
import { WizardContainer, WizardFunctionsProvider } from '../wizard';
import type { CopyFunction, UploadFunction } from '../wizard/functionsContext';
type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type DCWProps = {
  defaultDocumentDescription: string;
  defaultDocumentName: string;
  fileName: string;
  fileNumber: number;
  isCopyMode?: boolean;
  isFlatSetCreationMode?: boolean;
  numberOfFiles: number;
  presetEnvironmentInstance?: WSGInstance;
  projectId: string;
  synchronous?: boolean;
  toastHandle?: ToastHandle;
  totalFileCount: number;
  uploadNode: UploadNode;
  version: string;
  closeModal: CloseModal;
  onCancel: OnCancelWizardFunction;
  onSuccess: OnDocumentCreatedFunction;
  refreshAndSelect?: RefreshAndSelect;
} & RequireOnlyOne<
  {
    copy: CopyFunction;
    upload: UploadFunction;
  },
  'copy' | 'upload'
>;

export function DocumentCodeWizard({
  closeModal,
  onCancel,
  onSuccess,
  refreshAndSelect,
  upload,
  copy,
  ...stateProps
}: DCWProps): JSX.Element {
  return (
    <RecoilRoot key={stateProps.fileName}>
      <Suspense fallback={<DCWLoadingSpinner />}>
        <DCWStateProvider {...stateProps}>
          <WizardFunctionsProvider
            value={{
              closeModal,
              onCancel,
              onSuccess,
              refreshAndSelect,
              upload,
              copy
            }}
          >
            <WizardContainer />
          </WizardFunctionsProvider>
        </DCWStateProvider>
      </Suspense>
    </RecoilRoot>
  );
}
