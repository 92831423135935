import type { HttpService } from '@bentley/pw-api';

let _httpService: HttpService;

export function getAttributesHttpService(): HttpService {
  if (!_httpService) {
    throw new Error(
      'Http service has not been configured. Use setAttributesHttpService'
    );
  }

  return _httpService;
}

export function setAttributesHttpService(httpService: HttpService): void {
  _httpService = httpService;
}
