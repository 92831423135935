import React, { useCallback, useMemo } from 'react';
import type { PWDataItem, PWFileType, PWItem } from '@bentley/pw-api';
import { SvgSwap } from '@itwin/itwinui-icons-react';
import { showTakeOverCoAuthoringSession } from '../../../../actions/useGraphApi/requirements';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useGraphApiContext,
  useTableItemContext
} from '../../../../context';
import { useOutToMeSearch } from '../../../../hooks/useOutToMeSearch';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useTakeOverCoAuthoringSessionAction(
  items: PWItem[]
): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const graphApiManager = useGraphApiContext();

  const {
    dataManager: { refreshCurrentFolder },
    selectedDocumentsPermissions
  } = useTableItemContext();
  const { office365CoAuthoringSettings, userManager } = useEnvironmentContext();

  const item = items[0] as PWDataItem;

  const outToMe = useOutToMeSearch();

  const refreshOutToMe = useCallback((): void => {
    void outToMe();
  }, [outToMe]);

  const coAuthoringTakeOver = useMemo((): MenuItemAction => {
    const session = item?.instanceId
      ? graphApiManager.getCoAuthoringSession(item.instanceId)
      : undefined;
    const onComplete = () => {
      refreshCurrentFolder();
      refreshOutToMe();
    };
    const onClickFunc = graphApiManager.takeOverCoAuthoringSession;

    const title = t('MenuItem.TakeOverCoAuthoringSession');

    return {
      title: title,
      icon: <SvgSwap />,
      onClick: () => void onClickFunc(item as PWFileType, onComplete),
      hidden: !showTakeOverCoAuthoringSession(
        items,
        office365CoAuthoringSettings,
        launchDarklyFeatures.showCoauthoringUpdates as boolean,
        selectedDocumentsPermissions,
        userManager.currentUserId,
        session
      )
    };
  }, [
    graphApiManager,
    item,
    items,
    refreshCurrentFolder,
    refreshOutToMe,
    launchDarklyFeatures.showCoauthoringUpdates,
    selectedDocumentsPermissions,
    office365CoAuthoringSettings,
    userManager.currentUserId
  ]);

  return coAuthoringTakeOver;
}
