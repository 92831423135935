import type { WSGInstancesResponse, WSGProperties } from '@bentley/pw-api';
import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyVersionCreateStart(): ToastHandle {
  const content = t('Version.CreatingVersion');
  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyVersionCreated(
  toastHandle: ToastHandle,
  name: string,
  version?: string,
  items?: number
): void {
  let content;
  if (items && items > 1) {
    content = t('Version.CreatedVersionsOf', { items });
  } else {
    content = version
      ? t('Version.Created', { name, version })
      : t('Version.CreatedVersionOf', { name });
  }

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function notifyVersionsCreated(
  toastHandle: ToastHandle,
  items: number,
  version?: string
): void {
  if (items != 0) {
    const content = t('Version.CreatedVersionsOf', { items });
    replaceToast(toastHandle, { content, category: 'positive' });
  } else {
    toastHandle.close();
  }
}

export async function notifyVersionError(
  toastHandle: ToastHandle,
  response: Response
): Promise<void> {
  const content = await buildErrorMessage(response);
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyVersionsError(
  toastHandle: ToastHandle,
  failure: number
): void {
  const content = t('Version.UnableToCreateVersions', {
    number: failure
  });
  openToast({ content, category: 'negative' });
}

async function buildErrorMessage(response: Response): Promise<string> {
  const data = (await response.json()) as WSGInstancesResponse;
  if (data.errorMessage) {
    if (data.errorMessage.includes('Error creating new version')) {
      return data.errorMessage;
    }
    return t('Version.UnableToCreateVersion', {
      errorMsg: data.errorMessage
    });
  }
  return t('Version.UnableToCreateVersion', {
    errorMsg: t('Generic.UnknownErrorOccurred')
  });
}

export function notifyLogicalSetVersionError(
  toastHandle: ToastHandle,
  messages: WSGProperties[]
): void {
  const content = messages[0].Message;
  replaceToast(toastHandle, { content, category: 'negative' });
}
