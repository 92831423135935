import type {
  ECClassDef,
  ECPropertyDef,
  HttpService,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  flattenProperties,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import { logError } from '../../useErrorLogging';
import type { Attribute } from '../environmentAttributes/attributeDefinitions';

export async function getProjectTypes(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<ECClassDef[]> {
  const filter = "ClassHasBaseClass-forward-ECClassDef.Name eq 'ProjectType'";
  const select = 'Name,DisplayLabel';
  const query = `MetaSchema/ECClassDef?$filter=${filter}&$select=${select}`;

  const response = await httpService.get(query, {
    cacheSuffix: 'persistent',
    ...httpOptions
  });

  const projectTypes = await parseProjectTypes(response);
  return projectTypes;
}

async function parseProjectTypes(response: Response): Promise<ECClassDef[]> {
  if (!response.ok) {
    logError('Error loading project types', { response });
    return [];
  }

  const data = (await response.json()) as WSGInstancesResponse;

  const classDefinitions = data.instances.map(
    flattenProperties
  ) as ECClassDef[];

  return classDefinitions;
}

export async function getProjectPropertyDefinitions(
  projectType: ECClassDef,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<Attribute[]> {
  const select =
    'ECPropertyDef.Name,ECPropertyDef.TypeName,ECPropertyDef.DisplayLabel';
  const query = `MetaSchema/ECClassDef/${projectType.instanceId}?$select=${select}`;

  const response = await httpService.get(query, {
    cacheSuffix: 'persistent',
    ...httpOptions
  });

  const projectPropertyDefinitions = await parseProjectPropertyDefinitions(
    response,
    projectType
  );
  return projectPropertyDefinitions;
}

async function parseProjectPropertyDefinitions(
  response: Response,
  projectType: ECClassDef
): Promise<Attribute[]> {
  if (!response.ok) {
    logError('Error loading project property definitions', { response });
    return [];
  }

  const instances = await parseResponseRelationshipInstances<ECPropertyDef>(
    response
  );

  const environmentClass = projectType.instanceId.split('~3A').pop();
  const propertyDefinitions = instances.map(
    (instance) =>
      ({
        binding: '',
        environmentClass: environmentClass,
        environmentLabel: projectType.DisplayLabel,
        label: instance.DisplayLabel ?? '',
        name: instance.Name ?? '',
        typeName: instance.TypeName ?? ''
      } as Attribute)
  );
  return propertyDefinitions;
}
