import type { PWDataItem, WSGRelationshipInstance } from '@bentley/pw-api';
import type { Command } from './wreCommands';

export function setItemCommands(
  item: PWDataItem,
  commands: Command[] | undefined
): void {
  removeItemCommands(item);

  if (!item.relationshipInstances?.length) {
    item.relationshipInstances = [];
  }

  const relationshipInstances =
    commands?.map(
      (command) =>
        ({
          schemaName: 'PW_WSG',
          className: 'DocumentCommand',
          direction: 'forward',
          relatedInstance: command
        } as WSGRelationshipInstance)
    ) ?? [];

  item.relationshipInstances.push(...relationshipInstances);
}

function removeItemCommands(item: PWDataItem): void {
  const nonCommandRelationshipInstances = item.relationshipInstances?.filter(
    (relationshipInstance) =>
      relationshipInstance.className !== 'DocumentCommand'
  );

  item.relationshipInstances = nonCommandRelationshipInstances;
}

export function getItemCommands(item: PWDataItem): Command[] | undefined {
  const commands = item.relationshipInstances?.filter(
    (relationshipInstance) =>
      relationshipInstance.className === 'DocumentCommand'
  );

  return commands?.map((command) => command.relatedInstance as Command);
}
