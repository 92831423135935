import { useCallback, useMemo } from 'react';
import type { GetToken } from '../../../../../packages/projectwise/src';
import { PublicServiceToken } from '../../services/support/publicServiceToken';
import { useUserManager } from '../useUserManager';
import { useOidcEventTracker } from './useOidcEventTracker';
import type { UserManagerWithSaml } from './userManagerWithSaml';
import { isValidSamlUser } from './validSaml';

export type OIDCFunctions = {
  userManager: UserManagerWithSaml;
  getSamlToken: GetToken;
  getOidcToken: GetToken;
  onUserSignedOut: () => Promise<void>;
  signInRedirect: () => Promise<void>;
};

export function useOidc(): OIDCFunctions {
  const { userManager } = useUserManager();
  const { trackEvent } = useOidcEventTracker();

  const samlScope = 'sso://pw-integration-server/1016';

  const getSamlToken = useCallback(async (): Promise<string> => {
    if (PublicServiceToken.enabled) {
      const samlToken = await PublicServiceToken.getToken('SAML');
      return samlToken;
    }

    const user = await userManager.getUser();
    const samlUser = await userManager.getSamlUser(samlScope);

    if (isValidSamlUser(samlUser)) {
      return samlUser.access_token;
    }

    const samlUserRefreshed = await userManager.getSamlUser(samlScope, true);

    if (!isValidSamlUser(samlUserRefreshed)) {
      trackEvent('Saml token issue', user, samlUserRefreshed);
      throw new Error('Unable to retrieve saml token');
    } else {
      trackEvent('Saml token fixed', user, samlUser);
    }

    return samlUserRefreshed.access_token;
  }, [trackEvent, userManager]);

  const getOidcToken = useCallback(async (): Promise<string> => {
    let user = await userManager.getUser();

    if (user?.expired) {
      user = (await userManager.signinSilent()) ?? null;
    }

    return user?.access_token ?? '';
  }, [userManager]);

  const signInRedirect = useCallback(async (): Promise<void> => {
    if (window.self !== window.top) {
      // in iFrame - so silent redirect
      await userManager?.signinSilentCallback();
      return;
    }

    try {
      const user = await userManager.signinRedirectCallback();
      const path = (user?.state as string) ?? '';
      const href = `${window.location.origin}/${path}`;
      window.location.href = href;
    } catch (error) {
      console.error(error);
      window.location.pathname = '/';
    }
  }, [userManager]);

  const onUserSignedOut = useCallback(async (): Promise<void> => {
    await userManager.removeSamlUser();

    await signInRedirect();
  }, [signInRedirect, userManager]);

  const oidcFunctions = useMemo(
    (): OIDCFunctions => ({
      userManager,
      getSamlToken,
      getOidcToken,
      onUserSignedOut,
      signInRedirect
    }),
    [getSamlToken, getOidcToken, onUserSignedOut, signInRedirect, userManager]
  );

  return oidcFunctions;
}
