import type { PWProject } from '@bentley/pw-api';

export function addParentToNode(item: PWProject, parent: PWProject): void {
  item.relationshipInstances ??= [];

  if (
    item.relationshipInstances.find(
      (instance) => instance.className == 'ProjectParent'
    )
  ) {
    return;
  }

  item.relationshipInstances.push({
    schemaName: 'PW_WSG',
    className: 'ProjectParent',
    direction: 'forward',
    relatedInstance: parent
  });
}
