import React from 'react';
import { SvgChevronDown, SvgChevronRight } from '@itwin/itwinui-icons-react';

type ChevronProps = {
  hasChildren: boolean;
  isExpanded: boolean;
} & React.SVGProps<SVGSVGElement>;

export function Chevron({
  hasChildren,
  isExpanded,
  ...rest
}: ChevronProps): JSX.Element | null {
  function className(): string {
    if (hasChildren) {
      return 'tree-node-chevron';
    }
    return 'tree-node-chevron chevron-hidden';
  }

  if (isExpanded) {
    return <SvgChevronDown className={className()} {...rest} />;
  }

  return <SvgChevronRight className={className()} {...rest} />;
}
