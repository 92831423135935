// Changing conform to PWDI team using the year as the first part of the version
export type WsgVersion = {
  year: number;
  major: number;
  minor: number;
  patch: number;
};

export function isValidVersionString(versionString?: string): boolean {
  if (!versionString) {
    return false;
  }
  const versionStringParts = versionParts(versionString);
  if (versionStringParts.length != 4) {
    return false;
  }

  if (versionStringParts.some((part) => isNaN(Number(part)))) {
    return false;
  }

  return true;
}

export function buildWsgVersion(
  versionString?: string
): WsgVersion | undefined {
  if (!versionString || !isValidVersionString(versionString)) {
    return undefined;
  }
  return {
    year: yearVersion(versionString),
    major: majorVersion(versionString),
    minor: minorVersion(versionString),
    patch: patchVersion(versionString)
  };
}

function yearVersion(versionString: string): number {
  const versionStringParts = versionParts(versionString);
  const majorVersion = Number(versionStringParts[0]);
  return majorVersion;
}

function majorVersion(versionString: string): number {
  const versionStringParts = versionParts(versionString);
  const minorVersion = Number(versionStringParts[1]);
  return minorVersion;
}

function minorVersion(versionString: string): number {
  const versionStringParts = versionParts(versionString);
  const patchVersion = Number(versionStringParts[2]);
  return patchVersion;
}

function patchVersion(versionString: string): number {
  const versionStringParts = versionParts(versionString);
  const buildVersion = Number(versionStringParts[3]);
  return buildVersion;
}

function versionParts(versionString: string): string[] {
  return versionString.split('.');
}
