import type { HttpService, PWFileType, PWItem } from '@bentley/pw-api';
import { itemIsLogicalSet } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager';
import type { FeatureName } from '../../hooks/useTrackFeature';
import type { ToastHandle } from '../../services/pwToast';
import { confirmLargeDownload } from './eventHandlers';
import {
  logicalSetDownloadModal,
  openDownloadFailureSummaryModal
} from './modals';
import {
  notifyDownloadAborted,
  notifyDownloadError,
  notifyDownloadInProgress,
  notifySuccess
} from './notifications';
import { allowDownload } from './requirements';
import type { DownloadResult } from './utils';
import {
  downloadBlobWorkflow,
  isMultiItemDownload,
  performDownloadInBrowser,
  trackDownloadFeatures,
  verifyDownloadItemsData
} from './utils';
import { zipAndDownloadItems } from './zip';

export async function download(
  items: PWItem[],
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  progressManager: ProgressManager,
  trackFeature: (featureName: FeatureName) => void,
  consumerApp?: ConsumerApp,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  searchName?: string,
  useAttributeExchange?: boolean
): Promise<DownloadResult | void> {
  const itemsToDownload = items.filter(allowDownload);
  if (itemsToDownload.some(itemIsLogicalSet)) {
    return logicalSetDownloadModal(
      openModal,
      closeModal,
      itemsToDownload,
      httpService,
      trackFeature,
      progressManager,
      consumerApp,
      searchName,
      useAttributeExchange
    );
  } else {
    return downloadZippedItems(
      itemsToDownload,
      httpService,
      openModal,
      closeModal,
      trackFeature,
      progressManager,
      false,
      false,
      consumerApp,
      searchName,
      undefined,
      undefined,
      filterLatestVersionItems,
      useAttributeExchange
    );
  }
}

export async function downloadZippedItems(
  items: PWItem[],
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: (featureName: FeatureName) => void,
  progressManager: ProgressManager,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean,
  consumerApp?: ConsumerApp,
  searchName?: string,
  toastHandle?: ToastHandle,
  jobId?: string,
  filterLatestVersionItems?: (items: PWItem[]) => PWItem[],
  useAttributeExchange?: boolean
): Promise<DownloadResult | undefined> {
  if (!toastHandle || !jobId) {
    toastHandle = notifyDownloadInProgress();
    jobId = progressManager.addNewJobToTracker('download', toastHandle);
  }
  progressManager.progressTracker.cancelAllDownloads = false;
  const multiItemDownload = isMultiItemDownload(items, downloadReferencedFiles);
  try {
    items = await verifyDownloadItemsData(items, httpService);

    const downloadRequest = multiItemDownload
      ? zipAndDownloadItems(
          items,
          httpService,
          jobId,
          progressManager,
          downloadReferencedFiles,
          downloadRecursiveReferences,
          confirmLargeDownload(openModal, closeModal, toastHandle),
          toastHandle,
          filterLatestVersionItems,
          searchName,
          useAttributeExchange
        )
      : downloadSingleItem(
          items[0] as PWFileType,
          httpService,
          jobId,
          progressManager,
          confirmLargeDownload(openModal, closeModal, toastHandle),
          useAttributeExchange
        );

    const downloadResults = await downloadRequest;
    if (
      downloadResults &&
      !progressManager.progressTracker.cancelAllDownloads
    ) {
      trackDownloadFeatures(items, trackFeature, downloadResults.zip);
      notifySuccess(toastHandle, consumerApp);
    } else if (
      downloadResults &&
      progressManager.progressTracker.cancelAllDownloads
    ) {
      trackDownloadFeatures(items, trackFeature, downloadResults.zip);
      notifyDownloadAborted(toastHandle);
    }
    return downloadResults;
  } catch (error) {
    toastHandle.close();
    notifyDownloadError(
      (error as Error).message != undefined
        ? (error as Error).message
        : 'Download aborted',
      items[0],
      toastHandle,
      progressManager.progressTracker.cancelAllDownloads
    );
    throw error;
  } finally {
    const job = progressManager.progressTracker.jobsInProgress?.find(
      (jip) => jip.jobId == jobId
    );
    void openDownloadFailureSummaryModal(openModal, closeModal, job);
  }
}

/**
 * Download workflow for a single item
 * @param {PWFileType} item Item to download
 * @param {HttpService} httpService Configured http service
 * @param {string} jobId Id of job that download is part of
 * @param {ProgressManager} progressManager Tracker object used to keep track of download progress
 * @param {(progressManager: ProgressManager) => void} progressCallback Callback for download status updates
 * @param {(downloadSize: number) => Promise<boolean>} confirmLargeDownload User response to a modal prompt if download is large
 * @returns {Promise<DownloadResult | undefined>} Object with info about download
 */
async function downloadSingleItem(
  item: PWFileType,
  httpService: HttpService,
  jobId: string,
  progressManager: ProgressManager,
  confirmLargeDownload: (downloadSize: number) => Promise<boolean>,
  useAttributeExchange?: boolean
): Promise<DownloadResult | undefined> {
  const downloadSize = Number(item.FileSize);

  const proceedWithDownload = await confirmLargeDownload(downloadSize);
  if (!proceedWithDownload) {
    return;
  }

  const abortController = new AbortController();
  const uniqueId = progressManager.addDownloadToJob(
    jobId,
    item,
    abortController
  );

  const blobToDownload = await downloadBlobWorkflow(
    item,
    httpService,
    jobId,
    uniqueId,
    abortController,
    progressManager,
    useAttributeExchange
  );
  performDownloadInBrowser(blobToDownload, item.FileName);
  return { blob: blobToDownload };
}
