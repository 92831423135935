import type { ProviderProps } from 'react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  SvgProjectWiseWeb,
  SvgProjectWiseWebView
} from '../../../../../packages/projectwise/src/components/icons';
import { LoadingPage } from '../../../../../packages/projectwise/src/components/loadingPage';
import type { DataAccessLevel } from '../../../../../packages/projectwise/src/context';
import { t } from '../../services/translation';
import { loadConfig } from './loadConfig';

// HACK:publicServiceToken uses this to get
// config out of context; should rewrite to use this hook instead
export let __globalConfig = {} as Config;

const Context = createContext<ConfigContext | undefined>(undefined);

export type Config = {
  BUDDIRegionCode: '101' | '102' | '103';
  ['BUDDI.URL']: string;
  FeatureToggleClientKey: string;
  AppInsightsInstrumentationKey: string;
  AuthorityForPublicServices: string;
  OIDC_Authority: string;
  OIDC_IMSScope: string;
  OIDC_ClientId: string;
  OIDC_ServiceScope: string;
  AppVersion: string;
  branchName: string;
  ReadOnly: 'true' | 'false';
  DataAccessLevel: DataAccessLevel;
  ProductId: '2957' | '3060';
} & { [key: string]: string };

type ConfigContext = {
  config: Config;
  productIcon: JSX.Element;
  productName: string;
};

export function ConfigProvider({
  children
}: ProviderProps<undefined>): JSX.Element {
  const [config, setConfig] = useState<Config>();

  const productIcon = useMemo((): JSX.Element => {
    if (config?.DataAccessLevel == 'Datasource') {
      return <SvgProjectWiseWebView data-testid="webview-icon" />;
    }
    return <SvgProjectWiseWeb data-testid="web-icon" />;
  }, [config?.DataAccessLevel]);

  const productName = useMemo((): string => {
    if (config?.DataAccessLevel == 'Datasource') {
      return t('ProductName.ProjectWiseWebView');
    }

    if (config?.DataAccessLevel == 'WorkArea') {
      return t('ProductName.ProjectWiseWeb');
    }

    return '';
  }, [config?.DataAccessLevel]);

  const configContext = useMemo(
    (): ConfigContext =>
      ({ config: config ?? {}, productIcon, productName } as ConfigContext),
    [config, productIcon, productName]
  );

  useEffect(() => {
    async function initializeConfig(): Promise<void> {
      const config = await loadConfig();
      setConfig(config);
      __globalConfig = config;
    }

    void initializeConfig();
  }, []);

  if (!config) {
    return (
      <LoadingPage
        loadingSubText={t('LoadingPage.RetrievingConfigurationSettings')}
        fullscreen={true}
        fromValue={0}
        toValue={25}
      />
    );
  }

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
}

export function useConfigContext(): ConfigContext {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useConfigContext must be used within a ConfigProvider');
  }

  return context;
}
