import type { PWDataItem } from '@bentley/pw-api';
import type {
  OneDriveFileStatusManager,
  OneDriveUserData
} from '../../context';
import type { t } from '../../services/translation';

export type GraphErrorSummaryItem = {
  item: PWDataItem;
  type: 'warning' | 'negative';
  displayOutTo?: boolean;
  errorTextKey?: Parameters<typeof t>[0];
  participants?: OneDriveUserData[];
};

export async function checkForDriveErrors(
  files: PWDataItem[],
  driveStatusManager: OneDriveFileStatusManager,
  withCheckIn = false
): Promise<GraphErrorSummaryItem[]> {
  const errors = await Promise.all(
    files.map(async (file) => {
      if (!driveStatusManager.checkCoAuthoringSessionExists(file)) {
        return undefined;
      }

      if (!withCheckIn && file.IsLocked && !file.IsCheckedOut) {
        return {
          item: file,
          type: 'warning',
          errorTextKey: 'Sync.Coauthoring.AnotherUserCheckedOut',
          displayOutTo: true
        } as GraphErrorSummaryItem;
      }

      const status = await driveStatusManager.getSessionFileStatus(
        file.instanceId
      );

      if (status === 'Editing') {
        return {
          item: file,
          type: 'warning',
          participants: driveStatusManager.getCoAuthoringSessionParticipants(
            file.instanceId
          )
        } as GraphErrorSummaryItem;
      }

      if (!withCheckIn) {
        return undefined;
      }

      switch (status) {
        case 'NotFound':
          return {
            item: file,
            type: 'negative',
            errorTextKey: 'Sync.Coauthoring.OperationNotCompleted'
          } as GraphErrorSummaryItem;
        case 'Error':
          return {
            item: file,
            type: 'negative',
            errorTextKey: 'Sync.Coauthoring.SomethingWentWrong'
          } as GraphErrorSummaryItem;
        default:
          return undefined;
      }
    })
  );

  return errors.filter((error) => error) as GraphErrorSummaryItem[];
}
