import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { t } from '../../../services/translation';
import { DocCodePreview } from '../../docCodePreview';
import {
  formatDocCodeString,
  generateDocCodeProperties
} from '../../documentCreation/docCode';
import { mergePropertiesIntoInstance } from '../../documentCreation/properties';
import { handleDocCodeFormError } from '../error/handleError';
import {
  formDefinitionState,
  formValidState,
  generatingDocCodeState,
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from '../form/state';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState
} from '../state/state';

export function DocCodePreviewContainer(): JSX.Element | null {
  const docCodeFormDefinition = useRecoilValue(formDefinitionState);

  const editingDocCodeForm = useRecoilValue(editingDocCodeFormState);

  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);

  const [generatingDocCode, setGeneratingDocCode] = useRecoilState(
    generatingDocCodeState
  );

  const setFormValidState = useSetRecoilState(formValidState);

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const [docCode, setDocCode] = useState<string>('');

  useEffect(() => {
    async function getDocCode(): Promise<void> {
      if (currentEnvironmentInstance) {
        const docCode = await formatDocCodeString(currentEnvironmentInstance);
        setDocCode(docCode);
      }
    }

    void getDocCode();
  }, [currentEnvironmentInstance, setDocCode]);

  const docCodeUsesSerialNum = useMemo((): boolean => {
    return docCodeFormDefinition?.Type != 'DocCodeWithoutSerialNum';
  }, [docCodeFormDefinition?.Type]);

  const allowGenerateNext = useMemo((): boolean => {
    if (!editingDocCodeForm) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (generatingDocCode) {
      return false;
    }

    return docCodeUsesSerialNum;
  }, [
    docCodeUsesSerialNum,
    editingDocCodeForm,
    generatingDocCode,
    updatingReloadProperties,
    updatingTriggeredProperties
  ]);

  const generateNextTitle = useMemo((): string => {
    if (docCodeUsesSerialNum) {
      return t('DocumentCreation.GenerateNextInfoMessage');
    }

    return t('DocumentCreation.SerialNumberMissing');
  }, [docCodeUsesSerialNum]);

  async function onGenerate(): Promise<void> {
    if (!currentEnvironmentInstance) {
      return;
    }

    try {
      setGeneratingDocCode(true);

      const updatedProperties = await generateDocCodeProperties(
        currentEnvironmentInstance
      );

      if (updatedProperties && Object.keys(updatedProperties).length) {
        const updatedInstance = mergePropertiesIntoInstance(
          currentEnvironmentInstance,
          updatedProperties
        );
        setCurrentEnvironmentInstance(updatedInstance);

        setFormValidState(true);
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        void handleDocCodeFormError(error);
      }
    } finally {
      setGeneratingDocCode(false);
    }
  }

  if (updatingTriggeredProperties) {
    return null;
  }

  return (
    <DocCodePreview
      docCode={docCode}
      allowGenerateNext={allowGenerateNext}
      onGenerate={onGenerate}
      generateNextTitle={generateNextTitle}
    />
  );
}
