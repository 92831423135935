import type { PWItem } from '@bentley/pw-api';
import { itemIsDocument, itemIsLatestVersion } from '@bentley/pw-api';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';

export function allowNewVersion(
  items: PWItem[],
  itemExistsInConnection?: (item: PWItem) => boolean | undefined
): boolean {
  // Removed itemIsLockedOrCheckedOut and itemIsReadOnly checks due to datasource user permissions configuration
  // causing a support issue: https://bentleycs.visualstudio.com/beconnect/_workitems/edit/1033483
  return items.every((item) => {
    if (!item || !item.className || !item.instanceId) {
      return false;
    }

    if (
      !itemIsDocument(item) &&
      (item.className !== 'LogicalSet' || !ecPluginFeatures.logicalSetVersion())
    ) {
      return false;
    }

    if (!itemIsLatestVersion(item)) {
      return false;
    }

    if (itemExistsInConnection && !itemExistsInConnection(item)) {
      return false;
    }

    return true;
  });
}
