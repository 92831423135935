import React from 'react';
import { SvgDelete } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import { t } from '../../../services/translation';
import { Chevron } from '../chevron/chevron';

export function RecycleBinNode(): JSX.Element | null {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  function className(): string {
    if (currentAppView.type == 'RecycleBin') {
      return 'tree-node active-node';
    }
    return 'tree-node';
  }

  function onRecycleBinClick(): void {
    setAppView('RecycleBin');
  }

  if (!launchDarklyFeatures.showRecycleBin || !ecPluginFeatures.recycleBin()) {
    return null;
  }

  return (
    <div
      className={className()}
      role="treeitem"
      data-testid="recycle-bin-tree-node"
    >
      <Chevron hasChildren={false} isExpanded={false} />
      <SvgDelete className="pw-icon-xs explorer-icon" />
      <Text
        as="span"
        className="node-label"
        onClick={onRecycleBinClick}
        onKeyPress={keyPressAsClick(onRecycleBinClick)}
        role="link"
        tabIndex={0}
        data-testid="recycle-bin-node-label"
      >
        {t('RecycleBin.DeletedItems')}
      </Text>
    </div>
  );
}
