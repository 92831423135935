import { useEffect } from 'react';
import { useCancelWizard } from './useCancelWizard';

export function useCancelConfirmation(): void {
  const { cancelWizard } = useCancelWizard();

  useEffect(() => {
    window.addEventListener('beforeunload', cancelWizard);

    return () => {
      window.removeEventListener('beforeunload', cancelWizard);
    };
  }, [cancelWizard]);
}
