import { useEffect, useState } from 'react';
import { useHttpService } from '../../context';
import type { ECPluginVersion } from './ecPluginVersion';
import { getECPluginVersion } from './ecPluginVersion';
import type { ECPluginFeatures } from './features';
import { ecPluginFeatures } from './features';
import { useECPluginCache } from './useECPluginCache';

type ECPluginProperties = {
  ecPluginFeatures: ECPluginFeatures;
  ecPluginInitialized: boolean;
  ecPluginVersion: ECPluginVersion;
};

export function useECPluginVersion(connectionId?: string): ECPluginProperties {
  const httpService = useHttpService();

  const [ecPluginVersion, setECPluginVersion] = useECPluginCache(connectionId);

  const [ecPluginInitialized, setECPluginInitialized] = useState<boolean>(
    ecPluginVersion != null
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function queryECPluginVersion(): Promise<void> {
      const pluginVersion: ECPluginVersion = await getECPluginVersion({
        httpService,
        abortController
      });
      if (!abortController.signal.aborted) {
        setECPluginVersion(pluginVersion);
        setECPluginInitialized(true);
      }
    }

    void queryECPluginVersion();

    return () => {
      setECPluginInitialized(false);
      abortController.abort();
    };
  }, [httpService, setECPluginVersion]);

  useEffect(() => {
    if (ecPluginVersion) {
      setECPluginInitialized(true);
    } else {
      setECPluginInitialized(false);
    }
  }, [ecPluginVersion]);

  return { ecPluginInitialized, ecPluginFeatures, ecPluginVersion };
}
