import React from 'react';
import { Trans } from 'react-i18next';
import type { PWItem } from '@bentley/pw-api';
import { SvgHelpCircularHollow } from '@itwin/itwinui-icons-react';
import { Anchor, RadioTile, RadioTileGroup } from '@itwin/itwinui-react';
import {
  SvgDocuments,
  SvgMicrosoftTeams,
  SvgProjectWiseHollow,
  SvgProjectWiseWeb,
  SvgProjectWiseWebView
} from '../../components/icons';
import { PWTooltip } from '../../components/pwTooltip';
import { href } from '../../constants';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import type { Application } from './getShareableLink';

type AdvancedOptionsProps = {
  disabledApplications?: (Application | undefined)[];
  hiddenApplications?: Application[];
  items: (PWItem | SavedSearchDefinition)[];
  selectedApp: Application;
  showDocumentsButton?: boolean;
  setSelectedApp: (application: Application) => void;
};

export function AdvancedOptions({
  disabledApplications = [],
  hiddenApplications = [],
  items,
  selectedApp,
  showDocumentsButton,
  setSelectedApp
}: AdvancedOptionsProps): JSX.Element {
  return (
    <RadioTileGroup
      label={
        items.length == 1
          ? t('SharedLink.CreateLinkFor')
          : t('SharedLink.CreateLinksFor')
      }
      className="link-radio-group"
    >
      {!hiddenApplications.includes('Ask') && (
        <RadioTile
          defaultChecked={selectedApp == 'Ask'}
          icon={<SvgHelpCircularHollow />}
          label={t('SharedLink.AskToSelect')}
          name="application"
          value="Ask"
          data-testid="ask-application"
          onInput={() => setSelectedApp('Ask')}
          wrapperProps={{ className: 'link-tile' }}
        />
      )}
      {!hiddenApplications.includes('Web') && (
        <RadioTile
          defaultChecked={selectedApp == 'Web'}
          icon={showDocumentsButton ? <SvgDocuments /> : <SvgProjectWiseWeb />}
          label={
            showDocumentsButton
              ? t('Generic.Documents')
              : t('ProductName.ProjectWiseWebShort')
          }
          name="application"
          value="Web"
          data-testid="web-application"
          onInput={() => setSelectedApp('Web')}
          disabled={disabledApplications.includes('Web')}
          wrapperProps={{ className: 'link-tile' }}
        />
      )}
      {!hiddenApplications.includes('Explorer') && (
        <PWTooltip
          content={
            disabledApplications.includes('Explorer')
              ? t('SharedLink.ExplorerNotSupported')
              : undefined
          }
        >
          <RadioTile
            defaultChecked={selectedApp == 'Explorer'}
            icon={<SvgProjectWiseHollow data-testid="explorer-icon" />}
            label={t('ProductName.ProjectWiseExplorerShort')}
            name="application"
            value="Explorer"
            data-testid="explorer-application"
            onInput={() => setSelectedApp('Explorer')}
            disabled={disabledApplications.includes('Explorer')}
            wrapperProps={{ className: 'link-tile' }}
          />
        </PWTooltip>
      )}
      {!hiddenApplications.includes('WebView') && (
        <PWTooltip content={t('SharedLink.WebViewLinkHint')}>
          <RadioTile
            defaultChecked={selectedApp == 'WebView'}
            icon={<SvgProjectWiseWebView />}
            label={t('ProductName.ProjectWiseWebViewShort')}
            name="application"
            value="WebView"
            data-testid="webview-application"
            onInput={() => setSelectedApp('WebView')}
            disabled={disabledApplications.includes('WebView')}
            wrapperProps={{ className: 'link-tile' }}
          />
        </PWTooltip>
      )}
      {!hiddenApplications.includes('Teams') && (
        <PWTooltip
          content={
            disabledApplications.includes('Teams') ? (
              <Trans i18nKey="SharedLink.TeamsLearnMore">
                This connection does not have a default Microsoft Teams tab. To
                learn more about ProjectWise Teams integration, visit{' '}
                <Anchor
                  className="tooltip-anchor"
                  href={href.teamsHelp}
                  target="_blank"
                  rel="noopener noreferrer"
                  isExternal={true}
                >
                  Bentley Communities
                </Anchor>
                .
              </Trans>
            ) : undefined
          }
          placement="top-end"
        >
          <RadioTile
            defaultChecked={selectedApp == 'Teams'}
            icon={<SvgMicrosoftTeams data-testid="teams-icon" />}
            label={t('ProductName.MicrosoftTeamsShort')}
            name="application"
            value="Teams"
            data-testid="teams-application"
            onInput={() => setSelectedApp('Teams')}
            disabled={disabledApplications.includes('Teams')}
            wrapperProps={{ className: 'link-tile' }}
          />
        </PWTooltip>
      )}
    </RadioTileGroup>
  );
}
