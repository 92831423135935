import React, { useMemo } from 'react';
import { SvgSettings } from '@itwin/itwinui-icons-react';
import { useNavigationContext } from '../../../../context';
import { t } from '../../../../services/translation';
import { DisplayOptionsDialog } from '../../../displayOptionsDialog';
import type { MenuItemAction } from '../../MenuItemAction';

export function useDisplayOption(): MenuItemAction {
  const { primaryModal } = useNavigationContext();

  const displayOption = useMemo(
    (): MenuItemAction => ({
      title: t('DisplayOptions.DisplayOptions'),
      icon: <SvgSettings />,
      onClick: () => primaryModal.open(<DisplayOptionsDialog />)
    }),
    [primaryModal]
  );

  return displayOption;
}
