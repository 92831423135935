import { useEffect, useState } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../context';
import { checkProject } from './pwdmLinks';

export function useCheckProject(): boolean {
  const { buddiRegionCode, contextId } = usePluginContext();
  const { getOidcToken } = useToken();

  const [projectEnabled, setProjectEnabled] = useState<boolean>(false);
  const PWDMAPIUrl = useBuddi('BTSSvc.URL', buddiRegionCode);

  useEffect(() => {
    async function checkProjectEnabled() {
      if (contextId) {
        const projectEnabled = await checkProject(
          PWDMAPIUrl ?? '',
          contextId,
          getOidcToken
        );
        setProjectEnabled(projectEnabled);
      }
    }
    if (PWDMAPIUrl) {
      void checkProjectEnabled();
    }
  }, [contextId, PWDMAPIUrl, getOidcToken]);

  return projectEnabled;
}
