import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import type { WSGInstance, WSGProperties } from '@bentley/pw-api';
import type { DataError } from '../../../../actions/formRendering';
import { handleDCWError } from '../../error';
import { useSetProperties } from '../../properties';
import {
  currentEnvironmentInstanceState,
  ecPluginVersionState,
  getDCWHttpService,
  useRecoilValueAsync
} from '../../state';
import { updatingTriggeredPropertiesState } from '../state';
import {
  generateTriggeredDocCodeProperties,
  generateTriggeredProperties
} from './updateTriggeredPropertiesAction';

type TriggeredPropertyWorkflowFunctions = {
  updateInstanceWithTriggeredProperties: (
    propertyName: string,
    changedInstance: WSGInstance
  ) => Promise<WSGInstance | null>;
  updateInstanceWithTriggeredDocCodeProperties: () => Promise<WSGInstance | null>;
};

export function useTriggeredPropertyWorkflow(): TriggeredPropertyWorkflowFunctions {
  const ecPluginVersion = useRecoilValueAsync(ecPluginVersionState);
  const currentInstance = useRecoilValueAsync(currentEnvironmentInstanceState);

  const setUpdatingTriggeredProperties = useSetRecoilState(
    updatingTriggeredPropertiesState
  );

  const { setInstanceWithProperties } = useSetProperties();

  const updateTriggeredProperties = useCallback(
    async (
      environmentInstance: WSGInstance,
      updatePropertiesFunction: () => Promise<WSGProperties>
    ): Promise<WSGInstance | null> => {
      try {
        setUpdatingTriggeredProperties(true);
        const updatedProps = await updatePropertiesFunction();

        const updatedInstance = setInstanceWithProperties(
          environmentInstance,
          updatedProps
        );

        return updatedInstance;
      } catch (error) {
        void handleDCWError(error as DataError);
        return null;
      } finally {
        setTimeout(() => setUpdatingTriggeredProperties(false), 500);
      }
    },
    [setInstanceWithProperties, setUpdatingTriggeredProperties]
  );

  const updateInstanceWithTriggeredProperties = useCallback(
    async (
      propertyName: string,
      changedInstance: WSGInstance
    ): Promise<WSGInstance | null> => {
      if (!changedInstance.properties || !propertyName) {
        return null;
      }

      const updatePropertiesFunction = () =>
        generateTriggeredProperties(
          changedInstance,
          propertyName,
          getDCWHttpService()
        );

      const updatedInstance = await updateTriggeredProperties(
        changedInstance,
        updatePropertiesFunction
      );

      return updatedInstance;
    },
    [updateTriggeredProperties]
  );

  const updateInstanceWithTriggeredDocCodeProperties =
    useCallback(async (): Promise<WSGInstance | null> => {
      if (!currentInstance || Number(ecPluginVersion) < 3.41) {
        return null;
      }

      const updatePropertiesFunction = () =>
        generateTriggeredDocCodeProperties(currentInstance);

      const updatedInstance = await updateTriggeredProperties(
        currentInstance,
        updatePropertiesFunction
      );

      return updatedInstance;
    }, [currentInstance, updateTriggeredProperties, ecPluginVersion]);

  return {
    updateInstanceWithTriggeredProperties,
    updateInstanceWithTriggeredDocCodeProperties
  };
}
