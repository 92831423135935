import { useCallback } from 'react';
import type { PWDocument, PWItem } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { usePDFMarkup } from './usePDFMarkup';

type OpenPDFFunction = (item: PWItem) => void;

export function useOpenPDF(): OpenPDFFunction {
  const { buddiRegionCode, connection, contextId, dataAccessLevel } =
    usePluginContext();
  const { azureServerAccessible, openPDFInNewTab } = useEnvironmentContext();
  const { navigationManager, primaryModal } = useNavigationContext();

  const { OpenPdfMarkUp, openPDFMarkupUrl } = usePDFMarkup(
    buddiRegionCode,
    contextId
  );

  const onPDFClick = useCallback(
    (item: PWItem): void => {
      if (OpenPdfMarkUp && !openPDFInNewTab.allowed) {
        const pdfMarkUpModal = OpenPdfMarkUp({
          connectionId: connection.Id,
          datasourceId: connection.DatasourceInstanceId,
          item: item as PWDocument,
          readOnly: azureServerAccessible,
          dataAccessLevel: dataAccessLevel,
          itemParent: navigationManager.currentParent,
          isOpen: true,
          closeHandle: primaryModal.close
        });
        return primaryModal.open(pdfMarkUpModal);
      }
      openPDFMarkupUrl?.(
        connection.Id,
        connection.DatasourceInstanceId,
        item as PWDocument,
        azureServerAccessible,
        dataAccessLevel,
        navigationManager.currentParent
      );
    },
    [
      azureServerAccessible,
      connection.Id,
      connection.DatasourceInstanceId,
      navigationManager.currentParent,
      openPDFMarkupUrl,
      OpenPdfMarkUp,
      openPDFInNewTab,
      primaryModal,
      dataAccessLevel
    ]
  );

  return onPDFClick;
}
