import './itemIcon.css';
import React from 'react';
import { ThemeProvider } from '@itwin/itwinui-react';
import { Icon } from './icon';
import { IconBadge } from './iconBadge';
/**
 * A ProjectWise item icon with optional badge
 */
export function ItemIcon({ badge, badgeProps, iconProps, item, size = 'm' }) {
    return (React.createElement(ThemeProvider, { theme: "inherit", themeOptions: { applyBackground: false }, includeCss: false },
        React.createElement("div", { className: "pw-icon" },
            React.createElement(Icon, { item: item, size: size, ...iconProps }),
            badge && React.createElement(IconBadge, { badge: badge, ...badgeProps }))));
}
