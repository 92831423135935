import {
  parseFileExtension,
  parseFileNameWithoutExtension
} from '@bentley/pw-api';

export function getUniqueName(
  nameCandidate: string,
  existingNames: string[],
  style: 'parentheses' | 'underscore'
): string {
  const extension = parseFileExtension(nameCandidate);
  const name = parseFileNameWithoutExtension(nameCandidate);

  let uniqueName = nameCandidate;
  let version = 0;

  const versionName =
    style == 'parentheses'
      ? versionNameWithParentheses
      : versionNameWithUnderscore;

  while (existingNames.includes(uniqueName)) {
    version++;
    uniqueName = versionName(name, version, extension ? `.${extension}` : '');
  }

  return uniqueName;
}

function versionNameWithParentheses(
  name: string,
  version: number,
  extension: string
): string {
  return `${name} (${version})${extension}`;
}

function versionNameWithUnderscore(
  name: string,
  version: number,
  extension: string
): string {
  return `${name}_${version}${extension}`;
}
