import React, { useCallback, useEffect, useState } from 'react';
import type { PWProject, RequestOptions } from '@bentley/pw-api';
import { useHttpService, useNavigationContext } from '../../../context';
import type { SavedSearchDefinition } from '../../../services/search';
import { getSavedSearches } from '../../../services/search';
import { savedSearchesSupported } from './savedSearchSupported';
import { SavedSearchesNode } from './savedSearchesNode';

export type SavedSearchCategory = 'global' | 'personal';

type SavedSearchesGroupNodeProps = {
  parent: PWProject;
};

export function SavedSearchesNodes({
  parent
}: SavedSearchesGroupNodeProps): JSX.Element | null {
  const httpService = useHttpService();
  const { searchState } = useNavigationContext();

  const [savedSearches, setSavedSearches] = useState<SavedSearchDefinition[]>();

  const loadSavedSearches = useCallback(
    async (httpOptions?: RequestOptions): Promise<void> => {
      if (savedSearches) {
        return;
      }

      try {
        const savedSearches = await getSavedSearches(
          parent,
          httpService,
          httpOptions
        );

        if (!httpOptions?.abortController?.signal.aborted) {
          setSavedSearches(savedSearches);
        }
      } catch {
        if (!httpOptions?.abortController?.signal.aborted) {
          setSavedSearches([]);
        }
      }
    },
    [httpService, parent, savedSearches]
  );

  useEffect(() => {
    // load searches on link to saved search
    if (searchState.savedSearch?.instanceId) {
      void loadSavedSearches();
    }
  }, [loadSavedSearches, searchState.savedSearch?.instanceId]);

  if (!savedSearchesSupported(parent)) {
    return null;
  }

  return (
    <>
      <SavedSearchesNode
        savedSearches={savedSearches}
        type="global"
        loadSearches={loadSavedSearches}
      />
      <SavedSearchesNode
        savedSearches={savedSearches}
        type="personal"
        loadSearches={loadSavedSearches}
      />
    </>
  );
}
