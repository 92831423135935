import './booleanFilter.css';

import React, { useMemo, useState } from 'react';
import {
  BaseFilter,
  FilterButtonBar,
  InputGroup,
  Radio
} from '@itwin/itwinui-react';
import type { TableFilterProps } from '@itwin/itwinui-react/react-table';
import { formatBoolean } from '../../../services/stringFormatter';
import { t } from '../../../services/translation';

export function BooleanFilter({
  column,
  clearFilter,
  setFilter
}: TableFilterProps<Record<string, unknown>>): JSX.Element {
  const [value, setValue] = useState<boolean | undefined>(
    column.filterValue as boolean | undefined
  );

  const translatedLabels = useMemo(
    () => ({
      filter: t('Search.Filter'),
      clear: t('Search.Clear'),
      from: t('Generic.From'),
      to: t('Generic.To')
    }),
    []
  );

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      setFilter(value);
    }
  }

  return (
    <BaseFilter>
      <InputGroup displayStyle="inline" className="pw-boolean-filter-group">
        <Radio
          name="filterOption"
          onChange={() => setValue(true)}
          label={formatBoolean(true)}
          defaultChecked={value}
          onKeyDown={onKeyDown}
          data-testid="boolean-filter-true"
        />
        <Radio
          name="filterOption"
          onChange={() => setValue(false)}
          label={formatBoolean(false)}
          defaultChecked={value === false}
          onKeyDown={onKeyDown}
          data-testid="boolean-filter-false"
        />
      </InputGroup>
      <FilterButtonBar
        setFilter={() => setFilter(value)}
        clearFilter={clearFilter}
        translatedLabels={translatedLabels}
      />
    </BaseFilter>
  );
}
