import { WebStorageStateStore } from 'oidc-client';
import { useMemo } from 'react';
import { useConfigContext } from '../../context';
import { UserManagerWithSaml } from '../useOidc';

type UserManagers = {
  userManager: UserManagerWithSaml;
  publicServiceUserManager: UserManagerWithSaml;
};

export function useUserManager(): UserManagers {
  const { config } = useConfigContext();

  const userManager = useMemo((): UserManagerWithSaml => {
    return new UserManagerWithSaml({
      authority: config.OIDC_Authority,
      client_id: config.OIDC_ClientId,
      redirect_uri: `${window.location.origin}/signin-oidc`,
      silent_redirect_uri: `${window.location.origin}/signin-oidc`,
      automaticSilentRenew: true,
      response_type: 'code',
      scope: `${config.OIDC_Scopes} ${config.OIDC_IMSScope}`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      post_logout_redirect_uri: `${window.location.origin}/logged-out`
    });
  }, [config]);

  const publicServiceUserManager = useMemo((): UserManagerWithSaml => {
    return new UserManagerWithSaml({
      authority: config.AuthorityForPublicServices,
      client_id: config.OIDC_ClientId,
      redirect_uri: `${window.location.origin}/signin-oidc`,
      silent_redirect_uri: `${window.location.origin}/signin-oidc`,
      automaticSilentRenew: true,
      response_type: 'code',
      scope: `${config.OIDC_Scopes} ${config.OIDC_IMSScope}`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      post_logout_redirect_uri: `${window.location.origin}/logged-out`
    });
  }, [config]);

  const userManagers = useMemo(
    () => ({ userManager, publicServiceUserManager }),
    [userManager, publicServiceUserManager]
  );

  return userManagers;
}
