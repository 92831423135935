export type Version = {
  major: number;
  minor: number;
  patch: number;
};

export function parseVersion(version: string): Version {
  const versionParts = version.split('.');
  return {
    major: parseVersionPart(versionParts[0]),
    minor: parseVersionPart(versionParts[1]),
    patch: parseVersionPart(versionParts[2])
  };
}

function parseVersionPart(part: string | undefined): number {
  if (part == undefined || isNaN(+part)) {
    return 0;
  }
  return +part;
}

export function isVersionGreaterThan(
  a: Version,
  b: Version,
  depth: keyof Version = 'patch'
): boolean {
  if (a.major > b.major) {
    return true;
  }
  if (a.major < b.major || depth == 'major') {
    return false;
  }

  if (a.minor > b.minor) {
    return true;
  }
  if (a.minor < b.minor || depth == 'minor') {
    return false;
  }

  return a.patch > b.patch;
}
