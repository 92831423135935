import React, { useEffect, useState } from 'react';
import type { PWDataItem, PWItem, PWProject } from '@bentley/pw-api';
import {
  getDataItems,
  getProject,
  itemIsDocument,
  itemIsFlatSet
} from '@bentley/pw-api';
import { Alert, Text } from '@itwin/itwinui-react';
import { useAllowDCW } from '../../components/documentCreation';
import { PWModal } from '../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { ConflictResolution } from '../conflictResolution';
import { siblingConflicts } from '../conflictResolution';
import { openConflictResolutionModal } from './conflictResolution';
import { createCopyWorkflow } from './createCopy';
import { CreateCopyDestinationComponent } from './createCopyDestinationComponent';
import { useCreateCopyWizard } from './useCreateCopyWizard';

type DifferentEnvironmentWarningModalProps = {
  destinationFolder: PWProject;
  copyItems: PWItem[];
};

export function DifferentEnvironmentWarningModal({
  destinationFolder,
  copyItems
}: DifferentEnvironmentWarningModalProps): JSX.Element | null {
  const { primaryModal, navigationManager } = useNavigationContext();
  const { launchDarklyFeatures } = useFeatureTracking();
  const httpService = useHttpService();
  const { dataManager, selectedState } = useTableItemContext();
  const [destination, setDestination] = useState<PWProject | undefined>(
    undefined
  );
  const [destinationSiblings, setDestinationSiblings] = useState<PWDataItem[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [differentEnvironment, setDifferentEnvironment] =
    useState<boolean>(false);
  const [showDCW, setShowDCW] = useState<boolean>(false);

  const { show: showCreateCopyWizard } = useCreateCopyWizard();
  const { getDCWAllowed } = useAllowDCW();

  useEffect(() => {
    async function getCopyDestination() {
      try {
        const copyDestination = await getProject({
          instanceId: destinationFolder.instanceId,
          httpService
        });

        if (copyDestination) {
          setDestination(copyDestination);

          const siblings = await getDataItems({
            parentId: copyDestination.instanceId,
            httpService: httpService
          });
          setDestinationSiblings(siblings);

          const isDifferentEnvironment = copyItems.some(
            (item) => item.EnvironmentId != copyDestination?.EnvironmentId
          );

          if (isDifferentEnvironment) {
            setDifferentEnvironment(true);
          }

          const dcwAllowed = await getDCWAllowed(copyDestination);
          const useDCWLDFlag =
            launchDarklyFeatures.showDCWForCopyWorkflows as boolean;
          const copySetDCWLDFlag =
            launchDarklyFeatures.copyFlatSetWithDCW as boolean;
          const useDCWCopyFlatSet =
            copySetDCWLDFlag && itemIsFlatSet(copyItems[0]);

          setShowDCW(
            useDCWLDFlag &&
              dcwAllowed &&
              (itemIsDocument(copyItems[0]) || useDCWCopyFlatSet)
          );
        } else {
          openToast({
            content: t('CreateCopy.Notifications.DestinationFolderError'),
            category: 'negative'
          });
        }
      } catch (error) {
        primaryModal.close();
        openToast({
          content: error,
          category: 'negative'
        });
      }

      setLoading(false);
    }

    void getCopyDestination();
  }, [
    copyItems,
    destinationFolder.instanceId,
    getDCWAllowed,
    httpService,
    primaryModal,
    launchDarklyFeatures.showDCWForCopyWorkflows,
    launchDarklyFeatures.copyFlatSetWithDCW
  ]);

  const onComplete = (instanceIds?: string[]) => {
    if (instanceIds && destination) {
      void navigationManager.navigateTo(destination, true);
      selectedState.setSelectedIds(instanceIds);
    }
    void dataManager.refresh();
  };

  const openDCWOrCreateCopy = async (destination: PWProject) => {
    primaryModal.close();

    if (showDCW) {
      const { conflicts, nonConflicts } = splitItemsFromConflicts();

      if (conflicts.length > 0) {
        const resolveWithDCW = (resolutions: ConflictResolution[]) =>
          showCreateCopyWizard(destination, [
            ...resolutions.map((res) => res.sourceItem),
            ...nonConflicts
          ]);
        openConflictResolutionModal(
          conflicts,
          destination,
          primaryModal.open,
          primaryModal.close,
          resolveWithDCW
        );
      } else {
        showCreateCopyWizard(destination, copyItems);
      }
      return;
    }

    await createCopyWorkflow(
      httpService,
      copyItems,
      destination,
      onComplete,
      primaryModal.open,
      primaryModal.close,
      false
    );
  };

  function splitItemsFromConflicts(): {
    conflicts: PWItem[];
    nonConflicts: PWItem[];
  } {
    const conflicts: PWItem[] = [];
    const nonConflicts: PWItem[] = [];

    copyItems.forEach((item) => {
      const destinationConflicts = siblingConflicts(item, destinationSiblings);
      if (destinationConflicts.length) {
        destinationConflicts.forEach((con) => conflicts.push(con));
      } else {
        nonConflicts.push(item);
      }
    });

    return { conflicts, nonConflicts };
  }

  function onConfirm() {
    if (destination) {
      void openDCWOrCreateCopy(destination);
    }
  }

  function onCancel() {
    primaryModal.open(<CreateCopyDestinationComponent copyItems={copyItems} />);
  }

  if (!loading && !differentEnvironment) {
    if (destination) {
      void openDCWOrCreateCopy(destination);
      return null;
    }
  }

  return (
    <PWModal
      title={loading ? '' : t('CreateCopy.DifferentEnvironmentTitle')}
      isLoading={loading}
      primaryButton={
        loading
          ? undefined
          : { title: t('Generic.Yes'), onClick: onConfirm, disabled: loading }
      }
      secondaryButton={{ title: t('Generic.Cancel'), onClick: onCancel }}
      onClose={primaryModal.close}
    >
      <Alert
        type="warning"
        data-testid="differentEnvironmentWarningModalMessage"
      >
        {t('CreateCopy.DifferentEnvironmentMessage')}
      </Alert>
      <Text>{t('ManageConnections.Confirmation')}</Text>
    </PWModal>
  );
}
