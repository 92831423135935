import { floor } from 'lodash';
import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import type {
  ApplicationInsights,
  IEventTelemetry
} from '@microsoft/applicationinsights-web';
import { storedECPluginVersion } from '../../../hooks/useECPluginVersion/sessionStorage';
import { getAppInsights, loadAppInsights } from '../../../services/appInsights';
import type { GetToken } from '../../../services/http';
import { useConnectionContext } from '../connection';
import type { ConsumerApp } from '../plugin';

type TelemetryContext = {
  startTelemetry: (eventName: TelemetryTracker) => void;
  endTelemetry: (
    eventName: TelemetryTracker,
    additionalData?: IEventTelemetry['properties']
  ) => void;
};

type TelemetryTracker =
  | 'EnvironmentDataLoad'
  | 'FolderLoad'
  | 'SecondaryDataLoad'
  | 'loadingConnection'
  | 'PropertyDataLoad'
  | 'Search'
  | 'SigningIn';

const Context = createContext<TelemetryContext | undefined>(undefined);

type TelemetryProps = {
  buddiRegionCode: string;
  consumerApp?: ConsumerApp;
  contextId?: string;
  user: User;
  getSamlToken: GetToken;
};

export function TelemetryProvider({
  value: { buddiRegionCode, consumerApp, contextId, user, getSamlToken },
  children
}: ProviderProps<TelemetryProps>): JSX.Element {
  const { connection } = useConnectionContext();

  const [appInsightsInstance, setAppInsightsInstance] = useState<
    ApplicationInsights | undefined
  >(undefined);

  // Hardcoded insights keys so npm package can report telemetry to our instance
  function getPackageInsightsKey(regionCode: string): string {
    switch (regionCode) {
      case '103':
        return 'b5c72442-4104-4dde-89be-c855263af5fb';
      case '102':
        return 'a5a6c518-b5ff-4fd2-80f6-9cfdf00f2feb';
      default:
        return 'db2e8017-47c4-4dcb-a27e-f56c099ecda3';
    }
  }
  useEffect(() => {
    const instrumentationKey = getPackageInsightsKey(buddiRegionCode);
    loadAppInsights(instrumentationKey);
    const appInsights = getAppInsights();
    setAppInsightsInstance(appInsights);
  }, [buddiRegionCode]);

  const startTimer = useCallback((timerName: TelemetryTracker) => {
    const timerExists = performance.getEntriesByName(timerName).length > 0;
    if (timerExists) {
      performance.clearMarks(timerName);
    }
    performance.mark(timerName);
  }, []);

  const endTimer = useCallback(
    (
      timerName: TelemetryTracker,
      additionalData?: IEventTelemetry['properties']
    ) => {
      const currentTimer = performance.getEntriesByName(timerName);
      if (currentTimer.length > 0) {
        performance.measure('totalTime' + timerName, timerName);
        const totalTime = performance.getEntriesByName(
          'totalTime' + timerName
        )[0]?.duration;
        performance.clearMarks(timerName);
        performance.clearMeasures('totalTime' + timerName);
        if (appInsightsInstance) {
          appInsightsInstance.trackEvent({
            name: timerName,
            properties: {
              time: floor(totalTime),
              username: user.profile.preferred_username,
              userId: user.profile.sub,
              org: user.profile.org as string,
              connectionId: connection?.Id,
              contextId: contextId,
              server: connection?.ConnectionUrl,
              application: consumerApp,
              ecPluginBuild: storedECPluginVersion(),
              ...additionalData
            }
          });
        }
      }
    },
    [appInsightsInstance, user, connection, contextId, consumerApp]
  );

  const start = useCallback(
    (eventName: TelemetryTracker) => {
      startTimer(eventName);
    },
    [startTimer]
  );

  const end = useCallback(
    (
      eventName: TelemetryTracker,
      additionalData?: IEventTelemetry['properties']
    ) => {
      endTimer(eventName, additionalData);
    },
    [endTimer]
  );

  const telemetryContext = useMemo(
    (): TelemetryContext => ({
      startTelemetry: start,
      endTelemetry: end
    }),
    [start, end]
  );

  return (
    <Context.Provider value={telemetryContext}>{children}</Context.Provider>
  );
}

export function useTelemetry(): TelemetryContext {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error(`useTelemetry must be used within a telemetryProvider`);
  }

  return context;
}
