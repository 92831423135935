import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnvironmentContext, usePropertyContext } from '../../../context';
import type { View } from '../../../hooks/useViews';
import { defaultColumns } from '../../../hooks/useViews';
import type { TransferItem } from './conversion';
import {
  environmentAttributeToTransferItem,
  propertyToTransferItem
} from './conversion';

type ColumnOptions = {
  environment: string;
  availableColumns: TransferItem[];
  selectedColumns: TransferItem[];
  resetColumns: () => void;
  setEnvironment: Dispatch<SetStateAction<string>>;
  setAvailableColumns: Dispatch<SetStateAction<TransferItem[]>>;
  setSelectedColumns: Dispatch<SetStateAction<TransferItem[]>>;
};

type ColumnOptionsArgs = {
  propertiesLoaded: boolean;
  environmentsLoaded: boolean;
  existingView: View;
};

export function useColumnOptions({
  propertiesLoaded,
  environmentsLoaded,
  existingView
}: ColumnOptionsArgs): ColumnOptions {
  const {
    environmentManager: { attributeDefinitions }
  } = useEnvironmentContext();
  const { propertyManager } = usePropertyContext();

  const [environment, setEnvironment] = useState<string>('');
  const [availableColumns, setAvailableColumns] = useState<TransferItem[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<TransferItem[]>([]);

  const allColumns = useMemo((): TransferItem[] => {
    if (!propertiesLoaded || !environmentsLoaded) {
      return [];
    }

    const generalProperties = propertyManager.documentProperties.map(
      propertyToTransferItem
    );

    const environmentProperties = Object.values(attributeDefinitions).flatMap(
      (environmentAttributes) =>
        environmentAttributes.map(environmentAttributeToTransferItem)
    );

    return [...generalProperties, ...environmentProperties];
  }, [
    attributeDefinitions,
    propertyManager.documentProperties,
    environmentsLoaded,
    propertiesLoaded
  ]);

  const resetColumns = useCallback(() => {
    const newSelectedColumns = defaultColumns.map(propertyToTransferItem);
    setSelectedColumns(newSelectedColumns);

    const availableColumns = allColumns.filter(
      (column) =>
        !newSelectedColumns.some(
          (selected) =>
            selected.value.name == column.value.name &&
            selected.environment == column.environment
        )
    );
    setAvailableColumns(availableColumns);
  }, [allColumns]);

  useEffect(() => {
    const initialColumns = existingView.columns.map(propertyToTransferItem);
    setSelectedColumns(initialColumns);

    const availableColumns = allColumns.filter(
      (column) =>
        !initialColumns.some(
          (initialColumn) =>
            initialColumn.value.name == column.value.name &&
            initialColumn.environment == column.environment
        )
    );
    setAvailableColumns(availableColumns);
  }, [existingView.columns, allColumns]);

  return {
    environment,
    availableColumns,
    selectedColumns,
    resetColumns,
    setEnvironment,
    setAvailableColumns,
    setSelectedColumns
  };
}
