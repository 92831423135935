import { useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import type { EnvironmentProperty } from '../../hooks/useProperties';
import { generateUUID } from '../../services/data';
import { t } from '../../services/translation';
import type { SerializedAdvancedSearch } from './advancedSearchModal';
import { logicalDropdownOptions } from './row/dropdownOptions';

type SessionSearch = {
  searchCriteria: SerializedAdvancedSearch;
  setSearchCriteria: (criteria: SerializedAdvancedSearch) => void;
  clearSearchCriteria: () => void;
  editingSearchCriteria: SerializedAdvancedSearch;
  clearEditingSearchData: () => void;
};

export function useSessionSearch(
  properties: EnvironmentProperty[],
  searchFolder: PWProject,
  initialSerializedSearch?: string
): SessionSearch {
  const emptySearch = {
    selectedClass: 'Documents',
    selectedEnvironment: {
      label: t('CustomColumns.GeneralProperties'),
      value: 'General'
    },
    isAdvanceSearch: false,
    rowsData: [
      {
        id: generateUUID(),
        value: '',
        properties: properties,
        selectedProperty: properties[0],
        selectedOperator: '',
        operators: [],
        logicalOperators: logicalDropdownOptions().map((op) => op.label),
        selectedLogicalOperator: { label: '', value: '' },
        isLogicalOperatorVisible: false,
        isCheckBoxChecked: false
      }
    ],
    searchFolder: searchFolder,
    groups: []
  } as SerializedAdvancedSearch;

  function initialSearchState(): SerializedAdvancedSearch {
    if (initialSerializedSearch) {
      const search = JSON.parse(
        initialSerializedSearch
      ) as SerializedAdvancedSearch;
      search.groups = search.groups ?? [];
      search.searchFolder = search.searchFolder ?? searchFolder;
      return search;
    }

    const serializedSearch = window.sessionStorage.getItem(
      'serializedAdvancedSearch'
    );
    if (serializedSearch) {
      const search = JSON.parse(serializedSearch) as SerializedAdvancedSearch;
      search.groups = search.groups ?? [];
      return search;
    }

    return emptySearch;
  }

  const [searchCriteria, setSearchCriteria] =
    useState<SerializedAdvancedSearch>(initialSearchState);

  function setSearchCriteriaInStorage(value: SerializedAdvancedSearch): void {
    setSearchCriteria(value);
    storeSearchSessionStorage(value);
  }

  function clearSearchCriteria(): void {
    setSearchCriteria(emptySearch);
    clearSearchSessionStorage();
  }

  const [editingSearchCriteria, setEditingSearchCriteria] =
    useState<SerializedAdvancedSearch>(searchCriteria);

  function clearEditingSearchData(): void {
    setEditingSearchCriteria(emptySearch);
  }

  return {
    searchCriteria,
    setSearchCriteria: setSearchCriteriaInStorage,
    clearSearchCriteria,
    editingSearchCriteria,
    clearEditingSearchData
  };
}

export function storeSearchSessionStorage(
  searchCriteria: SerializedAdvancedSearch
): void {
  searchCriteria.groups = searchCriteria.groups ?? [];
  window.sessionStorage.setItem(
    'serializedAdvancedSearch',
    JSON.stringify(searchCriteria)
  );
}

export function clearSearchSessionStorage(): void {
  window.sessionStorage.removeItem('serializedAdvancedSearch');
}
