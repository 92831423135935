import React from 'react';
import { Input, Label } from '@itwin/itwinui-react';

type InfoRowProps = {
  label: string;
  value: string;
  hidden?: boolean;
};

export function InfoRow({
  label,
  value,
  hidden = false
}: InfoRowProps): JSX.Element | null {
  if (hidden) {
    return null;
  }

  return (
    <>
      <Label htmlFor={label}>{label}</Label>
      <Input id={label} value={value} readOnly />
    </>
  );
}
