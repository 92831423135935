import { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import { useNavigationContext, usePluginContext } from '../../../context';

export function useRootFolderSelected(actionableItems: PWItem[]): boolean {
  const { connection } = usePluginContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const rootSelected = useMemo((): boolean => {
    if (
      currentParent.instanceId == 'invalidRootId' ||
      (itemIsProject(currentParent) && currentParent.TypeString == 'Connection')
    ) {
      return true;
    }

    const connectionRootSelected =
      actionableItems[0]?.instanceId == connection.ProjectId;

    return connectionRootSelected;
  }, [currentParent, connection.ProjectId, actionableItems]);

  return rootSelected;
}
