import type { PWItem } from '@bentley/pw-api';
import { t } from '../../services/translation';
import type { View } from './useViews';

export type ColumnInfo = {
  displayLabel: string;
  propertyType: string;
  dataType: string;
  name: string;
  envLabel?: string;
  isProjectEnv?: boolean;
  width?: number;
};

export type ColumnDisplayOptions = {
  badges?: ((props: {
    item: PWItem;
    key: number | string;
  }) => JSX.Element | null)[];
  displayIcon?: boolean;
  isPrimary?: boolean;
  isSecondary?: boolean;
  isLast?: boolean;
};

export const defaultColumns: readonly ColumnInfo[] = [
  {
    displayLabel: t('Generic.Name'),
    propertyType: 'General',
    dataType: 'string',
    name: 'Name',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.Version'),
    propertyType: 'General',
    dataType: 'string',
    name: 'Version',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.Description'),
    propertyType: 'General',
    dataType: 'string',
    name: 'Description',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.FileName'),
    propertyType: 'General',
    dataType: 'string',
    name: 'FileName',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.FileSize'),
    propertyType: 'General',
    dataType: 'fileSize',
    name: 'FileSize',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.CheckedOutTo'),
    propertyType: 'General',
    dataType: 'string',
    name: 'OutTo',
    envLabel: t('CustomColumns.GeneralProperties')
  },
  {
    displayLabel: t('Generic.UpdatedOn'),
    propertyType: 'General',
    dataType: 'dateTime',
    name: 'UpdateTime',
    envLabel: t('CustomColumns.GeneralProperties')
  }
] as const;

export const defaultView = {
  columns: defaultColumns,
  name: t('MenuItem.DefaultView')
} as View;
