import type { User } from 'oidc-client';
import React from 'react';
import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem
} from '@bentley/pw-api';
import {
  checkForDriveErrors,
  showGraphCheckInErrorModal,
  showGraphFreeErrorModal
} from '../../components/graphErrorModal';
import type { GraphApiContext } from '../../context';
import type { AppViewSetting } from '../../hooks/useAppView';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import type { PWDriveManager } from '../drive';
import { checkInFile } from './checkIn';
import { freeDocument } from './freeDocument';
import { FreeDocumentModal } from './freeDocumentModal';
import { startUpdateFile } from './update';

export interface ISyncButtonProps {
  checkedRows: PWItem[];
  currentUserName: string;
  userGuid: string;
  contextId: string;
  isUserHasAdminLevelAccess: boolean;
  refreshView: () => void;
  refreshOutToMe: () => void;
  openModal: OpenModal;
  closeModal: CloseModal;
  trackFeature: TrackFeature;
  httpService: HttpService;
  driveData: PWDriveManager;
  graphApiManager: GraphApiContext;
  currentAppView: AppViewSetting;
  user: User;
}

async function onSuccess(props: ISyncButtonProps) {
  await props.httpService.cache?.clearEntriesMatching([
    ...props.checkedRows.map((item) => item.ParentGuid),
    ...props.checkedRows.map((item) => item.instanceId)
  ]);

  if (ecPluginFeatures.outToMePage()) {
    if (props.currentAppView.type == 'OutToMe') {
      props.trackFeature('OUT_TO_ME_CHECKIN_FILE');
    }
    props.refreshOutToMe();
  }
  props.refreshView();
}

export async function freeOperationWorkflow(
  props: ISyncButtonProps
): Promise<void> {
  await freeOperation(props);
}

async function freeOperation(props: ISyncButtonProps): Promise<void> {
  const { sessions, normalCheckout } = props.checkedRows.reduce<{
    sessions: PWItem[];
    normalCheckout: PWItem[];
  }>(
    (acc, item) => {
      props.graphApiManager.checkCoAuthoringSessionExists(item)
        ? acc.sessions.push(item)
        : acc.normalCheckout.push(item);
      return acc;
    },
    { sessions: [], normalCheckout: [] }
  );

  const freeItems = async () => {
    sessions.length && (await runFreeWithO365(sessions as PWFileType[]));
    normalCheckout.length && (await runFree(normalCheckout as PWFileType[]));
  };

  if (sessions.length > 0) {
    const errors = await checkForDriveErrors(
      sessions as PWDataItem[],
      props.graphApiManager
    );
    if (errors.length > 0) {
      showGraphFreeErrorModal(
        props.checkedRows,
        errors,
        props.openModal,
        props.closeModal,
        freeItems,
        props.user
      );
      return;
    }
  }
  props.openModal(
    <FreeDocumentModal
      items={props.checkedRows}
      onYes={() => void freeItems()}
    />
  );
  async function runFree(files: PWFileType[]) {
    await freeDocument(
      files,
      props.httpService,
      () => {
        void onSuccess(props);
      },
      props.userGuid,
      props.trackFeature,
      props.contextId,
      props.isUserHasAdminLevelAccess,
      props.currentUserName.includes('@')
        ? props.currentUserName.split('@')[0]
        : props.currentUserName,
      props.driveData
    );
  }
  async function runFreeWithO365(files: PWFileType[]) {
    await props.graphApiManager.freeCoAuthoringSessions(files, () =>
      onSuccess(props)
    );
  }
}

export async function endSessionOperation(
  props: ISyncButtonProps
): Promise<void> {
  async function runEnd(files: PWFileType[]) {
    await props.graphApiManager.endCoAuthoringSessions(files, () =>
      onSuccess(props)
    );
  }
  const errors = await checkForDriveErrors(
    props.checkedRows as PWDataItem[],
    props.graphApiManager,
    true
  );

  if (errors.length > 0) {
    showGraphCheckInErrorModal(
      props.checkedRows,
      errors,
      props.openModal,
      props.closeModal,
      runEnd,
      props.user
    );
    return;
  }
  void runEnd(props.checkedRows as PWFileType[]);
}

export async function checkInOperation(props: ISyncButtonProps): Promise<void> {
  await checkInFile(
    props.checkedRows,
    () => void onSuccess(props),
    props.userGuid,
    props.httpService,
    props.openModal,
    props.trackFeature,
    props.contextId,
    props.driveData
  );
}

export function updateServerCopyOperation(props: ISyncButtonProps): void {
  function successFunction() {
    void onSuccess(props);
  }
  startUpdateFile(
    props.checkedRows[0] as PWDataItem,
    props.httpService,
    props.openModal,
    props.trackFeature,
    successFunction
  );
}
