import type { ReactNode } from 'react';
import React from 'react';
import { PlainHttpServiceProvider, TokenProvider } from '../../../context';
import { ConnectionResolver } from './connectionResolver';
import type { GetLinkPluginProps } from './getLinkPlugin';

type GetLinkPluginsProviderProps = GetLinkPluginProps & {
  children: ReactNode;
};

export function GetLinkPluginsProvider({
  buddiRegionCode,
  connectionId,
  contextId,
  getOidcToken,
  getSamlToken,
  children
}: GetLinkPluginsProviderProps): JSX.Element {
  return (
    <TokenProvider value={{ getOidcToken, getSamlToken }}>
      <PlainHttpServiceProvider value={{ baseWsgUrl: '/', tokenType: 'OIDC' }}>
        <ConnectionResolver
          buddiRegionCode={buddiRegionCode}
          connectionId={connectionId}
          contextId={contextId}
        >
          {children}
        </ConnectionResolver>
      </PlainHttpServiceProvider>
    </TokenProvider>
  );
}
