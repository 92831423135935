import type { HttpService, WSGInstance } from '@bentley/pw-api';
import { parseResponseInstances } from '@bentley/pw-api';
import type { FormInterface } from '../formDefinition';

export async function getInterfaces(
  environmentClassName: string,
  httpService: HttpService
): Promise<FormInterface[]> {
  const query = getInterfacesQuery(environmentClassName);

  // Should be uncached, as the default interface may have changed
  const response = await httpService.get(query, { uncached: true });

  const interfaces = await parseResponseInstances<FormInterface>(response);
  return interfaces;
}

function getInterfacesQuery(className: string): string {
  const filter = `EnvironmentClassInterface-backward-MetaSchema.ECClassDef.$id eq 'N~3APW_WSG_Dynamic.01.02~3A${className}'`;

  return `PW_WSG/Interface?$filter=${filter}`;
}

export async function updateDefaultInterface(
  newInstanceId: string,
  httpService: HttpService
): Promise<void> {
  await setDefaultInterface(newInstanceId, httpService);
}

async function setDefaultInterface(
  instanceId: string,
  httpService: HttpService
): Promise<void> {
  const instance = defaultInterfaceInstance(instanceId);

  const response = await httpService.post(
    `PW_WSG/Interface/${instanceId}`,
    JSON.stringify({ instance })
  );

  if (!response.ok || response.status != 200) {
    throw new Error('Unable to set default interface');
  }
}

function defaultInterfaceInstance(instanceId: string): WSGInstance {
  return {
    instanceId: instanceId,
    schemaName: 'PW_WSG',
    className: 'Interface',
    properties: {
      IsDefault: true
    },
    relationshipInstances: [],
    changeState: 'modified'
  } as WSGInstance;
}
