import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import type { GetItemStatusBadge } from '../../../../hooks/useItemStatusBadge';

type CellIconProps = {
  item: PWItem;
  getItemStatusBadge: GetItemStatusBadge;
};

export function CellIcon({
  item,
  getItemStatusBadge
}: CellIconProps): JSX.Element {
  const badge = getItemStatusBadge(item);

  return <ItemIcon item={item} size="m" badge={badge} />;
}
