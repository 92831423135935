import type { HttpConfiguration } from './data';
import { contactConnectionEndpoint, contactPluginsEndpoint } from './data';
import type { WsgVersion } from './wsgVersion';
import { buildWsgVersion, isValidVersionString } from './wsgVersion';

export type ECPluginVersion = number | null;

export async function getECPluginVersion(
  config: HttpConfiguration
): Promise<ECPluginVersion> {
  const ecResponse = await contactConnectionEndpoint(config);
  const ecVersion = parseECVersionHeaders(ecResponse);
  return ecVersion;
}

export async function getWsgVersion(
  config: HttpConfiguration
): Promise<string> {
  const wsgResponse = await contactConnectionEndpoint(config);
  const wsgVersion = parseWSGVersion(wsgResponse);
  return wsgVersion;
}

export async function getECPluginBuild(
  config: HttpConfiguration
): Promise<string> {
  try {
    const ecResponse = await contactConnectionEndpoint(config);
    const ecBuild = parsePWVersion(ecResponse);
    return ecBuild;
  } catch {
    return '';
  }
}

export async function getMasServerVersion(
  config: HttpConfiguration
): Promise<WsgVersion | undefined> {
  const masResponse = await contactConnectionEndpoint(config);
  const masServerVersion = parsePWVersion(masResponse);
  const wsgVersion = buildWsgVersion(masServerVersion);
  return wsgVersion;
}

export async function getWebApiVersion(serverUrl: string): Promise<string> {
  const webApiResponse = await contactPluginsEndpoint(serverUrl);
  const webApiVersion = parseWebApiVersion(webApiResponse);
  return webApiVersion;
}

export function parseECVersionHeaders(response: Response): ECPluginVersion {
  const serverHeader = getServerHeader(response);
  const versionString = parsePWVersion(response);

  if (serverHeader && !versionString) {
    console.error('Bentley.PW server header not sent in response');
    return 3.0;
  }
  if (!versionString) {
    return null;
  }

  return informalECPluginVersion(versionString);
}

function parsePWVersion(response: Response): string {
  const serverHeader = getServerHeader(response);
  const pwVersion = getServerVersion(serverHeader, 'Bentley.PW');

  if (!isValidVersionString(pwVersion)) {
    return '';
  }

  return pwVersion ?? '';
}

function parseWSGVersion(response: Response): string {
  const serverHeader = getServerHeader(response);
  const wsgVersion = getServerVersion(serverHeader, 'Bentley-WSG');

  if (!isValidVersionString(wsgVersion)) {
    return '';
  }

  return wsgVersion ?? '';
}

function parseWebApiVersion(response: Response): string {
  const serverHeader = getServerHeader(response);
  const webApiVersion = getServerVersion(serverHeader, 'Bentley-WebAPI');
  return webApiVersion ?? '';
}

function getServerHeader(response: Response): string | null {
  if (!response?.headers) {
    return null;
  }

  return response.headers.get('mas-server') ?? response.headers.get('server');
}

function getServerVersion(
  serverHeader: string | null,
  serverSchema: string
): string | undefined {
  const schemas = serverHeader?.split(',');
  const matchingSchema = schemas?.find((schema) =>
    schema.trim().toLowerCase().startsWith(serverSchema.toLowerCase())
  );
  const schemaVersion = matchingSchema?.split('/')[1];
  return schemaVersion;
}

export function informalECPluginVersion(
  versionString: string
): ECPluginVersion {
  if (!isValidVersionString(versionString)) {
    return null;
  }
  const wsgVersion = buildWsgVersion(versionString);
  if (!wsgVersion) {
    return null;
  }

  const { year, major, minor, patch } = wsgVersion;

  // Version standards changed after major version 10. We now use YY.aa.bb.cc (Year.major.minor.patch)
  // This will return a larger value, still as a number
  // ex. '23.06.03.12' will become 230603.12
  if (year >= 23) {
    const majorString = major < 10 ? `0${major}` : `${major}`;
    const minorString = minor < 10 ? `0${minor}` : `${minor}`;
    return Number(`${year}${majorString}${minorString}.${patch}`);
  }

  if (year >= 10 && major >= 0 && minor >= 3) {
    return Number(`${minor}.${patch}`);
  }

  return 3.0;
}
