import React, { useMemo, useState } from 'react';
import type { SelectOption } from '@itwin/itwinui-react';
import {
  BaseFilter,
  ComboBox,
  FilterButtonBar,
  tableFilters
} from '@itwin/itwinui-react';
import type { TableFilterProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../services/translation';

export function buildDropdownFilter(
  values: string[]
): (props: TableFilterProps<Record<string, unknown>>) => JSX.Element {
  return DropdownFilter;

  function DropdownFilter(
    props: TableFilterProps<Record<string, unknown>>
  ): JSX.Element {
    const { column, clearFilter, setFilter } = props;

    const [value, setValue] = useState<string>(column.filterValue as string);

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
      if (event.key === 'Enter') {
        event.stopPropagation(); // Prevent sorting header
        const value = event.currentTarget.value;
        setValue(value);
        setFilter(value);
      }
    }

    const options = useMemo((): SelectOption<string>[] => {
      const dropdownValues = values.map((value) => ({
        label: String(value),
        value: value
      }));

      if (value && !dropdownValues.some((option) => option.value == value)) {
        dropdownValues.unshift({ label: value, value: value });
      }

      return dropdownValues;
    }, [value]);

    const translatedLabels = useMemo(
      () => ({
        filter: t('Search.Filter'),
        clear: t('Search.Clear'),
        from: t('Generic.From'),
        to: t('Generic.To')
      }),
      []
    );

    if (!options.length) {
      const textFilter = tableFilters.TextFilter(translatedLabels);
      return textFilter(props) as JSX.Element;
    }

    return (
      <BaseFilter>
        <ComboBox
          options={options}
          value={value}
          onChange={setValue}
          inputProps={{ autoFocus: !value, onKeyDown }}
          enableVirtualization={true}
        />
        <FilterButtonBar
          setFilter={() => setFilter(value)}
          clearFilter={clearFilter}
          translatedLabels={translatedLabels}
        />
      </BaseFilter>
    );
  }
}
