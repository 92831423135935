import React, { useMemo } from 'react';
import { SvgFlag } from '@itwin/itwinui-icons-react';
import { LdFlagsModal } from '../../../../actions/ldFlagsModal';
import { useNavigationContext } from '../../../../context';
import { productionEnvironment } from '../../../../services/environment';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useLaunchDarklyOption(): MenuItemAction {
  const { primaryModal } = useNavigationContext();

  const launchDarklyOption = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.DisplayLdFlags'),
      icon: <SvgFlag />,
      onClick: () => primaryModal.open(<LdFlagsModal />),
      hidden: productionEnvironment() == 'prod'
    }),
    [primaryModal]
  );

  return launchDarklyOption;
}
