import './navigationButtons.css';

import React from 'react';
import { Button } from '@itwin/itwinui-react';
import { ApplyToRemaining } from '../applyToRemaining';

type ButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'as'>;

type NavigationButtonsProps = {
  backButton?: ButtonProps;
  nextButton?: ButtonProps;
  createButton?: ButtonProps;
  cancelButton?: ButtonProps;
};

export function NavigationButtons({
  backButton,
  nextButton,
  createButton,
  cancelButton
}: NavigationButtonsProps): JSX.Element {
  const showBackOrNext = showButton(backButton) || showButton(nextButton);

  function showButton(button?: ButtonProps): boolean {
    return button != undefined && !button.hidden;
  }

  function spacer(): JSX.Element {
    return <div className="dcw-nav-spacer" />;
  }

  function placedButton(buttonProps: ButtonProps | undefined): JSX.Element {
    if (!showButton(buttonProps)) {
      return <Button className="dcw-nav-hidden-button" />;
    }

    return <Button {...buttonProps} />;
  }

  return (
    <div className="dcw-wizard-progression">
      <ApplyToRemaining>
        {showBackOrNext && placedButton(backButton)}
        {showBackOrNext && placedButton(nextButton)}
        {showBackOrNext && spacer()}
        {placedButton(createButton)}
        {placedButton(cancelButton)}
      </ApplyToRemaining>
    </div>
  );
}
