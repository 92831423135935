import _ from 'lodash';
import type { WSGInstance, WSGProperties } from '@bentley/pw-api';

export function mergePropertiesIntoInstance(
  instance: WSGInstance,
  properties: WSGProperties
): WSGInstance {
  const updatedInstance = _.cloneDeep(instance);
  const mergedProperties = mergeProperties(
    updatedInstance.properties,
    properties
  );
  updatedInstance.properties = mergedProperties;
  return updatedInstance;
}

export function mergeProperties(
  originalProperties: WSGProperties | undefined,
  updatedProperties: WSGProperties | undefined
): WSGProperties {
  const mergedProperties = {
    ...originalProperties,
    ...updatedProperties
  } as WSGProperties;
  return mergedProperties;
}
