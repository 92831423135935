import type { HttpService, PWItem } from '@bentley/pw-api';
import { getItem, itemIsProject } from '@bentley/pw-api';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { openToast } from '../../services/pwToast';
import type { SavedSearchDefinition } from '../../services/search';
import { sanitizeDataSourceId } from '../../services/stringFormatter';
import type { Connection } from '../../services/support/connection';
import { t } from '../../services/translation';
import type { Application } from '../getShareableLink/getShareableLink';

type HasURIScheme = {
  isRegistered: boolean;
};

async function isPWEInstalled(
  httpDriveService: HttpService
): Promise<boolean | undefined> {
  if (!httpDriveService.post) {
    return undefined;
  }

  try {
    const pweIsInstalledResponse = await httpDriveService.post(
      'hasUriScheme',
      JSON.stringify({ scheme: 'pw' })
    );
    const { isRegistered } =
      (await pweIsInstalledResponse.json()) as HasURIScheme;
    return isRegistered;
  } catch {
    return undefined;
  }
}

function displayOpenInPWENewTab(url: string): void {
  const openInPWETab = window.open(url);
  openInPWETab?.document.write(
    `
    <html>
      <head>
        <title>
          ${t('OpenInPWE.NewTab.Title')}
        </title>
        <style>
          p {
            font-family: "Noto Sans", "Open Sans", system-ui, sans-serif;
            font-size: 18px;
          }
        </style>
      </head>
      <body>
        <div style="display: flex; justify-content: center; align-items: center; text-align: center; height: 100%;">
          <p style="width: 880px;">
            ${t('OpenInPWE.NewTab.Content')}
          </p>
        </div>
      </body>
    </html>
    `
  );
  openInPWETab?.document.close();
}

export async function openInProjectWiseLink(
  item: PWItem,
  connection: Connection,
  httpService: HttpService,
  httpDriveService: HttpService,
  trackFeature?: TrackFeature,
  inTeams?: boolean,
  getLink?: (
    item: PWItem | SavedSearchDefinition,
    selectedApp: Application
  ) => string
): Promise<void> {
  trackFeature?.('OPEN_IN_PROJECTWISE');
  if (inTeams) {
    const url = getLink?.(item, 'Explorer');
    window.open(url);
    return;
  }

  const className: string = itemIsProject(item) ? 'P' : 'D';

  const testedItem = await getItem({
    instanceId: item.instanceId,
    httpService,
    requestOptions: { uncached: true }
  });
  if (!testedItem) {
    const content = t('OpenInPWE.ItemDoesNotExist');
    openToast({ content, category: 'negative' });

    return;
  }

  const url = generateProjectWiseExplorerLink(
    connection.DatasourceInstanceId,
    className,
    item.instanceId
  );

  const pweIsInstalled = await isPWEInstalled(httpDriveService);

  if (pweIsInstalled || pweIsInstalled === undefined) {
    displayOpenInPWENewTab(url);
  } else if (!pweIsInstalled) {
    openToast({
      category: 'warning',
      content: t('OpenInPWE.Toast.PWEIsNotInstalled')
    });
  }
}

export function generateProjectWiseExplorerLink(
  datasourceInstanceId: string,
  className: string,
  instanceId: string
): string {
  const datasource = sanitizeDataSourceId(datasourceInstanceId).replace(
    /~20/g,
    '%20'
  );
  return `pw://${datasource}/Documents/${className}{${instanceId}}/`;
}
