import type {
  HttpService,
  PWItem,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { modifyItem } from '@bentley/pw-api';
import { t } from '../../services/translation';

const ERROR_ACCESS_FORBIDDEN = t('Rename.UnauthorizedAccess');
const ERROR_NETWORKISSUE = t('Rename.NetworkError');
const ERROR_UNKNOWN = t('Generic.UnknownError');
const ERROR_NOT_FOUND = t('Generic.FileNotFound');
const ERROR_CONFLICT = t('Rename.NameAlreadyExist');
const ERROR_CONFLICT_FILENAME = t('ConflictResolution.FileNameExists');

/**
 * Sends network request to rename item
 * @param {PWItem} item Item to rename
 * @param {HttpService} httpService Configured http service
 * @param {RenameForm} renameFormData Rename information to submit
 */
export async function renameItem(
  item: PWItem,
  httpService: HttpService,
  name: string,
  description?: string,
  fileName?: string,
  version?: string
): Promise<Response> {
  async function sendRenameRequest(): Promise<Response> {
    try {
      const response = await modifyItem({
        item,
        properties: {
          Name: name,
          Description: description,
          FileName: fileName,
          Version: version
        },
        httpService
      });

      return response;
    } catch (err) {
      throw new Error(ERROR_NETWORKISSUE);
    }
  }

  const response = await sendRenameRequest();

  if (response.status === 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }
  if (response.status === 404) {
    throw new Error(ERROR_NOT_FOUND);
  }
  if (response.status === 409) {
    const data = (await response.json()) as WSGInstancesResponse;
    const conflictErrorMessage: string = data.errorMessage ?? '';
    if (conflictErrorMessage.search('file name') > -1) {
      throw new Error(ERROR_CONFLICT_FILENAME);
    } else if (conflictErrorMessage.search('Document name|Folder name') > -1) {
      throw new Error(ERROR_CONFLICT);
    } else {
      throw new Error(ERROR_UNKNOWN);
    }
  }
  if (!response.ok) {
    const data = (await response.json()) as WSGInstancesResponse;
    const conflictErrorMessage = data.errorMessage;
    if (conflictErrorMessage) {
      throw new Error(conflictErrorMessage);
    }
    throw new Error(ERROR_UNKNOWN);
  }
  return response;
}
