import { useEffect } from 'react';
import {
  AuthorizationService,
  HttpService,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { GetToken } from '../../services/http';
import { useSessionStorage } from '../useStorage';
import type { RBACPermission } from './rbacPermission';

export function useRbac(
  contextId: string | undefined,
  buddiRegionCode: string,
  getOidcToken: GetToken
): RBACPermission[] {
  const [permissions, setPermissions] = useSessionStorage<RBACPermission[]>(
    `rbac${contextId ? `:${contextId}` : ''}`,
    []
  );
  const pwWebUrl = useBuddi('ProjectWiseWAC.URL', buddiRegionCode);

  async function getRbacPermissions(
    pwWebUrl: string,
    contextId: string
  ): Promise<RBACPermission[]> {
    try {
      const urlParams = new URLSearchParams({ contextId });
      const httpService = new HttpService({
        authorization: new AuthorizationService({ getOidcToken }),
        baseUrl: pwWebUrl
      });

      const response = await httpService.get(
        `api/rbac?${urlParams.toString()}`
      );

      if (response.status == 200) {
        const permissions =
          await parseResponseRelationshipInstances<RBACPermission>(response);
        return permissions;
      }

      return [];
    } catch {
      return [];
    }
  }

  useEffect(() => {
    async function getRbac(): Promise<void> {
      if (!pwWebUrl || !contextId || permissions.length) {
        return;
      }

      const rbacPermissions = await getRbacPermissions(pwWebUrl, contextId);
      setPermissions(rbacPermissions);
    }

    void getRbac();
  }, [contextId, permissions.length, pwWebUrl, setPermissions]);

  return permissions;
}
