import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { ListItem, TransferList } from '@itwin/itwinui-react';
import type { TransferData } from './columnSelection';

type TransferListBoxProps<T> = {
  data: T[];
  label?: string;
  showDescription?: boolean;
  filter?: (item: T) => boolean;
  sort?: (a: T, b: T) => number;
  setData: Dispatch<SetStateAction<T[]>>;
};

export function TransferListBox<T extends TransferData>({
  data,
  label,
  showDescription,
  filter,
  sort,
  setData
}: TransferListBoxProps<T>): JSX.Element {
  const transferListItems = filter ? data.filter(filter) : data;
  if (sort) {
    transferListItems.sort(sort);
  }

  return (
    <TransferList.ListboxWrapper data-testid={`${label}-listbox`}>
      {label && <TransferList.ListboxLabel>{label}</TransferList.ListboxLabel>}
      <TransferList.Listbox className="column-list">
        {transferListItems.map((item, index) => (
          <TransferList.Item
            actionable
            active={item.active}
            onActiveChange={(isActive) =>
              setData((oldData) => {
                const newData = [...oldData];
                const index = oldData.indexOf(item);
                newData[index].active = isActive;
                return newData;
              })
            }
            key={item.label + index}
            data-testid={`${label}-${item.label}`}
          >
            <ListItem.Content>
              <div data-col={label}>{item.label}</div>
              {showDescription && (
                <ListItem.Description>{item.description}</ListItem.Description>
              )}
            </ListItem.Content>
          </TransferList.Item>
        ))}
      </TransferList.Listbox>
    </TransferList.ListboxWrapper>
  );
}
