import React, { useCallback, useMemo } from 'react';
import { itemIsProject } from '@bentley/pw-api';
import {
  SvgDockBottom,
  SvgDockRight,
  SvgSettings
} from '@itwin/itwinui-icons-react';
import { IconButton } from '@itwin/itwinui-react';
import { FolderSettingsModal } from '../../actions/dynamicFolderSettings';
import {
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import { useLocalStorage } from '../../hooks/useStorage';
import { t } from '../../services/translation';

type InfoPanelActions = {
  actions: JSX.Element;
  isOpen: boolean;
  orientation: 'vertical' | 'horizontal';
  onClose: () => void;
};

export function useInfoPanelActions(): InfoPanelActions {
  const { readOnly } = usePluginContext();
  const { infoPanelDisplayed, primaryModal, setInfoPanelDisplayed } =
    useNavigationContext();
  const { actionableItems } = useTableItemContext();

  const [dockRight, setDockRight] = useLocalStorage(
    'info-panel-dock-right',
    true
  );

  const showFolderSettings = useMemo((): boolean => {
    if (actionableItems.length !== 1) {
      return false;
    }

    const item = actionableItems[0];

    if (!itemIsProject(item)) {
      return false;
    }

    if (readOnly) {
      return false;
    }

    return true;
  }, [actionableItems, readOnly]);

  const folderSettingsButton = useMemo(() => {
    if (!showFolderSettings) {
      return null;
    }

    return (
      <IconButton
        label={t('InfoPanel.FolderSettings')}
        styleType="borderless"
        data-testid="FoldersettingsButton"
        onClick={() => {
          primaryModal.open(<FolderSettingsModal />);
        }}
      >
        <SvgSettings />
      </IconButton>
    );
  }, [showFolderSettings, primaryModal]);

  const toggleDockButton = useMemo(() => {
    return (
      <IconButton
        label={
          dockRight ? t('InfoPanel.PinToBottom') : t('InfoPanel.PinToRight')
        }
        styleType="borderless"
        onClick={() => setDockRight((cur) => !cur)}
      >
        {dockRight ? (
          <SvgDockBottom data-testid="toggle-dock" />
        ) : (
          <SvgDockRight data-testid="toggle-dock" />
        )}
      </IconButton>
    );
  }, [dockRight, setDockRight]);

  const actions = useMemo(() => {
    return (
      <>
        {folderSettingsButton}
        {toggleDockButton}
      </>
    );
  }, [folderSettingsButton, toggleDockButton]);

  const onClose = useCallback(() => {
    setInfoPanelDisplayed(false);
  }, [setInfoPanelDisplayed]);

  const infoPanelActions = useMemo(
    (): InfoPanelActions => ({
      actions,
      isOpen: infoPanelDisplayed,
      orientation: dockRight ? 'vertical' : 'horizontal',
      onClose
    }),
    [actions, dockRight, infoPanelDisplayed, onClose]
  );

  return infoPanelActions;
}
