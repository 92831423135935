import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsDocument } from '@bentley/pw-api';
import { SvgComputer } from '@itwin/itwinui-icons-react';
import { showFileOpenMenu } from '../../../../actions/sync';
import { useAppViewContext, usePluginContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';
import type { ItemMenuType } from '../useItemActions';
import { useO365CoAuthoringAction } from './useO365CoAuthoringAction';
import { useOpenItemInDesktopAppAction } from './useOpenItemInDesktopAppAction';
import { usePDFItemAction } from './usePDFItemAction';
import { useTakeOverCoAuthoringSessionAction } from './useTakeOverCoAuthoringSessionAction';

type OpenItemsActions = {
  getOpenItemActions: (menuType: ItemMenuType) => MenuItemAction[];
};

export function useOpenItemActions(items: PWItem[]): OpenItemsActions {
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const openPDFItem = usePDFItemAction(items);
  const officeCoAuthoringItem = useO365CoAuthoringAction(items);
  const openItemInDesktopItem = useOpenItemInDesktopAppAction(items);
  const takeOverCoAuthoringSessionItem =
    useTakeOverCoAuthoringSessionAction(items);
  const { dataAccessLevel } = usePluginContext();
  const childItems = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems([
        openItemInDesktopItem,
        officeCoAuthoringItem,
        takeOverCoAuthoringSessionItem,
        openPDFItem
      ]),
    [
      openItemInDesktopItem,
      officeCoAuthoringItem,
      takeOverCoAuthoringSessionItem,
      openPDFItem
    ]
  );

  const showOpenItemPlaceholder = useMemo((): boolean => {
    return items.every((item) => itemIsDocument(item));
  }, [items]);

  const showOpenItemActions = useMemo((): boolean => {
    if (!childItems.length) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    if (!showFileOpenMenu(items)) {
      return false;
    }

    return true;
  }, [childItems.length, currentAppView.type, items]);

  const useOpenItemActions = useMemo((): MenuItemAction => {
    if (childItems.length == 0 && dataAccessLevel == 'WorkArea') {
      return {
        title: t('MenuItem.Open'),
        icon: <SvgComputer />,
        hidden: !showOpenItemPlaceholder,
        disabled: true
      };
    }
    if (childItems.length == 1) {
      return childItems[0];
    } else {
      return {
        title: t('MenuItem.Open'),
        icon: <SvgComputer />,
        hidden: !showOpenItemActions,
        submenuItems: childItems
      };
    }
  }, [
    showOpenItemActions,
    showOpenItemPlaceholder,
    childItems,
    dataAccessLevel
  ]);

  const getOpenItemActions = useCallback(
    (menuType: ItemMenuType) => {
      if (menuType == 'toolbarMenu') {
        return [useOpenItemActions];
      } else {
        return childItems;
      }
    },
    [useOpenItemActions, childItems]
  );

  return {
    getOpenItemActions
  };
}
