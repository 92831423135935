import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { getProjectChildren } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';
import type { FetchItemsArgs } from './appViewSetting';
import type { AppViewSetting } from '..';

export function useRecycleBinView(): AppViewSetting {
  const httpService = useHttpService();
  const {
    ecPluginFeatures: { environmentQueryPerformance }
  } = useEnvironmentContext();
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const fetchItems = useCallback(
    async ({ requestOptions }: FetchItemsArgs): Promise<PWItem[]> => {
      const items = await getProjectChildren({
        parentId: currentParent.instanceId,
        selectRelationships: {
          parent: true,
          environment: environmentQueryPerformance()
        },
        httpService,
        requestOptions
      });
      return items;
    },
    [currentParent.instanceId, environmentQueryPerformance, httpService]
  );

  function clearView(): void {
    // No-op
  }

  const recycleBinViewSetting = useMemo(
    (): AppViewSetting => ({ type: 'RecycleBin', fetchItems, clearView }),
    [fetchItems]
  );

  return recycleBinViewSetting;
}
