import { useEffect, useMemo, useState } from 'react';
import {
  useConnectionAuth,
  useEnvironmentContext,
  useHttpService,
  useTableItemContext
} from '../../context';
import type { CurrentUserFolderAccessRights } from '../../services/permissions';
import { getUserFolderPermissions } from '../../services/permissions';

export function useFolderPermissions(): boolean | undefined {
  const { isAdmin } = useConnectionAuth();
  const httpService = useHttpService();
  const { ecPluginFeatures } = useEnvironmentContext();
  const { actionableItems } = useTableItemContext();

  const [folderPermissions, setFolderPermissions] =
    useState<CurrentUserFolderAccessRights>();

  const folderSettingsEnabled = useMemo((): boolean | undefined => {
    if (!ecPluginFeatures.userFolderPermissions()) {
      return isAdmin;
    }

    return folderPermissions?.Write;
  }, [ecPluginFeatures, folderPermissions, isAdmin]);

  useEffect(() => {
    async function initializeFolderPermissions(): Promise<void> {
      const selectedItem = actionableItems[0];
      if (!selectedItem) {
        return;
      }

      const folderPermissions = await getUserFolderPermissions(
        selectedItem.instanceId,
        httpService
      );
      setFolderPermissions(folderPermissions);
    }

    void initializeFolderPermissions();
  }, [actionableItems, httpService]);

  return folderSettingsEnabled;
}
