import './editDocCodeModal.css';

import React, { useEffect } from 'react';
import { DocCodeModalControl } from '../../components/docCodeForm';
import { PWModal } from '../../components/pwModal';
import { useFeatureTracking, useNavigationContext } from '../../context';
import { t } from '../../services/translation';

export function EditDocCodeModal(): JSX.Element {
  const { trackFeature } = useFeatureTracking();

  const { primaryModal } = useNavigationContext();

  useEffect(() => {
    trackFeature('EDIT_DOC_CODE_MODAL');
  }, [trackFeature]);

  return (
    <PWModal
      title={t('DocCode.EditDocCode')}
      className="edit-doc-code-modal"
      size="l"
      onClose={primaryModal.close}
    >
      <DocCodeModalControl />
    </PWModal>
  );
}
