import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import { usePropertyContext } from '../../../context';
import { minColumnWidth } from '../../../hooks/useColumnWidths';
import type { ColumnDisplayOptions, ColumnInfo } from '../../../hooks/useViews';
import { useFormattedCell } from '../cell/formattedCell';
import { ColumnHeader } from '../cell/header/columnHeader';
import { useTableFilters } from '../filter/useTableFilters';
import { columnSortType } from '../sort/columnSort';
import { propertyAccessor } from './accessor';
import { getColumnDataType } from './typedColumn';

type BaseColumnFunction = (
  columnInfo: ColumnInfo,
  displayOptions?: ColumnDisplayOptions | undefined
) => Column<PWItem>;

export function useBaseColumn(): BaseColumnFunction {
  const { columnWidthManager } = usePropertyContext();

  const formattedCell = useFormattedCell();
  const { columnFilter, columnFilterType } = useTableFilters();

  const baseColumn = useCallback(
    (
      { displayLabel, name, dataType, propertyType }: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const columnDataType = getColumnDataType(dataType);
      const valuesFilter = propertyType != 'General';

      const columnId =
        propertyType == 'General' ? name : `${propertyType}.${name}`;

      const columnWidth = columnWidthManager.getColumnWidth(columnId, {
        ...displayOptions,
        columnDataType
      });

      return {
        id: columnId,
        Header: () => ColumnHeader({ displayLabel }),
        accessor: propertyAccessor(name as keyof PWItem, columnDataType),
        Cell: formattedCell(columnDataType, displayOptions),
        sortType: columnSortType(columnDataType),
        Filter: columnFilter(columnDataType, valuesFilter),
        filter: columnFilterType(columnDataType),
        width: displayOptions?.isLast ? undefined : columnWidth,
        minWidth: displayOptions?.isLast ? minColumnWidth + 10 : minColumnWidth
      };
    },
    [columnFilter, columnFilterType, columnWidthManager, formattedCell]
  );

  return baseColumn;
}
