import type {
  HttpService,
  PWDataItem,
  PWItem,
  WSGInstance
} from '@bentley/pw-api';
import { uploadFile } from '@bentley/pw-api';
import { openFileInput } from '../../components/fileInput';
import type { OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import type { PWDriveManager } from '../drive';
import type { DriveResponseData } from '../drive/pwDrive.utils';
import {
  addIsLockedFileStatusToItem,
  checkInFileDrive,
  getFileLockedStatus,
  isValidDriveData
} from '../drive/pwDrive.utils';
import { checkInFileModal } from './modals';
import {
  notifyCheckIn,
  notifyCheckInDocumentSuccess,
  notifyCheckInErrorWhenDriveIsOff,
  notifyCheckedInDocumentsWithPartialSuccess,
  notifyDocumentCheckInSuccess,
  notifyDownloadDrive,
  notifyErrorDuringCheckIn,
  notifyFileLockedError,
  notifyInternalServerErrorDrive
} from './notifications';
import { allowCheckForDriveIntegratedMode } from './requirements';

export async function checkInFile(
  items: PWItem[],
  onSuccess: () => void,
  userId: string,
  httpService: HttpService,
  openModal: OpenModal,
  trackFeature: TrackFeature,
  contextId: string,
  driveData: PWDriveManager
): Promise<void> {
  if (isValidDriveData(driveData)) {
    const lockedInfo = await getFileLockedStatus(
      items,
      userId,
      driveData.httpDriveService,
      contextId
    );
    items.forEach((item) => addIsLockedFileStatusToItem(item, lockedInfo));
  }

  const checkInWithDrive = allowCheckForDriveIntegratedMode(
    driveData,
    items as PWDataItem[]
  );

  if (checkInWithDrive) {
    await checkInPwDriveFile(
      driveData.httpDriveService,
      onSuccess,
      items as PWDataItem[],
      userId,
      contextId,
      trackFeature
    );
  } else {
    startCheckInFile(
      items[0] as PWDataItem,
      httpService,
      openModal,
      trackFeature,
      onSuccess
    );
  }
}

/**
 * Initiates the check in workflow by putting up file select dialog
 * @param {PWDataItem} oldDocument document that is being checked in
 * @param {HttpService} httpService HttpService for post requests
 * @param {OpenModal} openModal function to open main UI modal
 * @param {CloseModal} closeModal funciton to close main UI modal
 * @param {TrackFeature} trackFeature function for tracking feature usage
 * @param {() => void} onSuccess callback for successful check in operation
 */
export function startCheckInFile(
  oldDocument: PWDataItem,
  httpService: HttpService,
  openModal: OpenModal,
  trackFeature: TrackFeature,
  onSuccess: () => void
): void {
  const handleInput = (file: File) => {
    checkInFileModal(
      openModal,
      oldDocument,
      file,
      httpService,
      trackFeature,
      onSuccess
    );
  };

  openFileInput(handleInput);
}

/**
 * Workflow for checking in file
 * @param newFile new file updating old item
 * @param oldItem Document being updated
 * @param httpService HttpService for put and post requests
 * @param trackFeature function for tracking feature usage
 * @param onSuccess callback for successful operation
 */
export async function checkInWorkflow(
  newFile: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onSuccess: () => void
): Promise<void> {
  const toastHandle = notifyCheckIn(1);

  trackFeature('CHECKIN_FILE');

  const response = await checkIn(newFile, oldItem, httpService);
  if (response.status == 200) {
    onSuccess();
    notifyDocumentCheckInSuccess(toastHandle);
  } else {
    const errorMsg = (await response?.json()) as Record<string, string>;
    notifyErrorDuringCheckIn(
      toastHandle,
      errorMsg.errorMessage ?? t('Sync.GenericFailedToCheckIn')
    );
  }
}

/**
 * Function that performs file check in
 * @param newFile new file updating old item
 * @param oldItem Document being updated
 * @param httpService HttpService for put and post requests
 * */
export async function checkIn(
  newFile: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  comment?: string
): Promise<Response> {
  let url: string;
  let checkInData: { instance: WSGInstance; file?: File };
  if (comment) {
    url = `PW_WSG/Document/${oldItem.instanceId}/CheckIn`;
    checkInData = {
      instance: {
        instanceId: oldItem.instanceId,
        schemaName: 'PW_WSG',
        className: 'CheckIn',
        changeState: 'new',
        properties: {
          comment: comment
        }
      }
    };
  } else {
    url = `PW_WSG/Document/${oldItem.instanceId}`;
    checkInData = {
      instance: {
        schemaName: 'PW_WSG',
        className: 'Document',
        instanceId: oldItem.instanceId,
        properties: {
          IsCheckedOut: false
        }
      },
      file: newFile
    };
  }
  const fileName = oldItem.FileName !== '' ? oldItem.FileName : oldItem.Name;
  const headers = {
    'Content-Disposition':
      'attachment; IsFileNameUrlEncoded=true; filename="' +
      encodeURIComponent(fileName) +
      '"',
    'Content-Range': `bytes */${newFile.size}`
  };

  const response = await httpService.post(url, JSON.stringify(checkInData), {
    headers
  });
  if (response.status == 308) {
    const etag = response.headers.get('etag') ?? '';

    const updateResponse = await uploadFile(
      {
        file: newFile,
        documentOptions: { instanceId: oldItem.instanceId, FileName: fileName },
        httpService
      },
      etag
    );

    return updateResponse;
  } else {
    return response;
  }
}

/**
 * This methods returns api response from drive when checkin
 * @param httpDriveService
 * @param onSuccess
 * @param selectedItems
 * @param userId
 */
export async function checkInPwDriveFile(
  httpDriveService: HttpService,
  onSuccess: () => void,
  selectedItems: PWDataItem[],
  userId: string,
  contextId: string,
  trackFeature?: TrackFeature
): Promise<DriveResponseData[]> {
  const toastHandle = notifyCheckIn(selectedItems.length);
  const checkInActions: Promise<Response>[] = selectedItems.map(
    (selectedItem) => {
      return checkInFileDrive(
        {
          UserId: userId,
          InstanceId: selectedItem.instanceId,
          ProjectId: contextId
        },
        httpDriveService,
        trackFeature
      ).catch((res) => {
        return Promise.reject(notifyCheckInErrorWhenDriveIsOff(toastHandle));
      });
    }
  );

  const responses = await Promise.all(checkInActions);
  if (responses.some(({ status }) => status == 500 || status == 401)) {
    return Promise.reject(notifyInternalServerErrorDrive(toastHandle));
  } else if (responses.some(({ status }) => status == 503)) {
    return Promise.reject(notifyDownloadDrive(toastHandle));
  }
  const driveResponses = await Promise.all(responses.map(parseResponse));

  if (driveResponses.some((item) => !item.data.success)) {
    const successCount = driveResponses.filter(
      (item) => item.data.success == true
    ).length;
    const fileLockedCount = driveResponses.filter(
      (item) => item.data.fileLockedByMe
    ).length;
    if (fileLockedCount > 0) {
      notifyFileLockedError(
        fileLockedCount,
        selectedItems.length,
        toastHandle,
        successCount
      );
    }

    if (driveResponses.some((item) => !item.data.success)) {
      notifyErrorDuringCheckIn(
        toastHandle,
        driveResponses.filter((item) => !item.data.success)[0].data.message
      );
    }
    // If User selects  multiple files , out of which some files are successfully checked in so show notification for success
    if (successCount > 0) {
      notifyCheckedInDocumentsWithPartialSuccess(successCount);
    }
    // Refresh table if multiple files are freed , out of which some files are successfully checked in so refresh the table
    if (fileLockedCount != selectedItems.length) {
      onSuccess();
    }
    return Promise.reject(t('Sync.GenericFailedToCheckIn'));
  } else {
    onSuccess();
    notifyCheckInDocumentSuccess(selectedItems.length, toastHandle);
  }

  return driveResponses;
}

async function parseResponse(response: Response): Promise<DriveResponseData> {
  const driveResponse = (await response.json()) as DriveResponseData;
  if (response.ok) {
    return driveResponse;
  }

  return {
    data: {
      fileLockedByMe: false,
      success: false,
      message: driveResponse?.data?.message ?? response.statusText
    },
    status: driveResponse.status ?? response.statusText
  };
}
