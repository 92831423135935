import { useEffect, useState } from 'react';

export function useFocusedTab(): boolean {
  const [focused, setFocused] = useState<boolean>(true);

  useEffect(() => {
    function onVisibilityChange(): void {
      setFocused(!document.hidden);
    }

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return focused;
}
