import type { PWItem } from '@bentley/pw-api';
import { filterFileTypes, filterProjects, pwConstants } from '@bentley/pw-api';
import type { InputError } from '../../hooks/useForm';
import { t } from '../../services/translation';
import type { RenameForm } from './renameModal';

export function validateName(
  inputs: RenameForm,
  value: string,
  name: keyof RenameForm,
  editedItem: PWItem,
  itemsInFolder: PWItem[]
): InputError<RenameForm> {
  const availableFolders = filterProjects(itemsInFolder).filter(
    ({ instanceId }) => instanceId != editedItem.instanceId
  );

  if (inputs.isFolder) {
    return validateFolderName(value, availableFolders);
  }

  if (name == 'name') {
    return validateDocumentName(value);
  }
  if (name == 'fileName') {
    return validateFileName(value);
  }
  return validInput(name);
}

function validateFolderName(
  value: string,
  availableFolders: PWItem[]
): InputError<RenameForm> {
  if (
    availableFolders.find(
      ({ Name }) => Name.toLowerCase() == value.toLowerCase()
    )
  ) {
    return {
      name: 'name',
      value: t('ConflictResolution.FolderExists'),
      type: 'error'
    };
  }

  if (value.length > pwConstants.maxFolderNameLength) {
    return valueTooLongError(
      'name',
      pwConstants.maxFolderNameLength,
      value.length
    );
  }
  if (!value?.trim()) {
    return emptyValueError('name');
  }
  if (containsInvalidCharacters(value) || containsInvalidEndCharacters(value)) {
    return invalidFolderCharactersError('name');
  }
  return validInput('name');
}

function validateDocumentName(value: string): InputError<RenameForm> {
  if (value.length > pwConstants.maxFileNameLength) {
    return valueTooLongError(
      'name',
      pwConstants.maxFileNameLength,
      value.length
    );
  }
  if (!value?.trim()) {
    return emptyValueError('name');
  }

  return validInput('name');
}

function validateFileName(value: string): InputError<RenameForm> {
  if (value.length > pwConstants.maxFileNameLength) {
    return valueTooLongError(
      'fileName',
      pwConstants.maxFileNameLength,
      value.length
    );
  }
  if (!value?.trim()) {
    return emptyValueError('fileName');
  }
  if (containsInvalidCharacters(value)) {
    return invalidFileCharactersError('fileName');
  }
  if (containsInvalidEndCharacters(value)) {
    return invalidFileCharactersEndError('fileName');
  }
  return validInput('fileName');
}

export function validateDescription(value: string): InputError<RenameForm> {
  if (value.length > pwConstants.maxDescriptionLength) {
    return valueTooLongError(
      'description',
      pwConstants.maxDescriptionLength,
      value.length
    );
  }
  return validInput('description');
}

export function validateVersion(
  value: string,
  editedItem: PWItem,
  itemsInFolder: PWItem[],
  initialVersion: string
): InputError<RenameForm> {
  const availableDocuments = filterFileTypes(itemsInFolder).filter(
    ({ instanceId }) => instanceId != editedItem.instanceId
  );
  const sameName = availableDocuments.filter(
    ({ Name }) => Name.toLowerCase() == editedItem.Name.toLowerCase()
  );
  if (sameName) {
    if (
      sameName.some(
        ({ Version }) => Version.toLowerCase() == value.toLowerCase()
      )
    ) {
      return duplicateVersionNameError('version');
    }
  }
  if (value.length > pwConstants.maxVersionLength) {
    return valueTooLongError(
      'version',
      pwConstants.maxVersionLength,
      value.length
    );
  }
  if (!value?.trim() && value.trim() != initialVersion) {
    return emptyValueError('version');
  }
  return validInput('version');
}

export function isFormValid({ name }: RenameForm): boolean {
  if (name) {
    return true;
  }
  return false;
}

export function containsInvalidCharacters(value: string): boolean {
  const regex = /[<>|:"?/\\*]+/i;
  return regex.test(value);
}

export function replaceInvalidFileNameCharacters(value: string): string {
  const regex = RegExp(/([/\\:*<>"|?])/, 'g');
  return value.replace(regex, '_');
}

function containsInvalidEndCharacters(value: string): boolean {
  const regex = /\.$/;
  return regex.test(value);
}

export function validInput(name: keyof RenameForm): InputError<RenameForm> {
  return { name, value: '', type: '' };
}

function valueTooLongError(
  name: keyof RenameForm,
  maxAllowed: number,
  actual: number
): InputError<RenameForm> {
  const count = maxAllowed;
  const excess = actual - maxAllowed;
  return {
    name,
    value: `${t('Generic.ContentLimitedTo', { count, excess })}`,
    type: 'error'
  };
}

function emptyValueError(name: keyof RenameForm): InputError<RenameForm> {
  return {
    name,
    value:
      name == 'version'
        ? t('Version.NameCannotBeEmpty')
        : t('Generic.NameCannotBeEmpty'),
    type: 'error'
  };
}

function invalidFolderCharactersError(
  name: keyof RenameForm
): InputError<RenameForm> {
  return {
    name,
    value: t('Upload.FolderNameContainsSpecialCharacters'),
    type: 'warning'
  };
}

function invalidFileCharactersError(
  name: keyof RenameForm
): InputError<RenameForm> {
  return {
    name,
    value: t('Rename.FileNameInvalidCharacters'),
    type: 'error'
  };
}

function invalidFileCharactersEndError(
  name: keyof RenameForm
): InputError<RenameForm> {
  return {
    name,
    value: t('Rename.FileNameEndWithDot'),
    type: 'error'
  };
}

function duplicateVersionNameError(
  name: keyof RenameForm
): InputError<RenameForm> {
  return {
    name,
    value: t('Version.NameInUse'),
    type: 'error'
  };
}
