import { useCallback, useMemo } from 'react';
import type { ConsumerApp, DataAccessLevel } from '../../context';
import { useLocalStorage } from '../../hooks/useStorage';
import type { Application } from './getShareableLink';

type SelectedApplicationProps = {
  consumerApp?: ConsumerApp;
  dataAccessLevel: DataAccessLevel;
  disabledApplications?: Application[];
  hiddenApplications?: Application[];
};

type SelectedApplication = [Application, (application: Application) => void];

export function useSelectedApplication({
  consumerApp,
  dataAccessLevel,
  disabledApplications,
  hiddenApplications
}: SelectedApplicationProps): SelectedApplication {
  const defaultApp = useMemo((): Application => {
    if (consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams') {
      return 'Teams';
    }

    if (dataAccessLevel == 'Datasource') {
      return 'WebView';
    }

    if (
      disabledApplications?.includes('Web') ||
      hiddenApplications?.includes('Web')
    ) {
      return 'Explorer';
    }

    return 'Web';
  }, [consumerApp, dataAccessLevel, disabledApplications, hiddenApplications]);

  const [lastUsedApp, setLastUsedApp] = useLocalStorage<Application>(
    'linkApplication',
    defaultApp
  );

  const selectedApp = useMemo((): Application => {
    if (
      disabledApplications?.includes(lastUsedApp) ||
      hiddenApplications?.includes(lastUsedApp)
    ) {
      return defaultApp;
    }

    return lastUsedApp;
  }, [defaultApp, disabledApplications, hiddenApplications, lastUsedApp]);

  const setSelectedApp = useCallback(
    (application: Application): void => {
      setLastUsedApp(application);
    },
    [setLastUsedApp]
  );

  return [selectedApp, setSelectedApp];
}
