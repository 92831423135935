import { useCallback } from 'react';
import { usePropertyContext, useTableColumnContext } from '../../../context';
import type { ColumnInfo } from '../../../hooks/useViews';
import { t } from '../../../services/translation';

type ColumnDragHandler = (newOrder: string[]) => void;

export function useDragColumns(): ColumnDragHandler {
  const {
    viewManager: {
      currentView,
      customViewSetting: { saveView }
    }
  } = usePropertyContext();
  const { columns: previousColumns } = useTableColumnContext();

  const onColumnDragged = useCallback(
    (newOrder: string[]): void => {
      const previousIds = previousColumns
        .map((column) => column.id as string)
        .filter((id) => id != 'FolderName');

      const newIds = newOrder.slice(1).filter((id) => id != 'FolderName');

      // Only way to know which columns are changed is to compare order of columns
      // previous order by id, and then change the ColumnInfo in the currentView
      // by the same re-ordering

      const delta = getDelta(previousIds, newIds);
      const columns = applyDelta(delta, currentView.columns);

      saveView({
        name: t('CustomColumns.CustomView'),
        columns
      });
    },
    [currentView.columns, previousColumns, saveView]
  );

  return onColumnDragged;
}

function getDelta(previousIds: string[], newIds: string[]): number[] {
  const delta = [] as number[];

  for (const id of previousIds) {
    const previousOrder = previousIds.indexOf(id);
    const newOrder = newIds.indexOf(id);
    const shift = newOrder - previousOrder;
    delta.push(shift);
  }

  return delta;
}

function applyDelta(delta: number[], columns: ColumnInfo[]): ColumnInfo[] {
  const orderedColumns = new Array<ColumnInfo>(delta.length);

  for (let i = 0; i < delta.length; i++) {
    const shift = delta[i];
    const column = columns[i];
    orderedColumns[i + shift] = column;
  }

  return orderedColumns.filter((column) => column != null);
}
