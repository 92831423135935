import { useCallback, useMemo } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { itemIsFlatSet, itemIsProject } from '@bentley/pw-api';
import {
  useConnectionContext,
  useEnvironmentContext,
  useNavigationContext
} from '../../context';

type LatestVersionItems = (items: PWItem[]) => PWItem[];

export function useLatestVersionItems(): LatestVersionItems {
  const {
    selectionState: { selectedItemId }
  } = useConnectionContext();
  const {
    versions: { displayLatestVersion }
  } = useEnvironmentContext();

  const { navigationManager } = useNavigationContext();

  const latestVersionItems = useCallback(
    (items: PWItem[]): PWItem[] => {
      return items.filter(
        (item) =>
          itemIsProject(item) ||
          (item as PWDataItem).IsLatest ||
          item.instanceId == selectedItemId
      );
    },
    [selectedItemId]
  );

  const shouldFilterLatestVersionItems = useMemo((): boolean => {
    if (itemIsFlatSet(navigationManager.currentParent)) {
      return false;
    }
    return displayLatestVersion;
  }, [displayLatestVersion, navigationManager.currentParent]);

  const filterLatestVersionItems = useCallback(
    (items: PWItem[]): PWItem[] => {
      if (shouldFilterLatestVersionItems) {
        return latestVersionItems(items);
      }
      return items;
    },
    [shouldFilterLatestVersionItems, latestVersionItems]
  );

  return filterLatestVersionItems;
}
