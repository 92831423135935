import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { LoadingPage } from '../../../../../packages/projectwise/src/components/loadingPage';
import { getAppInsights } from '../../../../../packages/projectwise/src/services/appInsights';
import { isAdmin } from '../../../../../packages/projectwise/src/services/permissions';
import { useAuthentication } from '../../hooks/useAuthentication';
import { t } from '../../services/translation';

const Context = createContext<UserContext | undefined>(undefined);

type UserContext = {
  isAdmin: boolean;
  user: User;
};

export function UserProvider({
  children
}: ProviderProps<undefined>): JSX.Element | null {
  const { user, samlUser } = useAuthentication();

  const userIsAdmin = useMemo(() => {
    if (!user) {
      return false;
    }

    return isAdmin(user);
  }, [user]);

  const userContext: UserContext = useMemo(
    (): UserContext => ({
      isAdmin: userIsAdmin,
      user: user as User
    }),
    [userIsAdmin, user]
  );

  useEffect(() => {
    const appInsights = getAppInsights();
    if (!user?.profile?.sub || !appInsights) {
      return;
    }

    appInsights.setAuthenticatedUserContext(user.profile.sub);
  }, [user?.profile?.sub]);

  if (!user || !samlUser) {
    return (
      <LoadingPage
        loadingSubText={t('SignIn.SigningIn')}
        fromValue={25}
        toValue={50}
      />
    );
  }

  return <Context.Provider value={userContext}>{children}</Context.Provider>;
}

export function useUserContext(): UserContext {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }

  return context;
}
