import type {
  HttpService,
  PWItem,
  PWProject,
  WSGChangedInstanceResponse,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  parseRelatedParent,
  parseResponseChangedInstance,
  pwConstants
} from '@bentley/pw-api';

/**
 * Takes file name and cuts off anything over max length while maintaining file extension
 * @param {string} filename Name of file to be truncated
 * @returns {string} Truncated file name
 */
export function truncateFileName(filename: string): string {
  const lastDot = filename.lastIndexOf('.');
  const namePrefix =
    lastDot > -1 ? filename.slice(0, filename.lastIndexOf('.')) : filename;
  const fileExtension = lastDot > -1 ? filename.slice(lastDot) : '';
  const truncatedName = namePrefix.slice(
    0,
    pwConstants.maxFileNameLength - fileExtension.length
  );
  return truncatedName + fileExtension;
}

/**
 * Takes folder name and cuts off anything over max length
 * @param {string} folderName Name of folder to be truncated
 * @returns {string} Truncated folder name
 */
export function truncateFolderName(folderName?: string): string {
  return (folderName ?? '').substring(0, pwConstants.maxFolderNameLength);
}

export function castProjectInfoToProject(
  name: string,
  description: string,
  instanceId?: string,
  parentGuid?: string
): PWProject {
  return {
    className: 'Project',
    TypeString: 'Folder',
    Name: name,
    Description: description,
    instanceId: instanceId,
    ParentGuid: parentGuid
  } as PWProject;
}

export async function getProjectFromChangedInstance(
  response: Response
): Promise<PWProject> {
  if (!response.ok) {
    return {} as PWProject;
  }

  const project = await parseResponseChangedInstance<PWProject>(
    response.clone()
  );
  const parent = parseRelatedParent(project);

  return castProjectInfoToProject(
    project.Name,
    '',
    project.instanceId,
    parent?.instanceId
  );
}

// Todo: this would be better in data module rather than upload
export async function getItemFromResponse(
  response: Response,
  knownParentGuid?: string
): Promise<PWItem | undefined> {
  if (!response.ok) {
    return undefined;
  }

  const changedInstance = (
    (await response.clone().json()) as WSGChangedInstanceResponse
  ).changedInstance.instanceAfterChange;

  return {
    instanceId: changedInstance.instanceId,
    className: changedInstance.className,
    Name:
      changedInstance.properties?.Name ??
      changedInstance.properties?.FileName ??
      '',
    Description: changedInstance.properties?.Description ?? '',
    ParentGuid:
      changedInstance.relationshipInstances?.[0].relatedInstance.instanceId ??
      knownParentGuid
  } as PWItem;
}

export function newFile(
  file: File | Blob,
  name: string,
  lastModified?: number
): File {
  return newFileWithProperties(file, { name, lastModified });
}

export function newFileWithProperties(
  file: File | Blob,
  properties: Record<string, unknown>
): File {
  const blob = new Blob([file], { type: file.type }) as Blob &
    Record<string, string | number> & { name: string };

  blob.name = (file as File).name;
  blob.lastModified = (file as File).lastModified;
  blob.lastModifiedDate = (
    file as File & {
      lastModifiedDate: string;
    }
  ).lastModifiedDate;

  for (const key in properties) {
    blob[key] = properties[key] as string;
  }
  return blob as Blob as File;
}

export async function checkServerConnectivity(
  httpService: HttpService
): Promise<boolean> {
  let isInternetConnected = true;
  try {
    const response = await httpService.get('', { uncached: true });
    if (response && response.status == 200) {
      const data = (await response.json()) as WSGInstancesResponse;
      if (data.instances.length > 0) {
        isInternetConnected = true;
      }
    } else {
      isInternetConnected = false;
    }
  } catch (e) {
    isInternetConnected = false;
  }
  return isInternetConnected;
}
