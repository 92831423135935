export function columnContainsUserNames(columnId: string): boolean {
  const userColumnIds = [
    'Owner',
    'OutTo',
    'CreatedBy',
    'UpdatedBy',
    'FileUpdatedBy',
    'FinalBy'
  ];
  return userColumnIds.includes(columnId);
}
