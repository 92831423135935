import React, { useCallback, useMemo } from 'react';
import { SvgBlank, SvgCheckmark } from '@itwin/itwinui-icons-react';
import {
  useAppViewContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { SavedAdvancedQuery } from '../../../advancedSearch/advancedSearchSaveModal';
import type { MenuItemAction } from '../../MenuItemAction';

export function useMySearchesActions(): MenuItemAction[] {
  const { connection } = usePluginContext();
  const {
    searchState: {
      advancedSearch,
      setAdvancedSearch,
      myConnectionSearches,
      myGlobalSearches
    }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  const { trackFeature } = useFeatureTracking();

  const onSearch = useCallback(
    (search: SavedAdvancedQuery) => {
      trackFeature('SAVED_ADVANCED_SEARCH_RUN');
      setAdvancedSearch({ ...search.query, name: search.name });
      setAppView('AdvancedSearch');
    },
    [setAdvancedSearch, setAppView, trackFeature]
  );

  const mySearch = useCallback(
    (query: SavedAdvancedQuery, index: number): MenuItemAction => ({
      title: query.name,
      icon:
        query.name == advancedSearch?.name ? (
          <SvgCheckmark data-testid="search-check" />
        ) : (
          <SvgBlank />
        ),
      groupLabel: t('MenuItem.MySearches'),
      startGroup: index == 0,
      hidden: connection.Canned || currentAppView.type == 'OutToMe',
      onClick: () => onSearch(query)
    }),
    [advancedSearch?.name, connection.Canned, currentAppView.type, onSearch]
  );

  const mySearches = useMemo(
    (): MenuItemAction[] =>
      [...(myConnectionSearches ?? []), ...(myGlobalSearches ?? [])].map(
        mySearch
      ),
    [myConnectionSearches, myGlobalSearches, mySearch]
  );

  return mySearches;
}
