import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type {
  ActionType,
  MetaBase,
  TableInstance,
  TableState
} from '@itwin/itwinui-react/react-table';
import {
  useNavigationContext,
  usePropertyContext,
  useTableColumnContext,
  useTableItemContext
} from '../../../context';
import { useDragColumns } from './useDragColumns';
import { useTableSorting } from './useTableSorting';

type TableStateFunctions = {
  controlledState: (
    state: TableState<PWItem>,
    meta: MetaBase<PWItem>
  ) => TableState<PWItem>;
  stateReducer: (
    newState: TableState,
    action: ActionType,
    previousState: TableState,
    instance?: TableInstance<PWItem>
  ) => TableState<PWItem>;
};

export function useTableState(): TableStateFunctions {
  const { setAllowDragAndDrop } = useNavigationContext();

  const {
    columnWidthManager: { updateColumnWidths }
  } = usePropertyContext();

  const {
    selectedState: { selectedRowIds, setSelectedIds }
  } = useTableItemContext();

  const {
    tableFiltering: { filters, saveFilters }
  } = useTableColumnContext();

  const onColumnDragged = useDragColumns();
  const { sortingRules: sortBy, saveSortingRules } = useTableSorting();

  const controlledState = useCallback(
    (state: TableState<PWItem>, meta: MetaBase<PWItem>): TableState<PWItem> => {
      if (!filters.length && state.filters.length) {
        meta.instance.setAllFilters([]);
      }

      return {
        ...state,
        selectedRowIds: selectedRowIds.current,
        sortBy,
        filters
      };
    },
    [filters, selectedRowIds, sortBy]
  );

  const stateReducer = useCallback(
    (
      newState: TableState<PWItem>,
      action: ActionType,
      previousState: TableState,
      instance?: TableInstance<PWItem>
    ) => {
      if (
        action.type == 'columnDoneResizing' &&
        !newState.columnResizing.isResizingColumn
      ) {
        const { columnWidths } = newState.columnResizing;
        updateColumnWidths(columnWidths);
      }

      if (action.type == 'columnDragStart') {
        setAllowDragAndDrop(false);
      }

      if (action.type == 'columnDragEnd') {
        onColumnDragged(newState.columnOrder);
        setAllowDragAndDrop(true);
      }

      if (
        action.type == 'singleRowSelected' ||
        action.type == 'toggleAllRowsSelected' ||
        action.type == 'shiftRowSelected' ||
        action.type == 'toggleRowSelected'
      ) {
        setSelectedIds(Object.keys(newState.selectedRowIds));
      }

      if (action.type == 'toggleSortBy') {
        saveSortingRules(newState.sortBy);
      }

      if (
        action.type == 'setFilter' &&
        typeof action.filterValue == 'undefined'
      ) {
        saveFilters(filters.filter(({ id }) => id != action.columnId));
      }

      if (
        action.type == 'setFilter' &&
        typeof action.filterValue != 'undefined'
      ) {
        saveFilters([
          ...filters.filter(
            (filter) => !newState.filters.find(({ id }) => id == filter.id)
          ),
          ...newState.filters
        ]);
      }

      return newState;
    },
    [
      updateColumnWidths,
      setAllowDragAndDrop,
      onColumnDragged,
      setSelectedIds,
      saveSortingRules,
      filters,
      saveFilters
    ]
  );

  const tableStateFunctions = useMemo(
    () => ({ controlledState, stateReducer }),
    [controlledState, stateReducer]
  );

  return tableStateFunctions;
}
