import React, { useCallback, useMemo } from 'react';
import type { FilterValue } from '@itwin/itwinui-react/react-table';
import { usePropertyContext, useTableColumnContext } from '../../context';
import type { ColumnInfo } from '../../hooks/useViews';
import { t } from '../../services/translation';
import { getColumnDataType } from '../pwTable/column/typedColumn';
import type { FilterTagData } from './filterToken';
import { FilterToken } from './filterToken';
import { formattedFilterToken } from './formatToken';

export function PWFilterToken(): JSX.Element | null {
  const { viewManager } = usePropertyContext();
  const {
    tableFiltering: { filters, clearFilters, removeFilter }
  } = useTableColumnContext();

  const displayFilter = useCallback(
    (filter: { id: string; value: FilterValue }): FilterTagData => {
      let column = viewManager.currentView.columns.find(
        (column) =>
          column.name == filter.id ||
          `${column.propertyType}.${column.name}` == filter.id
      );

      if (!column && filter.id == 'FolderName') {
        column = {
          dataType: 'string',
          displayLabel: t('Columns.FolderName')
        } as ColumnInfo;
      }

      if (!column) {
        return {
          id: filter.id,
          displayName: filter.id,
          value: String(filter.value)
        };
      }

      const columnDataType = getColumnDataType(column.dataType);

      return {
        id: filter.id,
        displayName: column.displayLabel,
        value: formattedFilterToken(columnDataType, filter.value)
      };
    },
    [viewManager.currentView.columns]
  );

  const displayFilters = useMemo(
    () => filters.map(displayFilter),
    [displayFilter, filters]
  );

  return (
    <FilterToken
      filters={displayFilters}
      removeFilter={removeFilter}
      clearFilters={clearFilters}
    />
  );
}
