import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import type { IRendererElement } from '@bentley/formsrenderer/lib/form-renderer/components/RendererElement/RendererElement';
import type { IFormContainer } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormContainer';
import type { INameValuePair } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IUtilities';
import type {
  IWsgInstance,
  WsgDataType
} from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';
import type { WSGInstance } from '@bentley/pw-api';
import type {
  Script,
  ScriptedRendererElement
} from '../../attributesForm/form';
import { useFormReloadWorkflow } from './formGenerate';
import { disableFormInputs, enableFormInputs } from './format';
import {
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from './state';
import { useTriggeredPropertyWorkflow } from './triggeredProperties';
import type { FormType } from './useDCWForm';

type ScriptedPropertiesFunctions = {
  saveScriptedProperties: (element: IRendererElement) => void;
  runScriptedProperties: (
    changedData: Array<INameValuePair<WsgDataType>>,
    instance: IWsgInstance<Record<string, WsgDataType>>
  ) => Promise<void>;
};

export function useScriptedProperties(
  formType: FormType,
  formContainer: IFormContainer | undefined
): ScriptedPropertiesFunctions {
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const [triggeredProperties, setTriggeredProperties] = useState<string[]>([]);
  const [reloadProperties, setReloadProperties] = useState<string[]>([]);

  const { updateInstanceWithTriggeredProperties } =
    useTriggeredPropertyWorkflow();

  const { reloadFormWorkflow } = useFormReloadWorkflow(formType);

  const saveReloadProperty = useCallback(
    (propertyName: string): void => {
      if (!reloadProperties.includes(propertyName)) {
        setReloadProperties((current) => [...current, propertyName]);
      }
    },
    [reloadProperties, setReloadProperties]
  );

  const saveTriggeredProperty = useCallback(
    (propertyName: string): void => {
      if (!triggeredProperties.includes(propertyName)) {
        setTriggeredProperties((current) => [...current, propertyName]);
      }
    },
    [setTriggeredProperties, triggeredProperties]
  );

  const saveScriptedProperty = useCallback(
    (script: Script): void => {
      const actionType = actionTypeFromScript(script);

      if (actionType == 'ReloadForm') {
        saveReloadProperty(script.Script.Name);
      }

      if (actionType == 'UpdateTriggeredProperties') {
        saveTriggeredProperty(script.Script.Name);
      }
    },
    [saveReloadProperty, saveTriggeredProperty]
  );

  const saveScriptedProperties = useCallback(
    (element: IRendererElement): void => {
      const scripts = scriptsFromElement(element);
      scripts?.forEach((script) => saveScriptedProperty(script));
    },
    [saveScriptedProperty]
  );

  const runTriggeredPropertiesScripts = useCallback(
    async (
      changedData: Array<INameValuePair<WsgDataType>>,
      instance: IWsgInstance<Record<string, WsgDataType>>
    ): Promise<IWsgInstance<Record<string, WsgDataType>>> => {
      const changedTriggeredProperties = changedData.filter((property) =>
        triggeredProperties.includes(propertyNameFromBinding(property.name))
      );

      let newInstance: IWsgInstance<Record<string, WsgDataType>> | null = null;

      for (const changedProperty of changedTriggeredProperties) {
        const propertyName = propertyNameFromBinding(changedProperty.name);

        newInstance = (await updateInstanceWithTriggeredProperties(
          propertyName,
          (newInstance ?? instance) as WSGInstance
        )) as IWsgInstance<Record<string, WsgDataType>>;
      }

      return newInstance ?? instance;
    },
    [triggeredProperties, updateInstanceWithTriggeredProperties]
  );

  const runReloadPropertiesScripts = useCallback(
    async (
      changedData: Array<INameValuePair<WsgDataType>>,
      instance: IWsgInstance<Record<string, WsgDataType>>
    ): Promise<void> => {
      const changedReloadProperties = changedData.some((property) =>
        reloadProperties.includes(propertyNameFromBinding(property.name))
      );

      if (changedReloadProperties && instance) {
        await reloadFormWorkflow(instance as WSGInstance);
      }
    },
    [reloadFormWorkflow, reloadProperties]
  );

  const runScriptedProperties = useCallback(
    async (
      changedData: Array<INameValuePair<WsgDataType>>,
      instance: IWsgInstance<Record<string, WsgDataType>>
    ) => {
      const newInstance = await runTriggeredPropertiesScripts(
        changedData,
        instance
      );

      await runReloadPropertiesScripts(changedData, newInstance);
    },
    [runReloadPropertiesScripts, runTriggeredPropertiesScripts]
  );

  useEffect(() => {
    if (updatingTriggeredProperties || updatingReloadProperties) {
      disableFormInputs();
    } else {
      enableFormInputs();
    }
  }, [formContainer, updatingReloadProperties, updatingTriggeredProperties]);

  return { saveScriptedProperties, runScriptedProperties };
}

function scriptsFromElement(element: IRendererElement): Script[] | undefined {
  const scriptEvents = (element as ScriptedRendererElement)?.scriptEvents;
  const scripts = scriptEvents?.[0]?.ScriptEvent?.Scripts;
  return scripts;
}

function actionTypeFromScript(script: Script): string | undefined {
  return script.Script.Actions?.[0]?.ScriptAction?.ActionType;
}

function propertyNameFromBinding(binding: string): string {
  const propertyName = binding.split('.')[1];
  return propertyName;
}
