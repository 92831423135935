import type { User } from 'oidc-client';
import { useCallback, useMemo } from 'react';
import { getAppInsights } from '../../../../../packages/projectwise/src/services/appInsights';

type EventTracker = {
  trackEvent: (
    eventName: string,
    user: User | null,
    samlUser: User | null
  ) => void;
};

export function useOidcEventTracker(): EventTracker {
  const appInsights = useMemo(getAppInsights, []);

  const trackEvent = useCallback(
    (eventName: string, user: User | null, samlUser: User | null) => {
      appInsights?.trackEvent({
        name: eventName,
        properties: {
          org: user?.profile.org_name as string,
          userId: user?.profile.sub,
          tokenExpired: samlUser?.expired,
          invalidToken: samlUser?.access_token.startsWith('PHN') ? false : true,
          tokenFailure: samlUser ? false : true
        }
      });
    },
    [appInsights]
  );

  const eventTracker = useMemo(
    (): EventTracker => ({ trackEvent }),
    [trackEvent]
  );

  return eventTracker;
}
