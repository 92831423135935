import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Command } from '../../../../actions/workflow';
import {
  WorkFlowModal,
  allowCommandAll,
  allowFinalAll,
  allowRemoveFinalStatusForAll,
  allowSetFinalStatusForAll,
  allowWorkFlow,
  nonDocumentCommands,
  setFinal,
  showWorkflow,
  unsetFinal
} from '../../../../actions/workflow';
import {
  useAppViewContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgWorkflow } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';

export function useWorkflowItemActions(items: PWItem[]): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const httpService = useHttpService();
  const { readOnly, connection } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder },
    wreCommands
  } = useTableItemContext();

  const loadingCommands = useMemo(
    (): boolean => wreCommands === undefined,
    [wreCommands]
  );

  const loadingPlaceHolder = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.Loading'),
      ['data-testid']: 'loading-commands',
      hidden: readOnly
    }),
    [readOnly]
  );

  const genericCommand = useCallback(
    (command: Command): MenuItemAction => ({
      title: command.Label,
      itemTooltip: command.Description,
      className: 'workflow-command-menu-item',
      onClick: () =>
        primaryModal.open(
          <WorkFlowModal checkedRows={items} command={command} />
        ),
      disabled:
        readOnly || !allowCommandAll(items, command, allItemsExistInConnection),
      hidden: readOnly
    }),
    [items, allItemsExistInConnection, primaryModal, readOnly]
  );

  const sharedCommandMenuItems = useMemo((): MenuItemAction[] => {
    if (!wreCommands) {
      return [];
    }

    const commandActions = nonDocumentCommands(wreCommands).map(genericCommand);
    return commandActions;
  }, [wreCommands, genericCommand]);

  const showFinalStatus = useMemo(() => {
    if (readOnly) {
      return false;
    }

    if (!allowSetFinalStatusForAll(items)) {
      return false;
    }

    return true;
  }, [items, readOnly]);

  const setFinalStatus = useMemo(
    (): MenuItemAction => ({
      title: t('Workflows.SetAsFinal'),
      className: 'workflow-command-menu-item',
      onClick: () =>
        void setFinal(items, httpService, trackFeature, refreshCurrentFolder),
      disabled:
        !showFinalStatus || !allowFinalAll(items, allItemsExistInConnection),
      hidden: !showFinalStatus
    }),
    [
      showFinalStatus,
      items,
      allItemsExistInConnection,
      httpService,
      trackFeature,
      refreshCurrentFolder
    ]
  );

  const removeFinalStatus = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.RemoveFinalStatus'),
      className: 'workflow-command-menu-item',
      onClick: () =>
        void unsetFinal(items, httpService, trackFeature, refreshCurrentFolder),
      hidden: readOnly || !allowRemoveFinalStatusForAll(items)
    }),
    [httpService, items, readOnly, refreshCurrentFolder, trackFeature]
  );

  const childItems = useMemo(
    (): MenuItemAction[] =>
      loadingCommands
        ? [loadingPlaceHolder]
        : formatMenuItems([
            ...sharedCommandMenuItems,
            setFinalStatus,
            removeFinalStatus
          ]),
    [
      sharedCommandMenuItems,
      loadingCommands,
      loadingPlaceHolder,
      removeFinalStatus,
      setFinalStatus
    ]
  );

  const workflowItemActions = useMemo(
    (): MenuItemAction => ({
      title: t('Generic.Workflow'),
      icon: <SvgWorkflow />,
      disabled: !allowWorkFlow(items),
      hidden:
        !childItems.length ||
        !showWorkflow(
          items,
          readOnly,
          connection.Canned ?? false,
          sharedCommandMenuItems
        ) ||
        currentAppView.type == 'RecycleBin',
      submenuItems: [...childItems]
    }),
    [
      childItems,
      items,
      readOnly,
      connection.Canned,
      sharedCommandMenuItems,
      currentAppView.type
    ]
  );

  return workflowItemActions;
}
