import type { PWDocument, PWParentType } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import type { DataAccessLevel } from '../../context';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { PdfMarkupMode, fileIsPdf, getPdfMarkupMode } from './utils';

export type OpenPdfLink = (
  connectionId: string,
  datasourceId: string,
  item: PWDocument,
  serverIsAccessible: boolean,
  dataAccessLevel?: DataAccessLevel,
  itemParent?: PWParentType
) => void;

export function openPDFMarkupUrl(
  PDFMarkupUrl: string,
  projectId: string | undefined,
  trackFeature: TrackFeature,
  connectionId: string,
  datasourceId: string,
  item: PWDocument,
  serverIsAccessible: boolean,
  dataAccessLevel?: DataAccessLevel,
  itemParent?: PWParentType
): void {
  const url: string = buildPDFMarkupLink(
    PDFMarkupUrl,
    projectId,
    connectionId,
    datasourceId,
    item,
    serverIsAccessible,
    itemParent,
    dataAccessLevel
  );

  if (!fileIsPdf(item)) {
    trackFeature('VIEW_DOCUMENT');
  } else if (!serverIsAccessible) {
    trackFeature('OPEN_PDFMARKUP_READONLY');
  } else {
    trackFeature('OPEN_PDFMARKUP_RW');
  }

  trackFeature('OPEN_VIEWER_IN_NEW_TAB');

  window.open(url, '_blank', 'noopener,noreferrer');
}

export function buildPDFMarkupLink(
  PDFMarkupUrl: string,
  projectId: string | undefined,
  connectionId: string,
  datasourceId: string,
  item: PWDocument,
  serverIsAccessible: boolean,
  itemParent?: PWParentType,
  dataAccessLevel?: DataAccessLevel
): string {
  if (!PDFMarkupUrl.endsWith('/')) {
    PDFMarkupUrl += '/';
  }

  const link =
    projectId && dataAccessLevel != 'Datasource'
      ? `${PDFMarkupUrl}${projectId}/DI/${connectionId}/${item.instanceId}`
      : `${PDFMarkupUrl}DI/${datasourceId}/${item.instanceId}`;

  const params = new URLSearchParams();

  const mode = getPdfMarkupMode(item, serverIsAccessible, dataAccessLevel);
  if (mode > PdfMarkupMode.Normal) {
    params.append('mode', mode.toString());
  }

  if (itemParent && itemIsFlatSet(itemParent)) {
    params.append('flatset', itemParent.instanceId);
  }

  const linkParams = params.toString();
  if (linkParams != '') {
    return link + '?' + linkParams;
  }

  return link;
}
