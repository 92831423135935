import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Filters } from '@itwin/itwinui-react/react-table';
import {
  useAppViewContext,
  useNavigationContext,
  usePropertyContext,
  useTableItemContext
} from '../../../context';

export type TableFiltering = {
  filters: Filters<PWItem>;
  clearFilters: () => void;
  removeFilter: (filterId: string) => void;
  saveFilters: (filters: Filters<PWItem>) => void;
};

export function useTableFiltering(): TableFiltering {
  const { viewManager } = usePropertyContext();
  const { navigationManager } = useNavigationContext();
  const { appViewManager } = useAppViewContext();

  const {
    selectedState: { setSelectedIds }
  } = useTableItemContext();

  const [filters, setFilters] = useState<Filters<PWItem>>([]);

  const clearFilters = useCallback((): void => {
    setFilters([]);
  }, []);

  const removeFilter = useCallback((filterId: string): void => {
    setFilters((filters) => filters.filter((filter) => filter.id != filterId));
  }, []);

  const saveFilters = useCallback(
    (filters: Filters<PWItem>) => {
      setSelectedIds([]);
      setFilters(filters);
    },
    [setSelectedIds]
  );

  useEffect(() => {
    clearFilters();
  }, [
    clearFilters,
    viewManager.currentView.columns,
    navigationManager.currentParent,
    appViewManager.currentAppView
  ]);

  const tableFiltering = useMemo(
    (): TableFiltering => ({
      filters,
      clearFilters,
      removeFilter,
      saveFilters
    }),
    [clearFilters, filters, removeFilter, saveFilters]
  );

  return tableFiltering;
}
