import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import type { SavedSearchDefinition } from '../../services/search';
import type { Connection } from '../../services/support';
import { getSavedSearchLink } from './getSavedSearchLink';
import type { Application } from './getShareableLink';
import { getItemType, getUniversalLink } from './getShareableLink';
import { getTeamsLink, getTeamsSavedSearchLink } from './getTeamsLink';
import { isSavedSearch } from './savedSearchConverter';
import { useLinkBaseUrl } from './useLinkBaseUrl';

type GetLinkSelectorFunctions = {
  getLink: (
    item: PWItem | SavedSearchDefinition,
    selectedApp: Application
  ) => string;
};

type GetLinkSelectorProps = {
  buddiRegionCode: string;
  consumerApp?: ConsumerApp;
  connection: Connection;
  contextId?: string;
  teamsTabLink?: string;
};

export function useGetLinkSelector({
  buddiRegionCode,
  consumerApp,
  connection,
  contextId,
  teamsTabLink
}: GetLinkSelectorProps): GetLinkSelectorFunctions {
  const linkBaseUrl = useLinkBaseUrl(buddiRegionCode);

  const getItemLink = useCallback(
    (item: PWItem, selectedApp: Application): string => {
      const itemId = item.instanceId;
      const itemType = getItemType(item.className);

      if (selectedApp == 'Teams') {
        return getTeamsLink(
          connection,
          item,
          teamsTabLink,
          consumerApp == 'ProjectWise 365' ||
            consumerApp == 'ProjectWise 365 in Teams'
        );
      }

      return getUniversalLink({
        datasource: connection.DatasourceInstanceId,
        workAreaId: connection.ProjectId,
        objectId: itemId,
        objectType: itemType,
        projectId: contextId,
        apiURL: linkBaseUrl,
        application: selectedApp,
        shortenLink: true
      });
    },
    [connection, consumerApp, contextId, linkBaseUrl, teamsTabLink]
  );

  const getShareableSavedSearchLink = useCallback(
    (savedSearch: SavedSearchDefinition, selectedApp: Application): string => {
      if (selectedApp == 'Teams') {
        return getTeamsSavedSearchLink(
          connection,
          savedSearch.instanceId,
          teamsTabLink,
          consumerApp == 'ProjectWise 365' ||
            consumerApp == 'ProjectWise 365 in Teams'
        );
      }

      return getSavedSearchLink({
        datasource: connection.DatasourceInstanceId,
        projectId: contextId,
        workAreaId: connection.ProjectId,
        savedSearchId: savedSearch.instanceId,
        apiURL: linkBaseUrl,
        application: selectedApp,
        shortenLink: true
      });
    },
    [connection, consumerApp, contextId, teamsTabLink, linkBaseUrl]
  );

  const getLink = useCallback(
    (
      item: PWItem | SavedSearchDefinition,
      selectedApp: Application
    ): string => {
      if (isSavedSearch(item)) {
        return getShareableSavedSearchLink(item, selectedApp);
      } else {
        return getItemLink(item, selectedApp);
      }
    },
    [getItemLink, getShareableSavedSearchLink]
  );

  const getLinkSelectorFunctions = useMemo(
    (): GetLinkSelectorFunctions => ({ getLink }),
    [getLink]
  );

  return getLinkSelectorFunctions;
}
