import React, { useMemo } from 'react';
import type { PWDataItem, PWFileType, PWItem } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { showOpenIn365 } from '../../../../actions/useGraphApi';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useGraphApiContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { useOpenCoAuthoring } from '../../../../hooks/useOpenCoAuthoring';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useO365CoAuthoringAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { readOnly } = usePluginContext();
  const { checkCoAuthoringSessionExists } = useGraphApiContext();
  const {
    workAreaManager: { itemExistsInConnection }
  } = useAppViewContext();

  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();
  const { office365CoAuthoringSettings } = useEnvironmentContext();

  const item = items[0] as PWDataItem;

  const openCoAuthoring = useOpenCoAuthoring();

  const office365CoAuthoring = useMemo((): MenuItemAction => {
    const sessionExists = checkCoAuthoringSessionExists(item);

    const title = launchDarklyFeatures.showCoauthoringUpdates
      ? t('MenuItem.OpenInOffice365')
      : sessionExists
      ? t('MenuItem.JoinO365')
      : t('MenuItem.StartO365');

    return {
      title: title,
      icon: (
        <ItemIcon
          item={{ className: 'Document' } as PWFileType}
          iconProps={{ style: { fill: 'red' } }}
          badge="user"
          badgeProps={{ style: { fill: 'var(--iui-color-icon)' } }}
          size="xs"
        />
      ),
      onClick: () => openCoAuthoring(item as PWFileType, refreshCurrentFolder),
      hidden: !showOpenIn365(
        items,
        readOnly,
        sessionExists,
        itemExistsInConnection,
        office365CoAuthoringSettings
      )
    };
  }, [
    checkCoAuthoringSessionExists,
    item,
    launchDarklyFeatures.showCoauthoringUpdates,
    items,
    readOnly,
    itemExistsInConnection,
    office365CoAuthoringSettings,
    openCoAuthoring,
    refreshCurrentFolder
  ]);

  return office365CoAuthoring;
}
