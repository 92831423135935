import React, { useCallback, useMemo } from 'react';
import { SvgInfo } from '@itwin/itwinui-icons-react';
import {
  useFeatureTracking,
  useNavigationContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useInfoPanelOption(): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const { infoPanelDisplayed, setInfoPanelDisplayed } = useNavigationContext();
  const { tableLoading } = useTableItemContext();

  const toggleInfoPanel = useCallback(() => {
    if (!infoPanelDisplayed) {
      trackFeature('GRID_INFO_PANEL');
    }
    setInfoPanelDisplayed((cur) => !cur);
  }, [infoPanelDisplayed, setInfoPanelDisplayed, trackFeature]);

  const infoPanelOption = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.InfoPanel'),
      icon: <SvgInfo />,
      isActive: infoPanelDisplayed,
      disabled: tableLoading,
      onClick: toggleInfoPanel
    }),
    [infoPanelDisplayed, tableLoading, toggleInfoPanel]
  );

  return infoPanelOption;
}
