import { useMemo } from 'react';
import { useAppViewContext, useTableItemContext } from '../../context';

export function useToolbarsDisabled(): boolean {
  const { workAreaManager } = useAppViewContext();
  const {
    dataManager: { isLoading, error }
  } = useTableItemContext();

  const toolbarDisabled = useMemo((): boolean => {
    if (isLoading) {
      return true;
    }
    if (error) {
      return true;
    }
    if (!workAreaManager.workAreaAvailable) {
      return true;
    }
    return false;
  }, [error, isLoading, workAreaManager.workAreaAvailable]);

  return toolbarDisabled;
}
