import React from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { getFlatSet, itemIsFlatSet, itemIsProject } from '@bentley/pw-api';
import type { DocumentLink } from '@bentley/pwnxt-document-picker';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import { t } from '../../../services/translation';
import { DocumentPickerModalWrapper } from '../useFlatSetWizard/documentPickerModalWrapper';
import { pwParentTypeToDocumentPickerParentDataType } from '../utils';
import { addFlatSetMembers, pwItemsToDocumentLink } from './addFlatSetMember';

interface IAddFlatSetMemberFromOutsideProps {
  addedItems: PWItem[];
}

export function AddMemberFromOutsideTheFlatSetModal({
  addedItems
}: IAddFlatSetMemberFromOutsideProps): JSX.Element {
  const { primaryModal, navigationManager } = useNavigationContext();
  const httpService = useHttpService();
  const { dataManager } = useTableItemContext();
  const { trackFeature } = useFeatureTracking();
  const onComplete = (items: PWItem[]): void => {
    dataManager.refreshCurrentFolder();
  };
  const onClose = () => {
    primaryModal.close();
  };

  const onItemsSelected = async (items: DocumentLink[]) => {
    primaryModal.close();
    const selectedMembersToDocumentLinks = addedItems.map((item) =>
      pwItemsToDocumentLink(item as PWDataItem)
    );
    const flatSet = await getFlatSet({ instanceId: items[0].id, httpService });
    await addFlatSetMembers(
      flatSet,
      selectedMembersToDocumentLinks,
      httpService,
      onComplete,
      primaryModal.open,
      primaryModal.close
    );

    trackFeature('ADD_SET_ITEM_ACTION_MENU');
  };

  const customFilter = (item: PWDataItem) => {
    return itemIsFlatSet(item) && !item.IsFinal;
  };

  const isNavigationAllowed = (item: PWDataItem) => {
    return itemIsProject(item as PWItem);
  };

  return (
    <DocumentPickerModalWrapper
      title={t('FlatSet.SelectFlatSet')}
      onClose={onClose}
      onItemsSelected={(items) => void onItemsSelected(items)}
      maximumSelectedItems={1}
      customFilter={(item) => customFilter(item as PWDataItem)}
      isNavigationAllowed={(item) => isNavigationAllowed(item as PWDataItem)}
      currentFolder={pwParentTypeToDocumentPickerParentDataType(
        navigationManager.currentParent
      )}
      hideConnectionListView={true}
      isSelectable={(item) => customFilter(item as PWDataItem)}
    />
  );
}
