import React, { useEffect, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { Button } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useFeatureTracking, usePluginContext } from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import { useGetLinkSelector } from './getLinkSelector';
import type { Application } from './getShareableLink';
import { GetShareableLinkUI } from './getShareableLinkUI';
import { isSavedSearch } from './savedSearchConverter';
import { useLinkBaseUrl } from './useLinkBaseUrl';

type GetShareableLinkModalProps = {
  items: (PWItem | SavedSearchDefinition)[];
  onClose: () => void;
};

export function GetShareableLinkModal({
  items,
  onClose
}: GetShareableLinkModalProps): ModalElement {
  const { launchDarklyFeatures, trackFeature } = useFeatureTracking();
  const {
    buddiRegionCode,
    dataAccessLevel,
    connection,
    consumerApp,
    contextId,
    teamsTabLink
  } = usePluginContext();

  const { getLink } = useGetLinkSelector({
    buddiRegionCode,
    consumerApp,
    connection,
    contextId,
    teamsTabLink
  });
  const linkBaseUrl = useLinkBaseUrl(buddiRegionCode);

  const isSavedSearchLink = isSavedSearch(items[0]);

  function modalTitle(): string {
    if (isSavedSearchLink) {
      return t('SharedLink.GetLinkSavedSearch');
    }
    if (items.length == 1) {
      return t('SharedLink.GetLink');
    }
    return t('SharedLink.GetLinks');
  }

  const hiddenApplications = useMemo((): Application[] => {
    const hiddenApplications = [] as Application[];

    if (connection.Canned) {
      hiddenApplications.push('WebView');
    } else if (
      !launchDarklyFeatures.showPWWebViewSavedSearchLink &&
      isSavedSearchLink
    ) {
      hiddenApplications.push('WebView');
    }

    if (!launchDarklyFeatures.showCustomHtmlLinks) {
      hiddenApplications.push('Ask');
    }

    return hiddenApplications;
  }, [connection.Canned, isSavedSearchLink, launchDarklyFeatures]);

  const disabledApplications = useMemo((): Application[] => {
    const disabledApplications = [] as Application[];

    if (connection.Canned) {
      disabledApplications.push('Explorer');
    }

    if (
      !connection.DefaultTab &&
      consumerApp != 'Teams' &&
      consumerApp != 'ProjectWise 365 in Teams'
    ) {
      disabledApplications.push('Teams');
    }

    if (isSavedSearchLink) {
      disabledApplications.push('Explorer');
    }

    return disabledApplications;
  }, [
    connection.Canned,
    connection.DefaultTab,
    consumerApp,
    isSavedSearchLink
  ]);

  const isLoading = useMemo(() => {
    if (!linkBaseUrl) {
      return true;
    }

    return false;
  }, [linkBaseUrl]);

  useEffect(() => {
    trackFeature('LINK_GENERATE');
  }, [trackFeature]);

  return (
    <PWModal
      className="g-s-l-modal pw-modal-md"
      title={modalTitle()}
      isLoading={isLoading}
      onClose={onClose}
      dialogProps={{ 'data-testid': 'GetShareableLinkModal' }}
    >
      <GetShareableLinkUI
        CloseButton={<Button onClick={onClose}>{t('Generic.Close')}</Button>}
        consumerApp={consumerApp}
        dataAccessLevel={dataAccessLevel}
        disabledApplications={disabledApplications}
        hiddenApplications={hiddenApplications}
        items={items}
        getLink={getLink}
      />
    </PWModal>
  );
}
