import './mapSavedSearchToIModelModal.css';

import React, { useEffect, useMemo, useState } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import { SvgInfoHollow } from '@itwin/itwinui-icons-color-react';
import { SvgFind } from '@itwin/itwinui-icons-react';
import type { SelectOption } from '@itwin/itwinui-react';
import { LabeledSelect, Radio, StatusMessage } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { PWTooltip } from '../../components/pwTooltip';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { sanitizeDataSourceId } from '../../services/stringFormatter';
import { t } from '../../services/translation';
import type { IModel } from './iModel';
import type { IModelBridge, IModelMapping } from './iModelBridge';
import { notifyMappingCompleted } from './notifications';
import { isMappableFile } from './requirements';
import {
  bridgeExists,
  getDatasourceLocation,
  getSpatialRootDocument,
  loadExistingSavedSearchMapData
} from './utils';

type MapSavedSearchToIModelModalProps = {
  onComplete?: () => void;
};

export function MapSavedSearchToIModelModal({
  onComplete
}: MapSavedSearchToIModelModalProps): ModalElement {
  const { trackFeature } = useFeatureTracking();
  const { connection, iModelMappingManager: iModelBridgeApi } =
    usePluginContext();
  const { iModels } = useEnvironmentContext();
  const { searchState, primaryModal } = useNavigationContext();
  const {
    dataManager: { items: dataManagerItems }
  } = useTableItemContext();

  const foundItems = useMemo(
    (): PWDataItem[] =>
      filterDataItems(dataManagerItems).filter(isMappableFile),
    [dataManagerItems]
  );

  const [availableIModels] = useState<IModel[]>(
    iModels.sort((a, b) => {
      return (a.properties?.Name ?? '') > (b.properties?.Name ?? '') ? 1 : -1;
    })
  );
  const startingMappings: IModelMapping[] = foundItems.map((item) => {
    return {
      document: item,
      iModel: undefined,
      isSheet: false,
      isMaster: false,
      isSpatialRoot: false
    };
  });

  const [selectedIModel, setSelectedIModel] = useState<IModel>();
  const [selectedSpatialRoot, setSelectedSpatialRoot] = useState<PWDataItem>(
    foundItems[0]
  );
  const [spatialRootName, setSpatialRootName] = useState<string>(
    t('IModel.NotDefined')
  );
  const [iModelMappings, setIModelMappings] =
    useState<IModelMapping[]>(startingMappings);
  const [showSpatialDropDown, setShowSpatialDropDown] =
    useState<boolean>(false);

  const [iModelChosen, setIModelChosen] = useState<boolean>(false);
  const [spatialRootChosen, setSpatialRootChosen] = useState<boolean>(false);
  const [isMasterModel, setIsMasterModel] = useState<boolean>(false);
  const [isSheet, setIsSheet] = useState<boolean>(false);
  const [isPropsDisabled, setIsPropsDisabled] = useState<boolean>(false);
  const [isMasterChecked, setIsMasterChecked] = useState<boolean>(false);
  const [isSheetChecked, setIsSheetChecked] = useState<boolean>(false);
  const [selectedSpatialRootName, setSelectedSpatialRootName] =
    useState<string>('');

  useEffect(() => {
    if (isPropsDisabled) {
      setIsMasterModel(false);
      setIsSheet(false);
    }
  }, [isPropsDisabled]);

  const pwHttpService = useHttpService();

  function mapButtonDisabled(): boolean | undefined {
    const isSheetOrMasterModelChecked = isSheetChecked || isMasterChecked;
    if (
      showSpatialDropDown &&
      iModelChosen &&
      spatialRootChosen &&
      isSheetOrMasterModelChecked
    ) {
      return false;
    }

    if (!showSpatialDropDown && iModelChosen && isSheetOrMasterModelChecked) {
      return false;
    }

    return true;
  }

  function radioButtonDisabled(): boolean | undefined {
    if (!iModelChosen) {
      return true;
    }
    return isPropsDisabled || bridgeExists(iModelMappings);
  }

  async function performMapping() {
    primaryModal.close();
    const datasourceLocation = await getDatasourceLocation(pwHttpService);

    const responses: boolean[] = [];

    if (showSpatialDropDown && selectedSpatialRoot) {
      const spatialRoot = iModelMappings.find(
        (i) => i.document.instanceId == selectedSpatialRoot.instanceId
      );
      if (spatialRoot && !spatialRoot.isExistingBridge) {
        const response = await iModelBridgeApi.createIModelBridge(
          {
            ...spatialRoot,
            iModel: selectedIModel,
            isSpatialRoot: true
          },
          datasourceLocation,
          trackFeature
        );
        responses.push(response);
      }
    }

    const mapping = {
      document: {
        instanceId: searchState.savedSearch?.instanceId
      },
      iModel: selectedIModel ? selectedIModel : undefined,
      isSheet: isSheet,
      isMaster: isMasterModel,
      isSpatialRoot: false,
      isExistingBridge: false
    } as IModelMapping;

    const response = await iModelBridgeApi.createIModelBridge(
      mapping,
      datasourceLocation,
      trackFeature,
      connection?.ProjectId
    );

    responses.push(response);
    notifyMappingCompleted(
      responses.filter((r) => r).length,
      String(selectedIModel?.properties?.Name),
      true,
      searchState.savedSearch?.Name
    );
    if (onComplete) {
      onComplete();
    }
  }

  async function iModelSelected(iModelName: string) {
    const iModels = availableIModels.filter((item) => {
      return item.properties?.Name == iModelName;
    });
    setIModelChosen(iModels.length > 0);
    if (iModels.length > 0) {
      const iModel = iModels[0];
      const existingBridges: IModelBridge[] =
        await iModelBridgeApi.getIModelBridgesForIModel(iModel.instanceId);
      setSelectedIModel(iModel);
      const spatialRootForIModel = existingBridges.find(
        (b) => b.properties?.IsSpatialRoot === 'true'
      );
      if (spatialRootForIModel) {
        const spatialRootDocument = await getSpatialRootDocument(
          pwHttpService,
          String(spatialRootForIModel.properties?.DocumentGuid ?? '')
        );
        if (spatialRootDocument) {
          setSpatialRootName(getNameWithVersion(spatialRootDocument));
        } else {
          const spatialRootInCurrentConnection =
            sanitizeDataSourceId(connection.DatasourceInstanceId) ==
            spatialRootForIModel.properties?.DataSourceIdentifier;

          if (spatialRootInCurrentConnection) {
            setSpatialRootName(
              `${t('IModel.LocatedIn', {
                connectionName: connection.Name
              })}`
            );
          } else {
            setSpatialRootName(t('IModel.LocatedInDifferentConnection'));
          }
        }
      } else {
        setSpatialRootName(t('IModel.NotDefined'));
      }

      const spatialRootExists = spatialRootForIModel !== undefined;
      setSpatialRootChosen(spatialRootExists);
      setShowSpatialDropDown(!spatialRootExists);
      setIsPropsDisabled(!spatialRootExists);
      // reset chosen mappings
      const newMappings = loadExistingSavedSearchMapData(
        existingBridges,
        searchState.savedSearch?.instanceId ?? '',
        connection?.ProjectId ?? ''
      );
      setIModelMappings(newMappings);

      if (showSpatialDropDown) {
        spatialRootSelected(selectedSpatialRootName);
      }
    } else {
      setShowSpatialDropDown(false);
      setIsPropsDisabled(true);
      setSpatialRootChosen(false);
      setIModelMappings(startingMappings);
    }
  }

  function spatialRootSelected(selectedName: string) {
    setSelectedSpatialRootName(selectedName);

    if (selectedName == '') {
      setSpatialRootChosen(false);
      return;
    }

    const spatialRootItems = foundItems.filter((item) => {
      return (
        item.Name == selectedName || getNameWithVersion(item) == selectedName
      );
    });
    if (spatialRootItems.length > 0) {
      setSelectedSpatialRoot(spatialRootItems[0]);
      const spatialRootMapping: IModelMapping = {
        document: spatialRootItems[0],
        isMaster: false,
        isSheet: false,
        isSpatialRoot: true
      };
      setIModelMappings([...iModelMappings, spatialRootMapping]);
      setSpatialRootChosen(true);
      setIsPropsDisabled(false);
    }
  }

  const mappingWarning = t('IModel.MappingCannotBeCreated');

  const iModelDropDownData: SelectOption<string>[] = [
    { label: '', value: '' },
    ...availableIModels.map((i) => {
      return {
        label: String(i.properties?.Name ?? ''),
        value: String(i.properties?.Name ?? '')
      };
    })
  ];
  const spatialRootDropDownData: SelectOption<string>[] = [
    { label: '', value: '' },
    ...foundItems.map((i) => {
      const name = getNameWithVersion(i);
      return { label: name, value: name };
    })
  ];

  function showSpatialRootMessage(): boolean {
    return iModelChosen && showSpatialDropDown && foundItems.length == 0;
  }

  function showSpatialRootTooltip(): boolean {
    return iModelChosen && showSpatialDropDown && !spatialRootChosen;
  }

  function getNameWithVersion(item: PWDataItem): string {
    if (item.Version) {
      return `${item.Name}, Version: ${item.Version}`;
    }

    return item.Name;
  }

  return (
    <PWModal
      title={t('IModel.MapSavedSearchToIModel')}
      className="iModelMapping"
      primaryButton={{
        title: t('IModel.Map'),
        onClick: () => void performMapping(),
        disabled: mapButtonDisabled() || bridgeExists(iModelMappings),
        'data-testid': 'map-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'MapIModelModal' }}
    >
      <div className="iModelDropdowns">
        <LabeledSelect
          id="iModelSelect"
          label={t('IModel.MapTo')}
          options={iModelDropDownData}
          onChange={(e: string) => void iModelSelected(e)}
          data-testid="i-model-select"
          value={String(selectedIModel?.properties?.Name)}
          wrapperProps={{ className: 'dropDown' }}
        />
        {showSpatialDropDown ? (
          <LabeledSelect
            id="spatialRootSelect"
            label={t('IModel.SpatialRoot')}
            options={spatialRootDropDownData}
            onChange={(e) => spatialRootSelected(e)}
            value={spatialRootChosen ? selectedSpatialRoot?.Name : ''}
            data-testid="spatial-root-select"
            wrapperProps={{ className: 'dropDown' }}
          />
        ) : null}
      </div>
      <div className="spatialRootRow">
        <div className="spatialRootLabel">{t('IModel.SpatialRoot')}:</div>
        <div className="spatialRootName">{spatialRootName}</div>
        {showSpatialRootTooltip() ? (
          <PWTooltip placement={'top'} content={mappingWarning}>
            <span>
              <SvgInfoHollow
                style={{
                  height: 'var(--iui-size-m)',
                  width: 'var(--iui-size-m)'
                }}
              />
            </span>
          </PWTooltip>
        ) : null}
      </div>
      {bridgeExists(iModelMappings) && (
        <StatusMessage status="warning">
          {t('IModel.MappingAlreadyExists')}
        </StatusMessage>
      )}
      {showSpatialRootMessage() && (
        <div className="spatial-root-warning">
          {t('IModel.NoSpatialRootAvailable')}
        </div>
      )}
      <div className="ss-container">
        <div className="ss-name">
          <SvgFind />
          <span className="ss-info-col">
            <span className="ss-title">{searchState.savedSearch?.Name}</span>
          </span>
        </div>
        <div className="ss-radio-group">
          <Radio
            onChange={(e) => {
              setIsSheet(false);
              setIsMasterModel(e.target.value == 'on');
              setIsSheetChecked(false);
              setIsMasterChecked(e.target.value == 'on');
            }}
            label={t('IModel.MasterModel')}
            name="props"
            disabled={radioButtonDisabled()}
          />
          <Radio
            onChange={(e) => {
              setIsMasterModel(false);
              setIsSheet(e.target.value == 'on');
              setIsMasterChecked(false);
              setIsSheetChecked(e.target.value == 'on');
            }}
            label={t('IModel.Sheet')}
            name="props"
            disabled={radioButtonDisabled()}
          />
        </div>
      </div>
    </PWModal>
  );
}
