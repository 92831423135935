import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyStateChangeInProgress(
  command: string,
  number: number
): ToastHandle {
  const content =
    number == 1
      ? t('Workflow.State', { command })
      : t('Workflow.StateMulti', { command, count: number });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyOnSetAsFinal(numberOfItems: number): ToastHandle {
  const content =
    numberOfItems == 1
      ? t('Workflows.SettingAsFinal')
      : t('Workflows.SettingAsFinalMulti', {
          count: numberOfItems
        });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyErrorDuringFinalStatus(
  toastHandle: ToastHandle,
  errorMsg: string
): void {
  const content = t('Workflows.ErrorSettingFinal', { errorMsg });
  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyFinalStatusSuccess(toastHandle: ToastHandle): void {
  replaceToast(toastHandle, {
    content: t('Workflows.FinalStatusSet'),
    category: 'positive'
  });
}

export function notifyOnChangeFinalStatus(numberOfItems: number): ToastHandle {
  const content =
    numberOfItems == 1
      ? t('Workflows.RemovingFinalStatus')
      : t('Workflows.RemovingFinalStatusMulti', {
          count: numberOfItems
        });

  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyErrorDuringUnsetFinalStatus(
  toastHandle: ToastHandle,
  errorMsg: string
): void {
  const content = t('Workflows.ErrorRemovingFinal', { errorMsg });

  replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifyOnUnsetFinalStatus(toastHandle: ToastHandle): void {
  replaceToast(toastHandle, {
    content: t('Workflows.FinalStatusRemoved'),
    category: 'positive'
  });
}
