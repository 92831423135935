import type { PWDataItem, PWItem } from '@bentley/pw-api';
import {
  itemFileExtension,
  itemIsFileType,
  itemIsLatestVersion
} from '@bentley/pw-api';
import { t } from '../../services/translation';

export function showDesignReviewMenu(items: PWItem[]): boolean {
  if (!items.every(itemIsFileType)) {
    return false;
  }

  if (items.some((item) => itemFileExtension(item) != 'pdf')) {
    return false;
  }

  return true;
}

export function allowDesignReview(items: PWDataItem[]): boolean {
  if (!items.length) {
    return false;
  }

  if (items.some((item) => !itemIsLatestVersion(item))) {
    return false;
  }

  return true;
}

export function designReviewTitle(items: PWDataItem[]): string {
  if (items.length == 1) {
    return t('DocumentReview.CreateDocumentReview');
  }

  return t('DocumentReview.DocumentReview');
}
