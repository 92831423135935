import React from 'react';
import { SvgSearch } from '@itwin/itwinui-icons-react';
import { Button, Flex, Text } from '@itwin/itwinui-react';
import { useAppViewContext, useNavigationContext } from '../../context';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';

export function EmptySearchState(): JSX.Element {
  const { searchState } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  function errorMessage(): string {
    if (currentAppView.type != 'BasicSearch') {
      return t('Grid.NoSearchResults');
    }

    if (searchState.ftr && /[*_']/.exec(searchState.basicQuery)) {
      return t('Grid.InvalidValueInSearch');
    }

    return t('Grid.NoResults', { searchString: searchState.basicQuery });
  }

  function onCloseEmptyState(): void {
    setAppView('FolderNavigation');
  }

  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgSearch className="ets-icon" />
      <Text>{errorMessage()}</Text>
      <Button
        styleType="high-visibility"
        onClick={onCloseEmptyState}
        onKeyPress={keyPressAsClick(onCloseEmptyState)}
      >
        {t('Grid.CloseSearch')}
      </Button>
    </Flex>
  );
}
