import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { Column } from "@itwin/itwinui-react/react-table";
import type { PWItem } from '@bentley/pw-api';
import { useTableColumns } from '../../../components/pwTable/column/useTableColumns';
import type { TableFiltering } from '../../../components/pwTable/state/useTableFiltering';
import { useTableFiltering } from '../../../components/pwTable/state/useTableFiltering';

type TableColumnContext = {
  columns: Column<PWItem>[];
  tableFiltering: TableFiltering;
};

const Context = createContext<TableColumnContext | undefined>(undefined);

export function TableColumnProvider({
  children
}: ProviderProps<TableColumnContext | undefined>): JSX.Element {
  const columns = useTableColumns();
  const tableFiltering = useTableFiltering();

  const tableColumnContext = useMemo(
    (): TableColumnContext => ({
      columns,
      tableFiltering
    }),
    [columns, tableFiltering]
  );

  return (
    <Context.Provider value={tableColumnContext}>{children}</Context.Provider>
  );
}

export function useTableColumnContext(): TableColumnContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useTableColumnContext must be used within a TableColumnProvider'
    );
  }
  return context;
}