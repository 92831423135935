import React from 'react';
import { Button, Tag, TagContainer } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

type FilterTokenProps = {
  filters: FilterTagData[];
  removeFilter: (filterId: string) => void;
  clearFilters: () => void;
};

export type FilterTagData = {
  id: string;
  displayName: string;
  value: string;
};

export function FilterToken({
  filters,
  clearFilters,
  removeFilter
}: FilterTokenProps): JSX.Element | null {
  if (!filters.length) {
    return null;
  }

  return (
    <TagContainer
      overflow="scroll"
      style={{
        maxWidth: '100%',
        minHeight: '44px',
        overflowY: 'hidden',
        overflowX: 'auto'
      }}
      data-testid="filter-tags"
    >
      {filters.map((filter) => (
        <Tag
          onRemove={() => removeFilter(filter.id)}
          key={filter.displayName}
          data-testid={`filter-tag-${filter.displayName}`}
        >
          {filter.displayName}: {filter.value}
        </Tag>
      ))}
      <Button
        styleType="borderless"
        data-testid="clearAllLink"
        onClick={clearFilters}
      >
        {t('Search.Clear')}
      </Button>
    </TagContainer>
  );
}
