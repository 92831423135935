import type { UploadNode } from './tree';

type Uploadable = File | FileSystemDirectoryEntry;

/**
 * Builds recursive UploadNode with all items dropped
 */
export async function onDragAndDrop(event: DragEvent): Promise<UploadNode> {
  const dataTransferItemList = event.dataTransfer?.items;

  if (!dataTransferItemList) {
    return { files: [], directories: [] };
  } else {
    const uploadNode = getStartingUploadNode(dataTransferItemList);
    const uploadNodeChildren = await getUploadNodeChildren(uploadNode);
    return uploadNodeChildren;
  }
}

/**
 * Builds recursive UploadNode with all items selected in file selection dialog
 */
export function onFilesSelected(event: Event): UploadNode | undefined {
  const filelist = (event.target as HTMLInputElement).files;
  if (!filelist || !filelist.length) {
    return;
  }

  const uploadNode = { files: [], directories: [] } as UploadNode;
  for (let i = 0; i < filelist.length; i++) {
    const file = filelist[i] as File & { webkitRelativePath?: string };
    const relativePath = file.webkitRelativePath;
    if (relativePath) {
      // Used when input element has attribute "webkitdirectory" to upload folder and all children
      // (Upload folder action - not currently in use as it does not see empty folders)
      const path = relativePath.split('/');
      path.splice(-1);
      appendFileToUploadNode(file, path, uploadNode);
    } else {
      uploadNode.files.push(filelist[i]);
    }
  }
  return uploadNode;
}

function getStartingUploadNode(
  dataTransferItemList: DataTransferItemList
): UploadNode {
  const uploadNode = { directories: [], files: [] } as UploadNode;
  for (let i = 0; i < dataTransferItemList.length; i++) {
    const dataTransferItem = dataTransferItemList[i];
    const fileSystemEntry = dataTransferItem.webkitGetAsEntry();
    if (fileSystemEntry === null) {
      continue;
    }

    if (fileSystemEntry.isFile) {
      const file = dataTransferItem.getAsFile();
      if (file) {
        uploadNode.files.push(file);
      }
    } else {
      const directory = { current: fileSystemEntry } as UploadNode;
      uploadNode.directories.push(directory);
    }
  }
  return uploadNode;
}

async function getUploadNodeChildren(
  uploadNode: UploadNode
): Promise<UploadNode> {
  if (uploadNode.current) {
    const children = await getChildren(uploadNode.current);
    uploadNode.files = getFiles(children);
    uploadNode.directories = await Promise.all(
      getDirectories(children).map(getUploadNodeChildren)
    );
  } else {
    // Root level of upload structure
    await Promise.all(uploadNode.directories.map(getUploadNodeChildren));
  }
  return uploadNode;
}

async function getChildren(
  directory: FileSystemDirectoryEntry
): Promise<Uploadable[]> {
  const directoryReader = directory.createReader();
  const entries = await readAllEntries(directoryReader);
  const uploadables = await Promise.all(entries.map(getFileOrDirectory));
  return uploadables;
}

async function readAllEntries(
  directoryReader: FileSystemDirectoryReader
): Promise<FileSystemEntry[]> {
  const entries = [];
  let lastBatch = [];
  do {
    lastBatch = await readEntries(directoryReader);
    entries.push(...lastBatch);
  } while (lastBatch.length);
  return entries;
}

async function readEntries(
  directoryReader: FileSystemDirectoryReader
): Promise<FileSystemEntry[]> {
  return new Promise<FileSystemEntry[]>((resolve, reject) => {
    directoryReader.readEntries(
      (entries) => resolve(entries),
      (error) => reject(error)
    );
  });
}

// FileSystemFileEntry.file() uses an async callback but not with promises
// This makes a consistent way to await a File or a FileSystemDirectory
async function getFileOrDirectory(
  fileSystemEntry: FileSystemEntry
): Promise<Uploadable> {
  return new Promise<Uploadable>((resolve, reject) => {
    try {
      if (fileSystemEntry.isFile) {
        const fileSystemFileEntry = fileSystemEntry as FileSystemFileEntry;
        fileSystemFileEntry.file(
          (file) => {
            resolve(file);
          },
          (error) => reject(error)
        );
      } else {
        const fileSystemDirectoryEntry =
          fileSystemEntry as FileSystemDirectoryEntry;
        resolve(fileSystemDirectoryEntry);
      }
    } catch (error) {
      reject(error);
    }
  });
}

function getFiles(items: Uploadable[]): File[] {
  return items.filter((item) => !isDirectory(item)) as File[];
}

function getDirectories(items: Uploadable[]): UploadNode[] {
  return items
    .filter(isDirectory)
    .map((item) => ({ current: item } as UploadNode));
}

function isDirectory(item: Uploadable): item is FileSystemDirectoryEntry {
  return (item as FileSystemDirectoryEntry).isDirectory;
}

// Recursively examine a file's path to ensure all needed directories are added to tree
function appendFileToUploadNode(
  file: File,
  path: string[],
  uploadNode: UploadNode
): void {
  if (!path.length) {
    // This is the end of the file path where the file belongs
    uploadNode.files.push(file);
    return;
  }

  const directory = path[0];

  const existingNode = uploadNode.directories.find(
    (d) => d.current?.name == directory
  );
  if (existingNode) {
    // Directory has already been created by a previous file
    return appendFileToUploadNode(file, path.splice(1), existingNode);
  } else {
    // Need to create directory structure to file
    const directoryEntry = createDirectoryEntry(directory);
    const newNode = {
      current: directoryEntry,
      directories: [],
      files: []
    } as UploadNode;
    uploadNode.directories.push(newNode);
    return appendFileToUploadNode(file, path.splice(1), newNode);
  }
}

function createDirectoryEntry(directoryName: string): FileSystemDirectoryEntry {
  return {
    name: directoryName,
    isDirectory: true
  } as FileSystemDirectoryEntry;
}
