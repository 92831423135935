import type { HttpService, PWDataItem, PWParentType } from '@bentley/pw-api';
import { getDataItems } from '@bentley/pw-api';
import { getAuditTrail, getAuditTrails } from '../../services/data';
import { findAssociatedAuditTrail } from '../../services/data/auditTrail/auditTrail';
import { isOlderVersion } from './olderVersionCheck';

export async function getAssociatedVersions(
  item: PWDataItem,
  parent: PWParentType,
  httpService: HttpService,
  abortController: AbortController = new AbortController()
): Promise<PWDataItem[]> {
  const itemAuditTrail = await getAuditTrail(item, httpService, {
    abortController,
    uncached: true
  });

  const items = await getDataItems({
    parentId: parent.instanceId,
    httpService,
    requestOptions: { abortController, uncached: true }
  });

  const auditTrails = await getAuditTrails(items, httpService, {
    abortController,
    uncached: true
  });

  const olderVersions = items.filter((sibling) => {
    const siblingAuditTrail = findAssociatedAuditTrail(sibling, auditTrails);
    return isOlderVersion(sibling, item, siblingAuditTrail, itemAuditTrail);
  });

  return olderVersions;
}
