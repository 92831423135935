import React, { useMemo } from 'react';
import type { PWFlatSet, PWItem } from '@bentley/pw-api';
import { filterDataItems, itemIsFlatSet } from '@bentley/pw-api';
import {
  SvgAdd,
  SvgDocumentLock,
  SvgProject,
  SvgRemove
} from '@itwin/itwinui-icons-react';
import {
  AddMemberFromOutsideTheFlatSetModal,
  RemoveFlatSetMemberModal,
  allowLockAllToVersion,
  allowUnlockAllFromVersion,
  lockToVersionWorkflow,
  showLockAllToVersion,
  showUnlockAllFromVersion,
  unlockFromVersionWorkflow,
  useFlatSetWizard
} from '../../../../actions/flatSetActions';
import {
  allowAddSelectedItemsToFlatSet,
  allowFlatSetCreationFromSelectedItems,
  allowRemoveFlatSetMember
} from '../../../../actions/flatSetActions/requirements';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';

export function useFlatSetActions(items: PWItem[]): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const httpService = useHttpService();
  const { readOnly } = usePluginContext();

  const {
    primaryModal,
    navigationManager: { currentParent }
  } = useNavigationContext();

  const {
    appViewManager: { currentAppView },
    workAreaManager
  } = useAppViewContext();

  const { dataManager } = useTableItemContext();
  const { ecPluginFeatures } = useEnvironmentContext();

  const { show: showFlatSetWizard } = useFlatSetWizard();

  const showFlatSetActionsAvailableInsideFlatSet = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }

    if (!itemIsFlatSet(currentParent)) {
      return false;
    }

    return true;
  }, [readOnly, currentParent]);

  const showAddSelectedItemsToFlatSet = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }

    if (itemIsFlatSet(currentParent)) {
      return false;
    }

    return true;
  }, [readOnly, currentParent]);

  const removeFlatSetMember = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.FlatSetRemove'),
      icon: <SvgRemove />,
      onClick: () => {
        primaryModal.open(<RemoveFlatSetMemberModal items={items} />);
      },
      disabled:
        currentAppView.type !== 'FolderNavigation' ||
        !allowRemoveFlatSetMember(currentParent as PWFlatSet, items),
      hidden: !showFlatSetActionsAvailableInsideFlatSet
    }),
    [
      currentAppView.type,
      currentParent,
      items,
      primaryModal,
      showFlatSetActionsAvailableInsideFlatSet
    ]
  );

  const lockToVersion = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.FlatSetLockToVersion'),
      icon: <SvgDocumentLock />,
      onClick: () =>
        void lockToVersionWorkflow(
          currentParent as PWFlatSet,
          filterDataItems(items),
          httpService,
          trackFeature,
          dataManager.refreshCurrentFolder
        ),
      disabled: !allowLockAllToVersion(currentParent as PWFlatSet, items),
      hidden:
        !showFlatSetActionsAvailableInsideFlatSet ||
        !showLockAllToVersion(currentParent as PWFlatSet, items)
    }),
    [
      trackFeature,
      currentParent,
      items,
      showFlatSetActionsAvailableInsideFlatSet,
      httpService,
      dataManager.refreshCurrentFolder
    ]
  );

  const unlockFromVersion = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.FlatSetUnlockFromVersion'),
      icon: <SvgDocumentLock />,
      onClick: () =>
        void unlockFromVersionWorkflow(
          currentParent as PWFlatSet,
          filterDataItems(items),
          httpService,
          dataManager.refreshCurrentFolder
        ),
      disabled: !allowUnlockAllFromVersion(currentParent as PWFlatSet, items),
      hidden:
        !showFlatSetActionsAvailableInsideFlatSet ||
        !showUnlockAllFromVersion(currentParent as PWFlatSet, items)
    }),
    [
      currentParent,
      items,
      showFlatSetActionsAvailableInsideFlatSet,
      httpService,
      dataManager.refreshCurrentFolder
    ]
  );

  const addSelectedItemsToFlatSet = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.AddToSet'),
      icon: <SvgAdd />,
      disabled:
        !showAddSelectedItemsToFlatSet ||
        !allowAddSelectedItemsToFlatSet(
          items,
          workAreaManager.allItemsExistInConnection
        ),
      hidden: !showAddSelectedItemsToFlatSet,
      onClick: () => {
        const addedItems = items;
        primaryModal.open(
          <AddMemberFromOutsideTheFlatSetModal addedItems={addedItems} />
        );
      }
    }),
    [
      items,
      primaryModal,
      showAddSelectedItemsToFlatSet,
      workAreaManager.allItemsExistInConnection
    ]
  );

  const createFlatSetFromSelectedItems = useMemo(
    (): MenuItemAction => ({
      title: t('FlatSet.Create'),
      icon: <SvgProject />,
      disabled:
        !showAddSelectedItemsToFlatSet ||
        !allowFlatSetCreationFromSelectedItems(
          items,
          currentParent,
          currentAppView.type,
          readOnly,
          workAreaManager.allItemsExistInConnection
        ),
      hidden: !showAddSelectedItemsToFlatSet,
      onClick: () => {
        trackFeature('CREATE_SET_FROM_SELECTED_ITEMS');
        showFlatSetWizard(items.map((i) => i.instanceId));
      }
    }),
    [
      trackFeature,
      items,
      currentParent,
      currentAppView.type,
      readOnly,
      workAreaManager.allItemsExistInConnection,
      showAddSelectedItemsToFlatSet,
      showFlatSetWizard
    ]
  );

  const childItems = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems([
        addSelectedItemsToFlatSet,
        createFlatSetFromSelectedItems,
        lockToVersion,
        unlockFromVersion,
        removeFlatSetMember
      ]),
    [
      removeFlatSetMember,
      lockToVersion,
      unlockFromVersion,
      addSelectedItemsToFlatSet,
      createFlatSetFromSelectedItems
    ]
  );

  const flatSetActions = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.Set'),
      icon: <SvgProject />,
      disabled: childItems.every((item) => item.hidden || item.disabled),
      hidden:
        childItems.every((item) => item.hidden || item.disabled) ||
        readOnly ||
        !ecPluginFeatures.flatSetActions() ||
        currentAppView.type == 'RecycleBin',
      submenuItems: childItems
    }),
    [childItems, readOnly, ecPluginFeatures, currentAppView.type]
  );

  return flatSetActions;
}
