import type { ProviderProps } from 'react';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import type {
  Feature,
  LaunchDarklyFeatures,
  LaunchDarklyValue
} from '@bentley/pw-config';
import { featureMap, useFeatures } from '@bentley/pw-config';
import { LoadingPage } from '../../../components/loadingPage';
import { ProjectWiseErrorPage } from '../../../components/projectWise';
import { useEquivalentState } from '../../../hooks/useNonRenderingState';
import type { TrackFeature } from '../../../hooks/useTrackFeature';
import { useTrackFeature } from '../../../hooks/useTrackFeature';
import { t } from '../../../services/translation';
import type { DataAccessLevel } from '../plugin';
import { useToken } from '../token';

export type FeatureTrackingProps = {
  buddiRegionCode: string;
  contextId?: string;
  dataAccessLevel?: DataAccessLevel;
  featureOverrides?: Feature[];
};

type FeatureTrackingContext = {
  featureOverrides: Feature[];
  launchDarklyFeatures: LaunchDarklyFeatures;
  trackFeature: TrackFeature;
};

const Context = createContext<FeatureTrackingContext | undefined>(undefined);

export function FeatureTrackingProvider({
  value: { buddiRegionCode, dataAccessLevel, contextId, featureOverrides = [] },
  children
}: ProviderProps<FeatureTrackingProps>): JSX.Element {
  const { getSamlToken, getOidcToken } = useToken();

  const { features, error } = useFeatures({
    getSamlToken,
    buddiRegionCode,
    dataAccessLevel
  });
  const trackFeature = useTrackFeature(
    buddiRegionCode,
    getOidcToken,
    contextId
  );

  const [overrides] = useEquivalentState(featureOverrides);

  const findFeatureToggle = useCallback(
    (flag: string): Feature | undefined => {
      const featureToggle = features?.find(
        (featureToggle) => featureToggle.featureFlag == flag
      );

      const overrideFlag = overrides.find(
        (featureToggle) => featureToggle.featureFlag == flag
      );
      return overrideFlag ?? featureToggle;
    },
    [features, overrides]
  );

  const getFeatureValue = useCallback(
    <T extends LaunchDarklyValue>(flag: string): T => {
      const featureToggle = findFeatureToggle(flag);

      if (!featureToggle) {
        return false as T;
      }

      return featureToggle.value as T;
    },
    [findFeatureToggle]
  );

  const launchDarklyFeatures = useMemo((): LaunchDarklyFeatures => {
    const features = {} as LaunchDarklyFeatures;

    for (const key in featureMap) {
      const featureValue = getFeatureValue(key);
      features[key as keyof typeof featureMap] = featureValue;
    }

    return features;
  }, [getFeatureValue]);

  const featureTrackingContext = useMemo(
    (): FeatureTrackingContext => ({
      featureOverrides: overrides,
      launchDarklyFeatures,
      trackFeature
    }),
    [overrides, launchDarklyFeatures, trackFeature]
  );

  if (!features && !error) {
    return (
      <LoadingPage
        loadingSubText={t('LoadingPage.RetrievingConfigurationSettings')}
        fromValue={0}
        toValue={25}
      />
    );
  }

  if (error) {
    return <ProjectWiseErrorPage error={error} />;
  }

  return (
    <Context.Provider value={featureTrackingContext}>
      {children}
    </Context.Provider>
  );
}

export function useFeatureTracking(): FeatureTrackingContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      `useFeatureTracking must be used within a FeatureTrackingProvider`
    );
  }

  return context;
}
