import { useCallback } from 'react';
import { AuthorizationService } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import { useConnectionContext, useToken } from '../../context';
import {
  decodeSessionCreds,
  getCachedSessionCreds,
  setCachedSessionCreds
} from './cachedCreds';

export type UserCredentials = { userName: string; password: string };

type GetAuthorizationService = {
  getAuthorizationService: (
    userCredentials?: UserCredentials,
    abortController?: AbortController
  ) => Promise<AuthorizationService | null>;
  getSessionCreds: () => UserCredentials | null;
};

export function useAuthorizationService(
  consumerApp?: ConsumerApp
): GetAuthorizationService {
  const { connection } = useConnectionContext();
  const { getOidcToken, getSamlToken } = useToken();

  const getCachedAuthorizationService =
    useCallback((): AuthorizationService | null => {
      const sessionCreds = getCachedSessionCreds(connection.Id, consumerApp);

      if (sessionCreds == 'OIDC token') {
        const authorizationService = new AuthorizationService({ getOidcToken });
        return authorizationService;
      }

      if (sessionCreds == 'SAML token') {
        const authorizationService = new AuthorizationService({ getSamlToken });
        return authorizationService;
      }

      if (sessionCreds) {
        const authorizationService = new AuthorizationService({
          userCredentials: { encodedCredentials: sessionCreds }
        });
        return authorizationService;
      }

      return null;
    }, [connection.Id, consumerApp, getOidcToken, getSamlToken]);

  const getAuthorizationService = useCallback(
    async (
      userCredentials?: UserCredentials,
      abortController?: AbortController
    ): Promise<AuthorizationService | null> => {
      const cachedAuthorizationService = getCachedAuthorizationService();

      if (cachedAuthorizationService) {
        return cachedAuthorizationService;
      }

      const authorizationOptions = userCredentials
        ? { userCredentials }
        : { getOidcToken, getSamlToken };

      const authorization = await AuthorizationService.Create({
        baseUrl: connection.ConnectionUrl,
        ...authorizationOptions,
        abortController
      });

      if (authorization) {
        await setCachedSessionCreds(connection.Id, authorization, consumerApp);
      }

      return authorization;
    },
    [
      connection.Id,
      connection.ConnectionUrl,
      consumerApp,
      getCachedAuthorizationService,
      getOidcToken,
      getSamlToken
    ]
  );

  const getSessionCreds = useCallback(() => {
    const cachedSessionCreds = getCachedSessionCreds(
      connection.Id,
      consumerApp
    );

    if (
      !cachedSessionCreds ||
      cachedSessionCreds == 'OIDC token' ||
      cachedSessionCreds == 'SAML token'
    ) {
      return null;
    }

    try {
      return decodeSessionCreds(cachedSessionCreds);
    } catch {
      return null;
    }
  }, [connection.Id, consumerApp]);

  return { getAuthorizationService, getSessionCreds };
}
