import React from 'react';
import type { PWItem, PWParentType, PWProject } from '@bentley/pw-api';
import { itemIsProject, pwConstants } from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary';
import { ProgressSummaryModal } from '../../components/ProgressSummary';
import type { CloseModal, ModalElement, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import { ConflictResolutionModal } from '../conflictResolution';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import { FileNameTruncationModal } from './fileNameTruncationModal';
import type { UploadNode } from './tree';
import { overLengthFileNames, overLengthFolderNames } from './tree';
import { newFile, truncateFileName, truncateFolderName } from './utils';

export function truncateFileNamesModal(
  uploadNode: UploadNode,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  onComplete: (uploadNode: UploadNode) => void,
  followUpActionsOnCancel?: () => void
): void {
  function renameFiles(uploadNode: UploadNode): UploadNode {
    const currentUploadNode = {
      ...uploadNode.current,
      name: truncateFolderName(uploadNode.current?.name)
    };

    return {
      current: currentUploadNode,
      files: uploadNode.files.map(truncateName),
      directories: uploadNode.directories.map(renameFiles)
    } as UploadNode;
  }

  function truncateName(file: File): File {
    if (file.name.length >= pwConstants.maxFileNameLength) {
      return newFile(file, truncateFileName(file.name));
    } else {
      return file;
    }
  }

  const onYes = () => {
    onComplete(renameFiles(uploadNode));
  };

  const onNo = () => {
    followUpActionsOnCancel?.();
  };

  const modal = (
    <FileNameTruncationModal
      closeModal={closeModal}
      onNo={onNo}
      onYes={onYes}
      files={overLengthFileNames(uploadNode)}
      folders={overLengthFolderNames(uploadNode)}
      trackFeature={trackFeature}
    />
  ) as ModalElement;

  openModal(modal);
}

export function uploadFailureSummaryModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  failures: ProgressSummaryItem[]
): void {
  function progressFailureTitle(failures: ProgressSummaryItem[]): string {
    if (failures.length == 1 && itemIsProject(failures[0].item)) {
      return t('Upload.FolderFailedToUpload');
    } else if (failures.every((failure) => itemIsProject(failure.item))) {
      return t('Upload.FoldersFailedToUpload');
    } else if (failures.length == 1 && !itemIsProject(failures[0].item)) {
      return t('Upload.FileFailedToUpload');
    } else if (failures.every((failure) => !itemIsProject(failure.item))) {
      return t('Upload.FilesFailedToUpload');
    } else {
      return t('Upload.ErrorOccurred');
    }
  }
  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={failures}
      title={progressFailureTitle(failures)}
    />
  );
}

export function conflictResolutionModal(
  sourceItems: { item: PWItem; file?: File }[],
  destinationParent: PWParentType,
  openModal: OpenModal,
  closeModal: CloseModal,
  resolveConflicts: (resolution: ConflictResolution[]) => void,
  onClose: () => void
): void {
  openModal(
    <ConflictResolutionModal
      sourceItems={sourceItems}
      destinationParent={destinationParent as PWProject}
      resolveConflicts={resolveConflicts}
      onClose={onClose}
      closeModal={closeModal}
    />
  );
}
