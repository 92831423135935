import './projectWiseApp.css';

import React, { useEffect } from 'react';
import { InformationPanelWrapper } from '@itwin/itwinui-react';
import { DriveBanner } from '../../actions/drive';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext,
  useTelemetry
} from '../../context';
import { useLinkResolution } from '../../hooks/useLinkResolution';
import { t } from '../../services/translation';
import { AccessibilityBanner } from '../accessibilityBanner';
import { PWBreadcrumbs } from '../breadcrumbs';
import { PWFilterToken } from '../filterToken/pwFilterToken';
import type { BannerInformation } from '../flagBanner/flagBanner';
import { FlagBanner } from '../flagBanner/flagBanner';
import { LoadingPage } from '../loadingPage';
import { LogicalAccountBanner } from '../logicalAccountBanner/logicalAccountBanner';
import { usePWErrorPage } from '../pwErrorPage/usePWErrorPage';
import { PWInfoPanel } from '../pwInfoPanel';
import { PWSearch } from '../pwSearch';
import { PWTable } from '../pwTable';
import { TableLoadingWrapper } from '../pwTable/loading/tableLoadingWrapper';
import { SearchIModels } from '../searchIModels';
import {
  ItemActionsToolbar,
  NewDropdownButton,
  OptionsToolbar
} from '../toolbar';
import { UploadDropzone } from '../uploadDropzone';
import { ViewStatus } from '../viewStatus';

export function ProjectWiseApp(): JSX.Element {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { startTelemetry } = useTelemetry();
  const { consumerApp, progressManager, connection } = usePluginContext();
  const { environmentContextInitialized } = useEnvironmentContext();
  const { primaryModal } = useNavigationContext();

  useLinkResolution();

  const errorPage = usePWErrorPage();

  useEffect(() => {
    startTelemetry('loadingConnection');
  }, [startTelemetry]);

  if (errorPage) {
    return errorPage;
  }

  if (!environmentContextInitialized) {
    return (
      <LoadingPage
        loadingSubText={t('LoadingPage.PreparingConnection', {
          connectionName: connection.Name
        })}
        fromValue={75}
        toValue={100}
      />
    );
  }

  return (
    <div className="appContainer">
      <DriveBanner />
      <AccessibilityBanner />
      <LogicalAccountBanner />
      <FlagBanner
        bannerInfo={
          launchDarklyFeatures.documentConnectionsInfoBanner as BannerInformation
        }
        consumerApp={consumerApp}
      />
      <div className="projectWiseAppContainer">
        <div className="projectWiseApp">
          <menu>
            <div className="horizontal-nav-bar">
              <PWBreadcrumbs />
              <ViewStatus />
            </div>
            <div className="toolbar-row" data-testid="Toolbar">
              <div>
                <NewDropdownButton />
                <ItemActionsToolbar />
                <SearchIModels />
              </div>
              <div className="toolbar-right">
                <PWSearch />
                <OptionsToolbar />
              </div>
            </div>
          </menu>
          <PWFilterToken />
          <UploadDropzone>
            <TableLoadingWrapper>
              <InformationPanelWrapper style={{ height: '100%' }}>
                <PWTable />
                <PWInfoPanel />
              </InformationPanelWrapper>
            </TableLoadingWrapper>
          </UploadDropzone>
          <div id="file-input-div" />
        </div>
        {primaryModal.modal}
        {progressManager.IPModal}
      </div>
    </div>
  );
}
