import { LogError } from './useErrorLogging';

declare const window: {
  logError?: LogError;
};

/**
 * Sends error logging to SEQ via PW Web backend
 * **Prefer to use `useErrorLogging` hook instead**
 */
export function logError(...args: Parameters<LogError>): void {
  if (!window.logError) {
    console.error('Error logging is not configured.');
    console.error(...args);
    return;
  }

  try {
    window.logError(...args);
  } catch (e) {
    console.error('Error logging failed:', e);
    console.error(...args);
  }
}

export function setGlobalLogError(logError: LogError): void {
  window.logError = logError;
}
