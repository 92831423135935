import React, { useCallback, useState } from 'react';
import type { HttpService, PWDataItem } from '@bentley/pw-api';
import { itemFileExtension } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Flex, Label, Radio, StatusMessage, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { usePluginContext } from '../../context';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager';
import { castFileToDataItem } from '../../hooks/useProgressManager/utils';
import { useLocalStorage } from '../../hooks/useStorage';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import { imageMimeTypes } from '../download/fileViewer';
import { associatedIModelBridges, isMappableFile } from '../imodelMapping';
import { getUpdatedName } from './names';
import { replaceFileWorkflow } from './replace';

type ReplaceFileModalProps = {
  isCannedConnection: boolean;
  closeModal: CloseModal;
  openModal: OpenModal;
  oldItem: PWDataItem;
  newFile: File;
  httpService: HttpService;
  trackFeature: TrackFeature;
  onSuccess: () => void;
  progressManager: ProgressManager;
  differentFileNamesAllowed?: boolean;
};

function ReplaceFileModal({
  isCannedConnection,
  closeModal,
  openModal,
  oldItem,
  newFile,
  httpService,
  trackFeature,
  onSuccess,
  progressManager,
  differentFileNamesAllowed
}: ReplaceFileModalProps): JSX.Element {
  const {
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();

  const [useOldName, setUseOldName] = useState<boolean>(false);

  const oldItemWithNewItemNameAndSize = {
    ...oldItem,
    Version: '',
    FileName: newFile.name,
    CreateTime: new Date(newFile.lastModified).toDateString(),
    UpdateTime: new Date(newFile.lastModified).toDateString(),
    FileSize: newFile.size.toString()
  } as PWDataItem;

  let disableReplaceOption = false;
  if (
    isCannedConnection &&
    isMappableFile(oldItem) &&
    associatedIModelBridges(oldItem, iModelBridges).length > 0 &&
    itemFileExtension(oldItem) !=
      itemFileExtension(oldItemWithNewItemNameAndSize)
  ) {
    disableReplaceOption = true;
  }

  const [nameIconLocked] = useLocalStorage<boolean>(
    'fileNameLockedSelection',
    false
  );

  const areFileNamesSame =
    oldItem.FileName === oldItemWithNewItemNameAndSize.FileName;
  const areBothFileImages =
    imageMimeTypes.includes(newFile.type) &&
    imageMimeTypes.includes(oldItem.MimeType);

  const nameAndFileNameMatch =
    (!differentFileNamesAllowed || nameIconLocked) &&
    !areFileNamesSame &&
    (areBothFileImages || !imageMimeTypes.includes(newFile.type));

  const showWarning =
    (!differentFileNamesAllowed || nameIconLocked) &&
    !areBothFileImages &&
    imageMimeTypes.includes(newFile.type);

  const differentFileType = oldItem.MimeType !== newFile.type;

  const primaryButtonHandle = useCallback(() => {
    closeModal();
    void replaceFileWorkflow(
      newFile,
      oldItem,
      httpService,
      trackFeature,
      progressManager,
      undefined,
      onSuccess,
      openModal,
      closeModal,
      useOldName,
      nameAndFileNameMatch
    );
  }, [
    closeModal,
    httpService,
    nameAndFileNameMatch,
    newFile,
    oldItem,
    onSuccess,
    openModal,
    progressManager,
    trackFeature,
    useOldName
  ]);

  return (
    <PWModal
      title={t('Replace.ReplaceFile')}
      primaryButton={{
        title: t('Replace.Replace'),
        onClick: primaryButtonHandle,
        disabled: disableReplaceOption
      }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: closeModal }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'ReplaceFileModal' }}
    >
      <Label>{t('Replace.ReplaceThisFile')}:</Label>
      <ItemSummary useFileName={true} item={[oldItem]} />
      <Label>{t('Replace.WithThisFile')}:</Label>
      <ItemSummary useFileName={true} item={[castFileToDataItem(newFile)]} />
      {showWarning && (
        <StatusMessage status="warning">
          {t('Replace.FileNameCannotBeChanged')}
        </StatusMessage>
      )}
      {nameAndFileNameMatch && (
        <Flex flexDirection="column" alignItems="stretch">
          <Text>{t('Replace.DocumentAndFileNameMustEqual')}</Text>
          <Text>{t('Replace.SelectNameToUse')}:</Text>
          <Radio
            className={'radio-button-input-replace-file-modal'}
            name="Options"
            label={t('Replace.UseOriginalFileName', {
              fileName: getUpdatedName(
                true,
                oldItem.FileName || oldItem.Name,
                newFile.name,
                true
              )
            })}
            onChange={() => null}
            onClick={() => setUseOldName(true)}
            checked={useOldName}
          />
          <Radio
            className={'radio-button-input-replace-file-modal'}
            name="Options"
            label={t('Replace.UseNewFileName', { fileName: newFile.name })}
            onClick={() => setUseOldName(false)}
            onChange={() => null}
            checked={!useOldName}
          />
        </Flex>
      )}
      {disableReplaceOption && (
        <StatusMessage status="negative">
          {t('Replace.IModelError')}
        </StatusMessage>
      )}
      {differentFileType && (
        <p className="replace-file-modal-warning">
          {t('Replace.FileTypesDoNotMatch')}
        </p>
      )}
    </PWModal>
  );
}
export default ReplaceFileModal;
