import './emptyTableState.css';

import React from 'react';
import {
  useAppViewContext,
  useTableColumnContext,
  useTableItemContext
} from '../../context';
import { EmptyFilterState } from './emptyFilterState';
import { EmptyFolderState } from './emptyFolderState';
import { EmptyOutToMeState } from './emptyOutToMeState';
import { EmptyRecycleBinState } from './emptyRecycleBinState';
import { EmptySearchState } from './emptySearchState';
import { EmptyWorkAreaState } from './emptyWorkAreaState';
import { TableErrorState } from './tableErrorState';

export function EmptyTableState(): JSX.Element {
  const {
    appViewManager: { currentAppView },
    workAreaManager: { workAreaAvailable }
  } = useAppViewContext();
  const { dataManager } = useTableItemContext();
  const { tableFiltering } = useTableColumnContext();

  if (!workAreaAvailable) {
    return <EmptyWorkAreaState />;
  }

  if (dataManager.error) {
    return <TableErrorState />;
  }

  if (tableFiltering.filters.length) {
    return <EmptyFilterState />;
  }

  if (
    currentAppView.type == 'BasicSearch' ||
    currentAppView.type == 'AdvancedSearch' ||
    currentAppView.type == 'SavedSearch'
  ) {
    return <EmptySearchState />;
  }

  if (currentAppView.type == 'OutToMe') {
    return <EmptyOutToMeState />;
  }

  if (currentAppView.type == 'RecycleBin') {
    return <EmptyRecycleBinState />;
  }

  return <EmptyFolderState />;
}
