import { useEffect, useState } from 'react';
import { logError } from '../useErrorLogging';

export function useSessionStorage<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  return useStorage<T>(sessionStorage, key, defaultValue);
}

export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  return useStorage<T>(localStorage, key, defaultValue);
}

function useStorage<T>(
  storage: Storage,
  key: string,
  defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  function initialValue(key: string, defaultValue: T): T {
    const value = storage.getItem(key);
    if (value) {
      if (value == 'undefined') {
        return undefined as unknown as T;
      }
      return JSON.parse(value) as T;
    }
    return defaultValue;
  }

  const [value, setValue] = useState<T>(initialValue(key, defaultValue));

  useEffect(() => {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logError('Error writing to storage', { error });
      if ((error as Error)?.message.includes('exceeded the quota')) {
        storage.clear();
      }
    }
  }, [value]);

  return [value, setValue];
}
