import { useCallback, useMemo } from 'react';
import { defaultView } from '../defaultView';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function useDefaultView(
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const setView = useCallback(
    (view?: View): void => {
      setCurrentViewType('Default');
    },
    [setCurrentViewType]
  );

  const saveView = useCallback((view: View): void => {
    throw new Error('Save default view not supported. Use save view');
  }, []);
  const editView = useCallback((originalView: View, newView: View): void => {
    throw new Error('Edit default view not supported. Use save view');
  }, []);

  const deleteView = useCallback((view: View): void => {
    throw new Error('Delete default view not supported. Use save view');
  }, []);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized: true,
      view: defaultView,
      views: [defaultView],
      saveView,
      setView,
      editView,
      deleteView
    }),
    [deleteView, editView, saveView, setView]
  );

  return viewSetting;
}
