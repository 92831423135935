import type { PWItem } from '@bentley/pw-api';
import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyCreateCopyInProgress(
  numberOfItems?: number
): ToastHandle {
  const content = inProgressNotificationContent(numberOfItems);

  return openToast({ content, type: 'persisting', spinner: true });
}

function inProgressNotificationContent(numberOfItems?: number): string {
  if (!numberOfItems) {
    return t('CreateCopy.Notifications.InProgress');
  }

  if (numberOfItems == 1) {
    return t('CreateCopy.Notifications.InProgressForOneFile');
  }
  return t('CreateCopy.Notifications.InProgressForMultipleFiles', {
    numberOfItems
  });
}

export function notifyErrorDuringCopy(
  toastHandle: ToastHandle,
  errorMessage: string
): void {
  replaceToast(toastHandle, {
    content: t('CreateCopy.Notifications.Error', { errorMessage }),
    category: 'negative'
  });
}

export function notifyCopySuccess(
  numberOfItems: number,
  toastHandle: ToastHandle
): void {
  const content =
    numberOfItems == 1
      ? t('CreateCopy.Notifications.SuccessOneFile')
      : t('CreateCopy.Notifications.SuccessOneMultipleFile', {
          numberOfItems
        });

  replaceToast(toastHandle, { content, category: 'positive' });
}

export function on403Error(): string {
  return t('CreateCopy.Notifications.403Error');
}

export function on400Error(item: PWItem): string {
  return t('CreateCopy.Notifications.400Error', {
    name: item.Name
  });
}

export function on409Error(item: PWItem): string {
  return t('CreateCopy.Notifications.409Error', {
    name: item.Name
  });
}
