import React, { useMemo } from 'react';
import { SvgImodel } from '@itwin/itwinui-icons-react';
import { useAppViewContext, useTableItemContext } from '../../context';
import { PWTooltip } from '../pwTooltip';

export function SearchIModels(): JSX.Element | null {
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const { savedSearchIModels } = useTableItemContext();

  const showSearchIModels = useMemo((): boolean => {
    if (currentAppView.type != 'SavedSearch') {
      return false;
    }
    if (!savedSearchIModels) {
      return false;
    }
    return true;
  }, [savedSearchIModels, currentAppView.type]);

  if (!showSearchIModels) {
    return null;
  }

  return (
    <PWTooltip placement={'top'} content={savedSearchIModels ?? ''}>
      <div
        className="mapped-savedsearch-imodel-icon"
        data-testid="saved-search-imodel-badge"
      >
        <SvgImodel
          style={{ height: 'var(--iui-size-m)', width: 'var(--iui-size-m)' }}
        />
      </div>
    </PWTooltip>
  );
}
