import type { PWItem, PWParentType } from '@bentley/pw-api';
import {
  itemHasOlderVersions,
  itemIsFlatSet,
  itemIsReadOnly
} from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';
import { ecPluginFeatures } from '../../hooks/useECPluginVersion';

/**
 * Returns whether these items are able to be moved
 * @param {PWItem[]} items Ttems to be moved
 * @param {PWParentType} parent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @returns {boolean} True if items may be moved
 */
export function allowMoveAll(
  items: PWItem[],
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  allItemsExistInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || !items.length) {
    return false;
  }

  if (!items.every((item) => allowMove(item, parent, appView, readOnly))) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

/**
 * Returns whether item is able to be moved
 * @param {PWItem} item Item to be moved
 * @param {PWParentType} parent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @returns True if item may be moved
 */
export function allowMove(
  item: PWItem,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): boolean {
  if (!item.className || !item.instanceId) {
    return false;
  }

  if (
    itemIsReadOnly(item) ||
    (itemHasOlderVersions(item) && !ecPluginFeatures.moveVersions())
  ) {
    return false;
  }

  if (!ecPluginFeatures.flatSetActions() && itemIsFlatSet(item)) {
    return false;
  }

  if (itemIsFlatSet(parent) && !itemIsFlatSet(item)) {
    return false;
  }

  if (appView == 'RecycleBin') {
    return false;
  }

  if (readOnly) {
    return false;
  }

  return true;
}
