import type { WSGProperties } from '@bentley/pw-api';

export function filterDocCodeProperties(
  propertiesWithDocCodeProperties: WSGProperties,
  propertiesWithoutDocCodeProperties: WSGProperties
): WSGProperties {
  const docCodeProperties = propertyDifference(
    propertiesWithDocCodeProperties,
    propertiesWithoutDocCodeProperties
  );
  return docCodeProperties;
}

export function filterNonDocCodeProperties(
  environmentProperties: WSGProperties,
  docCodeOnlyProperties: WSGProperties | null
): WSGProperties {
  const nonDocCodeProperties = propertyDifference(
    environmentProperties,
    docCodeOnlyProperties
  );
  return nonDocCodeProperties;
}

function propertyDifference(
  allProperties: WSGProperties,
  subsetToRemove: WSGProperties | null
): WSGProperties {
  if (!subsetToRemove || !Object.keys(subsetToRemove).length) {
    return allProperties;
  }

  const difference = {} as WSGProperties;
  for (const key in allProperties) {
    if (subsetToRemove[key] === undefined) {
      difference[key] = allProperties[key];
    }
  }

  return difference;
}
