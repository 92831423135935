import { useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';
/**
 * Fetches BUDDI URL and stores it in session storage. If URL is already stored
 * in session storage it will be returned, otherwise it will be fetched from
 * BUDDI.
 * @param urlNameOrId Name of URL stored in BUDDI
 * @param regionCode Region code (prod: 101, qa: 102, dev: 103)
 * @returns BUDDI URL with trailing slash; null if URL is not found; undefined
 * if URL is being fetched
 */
export function useBuddi(urlNameOrId, regionCode) {
    const [url, setUrl] = useSessionStorage(`buddi:${regionCode}:${urlNameOrId}`, undefined);
    async function fetchBUDDIUrl(urlNameOrId, regionCode) {
        var _a;
        const response = await fetch(`https://buddi.bentley.com/WebService/GetUrl?url=${urlNameOrId}&region=${regionCode}`);
        if (!response.ok) {
            return null;
        }
        const body = (await response.json());
        let url = (_a = body.result) === null || _a === void 0 ? void 0 : _a.url;
        if (!url) {
            return null;
        }
        if (!url.endsWith('/')) {
            url += '/';
        }
        return url;
    }
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchUrl(urlNameOrId) {
            const fetchedUrl = await fetchBUDDIUrl(urlNameOrId, regionCode);
            if (!abortController.signal.aborted) {
                setUrl(fetchedUrl);
            }
        }
        if (!url && urlNameOrId && regionCode) {
            void fetchUrl(urlNameOrId);
        }
        return () => {
            abortController.abort();
        };
    }, [urlNameOrId, regionCode, setUrl, url]);
    return url;
}
