import React, { useMemo } from 'react';
import type { LaunchDarklyFeatures } from '@bentley/pw-config';
import { featureMap } from '@bentley/pw-config';
import { Table, tableFilters } from '@itwin/itwinui-react';
import type { Column } from '@itwin/itwinui-react/react-table';
import { PWModal } from '../../components/pwModal';
import { useFeatureTracking, useNavigationContext } from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';

type LdFlag = { name: string; value: boolean | string | number };

export function LdFlagsModal(): ModalElement {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { primaryModal } = useNavigationContext();

  const tableData = useMemo((): LdFlag[] => {
    const flags: string[] = [];
    for (const key in launchDarklyFeatures) {
      flags.push(key);
    }

    return flags.map((flag) => {
      const flagKey = flag as keyof LaunchDarklyFeatures;
      return {
        name: featureMap[flagKey],
        value: launchDarklyFeatures[flagKey]
      } as LdFlag;
    });
  }, [launchDarklyFeatures]);

  const columns = useMemo(
    (): Column<LdFlag>[] => [
      {
        id: 'name',
        Header: t('Generic.Name'),
        accessor: 'name',
        Filter: tableFilters.TextFilter()
      },
      {
        id: 'value',
        Header: t('Generic.Value'),
        accessor: (row: LdFlag) => JSON.stringify(row.value),
        Cell: (row: LdFlag) => (
          <pre style={{ overflowX: 'auto' }}>
            {JSON.stringify(JSON.parse(String(row.value)), undefined, 2)}
          </pre>
        )
      }
    ],
    []
  );

  return (
    <PWModal
      title={t('MenuItem.DisplayLdFlags')}
      secondaryButton={{
        onClick: primaryModal.close,
        title: t('Generic.Close')
      }}
      onClose={primaryModal.close}
    >
      <Table
        columns={columns}
        data={tableData}
        isResizable={true}
        isSortable={true}
        style={{ maxHeight: '50vh' }}
        density="extra-condensed"
        emptyTableContent=""
      />
    </PWModal>
  );
}
