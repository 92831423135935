import React from 'react';
import { SvgDelete, SvgDuplicate, SvgEdit } from '@itwin/itwinui-icons-react';
import { IconButton } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import { useNavigationContext, usePropertyContext } from '../../../context';
import type { View } from '../../../hooks/useViews';
import { t } from '../../../services/translation';
import { CustomColumnDialog } from '../../customColumn';
import { DeleteConfirmationModal } from '../deleteConfirmationModal';
import { MyViewsModal } from '../myViewsModal';

export function ActionsCell({ row }: CellProps<View>): JSX.Element | null {
  const { viewManager } = usePropertyContext();
  const { primaryModal } = useNavigationContext();

  const view = row.original;

  function onEditClick(): void {
    primaryModal.open(
      <CustomColumnDialog
        onClose={() => primaryModal.open(<MyViewsModal />)}
        initialView={view}
        mode="EditMode"
        onSave={(newView) =>
          viewManager.userViewSetting.editView(view, newView)
        }
      />
    );
  }

  function onCopyClick(): void {
    primaryModal.open(
      <CustomColumnDialog
        onClose={() => primaryModal.open(<MyViewsModal />)}
        initialView={view}
        mode="CopyMode"
        onSave={viewManager.userViewSetting.saveView}
      />
    );
  }

  function onDeleteClick(): void {
    primaryModal.open(
      <DeleteConfirmationModal
        onYes={() => {
          viewManager.userViewSetting.deleteView(view);
          primaryModal.open(<MyViewsModal />);
        }}
        onNo={() => primaryModal.open(<MyViewsModal />)}
        customTitle={t('MyViews.DeleteView')}
        customMessage={t('MyViews.DeleteSelectedView')}
      />
    );
  }

  return (
    <>
      <IconButton
        label={t('Generic.Edit')}
        styleType="borderless"
        onClick={onEditClick}
        data-testid={`edit-button-${view.name}`}
      >
        <SvgEdit />
      </IconButton>
      <IconButton
        label={t('Generic.Copy')}
        styleType="borderless"
        onClick={onCopyClick}
        data-testid={`copy-button-${view.name}`}
      >
        <SvgDuplicate />
      </IconButton>
      <IconButton
        label={t('Generic.Delete')}
        styleType="borderless"
        onClick={onDeleteClick}
        data-testid={`delete-button-${view.name}`}
      >
        <SvgDelete />
      </IconButton>
    </>
  );
}
