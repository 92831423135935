import React, { useCallback, useMemo } from 'react';
import { AdvancedSavedSearchesModal } from '../../../../actions/savedAdvancedSearches';
import {
  useAppViewContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgMySearch } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useMyAdvancedSearchesAction(): MenuItemAction {
  const { connection } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const onSavedAdvancedSearchClick = useCallback((): void => {
    primaryModal.open(<AdvancedSavedSearchesModal />);
  }, [primaryModal]);

  const myAdvancedSearches = useMemo(
    (): MenuItemAction => ({
      title: t('SavedAdvSearch.MyAdvSearches'),
      icon: <SvgMySearch />,
      hidden: connection.Canned || currentAppView.type == 'OutToMe',

      onClick: onSavedAdvancedSearchClick
    }),
    [connection.Canned, currentAppView.type, onSavedAdvancedSearchClick]
  );

  return myAdvancedSearches;
}
