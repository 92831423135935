import { useCallback, useMemo } from 'react';
import { useConnectionAuth, usePluginContext } from '../../../context';
import { useLocalStorage } from '../../useStorage';
import { defaultView } from '../defaultView';
import type { PRDSSViewManager } from '../prdssViews/usePRDSSViews';
import { usePRDSSViews } from '../prdssViews/usePRDSSViews';
import type { View, ViewType } from '../useViews';
import type { ViewSetting } from './viewSetting';

export function useUserView(
  setCurrentViewType: (viewType: ViewType) => void
): ViewSetting {
  const { connection } = usePluginContext();
  const { userName } = useConnectionAuth();

  const [currentViewName, setCurrentViewName] = useLocalStorage<string>(
    `userView-${connection.Id}`,
    ''
  );

  const userConnectionViewsSetting = usePRDSSViews(
    `connectionViews-${connection.Id}-${userName}`,
    'User'
  );

  const userProjectViewsSetting = usePRDSSViews(
    `projectViews-${userName}`,
    'User'
  );

  const getScopedViewsSetting = useCallback(
    (view: View): PRDSSViewManager => {
      if (view.multiConnection) {
        return userProjectViewsSetting;
      }
      return userConnectionViewsSetting;
    },
    [userConnectionViewsSetting, userProjectViewsSetting]
  );

  const saveView = useCallback(
    (view: View): void => {
      const viewsSetting = getScopedViewsSetting(view);
      const existingViews = viewsSetting.views ?? [];
      const newViews = [...existingViews, view];
      viewsSetting.setViews(newViews);
    },
    [getScopedViewsSetting]
  );

  const setView = useCallback(
    (view?: View): void => {
      if (!view) {
        return;
      }
      setCurrentViewName(view.name);
      setCurrentViewType('UserSaved');
    },
    [setCurrentViewName, setCurrentViewType]
  );

  const editView = useCallback(
    (originalView: View, newView: View): void => {
      if (originalView.multiConnection == newView.multiConnection) {
        const viewsSetting = getScopedViewsSetting(newView);

        const existingViews = viewsSetting.views ?? [];
        const viewsWithoutOriginal = existingViews.filter(
          (view) => view != originalView
        );
        const newViews = [...viewsWithoutOriginal, newView];
        viewsSetting.setViews(newViews);
      } else {
        const originalViewsSetting = getScopedViewsSetting(originalView);
        const newViewsSetting = getScopedViewsSetting(newView);

        const existingOriginalViews = originalViewsSetting.views ?? [];
        const existingNewViews = newViewsSetting.views ?? [];
        const viewsWithoutOriginal = existingOriginalViews.filter(
          (view) => view != originalView
        );
        const newViews = [...existingNewViews, newView];

        originalViewsSetting.setViews(viewsWithoutOriginal);
        newViewsSetting.setViews(newViews);
      }
    },
    [getScopedViewsSetting]
  );

  const deleteView = useCallback(
    (view: View): void => {
      const viewsSetting = getScopedViewsSetting(view);

      const existingViews = viewsSetting.views ?? [];
      const viewsWithoutOriginal = existingViews.filter(
        (existingView) => existingView != view
      );

      viewsSetting.setViews(viewsWithoutOriginal);
    },
    [getScopedViewsSetting]
  );

  const view = useMemo((): View => {
    const connectionView = userConnectionViewsSetting.views?.find(
      (view) => view.name == currentViewName
    );
    const projectView = userProjectViewsSetting.views?.find(
      (view) => view.name == currentViewName
    );
    return connectionView ?? projectView ?? defaultView;
  }, [currentViewName, userConnectionViewsSetting, userProjectViewsSetting]);

  const viewSetting = useMemo(
    (): ViewSetting => ({
      initialized:
        userConnectionViewsSetting.views != undefined &&
        userProjectViewsSetting.views != undefined,
      view,
      views: [
        ...(userConnectionViewsSetting?.views ?? []),
        ...(userProjectViewsSetting?.views ?? [])
      ],
      saveView,
      setView,
      editView,
      deleteView
    }),
    [
      deleteView,
      editView,
      saveView,
      setView,
      userConnectionViewsSetting.views,
      userProjectViewsSetting.views,
      view
    ]
  );

  return viewSetting;
}
