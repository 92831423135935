import '../form/dcwForm.css';
import './wizardContainer.css';

import React from 'react';
import { Stepper } from '@itwin/itwinui-react';
import { useCancelConfirmation } from '../cancel';
import { useWizardSteps } from './useWizardSteps';

export function WizardContainer(): JSX.Element {
  useCancelConfirmation();

  const { currentStepIndex, steps, setCurrentStepIndex, currentStep } =
    useWizardSteps();

  return (
    <div className="dcw-wizard">
      {steps.length > 1 && (
        <Stepper
          currentStep={currentStepIndex}
          steps={steps}
          onStepClick={setCurrentStepIndex}
        />
      )}
      <div className="step-content">{currentStep}</div>
    </div>
  );
}
