import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import type { FormDefinition } from '../../../actions/formRendering';
import { getProjectDefaultFormDefinition } from '../../../actions/formRendering';
import { generateReloadFormAction } from '../../documentCreation/form';
import { validateEnvironmentProperties } from '../../documentCreation/properties';
import { getAttributesHttpService, getDocumentFormDefinition } from '../data';
import { handleAttributesFormError } from '../error';
import { originalEnvironmentInstanceState, selectedItemsState } from '../state';
import { formDefinitionState, updatingReloadPropertiesState } from './state';

type FormReloadWorkflowFunctions = {
  reloadFormByInstanceWorkflow: (
    environmentInstance: WSGInstance
  ) => Promise<void>;
  reloadFormWorkflow: () => Promise<void>;
};

export function useFormReloadWorkflow(): FormReloadWorkflowFunctions {
  const selectedItems = useRecoilValue(selectedItemsState);

  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const setUpdatingReloadProperties = useSetRecoilState(
    updatingReloadPropertiesState
  );

  const setFormDefinition = useSetRecoilState(formDefinitionState);

  const reloadWorkflow = useCallback(
    async (
      formRetrievalFunction: () => Promise<FormDefinition>
    ): Promise<void> => {
      try {
        setUpdatingReloadProperties(true);
        const formDefinition = await formRetrievalFunction();
        setFormDefinition(formDefinition);
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          void handleAttributesFormError(error);
        }
      } finally {
        setUpdatingReloadProperties(false);
      }
    },
    [setFormDefinition, setUpdatingReloadProperties]
  );

  const reloadFormByInstanceWorkflow = useCallback(
    async (environmentInstance: WSGInstance): Promise<void> => {
      if (
        environmentInstance.properties &&
        originalEnvironmentInstance?.properties
      ) {
        const validatedProperties = validateEnvironmentProperties(
          environmentInstance.properties,
          originalEnvironmentInstance?.properties,
          false
        );

        environmentInstance = {
          ...environmentInstance,
          properties: validatedProperties
        };
      }

      async function formRetrievalFunction(): Promise<FormDefinition> {
        return generateReloadFormAction(
          'attributespage',
          environmentInstance,
          getAttributesHttpService()
        );
      }

      await reloadWorkflow(formRetrievalFunction);
    },
    [originalEnvironmentInstance?.properties, reloadWorkflow]
  );

  const reloadFormWorkflow = useCallback(async (): Promise<void> => {
    async function formRetrievalFunction(): Promise<FormDefinition> {
      let formDefinition = await getDocumentFormDefinition(selectedItems[0]);

      if (!formDefinition) {
        formDefinition = await getProjectDefaultFormDefinition(
          selectedItems[0].ParentGuid ?? '',
          getAttributesHttpService()
        );

        if (!formDefinition) {
          throw 'Unable to get form definition';
        }
      }

      return formDefinition;
    }

    await reloadWorkflow(formRetrievalFunction);
  }, [reloadWorkflow, selectedItems]);

  return { reloadFormByInstanceWorkflow, reloadFormWorkflow };
}
