import React from 'react';
import { DocCodeFormContainer } from './docCodeFormContainer';
import { DocCodePreviewContainer } from './docCodePreviewContainer';

export function DocCodeSection(): JSX.Element {
  return (
    <>
      <DocCodeFormContainer />
      <DocCodePreviewContainer />
    </>
  );
}
