import React from 'react';
import { SvgChevronLeft, SvgChevronRight } from '@itwin/itwinui-icons-react';
import {
  Button,
  ButtonGroup,
  Dialog,
  Flex,
  IconButton,
  Text
} from '@itwin/itwinui-react';
import { useAllowDCW } from '../../components/documentCreation';
import { t } from '../../services/translation';
import type { ConflictResolution } from './conflictResolution';

type ConflictResolutionFooterProps = {
  currentIndex: number;
  doForNextChecked: boolean;
  fileCount: number;
  inputError: boolean;
  resolution: ConflictResolution;
  cancelFunction: () => void;
  onFinish: () => void;
  updateConflictIndex: (change: 'increment' | 'decrement') => void;
};

export function ConflictResolutionFooter({
  currentIndex,
  doForNextChecked,
  fileCount,
  inputError,
  resolution,
  cancelFunction,
  onFinish,
  updateConflictIndex
}: ConflictResolutionFooterProps): JSX.Element {
  const usingDCW = useAllowDCW();

  const isLastConflict = currentIndex == fileCount - 1;
  const isFirstConflict = currentIndex == 0;

  function primaryButtonText(): string {
    if (!isLastConflict && !doForNextChecked) {
      return t('Generic.Next');
    }

    if (usingDCW.dcwAllowedByFeatures && resolution.action == 'rename') {
      return t('Generic.Continue');
    }

    return t('Generic.Finish');
  }

  function nextButton() {
    if (isLastConflict || doForNextChecked) {
      onFinish();
    } else {
      updateConflictIndex('increment');
    }
  }

  return (
    <Dialog.ButtonBar>
      {fileCount > 1 && (
        <Flex alignItems="center" style={{ marginRight: 'auto' }}>
          <ButtonGroup>
            <IconButton
              data-testid="prevConflictArrow"
              onClick={() => {
                updateConflictIndex('decrement');
              }}
              label={t('Generic.Previous')}
              disabled={isFirstConflict || doForNextChecked}
            >
              <SvgChevronLeft />
            </IconButton>
            <IconButton
              data-testid="nextConflictArrow"
              onClick={() => {
                updateConflictIndex('increment');
              }}
              label={t('Generic.Next')}
              disabled={isLastConflict || doForNextChecked || inputError}
            >
              <SvgChevronRight />
            </IconButton>
          </ButtonGroup>
          <Text>
            {t('Generic.ShowingCurrentOfTotal', {
              current: currentIndex + 1,
              total: fileCount
            })}
          </Text>
        </Flex>
      )}
      <Button
        disabled={inputError}
        styleType="high-visibility"
        autoFocus={true}
        onClick={nextButton}
        data-testid="next-conflict-button"
      >
        {primaryButtonText()}
      </Button>
      <Button onClick={cancelFunction}>{t('Generic.Cancel')}</Button>
    </Dialog.ButtonBar>
  );
}
