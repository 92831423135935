import type {
  ECPropertyDef,
  HttpService,
  RequestOptions,
  WSGInstance
} from '@bentley/pw-api';
import {
  parseInstanceRelationshipInstances,
  parseResponseInstances,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import type { EnvironmentDefinition } from '../environmentDefinitions/environmentDefinition';

export type Attribute = {
  binding: string;
  environmentClass: string;
  environmentLabel: string;
  isArray?: boolean;
  label: string;
  name: string;
  picklist?: { DisplayLabel?: string; Value: string }[];
  typeName?: string;
};

type AttributeDefinition = {
  Binding: string;
  Label: string;
  Picklist?: AttributePicklistValue[];
} & WSGInstance;

export type AttributePicklistValue = { DisplayLabel?: string; Value: string };

export async function buildAttributeDefinitions(
  environmentDefinition: EnvironmentDefinition,
  includePicklistAttributes: 'includePicklist' | 'excludePicklist',
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<Attribute[]> {
  const attributeDefinitions = await getAttributeDefinitions(
    environmentDefinition.instanceId,
    includePicklistAttributes,
    httpService,
    httpOptions
  );
  if (!attributeDefinitions.length) {
    return [];
  }

  const propertyDefinitions = await getPropertyDefinitions(
    environmentDefinition.instanceId,
    httpService,
    httpOptions
  );

  const attributes = buildAttributes(
    attributeDefinitions,
    propertyDefinitions,
    environmentDefinition
  );
  return attributes;
}

async function getAttributeDefinitions(
  environmentInstanceId: string,
  includePicklistAttributes: 'includePicklist' | 'excludePicklist',
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<AttributeDefinition[]> {
  const query = attributeDefinitionQuery(
    environmentInstanceId,
    includePicklistAttributes
  );
  const response = await httpService.get(query, {
    cacheSuffix: 'persistent',
    ...httpOptions
  });
  const attributeDefinitions = await parseAttributeDefinition(response);

  return uniqueAttributeDefinitionBindings(attributeDefinitions);
}

function attributeDefinitionQuery(
  environmentInstanceId: string,
  includePicklistAttributes: 'includePicklist' | 'excludePicklist'
): string {
  const filter = `EnvironmentClassInterface-backward-MetaSchema.ECClassDef/MetaSchema.CustomAttributeContainerHasCustomAttribute-forward-PW_WSG.ElementIdentifier.ElementId eq ${environmentInstanceId}`;
  const bindingSelect = 'AttributeDefinition!poly.Binding';
  const labelSelect = 'AttributeDefinition!poly.Label';
  const picklistSelect =
    includePicklistAttributes == 'includePicklist'
      ? 'AttributeDefinition!poly.Picklist'
      : '';
  const select = `${bindingSelect},${labelSelect},${picklistSelect}`;

  return `PW_WSG/Interface?$filter=${filter}&$select=${select}`;
}

async function parseAttributeDefinition(
  response: Response
): Promise<AttributeDefinition[]> {
  if (!response.ok) {
    return [];
  }

  const instances = await parseResponseInstances<AttributeDefinition>(response);
  const attributeDefinitions = instances.flatMap(
    parseInstanceRelationshipInstances
  ) as AttributeDefinition[];

  attributeDefinitions.forEach(
    (definition) =>
      // eslint-disable-next-line
      (definition.Picklist = (definition as any).PickList)
  );

  return attributeDefinitions;
}

function uniqueAttributeDefinitionBindings(
  attributeDefinitions: AttributeDefinition[]
): AttributeDefinition[] {
  const uniqueAttributeDefinitions = attributeDefinitions.reduce(
    (uniqueDefinitions, definition) => {
      const existingBinding = uniqueDefinitions.find(
        ({ Binding }) => Binding == definition.Binding
      );

      if (!existingBinding) {
        uniqueDefinitions.push(definition);
      } else {
        existingBinding.Label = existingBinding.Label || definition.Label;
      }
      return uniqueDefinitions;
    },
    [] as AttributeDefinition[]
  );

  return uniqueAttributeDefinitions;
}

async function getPropertyDefinitions(
  environmentInstanceId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<ECPropertyDef[]> {
  const query = propertyDefinitionQuery(environmentInstanceId);
  const response = await httpService.get(query, {
    cacheSuffix: 'persistent',
    ...httpOptions
  });
  const environmentProperties = await parsePropertyDefinitions(response);

  return environmentProperties;
}

function propertyDefinitionQuery(environmentInstanceId: string): string {
  const filter = `MetaSchema.CustomAttributeContainerHasCustomAttribute-forward-PW_WSG.ElementIdentifier.ElementId eq ${environmentInstanceId}`;
  const select = 'ECPropertyDef.TypeName,ECPropertyDef.IsArray';

  return `MetaSchema/ECClassDef?$filter=${filter}&$select=${select}`;
}

async function parsePropertyDefinitions(
  response: Response
): Promise<ECPropertyDef[]> {
  if (!response.ok) {
    console.error('Error retrieving environment properties');
    return [];
  }

  const propertyDefinitions =
    await parseResponseRelationshipInstances<ECPropertyDef>(response);

  return propertyDefinitions;
}

function buildAttributes(
  attributeDefinitions: AttributeDefinition[],
  propertyDefinitions: ECPropertyDef[],
  environmentDefinition: EnvironmentDefinition
): Attribute[] {
  const attributes = attributeDefinitions.map((attributeDefinition) => {
    const attributeName = attributeDefinition.Binding.split('.').pop();

    const propertyDefinition = propertyDefinitions.find(({ instanceId }) => {
      const propertyName = instanceId.split('~3A').pop();
      return propertyName == attributeName;
    });

    const environmentClass =
      attributeDefinition.Binding.split(/(:|\.)/)[2] ??
      environmentDefinition.instanceId;
    const attribute = {
      binding: attributeDefinition.Binding,
      environmentClass: environmentClass,
      environmentLabel: environmentDefinition.Name,
      isArray: propertyDefinition?.IsArray,
      label: attributeDefinition.Label,
      name: attributeName,
      picklist: attributeDefinition.Picklist,
      typeName: propertyDefinition?.TypeName
    } as Attribute;
    return attribute;
  });

  return attributes;
}
