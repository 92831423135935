import { AuthorizationService, HttpService } from '@bentley/pw-api';
import type { GetToken } from '../http';
import type { FederatedRepository } from '../support';
import type {
  DriveSync,
  IFederatedRepositoryApi
} from '../support/federatedRepositoryApi';
import {
  DeleteError,
  ERROR_ACCESS_FORBIDDEN,
  ERROR_CONNECTION_ALREADY_DELETED,
  ERROR_DUPLICATE_REPO,
  ERROR_DUPLICATE_REPO_NAME,
  ERROR_NOTFOUND,
  ERROR_PRIMARY_EXISTS,
  ERROR_PRIMARY_UNDELETABLE,
  ERROR_UNKNOWN,
  PrimaryExistsError
} from '../support/federatedRepositoryApi';
import { webConnectionToFederatedRepo } from '../support/repositoryMapper';

export type WebConnection = {
  id: string;
  canned?: boolean;
  type: string;
  url: string;
  name: string;
  description: string;
  workAreaName: string;
  defaultTab?: string | null;
  legacyId?: string;
  displayLabel: string;
  disabled?: boolean;
};

export function getWebConnectionApi(
  connectionServiceUrl: string,
  getOidcToken: GetToken,
  contextId: string
): IFederatedRepositoryApi {
  const baseUrl = `${connectionServiceUrl}/api/v1/context/${contextId}`;

  const httpService = new HttpService({
    authorization: new AuthorizationService({ getOidcToken }),
    baseUrl
  });

  return {
    getAll: getFederatedRepositories.bind(null, httpService),
    addOne: addFederatedRepository.bind(null, httpService),
    deleteMany: deleteFederatedRepositories.bind(null, httpService),
    updateOne: updateFederatedRepository.bind(null, httpService),
    addSynced: addSyncedUserRepositories.bind(null, httpService),
    deleteSynced: deleteSyncedUserRepositories.bind(null, httpService),
    getSynced: getSyncedUserRepositories.bind(null, httpService),
    upgradeOne: upgradeConnection.bind(null, httpService),
    deleteDriveSynced: deleteDriveSynced.bind(null, httpService),
    addDriveSynced: addDriveSynced.bind(null, httpService),
    getDriveSynced: getDriveSynced.bind(null, httpService),
    setMultiMode: setMultiMode.bind(null, httpService),
    getPrimaryConnection: getPrimaryConnection.bind(null, httpService),
    setPrimaryConnection: setPrimaryConnection.bind(null, httpService),
    unsetPrimaryConnection: unsetPrimaryConnection.bind(null, httpService)
  };
}

async function getFederatedRepositories(
  httpService: HttpService
): Promise<FederatedRepository[]> {
  let totalData: WebConnection[] = [];
  let continuationToken: string | null = '';

  do {
    let requestHeaders = {} as HeadersInit;

    if (continuationToken) {
      requestHeaders = { ContinuationToken: continuationToken } as HeadersInit;
    }

    try {
      const response = await httpService.get('connections', {
        headers: requestHeaders
      });

      if (!response?.ok) {
        throw response;
      }

      const data = (await response.json()) as WebConnection[];
      totalData = totalData.concat(data);
      continuationToken = response.headers.get('ContinuationToken');
    } catch (err) {
      throw new Error('Failed to load federated repositories');
    }
  } while (continuationToken);

  return totalData
    .map(webConnectionToFederatedRepo)
    .filter((repo) => repo.Id && repo.Name && repo.Url);
}

async function addFederatedRepository(
  httpService: HttpService,
  federatedRepository: FederatedRepository,
  isImport?: boolean
): Promise<FederatedRepository> {
  const totalData = await getFederatedRepositories(httpService);

  if (
    totalData.filter(
      (a) => a.Name.toLowerCase() == federatedRepository.Name.toLowerCase()
    ).length > 0
  ) {
    throw new Error(ERROR_DUPLICATE_REPO_NAME);
  }

  const url = isImport ? 'connections/import' : 'connections';

  const response = await httpService.post(
    url,
    JSON.stringify({
      url: federatedRepository.Url,
      name: federatedRepository.Name,
      description: federatedRepository.Description,
      displayLabel: federatedRepository.DisplayLabel,
      workAreaName: federatedRepository.WorkAreaName,
      legacyId: federatedRepository.LegacyId,
      canned: federatedRepository.Canned,
      defaultTab: federatedRepository.DefaultTab
    } as WebConnection)
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status === 409) {
    throw new Error(ERROR_DUPLICATE_REPO);
  }

  if (response.status === 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    console.error('addFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as WebConnection;
  return webConnectionToFederatedRepo(data);
}

async function deleteFederatedRepositories(
  httpService: HttpService,
  repositoryIds: string[]
): Promise<void> {
  const deleted = new Array<string>();

  for (const repoId of repositoryIds) {
    try {
      await deleteFederatedRepository(httpService, repoId);
    } catch (err) {
      throw new DeleteError((err as Error).message, deleted);
    }
    deleted.push(repoId);
  }
}

async function deleteFederatedRepository(
  httpService: HttpService,
  repositoryId: string
): Promise<void> {
  const url = `connections/${repositoryId}`;
  const response = await httpService.delete(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 404) {
    throw new Error(ERROR_CONNECTION_ALREADY_DELETED);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 400) {
    throw new Error(ERROR_PRIMARY_UNDELETABLE);
  }

  if (!response.ok) {
    console.error('deleteFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }
}

async function updateFederatedRepository(
  httpService: HttpService,
  federatedRepository: FederatedRepository
): Promise<FederatedRepository> {
  const url = `connections/${federatedRepository.Id}`;

  const response = await httpService.put(
    url,
    JSON.stringify({
      name: federatedRepository.Name,
      description: federatedRepository.Description,
      displayLabel: federatedRepository.DisplayLabel,
      defaultTab: federatedRepository.DefaultTab,
      disabled: federatedRepository.Disabled
    } as WebConnection)
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    console.error('updateFederatedRepository failed: ', response);
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as WebConnection;
  return webConnectionToFederatedRepo(data);
}

async function addSyncedUserRepositories(
  httpService: HttpService,
  repositoryId: string
): Promise<void> {
  const url = `user-sync/${repositoryId}`;
  const response = await httpService.post(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function deleteSyncedUserRepositories(
  httpService: HttpService,
  repositoryIds: string[]
) {
  for (const repoId of repositoryIds) {
    await deleteSyncedUserRepository(httpService, repoId);
  }
}

async function deleteSyncedUserRepository(
  httpService: HttpService,
  repositoryId: string
) {
  const url = `user-sync/${repositoryId}`;
  const response = await httpService.delete(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function getSyncedUserRepositories(
  httpService: HttpService
): Promise<string[]> {
  const response = await httpService.get('user-sync');

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 404) {
    return [];
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as SyncCollection;
  return data.connections;
}

type SyncCollection = {
  connections: string[];
};

async function addDriveSynced(
  httpService: HttpService,
  repositoryId: string,
  backfill?: boolean
): Promise<void> {
  const backfillContext = backfill ? 'backfill/' : '';
  const url = `drive-sync/${backfillContext}${repositoryId}`;
  const response = await httpService.post(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function deleteDriveSynced(
  httpService: HttpService,
  repositoryId: string
): Promise<void> {
  const url = `drive-sync/${repositoryId}`;
  const response = await httpService.delete(url, null);

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 404) {
    throw new Error(ERROR_NOTFOUND);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function getDriveSynced(
  httpService: HttpService
): Promise<DriveSync | null> {
  const response = await httpService.get('drive-sync');

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 404) {
    return null;
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as DriveSync;
  return data;
}

async function upgradeConnection(
  httpService: HttpService,
  repositoryId: string,
  upgradeVersion: number
): Promise<FederatedRepository> {
  const url = `connections/${repositoryId}/upgrade`;

  const response = await httpService.post(
    url,
    JSON.stringify({
      upgradeVersion
    })
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 404) {
    throw new Error(ERROR_NOTFOUND);
  }

  if (response.status == 400 || !response.status) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as WebConnection;
  return webConnectionToFederatedRepo(data);
}

async function setMultiMode(
  httpService: HttpService,
  multiMode: boolean
): Promise<void> {
  const response = await httpService.put(
    `drive-sync/multimode/${String(multiMode)}`,
    null
  );

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403 || response.status == 404) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function getPrimaryConnection(httpService: HttpService): Promise<string> {
  const response = await httpService.get('primary-connection');

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 404) {
    return '';
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }

  const data = (await response.json()) as WebConnection;
  return data.id;
}

async function setPrimaryConnection(
  httpService: HttpService,
  connectionId: string,
  primary: boolean
): Promise<void> {
  const request = primary
    ? httpService.post(`primary-connection/${connectionId}`, null)
    : httpService.delete(`primary-connection`, null);

  const response = await request;

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403 || response.status == 404) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (response.status == 409) {
    if (response.body) {
      const data = (await response.json()) as { contextId?: string };
      throw new PrimaryExistsError(ERROR_PRIMARY_EXISTS, data.contextId);
    }
    throw new PrimaryExistsError(ERROR_PRIMARY_EXISTS, undefined);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }
}

async function unsetPrimaryConnection(httpService: HttpService): Promise<void> {
  const request = httpService.delete(`primary-connection`, null);
  const response = await request;

  if (!response) {
    throw new Error(ERROR_UNKNOWN);
  }

  if (response.status == 403) {
    throw new Error(ERROR_ACCESS_FORBIDDEN);
  }

  if (!response.ok) {
    throw new Error(ERROR_UNKNOWN);
  }

  return;
}
