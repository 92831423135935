import type { PWItem, PWProject } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { Connection } from '../../services/support/connection';
import {
  getItemType,
  getUniversalLink
} from '../getShareableLink/getShareableLink';

// Todo: add unit tests for this file
export function goToConnectedProject(
  item: PWProject,
  connection: Connection,
  pwWacUrl: string
): void {
  const itemType = getItemType(item.className);

  const url = getUniversalLink({
    datasource: connection.DatasourceInstanceId,
    workAreaId: item.instanceId,
    objectId: item.instanceId,
    objectType: itemType,
    projectId: item.ConnectedProjectGuid,
    application: 'Web',
    apiURL: pwWacUrl
  });

  window.open(url);
}

export function allowGoToConnectedProject(items: PWItem[]): boolean {
  if (items?.length != 1) {
    return false;
  }

  const item = items[0];
  if (!itemIsProject(item)) {
    return false;
  }

  if (!item.ConnectedProjectGuid) {
    return false;
  }

  return true;
}
