import './driveSyncDialog.css';

import React from 'react';
import { Text } from '@itwin/itwinui-react';
import type { ModalElement } from '../../hooks/useModal';
import type { FederatedRepository } from '../../services/support';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';

export type DriveSyncFeature = 'Disabled' | 'Single' | 'Multi';

export function openSingleDriveSyncDialog(
  repositoryToDisableSync: FederatedRepository,
  driveSyncFeature: DriveSyncFeature,
  driveSyncMultiMode: boolean,
  openModal: (modal: JSX.Element | null) => void
): Promise<DialogResponse> {
  const promise = new Promise<DialogResponse>((resolve) => {
    openModal(
      <DriveDialogModal
        repositoryToDisableSync={repositoryToDisableSync}
        driveSyncFeature={driveSyncFeature}
        driveSyncMultiMode={driveSyncMultiMode}
        resolve={resolve}
      />
    );
  });

  return promise;
}

type DriveDialogProps = {
  repositoryToDisableSync: FederatedRepository;
  driveSyncFeature: DriveSyncFeature;
  driveSyncMultiMode: boolean;
  resolve: (response: DialogResponse) => void;
};

function DriveDialogModal({
  repositoryToDisableSync,
  driveSyncFeature,
  driveSyncMultiMode,
  resolve
}: DriveDialogProps): ModalElement {
  return (
    <PWModal
      title={t('ManageConnections.OverrideDrive')}
      primaryButton={{
        title: t('Generic.Continue'),
        onClick: () => resolve('OK')
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: () => resolve('CANCEL')
      }}
      onClose={() => resolve('CANCEL')}
      dialogProps={{ 'data-testid': 'SwitchDriveSyncModal' }}
    >
      {driveSyncFeature == 'Multi' && !driveSyncMultiMode ? (
        <Text>
          {t('ManageConnections.DriveDisabledForMultiMode', {
            name: repositoryToDisableSync.Name
          })}
        </Text>
      ) : (
        <>
          <Text className="drive-switch-desc">
            {t('ManageConnections.ChangeDriveConnectionRule')}{' '}
            {t('ManageConnections.ChangeDriveConnectionAction', {
              name: repositoryToDisableSync.Name
            })}
          </Text>
          <Text>{t('ManageConnections.Confirmation')}</Text>
        </>
      )}
    </PWModal>
  );
}

type DriveConfirmationProps = {
  resolve: (response: DialogResponse) => void;
};

function DriveConfirmationModal({
  resolve
}: DriveConfirmationProps): ModalElement {
  return (
    <PWModal
      title="Confirm ProjectWise Drive Mode change"
      primaryButton={{
        title: t('Generic.Continue'),
        onClick: () => resolve('OK')
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: () => resolve('CANCEL')
      }}
      onClose={() => resolve('CANCEL')}
      dialogProps={{ 'data-testid': 'SwitchDriveSyncModal' }}
    >
      <Text>{t('ManageConnections.DriveModeChange')}</Text>
    </PWModal>
  );
}

export function confirmDriveModeChange(
  openModal: (modal: JSX.Element | null) => void
): Promise<DialogResponse> {
  const promise = new Promise<DialogResponse>((resolve) => {
    openModal(<DriveConfirmationModal resolve={resolve} />);
  });

  return promise;
}

export type DialogResponse = 'OK' | 'CANCEL';
