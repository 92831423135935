import React from 'react';
import { Text } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../context';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';

export function AccessibilityInfoModal(): JSX.Element {
  const { primaryModal } = useNavigationContext();

  return (
    <PWModal
      title={t('Generic.AdditionalConfigurationRequired')}
      secondaryButton={{
        title: t('Generic.Close'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
    >
      <Text>{t('AccessibilityBanner.ModalInfo')}</Text>
    </PWModal>
  );
}
