import './pwTable.css';

import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { Table } from '@itwin/itwinui-react';
import { useTableColumnContext, useTableItemContext } from '../../context';
import { EmptyTableState } from '../emptyState';
import { EmptyFilterState } from '../emptyState/emptyFilterState';
import { useRowActions } from './actions/useRowActions';
import { useTableState } from './state/useTableState';

export function PWTable(): JSX.Element {
  const {
    dataManager,
    selectedState: { selectedIds }
  } = useTableItemContext();
  const { columns } = useTableColumnContext();

  const { controlledState, stateReducer } = useTableState();
  const rowProps = useRowActions();

  return (
    <Table<PWItem>
      columns={columns}
      data={dataManager.items}
      getRowId={(row: PWItem) => row.instanceId}
      stateReducer={stateReducer}
      useControlledState={controlledState}
      scrollToRow={(rows) =>
        rows?.findIndex(({ original }) => original.instanceId == selectedIds[0])
      }
      enableVirtualization={true}
      enableColumnReordering={true}
      isResizable={true}
      isSelectable={true}
      isSortable={true}
      rowProps={rowProps}
      className="pw-table"
      columnResizeMode="expand"
      density="extra-condensed"
      emptyTableContent={<EmptyTableState />}
      emptyFilteredTableContent={<EmptyFilterState />}
    />
  );
}
