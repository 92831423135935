import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import {
  AuthorizationService,
  CacheService,
  HttpService
} from '@bentley/pw-api';
import { unauthorizedHttpService } from '../../../services/http';
import { useConnectionAuth } from '../connectionAuth';
import { usePluginContext } from '../plugin/pluginContext';
import { useToken } from '../token';

const HttpServiceContext = createContext<HttpService | undefined>(undefined);

type HttpServiceProps = {
  tokenType?: 'SAML' | 'OIDC';
  baseWsgUrl?: string;
};

export function HttpServiceProvider({
  value,
  ...props
}: ProviderProps<HttpServiceProps>): JSX.Element {
  const { authorizationService, connectionAuthenticated } = useConnectionAuth();
  const { connection } = usePluginContext();

  const httpService = useMemo((): HttpService => {
    if (connectionAuthenticated != 'authenticated' || !authorizationService) {
      return unauthorizedHttpService(connection.ConnectionUrl);
    }

    return new HttpService({
      authorization: authorizationService,
      baseUrl: connection.ConnectionUrl,
      cache: new CacheService({ name: connection.Name })
    });
  }, [
    authorizationService,
    connectionAuthenticated,
    connection.ConnectionUrl,
    connection.Name
  ]);

  return <HttpServiceContext.Provider value={httpService} {...props} />;
}

export function PlainHttpServiceProvider({
  value,
  ...props
}: ProviderProps<HttpServiceProps>): JSX.Element {
  const { getOidcToken, getSamlToken } = useToken();

  if (!value.baseWsgUrl) {
    throw new Error('baseWsgUrl is required');
  }

  const httpService = useMemo(() => {
    const authorizationMethod =
      value.tokenType == 'OIDC' ? { getOidcToken } : { getSamlToken };

    const httpService = new HttpService({
      authorization: new AuthorizationService(authorizationMethod),
      baseUrl: value.baseWsgUrl ?? '',
      cache: new CacheService({ name: value.baseWsgUrl ?? '' })
    });

    return httpService;
  }, [getOidcToken, getSamlToken, value.baseWsgUrl, value.tokenType]);

  return <HttpServiceContext.Provider value={httpService} {...props} />;
}

export function useHttpService(): HttpService {
  const context = useContext(HttpServiceContext);
  if (context === undefined) {
    throw new Error(`useHttpService must be used within a HttpServiceProvider`);
  }
  return context;
}
