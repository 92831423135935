import React, { useCallback } from 'react';
import { searchTimeout } from '../../constants';
import { useNavigationContext } from '../../context';
import { SearchTimeoutModal } from './searchTimeoutModal';

type ScheduleSearchTimeoutFunction = (
  abortController?: AbortController
) => CancelSearchTimeoutFunction;
type CancelSearchTimeoutFunction = () => void;

export function useSearchTimeout(): ScheduleSearchTimeoutFunction {
  const {
    primaryModal: { open: openModal, close: closeModal }
  } = useNavigationContext();

  const scheduleSearchTimeout = useCallback(
    (abortController?: AbortController): CancelSearchTimeoutFunction => {
      const modal = React.createElement(SearchTimeoutModal, {
        abortController: abortController
      });
      let modalOpened = false;

      const timeoutId = setTimeout(() => {
        openModal(modal);
        modalOpened = true;
      }, searchTimeout);

      return () => {
        clearTimeout(timeoutId);
        if (modalOpened) {
          // Only close modal if it is search timeout modal
          closeModal();
        }
      };
    },
    [closeModal, openModal]
  );

  return scheduleSearchTimeout;
}
