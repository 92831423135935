import React from 'react';
import { Alert } from '@itwin/itwinui-react';
import {
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { useLocalStorage } from '../../hooks/useStorage';
import { t } from '../../services/translation';
import { AccessibilityInfoModal } from './accessibilityInfoModal';

export function AccessibilityBanner(): JSX.Element | null {
  const { connection } = usePluginContext();
  const { azureServerAccessible } = useEnvironmentContext();
  const { primaryModal } = useNavigationContext();

  const [bannerDismissed, setBannerDismissed] = useLocalStorage(
    `publicAccessBannerDismissed:${connection.Id}`,
    false
  );

  const warningMessage = t('AccessibilityBanner.Message');

  function closeBanner(): void {
    setBannerDismissed(true);
  }

  function onMoreInfoClick(): void {
    primaryModal.open(<AccessibilityInfoModal />);
  }

  if (azureServerAccessible || bannerDismissed) {
    return null;
  }

  return (
    <Alert.Wrapper type="warning" className="main-page-alert">
      <Alert.Icon />
      <Alert.Message>
        {warningMessage}
        <Alert.Action onClick={onMoreInfoClick}>
          {t('InfoTooltip.MoreInfo')}
        </Alert.Action>
      </Alert.Message>
      <Alert.CloseButton onClick={closeBanner} />
    </Alert.Wrapper>
  );
}
