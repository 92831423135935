import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { Feature } from '@bentley/pw-config';
import { useFeatures } from '@bentley/pw-config';
import { LoadingPage } from '../../../../../packages/projectwise/src/components/loadingPage';
import { ProjectWiseErrorPage } from '../../../../../packages/projectwise/src/components/projectWise';
import { t } from '../../services/translation';
import { useConfigContext } from '../config/configContext';
import { useOidcContext } from '../oidc/oidcContext';

const Context = createContext<FeatureContext | undefined>(undefined);

type FeatureContext = {
  features: Feature[];
};

export function FeatureProvider({
  children
}: ProviderProps<undefined>): JSX.Element | null {
  const { config } = useConfigContext();
  const { getSamlToken } = useOidcContext();

  const { features, error } = useFeatures({
    featureToggleKey: config.FeatureToggleClientKey,
    getSamlToken
  });

  const featureContext: FeatureContext = useMemo(
    (): FeatureContext => ({
      features: features ?? []
    }),
    [features]
  );

  if (error) {
    return <ProjectWiseErrorPage error={error} />;
  }

  if (!features) {
    return (
      <LoadingPage
        loadingSubText={t('LoadingPage.RetrievingConfigurationSettings')}
        fromValue={50}
        toValue={75}
      />
    );
  }

  return <Context.Provider value={featureContext}>{children}</Context.Provider>;
}

export function useFeatureContext(): FeatureContext {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useFeatureContext must be used within a FeatureProvider');
  }

  return context;
}
