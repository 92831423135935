import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyRenditionInProgress(): ToastHandle {
  return openToast({
    content: t('CreateRendition.SubmittingJob')
  });
}

export function notifyRenditionSubmitted(
  toastHandle: ToastHandle,
  errorMessage?: string
): void {
  if (!errorMessage) {
    notifySuccess(toastHandle);
  } else {
    notifyError(toastHandle, errorMessage);
  }
}

function notifyError(toastHandle: ToastHandle, errorMessage: string): void {
  replaceToast(toastHandle, { content: errorMessage, category: 'negative' });
}

function notifySuccess(toastHandle: ToastHandle): void {
  const toasterMsg = t('CreateRendition.Success');

  replaceToast(toastHandle, { content: toasterMsg, category: 'positive' });
}
