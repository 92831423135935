type MonitoredKeys = {
  Enter?: boolean;
  Space?: boolean;
} & { [key: string]: boolean };

/**
 * Converts a keyboard action to a click action
 * @example
 * <div
 *   onClick={myOnClickHandler}
 *   onKeyPress={keyPressAsClick(myOnClickHandler, {Enter: true, Space: true, 'x', true})}
 * />
 * @param {(e?: React.SyntheticEvent) => void} action Action to call on click, will receive event from keyboard
 * @param {MonitoredKeys} [monitoredKeys] Optional keys to accept as click, defaults to Enter only
 * @returns {(e: React.KeyboardEvent) => any} Keyboard listener
 */
export function keyPressAsClick(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
  action: (e: React.SyntheticEvent & any) => void,
  monitoredKeys: MonitoredKeys = { Enter: true }
): (e: React.KeyboardEvent) => void {
  return (e: React.KeyboardEvent) => {
    const capturedKey = e.key == ' ' ? 'Space' : e.key;
    if (monitoredKeys[capturedKey]) {
      return action(e);
    }
  };
}
