import { useCallback, useMemo } from 'react';
import type {
  PWDataItem,
  PWItem,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { byDefaultOrder, parseResponseInstances } from '@bentley/pw-api';
import { useHttpService, useNavigationContext } from '../../../context';
import { getEnvironmentSelectString } from '../../useEnvironment';
import { formatFTRQuery } from './formatFTRQuery';

type FTRSearchFunction = (
  httpOptions?: RequestOptions | undefined
) => Promise<PWItem[]>;

export function useFTRSearch(): FTRSearchFunction {
  const httpService = useHttpService();
  const {
    navigationManager: { currentParent },
    searchState: { basicQuery }
  } = useNavigationContext();

  const invalidQuery = useMemo((): boolean => {
    if (basicQuery == '*') {
      return true;
    }

    if (
      basicQuery.includes("'") ||
      basicQuery.includes('_') ||
      basicQuery.includes('[')
    ) {
      return true;
    }

    return false;
  }, [basicQuery]);

  const apiAncestorQuery = useMemo((): string => {
    if (!currentParent.instanceId) {
      return '';
    }

    return `api.ancestor=PW_WSG.Project-${currentParent.instanceId}&`;
  }, [currentParent.instanceId]);

  const projectSearchUrl = useCallback((): string => {
    const searchString = formatFTRQuery(basicQuery);
    const filter = `SimpleSearchResult-backward-FullTextSearch.Query eq '${searchString}'`;
    const environmentSelect = getEnvironmentSelectString('Project');
    const select = `*,${environmentSelect},ProjectParent-forward-Project.*`;
    return `PW_WSG/Project!poly?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
  }, [apiAncestorQuery, basicQuery]);

  const projectSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWProject[]> => {
      const url = projectSearchUrl();
      const response = await httpService.get(url, httpOptions);
      const projects = await parseResponseInstances<PWProject>(response);
      const nonRootProjects = projects.filter(
        (project) => project.instanceId != currentParent.instanceId
      );
      return nonRootProjects;
    },
    [currentParent.instanceId, httpService, projectSearchUrl]
  );

  const documentSearchUrl = useCallback((): string => {
    const searchString = formatFTRQuery(basicQuery);
    const filter = `SimpleSearchResult-backward-FullTextSearch.Query eq '${searchString}'`;
    const environmentSelect = getEnvironmentSelectString('Document');
    const select = `*,${environmentSelect},DocumentParent-forward-Project.*`;
    return `PW_WSG/Document!poly?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
  }, [apiAncestorQuery, basicQuery]);

  const documentSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWDataItem[]> => {
      const url = documentSearchUrl();
      const response = await httpService.get(url, httpOptions);
      const documents = await parseResponseInstances<PWDataItem>(response);
      return documents;
    },
    [documentSearchUrl, httpService]
  );

  const ftrSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWItem[]> => {
      if (invalidQuery) {
        return [];
      }

      const [projects, documents] = await Promise.all([
        projectSearch(httpOptions),
        documentSearch(httpOptions)
      ]);
      const items = [...projects, ...documents];
      items.sort(byDefaultOrder);
      return items;
    },
    [documentSearch, invalidQuery, projectSearch]
  );

  return ftrSearch;
}
