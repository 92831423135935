import type { HttpService, PWProject } from '@bentley/pw-api';
import { parseResponseInstances } from '@bentley/pw-api';
import type { DataAccessLevel } from '../../../context';
import { parseECVersionHeaders } from '../../../hooks/useECPluginVersion';
import type { Connection } from '../../../services/support';

export type ConnectionStatus =
  | 'accessible'
  | 'unauthorized'
  | 'notFound'
  | 'unreachable';

export type ConnectionAssociationStatus =
  | 'CorrectAssociation'
  | 'IncorrectAssociation'
  | 'NoAssociation'
  | 'NotApplicable'
  | 'InvalidECPlugin';

export async function getConnectionStatus(
  dataAccessLevel: DataAccessLevel,
  connection: Connection,
  httpService: HttpService,
  isPrimaryConnection: boolean,
  contextId: string
): Promise<{
  connectionStatus: ConnectionStatus;
  associationStatus: ConnectionAssociationStatus;
}> {
  try {
    const url =
      dataAccessLevel == 'WorkArea'
        ? `PW_WSG/Project/${connection.ProjectId}`
        : `PW_WSG/Project?$filter=ParentGuid eq null&$select=$id`;

    const response = await httpService.get(url);
    const status = getStatusFromResponse(response);
    const pluginVersion = parseECVersionHeaders(response);
    let associationStatus: ConnectionAssociationStatus = 'NotApplicable';

    if (
      !connection.Canned &&
      isPrimaryConnection &&
      pluginVersion &&
      pluginVersion >= 3.449 &&
      dataAccessLevel == 'WorkArea'
    ) {
      associationStatus = await getAssociationStatusFromResponse(
        response,
        contextId
      );
    } else if (!pluginVersion || pluginVersion < 3.449) {
      associationStatus = 'InvalidECPlugin';
    }

    return {
      connectionStatus: status,
      associationStatus: associationStatus
    };
  } catch {
    return {
      connectionStatus: 'unreachable',
      associationStatus: 'NotApplicable'
    };
  }
}

function getStatusFromResponse(response: Response): ConnectionStatus {
  if (response.status == 200) {
    return 'accessible';
  }

  if (
    response.status == 401 ||
    response.status == 403 ||
    response.status == 429
  ) {
    return 'unauthorized';
  }

  if (response.status == 404) {
    return 'notFound';
  }

  return 'unreachable';
}

async function getAssociationStatusFromResponse(
  response: Response,
  contextId: string
): Promise<ConnectionAssociationStatus> {
  const workAreaData = await parseResponseInstances<PWProject>(response);

  if (!workAreaData?.length) {
    return 'NotApplicable';
  }

  const workArea = workAreaData[0];
  const projectAssociation = workArea.ConnectedProjectGuid;

  if (projectAssociation == undefined) {
    return 'NoAssociation';
  }

  if (projectAssociation == contextId) {
    return 'CorrectAssociation';
  }

  return 'IncorrectAssociation';
}
