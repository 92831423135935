import React from 'react';
import { FormContainer } from '../../../actions/formRendering';
import { LoadingPage } from '../../loadingPage';
import { useDocCodeForm } from './useDocCodeForm';

export function DocCodeFormContainer(): JSX.Element {
  const formJQuery = useDocCodeForm();

  if (!formJQuery) {
    return <LoadingPage />;
  }

  return <FormContainer formJQuery={formJQuery} formType="documentcode" />;
}
