import type { PWDataItem } from '@bentley/pw-api';

const basicDocumentProperties = [
  'Name',
  'Version',
  'Description',
  'FileName'
] as (keyof PWDataItem)[];

export function filterSetItems(item: PWDataItem, query: string): boolean {
  query = query.toLowerCase();

  if (query == '*') {
    return true;
  }

  if (query.includes('*')) {
    const regex = formatWildcardQuery(query.toLowerCase());

    return basicDocumentProperties.some((property) =>
      regex.exec(new String(item[property]).toLowerCase())
    );
  }

  return basicDocumentProperties.some((property) =>
    new String(item[property]).toLowerCase().includes(query)
  );
}

function formatWildcardQuery(query: string): RegExp {
  query = query.replace(/[.+?^${}()|[\]\\]/g, '\\$&');
  query = query.replace(/\*/g, '.+');
  return new RegExp(query);
}
