import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { t } from '../../../../services/translation';
import { useCancelWizard } from '../../cancel';
import {
  uploadInProgressState,
  useDocumentCreation,
  useFlatSetCreation
} from '../../create';
import { useCopy } from '../../create/useCopy';
import {
  updatingEnvironmentInterfacesState,
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from '../../form';
import { CreatingDocument } from '../creatingDocument';
import { NavigationButtons } from '../navigationButtons';
import {
  currentStepIndexState,
  isCopyModeState,
  isFlatSetCreationModeState
} from '../state';
import { AttributesSection } from './attributesSection';
import { attributesFormValidState } from './state';

export function AttributesStep(): JSX.Element {
  const { create } = useDocumentCreation();
  const { create: createFlatSet } = useFlatSetCreation();
  const { copyDocuments } = useCopy();
  const { cancelWizard } = useCancelWizard();

  const setCurrentStepIndex = useSetRecoilState(currentStepIndexState);
  const attributesFormValid = useRecoilValue(attributesFormValidState);
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );
  const uploadInProgress = useRecoilValue(uploadInProgressState);
  const updatingEnvironmentInterfaces = useRecoilValue(
    updatingEnvironmentInterfacesState
  );

  const isFlatSetCreationMode = useRecoilValue(isFlatSetCreationModeState);
  const isCopyMode = useRecoilValue(isCopyModeState);

  const actionButtonTitle = isCopyMode
    ? t('Generic.Copy')
    : t('Generic.Create');

  function allowBack(): boolean {
    if (
      updatingTriggeredProperties ||
      updatingReloadProperties ||
      uploadInProgress
    ) {
      return false;
    }
    return true;
  }

  function onBack(): void {
    setCurrentStepIndex((cur) => cur - 1);
  }

  function allowCreate(): boolean {
    if (!attributesFormValid) {
      return false;
    }
    if (updatingEnvironmentInterfaces) {
      return false;
    }
    if (
      updatingTriggeredProperties ||
      updatingReloadProperties ||
      uploadInProgress
    ) {
      return false;
    }
    return true;
  }

  function onCreate(): void {
    if (isFlatSetCreationMode) {
      void createFlatSet();
    } else if (isCopyMode) {
      void copyDocuments();
    } else {
      void create();
    }
  }

  if (uploadInProgress) {
    return <CreatingDocument />;
  }

  return (
    <>
      <AttributesSection />
      <NavigationButtons
        backButton={{
          children: t('Generic.Back'),
          onClick: onBack,
          disabled: !allowBack()
        }}
        createButton={{
          children: actionButtonTitle,
          onClick: onCreate,
          disabled: !allowCreate(),
          styleType: 'cta'
        }}
        cancelButton={{ children: t('Generic.Cancel'), onClick: cancelWizard }}
      />
    </>
  );
}
