import type { User } from 'oidc-client';
import { useCallback, useEffect, useState } from 'react';
import { useOidcContext } from '../../context';
import { refreshInterval } from './refreshInterval';
import { useSamlAuthentication } from './useSamlAuthentication';

type AuthenticationManager = {
  user?: User | null;
  samlUser?: User | null;
};

type UseAuthenticationOptions = {
  allowUnauthenticated?: boolean;
};

export function useAuthentication({
  allowUnauthenticated = false
}: UseAuthenticationOptions = {}): AuthenticationManager {
  const { userManager } = useOidcContext();

  const [forceRefreshSaml, setForceRefreshSaml] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>();

  const samlUser = useSamlAuthentication(user, forceRefreshSaml);

  const getUser = useCallback(async () => {
    try {
      const user = await userManager.getUser();
      return user ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [userManager]);

  useEffect(() => {
    userManager.events.addAccessTokenExpired(() => {
      void userManager.removeUser();
      setUser(null);
    });

    userManager.events.addSilentRenewError((e) => {
      console.error('Silent renew error', e.message);
    });
  }, [userManager]);

  useEffect(() => {
    async function fetchUser(): Promise<void> {
      setForceRefreshSaml(false);
      const user = await getUser();
      setUser(user);
    }

    void fetchUser();
  }, [getUser]);

  useEffect(() => {
    async function signInUser(): Promise<void> {
      try {
        const restoredUser = await userManager.signinSilent();
        if (restoredUser) {
          setUser(restoredUser);
          return;
        }
      } catch {
        // allow to continue
      }

      if (allowUnauthenticated) {
        return;
      }

      let state = window.location.pathname + window.location.search;
      if (state.startsWith('/')) {
        state = state.replace('/', '');
      }

      await userManager.signinRedirect({ state: state });
    }

    if (!user) {
      void signInUser();
    }
  }, [allowUnauthenticated, user, userManager]);

  useEffect(() => {
    function tokenRefresh(user: User) {
      const refreshTime = refreshInterval(user);

      const timeoutId = setTimeout(() => {
        void refreshUser();
      }, refreshTime);

      return timeoutId;
    }

    async function refreshUser() {
      setForceRefreshSaml(true);
      const user = await userManager.signinSilent();
      setUser(user);
    }

    if (!user) {
      return;
    }

    const refreshTimeoutId = tokenRefresh(user);

    return () => {
      clearTimeout(refreshTimeoutId);
    };
  }, [user, userManager]);

  return {
    user,
    samlUser
  };
}
