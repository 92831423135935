import type { PWItem } from '@bentley/pw-api';
import { itemIsLogicalSet } from '@bentley/pw-api';

export function allowCreateCopyAll(
  items: PWItem[],
  isMultipleItemExistsInConnection?: (items: PWItem[]) => boolean
): boolean {
  if (!items || !items.length) {
    return false;
  }

  if (!items.every((item) => allowCreateCopy(item))) {
    return false;
  }

  if (!allItemsSameClass(items)) {
    return false;
  }

  if (
    isMultipleItemExistsInConnection &&
    !isMultipleItemExistsInConnection(items)
  ) {
    return false;
  }

  return true;
}

function allowCreateCopy(item: PWItem): boolean {
  if (!item || !item.className || !item.instanceId) {
    return false;
  }

  if (itemIsLogicalSet(item)) {
    return false;
  }
  return true;
}

function allItemsSameClass(items: PWItem[]): boolean {
  if (items.length == 0) {
    return true;
  }

  return items.every((item) => item.className == items[0].className);
}
