import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { usePluginContext } from '../../../context';
import type { ECPluginVersion } from '../../../hooks/useECPluginVersion';
import { pluginVersionStorageKey } from '../../../hooks/useECPluginVersion/sessionStorage';
import { t } from '../../../services/translation';
import { LoadingPage } from '../../loadingPage';

type GatewayLoaderProps = {
  children: ReactNode;
};

// Fixes log in bug in older EC Plugin versions
// Task 979885: Load rest of app while performing gateway reset
export function GatewayLoader({ children }: GatewayLoaderProps): JSX.Element {
  const { connection } = usePluginContext();

  const [gatewayLoaded, setGatewayLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function resetGateway(): Promise<void> {
      const cacheKey = `${pluginVersionStorageKey}:${connection.Id}`;
      const storedInfo = localStorage.getItem(cacheKey) ?? 'null';
      const ecPluginVersion = JSON.parse(storedInfo) as ECPluginVersion;

      if (ecPluginVersion && ecPluginVersion >= 3.436) {
        setGatewayLoaded(true);
        return;
      }

      if (!connection.ServerUrl) {
        return;
      }

      setGatewayLoaded(false);

      let serverUrl = connection.ServerUrl;
      if (!serverUrl.endsWith('/')) {
        serverUrl = `${serverUrl}/`;
      }

      await fetch(`${serverUrl}repositories`);

      setGatewayLoaded(true);
    }

    void resetGateway();
  }, [connection.Id, connection.ServerUrl, setGatewayLoaded]);

  if (!gatewayLoaded) {
    return (
      <LoadingPage
        loadingSubText={t('LoadingPage.LoadingConnection', {
          connectionName: connection.Name
        })}
        fromValue={25}
        toValue={50}
      />
    );
  }

  return <>{children}</>;
}
