import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import {
  filterDataItems,
  itemFileExtension,
  parseResponseInstances
} from '@bentley/pw-api';

export async function findMatchingPdfFiles(
  items: PWItem[],
  httpService: HttpService
): Promise<PWDataItem[]> {
  const files = filterDataItems(items);
  const xfdfFiles = files.filter((file) => itemFileExtension(file) === 'xfdf');
  const pdfFiles = xfdfFiles.map((xfdf) =>
    xfdf.FileName.replace('.xfdf', '.pdf')
  );
  if (pdfFiles.length === 0) {
    return [];
  }

  try {
    const searchInFolders = Array.from(
      new Set(xfdfFiles.map((xfdf) => xfdf.ParentGuid))
    );
    const url = `PW_WSG/Document/?$filter=ParentGuid+in+['${searchInFolders.join(
      "','"
    )}']+and+FileName+in+['${pdfFiles.join("','")}']`;
    const response = await httpService.get(url);
    const matchingFiles = await parseResponseInstances<PWDataItem>(response);
    return matchingFiles;
  } catch {
    return [];
  }
}
