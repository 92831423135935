import type { PWParentType } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';

/**
 * Determines if uploading files should be enabled or not
 * @param {PWParentType} parent Parent of items in the grid
 * @param {AppView} appView Current search setting in the grid
 * @param {boolean} readOnly ReadOnly setting from config
 * @returns {boolean} If uploading files is allowed
 */
export function allowUploadFiles(
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): boolean {
  if (
    !parent.instanceId ||
    itemIsFlatSet(parent) ||
    appView != 'FolderNavigation' ||
    readOnly
  ) {
    return false;
  }
  return true;
}

export function allowCreateNewFolder(
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): boolean {
  if (
    !parent.instanceId ||
    itemIsFlatSet(parent) ||
    appView != 'FolderNavigation' ||
    readOnly
  ) {
    return false;
  }

  return true;
}
