import { useMemo } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import { t } from '../../../services/translation';
import { MasterModelCell } from './cells/masterModelCell';
import { MasterModelHeader } from './cells/masterModelHeader';
import { NameCell } from './cells/nameCell';
import { SheetCell } from './cells/sheetCell';
import { SheetHeader } from './cells/sheetHeader';
import type { MappingDataRecord } from './iModelMapTable';

type IModelColumnsState = {
  allMasterChecked?: boolean;
  allSheetChecked?: boolean;
  notEnoughMappings?: boolean;
  hasExistingBridges?: boolean;
  tableDisabled?: boolean;
  onMasterCellClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) => void;
  onMasterHeaderClick?: React.ChangeEventHandler<HTMLElement>;
  onSheetCellClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) => void;
  onSheetHeaderClick?: React.ChangeEventHandler<HTMLInputElement>;
};

export function useIModelColumns(
  state: IModelColumnsState
): Column<MappingDataRecord>[] {
  const columns = useMemo(
    (): Column<MappingDataRecord>[] => [
      {
        id: 'Name',
        accessor: (row: MappingDataRecord) => row.DataItem.Name,
        Header: t('Generic.Name'),
        Cell: NameCell
      },
      {
        id: 'MasterModel',
        accessor: 'isMaster',
        Header: (props) => {
          return MasterModelHeader({ ...props, ...state });
        },
        Cell: (props) => MasterModelCell({ ...props, ...state }),
        width: 200,
        minWidth: 150
      },
      {
        id: 'isSheet',
        accessor: 'isSheet',
        Header: (props) => SheetHeader({ ...props, ...state }),
        Cell: (props) => SheetCell({ ...props, ...state }),
        width: 200,
        minWidth: 150
      }
    ],
    [state]
  );

  return columns;
}
