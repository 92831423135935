import type { HttpService } from '@bentley/pw-api';
import type {
  InProgressActivityStatus,
  ProgressManager
} from '../../hooks/useProgressManager';
import { getStatusFromResponse } from './utils';

async function sleep(timeout: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

export async function handleWRSResponse(
  progressManager: ProgressManager,
  httpService: HttpService,
  jobId: string,
  uniqueId: string,
  instanceId: string
): Promise<Response> {
  const query = `PW_WSG/CommandRun/${instanceId}`;
  const response = await httpService.get(query);
  const status: InProgressActivityStatus = await getStatusFromResponse(
    response,
    progressManager,
    jobId,
    uniqueId
  );
  progressManager.updateProgressStatus(jobId, uniqueId, status);

  if (status !== 'inprogress') {
    return response;
  }

  // Wait 10 seconds before checking again
  await sleep(10000);

  return handleWRSResponse(
    progressManager,
    httpService,
    jobId,
    uniqueId,
    instanceId
  );
}
