import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useHttpService } from '../../context';
import { useModalOutput } from '../../hooks/useModalOutput';
import type {
  ConfirmRestoreModalOutput,
  ConfirmRestoreModalProps
} from './confirmRestoreModal';
import { ConfirmRestoreModal } from './confirmRestoreModal';
import { notifyRestoreFailed, notifyRestoreNotAllowed } from './notifications';
import { allowRestoreItem } from './requirements';
import { restoreItem } from './restore';
import { useOnRestoreComplete } from './useOnRestoreComplete';

type RestoreWorkflow = (items: PWItem[]) => Promise<void>;

export function useRestore(): RestoreWorkflow {
  const httpService = useHttpService();

  const confirmRestore = useModalOutput<
    ConfirmRestoreModalOutput,
    ConfirmRestoreModalProps
  >(ConfirmRestoreModal);

  const { onRestoreComplete } = useOnRestoreComplete();

  const restoreItemsWorkflow = useCallback(
    async (items: PWItem[]): Promise<void> => {
      if (!items.every(allowRestoreItem)) {
        notifyRestoreNotAllowed();
        return;
      }

      const { confirm, navigate } = await confirmRestore({ items });
      if (!confirm) {
        return;
      }

      try {
        await Promise.all(items.map((item) => restoreItem(item, httpService)));
        onRestoreComplete(items, navigate);
      } catch {
        notifyRestoreFailed(items);
      }
    },
    [confirmRestore, httpService, onRestoreComplete]
  );

  return restoreItemsWorkflow;
}
