import React from 'react';
import { SvgInfoHollow } from '@itwin/itwinui-icons-color-react';
import { Text } from '@itwin/itwinui-react';
import { InfoTooltip } from '../../components/infoTooltip';
import { t } from '../../services/translation';

type PDFMarkupTooltipProps = {
  serverIsAccessible: boolean;
};

export function PDFMarkupTooltip({
  serverIsAccessible
}: PDFMarkupTooltipProps): JSX.Element | null {
  if (serverIsAccessible) {
    return null;
  }

  return (
    <span className="pw-cell-badge">
      <InfoTooltip
        tooltipText={t('PDFMarkup.PDFReadOnly')}
        modalText={<Text>{t('PDFMarkup.ServerShouldBePublic')}</Text>}
        modalTitle={t('Generic.AdditionalConfigurationRequired')}
        data-testid="pdfInfo"
      >
        <SvgInfoHollow />
      </InfoTooltip>
    </span>
  );
}
