import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useFeatureTracking, useNavigationContext } from '../../../context';
import type { MenuItemAction } from '../MenuItemAction';
import {
  useCheckInItemActions,
  useCheckOutItemAction,
  useCreateCopyAction,
  useCreateRenditionAction,
  useDeleteItemAction,
  useDesignReviewAction,
  useDownloadItemAction,
  useEditDocCodeItemAction,
  useFlatSetActions,
  useGoToDropDownAction,
  useGoToProjectItemAction,
  useIModelActions,
  useLinkItemActions,
  useMoveItemAction,
  useNewVersionItemAction,
  useOpenItemActions,
  useRecycleBinDeleteItemAction,
  useRenameItemAction,
  useReplaceItemAction,
  useRestoreItemAction,
  useRestoreToFolderItemAction,
  useSendToPWDMItemAction,
  useSetFolderAccessItemAction,
  useUpdateServerCopyAction,
  useWorkflowItemActions
} from './buttons';

export type ItemMenuType = 'toolbarMenu' | 'contextMenu';

export function useItemActions(
  items: PWItem[]
): (menuType: ItemMenuType) => MenuItemAction[] {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { searchState } = useNavigationContext();

  const { getOpenItemActions } = useOpenItemActions(items);
  const checkOutItem = useCheckOutItemAction(items);
  const updateServerCopyItem = useUpdateServerCopyAction(items);
  const { getCheckInItemActions } = useCheckInItemActions(items);
  const downloadItem = useDownloadItemAction(items);
  const getLinkItemAction = useLinkItemActions(items, searchState.savedSearch);
  const renameItem = useRenameItemAction(items);
  const workflowItem = useWorkflowItemActions(items);
  const newVersionItem = useNewVersionItemAction(items);
  const flatSetItem = useFlatSetActions(items);
  const replaceItem = useReplaceItemAction(items);
  const moveItem = useMoveItemAction(items);
  const createCopyItem = useCreateCopyAction(items);
  const iModelMapItem = useIModelActions(items);
  const designReviewItem = useDesignReviewAction(items);
  const setFolderAccessItem = useSetFolderAccessItemAction(items);
  const deleteItem = useDeleteItemAction(items);
  const createRenditionItem = useCreateRenditionAction(items);
  const goToDropDownItem = useGoToDropDownAction(items);
  const goToProjectItem = useGoToProjectItemAction(items);
  const sendToPWDMItem = useSendToPWDMItemAction(items);
  const editDocCodeItem = useEditDocCodeItemAction(items);

  const restoreItem = useRestoreItemAction(items);
  const restoreToItem = useRestoreToFolderItemAction(items);
  const permanentlyDeleteItem = useRecycleBinDeleteItemAction(items);

  const getItemActions = useCallback(
    (menuType: ItemMenuType): MenuItemAction[] => {
      const openInDropDown = getOpenItemActions(menuType);
      const checkInItem = getCheckInItemActions(menuType);

      const linkItem = getLinkItemAction(menuType);

      return [
        updateServerCopyItem,
        ...openInDropDown,
        checkOutItem,
        checkInItem,
        downloadItem,
        linkItem,
        renameItem,
        workflowItem,
        newVersionItem,
        flatSetItem,
        replaceItem,
        moveItem,
        createCopyItem,
        iModelMapItem,
        designReviewItem,
        setFolderAccessItem,
        deleteItem,
        createRenditionItem,
        sendToPWDMItem,
        editDocCodeItem,
        goToDropDownItem,
        goToProjectItem,
        restoreItem,
        restoreToItem,
        permanentlyDeleteItem
      ];
    },
    [
      getLinkItemAction,
      downloadItem,
      updateServerCopyItem,
      checkOutItem,
      getCheckInItemActions,
      getOpenItemActions,
      workflowItem,
      newVersionItem,
      renameItem,
      flatSetItem,
      replaceItem,
      moveItem,
      createCopyItem,
      iModelMapItem,
      designReviewItem,
      setFolderAccessItem,
      deleteItem,
      createRenditionItem,
      goToDropDownItem,
      goToProjectItem,
      restoreItem,
      restoreToItem,
      permanentlyDeleteItem,
      sendToPWDMItem,
      editDocCodeItem,
      launchDarklyFeatures.showCoauthoringUpdates
    ]
  );

  return getItemActions;
}
