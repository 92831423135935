import React, { useCallback } from 'react';
import type {
  PWDataItem,
  PWFileType,
  PWItem,
  PWProject,
  WSGInstance
} from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import {
  ConfirmCancelModal,
  DocumentWizardModal
} from '../../components/documentCreation';
import {
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';
import type { ToastHandle } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { UploadNode } from '../upload';
import { createCopyOfItems, handleSuccessfulResponse } from './createCopy';
import {
  notifyCreateCopyInProgress,
  notifyErrorDuringCopy
} from './notifications';

type CreateCopyWizardFunctions = {
  show: (destinationFolder: PWProject, itemsToCopy: PWItem[]) => void;
};

export function useCreateCopyWizard(): CreateCopyWizardFunctions {
  const httpService = useHttpService();
  const { primaryModal, navigationManager } = useNavigationContext();
  const { dataManager, selectedState } = useTableItemContext();

  function prepareDCWCopyWorkflow(
    destinationFolder: PWProject
  ): (items: PWItem[]) => Promise<Response[]> {
    const onComplete = (instanceIds?: string[]) => {
      if (instanceIds) {
        void navigationManager.navigateTo(destinationFolder, true);
        selectedState.setSelectedIds(instanceIds);
      }
      void dataManager.refresh();
    };

    function handleConflictResponse(
      responses: Response[],
      toastHandle: ToastHandle
    ): void {
      const conflicts = responses.filter((res) => res.status == 409);
      if (conflicts.length) {
        notifyErrorDuringCopy(toastHandle, t('CreateCopy.CopyConflictMessage'));
      }
    }

    async function createCopyWorkflow(items: PWItem[]): Promise<Response[]> {
      const toastHandle = notifyCreateCopyInProgress(items.length);
      const responses = await createCopyOfItems(
        httpService,
        items,
        destinationFolder,
        toastHandle,
        true,
        true
      );

      void handleSuccessfulResponse(responses, onComplete, toastHandle);
      void handleConflictResponse(responses, toastHandle);
      if (responses.every((res) => res.ok)) {
        primaryModal.close();
      }

      return responses;
    }

    return createCopyWorkflow;
  }

  const show = useCallback(
    (destinationFolder: PWProject, itemsToCopy: PWItem[]) => {
      const uploadNode: UploadNode = {
        files: [],
        directories: [],
        itemsToCopy: itemsToCopy as PWDataItem[]
      };

      const wizardOnClose = (fileNumber: number) => {
        ++fileNumber;
        primaryModal.close();

        if (
          uploadNode.itemsToCopy &&
          fileNumber >= uploadNode.itemsToCopy.length
        ) {
          return;
        } else {
          runWizard(fileNumber);
        }
      };

      const runWizard = (fileNumber: number) => {
        function confirmCancel() {
          function removeDocumentAndRunWizard() {
            const newItemsToCopy = itemsToCopy.slice(fileNumber + 1);
            show(destinationFolder, newItemsToCopy);
          }

          primaryModal.open(
            <ConfirmCancelModal
              closeModal={primaryModal.close}
              closeConfirmModal={primaryModal.close}
              onConfirm={removeDocumentAndRunWizard}
            />
          );
        }

        primaryModal.open(
          <DocumentWizardModal
            onSuccess={(
              instanceId?: string,
              fileName?: string,
              name?: string,
              description?: string,
              currentEnvironmentInstance?: WSGInstance | null
            ) => {
              wizardOnClose(fileNumber);
            }}
            fileNumber={fileNumber}
            numberOfFiles={itemsToCopy.length}
            totalFileCount={itemsToCopy.length}
            projectId={destinationFolder.instanceId}
            defaultDocumentName={itemsToCopy[fileNumber].Name}
            defaultDocumentDescription={itemsToCopy[fileNumber].Description}
            fileName={(itemsToCopy[fileNumber] as PWFileType).FileName}
            version=""
            onCancel={
              itemsToCopy.length > 1 ? confirmCancel : primaryModal.close
            }
            copy={prepareDCWCopyWorkflow(destinationFolder)}
            isCopyMode={true}
            uploadNode={uploadNode}
            closeModal={primaryModal.close}
            isFlatSetCreationMode={itemIsFlatSet(itemsToCopy[fileNumber])}
          />
        );
      };

      // Open the Wizard initially
      runWizard(0);
    },
    [httpService, primaryModal, navigationManager, dataManager, selectedState]
  );

  return { show };
}
