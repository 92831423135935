import { useRecoilValue } from 'recoil';
import { uploadInProgressState } from '../create';
import { useWizardFunctionsContext } from '../wizard';
import { uploadToastHandleState } from '../wizard/state';

export type OnCancelWizardFunction = (
  uploadInProgress?: boolean,
  e?: BeforeUnloadEvent | React.MouseEvent
) => void;

type CancelCreationFunctions = {
  cancelWizard: (e?: BeforeUnloadEvent | React.MouseEvent) => void;
};

export function useCancelWizard(): CancelCreationFunctions {
  const { onCancel } = useWizardFunctionsContext();

  const uploadInProgress = useRecoilValue(uploadInProgressState);
  const toastHandle = useRecoilValue(uploadToastHandleState);

  function cancelWizard(e?: BeforeUnloadEvent | React.MouseEvent): void {
    onCancel(uploadInProgress, e);
    toastHandle?.close();
  }

  return { cancelWizard };
}
