import './treeNode.css';

import React from 'react';
import { useNavigationContext } from '../../context';
import { FolderNode } from './folderNode/folderNode';

export function FolderExplorer(): JSX.Element {
  const {
    breadcrumbManager: { connectionBreadcrumb }
  } = useNavigationContext();

  return <FolderNode folder={connectionBreadcrumb} />;
}
