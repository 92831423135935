import React from 'react';
import { itemIsFlatSet, itemIsLogicalSet } from '@bentley/pw-api';
import { SvgFiletypeProject } from '@itwin/itwinui-icons-color-react';
import { pwIconProps } from '../../sizes/iconClassName';
import { DocumentIcon } from './documentIcon';
import { LogicalSetIcon } from './logicalSetIcon';
export function DataItemIcon({ item, size, ...rest }) {
    if (itemIsFlatSet(item)) {
        return (React.createElement(SvgFiletypeProject, { "data-testid": "flat-set-filetype", ...pwIconProps(size, rest) }));
    }
    if (itemIsLogicalSet(item)) {
        return (React.createElement(LogicalSetIcon, { item: item, size: size, "data-testid": "logical-set-filetype", ...rest }));
    }
    return React.createElement(DocumentIcon, { item: item, size: size, ...rest });
}
