import React from 'react';
import { SvgStatusWarning } from '@itwin/itwinui-icons-react';
import { Flex, Text } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

export function EmptyWorkAreaState(): JSX.Element {
  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgStatusWarning className="ets-icon" />
      <Text>{t('WorkArea.DeletedWarning')}</Text>
    </Flex>
  );
}
