import './menuItem.css';

import React from 'react';
import {
  Label,
  MenuDivider,
  MenuExtraContent,
  MenuItem
} from '@itwin/itwinui-react';
import type { MenuItemAction } from '../MenuItemAction';

export function menuItemActionToMenuItem(
  menuItemAction: MenuItemAction,
  closeMenu: () => void
): JSX.Element {
  const {
    groupLabel,
    icon,
    itemTooltip,
    startGroup,
    submenuItems,
    title,
    isActive,
    ...menuItemProps
  } = menuItemAction;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div key={title} onClick={(e) => e.stopPropagation()}>
      {startGroup && <MenuDivider />}
      {groupLabel && startGroup && (
        <MenuExtraContent>
          <Label>{groupLabel}</Label>
        </MenuExtraContent>
      )}
      <MenuItem
        className="pw-menu-item"
        {...menuItemProps}
        onClick={() => {
          closeMenu();
          menuItemAction.onClick?.();
        }}
        startIcon={icon}
        subMenuItems={submenuItems?.map((item) =>
          menuItemActionToMenuItem(item, closeMenu)
        )}
        sublabel={itemTooltip}
      >
        {title}
      </MenuItem>
    </div>
  );
}
