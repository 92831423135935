import _ from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useProductSettingService } from '../../context';

export type ProductSettingType = 'User' | 'Org' | 'Context';

type ProductSettingOptions<T> = {
  contextId?: string;
  defaultValue?: T;
  isSharedSetting?: boolean;
  settingType: ProductSettingType;
};

export function useProductSetting<T>(
  key: string,
  {
    contextId,
    defaultValue,
    isSharedSetting = false,
    settingType
  }: ProductSettingOptions<T>
): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T>>,
  () => Promise<T | undefined>
] {
  const productSettingService = useProductSettingService();
  const [value, _setValue] = useState<T>();

  const initialValue = useRef<T>();

  const setValue = useCallback(
    (newValue: React.SetStateAction<T>): void => {
      if (typeof newValue == 'function') {
        const setter = newValue as (prevState?: T) => T;
        return _setValue(setter);
      }

      if (!_.isEqual(newValue, value)) {
        _setValue(_.cloneDeep(newValue));
      }
    },
    [value]
  );

  const getProductSetting = useCallback(
    async (key: string, defaultValue?: T): Promise<T | undefined> => {
      try {
        const setting = (await productSettingService.getSetting(
          key,
          settingType,
          isSharedSetting,
          contextId
        )) as { value: T };
        if (typeof setting?.value !== 'undefined') {
          initialValue.current = setting.value;
          setValue(setting.value);
          return setting.value;
        } else if (defaultValue) {
          setValue(defaultValue);
          return defaultValue;
        }
      } catch (e) {
        console.error(e);
        if (defaultValue) {
          setValue(defaultValue);
        }
        return defaultValue;
      }
      return undefined;
    },
    [contextId, isSharedSetting, productSettingService, setValue, settingType]
  );

  const refreshValue = useCallback(async (): Promise<T | undefined> => {
    return getProductSetting(key);
  }, [getProductSetting, key]);

  useEffect(() => {
    if (!productSettingService.initialized) {
      return;
    }

    void getProductSetting(key, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSettingService.initialized, key]);

  useEffect(() => {
    if (_.isEqual(initialValue.current, value)) {
      initialValue.current = undefined;
      return;
    }
    void productSettingService.putSetting(
      key,
      { value },
      settingType,
      isSharedSetting,
      contextId
    );
  }, [value, productSettingService, settingType, contextId, isSharedSetting]);

  const productSetting = useMemo(
    (): [
      T | undefined,
      React.Dispatch<React.SetStateAction<T>>,
      () => Promise<T | undefined>
    ] => [_.cloneDeep(value), setValue, refreshValue],
    [setValue, refreshValue, value]
  );

  return productSetting;
}
