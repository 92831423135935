import { useCallback } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import { itemIsProject, parseRelatedParent } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import type {
  ColumnDisplayOptions,
  ColumnInfo
} from '../../../../hooks/useViews';
import { t } from '../../../../services/translation';
import { customPropertySortType } from '../../sort/columnSort';
import { lookupAccessor } from '../accessor';
import type { PropertyColumn } from '../typedColumn';
import { getColumnDataType } from '../typedColumn';
import { useBaseColumn } from '../useBaseColumn';

export function useFolderPropertyColumn(): PropertyColumn {
  const baseColumn = useBaseColumn();

  const baseFolderPropertyColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const { name, dataType } = columnInfo;
      const columnDataType = getColumnDataType(dataType);

      const column = baseColumn(columnInfo, displayOptions);

      const lookupFunction = (row: PWItem) =>
        parseRelatedParent(row)?.[name as keyof PWItem] ?? '';

      return {
        ...column,
        accessor: lookupAccessor(lookupFunction, columnDataType),
        sortType: customPropertySortType(lookupFunction, columnDataType)
      } as Column<PWItem>;
    },
    [baseColumn]
  );

  const objectGuidColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      const column = baseFolderPropertyColumn(
        { ...columnInfo, name: 'instanceId' },
        displayOptions
      );

      return column;
    },
    [baseFolderPropertyColumn]
  );

  const typeStringColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions?: ColumnDisplayOptions
    ): Column<PWItem> => {
      function formatItem(parent?: PWParentType): string {
        if (!parent || !itemIsProject(parent)) {
          return '';
        }

        return parent.IsRichProject
          ? t('Generic.WorkArea')
          : t('Generic.Normal');
      }

      const column = baseFolderPropertyColumn(columnInfo, displayOptions);
      return {
        ...column,
        accessor: (item: PWItem) => formatItem(parseRelatedParent(item))
      } as Column<PWItem>;
    },
    [baseFolderPropertyColumn]
  );

  const folderPropertyColumn = useCallback(
    (
      columnInfo: ColumnInfo,
      displayOptions: ColumnDisplayOptions = {}
    ): Column<PWItem> => {
      if (columnInfo.name == '$id') {
        return objectGuidColumn(columnInfo, displayOptions);
      }

      if (columnInfo.name == 'TypeString') {
        return typeStringColumn(columnInfo, displayOptions);
      }

      return baseFolderPropertyColumn(columnInfo, displayOptions);
    },
    [baseFolderPropertyColumn, objectGuidColumn, typeStringColumn]
  );

  return folderPropertyColumn;
}
