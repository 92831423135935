import React from 'react';

export function SvgChevronUpTop(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <rect x="0" y="0" width="16" height="2" />
      <path d="M16 11.3l-1.4 1.4L8 6.1l-6.6 6.6L0 11.3l8-8" />
    </svg>
  );
}
