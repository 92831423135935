import { ecPluginFeatures } from '../../hooks/useECPluginVersion';
import type { BlackListFilter } from '../../hooks/useProperties';

const documentClass = 'Document';
const folderClass = 'Project';

export function escapeSearchString(queryString: string): string {
  const escapedString = queryString
    .replace(/'/g, `''`)
    .replace(/\\/g, '\\\\')
    .replace(/%/g, '%25')
    .replace(/\//g, '%2F')
    .replace(/\?/g, '%3F')
    .replace(/#/g, '%23')
    .replace(/"/g, '%22')
    .replace(/_/, '%5F')
    .replace(/&/g, '%26')
    .replace(/\+/g, '%2B')
    .replace(/=/g, '%3D')
    .replace(/\*/g, '%2A')
    .replace(/\[/g, '\\[');
  if (ecPluginFeatures.apiAncestorBug()) {
    return escapeFTRSearchString(escapedString);
  }
  return escapedString;
}

export function escapeFTRSearchString(queryString: string): string {
  const escapedString = queryString.replace(/&/g, '%26').replace(/\+/g, '%2B');
  return escapedString;
}

/**
 * Some searches (FTR, AllContent) only search with the EQ operator and do not support the LIKE operator
 * Surrounding the query with wildcards (*) makes the EQ operator behave like the LIKE operator
 * @param queryString
 */
export function addWildcardToQueryEnds(queryString: string): string {
  return `*${queryString}*`;
}

export const blackListedSearchProperties: BlackListFilter[] = [
  {
    propertyName: 'IsLatestRevision',
    filterOutCondition: (className: string) => className == documentClass
  },
  {
    propertyName: 'IsLatestApprovedRevision',
    filterOutCondition: (className: string) => className == documentClass
  },

  {
    propertyName: 'IsStateChangeControlledByWre',
    filterOutCondition: (className: string) => className == documentClass
  },
  {
    propertyName: 'IsVersioningControlledByWre',
    filterOutCondition: (className: string) => className == documentClass
  },
  {
    propertyName: 'CreatedBy',
    filterOutCondition: (className: string) =>
      className == documentClass && !ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'FileUpdatedBy',
    filterOutCondition: (className: string) =>
      className == documentClass && !ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'UpdatedBy',
    filterOutCondition: (className: string) =>
      className == documentClass && !ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'FinalBy',
    filterOutCondition: (className: string) =>
      className == documentClass && !ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'TypeString',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'ComponentClassId',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'ComponentInstanceId',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'EnvironmentId',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'IsConnectedProject',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'IsRichProject',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'ParentGuid',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'StateId',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'WorkflowId',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'Label',
    filterOutCondition: (className: string) => className == folderClass
  },
  {
    propertyName: 'CreatedById',
    filterOutCondition: (className: string) =>
      className == folderClass && ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'OwnerId',
    filterOutCondition: (className: string) =>
      className == folderClass && ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'OwnerType',
    filterOutCondition: (className: string) =>
      className == folderClass && ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'UpdatedById',
    filterOutCondition: (className: string) =>
      className == folderClass && ecPluginFeatures.retiredProperties()
  },
  {
    propertyName: 'SerialNo',
    environmentName: 'Complex',
    filterOutCondition: (className: string) => className == 'Environment'
  },
  {
    propertyName: 'Space1',
    environmentName: 'Simple',
    filterOutCondition: (className: string) => className == 'Environment'
  },
  {
    propertyName: 'Space2',
    environmentName: 'Simple',
    filterOutCondition: (className: string) => className == 'Environment'
  }
] as const;

const basicSearchBlacklistProperties = [
  '$id',
  'checkedOutTo',
  'instanceId',
  'State',
  'OutTo',
  'Workflow'
] as const;

export const basicSearchBlacklist: BlackListFilter[] = [
  ...Object.values(blackListedSearchProperties ?? []),
  ...basicSearchBlacklistProperties.map(alwaysBlacklist)
] as const;

function alwaysBlacklist(propertyName: string): BlackListFilter {
  return { propertyName, filterOutCondition: () => true };
}
