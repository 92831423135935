import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import type {
  DocumentLink,
  SavedSearchLink
} from '@bentley/pwnxt-document-picker';
import { DocumentPickerMode } from '@bentley/pwnxt-document-picker';
import { SvgAdd } from '@itwin/itwinui-icons-react';
import { Button, Tooltip } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../context';
import { useDocPickerFunctions } from '../../hooks/useDocPickerFunctions';
import { t } from '../../services/translation';
import { DocumentPickerModalWrapper } from '../flatSetActions/useFlatSetWizard/documentPickerModalWrapper';
import { pwParentTypeToDocumentPickerParentDataType } from '../flatSetActions/utils';
import { DifferentEnvironmentWarningModal } from './differentEnvironmentWarningModal';

type MoveModalProps = {
  moveItems: PWItem[];
};

export function MoveModal({ moveItems }: MoveModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();
  const {
    documentLinkToProject,
    newFolderModal,
    onNavigate,
    onNewFolderClick,
    validMoveDestination,
    validNavigation,
    docPickerBreadcrumbManager
  } = useDocPickerFunctions(moveItems);

  function onMoveClick(folder: (DocumentLink | SavedSearchLink)[]): void {
    const targetFolder = folder.map((item) =>
      documentLinkToProject(item as DocumentLink)
    )[0];

    primaryModal.open(
      <DifferentEnvironmentWarningModal
        destinationFolder={targetFolder}
        moveItems={moveItems}
      />
    );
  }

  if (newFolderModal) {
    return newFolderModal;
  }

  return (
    <DocumentPickerModalWrapper
      onClose={primaryModal.close}
      onItemsSelected={onMoveClick}
      title={t('Move.MoveInto')}
      hideConnectionListView={true}
      currentFolder={pwParentTypeToDocumentPickerParentDataType(
        docPickerBreadcrumbManager.currentBreadcrumb
      )}
      isSelectable={validMoveDestination}
      customFilter={validNavigation}
      isNavigationAllowed={validNavigation}
      maximumSelectedItems={1}
      selectionMode={DocumentPickerMode.FolderSelection}
      onNavigate={onNavigate}
    >
      {
        <div
          style={{
            position: 'absolute',
            left: '15px'
          }}
        >
          <Tooltip
            content={t('Move.AddNewFolder')}
            data-testid="tooltip"
            placement={'top'}
          >
            <Button
              title={t('Move.AddNewFolder')}
              onClick={onNewFolderClick}
              startIcon={<SvgAdd />}
            >
              {t('Move.AddNewFolder')}
            </Button>
          </Tooltip>
        </div>
      }
    </DocumentPickerModalWrapper>
  );
}
