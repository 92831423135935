import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgFolderLock } from '@itwin/itwinui-icons-react';
import {
  disableFolderAccessMenuItem,
  showFolderAccessMenuItem
} from '../../../../actions/folderAccess';
import {
  useAppViewContext,
  useConnectionAuth,
  useFeatureTracking,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useSetFolderAccessItemAction(items: PWItem[]): MenuItemAction {
  const { rbacPermissions } = useConnectionAuth();
  const { setFolderAccess, readOnly, connection } = usePluginContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const { trackFeature } = useFeatureTracking();

  const showFolderAccessItem = useMemo((): boolean => {
    if (!showFolderAccessMenuItem(items[0], rbacPermissions)) {
      return false;
    }
    if (readOnly) {
      return false;
    }
    if (!connection.Canned) {
      return false;
    }
    if (currentAppView.type == 'RecycleBin') {
      return false;
    }
    if (!setFolderAccess) {
      return false;
    }
    return true;
  }, [
    connection.Canned,
    currentAppView.type,
    items,
    rbacPermissions,
    readOnly,
    setFolderAccess
  ]);

  const setFolderAccessItemAction = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.SetFolderAccess'),
      icon: <SvgFolderLock />,
      onClick: () => {
        trackFeature('SET_FOLDER_ACCESS_DIALOGUE');
        setFolderAccess?.(connection, items[0], refreshCurrentFolder);
      },
      disabled: disableFolderAccessMenuItem(items),
      hidden: !showFolderAccessItem
    }),
    [
      connection,
      items,
      refreshCurrentFolder,
      setFolderAccess,
      showFolderAccessItem,
      trackFeature
    ]
  );

  return setFolderAccessItemAction;
}
