import React from 'react';
import { LoadingPage } from '../../loadingPage';

type DCWLoadingProps = {
  loadingText?: string;
  loadingSubText?: string;
};

export function DCWLoadingPage({
  loadingText,
  loadingSubText
}: DCWLoadingProps): JSX.Element {
  return (
    <div className="dcw-wizard" data-testid="dcw-loading">
      <LoadingPage loadingText={loadingText} loadingSubText={loadingSubText} />
    </div>
  );
}
