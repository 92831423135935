import { useEffect } from 'react';
import { useToaster } from '@itwin/itwinui-react';

declare const window: {
  toaster?: ReturnType<typeof useToaster>;
};

export function PWToastProvider(): null {
  const toaster = useToaster();

  useEffect(() => {
    setGlobalToaster(toaster);
  }, [toaster]);

  return null;
}

/**
 * Since iTwinUI-React only exposes toaster through a hook, it cannot be used in all places
 * This function is used to get the toaster from the global window object.
 */
export function getGlobalToaster(): ReturnType<typeof useToaster> {
  if (!window.toaster) {
    throw new Error(
      'Global toaster is not configured. Call `setGlobalToaster` first.'
    );
  }

  return window.toaster;
}

function setGlobalToaster(toaster: ReturnType<typeof useToaster>): void {
  window.toaster = toaster;
}
