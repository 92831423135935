import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useGetLinkSelector } from '../../../../actions/getShareableLink/getLinkSelector';
import {
  openInProjectWiseLink,
  useProjectWiseRequirements
} from '../../../../actions/openInProjectWise';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useHttpService,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgProjectWise } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useGoToProjectWiseExplorerItemAction(
  items: PWItem[]
): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const { isAdmin } = useConnectionAuth();
  const {
    buddiRegionCode,
    readOnly,
    connection,
    consumerApp,
    contextId,
    teamsTabLink,
    pwDriveData: { httpDriveService }
  } = usePluginContext();
  const httpService = useHttpService();
  const { goToPWE } = useEnvironmentContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const { getLink } = useGetLinkSelector({
    buddiRegionCode,
    consumerApp,
    connection,
    contextId,
    teamsTabLink
  });

  const allowOpenInProjectWise = useProjectWiseRequirements();

  const item = items[0];

  const showGoToProjectWiseExplorer = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }
    if (connection.Canned) {
      return false;
    }
    if (currentAppView.type == 'RecycleBin') {
      return false;
    }
    if (!isAdmin && !goToPWE.allowed) {
      return false;
    }
    return true;
  }, [
    readOnly,
    connection.Canned,
    currentAppView.type,
    isAdmin,
    goToPWE.allowed
  ]);

  const inTeams = useMemo(
    (): boolean =>
      consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams',
    [consumerApp]
  );

  const goToProjectWiseExplorer = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.GoToPWE'),
      icon: <SvgProjectWise />,
      onClick: () =>
        void openInProjectWiseLink(
          item,
          connection,
          httpService,
          httpDriveService,
          trackFeature,
          inTeams,
          getLink
        ),
      disabled: !allowOpenInProjectWise(items),
      hidden: !showGoToProjectWiseExplorer
    }),
    [
      allowOpenInProjectWise,
      items,
      showGoToProjectWiseExplorer,
      item,
      connection,
      httpService,
      httpDriveService,
      trackFeature,
      inTeams,
      getLink
    ]
  );

  return goToProjectWiseExplorer;
}
