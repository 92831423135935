import React, { useEffect, useState } from 'react';
import type { PWItem, WSGInstance } from '@bentley/pw-api';
import { pwConstants } from '@bentley/pw-api';
import {
  Flex,
  LabeledInput,
  Radio,
  StatusMessage,
  Text
} from '@itwin/itwinui-react';
import { DatePickerInput } from '../../components/datePickerInput';
import { PWModal } from '../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { InputChange } from '../../hooks/useForm';
import { useForm } from '../../hooks/useForm';
import { getAuditTrail } from '../../services/data';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import { performDownloadInBrowser } from '../download/utils';
import { getCsv } from './exportAuditTrail';

export type AuditTrail = {
  ActionName: string;
  AdditionalData: string;
  Comment: string;
  Date: string;
  UserName: string;
  ObjectType: string;
  ObjectName: string;
} & WSGInstance;

type ExportAuditTrailForm = {
  FileName: string;
};

type ExportAuditTrailProps = {
  selectedItem: PWItem;
  fileName?: string;
};

type DateRange = {
  beginDate: Date;
  endDate: Date;
};

export function ExportAuditTrailModal({
  selectedItem,
  fileName = t('InfoPanel.AuditTrail')
}: ExportAuditTrailProps): JSX.Element {
  const httpService = useHttpService();
  const { trackFeature } = useFeatureTracking();
  const { consumerApp } = usePluginContext();
  const { primaryModal } = useNavigationContext();

  const {
    form: { inputs, valid = validateFileName(fileName) == '', errors },
    onChange
  } = useForm<ExportAuditTrailForm>({
    inputs: {
      FileName: fileName
    },
    errors: {},
    validateInput,
    isFormValid: () => {
      return true;
    }
  });

  const [beginDate, setBeginDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auditTrail, setAuditTrail] = useState<AuditTrail[]>([]);

  const [isExportAllSelected, setIsExportAllSelected] = useState<boolean>(true);

  function onExport(): void {
    trackFeature('EXPORT_AUDIT_TRAIL');
    const toastHandle = openToast({
      content: t('AuditTrail.Notifications.ExportInProgress'),
      type: 'persisting',
      spinner: true
    });

    try {
      const csv = isExportAllSelected
        ? getCsv(auditTrail)
        : getCsv(auditTrail, beginDate, endDate);

      const csvBlob = new Blob(['\ufeff' + csv], {
        // changing charset as datetime in exported file does not displayed in proper format mostly in IE
        type: 'text/csv'
      });

      performDownloadInBrowser(csvBlob, inputs.FileName + '.csv');
      primaryModal.close();

      const toastMessage =
        consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams'
          ? t('AuditTrail.Notifications.ExportCompleteCheckDownloads')
          : t('AuditTrail.Notifications.ExportComplete');

      replaceToast(toastHandle, {
        content: toastMessage,
        category: 'positive'
      });
    } catch (e) {
      replaceToast(toastHandle, {
        content: t('AuditTrail.Notifications.ExportFailed'),
        category: 'negative'
      });
    }
  }

  function validateFileName(fileName: string): string {
    let toReturn = '';
    const regex = /[<>|:"?/\\*]+/i;
    if (fileName.length > pwConstants.maxFileNameLength) {
      toReturn = `${t('Generic.ContentLimitedTo', {
        count: pwConstants.maxFileNameLength,
        excess: fileName.length - pwConstants.maxFileNameLength
      })}`;
    } else if (!(fileName && fileName.trim())) {
      toReturn = t('Generic.NameCannotBeEmpty');
    } else if (regex.test(fileName)) {
      toReturn = t('AuditTrail.NameHasInvalidCharacters');
    }

    return toReturn;
  }

  function validateInput({ name, value }: InputChange<ExportAuditTrailForm>) {
    switch (name) {
      case 'FileName':
        return {
          name,
          value: validateFileName(value),
          type: validateFileName(value) == '' ? '' : 'error'
        };

      default:
        return {
          name,
          value: '',
          type: ''
        };
    }
  }

  function getDateRange(auditTrail?: AuditTrail[]): DateRange {
    if (!auditTrail || auditTrail.length == 0) {
      return { beginDate: new Date(), endDate: new Date() };
    }

    const beginDate = new Date(
      Math.min(...auditTrail.map((at) => Date.parse(at.Date)))
    );
    const newBeginDate = new Date(
      beginDate.getFullYear(),
      beginDate.getMonth(),
      beginDate.getDate()
    );
    const newEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59,
      59
    );
    return { beginDate: newBeginDate, endDate: newEndDate };
  }

  useEffect(() => {
    async function retrieveAuditTrail() {
      setIsLoading(true);

      try {
        const auditTrail = await getAuditTrail(selectedItem, httpService);

        if (!auditTrail?.length) {
          setIsLoading(false);
          primaryModal.close();
          openToast({ content: t('AuditTrail.NoRecords') });
          return;
        }

        setAuditTrail(auditTrail);
        const dateRange = getDateRange(auditTrail);
        setBeginDate(dateRange.beginDate);
        setEndDate(dateRange.endDate);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        primaryModal.close();
        openToast({
          content: `${t('AuditTrail.ErrorRetrievingRecords')}: ${
            (err as Error).message
          }`,
          category: 'negative'
        });
      }
    }

    void retrieveAuditTrail();
  }, [httpService, primaryModal, selectedItem]);

  return (
    <PWModal
      title={t('AuditTrail.ExportAuditTrail')}
      isLoading={isLoading}
      loadingText={t('AuditTrail.RetrievingAuditTrail')}
      primaryButton={{
        title: t('Generic.Export'),
        onClick: () => void onExport(),
        disabled: !valid || isLoading,
        'data-testid': 'export-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'ExportAuditTrailModal' }}
    >
      <Radio
        defaultChecked={true}
        onChange={() => setIsExportAllSelected(true)}
        label={t('AuditTrail.ExportAllRecords')}
        name="exportChoices"
        data-testid="export-all"
      />
      <Radio
        onChange={() => setIsExportAllSelected(false)}
        label={t('AuditTrail.ExportRecordsFrom')}
        name="exportChoices"
        data-testid="export-range"
      />
      <Flex alignItems="baseline" style={{ marginLeft: '24px' }}>
        <DatePickerInput
          label={t('Generic.From')}
          date={beginDate}
          onChange={(date) => setBeginDate((cur) => date ?? cur)}
          isDateDisabled={(date: Date) =>
            endDate != undefined && date > endDate
          }
          disabled={isExportAllSelected}
          inputProps={{
            style: { maxWidth: '150px' },
            'data-testid': 'from-date'
          }}
        />
        <DatePickerInput
          label={t('Generic.To')}
          date={endDate}
          onChange={(date) => setEndDate((cur) => date ?? cur)}
          isDateDisabled={(date: Date) =>
            beginDate != undefined && date < beginDate
          }
          disabled={isExportAllSelected}
          inputProps={{
            style: { maxWidth: '150px' },
            'data-testid': 'to-date'
          }}
        />
      </Flex>
      <LabeledInput
        label={`${t('Generic.FileName')}:`}
        name="FileName"
        value={inputs.FileName}
        onChange={onChange}
        status={errors.FileName?.errorType == 'error' ? 'negative' : undefined}
        data-testid="file-name-input"
        placeholder={t('AuditTrail.EnterFileName')}
        svgIcon={<Text isMuted={true}>.csv</Text>}
        message={
          <StatusMessage
            status={
              errors.FileName?.errorType == 'error' ? 'negative' : undefined
            }
          >
            {errors.FileName?.errorMessage ?? ''}
          </StatusMessage>
        }
      />
    </PWModal>
  );
}
