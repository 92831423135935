import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsDocument, itemIsFileType } from '@bentley/pw-api';
import { SvgLockUnlocked, SvgReplace } from '@itwin/itwinui-icons-react';
import type { ISyncButtonProps } from '../../../../actions/sync';
import {
  allowCheckInAll,
  allowFreeAll,
  allowUpdateAll,
  checkInOperation,
  endSessionOperation,
  freeOperationWorkflow,
  hideFreeFileButton,
  hideUpdateButton,
  isCheckActionsDisabled,
  isCheckOutHidden,
  showCheckInButton,
  showEndCoAuthoringButton,
  updateServerCopyOperation
} from '../../../../actions/sync';
import { showUpdateServerCopy } from '../../../../actions/useGraphApi';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { useOutToMeSearch } from '../../../../hooks/useOutToMeSearch';
import { t } from '../../../../services/translation';
import { SvgCheckIn, SvgCheckOut } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';
import type { ItemMenuType } from '../useItemActions';

type CheckInItemActions = {
  getCheckInItemActions: (menuType: ItemMenuType) => MenuItemAction;
};

export function useCheckInItemActions(items: PWItem[]): CheckInItemActions {
  const { trackFeature, launchDarklyFeatures } = useFeatureTracking();

  const { userId: userGuid, userName, isAdmin } = useConnectionAuth();

  const {
    pwDriveData,
    readOnly,
    contextId,
    dataAccessLevel,
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();
  const { user } = useConnectionAuth();
  const httpService = useHttpService();
  const graphApiManager = useGraphApiContext();

  const { primaryModal } = useNavigationContext();

  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const {
    ecPluginFeatures,
    office365CoAuthoringSettings: {
      isCoAuthoringEnabled: office365CoAuthoringEnabled
    },
    userManager: { currentUserId }
  } = useEnvironmentContext();

  const { dataManager } = useTableItemContext();

  const outToMe = useOutToMeSearch();

  const refreshOutToMe = useCallback((): void => {
    void outToMe();
  }, [outToMe]);

  // Todo: check in functions can be converted to a hook and will not need most of
  // these parameters passed in
  const checkInProps = useMemo(
    (): ISyncButtonProps => ({
      checkedRows: items,
      currentUserName: userName,
      userGuid,
      contextId: contextId ?? '',
      isUserHasAdminLevelAccess: isAdmin,
      refreshView: dataManager.refreshCurrentFolder,
      refreshOutToMe,
      openModal: primaryModal.open,
      closeModal: primaryModal.close,
      trackFeature: trackFeature,
      httpService: httpService,
      driveData: pwDriveData,
      graphApiManager: graphApiManager,
      currentAppView: currentAppView,
      user: user
    }),
    [
      items,
      userName,
      userGuid,
      contextId,
      isAdmin,
      dataManager.refreshCurrentFolder,
      refreshOutToMe,
      primaryModal.open,
      primaryModal.close,
      trackFeature,
      httpService,
      pwDriveData,
      graphApiManager,
      currentAppView,
      user
    ]
  );

  const coAuthoringSessionCount = items.filter(
    graphApiManager.checkCoAuthoringSessionExists
  ).length;
  const allItemsCoAuthored = coAuthoringSessionCount === items.length;
  const coAuthoredItemsExist = coAuthoringSessionCount > 0;

  const checkInItem = useMemo(
    (): MenuItemAction => ({
      title: t('Sync.CheckIn'),
      icon: <SvgCheckIn />,
      onClick: () => void checkInOperation(checkInProps),
      disabled: !allowCheckInAll(
        items,
        !pwDriveData.pwDriveSyncDisabled,
        ecPluginFeatures.masterReferenceFileOperations(),
        coAuthoredItemsExist,
        allItemsExistInConnection
      ),
      hidden: !showCheckInButton(
        items,
        !pwDriveData.pwDriveSyncDisabled,
        ecPluginFeatures.masterReferenceFileOperations(),
        readOnly,
        launchDarklyFeatures.showCoauthoringUpdates as boolean,
        coAuthoredItemsExist,
        allItemsExistInConnection
      )
    }),
    [
      showCheckInButton,
      items,
      pwDriveData.pwDriveSyncDisabled,
      ecPluginFeatures,
      allItemsExistInConnection,
      checkInProps,
      coAuthoredItemsExist,
      launchDarklyFeatures.showCoauthoringUpdates,
      allowCheckInAll
    ]
  );

  const endCoAuthoringItem = useMemo(
    (): MenuItemAction => ({
      title: launchDarklyFeatures.showCoauthoringUpdates
        ? t('MenuItem.EndCoAuthoringForAllUsers')
        : t('MenuItem.EndO365'),
      icon: <SvgCheckIn />,
      onClick: () => void endSessionOperation(checkInProps),
      hidden:
        !allItemsCoAuthored ||
        !office365CoAuthoringEnabled ||
        !showEndCoAuthoringButton(
          items,
          !pwDriveData.pwDriveSyncDisabled,
          ecPluginFeatures.masterReferenceFileOperations(),
          readOnly
        )
    }),
    [
      items,
      pwDriveData.pwDriveSyncDisabled,
      ecPluginFeatures,
      readOnly,
      checkInProps,
      office365CoAuthoringEnabled,
      allItemsCoAuthored,
      showEndCoAuthoringButton,
      launchDarklyFeatures.showCoauthoringUpdates
    ]
  );

  const freeItem = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.Free'),
      icon: <SvgLockUnlocked />,
      onClick: () => void freeOperationWorkflow(checkInProps),
      disabled: !allowFreeAll(
        items,
        !pwDriveData.pwDriveSyncDisabled,
        ecPluginFeatures.masterReferenceFileOperations(),
        currentUserId ?? '',
        isAdmin,
        allItemsExistInConnection
      ),
      hidden: hideFreeFileButton(items, readOnly)
    }),
    [
      items,
      pwDriveData.pwDriveSyncDisabled,
      ecPluginFeatures,
      currentUserId,
      isAdmin,
      allItemsExistInConnection,
      readOnly,
      checkInProps
    ]
  );

  const updateServerCopy = useMemo(
    (): MenuItemAction => ({
      title: launchDarklyFeatures.showCoauthoringUpdates ? t('MenuItem.UpdateOnProjectWise') : t('MenuItem.UpdateServerCopy'),
      icon: <SvgReplace />,
      onClick: () => void updateServerCopyOperation(checkInProps),
      disabled: !allowUpdateAll(
        items,
        !pwDriveData.pwDriveSyncDisabled,
        pwDriveData.isCurrentConnectionSynced,
        iModelBridges,
        allItemsExistInConnection
      ),
      hidden:
        hideUpdateButton(items, readOnly, iModelBridges) || coAuthoredItemsExist
    }),
    [
      items,
      pwDriveData.pwDriveSyncDisabled,
      pwDriveData.isCurrentConnectionSynced,
      iModelBridges,
      allItemsExistInConnection,
      readOnly,
      coAuthoredItemsExist,
      checkInProps,
      launchDarklyFeatures,
    ]
  );

  const childItems = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems([
        checkInItem,
        endCoAuthoringItem,
        freeItem,
        updateServerCopy
      ]),
    [checkInItem, endCoAuthoringItem, freeItem, updateServerCopy]
  );

  const showCheckInPlaceholder = useMemo((): boolean => {
    return (
      items.length > 0 &&
      items.every((item) => itemIsDocument(item)) &&
      isCheckOutHidden(
        readOnly,
        items,
        pwDriveData.pwDriveSyncDisabled,
        ecPluginFeatures.masterReferenceFileOperations()
      ) &&
      dataAccessLevel == 'WorkArea'
    );
  }, [items, dataAccessLevel, ecPluginFeatures, pwDriveData, readOnly]);

  const getCheckInItemActions = useCallback(
    (menuType: ItemMenuType): MenuItemAction => {
      const checkInItems =
        childItems.length > 0
          ? {
              title: t('MenuItem.CheckInWithDots'),
              icon: <SvgCheckIn />,
              disabled: isCheckActionsDisabled(childItems),
              hidden:
                readOnly ||
                currentAppView.type == 'RecycleBin' ||
                items.length == 0 ||
                items.some((item) => {
                  return !itemIsFileType(item);
                }),
              startGroup: launchDarklyFeatures.showCoauthoringUpdates
                ? true
                : !showUpdateServerCopy(
                    items,
                    graphApiManager.checkCoAuthoringSessionExists,
                    graphApiManager.coAuthoringFeatures,
                    userName
                  ),
              submenuItems: childItems
            }
          : {
              title: t('MenuItem.CheckOut'),
              icon: <SvgCheckOut />,
              disabled: true,
              startGroup: !showUpdateServerCopy(
                items,
                graphApiManager.checkCoAuthoringSessionExists,
                graphApiManager.coAuthoringFeatures,
                userName
              ),
              hidden: !showCheckInPlaceholder || menuType == 'contextMenu'
            };

      return checkInItems;
    },
    [
      childItems,
      readOnly,
      currentAppView.type,
      items,
      graphApiManager.checkCoAuthoringSessionExists,
      graphApiManager.coAuthoringFeatures,
      userName,
      showCheckInPlaceholder,
      launchDarklyFeatures.showCoauthoringUpdates
    ]
  );

  return { getCheckInItemActions };
}
