import type { PWItem } from '@bentley/pw-api';
import { itemFileExtension, itemIsFileType } from '@bentley/pw-api';
import type { DataAccessLevel } from '../../context';

export enum PdfMarkupMode {
  Normal = 0,
  NoIssues = 1,
  Readonly = 2,
  Preview = 3
}

export function getPdfMarkupMode(
  item: PWItem,
  serverIsAccessible: boolean,
  dataAccessLevel?: DataAccessLevel
): PdfMarkupMode {
  if (!fileIsPdf(item) || dataAccessLevel == 'Datasource') {
    return PdfMarkupMode.Preview;
  }

  if (!serverIsAccessible) {
    return PdfMarkupMode.Readonly;
  }

  return PdfMarkupMode.Normal;
}

export function fileIsPdf(item: PWItem): boolean {
  if (!itemIsFileType(item)) {
    return false;
  }

  const extension = itemFileExtension(item);
  return extension == 'pdf';
}
