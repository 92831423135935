import jQuery from 'jquery';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { IRendererElement } from '@bentley/formsrenderer/lib/form-renderer/components/RendererElement/RendererElement';
import type { IFormContainer } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormContainer';
import type { INameValuePair } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IUtilities';
import type {
  IWsgInstance,
  WsgClassType,
  WsgDataType
} from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';
import type { WSGInstance } from '@bentley/pw-api';
import { formDefinitionAsJQuery } from '../../../actions/formRendering';
import { useFeatureTracking } from '../../../context';
import {
  formatChangedElement,
  formatMultiSelectOption,
  highlightElement
} from '../../documentCreation/form';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState,
  originalEnvironmentInstanceState
} from '../state';
import {
  formDefinitionState,
  formValidState,
  projectIdState,
  updatingTriggeredPropertiesState
} from './state';
import { useFocusedElement } from './useFocusedElement';
import { useScriptedProperties } from './useScriptedProperties';

export function useDocCodeForm(): IFormContainer | undefined {
  const { launchDarklyFeatures } = useFeatureTracking();

  const formDefinition = useRecoilValue(formDefinitionState);
  const setFormValid = useSetRecoilState(formValidState);
  const editingDocCodeForm = useRecoilValue(editingDocCodeFormState);

  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const projectId = useRecoilValue(projectIdState);

  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);

  const { addFocusListener } = useFocusedElement();

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const formInstance = useRef<WSGInstance | undefined>(
    currentEnvironmentInstance
  );

  const [formJQuery, setFormJQuery] = useState<IFormContainer>();

  const { saveScriptedProperties, runScriptedProperties } =
    useScriptedProperties(formJQuery);

  const onInstanceDataChange = useCallback(
    (
      changedData: Array<INameValuePair<WsgDataType>>,
      instance: IWsgInstance<WsgClassType>,
      formIsValid: boolean
    ): void => {
      setFormValid(formIsValid);
      setCurrentEnvironmentInstance(_.cloneDeep(instance) as WSGInstance);
      void runScriptedProperties(changedData, instance);
    },
    [runScriptedProperties, setCurrentEnvironmentInstance, setFormValid]
  );

  const onElementDataChange = useCallback(
    (
      changedData: Array<INameValuePair<WsgDataType>>,
      dataClassInstanceId: string,
      element: IRendererElement,
      event: JQueryEventObject,
      formIsValid: boolean
    ): void => {
      formatChangedElement(
        changedData,
        element,
        originalEnvironmentInstance,
        'documentcode',
        setFormValid
      );
    },
    [originalEnvironmentInstance, setFormValid]
  );

  const onElementRenderComplete = useCallback(
    (element: IRendererElement, $element: JQuery, binding: string): void => {
      if (!binding) {
        return;
      }

      turnOffAutoCompleteOnSelects(element, $element);
      formatMultiSelectOption(element);

      highlightElement(
        element,
        binding,
        formInstance.current,
        originalEnvironmentInstance
      );

      saveScriptedProperties(element);
      addFocusListener(element, $element);
    },
    [addFocusListener, originalEnvironmentInstance, saveScriptedProperties]
  );

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access --
     * Sets jQuery to be available globally, as the forms renderer is not handling it nicely
     * Maybe their 2.0 version will fix this
     * We can also use webpack.ProvidePlugin to do this, but this removes the responsibility from the consumers
     */
    (window as any).$ = jQuery;
  }, []);

  useEffect(() => {
    if (currentEnvironmentInstance) {
      formInstance.current = currentEnvironmentInstance;
    }
  }, [currentEnvironmentInstance]);

  useEffect(() => {
    function getFormJQuery(): IFormContainer | undefined {
      const isReadOnly = !editingDocCodeForm;
      const formDefString = formDefinition?.Definition;

      if (!formDefString || !formInstance.current) {
        return undefined;
      }

      const formJQuery = formDefinitionAsJQuery(
        formDefString,
        _.cloneDeep(formInstance.current),
        {
          projectId,
          readonly: isReadOnly,
          onInstanceDataChange,
          onElementDataChange,
          onElementRenderComplete
        },
        launchDarklyFeatures.disableFormDateTimeLocalization as boolean
      );

      return formJQuery;
    }

    if (!updatingTriggeredProperties) {
      const formJQuery = getFormJQuery();
      setFormJQuery(formJQuery);
    }
  }, [
    editingDocCodeForm,
    projectId,
    formDefinition,
    formInstance,
    onElementDataChange,
    onElementRenderComplete,
    onInstanceDataChange,
    updatingTriggeredProperties,
    launchDarklyFeatures.disableFormDateTimeLocalization
  ]);

  return formJQuery;
}

function turnOffAutoCompleteOnSelects(
  element: IRendererElement,
  $element: JQuery<HTMLElement>
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  if ((element as any).type != 'SelectList') {
    return;
  }

  ($element[0] as HTMLInputElement).autocomplete = 'off';
}
