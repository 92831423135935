import React, { useMemo } from 'react';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { itemIsParentType } from '@bentley/pw-api';
import { NewVersionModal, allowNewVersion } from '../../../../actions/version';
import { newVersionWRECommand } from '../../../../actions/workflow';
import {
  useAppViewContext,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgDocuments } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useNewVersionItemAction(items: PWItem[]): MenuItemAction {
  const { readOnly } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { itemExistsInConnection }
  } = useAppViewContext();
  const { wreCommands } = useTableItemContext();

  const wreControlledVersion = useMemo((): boolean => {
    if (wreCommands == undefined) {
      return false;
    }
    if (
      wreCommands.find(({ instanceId }) => instanceId == newVersionWRECommand)
    ) {
      return false;
    }
    return true;
  }, [wreCommands]);

  const showNewVersion = useMemo((): boolean => {
    if (!items.length || items.some(itemIsParentType)) {
      return false;
    }
    if (readOnly || currentAppView.type == 'RecycleBin') {
      return false;
    }
    if (wreControlledVersion) {
      return false;
    }
    return true;
  }, [items, readOnly, wreControlledVersion, currentAppView.type]);

  const newVersionItem = useMemo(
    (): MenuItemAction => ({
      title: t('Version.NewVersion'),
      icon: <SvgDocuments />,
      onClick: () =>
        primaryModal.open(<NewVersionModal items={items as PWFileType[]} />),
      disabled:
        !showNewVersion ||
        wreCommands == undefined ||
        !allowNewVersion(items, itemExistsInConnection),
      hidden: !showNewVersion
    }),
    [items, itemExistsInConnection, showNewVersion, primaryModal, wreCommands]
  );

  return newVersionItem;
}
