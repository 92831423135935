import { createElement } from 'react';
import { useNavigationContext } from '../../context';

export type ResolveOutputFunction<T> = {
  resolveModalOutput: (output: T) => void;
};

type ResolveOutputModal<T, TProps extends ResolveOutputFunction<T>> = (
  props: TProps
) => JSX.Element;

type ModalConfirmationFunction<T, TProps extends ResolveOutputFunction<T>> = (
  props: Omit<TProps, 'resolveModalOutput'>
) => Promise<T>;

/**
 * Hook returns a function which awaits a modal close and returns an object representing a user choice
 * The modal *must* accept a props object with a `resolveUserChoice: (choice: T) => void` property
 *
 * @param modal The modal to open (the name of the JSX function)
 * @returns An awaitable function. Pass in the props to the modal as an object, excluding `resolveUserChoice`,
 * and await the response. The modal must call `resolveUserChoice({} as T)` before exiting.
 * Once this is called from the modal, the promise resolves with the response.
 *
 * **e.g.**
 * ```
 * const getModalConfirmation = useModalConfirmation<boolean, MyModalProps>(MyModal);
 * const modalConfirmation: boolean = await getModalConfirmation({propA: 1, propB: ""})
 * ```
 */
export function useModalOutput<T, TProps extends ResolveOutputFunction<T>>(
  modal: ResolveOutputModal<T, TProps>
): ModalConfirmationFunction<T, TProps> {
  const { primaryModal } = useNavigationContext();

  async function getModalOutput(
    props: Omit<TProps, 'resolveModalOutput'>
  ): Promise<T> {
    return new Promise<T>((resolve) => {
      function resolveModalOutput(choice: T): void {
        resolve(choice);
      }

      const modalProps = { ...props, resolveModalOutput } as TProps;
      const modalElement = createElement(modal, modalProps);

      primaryModal.open(modalElement);
    });
  }

  return getModalOutput;
}
