import { useCallback, useEffect, useMemo } from 'react';
import type { PWParentType } from '@bentley/pw-api';
import { getItem, getProject, itemIsProject } from '@bentley/pw-api';
import {
  useConnectionContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import { t } from '../../services/translation';
import { retrieveBreadcrumbs } from '../useBreadcrumb/retrieveBreadcrumbs';
import { useSavedSearchLinkResolution } from './useSavedSearchLinkResolution';

export function useLinkResolution(): void {
  const { trackFeature } = useFeatureTracking();
  const {
    selectionState: { currentProjectId, selectedItemId }
  } = useConnectionContext();
  const { consumerApp, connection, dataAccessLevel } = usePluginContext();
  const httpService = useHttpService();
  const {
    navigationManager: { navigateToRoot, setLinkError, navigateTo },
    setInfoPanelDisplayed,
    breadcrumbManager: { connectionBreadcrumb }
  } = useNavigationContext();
  const {
    selectedState: { setSelectedItems }
  } = useTableItemContext();

  useSavedSearchLinkResolution();

  const missingLinkMessage = useMemo((): string => {
    if (
      consumerApp == 'ProjectWise 365' ||
      consumerApp == 'ProjectWise 365 in Teams'
    ) {
      return t('SharedLink.PW365LinkNotFound');
    }

    return t('SharedLink.PWLinkNotFound');
  }, [consumerApp]);

  const handleMissingItem = useCallback((): void => {
    setSelectedItems([]);
    navigateToRoot();
    setLinkError(new Error(missingLinkMessage));
  }, [missingLinkMessage, navigateToRoot, setLinkError, setSelectedItems]);

  const verifyParentInWorkArea = useCallback(
    async (parent: PWParentType): Promise<void> => {
      if (dataAccessLevel == 'Datasource') {
        return;
      }

      const ancestors = await retrieveBreadcrumbs(
        parent,
        connection,
        connectionBreadcrumb,
        httpService
      );
      const parentOutOfWorkArea = !ancestors.some(
        ({ instanceId }) => instanceId == connection.ProjectId
      );

      if (parentOutOfWorkArea) {
        console.error('Project not found in work area');
        handleMissingItem();
      }
    },
    [connection, dataAccessLevel, handleMissingItem, httpService]
  );

  const navigateToParent = useCallback(
    async (parentId: string): Promise<void> => {
      const parent = await getProject({
        instanceId: parentId,
        httpService,
        selectRelationships: { parent: true }
      });
      navigateTo(parent, true);
      void verifyParentInWorkArea(parent);
    },
    [httpService, navigateTo, verifyParentInWorkArea]
  );

  const resolveItem = useCallback(
    async (instanceId: string): Promise<void> => {
      try {
        if (instanceId == connection.ProjectId) {
          return;
        }

        const item = await getItem({ instanceId, httpService });
        if (!item) {
          handleMissingItem();
          return;
        }

        if (itemIsProject(item)) {
          await navigateToParent(item.instanceId);
          return;
        }

        setSelectedItems([item]);
        setTimeout(() => setInfoPanelDisplayed(true), 2000);

        if (!item.ParentGuid) {
          navigateToRoot();
          return;
        }

        await navigateToParent(item.ParentGuid);
      } catch (e) {
        console.error(e);
        handleMissingItem();
      }
    },
    [
      connection,
      handleMissingItem,
      httpService,
      navigateToParent,
      navigateToRoot,
      setInfoPanelDisplayed,
      setSelectedItems
    ]
  );

  const resolveParent = useCallback(async (): Promise<void> => {
    const instanceId =
      currentProjectId ?? new URLSearchParams(location.search).get('project');

    if (instanceId == connection.ProjectId || !instanceId) {
      return;
    }

    await navigateToParent(instanceId);
  }, [connection.ProjectId, currentProjectId, navigateToParent]);

  useEffect(() => {
    if (selectedItemId) {
      trackFeature('LINK_ARRIVE_V2');
      void resolveItem(selectedItemId);
    } else {
      void resolveParent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
