import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import type { DataError } from '../../../../actions/formRendering';
import { handleDCWError } from '../../error';
import { getDCWHttpService, useRecoilStateAsync } from '../../state';
import {
  attributesFormDefinitionState,
  docCodeFormDefinitionState
} from '../../wizard';
import { updatingReloadPropertiesState } from '../state';
import type { FormType } from '../useDCWForm';
import { generateReloadFormAction } from './formGenerateAction';

type FormReloadWorkflowFunctions = {
  reloadFormWorkflow: (environmentInstance: WSGInstance) => Promise<void>;
};

export function useFormReloadWorkflow(
  formType: FormType
): FormReloadWorkflowFunctions {
  const [, setDocCodeFormDefinition] = useRecoilStateAsync(
    docCodeFormDefinitionState
  );
  const setAttributesFormDefinition = useSetRecoilState(
    attributesFormDefinitionState
  );

  const reloadAttributesForm = useCallback(
    async (environmentInstance: WSGInstance): Promise<void> => {
      const formDefinition = await generateReloadFormAction(
        'attributespage',
        environmentInstance,
        getDCWHttpService()
      );
      setAttributesFormDefinition(formDefinition);
    },
    [setAttributesFormDefinition]
  );

  const reloadDocumentCodeForm = useCallback(
    async (environmentInstance: WSGInstance): Promise<void> => {
      const formDefinition = await generateReloadFormAction(
        'documentcode',
        environmentInstance,
        getDCWHttpService()
      );
      setDocCodeFormDefinition(formDefinition);
      void reloadAttributesForm(environmentInstance);
    },
    [reloadAttributesForm, setDocCodeFormDefinition]
  );

  const setUpdatingReloadProperties = useSetRecoilState(
    updatingReloadPropertiesState
  );

  const reloadFormWorkflow = useCallback(
    async (environmentInstance: WSGInstance): Promise<void> => {
      try {
        setUpdatingReloadProperties(true);
        if (formType == 'documentcode') {
          await reloadDocumentCodeForm(environmentInstance);
        }
        if (formType == 'attributespage') {
          await reloadAttributesForm(environmentInstance);
        }
      } catch (error) {
        void handleDCWError(error as DataError);
      } finally {
        setUpdatingReloadProperties(false);
      }
    },
    [
      formType,
      reloadAttributesForm,
      reloadDocumentCodeForm,
      setUpdatingReloadProperties
    ]
  );

  return { reloadFormWorkflow };
}
