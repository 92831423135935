import type { HTMLProps } from 'react';
import React, { useEffect, useState } from 'react';
import { t } from '../../services/translation';
import { ErrorPage, errorMessage, errorType } from '../errorPage';
import type { ErrorPageType } from '../errorPage/utils';

interface IProjectWiseErrorPageProps extends HTMLProps<HTMLElement> {
  clearErrorPage?: () => void;
  error: Response | Error;
  buttonLabel?:
    | 'Generic.ViewConnection'
    | 'Generic.Refresh'
    | 'ErrorPage.SignOut';
  buttonHandle?: () => void;
  signOut?: () => void;
}

export function ProjectWiseErrorPage({
  clearErrorPage,
  error,
  buttonLabel,
  buttonHandle,
  signOut
}: IProjectWiseErrorPageProps): JSX.Element {
  const [errMsg, setErrMsg] = useState<string>();
  const [errType, setErrType] = useState<ErrorPageType>();
  const [errorPageButtonLabel, setErrorPageButtonLabel] = useState<string>();
  const [errorPageButtonHandle, setErrorPageButtonHandle] =
    useState<() => () => void>();

  function signInError(
    errorMessage: string,
    errorType: ErrorPageType
  ): boolean {
    if (buttonLabel != 'Generic.Refresh') {
      return false;
    }

    return (
      errorType == '401' ||
      errorType == '403' ||
      errorMessage == 'Too many invalid login requests.' ||
      errorMessage.includes('Datasource connection terminated.')
    );
  }

  function getButtonLabel(
    errorMessage: string,
    errorType: ErrorPageType
  ): string | undefined {
    if (signInError(errorMessage, errorType)) {
      return t('ErrorPage.SignOut');
    }
    return t(buttonLabel ?? 'ErrorPage.Retry');
  }

  function getButtonHandle(
    errorMessage: string,
    errorType: ErrorPageType
  ): () => void {
    if (!buttonLabel) {
      return () => null;
    }

    if (signInError(errorMessage, errorType)) {
      buttonHandle = signOut;
    }

    return clearErrorAndExecuteHandle;
  }

  function clearErrorAndExecuteHandle() {
    clearErrorPage?.();
    buttonHandle?.();
  }

  useEffect(() => {
    async function buildComponent(error: Response): Promise<void> {
      const em = await errorMessage(error, t('ErrorPage.TryAgain'));
      const et = errorType(error);
      const errorPageButtonLabel = getButtonLabel(em, et);
      const errorPageButtonHandle = getButtonHandle(em, et);
      setErrMsg(em);
      setErrType(et);
      setErrorPageButtonLabel(errorPageButtonLabel);
      setErrorPageButtonHandle(() => errorPageButtonHandle);
    }
    if (error instanceof Error) {
      if (error.message == 'Failed to fetch') {
        setErrMsg(t('ErrorPage.UnableToCommunicate'));
        setErrorPageButtonLabel(t('ErrorPage.Retry'));
      } else {
        setErrMsg(error.message);
        setErrorPageButtonLabel(t(buttonLabel ?? 'ErrorPage.Retry'));
      }
      setErrType('generic');
      setErrorPageButtonHandle(() => clearErrorAndExecuteHandle);
    } else {
      void buildComponent(error);
    }
  }, [error, buttonLabel]);

  return (
    <ErrorPage
      errorType={errType || 'generic'}
      errorMsg={errMsg}
      primaryButtonHandle={errorPageButtonHandle}
      primaryButtonLabel={errorPageButtonLabel}
    />
  );
}
