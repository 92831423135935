import React from 'react';
import { Text } from '@itwin/itwinui-react';
import type { CloseModal, ModalElement } from '../../../hooks/useModal';
import { t } from '../../../services/translation';
import { PWModal } from '../../pwModal';

type ConnectionAssociationConfirmationModalProps = {
  closeModal: CloseModal;
  onYes: () => void;
};

export function ConnectionAssociationConfirmationModal({
  closeModal,
  onYes
}: ConnectionAssociationConfirmationModalProps): ModalElement {
  return (
    <PWModal
      title={t('ManageConnections.UpdateProjectAssociation')}
      primaryButton={{ title: t('Generic.Yes'), onClick: onYes }}
      secondaryButton={{ title: t('Generic.Cancel'), onClick: closeModal }}
      onClose={closeModal}
      dialogProps={{ 'data-testid': 'ConnectionAssociationConfirmationModal' }}
    >
      <Text>{t('ManageConnections.ConfirmAssociationUpdate')}</Text>
    </PWModal>
  );
}
