import React, { useEffect, useMemo, useState } from 'react';
import { SvgFind } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import type { SavedSearchDefinition } from '../../../services/search';
import { t } from '../../../services/translation';
import { Chevron } from '../chevron/chevron';
import { LoadingNode } from '../loadingNode/loadingNode';
import { SavedSearchNode } from './savedSearchNode';
import type { SavedSearchCategory } from './savedSearchesNodes';

type SavedSearchesNodeProps = {
  savedSearches?: SavedSearchDefinition[];
  type: SavedSearchCategory;
  loadSearches: () => Promise<void>;
};
export function SavedSearchesNode({
  savedSearches,
  type,
  loadSearches
}: SavedSearchesNodeProps): JSX.Element {
  const { searchState } = useNavigationContext();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const nodeLabel =
    type == 'global'
      ? t('FolderExplorer.GlobalSearches')
      : t('FolderExplorer.PersonalSearches');

  const filteredSearches = useMemo(() => {
    function applicableSearches(search: SavedSearchDefinition): boolean {
      if (search.IsGlobal != (type == 'global')) {
        return false;
      }

      return true;
    }

    return savedSearches?.filter(applicableSearches);
  }, [savedSearches, type]);

  const rootSearches = useMemo(() => {
    return filteredSearches
      ?.filter((search) => search.ParentSavedSearchId == 0)
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1));
  }, [filteredSearches]);

  function onClick(): void {
    setIsExpanded((cur) => !cur);
    if (!savedSearches) {
      void loadSearches();
    }
  }

  useEffect(() => {
    const activeSearch = filteredSearches?.find(
      ({ instanceId }) => instanceId == searchState.savedSearch?.instanceId
    );
    if (activeSearch) {
      setIsExpanded(true);
    }
  }, [filteredSearches, searchState.savedSearch?.instanceId]);

  return (
    <>
      <div
        className={'tree-node'}
        onClick={onClick}
        onKeyPress={keyPressAsClick(onClick)}
        role="treeitem"
        tabIndex={0}
        data-testid={`${type} saved searches-tree-node`}
      >
        <Chevron
          hasChildren={!rootSearches || rootSearches.length > 0}
          isExpanded={isExpanded}
        />
        <SvgFind className="pw-icon-xs" data-testid="saved-search-filetype" />
        <Text
          as="span"
          className="node-label"
          data-testid={`${type} saved searches-node-label`}
        >
          {nodeLabel}
        </Text>
      </div>
      {isExpanded && (
        <div
          className="tree-children"
          data-testid={`${type} saved searches-tree-children`}
        >
          {!filteredSearches && <LoadingNode nodeType="savedSearch" />}
          {rootSearches &&
            rootSearches.map((search) => (
              <SavedSearchNode
                key={search.instanceId}
                savedSearch={search}
                savedSearches={filteredSearches ?? []}
              />
            ))}
        </div>
      )}
    </>
  );
}
