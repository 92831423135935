import './pwdmLink.css';

import React, { useEffect, useRef } from 'react';
import type { PWFileType } from '@bentley/pw-api';
import { SvgChevronLeft } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { SvgDeliverableManagement } from '../../components/icons/applications/SvgDeliverableManagement';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../context';
import { t } from '../../services/translation';
import { convertPWDocumentToDocumentLink } from './pwdmLinks';

export type OpenPWDMProps = {
  items: PWFileType[];
};

export function OpenPWDMMarkUp({ items }: OpenPWDMProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();
  const { primaryModal } = useNavigationContext();
  const { connection } = usePluginContext();

  const { pwdmAPI } = useEnvironmentContext();
  const { pwdmApi, setIFrame } = pwdmAPI;

  const overlayRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const closeHandle = () => {
    setIFrame(undefined);
    primaryModal.close();
  };

  const backButton = () => {
    if (pwdmApi) {
      pwdmApi.call('onCloseRequest');
    } else {
      closeHandle();
    }
  };

  useEffect(() => {
    if (iframeRef.current !== null) {
      setIFrame(iframeRef.current);
    }
  }, [iframeRef.current]);

  const documentsToSend = convertPWDocumentToDocumentLink(items, connection);

  useEffect(() => {
    if (pwdmApi) {
      pwdmApi.on('on-pwdm-loaded', () => {
        trackFeature('OPEN_PWDM');
        pwdmApi.call('onPwDocumentsSubmit', documentsToSend);
      });
      pwdmApi.on('on-pwdm-closed', () => {
        closeHandle();
      });
    }
  }, [pwdmApi]);

  function handleMouseDown(event: React.MouseEvent) {
    if (
      overlayRef &&
      overlayRef.current &&
      overlayRef.current.contains(event.target as Node) &&
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      closeHandle();
    }
  }

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        primaryModal.close();
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [closeHandle]);

  return (
    <>
      <div
        className="pwdm-markup-overlay"
        tabIndex={0}
        ref={overlayRef}
        onMouseDown={handleMouseDown}
        role="button"
      >
        <div className="pwdm-markup-modal" ref={modalRef}>
          <div className="pwdm-markup-modal-header">
            <button className={'modal-back'} onClick={backButton}>
              <SvgChevronLeft />
              <Text>{t('Generic.Back')}</Text>
            </button>
            <div className={'modal-title'}>
              <SvgDeliverableManagement
                data-testid="pwdm-icon"
                className="pwdm-icon"
              />
              {t('ProductName.ProjectWiseDeliverableManagement')}
            </div>
          </div>
          <div
            className={'modal-content'}
            title={t('MenuItem.SendToPWDM')}
            ref={iframeRef}
          />
        </div>
      </div>
    </>
  );
}
