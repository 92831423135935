import type { PWItem } from '@bentley/pw-api';
import type { Connection } from '../../services/support/connection';
import type { LinkItemType } from './getShareableLink';
import { getEncodedContext, getItemType } from './getShareableLink';

export const sharedProjectParam = 'project';
export const sharedSetParam = 'set';
export const sharedItemParam = 'item';
export const sharedItemType = 'type';
export const sharedDatasourceId = 'datasource';
export const sharedWorkArea = 'workArea';

export function getTeamsLink(
  connection: Connection,
  item: PWItem,
  teamsTabLink?: string,
  encodeSubEntityId = false
): string {
  const defaultTab = teamsTabLink || connection?.DefaultTab;
  if (!defaultTab) {
    return '';
  }

  const itemId = item.instanceId;
  const itemType = getItemType(item.className);
  const projectId = item.ParentGuid;
  const encodedContext = encodeSubEntityId
    ? getEncodedContext(
        connection.DatasourceInstanceId,
        itemId,
        itemType,
        connection.ProjectId
      )
    : undefined;

  return getTeamsLinkCore({
    teamsTabLink: defaultTab,
    itemId,
    itemType,
    projectId,
    encodedContext
  });
}

export function getTeamsSavedSearchLink(
  connection: Connection,
  savedSearchId: string,
  teamsTabLink?: string,
  encodeSubEntityId = false
): string {
  const defaultTab = teamsTabLink || connection?.DefaultTab;
  if (!defaultTab) {
    return '';
  }

  const encodedContext = encodeSubEntityId
    ? getEncodedContext(
        connection.DatasourceInstanceId,
        savedSearchId,
        'saved-search',
        connection.ProjectId
      )
    : undefined;

  return getTeamsLinkCore({
    savedSearchId,
    teamsTabLink: defaultTab,
    encodedContext
  });
}

export type TeamsLinkProps = {
  teamsTabLink?: string | null;
  itemId: string;
  itemType: LinkItemType;
  projectId: string;
  savedSearchId: string;
  encodedContext: string;
};

export function getTeamsLinkCore({
  teamsTabLink,
  itemId,
  itemType,
  projectId,
  savedSearchId,
  encodedContext
}: Partial<TeamsLinkProps>): string {
  if (!teamsTabLink) {
    return '';
  }

  const teamsUrl = new URL(teamsTabLink);
  const context = JSON.parse(
    teamsUrl.searchParams.get('context') as string
  ) as Record<string, unknown>;

  context.subEntityId =
    encodedContext ??
    ['', projectId, itemId, itemType, savedSearchId].join('--');

  teamsUrl.searchParams.set('context', JSON.stringify(context));
  return teamsUrl.href;
}
