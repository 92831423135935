import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Command } from '../../../actions/workflow';
import { useCommonWRECommands } from '../../../actions/workflow';
import {
  useActionableItems,
  useRootFolderSelected
} from '../../../components/toolbar';
import type { PWDataManager } from '../../../hooks/useData';
import { usePWDataManager } from '../../../hooks/useData';
import type { GetItemStatusBadge } from '../../../hooks/useItemStatusBadge';
import { useItemStatusBadge } from '../../../hooks/useItemStatusBadge';
import { useSavedSearchIModels } from '../../../hooks/useSavedSearchIModels';
import type { SelectedDocumentsPermissions } from '../../../hooks/useSelectedDocumentsPermissions';
import { useSelectedDocumentsPermissions } from '../../../hooks/useSelectedDocumentsPermissions';
import type { SelectedState } from '../../../hooks/useSelectedItems';
import { useSelectedItems } from '../../../hooks/useSelectedItems';
import type { TableItemAction } from '../../../hooks/useTableItemActions';
import { useTableItemAction } from '../../../hooks/useTableItemActions';
import { useTableLoading } from '../../../hooks/useTableLoading';

type TableItemContext = {
  actionableItems: PWItem[];
  dataManager: PWDataManager;
  selectedDocumentsPermissions: SelectedDocumentsPermissions;
  getItemStatusBadge: GetItemStatusBadge;
  rootFolderSelected: boolean;
  savedSearchIModels?: string;
  selectedState: SelectedState;
  tableItemAction: TableItemAction;
  tableLoading: boolean;
  wreCommands: Command[] | undefined;
};

const Context = createContext<TableItemContext | undefined>(undefined);

export function TableItemProvider({
  children
}: ProviderProps<TableItemContext | undefined>): JSX.Element {
  const dataManager = usePWDataManager();
  const selectedState = useSelectedItems(dataManager);
  const actionableItems = useActionableItems(selectedState);
  const selectedDocumentsPermissions =
    useSelectedDocumentsPermissions(actionableItems);
  const tableItemAction = useTableItemAction(
    selectedState.forceSelectedIds,
    dataManager,
    selectedDocumentsPermissions.getMappedDocumentPermissionsById
  );
  const tableLoading = useTableLoading(dataManager);
  const wreCommands = useCommonWRECommands(selectedState.selectedItems);
  const rootFolderSelected = useRootFolderSelected(actionableItems);
  const savedSearchIModels = useSavedSearchIModels(dataManager);
  const getItemStatusBadge = useItemStatusBadge();

  const tableItemContext = useMemo(
    (): TableItemContext => ({
      actionableItems,
      dataManager,
      selectedDocumentsPermissions,
      getItemStatusBadge,
      rootFolderSelected,
      savedSearchIModels,
      selectedState,
      tableItemAction,
      tableLoading,
      wreCommands
    }),
    [
      actionableItems,
      dataManager,
      selectedDocumentsPermissions,
      getItemStatusBadge,
      rootFolderSelected,
      savedSearchIModels,
      selectedState,
      tableItemAction,
      tableLoading,
      wreCommands
    ]
  );

  return (
    <Context.Provider value={tableItemContext}>{children}</Context.Provider>
  );
}

export function useTableItemContext(): TableItemContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useTableItemContext must be used within a TableItemProvider'
    );
  }
  return context;
}
