import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { t } from '../../../../services/translation';
import { useCancelWizard } from '../../cancel';
import {
  uploadInProgressState,
  useDocumentCreation,
  useFlatSetCreation
} from '../../create';
import { useCopy } from '../../create/useCopy';
import { useRecoilValueAsync } from '../../state';
import { CreatingDocument } from '../creatingDocument';
import { NavigationButtons } from '../navigationButtons';
import {
  currentStepIndexState,
  isCopyModeState,
  isFlatSetCreationModeState,
  showAttributeStepState,
  showDocCodeStepState
} from '../state';
import { PropertiesSection } from './propertiesSection';
import { propertiesFormValidState } from './state';

export function PropertiesStep(): JSX.Element {
  const { create } = useDocumentCreation();
  const { create: createFlatSet } = useFlatSetCreation();
  const { copyDocuments } = useCopy();
  const { cancelWizard } = useCancelWizard();

  const uploadInProgress = useRecoilValue(uploadInProgressState);

  const setCurrentStepIndex = useSetRecoilState(currentStepIndexState);
  const showDocCodeStep = useRecoilValueAsync(showDocCodeStepState);
  const showAttributeStep = useRecoilValueAsync(showAttributeStepState);
  const propertiesFormValid = useRecoilValue(propertiesFormValidState);

  const isFlatSetCreationMode = useRecoilValue(isFlatSetCreationModeState);
  const isCopyMode = useRecoilValue(isCopyModeState);

  const actionButtonTitle = isCopyMode
    ? t('Generic.Copy')
    : t('Generic.Create');

  function showBackButton(): boolean {
    if (showDocCodeStep) {
      return true;
    }
    return false;
  }

  function allowBack(): boolean {
    return !uploadInProgress;
  }

  function onBack(): void {
    setCurrentStepIndex((cur) => cur - 1);
  }

  function allowNext(): boolean {
    return propertiesFormValid;
  }

  function onNext(): void {
    setCurrentStepIndex((cur) => cur + 1);
  }

  function allowCreate(): boolean {
    if (uploadInProgress) {
      return false;
    }
    return propertiesFormValid;
  }

  function onCreate(): void {
    if (isFlatSetCreationMode) {
      void createFlatSet();
    } else if (isCopyMode) {
      void copyDocuments();
    } else {
      void create();
    }
  }

  if (uploadInProgress) {
    return <CreatingDocument />;
  }

  return (
    <>
      <PropertiesSection />
      <NavigationButtons
        backButton={{
          children: t('Generic.Back'),
          onClick: onBack,
          disabled: !allowBack(),
          hidden: !showBackButton()
        }}
        nextButton={{
          children: t('Generic.Next'),
          onClick: onNext,
          disabled: !allowNext(),
          hidden: !showAttributeStep
        }}
        createButton={{
          children: actionButtonTitle,
          onClick: onCreate,
          disabled: !allowCreate(),
          styleType: 'cta'
        }}
        cancelButton={{ children: t('Generic.Cancel'), onClick: cancelWizard }}
      />
    </>
  );
}
