import type { PWFileType } from '@bentley/pw-api';
import { itemFileExtension } from '@bentley/pw-api';
import type { FeatureName } from '../../hooks/useTrackFeature';

export const imageMimeTypes = [
  'image/gif',
  'image/x-icon',
  'raster',
  'image/bmp',
  'image/jpeg',
  'image/png'
];

export function openBlobAsUrl(
  blob: Blob,
  document: PWFileType,
  trackFeature: (featureName: FeatureName) => void
): void {
  const extension = itemFileExtension(document);
  if (extension === 'pdf') {
    trackFeature('VIEW_ITEM_PDF');
  } else if (extension === 'txt') {
    trackFeature('VIEW_ITEM_TXT');
  } else if (imageMimeTypes.includes(document.MimeType)) {
    trackFeature('VIEW_ITEM_IMAGE');
  }

  const url = window.URL.createObjectURL(blob);
  window.addEventListener('beforeunload', () => {
    window.URL.revokeObjectURL(url);
  });

  const viewerTab = window.open(url, '_blank');

  if (viewerTab) {
    viewerTab.document.title = document.FileName;
  }

  // downloadingTab.close();
}
