import React, { useState } from 'react';
import type { HttpService, PWDataItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Label, StatusMessage, ToggleSwitch } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import { castFileToDataItem } from '../../hooks/useProgressManager/utils';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { updateFile } from './update';

interface IUpdateFileModalProps {
  oldItem: PWDataItem;
  newFile: File;
  httpService: HttpService;
  trackFeature: TrackFeature;
  onSuccess: () => void;
}
function UpdateFileModal({
  oldItem,
  newFile,
  httpService,
  trackFeature,
  onSuccess
}: IUpdateFileModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();
  const [checkInFile, setCheckInFile] = useState<boolean>(false);
  const isMatchingFileName = oldItem.FileName === newFile.name;

  return (
    <PWModal
      title={t('Update.UpdateFile')}
      primaryButton={{
        title: t('Generic.Update'),
        onClick: () => {
          primaryModal.close();
          void updateFile(
            newFile,
            oldItem,
            checkInFile,
            httpService,
            trackFeature,
            onSuccess
          );
        }
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'UpdateFileModal' }}
    >
      <Label>{t('Update.UpdateThisFile')}:</Label>
      <ItemSummary useFileName={true} item={[oldItem]} />
      <Label>{t('Replace.WithThisFile')}:</Label>
      <ItemSummary useFileName={true} item={[castFileToDataItem(newFile)]} />
      <ToggleSwitch
        defaultChecked={checkInFile}
        onChange={() => setCheckInFile(!checkInFile)}
        label={t('Update.CheckInUponUpdate')}
        labelPosition="right"
      />
      {!isMatchingFileName && (
        <StatusMessage status="warning">
          {t('Update.DifferentFileNameWarning')}
        </StatusMessage>
      )}
    </PWModal>
  );
}
export default UpdateFileModal;
