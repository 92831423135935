import './documentWizardModal.css';

import React, { useEffect } from 'react';
import { useFeatureTracking } from '../../../context';
import { t } from '../../../services/translation';
import { PWModal } from '../../pwModal';
import type { DCWProps } from './documentCodeWizard';
import { DocumentCodeWizard } from './documentCodeWizard';

export function DocumentWizardModal(props: DCWProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();

  function getModalTitle(): string {
    if (props.isFlatSetCreationMode) {
      if (!props.isCopyMode) {
        return t('FlatSet.Create');
      }

      if (props.numberOfFiles == 1) {
        return `${t('FlatSet.CopySingle', {
          name: props.defaultDocumentName
        })}`;
      }

      return `${t('FlatSet.CopyMulti', {
        name: props.defaultDocumentName,
        currentIndex: props.fileNumber + 1,
        totalCount: props.totalFileCount
      })}`;
    }

    const docsTitle = props.totalFileCount == 1 ? 'document' : 'documents';
    const actionTitle = props.isCopyMode ? 'Copy' : 'Create';

    if (props.numberOfFiles == 1) {
      return `${t('DocumentCreation.CreateSingleDocument', {
        actionTitle: actionTitle,
        docsTitle: docsTitle,
        name: props.fileName
      })}`;
    }

    return `${t('DocumentCreation.CreateMultiDocument', {
      actionTitle: actionTitle,
      docsTitle: docsTitle,
      name: props.fileName,
      currentIndex: props.fileNumber + 1,
      totalCount: props.totalFileCount
    })}`;
  }

  useEffect(() => {
    trackFeature('DCW_OPEN');
  }, [trackFeature]);

  return (
    <PWModal
      title={getModalTitle()}
      onClose={() => props.onCancel()}
      className="document-wizard-modal"
      size="l"
    >
      <DocumentCodeWizard {...props} />
    </PWModal>
  );
}
