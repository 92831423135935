import { useMemo } from 'react';
import type { PWParentType } from '@bentley/pw-api';
import { useNavigationContext } from '../../../context';
import { t } from '../../../services/translation';
import type { AppViewSetting } from '../../useAppView';

export function useSearchResultBreadcrumb(
  currentAppView: AppViewSetting,
  itemCount: number | undefined
): PWParentType {
  const { searchState } = useNavigationContext();

  const searchName = useMemo((): string => {
    if (currentAppView.type == 'AdvancedSearch') {
      return t('Search.AdvancedSearchResults', { resultsCount: itemCount });
    }
    if (currentAppView.type == 'SavedSearch') {
      return t('Search.SavedSearchWithNameAndCount', {
        name: searchState.savedSearch?.Name,
        resultsCount: itemCount
      });
    }
    if (currentAppView.type == 'OutToMe') {
      return itemCount !== undefined
        ? `${t('Generic.OutToMe')} [${itemCount}]`
        : t('Generic.OutToMe');
    }

    if (currentAppView.type == 'RecycleBin') {
      return t('RecycleBin.DeletedItems', {
        resultsCount: itemCount
      });
    }
    return t('Search.SearchResults', { resultsCount: itemCount });
  }, [currentAppView.type, itemCount, searchState.savedSearch?.Name]);

  const getSearchResultBreadcrumbName = useMemo((): string => {
    if (
      itemCount === undefined &&
      currentAppView.type != 'OutToMe' &&
      currentAppView.type != 'RecycleBin'
    ) {
      return `${t('Grid.Searching')}`;
    }
    return searchName;
  }, [currentAppView.type, searchName, itemCount]);

  const getSearchResultBreadcrumbClass = useMemo((): string => {
    if (currentAppView.type == 'SavedSearch') {
      return 'SavedSearchDefinition';
    }
    if (currentAppView.type == 'OutToMe') {
      return 'OutToMe';
    }
    return 'Search';
  }, [currentAppView.type]);

  const searchResultBreadcrumb = useMemo(
    () =>
      ({
        Name: getSearchResultBreadcrumbName,
        className: getSearchResultBreadcrumbClass
      } as PWParentType),
    [getSearchResultBreadcrumbClass, getSearchResultBreadcrumbName]
  );

  return searchResultBreadcrumb;
}
