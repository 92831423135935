import React from 'react';
import type { PWParentType } from '@bentley/pw-api';
import { useAppViewContext, useNavigationContext } from '../../context';
import type { BreadcrumbManager } from '../../hooks/useBreadcrumb';
import { Breadcrumbs } from './breadcrumbs';

export function PWBreadcrumbs(): JSX.Element {
  const { breadcrumbManager, navigationManager } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView },
    searchBreadcrumbManager
  } = useAppViewContext();

  function navigateTo(item: PWParentType): void {
    const rebuildBreadcrumbs = currentAppView.type != 'FolderNavigation';
    setAppView('FolderNavigation');
    navigationManager.navigateTo(item, rebuildBreadcrumbs);
  }

  const currentBreadcrumbs =
    currentAppView.type == 'FolderNavigation'
      ? breadcrumbManager.breadcrumbs
      : searchBreadcrumbManager.breadcrumbs;
  const breadcrumbManagerWithSearch = {
    ...breadcrumbManager,
    breadcrumbs: currentBreadcrumbs
  } as BreadcrumbManager;

  return (
    <Breadcrumbs
      breadcrumbManager={breadcrumbManagerWithSearch}
      navigateTo={navigateTo}
    />
  );
}
