import '../../styles/plugin.css';

import type { ReactNode } from 'react';
import React, { useEffect } from 'react';
import { RecoilEnv } from 'recoil';
import { ThemeProvider } from '@itwin/itwinui-react';
import { tooltipRoot } from '../../constants';
import { PWToastProvider } from '../../services/pwToast';
import { PWErrorBoundary } from '../guards';

type PluginWrapperProps = {
  children?: ReactNode;
};

export function PluginWrapper({ children }: PluginWrapperProps): JSX.Element {
  useEffect(() => {
    // Hide Recoil hot-reload warning in non-production environments
    RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
  }, []);

  return (
    <ThemeProvider
      theme={'inherit'}
      themeOptions={{ applyBackground: true }}
      className="pw-web-plugin-root"
    >
      <PWErrorBoundary>
        <PWToastProvider />
        <div id={tooltipRoot} data-testid="tooltip-root" />
        {children}
      </PWErrorBoundary>
    </ThemeProvider>
  );
}
