import { useCallback } from 'react';
import type { WSGInstance, WSGProperties } from '@bentley/pw-api';
import { currentEnvironmentInstanceState, useRecoilStateAsync } from '../state';
import { mergePropertiesIntoInstance } from './merge';

type MergePropertiesFunctions = {
  setCurrentInstanceWithProperties: (
    properties: WSGProperties
  ) => WSGInstance | null;
  setInstanceWithProperties: (
    instance: WSGInstance,
    properties: WSGProperties
  ) => WSGInstance;
};

export function useSetProperties(): MergePropertiesFunctions {
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilStateAsync(currentEnvironmentInstanceState);

  const setInstanceWithProperties = useCallback(
    (instance: WSGInstance, properties: WSGProperties): WSGInstance => {
      const updatedInstance = mergePropertiesIntoInstance(instance, properties);
      setCurrentEnvironmentInstance(updatedInstance);
      return updatedInstance;
    },
    [setCurrentEnvironmentInstance]
  );

  const setCurrentInstanceWithProperties = useCallback(
    (properties: WSGProperties): WSGInstance | null => {
      if (!currentEnvironmentInstance) {
        return currentEnvironmentInstance ?? null;
      }
      const updatedInstance = setInstanceWithProperties(
        currentEnvironmentInstance,
        properties
      );
      return updatedInstance;
    },
    [currentEnvironmentInstance, setInstanceWithProperties]
  );

  return {
    setCurrentInstanceWithProperties,
    setInstanceWithProperties
  };
}
