import { datasourcePrefix } from '../../constants';
import type { Application } from './getShareableLink';
import { sharedDatasourceId } from './getShareableLink';
import type { UniversalLinkType } from './universalLinkType';

export const savedSearchIdParam = 'savedSearchId';

export type SavedSearchProps = {
  datasource: string;
  projectId?: string;
  workAreaId?: string;
  application: Application;
  savedSearchId: string;
  apiURL?: string;
  shortenLink?: boolean;
};

const linkConstants = {
  datasource: 'ds',
  workAreaId: 'wa',
  projectId: 'p',
  savedSearchId: 'sid'
};

export function getSavedSearchLink({
  datasource,
  projectId,
  workAreaId,
  application,
  savedSearchId,
  apiURL,
  shortenLink
}: SavedSearchProps): string {
  let datasourceQuery, projectQuery, workAreaQuery, savedSearchQuery;
  const appLinkType = getAppLinkType(application);
  const applicationQuery = appLinkType ? `&app=${appLinkType}` : '';

  if (shortenLink) {
    // Remove 'Bentley.PW--' from datasource name
    if (datasource.startsWith(datasourcePrefix)) {
      datasource = datasource.substring(datasourcePrefix.length);
    }

    // Remove hyphens from GUIDs
    projectId = projectId?.replace(/-/g, '');
    workAreaId = workAreaId?.replace(/-/g, '');

    // Shorten link constants' names and combine objectType and objectId params
    datasourceQuery = `${linkConstants.datasource}=${datasource}`;
    projectQuery = projectId ? `&${linkConstants.projectId}=${projectId}` : '';
    workAreaQuery = workAreaId
      ? `&${linkConstants.workAreaId}=${workAreaId}`
      : '';
    savedSearchQuery = `&${linkConstants.savedSearchId}=${savedSearchId}`;
  } else {
    datasourceQuery = `${sharedDatasourceId}=${datasource}`;
    projectQuery = projectId ? `&projectId=${projectId}` : '';
    workAreaQuery = workAreaId ? `&workAreaId=${workAreaId}` : '';
    savedSearchQuery = `&savedSearchId=${savedSearchId}`;
  }

  apiURL = apiURL ? apiURL : location.origin;

  if (!apiURL.endsWith('/')) {
    apiURL = `${apiURL}/`;
  }

  if (application == 'WebView') {
    return `${apiURL}saved-search?${datasourceQuery}${projectQuery}${savedSearchQuery}${applicationQuery}`;
  } else {
    return `${apiURL}saved-search?${datasourceQuery}${projectQuery}${workAreaQuery}${savedSearchQuery}${applicationQuery}`;
  }
}

function getAppLinkType(
  application: Application
): UniversalLinkType | undefined {
  if (application == 'Explorer') {
    return 'pwe';
  }
  if (application == 'Web') {
    return 'web';
  }
  if (application == 'WebView') {
    return 'webview';
  }
  return;
}
