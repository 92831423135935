import { useCallback, useEffect, useState } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../context';

export type CoAuthoringSettings = {
  isCoAuthoringEnabled: boolean;
  sharePointSiteId?: string;
};

export function useCoAuthoringSettings(): CoAuthoringSettings {
  const { getOidcToken } = useToken();
  const { contextId, buddiRegionCode } = usePluginContext();

  const collaborationServiceUrl = useBuddi(
    'Office365CollaborationService.Url',
    buddiRegionCode
  );

  const [coAuthoringSettings, setCoAuthoringSettings] =
    useState<CoAuthoringSettings>({ isCoAuthoringEnabled: false });

  const fetchCoAuthoringSettings = useCallback(
    async (baseUrl: string, contextId: string) => {
      const token = await getOidcToken();
      try {
        const response = await fetch(
          `${baseUrl}/api/v1/context/${contextId}/Settings/$aggregate`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${token ?? ''}` }
          }
        );
        return response.ok
          ? ((await response.json()) as CoAuthoringSettings)
          : undefined;
      } catch {
        return undefined;
      }
    },
    [getOidcToken]
  );

  useEffect(() => {
    async function initializeCoAuthoringSettings(): Promise<void> {
      if (!contextId || !collaborationServiceUrl) {
        return;
      }

      const settings = await fetchCoAuthoringSettings(
        collaborationServiceUrl,
        contextId
      );
      if (settings) {
        setCoAuthoringSettings(settings);
      }
    }

    void initializeCoAuthoringSettings();
  }, [contextId, collaborationServiceUrl, fetchCoAuthoringSettings]);

  return coAuthoringSettings;
}
