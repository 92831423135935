import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { ColumnWidthManager } from '../../../hooks/useColumnWidths';
import { useColumnWidths } from '../../../hooks/useColumnWidths';
import type { PropertyManager } from '../../../hooks/useProperties';
import { useProperties } from '../../../hooks/useProperties';
import type { ViewManager } from '../../../hooks/useViews';
import { useViews } from '../../../hooks/useViews';

type PropertyContext = {
  propertyManager: PropertyManager;
  viewManager: ViewManager;
  columnWidthManager: ColumnWidthManager;
};

const Context = createContext<PropertyContext | undefined>(undefined);

export function PropertyProvider({
  children
}: ProviderProps<PropertyContext | undefined>): JSX.Element {
  const propertyManager = useProperties();
  const viewManager = useViews();
  const columnWidthManager = useColumnWidths(viewManager.currentView);

  const propertyContext = useMemo(
    (): PropertyContext => ({
      propertyManager,
      viewManager,
      columnWidthManager
    }),
    [columnWidthManager, propertyManager, viewManager]
  );

  return (
    <Context.Provider value={propertyContext}>{children}</Context.Provider>
  );
}

export function usePropertyContext(): PropertyContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('usePropertyContext must be used within a PropertyContext');
  }
  return context;
}
