import {
  parseFileExtension,
  parseFileNameWithoutExtension,
  pwConstants
} from '@bentley/pw-api';
import { replaceInvalidFileNameCharacters } from '../../../actions/rename';

export function buildFileName(name: string, fileName: string): string {
  const extension = parseFileExtension(fileName);

  if (!extension) {
    return name;
  }

  if (!name) {
    return `.${extension}`;
  }

  if (parseFileExtension(name) != extension.toLowerCase()) {
    name = `${name}.${extension}`;
  }

  const validFileName = replaceInvalidFileNameCharacters(name);

  if (validFileName.length <= pwConstants.maxFileNameLength) {
    return validFileName;
  }

  const truncatedName = parseFileNameWithoutExtension(name).substring(
    0,
    pwConstants.maxFileNameLength - extension.length - 1
  );

  return `${truncatedName}.${extension}`;
}
