import type { HttpService, WSGInstance, WSGProperties } from '@bentley/pw-api';
import type { ProductSettingType } from '../../../hooks/useProductSetting';

function getSettingUrl(
  settingType: ProductSettingType,
  contextId: string | undefined,
  gprId: number,
  isSharedSetting: boolean
) {
  const contextString =
    settingType == 'Context' && contextId ? `/${contextId}` : '';
  return `Application/${gprId}/${settingType}${contextString}/${
    isSharedSetting ? 'SharedSetting' : 'Setting'
  }/`;
}

export async function putSetting(
  httpService: HttpService,
  contextId: string | undefined,
  gprId: number,
  key: string,
  valueJson: Record<string, unknown>,
  settingType: ProductSettingType,
  isSharedSetting: boolean
): Promise<Response> {
  const settingUrl = getSettingUrl(
    settingType,
    contextId,
    gprId,
    isSharedSetting
  );
  const response = await httpService.put(
    settingUrl + key,
    JSON.stringify({
      name: key,
      properties: valueJson
    })
  );
  return response;
}

export async function getSetting(
  httpService: HttpService,
  contextId: string | undefined,
  gprId: number,
  key: string,
  settingType: ProductSettingType,
  isSharedSetting: boolean
): Promise<WSGProperties | undefined> {
  const settingUrl = getSettingUrl(
    settingType,
    contextId,
    gprId,
    isSharedSetting
  );
  try {
    const response = await httpService.get(settingUrl + key);

    if (response.ok) {
      const json = (await response.json()) as WSGInstance;
      return json.properties;
    }
  } catch {
    return undefined;
  }

  return undefined;
}
