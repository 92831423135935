import type { HttpService, PWDataItem, PWItem } from '@bentley/pw-api';
import {
  modifyItem,
  parseRelatedParent,
  pwConstants,
  replaceDocumentFile
} from '@bentley/pw-api';
import { openFileInput } from '../../components/fileInput';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import type { ToastHandle } from '../../services/pwToast';
import { fileNameChangeAllowed } from '../rename/renameFileRequirements';
import {
  afterUploadDocumentFailure,
  afterUploadDocumentSuccess,
  uploadResponseCallback
} from '../upload/responses';
import { fileNameTruncationModal, replaceFileModal } from './modals';
import { getUpdatedName } from './names';
import {
  notifyErrorUpdatingProperties,
  notifyFailedPermissionCheck,
  notifyReplaceInProgress,
  sendReplaceCompleteNotification
} from './notifications';

export async function startReplaceFile(
  oldDocument: PWDataItem,
  isCannedConnection: boolean,
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  onSuccess: () => void,
  progressManager: ProgressManager
): Promise<void> {
  const differentFileNameAllowed = await checkFileNameChangePermission(
    httpService
  );
  const handleInput = (newFile: File) => {
    replaceFileModal(
      isCannedConnection,
      openModal,
      closeModal,
      oldDocument,
      newFile,
      httpService,
      trackFeature,
      onSuccess,
      progressManager,
      differentFileNameAllowed
    );
  };
  openFileInput(handleInput);
}

export async function replaceFileWorkflow(
  newFile: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager?: ProgressManager,
  toastHandle?: ToastHandle,
  onSuccess?: (uploadedItems: PWItem[]) => void,
  openModal?: OpenModal,
  closeModal?: CloseModal,
  useOldName?: boolean,
  nameAndFileNameMustMatch?: boolean
): Promise<Response | undefined> {
  if (
    openModal &&
    closeModal &&
    newFile.name.length > pwConstants.maxFileNameLength
  ) {
    fileNameTruncationModal(
      openModal,
      closeModal,
      newFile,
      oldItem,
      httpService,
      trackFeature,
      progressManager ?? undefined,
      onSuccess,
      nameAndFileNameMustMatch
    );
    return;
  }

  if (!toastHandle) {
    toastHandle = notifyReplaceInProgress();
  }
  let jobId;

  if (progressManager && toastHandle) {
    jobId = progressManager.addNewJobToTracker('upload', toastHandle);
  }

  const response = await replaceFile(
    newFile,
    oldItem,
    httpService,
    trackFeature,
    useOldName ?? false,
    progressManager,
    jobId,
    onSuccess,
    nameAndFileNameMustMatch
  );

  sendReplaceCompleteNotification(
    response,
    newFile.name,
    oldItem.Name,
    toastHandle
  );
  return response;
}

export async function replaceFile(
  newFile: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  trackFeature: TrackFeature,
  useOldName: boolean,
  progressManager?: ProgressManager,
  jobId?: string,
  onSuccess?: (uploadedItems: PWItem[]) => void,
  nameAndFileNameMustMatch?: boolean
): Promise<Response> {
  trackFeature('REPLACE_FILE');

  const abortController = new AbortController();
  let uniqueId = '';
  if (progressManager && jobId) {
    uniqueId = progressManager.addReplaceToJob(jobId, newFile, abortController);
  }

  const oldName = oldItem.FileName !== '' ? oldItem.FileName : oldItem.Name;
  const fileName = getUpdatedName(useOldName, oldName, newFile.name);

  try {
    const response = await replaceDocumentFile({
      file: newFile,
      documentOptions: { instanceId: oldItem.instanceId, FileName: fileName },
      httpService: httpService,
      responseCallback: uploadResponseCallback(
        uniqueId,
        jobId,
        progressManager
      ),
      requestOptions: { abortController }
    });

    if (response.status == 201) {
      afterUploadDocumentSuccess(uniqueId, jobId, progressManager);
    }
    if (response.status == 200) {
      if (nameAndFileNameMustMatch) {
        await updateFileAttributes(newFile, oldItem, httpService, useOldName);
      }

      onSuccess?.([oldItem]);
      afterUploadDocumentSuccess(uniqueId, jobId, progressManager);

      if (jobId) {
        progressManager?.updateProgressStatus(jobId, uniqueId, 'success');
      }
    }
    return response;
  } catch (error) {
    if (error instanceof Response) {
      if (jobId) {
        progressManager?.updateProgressStatus(jobId, uniqueId, 'error', error);
        await afterUploadDocumentFailure(
          error,
          newFile,
          parseRelatedParent(oldItem),
          uniqueId,
          jobId,
          progressManager
        );
      }

      return error;
    }

    throw error;
  }
}

async function updateFileAttributes(
  newFile: File,
  oldItem: PWDataItem,
  httpService: HttpService,
  useOldName: boolean
): Promise<void> {
  const documentName = getUpdatedName(
    useOldName,
    oldItem.Name,
    newFile.name,
    true
  );

  const updateResponse = await modifyItem({
    item: oldItem,
    properties: { Name: documentName },
    httpService
  });

  if (updateResponse.status !== 200) {
    notifyErrorUpdatingProperties(documentName);
  }
}

async function checkFileNameChangePermission(
  httpService: HttpService
): Promise<boolean> {
  try {
    const response = await fileNameChangeAllowed(httpService);
    return response;
  } catch (error) {
    const errorMessage: string = (error as Error)?.message ?? 'Unknown Error';
    notifyFailedPermissionCheck(errorMessage);
    return true;
  }
}
