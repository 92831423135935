import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgVisibilityShow } from '@itwin/itwinui-icons-react';
import { allowPDFMarkup, fileIsPdf, useOpenPDF } from '../../../../actions/pdf';
import {
  useAppViewContext,
  useConnectionAuth,
  useFeatureTracking,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgPDFMarkup } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function usePDFItemAction(items: PWItem[]): MenuItemAction {
  const { readOnly, dataAccessLevel } = usePluginContext();
  const { isLogicalUser } = useConnectionAuth();
  const {
    launchDarklyFeatures: {
      showWebViewDocumentViewer,
      showPdfMarkupDocumentPreview,
      documentViewExcludedExtensions
    }
  } = useFeatureTracking();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const openPDF = useOpenPDF();

  const isPdfReview = useCallback(
    (item: PWItem) => {
      if (!item) {
        return false;
      }

      if (dataAccessLevel == 'Datasource') {
        return false;
      }

      return !showPdfMarkupDocumentPreview || fileIsPdf(item);
    },
    [dataAccessLevel, showPdfMarkupDocumentPreview]
  );

  const pdfItem = useMemo((): MenuItemAction => {
    const pdfReview = isPdfReview(items[0]);
    const excludedExtensions = String(
      documentViewExcludedExtensions || ''
    ).split(',');

    return {
      title: pdfReview ? t('MenuItem.PDFReview') : t('MenuItem.ViewDocument'),
      icon: pdfReview ? <SvgPDFMarkup /> : <SvgVisibilityShow />,
      onClick: () => openPDF(items[0]),
      hidden:
        !allowPDFMarkup(
          items,
          readOnly,
          excludedExtensions,
          isLogicalUser,
          Boolean(showPdfMarkupDocumentPreview),
          Boolean(showWebViewDocumentViewer)
        ) || currentAppView.type == 'RecycleBin'
    };
  }, [
    currentAppView.type,
    documentViewExcludedExtensions,
    isPdfReview,
    items,
    openPDF,
    readOnly,
    isLogicalUser,
    showPdfMarkupDocumentPreview,
    showWebViewDocumentViewer
  ]);

  return pdfItem;
}
