import type {
  WSGChangedInstanceResponse,
  WSGInstance,
  WSGProperties
} from '@bentley/pw-api';
import { mergePropertiesIntoInstance } from '../properties';
import { getDCWHttpService } from '../state';

export async function generateDocCodeProperties(
  environmentInstance: WSGInstance
): Promise<WSGProperties> {
  const generatedDocCodeProperties = await getGeneratedDocCodeProperties(
    environmentInstance
  );

  const updatedInstance = mergePropertiesIntoInstance(
    environmentInstance,
    generatedDocCodeProperties
  );

  const formattedDocCodeProperties = await getDocCodeProperties(
    updatedInstance
  );
  return formattedDocCodeProperties;
}

async function getGeneratedDocCodeProperties(
  environmentInstance: WSGInstance
): Promise<WSGProperties> {
  const docCodeActionInstance = getDocumentCodeActionInstance(
    environmentInstance,
    'generate'
  );
  const data = await updateDocumentCodeAction(docCodeActionInstance);
  const changedInstance = data.changedInstance.instanceAfterChange;

  const environmentInstances = changedInstance.relationshipInstances?.map(
    (relationshipInstance) => relationshipInstance.relatedInstance
  );

  const updatedProperties = environmentInstances?.[0]?.properties ?? {};

  return updatedProperties;
}

export async function getDocCodeProperties(
  environmentInstance: WSGInstance
): Promise<WSGProperties> {
  const docCodeActionInstance = getDocumentCodeActionInstance(
    environmentInstance,
    'format'
  );
  const data = await updateDocumentCodeAction(docCodeActionInstance);
  const changedInstance = data.changedInstance.instanceAfterChange;

  const environmentInstances = changedInstance.relationshipInstances?.map(
    (relationshipInstance) => relationshipInstance.relatedInstance
  );

  const updatedProperties = environmentInstances?.[0]?.properties ?? {};
  const updatedInstance = mergePropertiesIntoInstance(
    environmentInstance,
    updatedProperties
  );
  return updatedInstance.properties ?? {};
}

export async function formatDocCodeString(
  environmentInstance: WSGInstance
): Promise<string> {
  const docCodeActionInstance = getDocumentCodeActionInstance(
    environmentInstance,
    'format'
  );
  const data = await updateDocumentCodeAction(docCodeActionInstance);
  const properties = data.changedInstance.instanceAfterChange?.properties ?? {};
  const docCode = properties?.Value?.toString() ?? '';
  return docCode;
}

async function updateDocumentCodeAction(
  instance: WSGInstance
): Promise<WSGChangedInstanceResponse> {
  const body = JSON.stringify({ instance });
  const httpService = getDCWHttpService();
  const response = await httpService.post('PW_WSG/DocumentCodeAction', body);
  throwIfBadResponse(response);
  const data = (await response.json()) as WSGChangedInstanceResponse;
  return data;
}

function getDocumentCodeActionInstance(
  environmentInstance: WSGInstance,
  type: 'generate' | 'format'
): WSGInstance {
  return {
    instanceId: '',
    schemaName: 'PW_WSG',
    className: 'DocumentCodeAction',
    properties: { type },
    relationshipInstances: [
      {
        className: 'DocumentCodeActionEnvironment',
        schemaName: 'PW_WSG',
        direction: 'backward',
        relatedInstance: {
          relationshipInstances: [],
          properties: environmentInstance.properties,
          instanceId: '0-0-0-0',
          schemaName: 'PW_WSG_Dynamic',
          className: environmentInstance.className
        }
      }
    ]
  };
}

function throwIfBadResponse(response: Response): void {
  if (!response.ok) {
    throw response;
  }
}
