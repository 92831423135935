import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgLink } from '@itwin/itwinui-icons-react';
import {
  GetShareableLinkModal,
  useLinkRequirements
} from '../../../../actions/getShareableLink';
import { useAppViewContext, useNavigationContext } from '../../../../context';
import type { SavedSearchDefinition } from '../../../../services/search';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import type { ItemMenuType } from '../useItemActions';

type LinkItemActions = (menuType: ItemMenuType) => MenuItemAction;

export function useLinkItemActions(
  items: PWItem[],
  savedSearch?: SavedSearchDefinition
): LinkItemActions {
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const allowGetLink = useLinkRequirements();

  const linkToItem = useMemo(
    (): MenuItemAction => ({
      title:
        items.length > 1 ? t('SharedLink.GetLinks') : t('SharedLink.GetLink'),
      icon: <SvgLink />,
      onClick: () =>
        primaryModal.open(
          <GetShareableLinkModal items={items} onClose={primaryModal.close} />
        ),
      'data-testid': 'GetlinkButton',
      disabled: !allowGetLink(items),
      hidden: currentAppView.type == 'RecycleBin'
    }),
    [items, allowGetLink, currentAppView.type, primaryModal]
  );

  const linkToSavedSearch = useMemo(
    (): MenuItemAction => ({
      title: t('SharedLink.GetLinkSavedSearch'),
      icon: <SvgLink />,
      onClick: () =>
        primaryModal.open(
          <GetShareableLinkModal
            items={[savedSearch as SavedSearchDefinition]}
            onClose={primaryModal.close}
          />
        )
    }),
    [primaryModal, savedSearch]
  );

  const savedSearchDropdown = useMemo(
    (): MenuItemAction => ({
      title: t('SharedLink.GetLink'),
      icon: <SvgLink />,
      submenuItems: [
        { ...linkToSavedSearch, title: t('MenuItem.LinkToSearch') },
        {
          ...linkToItem,
          title:
            items.length > 1
              ? t('MenuItem.LinkToSelectedItems')
              : t('MenuItem.LinkToSelectedItem')
        }
      ]
    }),
    [items.length, linkToItem, linkToSavedSearch]
  );

  const getLinkItemActions = useCallback(
    (menuType: ItemMenuType): MenuItemAction => {
      // Saved search menu for toolbar
      if (savedSearch && menuType == 'toolbarMenu') {
        return savedSearchDropdown;
      }

      // Saved search link for saved search node in folder explorer
      if (savedSearch && menuType == 'contextMenu' && !items.length) {
        return linkToSavedSearch;
      }

      // Item link for context menu, folder explorer, and toolbar
      return linkToItem;
    },
    [items, linkToItem, linkToSavedSearch, savedSearch, savedSearchDropdown]
  );

  return getLinkItemActions;
}
