import { useCallback } from 'react';
import type { Select } from '@bentley/pw-api';
import { query } from '@bentley/pw-api';
import { useEnvironmentContext, useNavigationContext } from '../../../context';

type BuildAdvancedSearchQuery = () => string;

export function useBuildAdvancedSearch(): BuildAdvancedSearchQuery {
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { advancedSearch }
  } = useNavigationContext();

  const buildEnvironmentSelect = useCallback((): Select | undefined => {
    if (!advancedSearch) {
      throw new Error('Advanced search not set');
    }

    const { itemClass, environment } = advancedSearch;

    if (
      itemClass == 'Project' ||
      !ecPluginFeatures.environmentQueryPerformance()
    ) {
      return undefined;
    }

    if (environment && environment != 'General') {
      return {
        relationshipClass: {
          relationshipSchema: 'PW_WSG',
          relationshipClassName: 'DocumentEnvironment',
          direction: 'forward',
          relatedSchema: 'PW_WSG_Dynamic',
          relatedClassName: environment
        },
        properties: 'all'
      };
    }

    return {
      relationshipClass: {
        relationshipClassName: 'DocumentEnvironment',
        direction: 'forward',
        relatedClassName: 'Environment',
        relatedClassPolymorphic: true
      },
      properties: 'all'
    };
  }, [advancedSearch, ecPluginFeatures]);

  const buildSelect = useCallback((): Select[] => {
    if (!advancedSearch) {
      throw new Error('Advanced search not set');
    }

    const { itemClass } = advancedSearch;

    const select: Select[] = [
      { properties: 'all' },
      {
        relationshipClass: {
          relationshipClassName: `${itemClass}Parent`,
          direction: 'forward',
          relatedClassName: 'Project'
        },
        properties: 'all'
      }
    ];

    const environmentSelect = buildEnvironmentSelect();
    if (environmentSelect) {
      select.push(environmentSelect);
    }

    return select;
  }, [advancedSearch, buildEnvironmentSelect]);

  const buildAdvancedSearch = useCallback((): string => {
    if (!advancedSearch) {
      throw new Error('Advanced search not set');
    }

    const { query: filter, itemClass, ancestor } = advancedSearch;
    const select = buildSelect();

    const url =
      query({
        schemaName: 'PW_WSG',
        className: itemClass,
        polymorphic: true,
        filter: [],
        select: select,
        apiAncestor: ancestor,
        filterSettings: 'CaseInsensitive'
      }) + `&$filter=(${filter})`;

    return url;
  }, [advancedSearch, buildSelect]);

  return buildAdvancedSearch;
}
