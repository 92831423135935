import type { DynamicProjectSettings } from '../dynamicProjectSettings';
import type { DynamicFolderSettings } from '.';

export function projectSettingsToFolderSettings(
  projectSettings: DynamicProjectSettings,
  folderSettings?: DynamicFolderSettings
): DynamicFolderSettings {
  return {
    UseDCWInFoldersWithCode: projectSettings.UseDCWInFoldersWithCode,
    UseDCWInFoldersWithoutCode: projectSettings.UseDCWInFoldersWithoutCode,
    UseDCWInPWIC: projectSettings.UseDCWInPWIC,
    AutoCheckoutDocsInDrive:
      folderSettings?.AutoCheckoutDocsInDrive ??
      projectSettings.AutoCheckoutDocsInDrive,
    InheritProjectSettings: true,
    SettingsInherited: folderSettings?.SettingsInherited
  } as DynamicFolderSettings;
}

export function generatePostFolderSettingsBody(
  folderSettings: DynamicFolderSettings,
  projectSettings: DynamicProjectSettings
): string {
  if (folderSettings.InheritProjectSettings) {
    return JSON.stringify({
      documentWizard: {
        showIfDocumentCode: projectSettings.UseDCWInFoldersWithCode,
        showIfNoDocumentCode: projectSettings.UseDCWInFoldersWithoutCode,
        showIfPWIC: projectSettings.UseDCWInPWIC,
        inheritProjectSettings: true
      },
      autoCheckout: {
        enabled: folderSettings.AutoCheckoutDocsInDrive
      }
    });
  }
  return JSON.stringify({
    documentWizard: {
      showIfDocumentCode: folderSettings.UseDCWInFoldersWithCode,
      showIfNoDocumentCode: folderSettings.UseDCWInFoldersWithoutCode,
      showIfPWIC: folderSettings.UseDCWInPWIC,
      inheritProjectSettings: folderSettings.InheritProjectSettings
    },
    autoCheckout: {
      enabled: folderSettings.AutoCheckoutDocsInDrive
    }
  });
}

export function breakUpArray(array: string[], chunkSize: number): string[][] {
  return [...Array<string>(Math.ceil(array.length / chunkSize))].map((_) =>
    array.splice(0, chunkSize)
  );
}

export function buildMultiFolderRequestUrl(
  datasource: string,
  workAreaId: string,
  descendantIds: string[]
): string {
  return `FolderSettings?datasource=${datasource}&workAreaId=${workAreaId}&folderId=${descendantIds.join(
    ','
  )}`;
}
