import { t } from '../../services/translation';
import type { EnvironmentProperty } from './environmentProperty';

const remappedDisplayLabels = [
  { original: 'Created', modified: 'Created on' },
  { original: 'Creator name', modified: 'Created by' },
  { original: 'File Updated', modified: 'File updated on' },
  { original: 'File Updater Name', modified: 'File updated by' },
  { original: 'Out to User Name', modified: 'Checked out to' },
  { original: 'Updated', modified: 'Updated on' },
  { original: 'Updater Name', modified: 'Updated by' },
  { original: 'Object GUID', modified: 'Document GUID' },
  { original: 'Application name', modified: 'Application' },
  { original: 'Department name', modified: 'Department' },
  { original: 'Owner name', modified: 'Owner' }
];

export function formattedDisplayLabel(displayLabel: string): string {
  const remappedDisplayLabel = remapDisplayLabel(displayLabel);
  const sentenceCaseDisplayLabel = toSentenceCase(remappedDisplayLabel);
  return sentenceCaseDisplayLabel;
}

function remapDisplayLabel(displayLabel: string): string {
  const mapping = remappedDisplayLabels.find(
    (mapping) => mapping.original.toLowerCase() == displayLabel.toLowerCase()
  );
  if (!mapping) {
    return displayLabel;
  }
  return mapping.modified;
}

function toSentenceCase(displayLabel: string): string {
  const words = displayLabel.split(' ');
  const firstWord = words.shift();
  const lowerCaseRest = words.map(toConditionalLowerCase);
  return [firstWord, ...lowerCaseRest].join(' ');
}

function toConditionalLowerCase(word: string): string {
  if (word == word.toLocaleUpperCase()) {
    return word;
  }

  return word.toLocaleLowerCase();
}

export function renameFolderPropertyDisplayLabels(
  properties: EnvironmentProperty[]
): void {
  const renameMap = new Map<string, string>([
    ['created', t('Columns.FolderCreated')],
    ['creator name', t('Columns.FolderCreatedBy')],
    ['description', t('Columns.FolderDescription')],
    ['environment name', t('Generic.Environment')],
    ['name', t('Columns.FolderName')],
    ['owner name', t('Columns.FolderOwner')],
    ['owner type', t('Columns.FolderOwnerType')],
    ['project type', t('Columns.FolderType')],
    ['state name', t('Columns.FolderState')],
    ['storage name', t('Columns.FolderStorage')],
    ['updated', t('Columns.FolderUpdated')],
    ['updater name', t('Columns.FolderUpdatedBy')],
    ['workflow name', t('Generic.Workflow')]
  ]);

  properties.forEach(
    (value) =>
      (value.displayLabel =
        renameMap.get(value.displayLabel.toLowerCase()) ?? value.displayLabel)
  );
}
