import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsParentType } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../../components/pwModal';
import type {
  CloseModal,
  ModalElement,
  OpenModal
} from '../../../hooks/useModal';
import { t } from '../../../services/translation';

type FlatSetCreationConfirmationModalProps = {
  showFlatSetWizard: (preSelectedItemIds?: string[]) => void;
  items: PWItem[];
  primaryModal: {
    modal: ModalElement;
    open: OpenModal;
    close: CloseModal;
  };
};

export function openFlatSetCreationConfirmationModal({
  showFlatSetWizard,
  items,
  primaryModal
}: FlatSetCreationConfirmationModalProps): void {
  function showFlatSetCreationConfirmationModal(): boolean {
    if (items.length == 0) {
      return false;
    }

    if (items.some((item) => itemIsParentType(item))) {
      return false;
    }

    return true;
  }

  if (showFlatSetCreationConfirmationModal()) {
    primaryModal.open(
      <FlatSetCreationConfirmationModal
        showFlatSetWizard={showFlatSetWizard}
        items={items}
        primaryModal={primaryModal}
      />
    );
  } else {
    showFlatSetWizard();
  }
}

function FlatSetCreationConfirmationModal({
  showFlatSetWizard,
  items,
  primaryModal
}: FlatSetCreationConfirmationModalProps): JSX.Element {
  function onYes(): void {
    showFlatSetWizard(items.map((i) => i.instanceId));
  }

  function onNo(): void {
    showFlatSetWizard();
  }

  return (
    <PWModal
      title={t('FlatSet.Create')}
      onClose={primaryModal.close}
      primaryButton={{
        title: t('Generic.Yes'),
        onClick: onYes,
        disabled: false
      }}
      secondaryButton={{ title: t('Generic.No'), onClick: onNo }}
      dialogProps={{ 'data-testid': 'FlatSetCreationConfirmationModal' }}
    >
      <ItemSummary item={items} />
      <Text>{t('FlatSet.CreateConfirmation')}</Text>
    </PWModal>
  );
}
