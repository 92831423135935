import React from 'react';
import { useItemActionContext } from '../../../context';
import { PWToolbar } from '../pwToolbar/pwToolbar';

export function ItemActionsToolbar(): JSX.Element | null {
  const { itemActions } = useItemActionContext();

  const toolbarActions = itemActions('toolbarMenu');

  return (
    <PWToolbar
      buttons={toolbarActions}
      numVisibleButtons={5}
      data-testid="LeftToolbar"
    />
  );
}
