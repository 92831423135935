import type { PWItem } from '@bentley/pw-api';
import { itemIsDataItem } from '@bentley/pw-api';
import { t } from '../translation';

export function getItemStatus(item: PWItem): string {
  if (!itemIsDataItem(item)) {
    return '';
  }
  if (item.StateString == 'CheckedOut' || item.IsCheckedOut) {
    return t('Status.CheckedOut');
  } else if (item.StateString == 'Locked' || item.IsLocked) {
    return t('Status.Locked');
  } else if (item.StateString == 'Final' || item.Status == 'Final') {
    return t('Status.Final');
  } else {
    return t('Status.CheckedIn');
  }
}

export function getItemLockedTo(item: PWItem): string {
  if (!itemIsDataItem(item)) {
    return '';
  }

  return item.OutTo ?? '';
}
