import type { ProviderProps } from 'react';
import React from 'react';
import {
  FeatureTrackingProvider,
  PlainHttpServiceProvider,
  TokenProvider
} from '../../../context';
import type { GetToken } from '../../../services/http';
import type { DCWProps } from '..';

export type DocumentWizardProps = DCWProps & {
  baseWsgUrl: string;
  buddiRegionCode: string;
  getOidcToken: GetToken;
  getSamlToken: GetToken;
};

function DocumentWizardProviders({
  value,
  children
}: ProviderProps<DocumentWizardProps>): JSX.Element {
  return (
    <TokenProvider value={value}>
      <FeatureTrackingProvider value={value}>
        <PlainHttpServiceProvider value={value}>
          {children}
        </PlainHttpServiceProvider>
      </FeatureTrackingProvider>
    </TokenProvider>
  );
}

export default DocumentWizardProviders;
