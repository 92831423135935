import React, { useEffect, useState } from 'react';
import { SvgExport, SvgStatusErrorHollow } from '@itwin/itwinui-icons-react';
import { Flex, Text } from '@itwin/itwinui-react';
import type { AuditTrail } from '../../../actions/exportAuditTrail';
import { ExportAuditTrailModal } from '../../../actions/exportAuditTrail';
import {
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import { getAuditTrail } from '../../../services/data';
import { t } from '../../../services/translation';
import { LoadingPage } from '../../loadingPage';
import { PWToolbar } from '../../toolbar';
import { AuditTrailTable } from './auditTrailTable';

export function PWInfoPanelAuditTrail(): JSX.Element {
  const httpService = useHttpService();
  const { infoPanelDisplayed, primaryModal } = useNavigationContext();
  const { actionableItems } = useTableItemContext();

  const [auditTrail, setAuditTrail] = useState<AuditTrail[]>([]);
  const [auditTrailLoading, setAuditTrailLoading] = useState<boolean>(true);
  const [auditTrailError, setAuditTrailError] = useState<boolean>(false);

  function onExportToFile() {
    primaryModal.open(
      <ExportAuditTrailModal selectedItem={actionableItems[0]} />
    );
  }

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeAuditTrail(): Promise<void> {
      if (!infoPanelDisplayed || actionableItems.length != 1) {
        return;
      }

      setAuditTrailLoading(true);
      setAuditTrailError(false);

      try {
        const auditTrail = await getAuditTrail(
          actionableItems[0],
          httpService,
          { abortController, uncached: true }
        );
        if (!abortController.signal.aborted) {
          setAuditTrail(auditTrail);
        }
      } catch (err) {
        console.error(err);
        if (!abortController.signal.aborted) {
          setAuditTrailError(true);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setAuditTrailLoading(false);
        }
      }
    }

    void initializeAuditTrail();

    return () => {
      abortController.abort();
    };
  }, [actionableItems, httpService, infoPanelDisplayed]);

  return (
    <Flex flexDirection="column" className="pw-info-audit">
      {!auditTrailLoading && (
        <Flex.Item alignSelf="flex-start" flex="0 0">
          <PWToolbar
            buttons={[
              {
                title: t('InfoPanel.ExportToFile'),
                icon: <SvgExport />,
                onClick: onExportToFile,
                disabled: auditTrailError,
                hidden: auditTrailLoading
              }
            ]}
          />
        </Flex.Item>
      )}
      <Flex.Item alignSelf="flex-start" className="pw-audit-table">
        {auditTrailLoading && <LoadingPage />}
        {auditTrailError && (
          <Flex flexDirection="column" alignItems="center">
            <SvgStatusErrorHollow className="pw-info-empty-icon" />
            <Text variant="leading">
              {t('AuditTrail.ErrorRetrievingRecords')}
            </Text>
          </Flex>
        )}
        {!auditTrailLoading && !auditTrailError && (
          <AuditTrailTable auditTrail={auditTrail} />
        )}
      </Flex.Item>
    </Flex>
  );
}
