import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { PWDocument, PWItem, WSGInstance } from '@bentley/pw-api';
import type { DataError } from '../../../actions/formRendering';
import type { ToastHandle } from '../../../services/pwToast';
import { handleDCWError } from '../error';
import { currentEnvironmentInstanceState, projectIdState } from '../state';
import {
  applyToAllState,
  multiUploadState,
  useInitializeProperties,
  useWizardFunctionsContext
} from '../wizard';
import { uploadToastHandleState } from '../wizard/state';
import { throwIfMultiNameConflicts, throwIfNameConflicts } from './conflicts';
import { notifyCreatingDocuments } from './notifications';
import { uploadInProgressState, uploadNodeState } from './state';
import { useUpload } from './useUpload';

type DocumentCreationFunctions = {
  create: (
    updatedInstance?: WSGInstance | null
  ) => Promise<PWItem[] | PWDocument>;
};

export type OnDocumentCreatedFunction = (
  instanceId: string,
  fileName: string,
  name: string,
  description: string,
  currentEnvironmentInstance?: WSGInstance | null
) => void | Promise<void>;

export function useDocumentCreation(): DocumentCreationFunctions {
  const applyToAll = useRecoilValue(applyToAllState);

  const currentEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    currentEnvironmentInstanceState
  );

  const multiUpload = useRecoilValue(multiUploadState);
  const projectId = useRecoilValue<string>(projectIdState);
  const uploadNode = useRecoilValue(uploadNodeState);
  const uploadToastHandle = useRecoilValue(uploadToastHandleState);
  const setUploadInProgress = useSetRecoilState(uploadInProgressState);

  const { initializeProperties } = useInitializeProperties();
  const { uploadDocument, uploadDirectoriesAndFilesOnNode } = useUpload();
  const { closeModal, onSuccess } = useWizardFunctionsContext();

  const createDocument = useCallback(
    async (updatedInstance?: WSGInstance | null): Promise<PWDocument> => {
      const docProperties = initializeProperties();
      const { name, fileName, description } = docProperties;

      try {
        await throwIfNameConflicts(name, fileName, projectId);

        const createdDocument = await uploadDocument(
          docProperties,
          updatedInstance ?? currentEnvironmentInstance,
          projectId
        );

        await onSuccess(
          createdDocument.instanceId,
          fileName,
          name,
          description
        );
        return createdDocument;
      } catch (error) {
        void handleDCWError(error as DataError);
        return { instanceId: '' } as PWDocument;
      }
    },
    [
      currentEnvironmentInstance,
      initializeProperties,
      onSuccess,
      projectId,
      uploadDocument
    ]
  );

  const createDocuments = useCallback(
    async (updatedInstance?: WSGInstance | null): Promise<PWItem[]> => {
      let toastHandle: ToastHandle | undefined = undefined;
      try {
        const docProperties = initializeProperties();
        const { name, fileName } = docProperties;

        await throwIfMultiNameConflicts(
          name,
          fileName,
          uploadNode.files,
          projectId
        );

        toastHandle = notifyCreatingDocuments(uploadToastHandle);

        const createdItems = await uploadDirectoriesAndFilesOnNode(
          uploadNode,
          projectId,
          updatedInstance
        );

        toastHandle?.close();
        return createdItems;
      } catch (error) {
        void handleDCWError(error as DataError, toastHandle);
        return [];
      } finally {
        toastHandle?.close();
      }
    },
    [
      initializeProperties,
      uploadNode,
      projectId,
      uploadToastHandle,
      uploadDirectoriesAndFilesOnNode
    ]
  );

  const createSingleOrMultiDocuments = useCallback(
    async (
      updatedInstance?: WSGInstance | null
    ): Promise<PWItem[] | PWDocument> => {
      if (multiUpload && applyToAll) {
        const documents = await createDocuments(updatedInstance);
        closeModal?.();
        return documents;
      }

      const createdDocument = await createDocument(updatedInstance);

      if (createdDocument?.instanceId && !multiUpload) {
        closeModal?.();
      }

      return createdDocument;
    },
    [applyToAll, closeModal, createDocument, createDocuments, multiUpload]
  );

  const create = useCallback(
    async (
      updatedInstance?: WSGInstance | null
    ): Promise<PWItem[] | PWDocument> => {
      setUploadInProgress(true);
      const createdItems = await createSingleOrMultiDocuments(updatedInstance);
      setUploadInProgress(false);
      return createdItems;
    },
    [createSingleOrMultiDocuments, setUploadInProgress]
  );

  return { create };
}
