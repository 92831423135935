import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { pwConstants } from '@bentley/pw-api';
import {
  Flex,
  InputGrid,
  InputWithDecorations,
  Label,
  LabeledInput,
  StatusMessage
} from '@itwin/itwinui-react';
import { replaceInvalidFileNameCharacters } from '../../../../actions/rename';
import { useLocalStorage } from '../../../../hooks/useStorage';
import { t } from '../../../../services/translation';
import { LockIcon } from '../../../lockIcon';
import { buildFileName } from '../../properties';
import { isFlatSetCreationModeState } from '../state';
import {
  allowFileRenameState,
  descriptionChangedState,
  docPropertiesState,
  fileNameChangedState,
  nameChangedState,
  originalFileNameState,
  propertiesFormValidState
} from './state';
import { useInitializeProperties } from './useInitializeProperties';

export type DCWDocProperties = {
  name: string;
  description: string;
  fileName: string;
  version: string;
  isLocked: boolean;
};

export function PropertiesSection(): JSX.Element {
  const [docProperties, setDocProperties] = useRecoilState(docPropertiesState);
  const [nameChanged, setNameChanged] = useRecoilState(nameChangedState);
  const setDescriptionChanged = useSetRecoilState(descriptionChangedState);
  const originalFileName = useRecoilValue(originalFileNameState);

  const setFileNameChanged = useSetRecoilState(fileNameChangedState);
  const setPropertiesFormValid = useSetRecoilState(propertiesFormValidState);

  const [, storeNameIconLocked] = useLocalStorage<boolean>(
    'fileNameLockedSelection',
    false
  );

  const allowFileRename = useRecoilValue(allowFileRenameState);

  const { propertiesInitialized } = useInitializeProperties();
  const isFlatSetCreationMode = useRecoilValue(isFlatSetCreationModeState);

  function onNameChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.value;
    const fileName = determineFileName(name, docProperties.isLocked);

    const updatedProps = {
      ...docProperties,
      name,
      fileName
    } as DCWDocProperties;

    setDocProperties(updatedProps);
    setNameChanged(true);

    if (docProperties.isLocked) {
      setFileNameChanged(true);
    }
  }

  const nameErrorMessage = useCallback((): string | undefined => {
    if (!isFlatSetCreationMode && !propertiesInitialized) {
      return;
    }

    if (isFlatSetCreationMode && !nameChanged) {
      return;
    }

    if (!docProperties.name.trim()) {
      return t('Generic.NameCannotBeEmpty');
    }

    if (docProperties.name.length > pwConstants.maxFileNameLength) {
      return `${t('Generic.ContentLimitedTo', {
        count: pwConstants.maxFileNameLength,
        excess: docProperties.name.length - pwConstants.maxFileNameLength
      })}`;
    }

    return;
  }, [
    isFlatSetCreationMode,
    propertiesInitialized,
    nameChanged,
    docProperties.name
  ]);

  const nameStatus = useCallback((): 'negative' | undefined => {
    if (nameErrorMessage()) {
      return 'negative';
    }

    return;
  }, [nameErrorMessage]);

  function onLockIconClick(): void {
    if (!allowFileRename) {
      return;
    }

    const isLocked = !docProperties.isLocked;
    storeNameIconLocked(isLocked);
    const fileName = determineFileName(docProperties.name, isLocked);

    const updatedProps = {
      ...docProperties,
      fileName,
      isLocked
    } as DCWDocProperties;

    setDocProperties(updatedProps);

    if (isLocked) {
      setFileNameChanged(true);
    }
  }

  function onDescriptionChange(event: ChangeEvent<HTMLInputElement>): void {
    const description = event.target.value;
    const updatedProps = { ...docProperties, description } as DCWDocProperties;
    setDocProperties(updatedProps);
    setDescriptionChanged(true);
  }

  const descriptionErrorMessage = useCallback((): string | undefined => {
    if (!isFlatSetCreationMode && !propertiesInitialized) {
      return;
    }

    if (docProperties.description.length > pwConstants.maxDescriptionLength) {
      return `${t('Generic.ContentLimitedTo', {
        count: pwConstants.maxDescriptionLength,
        excess:
          docProperties.description.length - pwConstants.maxDescriptionLength
      })}`;
    }

    return;
  }, [docProperties.description, propertiesInitialized, isFlatSetCreationMode]);

  const descriptionStatus = useCallback((): 'negative' | undefined => {
    if (descriptionErrorMessage()) {
      return 'negative';
    }

    return;
  }, [descriptionErrorMessage]);

  function onFileNameChange(event: ChangeEvent<HTMLInputElement>): void {
    const fileName = event.target.value;
    const updatedProps = { ...docProperties, fileName } as DCWDocProperties;
    setDocProperties(updatedProps);
    setFileNameChanged(true);
  }

  const fileNameErrorMessage = useCallback((): string | undefined => {
    if (!propertiesInitialized) {
      return;
    }

    if (!docProperties.fileName.trim()) {
      return t('DocumentCreation.FileNameCannotBeEmpty');
    }

    if (docProperties.fileName.length > pwConstants.maxFileNameLength) {
      return `${t('Generic.ContentLimitedTo', {
        count: pwConstants.maxFileNameLength,
        excess: docProperties.fileName.length - pwConstants.maxFileNameLength
      })}`;
    }

    return;
  }, [docProperties.fileName, propertiesInitialized]);

  const fileNameWarningMessage = useCallback((): string | undefined => {
    const invalidCharsRegex = RegExp(/([/\\:*<>"|?])/);

    if (invalidCharsRegex.test(docProperties.fileName)) {
      const validFileName = replaceInvalidFileNameCharacters(
        docProperties.fileName
      );
      return t('DocumentCreation.FileNameInvalidCharacters', {
        name: validFileName
      });
    }

    return;
  }, [docProperties.fileName]);

  const fileNameStatus = useCallback((): 'negative' | 'warning' | undefined => {
    if (fileNameErrorMessage()) {
      return 'negative';
    }

    if (fileNameWarningMessage()) {
      return 'warning';
    }

    return;
  }, [fileNameErrorMessage, fileNameWarningMessage]);

  function determineFileName(name: string, locked: boolean): string {
    if (!locked) {
      return docProperties.fileName;
    }

    return buildFileName(name, originalFileName);
  }

  function onVersionChange(event: ChangeEvent<HTMLInputElement>): void {
    const version = event.target.value;
    const updatedProps = { ...docProperties, version } as DCWDocProperties;
    setDocProperties(updatedProps);
  }

  const versionErrorMessage = useCallback((): string | undefined => {
    if (!propertiesInitialized) {
      return;
    }

    // Allow empty field but not blank spaces for version name
    if (docProperties.version.length != 0 && !docProperties.version.trim()) {
      return `${t('Version.NameCannotBeEmpty')}`;
    }

    if (docProperties.version.length > pwConstants.maxVersionLength) {
      return `${t('Generic.ContentLimitedTo', {
        count: pwConstants.maxVersionLength,
        excess: docProperties.version.length - pwConstants.maxVersionLength
      })}`;
    }

    return;
  }, [docProperties.version, propertiesInitialized]);

  const versionStatus = useCallback((): 'negative' | undefined => {
    if (versionErrorMessage()) {
      return 'negative';
    }

    return;
  }, [versionErrorMessage]);

  useEffect(() => {
    const flatSetPropertiesFormValid =
      docProperties.name.trim().length > 0 &&
      nameStatus() != 'negative' &&
      descriptionStatus() != 'negative';

    const propertiesFormValid =
      nameStatus() != 'negative' &&
      fileNameStatus() != 'negative' &&
      descriptionStatus() != 'negative' &&
      versionStatus() != 'negative';

    const isPropertiesFormValid = isFlatSetCreationMode
      ? flatSetPropertiesFormValid
      : propertiesFormValid;

    setPropertiesFormValid(isPropertiesFormValid);
  }, [
    fileNameStatus,
    nameStatus,
    descriptionStatus,
    versionStatus,
    setPropertiesFormValid,
    docProperties.name,
    isFlatSetCreationMode
  ]);

  return (
    <Flex flexDirection="column" alignItems="stretch">
      <InputGrid>
        <Label htmlFor="name-input">{t('Generic.Name')}</Label>
        <InputWithDecorations>
          <InputWithDecorations.Input
            id="name-input"
            name="name"
            data-testid="dcw-name"
            autoFocus={true}
            value={docProperties.name}
            onChange={onNameChange}
            status={nameStatus()}
          />
          {!isFlatSetCreationMode && (
            <LockIcon
              disabled={!allowFileRename}
              locked={docProperties.isLocked || !allowFileRename}
              tooltipText={t('Rename.ForceFileNameToBeSame')}
              onClick={onLockIconClick}
            />
          )}
        </InputWithDecorations>
        <StatusMessage status={nameStatus()}>
          {nameErrorMessage()}
        </StatusMessage>
      </InputGrid>
      <LabeledInput
        label={t('Generic.Description')}
        name="Description"
        data-testid="dcw-description"
        value={docProperties.description}
        onChange={onDescriptionChange}
        status={descriptionStatus()}
        message={
          <StatusMessage status={descriptionStatus()}>
            {descriptionErrorMessage()}
          </StatusMessage>
        }
      />
      {!isFlatSetCreationMode && (
        <>
          <LabeledInput
            label={t('Generic.FileName')}
            name="FileName"
            data-testid="dcw-file-name"
            value={docProperties.fileName}
            onChange={onFileNameChange}
            disabled={docProperties.isLocked}
            status={fileNameStatus()}
            message={
              <StatusMessage status={fileNameStatus()}>
                {fileNameErrorMessage() ?? fileNameWarningMessage()}
              </StatusMessage>
            }
          />
          <LabeledInput
            label={t('Generic.Version')}
            name="Version"
            data-testid="dcw-version"
            value={docProperties.version}
            onChange={onVersionChange}
            status={versionStatus()}
            message={
              <StatusMessage status={versionStatus()}>
                {versionErrorMessage()}
              </StatusMessage>
            }
          />
        </>
      )}
    </Flex>
  );
}
