import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { LoadingPage } from '../loadingPage';
import { DocCodeModalLayout } from './modal';
import { DocCodeStateProvider } from './state';

export function DocCodeModalControl(): JSX.Element {
  return (
    <RecoilRoot>
      <Suspense fallback={<LoadingPage />}>
        <DocCodeStateProvider>
          <DocCodeModalLayout />
        </DocCodeStateProvider>
      </Suspense>
    </RecoilRoot>
  );
}
