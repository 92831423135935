import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { tableFilters } from '@itwin/itwinui-react';
import type {
  DefaultFilterTypes,
  FilterProps,
  Renderer
} from '@itwin/itwinui-react/react-table';
import { t } from '../../../services/translation';
import type { ColumnDataType } from '../column/typedColumn';
import { BooleanFilter } from './booleanFilter';
import { ColumnValuesFilter } from './columnValuesFilter';
import { FileSizeFilter } from './fileSizeFilter';

type ColumnFilterFunctions = {
  columnFilter: (
    columnDataType: ColumnDataType,
    valuesFilter?: boolean
  ) => Renderer<FilterProps<PWItem>>;
  columnFilterType: (columnDataType: ColumnDataType) => string;
};

export function useTableFilters(): ColumnFilterFunctions {
  const translatedLabels = useMemo(
    () => ({
      filter: t('Search.Filter'),
      clear: t('Search.Clear'),
      from: t('Generic.From'),
      to: t('Generic.To')
    }),
    []
  );

  const columnFilter = useCallback(
    (
      columnDataType: ColumnDataType,
      valuesFilter?: boolean
    ): Renderer<FilterProps<PWItem>> => {
      if (columnDataType == 'dateTime') {
        return tableFilters.DateRangeFilter({
          translatedLabels,
          showYearSelection: true
        });
      }

      if (columnDataType == 'number') {
        return tableFilters.NumberRangeFilter(translatedLabels);
      }

      if (columnDataType == 'boolean') {
        return BooleanFilter;
      }

      if (columnDataType == 'fileSize') {
        return FileSizeFilter;
      }

      if (valuesFilter) {
        return ColumnValuesFilter;
      }

      return tableFilters.TextFilter(translatedLabels);
    },
    [translatedLabels]
  );

  const columnFilterType = useCallback(
    (columnDataType: ColumnDataType): DefaultFilterTypes | 'betweenDate' => {
      if (columnDataType == 'dateTime') {
        return 'betweenDate';
      }

      if (columnDataType == 'number' || columnDataType == 'fileSize') {
        return 'between';
      }

      if (columnDataType == 'boolean') {
        return 'equals';
      }

      return 'text';
    },
    []
  );

  return { columnFilter, columnFilterType };
}
