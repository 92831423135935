import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PWItem, WSGInstance } from '@bentley/pw-api';
import { useHttpService, usePluginContext } from '../../context';
import type { AttributePicklistValue } from '../useEnvironment/environmentAttributes/attributeDefinitions';
import { useLocalStorage } from '../useStorage';
import { applications } from './applications';
import {
  getStates,
  getStatuses,
  getWorkflows
} from './documentPropertyFunctions';

export type Workflow = {
  schemaName: 'PW_WSG';
  className: 'Workflow';
  Name: string;
  Description: string;
} & WSGInstance;

export type State = {
  schemaName: 'PW_WSG';
  className: 'State';
  Description: string;
  Name: string;
} & WSGInstance;

type DocumentProperties = {
  availableStates: State[];
  availableWorkflows: Workflow[];
  availableStatuses: string[];
  getDocumentPropertyPicklist: (
    columnName: string
  ) => AttributePicklistValue[] | undefined;
  getStateString: (item: PWItem) => string;
  getWorkflowString: (item: PWItem) => string;
  initialized: boolean;
};

type DocumentPropertyData = {
  cached: boolean;
  states: State[];
  statuses: string[];
  workflows: Workflow[];
};

export function useDocumentProperties(): DocumentProperties {
  const { connection } = usePluginContext();
  const httpService = useHttpService();

  const [documentPropertyData, setDocumentPropertyData] =
    useLocalStorage<DocumentPropertyData>(
      `documentProperties:${connection.Id}`,
      { cached: false, states: [], statuses: [], workflows: [] }
    );

  const timeoutDelay = documentPropertyData.cached ? 10000 : 0;

  const [initialized, setInitialized] = useState<boolean>(
    documentPropertyData.cached
  );

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchProperties(abortController: AbortController) {
      try {
        const [states, workflows, statuses] = await Promise.all([
          getStates(httpService, {
            abortController,
            cacheSuffix: 'persistent'
          }),
          getWorkflows(connection.ProjectId, httpService, {
            abortController,
            cacheSuffix: 'persistent'
          }),
          getStatuses(httpService, {
            abortController,
            cacheSuffix: 'persistent'
          })
        ]);
        const data = { cached: true, states, workflows, statuses };
        if (
          !abortController.signal.aborted &&
          !_.isEqual(data, documentPropertyData)
        ) {
          setDocumentPropertyData(data);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setInitialized(true);
        }
      }
    }

    setTimeout(() => {
      if (!abortController.signal.aborted) {
        void fetchProperties(abortController);
      }
    }, timeoutDelay);

    return () => {
      abortController.abort();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * Do not want to re-trigger when update data
     */
  }, [httpService]);

  const getDocumentPropertyPicklist = useCallback(
    (columnName: string): AttributePicklistValue[] | undefined => {
      switch (columnName) {
        case 'Application':
          return applications.map((application) => ({
            Value: application,
            DisplayLabel: application
          }));

        case 'State':
          return documentPropertyData.states.map((state) => ({
            Value: state.instanceId,
            DisplayLabel: state.Name
          }));

        case 'Status':
          return documentPropertyData.statuses.map((status) => ({
            Value: status,
            DisplayLabel: status
          }));

        case 'Workflow':
          return documentPropertyData.workflows.map((workflow) => ({
            Value: workflow.instanceId,
            DisplayLabel: workflow.Name
          }));

        default:
          return undefined;
      }
    },
    [documentPropertyData]
  );

  const getWorkflowString = useCallback(
    (item: PWItem): string => {
      if (item.Workflow) {
        return item.Workflow;
      }

      const workflow = documentPropertyData.workflows.find(
        (workflow) => +workflow.instanceId == item?.WorkflowId
      );

      return workflow?.Name ?? '';
    },
    [documentPropertyData.workflows]
  );

  const getStateString = useCallback(
    (item: PWItem): string => {
      if (item.State) {
        return item.State;
      }

      const state = documentPropertyData.states.find(
        (state) => +state.instanceId == item?.StateId
      );

      return state?.Name ?? '';
    },
    [documentPropertyData.states]
  );

  const documentProperties = useMemo(
    (): DocumentProperties => ({
      availableStates: documentPropertyData.states,
      availableWorkflows: documentPropertyData.workflows,
      availableStatuses: documentPropertyData.statuses,
      getDocumentPropertyPicklist,
      getStateString,
      getWorkflowString,
      initialized
    }),
    [
      documentPropertyData,
      getDocumentPropertyPicklist,
      getStateString,
      getWorkflowString,
      initialized
    ]
  );

  return documentProperties;
}
