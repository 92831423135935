import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ProgressSummaryModal } from '../../components/ProgressSummary';
import type { ProgressSummaryItem } from '../../components/ProgressSummary/progressSummaryModal';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyDeleteInProgress(items: PWItem[]): ToastHandle {
  const content =
    items.length == 1
      ? t('Delete.DeleteInProgress')
      : t('Delete.DeleteMultiInProgress');

  return openToast({
    content: content,
    spinner: true,
    type: 'persisting'
  });
}

export function notifyDeleteCompleted(
  numCompleted: number,
  toastHandle: ToastHandle
): ToastHandle {
  if (numCompleted < 1) {
    return onFailure(toastHandle);
  } else {
    return notifySuccess(numCompleted, toastHandle);
  }
}

export function notifyDeleteErrors(
  failures: ProgressSummaryItem[],
  openModal: OpenModal,
  closeModal: CloseModal
): void {
  if (!failures.length) {
    return;
  }
  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={failures}
      title={t('Delete.UnableToDelete')}
    />
  );
}

function notifySuccess(
  numCompleted: number,
  toastHandle: ToastHandle
): ToastHandle {
  const message =
    numCompleted == 1
      ? t('Delete.DeleteItemSuccess')
      : t('Delete.DeleteItemsSuccess', { count: numCompleted });

  return replaceToast(toastHandle, { content: message, category: 'positive' });
}

function onFailure(toastHandle: ToastHandle): ToastHandle {
  toastHandle.close();
  return toastHandle;
}
