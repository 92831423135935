import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary } from 'react-error-boundary';
import { logError } from '../../../hooks/useErrorLogging';
import { t } from '../../../services/translation';
import { ProjectWiseErrorPage } from '../../pwErrorPage/projectWiseErrorPage';

type PWErrorBoundaryProps = {
  children?: ReactNode;
};

export function PWErrorBoundary({
  children
}: PWErrorBoundaryProps): JSX.Element {
  return (
    <ErrorBoundary
      FallbackComponent={PWErrorFallback}
      onError={(error, info) => logError('Unhandled error', { error, info })}
    >
      {children}
    </ErrorBoundary>
  );
}

function PWErrorFallback({
  error,
  resetErrorBoundary
}: FallbackProps): JSX.Element {
  const displayError = useMemo((): Error => {
    if (error && error.message && error.name != 'Invariant Violation') {
      return error;
    }

    return new Error(t('Generic.UnknownErrorOccurred'));
  }, [error]);

  return (
    <ProjectWiseErrorPage
      clearErrorPage={resetErrorBoundary}
      error={displayError}
    />
  );
}
