import _ from 'lodash';
import type { DependencyList, EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

/**
 * Wrapper around useEffect that checks deep equality of dependency list. This
 * allows for comparison between nested arrays or properties to determine if the
 * effect needs re-run, as opposed to checking for object-level reference
 * equality. The trade off is that the effect cannot be run immediately upon
 * change of the dependencies, as the reference may stay the same. Instead, the
 * consumer must pass a reasonable interval to check for changes in a loop.
 * @param {EffectCallback} effect The callback to run when dependencies change
 * @param {DependencyList} deps Dependency list to check for deep equality changes
 * @param {number} updateMs How often in ms to check for deep dependency changes
 */
export function useTimedEffect(
  effect: EffectCallback,
  deps: DependencyList,
  updateMs: number
): void {
  const previousDeps = useRef<DependencyList>();

  useEffect(() => {
    function update() {
      if (_.isEqual(deps, previousDeps.current)) {
        return;
      }

      previousDeps.current = _.cloneDeep(deps);
      effect();
    }

    const intervalId = window.setInterval(update, updateMs);

    return () => {
      clearInterval(intervalId);
    };
  }, [deps, effect, updateMs]);
}
