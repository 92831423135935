import './iModelUnmappingModal.css';

import React, { useEffect, useState } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { SvgFiletypeMicrostation } from '@itwin/itwinui-icons-color-react';
import { SvgChevronRight } from '@itwin/itwinui-icons-react';
import { Checkbox, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { PWTooltip } from '../../components/pwTooltip';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';
import type { IModel } from './iModel';
import type { IModelBridge } from './iModelBridge';
import { notifyUnmappingCompleted } from './notifications';
import { associatedIModelBridges } from './utils';

type UnmapIModelModalProps = {
  items: PWDataItem[];
  onComplete?: () => void;
};

type UnmapMappingData = {
  name: string;
  id: string;
  version: string;
  isExpanded?: boolean;
  children: IModelBridge[];
};

export function UnmapIModelModal({
  items,
  onComplete
}: UnmapIModelModalProps): ModalElement {
  const { iModelMappingManager: iModelBridgeApi, connectionIModelBridges } =
    usePluginContext();
  const { iModels } = useEnvironmentContext();
  const { primaryModal } = useNavigationContext();

  const [treeItems, setTreeItems] = useState<UnmapMappingData[]>([]);
  const [allDocumentsChecked, setAllDocumentsChecked] =
    useState<boolean>(false);
  const [checkedDocuments, setCheckedDocuments] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());
  const [checkedItems, setCheckedItems] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSpatialRootExists, setIsSpatialRootExists] =
    useState<boolean>(false);
  const { trackFeature } = useFeatureTracking();

  useEffect(() => {
    const data: UnmapMappingData[] = [];

    items.forEach((item) => {
      data.push({
        name: item.Name,
        id: item.instanceId,
        children: associatedIModelBridges(
          item,
          connectionIModelBridges.iModelBridges
        ),
        isExpanded: true,
        version: item.Version
      });
    });

    data.map((dataItem) => {
      setCheckedDocuments((checkedDocuments) =>
        checkedDocuments.set(dataItem.id, false)
      );
      dataItem.children?.map((children: IModelBridge) => {
        setCheckedItems((checkedItems) =>
          checkedItems.set(children.instanceId, false)
        );
      });
    });

    setIsLoading(false);
    setTreeItems([...data]);
    setIsSpatialRootExists(
      data.some(
        (d) =>
          d.children &&
          d.children.some(
            (c) => c.properties && c.properties.IsSpatialRoot == 'true'
          )
      )
    );
  }, [connectionIModelBridges.iModelBridges, items]);

  function onChevronClick(event: React.MouseEvent, item: UnmapMappingData) {
    treeItems.map((treeitem) => {
      if (treeitem.id == item.id) {
        treeitem.isExpanded =
          treeitem.isExpanded == undefined ? true : !treeitem.isExpanded;
      }
    });
    event.stopPropagation();
    setTreeItems([...treeItems]);
  }

  function matchingIModel(
    iModels: IModel[],
    iModelBridge: IModelBridge
  ): IModel | undefined {
    return iModels.find(
      (iModel) => iModel.instanceId == iModelBridge.properties?.iModelId
    );
  }

  function isSelectAllDocumentChecked() {
    const items = treeItems.filter((item) => {
      return !disableDocument(item);
    });
    items.some((item) => checkedDocuments.get(item.id) == false)
      ? setAllDocumentsChecked(false)
      : setAllDocumentsChecked(true);
  }

  function disableSelectAll() {
    return !treeItems.some((item) => !disableDocument(item));
  }

  function disableDocument(item: UnmapMappingData): boolean | undefined {
    return !item.children?.some(
      (mapping) => mapping.properties?.IsSpatialRoot == 'false'
    );
  }

  function onIModelMappingChecked(bridge: IModelBridge) {
    if (bridge.properties?.IsSpatialRoot == 'false') {
      setCheckedItems(
        checkedItems.set(
          bridge.instanceId,
          !checkedItems.get(bridge.instanceId)
        )
      );
      const isDocumentChecked = treeItems
        .find((item) => item.id == bridge.properties?.DocumentGuid)
        ?.children?.filter(
          (bridge) => bridge.properties?.IsSpatialRoot == 'false'
        )
        .map((bridge) => {
          return checkedItems.get(bridge.instanceId);
        })
        .some((item) => item == false);

      setCheckedDocuments(
        checkedDocuments.set(
          bridge.properties?.DocumentGuid as string,
          !isDocumentChecked
        )
      );
      isSelectAllDocumentChecked();
      setTreeItems([...treeItems]);
    }
  }

  function onDocumentChecked(item: UnmapMappingData) {
    if (!disableDocument(item)) {
      const isChecked = !checkedDocuments.get(item.id);
      setCheckedDocuments(checkedDocuments.set(item.id, isChecked));
      item.children
        ?.filter((bridge) => bridge.properties?.IsSpatialRoot == 'false')
        .map((bridge) => {
          setCheckedItems(checkedItems.set(bridge.instanceId, isChecked));
        });
      isSelectAllDocumentChecked();
      setTreeItems([...treeItems]);
    }
  }

  function unmapButtonDisabled(): boolean | undefined {
    return !Array.from(checkedItems.values()).some((item) => item == true);
  }

  function onSelectAllDocumentChecked() {
    if (!disableSelectAll()) {
      const checked = !allDocumentsChecked;
      setAllDocumentsChecked(checked);
      const nonDisabledDocumnets = treeItems.filter((item) => {
        return !disableDocument(item);
      });
      nonDisabledDocumnets.map((document) =>
        setCheckedDocuments(checkedDocuments.set(document.id, checked))
      );
      nonDisabledDocumnets.map((document) => {
        document.children
          ?.filter((children) => children.properties?.IsSpatialRoot == 'false')
          .map((item) =>
            setCheckedItems(checkedItems.set(item.instanceId, checked))
          );
      });
    }
  }

  async function performUnMapping() {
    trackFeature('UNMAP_IMODEL');
    const responses = await Promise.all(
      Array.from(checkedItems.keys())
        .filter((key) => checkedItems.get(key) == true)
        .map(async (mapping) => {
          return await iModelBridgeApi.deleteIModelBridge(mapping);
        })
    );
    connectionIModelBridges.refreshBridges();

    primaryModal.close();
    notifyUnmappingCompleted(responses.filter((response) => response).length);
    if (onComplete) {
      onComplete();
    }
  }

  return (
    <PWModal
      title={t('IModel.UnmapFromIModel')}
      className="u-i-modal-content"
      isLoading={isLoading}
      primaryButton={{
        title: t('IModel.Unmap'),
        onClick: () => void performUnMapping(),
        disabled: unmapButtonDisabled(),
        'data-testid': 'unmap-button'
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'UnmapIModelModal' }}
    >
      <Text>{t('IModel.SelectFileToUnmap')}</Text>
      <Text isMuted={true} hidden={!isSpatialRootExists}>
        {t('IModel.SpatialRootRequired')}
      </Text>
      <div className={'tree-container-unmap'} data-testid={'unmap-tree'}>
        <div className={'tree-header-unmap'}>
          <div
            className="selectall-hover"
            onClick={onSelectAllDocumentChecked}
            role="button"
            tabIndex={0}
            onKeyDown={keyPressAsClick(onSelectAllDocumentChecked)}
            data-testid={'select-all-click'}
          >
            <Checkbox
              label={t('IModel.SelectAllMappings')}
              checked={allDocumentsChecked}
              onChange={() => null}
              disabled={disableSelectAll()}
              data-testid={'isSelectAllChecked'}
            />
          </div>
          <ul>
            {treeItems.map((item) => (
              <li key={item.id}>
                <PWTooltip
                  content={
                    item.children?.length == 0
                      ? t('IModel.MappingNotAvailable')
                      : item.name
                  }
                >
                  <div
                    className="tree-item-button-unmap"
                    role="button"
                    tabIndex={0}
                    onClick={onDocumentChecked.bind({}, item)}
                    onKeyDown={keyPressAsClick(
                      onDocumentChecked.bind({}, item)
                    )}
                    data-testid="document-click"
                  >
                    <div>
                      <SvgChevronRight
                        data-testid={item.name}
                        className={
                          item.isExpanded && item.children.length > 0
                            ? 'tree-chevron-expanded'
                            : 'tree-chevron-collapsed'
                        }
                        onClick={(e) => onChevronClick(e, item)}
                      />
                      <Checkbox
                        name={item.id}
                        checked={checkedDocuments.get(item.id)}
                        onChange={() => null}
                        className={'checkbox-margin-right'}
                        disabled={disableDocument(item)}
                        data-testid={`isDocumentChecked${item.name}`}
                      />
                      <SvgFiletypeMicrostation
                        data-testid="microstation-icon checkbox-margin-right"
                        style={{
                          height: 'var(--iui-size-m)',
                          width: 'var(--iui-size-m)'
                        }}
                      />
                      <Text className="name-margin">{item.name}</Text>
                    </div>
                    {item.version && (
                      <div className={'div-version'}>
                        <Text variant="small" className={'span-version'}>
                          {`${t('Generic.Version.UnmappingModal', {
                            version: item.version
                          })}`}
                        </Text>
                      </div>
                    )}
                  </div>
                </PWTooltip>
                <div
                  className={
                    item.isExpanded
                      ? 'tree-item-expanded'
                      : 'tree-item-collapsed'
                  }
                >
                  <ul>
                    {item.children?.map((bridge) => (
                      <li
                        className={'li-margin'}
                        key={`ul-${bridge.instanceId}`}
                      >
                        <PWTooltip
                          content={
                            bridge.properties?.IsSpatialRoot == 'true'
                              ? t('IModel.SpatialRootCannotUnmap')
                              : bridge.properties?.IsMaster == 'true'
                              ? t('IModel.MasterRoot')
                              : t('IModel.SheetRoot')
                          }
                        >
                          <div
                            data-testid={'imodel-mapping-checked'}
                            className="select-imodelmapping"
                            onClick={onIModelMappingChecked.bind({}, bridge)}
                            onKeyDown={keyPressAsClick(
                              onIModelMappingChecked.bind({}, bridge)
                            )}
                            role={'button'}
                            tabIndex={0}
                          >
                            <Checkbox
                              label={String(
                                matchingIModel(iModels, bridge)?.properties
                                  ?.Name
                              )}
                              name={bridge.instanceId}
                              checked={checkedItems.get(bridge.instanceId)}
                              onChange={() => null}
                              disabled={
                                bridge.properties?.IsSpatialRoot == 'true'
                              }
                              data-testid={`isImodelChecked${String(
                                matchingIModel(iModels, bridge)?.properties
                                  ?.Name
                              )}`}
                              wrapperProps={{
                                className: 'checkbox-margin-right'
                              }}
                            />
                          </div>
                        </PWTooltip>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </PWModal>
  );
}
