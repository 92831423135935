import _ from 'lodash';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { parseFileNameWithoutExtension } from '@bentley/pw-api';
import { replaceInvalidFileNameCharacters } from '../../../actions/rename';
import {
  formatDocCodeString,
  generateDocCodeProperties,
  usingDocCodeState
} from '../docCode';
import { buildFileName, mergePropertiesIntoInstance } from '../properties';
import type { DCWDocProperties } from '../wizard';
import {
  descriptionChangedState,
  docPropertiesState,
  fileNameChangedState,
  nameChangedState
} from '../wizard';
import { getUniqueName } from './naming';

type MultiUploadPropertiesFunctions = {
  getUploadEnvironmentInstance: (
    environmentInstance: WSGInstance | null
  ) => Promise<WSGInstance | null>;
  getUploadDocCode: (
    environmentInstance: WSGInstance | null
  ) => Promise<string>;
  getUploadDocProperties: (
    docName: string,
    docCode: string,
    siblingNames: string[]
  ) => DCWDocProperties;
};

export function useMultiUploadProperties(): MultiUploadPropertiesFunctions {
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const docProperties = useRecoilValue(docPropertiesState);
  const fileNameChanged = useRecoilValue(fileNameChangedState);
  const nameChanged = useRecoilValue(nameChangedState);
  const descriptionChanged = useRecoilValue(descriptionChangedState);

  const getUploadEnvironmentInstance = useCallback(
    async (
      environmentInstance: WSGInstance | null
    ): Promise<WSGInstance | null> => {
      if (!usingDocCode || !environmentInstance) {
        return environmentInstance;
      }

      const updatedProperties = await generateDocCodeProperties(
        environmentInstance
      );

      const updatedInstance = mergePropertiesIntoInstance(
        environmentInstance,
        updatedProperties
      );

      return updatedInstance;
    },
    [usingDocCode]
  );

  const getUploadDocCode = useCallback(
    async (environmentInstance: WSGInstance | null): Promise<string> => {
      if (!usingDocCode || !environmentInstance) {
        return '';
      }

      const docCode = await formatDocCodeString(environmentInstance);
      return docCode;
    },
    [usingDocCode]
  );

  const uploadNameCandidate = useCallback(
    (fileName: string, docCode: string): string => {
      if (usingDocCode && docCode && !nameChanged) {
        return docCode;
      }

      if (nameChanged) {
        return docProperties.name;
      }

      return fileName;
    },
    [docProperties, nameChanged, usingDocCode]
  );

  const uploadFileNameCandidate = useCallback(
    (fileName: string, docCode: string): string => {
      if (docProperties.isLocked) {
        const name = uploadNameCandidate(fileName, docCode);
        return buildFileName(name, fileName);
      }

      if (fileNameChanged) {
        return replaceInvalidFileNameCharacters(docProperties.fileName);
      }

      if (usingDocCode) {
        return buildFileName(docCode, fileName);
      }

      return fileName;
    },
    [docProperties, fileNameChanged, uploadNameCandidate, usingDocCode]
  );

  const uploadFileName = useCallback(
    (fileName: string, docCode: string, siblingNames: string[]): string => {
      const uploadFileName = uploadFileNameCandidate(fileName, docCode);

      const uniqueName = getUniqueName(
        uploadFileName,
        siblingNames,
        'underscore'
      );

      return uniqueName;
    },
    [uploadFileNameCandidate]
  );

  const uploadName = useCallback(
    (fileName: string, docCode: string, siblingNames: string[]): string => {
      const uploadName = uploadNameCandidate(fileName, docCode);
      const uniqueName = getUniqueName(uploadName, siblingNames, 'parentheses');
      return uniqueName;
    },
    [uploadNameCandidate]
  );

  const uploadDescription = useCallback(
    (fileName: string): string => {
      if (!descriptionChanged) {
        const description = parseFileNameWithoutExtension(fileName);
        return description;
      }

      return docProperties.description;
    },
    [descriptionChanged, docProperties]
  );

  const getUploadDocProperties = useCallback(
    (
      docName: string,
      docCode: string,
      siblingNames: string[]
    ): DCWDocProperties => {
      const name = uploadName(docName, docCode, siblingNames);
      const fileName = uploadFileName(docName, docCode, siblingNames);
      const description = uploadDescription(docName);
      const updatedProperties = _.cloneDeep(docProperties);
      return { ...updatedProperties, name, fileName, description };
    },
    [docProperties, uploadDescription, uploadFileName, uploadName]
  );

  return {
    getUploadEnvironmentInstance,
    getUploadDocCode,
    getUploadDocProperties
  };
}
