import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { DataError } from '../../../../actions/formRendering';
import { t } from '../../../../services/translation';
import { DocCodePreview } from '../../../docCodePreview';
import {
  docCodeStateAsync,
  generateDocCodeProperties,
  generatingDocCodeState,
  usingDocCodeState
} from '../../docCode';
import { handleDCWError } from '../../error';
import {
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState,
  useFormReloadWorkflow
} from '../../form';
import { useSetProperties } from '../../properties';
import {
  currentEnvironmentInstanceState,
  useRecoilValueAsync
} from '../../state';
import { applyToAllState } from '../applyToRemaining';
import { isCopyModeState } from '../state';
import { docCodeFormDefinitionState } from './state';

export function DocCodePreviewContainer(): JSX.Element | null {
  const docCode = useRecoilValueAsync(docCodeStateAsync);
  const docCodeFormDefinition = useRecoilValueAsync(docCodeFormDefinitionState);

  const currentEnvironmentInstance = useRecoilValueAsync(
    currentEnvironmentInstanceState
  );

  const [generatingDocCode, setGeneratingDocCode] = useRecoilState(
    generatingDocCodeState
  );

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );

  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const applyAllChecked = useRecoilValue(applyToAllState);
  const isCopyMode = useRecoilValue(isCopyModeState);

  const setUsingDocCode = useSetRecoilState(usingDocCodeState);

  const { setCurrentInstanceWithProperties } = useSetProperties();
  const { reloadFormWorkflow } = useFormReloadWorkflow('attributespage');

  const docCodeUsesSerialNum = useMemo((): boolean => {
    return docCodeFormDefinition?.Type != 'DocCodeWithoutSerialNum';
  }, [docCodeFormDefinition?.Type]);

  const allowGenerateNext = useMemo((): boolean => {
    if (isCopyMode && applyAllChecked) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (generatingDocCode) {
      return false;
    }

    return docCodeUsesSerialNum;
  }, [
    applyAllChecked,
    docCodeUsesSerialNum,
    generatingDocCode,
    isCopyMode,
    updatingReloadProperties,
    updatingTriggeredProperties
  ]);

  const generateNextTitle = useMemo((): string => {
    if (isCopyMode && applyAllChecked) {
      return t('DocumentCreation.GenerateNextDisabledWithApplyToRemaining');
    }

    if (docCodeUsesSerialNum) {
      return t('DocumentCreation.GenerateNextInfoMessage');
    }

    return t('DocumentCreation.SerialNumberMissing');
  }, [applyAllChecked, docCodeUsesSerialNum, isCopyMode]);

  async function onGenerate(): Promise<void> {
    if (!currentEnvironmentInstance) {
      return;
    }

    try {
      setGeneratingDocCode(true);

      const updatedProperties = await generateDocCodeProperties(
        currentEnvironmentInstance
      );

      if (updatedProperties && Object.keys(updatedProperties).length) {
        const updatedInstance =
          setCurrentInstanceWithProperties(updatedProperties);
        setUsingDocCode(true);

        if (updatedInstance) {
          void reloadFormWorkflow(updatedInstance);
        }
      }
    } catch (error) {
      void handleDCWError(error as DataError);
    } finally {
      setGeneratingDocCode(false);
    }
  }

  if (updatingTriggeredProperties) {
    return null;
  }

  return (
    <DocCodePreview
      docCode={docCode}
      allowGenerateNext={allowGenerateNext}
      onGenerate={onGenerate}
      generateNextTitle={generateNextTitle}
    />
  );
}
