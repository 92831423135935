import React from 'react';
import { itemIsProject } from '@bentley/pw-api';
import { DataItemIcon } from './dataItemIcon';
import { MultipleItemsIcon } from './multipleItemsIcon';
import { ProjectIcon } from './projectIcon';
export function Icon({ item, size, ...rest }) {
    if (Array.isArray(item)) {
        if (item.length == 1) {
            return React.createElement(Icon, { item: item[0], size: size, ...rest });
        }
        return React.createElement(MultipleItemsIcon, { size: size, ...rest });
    }
    if (itemIsProject(item)) {
        return React.createElement(ProjectIcon, { item: item, size: size, ...rest });
    }
    return React.createElement(DataItemIcon, { item: item, size: size, ...rest });
}
