import { t } from '../../services/translation';
import type { EnvironmentProperty } from './environmentProperty';

export function idColumn(
  displayLabel: string,
  propertyType: 'General' | 'Folder'
): EnvironmentProperty {
  return {
    name: '$id',
    displayLabel: displayLabel,
    dataType: 'id',
    propertyType: propertyType,
    envLabel:
      propertyType == 'General'
        ? t('CustomColumns.GeneralProperties')
        : t('CustomColumns.FolderProperties'),
    isProjectEnv: false
  };
}

export function workflowColumn(): EnvironmentProperty {
  return {
    name: 'Workflow',
    dataType: 'string',
    displayLabel: 'Workflow',
    propertyType: 'General',
    envLabel: t('CustomColumns.GeneralProperties'),
    isProjectEnv: false
  };
}

export function workflowIdColumn(): EnvironmentProperty {
  return {
    name: 'WorkflowId',
    dataType: 'int',
    displayLabel: 'Workflow Id',
    propertyType: 'General',
    envLabel: t('CustomColumns.GeneralProperties'),
    isProjectEnv: false,
    picklistOptions: undefined
  };
}

export function stateColumn(): EnvironmentProperty {
  return {
    name: 'State',
    dataType: 'string',
    displayLabel: 'State',
    propertyType: 'General',
    envLabel: t('CustomColumns.GeneralProperties'),
    isProjectEnv: false
  };
}

export function stateIdColumn(): EnvironmentProperty {
  return {
    name: 'StateId',
    dataType: 'int',
    displayLabel: 'State Id',
    propertyType: 'General',
    envLabel: t('CustomColumns.GeneralProperties'),
    isProjectEnv: false,
    picklistOptions: undefined
  };
}
