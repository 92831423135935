import type { ReactNode } from 'react';
import React from 'react';
import { ProgressRadial } from '@itwin/itwinui-react';
import { LoadingPage } from '../loadingPage';

type PWModalLoadingWrapperProps = {
  isLoading?: boolean;
  loadingStyle?: 'loadingPage' | 'spinnerOverlay';
  loadingText?: string;
  children: ReactNode;
};

export function PWModalLoadingWrapper({
  isLoading,
  loadingStyle,
  loadingText,
  children
}: PWModalLoadingWrapperProps): JSX.Element {
  if (!isLoading) {
    return <>{children}</>;
  }

  if (loadingStyle == 'spinnerOverlay') {
    return (
      <>
        <div className="pw-modal-spinner-overlay">
          <ProgressRadial size="large" role="progressbar" />
        </div>
        <>{children}</>
      </>
    );
  }

  return (
    <LoadingPage
      loadingSubText={loadingText}
      className="pw-modal-loading-page"
    />
  );
}
