import { useMemo } from 'react';
import type { MenuItemAction } from '../MenuItemAction';
import { useAboutConnectionOption } from './buttons/useAboutConnectionOption';
import { useCoAuthoringOptionsOption } from './buttons/useCoAuthoringOptionsOption';
import { useDisplayOption } from './buttons/useDisplayOption';
import { useDriveOption } from './buttons/useDriveOption';
import { useInProgressOption } from './buttons/useInProgressOption';
import { useInfoPanelOption } from './buttons/useInfoPanelOption';
import { useLaunchDarklyOption } from './buttons/useLaunchDarklyOption';
import { useRefreshOption } from './buttons/useRefreshOption';
import { useSignOutOption } from './buttons/useSignOutOption';
import { useViewsOption } from './buttons/useViewsOption';

export function useOptionActions(): MenuItemAction[] {
  const refreshOption = useRefreshOption();
  const infoPanelOption = useInfoPanelOption();
  const viewsOption = useViewsOption();
  const driveOption = useDriveOption();
  const inProgressOption = useInProgressOption();
  const displayOption = useDisplayOption();
  const aboutConnectionOption = useAboutConnectionOption();
  const launchDarklyOption = useLaunchDarklyOption();
  const coAuthoringOptionsOption = useCoAuthoringOptionsOption();
  const signOutOption = useSignOutOption();

  const optionActions = useMemo((): MenuItemAction[] => {
    return [
      refreshOption,
      infoPanelOption,
      viewsOption,
      driveOption,
      inProgressOption,
      displayOption,
      aboutConnectionOption,
      launchDarklyOption,
      coAuthoringOptionsOption,
      signOutOption
    ];
  }, [
    aboutConnectionOption,
    displayOption,
    driveOption,
    inProgressOption,
    infoPanelOption,
    launchDarklyOption,
    coAuthoringOptionsOption,
    refreshOption,
    signOutOption,
    viewsOption
  ]);

  return optionActions;
}
