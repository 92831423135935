import './connectionPanelContainer.css';

import type { ReactNode } from 'react';
import React from 'react';
import { useConnectionsContext } from '../../context';
import { ConnectionsList } from '../connectionsList';

type ConnectionPanelContainerProps = {
  children?: ReactNode;
};

export function ConnectionPanelContainer({
  children
}: ConnectionPanelContainerProps): JSX.Element | null {
  const { connections } = useConnectionsContext();

  function connectionList(): JSX.Element | null {
    if (!connections.length) {
      return null;
    }

    return <ConnectionsList />;
  }

  return (
    <div className="connectionPanelContainer" id="projectWiseAppMain">
      {connectionList()}
      {children}
    </div>
  );
}
