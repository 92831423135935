import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject
} from '@bentley/pw-api';
import { createDocumentWithFile, createFolder } from '@bentley/pw-api';
import { MAX_UPLOAD_CHUNK_SIZE } from '../../constants';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type {
  InProgressJob,
  ProgressManager
} from '../../hooks/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { generateUUID } from '../../services/data';
import type { ToastHandle } from '../../services/pwToast';
import { deleteItem } from '../delete';
import { handleFailedUploads } from './errors';
import { notifyUploadInProgress } from './notifications';
import {
  afterUploadDocumentFailure,
  afterUploadDocumentSuccess,
  onUploadError,
  uploadResponseCallback
} from './responses';
import type { CustomFile, UploadNode } from './tree';
import {
  castProjectInfoToProject,
  getItemFromResponse,
  getProjectFromChangedInstance
} from './utils';

export async function uploadMultipleWorkflow(
  uploadNode: UploadNode,
  parent: PWProject,
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  jobId?: string,
  onComplete?: (uploadedItems: PWItem[]) => void,
  shouldToast = true,
  trackJob?: (jobId: string) => void,
  toastHandle?: ToastHandle
): Promise<Response[]> {
  trackFeature('UPLOAD_START');
  if (shouldToast) {
    if (!jobId) {
      toastHandle = notifyUploadInProgress(toastHandle);
      jobId = progressManager.addNewJobToTracker('upload', toastHandle);
    } else {
      const index = progressManager.progressTracker.jobsInProgress.findIndex(
        (job) => job.jobId == jobId
      );
      if (
        index >= 0 &&
        !progressManager.progressTracker.jobsInProgress[index].toastHandle
      ) {
        toastHandle = notifyUploadInProgress(toastHandle);
        progressManager.progressTracker.jobsInProgress[index].toastHandle =
          toastHandle;
      } else if (jobId && index == -1) {
        toastHandle = notifyUploadInProgress(toastHandle);
        progressManager.reactivateOldJob(jobId, 'upload', toastHandle);
      }
    }
  } else {
    jobId = '';
  }

  uploadNode.files.sort((a, b) => (a.name > b.name ? 1 : -1));

  const responses = await uploadMultiple(
    uploadNode,
    parent,
    httpService,
    trackFeature,
    jobId,
    progressManager,
    onComplete
  );

  const uploadJob = progressManager.progressTracker.jobsInProgress?.find(
    (j) => j.jobId == jobId
  );
  if (uploadJob) {
    await deleteCancelledItemPlaceholders(
      uploadJob,
      parent,
      httpService,
      trackFeature
    );
  }

  if (jobId) {
    trackJob?.(jobId);
  }

  if (needToHandleFailedUploads(uploadJob)) {
    handleFailedUploads(
      progressManager,
      jobId,
      parent,
      openModal,
      closeModal,
      httpService,
      trackFeature,
      onComplete
    );
  }

  return responses;
}

function needToHandleFailedUploads(uploadJob?: InProgressJob): boolean {
  const failedActivities = uploadJob?.activitiesInProgress.filter(
    (a) => a.status == 'error'
  );
  return failedActivities !== undefined && failedActivities.length > 0;
}

async function deleteCancelledItemPlaceholders(
  uploadJob: InProgressJob,
  parent: PWParentType,
  httpService: HttpService,
  trackFeature: TrackFeature
) {
  const cancelledItems = uploadJob?.activitiesInProgress
    .filter((a) => a.status == 'abort' || a.abortController?.signal.aborted)
    .map((a) => a.item);

  if (cancelledItems && cancelledItems.length > 0) {
    await Promise.all(
      cancelledItems.map((item) =>
        deleteItem(item, parent, httpService, trackFeature)
      )
    );
  }
}

async function uploadMultiple(
  uploadNode: UploadNode,
  parent: PWProject,
  httpService: HttpService,
  trackFeature: TrackFeature,
  jobId: string,
  progressManager: ProgressManager,
  onComplete?: (uploadedItems: PWItem[]) => void
): Promise<Response[]> {
  if (progressManager?.progressTracker.cancelAllUploads) {
    onComplete?.([]);
    return [];
  }

  if (!parent.instanceId) {
    return [];
  }

  // Upload directories
  const directories = await Promise.all(
    uploadNode.directories.map((directory) =>
      uploadNewFolderWorkflow(
        directory.current?.name ?? '',
        '',
        parent.instanceId,
        httpService,
        trackFeature,
        jobId,
        progressManager,
        directory
      )
    )
  );

  // Upload files
  const files = await Promise.all(
    uploadNode.files.map((file) =>
      uploadDocumentWorkflow(
        file,
        parent,
        httpService,
        trackFeature,
        jobId,
        progressManager
      )
    )
  );

  if (onComplete) {
    const uploadedProjects = await Promise.all(
      directories.map(getProjectFromChangedInstance)
    );

    const uploadActivities = progressManager
      .getJobActivities(jobId)
      .filter((activity) => activity.activityType == 'upload');
    if (
      uploadActivities.every(
        ({ status }) => status != 'inprogress' && status != 'processing'
      )
    ) {
      const allUploadedFiles = uploadActivities.map(
        (activity) => activity.item
      );
      onComplete([...allUploadedFiles, ...uploadedProjects]);
    }
  }

  // Recursively upload contents in each directory
  const subdirectories = (
    await Promise.all(
      uploadNode.directories.map(async (directory, idx) => {
        const parent = await getProjectFromChangedInstance(directories[idx]);
        return uploadMultiple(
          directory,
          parent,
          httpService,
          trackFeature,
          jobId,
          progressManager,
          onComplete
        );
      })
    )
  ).reduce((acc, cur) => [...acc, ...cur], []);

  // Return flattened list of all responses
  return [...directories, ...files, ...subdirectories];
}

/**
 * Uploads a new file to the PW datasource
 * @param {CustomFile} file File from the file system to create document for and upload
 * @param {PWProject} parent Instance of parent folder that the items are being uploaded to
 * @param {HttpService} httpService Configured http service
 * @param {(featureName: string) => void} trackFeature Feature tracking method
 * @param {string} [jobId] id of job that upload is associated with
 * @param {ProgressManager} [progressManager] ProgressManager used in multiple uploads
 * @param {(uploadedItems: PWItem[]) => any} [onComplete] Function called when workflow is finished
 * @param {string} [customVersion] version string for the upload
 * @returns {Promise<Response>} Response from upload request
 */
export async function uploadDocumentWorkflow(
  file: CustomFile,
  parent: PWProject,
  httpService: HttpService,
  trackFeature: TrackFeature,
  jobId?: string,
  progressManager?: ProgressManager,
  onComplete?: (uploadedItems: PWItem[]) => void,
  customVersion?: string
): Promise<Response> {
  let uniqueId;
  const abortController = new AbortController();
  if (progressManager?.progressTracker.cancelAllUploads) {
    abortController.abort();
    onComplete?.([]);
    return {} as Response;
  }

  if (progressManager && jobId) {
    uniqueId = progressManager.addUploadToJob(
      jobId,
      file,
      parent,
      abortController
    );
  }

  const provisionalGuid = generateUUID();
  const response = await uploadDocument(
    file,
    uniqueId ?? provisionalGuid,
    parent,
    httpService,
    abortController,
    jobId,
    progressManager,
    customVersion,
    onComplete
  );
  const isSuccess = response.status == 201 || response.status == 200;
  if (isSuccess) {
    afterUploadDocumentSuccess(
      uniqueId ?? provisionalGuid,
      jobId,
      progressManager
    );
  }
  trackFeature(isSuccess ? 'UPLOAD_SUCCESSFUL' : 'UPLOAD_FAILURE');

  const uploadedItem = await getItemFromResponse(response);
  if (jobId && uniqueId && uploadedItem) {
    progressManager?.updateItemInfo(jobId, uniqueId, uploadedItem);
  }
  onComplete?.(uploadedItem ? [uploadedItem] : []);

  return response;
}

async function uploadDocument(
  file: CustomFile,
  uniqueId: string,
  parent: PWProject,
  httpService: HttpService,
  abortController: AbortController,
  jobId?: string,
  progressManager?: ProgressManager,
  customVersion?: string,
  onComplete?: (uploadedItems: PWItem[]) => void
): Promise<Response> {
  const fileName = file.customName ?? file.name;
  const documentOptions = {
    instanceId: file.id,
    Name: file.id ? file.documentName : fileName,
    Description: file.id ? undefined : file.documentDescription ?? fileName,
    FileName: file.id ? fileName : file.name,
    Version: customVersion
  };

  if (progressManager && jobId) {
    progressManager.updateProgressStatus(jobId, uniqueId, 'inprogress');
    if (file.size <= MAX_UPLOAD_CHUNK_SIZE) {
      progressManager.disableAbortForActivity(jobId, uniqueId);
    }
  }

  const response = await usingConcurrencyLimiter(async () => {
    try {
      const response = await createDocumentWithFile({
        file,
        parentId: parent.instanceId,
        documentOptions,
        httpService,
        requestOptions: { abortController },
        responseCallback: uploadResponseCallback(
          uniqueId,
          jobId,
          progressManager
        )
      });
      return response;
    } catch (error) {
      if (error instanceof Response) {
        await afterUploadDocumentFailure(
          error,
          file,
          parent,
          uniqueId,
          jobId,
          progressManager,
          onComplete
        );
        return error;
      }

      void onUploadError(
        error as Error,
        uniqueId,
        httpService,
        jobId,
        progressManager
      );
      const status =
        (error as Error).name == 'AbortError'
          ? -1
          : (error as Response).status
          ? (error as Response).status
          : 400;
      return { status } as Response;
    }
  });
  return response;
}

/**
 * Uploads a new folder to the PW datasource
 * @param {string} projectName Name of new folder to upload
 * @param {string} description Description of new folder. May be empty.
 * @param {string} parentId InstanceId of parent folder that new folder is being uploaded to
 * @param {HttpService} httpService Configured http service
 * @param {(featureName: string) => void} trackFeature Feature tracking method
 * @param {ProgressManager} [progressManager] ProgressManager used in multiple uploads
 * @param {UploadNode} [uploadNode] Upload node with children (used for conflict resolution recovery)
 * @param {() => void} [onComplete] Function called when workflow is finished
 * @returns {Promise<Response>} Response from upload request
 */
export async function uploadNewFolderWorkflow(
  projectName: string,
  description: string,
  parentId: string,
  httpService: HttpService,
  trackFeature?: TrackFeature,
  jobId?: string,
  progressManager?: ProgressManager,
  uploadNode?: UploadNode,
  onComplete?: () => void
): Promise<Response> {
  let uniqueId;
  if (progressManager && jobId) {
    const item = {
      ...castProjectInfoToProject(projectName, description),
      uploadNode: uploadNode
    };
    uniqueId = progressManager.addUploadFolderToJob(jobId, item);
  }

  const response = await uploadNewFolder(
    projectName,
    description,
    parentId,
    httpService
  );
  trackFeature?.(
    response.status == 201
      ? 'UPLOAD_CREATE_FOLDER_SUCCESSFUL'
      : 'UPLOAD_FAILURE'
  );

  if (uniqueId && progressManager && jobId) {
    if (response.status !== 201) {
      progressManager.updateProgressStatus(jobId, uniqueId, 'error', response);
    } else {
      progressManager.updateProgressStatus(jobId, uniqueId, 'success');
    }
  }

  onComplete?.();
  return response;
}

async function uploadNewFolder(
  projectName: string,
  description: string,
  parentId: string,
  httpService: HttpService
): Promise<Response> {
  return usingConcurrencyLimiter(
    async () =>
      await createFolder({
        parentId,
        projectOptions: { Name: projectName, Description: description },
        httpService
      })
  );
}
