import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgImodel } from '@itwin/itwinui-icons-react';
import {
  getIModelLabels,
  getSpatialRootLabels
} from '../../../../actions/imodelMapping';
import { useEnvironmentContext, usePluginContext } from '../../../../context';
import { SvgSpatialRoot } from '../../../icons';
import { PWTooltip } from '../../../pwTooltip';

type IModelBadgeProps = {
  item: PWItem;
};

export function IModelBadge({ item }: IModelBadgeProps): JSX.Element | null {
  const {
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();
  const { iModels } = useEnvironmentContext();

  const iModelLabel = getIModelLabels(item, iModelBridges, iModels);
  const spatialRootLabel = getSpatialRootLabels(item, iModelBridges, iModels);

  const showIModelInfo = useMemo((): boolean => {
    if (!iModelLabel && !spatialRootLabel) {
      return false;
    }

    return true;
  }, [iModelLabel, spatialRootLabel]);

  if (!showIModelInfo) {
    return null;
  }

  return (
    <span style={{ display: 'flex' }} key="sls">
      {spatialRootLabel && (
        <PWTooltip content={spatialRootLabel}>
          <span className="pw-cell-badge">
            <SvgSpatialRoot data-testid="spatialRootBadge" />
          </span>
        </PWTooltip>
      )}
      {iModelLabel && (
        <PWTooltip content={iModelLabel}>
          <span className="pw-cell-badge">
            <SvgImodel data-testid="iModelBadge" />
          </span>
        </PWTooltip>
      )}
    </span>
  );
}
