import React from 'react';
import type { PWProject } from '@bentley/pw-api';
import { useNavigationContext } from '../../../context';
import { ShowMoreNode } from '../showMoreNode/showMoreNode';
import { FolderNode } from './folderNode';

export const pageSize = 50;

type TruncatedFoldersNodeProps = {
  folders: PWProject[];
  numChildrenDisplayed: number;
  setNumChildrenDisplayed: React.Dispatch<React.SetStateAction<number>>;
};

export function TruncatedFoldersNode({
  folders,
  numChildrenDisplayed,
  setNumChildrenDisplayed
}: TruncatedFoldersNodeProps): JSX.Element | null {
  const { navigationManager, breadcrumbManager } = useNavigationContext();

  function isCurrentFolder(folder: PWProject): boolean {
    if (navigationManager.currentParent.instanceId == folder.instanceId) {
      return true;
    }

    if (
      breadcrumbManager.breadcrumbs.some(
        (breadcrumb) => breadcrumb.instanceId == folder.instanceId
      )
    ) {
      return true;
    }

    return false;
  }

  const foldersToDisplay = folders.slice(0, numChildrenDisplayed);
  const currentFolder = folders.find(isCurrentFolder);
  const currentFolderTruncated =
    currentFolder && !foldersToDisplay.includes(currentFolder);

  let hiddenCount = folders.length - numChildrenDisplayed;
  if (currentFolderTruncated) {
    hiddenCount -= 1;
  }

  if (!currentFolderTruncated && !hiddenCount) {
    return null;
  }

  return (
    <>
      {hiddenCount > 0 && (
        <ShowMoreNode
          hiddenCount={hiddenCount}
          onClick={() => setNumChildrenDisplayed((cur) => cur + pageSize)}
        />
      )}
      {currentFolderTruncated && (
        <FolderNode key={currentFolder.instanceId} folder={currentFolder} />
      )}
    </>
  );
}
