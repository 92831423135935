import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTableItemContext } from '../../../context';
import {
  createDocumentEnvironmentInstance,
  saveDocumentEnvironmentInstance
} from '../../attributesForm/data/data';
import {
  changedProperties,
  validateEnvironmentProperties
} from '../../documentCreation/properties/compare';
import {
  handleDocCodeFormError,
  handleDocCodeFormSuccess
} from '../error/handleError';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState,
  originalEnvironmentInstanceState
} from '../state/state';
import { saveInProgressState } from './state';

type SaveFunctions = {
  saveInstance: () => Promise<void>;
};

export function useSave(onSave?: () => void): SaveFunctions {
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const [originalEnvironmentInstance, setOriginalEnvironmentInstance] =
    useRecoilState(originalEnvironmentInstanceState);
  const setEditingDocCodeForm = useSetRecoilState(editingDocCodeFormState);
  const [saveInProgress, setSaveInProgress] =
    useRecoilState(saveInProgressState);

  const { dataManager, actionableItems } = useTableItemContext();

  async function saveInstance(): Promise<void> {
    if (saveInProgress) {
      return;
    }

    setSaveInProgress(true);

    try {
      if (!currentEnvironmentInstance) {
        return;
      }

      const updatedProperties = changedProperties(
        currentEnvironmentInstance.properties,
        originalEnvironmentInstance?.properties
      );

      const validatedProperties = validateEnvironmentProperties(
        updatedProperties,
        originalEnvironmentInstance?.properties ?? {}
      );

      if (!originalEnvironmentInstance) {
        const updatedInstance = await createDocumentEnvironmentInstance(
          actionableItems[0],
          currentEnvironmentInstance,
          validatedProperties
        );
        setOriginalEnvironmentInstance(updatedInstance);
        setCurrentEnvironmentInstance(updatedInstance);
      } else {
        const updatedInstance = await saveDocumentEnvironmentInstance(
          actionableItems[0],
          currentEnvironmentInstance,
          validatedProperties
        );
        setOriginalEnvironmentInstance(updatedInstance);
        setCurrentEnvironmentInstance(updatedInstance);
      }

      void dataManager.refresh();
      setEditingDocCodeForm(false);
      handleDocCodeFormSuccess();
      if (onSave) {
        onSave();
      }
    } catch (error) {
      if (error instanceof Response || error instanceof Error) {
        void handleDocCodeFormError(error);
      }
    } finally {
      setSaveInProgress(false);
    }
  }

  return { saveInstance };
}
