import React from 'react';
export function SvgFiletypeIfc(props) {
    return (React.createElement("svg", { ...props, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16" },
        React.createElement("path", { d: "M11,3V0H2V16H14V3Z", fill: "#bcece0" }),
        React.createElement("path", { d: "M11,0l3,3H11Z", fill: "#99cfc9" }),
        React.createElement("path", { d: "M7.23313,8.191l-.12494-.127a.91169.91169,0,0,0-1.11375,0l-1.62,1.62a.79453.79453,0,0,0,0,1.11375l1.97437,1.97438a.73407.73407,0,0,0,1.06313,0l.65812-.65813.05063-.05062-.65813-.65813-.55687.55688L5.18444,10.24094m2.2275-.55688,1.51875,1.51875.10125-.10125a.73406.73406,0,0,0,0-1.06312c-.30376-.35438-.65813-.65813-1.0125-1.0125", fill: "#fff" }),
        React.createElement("path", { d: "M11.20881,8.92469l-.65812.65812.05062.05063.45563.45562c.05062.05063.05062.05063,0,.10125l-1.62,1.62c-.05063.05063-.05063.05063-.10125,0L7.46256,9.88656l-.11994-.11913-.13318.11913a.73407.73407,0,0,0,0,1.06313l1.62,1.62a.73351.73351,0,0,0,1.06312,0l1.97438-1.97438.05062-.05062a.84137.84137,0,0,0-.05062-1.06313l-.65813-.55687m-2.37937.65812,1.51875-1.51875-.15188-.15187a.72.72,0,0,0-1.0125.05062l-.96187.91125-.05063.05063.65813.65812", fill: "#fff" }),
        React.createElement("path", { d: "M9.28506,5.88719l1.72125,1.72125L8.93069,9.68406l.10125.10125a.79452.79452,0,0,0,1.11374,0l1.62-1.62a.79453.79453,0,0,0,0-1.11375L9.84193,5.12781a.73406.73406,0,0,0-1.06312,0,4.37132,4.37132,0,0,0-.70875.65813l.65812.65812M8.07006,8.82344l.65812-.65813L7.20943,6.64656l-.10124.10125a.79636.79636,0,0,0-.05063,1.11375l.05063.05063.96187.91125", fill: "#fff" }),
        React.createElement("path", { d: "M8.84452,8.078l.13679-.1658a.783.783,0,0,0-.05062-1.06313l-1.62-1.62a.73407.73407,0,0,0-1.06313,0c-.70875.65813-1.36687,1.31625-2.025,1.97438a.73406.73406,0,0,0,0,1.06312,9.05,9.05,0,0,1,.70875.65813l.65813-.65813-.55688-.55687.05063-.05063,1.62-1.62c.05062-.05062.05062-.05062.10125,0L8.77881,8.01344ZM5.79194,9.78531l.15187.15188a.75189.75189,0,0,0,.70875.2025.95284.95284,0,0,0,.405-.25313l.91125-.91125-.65812-.70875L5.79194,9.78531", fill: "#fff" })));
}
