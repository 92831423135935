import { useCallback } from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import {
  useAppViewContext,
  useNavigationContext,
  useTableItemContext
} from '../../context';
import { notifyItemsRestored } from './notifications';

type OnRestoreCompleteFunctions = {
  onRestoreComplete: (items: PWItem[], navigate: boolean | undefined) => void;
  onRestoreToFolderComplete: (items: PWItem[], parentId: string) => void;
};

export function useOnRestoreComplete(): OnRestoreCompleteFunctions {
  const {
    navigationManager: { navigateTo }
  } = useNavigationContext();
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder },
    selectedState: { forceSelectedIds }
  } = useTableItemContext();

  const navigateAndSelect = useCallback(
    (parentId: string, items: PWItem[]): void => {
      const parent = {
        className: 'Project',
        instanceId: parentId
      } as PWProject;
      navigateTo(parent, true);
      forceSelectedIds(items.map((item) => item.instanceId));
      setAppView('FolderNavigation');
    },
    [forceSelectedIds, navigateTo, setAppView]
  );

  const onRestoreComplete = useCallback(
    (items: PWItem[], navigate: boolean | undefined) => {
      notifyItemsRestored(items);
      if (navigate && items[0].ParentGuid) {
        navigateAndSelect(items[0].ParentGuid, items);
      } else {
        refreshCurrentFolder();
      }
    },
    [navigateAndSelect, refreshCurrentFolder]
  );

  const onRestoreToFolderComplete = useCallback(
    (items: PWItem[], parentId: string): void => {
      notifyItemsRestored(items);
      navigateAndSelect(parentId, items);
    },
    [navigateAndSelect]
  );

  return { onRestoreComplete, onRestoreToFolderComplete };
}
