import { User } from 'oidc-client';
import { RBACPermission } from '../../../hooks/useRbac';
import { isAdmin } from '../../../services/permissions';

export function hasSettingsPermissions(
  user: User,
  rbacPermissions: RBACPermission[]
): boolean {
  if (isAdmin(user)) {
    return true;
  }

  if (hasBothConnectionEditPermissions(rbacPermissions)) {
    return true;
  }

  return false;
}

function hasBothConnectionEditPermissions(
  rbacPermissions: RBACPermission[]
): boolean {
  const hasModifyConnectionsPermission = rbacPermissions.some(
    ({ instanceId }) => instanceId == 'PSFEDCONNMODIFY'
  );
  const hasManageSettingsPermission = rbacPermissions.some(
    ({ instanceId }) => instanceId == '2752_PrdssCtxWrite'
  );

  return hasModifyConnectionsPermission && hasManageSettingsPermission;
}
