import React from 'react';
import { Alert } from '@itwin/itwinui-react';
import { useConnectionAuth, usePluginContext } from '../../context';
import { useLocalStorage } from '../../hooks/useStorage';
import { t } from '../../services/translation';

export function LogicalAccountBanner(): JSX.Element | null {
  const { isLogicalUser } = useConnectionAuth();
  const { connection } = usePluginContext();

  const [showBanner, setShowBanner] = useLocalStorage(
    `logicalAccountBanner:${connection.Id}`,
    true
  );

  function closeBanner(): void {
    setShowBanner(false);
  }

  if (!isLogicalUser || !showBanner) {
    return null;
  }

  return (
    <Alert.Wrapper type="warning" className="main-page-alert">
      <Alert.Icon />
      <Alert.Message>{t('SignIn.LogicalAcctWarning')}</Alert.Message>
      <Alert.CloseButton onClick={closeBanner} />
    </Alert.Wrapper>
  );
}
