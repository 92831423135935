import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import type { IRendererElement } from '@bentley/formsrenderer/lib/form-renderer/components/RendererElement/RendererElement';
import { updatingReloadPropertiesState } from './state';
import { updatingTriggeredPropertiesState } from '.';

type FocusedElementFunctions = {
  addFocusListener: (element: IRendererElement, $element: JQuery) => void;
};

export function useFocusedElement(): FocusedElementFunctions {
  const [focusedElementId, setFocusedElementId] = useState<string>();

  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );

  const addFocusListener = useCallback(
    (element: IRendererElement, $element: JQuery): void => {
      const elementId = element.getElementId();
      if (!elementId) {
        return;
      }

      $element.on('focus', () => {
        setFocusedElementId(elementId);
        focusDropdownSelection(elementId);
      });
    },
    []
  );

  const refocusElement = useCallback((): void => {
    if (!focusedElementId) {
      return;
    }

    focusElementWithElementId(focusedElementId);
    setFocusedElementId(undefined);
  }, [focusedElementId]);

  useEffect(() => {
    if (!updatingTriggeredProperties && !updatingReloadProperties) {
      setTimeout(refocusElement);
    }
  }, [refocusElement, updatingReloadProperties, updatingTriggeredProperties]);

  return { addFocusListener };
}

function focusElementWithElementId(elementId: string): void {
  const elements = document.querySelectorAll(`[data-elementid='${elementId}']`);
  // Sometimes parent elements have the same elementId. We want to focus on the input that is a child of the last element.
  const lastElement = elements[elements.length - 1];
  const input = lastElement.querySelector('input');
  if (document.activeElement != input) {
    input?.focus();
  }
}

function focusDropdownSelection(elementId: string): void {
  const selectedOption = getDropdownOption(elementId);
  if (!selectedOption) {
    return;
  }

  const selectedValue = getDropdownOptionValue(selectedOption);
  if (!selectedValue) {
    return;
  }

  const dataValue = getSelectOptionValue(elementId);

  if (selectedValue == dataValue) {
    scrollToItem(selectedOption);
  }
}

type ScrollableElement = Element & { scrollIntoViewIfNeeded?: () => void };

function getDropdownOption(elementId: string): Element | undefined {
  const dropdown = document.getElementById(`dropdown_${elementId}`);
  if (!dropdown) {
    return;
  }

  const selectedOptions = dropdown.getElementsByClassName(
    'autocomplete-active'
  );
  if (selectedOptions.length != 1) {
    return;
  }

  const selectedOption = selectedOptions[0];
  return selectedOption;
}

function getDropdownOptionValue(selectedOption: Element): string | undefined {
  const selectedValue = selectedOption.getAttribute('data-value');
  if (!selectedValue) {
    return;
  }

  return selectedValue;
}

function getSelectOptionValue(elementId: string): string | undefined {
  const inputWrapper = document.getElementById(
    `${elementId}-validation-wrapper`
  );
  const input = inputWrapper?.getElementsByTagName('input')[0];
  const dataValue = input?.getAttribute('data-value');
  if (!dataValue) {
    return;
  }

  return dataValue;
}

function scrollToItem(element: ScrollableElement): void {
  if (element.scrollIntoViewIfNeeded) {
    return element.scrollIntoViewIfNeeded();
  }

  element.scrollIntoView();
}
