import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgFolderImport } from '@itwin/itwinui-icons-react';
import { useRestoreToFolder } from '../../../../actions/restore';
import { useAppViewContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useRestoreToFolderItemAction(items: PWItem[]): MenuItemAction {
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const restoreItemsToFolderWorkflow = useRestoreToFolder();

  const onRestoreToClick = useCallback((): void => {
    void restoreItemsToFolderWorkflow(items);
  }, [items, restoreItemsToFolderWorkflow]);

  const restoreToItem = useMemo(
    (): MenuItemAction => ({
      title: t('Restore.RestoreTo'),
      icon: <SvgFolderImport />,
      onClick: onRestoreToClick,
      disabled: !items.length,
      hidden: currentAppView.type != 'RecycleBin'
    }),
    [currentAppView.type, items.length, onRestoreToClick]
  );

  return restoreToItem;
}
