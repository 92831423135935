import React from 'react';
import { Text } from '@itwin/itwinui-react';
import type { UploadNode } from '../../../actions/upload';
import type { CloseModal } from '../../../hooks/useModal';
import { t } from '../../../services/translation';
import { PluginWrapper } from '../../pluginWrapper/pluginWrapper';
import { PWModal } from '../../pwModal';

// eslint-disable-next-line no-warning-comments
// TODO: Remove fileNumber, openDocumentWizard, and uploadNode from props
// These are here solely to let the Drive team access this modal without adjusting their code
// To be removed when Drive updates
interface IConfirmCancelModalProps {
  closeModal: CloseModal;
  closeConfirmModal: CloseModal;
  fileNumber?: number;
  openDocumentWizard?: (node: UploadNode | undefined) => void;
  uploadNode?: UploadNode;
  onConfirm?: () => void;
}

export function ConfirmCancelModal({
  closeModal,
  closeConfirmModal,
  fileNumber,
  openDocumentWizard,
  uploadNode,
  onConfirm
}: IConfirmCancelModalProps): JSX.Element {
  // Function to be removed upon updates by PW Drive team
  function removeFileAndRunWizard() {
    if (uploadNode?.directories.length) {
      return;
    }

    const newNode = {
      ...uploadNode,
      files: uploadNode?.files.slice(fileNumber ?? 0 + 1)
    } as UploadNode;

    openDocumentWizard?.(newNode);
  }

  return (
    <PluginWrapper>
      <PWModal
        title={t('DocumentCreation.CancelDocumentCreation')}
        primaryButton={{
          title: t('Generic.Yes'),
          onClick: () => {
            closeConfirmModal();
            closeModal();
          }
        }}
        secondaryButton={{
          title: t('Generic.No'),
          onClick: () => {
            closeConfirmModal();
            onConfirm ? onConfirm() : removeFileAndRunWizard();
          }
        }}
        onClose={closeConfirmModal}
      >
        <Text>{t('DocumentCreation.CancelForRemaining')}</Text>
      </PWModal>
    </PluginWrapper>
  );
}
