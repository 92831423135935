import { useEffect, useState } from 'react';
import type { WSGInstancesResponse } from '@bentley/pw-api';
import {
  AuthorizationService,
  CacheService,
  HttpService
} from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../context';
import type { IModel } from './iModel';

export function useIModels(): IModel[] | undefined {
  const { getSamlToken } = useToken();

  const [iModels, setIModels] = useState<IModel[]>();
  const { contextId, buddiRegionCode } = usePluginContext();

  const iModelHubUrl = useBuddi('iModelHubApi', buddiRegionCode);

  useEffect(() => {
    if (!iModelHubUrl || !contextId) {
      return;
    }

    const baseUrl = `${iModelHubUrl}/v2.5/Repositories/Project--${contextId}`;
    const httpService = new HttpService({
      authorization: new AuthorizationService({ getSamlToken }),
      baseUrl,
      cache: new CacheService({ name: 'IModel' })
    });

    async function getIModels() {
      try {
        const response = await httpService.get('ProjectScope/iModel');
        if (!response.ok) {
          throw response;
        }

        const data = (await response.json()) as WSGInstancesResponse;
        const iModels = data.instances as IModel[];
        setIModels(iModels);
      } catch (err) {
        console.error('Failed to load iModels', err);
      }
    }

    void getIModels();
  }, [iModelHubUrl, contextId, getSamlToken]);

  return iModels;
}
