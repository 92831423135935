import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { Anchor } from '@itwin/itwinui-react';
import { useCoAuthoringFeatureClient } from '../../components/coAuthoring';
import type { GraphApiContext, OneDriveFileStatus } from '../../context';
import { useFeatureTracking, usePluginContext } from '../../context';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { officeApplication } from '../drive/pwDrive.utils';
import {
  getOfficeApplicationType,
  isDriveFeatureAvailable,
  openO365FileDrive
} from '../drive/pwDrive.utils';
import { showEnablePopupsModal } from './enablePopupsModal';
import { isSupportedOfficeType } from './requirements';
import type { CoAuthoringSessionContext } from './useCoAuthoringClient';
import {
  isCoAuthoringError,
  useCoAuthoringClient,
  useCoAuthoringSessions
} from './useCoAuthoringClient';
import type { CoAuthoringSession } from './useCoAuthoringClient/coAuthoringTypes';
import { useCoAuthoringModal } from './useCoAuthoringModal';
import { useCommonErrorHandler } from './useCommonErrorHandler';

export function useGraphApi(): GraphApiContext {
  const { user, pwDriveData } = usePluginContext();
  const modal = useCoAuthoringModal();
  const { trackFeature } = useFeatureTracking();

  const { coAuthoringSessions, refreshCoAuthoringSessions } =
    useCoAuthoringSessions();

  const handleCommonErrors = useCommonErrorHandler(
    modal,
    refreshCoAuthoringSessions
  );
  const coAuthoringFeatures = useCoAuthoringFeatureClient();

  const coAuthoringClient = useCoAuthoringClient();

  const getCoAuthoringSession = useCallback(
    (documentId: string): CoAuthoringSessionContext | undefined => {
      return coAuthoringSessions.find((item) => item.sessionId == documentId);
    },
    [coAuthoringSessions]
  );

  const checkCoAuthoringSessionExists = useCallback(
    (document: PWItem): boolean => {
      if (!document || !isSupportedOfficeType(document)) {
        return false;
      }
      return !!getCoAuthoringSession(document.instanceId);
    },
    [getCoAuthoringSession]
  );

  const openInDesktop = useCallback(
    async (
      sessionData: Pick<CoAuthoringSession, 'fileDriveUrl' | 'sessionId'>,
      applicationType: officeApplication
    ): Promise<boolean> => {
      if (
        !pwDriveData.pwDriveMetadataEnabled ||
        !isDriveFeatureAvailable(pwDriveData.driveVersion, 'OpenO365')
      ) {
        return false;
      }

      const session = await coAuthoringClient.joinSession(
        sessionData.sessionId,
        true
      );
      if (isCoAuthoringError(session)) {
        if (session.errorId === 'LoginFailed') {
          showEnablePopupsModal(modal.open);
        }
        return false;
      }
      const openedInDesktop = await openO365FileDrive(
        session.fileDriveUrl,
        user.profile.sub,
        pwDriveData.httpDriveService,
        applicationType
      );
      if (openedInDesktop) {
        modal.showSecondary(
          <Trans i18nKey="PWDrive.OpenedOnDesktop">
            Document is ready for co-authoring and is opening in an Office 365
            desktop application.{' '}
            <Anchor
              href={sessionData.fileDriveUrl}
              target="_blank"
              rel="noopener noreferrer"
              isExternal={true}
            >
              Click here
            </Anchor>{' '}
            if you want to also open it online.
          </Trans>,
          t('PWDrive.OpenForCoauthoring'),
          'desktop'
        );
        trackFeature('OPEN_O365_SESSION_DRIVE');
      }
      return openedInDesktop;
    },
    [
      pwDriveData.pwDriveMetadataEnabled,
      pwDriveData.driveVersion,
      pwDriveData.httpDriveService,
      coAuthoringClient,
      user.profile.sub,
      modal,
      trackFeature
    ]
  );

  const openCoAuthoringSession = useCallback(
    async (
      fileName: string,
      session: Pick<CoAuthoringSession, 'fileDriveUrl' | 'sessionId'>
    ) => {
      const applicationType = getOfficeApplicationType(fileName);
      if (applicationType === undefined) {
        return;
      }

      const openedInDesktop = await openInDesktop(session, applicationType);
      if (openedInDesktop) {
        return;
      }

      modal.showSecondary(
        <Trans i18nKey="PWDrive.OpenedInWeb">
          Document is ready for co-authoring and has been opened in a new tab.
          If you do not see the tab,{' '}
          <Anchor
            href={session.fileDriveUrl}
            target="_blank"
            rel="noopener noreferrer"
            isExternal={true}
          >
            Click here
          </Anchor>{' '}
          to open again.
        </Trans>,
        t('PWDrive.OpenForCoauthoring'),
        'web'
      );
      if (modal.modalIsOpen.current) {
        window.open(session.fileDriveUrl, '_blank');
      }
    },
    [openInDesktop, modal]
  );

  const joinCoAuthoringSession = useCallback(
    async (item: PWFileType, onComplete: () => void): Promise<void> => {
      modal.showPreparingSession(item.Name);
      const session = await coAuthoringClient.joinSession(item.instanceId);

      if (isCoAuthoringError(session)) {
        handleCommonErrors(session, onComplete);
        return;
      }
      await openCoAuthoringSession(item.FileName, session);
      trackFeature('JOIN_O365_SESSION');
    },
    [
      coAuthoringClient,
      handleCommonErrors,
      openCoAuthoringSession,
      modal,
      trackFeature
    ]
  );

  const takeOverCoAuthoringSession = useCallback(
    async (item: PWFileType, onComplete: () => void): Promise<void> => {
      const toast = openToast({
        content: t('OfficeIntegration.TransferringCoAuthoringSession'),
        type: 'persisting',
        spinner: true
      });
      const result = await coAuthoringClient.takeOverSession(item.instanceId);

      if (result !== null) {
        replaceToast(toast, {
          content: t('OfficeIntegration.TakeOverSessionFailed'),
          category: 'negative'
        });
        await refreshCoAuthoringSessions();
        return;
      }
      replaceToast(toast, {
        content: t('OfficeIntegration.TookOverSessionSuccessfully'),
        category: 'positive'
      });
      await refreshCoAuthoringSessions();
      onComplete();
    },
    [coAuthoringClient, refreshCoAuthoringSessions]
  );

  const startCoAuthoringSession = useCallback(
    async (item: PWFileType, onComplete: () => void): Promise<void> => {
      modal.showPreparingSession(item.Name);
      const session = await coAuthoringClient.startSession(item.instanceId);

      if (!isCoAuthoringError(session)) {
        trackFeature('START_O365_SESSION');

        if (
          session.referencedFilesStatus === 'failedToParse' ||
          session.referencedFilesStatus === 'limitExceeded' ||
          session.referencedFilesStatus === 'fileTooLarge'
        ) {
          openToast({
            content: t('OfficeIntegration.Co-authoringWithoutReferences'),
            category: 'warning'
          });
        }

        void refreshCoAuthoringSessions();
        await openCoAuthoringSession(item.FileName, session);
        onComplete();
        return;
      }

      if (session.errorId === 'SessionExists') {
        void refreshCoAuthoringSessions();
        onComplete();
        modal.showPrimary(
          () => void joinCoAuthoringSession(item, onComplete),
          t('OfficeIntegration.EditSessionInProgress'),
          t('OfficeIntegration.EditSessionInProgressTitle'),
          t('OfficeIntegration.JoinSession'),
          t('Generic.Cancel')
        );
        return;
      }

      handleCommonErrors(session, onComplete);
    },
    [
      coAuthoringClient,
      handleCommonErrors,
      openCoAuthoringSession,
      joinCoAuthoringSession,
      modal,
      refreshCoAuthoringSessions,
      trackFeature
    ]
  );

  const freeCoAuthoringSessions = useCallback(
    async (items: PWFileType[], onComplete: () => void) => {
      const results = await Promise.all(
        items.map((item) => coAuthoringClient.freeSession(item.instanceId))
      );
      const success = results.filter((res) => !isCoAuthoringError(res)).length;
      showResultToast(
        {
          singleSuccess: t('OfficeIntegration.FreedSessionSuccessfully', {
            name: items[0].Name
          }),
          singleFail: t('OfficeIntegration.FreeSessionFailed', {
            name: items[0].Name
          }),
          multiple: t('OfficeIntegration.FreeSessionMultiple', {
            successCount: success,
            itemCount: items.length
          })
        },
        items.length,
        success
      );
      await refreshCoAuthoringSessions();
      onComplete();
      trackFeature('FREE_O365_SESSION');
    },
    [coAuthoringClient, refreshCoAuthoringSessions, trackFeature]
  );

  const endCoAuthoringSessions = useCallback(
    async (items: PWFileType[], onComplete: () => void) => {
      modal.showEndingSession(items.length > 1 ? undefined : items[0].FileName);
      const results = await Promise.all(
        items.map((item) => coAuthoringClient.endSession(item.instanceId))
      );

      if (
        results.length === 1 &&
        isCoAuthoringError(results[0]) &&
        results[0].errorId === 'FileWithReferencesTooLarge'
      ) {
        openToast({
          content: t(
            'OfficeIntegration.FileWithReferencesTooLargeForEndingSession'
          ),
          category: 'negative'
        });
      } else {
        const successCount = results.filter(
          (res) => !isCoAuthoringError(res)
        ).length;
        showResultToast(
          {
            singleSuccess: t('OfficeIntegration.EndedSessionSuccessfully', {
              name: items[0].Name
            }),
            singleFail: t('OfficeIntegration.EndSessionFailed', {
              name: items[0].Name
            }),
            multiple: t('OfficeIntegration.EndSessionMultiple', {
              successCount: successCount,
              itemCount: items.length
            })
          },
          items.length,
          successCount
        );
      }

      await refreshCoAuthoringSessions();
      onComplete();
      modal.close();
      trackFeature('END_0365_SESSION');
    },
    [modal, refreshCoAuthoringSessions, trackFeature, coAuthoringClient]
  );

  const getSessionFileStatus = useCallback(
    async (itemId: string): Promise<OneDriveFileStatus> => {
      const status = await coAuthoringClient.getSessionFileStatus(itemId);
      if (isCoAuthoringError(status)) {
        return 'Error';
      }
      switch (status.driveItemStatus) {
        case 423:
          return 'Editing';
        case 404:
          return 'NotFound';
        case 200:
          return 'Available';
        default:
          return 'Error';
      }
    },
    [coAuthoringClient]
  );

  const getCoAuthoringSessionsIds = useCallback(() => {
    const coAuthoringItems = coAuthoringSessions.filter((session) =>
      session.participants
        ?.map((user) => user.userId)
        .includes(user.profile.sub)
    );
    return coAuthoringItems?.map((data) => data.sessionId) ?? [];
  }, [coAuthoringSessions, user.profile.sub]);

  const getCoAuthoringSessionParticipants = useCallback(
    (documentId: string) => {
      return getCoAuthoringSession(documentId)?.participants ?? [];
    },
    [getCoAuthoringSession]
  );

  const showResultToast = (
    toastMessage: {
      singleSuccess: string;
      singleFail: string;
      multiple: string;
    },
    itemCount: number,
    successCount: number
  ) => {
    const getCategory = (): 'negative' | 'positive' | 'warning' => {
      switch (successCount) {
        case 0:
          return 'negative';
        case itemCount:
          return 'positive';
      }
      return 'warning';
    };

    const getMessage = (): string => {
      if (itemCount > 1) {
        return toastMessage.multiple;
      }
      return successCount === 1
        ? toastMessage.singleSuccess
        : toastMessage.singleFail;
    };

    openToast({
      content: getMessage(),
      category: getCategory()
    });
  };

  const updateServerCopy = useCallback(
    async (items: PWItem[]) => {
      modal.showUpdatingSession(items[0].Name, items.length);
      const results = await Promise.all(
        items.map((document) =>
          coAuthoringClient.updateServerCopy(document.instanceId)
        )
      );
      modal.close();

      if (
        results.length === 1 &&
        results[0] &&
        results[0].errorId === 'FileWithReferencesTooLarge'
      ) {
        openToast({
          content: t(
            'OfficeIntegration.FileWithReferencesTooLargeForUpdatingServerCopy'
          ),
          category: 'negative'
        });
      } else {
        const successCount = results.filter((res) => res === null).length;
        showResultToast(
          {
            singleSuccess: t(
              'OfficeIntegration.ServerCopyUpdatedSuccessfully',
              {
                name: items[0].Name
              }
            ),
            singleFail: t('OfficeIntegration.ServerCopyUpdateFailed', {
              name: items[0].Name
            }),
            multiple: t('OfficeIntegration.ServerCopyUpdatedMultiple', {
              successCount: successCount,
              itemCount: items.length
            })
          },
          items.length,
          successCount
        );
      }
    },
    [coAuthoringClient, modal]
  );

  const graphApiContext = useMemo(
    () => ({
      updateServerCopy,
      endCoAuthoringSessions,
      freeCoAuthoringSessions,
      refreshCoAuthoringSessions,
      checkCoAuthoringSessionExists,
      getSessionFileStatus,
      getCoAuthoringSession,
      getCoAuthoringSessionsIds,
      getCoAuthoringSessionParticipants,
      coAuthoringFeatures,
      startCoAuthoringSession,
      joinCoAuthoringSession,
      takeOverCoAuthoringSession
    }),
    [
      updateServerCopy,
      endCoAuthoringSessions,
      freeCoAuthoringSessions,
      checkCoAuthoringSessionExists,
      getSessionFileStatus,
      getCoAuthoringSession,
      getCoAuthoringSessionsIds,
      refreshCoAuthoringSessions,
      getCoAuthoringSessionParticipants,
      coAuthoringFeatures,
      startCoAuthoringSession,
      joinCoAuthoringSession,
      takeOverCoAuthoringSession
    ]
  );

  return graphApiContext;
}
