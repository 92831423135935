import React, { useMemo } from 'react';
import { SvgBlank, SvgCheckmark } from '@itwin/itwinui-icons-react';
import {
  useEnvironmentContext,
  useNavigationContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useQuickSearchAction(): MenuItemAction {
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { ftr, setFtr }
  } = useNavigationContext();

  const showQuickSearch = useMemo((): boolean => {
    if (ecPluginFeatures.allContentSearch()) {
      return false;
    }

    if (!ecPluginFeatures.fullTextSearch()) {
      return false;
    }

    return true;
  }, [ecPluginFeatures]);

  const quickSearch = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.QuickSearch'),
      icon: ftr ? (
        <SvgBlank />
      ) : (
        <SvgCheckmark data-testid="quick-search-check" />
      ),
      hidden: !showQuickSearch,
      onClick: () => setFtr(false)
    }),
    [ftr, setFtr, showQuickSearch]
  );

  return quickSearch;
}
