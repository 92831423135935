import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { folderExplorerRoot } from '../../constants';
import { useConnectionContext } from '../../context';
import { FolderExplorer } from './folderExplorer';

export function FolderExplorerPlaceholder(): JSX.Element {
  return <div id={folderExplorerRoot} data-testid={folderExplorerRoot} />;
}

export function FolderExplorerPortal(): JSX.Element | null {
  const { connection } = useConnectionContext();

  const [portal, setPortal] = useState<JSX.Element | null>(null);

  const buildPortal = useCallback((): JSX.Element | null => {
    const folderExplorerPlaceholder =
      document.getElementById(folderExplorerRoot);

    if (folderExplorerPlaceholder) {
      return createPortal(
        <FolderExplorer key={connection.Id} />,
        folderExplorerPlaceholder,
        connection.Id
      );
    }

    return null;
  }, [connection.Id]);

  // Instead of returning the portal immediately,
  // give the UI time to put the placeholder ID in place
  useEffect(() => {
    setImmediate(() => {
      const portal = buildPortal();
      setPortal(portal);
    });
  }, [buildPortal]);

  return portal;
}
