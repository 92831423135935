import type { User } from 'oidc-client';
import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { Feature } from '@bentley/pw-config';
import type { ConsumerApp, DataAccessLevel } from '../../context';
import type { SelectionState } from '../../context/appProviders';
import {
  ConnectionProvider,
  ConnectionsProvider
} from '../../context/appProviders';
import type { GetToken } from '../../services/http';
import type { Connection } from '../../services/support';
import { PluginWrapper } from '../pluginWrapper';
import { ProjectWiseConfiguredConnection } from './projectWiseConfiguredConnection';

export type ProjectWiseConnectionProps = {
  buddiRegionCode: string;
  connectionId: string;
  consumerApp?: ConsumerApp;
  contextId?: string;
  dataAccessLevel?: DataAccessLevel;
  featureOverrides?: Feature[];
  gprId: number;
  pdfMarkupForms?: { selectedCategory: string; selectedForm: string }[];
  readOnly?: boolean;
  selectionState?: SelectionState | string;
  user: User;
  getTeamsTabLink?: () => Promise<string>;
  getOidcToken: GetToken;
  getSamlToken: GetToken;
  navigate?: (to: string) => void | Promise<void>;
  openAuthenticationPopup?: (url: string) => Promise<boolean>;
  openDesignReview?: (connection: Connection, items: PWItem[]) => void;
  setFolderAccess?: (
    connection: Connection,
    item: PWItem,
    refresh: () => void
  ) => void;
};

export function ProjectWiseConnection(
  props: ProjectWiseConnectionProps
): JSX.Element {
  return (
    <PluginWrapper>
      <ConnectionsProvider value={{ ...props, connectionList: 'single' }}>
        <ConnectionProvider value={props}>
          <ProjectWiseConfiguredConnection {...props} />
        </ConnectionProvider>
      </ConnectionsProvider>
    </PluginWrapper>
  );
}
