import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsParentType } from '@bentley/pw-api';
import { SvgRename } from '@itwin/itwinui-icons-react';
import { RenameModal, allowRename } from '../../../../actions/rename';
import {
  useAppViewContext,
  useFolderExplorerContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { useRootFolderSelected } from '../useRootFolderSelected';

export function useRenameItemAction(items: PWItem[]): MenuItemAction {
  const { readOnly } = usePluginContext();
  const httpService = useHttpService();
  const { reloadExplorerFolders } = useFolderExplorerContext();

  const {
    breadcrumbManager: { updateBreadcrumb },
    navigationManager: { currentParent, navigateTo },
    primaryModal
  } = useNavigationContext();

  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const {
    dataManager: { refreshCurrentFolder, items: itemsInFolder }
  } = useTableItemContext();

  const rootFolderSelected = useRootFolderSelected(items);

  const item = items[0];

  const onRenameSuccess = useCallback(
    async (updatedItem: PWItem): Promise<void> => {
      await httpService.cache?.clearEntriesMatching(updatedItem.instanceId);
      await httpService.cache?.clearEntriesMatching(updatedItem.ParentGuid);

      if (
        item.instanceId == currentParent.instanceId &&
        itemIsParentType(updatedItem)
      ) {
        // Todo: could probably just use `navigateTo(updatedItem, true)` and skip `updateBreadcrumb`
        updateBreadcrumb(updatedItem);
        void navigateTo(updatedItem);
      }

      await httpService.cache?.clearEntriesMatching(item.ParentGuid);

      reloadExplorerFolders([item.ParentGuid ?? '']);
      refreshCurrentFolder();
    },
    [
      currentParent,
      item,
      httpService.cache,
      navigateTo,
      refreshCurrentFolder,
      reloadExplorerFolders,
      updateBreadcrumb
    ]
  );

  const showRename = useMemo(() => {
    if (readOnly) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    return true;
  }, [currentAppView.type, readOnly]);

  const renameItem = useMemo(
    (): MenuItemAction => ({
      title: t('Generic.Rename'),
      icon: <SvgRename />,
      onClick: () =>
        primaryModal.open(
          <RenameModal
            item={item}
            onSuccess={(item) => void onRenameSuccess(item)}
            itemsInFolder={itemsInFolder}
          />
        ),
      disabled:
        !showRename ||
        rootFolderSelected ||
        !allowRename(items, allItemsExistInConnection),
      hidden: !showRename
    }),
    [
      showRename,
      items,
      allItemsExistInConnection,
      rootFolderSelected,
      primaryModal,
      item,
      onRenameSuccess,
      itemsInFolder
    ]
  );

  return renameItem;
}
