import _ from 'lodash';
import { useCallback, useState } from 'react';

/**
 * Similar to useState, but will not trigger an update if the new value is equal
 * equivalent to the existing value. Uses lodash `isEqual`. Useful for preventing
 * re-renders for equivalent items (e.g., replacing an empty array with another empty array).
 */
export function useEquivalentState<T>(
  initialState: T
): [T, (value: T) => void, (value: T) => void] {
  const [value, _setValue] = useState<T>(initialState);

  const setValue = useCallback(
    (newValue: T): void => {
      if (!_.isEqual(newValue, value)) {
        _setValue(newValue);
      }
    },
    [value]
  );

  return [value, setValue, _setValue];
}
