import './tableLoadingWrapper.css';

import React, { useEffect } from 'react';
import { itemIsFlatSet } from '@bentley/pw-api';
import { SvgError } from '@itwin/itwinui-illustrations-react';
import { NonIdealState } from '@itwin/itwinui-react';
import {
  useAppViewContext,
  useNavigationContext,
  usePropertyContext,
  useTableItemContext,
  useTelemetry
} from '../../../context';
import { t } from '../../../services/translation';
import { LoadingPage } from '../../loadingPage';

type TableLoadingWrapperProps = {
  children: JSX.Element;
};

export function TableLoadingWrapper({
  children
}: TableLoadingWrapperProps): JSX.Element {
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();

  const { startTelemetry, endTelemetry } = useTelemetry();

  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const {
    viewManager: { currentView }
  } = usePropertyContext();
  const { dataManager, tableLoading } = useTableItemContext();

  useEffect(() => {
    if (tableLoading) {
      startTelemetry('FolderLoad');
    } else {
      endTelemetry('FolderLoad', {
        numItems: dataManager.items.length,
        folderId: currentParent.instanceId,
        folderType: itemIsFlatSet(currentParent)
          ? 'FlatSet'
          : currentAppView.type
      });
      endTelemetry('loadingConnection');
    }
  }, [tableLoading, startTelemetry, endTelemetry]);

  if (tableLoading) {
    return (
      <div className="table-loading pw-table" data-testid="table-loading">
        <LoadingPage
          loadingSubText={
            currentAppView.type == 'BasicSearch' ||
            currentAppView.type == 'AdvancedSearch' ||
            currentAppView.type == 'SavedSearch' ||
            currentAppView.type == 'OutToMe'
              ? `${t('Grid.Searching')}`
              : `${t('Grid.Opening', { name: currentParent?.Name ?? '' })}`
          }
        />
      </div>
    );
  }

  if (!currentView.columns?.length) {
    return (
      <div className="pw-table">
        <NonIdealState
          svg={<SvgError />}
          heading={t('Generic.Error')}
          description={t('Grid.GridColumnConfigurationBad')}
        />
      </div>
    );
  }

  return children;
}
