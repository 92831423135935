import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { getProject, itemIsProject, parseRelatedParent } from '@bentley/pw-api';
import type { Connection } from '../support';
import { getItemWorkArea } from './itemWorkArea';

export async function getWorkArea(
  item: PWItem,
  connection: Connection,
  httpService: HttpService,
  cachedParents?: (item: PWItem) => PWParentType | undefined,
  httpOptions?: RequestOptions
): Promise<PWProject | undefined> {
  if (getItemWorkArea(item)) {
    return getItemWorkArea(item);
  }

  if (httpOptions?.abortController?.signal.aborted) {
    return;
  }

  let parent = parseRelatedParent(item);
  if (!parent) {
    parent = await getParent(
      item,
      connection.Name,
      httpService,
      cachedParents,
      httpOptions
    );
  }

  if (parent.TypeString != 'Folder') {
    return parent;
  }

  return getWorkArea(
    parent,
    connection,
    httpService,
    cachedParents,
    httpOptions
  );
}

async function getParent(
  item: PWItem,
  connectionName: string,
  httpService: HttpService,
  cachedParents?: (item: PWItem) => PWParentType | undefined,
  requestOptions?: RequestOptions
): Promise<PWProject> {
  const parent = parseRelatedParent(item);

  if (parent && itemIsProject(parent)) {
    return parent;
  }

  if (!item.ParentGuid) {
    return {
      Name: connectionName,
      className: 'Project',
      TypeString: 'Project'
    } as PWProject;
  }

  const cachedParent = cachedParents?.(item);

  if (cachedParent && itemIsProject(cachedParent)) {
    return cachedParent;
  }

  return getProject({
    instanceId: item.ParentGuid,
    httpService,
    requestOptions
  });
}
