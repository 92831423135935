import _ from 'lodash';

export function responseAborted(response: Response): boolean {
  if (response.ok) {
    return false;
  }

  if (!_.isEmpty(response) || response.ok !== undefined) {
    return false;
  }

  return true;
}

export async function parseResponseError(
  response: Response
): Promise<string | undefined> {
  const jsonError = await parseJSONError(response);
  if (jsonError) {
    return jsonError;
  }

  const textError = await parseTextError(response);
  if (textError) {
    return textError;
  }

  return;
}

async function parseTextError(response: Response): Promise<string | undefined> {
  try {
    const text = await response.clone().text();
    return text || undefined;
  } catch {
    return;
  }
}

async function parseJSONError(response: Response): Promise<string | undefined> {
  try {
    const data = (await response.clone().json()) as Record<string, string>;
    if (data.errorMessage) {
      return data.errorMessage;
    }
  } catch {
    return;
  }
  return;
}
