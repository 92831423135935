import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useNavigationContext } from '../../../context';
import { useBasicSearch } from '../../useBasicSearch';
import type { AppViewSetting, FetchItemsArgs } from './appViewSetting';

export function useBasicSearchView(): AppViewSetting {
  const { searchState } = useNavigationContext();

  const basicSearch = useBasicSearch();

  const fetchItems = useCallback(
    ({ requestOptions: httpOptions }: FetchItemsArgs): Promise<PWItem[]> => {
      return basicSearch(httpOptions);
    },
    [basicSearch]
  );

  const clearView = useCallback((): void => {
    searchState.setBasicQuery('');
  }, [searchState]);

  const basicSearchViewSetting = useMemo(
    (): AppViewSetting => ({
      type: 'BasicSearch',
      fetchItems,
      clearView
    }),
    [clearView, fetchItems]
  );

  return basicSearchViewSetting;
}
