import React, { useCallback, useMemo } from 'react';
import { SvgClose } from '@itwin/itwinui-icons-react';
import { useAppViewContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCloseSearchAction(): MenuItemAction {
  const { appViewManager } = useAppViewContext();

  const showCloseButton = useMemo((): boolean => {
    if (appViewManager.currentAppView.type == 'BasicSearch') {
      return true;
    }
    if (appViewManager.currentAppView.type == 'AdvancedSearch') {
      return true;
    }
    if (appViewManager.currentAppView.type == 'SavedSearch') {
      return true;
    }
    return false;
  }, [appViewManager.currentAppView.type]);

  const onCloseSearchClick = useCallback((): void => {
    appViewManager.setAppView('FolderNavigation');
  }, [appViewManager]);

  const closeSearch = useMemo(
    (): MenuItemAction => ({
      title: t('Grid.CloseSearch'),
      icon: <SvgClose />,
      hidden: !showCloseButton,
      onClick: onCloseSearchClick
    }),
    [onCloseSearchClick, showCloseButton]
  );

  return closeSearch;
}
