import type { PWDataItem, PWItem } from '@bentley/pw-api';
import {
  itemFileExtension,
  itemIsDataItem,
  itemIsFileType
} from '@bentley/pw-api';
import type { CoAuthoringFeatureFlags } from '../../components/coAuthoring';
import type { CoAuthoringSettings } from '../../hooks/useCoAuthoringSettings';
import type { SelectedDocumentsPermissions } from '../../hooks/useSelectedDocumentsPermissions';
import { isCheckedOutToSameUser } from '../sync/requirements';
import type { CoAuthoringSessionContext } from './useCoAuthoringClient';

const supportedFileExtensions = [
  'DOCX',
  'DOCM',
  'XLSX',
  'XLSM',
  'XLSB',
  'PPTX',
  'PPTM'
];

export function showOpenIn365(
  items: PWItem[],
  readOnly: boolean,
  sessionExists: boolean,
  itemExistsInConnection: (item: PWItem) => boolean | undefined,
  coAuthoringSettings: CoAuthoringSettings
): boolean {
  if (!coAuthoringSettings.isCoAuthoringEnabled) {
    return false;
  }

  if (items.length != 1) {
    return false;
  }

  const item = items[0] as PWDataItem;
  if (item.IsFinal) {
    return false;
  }

  if (!isSupportedOfficeType(item)) {
    return false;
  }

  if ((item.IsLocked || item.IsReadOnly) && !sessionExists) {
    return false;
  }

  if (readOnly) {
    return !!coAuthoringSettings.sharePointSiteId;
  }

  if (!itemExistsInConnection(item)) {
    return false;
  }

  return true;
}

export function showTakeOverCoAuthoringSession(
  items: PWItem[],
  coAuthoringSettings: CoAuthoringSettings | undefined,
  showCoauthoringUpdates: boolean,
  userFilePermissions: SelectedDocumentsPermissions,
  pwUserId: string | undefined,
  session: CoAuthoringSessionContext | undefined
): boolean {
  if (items.length != 1) {
    return false;
  }

  const item = items[0];

  if (!itemIsDataItem(item)) {
    return false;
  }

  if (!coAuthoringSettings?.sharePointSiteId || !session?.sharePointSiteId) {
    return false;
  }

  if (!isSupportedOfficeType(item)) {
    return false;
  }

  if (!session) {
    return false;
  }

  if (pwUserId !== undefined && isCheckedOutToSameUser(item, pwUserId)) {
    return false;
  }

  if (
    !userFilePermissions.permissionsSummary?.Write ||
    !userFilePermissions.permissionsSummary?.FileWrite ||
    !userFilePermissions.permissionsSummary?.Free
  ) {
    return false;
  }

  if (!showCoauthoringUpdates) {
    return false;
  }

  return true;
}

export function showUpdateServerCopy(
  items: PWItem[],
  checkCoAuthoringSessionExists: (document: PWItem) => boolean,
  coAuthoringFeatures: CoAuthoringFeatureFlags,
  userName: string
): boolean {
  if (items.length < 1 || !coAuthoringFeatures.updateServerCopy) {
    return false;
  }

  return items.every(
    (item) =>
      checkCoAuthoringSessionExists(item) &&
      (item as PWDataItem).OutTo === userName
  );
}

export function isSupportedOfficeType(item: PWItem): boolean {
  if (!itemIsFileType(item)) {
    return false;
  }

  const extension = itemFileExtension(item).toUpperCase();
  return supportedFileExtensions.includes(extension);
}
