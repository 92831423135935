import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyReplaceInProgress(): ToastHandle {
  const content = t('Replace.Notification.ReplacingFile');
  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyReplaceMultipleInProgress(number: number): ToastHandle {
  const content =
    number == 1
      ? t('Replace.Notification.ReplacingFile')
      : t('Replace.Notification.ReplacingFiles', {
          count: number
        });
  return openToast({ content, type: 'persisting', spinner: true });
}

export function sendReplaceCompleteNotification(
  response: Response,
  newName: string,
  originalName: string,
  toastHandle: ToastHandle
): void {
  if (response.status === 200) {
    const content = t('Upload.Notification.FileSuccess', {
      name: newName
    });
    replaceToast(toastHandle, { content, category: 'positive' });
  } else if (response.status === 409) {
    const content = t('Replace.Notification.ErrorFileExists', {
      newName,
      originalName
    });
    replaceToast(toastHandle, { content, category: 'negative' });
  } else {
    const content = t('Replace.Notification.ErrorUploading', {
      name: newName,
      errorMsg: getReplaceFileErrorText(response as ParsableResponse)
    });
    replaceToast(toastHandle, { content, category: 'negative' });
  }
}

// Todo: we never pass in anything other than a Response, so we need to consider if/how we are accessing the data properties
export type ParsableResponse = Response &
  Record<string, string & Record<string, string>>;

function getReplaceFileErrorText(response: ParsableResponse): string {
  if (
    response.status === 403 ||
    (response.status === 404 &&
      response.data &&
      response.data.errorId &&
      response.data.errorId == 'InstanceNotFound')
  ) {
    return t('Replace.Error.AccessDenied');
  } else if (response.status === 412) {
    // only try to do reuploads if the response is a 412 etag lost issue because that should likely be fine to reupload other errors codes are internal server errors that might not work to reupload
    return t('Replace.Error.BadRequest');
  } else if (response.status == 413) {
    // File too large; remove from in progress
    return t('Replace.Error.TooLarge');
  } else if (response.status === 400) {
    return t('Replace.Error.BadRequest');
  } else if (response.status === 499) {
    return t('Replace.Error.MimeTypeError');
  } else if (response.status === 500 && response.errorMessage) {
    return response.errorMessage;
  } else if (response.status !== -1) {
    return t('Replace.Error.ServerOutOfSpace');
    // TODO: set server out of space variable to true and disable uploads
  } else if (response.status === -1) {
    // user canceled upload
    return t('Replace.Error.UserCancelled');
  } else {
    return '';
  }
}

export function notifyErrorUpdatingProperties(docName: string): void {
  openToast({
    content: t('Replace.Notification.FailedUpdating', {
      name: docName
    }),
    category: 'negative'
  });
}

export function notifyFailedPermissionCheck(errorMessage: string): void {
  openToast({
    content: t('Rename.ErrorWithEditPermission', {
      errorMsg: errorMessage
    }),
    category: 'negative'
  });
}
