import type {
  PWItem,
  WSGProperties,
  WSGRelationshipInstance
} from '@bentley/pw-api';
import type { DriveLockedInfo } from './pwDrive.utils';

export function setItemDriveLock(
  item: PWItem,
  driveLockData: DriveLockedInfo
): void {
  removeItemDriveLock(item);

  if (!item.relationshipInstances?.length) {
    item.relationshipInstances = [];
  }

  const relationshipInstance = {
    className: 'ItemDriveLock',
    direction: 'forward',
    relatedInstance: {
      className: 'DriveLock',
      properties: driveLockData as WSGProperties
    }
  } as WSGRelationshipInstance;

  item.relationshipInstances.push(relationshipInstance);
}

function removeItemDriveLock(item: PWItem): void {
  const relationshipInstanceIndex = item.relationshipInstances?.findIndex(
    ({ className }) => className == 'ItemDriveLock'
  );

  if (
    relationshipInstanceIndex !== undefined &&
    relationshipInstanceIndex > -1
  ) {
    item.relationshipInstances?.splice(relationshipInstanceIndex, 1);
  }
}

export function getItemDriveLock(item: PWItem): DriveLockedInfo | undefined {
  const relationshipInstance = item.relationshipInstances?.find(
    ({ className }) => className == 'ItemDriveLock'
  );

  if (!relationshipInstance?.relatedInstance?.properties) {
    return undefined;
  }

  return relationshipInstance.relatedInstance.properties as DriveLockedInfo;
}
