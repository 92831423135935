import './form.css';
import './formRenderer.css';

import type { UIEvent } from 'react';
import React, { useLayoutEffect, useRef } from 'react';
import type { IFormContainer } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IFormContainer';
import type { FormType } from '../../components/documentCreation';

type FormContainerProps = {
  formJQuery?: IFormContainer;
  formType: FormType;
};

export function FormContainer({
  formJQuery,
  formType
}: FormContainerProps): JSX.Element {
  const scrollTop = useRef<number>(0);
  const formRef = useRef<HTMLDivElement>(null);

  const formContents = (
    <div
      onScrollCapture={(e: UIEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;

        if (target.classList.contains('renderer-form')) {
          scrollTop.current = target.scrollTop;
        }
      }}
      data-testid="formRendererContainer"
      data-formtype={formType}
      id="form"
      ref={formRef}
    />
  );

  useLayoutEffect(() => {
    if (formJQuery && formRef.current) {
      if (formRef.current.firstChild) {
        formRef.current.replaceChild(
          formJQuery[0],
          formRef.current.firstChild as Node
        );
      } else {
        formRef.current.appendChild(formJQuery[0]);
      }

      const formRendererContainer =
        document.getElementsByClassName('renderer-form')?.[0];
      if (formRendererContainer) {
        formRendererContainer.scrollTop = scrollTop.current;
      }
    }
  }, [formJQuery]);

  return formContents;
}
