import React from 'react';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { DriveDecisionModal } from './driveDecisionModal';

export function driveManualCheckoutRequiredModal(
  openModal: OpenModal,
  checkoutOptionFunction: () => Promise<void>,
  openReadOnlyFunction: () => void
): void {
  const modal = (
    <DriveDecisionModal
      modalTitle={t('PWDrive.ManualCheckoutRequired')}
      modalText={t('PWDrive.CheckoutRequired')}
      optionOneTitle={t('PWDrive.CheckoutForEditing')}
      optionTwoTitle={t('PWDrive.OpenReadOnly')}
      optionOneFunction={checkoutOptionFunction}
      optionTwoFunction={openReadOnlyFunction}
    />
  );
  openModal(modal);
}

export function driveCoAuthoringSessionRunningModal(
  openModal: OpenModal,
  openForCoauthoringFunction: () => Promise<void>,
  openInDriveFunction: () => void
): void {
  const modal = (
    <DriveDecisionModal
      modalTitle={t('PWDrive.DocumentOpenForCoauthoring')}
      modalText={t('PWDrive.OpenForCoauthoringChoices')}
      optionOneTitle={t('PWDrive.OpenForCoauthoring')}
      optionTwoTitle={t('PWDrive.OpenReadOnlyCopy')}
      optionOneFunction={openForCoauthoringFunction}
      optionTwoFunction={openInDriveFunction}
    />
  );
  openModal(modal);
}

export function driveSyncRequiredModal(
  closeModal: CloseModal,
  openModal: OpenModal,
  driveSyncFunction: () => Promise<void>
): void {
  const modal = (
    <DriveDecisionModal
      modalTitle={t('PWDrive.DriveSyncRequired')}
      modalText={t('PWDrive.DriveSyncRequiredChoices')}
      optionOneTitle={t('Generic.Yes')}
      optionTwoTitle={t('Generic.No')}
      optionOneFunction={driveSyncFunction}
      optionTwoFunction={closeModal}
    />
  );
  openModal(modal);
}
