import type { KeyboardEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useAppViewContext, useNavigationContext } from '../../context';

type SearchInputEvents = {
  clearSearch: () => void;
  onKeyDown: (e: KeyboardEvent) => void;
  onSearch: () => void;
};

type UseSearchInputEventsProps = {
  inputValue: string;
  setInputValue: (value: string) => void;
};

export function useSearchInputEvents({
  inputValue,
  setInputValue
}: UseSearchInputEventsProps): SearchInputEvents {
  const { searchState } = useNavigationContext();
  const {
    appViewManager: { currentAppView, setAppView }
  } = useAppViewContext();

  const onSearch = useCallback((): void => {
    if (inputValue) {
      searchState.setBasicQuery(inputValue);
      setAppView('BasicSearch');
    }
  }, [inputValue, searchState, setAppView]);

  const clearSearch = useCallback((): void => {
    setAppView('FolderNavigation');
  }, [setAppView]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      switch (e.keyCode) {
        // enter
        case 13: {
          onSearch();
          break;
        }

        // escape
        case 27: {
          setInputValue('');
          if (currentAppView.type == 'BasicSearch') {
            clearSearch();
          }
        }
      }
    },
    [currentAppView.type, onSearch, clearSearch, setInputValue]
  );

  const searchInputEvents = useMemo(
    (): SearchInputEvents => ({ onSearch, onKeyDown, clearSearch }),
    [clearSearch, onKeyDown, onSearch]
  );

  return searchInputEvents;
}
