import React from 'react';
import { Trans } from 'react-i18next';
import { Alert, Anchor, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext, usePluginContext } from '../../context';
import { formatFileSize } from '../../services/stringFormatter';
import { t } from '../../services/translation';

type ConfirmLargeDownloadModalProps = {
  downloadSize: number;
  onClose: (proceed: boolean) => void;
};

export function ConfirmLargeDownloadModal({
  downloadSize,
  onClose
}: ConfirmLargeDownloadModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();
  const { pwDriveData, readOnly } = usePluginContext();

  function closeButtonClick(): void {
    onClose(true);
    primaryModal.close();
  }

  function cancelButtonClick(): void {
    onClose(false);
    primaryModal.close();
  }

  return (
    <PWModal
      title={t('Download.LargeDownloadQuestion')}
      primaryButton={{
        title: t('Download.Download'),
        onClick: closeButtonClick
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: cancelButtonClick
      }}
      onClose={cancelButtonClick}
      dialogProps={{ 'data-testid': 'ConfirmLargeDownloadModal' }}
    >
      <Alert type="warning">{`${t('Download.LargeDownloadWarning', {
        size: formatFileSize(downloadSize)
      })}`}</Alert>
      {!readOnly && (
        <Alert
          type="informational"
          data-testId="largeDownloadPWDriveRecommendation"
        >
          <Trans i18nKey="Download.LargeDownloadPWDriveRecommendation">
            For better download performance, try using ProjectWise Drive.{' '}
            <Anchor
              href={pwDriveData.driveDownloadUrl}
              target="_blank"
              isExternal={true}
            >
              Download ProjectWise Drive
            </Anchor>
          </Trans>
        </Alert>
      )}
      <Text>{t('Download.ContinueWithDownload')}</Text>
    </PWModal>
  );
}
