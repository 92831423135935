import React from 'react';
import { DropdownMenu, IconButton } from '@itwin/itwinui-react';
import type { MenuItemAction } from '../MenuItemAction';
import { menuItemActionToMenuItem } from './menuItemActionToMenuItem';

type ToolbarButtonProps = {
  childItems: MenuItemAction[];
  icon?: JSX.Element;
  iconButtonProps: MenuItemAction;
};

export function ToolbarButtonWithChildren({
  childItems,
  icon,
  iconButtonProps
}: ToolbarButtonProps): JSX.Element | null {
  const { itemTooltip, title, className } = iconButtonProps;

  return (
    <DropdownMenu
      menuItems={(close) =>
        childItems.map((action) => menuItemActionToMenuItem(action, close))
      }
    >
      <IconButton
        label={itemTooltip || title}
        className={`${className ?? ''} button-with-children`}
        data-testid={iconButtonProps['data-testid']}
      >
        {icon}
      </IconButton>
    </DropdownMenu>
  );
}
