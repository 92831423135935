import type { HttpService, WSGInstance, WSGProperties } from '@bentley/pw-api';
import { parseResponseChangedInstance } from '@bentley/pw-api';
import { getDocCodeProperties } from '../../docCode';
import { mergePropertiesIntoInstance } from '../../properties';
import { getDCWHttpService } from '../../state';

export async function generateTriggeredDocCodeProperties(
  environmentInstance: WSGInstance
): Promise<WSGProperties> {
  const definedDocCodeInstance = getDefinedDocCodeInstance(environmentInstance);
  const updatedTriggeredProperties = await updateTriggeredPropertiesAction(
    definedDocCodeInstance,
    getDCWHttpService()
  );

  const instance = mergePropertiesIntoInstance(
    environmentInstance,
    updatedTriggeredProperties
  );
  const updatedDocCodeProperties = await getDocCodeProperties(instance);
  return updatedDocCodeProperties;
}

export async function generateTriggeredProperties(
  environmentInstance: WSGInstance,
  propertyName: string,
  httpService: HttpService
): Promise<WSGProperties> {
  const triggeredPropertyInstance = getTriggeredPropertyInstance(
    environmentInstance,
    propertyName
  );
  try {
    const updatedProperties = await updateTriggeredPropertiesAction(
      triggeredPropertyInstance,
      httpService
    );
    return updatedProperties;
  } catch {
    // Do not show toast on triggered properties failure
  }
  return environmentInstance.properties ?? {};
}

function getDefinedDocCodeInstance(
  environmentInstance: WSGInstance
): WSGInstance {
  const actionProperties = { TriggeringAction: 'DefinedDocCode' };
  return getUpdateTriggeredPropertiesActionInstance(
    actionProperties,
    environmentInstance.instanceId,
    environmentInstance.className,
    environmentInstance.properties
  );
}

function getTriggeredPropertyInstance(
  environmentInstance: WSGInstance,
  propertyName: string
): WSGInstance {
  const actionProperties = { TriggeringPropertyName: propertyName };

  return getUpdateTriggeredPropertiesActionInstance(
    actionProperties,
    environmentInstance.instanceId,
    environmentInstance.className,
    environmentInstance.properties
  );
}

function getUpdateTriggeredPropertiesActionInstance(
  actionProperties: WSGProperties,
  environmentInstanceId: string,
  environmentInstanceClassName: string,
  environmentInstanceProperties: WSGProperties | undefined
): WSGInstance {
  return {
    schemaName: 'PW_WSG',
    className: 'UpdateTriggeredPropertiesAction',
    instanceId: '',
    properties: actionProperties,
    relationshipInstances: [
      {
        schemaName: 'PW_WSG',
        className: 'UpdateTriggeredPropertiesActionEnvironment',
        direction: 'backward',
        relatedInstance: {
          schemaName: 'PW_WSG_Dynamic',
          className: environmentInstanceClassName,
          instanceId: environmentInstanceId,
          properties: environmentInstanceProperties
        }
      }
    ]
  };
}

async function updateTriggeredPropertiesAction(
  instance: WSGInstance,
  httpService: HttpService
): Promise<WSGProperties> {
  const body = JSON.stringify({ instance });
  const response = await httpService.post(
    'PW_WSG/UpdateTriggeredPropertiesAction',
    body
  );
  throwIfBadResponse(response);
  const changedInstance = await parseResponseChangedInstance(response);

  const environmentInstances = changedInstance.relationshipInstances?.map(
    (relationshipInstance) => relationshipInstance.relatedInstance
  );

  const updatedInstance = environmentInstances?.[0];
  const updatedProperties = updatedInstance?.properties;
  throwIfNoProperties(updatedProperties);
  return updatedProperties ?? {};
}

function throwIfBadResponse(response: Response): void {
  if (!response.ok) {
    throw new Error(
      `Triggered properties failed with error: ${response.status}`
    );
  }
}

function throwIfNoProperties(properties: WSGProperties | undefined): void {
  if (!properties || !Object.keys(properties).length) {
    throw new Error('Unable to fetch triggered properties');
  }
}
