import type JSZip from 'jszip';
import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem,
  PWItemClass
} from '@bentley/pw-api';
import {
  downloadBlob,
  filterFileTypes,
  itemIsLogicalSet,
  itemIsParentType,
  parseResponseInstances
} from '@bentley/pw-api';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import type { FeatureName } from '../../hooks/useTrackFeature';
import { responseAborted } from '../../services/http';
import { t } from '../../services/translation';

export type DownloadResult = { blob?: Blob; zip?: JSZip };
const MB = 1024 * 1024;
const GB = 1024 * MB;
export const maxDownloadSize = 1 * GB;

export function isMultiItemDownload(
  items: PWItem[],
  downloadReferencedFiles: boolean
): boolean {
  if (items.length > 1) {
    return true;
  }
  const item = items[0];

  if (itemIsParentType(item)) {
    return true;
  }

  if (itemIsLogicalSet(item) && downloadReferencedFiles) {
    return true;
  }

  return false;
}

export function performDownloadInBrowser(blob: Blob, fileName: string): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  if ((window.navigator as any).msSaveOrOpenBlob) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    (window.navigator as any).msSaveOrOpenBlob(
      blob,
      fileName.replace(/\u200E/g, '')
    );
  } else {
    const url = window.URL.createObjectURL(blob);
    const downloadElement = document.createElement('a');
    downloadElement.style.cssText = 'display: none';
    downloadElement.href = url;
    (downloadElement as HTMLAnchorElement & { 'data-testid'?: string })[
      'data-testid'
    ] = 'DownloadLink';
    if (fileName !== '' && fileName != null) {
      downloadElement.download = fileName;
    }
    document.body.append(downloadElement);
    downloadElement.click();
    setTimeout(() => {
      try {
        document.body.removeChild(downloadElement);
      } catch (e) {
        // Continue if can't remove temp download anchor
      }
      window.URL.revokeObjectURL(url);
    }, 2000);
  }
}

/**
 * Downloads the file attached to a document as a blob
 * @param {PWFileType} item Document or Logical Set containing file to download
 * @param {HttpService} httpService Configured http service
 * @param {ProgressManager} ProgressManager Tracker object used to keep track of download progress
 * @param {string} uniqueId Generated id attached to file being downloaded
 * @param {AbortController} abortController Controller used to send abort signal to fetch call
 * @returns {Promise<Blob>} Async call to get blob with file contents
 */
export async function downloadBlobWorkflow(
  item: PWFileType,
  httpService: HttpService,
  jobId: string,
  uniqueId: string,
  abortController: AbortController,
  progressManager?: ProgressManager,
  attributeExchange?: boolean,
  retriesLeft = 5
): Promise<Blob> {
  try {
    const responseCallback = progressManager
      ? buildResponseCallback(progressManager, jobId, uniqueId)
      : undefined;

    const blob = await downloadBlob({
      document: item,
      downloadOptions: { attributeExchange },
      httpService,
      requestOptions: { abortController, uncached: true },
      responseCallback
    });

    return blob;
  } catch (err) {
    return handleDownloadBlobError(
      err,
      retriesLeft,
      item,
      httpService,
      jobId,
      uniqueId,
      abortController,
      progressManager,
      attributeExchange
    );
  }
}

function buildResponseCallback(
  progressManager: ProgressManager,
  jobId: string,
  uniqueId: string
): (amountTransferred: number) => void {
  function callback(amountTransferred: number): void {
    progressManager.updateAmountDownloaded(jobId, uniqueId, amountTransferred);
  }
  return callback;
}

function handleDownloadBlobError(
  err: unknown,
  retriesLeft: number,
  item: PWFileType,
  httpService: HttpService,
  jobId: string,
  uniqueId: string,
  abortController: AbortController,
  progressManager?: ProgressManager,
  attributeExchange?: boolean
): Promise<Blob> {
  const fetchError = err as Record<string, string>;
  const response = err as Response;

  console.error('Error downloading file', {
    Item: item.Name,
    retries: retriesLeft,
    message: fetchError?.message
  });

  if (fetchError?.message == 'Failed to fetch' && retriesLeft) {
    return downloadBlobWorkflow(
      item,
      httpService,
      jobId,
      uniqueId,
      abortController,
      progressManager,
      attributeExchange,
      retriesLeft - 1
    );
  }

  progressManager?.updateProgressStatus(jobId, uniqueId, 'error', response);

  if (response?.ok) {
    throw err;
  }

  if (responseAborted(response)) {
    progressManager?.clearAbortedActivityData(jobId, uniqueId);
    throw err;
  }

  progressManager?.updateProgressStatus(jobId, uniqueId, 'error', response);
  if (response.status === 403) {
    throw new Error(t('Downloads.Notifications.InsufficientPermission'));
  }

  const errorMessage = response.statusText || t('Generic.FileNotFound');
  throw new Error(errorMessage);
}

export function trackDownloadFeatures(
  selectedFiles: PWItem[],
  trackFeature: (featureName: FeatureName) => void,
  zippedItem?: JSZip
): void {
  if (selectedFiles.length === 1) {
    trackSingleItemDownload(
      selectedFiles[0].className,
      trackFeature,
      zippedItem
    );
  } else {
    trackFeature('DOWNLOAD_MULTIPLE');
  }
}

function trackSingleItemDownload(
  className: PWItemClass,
  trackFeature: (featureName: FeatureName) => void,
  zipFile?: JSZip
): void {
  switch (className) {
    case 'Document':
      trackFeature('DOWNLOAD_SINGLE_FILE');
      break;
    case 'Project':
      trackFeature('DOWNLOAD_SINGLE_FOLDER');
      break;
    case 'FlatSet':
      trackFeature('DOWNLOAD_FLAT_SET');
      break;
    case 'LogicalSet':
      trackFeature('DOWNLOAD_LOGICAL_SET');
      break;
  }
}

export async function verifyDownloadItemsData(
  items: PWItem[],
  httpService: HttpService
): Promise<PWItem[]> {
  const files = filterFileTypes(items);
  if (!files.length) {
    return items;
  }

  const instanceIds = files.map((file) => file.instanceId);
  const url = 'PW_WSG/Document/$query';
  const filter = `$filter=$id+in+['${instanceIds.join("','")}']`;
  const select = '$select=FileName,FileSize';

  try {
    const response = await httpService.post(url, `${filter}&${select}`, {
      uncached: true
    });
    const updatedFiles = await parseResponseInstances<PWDataItem>(response);
    items = updateItemsWithNewInfo(items, updatedFiles);
  } catch {
    return items;
  }
  return items;
}

function updateItemsWithNewInfo(
  oldItems: PWItem[],
  newItems: PWDataItem[]
): PWItem[] {
  newItems.forEach((item) => {
    const oldItemIndex = oldItems.findIndex(
      (oi) => oi.instanceId == item.instanceId
    );
    if (oldItemIndex == -1) {
      return;
    }
    (oldItems[oldItemIndex] as PWDataItem).FileName = item.FileName;
    (oldItems[oldItemIndex] as PWDataItem).FileSize = item.FileSize;
  });
  return oldItems;
}
