import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyMultiSaveFailure(responses: Response[]): void {
  let content = t('FolderSettings.Failed');
  if (responses.length > 1) {
    content = t('FolderSettings.FailedMulti', {
      count: responses.length
    });
  }

  openToast({ content, category: 'negative' });
}
