import './applyToRemaining.css';

import type { ReactNode } from 'react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ApplyAllCheck } from './applyAllCheck';
import { multiUploadState } from './state';

type ApplyToRemainingProps = {
  children?: ReactNode;
};

export function ApplyToRemaining({
  children
}: ApplyToRemainingProps): JSX.Element {
  const multiUpload = useRecoilValue(multiUploadState);

  if (!multiUpload) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="apply-all-check">
        <ApplyAllCheck data-testid="apply-all" />
      </div>
      {children}
      <div className="apply-all-check-hidden" style={{ visibility: 'hidden' }}>
        <ApplyAllCheck />
      </div>
    </>
  );
}
