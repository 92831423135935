import React from 'react';
import { SvgExpand } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { keyPressAsClick } from '../../../services/accessibility';
import { t } from '../../../services/translation';

type ShowMoreNodeProps = {
  hiddenCount: number;
  onClick: () => void;
};

export function ShowMoreNode({
  hiddenCount,
  onClick
}: ShowMoreNodeProps): JSX.Element {
  return (
    <div
      className="tree-node"
      onClick={onClick}
      onKeyPress={keyPressAsClick(onClick)}
      role="button"
      tabIndex={0}
      data-testid="show-more-node"
    >
      <SvgExpand />
      <Text variant="small" isMuted={true} style={{ marginLeft: '4px' }}>
        {t('FolderExplorer.ShowMore', { hiddenCount })}
      </Text>
    </div>
  );
}
