import type {
  HttpService,
  PWFileType,
  PWItem,
  PWProject
} from '@bentley/pw-api';
import { itemIsProject, parseRelatedParent } from '@bentley/pw-api';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import type { ToastHandle } from '../../services/pwToast';
import type { ConflictResolution } from '../conflictResolution/conflictResolution';
import { deleteItem } from '../delete';
import { replaceFileWorkflow } from '../replace';
import {
  buildConflictToasts,
  handleVersionError,
  notifyConflictsResolved,
  sendAccessDeniedNotification,
  sendUploadNotification
} from './notifications';
import type { CustomFile, UploadNode } from './tree';
import {
  uploadDocumentWorkflow,
  uploadMultipleWorkflow,
  uploadNewFolderWorkflow
} from './upload';
import { getItemFromResponse, newFile } from './utils';

export type ProjectWithUploadNode = {
  uploadNode: UploadNode;
} & PWProject;

export async function resolveConflicts(
  resolutions: ConflictResolution[],
  httpService: HttpService,
  trackFeature: TrackFeature,
  openModal: OpenModal,
  closeModal: CloseModal,
  progressManager: ProgressManager,
  jobId: string,
  onComplete?: (uploadedItems: PWItem[]) => void,
  originalItem?: PWItem
): Promise<void> {
  const toasts = buildConflictToasts(resolutions);

  const uploadedItems = (
    await Promise.all(
      resolutions.map((resolution) =>
        handleResolution(
          resolution,
          httpService,
          trackFeature,
          openModal,
          closeModal,
          progressManager,
          jobId,
          toasts[resolution.action],
          originalItem
        )
      )
    )
  ).filter((response) => response != undefined);

  if (uploadedItems.length && onComplete) {
    notifyConflictsResolved(uploadedItems.length);
    onComplete(uploadedItems);
  }
}

async function handleResolution(
  resolution: ConflictResolution,
  httpService: HttpService,
  trackFeature: TrackFeature,
  openModal: OpenModal,
  closeModal: CloseModal,
  progressManager: ProgressManager,
  jobId: string,
  toastHandle?: ToastHandle,
  originalItem?: PWItem
): Promise<PWItem | undefined> {
  progressManager.clearConflictsFromJob(jobId);

  if (resolution.action == 'keep') {
    return;
  }

  const sourceItem = resolution.sourceItem;

  const destinationItem =
    resolution.destinationItems[resolution.destinationItems.length - 1];

  if (!sourceItem || !destinationItem) {
    return;
  }

  if (toastHandle) {
    progressManager.updateToastForJob(jobId, toastHandle);
  }

  if (resolution.action == 'replace') {
    return replaceResolution(
      resolution.file,
      destinationItem as PWFileType,
      httpService,
      trackFeature,
      progressManager,
      toastHandle,
      originalItem
    );
  } else if (resolution.action == 'version') {
    sourceItem.Description = destinationItem.Description;
    return versionResolution(
      sourceItem as PWFileType,
      resolution.file,
      parseRelatedParent(destinationItem) as PWProject,
      resolution,
      httpService,
      trackFeature,
      progressManager,
      toastHandle,
      jobId,
      originalItem
    );
  } else if (resolution.action == 'rename') {
    return renameResolution(
      sourceItem,
      resolution.file,
      parseRelatedParent(destinationItem) as PWProject,
      resolution.customName,
      httpService,
      trackFeature,
      openModal,
      closeModal,
      progressManager,
      toastHandle,
      jobId,
      originalItem
    );
  } else {
    return undefined;
  }
}

async function replaceResolution(
  file: File | undefined,
  destinationItem: PWFileType,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  toastHandle?: ToastHandle,
  originalItem?: PWItem
): Promise<PWItem | undefined> {
  if (!file) {
    return;
  }

  const response = await replaceFileWorkflow(
    file,
    destinationItem,
    httpService,
    trackFeature,
    progressManager,
    toastHandle,
    undefined,
    undefined,
    undefined
  );

  if (originalItem) {
    const originalItemParent = parseRelatedParent(originalItem);
    if (originalItemParent) {
      void deleteItem(
        originalItem,
        originalItemParent,
        httpService,
        trackFeature
      );
    }
  }

  if (!response) {
    return;
  }

  return getItemFromResponse(response, destinationItem.ParentGuid);
}

async function versionResolution(
  sourceItem: PWFileType,
  file: File | undefined,
  destinationParent: PWProject,
  resolution: ConflictResolution,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  toastHandle: ToastHandle | undefined,
  jobId: string,
  originalItem?: PWItem
): Promise<PWItem | undefined> {
  if (!file || !destinationParent) {
    return;
  }

  const customFile = file as CustomFile;
  customFile.customName = resolution.originalName;
  customFile.documentDescription = sourceItem.Description;

  const response = await uploadDocumentWorkflow(
    customFile,
    destinationParent,
    httpService,
    trackFeature,
    jobId,
    progressManager,
    undefined,
    resolution.customVersion ?? ''
  );

  if (!response.ok) {
    void handleVersionError(response);
  } else {
    const sourceItemParent = parseRelatedParent(sourceItem);
    if (originalItem && sourceItemParent) {
      void deleteItem(
        originalItem,
        sourceItemParent,
        httpService,
        trackFeature
      );
    }
  }
  toastHandle?.close();
  return getItemFromResponse(response);
}

async function renameResolution(
  sourceItem: PWItem,
  file: File | undefined,
  destinationParent: PWProject,
  customName: string,
  httpService: HttpService,
  trackFeature: TrackFeature,
  openModal: OpenModal,
  closeModal: CloseModal,
  progressManager: ProgressManager,
  toastHandle: ToastHandle | undefined,
  jobId: string,
  originalItem?: PWItem
): Promise<PWItem | undefined> {
  try {
    if (itemIsProject(sourceItem)) {
      return renameProjectResolution(
        sourceItem,
        destinationParent,
        customName,
        httpService,
        openModal,
        closeModal,
        trackFeature,
        progressManager,
        jobId,
        toastHandle
      );
    } else {
      return renameFileResolution(
        file,
        destinationParent,
        customName,
        httpService,
        trackFeature,
        progressManager,
        jobId,
        toastHandle
      );
    }
  } finally {
    if (originalItem) {
      const sourceItemParent = parseRelatedParent(sourceItem);
      if (sourceItemParent) {
        void deleteItem(
          originalItem,
          sourceItemParent,
          httpService,
          trackFeature
        );
      }
    }
  }
}

async function renameProjectResolution(
  sourceItem: PWProject,
  destinationParent: PWProject,
  customName: string,
  httpService: HttpService,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  jobId: string,
  toastHandle?: ToastHandle
): Promise<PWItem | undefined> {
  const { uploadNode } = sourceItem as PWProject & { uploadNode?: UploadNode };

  if (!uploadNode || !destinationParent) {
    return;
  }

  const response = await uploadNewFolderWorkflow(
    customName,
    '',
    destinationParent.instanceId,
    httpService,
    trackFeature,
    jobId,
    progressManager
  );

  if (response.status == 403) {
    sendAccessDeniedNotification(response);
    return undefined;
  }

  const renamedProject = (await getItemFromResponse(response)) as PWProject;

  // Upload subitems in the folder, no need to await
  void uploadMultipleWorkflow(
    uploadNode,
    renamedProject,
    httpService,
    openModal,
    closeModal,
    trackFeature,
    progressManager,
    jobId,
    undefined,
    true,
    undefined,
    toastHandle
  );

  return getItemFromResponse(response);
}

async function renameFileResolution(
  file: File | undefined,
  destinationParent: PWProject,
  customName: string,
  httpService: HttpService,
  trackFeature: TrackFeature,
  progressManager: ProgressManager,
  jobId: string,
  toastHandle?: ToastHandle
): Promise<PWItem | undefined> {
  if (!file || !destinationParent) {
    return;
  }

  const response = await uploadDocumentWorkflow(
    newFile(file, customName),
    destinationParent,
    httpService,
    trackFeature,
    jobId,
    progressManager,
    undefined
  );
  sendUploadNotification(response, customName, 'File', toastHandle);
  return getItemFromResponse(response);
}
