import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import {
  useAppViewContext,
  useEnvironmentContext,
  useFolderExplorerContext,
  useNavigationContext,
  usePluginContext
} from '../../../context';
import { keyPressAsClick } from '../../../services/accessibility';
import { Chevron } from '../chevron/chevron';
import { OutToMeNode } from '../outToMeNode/outToMeNode';
import { RecycleBinNode } from '../recycleBinNode/recycleBinNode';
import { savedSearchesSupported } from '../savedSearchNode/savedSearchSupported';
import { SavedSearchesNodes } from '../savedSearchNode/savedSearchesNodes';
import { FolderNodeContextMenu } from './folderNodeContextMenu';
import { SubFoldersNode } from './subFoldersNode';

type FolderNodeProps = {
  folder: PWProject;
};

export function FolderNode({ folder }: FolderNodeProps): JSX.Element {
  const { connection } = usePluginContext();
  const { selectedIds } = useFolderExplorerContext();
  const { navigationManager, breadcrumbManager } = useNavigationContext();
  const { environmentContextInitialized } = useEnvironmentContext();
  const {
    appViewManager: { currentAppView, setAppView },
    searchBreadcrumbManager
  } = useAppViewContext();

  const isConnectionRoot = connection.ProjectId == (folder.instanceId ?? '');
  const isCurrentFolder =
    navigationManager.currentParent.instanceId == folder.instanceId;

  const [isExpanded, setIsExpanded] = useState<boolean>(isConnectionRoot);
  const [containsSubfolders, setContainsSubfolders] = useState<boolean>(true);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

  const nodeRef = useRef<HTMLDivElement>(null);

  function className(): string {
    let className = 'tree-node';

    if (
      isCurrentFolder &&
      (currentAppView.type == 'FolderNavigation' ||
        currentAppView.type == 'BasicSearch')
    ) {
      className += ' active-node';
    }

    if (selectedIds.includes(folder.instanceId)) {
      className += ' focused-node';
    }

    if (isConnectionRoot) {
      className += ' connection-root';
    }

    return className;
  }

  function onFolderClick(): void {
    setIsExpanded((cur) => !cur);
  }

  function onContextMenu(e: React.MouseEvent): void {
    e.preventDefault();
    if (!nodeRef.current) {
      return;
    }
    const contextMenu = nodeRef.current.querySelector(
      'button.context-menu-button'
    ) as HTMLElement;
    contextMenu.click();
  }

  function onMouseEnter(): void {
    // Context menu calls a lot of code to render, and we don't want to do it
    // for all nodes in folder explorer unnecessarily
    setShowContextMenu(true);
  }

  function onFolderLinkClick(e: React.MouseEvent): void {
    e.stopPropagation();
    navigationManager.navigateTo(folder, true);
    setAppView('FolderNavigation');
  }

  useEffect(() => {
    const inBreadcrumbPath = breadcrumbManager.breadcrumbs.some(
      ({ instanceId }) => instanceId == folder.instanceId
    );

    if (isCurrentFolder || inBreadcrumbPath) {
      setIsExpanded(true);
    }
  }, [breadcrumbManager.breadcrumbs, folder.instanceId, isCurrentFolder]);

  useEffect(() => {
    const inSearchBreadcrumbPath = searchBreadcrumbManager.breadcrumbs.some(
      ({ instanceId }) => instanceId == folder.instanceId
    );

    if (inSearchBreadcrumbPath && currentAppView.type != 'FolderNavigation') {
      setIsExpanded(true);
    }
  }, [
    currentAppView.type,
    folder.instanceId,
    searchBreadcrumbManager.breadcrumbs
  ]);

  useLayoutEffect(() => {
    if (isCurrentFolder) {
      nodeRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isCurrentFolder]);

  return (
    <>
      <div
        className={className()}
        onClick={onFolderClick}
        onKeyPress={keyPressAsClick(onFolderClick)}
        onContextMenu={onContextMenu}
        onMouseEnter={onMouseEnter}
        role="treeitem"
        tabIndex={0}
        data-testid={`${folder.Label ?? folder.Name}-tree-node`}
        ref={nodeRef}
      >
        <Chevron
          hasChildren={
            containsSubfolders ||
            savedSearchesSupported(folder) ||
            isConnectionRoot
          }
          isExpanded={isExpanded}
        />
        <ItemIcon
          item={folder}
          size="xs"
          iconProps={{ className: 'explorer-icon' }}
        />
        <Text
          as="span"
          className="node-label"
          onClick={onFolderLinkClick}
          onKeyPress={keyPressAsClick(onFolderLinkClick)}
          role="link"
          tabIndex={0}
          data-testid={`${folder.Name}-node-label`}
        >
          {folder.Label ?? folder.Name}
        </Text>
        {showContextMenu && <FolderNodeContextMenu folder={folder} />}
      </div>
      {isExpanded && environmentContextInitialized && (
        <div
          className="tree-children"
          data-testid={`${folder.Name}-tree-children`}
        >
          {isConnectionRoot && <OutToMeNode />}
          <SubFoldersNode
            parent={folder}
            notifyHasChildren={setContainsSubfolders}
          />
          {!connection.Canned && <SavedSearchesNodes parent={folder} />}
          {isConnectionRoot && <RecycleBinNode />}
        </div>
      )}
    </>
  );
}
