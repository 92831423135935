import { useMemo } from 'react';
import type {
  Accessor,
  Column,
  TableFilterProps
} from '@itwin/itwinui-react/react-table';
import { byStringOrNumber } from '../sort/sort';
import { buildDropdownFilter } from './dropdownFilter';

export function ColumnValuesFilter(
  props: TableFilterProps<Record<string, unknown>>
): JSX.Element {
  const { column, initialRows } = props;

  const values = useMemo((): string[] => {
    const items = initialRows.map((row) => row.original);

    const accessorFunction = (column as Column).accessor as Accessor<
      Record<string, unknown>
    >;

    function parseValue(item: Record<string, unknown>): string {
      if (!accessorFunction) {
        return '';
      }

      return accessorFunction(item, 0, {
        subRows: [],
        depth: 0,
        data: []
      }) as string;
    }

    const values = items
      .map(parseValue)
      .filter((value) => value !== undefined && value !== null && value !== '');

    const uniqueValues = Array.from(new Set(values)).sort(byStringOrNumber);

    return uniqueValues;
  }, [column, initialRows]);

  const dropdownFilter = buildDropdownFilter(values);
  return dropdownFilter(props);
}
