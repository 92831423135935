import { useCallback, useMemo } from 'react';
import type { PWItem, PWParentType, PWProject } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import { usePluginContext } from '../../context';
import type { AppViewSetting } from '../useAppView';
import { useNonRenderingState } from '../useNonRenderingState';
import { useQueriedParents } from './useQueriedParents';
import { useWorkAreaAvailable } from './useWorkAreaAvailable';

export type WorkAreaManager = {
  getCachedParent: (item: PWItem) => PWProject | undefined;
  itemExistsInConnection: (item: PWItem) => boolean | undefined;
  allItemsExistInConnection: (item: PWItem[]) => boolean;
  addCachedParent: (parent: PWProject) => void;
  workAreaAvailable: boolean;
};

export function useCurrentWorkArea(
  currentParent: PWParentType,
  currentAppView: AppViewSetting
): WorkAreaManager {
  const { connection, dataAccessLevel } = usePluginContext();

  const workAreaAvailable = useWorkAreaAvailable();
  const {
    knownWorkAreaParents,
    addKnownWorkAreaParents,
    queriedParentIds,
    queryAndCacheAncestors
  } = useQueriedParents();

  const workAreaParentsRef = useNonRenderingState(knownWorkAreaParents);

  const getCachedParent = useCallback(
    (item: PWItem): PWProject | undefined => {
      if (!item.ParentGuid) {
        return undefined;
      }

      const parent = workAreaParentsRef.current.find(
        (project) => project.instanceId == item.ParentGuid
      );

      return parent;
    },
    [workAreaParentsRef]
  );

  const itemExistsInConnection = useCallback(
    (item: PWItem): boolean | undefined => {
      if (dataAccessLevel == 'Datasource') {
        return true;
      }

      // The only way to see items outside the connection is through a saved search or a flat set
      if (
        currentAppView.type != 'SavedSearch' &&
        !itemIsFlatSet(currentParent)
      ) {
        return true;
      }

      if (
        item.instanceId == currentParent.instanceId ||
        item.ParentGuid == currentParent.instanceId
      ) {
        return true;
      }

      if (!item.ParentGuid) {
        return item.instanceId == connection.ProjectId;
      }

      const cachedParent = getCachedParent(item);
      if (cachedParent) {
        return true;
      }

      void queryAndCacheAncestors(item);

      if (queriedParentIds.includes(item.ParentGuid)) {
        return false;
      }

      return undefined;
    },
    [
      dataAccessLevel,
      currentAppView.type,
      currentParent,
      getCachedParent,
      queryAndCacheAncestors,
      queriedParentIds,
      connection.ProjectId
    ]
  );

  const allItemsExistInConnection = useCallback(
    (items: PWItem[]): boolean => {
      return items.every(itemExistsInConnection);
    },
    [itemExistsInConnection]
  );

  const addCachedParent = useCallback(
    (parent: PWProject): void => {
      if (dataAccessLevel == 'Datasource') {
        return;
      }

      addKnownWorkAreaParents([parent]);
    },
    [addKnownWorkAreaParents, dataAccessLevel]
  );

  const workAreaManager = useMemo(
    (): WorkAreaManager => ({
      getCachedParent,
      itemExistsInConnection,
      allItemsExistInConnection,
      addCachedParent,
      workAreaAvailable
    }),
    [
      addCachedParent,
      allItemsExistInConnection,
      getCachedParent,
      itemExistsInConnection,
      workAreaAvailable
    ]
  );

  return workAreaManager;
}
