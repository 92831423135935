import type {
  WSGChangedInstanceResponse,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';
import {
  AuthorizationService,
  HttpService,
  flattenProperties
} from '@bentley/pw-api';
import type { GetToken } from '../http';
import type { FederatedRepository } from './federatedRepository';
import {
  federatedRepoToLegacyRepo,
  legacyRepoToFederatedRepo,
  removeVersionString
} from './repositoryMapper';

export type LegacyRepository = WSGInstance & {
  Type: string;
  Url: string;
  UIUrl: string;
  Name: string;
  Description: string;
  DisplayLabel: string;
};

// legacy repository delete should be done in the background so it does not need to be async
export interface ILegacyRepositoryApi {
  getAll: () => Promise<LegacyRepository[]>;
  deleteMany: (repositoryIds: string[]) => void;
  addOne: (legacyRepository: LegacyRepository) => Promise<LegacyRepository>;
}

interface GenericRepo {
  Name: string;
}

export const getConnectionsNotInPRDSS = (
  repos: FederatedRepository[],
  legacyRepos: LegacyRepository[]
): FederatedRepository[] => {
  const missingRepos = legacyRepos.filter((legacyRepo) => {
    const reducedLegacyUrl = removeVersionString(legacyRepo.Url);
    return !repos.find(
      (repo) =>
        removeVersionString(repo.Url).toLowerCase() ==
        reducedLegacyUrl.toLowerCase()
    );
  });
  const newRepos = missingRepos.map((missingRepo) =>
    legacyRepoToFederatedRepo(missingRepo)
  );
  // update new repositories to make sure there are no name clashes
  updateRepoNames(repos, newRepos);
  return newRepos;
};

export function getConnectionsNotInFedRepoService(
  repos: FederatedRepository[],
  legacyRepos: LegacyRepository[]
): LegacyRepository[] {
  const newRepos = reposWithoutLegacyEquivalent(repos, legacyRepos);
  const missingRepos = newRepos.filter((repo) => !repo.LegacyId);
  const oldRepos = missingRepos.map(federatedRepoToLegacyRepo);
  updateRepoNames(legacyRepos, oldRepos);
  return oldRepos;
}

const updateRepoNames = (
  existingList: GenericRepo[],
  newList: GenericRepo[]
): void => {
  newList
    .filter((newRepo) => existingList.find((repo) => repo.Name == newRepo.Name))
    .forEach((existingRepo) => {
      let repoNumber = 1;
      while (
        existingList.find(
          (repo) => repo.Name == `${existingRepo.Name} ${repoNumber}`
        )
      ) {
        repoNumber++;
      }
      existingRepo.Name = `${existingRepo.Name} ${repoNumber}`;
    });
};

export function connectionsDeletedInFedRepoService(
  repos: FederatedRepository[],
  legacyRepos: LegacyRepository[]
): FederatedRepository[] {
  const newRepos = reposWithoutLegacyEquivalent(repos, legacyRepos);
  const deletedRepos = newRepos.filter(
    (repo) => repo.LegacyId && !repo.WorkAreaName
  );
  return deletedRepos;
}

function reposWithoutLegacyEquivalent(
  repos: FederatedRepository[],
  legacyRepos: LegacyRepository[]
): FederatedRepository[] {
  return repos.filter(notInLegacy(legacyRepos));
}

export function getLegacyConnectionFromUrl(
  connectionUrl: string,
  legacyRepos: LegacyRepository[]
): string | undefined {
  const reducedConnectionUrl = removeVersionString(connectionUrl);
  const legacyRepoWithUrl = legacyRepos.find((legacyRepo) => {
    const legacyUrl = removeVersionString(legacyRepo.Url);
    return legacyUrl.toLowerCase() == reducedConnectionUrl.toLowerCase();
  });
  return legacyRepoWithUrl ? legacyRepoWithUrl.instanceId : undefined;
}

function notInLegacy(
  legacyRepos: LegacyRepository[]
): (repo: FederatedRepository) => boolean {
  function urlNotInLegacyRepos(repo: FederatedRepository) {
    if (repo.Canned) {
      return false;
    }
    const reducedRepoUrl = removeVersionString(repo.Url);
    const legacyRepoWithUrl = legacyRepos.find(
      (legacyRepo) =>
        removeVersionString(legacyRepo.Url).toLowerCase() ==
        reducedRepoUrl.toLowerCase()
    );
    return !legacyRepoWithUrl;
  }

  return urlNotInLegacyRepos;
}

export function getLegacyRepositoryApi(
  serviceUrl: string,
  getSamlToken: GetToken,
  contextId?: string
): ILegacyRepositoryApi {
  const baseUrl = `${serviceUrl}/v2.4/Repositories/BentleyCONNECT.RepositoryFederation--${
    contextId ?? ''
  }`;
  const httpService = new HttpService({
    authorization: new AuthorizationService({ getSamlToken }),
    baseUrl
  });

  return {
    getAll: getLegacyRepositories.bind(null, httpService),
    deleteMany: deleteLegacyRepositories.bind(null, httpService),
    addOne: addLegacyRepository.bind(null, httpService)
  };
}

async function getLegacyRepositories(
  httpService: HttpService
): Promise<LegacyRepository[]> {
  const url = `RepositoryFederation/FederatedRepository`;

  try {
    const response = await httpService.get(url, { uncached: true });
    if (!response?.ok) {
      throw new Error('Failed to load legacy repositories');
    }

    const data = (await response.json()) as WSGInstancesResponse;
    const legacyRepositories = data.instances.map(
      (dataItem) => flattenProperties(dataItem) as LegacyRepository
    );
    return legacyRepositories.filter((repo) => repo.Type == 'Bentley.PW');
  } catch (e) {
    console.error('Failed to load legacy repositories', e);
    throw new Error('Failed to load legacy repositories');
  }
}

function deleteLegacyRepositories(
  httpService: HttpService,
  repositoryIds: string[]
): void {
  for (const repoId of repositoryIds) {
    deleteLegacyRepository(httpService, repoId);
  }
}

function deleteLegacyRepository(
  httpService: HttpService,
  repositoryId: string
): void {
  const url = `RepositoryFederation/FederatedRepository/${repositoryId}`;
  void httpService.delete(url, null);
}

function toLegacyRepositoryType(response: WSGInstance): LegacyRepository {
  return {
    Type: response.properties?.Type,
    Url: response.properties?.Url,
    UIUrl: response.properties?.UIUrl,
    Name: response.properties?.Name,
    Description: response.properties?.Description,
    instanceId: response.instanceId,
    className: response.className
  } as LegacyRepository;
}

async function addLegacyRepository(
  httpService: HttpService,
  legacyRepo: LegacyRepository
): Promise<LegacyRepository> {
  const url = `RepositoryFederation/FederatedRepository`;
  const postBody = getPostBody(legacyRepo);
  const responseJson = (await (
    await httpService.post(url, JSON.stringify(postBody))
  ).json()) as WSGChangedInstanceResponse;
  return toLegacyRepositoryType(
    responseJson.changedInstance.instanceAfterChange
  );
}

function getPostBody(legacyRepo: LegacyRepository): {
  instance: {
    className: string;
    schemaName: string;
    properties: LegacyRepository;
  };
} {
  return {
    instance: {
      className: 'FederatedRepository',
      schemaName: 'RepositoryFederation',
      properties: legacyRepo
    }
  };
}
