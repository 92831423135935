import React, { useMemo } from 'react';
import { Table } from '@itwin/itwinui-react';
import { usePropertyContext } from '../../../context';
import type { View } from '../../../hooks/useViews';
import { EmptyView } from '../emptyView';
import { useViewsColumns } from './viewsColumns';

export function ViewsTable(): JSX.Element {
  const { viewManager } = usePropertyContext();

  const tableData = useMemo((): View[] => {
    const tableData = viewManager.userViewSetting.views ?? [];
    return tableData.sort((a, b) =>
      a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
    );
  }, [viewManager.userViewSetting.views]);

  const columns = useViewsColumns();

  if (!tableData.length) {
    return <EmptyView />;
  }

  return (
    <Table
      columns={columns}
      data={tableData}
      isSortable={true}
      style={{ maxHeight: '50vh', minWidth: '525px' }}
      density="extra-condensed"
      emptyTableContent=""
    />
  );
}
