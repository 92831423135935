import { useEffect, useState } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../context';
import { azureAccessibilityCheck } from './serverAccessible';

export function useAzureServerAccessible(): boolean {
  const { getOidcToken } = useToken();
  const { contextId, connection, buddiRegionCode } = usePluginContext();

  const connectionServiceUrl = useBuddi(
    'ProjectWiseWebConnections.URL',
    buddiRegionCode
  );

  const [serverIsAccessible, setServerIsAccessible] = useState<boolean>(true);

  useEffect(() => {
    async function checkServer(): Promise<void> {
      if (!contextId || !connection.Id || !connectionServiceUrl) {
        return;
      }

      const serverAccessible = await azureAccessibilityCheck(
        getOidcToken,
        connectionServiceUrl,
        contextId ?? '',
        connection.Id
      );
      setServerIsAccessible(serverAccessible);
    }

    void checkServer();
  }, [contextId, connection.Id, getOidcToken, connectionServiceUrl]);

  return serverIsAccessible;
}
