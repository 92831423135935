import type { ChangeEvent } from 'react';
import React from 'react';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  SvgAdd,
  SvgCheckboxDeselect,
  SvgRemove
} from '@itwin/itwinui-icons-react';
import type { SelectOption } from '@itwin/itwinui-react';
import {
  Checkbox,
  ComboBox,
  IconButton,
  Input,
  Select
} from '@itwin/itwinui-react';
import { useEnvironmentContext } from '../../context';
import type { AttributePicklistValue } from '../../hooks/useEnvironment/environmentAttributes/attributeDefinitions';
import type { EnvironmentProperty } from '../../hooks/useProperties';
import { columnContainsUserNames } from '../../hooks/useUsers';
import type { User } from '../../hooks/useUsers/data';
import { t } from '../../services/translation';
import { DatePickerInput } from '../datePickerInput';
import { booleanValueDropdownOptions } from './row/dropdownOptions';
import { dataTypes } from './search/query';

export type AdvancedSearchGroup = {
  depthLevel?: number;
  id: number;
  startRow: number;
  endRow: number;
  selectedRowIDs: string[];
};

type RowProviderProps = {
  id: string;
  textValue: string | number;
  logicalOperator: SelectOption<string>[];
  operators: string[];
  isLogicalOperatorVisible: boolean;
  isCheckBoxVisible: boolean;
  selectedProperty: EnvironmentProperty;
  properties: EnvironmentProperty[];
  onAdd: (id: string) => void;
  onRemove: (rowId: string, index: number) => void;
  onPropertiesChange: (
    id: string,
    name: string,
    propertyType: string,
    envLabel: string,
    label: string,
    dataType: string,
    picklistOptions?: AttributePicklistValue[]
  ) => void;
  onOperatorsChange: (id: string, value: string) => void;
  onTextChange: (id: string, value: string) => void;
  onNumericTextChange: (id: string, value: number) => void;
  onLogicalOperatorChange: (id: string, value: string) => void;
  onCheckBoxChange: (id: string, index: number) => void;
  isChecked: boolean;
  index: number;
  groups?: AdvancedSearchGroup[];
  onGroupRemove: (id: number) => void;
  selectedOperator: string;
  selectedLogicalOperator: SelectOption<string>;
};

export function RowProvider({
  id,
  textValue,
  logicalOperator,
  operators,
  isLogicalOperatorVisible,
  isCheckBoxVisible,
  selectedProperty,
  properties,
  onAdd,
  onRemove,
  onPropertiesChange,
  onOperatorsChange,
  onTextChange,
  onNumericTextChange,
  onLogicalOperatorChange,
  onCheckBoxChange,
  isChecked,
  index,
  groups,
  onGroupRemove,
  selectedOperator,
  selectedLogicalOperator
}: RowProviderProps): JSX.Element {
  const { userManager } = useEnvironmentContext();

  function getValueSelector(
    dataType: string | undefined,
    picklistOptions: AttributePicklistValue[] | string[] | undefined,
    isLimitToList = true
  ) {
    if (picklistOptions) {
      // picklistOptions could be string[] for older advanced searches so converting it to AttributePicklistValue[]
      picklistOptions = picklistOptions.map((option) =>
        typeof option === 'string'
          ? { Value: option, DisplayLabel: option }
          : option
      );

      return isLimitToList ? (
        <Select
          key={`picklist_${id}`}
          options={picklistOptions.map((option) => ({
            value: option.Value,
            label: option.DisplayLabel ?? option.Value
          }))}
          value={textValue.toString()}
          onChange={(e: string) => {
            onTextChange(id, e);
          }}
          data-testid="input-picklist"
          className="input-Width type-field"
          placeholder={t('Typeahead.Placeholder')}
        />
      ) : (
        <ComboBox
          key={`picklist_${id}`}
          options={picklistOptions.map((option) => ({
            value: option.Value,
            label: option.DisplayLabel ?? option.Value
          }))}
          value={textValue.toString()}
          onChange={(e: string) => {
            onTextChange(id, e);
          }}
          data-testid="input-picklist"
          className="input-Width type-field"
          inputProps={{ placeholder: t('Typeahead.Placeholder') }}
        />
      );
    }
    switch (dataType) {
      case dataTypes.dateTime:
        return (
          <DatePickerInput
            date={new Date(textValue)}
            onChange={(date?: Date) => {
              if (date) {
                onTextChange(id, date.toDateString());
              }
            }}
            inputProps={
              {
                style: { width: 'calc(100% - 9px)' },
                'data-testId': 'input-datetime'
              } as never
            }
          />
        );
      case dataTypes.double: {
        return (
          <Input
            className="input-Width input-Margin"
            onChange={(g: ChangeEvent<HTMLInputElement>) =>
              onNumericTextChange(id, +g.target.value)
            }
            pattern="^[+-]?\d+(\.\d+)?$"
            value={textValue}
            data-testid="input-double"
          />
        );
      }
      case dataTypes.int:
      case dataTypes.long: {
        return (
          <Input
            className="input-Width input-Margin"
            onChange={(g: ChangeEvent<HTMLInputElement>) =>
              onNumericTextChange(id, +g.target.value)
            }
            pattern="(\d)+"
            value={textValue}
            data-testid="input-int"
          />
        );
      }
      case dataTypes.boolean:
        return (
          <div data-testid="input-boolean">
            <Select
              options={booleanValueDropdownOptions()}
              onChange={(e) => onTextChange(id, e.toString())}
              id={id}
              key={id}
              itemType={'number'}
              className="input-Width input-Margin"
              value={String(textValue).toLowerCase()}
            />
          </div>
        );
      case dataTypes.string:
        return (
          <Input
            name="value"
            value={textValue}
            disabled={false}
            id={id}
            onChange={(g: ChangeEvent<HTMLInputElement>) =>
              onTextChange(id, g.target.value)
            }
            key={id}
            className="input-Width input-Margin"
            data-testid="input-string"
          />
        );
      default:
        return (
          <Input
            key={id}
            name="value"
            value={textValue}
            disabled={false}
            id={id}
            onChange={(g: ChangeEvent<HTMLInputElement>) =>
              onTextChange(id, g.target.value)
            }
            className="input-Width input-Margin"
            data-testid="input-default"
          />
        );
    }
  }
  function getGroupByCell(groups?: AdvancedSearchGroup[]) {
    if (!groups) {
      return;
    }
    let groupRow = <td className="cls-group-by"></td>;
    for (const group of groups) {
      if (group.startRow == index) {
        groupRow = (
          <td className="group-by-startrow cls-group-by">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onGroupRemove(group.id);
              }}
              styleType="borderless"
              size="small"
              data-testid="button-group"
            >
              <SvgCheckboxDeselect />
            </IconButton>
          </td>
        );
      } else if (group.endRow == index) {
        groupRow = <td className="group-by-endrow cls-group-by"></td>;
      } else if (group.startRow < index && group.endRow > index) {
        groupRow = <td className="group-by-middlerow cls-group-by"></td>;
      }
    }
    return groupRow;
  }

  function getFieldOptions(
    properties: EnvironmentProperty[]
  ): SelectOption<string>[] {
    const options = properties
      .map((property) => {
        if (
          property.displayLabel == selectedProperty.displayLabel &&
          property.name != selectedProperty.name
        ) {
          return {
            value: `${property.name}Id|${property.propertyType}|${property.envLabel}`,
            label: property.displayLabel,
            sublabel: property.envLabel
          };
        }
        return {
          value: `${property.name}|${property.propertyType}|${property.envLabel}`,
          label: property.displayLabel,
          sublabel: property.envLabel
        };
      })
      .filter(
        (item, i, ar) => ar.map((a) => a.label).indexOf(item.label) === i
      );

    return options;
  }

  function getPropertyFromPropValue(prop: string): EnvironmentProperty {
    const name = prop.split('|')[0];
    const propertyType = prop.split('|')[1];
    const envLabel = prop.split('|')[2];
    const property = properties.find(
      (p) =>
        p.name == name &&
        p.propertyType == propertyType &&
        p.envLabel == envLabel
    );

    return (
      property ?? {
        name,
        propertyType,
        envLabel,
        displayLabel: t('CustomColumns.GeneralProperties'),
        dataType: 'string',
        isProjectEnv: false
      }
    );
  }

  function onPropsChange(prop: string): void {
    const selectedProperty = getPropertyFromPropValue(prop);
    onPropertiesChange(
      id,
      selectedProperty.name,
      selectedProperty.propertyType,
      selectedProperty.envLabel,
      selectedProperty.displayLabel,
      selectedProperty.dataType,
      getPicklistOptionsFromSelectedProperty(
        selectedProperty,
        userManager.users.current
      )
    );
  }

  return (
    <tr key={id} id={id} data-testid="advanced-search-row">
      <td className="row-op-btn">
        <IconButton
          styleType="borderless"
          onClick={() => onAdd(id)}
          data-testid="add-button"
        >
          <SvgAdd />
        </IconButton>
      </td>
      <td className="row-op-btn">
        <IconButton
          styleType="borderless"
          onClick={() => onRemove(id, index)}
          data-testid="remove-button"
        >
          <SvgRemove />
        </IconButton>
      </td>
      <td className="align-checkbox row-op-btn">
        <Checkbox
          label=""
          id={id}
          name="checkboxGroup"
          data-testid="checkboxGroup"
          onChange={(e) => onCheckBoxChange(e.target.id, index)}
          checked={isChecked}
          hidden={!isCheckBoxVisible}
        />
      </td>
      {getGroupByCell(groups)}

      <td className="logical-width" data-testid="dropdown-logicalOperator">
        {index != 0 && isLogicalOperatorVisible && (
          <Select
            options={logicalOperator}
            onChange={(e) => onLogicalOperatorChange(id, e)}
            id={id}
            key={id}
            className="input-Margin"
            value={selectedLogicalOperator.label}
          />
        )}
      </td>
      <td className="td-width" data-testid="typeAhead-SelectedProperty">
        <ComboBox<string>
          key={`property_${id}`}
          options={getFieldOptions(properties)}
          value={
            selectedProperty.name == ''
              ? undefined
              : `${selectedProperty.name}|${selectedProperty.propertyType}|${selectedProperty.envLabel}`
          }
          onChange={onPropsChange}
          className="input-Width type-field"
          inputProps={{ placeholder: t('Typeahead.Placeholder') }}
        />
      </td>
      <td className="td-width" data-testid="typeAhead-operators">
        <Select
          key={`operator_${id}`}
          disabled={false}
          options={operators.map((a) => {
            return { value: a, label: a } as SelectOption<string>;
          })}
          value={selectedOperator == '' ? undefined : selectedOperator}
          onChange={(e: string) => onOperatorsChange(id, e)}
          className="input-Width type-field"
          placeholder={t('Typeahead.Placeholder')}
        />
      </td>
      <td className="td-width">
        {getValueSelector(
          selectedProperty?.dataType,
          selectedProperty?.picklistOptions,
          selectedProperty.isLimitedToList
        )}
      </td>
    </tr>
  );
}

function getPicklistOptionsFromSelectedProperty(
  selectedProperty: EnvironmentProperty,
  users?: User[]
): AttributePicklistValue[] | undefined {
  if (selectedProperty.picklistOptions) {
    return selectedProperty.picklistOptions;
  }

  if (
    (selectedProperty.propertyType == 'General' ||
      selectedProperty.propertyType == 'Folder') &&
    columnContainsUserNames(selectedProperty.name)
  ) {
    return users?.map((user) => ({
      Value: user.instanceId,
      DisplayLabel: user.Name
    }));
  }

  return undefined;
}
