import { useMemo } from 'react';
import {
  itemIsFinal,
  itemIsLatestVersion,
  itemIsProject
} from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useTableItemContext
} from '../../../context';

export function useAllowEditDocCode(): boolean {
  const { ecPluginFeatures } = useEnvironmentContext();
  const { launchDarklyFeatures } = useFeatureTracking();
  const { actionableItems } = useTableItemContext();

  const allowEditDocCode = useMemo((): boolean => {
    if (!launchDarklyFeatures.showDocCodeEditing) {
      return false;
    }

    if (!ecPluginFeatures.attributeInterfaces()) {
      return false;
    }

    if (actionableItems.length != 1) {
      return false;
    }

    if (itemIsProject(actionableItems[0])) {
      return false;
    }

    if (itemIsFinal(actionableItems[0])) {
      return false;
    }

    if (!itemIsLatestVersion(actionableItems[0])) {
      return false;
    }

    if (actionableItems[0].EnvironmentId == undefined) {
      return false;
    }

    return true;
  }, [actionableItems, ecPluginFeatures, launchDarklyFeatures]);

  return allowEditDocCode;
}
