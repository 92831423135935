import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FormContainer } from '../../../actions/formRendering';
import { LoadingPage } from '../../loadingPage';
import { updatingEnvironmentInterfacesState } from './state';
import { useAttributesForm } from './useAttributesForm';

export function AttributesFormContainer(): JSX.Element {
  const updatingEnvironmentInterfaces = useRecoilValue(
    updatingEnvironmentInterfacesState
  );

  const [loadingEnvironment, setLoadingEnvironment] = useState<boolean>(
    updatingEnvironmentInterfaces
  );

  function keepSpinnerUntilNewFormLoaded() {
    setTimeout(() => setLoadingEnvironment(false));
  }

  const formJQuery = useAttributesForm();

  useEffect(() => {
    if (!updatingEnvironmentInterfaces) {
      keepSpinnerUntilNewFormLoaded();
    } else {
      setLoadingEnvironment(true);
    }
  }, [updatingEnvironmentInterfaces]);

  if (!formJQuery || loadingEnvironment) {
    return <LoadingPage />;
  }

  return <FormContainer formJQuery={formJQuery} formType="attributespage" />;
}
