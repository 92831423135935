import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormContainer } from '../../../../actions/formRendering';
import { updatingEnvironmentInterfacesState, useDCWForm } from '../../form';
import { DCWSpinner } from '../../loading';
import {
  attributesFormDefinitionState,
  attributesFormValidState
} from './state';

export function AttributesFormContainer(): JSX.Element {
  const setAttributesFormIsValid = useSetRecoilState(attributesFormValidState);
  const updatingEnvironmentInterfaces = useRecoilValue(
    updatingEnvironmentInterfacesState
  );

  const attributesFormDefinition = useRecoilValue(
    attributesFormDefinitionState
  );

  const [loadingEnvironment, setLoadingEnvironment] = useState<boolean>(
    updatingEnvironmentInterfaces
  );

  const formJQuery = useDCWForm(
    'attributespage',
    attributesFormDefinition,
    setAttributesFormIsValid
  );

  function keepSpinnerUntilNewFormLoaded() {
    setTimeout(() => setLoadingEnvironment(false));
  }

  useEffect(() => {
    if (!updatingEnvironmentInterfaces) {
      keepSpinnerUntilNewFormLoaded();
    } else {
      setLoadingEnvironment(true);
    }
  }, [updatingEnvironmentInterfaces]);

  if (!formJQuery || loadingEnvironment) {
    return <DCWSpinner className="dcw-form-placeholder" />;
  }

  return <FormContainer formJQuery={formJQuery} formType="attributespage" />;
}
