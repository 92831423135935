import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { GetToken } from '../../../services/http';

export type TokenProps = {
  getOidcToken: GetToken;
  getSamlToken: GetToken;
};

type TokenContext = {
  getOidcToken: GetToken;
  getSamlToken: GetToken;
};

const Context = createContext<TokenContext | undefined>(undefined);

export function TokenProvider({
  value: { getOidcToken, getSamlToken },
  children
}: ProviderProps<TokenProps>): JSX.Element {
  const tokenContext = useMemo((): TokenContext => {
    return {
      getOidcToken,
      getSamlToken
    };
  }, [getOidcToken, getSamlToken]);

  return <Context.Provider value={tokenContext}>{children}</Context.Provider>;
}

export function useToken(): TokenContext {
  const context = useContext(Context);
  if (context == undefined) {
    throw new Error('useToken must be used within a TokenProvider');
  }

  return context;
}
