import { responseAborted } from '../../services/http';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { DynamicProjectSettings } from './driveSettingsModal';
import { defaultProjectSettings } from '.';

type DynamicProjectSettingsResponse = {
  projectId: string;
  timestamp: number;
  settings: string;
};

type DynamicSettingsJson = {
  autoCheckout?: {
    enabled: boolean;
  };
  autoCreate?: {
    ignoredFilePatterns: IgnoredFilePattern[];
  };
  documentWizard: {
    showIfDocumentCode: boolean;
    showIfNoDocumentCode: boolean;
    showIfPWIC: boolean;
  };
};

export type IgnoredFilePattern = {
  name: string;
  value: string;
};

export async function handleGetSettingsResponse(
  response: Response
): Promise<DynamicProjectSettings> {
  if (responseAborted(response)) {
    return defaultProjectSettings;
  }
  if (!response.ok) {
    throw t('ProjectSettings.ErrorRetrievingSettings', {
      error: response.statusText
    });
  }
  const data = (await response.json()) as DynamicProjectSettingsResponse;
  return parseDynamicSettingsResponse(data.settings);
}

function parseDynamicSettingsResponse(
  rawSettings: string
): DynamicProjectSettings {
  const settings = JSON.parse(rawSettings) as DynamicSettingsJson;
  return {
    UseDCWInFoldersWithCode: settings.documentWizard.showIfDocumentCode,
    UseDCWInFoldersWithoutCode: settings.documentWizard.showIfNoDocumentCode,
    UseDCWInPWIC: settings.documentWizard.showIfPWIC,
    AutoCheckoutDocsInDrive: settings.autoCheckout?.enabled,
    AutoCreationBlacklist:
      settings.autoCreate?.ignoredFilePatterns?.map(
        (pattern) => pattern.value
      ) ?? []
  } as DynamicProjectSettings;
}

export function handlePostSettingsResponse(response: Response): void {
  if (response.status == 401) {
    const toastContent = t('ProjectSettings.PermissionWarning');
    openToast({ content: toastContent, category: 'warning' });
  }
  if (responseAborted(response)) {
    return;
  }
  if (!response.ok) {
    const toastContent = t('ProjectSettings.ErrorRetrievingSettings', {
      error: response.statusText
    });
    openToast({ content: toastContent, category: 'negative' });
  } else {
    const toastContent = t('ProjectSettings.LocationSaved');
    openToast({ content: toastContent, category: 'positive' });
  }
  return;
}
