import './columnSelection.css';

import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { TransferList } from '@itwin/itwinui-react';
import { ReorderToolbar } from './reorderToolbar';
import { TransferListBox } from './transferListBox';
import { TransferToolbar } from './transferToolbar';

export type TransferData = {
  label: string;
  description?: string;
  active?: boolean;
};

type ColumnSelectionProps<T> = {
  leftLabel?: string;
  leftData: T[];
  rightLabel?: string;
  rightData: T[];
  showDescription?: 'left' | 'right' | 'both';
  leftFilter?: (item: T) => boolean;
  leftSort?: (a: T, b: T) => number;
  rightFilter?: (item: T) => boolean;
  rightSort?: (a: T, b: T) => number;
  setLeftData: Dispatch<SetStateAction<T[]>>;
  setRightData: Dispatch<SetStateAction<T[]>>;
};

export function ColumnSelection<T extends TransferData>({
  leftLabel,
  leftData,
  rightLabel,
  rightData,
  showDescription,
  leftFilter,
  leftSort,
  rightFilter,
  rightSort,
  setLeftData,
  setRightData
}: ColumnSelectionProps<T>): JSX.Element {
  return (
    <TransferList className="column-selection">
      <TransferListBox
        label={leftLabel}
        data={leftData}
        showDescription={showDescription == 'left' || showDescription == 'both'}
        filter={leftFilter}
        sort={leftSort}
        setData={setLeftData}
      />
      <TransferToolbar
        leftColumn={leftData}
        rightColumn={rightData}
        leftFilter={leftFilter}
        rightFilter={rightFilter}
        setLeftColumn={setLeftData}
        setRightColumn={setRightData}
      />
      <TransferListBox
        label={rightLabel}
        data={rightData}
        showDescription={
          showDescription == 'right' || showDescription == 'both'
        }
        filter={rightFilter}
        sort={rightSort}
        setData={setRightData}
      />
      <ReorderToolbar data={rightData} setData={setRightData} />
    </TransferList>
  );
}
