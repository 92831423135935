import type { PWItem } from '@bentley/pw-api';
import { itemIsDocument, itemIsPlaceholder } from '@bentley/pw-api';

/**
 * Returns whether an item is able to be downloaded
 * @param {PWItem} item Item to download
 * @returns {boolean} True if item may be downloaded
 */
export function allowDownload(item: PWItem): boolean {
  return (
    item.instanceId !== null &&
    (!itemIsDocument(item) ||
      (itemIsDocument(item) && !itemIsPlaceholder(item)))
  );
}

/**
 * Returns whether some items are able to be downloaded
 * @param {PWItem[]} items Items to download
 * @returns {boolean} True if some items may be downloaded
 */
export const allowDownloadSome = (items: PWItem[]): boolean => {
  if (!items || !items.length) {
    return false;
  }

  return items.some(allowDownload);
};
