import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  checkOut,
  isCheckOutDisabled,
  isCheckOutHidden
} from '../../../../actions/sync';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { useOutToMeSearch } from '../../../../hooks/useOutToMeSearch';
import { t } from '../../../../services/translation';
import { SvgCheckOut } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useCheckOutItemAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures, trackFeature } = useFeatureTracking();
  const { userId: userGuid } = useConnectionAuth();
  const { progressManager, consumerApp, pwDriveData, readOnly, contextId } =
    usePluginContext();
  const httpService = useHttpService();
  const graphApiManager = useGraphApiContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const outToMe = useOutToMeSearch();

  // Todo: should be in check out module
  const refresh = useCallback((): void => {
    void graphApiManager.refreshCoAuthoringSessions();
    refreshCurrentFolder();
  }, [graphApiManager, refreshCurrentFolder]);

  const refreshOutToMe = useCallback((): void => {
    void outToMe();
  }, [outToMe]);

  const useAttributeExchange =
    ecPluginFeatures.attributeExchange() &&
    !readOnly &&
    (launchDarklyFeatures.useAttributeExchange as boolean);

  const checkoutHidden = useMemo(() => {
    return (
      isCheckOutHidden(
        readOnly,
        items,
        pwDriveData.pwDriveSyncDisabled,
        ecPluginFeatures.masterReferenceFileOperations()
      ) || currentAppView.type == 'RecycleBin'
    );
  }, [
    currentAppView.type,
    ecPluginFeatures,
    items,
    pwDriveData.pwDriveSyncDisabled,
    readOnly
  ]);

  const checkOutItem = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.CheckOut'),
      icon: <SvgCheckOut />,
      disabled:
        checkoutHidden ||
        isCheckOutDisabled(
          items,
          pwDriveData.pwDriveSyncDisabled,
          ecPluginFeatures.masterReferenceFileOperations(),
          allItemsExistInConnection
        ),
      hidden: checkoutHidden,
      startGroup: true,
      onClick: () =>
        void checkOut(
          items,
          httpService,
          refresh,
          refreshOutToMe,
          contextId ?? '',
          pwDriveData,
          progressManager,
          primaryModal.open,
          primaryModal.close,
          trackFeature,
          userGuid,
          consumerApp,
          useAttributeExchange
        )
    }),
    [
      checkoutHidden,
      useAttributeExchange,
      items,
      pwDriveData,
      ecPluginFeatures,
      allItemsExistInConnection,
      httpService,
      refresh,
      refreshOutToMe,
      contextId,
      progressManager,
      primaryModal.open,
      primaryModal.close,
      trackFeature,
      userGuid,
      consumerApp
    ]
  );

  return checkOutItem;
}
