import type { PWDataItem } from '@bentley/pw-api';
import type {
  OneDriveFileStatusManager,
  OneDriveUserData
} from '../../../context';
import { checkForDriveErrors } from '../graphErrorSummary';

export type GraphErrorSummaryPluginItem = {
  instanceId: string;
  type: 'warning' | 'negative';
  displayOutTo?: boolean;
  errorTextKey?: string;
  participants?: OneDriveUserData[];
};

export async function checkForPluginDriveErrors(
  files: {
    instanceId: string;
    isCheckedOut: boolean;
    isLocked: boolean;
  }[],
  driveStatusManager: OneDriveFileStatusManager,
  withCheckIn = false
): Promise<GraphErrorSummaryPluginItem[]> {
  const dataItems = files.map(
    (file) =>
      ({
        instanceId: file.instanceId,
        IsCheckedOut: file.isCheckedOut,
        IsLocked: file.isLocked
      } as PWDataItem)
  );

  const summaries = await checkForDriveErrors(
    dataItems,
    driveStatusManager,
    withCheckIn
  );

  return summaries.map(({ item, ...summary }) => ({
    instanceId: item.instanceId,
    ...summary
  }));
}
