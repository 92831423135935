import type {
  HttpService,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { parseInstanceRelationshipInstances } from '@bentley/pw-api';
import type { View } from '../useViews';
import { folderViewToView, instanceToFolderView } from './instanceToFolderView';

export async function getSavedSearchView(
  savedSearchId: string,
  httpService: HttpService,
  options?: RequestOptions
): Promise<View> {
  const query = buildQuery(savedSearchId);
  const response = await httpService.get(query, options);
  const view = await parseViewsResponse(response);
  return view;
}

function buildQuery(savedSearchId: string): string {
  const select =
    'SavedSearchDefaultDocumentListView-forward-View.*,SavedSearchDefaultDocumentListView-forward-View/ViewColumn-forward-Column.*';
  const id = Number.parseInt(savedSearchId);
  return `PW_WSG/savedsearchdefinition/${id}?$select=${select}`;
}

async function parseViewsResponse(response: Response): Promise<View> {
  if (!response.ok) {
    throw new Error('Error fetching columns');
  }

  const data = (await response.json()) as WSGInstancesResponse;
  if (data.errorId && data.errorId == 'ClassNotFound') {
    throw new Error(data.errorId);
  }

  if (!data.instances || !data.instances.length) {
    throw new Error('No definition was retrieved for the Saved Search');
  }

  // Default view may be a personal view instead of global.
  if (!data.instances[0].relationshipInstances?.length) {
    throw new Error('No access to view');
  }

  const relatedInstance = parseInstanceRelationshipInstances(data.instances[0]);

  const folderView = instanceToFolderView(relatedInstance[0]);
  const view = folderViewToView(folderView);
  return view;
}
