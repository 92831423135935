import React, { useCallback, useEffect } from 'react';
import { Alert } from '@itwin/itwinui-react';
import { useConnectionAuth, usePluginContext } from '../../../context';
import { useLocalStorage } from '../../../hooks/useStorage';
import { t } from '../../../services/translation';

export function DriveBanner(): JSX.Element | null {
  const { userName } = useConnectionAuth();
  const {
    pwDriveData: {
      driveDownloadUrl,
      driveLatestVersion,
      driveMessageText,
      driveVersionType,
      isCurrentConnectionSynced,
      isCurrentProjectSynced,
      validDriveUser
    }
  } = usePluginContext();

  const [showBanner, setShowBanner] = useLocalStorage(
    'browserBannerDrive',
    true
  );

  function bannerMessage(): string {
    if (showUserMismatchBanner()) {
      return driveMismatchUserMessage();
    }
    return driveVersionDownloadMessage();
  }

  function driveMismatchUserMessage(): string {
    switch (validDriveUser) {
      case 'UserMismatch':
        return t('PWDrive.Notifications.UserMisMatch');
      case 'SameUser':
      default:
        return '';
    }
  }

  function driveVersionDownloadMessage(): string {
    if (driveMessageText == '') {
      switch (driveVersionType) {
        case 'Obsolete':
          return t('PWDrive.Notifications.DriveObsolete');

        case 'OldValidVersion':
          return t('PWDrive.Notifications.NewerVersionAvailable');

        default:
          return '';
      }
    } else {
      return driveMessageText;
    }
  }

  function closeBanner(): void {
    setShowBanner(false);
  }

  const showVersionBanner = useCallback((): boolean => {
    if (
      driveVersionType == 'Latest' ||
      driveVersionType == 'InvalidVersion' ||
      driveVersionType == ''
    ) {
      return false;
    }
    return true;
  }, [driveVersionType]);

  const showUserMismatchBanner = useCallback((): boolean => {
    if (validDriveUser != 'UserMismatch') {
      return false;
    }
    return true;
  }, [validDriveUser]);

  function showDriveBanner(): boolean {
    if (!isCurrentProjectSynced) {
      return false;
    }
    if (!isCurrentConnectionSynced) {
      return false;
    }

    if (!showBanner) {
      return false;
    }

    return showVersionBanner() || showUserMismatchBanner();
  }

  function showDownloadDrive(): boolean {
    if (driveVersionType == 'Latest') {
      return false;
    }

    if (validDriveUser != 'SameUser') {
      return false;
    }

    if (!driveDownloadUrl?.includes('bentley.com')) {
      return false;
    }

    if (!driveLatestVersion) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (showUserMismatchBanner() || showVersionBanner()) {
      setShowBanner(true);
    }
  }, [
    driveVersionType,
    setShowBanner,
    showUserMismatchBanner,
    showVersionBanner,
    userName,
    validDriveUser
  ]);

  if (!showDriveBanner()) {
    return null;
  }

  return (
    <Alert.Wrapper type="warning" className="main-page-alert">
      <Alert.Icon />
      <Alert.Message data-testid="message-text">
        {bannerMessage()}
        {showDownloadDrive() && (
          <Alert.Action href={driveDownloadUrl} target="_blank">
            {t('PWDrive.DownloadDrive', { version: driveLatestVersion })}
          </Alert.Action>
        )}
      </Alert.Message>
      <Alert.CloseButton onClick={closeBanner} />
    </Alert.Wrapper>
  );
}
