import { useMemo } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { productionEnvironment } from '../../services/environment';

export function useLinkBaseUrl(buddiRegionCode: string): string {
  const buddiUrl = useBuddi('ProjectWiseWebLinks.URL', buddiRegionCode);
  const pwWacUrl = useBuddi('ProjectwiseWac.URL', buddiRegionCode);

  const linkBaseUrl = useMemo((): string => {
    if (!buddiUrl || !pwWacUrl) {
      return '';
    }

    if (productionEnvironment() == 'localhost') {
      return `${location.origin}/`;
    }

    if (productionEnvironment() == 'release') {
      return buddiUrl.replace('qa-', 'qa-release-');
    }

    return buddiUrl;
  }, [buddiUrl, pwWacUrl]);

  return linkBaseUrl;
}
