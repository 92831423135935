import React, { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgDelete } from '@itwin/itwinui-icons-react';
import { useRecycleBinDelete } from '../../../../actions/recycleBinDelete';
import { useAppViewContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useRecycleBinDeleteItemAction(items: PWItem[]): MenuItemAction {
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const recycleBinDeleteWorkflow = useRecycleBinDelete();

  const onDeleteClick = useCallback((): void => {
    void recycleBinDeleteWorkflow(items);
  }, [items, recycleBinDeleteWorkflow]);

  const deleteItem = useMemo(
    (): MenuItemAction => ({
      title: t('RecycleBin.PermanentlyDelete'),
      icon: <SvgDelete />,
      onClick: onDeleteClick,
      disabled: !items.length,
      hidden: currentAppView.type != 'RecycleBin'
    }),
    [currentAppView.type, items.length, onDeleteClick]
  );

  return deleteItem;
}
