import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Dialog } from '@itwin/itwinui-react';
import { useNavigationContext } from '../../../context';
import { t } from '../../../services/translation';
import { DocCodePreviewContainer } from '../common/docCodePreviewContainer';
import { DocCodeFormContainer } from '../form/docCodeFormContainer';
import {
  formValidState,
  updatingReloadPropertiesState,
  updatingTriggeredPropertiesState
} from '../form/state';
import { saveInProgressState } from '../save/state';
import { useSave } from '../save/useSave';
import {
  currentEnvironmentInstanceState,
  editingDocCodeFormState,
  originalEnvironmentInstanceState
} from '../state/state';

export function DocCodeModalLayout(): JSX.Element {
  const { primaryModal } = useNavigationContext();

  const { saveInstance } = useSave(primaryModal.close);

  const [editingDocCodeForm, setEditingDocCodeForm] = useRecoilState(
    editingDocCodeFormState
  );
  const saveInProgress = useRecoilValue(saveInProgressState);
  const [currentEnvironmentInstance, setCurrentEnvironmentInstance] =
    useRecoilState(currentEnvironmentInstanceState);
  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );
  const updatingTriggeredProperties = useRecoilValue(
    updatingTriggeredPropertiesState
  );
  const updatingReloadProperties = useRecoilValue(
    updatingReloadPropertiesState
  );
  const formValid = useRecoilValue(formValidState);

  useEffect(() => {
    setEditingDocCodeForm(true);
  }, [setEditingDocCodeForm]);

  const allowSave = useMemo((): boolean => {
    if (!editingDocCodeForm || saveInProgress) {
      return false;
    }

    if (updatingTriggeredProperties || updatingReloadProperties) {
      return false;
    }

    if (originalEnvironmentInstance == currentEnvironmentInstance) {
      return false;
    }

    if (!formValid) {
      return false;
    }

    return true;
  }, [
    currentEnvironmentInstance,
    editingDocCodeForm,
    formValid,
    originalEnvironmentInstance,
    saveInProgress,
    updatingReloadProperties,
    updatingTriggeredProperties
  ]);

  function onSave(): void {
    void saveInstance();
  }

  function onCancel(): void {
    setCurrentEnvironmentInstance(originalEnvironmentInstance);
    setEditingDocCodeForm(false);
    primaryModal.close();
  }

  return (
    <>
      <DocCodeFormContainer />
      <DocCodePreviewContainer />
      <Dialog.ButtonBar className="dcw-wizard-progression">
        <Button
          styleType="high-visibility"
          autoFocus={true}
          onClick={onSave}
          disabled={!allowSave}
        >
          {t('Generic.Save')}
        </Button>
        <Button onClick={onCancel}>{t('Generic.Cancel')}</Button>
      </Dialog.ButtonBar>
    </>
  );
}
