import React from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { Radio } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../../services/translation';
import type { MappingDataRecord } from '../iModelMapTable';

type SheetCellProps = {
  tableDisabled?: boolean;
  onSheetCellClick?: (
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) => void;
} & CellProps<MappingDataRecord>;

export function SheetCell({
  row,
  tableDisabled,
  onSheetCellClick
}: SheetCellProps): JSX.Element {
  const { isSheet, isExistingBridge, DataItem } = row.original;

  return (
    <Radio
      disabled={tableDisabled || isExistingBridge}
      label={t('IModel.Sheet')}
      name={`mappingType--${DataItem.instanceId}`}
      checked={isSheet}
      onChange={(event) => onSheetCellClick?.(event, DataItem)}
      data-testid={'input-row-sheet'}
    />
  );
}
