import {
  addWildcardToQueryEnds,
  escapeFTRSearchString,
  escapeSearchString
} from '../../../services/search';

export function formatFTRQuery(query: string): string {
  query = query.toLowerCase();
  query = addWildcardToQueryEnds(query);
  query = escapeSearchString(query);
  query = escapeFTRSearchString(query);
  return query;
}
