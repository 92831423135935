import type { ProviderProps } from 'react';
import React, { createContext, useContext } from 'react';
import type { OIDCFunctions } from '../../hooks/useOidc';
import { useOidc } from '../../hooks/useOidc';

const Context = createContext<OIDCFunctions | undefined>(undefined);

export function OidcProvider({
  children
}: ProviderProps<undefined>): JSX.Element {
  const oidc = useOidc();

  return <Context.Provider value={oidc}>{children}</Context.Provider>;
}

export function useOidcContext(): OIDCFunctions {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useOidcContext must be used within a OidcProvider');
  }

  return context;
}
