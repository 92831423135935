import React from 'react';
import { SvgDelete } from '@itwin/itwinui-icons-react';
import { Button, Flex, Text } from '@itwin/itwinui-react';
import { useAppViewContext } from '../../context';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';

export function EmptyRecycleBinState(): JSX.Element {
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();

  function onCloseEmptyState(): void {
    setAppView('FolderNavigation');
  }

  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgDelete className="ets-icon" />
      <Text>{t('RecycleBin.NoDeletedItems')}</Text>
      <Button
        styleType="high-visibility"
        onClick={onCloseEmptyState}
        onKeyPress={keyPressAsClick(onCloseEmptyState)}
      >
        {t('Generic.Close')}
      </Button>
    </Flex>
  );
}
