import { useCallback } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { pwConstants } from '@bentley/pw-api';
import type { FormManager, InputChange, InputError } from '../../hooks/useForm';
import { useForm } from '../../hooks/useForm';
import { useNonRenderingState } from '../../hooks/useNonRenderingState';
import { t } from '../../services/translation';
import { useVersionNames } from './useVersionNames';

type NewVersionForm = {
  versionName?: string;
};

export function compressWhitespace(value: string): string {
  return value.trim().replace(/\s\s+/g, ' ');
}

export function useNewVersionForm(
  items: PWDataItem[]
): FormManager<NewVersionForm> {
  const item = items[0];

  const versionNames = useVersionNames(items);
  // Use form won't update callbacks, so need to wrap state in a ref
  const versionNamesRef = useNonRenderingState(versionNames);
  const validateInput = useCallback(
    ({ value }: InputChange<NewVersionForm>): InputError<NewVersionForm> => {
      if (!value) {
        return { name: 'versionName', value: '', type: '' };
      }

      value = compressWhitespace(value);

      if (!value.length) {
        const errorMessage = t('Version.NameCannotBeEmpty');
        return {
          name: 'versionName',
          value: errorMessage,
          type: 'error'
        };
      }

      const isVersionInUse = (item: PWDataItem, versionName: string) => {
        const versionNamesRefList = [...versionNamesRef.current];
        const allVersionsOfFile = [
          ...versionNamesRefList.map((version) => version.trim().toLowerCase()),
          item.Version.trim().toLowerCase()
        ];
        return allVersionsOfFile.includes(versionName.trim().toLowerCase());
      };

      if (items.length == 1) {
        if (isVersionInUse(item, value)) {
          const errorMessage = t('Version.NameInUse');
          return {
            name: 'versionName',
            value: errorMessage,
            type: 'error'
          };
        }
      } else {
        // treat existing version input as blank
        if (items.some((item) => isVersionInUse(item, (value = value || '')))) {
          formManager.setData({ name: 'versionName', value: '' });
          return { name: 'versionName', value: '', type: '' };
        }
      }

      if (value.length > pwConstants.maxVersionLength) {
        return {
          name: 'versionName',
          value: `${t('Generic.ContentLimitedTo', {
            count: pwConstants.maxVersionLength,
            excess: value.length - pwConstants.maxVersionLength
          })}`,
          type: 'error'
        };
      }

      return { name: 'versionName', value: '', type: '' };
    },
    [item.Version, versionNamesRef, versionNames]
  );

  function isFormValid({ versionName }: NewVersionForm): boolean {
    if (!versionName || !versionName.length) {
      return true;
    }
    return (
      compressWhitespace(versionName).length <= pwConstants.maxVersionLength
    );
  }

  const formManager = useForm<NewVersionForm>({
    inputs: {},
    errors: {},
    validateInput,
    isFormValid
  });

  return formManager;
}
