import { t } from '../../../services/translation';
import {
  notifyDownloadDrive,
  notifyInternalServerErrorDrive,
  notifyProjectSyncFailure
} from '../../sync/notifications';
import type { SyncProjectDriveResponseBody } from '../pwDrive.utils';

export async function handleSyncResponse(response: Response): Promise<void> {
  if (!response) {
    return;
  }

  if (response.status == 401) {
    notifyInternalServerErrorDrive();
  } else if (response.status == 503) {
    notifyDownloadDrive();
  } else if (response.status == 500) {
    const syncFailureMessage = await parseSyncError(response);
    notifyProjectSyncFailure(syncFailureMessage);
  }
}

async function parseSyncError(response: Response): Promise<string> {
  try {
    const driveResponse =
      (await response.json()) as SyncProjectDriveResponseBody;
    return driveResponse.message;
  } catch {
    return t('PWDrive.SyncError');
  }
}
