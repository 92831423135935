import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import {
  allowDesignReview,
  designReviewTitle,
  showDesignReviewMenu
} from '../../../../actions/designReview';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgProjectWise } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useDesignReviewAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { isAdmin } = useConnectionAuth();
  const { connection, openDesignReview, readOnly, consumerApp } =
    usePluginContext();
  const { goToPWE, azureServerAccessible } = useEnvironmentContext();

  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const showDesignReview = useMemo((): boolean => {
    if (!openDesignReview) {
      return false;
    }

    if (!launchDarklyFeatures.document3DDesignReviewIntegration) {
      return false;
    }

    if (readOnly) {
      return false;
    }

    if (!showDesignReviewMenu(items)) {
      return false;
    }

    if (
      consumerApp != 'ProjectWise 365' &&
      consumerApp != 'ProjectWise 365 in Teams'
    ) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    if (!isAdmin && !goToPWE.allowed) {
      return false;
    }

    return true;
  }, [
    openDesignReview,
    launchDarklyFeatures,
    readOnly,
    items,
    consumerApp,
    currentAppView.type,
    isAdmin,
    goToPWE.allowed
  ]);

  const itemTooltip = useMemo((): string => {
    if (!azureServerAccessible) {
      return t('MenuItem.ConnectionNotAccessible');
    }

    return designReviewTitle(filterDataItems(items));
  }, [azureServerAccessible, items]);

  const designReviewAction = useMemo(
    (): MenuItemAction => ({
      title: designReviewTitle(filterDataItems(items)),
      icon: <SvgProjectWise />,
      itemTooltip: itemTooltip,
      onClick: () => openDesignReview?.(connection, items),
      disabled:
        !azureServerAccessible || !allowDesignReview(filterDataItems(items)),
      hidden: !showDesignReview
    }),
    [
      azureServerAccessible,
      connection,
      itemTooltip,
      items,
      openDesignReview,
      showDesignReview
    ]
  );

  return designReviewAction;
}
