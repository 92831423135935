import { useCallback, useEffect, useMemo, useState } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import { useNavigationContext } from '../../../context';
import type { AppViewSetting } from '../../useAppView';
import { useAdvancedSearchBreadcrumbs } from './useAdvancedSearchBreadcrumbs';
import { useSavedSearchBreadcrumbs } from './useSavedSearchBreadcrumbs';
import { useSearchResultBreadcrumb } from './useSearchResultBreadcrumb';

// Todo: this would be better thought of as custom breadcrumbs, rather than search breadcrumbs
// For most of these, we need to update the breadcrumbs to show the connection root and then
// the type (e.g., SavedSearch, OutToMe, RecycleBin)
// For basic search we should show the existing breadcrumbs, and then the search breadcrumb
// For advanced search we should show the root to the search folder, and then the search breadcrumb

export type SearchBreadcrumbManager = {
  breadcrumbs: PWParentType[];
  updateSearchCount: (items: PWItem[]) => void;
};

export function useSearchBreadcrumbs(
  currentAppView: AppViewSetting
): SearchBreadcrumbManager {
  const {
    breadcrumbManager: {
      breadcrumbs: navigationBreadcrumbs,
      connectionBreadcrumb
    },
    searchState: { savedSearch }
  } = useNavigationContext();

  const [itemCount, setItemCount] = useState<number>();
  const searchResultBreadcrumb = useSearchResultBreadcrumb(
    currentAppView,
    itemCount
  );
  const savedSearchBreadcrumbs = useSavedSearchBreadcrumbs();
  const advancedSearchBreadcrumbs = useAdvancedSearchBreadcrumbs();

  const breadcrumbs = useMemo((): PWParentType[] => {
    if (currentAppView.type == 'AdvancedSearch') {
      return [...advancedSearchBreadcrumbs, searchResultBreadcrumb];
    } else if (currentAppView.type == 'BasicSearch') {
      return [...navigationBreadcrumbs, searchResultBreadcrumb];
    } else if (savedSearch && currentAppView.type == 'SavedSearch') {
      return [...savedSearchBreadcrumbs, searchResultBreadcrumb];
    } else {
      return [connectionBreadcrumb, searchResultBreadcrumb];
    }
  }, [
    currentAppView.type,
    savedSearch,
    advancedSearchBreadcrumbs,
    searchResultBreadcrumb,
    navigationBreadcrumbs,
    savedSearchBreadcrumbs,
    connectionBreadcrumb
  ]);

  const updateSearchCount = useCallback(
    (items: PWItem[]): void => {
      if (currentAppView.type == 'FolderNavigation') {
        setItemCount(undefined);
        return;
      }
      setItemCount(items.length);
    },
    [currentAppView.type]
  );

  useEffect(() => {
    setItemCount(undefined);
  }, [currentAppView]);

  const searchBreadcrumbManager = useMemo(
    (): SearchBreadcrumbManager => ({
      breadcrumbs,
      updateSearchCount
    }),
    [breadcrumbs, updateSearchCount]
  );

  return searchBreadcrumbManager;
}
