import './fileSizeFilter.css';

import React, { useMemo, useState } from 'react';
import {
  BaseFilter,
  FilterButtonBar,
  InputGroup,
  LabeledInput,
  Select
} from '@itwin/itwinui-react';
import type { TableFilterProps } from '@itwin/itwinui-react/react-table';
import { t } from '../../../services/translation';

type FileSizeFilterValue = [number?, number?] | undefined;
type FileSizeUnit = 'KB' | 'MB' | 'GB';

export function FileSizeFilter({
  column,
  clearFilter,
  setFilter
}: TableFilterProps<Record<string, unknown>>): JSX.Element {
  const [fromValue, setFromValue] = useState<number | undefined>(
    initialValue(0).value
  );

  const [fromUnit, setFromUnit] = useState<FileSizeUnit>(initialValue(0).unit);

  const [toValue, setToValue] = useState<number | undefined>(
    initialValue(1).value
  );

  const [toUnit, setToUnit] = useState<FileSizeUnit>(initialValue(1).unit);

  const translatedLabels = useMemo(
    () => ({
      filter: t('Search.Filter'),
      clear: t('Search.Clear'),
      from: t('Generic.From'),
      to: t('Generic.To')
    }),
    []
  );

  function initialValue(index: 0 | 1): {
    value: number | undefined;
    unit: FileSizeUnit;
  } {
    const filterValue = valueFromFilter(index);

    if (!filterValue || filterValue == Number.MAX_SAFE_INTEGER) {
      return { value: undefined, unit: 'KB' };
    }

    const valueInKB = filterValue / 1024;

    if (valueInKB < 1024) {
      return { value: Number(valueInKB.toFixed(2)), unit: 'KB' };
    }

    const valueInMB = valueInKB / 1024;

    if (valueInMB < 1024) {
      return { value: Number(valueInMB.toFixed(2)), unit: 'MB' };
    }

    return { value: Number((valueInMB / 1024).toFixed(2)), unit: 'GB' };
  }

  function valueFromFilter(index: 0 | 1): number | undefined {
    const filterValue = (column.filterValue as FileSizeFilterValue)?.[index];

    return filterValue;
  }

  function setFilterValue() {
    const from = (fromValue ?? 0) * toBytes(fromUnit);
    let to = (toValue ?? 0) * toBytes(toUnit);

    if (toValue === undefined) {
      to = Number.MAX_SAFE_INTEGER;
    }

    setFilter([from, to]);
  }

  function toBytes(fileSize: FileSizeUnit): number {
    if (fileSize == 'KB') {
      return 1024;
    }

    if (fileSize == 'MB') {
      return 1024 * 1024;
    }

    return 1024 * 1024 * 1024;
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      setFilterValue();
    }
  }

  return (
    <BaseFilter>
      <InputGroup
        displayStyle="inline"
        className="pw-file-size-filter-group"
        innerProps={{ className: 'pw-file-size-input-group' }}
      >
        <LabeledInput
          label={translatedLabels.from}
          autoFocus={true}
          value={fromValue}
          onChange={(e) => setFromValue(Number(e.target.value))}
          onKeyDown={onKeyDown}
          type="number"
          displayStyle="inline"
          data-testid="file-size-from"
          wrapperProps={{ className: 'pw-file-size-filter-value' }}
        />
        <Select
          options={[
            { value: 'KB', label: 'KB' },
            { value: 'MB', label: 'MB' },
            { value: 'GB', label: 'GB' }
          ]}
          value={fromUnit}
          className="pw-file-size-filter-unit"
          onChange={setFromUnit}
          data-testid="file-size-from-unit"
        />
      </InputGroup>
      <InputGroup
        displayStyle="inline"
        className="pw-file-size-filter-group"
        innerProps={{ className: 'pw-file-size-input-group' }}
      >
        <LabeledInput
          label={translatedLabels.to}
          value={toValue}
          onChange={(e) => setToValue(Number(e.target.value))}
          onKeyDown={onKeyDown}
          type="number"
          displayStyle="inline"
          data-testid="file-size-to"
          wrapperProps={{ className: 'pw-file-size-filter-value' }}
        />
        <Select
          options={[
            { value: 'KB', label: 'KB' },
            { value: 'MB', label: 'MB' },
            { value: 'GB', label: 'GB' }
          ]}
          value={toUnit}
          className="pw-file-size-filter-unit"
          onChange={setToUnit}
          data-testid="file-size-to-unit"
        />
      </InputGroup>
      <FilterButtonBar
        setFilter={setFilterValue}
        clearFilter={clearFilter}
        translatedLabels={translatedLabels}
      />
    </BaseFilter>
  );
}
