import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { SortingRule } from '@itwin/itwinui-react/react-table';
import { useFeatureTracking, usePluginContext } from '../../../context';
import { useSessionStorage } from '../../../hooks/useStorage';

type TableSorting = {
  sortingRules: SortingRule<PWItem>[];
  saveSortingRules: (rules: SortingRule<PWItem>[]) => void;
};

export function useTableSorting(): TableSorting {
  const { connection } = usePluginContext();
  const { trackFeature } = useFeatureTracking();

  const [sortingRules, setSortingRules] = useSessionStorage<
    SortingRule<PWItem>[]
  >(`sortingRules:${connection.Id}`, []);

  const saveSortingRules = useCallback(
    (rules: SortingRule<PWItem>[]): void => {
      trackFeature('GRID_SORT_COLUMNS');
      setSortingRules(rules);
    },
    [setSortingRules, trackFeature]
  );

  const tableSorting = useMemo(
    (): TableSorting => ({ sortingRules, saveSortingRules }),
    [saveSortingRules, sortingRules]
  );

  return tableSorting;
}
