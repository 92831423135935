import React, { useMemo } from 'react';
import { SvgBlank, SvgCheckmark } from '@itwin/itwinui-icons-react';
import {
  useEnvironmentContext,
  useNavigationContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useFTRSearchAction(): MenuItemAction {
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { ftr, setFtr }
  } = useNavigationContext();

  const showFTRSearch = useMemo((): boolean => {
    if (ecPluginFeatures.allContentSearch()) {
      return false;
    }

    if (!ecPluginFeatures.fullTextSearch()) {
      return false;
    }

    return true;
  }, [ecPluginFeatures]);

  const ftrSearch = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.FullTextSearch'),
      icon: ftr ? <SvgCheckmark data-testid="ftr-check" /> : <SvgBlank />,
      hidden: !showFTRSearch,
      onClick: () => setFtr(true)
    }),
    [ftr, setFtr, showFTRSearch]
  );

  return ftrSearch;
}
