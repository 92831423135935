import { atom, selector } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { getProjectDefaultEnvironmentInstance } from '../../../actions/formRendering';
import type { ECPluginVersion } from '../../../hooks/useECPluginVersion';
import { mergePropertiesIntoInstance } from '../properties';
// import { itemsToCopyState } from '../wizard/state';
import { logError } from '../../../hooks/useErrorLogging';
import { tryGetDCWHttpService } from './httpService';

export const ecPluginVersionState = atom<ECPluginVersion>({
  key: 'ecPluginVersionState',
  default: null
});

export const synchronousModeState = atom<boolean>({
  key: 'synchronousModeState',
  default: false
});

export const projectIdState = atom<string>({
  key: 'projectIdState',
  default: ''
});

export const defaultEnvironmentInstanceStateAsync =
  selector<WSGInstance | null>({
    key: 'defaultEnvironmentInstanceStateAsync',
    get: async ({ get }) => {
      try {
        const presetEnvironmentInstance = get(presetEnvironmentInstanceState);
        // const documentsToCopy = get(itemsToCopyState);
        const projectId = get(projectIdState);
        const includeDocCodeDefaults = true;
        const httpService = tryGetDCWHttpService();

        if (!projectId || !httpService) {
          return null;
        }

        const environmentInstances = await getProjectDefaultEnvironmentInstance(
          projectId,
          includeDocCodeDefaults,
          httpService
        );

        if (!environmentInstances?.length) {
          return presetEnvironmentInstance;
        }

        // Commenting out logic to transfer attributes from old doc to new copy
        // For now, we are mimicking PWE, where copies in the DCW start with the default environment instance
        // We might want to change this back in the future, so leaving commented out

        // if (documentsToCopy?.length) {
        //   const currentEnvironment = environmentInstances[0].className;
        //   const copyDocEnvironment = parseRelatedEnvironmentSheets(
        //     documentsToCopy[0]
        //   )[0]?.className;
        //   if (
        //     currentEnvironment &&
        //     copyDocEnvironment &&
        //     currentEnvironment == copyDocEnvironment
        //   ) {
        //     const environmentSheets = parseRelatedEnvironmentSheets(
        //       documentsToCopy[0]
        //     );

        //     if (!environmentSheets.length) {
        //       return presetEnvironmentInstance;
        //     }

        //     const properties =
        //       environmentSheets[environmentSheets.length - 1].properties;
        //     return properties
        //       ? mergePropertiesIntoInstance(environmentInstances[0], properties)
        //       : environmentInstances[0];
        //   }
        // }

        return presetEnvironmentInstance?.properties
          ? mergePropertiesIntoInstance(
              environmentInstances[0],
              presetEnvironmentInstance.properties
            )
          : environmentInstances[0];
      } catch (e) {
        logError('Error retrieving environment instance', { error: e });
        return null;
      }
    }
  });

export const currentEnvironmentInstanceState = atom<WSGInstance | null>({
  key: 'currentEnvironmentInstanceState',
  default: defaultEnvironmentInstanceStateAsync
});

export const presetEnvironmentInstanceState = atom<WSGInstance | null>({
  key: 'presetEnvironmentInstanceState',
  default: null
});
