import type {
  HttpService,
  PWItem,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import {
  filterFileTypes,
  itemIsDataItem,
  itemIsProject,
  parseResponseInstances
} from '@bentley/pw-api';
import { formatFileSize } from '../../../services/stringFormatter';

export type DescendantProperties = {
  FolderCount: string;
  DocumentCount: string;
  DiskUsage: string;
};

export async function getFolderDescendantProperties(
  project: PWProject,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<DescendantProperties | undefined> {
  try {
    const query = descendantsQuery(project);
    const response = await httpService.get(query, httpOptions);
    const items = await parseResponseInstances<PWItem>(response);
    const descendants = items.filter(
      ({ instanceId }) => instanceId != project.instanceId
    );

    const FolderCount = String(descendants.filter(itemIsProject).length);
    const DocumentCount = String(descendants.filter(itemIsDataItem).length);
    const totalFileSize = filterFileTypes(descendants).reduce(
      (fileSize, item) => {
        return fileSize + +item.FileSize;
      },
      0
    );
    const DiskUsage = formatFileSize(totalFileSize) || '0.00 KB';

    return { FolderCount, DocumentCount, DiskUsage };
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

function descendantsQuery(project: PWProject): string {
  if (!project.instanceId) {
    return 'PW_WSG/Project!poly,Document!poly?$select=$id,FileSize';
  }

  return `PW_WSG/Project!poly,Document!poly?$select=$id,FileSize&api.ancestor=PW_WSG.Project-${project.instanceId}`;
}
