import { getConflictingFiles } from '../../../actions/conflictResolution';
import type { CustomFile } from '../../../actions/upload';
import { t } from '../../../services/translation';
import { getDCWHttpService } from '../state';

/**
 * Some datasources will return a 400 error if trying to upload a conflicting name,
 * but others will create the item as a new version. This circumvents that behavior
 * by mimicking a 409 error
 */
export async function throwIfNameConflicts(
  name: string,
  fileName: string,
  projectId: string
): Promise<void> {
  const httpService = getDCWHttpService();

  const conflicts = await getConflictingFiles(
    projectId,
    [name, fileName],
    httpService
  );

  if (conflicts.length) {
    throw conflictStatus();
  }
}

export async function throwIfMultiNameConflicts(
  name: string,
  fileName: string,
  files: CustomFile[],
  projectId: string
): Promise<void> {
  if (!files.length) {
    return;
  }

  const httpService = getDCWHttpService();

  const fileNames = files.map((file) => file.name).filter((name) => name);

  const fileCustomNames = files
    .map((file) => file.customName)
    .filter((name) => name) as string[];

  const fileDocumentNames = files
    .map((file) => file.documentName)
    .filter((name) => name) as string[];

  const uploadNames = [
    name,
    fileName,
    ...fileNames,
    ...fileCustomNames,
    ...fileDocumentNames
  ];

  const conflicts = await getConflictingFiles(
    projectId,
    uploadNames,
    httpService
  );

  if (conflicts.length) {
    throw conflictStatus();
  }
}

function conflictStatus(): Response {
  const errorMessage = t('DocumentCreation.FileWithSameName');
  return new Response(JSON.stringify({ errorMessage }), { status: 409 });
}
