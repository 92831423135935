import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import type { ItemIcon } from '@bentley/pw-file-icons';
import { getItemDriveLock, isValidDriveData } from '../../actions/drive';
import {
  useEnvironmentContext,
  useGraphApiContext,
  usePluginContext
} from '../../context';

type Badge = Parameters<typeof ItemIcon>[0]['badge'];

export type GetItemStatusBadge = (item: PWItem | PWItem[]) => Badge;

export function useItemStatusBadge(): GetItemStatusBadge {
  const { readOnly, pwDriveData } = usePluginContext();
  const { checkCoAuthoringSessionExists } = useGraphApiContext();

  const { office365CoAuthoringSettings } = useEnvironmentContext();

  const checkedOutInOneDrive = useCallback(
    (item: PWItem): boolean => {
      if (!item.instanceId) {
        return false;
      }

      return checkCoAuthoringSessionExists(item);
    },
    [checkCoAuthoringSessionExists]
  );

  const getItemStatusBadge = useCallback(
    (item: PWItem | PWItem[]): Badge => {
      if (Array.isArray(item)) {
        return undefined;
      }

      if (itemIsProject(item) || readOnly) {
        return undefined;
      }

      if (
        checkedOutInOneDrive(item) &&
        office365CoAuthoringSettings.isCoAuthoringEnabled &&
        item.IsLocked
      ) {
        if (item.StateString == 'CheckedOut') {
          return 'userBlocked';
        }

        return 'user';
      }

      if (item.StateString == 'Locked') {
        return 'locked';
      }

      if (item.StateString == 'ReadOnly') {
        return 'readOnly';
      }

      if (item.StateString == 'Final') {
        return 'final';
      }

      if (item.StateString == 'CheckedOut' && isValidDriveData(pwDriveData)) {
        const driveInfo = getItemDriveLock(item);

        if (driveInfo?.lockedViaAutoCheckout) {
          return 'inUseHollow';
        }

        if (driveInfo?.locked === false) {
          return 'inUse';
        }
      }

      if (item.StateString == 'CheckedOut') {
        return 'checkedOut';
      }

      return undefined;
    },
    [
      checkedOutInOneDrive,
      office365CoAuthoringSettings.isCoAuthoringEnabled,
      pwDriveData,
      readOnly
    ]
  );

  return getItemStatusBadge;
}
