import { atom } from 'recoil';
import type { DCWDocProperties } from './propertiesSection';

export const propertiesFormValidState = atom<boolean>({
  key: 'propertiesFormValidState',
  default: false
});

export const docPropertiesState = atom<DCWDocProperties>({
  key: 'docPropertiesState',
  default: {
    name: '',
    fileName: '',
    description: '',
    version: '',
    isLocked: false
  }
});

export const defaultDocumentNameState = atom<string>({
  key: 'defaultDocumentNameState',
  default: ''
});

export const nameChangedState = atom<boolean>({
  key: 'nameChangedState',
  default: false
});

export const defaultDocumentDescriptionState = atom<string>({
  key: 'defaultDocumentDescriptionState',
  default: ''
});

export const descriptionChangedState = atom<boolean>({
  key: 'descriptionChangedState',
  default: false
});

export const originalFileNameState = atom<string>({
  key: 'originalFileNameState',
  default: ''
});

export const fileNameChangedState = atom<boolean>({
  key: 'fileNameChangedState',
  default: false
});

export const defaultVersionState = atom<string>({
  key: 'defaultVersionState',
  default: ''
});

export const allowFileRenameState = atom<boolean>({
  key: 'allowFileRenameState',
  default: false
});
