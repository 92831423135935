import type { SVGProps } from 'react';
import React from 'react';

export const SvgCheckIn = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M11.996 6.007H5.98V16H0V0h8v4h3.997zM8.996 0v3h3zm1 10.706l3.297 3.297.707-.707-3.296-3.297h2.292V9h-4v4h1zM16 7.002V16H7V7.002zm-1 1H8V15h7z"
    />
  </svg>
);
