import type { PWItem } from '@bentley/pw-api';
import {
  itemIsDataItem,
  itemIsFinal,
  itemIsLatestVersion,
  itemIsLockedOrCheckedOut,
  itemIsProject,
  itemIsReadOnly
} from '@bentley/pw-api';
import type { MenuItemAction } from '../../components/toolbar';
import type { Command } from './wreCommands';

// check condition enable/disable set as final option in the context menu
export function allowFinalAll(
  items: PWItem[],
  allItemsExistInConnection?: (item: PWItem[]) => boolean
): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  if (!items.every((item) => allowFinal(item))) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

function allowFinal(item: PWItem): boolean {
  if (itemIsProject(item)) {
    return false;
  }
  if (itemIsFinal(item)) {
    return false;
  }
  if (itemIsReadOnly(item) && itemIsLatestVersion(item)) {
    return false;
  }

  return true;
}

export function allowSetFinalStatusForAll(items: PWItem[]): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  const item = items[0];
  if (!itemIsDataItem(item)) {
    return false;
  }

  if (item.IsFinal) {
    return false;
  }

  return true;
}

export function allowRemoveFinalStatusForAll(items: PWItem[]): boolean {
  if (!items || items.length < 1) {
    return false;
  }

  const item = items[0];
  if (!itemIsDataItem(item)) {
    return false;
  }

  if (!item.IsFinal) {
    return false;
  }

  return true;
}

export function allowCommandAll(
  items: PWItem[],
  command: Command,
  allItemsExistInConnection?: (item: PWItem[]) => boolean
): boolean {
  if (!items || !items.length) {
    return false;
  }

  if (!items.every((item) => allowCommand(item, command))) {
    return false;
  }

  if (allItemsExistInConnection && !allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

export function allowCommand(item: PWItem, command: Command): boolean {
  if (!command.IsEnabled) {
    return false;
  }
  if (itemIsFinal(item)) {
    return false;
  }
  if (
    command.instanceId != 'urn~3Abentley~3Adocument~3Acommand~3AAddComment' &&
    itemIsLockedOrCheckedOut(item)
  ) {
    return false;
  }
  if (!itemIsLatestVersion(item)) {
    return false;
  }

  return true;
}

export function allowWorkFlow(items: PWItem[]): boolean {
  if (!items || items.length < 1) {
    return false;
  }
  return true;
}

// on canned connections hide workflow menu if there are no enabled workflow commands
function showWorkflowCannedConnection(childItems: MenuItemAction[]): boolean {
  return childItems.some((action) => !action.disabled);
}

export function showWorkflow(
  items: PWItem[],
  readOnly: boolean,
  canned: boolean,
  childItems?: MenuItemAction[]
): boolean {
  if (!items.length) {
    return false;
  }

  if (readOnly) {
    return false;
  }

  if (items.some(itemIsProject)) {
    return false;
  }

  if (canned) {
    return showWorkflowCannedConnection(childItems ?? []);
  }

  return true;
}
