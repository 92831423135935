import { atom, selector } from 'recoil';
import type { WSGInstance, WSGProperties } from '@bentley/pw-api';
import { getProjectDefaultEnvironmentInstance } from '../../../actions/formRendering';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync,
  getDCWHttpService,
  projectIdState
} from '../state';
import { showDocCodeStepState } from '../wizard';
import { formatDocCodeString } from './documentCodeAction';
import { filterDocCodeProperties } from '.';

export const generatingDocCodeState = atom<boolean>({
  key: 'generatingDocCodeState',
  default: false
});

export const usingDocCodeState = atom<boolean>({
  key: 'usingDocCodeState',
  default: false
});

export const docCodeStateAsync = selector<string>({
  key: 'docCodeState',
  get: async ({ get }) => {
    const showDocCodeStep = get(showDocCodeStepState);

    const currentInstance = get<WSGInstance | null>(
      currentEnvironmentInstanceState
    );

    if (!showDocCodeStep || !currentInstance) {
      return '';
    }

    try {
      const docCode = await formatDocCodeString(currentInstance);
      return docCode;
    } catch {
      return '';
    }
  }
});

export const docCodePropertiesAsync = selector<WSGProperties | null>({
  key: 'docCodePropertiesAsync',
  get: async ({ get }) => {
    try {
      const projectId = get<string>(projectIdState);
      const includeDocCodeDefaults = false;
      const httpService = getDCWHttpService();

      const defaultEnvironmentInstance = get<WSGInstance | null>(
        defaultEnvironmentInstanceStateAsync
      );

      const allProperties = defaultEnvironmentInstance?.properties;

      if (!projectId || !httpService || !allProperties) {
        return null;
      }

      const environmentInstancesWithoutDocCode =
        await getProjectDefaultEnvironmentInstance(
          projectId,
          includeDocCodeDefaults,
          httpService
        );

      const nonDocCodeProperties =
        environmentInstancesWithoutDocCode?.[0].properties;

      if (!nonDocCodeProperties) {
        return null;
      }

      return filterDocCodeProperties(allProperties, nonDocCodeProperties);
    } catch {
      return null;
    }
  }
});
