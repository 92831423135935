import type {
  HttpService,
  PWItem,
  PWParentType,
  PWProject
} from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { openConflictResolutionModal, openErrorModal } from './modals';
import type { OnComplete } from './move';

export type Outcome = { response: Response; item: PWItem };

export async function handleSuccessfulMoves(
  outcomes: Outcome[],
  destinationParent: PWProject,
  sourceParent: PWParentType,
  onComplete?: OnComplete
): Promise<void> {
  const successfulMoves = outcomes
    .filter((outcome) => outcome.response.ok)
    .map((outcome) => outcome.item);

  if (successfulMoves.length) {
    await onComplete?.(successfulMoves, destinationParent, sourceParent);
  }
}

export function handleFailedMoves(
  outcomes: Outcome[],
  openModal: OpenModal,
  closeModal: CloseModal,
  destinationParent: PWProject,
  sourceParent: PWParentType,
  httpService: HttpService,
  trackFeature: TrackFeature,
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  onComplete?: OnComplete
): void {
  const conflicts = outcomes.filter(
    (outcome) => outcome.response.status == 409
  );
  const errors = outcomes.filter(
    (outcome) =>
      !outcome.response.ok && ![200, 201, 409].includes(outcome.response.status)
  );

  const showErrorModal = errors.length
    ? () => openErrorModal(errors, openModal, closeModal)
    : undefined;

  if (conflicts.length) {
    openConflictResolutionModal(
      conflicts.map((c) => c.item),
      destinationParent,
      sourceParent,
      openModal,
      closeModal,
      httpService,
      trackFeature,
      parent,
      appView,
      readOnly,
      onComplete,
      showErrorModal
    );
  } else {
    void showErrorModal?.();
  }
}
