import './displayOptionsDialog.css';

import React, { useMemo, useRef, useState } from 'react';
import { useTernaryDarkMode } from 'usehooks-ts';
import type { PWDataItem } from '@bentley/pw-api';
import { itemIsProject } from '@bentley/pw-api';
import { SvgInfoHollow } from '@itwin/itwinui-icons-color-react';
import type { SelectOption } from '@itwin/itwinui-react';
import { LabeledSelect, ToggleSwitch, Tooltip } from '@itwin/itwinui-react';
import {
  useConnectionAuth,
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext,
  usePropertyContext,
  useTableItemContext
} from '../../context';
import type { View } from '../../hooks/useViews';
import { defaultView } from '../../hooks/useViews/defaultView';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';

export function DisplayOptionsDialog(): JSX.Element {
  const { trackFeature } = useFeatureTracking();
  const { isAdmin } = useConnectionAuth();
  const { readOnly, connection, consumerApp } = usePluginContext();
  const {
    ecPluginFeatures,
    goToPWE,
    openPDFInNewTab,
    versions: { displayLatestVersion, setDisplayLatestVersion }
  } = useEnvironmentContext();
  const { viewManager } = usePropertyContext();
  const {
    primaryModal,
    autoDisplayProgressModal,
    setAutoDisplayProgressModal
  } = useNavigationContext();
  const { selectedState: selectedState } = useTableItemContext();

  const [displayLatestVersionToggle, setDisplayLatestVersionToggle] =
    useState<boolean>(displayLatestVersion);
  const [allowGoToPWE, setAllowGoToPWE] = useState<boolean>(goToPWE.allowed);
  const [autoDisplayProgressToggle, setAutoDisplayProgressToggle] =
    useState<boolean>(autoDisplayProgressModal ?? true);
  const [openInNewTab, setOpenInNewTab] = useState<boolean>(
    openPDFInNewTab.allowed
  );

  const darkMode = useTernaryDarkMode();
  const [colorTheme, setColorTheme] = useState(darkMode.ternaryDarkMode);
  const showDefaultViewSelection = ecPluginFeatures.views();

  const noneSelection = useMemo(
    () => ({
      label: t('DisplayOptions.NoDefaultView'),
      value: { name: t('DisplayOptions.NoDefaultView') } as View
    }),
    []
  );

  const initialView = useRef(
    viewManager.savedViewSetting.views?.find(
      (view) => viewManager.adminViewSetting.view.name == view.name
    ) ?? noneSelection.value
  );
  const [viewSelected, setViewSelected] = useState<View>(initialView.current);

  const viewOptions = useMemo((): SelectOption<View>[] => {
    const viewOptions: SelectOption<View>[] = [];
    const globalViews = viewManager.savedViewSetting.views?.filter(
      (savedView) => savedView.isGlobalPWE
    );
    viewOptions.push(noneSelection);
    if (globalViews?.length) {
      globalViews.forEach((view) =>
        viewOptions.push({ value: view, label: view.name })
      );
    }
    return viewOptions;
  }, [noneSelection, viewManager.savedViewSetting.views]);

  const showThemeSelection = consumerApp == 'Generic';

  function onSaveButton(): void {
    primaryModal.close();

    if (displayLatestVersion != displayLatestVersionToggle) {
      displayLatestVersionToggle
        ? trackFeature('GRID_DISPLAY_LATEST_VERSION')
        : trackFeature('GRID_DISPLAY_ALL_VERSIONS');
      setDisplayLatestVersion(displayLatestVersionToggle);
      if (displayLatestVersionToggle) {
        removeVersionedCheckedRows();
      }
    }

    if (goToPWE.allowed != allowGoToPWE) {
      goToPWE.setAllowed(allowGoToPWE);
    }

    if (openPDFInNewTab.allowed != openInNewTab) {
      openPDFInNewTab.setAllowed(openInNewTab);
    }

    if (viewSelected?.name != viewManager.adminViewSetting.view.name) {
      const viewToSave =
        viewSelected == noneSelection.value ? defaultView : viewSelected;
      void viewManager.adminViewSetting.saveView(viewToSave ?? defaultView);
    }

    if (autoDisplayProgressModal != autoDisplayProgressToggle) {
      setAutoDisplayProgressModal(autoDisplayProgressToggle);
    }

    if (colorTheme != darkMode.ternaryDarkMode) {
      darkMode.setTernaryDarkMode(colorTheme);
    }
  }

  function onVersionDisplayChange(newValue: boolean): void {
    setDisplayLatestVersionToggle(!newValue);
  }

  function removeVersionedCheckedRows(): void {
    const nonVersionedSelectedItems = selectedState.selectedItems.filter(
      (item) => itemIsProject(item) || (item as PWDataItem).IsLatest
    );

    selectedState.setSelectedItems(nonVersionedSelectedItems);
  }

  function onDisplayGoToProjectWiseExplorerChange(newValue: boolean): void {
    setAllowGoToPWE(newValue);
  }

  function onProgressDisplayChange(newValue: boolean): void {
    setAutoDisplayProgressToggle(newValue);
  }

  const enableSaveButton = useMemo(() => {
    if (displayLatestVersionToggle !== displayLatestVersion) {
      return true;
    }

    if (allowGoToPWE !== goToPWE.allowed) {
      return true;
    }

    if (openInNewTab !== openPDFInNewTab.allowed) {
      return true;
    }

    const initialAutoDisplayProgress = autoDisplayProgressModal ?? true;
    if (autoDisplayProgressToggle !== initialAutoDisplayProgress) {
      return true;
    }

    if (viewSelected.name !== initialView.current.name) {
      return true;
    }

    if (colorTheme !== darkMode.ternaryDarkMode) {
      return true;
    }

    return false;
  }, [
    allowGoToPWE,
    autoDisplayProgressModal,
    autoDisplayProgressToggle,
    colorTheme,
    darkMode.ternaryDarkMode,
    displayLatestVersion,
    displayLatestVersionToggle,
    goToPWE.allowed,
    openInNewTab,
    openPDFInNewTab.allowed,
    viewSelected
  ]);

  return (
    <PWModal
      title={t('DisplayOptions.DisplayOptions')}
      primaryButton={{
        title: t('Generic.Save'),
        onClick: () => void onSaveButton(),
        disabled: !enableSaveButton
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'OptionsModal' }}
    >
      <span className="toggle-with-tooltip">
        <ToggleSwitch
          label={t('DisplayOptions.DisplayAllVersions')}
          data-testid="DisplayLatestVersionToggle"
          defaultChecked={!displayLatestVersionToggle}
          onChange={(e) => onVersionDisplayChange(e.target.checked)}
        />
        <Tooltip content={t('DisplayOptions.AllVersionsTooltip')}>
          <span className="new-tab-viewer-tooltip">
            <SvgInfoHollow />
          </span>
        </Tooltip>
      </span>
      {isAdmin && !readOnly && connection && !connection.Canned && (
        <ToggleSwitch
          label={t('DisplayOptions.EnableGoToPWE')}
          data-testid="EnablePWEToggle"
          defaultChecked={allowGoToPWE}
          onChange={(e) =>
            onDisplayGoToProjectWiseExplorerChange(e.target.checked)
          }
        />
      )}

      <span className="toggle-with-tooltip">
        <ToggleSwitch
          label={t('DisplayOptions.UseEmbeddedViewer')}
          data-testid="DocViewerNewTabToggle"
          defaultChecked={!openInNewTab}
          onChange={(e) => setOpenInNewTab(!e.target.checked)}
        />
        <Tooltip content={t('DisplayOptions.ViewerTooltip')}>
          <span className="new-tab-viewer-tooltip">
            <SvgInfoHollow />
          </span>
        </Tooltip>
      </span>
      <ToggleSwitch
        label={t('DisplayOptions.DisplayProgressForActions')}
        data-testid="ProgressModalDisplayToggle"
        defaultChecked={autoDisplayProgressToggle}
        onChange={(e) => onProgressDisplayChange(e.target.checked)}
      />
      {showDefaultViewSelection &&
        isAdmin &&
        connection &&
        !connection.Canned && (
          <LabeledSelect
            label={t('MenuItem.DefaultView')}
            message={t('DisplayOptions.SelectDefaultView')}
            id="default-view"
            data-testid="default-view"
            options={viewOptions}
            value={viewSelected}
            onChange={setViewSelected}
          />
        )}
      {showThemeSelection && (
        <LabeledSelect
          label={t('DisplayOptions.ColorTheme')}
          id="color-theme"
          data-testid="color-theme"
          options={[
            { value: 'light', label: t('DisplayOptions.ColorThemeLightTheme') },
            { value: 'dark', label: t('DisplayOptions.ColorThemeDarkTheme') },
            {
              value: 'system',
              label: t('DisplayOptions.ColorThemeDeviceDefault')
            }
          ]}
          value={colorTheme}
          onChange={setColorTheme}
        />
      )}
    </PWModal>
  );
}
