import React, { useEffect, useMemo, useState } from 'react';
import type { PWDataItem, PWItem, PWParentType } from '@bentley/pw-api';
import {
  filterDataItems,
  itemHasOlderVersions,
  itemIsProject
} from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useConnectionAuth,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useProjectSettingsContext
} from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import { t } from '../../services/translation';
import { deleteItems, itemsIncludedInSets } from './delete';
import { buildDeleteEvents } from './eventHandlers';
import { allowDeleteAll } from './requirements';
import { useOnDeleteComplete } from './useOnDeleteComplete';
import { findMatchingPdfFiles } from './utils';

type DeleteModalProps = {
  items: PWItem[];
  parent: PWParentType;
  userFolderPermissionsEnabled: boolean;
};

export function DeleteModal({
  items,
  parent,
  userFolderPermissionsEnabled
}: DeleteModalProps): ModalElement {
  const { trackFeature } = useFeatureTracking();
  const {
    consumerApp,
    connection,
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();
  const httpService = useHttpService();
  const { httpService: driveHttpService } = useProjectSettingsContext();
  const { isAdmin } = useConnectionAuth();
  const { primaryModal } = useNavigationContext();

  const onDeleteComplete = useOnDeleteComplete(items);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [itemsPartOfSet, setItemsPartOfSet] = useState<PWDataItem[]>([]);
  const [itemsNotPartOfSet, setItemsNotPartOfSet] = useState<PWDataItem[]>([]);
  const [matchingPdfs, setMatchingPdfs] = useState<PWDataItem[]>([]);

  useEffect(() => {
    async function checkIfPartOfSet() {
      const itemsInSet = await itemsIncludedInSets(
        filterDataItems(items),
        httpService
      );

      setItemsPartOfSet(itemsInSet);

      const itemsNotInSet = items
        .map((i) => i as PWDataItem)
        .filter((srcItem) => !itemsInSet.includes(srcItem));

      setItemsNotPartOfSet(itemsNotInSet);
    }

    const abortController = new AbortController();

    async function checkXfdfMatchesPdf() {
      const matchingPdfFiles = await findMatchingPdfFiles(items, httpService);
      setMatchingPdfs(matchingPdfFiles);
    }

    async function checkAll() {
      try {
        await Promise.all([checkIfPartOfSet(), checkXfdfMatchesPdf()]);
      } finally {
        setIsLoading(false);
      }
    }

    void checkAll();

    return () => {
      abortController.abort();
    };
  }, [consumerApp, httpService, iModelBridges, items]);

  function confirmationMsg(items: PWItem[]): string {
    const singleItem_noProjects_noVersions_msg = `${t(
      'Delete.SingleItemNoProjectsNoVersions',
      {
        name: items[0].Name
      }
    )}`;
    const singleItem_noProjects_withVersions_msg = `${t(
      'Delete.SingleItemNoProjectsWithVersions',
      {
        name: items[0].Name
      }
    )}`;
    const singleItem_withProjects_noVersions_msg = `${t(
      'Delete.SingleItemWithProjectsNoVersions',
      {
        name: items[0].Name
      }
    )}`;

    const multipleItems_noProjects_noVersions_msg = `${t(
      'Delete.MultipleItemsNoProjectsNoVersions',
      {
        count: items.length
      }
    )}`;
    const multipleItems_noProjects_withVersions_msg = `${t(
      'Delete.MultipleItemsNoProjectsWithVersions',
      {
        count: items.length
      }
    )}`;
    const multipleItems_withProjects_noVersions_msg = `${t(
      'Delete.MultipleItemsWithProjectsNoVersions',
      {
        count: items.length
      }
    )}`;
    const multipleItems_withProjects_withVersions_msg = `${t(
      'Delete.MultipleItemsWithProjectsWithVersions',
      {
        count: items.length
      }
    )}`;

    const singleItem = items.length == 1;
    const containsProjects = items.some(itemIsProject);
    const containsVersions = items.some(itemHasOlderVersions);

    if (singleItem && !containsProjects && !containsVersions) {
      return singleItem_noProjects_noVersions_msg;
    }
    if (singleItem && !containsProjects && containsVersions) {
      return singleItem_noProjects_withVersions_msg;
    }
    if (singleItem && containsProjects) {
      return singleItem_withProjects_noVersions_msg;
    }

    if (!singleItem && !containsProjects && !containsVersions) {
      return multipleItems_noProjects_noVersions_msg;
    }
    if (!singleItem && !containsProjects && containsVersions) {
      return multipleItems_noProjects_withVersions_msg;
    }
    if (!singleItem && containsProjects && !containsVersions) {
      return multipleItems_withProjects_noVersions_msg;
    }

    return multipleItems_withProjects_withVersions_msg;
  }

  function itemsArePartOfSetMsg(items: PWDataItem[]): string {
    const singleItemInSetMessage = `${t('Delete.ItemIsPartOfSet')}`;
    const multiItemsInSetMessage = `${t('Delete.ItemsArePartOfSet', {
      count: items.length
    })}`;

    if (items.length > 1) {
      return multiItemsInSetMessage;
    }
    return singleItemInSetMessage;
  }

  const onSave = () => {
    if (!driveHttpService) {
      throw new Error('Drive http service not initialized');
    }

    // Todo: change these events to hooks which have access to context and can simply return their functions
    const events = buildDeleteEvents(
      items,
      primaryModal.open,
      primaryModal.close,
      connection,
      httpService,
      driveHttpService,
      trackFeature,
      userFolderPermissionsEnabled,
      isAdmin,
      onDeleteComplete
    );

    primaryModal.close();
    void deleteItems(
      items,
      parent,
      httpService,
      trackFeature,
      events.onDeleteSuccessCallback,
      events.onDeleteFailureCallback,
      events.onCompleteCallback
    );
  };

  function itemSummary(): JSX.Element {
    return (
      <>
        <ItemSummary item={items} data-testid="dmItemSummary" />
        <Text>
          {confirmationMsg(items)}
          {matchingPdfs.length > 0 &&
            t('Delete.XfdfWarning', {
              fileName: matchingPdfs.map((pdf) => pdf.Name).join(', ')
            })}
        </Text>
      </>
    );
  }

  function modalContent(): JSX.Element {
    if (itemsPartOfSet.length) {
      return (
        <>
          <ItemSummary item={itemsPartOfSet} data-testid="dmItemSummary" />
          <Text>{itemsArePartOfSetMsg(itemsPartOfSet)}</Text>
          {itemsNotPartOfSet.length > 0 ? (
            itemSummary()
          ) : (
            <Text>{confirmationMsg(itemsPartOfSet)}</Text>
          )}
        </>
      );
    }
    return itemSummary();
  }

  const deleteDisabled = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  if (!allowDeleteAll(items, parent)) {
    return null;
  }

  return (
    <PWModal
      title={t('Generic.Delete')}
      className="delete-modal"
      isLoading={isLoading}
      primaryButton={{
        title: t('Generic.Delete'),
        onClick: onSave,
        disabled: deleteDisabled
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'DeleteModal' }}
    >
      {modalContent()}
    </PWModal>
  );
}

export default DeleteModal;
