import { useEffect, useState } from 'react';
import { buildFeatureToggleClient } from '@bentley/pw-config';
import { useConnectionContext, useToken } from '../../../context';

export type DriveFeatures = {
  cutoffVersion: string;
  downloadURL: string;
  excludedVersions: string[];
  latestVersion: string;
  downloadVersion: string;
};

type DriveFeatureClient = {
  driveFeatures: DriveFeatures;
};

export function useDriveFeatureClient(): DriveFeatureClient | undefined {
  const { getSamlToken } = useToken();
  const { buddiRegionCode } = useConnectionContext();

  const [driveFeatures, setDriveFeatures] = useState<DriveFeatureClient>();

  useEffect(() => {
    async function initFeatures(): Promise<void> {
      const clientSideId = getDriveLDClientId(buddiRegionCode);
      const driveClient = await buildFeatureToggleClient(
        getSamlToken,
        clientSideId
      );
      const driveFeatures = driveClient.getVariation(
        'update-information',
        {} as DriveFeatures
      );
      setDriveFeatures({ driveFeatures });
    }

    void initFeatures();
  }, [buddiRegionCode, getSamlToken]);

  return driveFeatures;
}

function getDriveLDClientId(buddiRegion: string): string {
  if (buddiRegion == '102') {
    return '5f031befc578b50bc3ba7e58';
  }
  if (buddiRegion == '103') {
    return '5f031befc578b50bc3ba7e55';
  }
  return '5f031beec5471c0bfe7408ec';
}
