import type { PWFlatSet, PWItem, PWParentType } from '@bentley/pw-api';
import {
  filterFileTypes,
  itemIsFileType,
  itemIsFlatSet,
  itemIsParentType,
  parseSetRelationshipInstance
} from '@bentley/pw-api';
import type { AppView } from '../../hooks/useAppView';

export function allowFlatSetCreation(
  parent: PWParentType,
  appView: AppView,
  readOnly: boolean
): boolean {
  if (
    !parent.instanceId ||
    itemIsFlatSet(parent) ||
    appView != 'FolderNavigation' ||
    readOnly
  ) {
    return false;
  }

  return true;
}

export function allowRemoveFlatSetMember(
  flatSet: PWFlatSet,
  items: PWItem[]
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }
  if (flatSetIsImmutable(flatSet)) {
    return false;
  }
  return true;
}

export function allowAddSelectedItemsToFlatSet(
  items: PWItem[],
  allItemsExistInConnection: (items: PWItem[]) => boolean
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }

  if (items.some((item) => itemIsParentType(item))) {
    return false;
  }

  return allItemsExistInConnection(items);
}

export function allowFlatSetCreationFromSelectedItems(
  items: PWItem[],
  currentParent: PWParentType,
  appView: AppView,
  readOnly: boolean,
  allItemsExistInConnection: (items: PWItem[]) => boolean
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }

  if (items.some((item) => itemIsParentType(item))) {
    return false;
  }

  if (!allowFlatSetCreation(currentParent, appView, readOnly)) {
    return false;
  }

  if (!allItemsExistInConnection(items)) {
    return false;
  }

  return true;
}

export function allowLockAllToVersion(
  flatSet: PWFlatSet,
  items: PWItem[]
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }

  return items.every((item) => allowLockToVersion(flatSet, item));
}

export function showLockAllToVersion(
  flatSet: PWFlatSet,
  items: PWItem[]
): boolean {
  return items.some((item) => allowLockToVersion(flatSet, item));
}

function allowLockToVersion(flatSet: PWFlatSet, item: PWItem): boolean {
  if (!itemIsFileType(item)) {
    return false;
  }
  if (parseSetRelationshipInstance(item, flatSet)?.properties?.LockToVersion) {
    return false;
  }
  if (flatSetIsImmutable(flatSet)) {
    return false;
  }
  if (item.IsReadOnly) {
    return false;
  }
  return true;
}

export function allowUnlockAllFromVersion(
  flatSet: PWFlatSet,
  items: PWItem[]
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }

  return items.every((item) => allowUnlockFromVersion(flatSet, item));
}

export function showUnlockAllFromVersion(
  flatSet: PWFlatSet,
  items: PWItem[]
): boolean {
  if (!filterFileTypes(items).length) {
    return false;
  }
  return items.some((item) => allowUnlockFromVersion(flatSet, item));
}

function allowUnlockFromVersion(flatSet: PWFlatSet, item: PWItem): boolean {
  if (!itemIsFileType(item)) {
    return false;
  }
  if (!parseSetRelationshipInstance(item, flatSet)?.properties?.LockToVersion) {
    return false;
  }
  if (flatSetIsImmutable(flatSet)) {
    return false;
  }
  return true;
}

function flatSetIsImmutable(flatSet: PWFlatSet): boolean {
  if (flatSet.IsFinal) {
    return true;
  }
  if (flatSet.IsReadOnly) {
    return true;
  }
  return false;
}
