import { atom } from 'recoil';
import type { FormInterface } from '../../../actions/formRendering';

export const updatingTriggeredPropertiesState = atom<boolean>({
  key: 'updatingTriggeredPropertiesState',
  default: false
});

export const updatingReloadPropertiesState = atom<boolean>({
  key: 'updatingReloadPropertiesState',
  default: false
});

export const updatingEnvironmentInterfacesState = atom<boolean>({
  key: 'updatingEnvironmentInterfacesState',
  default: false
});

export const environmentInterfacesState = atom<FormInterface[]>({
  key: 'environmentInterfacesState',
  default: []
});
