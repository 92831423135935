import type { PWItem } from '@bentley/pw-api';
import type { ToastHandle } from '../../services/pwToast';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyDeleteNotAllowed(): ToastHandle {
  const message = t('RecycleBin.DeleteNotAllowed');
  return openToast({ content: message, category: 'negative' });
}

export function notifyItemsDeleted(items: PWItem[]): ToastHandle {
  const message =
    items.length == 1
      ? t('RecycleBin.ItemDeleted')
      : t('RecycleBin.ItemsDeleted');
  return openToast({ content: message, category: 'positive' });
}

export function notifyDeleteFailed(items: PWItem[]): ToastHandle {
  const message =
    items.length == 1
      ? t('RecycleBin.ErrorDeletingItem')
      : t('Delete.UnableToDelete');

  return openToast({ content: message, category: 'negative' });
}
