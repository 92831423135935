import type { ColumnWidthOptions } from './useColumnWidths';

export const minColumnWidth = 125;
const primaryColumnWidth = 225;
const compactColumnWidth = 150;
const mediumColumnWidth = 200;

const defaultColumnWidths = {
  Name: primaryColumnWidth,
  Version: compactColumnWidth,
  Description: primaryColumnWidth,
  FileName: primaryColumnWidth,
  FileSize: compactColumnWidth
} as Record<string, number | undefined>;

export function defaultColumnWidth(
  columnId: string,
  options?: ColumnWidthOptions
): number {
  let width = widthFromType(columnId, options);

  if (options?.displayIcon) {
    width = width + 32;
  }

  if (options?.isPrimary) {
    width = width + 40;
  }

  return width;
}

function widthFromType(columnId: string, options?: ColumnWidthOptions): number {
  const constantDefault = defaultColumnWidths[columnId];

  if (constantDefault) {
    return constantDefault;
  }

  if (
    options?.columnDataType == 'boolean' ||
    options?.columnDataType == 'number' ||
    options?.columnDataType == 'fileSize'
  ) {
    return compactColumnWidth;
  }

  if (options?.isPrimary || options?.isSecondary) {
    return primaryColumnWidth;
  }

  if (
    options?.columnDataType == 'dateTime' ||
    options?.columnDataType == 'string'
  ) {
    return mediumColumnWidth;
  }

  return minColumnWidth;
}
