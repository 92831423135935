import React, { useEffect, useMemo } from 'react';
import { parseRelatedEnvironmentSheets } from '@bentley/pw-api';
import { TablePaginator } from '@itwin/itwinui-react';
import { useNavigationContext, useTableItemContext } from '../../../context';

type AttributeSheetSelectorProps = {
  currentAttributeSheet: number;
  setCurrentAttributeSheet: React.Dispatch<React.SetStateAction<number>>;
};

export function AttributeSheetSelector({
  currentAttributeSheet,
  setCurrentAttributeSheet
}: AttributeSheetSelectorProps): JSX.Element {
  const { infoPanelDisplayed } = useNavigationContext();
  const { actionableItems } = useTableItemContext();

  const totalRowsCount = useMemo(() => {
    if (actionableItems.length != 1) {
      return 1;
    }

    const attributeSheets = parseRelatedEnvironmentSheets(actionableItems[0]);
    return attributeSheets.length;
  }, [actionableItems]);

  useEffect(() => {
    if (!infoPanelDisplayed) {
      return;
    }

    setCurrentAttributeSheet(0);
  }, [actionableItems, infoPanelDisplayed, setCurrentAttributeSheet]);

  return (
    <TablePaginator
      totalRowsCount={totalRowsCount}
      currentPage={currentAttributeSheet}
      pageSize={1}
      onPageChange={setCurrentAttributeSheet}
      onPageSizeChange={(size: number) => null}
      size="small"
    />
  );
}
