import React from 'react';
import { SvgFilter } from '@itwin/itwinui-icons-react';
import { Button, Flex, Text } from '@itwin/itwinui-react';
import { useTableColumnContext } from '../../context';
import { keyPressAsClick } from '../../services/accessibility';
import { t } from '../../services/translation';

export function EmptyFilterState(): JSX.Element {
  const { tableFiltering } = useTableColumnContext();

  function onCloseEmptyState(): void {
    tableFiltering.clearFilters();
  }

  return (
    <Flex flexDirection="column" gap="m" className="pw-empty-state">
      <SvgFilter className="ets-icon" />
      <Text>{t('Grid.NoFilterResults')}</Text>
      <Button
        styleType="high-visibility"
        onClick={onCloseEmptyState}
        onKeyPress={keyPressAsClick(onCloseEmptyState)}
      >
        {t('Grid.ClearFilters')}
      </Button>
    </Flex>
  );
}
