import { useCallback, useMemo } from 'react';
import type {
  PWDataItem,
  PWItem,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { byDefaultOrder, parseResponseInstances } from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';
import { escapeSearchString } from '../../../services/search';
import { getEnvironmentSelectString } from '../../useEnvironment';
import { useColumnFilterString } from './useColumnFilterString';
import { useLatestVersionFilterString } from './useLatestVersionFilterString';
import { useProjectFilterString } from './useProjectFilterString';

type ColumnSearchFunction = (httpOptions?: RequestOptions) => Promise<PWItem[]>;

export function useColumnSearch(): ColumnSearchFunction {
  const httpService = useHttpService();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    searchState: { basicQuery },
    navigationManager: { currentParent }
  } = useNavigationContext();

  const columnFilterString = useColumnFilterString();
  const projectFilterString = useProjectFilterString();
  const latestVersionFilterString = useLatestVersionFilterString();

  const apiAncestorQuery = useMemo((): string => {
    if (!currentParent.instanceId || ecPluginFeatures.apiAncestorBug()) {
      return '';
    }

    return `api.ancestor=PW_WSG.Project-${currentParent.instanceId}&`;
  }, [currentParent.instanceId, ecPluginFeatures]);

  const projectSearchUrl = useCallback((): string => {
    const searchString = escapeSearchString(basicQuery.toLowerCase());
    const filter = `Name like '${searchString}' or Description like '${searchString}'`;
    const environmentSelect = getEnvironmentSelectString('Project');
    const select = `*,${environmentSelect},ProjectParent-forward-Project.*`;
    return `PW_WSG/Project?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
  }, [apiAncestorQuery, basicQuery]);

  const projectSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWProject[]> => {
      if (!ecPluginFeatures.projectSearch()) {
        return [];
      }

      const url = projectSearchUrl();
      const response = await httpService.get(url, httpOptions);
      const projects = await parseResponseInstances<PWProject>(response);
      const nonRootProjects = projects.filter(
        (project) => project.instanceId != currentParent.instanceId
      );
      return nonRootProjects;
    },
    [currentParent.instanceId, ecPluginFeatures, httpService, projectSearchUrl]
  );

  const documentSearchUrl = useCallback(
    async (httpOptions?: RequestOptions): Promise<string> => {
      const searchString = escapeSearchString(basicQuery.toLowerCase());
      const columnFilter = columnFilterString(searchString);
      const projectFilter = await projectFilterString(httpOptions);
      const latestVersionFilter = latestVersionFilterString();
      const filter = `${columnFilter}${projectFilter}${latestVersionFilter}`;
      const environmentSelect = getEnvironmentSelectString('Document');
      const select = `*,${environmentSelect},DocumentParent-forward-Project.*`;
      return `PW_WSG/Document!poly?${apiAncestorQuery}$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
    },
    [
      apiAncestorQuery,
      basicQuery,
      columnFilterString,
      latestVersionFilterString,
      projectFilterString
    ]
  );

  const documentSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWDataItem[]> => {
      const url = await documentSearchUrl(httpOptions);
      const response = ecPluginFeatures.apiAncestorBug()
        ? await httpService.post(
            'PW_WSG/Document!poly/$query',
            url.slice(url.indexOf('$filter=')),
            httpOptions
          )
        : await httpService.get(url, httpOptions);
      const documents = await parseResponseInstances<PWDataItem>(response);
      return documents;
    },
    [documentSearchUrl, ecPluginFeatures, httpService]
  );

  const columnSearch = useCallback(
    async (httpOptions?: RequestOptions): Promise<PWItem[]> => {
      const [projects, documents] = await Promise.all([
        projectSearch(httpOptions),
        documentSearch(httpOptions)
      ]);
      const items = [...projects, ...documents];
      items.sort(byDefaultOrder);
      return items;
    },
    [documentSearch, projectSearch]
  );

  return columnSearch;
}
