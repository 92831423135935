import './loadingNode.css';

import React from 'react';
import type { PWDocument, PWProject } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { SvgFind } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { Chevron } from '../chevron/chevron';

type LoadingNodeProps = {
  nodeType: 'folder' | 'savedSearch';
};

export function LoadingNode({ nodeType }: LoadingNodeProps): JSX.Element {
  function getIcon(): JSX.Element {
    if (nodeType == 'folder') {
      return (
        <ItemIcon
          item={{ className: 'Project', TypeString: 'Folder' } as PWProject}
          size="xs"
        />
      );
    }

    if (nodeType == 'savedSearch') {
      return (
        <SvgFind className="pw-icon-xs" data-testid="saved-search-filetype" />
      );
    }

    return (
      <ItemIcon item={{ className: 'Document' } as PWDocument} size="xs" />
    );
  }

  return (
    <div className="tree-node" data-testid="loading-node">
      <Chevron hasChildren={false} isExpanded={false} />
      {getIcon()}
      <Text isSkeleton={true} variant="small" className="loading-node"></Text>
    </div>
  );
}
