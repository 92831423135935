import type { WSGInstancesResponse } from '@bentley/pw-api';
import { t } from '../../../services/translation';

export type DataError = Response & Error & WSGInstancesResponse;

export async function getErrorMessage(error: DataError): Promise<string> {
  const errorMessage = await parseErrorMessage(error);
  const friendlyMessage = friendlyErrorMessage(errorMessage);
  return friendlyMessage;
}

async function parseErrorMessage(error?: DataError): Promise<string> {
  if (error instanceof Response) {
    const message = await parseJsonError(error);

    if (message) {
      return message;
    }
  }

  if (error instanceof Error && error?.message) {
    return error.message;
  }

  if (error?.errorMessage) {
    return error.errorMessage;
  }

  if (error?.toString) {
    const message = parseStringError(error);

    if (message) {
      return message;
    }
  }

  return t('DocumentCreation.UnspecifiedError');
}

async function parseJsonError(error: Response): Promise<string | undefined> {
  try {
    const json = (await error.json()) as WSGInstancesResponse | null;
    const errorMessage = json?.errorMessage;
    return errorMessage;
  } catch {
    return '';
  }
}

function parseStringError(error: DataError): string {
  const stringError = error.toString();

  if (stringError == '[object Object]' || stringError == '[object Response]') {
    return '';
  }

  return stringError;
}

function friendlyErrorMessage(errorMessage: string): string {
  if (errorMessage.includes('Required field has no value')) {
    return t('DocumentCreation.RequiredAttributeError');
  }

  if (errorMessage.includes('Incorrect document attribute value')) {
    return t('DocumentCreation.NullAttributeError');
  }

  if (errorMessage.includes('Field value must be unique')) {
    return t('DocumentCreation.UniqueAttributeError');
  }

  if (errorMessage.includes('Some of required code parts are empty')) {
    return t('DocumentCreation.RequiredFieldsError');
  }

  if (
    errorMessage.includes('Error creating new version of document') ||
    errorMessage.includes('Error creating code restriction')
  ) {
    return t('DocumentCreation.DocumentNameInUse');
  }

  if (
    errorMessage.includes('No documents with name') ||
    errorMessage.includes('No documents with specified name have been found.')
  ) {
    return t('DocumentCreation.AccessDeniedError');
  }

  return errorMessage;
}
