import { useMemo } from 'react';
import type { Column } from '@itwin/itwinui-react/react-table';
import { useTableFilters } from '../../../components/pwTable/filter';
import type { View } from '../../../hooks/useViews';
import { t } from '../../../services/translation';
import { ActionsCell } from './actionsCell';
import { AllConnectionsCell } from './allConnectionsCell';
import { NameCell } from './nameCell';

export function useViewsColumns(): Column<View>[] {
  const { columnFilter } = useTableFilters();

  const columns = useMemo(
    (): Column<View>[] => [
      {
        id: 'name',
        accessor: 'name',
        Header: t('Generic.Name'),
        Cell: NameCell,
        Filter: columnFilter('string'),
        filter: 'text'
      },
      {
        id: 'allConnections',
        accessor: 'multiConnection',
        Header: t('SavedAdvSearch.AllConnections'),
        Cell: AllConnectionsCell,
        width: 175,
        minWidth: 175
      },
      {
        id: 'actions',
        accessor: 'name',
        Header: t('Generic.ColumnActions'),
        Cell: ActionsCell,
        disableSortBy: true,
        width: 150,
        minWidth: 150
      }
    ],
    [columnFilter]
  );

  return columns;
}
