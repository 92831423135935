import './emptyView.css';

import React from 'react';
import { Trans } from 'react-i18next';
import { SvgImageFrame } from '@itwin/itwinui-icons-react';
import { Anchor, Text } from '@itwin/itwinui-react';
import { useNavigationContext, usePropertyContext } from '../../context';
import { t } from '../../services/translation';
import { CustomColumnDialog } from '../customColumn';

export function EmptyView(): JSX.Element {
  const { viewManager } = usePropertyContext();

  const { primaryModal } = useNavigationContext();

  function configureColumnsClick(): void {
    primaryModal.open(
      <CustomColumnDialog
        initialView={viewManager.currentView}
        onClose={primaryModal.close}
      />
    );
  }

  return (
    <div className="views-empty-state">
      <SvgImageFrame className="empty-state-icon" />
      <Text isMuted={true}>{t('Views.NoViewsSaved')}</Text>
      <Text isMuted={true}>
        <Trans i18nKey="Views.UseConfigureColumns">
          Save a view from the{' '}
          <Anchor onClick={configureColumnsClick}>Configure columns</Anchor>{' '}
          option.
        </Trans>
      </Text>
    </div>
  );
}
