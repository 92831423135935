import type { ProviderProps } from 'react';
import React from 'react';
import { GraphApiProvider } from '../appProviders';
import { AppViewProvider } from './appView';
import { EnvironmentProvider } from './environment';
import { ItemActionProvider } from './itemAction';
import { NavigationProvider } from './navigation';
import { PropertyProvider } from './property';
import { TableColumnProvider } from './tableColumn';
import { TableItemProvider } from './tableItem';

export function AuthorizedProviders({
  children
}: ProviderProps<never>): JSX.Element {
  return (
    <EnvironmentProvider value={undefined}>
      <NavigationProvider value={undefined}>
        <GraphApiProvider value={undefined}>
          <AppViewProvider value={undefined}>
            <PropertyProvider value={undefined}>
              <TableItemProvider value={undefined}>
                <ItemActionProvider value={undefined}>
                  <TableColumnProvider value={undefined}>
                    {children}
                  </TableColumnProvider>
                </ItemActionProvider>
              </TableItemProvider>
            </PropertyProvider>
          </AppViewProvider>
        </GraphApiProvider>
      </NavigationProvider>
    </EnvironmentProvider>
  );
}
