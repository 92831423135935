import type { ChangeEvent } from 'react';
import React from 'react';
import { pwConstants } from '@bentley/pw-api';
import { Divider, DropdownButton, SearchBox } from '@itwin/itwinui-react';
import { useSearchActions } from '../toolbar';
import { menuItemActionToMenuItem } from '../toolbar/pwToolbar/menuItemActionToMenuItem';
import { useSearchInputComponents } from './useSearchInputComponents';
import { useSearchInputEvents } from './useSearchInputEvents';
import { useSearchInputState } from './useSearchInputState';

export function PWSearch(): JSX.Element {
  const searchActions = useSearchActions();
  const {
    inputValue,
    inSearchState,
    inputEnabled,
    dropdownEnabled,
    showDropdown,
    setInputValue
  } = useSearchInputState(searchActions);

  const { onSearch, clearSearch, onKeyDown } = useSearchInputEvents({
    inputValue,
    setInputValue
  });

  const { button, placeholder } = useSearchInputComponents({
    inSearchState,
    clearSearch,
    onSearch
  });

  const input = (
    <SearchBox.Input
      placeholder={inputEnabled ? placeholder : ''}
      value={inputValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setInputValue(e.target.value)
      }
      onKeyDown={onKeyDown}
      disabled={!inputEnabled}
      maxLength={pwConstants.maxFileNameLength}
      data-testid="SearchInput"
    />
  );

  const dropdown = showDropdown ? (
    <>
      <Divider orientation="vertical" />
      <DropdownButton
        styleType="borderless"
        data-testid="search-dropdown"
        disabled={!dropdownEnabled}
        dropdownMenuProps={{ placement: 'bottom-end', matchWidth: false }}
        menuItems={(close) =>
          searchActions.map((action) => menuItemActionToMenuItem(action, close))
        }
      />
    </>
  ) : null;

  return (
    <SearchBox isDisabled={!inputEnabled}>
      {input}
      {button}
      {dropdown}
    </SearchBox>
  );
}
