import type { EnvironmentProperty } from './environmentProperty';

export function byEnvironmentAndDisplayLabel(
  propA: EnvironmentProperty,
  propB: EnvironmentProperty
): number {
  if (
    propA.envLabel?.toLocaleLowerCase() == propB.envLabel?.toLocaleLowerCase()
  ) {
    return byDisplayLabel(propA, propB);
  }
  return byEnvironmentLabel(propA, propB);
}

export function byDisplayLabel(
  propA: EnvironmentProperty,
  propB: EnvironmentProperty
): number {
  if (
    propA.displayLabel?.toLocaleLowerCase() >
    propB.displayLabel?.toLocaleLowerCase()
  ) {
    return 1;
  }
  return -1;
}

function byEnvironmentLabel(
  propA: EnvironmentProperty,
  propB: EnvironmentProperty
): number {
  if (
    propA.envLabel?.toLocaleLowerCase() > propB.envLabel?.toLocaleLowerCase()
  ) {
    return 1;
  }
  return -1;
}
