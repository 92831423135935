import Postmate from 'postmate';
import { useEffect, useState } from 'react';
import { useBuddi } from '@bentley/pw-config';
import { usePluginContext } from '../../context';
import { buildPWDMMarkupLink } from './pwdmLinks';
import { useCheckProject } from './useCheckProject';

export type PWDMAPIFunction = {
  pwdmApi: Postmate.ParentAPI | null;
  pwdmMarkupLink: string;
  setIFrame: React.Dispatch<
    React.SetStateAction<HTMLIFrameElement | undefined>
  >;
  isProjectEnabled: boolean;
};

export function usePWDMAPI(): PWDMAPIFunction {
  const [pwdmApi, setPWDMApi] = useState<Postmate.ParentAPI | null>(null);
  const { buddiRegionCode, contextId, logError } = usePluginContext();
  const projectEnabled = useCheckProject();

  const [iFrame, setIFrame] = useState<HTMLIFrameElement>();
  const PWDMMarkupurl = useBuddi('BTSPortal.URL', buddiRegionCode);

  const pwdmMarkupLink = buildPWDMMarkupLink(PWDMMarkupurl, contextId ?? '');

  useEffect(() => {
    if (!PWDMMarkupurl || !iFrame) {
      setPWDMApi(null);
      return;
    }

    async function createPostmate(): Promise<void> {
      try {
        const postmate = await new Postmate({
          container: iFrame,
          url: pwdmMarkupLink
        });
        setPWDMApi(postmate);
      } catch (e) {
        logError('Error initializing PWDM Postmate', {
          error: e,
          pwdmMarkupLink
        });
      }
    }

    if (!pwdmApi) {
      void createPostmate();
    }

    return () => {
      pwdmApi?.destroy();
    };
  }, [pwdmMarkupLink, PWDMMarkupurl, pwdmApi, logError, iFrame]);

  return {
    pwdmApi,
    pwdmMarkupLink,
    setIFrame,
    isProjectEnabled: projectEnabled
  };
}
