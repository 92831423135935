import React from 'react';
import { SvgStatusWarning } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { t } from '../../services/translation';

export function TableErrorState(): JSX.Element {
  return (
    <>
      <SvgStatusWarning className="ets-icon" />
      <Text>{t('Grid.ErrorRetrievingRecords')}</Text>
    </>
  );
}
