import React, { useCallback, useMemo, useState } from 'react';
import type {
  PWDataItem,
  PWFileType,
  PWItem,
  PWParentType
} from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import type { CustomFile } from '../../actions/upload';
import { InProgressModal } from '../../components/inProgress';
import { MAX_UPLOAD_CHUNK_SIZE } from '../../constants';
import { generateUUID } from '../../services/data';
import type { ToastHandle } from '../../services/pwToast';
import { t } from '../../services/translation';
import type {
  InProgressActivity,
  InProgressActivityStatus,
  InProgressActivityType,
  InProgressJob,
  InProgressTracker
} from './types';
import { castFileToDataItem, castFileToFileType, downloadTitle } from './utils';

export type ProgressManager = {
  progressTracker: InProgressTracker;
  openIPModal: () => void;
  closeIPModal: () => void;
  IPModal: JSX.Element | null;
  addNewJobToTracker: (
    type: InProgressActivityType,
    toastHandle?: ToastHandle
  ) => string;
  removeJobFromTracker: (jobId: string) => void;
  addUploadToJob: (
    jobId: string,
    file: File,
    parent: PWParentType,
    abortController: AbortController
  ) => string;
  addUploadFolderToJob: (jobId: string, item: PWItem) => string;
  getJobActivities: (jobId: string) => InProgressActivity[];
  addDownloadToJob: (
    jobId: string,
    item: PWFileType,
    abortController: AbortController
  ) => string;
  addReplaceToJob: (
    jobId: string,
    item: File,
    abortController: AbortController
  ) => string;
  addWorkflowActivityToJob: (
    jobId: string,
    item: PWItem,
    abortController: AbortController
  ) => string;
  reactivateOldJob: (
    jobId: string,
    type: InProgressActivityType,
    toastHandle?: ToastHandle
  ) => void;
  clearFailuresFromJob: (jobId: string) => void;
  clearConflictsFromJob: (jobId: string) => void;
  clearAbortedActivityData: (jobId: string, activityId: string) => void;
  clearCompletedJobs: () => void;
  updateProgressStatus: (
    jobId: string,
    activityId: string,
    newStatus: InProgressActivityStatus,
    errorResponse?: Response,
    file?: File,
    parent?: PWParentType
  ) => void;
  addWarningMessageToActivity: (
    jobId: string,
    activityId: string,
    warning: string
  ) => void;
  addSuccessMessageToActivity: (
    jobId: string,
    activityId: string,
    success: string
  ) => void;
  updateAmountUploaded: (
    jobId: string,
    activityId: string,
    amountTransferred: number,
    transferComplete?: boolean
  ) => void;
  updateAmountDownloaded: (
    jobId: string,
    activityId: string,
    amountTransferred: number
  ) => void;
  updateItemInfo: (jobId: string, activityId: string, newItem: PWItem) => void;
  updateToastForJob: (jobId: string, newToast: ToastHandle) => void;
  disableAbortForActivity: (jobId: string, activityId: string) => void;
  setAutoDisplayModal: (value: boolean) => void;
  stopAllInProgress: () => void;
};

export function useProgressManager(): ProgressManager {
  const [progressTracker] = useState<InProgressTracker>(newProgressTracker);
  const [showIPModal, setShowIPModal] = useState<boolean>(false);
  const [autoDisplayModal, setAutoDisplayModal] = useState<boolean>(true);

  const openIPModal = useCallback((): void => {
    setShowIPModal(true);
  }, []);

  const closeIPModal = useCallback((): void => {
    setShowIPModal(false);
  }, []);

  // Indexing functions
  const getJobIndex = useCallback(
    (jobId: string): number => {
      return progressTracker.jobsInProgress.findIndex(
        (job) => job.jobId == jobId
      );
    },
    [progressTracker.jobsInProgress]
  );

  const getActivityIndex = useCallback(
    (jobIndex: number, activityId: string): number => {
      return progressTracker.jobsInProgress[
        jobIndex
      ].activitiesInProgress.findIndex(
        (activity) => activity.uniqueId == activityId
      );
    },
    [progressTracker.jobsInProgress]
  );

  const stopAllInProgress = useCallback((): void => {
    closeIPModal();
    progressTracker.jobsInProgress?.forEach((job) =>
      job.activitiesInProgress?.forEach((activity) =>
        activity.abortController?.abort()
      )
    );
    progressTracker.jobsInProgress = [];
  }, [closeIPModal, progressTracker]);

  const clearCompletedJobs = useCallback((): void => {
    progressTracker.jobsInProgress = progressTracker.jobsInProgress.filter(
      (jip) => {
        return jip.activitiesInProgress.some((aip) =>
          ['inprogress', 'processing'].includes(aip.status)
        );
      }
    );
  }, [progressTracker.jobsInProgress]);

  const IPModal = useMemo(
    () =>
      showIPModal ? (
        <InProgressModal
          closeModal={() => {
            setShowIPModal(false);
            clearCompletedJobs();
          }}
          inProgressTracker={progressTracker}
        />
      ) : null,
    [clearCompletedJobs, progressTracker, showIPModal]
  );

  function newProgressTracker(): InProgressTracker {
    return {
      jobsInProgress: [],
      mostRecentJobType: 'upload'
    } as InProgressTracker;
  }

  // Adding/removing jobs

  const addNewJobToTracker = useCallback(
    (type: InProgressActivityType, toastHandle?: ToastHandle): string => {
      const jobId = generateUUID();
      const newJob = {
        activitiesInProgress: [],
        activityType: type,
        jobId: jobId,
        toastHandle: toastHandle
      } as InProgressJob;
      progressTracker.mostRecentJobType = type;
      progressTracker.jobsInProgress.push(newJob);
      if (autoDisplayModal) {
        openIPModal();
      }
      return jobId;
    },
    [autoDisplayModal, openIPModal, progressTracker]
  );

  const reactivateOldJob = useCallback(
    (
      jobId: string,
      type: InProgressActivityType,
      toastHandle?: ToastHandle
    ): void => {
      const newJob = {
        activitiesInProgress: [],
        activityType: type,
        jobId: jobId,
        toastHandle: toastHandle
      } as InProgressJob;
      progressTracker.mostRecentJobType = type;
      progressTracker.jobsInProgress.push(newJob);
      if (autoDisplayModal) {
        openIPModal();
      }
    },
    [autoDisplayModal, openIPModal, progressTracker]
  );

  /**
   * Removes a job from the tracker
   * @param {string} jobId The id of the job that is being removed
   */
  const removeJobFromTracker = useCallback(
    (jobId: string): void => {
      progressTracker.jobsInProgress = progressTracker.jobsInProgress.filter(
        (job) => job.jobId !== jobId
      );
    },
    [progressTracker]
  );

  // Upload Actions

  /**
   * Adding a new upload activity to a job
   * @param {string} jobId Id of the job to add activity to
   * @param {CustomFile} file The file being uploaded
   * @param {PWParentType} parent The parent where the file is being uploaded
   * @param {AbortController} abortController Controller for aborting the upload from the progress modal
   * @returns {string} The unique Id to reference the activity
   */
  const addUploadToJob = useCallback(
    (
      jobId: string,
      file: CustomFile,
      parent: PWParentType,
      abortController: AbortController,
      toastHandle?: ToastHandle
    ): string => {
      const item = castFileToDataItem(file);
      if (file.id) {
        item.instanceId = file.id;
      }
      const uniqueId = generateUUID();
      const fileName = file.customName ?? file.name;
      const upload = {
        status: 'inprogress',
        size: file.size,
        amountTransferred: 0,
        label: fileName,
        item: item,
        title: `${t('InProgress.InFolder', {
          name: fileName,
          parentName: parent.Name
        })}`,
        uniqueId: uniqueId,
        activityType: 'upload',
        abortController: abortController
      } as InProgressActivity;
      const index = getJobIndex(jobId);
      if (index === -1) {
        reactivateOldJob(jobId, 'upload', toastHandle);
      } else {
        progressTracker.jobsInProgress[index].activitiesInProgress.push(upload);
      }
      return uniqueId;
    },
    [getJobIndex, progressTracker.jobsInProgress, reactivateOldJob]
  );

  /**
   * Adding a new upload folder activity to a job
   * @param {string} jobId Id of the job to add activity to
   * @param {PWItem} item The folder being uploaded
   * @returns {string} The unique Id to reference the activity
   */
  const addUploadFolderToJob = useCallback(
    (jobId: string, item: PWItem): string => {
      const activityId = generateUUID();
      const uploadFolder = {
        status: 'inprogress',
        size: 0,
        amountTransferred: 0,
        item: item,
        uniqueId: activityId,
        activityType: 'uploadFolder'
      } as InProgressActivity;
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress.push(
        uploadFolder
      );
      return activityId;
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  const getJobActivities = useCallback(
    (jobId: string): InProgressActivity[] => {
      const jobIndex = getJobIndex(jobId);
      return progressTracker.jobsInProgress[jobIndex].activitiesInProgress;
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  // Download Actions

  /**
   * Add a new download activity to a job
   * @param {string} jobId Id of the job to add activity to
   * @param {PWFileType} item the File to be downloaded
   * @param {AbortController} abortController Controller for aborting the download from the progress modal
   * @returns {string} The unique Id to reference the activity
   */
  const addDownloadToJob = useCallback(
    (
      jobId: string,
      item: PWFileType,
      abortController: AbortController
    ): string => {
      const title = downloadTitle(item);
      const uniqueId = generateUUID();
      const download = {
        status: 'inprogress',
        size: Number(item.FileSize),
        amountTransferred: 0,
        label: item.Name,
        item: item as PWItem,
        uniqueId: uniqueId,
        title: title,
        activityType: 'download',
        abortController: abortController
      } as InProgressActivity;
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress.push(download);
      return uniqueId;
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Adding a new replace activity to a job
   * @param {string} jobId Id of the job to add activity to
   * @param {File} file The file being replaced
   * @param {AbortController} abortController Controller for aborting the replace from the progress modal
   * @returns {string} The unique Id to reference the activity
   */
  const addReplaceToJob = useCallback(
    (jobId: string, file: File, abortController: AbortController): string => {
      const item = castFileToDataItem(file);
      const title = parseRelatedParent(item)?.Name
        ? `${t('InProgress.InFolder', {
            name: item.Name,
            parentName: parseRelatedParent(item)?.Name
          })}`
        : `${item.Name}`;
      const uniqueId = generateUUID();
      const replaceFile = {
        status: 'inprogress',
        size: file.size,
        amountTransferred: 0,
        label: file.name,
        item: item,
        title: title,
        uniqueId: uniqueId,
        activityType: 'upload',
        abortController: abortController
      } as InProgressActivity;
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress.push(
        replaceFile
      );
      return uniqueId;
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  // Workflow Actions
  const addWorkflowActivityToJob = useCallback(
    (jobId: string, item: PWItem, abortController: AbortController): string => {
      const title = parseRelatedParent(item)?.Name
        ? `${t('InProgress.InFolder', {
            name: item.Name,
            parentName: parseRelatedParent(item)?.Name
          })}`
        : `${item.Name}`;
      const uniqueId = generateUUID();
      const workflowActivity = {
        status: 'inprogress',
        size: 0,
        amountTransferred: 0,
        label: item.Name,
        item: item,
        uniqueId: uniqueId,
        title: title,
        activityType: 'workflow',
        abortController: abortController
      } as InProgressActivity;
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress.push(
        workflowActivity
      );
      if (autoDisplayModal) {
        openIPModal();
      }
      return uniqueId;
    },
    [autoDisplayModal, getJobIndex, openIPModal, progressTracker.jobsInProgress]
  );

  // Clearing items from tracker

  /**
   * Clears all failures from a job in the tracker
   * @param {string} jobId Id of job to remove failures from
   */
  const clearFailuresFromJob = useCallback(
    (jobId: string): void => {
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress =
        progressTracker.jobsInProgress[index].activitiesInProgress.filter(
          (aip) => aip.error == undefined || aip.error.status == 409
        );
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Clears all conflicts from a job in the tracker
   * @param {string} jobId Id of job to remove conflicts from
   */
  const clearConflictsFromJob = useCallback(
    (jobId: string): void => {
      const index = getJobIndex(jobId);
      progressTracker.jobsInProgress[index].activitiesInProgress =
        progressTracker.jobsInProgress[index].activitiesInProgress.filter(
          (aip) => aip.error?.status !== 409
        );
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Sets the error status for an aborted activity
   * @param {string} jobId The job that the aborted activity belongs to
   * @param {string} activityId The Id of the aborted activity
   */
  const clearAbortedActivityData = useCallback(
    (jobId: string, activityId: string): void => {
      const jobIndex = getJobIndex(jobId);
      progressTracker.jobsInProgress[jobIndex].activitiesInProgress =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress.filter(
          (aip) => aip.uniqueId !== activityId
        );
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  // Update Status

  /**
   * Update the status of an activity within a job
   * @param {string} jobId the Id of the job that the activity belongs to
   * @param {string} activityId the Id of the activity
   * @param {InProgressActivityStatus} newStatus the new status to update the activity with
   * @param {Response} [errorResponse] the response from a failed fetch, only included when setting status to 'error'
   * @param {File} [file] File for conflict resolution only
   * @param {PWParentType} [parent] Parent of file for conflict resolution only
   */
  const updateProgressStatus = useCallback(
    (
      jobId: string,
      activityId: string,
      newStatus: InProgressActivityStatus,
      errorResponse?: Response,
      file?: File,
      parent?: PWParentType
    ): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const activity =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ];
      if (activity) {
        activity.status = newStatus;
      }
      if (errorResponse && activity) {
        activity.error = errorResponse;
        if (file && parent) {
          const dataItem = castFileToFileType(file, parent);
          activity.item = dataItem;
        }
        activity.file = file;
      }
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );

  const addWarningMessageToActivity = useCallback(
    (jobId: string, activityId: string, warning: string): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const warningMessage =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].warning;
      if (warningMessage && warningMessage.indexOf(warning) == -1) {
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].warning += ` | ${warning}`;
      } else {
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].warning = warning;
      }
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );

  const addSuccessMessageToActivity = useCallback(
    (jobId: string, activityId: string, success: string): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const warningMessage =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].success;
      if (warningMessage && warningMessage.indexOf(success) == -1) {
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].success += ` | ${success}`;
      } else {
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].success = success;
      }
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );
  /**
   * Add the amount of bytes transferred for a portion of an upload
   * @param {string} jobId The Id of the job that the activity belongs to
   * @param {string} activityId The Id of the activity
   * @param {number} amountTransferred The number of bytes that have been transferred
   * @param {boolean} [transferComplete] Optional param to set activity's amount transferred to 100%
   */
  const updateAmountUploaded = useCallback(
    (
      jobId: string,
      activityId: string,
      amountTransferred: number,
      transferComplete?: boolean
    ): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const activity =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ];
      if (transferComplete) {
        activity.amountTransferred = activity.size;
      } else {
        activity.amountTransferred = amountTransferred;
        if (
          activity.size - activity.amountTransferred <
          MAX_UPLOAD_CHUNK_SIZE
        ) {
          activity.abortDisabled = true;
        }
      }
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Update the amount of bytes that have been transferred for a download
   * @param {string} jobId The Id of the job that the activity belongs to
   * @param {string} activityId The Id of the activity
   * @param {number} amountTransferred The number of bytes that have been transferred
   */
  const updateAmountDownloaded = useCallback(
    (jobId: string, activityId: string, amountTransferred: number): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const activity =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ];

      activity.amountTransferred = amountTransferred;
      const size = activity.size;

      if (
        size == amountTransferred &&
        activity.status != 'abort' &&
        activity.status != 'error'
      ) {
        updateProgressStatus(jobId, activityId, 'success');
      }
    },
    [
      getActivityIndex,
      getJobIndex,
      progressTracker.jobsInProgress,
      updateProgressStatus
    ]
  );

  const updateToastForJob = useCallback(
    (jobId: string, newToast: ToastHandle): void => {
      const jobIndex = getJobIndex(jobId);
      progressTracker.jobsInProgress[jobIndex].toastHandle = newToast;
    },
    [getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Don't allow aborting activity if past certain stage
   * @param {string} jobId Id of job to update
   * @param {string} activityId Id of activity to update
   */
  const disableAbortForActivity = useCallback(
    (jobId: string, activityId: string): void => {
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
        activityIndex
      ].abortDisabled = true;
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );

  /**
   * Update the meta data on a PWItem being tracked by Progress Manager
   * @param {string} jobId Id of job that item belongs to
   * @param {string} activityId Id of activity that item belongs to
   * @param {PWItem} newItem The Item with new meta data
   */
  const updateItemInfo = useCallback(
    (jobId: string, activityId: string, newItem: PWItem): void => {
      const item = newItem as PWDataItem;
      const jobIndex = getJobIndex(jobId);
      const activityIndex = getActivityIndex(jobIndex, activityId);
      const oldItem =
        progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
          activityIndex
        ].item;
      progressTracker.jobsInProgress[jobIndex].activitiesInProgress[
        activityIndex
      ].item = { ...oldItem, ...item };
    },
    [getActivityIndex, getJobIndex, progressTracker.jobsInProgress]
  );

  const progressManager = useMemo(
    (): ProgressManager => ({
      progressTracker,
      IPModal,
      openIPModal,
      closeIPModal,
      addNewJobToTracker,
      removeJobFromTracker,
      addUploadToJob,
      addUploadFolderToJob,
      getJobActivities,
      addDownloadToJob,
      addReplaceToJob,
      addWorkflowActivityToJob,
      reactivateOldJob,
      clearFailuresFromJob,
      clearConflictsFromJob,
      clearAbortedActivityData,
      clearCompletedJobs,
      updateProgressStatus,
      addWarningMessageToActivity,
      addSuccessMessageToActivity,
      updateAmountUploaded,
      updateAmountDownloaded,
      disableAbortForActivity,
      updateItemInfo,
      updateToastForJob,
      setAutoDisplayModal,
      stopAllInProgress
    }),
    [
      progressTracker,
      IPModal,
      openIPModal,
      closeIPModal,
      addNewJobToTracker,
      removeJobFromTracker,
      addUploadToJob,
      addUploadFolderToJob,
      getJobActivities,
      addDownloadToJob,
      addReplaceToJob,
      addWorkflowActivityToJob,
      reactivateOldJob,
      clearFailuresFromJob,
      clearConflictsFromJob,
      clearAbortedActivityData,
      clearCompletedJobs,
      updateProgressStatus,
      addWarningMessageToActivity,
      addSuccessMessageToActivity,
      updateAmountUploaded,
      updateAmountDownloaded,
      disableAbortForActivity,
      updateItemInfo,
      updateToastForJob,
      stopAllInProgress
    ]
  );

  return progressManager;
}
