import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigationContext, usePluginContext } from '../../../context';
import type {
  CoAuthoringError,
  CoAuthoringSessionContext
} from './coAuthoringTypes';
import { useCoAuthoringClient } from './useCoAuthoringClient';
import { isCoAuthoringError } from './utils';

type CoauthoringManager = {
  coAuthoringSessions: CoAuthoringSessionContext[];
  refreshCoAuthoringSessions: () => Promise<string[] | undefined>;
};

export function useCoAuthoringSessions(): CoauthoringManager {
  const { readOnly } = usePluginContext();

  const { navigationManager } = useNavigationContext();

  const { getCoAuthoringSessions } = useCoAuthoringClient();

  const coAuthoringSessionsCache = useRef<CoAuthoringSessionContext[]>([]);
  const [coAuthoringSessions, setCoAuthoringSessions] = useState<
    CoAuthoringSessionContext[]
  >([]);

  const updateCoauthoringSessions = useCallback(
    (sessions: CoAuthoringSessionContext[] | CoAuthoringError) => {
      if (isCoAuthoringError(sessions)) {
        return;
      }

      // Prevent updating state if the sessions are the same
      // React treats arrays as different objects even if they have the same content
      // This prevents re-renders when the content is the same
      if (_.isEqual(sessions, coAuthoringSessionsCache.current)) {
        return;
      }

      coAuthoringSessionsCache.current = sessions;
      setCoAuthoringSessions(sessions);
    },
    []
  );

  const refreshCoAuthoringSessions = useCallback(async () => {
    if (readOnly) {
      return;
    }

    const sessions = await getCoAuthoringSessions();

    if (isCoAuthoringError(sessions)) {
      return;
    }

    const changedSessions = [
      ...sessions.filter(
        (session) =>
          !coAuthoringSessionsCache.current.find(
            (cachedSession) => cachedSession.sessionId == session.sessionId
          )
      ),
      ...coAuthoringSessionsCache.current.filter(
        (cachedSession) =>
          !sessions.find(
            (session) => cachedSession.sessionId == session.sessionId
          )
      )
    ];

    updateCoauthoringSessions(sessions);

    return changedSessions.map((session) => session.sessionId);
  }, [getCoAuthoringSessions, updateCoauthoringSessions, readOnly]);

  useEffect(() => {
    void refreshCoAuthoringSessions();
  }, [refreshCoAuthoringSessions, navigationManager.currentParent]);

  const coauthoringManager = useMemo(() => {
    return {
      coAuthoringSessions,
      refreshCoAuthoringSessions
    };
  }, [coAuthoringSessions, refreshCoAuthoringSessions]);

  return coauthoringManager;
}
