import { useEffect } from 'react';

export function useConnectionsListResizer(): void {
  useEffect(() => {
    const connectionsList = document.getElementById(
      'connections-list-nav-panel'
    );
    const connectionsListPlaceholder = document.getElementById(
      'connections-list-placeholder'
    );
    if (!connectionsList || !connectionsListPlaceholder) {
      console.error(
        'Do not use this hook outside of ConnectionsList component'
      );
      return;
    }

    function hideConnectionsList(): void {
      connectionsList?.classList.add('connections-list-hidden');
      connectionsListPlaceholder?.classList.remove(
        'connections-list-placeholder-hidden'
      );
    }

    function showConnectionsList() {
      connectionsList?.classList.remove('connections-list-hidden');
      if (connectionsList) {
        connectionsList.style.width = '270px';
      }
      connectionsListPlaceholder?.classList.add(
        'connections-list-placeholder-hidden'
      );
    }

    connectionsListPlaceholder.addEventListener('click', showConnectionsList);

    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].target.clientWidth;

      if (width < 100) {
        hideConnectionsList();
      }
    });

    resizeObserver.observe(connectionsList);

    return () => {
      connectionsListPlaceholder.removeEventListener(
        'click',
        showConnectionsList
      );
      resizeObserver.unobserve(connectionsList);
    };
  }, []);
}
