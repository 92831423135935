import React, { useMemo } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { filterDataItems, itemIsParentType } from '@bentley/pw-api';
import { SvgImodel } from '@itwin/itwinui-icons-react';
import {
  MapIModelModal,
  MapSavedSearchToIModelModal,
  UnmapIModelModal,
  UnmapSavedSearchModal,
  allowIModelMapping,
  allowIModelUnmapping,
  iModelMappingRbacPermitted,
  isMappableFile
} from '../../../../actions/imodelMapping';
import {
  useAppViewContext,
  useConnectionAuth,
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';

export function useIModelActions(items: PWItem[]): MenuItemAction {
  const { rbacPermissions } = useConnectionAuth();
  const {
    readOnly,
    consumerApp,
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();
  const { iModels } = useEnvironmentContext();
  const { primaryModal, searchState } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const { dataManager, savedSearchIModels, selectedState } =
    useTableItemContext();

  const enableSavedSearchUnmap = useMemo((): boolean => {
    return savedSearchIModels != undefined && savedSearchIModels != '';
  }, [savedSearchIModels]);

  const iModelMappingSupportedEnvironment = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }
    if (
      consumerApp == 'ProjectWise 365' ||
      consumerApp == 'ProjectWise 365 in Teams' ||
      consumerApp == 'Synchro'
    ) {
      return false;
    }
    return true;
  }, [consumerApp, readOnly]);

  const showMapItemToIModel = useMemo((): boolean => {
    if (!items.every((item) => isMappableFile(item as PWDataItem))) {
      return false;
    }
    if (!iModelMappingSupportedEnvironment) {
      return false;
    }
    if (
      !selectedState.selectedItems.length &&
      currentAppView.type == 'SavedSearch'
    ) {
      return false;
    }
    if (items.some(itemIsParentType)) {
      return false;
    }
    if (!items.length) {
      return false;
    }
    return true;
  }, [
    selectedState.selectedItems.length,
    iModelMappingSupportedEnvironment,
    items,
    currentAppView.type
  ]);

  const showUnmapItemFromIModel = useMemo((): boolean => {
    if (!iModelMappingSupportedEnvironment) {
      return false;
    }
    return showMapItemToIModel;
  }, [iModelMappingSupportedEnvironment, showMapItemToIModel]);

  const showMapSearchToIModel = useMemo((): boolean => {
    if (!iModelMappingSupportedEnvironment) {
      return false;
    }
    if (!searchState.savedSearch) {
      return false;
    }
    if (selectedState.selectedItems.length) {
      return false;
    }
    return true;
  }, [
    selectedState.selectedItems.length,
    iModelMappingSupportedEnvironment,
    searchState.savedSearch
  ]);

  const showUnmapSearchFromIModel = useMemo((): boolean => {
    if (!iModelMappingSupportedEnvironment) {
      return false;
    }

    return showMapSearchToIModel;
  }, [iModelMappingSupportedEnvironment, showMapSearchToIModel]);

  const mapItemToIModel = useMemo(
    (): MenuItemAction => ({
      title: t('IModel.MapToIModel'),
      icon: <SvgImodel />,
      onClick: () =>
        primaryModal.open(
          <MapIModelModal
            items={filterDataItems(items)}
            onComplete={dataManager.refreshCurrentFolder}
          />
        ),
      disabled:
        !showMapItemToIModel ||
        !allowIModelMapping(
          items,
          rbacPermissions,
          allItemsExistInConnection,
          iModels
        ),
      hidden: !showMapItemToIModel
    }),
    [
      items,
      rbacPermissions,
      allItemsExistInConnection,
      iModels,
      showMapItemToIModel,
      primaryModal,
      dataManager.refreshCurrentFolder
    ]
  );

  const unmapItemFromIModel = useMemo(
    (): MenuItemAction => ({
      title: t('IModel.UnmapFromIModel'),
      icon: <SvgImodel />,
      onClick: () =>
        primaryModal.open(
          <UnmapIModelModal
            items={filterDataItems(items)}
            onComplete={dataManager.refreshCurrentFolder}
          />
        ),
      disabled: !allowIModelUnmapping(items, rbacPermissions, iModelBridges),
      hidden: !showUnmapItemFromIModel
    }),
    [
      items,
      primaryModal,
      rbacPermissions,
      dataManager.refreshCurrentFolder,
      showUnmapItemFromIModel,
      iModelBridges
    ]
  );

  const mapSearchToIModel = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.MapSearchToIModel'),
      icon: <SvgImodel />,
      onClick: () =>
        primaryModal.open(
          <MapSavedSearchToIModelModal
            onComplete={dataManager.refreshCurrentFolder}
          />
        ),
      disabled: !iModelMappingRbacPermitted(rbacPermissions),
      hidden: !showMapSearchToIModel
    }),
    [
      primaryModal,
      rbacPermissions,
      dataManager.refreshCurrentFolder,
      showMapSearchToIModel
    ]
  );

  const unmapSearchFromIModel = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.UnmapSearchIModel'),
      icon: <SvgImodel />,
      onClick: () =>
        primaryModal.open(
          <UnmapSavedSearchModal
            onComplete={dataManager.refreshCurrentFolder}
          />
        ),
      disabled: !enableSavedSearchUnmap,
      hidden: !showUnmapSearchFromIModel
    }),
    [
      primaryModal,
      dataManager.refreshCurrentFolder,
      enableSavedSearchUnmap,
      showUnmapSearchFromIModel
    ]
  );

  const childItems = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems([
        mapSearchToIModel,
        unmapSearchFromIModel,
        mapItemToIModel,
        unmapItemFromIModel
      ]),
    [
      mapItemToIModel,
      mapSearchToIModel,
      unmapItemFromIModel,
      unmapSearchFromIModel
    ]
  );

  const iModelActions = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.IModel'),
      icon: <SvgImodel />,
      disabled: childItems.every((item) => item.hidden || item.disabled),
      hidden:
        childItems.every((item) => item.hidden) ||
        currentAppView.type == 'RecycleBin',
      submenuItems: childItems
    }),
    [childItems, currentAppView.type]
  );

  return iModelActions;
}
