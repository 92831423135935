import type { PWItem } from '@bentley/pw-api';
import { itemIsFlatSet, itemIsProject } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';

export function notifyDownloadInProgress(): ToastHandle {
  const content = t('Downloads.Notifications.DownloadInProgress');
  return openToast({
    content,
    type: 'persisting',
    spinner: true
  });
}

export function notifyProcessing(toastHandle: ToastHandle): ToastHandle {
  const content = t('Downloads.Notifications.ProcessingDownload');
  return replaceToast(toastHandle, {
    content,
    spinner: true,
    type: 'persisting'
  });
}

export function notifyDownloadAborted(toastHandle: ToastHandle): ToastHandle {
  const content = t('Downloads.Notifications.Cancelled');
  return replaceToast(toastHandle, { content, category: 'negative' });
}

export function notifySuccess(
  toastHandle: ToastHandle,
  consumerApp?: ConsumerApp
): ToastHandle {
  const content =
    consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams'
      ? t('Downloads.Notifications.DownloadCompletedTeams')
      : t('Downloads.Notifications.DownloadCompleted');

  return replaceToast(toastHandle, { content, category: 'positive' });
}

export function notifyDownloadCancelled(toastHandle: ToastHandle): void {
  const content = t('Downloads.Notifications.Cancelled');

  replaceToast(toastHandle, { content });
}

export function notifyEmptyDownload(
  toastHandle: ToastHandle,
  error: string
): ToastHandle {
  const content = t('Downloads.Notifications.ErrorDuringDownload', { error });
  return replaceToast(toastHandle, { content, category: 'warning' });
}

export function notifyDownloadError(
  error: string,
  items: PWItem,
  toastHandle: ToastHandle,
  cancelAllClicked?: boolean
): void {
  if (error == 'Empty Folder' && itemIsProject(items)) {
    const errMsg = t('Downloads.Notifications.FolderEmpty');
    notifyEmptyDownload(toastHandle, errMsg);
  } else if (error == 'Empty Folder' && itemIsFlatSet(items)) {
    const errMsg = t('Downloads.Notifications.SetEmpty');
    notifyEmptyDownload(toastHandle, errMsg);
  } else if (error == 'Empty Zip') {
    if (!cancelAllClicked) {
      const errMsg = t('Downloads.Notifications.FolderEmpty');
      notifyEmptyDownload(toastHandle, errMsg);
    } else {
      const content = t('Downloads.Notifications.AllCancelled');
      replaceToast(toastHandle, { content, category: 'negative' });
    }
  } else if (
    error == 'Download aborted' ||
    error == 'The user aborted a request.' ||
    error == 'response.clone is not a function'
  ) {
    const content = t('Downloads.Notifications.Cancelled');
    replaceToast(toastHandle, { content, category: 'negative' });
  } else {
    // Download error modal handles all other errors
    toastHandle.close();
  }
}
