import React from 'react';
import { Text } from '@itwin/itwinui-react';
import { useAppViewContext, useNavigationContext } from '../../context';
import { t } from '../../services/translation';
import { PWModal } from '../pwModal';

type SearchTimeoutModalProps = {
  abortController?: AbortController;
};

export function SearchTimeoutModal({
  abortController
}: SearchTimeoutModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();

  function onCancel(): void {
    primaryModal.close();
    abortController?.abort();
    setAppView('FolderNavigation');
  }

  return (
    <PWModal
      title={t('SearchTimeout.SearchTimeout')}
      primaryButton={{
        title: t('Generic.Continue'),
        onClick: primaryModal.close
      }}
      secondaryButton={{
        title: t('SearchTimeout.CancelSearch'),
        onClick: onCancel
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'SearchTimeoutModal' }}
    >
      <Text>{t('SearchTimeout.SearchTooWide')}</Text>
      <Text>{t('SearchTimeout.ContinueSearching')}</Text>
    </PWModal>
  );
}
