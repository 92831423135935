import React, { useMemo } from 'react';
import { SvgSync } from '@itwin/itwinui-icons-react';
import { PwDriveProjectSyncDialog } from '../../../../actions/drive';
import { useNavigationContext, usePluginContext } from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useDriveOption(): MenuItemAction {
  const { pwDriveData, readOnly } = usePluginContext();
  const { primaryModal } = useNavigationContext();

  const driveOption = useMemo(
    (): MenuItemAction => ({
      title: t('PWDrive.DriveOptions'),
      icon: <SvgSync />,
      onClick: () => primaryModal.open(<PwDriveProjectSyncDialog />),
      isActive:
        pwDriveData.isCurrentConnectionSynced &&
        pwDriveData.validDriveUser != 'LogicalUser',
      hidden: readOnly
    }),
    [
      primaryModal,
      pwDriveData.isCurrentConnectionSynced,
      pwDriveData.validDriveUser,
      readOnly
    ]
  );

  return driveOption;
}
