import './nameCell.css';

import React from 'react';
import { ItemIcon } from '@bentley/pw-file-icons';
import { Text } from '@itwin/itwinui-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import type { MappingDataRecord } from '../iModelMapTable';

export function NameCell({ row }: CellProps<MappingDataRecord>): JSX.Element {
  const dataItem = row.original.DataItem;

  return (
    <span className="imodel-name-cell">
      <ItemIcon item={dataItem} size="m" />
      <Text className="imodel-name-text">{dataItem.Name}</Text>
    </span>
  );
}
