import type { PWItem } from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../../components/ProgressSummary/progressSummaryModal';
import type { CloseModal, OpenModal } from '../../../hooks/useModal';
import type { ToastHandle } from '../../../services/pwToast';
import {
  notifyRemoveErrors,
  notifyRemoveInProgress,
  notifyRemovedCompleted
} from './notifications';

export type RemoveProgressTracker = {
  removedItems: number;
  failedItems: number;
  totalItems: number;
  failures: ProgressSummaryItem[];
};

export function buildRemoveEvents(
  items: PWItem[],
  openModal: OpenModal,
  closeModal: CloseModal,
  onComplete?: () => void
): {
  onCompleteCallback: () => void;
  onRemoveSuccessCallback: (item: PWItem, response: Response) => void;
  onRemoveFailureCallback: (item: PWItem, response: Response) => void;
} {
  const progressTracker = newRemoveProgressTracker(items);
  const toastHandle = notifyRemoveInProgress(items.length);

  function onCompleteCallback(): void {
    notifyRemovedCompleted(progressTracker.removedItems, toastHandle);
    if (progressTracker.failures.length) {
      notifyRemoveErrors(progressTracker.failures, openModal, closeModal);
      onComplete?.();
    } else {
      onComplete?.();
    }
  }

  function onRemoveSuccessCallback(item: PWItem, response: Response): void {
    onRemoveSuccess(progressTracker, toastHandle);
  }

  function onRemoveFailureCallback(item: PWItem, response: Response): void {
    void onRemoveFailure(item, response, progressTracker);
  }

  return {
    onCompleteCallback,
    onRemoveSuccessCallback,
    onRemoveFailureCallback
  };
}

function newRemoveProgressTracker(items: PWItem[]): RemoveProgressTracker {
  return {
    removedItems: 0,
    failedItems: 0,
    totalItems: items.length,
    failures: []
  } as RemoveProgressTracker;
}

function onRemoveSuccess(
  progressTracker: RemoveProgressTracker,
  toastHandle: ToastHandle
): void {
  progressTracker.removedItems++;
}

async function onRemoveFailure(
  item: PWItem,
  response: Response,
  progressTracker: RemoveProgressTracker
): Promise<void> {
  progressTracker.failedItems++;

  const responseBody = (await response.json()) as Record<string, string> | null;
  const errorText =
    (responseBody && responseBody.errorMessage) || response.statusText;
  progressTracker.failures.push({ item, errorText });
}
