import './attributesToolbarContainer.css';

import React from 'react';
import { usePluginContext } from '../../../context';
import { AttributesFormToolbar } from './attributesFormToolbar';
import { EnvironmentInterfaceSelect } from './environmentInterfaceSelect';

export function AttributesToolbarContainer(): JSX.Element {
  const { readOnly } = usePluginContext();
  return (
    <div
      className="attributesToolbarContainer"
      data-testid="attributes-toolbar-container"
    >
      {!readOnly && <AttributesFormToolbar />}
      <EnvironmentInterfaceSelect />
    </div>
  );
}
