import type {
  HttpService,
  PWDataItem,
  PWItemClass,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import { parseResponseInstances } from '@bentley/pw-api';
import { escapeSearchString } from '../../services/search';

export async function getConflictingProjects(
  projectId: string,
  uploadNames: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWProject[]> {
  const url = 'PW_WSG/Project/$query';
  const filterString = buildFilterString(projectId, 'Project', uploadNames);

  const response = await httpService.post(url, filterString, httpOptions);
  const items = await parseResponseInstances<PWProject>(response);
  return items;
}

export async function getConflictingFiles(
  projectId: string,
  uploadNames: string[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWDataItem[]> {
  const url = `PW_WSG/Document!poly/$query`;
  const filterString = buildFilterString(projectId, 'Document', uploadNames);

  const response = await httpService.post(url, filterString, httpOptions);
  const items = await parseResponseInstances<PWDataItem>(response);
  return items;
}

function buildFilterString(
  projectId: string,
  itemClass: PWItemClass,
  uploadNames: string[]
): string {
  const parentFilter = parentFilterString(projectId, itemClass);
  const nameFilter = nameFilterString(uploadNames, itemClass);
  const selectString = buildSelectString(itemClass);

  return `$filter=${parentFilter} and (${nameFilter})&$select=${selectString}`;
}

function parentFilterString(projectId: string, itemClass: PWItemClass): string {
  return `PW_WSG.${itemClass}Parent-forward-PW_WSG.Project.$id eq '${projectId}'`;
}

function nameFilterString(
  uploadNames: string[],
  itemClass: PWItemClass
): string {
  const names = removeDuplicates(uploadNames);
  return names
    .map((name) => {
      name = escapeSearchString(name);
      const nameFilter = `Name eq '${name}'`;
      const fileNameFilter =
        itemClass == 'Project' ? '' : ` or FileName eq '${name}'`;
      return `${nameFilter}${fileNameFilter}`;
    })
    .join(' or ');
}

function buildSelectString(itemClass: PWItemClass): string {
  const className = itemClass == 'Project' ? 'Project' : 'Document';
  return `*,${className}Parent-forward-Project.*`;
}

function removeDuplicates(names: string[]): string[] {
  return names.reduce((names, name) => {
    if (names.includes(name.toLowerCase())) {
      return names;
    }

    return [...names, name.toLowerCase()];
  }, [] as string[]);
}
