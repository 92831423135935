import type { HttpService } from '@bentley/pw-api';

const versionEndpoint = 'schema/class/id';

export type HttpConfiguration =
  | { httpService: HttpService; abortController?: AbortController }
  | {
      connectionUrl: string;
      samlToken: string;
      abortController?: AbortController;
    };

export async function contactConnectionEndpoint(
  httpConfig: HttpConfiguration
): Promise<Response> {
  if ('httpService' in httpConfig) {
    return contactWithHttpService(
      httpConfig.httpService,
      httpConfig.abortController
    );
  }

  return contactWithToken(
    httpConfig.connectionUrl,
    httpConfig.samlToken,
    httpConfig.abortController
  );
}

export async function contactPluginsEndpoint(
  serverUrl: string
): Promise<Response> {
  if (!serverUrl.endsWith('/')) {
    serverUrl += '/';
  }

  return fetch(`${serverUrl}v2.0/Plugins`);
}

function contactWithHttpService(
  httpService: HttpService,
  abortController?: AbortController
): Promise<Response> {
  return httpService.get(versionEndpoint, {
    abortController,
    cacheSuffix: 'persistent'
  });
}

function contactWithToken(
  connectionUrl: string,
  samlToken: string,
  abortController?: AbortController
): Promise<Response> {
  const url = `${connectionUrl}/${versionEndpoint}`;
  return fetch(url, {
    headers: { Authorization: `Token ${samlToken}` },
    signal: abortController?.signal
  });
}
