import { useCallback, useMemo } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { useOutToMeSearch } from '../../useOutToMeSearch/useOutToMeSearch';
import type { AppViewSetting } from './appViewSetting';

export function useOutToMeView(): AppViewSetting {
  const outToMe = useOutToMeSearch();

  const fetchItems = useCallback(async (): Promise<PWDataItem[]> => {
    return outToMe();
  }, [outToMe]);

  function clearView(): void {
    // No-op
  }

  const outToMeViewSetting = useMemo(
    (): AppViewSetting => ({
      type: 'OutToMe',
      fetchItems,
      clearView
    }),
    [fetchItems]
  );

  return outToMeViewSetting;
}
