import type { ToastHandle } from '../../services/pwToast';
import { openToast, replaceToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { MoveProgressTracker } from './progressTracker';

export function notifyMoveInProgress(itemCount: number): ToastHandle {
  const content =
    itemCount == 1
      ? t('Move.GenericInProgress')
      : t('Move.GenericInProgressMulti');

  return openToast({
    content,
    type: 'persisting',
    spinner: true
  });
}

export function notifyMoveComplete(
  progressTracker: MoveProgressTracker,
  toastHandle: ToastHandle
): void {
  if (progressTracker.successCount == progressTracker.total) {
    const content =
      progressTracker.total == 1
        ? t('Move.MovedOneSuccessfully')
        : t('Move.MovedMultipleSuccessfully', {
            successCount: progressTracker.successCount
          });

    replaceToast(toastHandle, { content, category: 'positive' });
  } else {
    replaceToast(toastHandle, {
      content: t('Move.ErrorMovingItems'),
      category: 'negative'
    });
  }
}
