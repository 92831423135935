import type { HttpService, PWItem, PWParentType } from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary/progressSummaryModal';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { resolveConflicts } from './conflictResolution';
import { conflictResolutionModal, uploadFailureSummaryModal } from './modals';

export function handleFailedUploads(
  progressManager: ProgressManager,
  jobId: string,
  parent: PWParentType,
  openModal: OpenModal,
  closeModal: CloseModal,
  httpService: HttpService,
  trackFeature: TrackFeature,
  onComplete?: (uploadedItems: PWItem[]) => void,
  originalItem?: PWItem
): void {
  const job = progressManager.progressTracker.jobsInProgress.find(
    (jip) => jip.jobId == jobId
  );
  if (!job) {
    return;
  }
  const failures = job.activitiesInProgress.filter(
    (aip) =>
      (aip.status == 'error' || aip.status == 'abort') &&
      aip.error?.status !== 409
  );
  const conflicts = job.activitiesInProgress.filter(
    (aip) => aip.error?.status == 409
  );
  const showErrorModal = failures.length
    ? () => {
        uploadFailureSummaryModal(
          openModal,
          closeModal,
          failures.map(
            (failure) =>
              ({
                item: failure.item,
                errorText:
                  failure.warning ??
                  getUploadErrorText(failure.error as ParsableResponse),
                uniqueId: failure.uniqueId
              } as ProgressSummaryItem)
          )
        );
      }
    : undefined;

  if (conflicts.length) {
    const currentJob = progressManager.progressTracker.jobsInProgress.find(
      (job) => job.jobId === jobId
    );
    currentJob?.toastHandle?.close();

    conflictResolutionModal(
      conflicts.map(({ item, file }) => ({ item, file })),
      parent,
      openModal,
      closeModal,
      (resolutions) => {
        void resolveConflicts(
          resolutions,
          httpService,
          trackFeature,
          openModal,
          closeModal,
          progressManager,
          jobId,
          onComplete,
          originalItem
        );
      },
      () => {
        showErrorModal?.();
      }
    );
  } else {
    showErrorModal?.();
  }
}

// Todo: we never pass in anything other than a Response, so we need to consider if/how we are accessing the data properties
type ParsableResponse = Response &
  Record<string, string & Record<string, string>>;

export function getUploadErrorText(response: ParsableResponse): string {
  if (
    response.status === 403 ||
    (response.status === 404 &&
      response.data &&
      response.data.errorId &&
      response.data.errorId == 'InstanceNotFound')
  ) {
    return t('Upload.AccessDenied');
  } else if (response.status === 412) {
    // only try to do reuploads if the response is a 412 etag lost issue because that should likely be fine to reupload other errors codes are internal server errors that might not work to reupload
    return t('Upload.LostETag');
  } else if (response.status == 413) {
    // File too large; remove from in progress
    if (response.errorMessage) {
      const message = response.errorMessage as string;
      return message.substring(0, message.indexOf('(')) || message;
    } else {
      return t('Upload.FileTooLarge');
    }
  } else if (response.status === 409) {
    // file conflict error
    return t('Upload.NamingConflict');
  }
  if (response.status === 502) {
    return t('Upload.LostInternet');
  } else if (response.status === 400) {
    return t('Upload.BadRequest');
  } // checks if user has aborted the upload
  if (response.status === 410) {
    return t('Upload.Cancelled');
  } else if (response.status !== -1) {
    return t('Upload.ErrorOccurred');
  } else {
    return '';
  }
}
