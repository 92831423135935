import * as React from 'react';
export const SvgPDFMarkup = (
  props: React.SVGProps<SVGSVGElement>
): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    overflow="visible"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M17,14v4H10V11h4v1H11v5h5V14Zm-2-4v1h1.293l-2.1465,2.1465.707.707L17,11.707V13h1V10ZM9.52,9.93a2.73709,2.73709,0,0,0-1.31.04,3.76732,3.76732,0,0,0,.79.48V10h.62A.35873.35873,0,0,0,9.52,9.93Zm-3.35.06c.17-.05.33-.1.5-.15.21-.05005.42-.1.63-.14a6.59831,6.59831,0,0,1-.65-.85A11.43731,11.43731,0,0,1,6.17,9.99Zm.46-3.98a.22131.22131,0,0,0-.24-.12c-.13.03-.19.16-.21.32a2.64038,2.64038,0,0,0,.21,1.17A2.15667,2.15667,0,0,0,6.63,6.01ZM3.6,11.77a.19411.19411,0,0,0,.18005.31.45116.45116,0,0,0,.33-.12,8.64773,8.64773,0,0,0,.75-1.02A3.37961,3.37961,0,0,0,3.6,11.77ZM8.29,5.14V2H2V14.57H9v-3.7a3.16914,3.16914,0,0,1-1.34-.81,14.21025,14.21025,0,0,0-1.78.48,5.69586,5.69586,0,0,1-1.46,1.84.83577.83577,0,0,1-.76.12.62.62,0,0,1-.4-.92,4.08315,4.08315,0,0,1,1.92-1.24A22.76727,22.76727,0,0,0,6.17,8a3.62251,3.62251,0,0,1-.36-1.83c.14-.92,1.26-.94,1.33.01a8.02031,8.02031,0,0,1-.32,2.11A5.70762,5.70762,0,0,0,7.79,9.6,4.43714,4.43714,0,0,1,9.84,9.59a.6868.6868,0,0,1,.36.41h1.23V5.14ZM8.21,9.97a3.76732,3.76732,0,0,0,.79.48V10h.62a.35873.35873,0,0,0-.1-.07A2.73709,2.73709,0,0,0,8.21,9.97ZM6.67,9.84c.21-.05005.42-.1.63-.14a6.59831,6.59831,0,0,1-.65-.85,11.43731,11.43731,0,0,1-.48,1.14C6.34,9.94,6.5,9.89,6.67,9.84ZM6.63,6.01a.22131.22131,0,0,0-.24-.12c-.13.03-.19.16-.21.32a2.64038,2.64038,0,0,0,.21,1.17A2.15667,2.15667,0,0,0,6.63,6.01ZM3.78,12.08a.45116.45116,0,0,0,.33-.12,8.64773,8.64773,0,0,0,.75-1.02,3.37961,3.37961,0,0,0-1.26.83A.19411.19411,0,0,0,3.78,12.08ZM9.52,9.93a2.73709,2.73709,0,0,0-1.31.04,3.76732,3.76732,0,0,0,.79.48V10h.62A.35873.35873,0,0,0,9.52,9.93ZM6.65,8.85a11.43731,11.43731,0,0,1-.48,1.14c.17-.05.33-.1.5-.15.21-.05005.42-.1.63-.14A6.59831,6.59831,0,0,1,6.65,8.85ZM6.63,6.01a.22131.22131,0,0,0-.24-.12c-.13.03-.19.16-.21.32a2.64038,2.64038,0,0,0,.21,1.17A2.15667,2.15667,0,0,0,6.63,6.01ZM3.6,11.77a.19411.19411,0,0,0,.18005.31.45116.45116,0,0,0,.33-.12,8.64773,8.64773,0,0,0,.75-1.02A3.37961,3.37961,0,0,0,3.6,11.77ZM9.07,2V4.36h2.36Z"
    />
  </svg>
);
