import './tableCell.css';

import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import type { GetItemStatusBadge } from '../../../hooks/useItemStatusBadge';
import type { ColumnDisplayOptions } from '../../../hooks/useViews';
import { TableContextMenu } from './contextMenu/tableContextMenu';
import { CellIcon } from './icon/cellIcon';
import { CellLabel } from './label/cellLabel';

type TableCellProps = {
  displayContent: string;
  displayOptions?: ColumnDisplayOptions;
  item: PWItem;
  itemHasClickAction: boolean;
  propertyName: string;
  getItemStatusBadge: GetItemStatusBadge;
  onItemClick: () => void;
};

export function TableCell(props: TableCellProps): JSX.Element {
  const { displayOptions, item, getItemStatusBadge } = props;

  return (
    <>
      {displayOptions?.displayIcon && (
        <CellIcon item={item} getItemStatusBadge={getItemStatusBadge} />
      )}
      <CellLabel {...props} />
      <CellDecoration {...props} />
    </>
  );
}

function CellDecoration({
  displayOptions,
  item
}: TableCellProps): JSX.Element | null {
  if (!displayOptions?.badges && !displayOptions?.isPrimary) {
    return null;
  }

  return (
    <div className="badges-and-context-menu">
      {displayOptions?.badges?.map((badge, key) => badge({ item, key }))}
      {displayOptions?.isPrimary && <TableContextMenu item={item} />}
    </div>
  );
}
