import type { ProviderProps } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { ProjectWiseConnectionProps } from '../../components/projectWise';
import { SignInErrorBoundary } from '../../components/signIn';
import { ProjectSettingsProvider } from '../settingsProviders';
import { ConnectionAuthProvider } from './connectionAuth';
import { FeatureTrackingProvider } from './featureTracking';
import { FolderExplorerProvider } from './folderExplorer';
import { HttpServiceProvider } from './httpService';
import { PluginProvider } from './plugin';
import { ProductSettingProvider } from './productSettings';
import { TelemetryProvider } from './telemetry';
import { TokenProvider } from './token';

export function AppProviders({
  value,
  children
}: ProviderProps<ProjectWiseConnectionProps>): JSX.Element {
  return (
    <ErrorBoundary FallbackComponent={SignInErrorBoundary}>
      <TokenProvider value={value}>
        <FeatureTrackingProvider value={value}>
          <ConnectionAuthProvider value={value}>
            <TelemetryProvider value={value}>
              <PluginProvider value={value}>
                <ProjectSettingsProvider value={value}>
                  <ProductSettingProvider value={value}>
                    <HttpServiceProvider value={{}}>
                      <FolderExplorerProvider value={undefined}>
                        {children}
                      </FolderExplorerProvider>
                    </HttpServiceProvider>
                  </ProductSettingProvider>
                </ProjectSettingsProvider>
              </PluginProvider>
            </TelemetryProvider>
          </ConnectionAuthProvider>
        </FeatureTrackingProvider>
      </TokenProvider>
    </ErrorBoundary>
  );
}
