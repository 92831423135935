import '../folderExplorer/treeNode.css';

import React from 'react';
import { SvgPwWorkarea } from '@itwin/itwinui-icons-color-react';
import { SvgDatabase } from '@itwin/itwinui-icons-react';
import { Text } from '@itwin/itwinui-react';
import { useConnectionContext, useConnectionsContext } from '../../context';
import { keyPressAsClick } from '../../services/accessibility';
import type { FederatedRepository } from '../../services/support';
import { Chevron, FolderExplorerPlaceholder } from '../folderExplorer';

type ConnectionLinkProps = {
  repository: FederatedRepository;
};

export function ConnectionLink({
  repository
}: ConnectionLinkProps): JSX.Element {
  const { setActiveConnection, connectionError } = useConnectionsContext();
  const { connection, connectionAuthenticated, dataAccessLevel } =
    useConnectionContext();

  function onConnectionClick(): void {
    setActiveConnection(repository.Id);
  }

  const icon =
    dataAccessLevel == 'Datasource' ? (
      <SvgDatabase
        className="pw-icon-xs explorer-icon"
        data-testid="datasource-icon"
      />
    ) : (
      <SvgPwWorkarea
        className="pw-icon-xs explorer-icon"
        data-testid="work-area-icon"
      />
    );

  function className(): string {
    if (repository.Id == connection.Id && !connectionError) {
      return 'tree-node active-node';
    }
    return 'tree-node';
  }

  // Should be authenticated, as the folder explorer is inside an authenticated context and won't show otherwise
  if (
    repository.Id == connection.Id &&
    connectionAuthenticated &&
    !connectionError
  ) {
    return <FolderExplorerPlaceholder />;
  }

  return (
    <div
      className={className()}
      onClick={onConnectionClick}
      onKeyPress={keyPressAsClick(onConnectionClick)}
      role="treeitem"
      data-testid={`connection-${repository.Name}`}
      tabIndex={0}
    >
      <Chevron hasChildren={true} isExpanded={false} />
      {icon}
      <Text
        as="span"
        data-testid={`${repository.Name}-node-label`}
        className="node-label"
      >
        {repository.Name}
      </Text>
    </div>
  );
}
