import React, { useCallback, useMemo } from 'react';
import { getProject, itemIsFlatSet } from '@bentley/pw-api';
import { SvgAdd } from '@itwin/itwinui-icons-react';
import { AddFlatSetMemberModal } from '../../../../actions/flatSetActions';
import {
  useAppViewContext,
  useEnvironmentContext,
  useHttpService,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useAddToSetAction(): MenuItemAction {
  const { readOnly } = usePluginContext();
  const httpService = useHttpService();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    navigationManager: { currentParent },
    primaryModal
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const allowAddToSet = useMemo((): boolean => {
    if (!itemIsFlatSet(currentParent)) {
      return false;
    }
    if (currentParent.IsReadOnly || currentParent.IsFinal) {
      return false;
    }
    return true;
  }, [currentParent]);

  const showAddToSet = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }
    if (!itemIsFlatSet(currentParent)) {
      return false;
    }
    if (!ecPluginFeatures.flatSetActions()) {
      return false;
    }
    if (currentAppView.type != 'FolderNavigation') {
      return false;
    }
    return true;
  }, [currentAppView.type, ecPluginFeatures, currentParent, readOnly]);

  const onAddToSetClick = useCallback(async (): Promise<void> => {
    const flatSetParent = await getProject({
      instanceId: currentParent.ParentGuid,
      httpService
    });
    primaryModal.open(<AddFlatSetMemberModal flatSetParent={flatSetParent} />);
  }, [httpService, currentParent.ParentGuid, primaryModal]);

  const addToSetAction = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.AddToSet'),
      itemTooltip: allowAddToSet ? undefined : t('FlatSet.Add.Final'),
      icon: <SvgAdd />,
      disabled: !allowAddToSet,
      hidden: !showAddToSet,
      onClick: () => void onAddToSetClick()
    }),
    [allowAddToSet, onAddToSetClick, showAddToSet]
  );

  return addToSetAction;
}
