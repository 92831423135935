import { useEffect, useState } from 'react';
import { buildFeatureToggleClient } from '@bentley/pw-config';
import { usePluginContext, useToken } from '../../../context';

export type CoAuthoringFeatureFlags = {
  updateServerCopy: boolean;
};

export function useCoAuthoringFeatureClient(): CoAuthoringFeatureFlags {
  const { getSamlToken } = useToken();
  const { buddiRegionCode } = usePluginContext();

  const [coAuthoringFeatures, setCoAuthoringFeatures] =
    useState<CoAuthoringFeatureFlags>({ updateServerCopy: false });

  useEffect(() => {
    async function initFeatures(): Promise<void> {
      const clientSideId = getCoAuthoringLDClientId(buddiRegionCode);

      const coAuthoringClient = await buildFeatureToggleClient(
        getSamlToken,
        clientSideId
      );
      const updateServerCopy = coAuthoringClient.getVariation(
        'update-server-copy',
        true
      );
      setCoAuthoringFeatures({ updateServerCopy });
    }

    void initFeatures();
  }, [buddiRegionCode, getSamlToken]);

  return coAuthoringFeatures;
}

function getCoAuthoringLDClientId(buddiRegion: string): string {
  if (buddiRegion == '102') {
    return '6424315013a8fb13b4b77376';
  }
  if (buddiRegion == '103') {
    return '64243150f966dd1325a11aae';
  }
  return '6424314ffe700f135b1d461a';
}
