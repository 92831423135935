import React, { useMemo } from 'react';
import { Table } from '@itwin/itwinui-react';
import type { SavedAdvancedQuery } from '../../../components/advancedSearch';
import { useNavigationContext } from '../../../context';
import { t } from '../../../services/translation';
import { useSearchesColumns } from './searchesColumns';

export function SearchesTable(): JSX.Element {
  const { searchState } = useNavigationContext();

  const columns = useSearchesColumns();

  function byName(a: SavedAdvancedQuery, b: SavedAdvancedQuery): number {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return -1;
  }

  const tableData = useMemo(
    (): SavedAdvancedQuery[] => [
      ...(searchState.myConnectionSearches ?? []).sort(byName),
      ...(searchState.myGlobalSearches ?? []).sort(byName)
    ],
    [searchState.myConnectionSearches, searchState.myGlobalSearches]
  );

  return (
    <Table<SavedAdvancedQuery>
      columns={columns}
      data={tableData}
      isSortable={true}
      style={{ maxHeight: '50vh' }}
      density="extra-condensed"
      emptyTableContent={t('SavedAdvSearch.NoSearchesFound')}
    />
  );
}
