import { useCallback, useMemo } from 'react';
import type {
  PWDataItem,
  PWItem,
  PWProject,
  RequestOptions,
  WSGInstancesResponse,
  WSGProperties
} from '@bentley/pw-api';
import {
  getProject,
  itemIsProject,
  parseResponseInstances
} from '@bentley/pw-api';
import {
  useAppViewContext,
  useHttpService,
  usePluginContext,
  usePropertyContext
} from '../../context';
import { batchedFetch } from '../batchedFetch';
import { getItemWorkArea, setItemWorkArea } from './itemWorkArea';
import { getWorkArea } from './workArea';

type EnvironmentAttributesManager = {
  attachEnvironmentAttributesToItemsBatched: (
    items: PWDataItem[],
    httpOptions?: RequestOptions
  ) => Promise<void>;
  attachWorkAreaAttributesToItems: (
    items: PWItem[],
    httpOptions?: RequestOptions
  ) => Promise<void>;
};

export function useEnvironmentAttributes(): EnvironmentAttributesManager {
  const { connection } = usePluginContext();
  const httpService = useHttpService();
  const {
    workAreaManager: { getCachedParent }
  } = useAppViewContext();
  const {
    viewManager: {
      currentView: { columns }
    }
  } = usePropertyContext();

  const attachEnvironmentAttributesToItems = useCallback(
    async (
      items: PWDataItem[],
      httpOptions?: RequestOptions
    ): Promise<void[]> => {
      const filter = `$id in ['${items
        .map((item) => item.instanceId)
        .join("','")}']`;
      const select =
        'PW_WSG.DocumentEnvironment!poly-forward-PW_WSG.Environment!poly.*';

      const url = `PW_WSG/Document!poly/?$filter=(${filter})&$select=${select}`;
      const response = await httpService.get(url, httpOptions);

      if (httpOptions?.abortController?.signal.aborted || !response.ok) {
        return [];
      }

      const data = await parseResponseInstances<PWItem>(response);

      return items.map((item) => {
        const relationshipInstances = data.find(
          ({ instanceId }) => instanceId == item.instanceId
        )?.relationshipInstances;

        if (!relationshipInstances) {
          return;
        }

        const itemRelationshipInstances = item.relationshipInstances ?? [];
        const newRelationshipInstances = relationshipInstances.filter(
          (relationshipInstance) =>
            !itemRelationshipInstances.some(
              ({ instanceId }) => instanceId == relationshipInstance.instanceId
            )
        );

        item.relationshipInstances = [
          ...itemRelationshipInstances,
          ...newRelationshipInstances
        ];
      });
    },
    [httpService]
  );

  const attachEnvironmentAttributesToItemsBatched = useCallback(
    async (
      items: PWDataItem[],
      httpOptions?: RequestOptions
    ): Promise<void> => {
      await batchedFetch<PWDataItem, void>(
        (items) => attachEnvironmentAttributesToItems(items, httpOptions),
        items,
        20
      );
    },
    [attachEnvironmentAttributesToItems]
  );

  const attachWorkAreaToItemsWithSameParent = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      if (httpOptions?.abortController?.signal.aborted) {
        return;
      }

      const workArea = await getWorkArea(
        items[0],
        connection,
        httpService,
        getCachedParent,
        httpOptions
      );

      items.forEach((item) => setItemWorkArea(item, workArea));
    },
    [connection, httpService, getCachedParent]
  );

  const attachWorkAreaToItemsWithoutParentGuid = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      if (httpOptions?.abortController?.signal.aborted) {
        return;
      }

      await Promise.all(
        items.map(async (item) => {
          const workArea = await getWorkArea(
            item,
            connection,
            httpService,
            getCachedParent,
            httpOptions
          );

          setItemWorkArea(item, workArea);
        })
      );
    },
    [connection, httpService, getCachedParent]
  );

  const attachWorkAreaToItems = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      const workAreas = items
        .filter(itemIsProject)
        .filter(({ TypeString }) => TypeString != 'Folder');

      // Work areas should use their own project attributes
      workAreas.forEach((item) => setItemWorkArea(item, item));

      const nonWorkAreas = items.filter(
        (item) => !(workAreas as PWItem[]).includes(item)
      );

      const uniqueParentIds = nonWorkAreas.reduce((uniqueParentIds, item) => {
        if (!item.ParentGuid || uniqueParentIds.includes(item.ParentGuid)) {
          return uniqueParentIds;
        }

        return [...uniqueParentIds, item.ParentGuid];
      }, [] as string[]);

      await Promise.all(
        uniqueParentIds.map(async (parentId) => {
          const itemsWithSameParent = nonWorkAreas.filter(
            (item) => item.ParentGuid == parentId
          );

          await attachWorkAreaToItemsWithSameParent(
            itemsWithSameParent,
            httpOptions
          );
        })
      );

      const itemsWithoutParentGuid = nonWorkAreas.filter(
        (item) => !item.ParentGuid
      );

      await attachWorkAreaToItemsWithoutParentGuid(
        itemsWithoutParentGuid,
        httpOptions
      );
    },
    [
      attachWorkAreaToItemsWithSameParent,
      attachWorkAreaToItemsWithoutParentGuid
    ]
  );

  const fetchProjectTypeClassName = useCallback(
    async (
      projectTypeClassId: number,
      httpOptions?: RequestOptions
    ): Promise<string | undefined> => {
      if (httpOptions?.abortController?.signal.aborted) {
        return;
      }

      const response = await httpService.get(
        `MetaSchema/ECClassDef?$filter=ClassHasBaseClass-forward-ECClassDef.Name eq 'ProjectType' and CustomAttributeContainerHasCustomAttribute-forward-PW_WSG.ElementIdentifier.ElementId eq ${projectTypeClassId}&$select=Name`,
        httpOptions
      );
      const body = (await response.json()) as WSGInstancesResponse;
      const propertyTypeName = body.instances?.[0]?.properties?.[
        'Name'
      ] as string;

      return propertyTypeName;
    },
    [httpService]
  );

  const fetchProjectTypeProperties = useCallback(
    async (
      projectTypeClassName: string,
      projectTypeInstanceId: number,
      httpOptions?: RequestOptions
    ): Promise<WSGProperties | undefined> => {
      if (httpOptions?.abortController?.signal.aborted) {
        return;
      }

      const response = await httpService.get(
        `PW_WSG_Dynamic/${projectTypeClassName}/${projectTypeInstanceId}`,
        httpOptions
      );
      const body = (await response.json()) as WSGInstancesResponse;
      const properties = body.instances?.[0]?.properties;

      return properties;
    },
    [httpService]
  );

  const buildWorkAreasWithAttributes = useCallback(
    async (
      workAreas: PWProject[],
      requestOptions?: RequestOptions
    ): Promise<PWProject[]> => {
      for (const workArea of workAreas) {
        if (requestOptions?.abortController?.signal.aborted) {
          return [];
        }

        if (!workArea.instanceId) {
          continue;
        }

        const { ComponentClassId, ComponentInstanceId } = await getProject({
          instanceId: workArea.instanceId,
          selectProperties: ['ComponentClassId', 'ComponentInstanceId'],
          httpService,
          requestOptions
        });

        const projectTypeClassName = await fetchProjectTypeClassName(
          ComponentClassId,
          requestOptions
        );

        if (
          !projectTypeClassName ||
          !columns.some(
            ({ propertyType }) => propertyType == projectTypeClassName
          )
        ) {
          continue;
        }

        const projectAttributes = await fetchProjectTypeProperties(
          projectTypeClassName,
          ComponentInstanceId,
          requestOptions
        );

        workArea.properties = { ...workArea.properties, projectAttributes };
      }

      return workAreas;
    },
    [
      columns,
      fetchProjectTypeClassName,
      fetchProjectTypeProperties,
      httpService
    ]
  );

  const attachWorkAreaAttributesToItems = useCallback(
    async (items: PWItem[], httpOptions?: RequestOptions): Promise<void> => {
      await attachWorkAreaToItems(items, httpOptions);

      if (httpOptions?.abortController?.signal.aborted) {
        return;
      }

      const workAreas = items
        .map(getItemWorkArea)
        .filter((workArea) => workArea)
        .filter(
          (workArea) => !(workArea as PWProject).properties?.projectAttributes
        ) as PWProject[];
      const uniqueWorkAreas = workAreas.reduce((workAreas, workArea) => {
        if (
          workAreas.some(({ instanceId }) => instanceId == workArea.instanceId)
        ) {
          return workAreas;
        }
        return [...workAreas, workArea];
      }, [] as PWProject[]);

      const workAreasWithAttributes = await buildWorkAreasWithAttributes(
        uniqueWorkAreas,
        { ...httpOptions, cacheSuffix: 'persistent' }
      );

      for (const item of items) {
        const workArea = workAreasWithAttributes.find(
          ({ instanceId }) => getItemWorkArea(item)?.instanceId == instanceId
        );
        setItemWorkArea(item, workArea);
      }
    },
    [attachWorkAreaToItems, buildWorkAreasWithAttributes]
  );

  const environmentAttributesManager = useMemo(
    () => ({
      attachEnvironmentAttributesToItemsBatched,
      attachWorkAreaAttributesToItems
    }),
    [attachEnvironmentAttributesToItemsBatched, attachWorkAreaAttributesToItems]
  );

  return environmentAttributesManager;
}
