import type { AuthorizationService } from '@bentley/pw-api';
import type { ConsumerApp } from '../../context';
import type { UserCredentials } from './useAuthorizationService';

/**
 * Gets last used auth type from session storage
 */
export function getCachedSessionCreds(
  connectionId: string,
  consumerApp?: ConsumerApp
): string | null {
  const key = sessionStoragePermissionsKey(connectionId);
  const storage = environmentSessionStorage(consumerApp);
  return storage.getItem(key);
}

/**
 * Saves last used auth type to session storage
 */
export async function setCachedSessionCreds(
  connectionId: string,
  authorizationService: AuthorizationService,
  consumerApp?: ConsumerApp
): Promise<void> {
  const key = sessionStoragePermissionsKey(connectionId);
  const storage = environmentSessionStorage(consumerApp);
  const value = await storedCreds(authorizationService);
  storage.setItem(key, value);
}

/**
 * Clears last used auth type from session storage
 */
export function clearCachedSessionCreds(
  connectionId: string,
  consumerApp?: ConsumerApp
): void {
  const key = sessionStoragePermissionsKey(connectionId);
  const storage = environmentSessionStorage(consumerApp);
  storage.removeItem(key);
}

export function decodeSessionCreds(
  encodedCredentials: string
): UserCredentials {
  const decoded = window.atob(encodedCredentials);
  const [userName, password] = decoded.split(':');
  return { userName, password };
}

function sessionStoragePermissionsKey(connectionId: string): string {
  return `sessionCredentials:${connectionId}`;
}

function environmentSessionStorage(consumerApp?: ConsumerApp): Storage {
  return consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams'
    ? localStorage
    : sessionStorage;
}

async function storedCreds(
  authorizationService: AuthorizationService
): Promise<string> {
  const authorizationType = authorizationService.authorizationType;

  if (authorizationType == 'user credentials') {
    const header = await authorizationService.header();
    return header?.replace('Basic ', '') ?? '';
  }

  return authorizationType;
}
