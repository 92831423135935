import type {
  HttpService,
  RequestOptions,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { ecPluginFeatures } from '../../useECPluginVersion';
import type { View } from '../useViews';
import { folderViewToView, instanceToFolderView } from './instanceToFolderView';

export async function getPWEViewsWithoutColumns(
  connectionProjectId: string,
  httpService: HttpService,
  options?: RequestOptions
): Promise<View[]> {
  const query = buildQuery(connectionProjectId);
  const response = await httpService.get(query, options);
  const views = await parseViews(response);
  return views;
}

function buildQuery(connectionProjectId: string): string {
  if (ecPluginFeatures.projectResourceMetadata() && connectionProjectId) {
    return projectViewsQuery(connectionProjectId);
  }
  return allViewsQuery();
}

function allViewsQuery(): string {
  const baseQuery = 'PW_WSG/View';
  const select = '$select=*,Column.*';
  return `${baseQuery}?${select}`;
}

function projectViewsQuery(connectionProjectId: string): string {
  const select = 'ProjectResource-forward-View.*';
  return `PW_WSG/Project/${connectionProjectId}?$select=${select}`;
}

async function parseViews(response: Response): Promise<View[]> {
  if (!response.ok) {
    throw new Error('Error fetching views');
  }

  const data = (await response.json()) as WSGInstancesResponse;

  if (data.instances[0]?.className == 'Project') {
    return parseProjectViewsResponse(data);
  }

  return parseAllViewsResponse(data);
}

function parseAllViewsResponse(data: WSGInstancesResponse): View[] {
  const folderViews = data.instances.map(instanceToFolderView);
  const views = folderViews.map(folderViewToView);
  return views;
}

function parseProjectViewsResponse(data: WSGInstancesResponse): View[] {
  const relationshipInstances = data.instances[0].relationshipInstances ?? [];
  const folderViews = relationshipInstances.map(({ relatedInstance }) =>
    instanceToFolderView(relatedInstance)
  );
  const views = folderViews.map(folderViewToView);
  return views;
}
