import Sanitize from 'sanitize-filename';
import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem
} from '@bentley/pw-api';
import {
  getProject,
  itemIsFlatSet,
  itemIsLogicalSet,
  itemIsProject,
  parseRelatedParent
} from '@bentley/pw-api';
import type { Connection } from '../../services/support';
import { t } from '../../services/translation';
import type { DownloadNode } from './tree';

export async function getZipFileName(
  items: PWItem[],
  httpService: HttpService,
  searchName?: string
): Promise<string> {
  let zipName = getDefaultZipName();
  if (items.length == 1) {
    zipName = getSingleItemZipName(items[0]);
  } else if (searchName) {
    zipName = `${searchName} ${t('Download.SearchResults')}`;
  } else if (items[0].ParentGuid) {
    zipName = await getParentFolderZipName(items[0].ParentGuid, httpService);
  } else {
    zipName = getConnectionZipName();
  }
  return `${zipName}.zip`;
}

function getSingleItemZipName(item: PWItem): string {
  if ((item as PWDataItem).FileName) {
    return (item as PWDataItem).FileName;
  }
  return item.Name;
}

async function getParentFolderZipName(
  parentGuid: string,
  httpService: HttpService
): Promise<string> {
  try {
    const parent = await getProject({ instanceId: parentGuid, httpService });
    return `${parent.Name} ${getDateString()}`;
  } catch {
    return getDefaultZipName();
  }
}

function getConnectionZipName(): string {
  const savedConnection = localStorage.getItem('currentConnection');
  if (savedConnection && savedConnection != 'undefined') {
    const connection = JSON.parse(savedConnection) as Connection;
    if (connection.Name) {
      return `${connection.Name} ${getDateString()}`;
    }
  }
  return getDefaultZipName();
}

function getDefaultZipName(): string {
  return `${t('Downloads.ZipName')} ${getDateString()}`;
}

function getDateString(): string {
  const dateString = new Date(Date.now()).toLocaleDateString(undefined, {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  return `(${dateString})`.replace(/,/g, '');
}

export function generateVersionFileName(
  item: PWFileType,
  siblingItems: PWFileType[],
  itemPath: string
): string {
  if (!sameFileNames(item, siblingItems)) {
    return item.FileName;
  }

  // Downloading multiple versions of master reference file with same name but different references need unique names.
  return diffrentiatedName(item, itemPath, true, siblingItems);
}

function diffrentiatedName(
  item: PWDataItem,
  itemPath: string,
  isFile: boolean,
  siblingItems?: PWDataItem[]
): string {
  const fileName = fileNamePrefix(item);
  const version = item.Version ? `_${item.Version}` : '';
  const sequence = String(item.Sequence).padStart(6, '0');
  const differentiator =
    item.Version || item.Sequence == 0 ? `${sequence}${version}` : itemPath;

  function getNameAppend(): string {
    if (item.IsLatest) {
      const conflictingSibling = siblingItems?.find(
        (sibling) =>
          sibling.FileName == item.FileName &&
          sibling.ParentGuid != item.ParentGuid &&
          sibling.instanceId != item.instanceId
      );
      if (conflictingSibling) {
        const parent = parseRelatedParent(item);
        return `_${parent?.Name ?? ''}`;
      } else {
        return '';
      }
    } else {
      return `_${differentiator}`;
    }
  }

  const nameAppend = getNameAppend();
  const extension = fileNameExtension(item)
    ? `.${fileNameExtension(item)}`
    : '';

  const extendedName = isFile
    ? `${nameAppend}${extension}`
    : `${t('Downloads.ReferencedFiles', {
        nameAppend: nameAppend
      })}`;

  const truncatedPrefix = `${fileName}`.substr(0, 255 - extendedName.length);

  if (
    siblingItems &&
    sameFileNames(item, siblingItems) &&
    (itemPath.toLowerCase() == 'flatset' ||
      differentRelativeDirectory(item, siblingItems)) &&
    item.Version == ''
  ) {
    const sameFiles = [
      ...siblingItems.filter((sibling) => sibling.FileName == item.FileName)
    ];
    for (let i = 0; i < sameFiles.length; i++) {
      sameFiles[i].FileName =
        i == 0 ? `${fileName}${extension}` : `${fileName} (${i})${extension}`;
    }
  }

  return `${truncatedPrefix}${extendedName}`;
}

function sameFileNames(item: PWDataItem, siblings: PWDataItem[]): boolean {
  return (
    siblings.filter((sibling) => item.FileName == sibling.FileName).length > 1
  );
}

function differentRelativeDirectory(
  item: PWDataItem,
  siblings: PWDataItem[]
): boolean {
  return (
    siblings.filter(
      (sibling) =>
        item.RelativeDirectory?.split('\\')[0] !=
        sibling.RelativeDirectory?.split('\\')[0]
    ).length > 1
  );
}

function fileNamePrefix(item: PWDataItem): string {
  if (item.FileName.indexOf('.') == -1) {
    return item.FileName;
  }
  return item.FileName.split('.').slice(0, -1).join('.');
}

function fileNameExtension(item: PWDataItem): string {
  if (item.FileName.indexOf('.') == -1) {
    return '';
  }
  return item.FileName.split('.').slice(-1)[0];
}

export function subfolderName(
  parent: PWItem,
  neighboringFolders: DownloadNode[]
): string {
  if (
    itemIsFlatSet(parent) &&
    neighboringFolders.find(
      (f) =>
        f.current &&
        itemIsProject(f.current) &&
        f.current.Name.toLowerCase() == parent?.Name.toLowerCase()
    )
  ) {
    return Sanitize(
      `${t('Downloads.DocumentSet', {
        name: parent.Name
      })}`
    );
  }

  if (itemIsLogicalSet(parent)) {
    const sameFolderName =
      neighboringFolders.filter((folder) => parent.Name == folder.current?.Name)
        .length > 1;

    if (!sameFolderName) {
      return Sanitize(
        `${t('Downloads.ReferencedFiles', {
          nameAppend: parent.Name
        })}`
      );
    }

    // Downloading multiple versions of master reference file with same name but different references need unique names.
    return diffrentiatedName(parent, '', false);
  }
  return Sanitize(parent.Name);
}
