import type {
  HttpService,
  PWDataItem,
  PWItem,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { itemIsPlaceholder } from '@bentley/pw-api';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { openToast } from '../../services/pwToast';
import { t } from '../../services/translation';
import type { PWDriveManager } from '../drive';
import type { DriveResponseData } from '../drive/pwDrive.utils';
import { freeFileDrive } from '../drive/pwDrive.utils';
import { free } from './data';
import { allowCheckForDriveIntegratedMode } from './requirements';

/**
 * Frees (unlocks) and all selected items
 * @param {PWItem[]} selectedItems Items that are currently in selection
 * @param {HttpService} httpService Configured http service
 * */
export async function freeDocument(
  selectedItems: PWItem[],
  httpService: HttpService,
  onSuccess: () => void,
  userId: string,
  trackFeature: TrackFeature,
  contextId: string,
  isAdmin: boolean,
  userName: string,
  driveData: PWDriveManager
): Promise<Response[] | DriveResponseData[]> {
  const dataItems = selectedItems as PWDataItem[];
  let response = [];

  if (
    allowCheckForDriveIntegratedMode(driveData, selectedItems as PWDataItem[])
  ) {
    response = await freePwDriveFile(
      dataItems,
      driveData.httpDriveService,
      userId,
      onSuccess,
      contextId,
      isAdmin,
      userName
    );
  } else {
    response = await freeFile(dataItems, httpService, onSuccess, trackFeature);
  }
  return response;
}

async function freeFile(
  selectedItems: PWDataItem[],
  httpService: HttpService,
  onSuccess: () => void,
  trackFeature: TrackFeature
): Promise<Response[]> {
  try {
    const toggleLockInfo = await Promise.all(
      selectedItems.map((dataItem) => {
        trackFeature('FREE_FILE');
        return free(dataItem, httpService);
      })
    );

    if (toggleLockInfo.some((response) => response.status == 403)) {
      const errorResponse = toggleLockInfo.find(
        (response) => response.status == 403
      );
      const message = ((await errorResponse?.json()) as WSGInstancesResponse)
        .errorMessage;
      openToast({ content: message, category: 'negative' });

      return toggleLockInfo;
    }

    onSuccess();
    const content =
      selectedItems.length == 1
        ? t('Sync.Notification.SuccessfullyFreedFile')
        : t('Sync.Notification.SuccessfullyFreedFiles', {
            count: selectedItems.length
          });
    openToast({ content, category: 'positive' });

    return toggleLockInfo;
  } catch (error) {
    const errorMessage = (error as Error)?.message ?? t('Sync.FailedToFree');
    openToast({ content: errorMessage, category: 'negative' });
    throw new Error(errorMessage);
  }
}

export async function freePwDriveFile(
  selectedItems: PWDataItem[],
  httpDriveService: HttpService,
  userId: string,
  onSuccess: () => void,
  contextId: string,
  isAdmin: boolean,
  userName: string
): Promise<DriveResponseData[]> {
  const freeFileInfo = await Promise.all(
    selectedItems.map((selectedItem) => {
      // Checks if in drive integerated mode we have placeholder file , if yes , executing freefile using non integerated mode

      if (!itemIsPlaceholder(selectedItem)) {
        const fileOwnerName = selectedItem.OutTo?.split('@')[0];
        // Free file functionality should be available only to admin and to the owner of document for rest other users Toast message will be displayed
        if (isAdmin || fileOwnerName?.toLowerCase() == userName.toLowerCase()) {
          return freeFileDrive(
            {
              InstanceId: selectedItem.instanceId,
              UserId: userId,
              ProjectId: contextId
            },
            httpDriveService
          ).catch((res) => {
            return Promise.reject();
          });
        } else {
          return Promise.reject();
        }
      } else {
        return free(selectedItem, httpDriveService);
      }
    })
  );

  if (freeFileInfo.some((item) => item.status == 500 || item.status == 401)) {
    return Promise.reject();
  } else if (freeFileInfo.some((item) => item.status == 503)) {
    return Promise.reject();
  }
  const response = await Promise.all(
    freeFileInfo
      .filter((item) => item.ok)
      .map(async (item) => {
        return (await item.json()) as DriveResponseData;
      })
  );

  if (!freeFileInfo || response.some((item) => !item.data.success)) {
    const fileLockedCount = response.filter(
      (item) => item.data.fileLockedByMe
    ).length;

    // Refresh table if multiple files are freed , out of which some files are successfully freed so refresh the table
    if (fileLockedCount != selectedItems.length) {
      onSuccess();
    }
    throw new Error(t('Sync.FailedToFree'));
  } else {
    onSuccess();
  }

  return response;
}
