import { useEffect } from 'react';
import { getSavedSearch } from '../../actions/savedSearch';
import {
  useAppViewContext,
  useConnectionContext,
  useHttpService,
  useNavigationContext
} from '../../context';

export function useSavedSearchLinkResolution(): void {
  const httpService = useHttpService();
  const {
    selectionState: { savedSearchId }
  } = useConnectionContext();
  const {
    searchState: { setSavedSearch }
  } = useNavigationContext();
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();

  useEffect(() => {
    if (!savedSearchId) {
      return;
    }

    async function executeSavedSearch(searchId: string) {
      const savedSearch = await getSavedSearch(searchId, httpService);

      if (savedSearch?.instanceId && savedSearch?.Name) {
        setTimeout(() => {
          setSavedSearch(savedSearch);
          setAppView('SavedSearch');
        }, 1000);
      }
    }

    void executeSavedSearch(savedSearchId);
  }, [httpService, savedSearchId, setAppView, setSavedSearch]);
}
