import type { PWItemClass, PWParentType } from '@bentley/pw-api';
import { itemIsFlatSet } from '@bentley/pw-api';
import { datasourcePrefix } from '../../constants';
import type { Connection } from '../../services/support/connection';

export const sharedProjectParam = 'project';
export const sharedSetParam = 'set';
export const sharedItemParam = 'item';
export const sharedItemType = 'type';
export const sharedDatasourceId = 'datasource';
export const sharedWorkArea = 'workArea';

export type Application = 'Ask' | 'Explorer' | 'Web' | 'Teams' | 'WebView';

export type LinkItemType = 'folder' | 'doc' | 'saved-search';

const linkConstants = {
  datasource: 'ds',
  objectType: 't',
  objectId: 'id',
  workAreaId: 'wa',
  projectId: 'p'
};

export const getShareableLink = (
  connection: Connection,
  itemId: string,
  itemType: LinkItemType
): string => {
  if (!connection.DatasourceInstanceId) {
    throw 'Datasource ID missing';
  }
  if (!itemId) {
    throw 'Item ID missing';
  }
  if (!itemType) {
    throw 'Item type missing';
  }
  const datasourceQuery = `${sharedDatasourceId}=${connection.DatasourceInstanceId}`;
  const workAreaQuery = connection.ProjectId
    ? `&${sharedWorkArea}=${connection.ProjectId}`
    : '';
  const itemQuery = `${sharedItemParam}=${itemId}`;
  const itemTypeQuery = `${sharedItemType}=${itemType}`;

  const pathWithoutConnection = location.pathname.substring(
    0,
    location.pathname.indexOf('/connection')
  );
  return `${location.origin}${pathWithoutConnection}?${datasourceQuery}&${itemQuery}&${itemTypeQuery}${workAreaQuery}`;
};

export type UniversalQueryProps = {
  datasource?: string;
  workAreaId?: string;
  objectId: string;
  objectType: LinkItemType;
  projectId?: string;
  application: Application;
  apiURL?: string;
  shortenLink?: boolean;
};

export function getUniversalLink({
  datasource,
  workAreaId,
  objectId,
  objectType,
  projectId,
  application,
  apiURL,
  shortenLink
}: UniversalQueryProps): string {
  let datasourceQuery, workAreaQuery, itemQuery, projectQuery;

  if (shortenLink) {
    // Remove 'Bentley.PW--' from datasource name
    if (datasource?.startsWith(datasourcePrefix)) {
      datasource = datasource.substring(datasourcePrefix.length);
    }

    // Remove hyphens from GUIDs
    workAreaId = workAreaId?.replace(/-/g, '');
    objectId = objectId.replace(/-/g, '');
    projectId = projectId?.replace(/-/g, '');

    // Shorten link constants' names and combine objectType and objectId params
    datasourceQuery = `${linkConstants.datasource}=${datasource}`;
    workAreaQuery = workAreaId
      ? `&${linkConstants.workAreaId}=${workAreaId}`
      : '';
    itemQuery = `${objectType == 'folder' ? 'fld' : objectType}=${objectId}`;
    projectQuery = projectId ? `&${linkConstants.projectId}=${projectId}` : '';
  } else {
    datasourceQuery = `${sharedDatasourceId}=${datasource}`;
    workAreaQuery = workAreaId ? `&workAreaId=${workAreaId}` : '';
    itemQuery = `objectId=${objectId}&objectType=${objectType}`;
    projectQuery = projectId ? `&projectId=${projectId}` : '';
  }

  apiURL = apiURL ? apiURL : location.origin;

  if (!apiURL.endsWith('/')) {
    apiURL = `${apiURL}/`;
  }

  const path = shortenLink ? 'link' : 'pwlink';

  if (application == 'WebView') {
    return `${apiURL}link?${datasourceQuery}&${itemQuery}${getApplicationQuery(
      application
    )}`;
  } else {
    const correctedDatasourceQuery =
      application == 'Explorer'
        ? datasourceQuery.replace(/~20/g, '%20')
        : datasourceQuery;
    return `${apiURL}${path}?${correctedDatasourceQuery}&${itemQuery}${workAreaQuery}${projectQuery}${getApplicationQuery(
      application
    )}`;
  }
}

export function getItemType(itemClass: PWItemClass): LinkItemType {
  if (itemClass == 'Project') {
    return 'folder';
  }
  return 'doc';
}

function getApplicationQuery(application: Application) {
  const param = '&app=';
  if (application == 'Explorer') {
    return param + 'pwe';
  }
  if (application == 'Web') {
    return param + 'web';
  }
  if (application == 'WebView') {
    return param + 'webview';
  }
  return '';
}

export function getLocation(
  connection: Connection,
  parent?: PWParentType
): string {
  if (!parent || !parent.instanceId) {
    return connection.Id;
  }

  const parentType = itemIsFlatSet(parent)
    ? sharedSetParam
    : sharedProjectParam;
  return `connection/${connection.Id}?${parentType}=${parent.instanceId}`;
}

export function parseQueryString(searchParameter: string): string | undefined {
  const searchParameters = new URLSearchParams(location.search);
  return (
    searchParameters.get(searchParameter) ||
    searchParameters.get(searchParameter.toLowerCase()) ||
    undefined
  );
}

export function getEncodedContext(
  datasource: string,
  objectGuid: string,
  objectType: LinkItemType,
  wacId: string
): string {
  const context = `${datasource}/${objectGuid}/${objectType}/${wacId}`;
  return base64EncodeUrl(window.btoa(context));
}

// To handle string like "QmVudGxleS5QVy0tUFdXZWJXQUNWTTEuYmVudGxleS5jb21+M0FSYW1ib2xsLzRlOTAwZWIzLTlmNTUtNGFiNS1iOWVkLWI2ZTJjMTAwOWIwNy9mb2xkZXIvYjllMzc4YjAtMzEwOC00ZTAwLWJhMjktNDRjNjFhYWQ0NDkw"
function base64EncodeUrl(str: string): string {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}
