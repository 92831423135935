import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { t } from '../../../../services/translation';
import { SvgProjectWise } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';
import { formatMenuItems } from '../../formatMenuItems';
import { useGoToProjectWiseDriveItemAction } from './useGoToProjectWiseDriveItemAction';
import { useGoToProjectWiseExplorerItemAction } from './useGoToProjectWiseExplorerItemAction';

export function useGoToDropDownAction(items: PWItem[]): MenuItemAction {
  const goToProjectWiseDriveItem = useGoToProjectWiseDriveItemAction(items);
  const goToProjectWiseExplorerItem =
    useGoToProjectWiseExplorerItemAction(items);

  const goToMenuButtons = useMemo(() => {
    return {
      title: t('MenuItem.GoTo'),
      icon: <SvgProjectWise />,
      submenuItems: formatMenuItems([
        goToProjectWiseDriveItem,
        goToProjectWiseExplorerItem
      ])
    } as MenuItemAction;
  }, [goToProjectWiseDriveItem, goToProjectWiseExplorerItem]);

  return goToMenuButtons;
}
