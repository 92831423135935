import type { PWItem } from '@bentley/pw-api';
import { t } from '../translation';

export function getClearErrorText(
  errorText: string | undefined,
  item: PWItem
): string | undefined {
  if (!errorText) {
    return errorText;
  }

  if (
    errorText.includes(
      "'Deleteable' policy, the requested operation is not allowed due to permissions"
    )
  ) {
    return `${t('ErrorMappings.ErrorDeleting', {
      name: item.Name,
      message: t('ErrorMappings.NoDeletePermissions')
    })}`;
  } else if (
    errorText.startsWith('Error deleting a folder. Error deleting a folder')
  ) {
    return t('ErrorMappings.FolderDeleteError');
  }

  return errorText;
}
