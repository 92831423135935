import _ from 'lodash';
import type { IRendererElement } from '@bentley/formsrenderer/lib/form-renderer/components/RendererElement/RendererElement';
import type { INameValuePair } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IUtilities';
import type { WsgDataType } from '@bentley/formsrenderer/lib/form-renderer/interfaces/IWsgSchemas';
import type { WSGInstance } from '@bentley/pw-api';
import type { ElementValidation } from '../../../actions/formRendering';
import { getFormattedText } from '../../../actions/formRendering';
import type { FormType } from './useDCWForm';

export function formatChangedElement(
  changedData: Array<INameValuePair<WsgDataType>>,
  element: IRendererElement,
  originalInstance: WSGInstance | null | undefined,
  formType: FormType,
  setFormIsValid: (value: boolean) => void
): void {
  if (!elementIsFormattable(element)) {
    return;
  }

  for (const changedItem of changedData) {
    const propertyName = changedItem.name.split('.')[1];
    const changedValue = changedItem.value;
    const originalValue = originalInstance?.properties?.[propertyName];

    formatValue(element, changedValue);
    highlightChangedField(originalValue as string, changedValue, element);
  }

  void checkFormValidity(formType, setFormIsValid);
}

export function highlightElement(
  element: IRendererElement,
  binding: string,
  currentEnvironmentInstance: WSGInstance | null | undefined,
  originalInstance: WSGInstance | null | undefined
): void {
  const propertyName = binding.split('.')[1];
  const originalValue = originalInstance?.properties?.[propertyName];
  const currentValue = currentEnvironmentInstance?.properties?.[propertyName];
  highlightChangedField(
    originalValue as string,
    currentValue as WsgDataType,
    element
  );
}

function elementIsFormattable(element: IRendererElement): boolean {
  const elementHtml = element.getElementHtml();
  if (!elementHtml || elementHtml.hasClass('renderer-labeled-control')) {
    return false;
  }
  return true;
}

type RendererElementWithValidation = {
  validation?: ElementValidation;
} & IRendererElement;

export function formatValue(
  element: RendererElementWithValidation,
  changedValue: WsgDataType
): void {
  const format = element.getTextFormat();
  const validation = element.validation;

  if (!validation || !hasFormattableValue(format, validation, changedValue)) {
    return;
  }

  const formattedValue = getFormattedText(changedValue, format, validation);
  if (formattedValue && changedValue != formattedValue) {
    element.getEventTarget().val(String(formattedValue));
  }
}

function hasFormattableValue(
  format: string,
  validation: ElementValidation,
  changedValue: WsgDataType
): boolean {
  if (!format && _.isEmpty(validation)) {
    return false;
  }
  if (!changedValue) {
    return false;
  }
  return true;
}

function highlightChangedField(
  originalValue: string | undefined,
  changedValue: WsgDataType,
  element: IRendererElement
): void {
  if (!formattableElement(element)) {
    return;
  }

  if (!hasComparableValues(originalValue, changedValue)) {
    return;
  }

  const highlightClass = 'f-r-highlight-field';
  const elementHtml = element.getElementHtml();
  if (valueDoesNotMatchOriginal(originalValue, changedValue)) {
    elementHtml.addClass(highlightClass);
  } else {
    elementHtml.removeClass(highlightClass);
  }
}

type RendererElementWithType = { type?: string } & IRendererElement;
function formattableElement(element: RendererElementWithType): boolean {
  if (element.getIsReadOnly()) {
    return false;
  }

  if (element.type == 'LabeledControl') {
    return false;
  }

  return true;
}

function hasComparableValues(
  originalValue: string | undefined,
  changedValue: WsgDataType
): boolean {
  if (isEmpty(originalValue) && isEmpty(changedValue)) {
    return false;
  }
  return true;
}

function isEmpty(value?: string | WsgDataType): boolean {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  return false;
}

function valueDoesNotMatchOriginal(
  originalValue: string | undefined,
  changedValue: WsgDataType
): boolean {
  const original = originalValue?.toString().toLocaleLowerCase();
  const changed = changedValue?.toString().toLocaleLowerCase();
  if (original == changed) {
    return false;
  }
  return true;
}

export async function checkFormValidity(
  formType: FormType,
  setFormIsValid: (value: boolean) => void
): Promise<void> {
  await waitForFormLoad();

  const formValidity = manualCheckFormIsValid(formType);
  setFormIsValid(formValidity);
}

async function waitForFormLoad(): Promise<void> {
  const start = new Date().getTime();
  const maxWaitTime = 10000;

  while (document.querySelector('.renderer-form') == null) {
    const now = new Date().getTime();
    if (now - start > maxWaitTime) {
      throw new Error('Form load timed out');
    }

    // Wait until next DOM repaint, then restart while loop
    await new Promise((resolve) => requestAnimationFrame(resolve));
  }
}

function manualCheckFormIsValid(formType: FormType): boolean {
  const rendererForms = document.querySelectorAll(
    `[data-formtype="${formType}"] .renderer-form`
  );
  const foundInvalidAttributes = Array.from(rendererForms).some(
    (form) =>
      form.querySelectorAll("[data-renderer-is-valid='false']")?.length > 0
  );
  return !foundInvalidAttributes;
}

export function disableFormInputs(): void {
  const formContainer = getFormContainer();
  if (!formContainer) {
    return;
  }

  formContainer.classList.add('f-r-progress-cursor');

  const inputs = getInputs(formContainer);
  inputs.forEach((input) => input.classList.add('f-r-disable-inputs'));
}

export function enableFormInputs(): void {
  const formContainer = getFormContainer();
  if (!formContainer) {
    return;
  }

  formContainer.classList.remove('f-r-progress-cursor');

  const inputs = getInputs(formContainer);
  inputs.forEach((input) => input.classList.remove('f-r-disable-inputs'));
}

function getFormContainer(): HTMLElement | null {
  const formContainer = document.getElementById('form');
  return formContainer;
}

function getInputs(formContainer: HTMLElement): HTMLInputElement[] {
  const inputCollection = formContainer.getElementsByTagName('input');
  const inputElements = Array.from(inputCollection);
  return inputElements;
}

export function formatMultiSelectOption(element: IRendererElement): void {
  const elementId = element.getElementId();
  if (!elementId) {
    return;
  }

  setTimeout(() => scrollToSelectedOption(elementId), 500);
}

function scrollToSelectedOption(elementId: string): void {
  const select = getMultiSelectElement(elementId);
  if (!select) {
    return;
  }

  const selectedOption = getSelectedOption(select);
  if (!selectedOption) {
    return;
  }

  const heightDifference = calculateHeightDifference(select, selectedOption);
  select.scrollTo(0, heightDifference);
}

function getMultiSelectElement(
  elementId: string
): HTMLSelectElement | undefined {
  const element = document.getElementById(`${elementId}-validation-wrapper`);
  if (!element) {
    return;
  }
  const selects = element.getElementsByTagName('select');
  if (selects.length < 1) {
    return;
  }

  const select = selects[0];
  if (!select.multiple) {
    return;
  }

  return select;
}

function getSelectedOption(select: HTMLElement): HTMLElement | undefined {
  const options = select.getElementsByTagName('option');
  if (!options.length) {
    return;
  }

  const selectedOption = Array.from(options).find((option) => option.selected);
  return selectedOption;
}

function calculateHeightDifference(
  select: HTMLElement,
  option: HTMLElement
): number {
  const heightDifference = option.offsetTop - select.offsetTop;
  const visualPadding = 6;
  return heightDifference - visualPadding;
}

export function turnOffAutoCompleteOnSelects(
  element: RendererElementWithType,
  $element: JQuery
): void {
  if (element.type != 'SelectList') {
    return;
  }
  ($element[0] as HTMLInputElement).autocomplete = 'off';
}
