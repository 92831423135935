import React, { useMemo } from 'react';
import { SvgClose } from '@itwin/itwinui-icons-react';
import { SearchBox } from '@itwin/itwinui-react';
import { useAppViewContext, useNavigationContext } from '../../context';
import { t } from '../../services/translation';

type SearchInputComponents = {
  button: JSX.Element;
  placeholder: string;
};

type UseSearchInputComponentsProps = {
  inSearchState: boolean;
  clearSearch: () => void;
  onSearch: () => void;
};

export function useSearchInputComponents({
  inSearchState,
  clearSearch,
  onSearch
}: UseSearchInputComponentsProps): SearchInputComponents {
  const { searchState, navigationManager } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const placeholder = useMemo((): string => {
    if (currentAppView.type == 'AdvancedSearch') {
      return t('Search.AdvancedSearch');
    }
    if (currentAppView.type == 'SavedSearch') {
      return searchState.savedSearch?.Name ?? t('Search.SavedSearch');
    }
    return `${t('Search.AllSubfolders', {
      name: navigationManager.currentParent.Name
    })}`;
  }, [
    currentAppView.type,
    navigationManager.currentParent.Name,
    searchState.savedSearch?.Name
  ]);

  const button = useMemo((): JSX.Element => {
    if (inSearchState) {
      return (
        <SearchBox.Button onClick={clearSearch} disabled={false}>
          <SvgClose data-testid="closeSearchBtn" />
        </SearchBox.Button>
      );
    }

    return <SearchBox.Button onClick={onSearch} data-testid="searchBtn" />;
  }, [inSearchState, onSearch, clearSearch]);

  const searchInputComponents = useMemo(
    (): SearchInputComponents => ({ button, placeholder }),
    [button, placeholder]
  );

  return searchInputComponents;
}
