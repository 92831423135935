import React from 'react';

export function SvgSpatialRoot(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M13.6,0.1C5.7-0.9-0.9,5.7,0.1,13.6c0.7,5.3,5,9.7,10.3,10.3c7.8,1,14.5-5.6,13.5-13.5C23.2,5.1,18.9,0.8,13.6,0.1z
  M13.2,20.9l-1.3-2.3l-1.2,2.3c-4-0.6-7.1-3.7-7.7-7.7l2.3-1.3L3,10.7c0.5-4,3.7-7.1,7.7-7.7L12,5.4l1.2-2.3c4,0.5,7.1,3.7,7.7,7.7
  l-2.4,1.3l2.4,1.2C20.4,17.2,17.2,20.4,13.2,20.9z M15.9,7.6l-2.4,1.1l-3.2,1.5c-0.1,0-0.1,0.1-0.2,0.2l-1.5,3.2L7.5,16
  c-0.1,0.3,0.2,0.6,0.5,0.5l2.4-1.1l3.2-1.5c0.1,0,0.1-0.1,0.2-0.2l1.5-3.2l1.1-2.4C16.6,7.7,16.3,7.4,15.9,7.6z M12,13.1
  c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S12.6,13.1,12,13.1z"
      />
    </svg>
  );
}
