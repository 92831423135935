import React, { useMemo } from 'react';
import type { PWFileType, PWItem } from '@bentley/pw-api';
import { itemIsFileType, itemIsParentType } from '@bentley/pw-api';
import { OpenPWDMMarkUp } from '../../../../actions/sendToPWDM';
import {
  useEnvironmentContext,
  useFeatureTracking,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import { SvgDeliverableManagement } from '../../../icons';
import type { MenuItemAction } from '../../MenuItemAction';

export function useSendToPWDMItemAction(items: PWItem[]): MenuItemAction {
  const { launchDarklyFeatures } = useFeatureTracking();
  const { dataAccessLevel } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const { pwdmAPI } = useEnvironmentContext();
  const { isProjectEnabled } = pwdmAPI;

  const allowSendToPWDM = useMemo(() => {
    return items.every((item) => itemIsFileType(item));
  }, [items]);

  const showPWDMIntegration = useMemo((): boolean => {
    if (!launchDarklyFeatures.showPWDMIntegration) {
      return false;
    }
    if (dataAccessLevel == 'Datasource') {
      return false;
    }
    if (items.some((item) => itemIsParentType(item))) {
      return false;
    }
    if (!isProjectEnabled) {
      return false;
    }
    if (items.length == 0) {
      return false;
    }
    return true;
  }, [
    launchDarklyFeatures.showPWDMIntegration,
    items,
    isProjectEnabled,
    dataAccessLevel
  ]);

  const sendToPWDM = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.SendToPWDM'),
      icon: <SvgDeliverableManagement />,
      onClick: () =>
        primaryModal.open(<OpenPWDMMarkUp items={items as PWFileType[]} />),
      disabled: !allowSendToPWDM,
      hidden: !showPWDMIntegration
    }),
    [primaryModal, items, allowSendToPWDM, showPWDMIntegration]
  );

  return sendToPWDM;
}
