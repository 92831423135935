import React from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { t } from '../../services/translation';
import { ErrorPage } from '../errorPage';

export function SignInErrorBoundary({ error }: FallbackProps): JSX.Element {
  return (
    <ErrorPage
      errorType="generic"
      errorMsg={error?.message ?? t('SignIn.ErrorOnSignIn')}
    />
  );
}
