import React, { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { SvgDelete } from '@itwin/itwinui-icons-react';
import { DeleteModal, allowDeleteAll } from '../../../../actions/delete';
import {
  useAppViewContext,
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { useRootFolderSelected } from '../useRootFolderSelected';

export function useDeleteItemAction(items: PWItem[]): MenuItemAction {
  const { readOnly } = usePluginContext();
  const { ecPluginFeatures } = useEnvironmentContext();
  const {
    primaryModal,
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const rootFolderSelected = useRootFolderSelected(items);

  const showDelete = useMemo((): boolean => {
    if (readOnly) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    return true;
  }, [readOnly, currentAppView.type]);

  const deleteItemAction = useMemo(
    (): MenuItemAction => ({
      title: t('Generic.Delete'),
      icon: <SvgDelete />,
      onClick: () =>
        primaryModal.open(
          <DeleteModal
            items={items}
            parent={currentParent}
            userFolderPermissionsEnabled={ecPluginFeatures.userFolderPermissions()}
          />
        ),
      disabled:
        !showDelete ||
        rootFolderSelected ||
        !allowDeleteAll(items, currentParent, allItemsExistInConnection),
      hidden: !showDelete
    }),
    [
      showDelete,
      rootFolderSelected,
      items,
      currentParent,
      allItemsExistInConnection,
      primaryModal,
      ecPluginFeatures
    ]
  );

  return deleteItemAction;
}
