import React, { useCallback } from 'react';
import type { HttpService, PWDataItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Label, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { useNavigationContext } from '../../context';
import { castFileToDataItem } from '../../hooks/useProgressManager/utils';
import type { TrackFeature } from '../../hooks/useTrackFeature/useTrackFeature';
import { t } from '../../services/translation';
import { checkInWorkflow } from './checkIn';

interface ICheckInFileModalProps {
  oldItem: PWDataItem;
  newFile: File;
  httpService: HttpService;
  trackFeature: TrackFeature;
  onSuccess: () => void;
}
function CheckInFileModal({
  oldItem,
  newFile,
  httpService,
  trackFeature,
  onSuccess
}: ICheckInFileModalProps): JSX.Element {
  const { primaryModal } = useNavigationContext();
  const namesMatch = oldItem.FileName === newFile.name;
  const newItem = castFileToDataItem(newFile);
  const primaryButtonHandle = useCallback(() => {
    primaryModal.close();
    void checkInWorkflow(
      newFile,
      oldItem,
      httpService,
      trackFeature,
      onSuccess
    );
  }, [
    primaryModal.close,
    httpService,
    newFile,
    oldItem,
    onSuccess,
    trackFeature
  ]);

  return (
    <PWModal
      title={t('Sync.CheckInFile')}
      primaryButton={{ title: t('Sync.CheckIn'), onClick: primaryButtonHandle }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'CheckInFileModal' }}
    >
      {namesMatch ? (
        <>
          <Label>{t('Sync.CheckInThisFile')}:</Label>
          <ItemSummary useFileName={true} item={oldItem} />
        </>
      ) : (
        <>
          <Label>{t('Sync.FileNamesDoNotMatch')}:</Label>
          <ItemSummary useFileName={true} item={oldItem} />
          <ItemSummary useFileName={true} item={newItem} />
          <Text>{t('Sync.ProceedWithCheckIn')}</Text>
        </>
      )}
    </PWModal>
  );
}
export default CheckInFileModal;
