import React, { useMemo } from 'react';
import type { PWDataItem, PWItem } from '@bentley/pw-api';
import { SvgReplace } from '@itwin/itwinui-icons-react';
import { allowReplace, startReplaceFile } from '../../../../actions/replace';
import { hideReplaceButton } from '../../../../actions/replace/requirements';
import {
  useAppViewContext,
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useReplaceItemAction(items: PWItem[]): MenuItemAction {
  const { trackFeature } = useFeatureTracking();
  const httpService = useHttpService();
  const {
    readOnly,
    connection,
    progressManager,
    connectionIModelBridges: { iModelBridges }
  } = usePluginContext();
  const { primaryModal } = useNavigationContext();
  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();
  const {
    dataManager: { refreshCurrentFolder }
  } = useTableItemContext();

  const showReplace = useMemo(() => {
    if (readOnly) {
      return false;
    }

    if (hideReplaceButton(items)) {
      return false;
    }

    if (currentAppView.type == 'RecycleBin') {
      return false;
    }

    return true;
  }, [currentAppView.type, items, readOnly]);

  const replaceItem = useMemo(
    (): MenuItemAction => ({
      title: t('Replace.ReplaceFile'),
      icon: <SvgReplace />,
      onClick: () =>
        void startReplaceFile(
          items[0] as PWDataItem,
          connection.Canned ?? false,
          httpService,
          primaryModal.open,
          primaryModal.close,
          trackFeature,
          refreshCurrentFolder,
          progressManager
        ),
      disabled:
        !showReplace ||
        !allowReplace(
          items,
          iModelBridges,
          allItemsExistInConnection,
          connection.Canned
        ),
      hidden: !showReplace
    }),
    [
      showReplace,
      items,
      allItemsExistInConnection,
      connection.Canned,
      httpService,
      primaryModal.open,
      primaryModal.close,
      trackFeature,
      refreshCurrentFolder,
      progressManager,
      iModelBridges
    ]
  );

  return replaceItem;
}
