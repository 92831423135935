import type { ConsumerApp } from '../../../context';
import type { EnvironmentDefinition } from './environmentDefinition';

export function byAllowedAppEnvironment(
  consumerApp?: ConsumerApp
): (environmentDefinition: EnvironmentDefinition) => boolean {
  function filterFunction(
    environmentDefinition: EnvironmentDefinition
  ): boolean {
    if (
      consumerApp != 'ProjectWise 365' &&
      consumerApp != 'ProjectWise 365 in Teams'
    ) {
      if (environmentDefinition.Name.includes('PW365')) {
        return false;
      }
    }

    if (consumerApp != 'Synchro') {
      if (environmentDefinition.Name.includes('Synchro')) {
        return false;
      }
    }

    return true;
  }

  return filterFunction;
}
