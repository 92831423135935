import type {
  HttpService,
  PWDataItem,
  PWFlatSet,
  PWItem,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { parseSetRelationshipInstance } from '@bentley/pw-api';
import type { TrackFeature } from '../../../hooks/useTrackFeature';
import { usingConcurrencyLimiter } from '../../../services/concurrencyLimiter';
import { t } from '../../../services/translation';
import { getToggleVersionLockQueryBody } from '../utils';
import {
  lockToVersionFailureToast,
  lockToVersionInProgressToast,
  lockToVersionSuccessToast,
  on400Error,
  on403Error,
  unlockFromVersionFailureToast,
  unlockFromVersionInProgressToast,
  unlockFromVersionSuccessToast
} from './notifications';

export async function lockToVersionWorkflow(
  flatSet: PWFlatSet,
  items: PWDataItem[],
  httpService: HttpService,
  trackFeature: TrackFeature,
  onComplete?: () => void
): Promise<Response[]> {
  trackFeature('LOCK_TO_VERSION_SET');
  const toastHandle = lockToVersionInProgressToast();
  try {
    const responses = await Promise.all(
      items.map((item) =>
        usingConcurrencyLimiter(
          async () =>
            toggleVersionLockForFlatSetMember(flatSet, item, true, httpService),
          'background'
        )
      )
    );

    lockToVersionSuccessToast(toastHandle);
    onComplete?.();
    return responses;
  } catch (e) {
    lockToVersionFailureToast(toastHandle, e as Error);
    throw e;
  }
}

export async function unlockFromVersionWorkflow(
  flatSet: PWFlatSet,
  items: PWDataItem[],
  httpService: HttpService,
  onComplete?: () => void
): Promise<Response[]> {
  const toastHandle = unlockFromVersionInProgressToast();
  try {
    const responses = await Promise.all(
      items.map((item) =>
        usingConcurrencyLimiter(
          async () =>
            toggleVersionLockForFlatSetMember(
              flatSet,
              item,
              false,
              httpService
            ),
          'background'
        )
      )
    );

    unlockFromVersionSuccessToast(toastHandle);
    onComplete?.();
    return responses;
  } catch (e) {
    unlockFromVersionFailureToast(toastHandle, e as Error);
    throw e;
  }
}

async function toggleVersionLockForFlatSetMember(
  flatSet: PWFlatSet,
  flatSetMember: PWDataItem,
  lockToVersion: boolean,
  httpService: HttpService
): Promise<Response> {
  const relationshipInstance = parseSetRelationshipInstance(
    flatSetMember,
    flatSet
  );

  if (!relationshipInstance?.instanceId) {
    throw new Error(t('FlatSet.Notifications.MissingSetId'));
  }

  const url = `PW_WSG/FlatSet/${flatSet.instanceId}`;
  const body = getToggleVersionLockQueryBody(
    flatSet.instanceId,
    relationshipInstance.instanceId,
    flatSetMember.instanceId,
    lockToVersion
  );
  const response = await httpService.post(url, body);
  await handleBadResponse(response, flatSetMember);
  return response;
}

async function handleBadResponse(
  response: Response,
  item: PWItem
): Promise<void> {
  if (response.ok) {
    return;
  }

  if (response.status == 403) {
    throw new Error(on403Error());
  }
  if (response.status == 400) {
    throw new Error(on400Error(item));
  }

  const data = (await response.json()) as WSGInstancesResponse;

  throw new Error(data.errorMessage || t('Generic.FileNotFound'));
}
