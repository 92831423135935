import React, { useMemo } from 'react';
import { SvgColumnManager } from '@itwin/itwinui-icons-react';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';
import { useViewsMenuItems } from './viewsDropdown';

export function useViewsOption(): MenuItemAction {
  const { configureColumns, manageMyViews, folderViews, savedViews, myViews } =
    useViewsMenuItems();

  const viewsOption = useMemo(
    (): MenuItemAction => ({
      title: t('MenuItem.ColumnManager'),
      icon: <SvgColumnManager />,
      submenuItems: [
        configureColumns,
        manageMyViews,
        ...folderViews,
        ...savedViews,
        ...myViews
      ]
    }),
    [configureColumns, folderViews, manageMyViews, myViews, savedViews]
  );

  return viewsOption;
}
