import { useEffect, useMemo, useState } from 'react';
import {
  useConnectionContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { AppViewSetting } from './appViewSettings';
import {
  useAdvancedSearchView,
  useBasicSearchView,
  useFolderNavigationView,
  useOutToMeView,
  useRecycleBinView,
  useSavedSearchView
} from './appViewSettings';

export type AppView =
  | 'FolderNavigation'
  | 'BasicSearch'
  | 'AdvancedSearch'
  | 'SavedSearch'
  | 'OutToMe'
  | 'RecycleBin';

export type AppViewManager = {
  currentAppView: AppViewSetting;
  setAppView: (appView: AppView) => void;
};

export function useAppView(): AppViewManager {
  const { selectionState } = useConnectionContext();
  const { connection, navigate: useUrlNavigation } = usePluginContext();
  const {
    navigationManager: { currentParent },
    setInfoPanelDisplayed
  } = useNavigationContext();

  const [appView, setAppView] = useState<AppView>('FolderNavigation');

  const folderNavigationView = useFolderNavigationView();
  const basicSearchView = useBasicSearchView();
  const advancedSearchView = useAdvancedSearchView();
  const savedSearchView = useSavedSearchView();
  const outToMeView = useOutToMeView();
  const recycleBinView = useRecycleBinView();

  const currentAppView = useMemo((): AppViewSetting => {
    switch (appView) {
      case 'FolderNavigation':
        return folderNavigationView;
      case 'BasicSearch':
        return basicSearchView;
      case 'AdvancedSearch':
        return advancedSearchView;
      case 'SavedSearch':
        return savedSearchView;
      case 'OutToMe':
        return outToMeView;
      case 'RecycleBin':
        return recycleBinView;
      default:
        return folderNavigationView;
    }
  }, [
    advancedSearchView,
    appView,
    basicSearchView,
    folderNavigationView,
    outToMeView,
    recycleBinView,
    savedSearchView
  ]);

  useEffect(() => {
    setInfoPanelDisplayed(false);
    return () => {
      currentAppView.clearView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appView]);

  useEffect(() => {
    setAppView('FolderNavigation');
  }, [connection.Id]);

  useEffect(() => {
    if (!useUrlNavigation) {
      return;
    }

    // Reset state on back button
    function onBackButton(): void {
      setAppView('FolderNavigation');
    }
    window.addEventListener('popstate', onBackButton);
    return () => {
      window.removeEventListener('popstate', onBackButton);
    };
  }, [useUrlNavigation]);

  useEffect(() => {
    return () => {
      delete selectionState.selectedItemId;
      delete selectionState.currentProjectId;
      delete selectionState.savedSearchId;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection.Id, currentParent.instanceId, appView]);

  const appViewManager = useMemo(
    (): AppViewManager => ({ currentAppView, setAppView }),
    [currentAppView]
  );

  return appViewManager;
}
