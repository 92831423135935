import { atom } from 'recoil';

export const applyToAllState = atom<boolean>({
  key: 'applyToAllState',
  default: false
});

export const multiUploadState = atom<boolean>({
  key: 'multiUploadState',
  default: false
});

export const uploadingDirectoriesState = atom<boolean>({
  key: 'uploadingDirectoriesState',
  default: false
});
