import type { WSGProperties } from '@bentley/pw-api';

/**
 * Removes a subset of properties if they exist
 */
export function propertyDifference(
  allProperties: WSGProperties,
  subsetToRemove: WSGProperties | null
): WSGProperties {
  if (!subsetToRemove || !Object.keys(subsetToRemove).length) {
    return allProperties;
  }

  const difference = {} as WSGProperties;
  for (const key in allProperties) {
    if (subsetToRemove[key] === undefined) {
      difference[key] = allProperties[key];
    }
  }

  return difference;
}

/**
 * Removes a subset of properties if they are the same
 */
export function changedProperties(
  updatedProperties?: WSGProperties,
  originalInstanceProperties?: WSGProperties
): WSGProperties {
  const changedProperties = {} as WSGProperties;

  if (
    !originalInstanceProperties ||
    !Object.keys(originalInstanceProperties).length
  ) {
    return updatedProperties ?? changedProperties;
  }

  for (const key in updatedProperties) {
    if (originalInstanceProperties[key] !== updatedProperties[key]) {
      changedProperties[key] = updatedProperties[key];
    }
  }

  return changedProperties;
}

/**
 * Compare current and original environment instances before creating/saving document
 */
export function validateEnvironmentProperties(
  currentInstanceProperties: WSGProperties,
  originalInstanceProperties: WSGProperties,
  usingDocCode?: boolean
): WSGProperties {
  let validatedProperties = { ...currentInstanceProperties };
  for (const property in currentInstanceProperties) {
    // Current property may show '' when it is undefined. If it is empty and doesn't exist on original, don't send on create
    if (
      currentInstanceProperties[property] == '' &&
      !originalInstanceProperties?.[property]
    ) {
      validatedProperties = removePropertyFromInstance(
        validatedProperties,
        property
      );
      continue;
    }
    // Current property equals default value, no need to send on create
    if (
      !usingDocCode &&
      currentInstanceProperties[property] ==
        originalInstanceProperties?.[property]
    ) {
      validatedProperties = removePropertyFromInstance(
        validatedProperties,
        property
      );
      continue;
    }
  }
  return validatedProperties;
}

/**
 * Remove individual property from set of properties
 */
function removePropertyFromInstance(
  properties: WSGProperties,
  prop: string
): WSGProperties {
  return Object.keys(properties).reduce(
    (object: WSGProperties, key: string) => {
      if (key !== prop) {
        object[key] = properties[key];
      }
      return object;
    },
    {}
  );
}
