import { useEffect, useState } from 'react';
import { matchingIModel } from '../../actions/imodelMapping';
import {
  useEnvironmentContext,
  useNavigationContext,
  usePluginContext
} from '../../context';
import type { PWDataManager } from '../useData';

export function useSavedSearchIModels(
  dataManager: PWDataManager
): string | undefined {
  const {
    iModelMappingManager: { getIModelBridgesForDocument },
    connection
  } = usePluginContext();
  const { searchState } = useNavigationContext();
  const { iModels } = useEnvironmentContext();
  const [savedSearchIModels, setSavedSearchIModels] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    async function initializeIModelsForSavedSearch() {
      const iModelBridges = await getIModelBridgesForDocument(
        searchState.savedSearch?.instanceId ?? '',
        undefined,
        connection.ProjectId
      );
      const searchIModels = iModelBridges
        .map((bridge) => matchingIModel(iModels, bridge)?.properties?.Name)
        .filter((iModelName) => iModelName != undefined)
        .join(', ');

      setSavedSearchIModels(searchIModels);
    }

    if (searchState.savedSearch && !dataManager.isLoading) {
      void initializeIModelsForSavedSearch();
    } else {
      setSavedSearchIModels(undefined);
    }
  }, [
    connection.ProjectId,
    dataManager.isLoading,
    getIModelBridgesForDocument,
    iModels,
    searchState.savedSearch
  ]);

  return savedSearchIModels;
}
