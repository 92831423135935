import { atom } from 'recoil';
import type { UploadNode } from '../../../actions/upload/tree';

export const uploadNodeState = atom<UploadNode>({
  key: 'uploadNodeState',
  default: { directories: [], files: [] }
});

export const uploadInProgressState = atom<boolean>({
  key: 'uploadInProgressState',
  default: false
});
