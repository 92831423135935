import sha1 from 'sha1';
import type { PWProject } from '@bentley/pw-api';
import type { PRDSSSetting } from '../../hooks/useProductSetting';
import type { WebConnection } from '../webConnections/webConnections';
import type { Connection } from './connection';
import type { FederatedRepository, Repository } from './federatedRepository';
import type { LegacyRepository } from './legacyRepository';

export function webConnectionToConnection(
  webConnection: WebConnection
): Connection {
  const federatedRepo = webConnectionToFederatedRepo(webConnection);
  const connection = federatedRepoToConnection(federatedRepo);
  return connection;
}

export function webConnectionToFederatedRepo(
  connection: WebConnection
): FederatedRepository {
  return {
    Id: connection.id,
    Url: connection.url,
    Name: connection.name,
    Description: connection.description,
    DisplayLabel: connection.displayLabel,
    Disabled: connection.disabled,
    Type: connection.type,
    UIUrl: null,
    WorkAreaName: connection.workAreaName,
    LegacyId: connection.legacyId,
    Canned: connection.canned,
    DefaultTab: connection.defaultTab
  };
}

type SplitConnectionData = {
  projectId: string;
  datasourceId: string;
  serverUrl: string;
  connectionUrl: string;
  wsgVersion: string;
};

function splitConnectionUrl(
  url: string,
  customServerVersion?: string
): SplitConnectionData {
  // wac connection: https://server/ws/v2.8/repositories/datasourceInstanceId/PW_WSG/Project/projectId
  // ds connection:  https://server/ws/v2.8/repositories/datasourceInstanceId
  // server url can contain additional parts separated by '/'
  // finds relevant parts of connection Url by working with constants in the url we control like the term repositories and PW_WSG schema
  const containsWorkArea = url.toLocaleLowerCase().includes('pw_wsg/project');
  const repoUrlParts = url.split('/');

  const projectId = containsWorkArea
    ? repoUrlParts[repoUrlParts.length - 1]
    : '';

  const datasourceIdPosition = containsWorkArea
    ? repoUrlParts.length - 4
    : repoUrlParts.length - 1;

  const datasourceId = repoUrlParts[datasourceIdPosition];

  const repositoryStringPosition = repoUrlParts.findIndex((item) => {
    return item.toLocaleLowerCase() == 'repositories';
  });

  const version =
    repositoryStringPosition > 1
      ? repoUrlParts[repositoryStringPosition - 1]
      : '';

  let serverUrl = repoUrlParts.slice(0, repositoryStringPosition).join('/');

  if (customServerVersion) {
    const versionLocation = serverUrl.lastIndexOf('/');
    serverUrl = serverUrl.substr(0, versionLocation) + customServerVersion;
  }

  serverUrl += '/';

  const schemaNamePosition = repoUrlParts.findIndex((item) => {
    return item.toLocaleLowerCase() == 'pw_wsg';
  });

  const connectionUrl = containsWorkArea
    ? repoUrlParts.slice(0, schemaNamePosition).join('/')
    : url;

  return {
    projectId: projectId,
    datasourceId: datasourceId,
    serverUrl: serverUrl,
    connectionUrl: connectionUrl,
    wsgVersion: version
  };
}

export function prdssSettingToFederatedRepo(
  setting: PRDSSSetting
): FederatedRepository {
  const connection = setting.properties as FederatedRepository;
  return {
    Id: connection.Id,
    Url: connection.Url ?? connection.ConnectionUrl,
    Name: connection.Name,
    Description: connection.Description,
    DisplayLabel: connection.DisplayLabel,
    Type: connection.Type,
    UIUrl: null,
    WorkAreaName: connection.WorkAreaName,
    DataSource: connection.DataSource,
    DefaultTab: connection.DefaultTab,
    LegacyId: connection.LegacyId,
    ServerUrl: connection.ServerUrl,
    ConnectionUrl: connection.ConnectionUrl,
    ProjectId: connection.ProjectId,
    Canned: connection.Canned,
    Disabled: connection.Disabled
  };
}

/**
 * Maps FederatedRepository to Connection
 * @param {FederatedRepository?} [federatedRepository] Repository to map to Connection
 * @param {boolean?} [updateWsgVersionData] update missing wsg version string data
 * @returns {Connection} Mapped Connection object or empty object if unable to map url
 */
export function federatedRepoToConnection(
  federatedRepository?: FederatedRepository,
  updateWsgVersionData?: boolean
): Connection {
  if (!federatedRepository) {
    return {} as Connection;
  }
  let updatedUrl = federatedRepository.Url;

  if (updateWsgVersionData) {
    updatedUrl = addDefaultVersionString(updatedUrl);
  }
  const urlData = splitConnectionUrl(updatedUrl);

  return {
    Name: federatedRepository.Name,
    Type: federatedRepository.Type,
    Description: federatedRepository.Description,
    DisplayLabel: federatedRepository.DisplayLabel,
    ServerUrl: urlData.serverUrl,
    DatasourceInstanceId: urlData.datasourceId,
    ConnectionUrl: urlData.connectionUrl,
    Id: federatedRepository.Id,
    ProjectId: urlData.projectId,
    WorkAreaName: federatedRepository.WorkAreaName,
    DefaultTab: federatedRepository.DefaultTab,
    Canned: federatedRepository.Canned ?? false
  };
}

export function repositoryToFederatedRepository(
  repository: Repository,
  workAreaName: string
): FederatedRepository {
  return {
    Id: getFederatedRepositoryId(repository.ServerUrl, repository.instanceId),
    Url: `${addEndingSlash(repository.ServerUrl)}v${
      repository.ServerVersion
    }/repositories/${repository.instanceId}`,
    Name: repository.DisplayLabel,
    Description: repository.Description,
    DisplayLabel: repository.DisplayLabel,
    Type: repository.ECPluginID,
    UIUrl: null,
    WorkAreaName: workAreaName
  };
}

export function workAreaToFederatedRepository(
  workArea: PWProject,
  repository: Repository
): FederatedRepository {
  return {
    Id: getFederatedRepositoryId(
      repository.ServerUrl,
      repository.instanceId,
      workArea.instanceId
    ),
    Url: `${addEndingSlash(repository.ServerUrl)}v${
      repository.ServerVersion
    }/repositories/${repository.instanceId}/PW_WSG/Project/${
      workArea.instanceId
    }`,
    Name: repository.DisplayLabel,
    Description: repository.Description,
    DisplayLabel: repository.DisplayLabel,
    Type: repository.ECPluginID,
    UIUrl: null,
    WorkAreaName: workArea.Name
  };
}

// function to remove wsg version id for legacy/new connection comparison
export const removeVersionString = (url: string): string => {
  const urlParts = url.split('/');
  const versionIndex =
    urlParts.map((part) => part.toLowerCase()).indexOf('repositories') - 1;

  const reducedParts = [
    ...urlParts.slice(0, versionIndex),
    ...urlParts.slice(versionIndex + 1, urlParts.length)
  ];

  return reducedParts.join('/');
};

// add default wsg version v2.8 if missing
function addDefaultVersionString(url: string): string {
  const urlParts = url.split('/');
  let versionIndex =
    urlParts.findIndex((part) => part.toLowerCase() == 'repositories') - 1;

  if (urlParts[versionIndex].toLowerCase().includes('v')) {
    return url;
  }

  if (!urlParts[versionIndex]) {
    urlParts.splice(versionIndex, 1);
  }

  versionIndex =
    urlParts.findIndex((part) => part.toLowerCase() == 'repositories') - 1;
  let splitIndex = versionIndex;
  //check to see if the version string is completely missing or is just using something other than vX.X
  if (urlParts[versionIndex].toLowerCase() === 'ws') {
    splitIndex++;
  }

  const reducedParts = [
    ...urlParts.slice(0, splitIndex),
    'v2.8',
    ...urlParts.slice(versionIndex + 1, urlParts.length)
  ];

  const joinedUrl = reducedParts.join('/');
  return joinedUrl;
}

export function legacyRepoToFederatedRepo(
  legacyRepo: LegacyRepository
): FederatedRepository {
  const urlData = splitConnectionUrl(legacyRepo.Url);
  // Legacy connections did not store the Work Area name
  return {
    Id: getFederatedRepositoryId(
      urlData.serverUrl,
      urlData.datasourceId,
      urlData.projectId
    ),
    Url: legacyRepo.Url,
    Name: legacyRepo.Name,
    Description: legacyRepo.Description,
    DisplayLabel: legacyRepo.DisplayLabel,
    Type: legacyRepo.Type,
    UIUrl: null,
    WorkAreaName: '',
    LegacyId: legacyRepo.instanceId
  } as FederatedRepository;
}

export function federatedRepoToLegacyRepo(
  federatedRepo: FederatedRepository
): LegacyRepository {
  return {
    Type: 'Bentley.PW',
    Url: federatedRepo.Url,
    UIUrl: federatedRepo.UIUrl,
    Name: federatedRepo.Name,
    Description: federatedRepo.Description
  } as LegacyRepository;
}

export function parseConnectionUrl(
  federatedRepository: FederatedRepository,
  serverVersion?: string
): string {
  return splitConnectionUrl(federatedRepository.Url, serverVersion)
    .connectionUrl;
}

export function parseServerVersion(
  federatedRepository: FederatedRepository
): number {
  const version = splitConnectionUrl(federatedRepository.Url).wsgVersion;
  return parseFloat(version.substr(1)) || 2.5;
}

function getFederatedRepositoryId(
  serverUrl: string,
  repositoryInstanceId: string,
  projectId = ''
) {
  // unlike Url, uniqueUrl excludes serverversion
  // this is so that, if the server version changes in future user can't add the same datasource twice
  const server = addEndingSlash(serverUrl);
  const project = projectId ? '/project/' + projectId : '';
  const uniqueURl = `${server}repositories/${repositoryInstanceId}${project}`;
  return sha1(uniqueURl.toLowerCase());
}

function addEndingSlash(url: string): string {
  if (/\/$/.test(url)) {
    return url;
  }

  return url + '/';
}

export function connectionToFederatedRepo(
  connection: Connection
): FederatedRepository {
  if (!connection) {
    return {} as FederatedRepository;
  }

  return {
    Id: connection.Id,
    Url:
      connection.ProjectId == ''
        ? connection.ConnectionUrl
        : [
            connection.ConnectionUrl,
            'PW_WSG',
            'Project',
            connection.ProjectId
          ].join('/'),
    Name: connection.Name,
    Description: connection.Description,
    DisplayLabel: connection.DisplayLabel ?? '',
    Type: connection.Type,
    UIUrl: null,
    WorkAreaName: connection.WorkAreaName,
    DefaultTab: connection.DefaultTab,
    ServerUrl: connection.ServerUrl
  };
}
