import React from 'react';
import { SvgUser } from '@itwin/itwinui-icons-react';
import { pwIconBadgeProps } from '../../sizes/iconClassName';
import { SvgStatusCheckedOut } from '../status/SvgStatusCheckedOut';
import { SvgStatusFinal } from '../status/SvgStatusFinal';
import { SvgStatusInUse } from '../status/SvgStatusInUse';
import { SvgStatusInUseHollow } from '../status/SvgStatusInUseHollow';
import { SvgStatusLocked } from '../status/SvgStatusLocked';
import { SvgStatusReadOnly } from '../status/SvgStatusReadOnly';
export function IconBadge({ badge, ...rest }) {
    const iconBadgeProps = pwIconBadgeProps(rest);
    if (badge == 'checkedOut') {
        return (React.createElement(SvgStatusCheckedOut, { "data-testid": "checked-out-status", ...iconBadgeProps }));
    }
    if (badge == 'final') {
        return React.createElement(SvgStatusFinal, { "data-testid": "final-status", ...iconBadgeProps });
    }
    if (badge == 'inUse') {
        return React.createElement(SvgStatusInUse, { "data-testid": "in-use-status", ...iconBadgeProps });
    }
    if (badge == 'inUseHollow') {
        return (React.createElement(SvgStatusInUseHollow, { "data-testid": "in-use-hollow-status", ...iconBadgeProps }));
    }
    if (badge == 'locked') {
        return React.createElement(SvgStatusLocked, { "data-testid": "locked-status", ...iconBadgeProps });
    }
    if (badge == 'readOnly') {
        return (React.createElement(SvgStatusReadOnly, { "data-testid": "read-only-status", ...iconBadgeProps }));
    }
    if (badge == 'user') {
        return (React.createElement(SvgUser, { "data-testid": "user-status", ...pwIconBadgeProps(rest, ['pw-icon-user-status-blue']) }));
    }
    if (badge == 'userBlocked') {
        return (React.createElement(SvgUser, { "data-testid": "user-blocked-status", ...pwIconBadgeProps(rest, ['pw-icon-user-status-red']) }));
    }
    return null;
}
