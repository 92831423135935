import _ from 'lodash';
import { useEffect, useState } from 'react';
import type { Connection } from '../../services/support';

/**
 * Prevents unnecessary re-renders when driveSyncedConnections updates
 * with the same info, as arrays are never equal
 */
export function useDriveSyncedIds(
  driveSyncedConnections?: Connection[]
): string[] {
  const [driveSyncedIds, setDriveSyncedIds] = useState<string[]>([]);

  useEffect(() => {
    const driveSyncedConnectionIds =
      driveSyncedConnections?.map((connection) => connection.Id) ?? [];
    if (!_.isEqual(driveSyncedConnectionIds, driveSyncedIds)) {
      setDriveSyncedIds(driveSyncedConnectionIds);
    }
  }, [driveSyncedIds, driveSyncedConnections]);

  return driveSyncedIds;
}
