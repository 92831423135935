import React from 'react';
import type { PWFlatSet, PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { StatusMessage, Text } from '@itwin/itwinui-react';
import { PWModal } from '../../../components/pwModal';
import {
  useFeatureTracking,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import type { ModalElement } from '../../../hooks/useModal';
import { t } from '../../../services/translation';
import { buildRemoveEvents } from './eventHandlers';
import { removeFlatSetMembers } from './removeFlatSetMember';

type RemoveModalProps = {
  items: PWItem[];
};

export function RemoveFlatSetMemberModal({
  items
}: RemoveModalProps): ModalElement {
  const { trackFeature } = useFeatureTracking();

  const httpService = useHttpService();
  const {
    primaryModal,
    navigationManager: { currentParent, navigateToParent }
  } = useNavigationContext();
  const { dataManager, selectedState } = useTableItemContext();

  const containsLastItem = items.length == dataManager.items.length;

  function confirmationMsg(items: PWItem[]): string {
    const singleItem_msg = t('FlatSet.RemoveSingleItem', {
      name: items[0].Name,
      parentName: currentParent.Name
    });
    const multipleItems_msg = t('FlatSet.RemoveMultipleItems', {
      count: items.length,
      parentName: currentParent.Name
    });
    const singleItem = items.length == 1;
    if (singleItem) {
      return singleItem_msg;
    }
    return multipleItems_msg;
  }

  function onComplete(): void {
    selectedState.setSelectedIds([]);

    if (containsLastItem) {
      navigateToParent?.();
      void dataManager.refresh();
      return;
    }
    dataManager.refreshCurrentFolder();
  }

  const onSave = () => {
    const events = buildRemoveEvents(
      items,
      primaryModal.open,
      primaryModal.close,
      onComplete
    );

    primaryModal.close();
    trackFeature('REMOVE_SET_ITEM');
    void removeFlatSetMembers(
      currentParent as PWFlatSet,
      httpService,
      items,
      events.onRemoveSuccessCallback,
      events.onRemoveFailureCallback,
      events.onCompleteCallback
    );
  };

  return (
    <PWModal
      title={t('Generic.Remove')}
      primaryButton={{
        title: t('Generic.Remove'),
        onClick: onSave,
        disabled: false
      }}
      secondaryButton={{
        title: t('Generic.Cancel'),
        onClick: primaryModal.close
      }}
      onClose={primaryModal.close}
      dialogProps={{ 'data-testid': 'RemoveModal' }}
    >
      <ItemSummary item={items} />
      <Text>{confirmationMsg(items)}</Text>
      {containsLastItem && (
        <StatusMessage status="warning">
          {t('FlatSet.RemoveLastItem')}
        </StatusMessage>
      )}
    </PWModal>
  );
}
