import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import {
  useAppViewContext,
  useFeatureTracking,
  usePluginContext
} from '../../context';

type AllowGetLink = (items: PWItem[]) => boolean;

export function useLinkRequirements(): AllowGetLink {
  const {
    launchDarklyFeatures: { showCustomHtmlLinks }
  } = useFeatureTracking();
  const { connection } = usePluginContext();

  const {
    appViewManager: { currentAppView },
    workAreaManager: { allItemsExistInConnection }
  } = useAppViewContext();

  const allowGetLink = useCallback(
    (items: PWItem[]): boolean => {
      if (!items.length) {
        return false;
      }

      if (!showCustomHtmlLinks && items.length != 1) {
        return false;
      }

      // Missing a parent in a search means user does not have access to the parent
      if (
        currentAppView.type != 'FolderNavigation' &&
        items.some((item) => !parseRelatedParent(item)) &&
        items.some((item) => item.instanceId != connection.ProjectId)
      ) {
        return false;
      }

      if (items.some(({ instanceId }) => instanceId == null)) {
        return false;
      }

      if (!allItemsExistInConnection(items)) {
        return false;
      }

      return true;
    },
    [
      allItemsExistInConnection,
      showCustomHtmlLinks,
      connection,
      currentAppView.type
    ]
  );

  return allowGetLink;
}
