import './conflictResolutionModal.css';

import React, { useEffect, useState } from 'react';
import type { PWItem, PWProject } from '@bentley/pw-api';
import { itemIsImmutable } from '@bentley/pw-api';
import { Checkbox } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import {
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';
import { useConflictSiblings } from '../../hooks/useConflictSiblings';
import { t } from '../../services/translation';
import { newVersionWRECommand } from '../workflow';
import type { ConflictResolution } from './conflictResolution';
import {
  byName,
  bySequence,
  getConflictType,
  getNextName,
  getOriginalFileName,
  getOriginalName,
  siblingConflicts
} from './conflictResolution';
import { ConflictResolutionFooter } from './conflictResolutionFooter';
import { ConflictResolutionSummary } from './conflictResolutionSummary';

interface IConflictResolutionModalProps {
  closeModal: () => void;
  destinationParent: PWProject;
  onClose?: () => void;
  resolveConflicts: (resolutions: ConflictResolution[]) => void;
  sourceItems: { item: PWItem; file?: File }[];
  doNotShowVersionOption?: boolean;
  doNotShowReplaceOption?: boolean;
}

export function ConflictResolutionModal({
  closeModal,
  destinationParent,
  onClose,
  resolveConflicts,
  sourceItems,
  doNotShowVersionOption,
  doNotShowReplaceOption
}: IConflictResolutionModalProps): JSX.Element {
  // State
  const [conflictIndex, setConflictIndex] = useState<number>(0);
  const [resolutions, setResolutions] = useState<ConflictResolution[]>([]);
  const [siblings, setSiblings] = useState<PWItem[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [versionRestrictedByWRE, setVersionRestrictedByWRE] =
    useState<boolean>(false);

  const [doForNext, setDoForNext] = useState<boolean>(false);
  const [doForNextEnabled, setDoForNextEnabled] = useState<boolean>(true);

  const [containsImmutableFile, setContainsImmutableFile] =
    useState<boolean>(false);

  const [inputError, setInputError] = useState<boolean>(false);

  useEffect(() => {
    if (
      containsImmutableFile &&
      conflictIndex < resolutions.length &&
      (resolutions[conflictIndex].action == 'replace' ||
        resolutions[conflictIndex].action == 'version')
    ) {
      setDoForNextAvailable(false);
    }
  }, [conflictIndex, containsImmutableFile, resolutions]);

  // Custom Hooks
  const httpService = useHttpService();

  const { getConflictSiblings } = useConflictSiblings(
    destinationParent,
    sourceItems
  );
  const {
    navigationManager: { currentParent }
  } = useNavigationContext();
  const { dataManager } = useTableItemContext();

  // Functions
  function doForNextString(): string {
    const count =
      conflictIndex == resolutions.length - 2
        ? 1
        : resolutions.length - conflictIndex - 1;

    const message =
      count == 1
        ? t('ConflictResolution.DoForNext')
        : t('ConflictResolution.DoForNextMulti', { count });

    return message;
  }

  function allowDoForNext(): boolean {
    return doForNextEnabled && conflictIndex < resolutions.length - 1;
  }

  // Event Handlers
  function updateConflictIndex(change: 'increment' | 'decrement'): void {
    if (change == 'increment' && conflictIndex < resolutions.length - 1) {
      setConflictIndex((cur) => cur + 1);
    } else if (change == 'decrement' && conflictIndex > 0) {
      setConflictIndex((cur) => cur - 1);
    }
  }

  function updateResolution(resolution: ConflictResolution): void {
    const updatedResolutions = [...resolutions];
    updatedResolutions.splice(conflictIndex, 1, resolution);
    setResolutions(updatedResolutions);
  }

  function setDoForNextAvailable(available: boolean): void {
    setDoForNextEnabled(available);

    if (!available) {
      setDoForNext(false);
    }
  }

  function onFinish(): void {
    if (doForNext) {
      resolutions.slice(conflictIndex).forEach((resolution) => {
        resolution.action = resolutions[conflictIndex].action;
      });
    }

    close();
    resolveConflicts(resolutions);
  }

  function close(): void {
    closeModal();
    onClose?.();
  }

  // Effects
  useEffect(() => {
    async function setInitialResolutions(): Promise<void> {
      const {
        conflictSiblingsItems: updatedSiblings,
        conflictSiblingsWRECommands: commands
      } = await getConflictSiblings();

      if (currentParent.instanceId == destinationParent.instanceId) {
        for (const item of dataManager.items) {
          if (
            !updatedSiblings.find(
              (sibling) => sibling.instanceId == item.instanceId
            )
          ) {
            updatedSiblings.push(item);
          }
        }
      }

      setSiblings(updatedSiblings);

      if (
        commands.length &&
        !commands.find((instanceId) => instanceId == newVersionWRECommand)
      ) {
        setVersionRestrictedByWRE(true);
      }

      const resolutions = sourceItems
        .map(
          ({ item, file }) =>
            ({
              action: 'keep',
              conflictType: getConflictType(item, updatedSiblings),
              sourceItem: item,
              file: file,
              destinationItems: siblingConflicts(item, updatedSiblings).sort(
                bySequence
              ),
              customName: getNextName(item, updatedSiblings),
              customVersion: undefined,
              originalName: getOriginalName(item, updatedSiblings),
              originalFileName: getOriginalFileName(item, updatedSiblings)
            } as ConflictResolution)
        )
        .sort(byName);

      setContainsImmutableFile(
        resolutions.some((resolution) =>
          resolution.destinationItems.some((item) => itemIsImmutable(item))
        )
      );

      setResolutions(resolutions);
      setIsLoading(false);
    }

    setIsLoading(true);
    void setInitialResolutions();
  }, [httpService, sourceItems, getConflictSiblings]);

  // TSX
  return (
    <PWModal
      title={
        sourceItems.length == 1
          ? t('ConflictResolution.ResolveConflict')
          : t('ConflictResolution.ResolveConflicts')
      }
      className="c-r-modal"
      isLoading={isLoading}
      onClose={close}
      dialogProps={{ 'data-testid': 'ConflictResolutionModal' }}
    >
      <ConflictResolutionSummary
        resolution={resolutions[conflictIndex]}
        setDoForNextAvailable={setDoForNextAvailable}
        siblings={siblings}
        updateResolution={updateResolution}
        containsImmutableFile={containsImmutableFile}
        setInputError={setInputError}
        doNotShowReplaceOption={doNotShowReplaceOption}
        doNotShowVersionOption={
          doNotShowVersionOption || versionRestrictedByWRE
        }
      />
      {resolutions.length < 2 ? null : (
        <Checkbox
          label={doForNextString()}
          onChange={(e) => {
            setDoForNext(e.target.checked);
          }}
          data-testid="do-for-next-checkbox"
          checked={doForNext}
          disabled={!allowDoForNext()}
        />
      )}
      <ConflictResolutionFooter
        updateConflictIndex={updateConflictIndex}
        currentIndex={conflictIndex}
        fileCount={resolutions.length}
        resolution={resolutions[conflictIndex]}
        doForNextChecked={doForNext}
        onFinish={onFinish}
        cancelFunction={close}
        inputError={inputError}
      />
    </PWModal>
  );
}
