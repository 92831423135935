import type { WSGInstance, WSGProperties } from '@bentley/pw-api';

export function commonProperties(instances: WSGInstance[]): WSGProperties {
  const propertyStructure = emptyProperties(instances[0].properties);

  for (const key in propertyStructure) {
    if (propertyEqualAcrossInstances(instances, key)) {
      propertyStructure[key] = instances[0].properties?.[key];
    }
  }

  return propertyStructure;
}

export function emptyProperties(properties?: WSGProperties): WSGProperties {
  if (!properties || !Object.keys(properties).length) {
    return {};
  }

  const emptiedProperties = {} as WSGProperties;
  for (const key in properties) {
    const property = properties[key];
    emptiedProperties[key] = Array.isArray(property) ? [] : '';
  }

  return emptiedProperties;
}

function propertyEqualAcrossInstances(
  instances: WSGInstance[],
  propertyName: string
): boolean {
  const propertyValues = instances.map(
    (instance) => instance.properties?.[propertyName]
  );

  if (propertyValues.some((value) => value === undefined)) {
    return false;
  }

  return propertyValues.every((value) => value == propertyValues[0]);
}
