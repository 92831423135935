import type {
  HttpService,
  PWDataItem,
  PWFileType,
  PWItem
} from '@bentley/pw-api';
import {
  itemIsDataItem,
  itemIsFileType,
  itemIsFlatSet,
  parseFileExtension
} from '@bentley/pw-api';
import type { GraphApiContext } from '../../context';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type { ProgressManager } from '../../hooks/useProgressManager';
import type { TrackFeature } from '../../hooks/useTrackFeature';
import type { Connection } from '../../services/support';
import { checkOut } from '../sync';
import {
  notifyDownloadDrive,
  notifyInternalServerErrorDrive,
  notifyNavigateToDriveErrorWhenDriveIsOff,
  notifyOpenFileErrorWhenDriveIsOff
} from '../sync/notifications';
import { getItemDriveLock, setItemDriveLock } from './itemDriveLock';
import {
  driveCoAuthoringSessionRunningModal,
  driveManualCheckoutRequiredModal,
  driveSyncRequiredModal
} from './modals';
import { handleSyncResponse } from './pwDriveSyncProjectDialog/response';
import type {
  DriveVersionType,
  PWDriveManager,
  ValidDriveUser
} from './usePwDrive';

export type PwDriveProject = {
  ConnectionId: string;
  ProjectId: string;
  UserId: string;
};

export type PwDriveData = {
  InstanceId: string;
  ProjectId: string;
  UserId: string;
};

export type DriveData = {
  driveInfo: DriveInfo;
  port: number;
  isDriveActive: boolean;
};

type DriveUser = {
  id: string;
  email: string;
  name: string;
};

type DriveInfo = {
  activeConnectionIds: string[];
  driveUpdate: DriveUpdate;
  enabledFeatures: string[];
  name: string;
  syncedProjects: string[];
  user: DriveUser;
  version: string;
};

export type VersionInfo = {
  driveUpdate: DriveUpdate;
};

export type DriveUpdate = {
  updateState: number;
  updateStateString: string;
  downloadURL: string;
  isIdle: boolean;
  displayText: string;
  linkText: string;
  downloadVersion: string;
};

export type DriveResponseData = {
  data: DriveResponseBody;
  status: string;
};

type DriveResponseBody = {
  fileLockedByMe: boolean;
  success: boolean;
  message: string;
};

export type SyncProjectDriveResponseBody = {
  status: boolean;
  success: boolean;
  message: string;
};

type DriveLockedByResponse = {
  data: DriveLockedInfo[];
};

export type DriveLockedInfo = {
  instanceId: string;
  locked: boolean;
  lockedViaAutoCheckout: boolean;
  found: boolean;
};

export async function checkOutFileDrive(
  pwDriveData: PwDriveData,
  httpService: HttpService,
  trackFeature?: TrackFeature
): Promise<Response> {
  trackFeature?.('CHECKOUT_FILE_DRIVE');

  const response = await httpService.post(
    'CheckoutFile',
    JSON.stringify(pwDriveData)
  );

  return validatedResponse(response);
}

export async function checkInFileDrive(
  pwDriveData: PwDriveData,
  httpService: HttpService,
  trackFeature?: TrackFeature
): Promise<Response> {
  trackFeature?.('CHECKIN_FILE_DRIVE');

  const response = await httpService.post(
    'CheckinFile',
    JSON.stringify(pwDriveData)
  );

  return validatedResponse(response);
}

export async function freeFileDrive(
  pwDriveData: PwDriveData,
  httpService: HttpService,
  trackFeature?: TrackFeature
): Promise<Response> {
  trackFeature?.('FREE_FILE_DRIVE');

  const response = await httpService.post(
    'FreeFile',
    JSON.stringify(pwDriveData)
  );

  return validatedResponse(response);
}

export enum officeApplication {
  Word = 0,
  Excel = 1,
  PowerPoint = 2
}

export async function openO365FileDrive(
  oneDriveLink: string,
  userId: string,
  httpService: HttpService,
  officeApplication: officeApplication
): Promise<boolean> {
  try {
    const response = await httpService.post(
      'openOfficeDocument',
      JSON.stringify({
        UserId: userId,
        oneDriveLink: oneDriveLink,
        officeApplication: officeApplication
      })
    );

    const success = await successful365Response(response);
    return success;
  } catch {
    return false;
  }
}

async function successful365Response(response: Response): Promise<boolean> {
  if (
    response.status == 401 ||
    response.status == 500 ||
    response.status == 503
  ) {
    return false;
  }

  const responseObject = (await response.clone().json()) as Record<
    string,
    unknown
  >;

  if (!responseObject.success) {
    return false;
  }

  return true;
}

// Todo: this function is exported, but not used outside this file
// Remove export and check for other functions that are not used outside this file
export async function openFileDrive(
  selectedItem: PWDataItem,
  userId: string,
  httpService: HttpService,
  contextId: string
): Promise<Response> {
  let response;
  try {
    response = await httpService.post(
      'OpenFile',
      JSON.stringify({
        InstanceId: selectedItem.instanceId,
        UserId: userId,
        ProjectId: contextId
      })
    );
  } catch (error) {
    notifyOpenFileErrorWhenDriveIsOff();
    throw error;
  }

  return validatedDriveResponse(response);
}

export async function openFolderSelectFileDrive(
  selectedItem: PWDataItem,
  userId: string,
  httpService: HttpService,
  contextId: string
): Promise<Response> {
  let response;
  try {
    response = await httpService.post(
      'OpenFolderAndSelectItem',
      JSON.stringify({
        InstanceId: selectedItem.instanceId,
        UserId: userId,
        ProjectId: contextId
      })
    );
  } catch (error) {
    notifyNavigateToDriveErrorWhenDriveIsOff();
    throw error;
  }

  return validatedDriveResponse(response);
}

function validatedDriveResponse(response: Response): Promise<Response> {
  if (response.status == 500 || response.status == 401) {
    notifyInternalServerErrorDrive();
  }

  if (response.status == 503) {
    notifyDownloadDrive();
  }

  return validatedResponse(response);
}

export async function syncProjectDrive(
  pwDriveProject: PwDriveProject,
  httpService: HttpService
): Promise<Response> {
  const response = await httpService.post(
    'SyncProject',
    JSON.stringify(pwDriveProject)
  );

  return validatedResponse(response);
}

export async function unsyncProjectDrive(
  pwDriveData: PwDriveProject,
  httpService: HttpService
): Promise<Response> {
  const response = await httpService.post(
    'UnsyncProject',
    JSON.stringify(pwDriveData)
  );

  return validatedResponse(response);
}

export function showDesktopApp(
  items: PWItem[],
  driveManager: PWDriveManager
): boolean {
  if (items.length != 1) {
    return false;
  }

  const item = items[0];

  if (!itemIsFileType(item)) {
    return false;
  }

  if (
    driveManager.pwDriveSyncDisabled &&
    !driveManager.isDriveEnabledForConnection
  ) {
    return false;
  }

  if (driveManager.driveVersionType == 'Obsolete') {
    return false;
  }

  if (
    !driveManager.isCurrentConnectionSynced &&
    !driveManager.isDriveEnabledForConnection
  ) {
    return false;
  }

  if (driveManager.validDriveUser == 'LogicalUser') {
    return false;
  }

  return true;
}

export function showOpenWindowsExplorer(
  readOnly: boolean,
  checkedRows: PWItem[],
  isPwDriveSyncEnabled: boolean,
  driveVersionType: DriveVersionType,
  isCurrentConnectionSynced: boolean,
  validDriveUser: ValidDriveUser
): boolean {
  if (readOnly) {
    return false;
  }

  if (checkedRows.length != 1) {
    return false;
  }

  if (itemIsFlatSet(checkedRows[0])) {
    return false;
  }

  if (!isPwDriveSyncEnabled) {
    return false;
  }

  if (driveVersionType == 'Obsolete') {
    return false;
  }

  if (!isCurrentConnectionSynced) {
    return false;
  }

  if (validDriveUser == 'LogicalUser') {
    return false;
  }

  return true;
}

// Scan ports starting at 5000 to find which port Drive is running on
export async function findPWDriveInfo(
  abortController?: AbortController
): Promise<DriveData | undefined> {
  for (let port = 5000; port <= 5100; port++) {
    if (abortController?.signal.aborted) {
      return;
    }

    try {
      const response = await getDriveMetaData(port);
      if (response && response.driveInfo.user) {
        return response;
      }
    } catch (error) {
      // This is not the port you are looking for
    }
  }

  return undefined;
}

export async function getDriveMetaData(
  port: number
): Promise<DriveData | undefined> {
  try {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), 5000);
    const response = await fetch(`http://localhost:${port}/metadata`, {
      signal: abortController.signal
    });
    const driveData = (await response.json()) as DriveInfo;
    return {
      driveInfo: driveData,
      port,
      isDriveActive: true
    } as DriveData;
  } catch (error) {
    return undefined;
  }
}

export async function getDriveVersionInfo(
  port: number
): Promise<VersionInfo | undefined> {
  try {
    const response = await fetch(`http://localhost:${port}/version`);
    const driveData = (await response.json()) as VersionInfo;
    return driveData;
  } catch (error) {
    return undefined;
  }
}

export async function getFileLockedStatus(
  items: PWItem[],
  userId: string,
  httpService: HttpService,
  contextId: string
): Promise<DriveLockedInfo[]> {
  try {
    const response = await httpService.post(
      'LockedByMe',
      JSON.stringify({
        InstanceIds: items.map((item) => item.instanceId),
        UserId: userId,
        ProjectId: contextId
      })
    );

    const lockedByMeData = (await response.json()) as DriveLockedByResponse;
    return lockedByMeData.data;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}

export function addIsLockedFileStatusToItem(
  item: PWItem,
  driveLockedInfo: DriveLockedInfo[]
): boolean {
  if (!itemIsDataItem(item)) {
    return false;
  }

  let dataItemUpdated = false;

  const data =
    driveLockedInfo.find(
      (data) => data.found && data.instanceId == item.instanceId
    ) ?? ({ locked: true, lockedViaAutoCheckout: false } as DriveLockedInfo);

  const previousLockedData = getItemDriveLock(item);

  if (
    previousLockedData?.locked != data.locked ||
    previousLockedData?.lockedViaAutoCheckout != data.lockedViaAutoCheckout
  ) {
    setItemDriveLock(item, data);
    dataItemUpdated = true;
  }

  return dataItemUpdated;
}

export function openInDesktopApp(
  item: PWDataItem,
  httpService: HttpService,
  refreshView: () => void,
  refreshOutToMe: () => void,
  contextId: string,
  driveManager: PWDriveManager,
  driveSynced: Connection[],
  userId: string,
  manualCheckoutRequired: boolean,
  graphApiManager: GraphApiContext,
  openModal: OpenModal,
  closeModal: CloseModal,
  trackFeature: TrackFeature,
  progressManager?: ProgressManager
): void {
  const openItemInDrive = () => {
    void openFileDrive(item, userId, driveManager.httpDriveService, contextId);
    if (getOfficeApplicationType(item.FileName)) {
      trackFeature('OPEN_IN_DESKTOP_APP_OFFICE_FILE');
    } else {
      trackFeature('OPEN_IN_DESKTOP_APP_OTHER');
    }
  };

  const syncDrive = async () => {
    const response = await syncProjectDrive(
      {
        ConnectionId: driveSynced[0]?.Id,
        ProjectId: contextId,
        UserId: userId
      } as PwDriveProject,
      driveManager.httpDriveService
    );
    void handleSyncResponse(response);
    driveManager.setPwDriveSyncDisabled(false);
    driveManager.syncDrive();
  };

  const openForCoauthoring = async (): Promise<void> => {
    const onComplete = () => {
      refreshView();
      refreshOutToMe();
    };
    await graphApiManager.joinCoAuthoringSession(
      item as PWFileType,
      onComplete
    );
  };

  const manualCheckoutFunction = async () => {
    await checkOut(
      [item],
      httpService,
      refreshView,
      refreshOutToMe,
      contextId,
      driveManager,
      progressManager,
      openModal,
      closeModal,
      trackFeature,
      userId
    );
    openItemInDrive();
  };

  if (
    driveManager.isDriveEnabledForConnection &&
    !driveManager.isCurrentConnectionSynced
  ) {
    return driveSyncRequiredModal(closeModal, openModal, syncDrive);
  }

  const oneDriveSessionInProgress =
    graphApiManager.checkCoAuthoringSessionExists(item);
  if (oneDriveSessionInProgress) {
    return driveCoAuthoringSessionRunningModal(
      openModal,
      openForCoauthoring,
      openItemInDrive
    );
  }

  if (
    (manualCheckoutRequired && !item.IsLocked) ||
    (getItemDriveLock(item)?.locked &&
      !doesDriveSupportAutoCheckout(driveManager.driveVersion))
  ) {
    return driveManualCheckoutRequiredModal(
      openModal,
      manualCheckoutFunction,
      openItemInDrive
    );
  }

  return openItemInDrive();
}

export type DriveVersionSpecificFeature = 'CheckOut' | 'OpenO365';

export function isDriveFeatureAvailable(
  driveVersion: string,
  feature: DriveVersionSpecificFeature
): boolean {
  const driveVersionParts: string[] = driveVersion?.split('.');
  const isNewVersionFormat = driveVersionParts.length == 4;
  let supportedVersion = '';
  switch (feature) {
    case 'CheckOut':
      supportedVersion = isNewVersionFormat ? '2020.8.23.0' : '2020.8.23';
      break;
    case 'OpenO365':
      supportedVersion = isNewVersionFormat ? '2021.3.21.9' : '2021.3.219';
      break;
  }
  if (supportedVersion == '') {
    return false;
  }
  const supportedVersionParts = supportedVersion.split('.');
  // Todo: this is a bug; a version with the same year would pass, even if it doesn't have same month
  // Additionally, if a the month is equal or greater it would pass, even if the year is lower
  if (
    parseInt(driveVersionParts[0]) >= parseInt(supportedVersionParts[0]) ||
    parseInt(driveVersionParts[1]) >= parseInt(supportedVersionParts[1]) ||
    parseInt(driveVersionParts[2]) >= parseInt(supportedVersionParts[2]) ||
    (isNewVersionFormat &&
      parseInt(driveVersionParts[3]) >= parseInt(supportedVersionParts[3]))
  ) {
    return true;
  } else {
    return false;
  }
}

function driveVersionOlderThanRequired(
  driveVersion: string,
  requiredVersion: string
): boolean {
  const driveVerArr: string[] = driveVersion?.split('.');
  const driveServiceVerArr: string[] = requiredVersion?.split('.');

  if (Number(driveVerArr[0]) < Number(driveServiceVerArr[0])) {
    return true;
  } else if (Number(driveVerArr[1]) < Number(driveServiceVerArr[1])) {
    return true;
  } else if (Number(driveVerArr[2]) < Number(driveServiceVerArr[2])) {
    return true;
  } else if (Number(driveVerArr[3]) < Number(driveServiceVerArr[3])) {
    return true;
  } else {
    return false;
  }
}

function doesDriveSupportAutoCheckout(driveVersion: string): boolean {
  return !driveVersionOlderThanRequired(driveVersion, '2022.1.0.0');
}

export function getVersionTypeDriveApi(value: number): DriveVersionType {
  // No new version is available, so no notification or update in the UX.
  if (value == 0) {
    return 'Latest';
  }

  // New version is available, but not mandatory to download.
  if (value == 1) {
    return 'OldValidVersion';
  }

  // New version available and is mandatory to continue running Drive.
  if (value == 2) {
    return 'Obsolete';
  }

  // Update functionality flag is Off
  if (value == 6) {
    return 'InvalidVersion';
  }

  return '';
}

export function isValidDriveData(driveManager: PWDriveManager): boolean {
  return (
    driveManager &&
    driveManager.pwDriveMetadataEnabled &&
    !driveManager.pwDriveSyncDisabled &&
    driveManager.validDriveUser != 'UserMismatch' &&
    driveManager.validDriveUser != 'LogicalUser' &&
    driveManager.driveVersionType != 'Obsolete' &&
    driveManager.isCurrentConnectionSynced &&
    driveManager.isDriveConnectionSynced
  );
}

export function getOfficeApplicationType(
  fileName: string
): officeApplication | undefined {
  const extension = parseFileExtension(fileName);
  switch (extension) {
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
      return officeApplication.Excel;
    case 'docx':
    case 'docm':
      return officeApplication.Word;
    case 'pptx':
    case 'pptm':
      return officeApplication.PowerPoint;
    default:
      return undefined;
  }
}

async function validatedResponse(response: Response): Promise<Response> {
  if (!response.ok) {
    const error = (await response.json()) as Record<string, unknown> | string;
    throw new Error(JSON.stringify({ error, status: response.status }));
  }

  return response;
}
