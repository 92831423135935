import { useCallback } from 'react';
import {
  useAppViewContext,
  useFeatureTracking,
  useFolderExplorerContext,
  useGraphApiContext,
  useHttpService,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../context';
import { useOutToMeSearch } from '../../hooks/useOutToMeSearch';

export function useRefreshData(): () => Promise<void> {
  const { trackFeature } = useFeatureTracking();
  const {
    connection,
    connectionIModelBridges: { refreshBridges },
    pwDriveData: { syncDrive }
  } = usePluginContext();
  const httpService = useHttpService();
  const { refreshCoAuthoringSessions } = useGraphApiContext();
  const { reloadExplorerFolders } = useFolderExplorerContext();
  const {
    setInfoPanelDisplayed,
    setShowDropzone,
    navigationManager: { refreshNavigation, currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const {
    dataManager: { refresh: refreshDataManager },
    selectedState: { setSelectedItems }
  } = useTableItemContext();

  const refreshOutToMe = useOutToMeSearch();

  const refreshData = useCallback(async (): Promise<void> => {
    trackFeature('GRID_REFRESH');
    await httpService.cache?.clear();
    void refreshOutToMe();

    if (currentAppView.type == 'FolderNavigation' && currentParent.instanceId) {
      refreshBridges();
      void refreshNavigation();
      reloadExplorerFolders([connection.ProjectId]);
    }

    void refreshDataManager();
    syncDrive();
    void refreshCoAuthoringSessions();
    setSelectedItems([]);

    setInfoPanelDisplayed(false);
    setShowDropzone(false);
  }, [
    httpService.cache,
    connection.ProjectId,
    currentAppView.type,
    currentParent.instanceId,
    refreshBridges,
    refreshDataManager,
    refreshNavigation,
    refreshOutToMe,
    reloadExplorerFolders,
    setInfoPanelDisplayed,
    setSelectedItems,
    setShowDropzone,
    syncDrive,
    trackFeature,
    refreshCoAuthoringSessions
  ]);

  return refreshData;
}
