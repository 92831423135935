import { useMemo } from 'react';
import type { MenuItemAction } from '../MenuItemAction';
import { formatMenuItems } from '../formatMenuItems';
import { useToolbarsDisabled } from '../useToolbarsDisabled';
import {
  useAdvancedSearchAction,
  useCloseSearchAction,
  useFTRSearchAction,
  useMyAdvancedSearchesAction,
  useMySearchesActions,
  useQuickSearchAction
} from './buttons';

export function useSearchActions(): MenuItemAction[] {
  const quickSearch = useQuickSearchAction();
  const ftrSearch = useFTRSearchAction();
  const advancedSearch = useAdvancedSearchAction();
  const myAdvancedSearches = useMyAdvancedSearchesAction();
  const closeSearch = useCloseSearchAction();
  const mySearches = useMySearchesActions();

  const toolbarDisabled = useToolbarsDisabled();

  const searchActions = useMemo(
    (): MenuItemAction[] =>
      formatMenuItems(
        [
          quickSearch,
          ftrSearch,
          advancedSearch,
          myAdvancedSearches,
          closeSearch,
          ...mySearches
        ],
        toolbarDisabled
      ),
    [
      quickSearch,
      ftrSearch,
      advancedSearch,
      myAdvancedSearches,
      closeSearch,
      mySearches,
      toolbarDisabled
    ]
  );

  return searchActions;
}
