import type {
  PWDataItem,
  PWItem,
  RequestOptions,
  WSGChangedInstanceResponse,
  WSGInstance,
  WSGInstancesResponse,
  WSGProperties
} from '@bentley/pw-api';
import {
  parseInstanceRelationshipInstances,
  parseResponseChangedInstance,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import type { FormDefinition } from '../../../actions/formRendering';
import { getAttributesHttpService } from './httpService';

export async function getDocumentEnvironmentInstances(
  item: PWDataItem,
  requestOptions?: RequestOptions
): Promise<WSGInstance[]> {
  const httpService = getAttributesHttpService();
  const response = await httpService.get(
    `PW_WSG/${item.className}/${item.instanceId}?$select=PW_WSG.DocumentEnvironment-forward-PW_WSG.Environment!poly.*`,
    { ...requestOptions, uncached: true }
  );

  const environmentInstances = await parseEnvironmentInstances(response);
  return environmentInstances;
}

export async function getDocumentFormDefinition(
  item: PWDataItem
): Promise<FormDefinition | undefined> {
  const httpService = getAttributesHttpService();

  const response = await httpService.get(
    `PW_WSG/${item.className}/${item.instanceId}?$select=PW_WSG.DocumentEnvironment-forward-PW_WSG.Environment!poly/PW_WSG.EnvironmentFormDefinition-forward-Forms_EC_Mapping.FormDefinition.*`,
    { uncached: true }
  );

  const formDefinition = await parseFormDefinition(response);
  return formDefinition;
}

export async function saveDocumentEnvironmentInstance(
  item: PWItem,
  instance: WSGInstance,
  updatedProperties: WSGProperties
): Promise<WSGInstance> {
  const httpService = getAttributesHttpService();

  const body = JSON.stringify({
    instance: saveDocumentEnvironmentInstanceBody(instance, updatedProperties),
    requestOptions: { RefreshInstances: true }
  });

  const response = await httpService.post(
    `PW_WSG_Dynamic/${instance.className}/${instance.instanceId}`,
    body
  );
  if (response.status == 404) {
    return createDocumentEnvironmentInstance(item, instance, updatedProperties);
  }
  const environmentInstance = await parseChangedEnvironmentInstance(response);
  return environmentInstance;
}

export async function createDocumentEnvironmentInstance(
  item: PWItem,
  instance: WSGInstance,
  properties: WSGProperties
): Promise<WSGInstance> {
  const httpService = getAttributesHttpService();
  const body = JSON.stringify({
    instance: createEnvironmentInstanceBody(item, instance, properties)
  });
  const response = await httpService.post(
    `PW_WSG/${item.className}/${item.instanceId}`,
    body
  );
  const environmentInstance = await parseCreatedEnvironmentInstance(response);
  return environmentInstance;
}

async function parseEnvironmentInstances(
  response: Response
): Promise<WSGInstance[]> {
  if (!response.ok || response.status != 200) {
    throw response;
  }
  const data = (await response.json()) as WSGInstancesResponse;

  const environmentInstances =
    data.instances[0].relationshipInstances?.map((ri) => ri.relatedInstance) ??
    [];

  return environmentInstances;
}

async function parseFormDefinition(
  response: Response
): Promise<FormDefinition | undefined> {
  if (!response.ok || response.status != 200) {
    throw response;
  }

  const environmentInstances = await parseResponseRelationshipInstances(
    response
  );

  if (!environmentInstances?.length) {
    return undefined;
  }

  const formDefinitions = parseInstanceRelationshipInstances<FormDefinition>(
    environmentInstances[0]
  );
  const formDefinition = formDefinitions[0];

  return formDefinition;
}

function saveDocumentEnvironmentInstanceBody(
  instance: WSGInstance,
  properties: WSGProperties
): WSGInstance {
  return {
    schemaName: 'PW_WSG_Dynamic',
    className: instance.className,
    instanceId: instance.instanceId,
    changeState: 'modified',
    properties: properties
  } as WSGInstance;
}

function createEnvironmentInstanceBody(
  item: PWItem,
  instance: WSGInstance,
  properties: WSGProperties
): WSGInstance {
  return {
    schemaName: 'PW_WSG',
    className: item.className,
    instanceId: item.instanceId,
    changeState: 'modified',
    relationshipInstances: [
      {
        schemaName: 'PW_WSG',
        className: 'DocumentEnvironment',
        direction: 'forward',
        changeState: 'new',
        relatedInstance: {
          schemaName: 'PW_WSG_Dynamic',
          className: instance.className,
          instanceId: instance.instanceId,
          changeState: 'new',
          properties: properties
        }
      }
    ]
  } as WSGInstance;
}

async function parseChangedEnvironmentInstance(
  response: Response
): Promise<WSGInstance> {
  if (!response.ok || response.status != 200) {
    throw response;
  }
  const data = (await response.json()) as WSGChangedInstanceResponse;
  const instance = data.changedInstance.instanceAfterChange;
  return instance;
}

async function parseCreatedEnvironmentInstance(
  response: Response
): Promise<WSGInstance> {
  const changedInstance = await parseResponseChangedInstance(response);

  const environmentInstances = changedInstance.relationshipInstances?.map(
    (relationshipInstance) => relationshipInstance.relatedInstance
  );

  if (!environmentInstances?.length) {
    throw new Error('Unable to create new environment instance');
  }
  return environmentInstances[0];
}
