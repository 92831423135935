import type { ProviderProps } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import type { AppViewManager } from '../../../hooks/useAppView';
import { useAppView } from '../../../hooks/useAppView';
import type { SearchBreadcrumbManager } from '../../../hooks/useBreadcrumb';
import { useSearchBreadcrumbs } from '../../../hooks/useBreadcrumb';
import type { WorkAreaManager } from '../../../hooks/useCurrentWorkArea';
import { useCurrentWorkArea } from '../../../hooks/useCurrentWorkArea';
import type { FolderSettingsManager } from '../../../hooks/useFolderSettings';
import { useFolderSettings } from '../../../hooks/useFolderSettings';
import { useNavigationContext } from '../navigation';

type AppViewContext = {
  appViewManager: AppViewManager;
  folderSettingsManager: FolderSettingsManager;
  searchBreadcrumbManager: SearchBreadcrumbManager;
  workAreaManager: WorkAreaManager;
};

const Context = createContext<AppViewContext | undefined>(undefined);

export function AppViewProvider({
  children
}: ProviderProps<AppViewContext | undefined>): JSX.Element {
  const { navigationManager } = useNavigationContext();

  const appViewManager = useAppView();
  const folderSettingsManager = useFolderSettings(
    appViewManager.currentAppView.type
  );
  const searchBreadcrumbManager = useSearchBreadcrumbs(
    appViewManager.currentAppView
  );
  const workAreaManager = useCurrentWorkArea(
    navigationManager.currentParent,
    appViewManager.currentAppView
  );

  const appViewContext = useMemo(
    (): AppViewContext => ({
      appViewManager,
      folderSettingsManager,
      searchBreadcrumbManager,
      workAreaManager
    }),
    [
      appViewManager,
      folderSettingsManager,
      searchBreadcrumbManager,
      workAreaManager
    ]
  );

  return <Context.Provider value={appViewContext}>{children}</Context.Provider>;
}

export function useAppViewContext(): AppViewContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useAppViewContext must be used within an AppViewContext');
  }
  return context;
}
