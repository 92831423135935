import React from 'react';
import { SvgCheckmark } from '@itwin/itwinui-icons-react';
import type { CellProps } from '@itwin/itwinui-react/react-table';
import type { View } from '../../../hooks/useViews';

export function AllConnectionsCell({
  row
}: CellProps<View>): JSX.Element | null {
  const view = row.original;

  if (!view.multiConnection) {
    return null;
  }

  return <SvgCheckmark className="all-connections-check" />;
}
