import type {
  HttpService,
  RequestOptions,
  WSGInstancesResponse,
  WSGProperties
} from '@bentley/pw-api';
import { parseResponseRelationshipInstances } from '@bentley/pw-api';
import { ecPluginFeatures } from '../useECPluginVersion';
import type { State, Workflow } from './useDocumentProperties';

export function valueMustBeLimitedToList(columnName: string): boolean {
  return columnName != 'Application';
}

export async function getStates(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<State[]> {
  const response = await httpService.get(
    'PW_WSG/State?$select=Name',
    httpOptions
  );

  const data = (await response.json()) as WSGInstancesResponse;
  const states = data.instances.map(
    (instance) =>
      ({
        instanceId: instance.instanceId,
        Name: instance.properties?.Name
      } as State)
  );
  return states;
}

export async function getWorkflows(
  connectionProjectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<Workflow[]> {
  if (ecPluginFeatures.projectResourceMetadata() && connectionProjectId) {
    return getConnectionWorkflows(
      connectionProjectId,
      httpService,
      httpOptions
    );
  }

  return getDatasourceWorkflows(httpService, httpOptions);
}

async function getConnectionWorkflows(
  connectionProjectId: string,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<Workflow[]> {
  const query = `PW_WSG/Project/${connectionProjectId}?$select=ProjectResource-forward-Workflow.Name`;
  const response = await httpService.get(query, httpOptions);

  const workflows = await parseResponseRelationshipInstances<Workflow>(
    response
  );
  return workflows;
}

async function getDatasourceWorkflows(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<Workflow[]> {
  const response = await httpService.get(
    'PW_WSG/Workflow?$select=Name',
    httpOptions
  );

  const data = (await response.json()) as WSGInstancesResponse;
  const workflows = data.instances.map(
    (instance) =>
      ({
        instanceId: instance.instanceId,
        Name: instance.properties?.Name
      } as Workflow)
  );
  return workflows;
}

export async function getStatuses(
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<string[]> {
  const filter = `ClassHasProperty-backward-ECClassDef.Name eq 'Document' and ClassHasProperty-backward-ECClassDef.Schema like 'PW_WSG'`;
  const select =
    'CustomAttributeContainerHasCustomAttribute-forward-DataSourceSpecification.StandardValues.EnumValues';
  const response = await httpService.get(
    `MetaSchema/ECPropertyDef?$filter=${filter}&$select=${select}`,
    httpOptions
  );

  if (!response.ok) {
    return [];
  }

  const wsgResponse = (await response.json()) as WSGInstancesResponse;
  return parseDocumentStatusResponse(wsgResponse);
}

function parseDocumentStatusResponse(response: WSGInstancesResponse): string[] {
  const statusInstance = response.instances.find((instance) =>
    instance.instanceId.includes('Status')
  );

  if (!statusInstance) {
    return [];
  }

  const statusProperties =
    statusInstance.relationshipInstances?.[0]?.relatedInstance.properties;

  if (!statusProperties) {
    return [];
  }

  const enumValues =
    statusProperties.EnumValues as typeof statusProperties.EnumValues[];
  return enumValues.map((value) => (value as WSGProperties).Value as string);
}
