import { atom } from 'recoil';
import type { PWDataItem, WSGInstance } from '@bentley/pw-api';

export const selectedItemsState = atom<PWDataItem[]>({
  key: 'attr_selectedItemsState',
  default: []
});

export const currentSheetState = atom<number>({
  key: 'attr_currentSheetState',
  default: 0
});

export const originalEnvironmentInstanceState = atom<WSGInstance | null>({
  key: 'attr_originalEnvironmentInstanceState',
  default: null
});

export const currentEnvironmentInstanceState = atom<WSGInstance | null>({
  key: 'attr_currentEnvironmentInstanceState',
  default: originalEnvironmentInstanceState
});

export const editingAttributesFormState = atom<boolean>({
  key: 'attr_editingAttributesFormState',
  default: false
});

export const forceFormRefreshState = atom<boolean>({
  key: 'attr_forceFormRefreshState',
  default: false
});
