import React, { useCallback, useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { Tabs } from '@itwin/itwinui-react';
import { useFeatureTracking, useTableItemContext } from '../../../context';
import { PWInfoPanelEmptyState } from './pwInfoPanelEmptyState';
import { usePWInfoPanelTabs } from './usePWInfoPanelTabs';

type PWInfoPanelTabsProps = {
  orientation: 'vertical' | 'horizontal';
};

export function PWInfoPanelTabs({
  orientation
}: PWInfoPanelTabsProps): JSX.Element {
  const { trackFeature } = useFeatureTracking();

  const { actionableItems } = useTableItemContext();

  const [activeTab, setActiveTab] = useSessionStorage<string>(
    'infoPanelTab',
    'Details'
  );

  const { details, auditTrail, attributes, docCode } = usePWInfoPanelTabs();

  const onTabValueChange = useCallback(
    (label: string): void => {
      setActiveTab(label);
    },
    [setActiveTab]
  );

  useEffect(() => {
    if (activeTab == auditTrail.label && !auditTrail.enabled) {
      onTabValueChange(details.label);
    }

    if (activeTab == attributes.label && !attributes.enabled) {
      onTabValueChange(details.label);
    }

    if (activeTab == docCode.label && !docCode.enabled) {
      onTabValueChange(details.label);
    }
  }, [
    activeTab,
    onTabValueChange,
    attributes.enabled,
    attributes.label,
    auditTrail.enabled,
    auditTrail.label,
    details.label,
    docCode.enabled,
    docCode.label
  ]);

  useEffect(() => {
    if (activeTab == auditTrail.label) {
      trackFeature('INFO_PANEL_AUDIT_TRAIL');
    }

    if (activeTab == attributes.label) {
      trackFeature('INFO_PANEL_ENV_ATTR');
    }

    if (activeTab == docCode.label) {
      trackFeature('INFO_PANEL_DOC_CODE');
    }
  }, [
    activeTab,
    attributes.label,
    auditTrail.label,
    docCode.label,
    trackFeature
  ]);

  if (actionableItems.length == 0) {
    return <PWInfoPanelEmptyState />;
  }

  return (
    <Tabs.Wrapper
      type="borderless"
      orientation={orientation === 'vertical' ? 'horizontal' : 'vertical'}
      className="tabs-wrapper"
      onValueChange={onTabValueChange}
      value={activeTab}
    >
      <Tabs.TabList>
        {details.tab}
        {auditTrail.tab}
        {attributes.tab}
        {docCode.tab}
      </Tabs.TabList>
      {details.panel}
      {auditTrail.panel}
      {attributes.panel}
      {docCode.panel}
    </Tabs.Wrapper>
  );
}
