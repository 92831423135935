import React from 'react';
import { Text } from '@itwin/itwinui-react';
import { PWModal } from '../../components/pwModal';
import { t } from '../../services/translation';

type DeleteConfirmationModalProps = {
  onYes: () => void;
  onNo: () => void;
  customTitle?: string;
  customMessage?: string;
};

export function DeleteConfirmationModal({
  onYes,
  onNo,
  customTitle,
  customMessage
}: DeleteConfirmationModalProps): JSX.Element {
  return (
    <PWModal
      title={customTitle || t('MyViews.ConfirmDelete')}
      primaryButton={{
        title: t('Generic.Yes'),
        onClick: () => {
          onNo();
          onYes();
        },
        disabled: false
      }}
      secondaryButton={{
        title: t('Generic.No'),
        onClick: onNo
      }}
      onClose={onNo}
      dialogProps={{ 'data-testid': 'deleteConfirmationModal' }}
    >
      <Text>{customMessage || t('MyViews.DeleteItem')}</Text>
    </PWModal>
  );
}
