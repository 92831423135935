import React, { useEffect, useMemo, useState } from 'react';
import type { PWDataItem } from '@bentley/pw-api';
import { Table } from '@itwin/itwinui-react';
import { t } from '../../../services/translation';
import type { IModelMapping } from '../iModelBridge';
import { bridgeExists } from '../utils';
import { useIModelColumns } from './columns';

type IModelMapTableProps = {
  tableDisabled: boolean;
  iModelMappings: IModelMapping[];
  setIModelMappings: (value: IModelMapping[]) => void;
  updateIModelMapping: (
    document: PWDataItem,
    isSheet: boolean,
    isMaster: boolean,
    isSpatialRoot?: boolean
  ) => void;
};

export type MappingDataRecord = {
  DataItem: PWDataItem;
  isMaster: boolean;
  isSheet: boolean;
  isExistingBridge: boolean | undefined;
};

export function IModelMapTable({
  tableDisabled,
  iModelMappings,
  setIModelMappings,
  updateIModelMapping
}: IModelMapTableProps): JSX.Element {
  const [allMasterChecked, setAllMasterChecked] = useState<boolean>(false);
  const [allSheetChecked, setAllSheetChecked] = useState<boolean>(false);

  const hasExistingBridges = bridgeExists(iModelMappings);
  const notEnoughMappings = iModelMappings.length < 2;

  const columns = useIModelColumns({
    tableDisabled,
    hasExistingBridges,
    notEnoughMappings,
    allMasterChecked,
    onMasterHeaderClick,
    onMasterCellClick,
    allSheetChecked,
    onSheetHeaderClick,
    onSheetCellClick
  });

  useEffect(() => {
    const allMasterChecked =
      !notEnoughMappings &&
      iModelMappings.every(
        (mapping) => mapping.isMaster && !mapping.isSpatialRoot
      );
    const allSheetChecked =
      !notEnoughMappings &&
      iModelMappings.every(
        (mapping) => mapping.isSheet && !mapping.isSpatialRoot
      );
    setAllMasterChecked(allMasterChecked);
    setAllSheetChecked(allSheetChecked);
  }, [iModelMappings]);

  function onMasterHeaderClick() {
    const checked = !allMasterChecked;
    setAllMasterChecked(checked);
    if (checked) {
      setAllSheetChecked(false);
    }
    const updatedMappings = [...iModelMappings];
    updatedMappings.forEach((mapping) => {
      if (!mapping.isSpatialRoot) {
        mapping.isSheet = false;
        mapping.isMaster = checked;
      }
    });
    setIModelMappings(updatedMappings);
  }

  function onSheetHeaderClick() {
    const checked = !allSheetChecked;
    setAllSheetChecked(checked);
    if (checked) {
      setAllMasterChecked(false);
    }
    const updatedMappings = [...iModelMappings];
    updatedMappings.forEach((mapping) => {
      if (!mapping.isSpatialRoot) {
        mapping.isSheet = checked;
        mapping.isMaster = false;
      }
    });
    setIModelMappings(updatedMappings);
  }

  function onMasterCellClick(
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) {
    const isMaster = event.target.value == 'on';
    if (allSheetChecked) {
      setAllSheetChecked(false);
    }
    updateIModelMapping(item, false, isMaster);
  }

  function onSheetCellClick(
    event: React.ChangeEvent<HTMLInputElement>,
    item: PWDataItem
  ) {
    const isSheet = event.target.value == 'on';
    if (allMasterChecked) {
      setAllMasterChecked(false);
    }
    updateIModelMapping(item, isSheet, false);
  }

  const tableData: MappingDataRecord[] = useMemo(() => {
    return iModelMappings
      .filter((mapping) => !mapping.isSpatialRoot)
      .map((mapping) => ({
        DataItem: mapping.document,
        isMaster: mapping.isMaster,
        isSheet: mapping.isSheet,
        isExistingBridge: mapping.isExistingBridge
      }));
  }, [iModelMappings]);

  return (
    <Table<MappingDataRecord>
      columns={columns}
      data={tableData}
      isResizable={true}
      className="iModel-table"
      density="extra-condensed"
      emptyTableContent={t('IModel.MappedAsSpatialRoot')}
    />
  );
}
