import _ from 'lodash';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import { flattenProperties } from '@bentley/pw-api';
import type { RenditionProfile } from './renditionProfile';

export type ComponentClassName =
  | 'RendSvcPresentationOption'
  | 'RendSvcRenditionOption'
  | 'RendSvcFilenameOption'
  | 'RendSvcDestinationOption'
  | 'RendSvcDestinationFolder';
export type RenditionSubmitAction =
  | 'RenditionSubmitActionDocument'
  | 'RenditionSubmitActionPresentationOption'
  | 'RenditionSubmitActionRenditionOption'
  | 'RenditionSubmitActionFilenameOption'
  | 'RenditionSubmitActionDestinationOption'
  | 'RenditionSubmitActionOutputProject';
export type RenditionDropdownOption = {
  label: string;
  value: string;
  isDefault?: boolean;
  allowBrowse?: boolean;
};
export type RenditionSelection = {
  profileId: string | undefined;
  presentationId: string | undefined;
  formatId: string | undefined;
  fileNameId: string | undefined;
  destinationId: string | undefined;
  folder: string | undefined;
  folderName: string | undefined;
  sendEmail: boolean | undefined;
};

export function flattenRendtionData(
  renditonData: RenditionProfile[]
): RenditionProfile[][] {
  return renditonData.map((data) => {
    if (data.relationshipInstances) {
      return data.relationshipInstances.map((instance) => {
        if (instance.relatedInstance) {
          return flattenProperties(
            instance.relatedInstance
          ) as RenditionProfile;
        } else {
          return {} as RenditionProfile;
        }
      });
    } else {
      return [{}] as RenditionProfile[];
    }
  });
}

export function getLargestRenditionData(
  renditionsResources: RenditionProfile[][]
): RenditionProfile[] {
  let largestData = [{}] as RenditionProfile[];
  if (!renditionsResources.length) {
    return largestData;
  }
  largestData = renditionsResources[0];
  renditionsResources.forEach((resources) => {
    if (resources.length > largestData.length) {
      largestData = resources;
    }
  });
  // find and set the default rendition profile from the query
  const defaultRenditionIndex = largestData.findIndex(
    (rendtion) => !rendtion.relationshipInstances
  );
  if (defaultRenditionIndex > 0) {
    const defaultRenditionId = largestData[defaultRenditionIndex].instanceId;
    largestData.splice(defaultRenditionIndex, 1);
    largestData = largestData.map((item) => {
      if (item.instanceId == defaultRenditionId) {
        item.IsDefaultForWA = true;
      }
      return item;
    });
  }

  return largestData;
}

export function getRenditionProjectId(
  currentParent: PWParentType,
  items: PWItem[],
  breadcrumbs: PWParentType[]
): string {
  let renditionProjectId = `'${currentParent.instanceId}'`;
  if (!currentParent.className || currentParent.className == 'FlatSet') {
    // check if the parentGuids have been loaded for the flatset data
    if (!items.some((item) => !item.ParentGuid)) {
      renditionProjectId = `'${_.uniq(
        items.map((item) => item.ParentGuid)
      ).join("','")}'`;
    } else {
      if (breadcrumbs.length > 1) {
        renditionProjectId = `'${
          breadcrumbs[breadcrumbs.length - 2].instanceId
        }'`;
      } else {
        renditionProjectId = '';
      }
    }
  }
  return renditionProjectId;
}
