import React from 'react';
import { ProgressSummaryModal } from '../../../components/ProgressSummary';
import type { ProgressSummaryItem } from '../../../components/ProgressSummary/progressSummaryModal';
import type { CloseModal, OpenModal } from '../../../hooks/useModal';
import type { ToastHandle } from '../../../services/pwToast';
import { openToast, replaceToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';

export function notifyRemoveInProgress(count: number): ToastHandle {
  const content =
    count == 1
      ? t('FlatSet.Remove.Notifications.InProgress')
      : t('FlatSet.Remove.Notifications.InProgressMulti');
  return openToast({ content, type: 'persisting', spinner: true });
}

export function notifyRemovedCompleted(
  numCompleted: number,
  toastHandle: ToastHandle
): void {
  if (numCompleted < 1) {
    notifyFailure(toastHandle);
  } else {
    notifySuccess(numCompleted, toastHandle);
  }
}

export function notifyRemoveErrors(
  failures: ProgressSummaryItem[],
  openModal: OpenModal,
  closeModal: CloseModal
): void {
  if (!failures.length) {
    return;
  }
  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={failures}
      title={t('FlatSet.Remove.Notifications.RemoveFailure')}
    />
  );
}

function notifySuccess(count: number, toastHandle: ToastHandle): void {
  const content =
    count == 1
      ? t('FlatSet.Remove.Notifications.RemoveSuccessSingle')
      : t('FlatSet.Remove.Notifications.RemoveSuccessMulti', {
          count
        });

  replaceToast(toastHandle, { content, category: 'positive' });
}

function notifyFailure(toastHandle: ToastHandle): void {
  const toasterMsg = t('FlatSet.Remove.Notifications.RemoveFailure');

  replaceToast(toastHandle, { content: toasterMsg, category: 'negative' });
}
