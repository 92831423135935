import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RequestOptions } from '@bentley/pw-api';
import { useEquivalentState } from '../../hooks/useNonRenderingState';
import type { IModelBridge } from './iModelBridge';

export type ConnectionIModelBridges = {
  iModelBridges: IModelBridge[];
  refreshBridges: () => void;
};

export function useConnectionIModelBridges(
  iModelRetrievalFunction: (
    httpOptions?: RequestOptions
  ) => Promise<IModelBridge[]>,
  readOnly?: boolean
): ConnectionIModelBridges {
  const [iModelBridges, setIModelBridges] = useEquivalentState<IModelBridge[]>(
    []
  );
  const [refresh, setRefresh] = useState<boolean>(false);

  const refreshBridges = useCallback((): void => {
    setRefresh((cur) => !cur);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    async function initializeIModelBridgesForConnectProject(): Promise<void> {
      const iModelBridges = await iModelRetrievalFunction({
        abortController
      });
      if (!abortController.signal.aborted) {
        setIModelBridges(iModelBridges);
      }
    }

    if (!readOnly) {
      void initializeIModelBridgesForConnectProject();
    }

    return () => {
      abortController.abort();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps --
     * setIModelBridges should not re-trigger effect
     */
  }, [iModelRetrievalFunction, refresh]);

  const connectionIModelBridges = useMemo(
    (): ConnectionIModelBridges => ({
      iModelBridges,
      refreshBridges
    }),
    [iModelBridges, refreshBridges]
  );

  return connectionIModelBridges;
}
