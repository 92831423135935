import type { User } from 'oidc-client';
import type { ProviderProps } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useBuddi } from '@bentley/pw-config';
import type { PWDriveManager } from '../../../actions/drive';
import { usePWDrive } from '../../../actions/drive';
import type {
  ConnectionIModelBridges,
  IModelMappingFunctions
} from '../../../actions/imodelMapping';
import {
  useConnectionIModelBridges,
  useIModelBridges
} from '../../../actions/imodelMapping';
import { useErrorLogging } from '../../../hooks/useErrorLogging';
import type { ProgressManager } from '../../../hooks/useProgressManager';
import { useProgressManager } from '../../../hooks/useProgressManager';
import type { Connection } from '../../../services/support';
import { useConnectionContext } from '../connection';
import { useConnectionsContext } from '../connections';
import { useToken } from '../token';
import type { ConsumerApp, DataAccessLevel } from './types';

const Context = createContext<PluginContext | undefined>(undefined);

type PluginProps = {
  buddiRegionCode: string;
  consumerApp?: ConsumerApp;
  contextId?: string;
  dataAccessLevel?: DataAccessLevel;
  gprId: number;
  pdfMarkupForms?: { selectedCategory: string; selectedForm: string }[];
  readOnly?: boolean;
  user: User;
  getTeamsTabLink?: () => Promise<string>;
  navigate?: (to: string) => void;
  openAuthenticationPopup?: (url: string) => Promise<boolean>;
  openDesignReview?: (connection: Connection, checkedRows: PWItem[]) => void;
  setFolderAccess?: (
    connection: Connection,
    item: PWItem,
    refresh: () => void
  ) => void;
};

type PluginContext = {
  connection: Connection;
  connectionIModelBridges: ConnectionIModelBridges;
  dataAccessLevel: DataAccessLevel;
  iModelMappingManager: IModelMappingFunctions;
  progressManager: ProgressManager;
  pwDriveData: PWDriveManager;
  pwWebConnectionUrl?: string | null;
  readOnly: boolean;
  teamsTabLink: string | undefined;
  logError: ReturnType<typeof useErrorLogging>;
} & PluginProps;

export function PluginProvider({
  value: {
    buddiRegionCode,
    consumerApp,
    contextId,
    dataAccessLevel = 'WorkArea',
    gprId,
    pdfMarkupForms,
    readOnly = false,
    user,
    getTeamsTabLink,
    navigate,
    openAuthenticationPopup,
    openDesignReview,
    setFolderAccess
  },
  children
}: ProviderProps<PluginProps>): JSX.Element {
  const { repositoryManager } = useConnectionsContext();
  const { connection } = useConnectionContext();

  const { getOidcToken, getSamlToken } = useToken();

  const iModelMappingManager = useIModelBridges(
    getSamlToken,
    buddiRegionCode,
    contextId
  );

  const pwDriveData = usePWDrive(
    getOidcToken,
    contextId,
    readOnly,
    connection.Id,
    repositoryManager.driveSyncedConnections
  );

  const progressManager = useProgressManager();

  const logError = useErrorLogging({
    buddiRegionCode,
    connection,
    consumerApp,
    contextId,
    dataAccessLevel
  });

  const pwWebConnectionUrl = useBuddi(
    'ProjectwiseWebconnections.URL',
    buddiRegionCode
  );

  const [teamsTabLink, setTeamsTabLink] = useState<string>();

  useEffect(() => {
    async function initializeTeamsTabLink(): Promise<void> {
      const link = await getTeamsTabLink?.();
      setTeamsTabLink(link);
    }

    void initializeTeamsTabLink();
  }, [getTeamsTabLink]);

  const connectionIModelBridges = useConnectionIModelBridges(
    iModelMappingManager.getIModelBridgesForConnectProject,
    readOnly
  );

  const pluginContext: PluginContext = {
    buddiRegionCode,
    connection,
    connectionIModelBridges,
    consumerApp,
    contextId,
    dataAccessLevel,
    gprId,
    iModelMappingManager,
    pdfMarkupForms,
    progressManager,
    pwDriveData,
    pwWebConnectionUrl,
    readOnly,
    teamsTabLink,
    user,
    logError,
    navigate,
    openAuthenticationPopup,
    openDesignReview,
    setFolderAccess
  };

  return <Context.Provider value={pluginContext}>{children}</Context.Provider>;
}

export function usePluginContext(): PluginContext {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('usePluginContext must be used within a PluginProvider');
  }

  return context;
}
