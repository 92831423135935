import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppViewContext, usePluginContext } from '../../context';
import { t } from '../../services/translation';
import { useLocalStorage } from '../useStorage';
import type { ColumnInfo } from './defaultView';
import { useEnvironmentProperties } from './environmentProperties/useEnvironmentProperties';
import { useAdminView } from './viewSettings/useAdminView';
import { useCustomView } from './viewSettings/useCustomView';
import { useDefaultView } from './viewSettings/useDefaultView';
import { usePWEFolderView } from './viewSettings/usePWEFolderView';
import { usePWEView } from './viewSettings/usePWEView';
import { useSavedSearchView } from './viewSettings/useSavedSearchView';
import { useUserView } from './viewSettings/useUserView';
import type { ViewSetting } from './viewSettings/viewSetting';

export type ViewManager = {
  currentView: View;
  currentViewDisplayLabel: string;
  initialized: boolean;
  pweFolderViewSetting: ViewSetting;
  adminViewSetting: ViewSetting;
  savedViewSetting: ViewSetting;
  userViewSetting: ViewSetting;
  customViewSetting: ViewSetting;
  loadViews: () => void;
};

export type View = {
  columns: ColumnInfo[];
  name: string;
  instanceId?: string;
  multiConnection?: boolean;
  isGlobalPWE?: boolean;
};

export type ViewType =
  | 'Default'
  | 'PWEFolderView'
  | 'AdminView'
  | 'PWESaved'
  | 'UserSaved'
  | 'SavedSearchView'
  | 'Custom';

export function useViews(): ViewManager {
  const { connection } = usePluginContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const [currentViewType, setCurrentViewType] = useLocalStorage<ViewType>(
    `columnView-${connection.Id}`,
    'PWEFolderView'
  );

  const [loadStarted, setLoadStarted] = useState<boolean>(false);

  const userRequestedView = useRef<ViewType>(currentViewType);

  const { addEnvironmentPropertiesToView } = useEnvironmentProperties();

  // "Global view"
  const pweFolderViewSetting = usePWEFolderView(
    loadStarted || currentViewType == 'PWEFolderView',
    setCurrentViewType
  );

  // "Default view"
  const defaultViewSetting = useDefaultView(setCurrentViewType);

  // "Admin view"
  const adminViewSetting = useAdminView(setCurrentViewType);

  // "Saved views"
  const savedViewSetting = usePWEView(
    loadStarted || currentViewType == 'PWESaved',
    setCurrentViewType
  );

  // "My Views"
  const userViewSetting = useUserView(setCurrentViewType);

  // Saved Search View
  const savedSearchView = useSavedSearchView(setCurrentViewType);

  // Column manager
  const customViewSetting = useCustomView(setCurrentViewType);

  const currentViewSetting = useMemo((): ViewSetting => {
    switch (currentViewType) {
      case 'PWEFolderView':
        return pweFolderViewSetting;
      case 'AdminView':
        return adminViewSetting;
      case 'PWESaved':
        return savedViewSetting;
      case 'UserSaved':
        return userViewSetting;
      case 'SavedSearchView':
        return savedSearchView;
      case 'Custom':
        return customViewSetting;
      case 'Default':
      default:
        return defaultViewSetting;
    }
  }, [
    adminViewSetting,
    currentViewType,
    customViewSetting,
    defaultViewSetting,
    pweFolderViewSetting,
    savedSearchView,
    savedViewSetting,
    userViewSetting
  ]);

  const currentView = useMemo(
    (): View => addEnvironmentPropertiesToView(currentViewSetting.view),
    [currentViewSetting.view, addEnvironmentPropertiesToView]
  );

  const currentViewDisplayLabel = useMemo((): string => {
    if (currentView.name == 'Default view' || currentView.name == 'default') {
      return t('ViewName.Default');
    }
    if (currentView.name == 'Custom view') {
      return t('ViewName.Custom');
    }

    if (currentView == pweFolderViewSetting.view) {
      return t('ViewName.Global');
    }

    return currentView.name;
  }, [currentView, pweFolderViewSetting.view]);

  const overrideUserRequestedView = useCallback(
    (viewType: ViewType): void => {
      setCurrentViewType((currentViewType) => {
        userRequestedView.current = currentViewType;
        return viewType;
      });
    },
    [setCurrentViewType]
  );

  const restoreUserRequestedView = useCallback((): void => {
    setCurrentViewType(userRequestedView.current);
  }, [setCurrentViewType]);

  useEffect(() => {
    if (currentAppView.type == 'SavedSearch') {
      overrideUserRequestedView('SavedSearchView');
      return restoreUserRequestedView;
    }

    if (currentAppView.type == 'RecycleBin') {
      overrideUserRequestedView('Default');
      return restoreUserRequestedView;
    }

    return;
  }, [
    currentAppView.type,
    overrideUserRequestedView,
    restoreUserRequestedView
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoadStarted(true);
    }, 15000);
  }, []);

  const viewManager = useMemo(
    (): ViewManager => ({
      initialized: currentViewSetting.initialized,
      currentView,
      currentViewDisplayLabel,
      pweFolderViewSetting,
      adminViewSetting,
      savedViewSetting,
      userViewSetting,
      customViewSetting,
      loadViews: () => setLoadStarted(true)
    }),
    [
      adminViewSetting,
      currentView,
      currentViewDisplayLabel,
      currentViewSetting.initialized,
      customViewSetting,
      pweFolderViewSetting,
      savedViewSetting,
      userViewSetting
    ]
  );

  return viewManager;
}
