import type { User } from 'oidc-client';
import React from 'react';
import type { RBACPermission } from '../../../hooks/useRbac';
import { t } from '../../../services/translation';
import { ErrorPage } from '../../errorPage';
import { hasSettingsPermissions } from './hasSettingsPermissions';

type WithSettingsPermissionsProps = {
  rbacPermissions: RBACPermission[];
  user: User;
  children: React.ReactNode;
};

export function WithSettingsPermissions({
  rbacPermissions,
  user,
  children
}: WithSettingsPermissionsProps): JSX.Element {
  if (!hasSettingsPermissions(user, rbacPermissions)) {
    return (
      <ErrorPage
        errorType="403"
        errorName={t('ErrorPage.Unauthorized')}
        errorMsg={t('ProjectSettings.PermissionWarning')}
      />
    );
  }

  return <>{children}</>;
}
