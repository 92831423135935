import { SvgFiletypeTxt2, SvgFolder } from '@itwin/itwinui-icons-color-react';
import type { SelectOption } from '@itwin/itwinui-react';
import { ecPluginFeatures } from '../../../hooks/useECPluginVersion';
import type { AttributePicklistValue } from '../../../hooks/useEnvironment';
import { t } from '../../../services/translation';
import { dataTypes } from '../search/query';

export function searchClassDropdownOptions(): SelectOption<string>[] {
  const options = [
    {
      value: 'Documents',
      label: t('Generic.Documents'),
      startIcon: SvgFiletypeTxt2({}) as JSX.Element
    }
  ];

  if (ecPluginFeatures.folderSearch()) {
    options.push({
      value: 'Folders',
      label: t('Generic.Folders'),
      startIcon: SvgFolder({}) as JSX.Element
    });
  }

  return options;
}

export function logicalDropdownOptions(): SelectOption<string>[] {
  return [
    { value: 'and', label: t('AdvancedSearch.And') },
    { value: 'or', label: t('AdvancedSearch.Or') }
  ];
}

export function defaultOperator(dataType: string): string {
  if (dataType == dataTypes.dateTime) {
    return t('AdvancedSearch.On');
  }

  return t('AdvancedSearch.Equals');
}

type GetOperatorsArgs = {
  dataType: string;
  picklistOptions?: AttributePicklistValue[];
};

export function operatorDropdownOptions({
  dataType,
  picklistOptions
}: GetOperatorsArgs): string[] {
  if (picklistOptions?.length) {
    return picklistDropdownOptions();
  }

  switch (dataType) {
    case dataTypes.dateTime: {
      return dateDropdownOptions();
    }

    case dataTypes.boolean: {
      return booleanDropdownOptions();
    }

    case dataTypes.string: {
      return stringDropdownOptions();
    }

    case dataTypes.double:
    case dataTypes.int:
    case dataTypes.long: {
      return numberDropdownOptions();
    }

    case dataTypes.id: {
      return idDropdownOptions();
    }

    default: {
      return stringDropdownOptions();
    }
  }
}

function picklistDropdownOptions(): string[] {
  return [t('AdvancedSearch.Equals'), t('AdvancedSearch.DoesNotEqual')];
}

function dateDropdownOptions(): string[] {
  return [
    t('AdvancedSearch.Before'),
    t('AdvancedSearch.OnOrBefore'),
    t('AdvancedSearch.On'),
    t('AdvancedSearch.OnOrAfter'),
    t('AdvancedSearch.After'),
    t('AdvancedSearch.NotOn')
  ];
}

function booleanDropdownOptions(): string[] {
  return [t('AdvancedSearch.Equals'), t('AdvancedSearch.DoesNotEqual')];
}

function stringDropdownOptions(): string[] {
  return [
    t('AdvancedSearch.Equals'),
    t('AdvancedSearch.DoesNotEqual'),
    t('AdvancedSearch.StartsWith'),
    t('AdvancedSearch.Contains'),
    t('AdvancedSearch.DoesNotContain')
  ];
}

function numberDropdownOptions(): string[] {
  return [
    t('AdvancedSearch.Equals'),
    t('AdvancedSearch.DoesNotEqual'),
    t('AdvancedSearch.IsLessThan'),
    t('AdvancedSearch.IsGreaterThan'),
    t('AdvancedSearch.IsLessThanOrEqualTo'),
    t('AdvancedSearch.IsGreaterThanOrEqualTo')
  ];
}

function idDropdownOptions(): string[] {
  return [t('AdvancedSearch.Equals')];
}

export function booleanValueDropdownOptions(): SelectOption<string>[] {
  return [
    { value: 'true', label: t('AdvancedSearch.True') },
    { value: 'false', label: t('AdvancedSearch.False') }
  ];
}
