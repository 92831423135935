import React, { useState } from 'react';
import type { PWProject } from '@bentley/pw-api';
import { ItemIcon } from '@bentley/pw-file-icons';
import { IconButton, MenuItem } from '@itwin/itwinui-react';
import { Chevron } from '../folderExplorer/chevron/chevron';
import { FolderSelectChildrenNode } from './folderSelectChildrenNode';

type FolderSelectTreeProps = {
  breadcrumbPath: string[];
  folder: PWProject;
  selectedFolder: PWProject;
  appendBreadcrumb: (path: string[]) => void;
  closeDropdown: () => void;
  onFolderSelect: (folder: PWProject) => void;
};

export function FolderSelectNode({
  breadcrumbPath,
  folder,
  selectedFolder,
  appendBreadcrumb,
  closeDropdown,
  onFolderSelect
}: FolderSelectTreeProps): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(
    breadcrumbPath.includes(folder.instanceId)
  );
  const [hasChildren, setHasChildren] = useState<boolean>(true);

  function toggleNodeExpansion(): void {
    setExpanded((cur) => !cur);
  }

  function selectFolder(): void {
    appendBreadcrumb([]);
    onFolderSelect(folder);
    closeDropdown();
  }

  function addBreadcrumb(path: string[]): void {
    appendBreadcrumb([folder.instanceId, ...path]);
  }

  return (
    <>
      <span className="fsd-node-row">
        <IconButton
          styleType="borderless"
          data-testid={`folder-select-chevron-${folder.Name}`}
          onClick={toggleNodeExpansion}
        >
          <Chevron hasChildren={hasChildren} isExpanded={expanded} />
        </IconButton>
        <MenuItem
          isSelected={folder.instanceId == selectedFolder.instanceId}
          className="tree-node"
          startIcon={<ItemIcon item={folder} size="xs" />}
          onClick={selectFolder}
          data-testid={`folder-select-${folder.Name}`}
        >
          {folder.Name}
        </MenuItem>
      </span>
      {expanded && (
        <FolderSelectChildrenNode
          appendBreadcrumb={addBreadcrumb}
          breadcrumbPath={breadcrumbPath}
          parent={folder}
          selectedFolder={selectedFolder}
          closeDropdown={closeDropdown}
          onFolderSelect={onFolderSelect}
          setHasChildren={setHasChildren}
        />
      )}
    </>
  );
}
