import { useEffect } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useAppViewContext, useNavigationContext } from '../../../context';
import { useEquivalentState } from '../../../hooks/useNonRenderingState';
import type { SelectedState } from '../../../hooks/useSelectedItems';

export function useActionableItems(selectedState: SelectedState): PWItem[] {
  const { navigationManager } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();

  const [actionableItems, setActionableItems] = useEquivalentState<PWItem[]>(
    []
  );

  useEffect(() => {
    if (
      currentAppView.type == 'FolderNavigation' &&
      !selectedState.selectedItems.length
    ) {
      setActionableItems([navigationManager.currentParent]);
      return;
    }

    setActionableItems(selectedState.selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedState.selectedItems,
    navigationManager.currentParent,
    currentAppView.type
  ]);

  return actionableItems;
}
