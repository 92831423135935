import './docCodePreview.css';

import React from 'react';
import { SvgInfoHollow } from '@itwin/itwinui-icons-color-react';
import { SvgNew } from '@itwin/itwinui-icons-react';
import {
  Button,
  ButtonGroup,
  Input,
  Label,
  Tooltip
} from '@itwin/itwinui-react';
import { t } from '../../services/translation';

type DocCodePreviewProps = {
  docCode: string | undefined;
  allowGenerateNext: boolean;
  onGenerate: () => Promise<void>;
  generateNextTitle: string;
};

export function DocCodePreview({
  docCode,
  allowGenerateNext,
  onGenerate,
  generateNextTitle
}: DocCodePreviewProps): JSX.Element | null {
  return (
    <div className="dcw-code-preview">
      <span className="doc-code-preview-span">
        <Label style={{ flexShrink: 0 }}>
          {t('DocumentCreation.CodePreview')}
        </Label>
        <ButtonGroup className="code-preview-input">
          <Input
            disabled={true}
            value={docCode ?? ''}
            data-testid="code-preview"
          />
          <Button
            disabled={!allowGenerateNext}
            onClick={() => void onGenerate()}
            startIcon={<SvgNew />}
            data-testid="generate-next"
          >
            {t('DocumentCreation.GenerateNext')}
          </Button>
        </ButtonGroup>
        <Tooltip content={generateNextTitle}>
          <span
            className="generate-next-info-icon"
            data-testid="generate-next-info-icon"
          >
            {<SvgInfoHollow />}
          </span>
        </Tooltip>
      </span>
    </div>
  );
}
