import React from 'react';
import type { PWProject } from '@bentley/pw-api';
import { SvgMoreVerticalSmall } from '@itwin/itwinui-icons-react';
import {
  useEnvironmentContext,
  useFolderExplorerContext
} from '../../../context';
import { ContextMenu } from '../../contextMenu';
import { useFolderNodeContextMenuItems } from './useFolderNodeContextMenuItems';

type FolderNodeContextMenuProps = {
  folder: PWProject;
};

export function FolderNodeContextMenu({
  folder
}: FolderNodeContextMenuProps): JSX.Element | null {
  const { selectedIds, setSelectedIds } = useFolderExplorerContext();
  const { environmentContextInitialized } = useEnvironmentContext();

  const menuOptions = useFolderNodeContextMenuItems(folder);

  const activeMenu = selectedIds.includes(folder.instanceId);

  function onContextMenuClose(): void {
    setSelectedIds([]);
  }

  if (!environmentContextInitialized) {
    return null;
  }

  return (
    <div
      className={
        activeMenu
          ? 'tree-context-menu-active tree-context-menu'
          : 'tree-context-menu'
      }
      data-testid={`tree-context-menu-${folder.instanceId}`}
    >
      <ContextMenu
        icon={<SvgMoreVerticalSmall />}
        itemId={folder.instanceId}
        menuOptions={menuOptions}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onClose={onContextMenuClose}
        buttonProps={{ size: 'small' }}
      />
    </div>
  );
}
