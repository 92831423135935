import type { User } from 'oidc-client';

const SECONDS_TO_MILLISECONDS = 1000;
const MINUTES_TO_MILLISECONDS = SECONDS_TO_MILLISECONDS * 60;

const minutesToRefresh = 30;
const maxRefreshInterval = minutesToRefresh * MINUTES_TO_MILLISECONDS;

export function refreshInterval(user: User): number {
  if (!user.expires_in) {
    return maxRefreshInterval;
  }

  const userExpireTime = user.expires_in * SECONDS_TO_MILLISECONDS;

  if (userExpireTime >= maxRefreshInterval) {
    return maxRefreshInterval;
  }

  return (user.expires_in - 60) * SECONDS_TO_MILLISECONDS;
}
