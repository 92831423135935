import { t } from '../../../services/translation';
import type { ColumnInfo } from '../defaultView';
import type { Column } from './view';

export function viewColumnToColumnInfo(
  viewColumn: Column
): ColumnInfo | undefined {
  if (viewColumn.Type == 'Basic') {
    const column = basicColumn(viewColumn);
    return validColumn(column);
  }

  const column = parseColumnInfo(viewColumn);
  return validColumn(column);
}

function basicColumn(viewColumn: Column): ColumnInfo | undefined {
  const column = {
    displayLabel: viewColumn.DisplayName,
    propertyType: 'General',
    dataType: viewColumn.DataType,
    envLabel: t('CustomColumns.GeneralProperties'),
    name: columnName(viewColumn),
    width: viewColumn.Width
  } as ColumnInfo;

  if (column.name == 'FileSize') {
    column.dataType = 'fileSize';
  }

  return column;
}

function parseColumnInfo(viewColumn: Column): ColumnInfo {
  const environment = columnEnvironment(viewColumn);
  const propertyType = environment == 'Project' ? 'Folder' : environment;
  const environmentLabel = environment.split(/Env_\d+_/).pop();
  const name = columnName(viewColumn);

  const columnInfo: ColumnInfo = {
    displayLabel: viewColumn.DisplayName,
    propertyType: propertyType,
    dataType: viewColumn.DataType,
    name: name,
    envLabel: environmentLabel,
    isProjectEnv: viewColumn.Type == 'ProjectType',
    width: viewColumn.Width
  };
  return columnInfo;
}

function columnName(viewColumn: Column): string {
  const binding = columnBinding(viewColumn);
  const name = binding.substr(binding.lastIndexOf('.') + 1);
  return name;
}

function columnEnvironment(viewColumn: Column): string {
  const binding = columnBinding(viewColumn);
  const sliceSchemaName = binding.substr(binding.indexOf(':') + 1);
  const environment = sliceSchemaName.substr(
    0,
    sliceSchemaName.lastIndexOf('.')
  );
  return environment;
}

function columnBinding(viewColumn: Column): string {
  const binding = viewColumn.Bindings[0];
  return binding;
}

function validColumn(column?: ColumnInfo): ColumnInfo | undefined {
  if (
    !column?.dataType ||
    !column?.displayLabel ||
    !column?.envLabel ||
    !column?.name ||
    !column?.propertyType
  ) {
    return undefined;
  }

  return column;
}
