import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import type {
  PWDocument,
  PWItem,
  PWProject,
  WSGChangedInstanceResponse,
  WSGInstance
} from '@bentley/pw-api';
import { filterDataItems, getDocument, getProjects } from '@bentley/pw-api';
import type { CustomFile, UploadNode } from '../../../actions/upload';
import {
  getProjectFromChangedInstance,
  newFileWithProperties,
  uploadNewFolderWorkflow
} from '../../../actions/upload';
import { usingDocCodeState } from '../docCode';
import { validateEnvironmentProperties } from '../properties/compare';
import {
  currentEnvironmentInstanceState,
  defaultEnvironmentInstanceStateAsync,
  getDCWHttpService,
  synchronousModeState
} from '../state';
import type { DCWDocProperties } from '../wizard';
import { useWizardFunctionsContext } from '../wizard';
import { useDocumentInstance } from './useDocumentInstance';
import { useMultiUploadProperties } from './useMultiUploadProperties';

export type UploadFunction = (
  uploadNode: UploadNode
) => Promise<Response | Response[]>;
export type RefreshAndSelect = (items: PWItem[]) => void;

type UploadFunctions = {
  uploadDocument: (
    docProperties: DCWDocProperties,
    environmentInstance: WSGInstance | null,
    projectId: string
  ) => Promise<PWDocument>;
  uploadDirectoriesAndFilesOnNode: (
    uploadNode: UploadNode,
    projectId: string,
    environmentInstance?: WSGInstance | null
  ) => Promise<PWItem[]>;
};

export function useUpload(): UploadFunctions {
  const currentEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    currentEnvironmentInstanceState
  );
  const originalEnvironmentInstance = useRecoilValue<WSGInstance | null>(
    defaultEnvironmentInstanceStateAsync
  );
  const usingDocCode = useRecoilValue(usingDocCodeState);
  const synchronousMode = useRecoilValue(synchronousModeState);

  const documentInstance = useDocumentInstance();
  const {
    getUploadEnvironmentInstance,
    getUploadDocCode,
    getUploadDocProperties
  } = useMultiUploadProperties();
  const { closeModal, refreshAndSelect, upload } = useWizardFunctionsContext();

  const uploadDocument = useCallback(
    async (
      docProperties: DCWDocProperties,
      environmentInstance: WSGInstance | null,
      projectId: string
    ): Promise<PWDocument> => {
      if (!upload) {
        throw new Error('Upload function is undefined');
      }
      const httpService = getDCWHttpService();
      const validatedProperties =
        environmentInstance?.properties &&
        originalEnvironmentInstance?.properties
          ? validateEnvironmentProperties(
              environmentInstance?.properties,
              originalEnvironmentInstance?.properties,
              usingDocCode
            )
          : environmentInstance?.properties;

      const instance = documentInstance(
        docProperties,
        {
          ...environmentInstance,
          properties: validatedProperties
        } as WSGInstance,
        projectId
      );
      const body = JSON.stringify({ instance });
      const response = await httpService.post('PW_WSG/Document', body);
      const document = await parseDocumentInstance(response);
      return document;
    },
    [
      documentInstance,
      originalEnvironmentInstance?.properties,
      upload,
      usingDocCode
    ]
  );

  const uploadDocuments = useCallback(
    async (
      uploadNode: UploadNode,
      projectId: string,
      containsInitialFile: boolean,
      environmentInstance?: WSGInstance | null
    ): Promise<PWDocument[]> => {
      if (!upload) {
        throw new Error('Upload function is undefined');
      }
      const documents = [] as PWDocument[];
      const siblingNames = [] as string[];
      const subUploadNode = { ...uploadNode, files: [], directories: [] };
      let uploadInstance: WSGInstance | null =
        environmentInstance ?? currentEnvironmentInstance;

      const files = [...uploadNode.files];
      for (const file of files) {
        const firstUpload = file == files[0] && containsInitialFile;
        if (!firstUpload) {
          uploadInstance = await getUploadEnvironmentInstance(uploadInstance);
        }
        const uploadDocCode = await getUploadDocCode(uploadInstance);
        const uploadProperties = getUploadDocProperties(
          file.name,
          uploadDocCode,
          siblingNames
        );

        siblingNames.push(uploadProperties.name, uploadProperties.fileName);

        const document = await uploadDocument(
          uploadProperties,
          uploadInstance,
          projectId
        );
        documents.push(document);

        appendFileToNode(
          subUploadNode,
          file,
          document.instanceId,
          uploadProperties
        );

        if (firstUpload && !synchronousMode) {
          closeModal?.();
        }
      }

      if (synchronousMode) {
        await upload(subUploadNode);
      } else {
        void upload(subUploadNode);
      }

      return documents;
    },
    [
      closeModal,
      currentEnvironmentInstance,
      getUploadDocCode,
      getUploadDocProperties,
      getUploadEnvironmentInstance,
      synchronousMode,
      upload,
      uploadDocument
    ]
  );

  const uploadDirectory = useCallback(
    async (directory: UploadNode, projectId: string): Promise<PWProject> => {
      const name =
        directory.current?.name ?? `upload-${new Date().toISOString()}`;
      const description = '';
      const httpService = getDCWHttpService();
      const response = await uploadNewFolderWorkflow(
        name,
        description,
        projectId,
        httpService
      );
      const uploadedProject = await parseProjectResponse(
        response,
        projectId,
        name
      );
      return uploadedProject;
    },
    []
  );

  const uploadDirectoriesAndFilesOnNode = useCallback(
    async (
      uploadNode: UploadNode,
      projectId: string,
      environmentInstance?: WSGInstance | null,
      containsInitialFile = true
    ): Promise<PWItem[]> => {
      const createdItems = [] as PWItem[];
      const subNodes = [] as UploadNode[];

      for (const directory of uploadNode.directories) {
        const uploadedProject = await uploadDirectory(directory, projectId);
        createdItems.push(uploadedProject);
        subNodes.push({ ...directory, parent: uploadedProject });
      }

      if (uploadNode.directories.length && !uploadNode.files.length) {
        refreshAndSelect?.(createdItems);
      }

      const createdDocuments = await uploadDocuments(
        uploadNode,
        projectId,
        containsInitialFile as boolean,
        environmentInstance
      );
      createdItems.push(...createdDocuments);

      for (const subNode of subNodes) {
        containsInitialFile =
          (containsInitialFile as boolean) &&
          filterDataItems(createdItems).length == 0;
        const subItems = await uploadDirectoriesAndFilesOnNode(
          subNode,
          subNode.parent?.instanceId ?? '',
          environmentInstance,
          containsInitialFile
        );
        createdItems.push(...subItems);
      }

      return createdItems;
    },
    [refreshAndSelect, uploadDirectory, uploadDocuments]
  );

  return { uploadDocument, uploadDirectoriesAndFilesOnNode };
}

async function parseDocumentInstance(response: Response): Promise<PWDocument> {
  if (!response.ok || response.status != 201) {
    throw response;
  }
  const data = (await response.json()) as WSGChangedInstanceResponse;
  const documentInstance = data.changedInstance.instanceAfterChange;
  const httpService = getDCWHttpService();
  const createdDocument = await getDocument({
    instanceId: documentInstance.instanceId,
    httpService
  });
  return createdDocument;
}

async function parseProjectResponse(
  response: Response,
  projectId: string,
  name: string
): Promise<PWProject> {
  if (response.status == 409) {
    return getCreatedProject(response, projectId, name);
  }
  if (!response.ok || response.status != 201) {
    throw response;
  }

  const uploadedProject = await getProjectFromChangedInstance(response);
  return uploadedProject;
}

async function getCreatedProject(
  response: Response,
  projectId: string,
  name: string
): Promise<PWProject> {
  const httpService = getDCWHttpService();
  const parent = { instanceId: projectId } as PWItem;
  const siblingProjects = await getProjects({
    parentId: parent.instanceId,
    httpService
  });
  const createdProject = siblingProjects.find(
    (project) => project.Name == name
  );
  if (!createdProject) {
    throw response;
  }
  return createdProject;
}

function appendFileToNode(
  currentNode: UploadNode,
  file: CustomFile,
  customFileId: string,
  properties: DCWDocProperties
): void {
  const customFile = newFileWithProperties(file, {
    id: customFileId,
    customName: properties.fileName,
    documentName: properties.name,
    documentDescription: properties.description
  });

  currentNode.files.push(customFile);
}
