import type {
  HttpService,
  PWDataItem,
  RequestOptions,
  WSGInstance,
  WSGInstancesResponse
} from '@bentley/pw-api';
import { parseInstanceRelationshipInstances } from '@bentley/pw-api';
import { batchedFetch } from '../../services/batchedFetch';
import { getItemCommands } from './itemCommands';

export const newVersionWRECommand =
  'urn~3Abentley~3Adocument~3Acommand~3ANewVersion';

export type Command = {
  schemaName: 'PW_WSG';
  className: 'Command';
  CommandParameters?: { Name: string; Label: string; IsRequired: boolean }[];
  CommentDefault: string;
  CommentLabel: string;
  CommentRequested: boolean;
  Description: string;
  DisplayOrder: string;
  IsEnabled: boolean;
  Label: string;
  Prompt: string;
} & WSGInstance;

/**
 * Retrieves WRE commands for an item
 * @param {PWDataItem} item Item to retrieve commands on
 * @param {HttpService} httpService Configured http service
 * @param {RequestOptions} [httpOptions] Configured http options
 */
export async function getItemCommandsBatched(
  items: PWDataItem[],
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<{ itemId: string; commands: Command[] }[]> {
  const itemCommands = await batchedFetch(
    async (items: PWDataItem[]) => {
      const ids = `'${items.map((item) => item.instanceId).join("','")}'`;
      const classQuery = `PW_WSG/Document!poly/?`;
      const filter = `$filter=$id+in+[${ids}]&`;
      const select = `$select=PW_WSG.DocumentCommand!poly-forward-PW_WSG.Command!poly.*`;

      const response = await httpService.get(
        `${classQuery}${filter}${select}`,
        httpOptions
      );

      if (!response.ok) {
        return [];
      }

      const data = (await response.json()) as WSGInstancesResponse;

      const commands = data.instances.map((instance) => ({
        itemId: instance.instanceId,
        commands: parseInstanceRelationshipInstances<Command>(instance)
      }));

      return commands;
    },
    items,
    20,
    'consecutive'
  );

  return itemCommands;
}

export function getCommonCommands(checkedRows: PWDataItem[]): Command[] {
  if (!checkedRows.length) {
    return [];
  }

  if (checkedRows.length == 1) {
    return getItemCommands(checkedRows[0]) ?? [];
  }

  const checkedRowsCommands = checkedRows.flatMap(
    (item) => getItemCommands(item) ?? []
  );

  const commandIds = checkedRowsCommands.map((command) => command.instanceId);
  const uniqueCommandIds = Array.from(new Set(commandIds));

  const sharedCommandIds = uniqueCommandIds.filter((commandId) =>
    checkedRows.every((row) =>
      getItemCommands(row)?.find(
        (command) => command.instanceId == commandId && command.IsEnabled
      )
    )
  );

  return (
    getItemCommands(checkedRows[0])?.filter((command) =>
      sharedCommandIds.includes(command.instanceId)
    ) ?? []
  );
}

export function nonDocumentCommands(commands: Command[]): Command[] {
  const commandsToHide = [
    'urn~3Abentley~3Adocument~3Acommand~3ASyncAttributesFromDocument',
    'urn~3Abentley~3Adocument~3Acommand~3ASyncAttributesToDocument',
    newVersionWRECommand
  ];

  return commands.filter(
    (command) => !commandsToHide.includes(command.instanceId)
  );
}
