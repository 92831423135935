import React from 'react';
import {
  AppProviders,
  AuthorizedProviders,
  useConnectionContext
} from '../../context';
import { ConnectionPanelContainer } from '../connectionPanel';
import { FolderExplorerPortal } from '../folderExplorer';
import {
  GatewayLoader,
  WithAuthentication,
  WithConnectionError
} from '../guards';
import { ProjectWiseApp } from './projectWiseApp';
import type { ProjectWiseConnectionProps } from './projectWiseConnection';

export function ProjectWiseConfiguredConnection(
  props: ProjectWiseConnectionProps
): JSX.Element {
  const { connection } = useConnectionContext();

  return (
    <ConnectionPanelContainer>
      <AppProviders value={props}>
        <WithConnectionError>
          <WithAuthentication>
            <AuthorizedProviders value={undefined as never}>
              <GatewayLoader>
                <FolderExplorerPortal key={connection.Id} />
                <ProjectWiseApp />
              </GatewayLoader>
            </AuthorizedProviders>
          </WithAuthentication>
        </WithConnectionError>
      </AppProviders>
    </ConnectionPanelContainer>
  );
}
