import type { DataError } from '../../../actions/formRendering';
import { getErrorMessage } from '../../../actions/formRendering';
import { openToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';

export async function handleDocCodeFormError(
  error: Response | Error
): Promise<string> {
  const content = await getErrorMessage(error as DataError);
  openToast({ content, category: 'negative' });
  return content;
}

export function handleDocCodeFormSuccess(): void {
  const content = t('DocCodeForm.DocCodeSaved');
  openToast({ content, category: 'positive' });
}
