import type { PWItem } from '@bentley/pw-api';

export type MoveProgressTracker = {
  successCount: number;
  failureCount: number;
  total: number;
};

export function newProgressTracker(items: PWItem[]): MoveProgressTracker {
  return {
    successCount: 0,
    failureCount: 0,
    total: items.length
  } as MoveProgressTracker;
}

export function updateProgressTracker(
  progressTracker: MoveProgressTracker,
  response?: Response
): void {
  if (response?.ok && [200, 201].includes(response.status)) {
    progressTracker.successCount += 1;
  } else {
    progressTracker.failureCount += 1;
  }
}
