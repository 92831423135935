import { useRecoilState, useRecoilValue } from 'recoil';
import type { StepProperties } from '@itwin/itwinui-react';
import { t } from '../../../services/translation';
import { AttributesStep } from './attributesStep';
import { DocCodeStep } from './docCodeStep';
import { PropertiesStep } from './propertiesStep';
import {
  currentStepIndexState,
  isFlatSetCreationModeState,
  showAttributeStepState,
  showDocCodeStepState
} from './state';

type WizardSteps = {
  currentStep: JSX.Element;
  currentStepIndex: number;
  steps: StepProperties[];
  setCurrentStepIndex: (index: number) => void;
};

export function useWizardSteps(): WizardSteps {
  const showDocCodeStep = useRecoilValue(showDocCodeStepState);
  const showAttributeStep = useRecoilValue(showAttributeStepState);
  const isFlatSetCreationMode = useRecoilValue(isFlatSetCreationModeState);

  const [currentStepIndex, setCurrentStepIndex] = useRecoilState(
    currentStepIndexState
  );

  const docCodeStep = {
    stepProperties: {
      name: t('DocCode.DocCode'),
      description: t('DocumentCreation.DefineDocumentCode')
    },
    stepElement: DocCodeStep()
  };

  const propertiesStep = {
    stepProperties: {
      name: isFlatSetCreationMode
        ? t('DocumentCreation.DocumentSetName')
        : t('DocumentCreation.DocumentName'),
      description: t('DocumentCreation.SetDocumentProperties')
    },
    stepElement: PropertiesStep()
  };

  const attributesStep = {
    stepProperties: {
      name: t('InfoPanel.Attributes'),
      description: t('DocumentCreation.SetDocumentAttributes')
    },
    stepElement: AttributesStep()
  };

  const steps = [];

  if (showDocCodeStep) {
    steps.push(docCodeStep);
  }
  steps.push(propertiesStep);
  if (showAttributeStep) {
    steps.push(attributesStep);
  }

  return {
    currentStepIndex,
    setCurrentStepIndex,
    steps: steps.map((step) => step.stepProperties),
    currentStep: steps[currentStepIndex].stepElement
  };
}
