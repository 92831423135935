import { useCallback, useMemo, useState } from 'react';
import type { PWParentType } from '@bentley/pw-api';
import { useConnectionAuth, usePluginContext } from '../../context';
import { connectionToBreadcrumb } from '../useBreadcrumb';

type CachedParentManager = [PWParentType, (parent: PWParentType) => void];

export function useCachedParent(): CachedParentManager {
  const { userName } = useConnectionAuth();
  const { connection, consumerApp } = usePluginContext();

  const [refresh, setRefresh] = useState<boolean>(false);

  const storage = useMemo((): Storage => {
    if (consumerApp == 'Teams' || consumerApp == 'ProjectWise 365 in Teams') {
      return localStorage;
    }
    return sessionStorage;
  }, [consumerApp]);

  const cachedParentKey = useMemo(
    (): string => `currentParent:${userName}:${connection.Id}`,
    [connection.Id, userName]
  );

  const cachedParent = useMemo((): PWParentType => {
    const JsonParent = storage.getItem(cachedParentKey);

    if (!JsonParent) {
      return connectionToBreadcrumb(connection);
    }

    const parent = JSON.parse(JsonParent) as PWParentType;

    if (
      parent.instanceId == connection.ProjectId &&
      parent.Name != connection.Name
    ) {
      const parent = connectionToBreadcrumb(connection);
      storage.setItem(cachedParentKey, JSON.stringify(parent));
      return parent;
    }

    return parent;
  }, [connection, cachedParentKey, connection, refresh, storage]);

  const setCachedParent = useCallback(
    (parent: PWParentType): void => {
      storage.setItem(cachedParentKey, JSON.stringify(parent));
      setRefresh((cur) => !cur);
    },
    [cachedParentKey, storage]
  );

  const cachedParentManager = useMemo(
    (): CachedParentManager => [cachedParent, setCachedParent],
    [cachedParent, setCachedParent]
  );

  return cachedParentManager;
}
