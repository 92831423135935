import { useCallback } from 'react';
import type { PWItem, RequestOptions } from '@bentley/pw-api';
import {
  byDefaultOrder,
  getFlatSetChildren,
  itemIsFlatSet
} from '@bentley/pw-api';
import {
  useEnvironmentContext,
  useHttpService,
  useNavigationContext
} from '../../../context';
import { filterSetItems } from './filterSetItems';

type FlatSetSearchFunction = (
  httpOptions?: RequestOptions
) => Promise<PWItem[]>;

export function useFlatSetSearch(): FlatSetSearchFunction {
  const httpService = useHttpService();
  const {
    ecPluginFeatures: { environmentQueryPerformance }
  } = useEnvironmentContext();
  const {
    navigationManager: { currentParent },
    searchState: { basicQuery }
  } = useNavigationContext();

  const flatSetSearch = useCallback(
    async (requestOptions?: RequestOptions): Promise<PWItem[]> => {
      if (!itemIsFlatSet(currentParent)) {
        throw new Error('Unable to search in flat set from outside flat set');
      }

      const items = await getFlatSetChildren({
        flatSetId: currentParent.instanceId,
        selectRelationships: {
          parent: true,
          environment: environmentQueryPerformance(),
          setRelationshipProperties: true
        },
        httpService,
        requestOptions
      });

      const matchingItems = items.filter((item) =>
        filterSetItems(item, basicQuery)
      );
      matchingItems.sort(byDefaultOrder);
      return matchingItems;
    },
    [basicQuery, currentParent, environmentQueryPerformance, httpService]
  );

  return flatSetSearch;
}
