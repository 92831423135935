import React, { useMemo } from 'react';
import { itemIsProject } from '@bentley/pw-api';
import {
  SvgDeveloper,
  SvgHistory,
  SvgList,
  SvgReports
} from '@itwin/itwinui-icons-react';
import {
  useFeatureTracking,
  useNavigationContext,
  useTableItemContext
} from '../../../context';
import { t } from '../../../services/translation';
import { useAllowEditDocCode } from '../../docCodeForm/hooks';
import { PWInfoPanelAttributes } from '../attributes/pwInfoPanelAttributes';
import { PWInfoPanelAuditTrail } from '../auditTrail/pwInfoPanelAuditTrail';
import { PWInfoPanelDetails } from '../details/pwInfoPanelDetails';
import { PWInfoPanelDocCode } from '../docCode/pwInfoPanelDocCode';
import type { PWInfoPanelTab } from './buildPWInfoPanelTab';
import { buildPWInfoPanelTab } from './buildPWInfoPanelTab';

type PWInfoPanelTabs = {
  details: PWInfoPanelTab;
  auditTrail: PWInfoPanelTab;
  attributes: PWInfoPanelTab;
  docCode: PWInfoPanelTab;
};

export function usePWInfoPanelTabs(): PWInfoPanelTabs {
  const { launchDarklyFeatures } = useFeatureTracking();

  const { infoPanelDisplayed } = useNavigationContext();
  const { actionableItems } = useTableItemContext();

  const allowEditDocCode = useAllowEditDocCode();

  const auditTrailTabEnabled = useMemo((): boolean => {
    if (actionableItems.length != 1) {
      return false;
    }

    if (!actionableItems[0].instanceId) {
      return false;
    }

    return true;
  }, [actionableItems]);

  const attributesTabEnabled = useMemo((): boolean => {
    if (actionableItems.some(itemIsProject)) {
      return false;
    }

    if (actionableItems.some((item) => !item.EnvironmentId)) {
      return false;
    }

    const allSameEnvironment = actionableItems.every(
      ({ EnvironmentId }) => EnvironmentId == actionableItems[0]?.EnvironmentId
    );

    if (!allSameEnvironment) {
      return false;
    }

    return true;
  }, [actionableItems]);

  const attributesTabVisible = useMemo((): boolean => {
    if (actionableItems.every(itemIsProject)) {
      return false;
    }

    if (actionableItems.every((item) => !item.EnvironmentId)) {
      return false;
    }

    if (!infoPanelDisplayed) {
      return false;
    }

    return true;
  }, [actionableItems, infoPanelDisplayed]);

  const docCodeTabEnabled = useMemo((): boolean => {
    if (!attributesTabEnabled) {
      return false;
    }

    if (!allowEditDocCode) {
      return false;
    }

    return true;
  }, [allowEditDocCode, attributesTabEnabled]);

  const docCodeTabVisible = useMemo((): boolean => {
    if (!launchDarklyFeatures.showDocCodeEditing) {
      return false;
    }

    if (!attributesTabVisible) {
      return false;
    }

    return true;
  }, [attributesTabVisible, launchDarklyFeatures.showDocCodeEditing]);

  const pwInfoPanelTabs = useMemo(() => {
    return {
      details: buildPWInfoPanelTab({
        label: t('InfoPanel.Details'),
        icon: <SvgList />,
        content: <PWInfoPanelDetails />,
        enabled: true,
        visible: true
      }),
      auditTrail: buildPWInfoPanelTab({
        label: t('InfoPanel.AuditTrail'),
        icon: <SvgHistory />,
        content: <PWInfoPanelAuditTrail />,
        enabled: auditTrailTabEnabled,
        visible: infoPanelDisplayed
      }),
      attributes: buildPWInfoPanelTab({
        label: t('InfoPanel.Attributes'),
        icon: <SvgReports />,
        content: <PWInfoPanelAttributes />,
        enabled: attributesTabEnabled,
        visible: attributesTabVisible
      }),
      docCode: buildPWInfoPanelTab({
        label: t('DocCode.DocCode'),
        icon: <SvgDeveloper />,
        content: <PWInfoPanelDocCode />,
        enabled: docCodeTabEnabled,
        visible: docCodeTabVisible
      })
    };
  }, [
    attributesTabEnabled,
    attributesTabVisible,
    auditTrailTabEnabled,
    docCodeTabEnabled,
    docCodeTabVisible,
    infoPanelDisplayed
  ]);

  return pwInfoPanelTabs;
}
