import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { useItemActionContext, useTableItemContext } from '../../../../context';
import { ContextMenu } from '../../../contextMenu';

type TableContextMenuProps = {
  item: PWItem;
};

export function TableContextMenu({
  item
}: TableContextMenuProps): JSX.Element | null {
  const {
    selectedState: { selectedIds, setSelectedIds }
  } = useTableItemContext();

  const { itemActions } = useItemActionContext();

  const menuOptions = itemActions('contextMenu');

  if (!menuOptions.length) {
    return null;
  }

  return (
    <ContextMenu
      menuOptions={menuOptions}
      itemId={item.instanceId}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      allowCtrlClick={true}
      buttonProps={{
        'data-btn-id': item.instanceId,
        'data-col': 'contextMenu'
      }}
      dropdownMenuProps={{
        portal: {
          to: () =>
            document.querySelector<HTMLElement>('.projectWiseAppContainer')
        }
      }}
    />
  );
}
