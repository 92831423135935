import type { PWDataItem, PWProject } from '@bentley/pw-api';
import { pwConstants } from '@bentley/pw-api';

export interface CustomFile extends File {
  customName?: string;
  id?: string;
  documentName?: string;
  documentDescription?: string;
}

export type UploadNode = {
  current?: FileSystemDirectoryEntry;
  directories: UploadNode[];
  files: CustomFile[];
  parent?: PWProject;
  itemsToCopy?: PWDataItem[];
};

/**
 * Returns the total count of files to be uploaded
 * @param {UploadNode} uploadNode Recursive structure of folders and files to upload
 * @returns {number} Count of files to upload
 */
export function getTotalFileCount(uploadNode: UploadNode): number {
  const fileCount = uploadNode.files.length;
  const subfileCount = uploadNode.directories
    .map(getTotalFileCount)
    .reduce((acc, cur) => acc + cur, 0);

  return fileCount + subfileCount;
}

export function getTotalFiles(uploadNode: UploadNode): CustomFile[] {
  const files = uploadNode.files;
  const subfiles = uploadNode.directories
    .map(getTotalFiles)
    .reduce((acc, cur) => [...acc, ...cur], []);

  return [...files, ...subfiles];
}

/**
 * Checks length of file names and returns true if any are over max length
 * @param {UploadNode} uploadNode Recursive structure of folders and files to upload
 * @returns {boolean} True if any file names are over max length
 */
export function anyFileNamesTooLong(uploadNode: UploadNode): boolean {
  const overLength = uploadNode.files.some(
    (file) => file.name.length > pwConstants.maxFileNameLength
  );
  return (
    overLength ||
    uploadNode.directories
      .map(anyFileNamesTooLong)
      .some((overlength) => overlength == true)
  );
}

/**
 * Checks length of folder names and returns true if any are over max length
 * @param {UploadNode} uploadNode Recursive structure of folders and files to upload
 * @returns {boolean} True if any file names are over max length
 */
export function anyFolderNamesTooLong(uploadNode: UploadNode): boolean {
  const overLength =
    uploadNode.current &&
    uploadNode.current.name.length > pwConstants.maxFolderNameLength;
  return (
    overLength ||
    uploadNode.directories
      .map(anyFolderNamesTooLong)
      .some((overlength) => overlength == true)
  );
}

/**
 * Finds files being uploaded with names over max length
 * @param {UploadNode} uploadNode Recursive structure of folders and files to upload
 * @returns {boolean} Flat list of files with names over max length
 */
export function overLengthFileNames(uploadNode: UploadNode): File[] {
  const files = uploadNode.files.filter(
    (file) => file.name.length > pwConstants.maxFileNameLength
  );
  const subfiles = uploadNode.directories
    .map(overLengthFileNames)
    .reduce((acc, cur) => [...acc, ...cur], []);

  return [...files, ...subfiles];
}

/**
 * Finds folders being uploaded with names over max length
 * @param {UploadNode} uploadNode Recursive structure of folders and files to upload
 * @returns {boolean} Flat list of folders with names over max length
 */
export function overLengthFolderNames(
  uploadNode: UploadNode
): FileSystemDirectoryEntry[] {
  const subfolders = uploadNode.directories
    .map(overLengthFolderNames)
    .reduce((acc, cur) => [...acc, ...cur], []);

  if (
    uploadNode.current &&
    uploadNode.current.name.length > pwConstants.maxFolderNameLength
  ) {
    return [uploadNode.current, ...subfolders];
  }

  return subfolders;
}
