import './getShareableLinkUI.css';

import React from 'react';
import type { PWItem } from '@bentley/pw-api';
import { ItemSummary } from '@bentley/pw-file-icons';
import { Dialog, Flex, LabeledTextarea } from '@itwin/itwinui-react';
import type { ConsumerApp, DataAccessLevel } from '../../context';
import type { ModalElement } from '../../hooks/useModal';
import type { SavedSearchDefinition } from '../../services/search';
import { t } from '../../services/translation';
import { AdvancedOptions } from './advancedOptions';
import { CopyButton } from './copyButton';
import type { Application } from './getShareableLink';
import { asItem } from './savedSearchConverter';
import { useSelectedApplication } from './useSelectedApplication';

type GetShareableLinkModalProps = {
  CloseButton?: JSX.Element;
  consumerApp?: ConsumerApp;
  dataAccessLevel: DataAccessLevel;
  disabledApplications?: Application[];
  hiddenApplications?: Application[];
  items: (PWItem | SavedSearchDefinition)[];
  useFileName?: boolean;
  getLink: (
    item: PWItem | SavedSearchDefinition,
    application: Application
  ) => string;
};

export function GetShareableLinkUI({
  CloseButton,
  consumerApp,
  dataAccessLevel,
  disabledApplications,
  hiddenApplications,
  items,
  useFileName = false,
  getLink
}: GetShareableLinkModalProps): ModalElement {
  const [selectedApp, setSelectedApp] = useSelectedApplication({
    consumerApp,
    dataAccessLevel,
    disabledApplications,
    hiddenApplications
  });

  function showAdvancedOptions(): boolean {
    if (dataAccessLevel == 'Datasource') {
      return false;
    }

    return true;
  }

  function showDocumentsButton(): boolean {
    return (
      consumerApp == 'ProjectWise 365' ||
      consumerApp == 'ProjectWise 365 in Teams' ||
      consumerApp == 'Synchro'
    );
  }

  return (
    <>
      <Dialog.Content className="share-link-ui">
        <Flex flexDirection="column" alignItems="stretch">
          <ItemSummary item={items.map(asItem)} useFileName={useFileName} />
          <LabeledTextarea
            label={
              items.length == 1
                ? t('SharedLink.CopyLink')
                : t('SharedLink.CopyLinks')
            }
            readOnly={true}
            value={items.map((item) => getLink(item, selectedApp)).join('\r\n')}
            rows={Math.min(items.length, 3)}
            wrap="off"
            data-testid="shareableLink"
            wrapperProps={{ className: 's-l-input-link' }}
          />
          {showAdvancedOptions() && (
            <AdvancedOptions
              disabledApplications={disabledApplications}
              hiddenApplications={hiddenApplications}
              items={items}
              selectedApp={selectedApp}
              showDocumentsButton={showDocumentsButton()}
              setSelectedApp={setSelectedApp}
            />
          )}
        </Flex>
      </Dialog.Content>
      <Dialog.ButtonBar>
        <CopyButton items={items} selectedApp={selectedApp} getLink={getLink} />
        {CloseButton}
      </Dialog.ButtonBar>
    </>
  );
}
