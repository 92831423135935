import { useCallback } from 'react';
import type { PWItem } from '@bentley/pw-api';
import type {
  CellProps,
  CellValue,
  Renderer
} from '@itwin/itwinui-react/react-table';
import { useTableItemContext } from '../../../context';
import type { ColumnDisplayOptions } from '../../../hooks/useViews';
import {
  formatBoolean,
  formatDateTime,
  formatFileSize,
  formatNumber,
  formatString
} from '../../../services/stringFormatter';
import type { ColumnDataType } from '../column/typedColumn';
import { TableCell } from './tableCell';

type FormattedCell = (
  dataType: ColumnDataType,
  displayOptions?: ColumnDisplayOptions | undefined
) => Renderer<CellProps<PWItem>>;

export function useFormattedCell(): FormattedCell {
  const {
    tableItemAction: { itemHasClickAction, onItemClick },
    getItemStatusBadge
  } = useTableItemContext();

  const formattedCell = useCallback(
    (
      dataType: ColumnDataType,
      displayOptions?: ColumnDisplayOptions
    ): Renderer<CellProps<PWItem>> => {
      function cellRenderer({
        column,
        row,
        value
      }: CellProps<PWItem>): JSX.Element {
        const displayContent = formattedValue(dataType, value);

        return TableCell({
          displayContent: displayContent,
          propertyName: column.id,
          item: row.original,
          displayOptions: displayOptions,
          itemHasClickAction: itemHasClickAction(row.original),
          getItemStatusBadge: getItemStatusBadge,
          onItemClick: () => onItemClick(row.original)
        });
      }

      return cellRenderer;
    },
    [getItemStatusBadge, itemHasClickAction, onItemClick]
  );

  return formattedCell;
}

function formattedValue(dataType: ColumnDataType, value: CellValue): string {
  if (value === undefined || value === null || value === '') {
    return '';
  }

  const formatFunction = formatByType(dataType);
  const formattedValue = formatFunction(value);

  return formattedValue;
}

function formatByType(
  dataType: ColumnDataType
): (propertyValue: CellValue) => string {
  if (dataType == 'boolean') {
    return formatBoolean;
  }

  if (dataType == 'dateTime') {
    return formatDateTime;
  }

  if (dataType == 'fileSize') {
    return formatFileSize;
  }

  if (dataType == 'number') {
    return formatNumber;
  }

  return formatString;
}
