import type {
  HttpService,
  PWFileType,
  PWItem,
  PWLogicalSet,
  PWParentType
} from '@bentley/pw-api';
import {
  filterDocuments,
  filterFlatSets,
  filterLogicalSets,
  filterProjects,
  getDataItems,
  getFlatSetChildren,
  getLogicalSetChildren,
  getProjects,
  itemIsFlatSet,
  itemIsLogicalSet,
  itemIsProject,
  itemIsSet
} from '@bentley/pw-api';
import { usingConcurrencyLimiter } from '../../services/concurrencyLimiter';
import { allowDownload } from './requirements';

export type DownloadNode = {
  folders: DownloadNode[];
  current?: PWParentType | PWLogicalSet;
  files: PWFileType[];
};

export async function buildDownloadTree(
  items: PWItem[],
  httpService: HttpService,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean
): Promise<DownloadNode> {
  const tree = {} as DownloadNode;
  addItemsToNode(tree, items, tree, downloadReferencedFiles, true);

  await buildDownloadTreeRecursive(
    httpService,
    downloadReferencedFiles,
    downloadRecursiveReferences,
    tree,
    tree
  );

  return tree;
}

async function buildDownloadTreeRecursive(
  httpService: HttpService,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean,
  tree: DownloadNode,
  node: DownloadNode
): Promise<DownloadNode> {
  const folderContents = node.folders.map(async (folder) => {
    if (folder.current && itemIsProject(folder.current)) {
      await buildFolderContents(
        tree,
        folder,
        httpService,
        downloadReferencedFiles,
        downloadRecursiveReferences
      );
      if (numFilesInNode(folder) == 0) {
        delete folder.current;
      }
    } else if (
      folder.current &&
      (itemIsFlatSet(folder.current) ||
        (itemIsLogicalSet(folder.current) && downloadReferencedFiles))
    ) {
      await buildSetContents(
        tree,
        folder,
        httpService,
        downloadReferencedFiles,
        downloadRecursiveReferences
      );
    }
  });
  await Promise.all(folderContents);
  return node;
}

async function buildFolderContents(
  tree: DownloadNode,
  folder: DownloadNode,
  httpService: HttpService,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean
): Promise<void> {
  const project = folder.current;
  if (project) {
    const { subProjects, documents } = await usingConcurrencyLimiter(
      async () => {
        const subProjects = await getProjects({
          parentId: project.instanceId,
          httpService
        });
        const documents = await getDataItems({
          parentId: project.instanceId,
          httpService
        });
        return { subProjects, documents };
      }
    );
    addItemsToNode(
      tree,
      [...subProjects, ...documents],
      folder,
      downloadReferencedFiles,
      true
    );

    await buildDownloadTreeRecursive(
      httpService,
      downloadReferencedFiles,
      downloadRecursiveReferences,
      tree,
      folder
    );
  }
}

async function buildSetContents(
  tree: DownloadNode,
  folder: DownloadNode,
  httpService: HttpService,
  downloadReferencedFiles: boolean,
  downloadRecursiveReferences: boolean
): Promise<void> {
  const set = folder.current;
  if (set && itemIsSet(set)) {
    const files = await usingConcurrencyLimiter(async () => {
      if (itemIsFlatSet(set)) {
        return await getFlatSetChildren({
          flatSetId: set.instanceId,
          httpService,
          requestOptions: { uncached: true }
        });
      } else {
        return await getLogicalSetChildren({
          logicalSetId: set.instanceId,
          httpService,
          requestOptions: { uncached: true }
        });
      }
    });
    addItemsToNode(
      tree,
      files,
      folder,
      downloadReferencedFiles,
      downloadRecursiveReferences
    );

    if (downloadReferencedFiles && downloadRecursiveReferences) {
      await buildDownloadTreeRecursive(
        httpService,
        downloadReferencedFiles,
        downloadRecursiveReferences,
        tree,
        folder
      );
    }
  }
}

function addItemsToNode(
  tree: DownloadNode,
  items: PWItem[],
  node: DownloadNode,
  downloadReferencedFiles: boolean,
  createFoldersForMasterReference: boolean
): void {
  const downloadableItems = items.filter(allowDownload);

  const documents = filterDocuments(downloadableItems);
  const projects = filterProjects(downloadableItems);
  const flatSets = filterFlatSets(downloadableItems);
  const logicalSets = filterLogicalSets(downloadableItems);

  // Prevent inifinte circular references
  const masterReferences = createFoldersForMasterReference
    ? logicalSets.filter(
        (ls) => downloadReferencedFiles && itemCount(tree, ls) <= 1
      )
    : [];

  const folders = [...projects, ...flatSets, ...masterReferences];
  const files = [...documents, ...logicalSets];

  node.folders = folders.map((folder) => ({ current: folder } as DownloadNode));
  if (node.current && itemIsLogicalSet(node.current)) {
    tree.files = [...(tree.files ?? []), ...files];
    node.files = [];
    tree.folders = tree.folders.filter(
      (folder) => folder.current?.instanceId != node.current?.instanceId
    );
  } else {
    node.files = [...(node.files ?? []), ...files];
  }
}

function numFilesInNode(node: DownloadNode): number {
  const numfiles = node.files.length;
  const numInSubfolders = node.folders
    .map((folder) => numFilesInNode(folder))
    .reduce((acc, cur) => acc + cur, 0);
  return numfiles + numInSubfolders;
}

export function calculateDownloadSize(downloadTree: DownloadNode): number {
  const combinedFileSizes = (downloadTree.files ?? []).reduce(
    (acc, cur) => acc + +cur.FileSize,
    0
  );
  const combinedFolderSizes = (downloadTree.folders ?? []).reduce(
    (acc, cur) => acc + calculateDownloadSize(cur),
    0
  );

  return combinedFileSizes + combinedFolderSizes;
}

export function calculateDownloadFileCount(downloadTree: DownloadNode): number {
  const fileCount = (downloadTree.files ?? []).length;
  const folderSubFileCount = (downloadTree.folders ?? []).reduce(
    (acc, cur) => acc + calculateDownloadFileCount(cur),
    0
  );

  return fileCount + folderSubFileCount;
}

function itemCount(node: DownloadNode, item: PWItem): number {
  const rootCount =
    node.current && node.current.instanceId == item.instanceId ? 1 : 0;
  const fileCount = (node.files ?? []).filter(
    (i) => i.instanceId == item.instanceId
  ).length;
  const folderCount = (node.folders ?? []).reduce(
    (acc, cur) => acc + itemCount(cur, item),
    0
  );

  return rootCount + fileCount + folderCount;
}
