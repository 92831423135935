import React from 'react';
import { AttributesFormContainer } from './attributesFormContainer';
import { EnvironmentInterfaceSelect } from './environmentInterfaceSelect';

export function AttributesSection(): JSX.Element {
  return (
    <>
      <EnvironmentInterfaceSelect />
      <AttributesFormContainer />
    </>
  );
}
