import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

/**
 * Wraps an object or state which is used in a dependency array to provide
 * up to date values without triggering a re-render in the dependent function
 */
export function useNonRenderingState<T>(
  renderingState: T
): MutableRefObject<T> {
  const referenceToState = useRef<T>(renderingState);

  useEffect(() => {
    referenceToState.current = renderingState;
  }, [renderingState]);

  return referenceToState;
}
