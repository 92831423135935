import { useCallback, useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import {
  clearSearchSessionStorage,
  useAdvancedSearch
} from '../../../components/advancedSearch';
import { useNavigationContext, useTelemetry } from '../../../context';
import type { FetchItemsArgs } from './appViewSetting';
import type { AppViewSetting } from '..';

export function useAdvancedSearchView(): AppViewSetting {
  const { startTelemetry, endTelemetry } = useTelemetry();

  const {
    searchState: { advancedSearch, setAdvancedSearch }
  } = useNavigationContext();

  const executeAdvancedSearch = useAdvancedSearch();

  const fetchItems = useCallback(
    async ({
      requestOptions: httpOptions
    }: FetchItemsArgs): Promise<PWItem[]> => {
      startTelemetry('Search');
      const items = await executeAdvancedSearch(httpOptions);
      endTelemetry('Search', {
        searchType: 'Advanced',
        query: JSON.stringify(advancedSearch),
        numItems: items.length
      });

      return items;
    },
    [executeAdvancedSearch, advancedSearch, startTelemetry, endTelemetry]
  );

  const clearView = useCallback((): void => {
    clearSearchSessionStorage();
    setAdvancedSearch(undefined);
  }, [setAdvancedSearch]);

  const advancedSearchViewSetting = useMemo(
    (): AppViewSetting => ({ type: 'AdvancedSearch', fetchItems, clearView }),
    [clearView, fetchItems]
  );

  return advancedSearchViewSetting;
}
