import type {
  HttpService,
  PWDataItem,
  PWItem,
  RequestOptions
} from '@bentley/pw-api';
import {
  filterDataItems,
  itemIsProject,
  parseInstanceRelationshipInstances,
  parseResponseInstances,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import type { AuditTrail } from '../../../actions/exportAuditTrail';
import { batchedFetch } from '../../batchedFetch';

export async function getAuditTrail(
  item: PWItem,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<AuditTrail[]> {
  if (!item || !item.instanceId || !item.className) {
    throw new Error('Missing item');
  }

  const relationshipClass = itemIsProject(item)
    ? 'PW_WSG.ProjectAuditTrail!poly'
    : 'PW_WSG.DocumentAuditTrail!poly';

  const query = `PW_WSG/${item.className}/${item.instanceId}?$select=${relationshipClass}-forward-PW_WSG.AuditTrail!poly.*`;

  const response = await httpService.get(query, httpOptions);

  const auditTrail = await parseResponseRelationshipInstances<AuditTrail>(
    response
  );
  return auditTrail;
}

export async function getAuditTrails(
  items: PWItem[],
  httpService: HttpService,
  customHttpOptions?: RequestOptions
): Promise<AuditTrail[][]> {
  const dataItems = filterDataItems(items);

  if (!dataItems.every((item) => item.instanceId && item.className)) {
    throw new Error('Missing item');
  }

  const request = buildBatchedRequest(httpService, customHttpOptions);
  const auditTrails = await batchedFetch(request, dataItems, 20, 'concurrent');

  return auditTrails;
}

function buildBatchedRequest(
  httpService: HttpService,
  customHttpOptions?: RequestOptions
) {
  async function batchedAuditTrailRequest(
    items: PWDataItem[]
  ): Promise<AuditTrail[][]> {
    const ids = `'${items.map((item) => item.instanceId).join("','")}'`;
    const classQuery = `PW_WSG/Document!poly/?`;
    const filter = `$filter=$id+in+[${ids}]&`;
    const select = `$select=PW_WSG.DocumentAuditTrail!poly-forward-PW_WSG.AuditTrail!poly.*`;

    const response = await httpService.get(
      `${classQuery}${filter}${select}`,
      customHttpOptions
    );

    const instances = await parseResponseInstances(response);

    const auditTrail = instances.map((document) => {
      const auditTrail =
        parseInstanceRelationshipInstances<AuditTrail>(document);
      return [
        ...auditTrail,
        { instanceId: document.instanceId }
      ] as AuditTrail[];
    });

    return auditTrail;
  }

  return batchedAuditTrailRequest;
}

export function findAssociatedAuditTrail(
  item: PWItem,
  auditTrailsList: AuditTrail[][]
): AuditTrail[] | undefined {
  const auditTrail = auditTrailsList.find((auditTrail) => {
    return auditTrail.pop()?.instanceId == item.instanceId;
  });

  return auditTrail;
}
