import React, { useCallback, useMemo } from 'react';
import { filterProjects, itemIsFlatSet } from '@bentley/pw-api';
import { SvgFolderAdd } from '@itwin/itwinui-icons-react';
import {
  NewFolderDialog,
  allowCreateNewFolder,
  getItemFromResponse
} from '../../../../actions/upload';
import {
  useAppViewContext,
  useNavigationContext,
  usePluginContext,
  useTableItemContext
} from '../../../../context';
import { t } from '../../../../services/translation';
import type { MenuItemAction } from '../../MenuItemAction';

export function useNewFolderAction(): MenuItemAction {
  const { readOnly } = usePluginContext();
  const {
    primaryModal,
    navigationManager: { currentParent }
  } = useNavigationContext();
  const {
    appViewManager: { currentAppView }
  } = useAppViewContext();
  const {
    dataManager,
    selectedState: { setSelectedItems }
  } = useTableItemContext();

  const refresh = useCallback(
    async (response: Response): Promise<void> => {
      const folder = await getItemFromResponse(response);
      if (folder) {
        setSelectedItems([folder]);
      }
      dataManager.refreshCurrentFolder();
    },
    [dataManager, setSelectedItems]
  );

  const onNewFolderClick = useCallback((): void => {
    primaryModal.open(
      <NewFolderDialog
        siblingFolders={filterProjects(dataManager.items)}
        parentId={currentParent.instanceId}
        onFolderComplete={(response) => void refresh(response)}
      />
    );
  }, [currentParent.instanceId, dataManager.items, primaryModal, refresh]);

  const newFolderAction = useMemo(
    (): MenuItemAction => ({
      title: t('NewButton.NewFolder'),
      icon: <SvgFolderAdd />,
      disabled: !allowCreateNewFolder(
        currentParent,
        currentAppView.type,
        readOnly
      ),
      hidden:
        readOnly ||
        itemIsFlatSet(currentParent) ||
        currentAppView.type != 'FolderNavigation',
      onClick: onNewFolderClick
    }),
    [currentAppView.type, currentParent, onNewFolderClick, readOnly]
  );

  return newFolderAction;
}
