import React from 'react';
import type {
  HttpService,
  PWItem,
  WSGInstancesResponse
} from '@bentley/pw-api';
import type { ProgressSummaryItem } from '../../components/ProgressSummary';
import { ProgressSummaryModal } from '../../components/ProgressSummary';
import type { ConsumerApp } from '../../context';
import type { CloseModal, OpenModal } from '../../hooks/useModal';
import type {
  InProgressActivity,
  InProgressJob,
  ProgressManager
} from '../../hooks/useProgressManager';
import type { FeatureName } from '../../hooks/useTrackFeature';
import { t } from '../../services/translation';
import { ConfirmLargeDownloadModal } from './confirmLargeDownloadModal';
import { downloadZippedItems } from './download';
import LogicalSetConfirmationModal from './logicalSetConfirmationModal';

export function logicalSetDownloadModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  items: PWItem[],
  httpService: HttpService,
  trackFeature: (featureName: FeatureName) => void,
  progressManager: ProgressManager,
  consumerApp?: ConsumerApp,
  searchName?: string,
  useAttributeExchange?: boolean
): void {
  const modal = (
    <LogicalSetConfirmationModal
      closeModal={closeModal}
      onNo={() =>
        void downloadZippedItems(
          items,
          httpService,
          openModal,
          closeModal,
          trackFeature,
          progressManager,
          false,
          false,
          consumerApp,
          searchName,
          undefined,
          undefined,
          undefined,
          useAttributeExchange
        )
      }
      onYes={() =>
        void downloadZippedItems(
          items,
          httpService,
          openModal,
          closeModal,
          trackFeature,
          progressManager,
          true,
          false,
          consumerApp,
          searchName,
          undefined,
          undefined,
          undefined,
          useAttributeExchange
        )
      }
    />
  );
  openModal(modal);
}

export function confirmLargeDownloadModal(
  downloadSize: number,
  openModal: OpenModal,
  closeModal: CloseModal,
  onClose: (proceed: boolean) => void
): void {
  const modal = (
    <ConfirmLargeDownloadModal downloadSize={downloadSize} onClose={onClose} />
  );
  openModal(modal);
}

export async function openDownloadFailureSummaryModal(
  openModal: OpenModal,
  closeModal: CloseModal,
  job?: InProgressJob
): Promise<void> {
  const failures = job?.activitiesInProgress.filter(
    (aip) => aip.status == 'error'
  );

  if (!failures?.length) {
    return;
  }

  const title =
    failures.length == 1
      ? t('Download.FileFailedToDownload')
      : t('Download.FilesFailedToDownload');
  const progressSummaryItems = await Promise.all(
    failures.map(toProgressSummaryItem)
  );

  openModal(
    <ProgressSummaryModal
      closeModal={closeModal}
      summaries={progressSummaryItems}
      title={title}
    />
  );
}

async function toProgressSummaryItem(
  failure: InProgressActivity
): Promise<ProgressSummaryItem> {
  const errorText = await errorMessage(
    failure.error as Response & Record<string, string>
  );

  return {
    item: failure.item,
    errorText: errorText,
    uniqueId: failure.uniqueId
  } as ProgressSummaryItem;
}

async function errorMessage(
  error: Response & Record<string, string>
): Promise<string> {
  if (error instanceof Response) {
    if (error.status == 401 || error.status == 403) {
      return t('Downloads.Notifications.InsufficientPermission');
    }

    const body = (await error.json()) as WSGInstancesResponse;
    if (body.errorMessage) {
      return body.errorMessage;
    }
  }

  return error.message;
}
