import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { WSGInstance } from '@bentley/pw-api';
import { filterDataItems } from '@bentley/pw-api';
import type { FormDefinition } from '../../../actions/formRendering';
import { getProjectDefaultFormDefinition } from '../../../actions/formRendering';
import { useHttpService, useTableItemContext } from '../../../context';
import { getDocumentFormDefinition } from '../../attributesForm/data';
import { generateReloadFormAction } from '../../documentCreation/form';
import { validateEnvironmentProperties } from '../../documentCreation/properties';
import { handleDocCodeFormError } from '../error';
import { originalEnvironmentInstanceState } from '../state';
import { formDefinitionState, updatingReloadPropertiesState } from './state';

type FormReloadWorkflowFunctions = {
  reloadFormByInstanceWorkflow: (
    environmentInstance: WSGInstance
  ) => Promise<void>;
  reloadFormWorkflow: () => Promise<void>;
};

export function useFormReloadWorkflow(): FormReloadWorkflowFunctions {
  const { actionableItems } = useTableItemContext();

  const httpService = useHttpService();

  const originalEnvironmentInstance = useRecoilValue(
    originalEnvironmentInstanceState
  );

  const setUpdatingReloadProperties = useSetRecoilState(
    updatingReloadPropertiesState
  );

  const setFormDefinition = useSetRecoilState(formDefinitionState);

  const reloadWorkflow = useCallback(
    async (
      formRetrievalFunction: () => Promise<FormDefinition>
    ): Promise<void> => {
      try {
        setUpdatingReloadProperties(true);
        const formDefinition = await formRetrievalFunction();
        setFormDefinition(formDefinition);
      } catch (error) {
        if (error instanceof Response || error instanceof Error) {
          void handleDocCodeFormError(error);
        }
      } finally {
        setUpdatingReloadProperties(false);
      }
    },
    [setFormDefinition, setUpdatingReloadProperties]
  );

  const reloadFormByInstanceWorkflow = useCallback(
    async (environmentInstance: WSGInstance): Promise<void> => {
      if (
        environmentInstance.properties &&
        originalEnvironmentInstance?.properties
      ) {
        const validatedProperties = validateEnvironmentProperties(
          environmentInstance.properties,
          originalEnvironmentInstance?.properties,
          false
        );

        environmentInstance = {
          ...environmentInstance,
          properties: validatedProperties
        };
      }

      async function formRetrievalFunction(): Promise<FormDefinition> {
        return generateReloadFormAction(
          'documentcode',
          environmentInstance,
          httpService
        );
      }

      await reloadWorkflow(formRetrievalFunction);
    },
    [httpService, originalEnvironmentInstance?.properties, reloadWorkflow]
  );

  const reloadFormWorkflow = useCallback(async (): Promise<void> => {
    async function formRetrievalFunction(): Promise<FormDefinition> {
      const item = filterDataItems(actionableItems)[0];
      let formDefinition = await getDocumentFormDefinition(item);

      if (!formDefinition) {
        formDefinition = await getProjectDefaultFormDefinition(
          item.ParentGuid ?? '',
          httpService
        );

        if (!formDefinition) {
          throw 'Unable to get form definition';
        }
      }

      return formDefinition;
    }

    await reloadWorkflow(formRetrievalFunction);
  }, [reloadWorkflow, actionableItems, httpService]);

  return { reloadFormByInstanceWorkflow, reloadFormWorkflow };
}
