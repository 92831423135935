import type { HttpService, PWDataItem, WSGInstance } from '@bentley/pw-api';

export async function newDocumentVersion(
  item: PWDataItem,
  version: string | undefined,
  httpService: HttpService
): Promise<Response> {
  const url = `PW_WSG/${item.className}/`;
  const instance = getNewVersionInstance(item, version);
  const body = JSON.stringify({ instance });

  const response = await httpService.post(url, body);

  return response;
}

export async function newDocumentVersions(
  items: PWDataItem[],
  version: string | undefined,
  httpService: HttpService
): Promise<Response> {
  const instances = getNewVersionInstances(items, version);
  const body = JSON.stringify({ instances });

  const response = await httpService.post('$changeset', body);

  return response;
}

export async function newLogicalSetVersion(
  item: PWDataItem,
  version: string | undefined,
  httpService: HttpService
): Promise<Response> {
  const url = `PW_WSG/${item.className}/${item.instanceId}/CommandRun`;
  const instance = getNewLogicalSetVersionInstance(item, version);
  const body = JSON.stringify({ instance });

  const response = await httpService.post(url, body);

  return response;
}

export async function newLogicalSetVersions(
  items: PWDataItem[],
  version: string | undefined,
  httpService: HttpService
): Promise<Response[]> {
  const responses = await Promise.all(
    items.map((item) => newLogicalSetVersion(item, version, httpService))
  );
  return responses;
}

function getNewVersionInstance(item: PWDataItem, newVersion = ''): WSGInstance {
  return {
    schemaName: 'PW_WSG',
    className: item.className,
    instanceId: item.instanceId,
    changeState: 'new',
    properties: {
      Name: item.Name,
      version: newVersion
    },
    relationshipInstances: [
      {
        schemaName: 'PW_WSG',
        className: 'DocumentParent',
        direction: 'forward',
        relatedInstance: {
          schemaName: 'PW_WSG',
          className: 'Project',
          instanceId: item.ParentGuid
        }
      }
    ]
  } as WSGInstance;
}

function getNewVersionInstances(
  items: PWDataItem[],
  newVersion = ''
): WSGInstance[] {
  return items.map((item) => getNewVersionInstance(item, newVersion));
}

function getNewLogicalSetVersionInstance(item: PWDataItem, newVersion = '') {
  return {
    className: 'CommandRun',
    schemaName: 'PW_WSG',
    instanceId: item.instanceId,
    properties: {
      CommandId: 'urn:bentley:document:command:NewVersion',
      Comment: newVersion,
      CommandArguments: [{ Name: 'VersionName', Value: newVersion }]
    }
  };
}
