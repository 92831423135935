import { useCallback, useMemo } from 'react';
import type {
  PWDataItem,
  PWDocument,
  PWItem,
  PWProject
} from '@bentley/pw-api';
import { filterDataItems, filterDocuments } from '@bentley/pw-api';
import {
  getConflictingFiles,
  getConflictingProjects
} from '../../actions/conflictResolution';
import { getItemCommandsBatched } from '../../actions/workflow';
import { useHttpService } from '../../context';

type ConflictSiblings = {
  conflictSiblingsItems: PWItem[];
  conflictSiblingsWRECommands: string[];
};

type ConflictSiblingsFunction = {
  getConflictSiblings: () => Promise<ConflictSiblings>;
};

export function useConflictSiblings(
  parent: PWProject,
  sourceItems: { item: PWItem }[]
): ConflictSiblingsFunction {
  const httpService = useHttpService();

  const getWRECommandIds = useCallback(
    async (documents: PWDocument[]): Promise<string[]> => {
      const abortController = new AbortController();

      const commands = await getItemCommandsBatched(documents, httpService, {
        abortController
      });

      if (!commands?.length) {
        return [];
      }

      if (abortController.signal.aborted) {
        return [];
      }

      const allCommands = commands.map((command) => command.commands).flat();
      const commandIds = allCommands.map((com) => com.instanceId);
      return Array.from(new Set(commandIds));
    },
    [httpService]
  );

  const getConflictingFolders = useCallback(async (): Promise<PWProject[]> => {
    const names = sourceItems.map(({ item }) => item.Name);

    const siblings = await getConflictingProjects(
      parent.instanceId,
      names,
      httpService
    );

    return siblings;
  }, [httpService, parent, sourceItems]);

  const getConflictingDocuments = useCallback(async (): Promise<
    PWDataItem[]
  > => {
    const names = sourceItems.map(({ item }) => item.Name);

    const fileNames = filterDataItems(sourceItems.map((item) => item.item)).map(
      (item) => item.FileName
    );

    const uploadNames = [...names, ...fileNames];

    const siblings = await getConflictingFiles(
      parent.instanceId,
      uploadNames,
      httpService
    );

    return siblings;
  }, [httpService, parent, sourceItems]);

  const getConflictSiblings =
    useCallback(async (): Promise<ConflictSiblings> => {
      const conflictingFolders = await getConflictingFolders();
      const conflictingDocuments = await getConflictingDocuments();

      let commands: string[] = [];

      const documents = filterDocuments(conflictingDocuments);

      if (documents.length) {
        commands = await getWRECommandIds(documents);
      }

      return {
        conflictSiblingsItems: [...conflictingFolders, ...conflictingDocuments],
        conflictSiblingsWRECommands: commands
      };
    }, [getConflictingDocuments, getConflictingFolders, getWRECommandIds]);

  const conflictSiblings = useMemo(
    (): ConflictSiblingsFunction => ({
      getConflictSiblings
    }),
    [getConflictSiblings]
  );

  return conflictSiblings;
}
