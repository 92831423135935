import { formatDateTime } from '../../services/stringFormatter';
import { t } from '../../services/translation';
import type { AuditTrail } from './exportAuditTrailModal';

export function getCsv(
  auditTrail: AuditTrail[],
  beginDate?: Date,
  endDate?: Date
): string {
  let csv = `${t('AuditTrail.BaseCSV')}`;
  auditTrail = auditTrail.sort(
    (at1, at2) => Date.parse(at1.Date) - Date.parse(at2.Date)
  );

  const filteredAuditTrail =
    beginDate && endDate
      ? [...auditTrail].filter((at) => {
          const date = new Date(Date.parse(at.Date));
          return date >= beginDate && date <= endDate;
        })
      : auditTrail;

  if (filteredAuditTrail.length > 0) {
    filteredAuditTrail.forEach(
      (t) =>
        (csv += `\n${t.ObjectType},${t.ObjectName},${
          t.ActionName
        },"${formatDateTime(
          t.Date
        )}",${`"${t.UserName}"`},${`"${t.AdditionalData}"`},${`"${t.Comment}"`}`)
    );
  } else {
    csv = `${t('AuditTrail.NoHistory')}`;
  }

  return csv;
}
