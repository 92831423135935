import { useCallback } from 'react';
import type { PWItem, PWParentType } from '@bentley/pw-api';
import {
  useAppViewContext,
  useFolderExplorerContext,
  useHttpService,
  useNavigationContext,
  useTableItemContext
} from '../../context';

type OnMoveComplete = (
  items: PWItem[],
  destinationParent: PWParentType,
  sourceParent: PWParentType
) => Promise<void>;

export function useOnMoveComplete(): OnMoveComplete {
  const httpService = useHttpService();
  const {
    navigationManager: { navigateTo }
  } = useNavigationContext();
  const {
    selectedState: { forceSelectedIds }
  } = useTableItemContext();
  const { reloadExplorerFolders } = useFolderExplorerContext();
  const {
    appViewManager: { setAppView }
  } = useAppViewContext();

  const onComplete = useCallback(
    async (
      items: PWItem[],
      destinationParent: PWParentType,
      sourceParent: PWParentType
    ): Promise<void> => {
      await httpService.cache?.clearEntriesMatching(
        destinationParent.instanceId
      );
      await httpService.cache?.clearEntriesMatching(sourceParent.instanceId);
      await httpService.cache?.clearEntriesMatching(
        items.map((item) => item.instanceId)
      );

      setAppView('FolderNavigation');

      navigateTo(destinationParent, true);

      reloadExplorerFolders([
        destinationParent.instanceId,
        ...items.map((item) => item.ParentGuid ?? '')
      ]);
      forceSelectedIds(items.map((item) => item.instanceId));
    },
    [
      httpService.cache,
      forceSelectedIds,
      navigateTo,
      reloadExplorerFolders,
      setAppView
    ]
  );

  return onComplete;
}
