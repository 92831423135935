import { useCallback, useMemo, useRef, useState } from 'react';
import { useHttpService } from '../../../context';
import type { Attribute } from '../environmentAttributes/attributeDefinitions';
import {
  getProjectPropertyDefinitions,
  getProjectTypes
} from './projectPropertyDefinitions';

type ProjectPropertyDefinitions = {
  projectPropertyDefinitions: Record<string, Attribute[]> | undefined;
  loadProjectPropertyDefinitions: () => Promise<void>;
};

export function useProjectPropertyDefinitions(): ProjectPropertyDefinitions {
  const httpService = useHttpService();

  const [propertyDefinitions, setPropertyDefinitions] =
    useState<Record<string, Attribute[]>>();
  const propertyDefinitionsLoaded = useRef<boolean>(false);

  const loadProjectPropertyDefinitions =
    useCallback(async (): Promise<void> => {
      if (propertyDefinitionsLoaded.current) {
        return;
      }

      const loadedDefinitions = {} as Record<string, Attribute[]>;
      const projectTypes = await getProjectTypes(httpService);

      await Promise.all(
        projectTypes.map(async (projectType) => {
          const definitions = await getProjectPropertyDefinitions(
            projectType,
            httpService
          );
          loadedDefinitions[projectType.DisplayLabel] = definitions;
        })
      );

      setPropertyDefinitions(loadedDefinitions);
      propertyDefinitionsLoaded.current = true;
    }, [httpService]);

  const projectProperty = useMemo(
    () => ({
      projectPropertyDefinitions: propertyDefinitions,
      loadProjectPropertyDefinitions: loadProjectPropertyDefinitions
    }),
    [loadProjectPropertyDefinitions, propertyDefinitions]
  );

  return projectProperty;
}
