import _ from 'lodash';
import type {
  HttpService,
  PWItem,
  PWItemClass,
  PWProject,
  RequestOptions
} from '@bentley/pw-api';
import {
  parseResponseInstances,
  parseResponseRelationshipInstances
} from '@bentley/pw-api';
import type { SavedSearchDefinition } from '../../services/search';
import { savedSearchSelect } from './select';
import { logError } from '../../hooks/useErrorLogging';

export async function getSavedSearch(
  searchId: string,
  httpService: HttpService
): Promise<SavedSearchDefinition> {
  const response = await httpService.get(
    `PW_WSG/SavedSearchDefinition/${searchId}`
  );
  const search = await parseResponseInstances<SavedSearchDefinition>(response);
  return search[0];
}

export async function getWorkAreasInProject(
  id: string,
  httpService: HttpService,
  httpOptions: RequestOptions
): Promise<PWProject[]> {
  const url =
    id !== null && id !== undefined && id !== ''
      ? `PW_WSG/Project!poly?api.ancestor=PW_WSG.Project-${id}&`
      : 'PW_WSG/Project!poly?';
  const filter = `$filter=IsRichProject+eq+true`;
  const orderBy = '&$orderby=Name';
  const apiSettings = '&api.filtersettings=CaseInsensitive';
  const response = await httpService.get(
    `${url}${filter}${orderBy}${apiSettings}`,
    httpOptions
  );

  let data = await parseResponseInstances<PWProject>(response);
  let orphanedProjects = orphanedItems(data);

  while (orphanedProjects.length > 0) {
    const parentFolderInfos = await getFolderInfo(
      orphanedProjects.map((a) => a.ParentGuid ?? ''),
      httpService,
      httpOptions
    );
    // There are some folder which are not accessible coz user doesn't have access.
    if (orphanedProjects.length != parentFolderInfos.length) {
      const nonAccessibleFolders = _.intersectionWith(
        orphanedProjects,
        parentFolderInfos,
        (orphaned, folder) => orphaned.ParentGuid != folder.instanceId
      );
      data = _.differenceBy(data, nonAccessibleFolders, 'instanceId');
    }
    data = data.concat(parentFolderInfos);
    orphanedProjects = orphanedItems(data);
  }
  return data;
}

function orphanedItems(items: PWProject[]): PWProject[] {
  const instanceIds = items.map((item) => item.instanceId);

  const nonRootItems = items.filter((a) => a.ParentGuid != undefined);

  const orphanedItems = nonRootItems.filter(
    ({ ParentGuid }) => !instanceIds.includes(ParentGuid ?? '')
  );

  return orphanedItems;
}

async function getFolderInfo(
  ids: string[],
  httpService: HttpService,
  httpOptions: RequestOptions
): Promise<PWProject[]> {
  const url = 'PW_WSG/Project!poly';
  const filter = `?$filter=$id+in+['${ids.join("','")}']`;
  const orderBy = '&$orderby=Name';
  const apiSettings = '&api.filtersettings=CaseInsensitive';
  const response = await httpService.get(
    `${url}${filter}${orderBy}${apiSettings}`,
    httpOptions
  );

  return await parseResponseInstances<PWProject>(response);
}

export async function executeSavedSearch(
  id: string,
  documentClassName: PWItemClass,
  httpService: HttpService,
  httpOptions?: RequestOptions
): Promise<PWItem[]> {
  try {
    const url = `PW_WSG/savedsearchdefinition`;
    const filter = `$id+eq+'${id}'`;

    const select = savedSearchSelect(documentClassName);
    const query = `${url}?$filter=${filter}&$select=${select}&api.filtersettings=CaseInsensitive`;
    const response = await httpService.get(query, httpOptions);

    const items = await parseResponseRelationshipInstances<PWItem>(response);

    return items;
  } catch (error) {
    logError('Saved search failed', { error });
    throw error;
  }
}
