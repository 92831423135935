import type { HttpService, PWItem, WSGInstance } from '@bentley/pw-api';

export async function permanentlyDeleteItem(
  item: PWItem,
  httpService: HttpService
): Promise<void> {
  const instance = buildDeleteInstance(item);
  const body = JSON.stringify({ instance });
  const response = await httpService.post('MAGIC URL TBD', body);
  throwIfBadResponse(response);
}

function buildDeleteInstance(item: PWItem): WSGInstance {
  // Todo: build out instance
  return {
    schemaName: 'PW_WSG',
    className: item.className,
    instanceId: item.instanceId
  };
}

function throwIfBadResponse(response: Response): void {
  if (!response.ok || response.status != 200) {
    throw new Error('Unable to delete item');
  }
}
