import { useMemo } from 'react';
import type { PWItem } from '@bentley/pw-api';
import { parseRelatedParent } from '@bentley/pw-api';
import type { Column } from '@itwin/itwinui-react/react-table';
import { usePluginContext } from '../../../../context';
import { t } from '../../../../services/translation';
import { ParentCell } from '../../cell/parentCell';
import { customPropertySortType } from '../../sort/columnSort';
import { lookupAccessor } from '../accessor';
import { useBaseColumn } from '../useBaseColumn';

export function useParentColumn(): Column<PWItem> {
  const { connection } = usePluginContext();

  const baseColumn = useBaseColumn();

  const parentColumn = useMemo((): Column<PWItem> => {
    const column = baseColumn({
      displayLabel: t('Columns.FolderName'),
      name: 'FolderName',
      dataType: 'string',
      propertyType: 'General'
    });

    const lookupFunction = (row: PWItem) => {
      if (parseRelatedParent(row)?.instanceId == connection.ProjectId) {
        return connection.Name;
      }

      return parseRelatedParent(row)?.Name ?? '';
    };

    return {
      ...column,
      accessor: lookupAccessor(lookupFunction, 'string'),
      Cell: ParentCell,
      sortType: customPropertySortType(lookupFunction, 'string')
    } as Column<PWItem>;
  }, [baseColumn, connection.Name, connection.ProjectId]);

  return parentColumn;
}
