import type { ToastHandle } from '../../../services/pwToast';
import { replaceToast } from '../../../services/pwToast';
import { t } from '../../../services/translation';

export function notifyCreatingDocuments(
  toastHandle?: ToastHandle
): ToastHandle {
  const content = t('DocumentCreation.CreatingDocuments');
  return replaceToast(toastHandle, {
    content,
    type: 'persisting',
    spinner: true
  });
}
